import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { EmptyStatePageComponent } from '@shared/components/empty-state/empty-state-page.component';
import { ComponentRenderState } from '@shared/types';

@Component({
  selector: 'admin-segment-member-count',
  template: `
    <div class="counter-container">
      @switch (state) {
        @case ('loading') {
          <admin-empty-state-page
            title="Calculating..."
            message="We’re calculating the number of members in the segment. Thank you for your patience."
            iconName="calculator"
          >
          </admin-empty-state-page>
        }
        @case ('loaded') {
          <div class="counter-loaded-state">
            <span data-testid="segment-review-member-timestamp"
              >As of {{ countedAt ?? currentDate | date: 'MMM dd, yyyy' : timezone }}</span
            >
            <p data-testid="segment-review-member-count">
              Total number of members:
              <span class="counter-total-value font-semibold text-cloudburst-500">{{ memberCount | number }}</span>
            </p>
            <ng-content></ng-content>
          </div>
        }
        @default {
          <admin-empty-state-page
            message="The number of members is currently unavailable, please try again."
          ></admin-empty-state-page>
        }
      }
    </div>
  `,
  styleUrls: ['./segment-member-count.component.scss', '../../styles/rules-container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [EmptyStatePageComponent, CommonModule]
})
export class SegmentMemberCountComponent {
  @Input() memberCount = 0;
  @Input() countedAt: Date | string | null;
  @Input() state: ComponentRenderState = 'initial';

  currentDate = new Date();

  constructor(@Inject('timezone') protected timezone: string) {}
}
