import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { GenesisModule } from '@kaligo/genesis';
import { FeatureDisabledDirective, FeatureEnabledDirective } from '@karelics/angular-unleash-proxy-client';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ColorChromeModule } from 'ngx-color/chrome';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { coreReducers } from '@core/store';
import { AuthEffects } from '@core/store/auth/effects/auth.effects';
import { ErrorActionTypes, ErrorEffects } from '@core/store/errors';
import { NoopEffects } from '@core/store/noop';
import { NydusNetworkBootstrapEffects } from '@core/store/nydus-network/effects/nydus-network-bootstrap.effects';
import { RouterEffects } from '@core/store/router/effects/router.effects';
import { SharedConfigEffects } from '@core/store/shared-config/effects/shared-config.effects';
import { SuccessActionTypes, SuccessEffects } from '@core/store/success';
import { TenantBootstrapEffects } from '@core/store/tenant-bootstrap/effects/tenant-bootstrap.effects';
import { UserAbilityEffects } from '@core/store/user-abilities/effects/user-abilities.effects';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { VerifiedIndicatorComponent } from '@shared/components/verified-indicator/verified-indicator.component';
import { AmplitudeClickEventDirective } from '@shared/directives/amplitude-click-event.directive';

import { AbilitiesEffects } from '../abilities/store/effects/abilities.effects';
import { abilitiesReducer } from '../abilities/store/reducers/abilities.reducer';
import { AccessPoliciesEffects } from '../access-policies/store/effects/access-policies.effects';
import { accessPoliciesReducer } from '../access-policies/store/reducers/access-policies.reducer';
import { AlfredSuppliersEffects } from '../alfred-suppliers/store/effects/alfred-suppliers.effects';
import { alfredSuppliersReducer } from '../alfred-suppliers/store/reducers/alfred-suppliers.reducer';
import { AllowedOriginsEffects } from '../allowed-origins/store/effects/allowed-origins.effects';
import { allowedOriginsReducer } from '../allowed-origins/store/reducers/allowed-origins.reducer';
import { AnalyticsEffects } from '../analytics/store/effects/analytics.effects';
import { analyticsReducer } from '../analytics/store/reducers/analytics.reducer';
import { ApiSettingsTokenEffects } from '../api-settings/store/effects/api-settings-token.effects';
import { ApiSettingsEffects } from '../api-settings/store/effects/api-settings.effects';
import { apiSettingsTokenReducer } from '../api-settings/store/reducers/api-settings-token.reducer';
import { apiSettingsReducer } from '../api-settings/store/reducers/api-settings.reducer';
import { ApprovalRequestsEffects } from '../approval-requests/store/effects/approval-requests.effects';
import { approvalRequestsReducer } from '../approval-requests/store/reducers/approval-requests.reducer';
import { AppConnectionsEffects } from '../apps/store/effects/app-connections.effects';
import { AppsEffects } from '../apps/store/effects/apps.effects';
import { CerberusConfigsEffects } from '../apps/store/effects/cerberus-configs.effects';
import { ConnectionCredentialsEffects } from '../apps/store/effects/connection-credentials.effects';
import { SentinelConfigsEffects } from '../apps/store/effects/sentinel-configs.effects';
import { appConnectionsReducer } from '../apps/store/reducers/app-connections.reducer';
import { appsReducer } from '../apps/store/reducers/apps.reducer';
import { cerberusConfigsReducer } from '../apps/store/reducers/cerberus-configs.reducer';
import { connectionCredentialsReducer } from '../apps/store/reducers/connection-credentials.reducer';
import { sentinelConfigsReducer } from '../apps/store/reducers/sentinel-configs.reducer';
import { AuditLogsEffects } from '../audit-logs/store/effects/audit-logs.effects';
import { auditLogsReducer } from '../audit-logs/store/reducers/audit-logs.reducer';
import { AuthenticationProvidersEffects } from '../authentication-providers/store/effects/authentication-providers.effects';
import { authenticationProvidersReducer } from '../authentication-providers/store/reducers/authentication-providers.reducer';
import { AutoRedemptionsEffects } from '../auto-redemptions/store/effects/auto-redemptions.effects';
import { autoRedemptionsReducer } from '../auto-redemptions/store/reducers/auto-redemptions.reducer';
import { CampaignProposalsEffects } from '../campaign-proposals/store/effects/campaign-proposals.effects';
import { campaignProposalsReducer } from '../campaign-proposals/store/reducers/campaign-proposals.reducer';
import { merchantPortalBootstrapReducer } from '../campaign-proposals/store/reducers/merchant-portal-bootstrap.reducer';
import { CampaignsEffects } from '../campaigns/store/effects/campaigns.effects';
import { campaignsReducer } from '../campaigns/store/reducers/campaigns.reducer';
import { CustomDomainEffects } from '../custom-domain/store/effects/custom-domain.effects';
import { customDomainReducer } from '../custom-domain/store/reducers/custom-domain.reducer';
import { PointsAccountsEffects } from '../dashboard/store/effects/points-accounts.effects';
import { PointsActivitiesEffects } from '../dashboard/store/effects/points-activities.effects';
import { PointsAdjustmentsEffects } from '../dashboard/store/effects/points-adjustments.effects';
import { PointsStatementsEffects } from '../dashboard/store/effects/points-statements.effects';
import { dashboardReducers } from '../dashboard/store/reducers';
import { DomainsEffects } from '../domains/store/effects/domains.effects';
import { domainsReducer } from '../domains/store/reducers/domains.reducer';
import { EarnRulesEffects } from '../earn-rules/store/effects/earn-rules.effects';
import { EventEarnRulesEffects } from '../earn-rules/store/effects/event-earn-rules.effects';
import { earnRulesReducer } from '../earn-rules/store/reducers/earn-rules.reducer';
import { eventEarnRulesReducer } from '../earn-rules/store/reducers/event-earn-rules.reducer';
import { EnrollmentsEffects } from '../enrollments/store/effects/enrollments.effects';
import { enrollmentsReducer } from '../enrollments/store/reducers/enrollments.reducer';
import { EventTypesEffects } from '../event-types/store/effects/event-types.effects';
import { eventTypesReducer } from '../event-types/store/reducers/event-types.reducer';
import { EventsEffects } from '../events/store/effects/events.effects';
import { eventsReducer } from '../events/store/reducers/events.reducer';
import { InputChipListComponent } from '../form-fields/components/input-chip-list/input-chip-list.component';
import { FraudEvaluationsEffects } from '../fraud-evaluations/store/effects/fraud-evaluations.effects';
import { fraudEvaluationsReducer } from '../fraud-evaluations/store/reducers/fraud-evaluations.reducer';
import { FraudListsEffects } from '../fraud-lists/store/effects/fraud-lists.effects';
import { fraudListsReducer } from '../fraud-lists/store/reducers/fraud-lists.reducer';
import { FraudRuleSchemasEffects } from '../fraud-rules/store/effects/fraud-rule-schemas.effects';
import { FraudRulesEffects } from '../fraud-rules/store/effects/fraud-rules.effects';
import { fraudRuleSchemasReducer } from '../fraud-rules/store/reducers/fraud-rule-schemas.reducer';
import { fraudRulesReducer } from '../fraud-rules/store/reducers/fraud-rules.reducer';
import { GhCacheEffects } from '../gh-cache/store/effects/gh-cache.effects';
import { ghCacheReducer } from '../gh-cache/store/reducers/gh-cache.reducer';
import { AlfredTenantsEffects } from '../gift-cards/store/effects/alfred-tenants.effects';
import { BootstrapsEffects } from '../gift-cards/store/effects/bootstraps.effects';
import { GiftCardOrdersEffects } from '../gift-cards/store/effects/gift-card-orders.effects';
import { GiftCardsEffects } from '../gift-cards/store/effects/gift-cards.effects';
import { OrderAttemptsEffects } from '../gift-cards/store/effects/order-attempts.effects';
import { OrderWebhooksEffects } from '../gift-cards/store/effects/order-webhooks.effects';
import { alfredTenantsReducer } from '../gift-cards/store/reducers/alfred-tenants.reducer';
import { bootstrapsReducer } from '../gift-cards/store/reducers/bootstraps.reducer';
import { giftCardOrdersReducer } from '../gift-cards/store/reducers/gift-card-orders.reducer';
import { giftCardsReducer } from '../gift-cards/store/reducers/gift-cards.reducer';
import { orderAttemptsReducer } from '../gift-cards/store/reducers/order-attempts.reducer';
import { orderWebhooksReducer } from '../gift-cards/store/reducers/order-webhooks.reducer';
import { KeysEffects } from '../keys/store/effects/keys.effects';
import { keysReducer } from '../keys/store/reducers/keys.reducer';
import { LoginAttemptsEffects } from '../login-attempts/store/effects/login-attempts.effects';
import { loginAttemptsReducer } from '../login-attempts/store/reducers/login-attempts.reducer';
import { PointsAccountsEffects as LoyaltyDataPointsAccountEffects } from '../loyalty-data/store/effects/points-accounts.effects';
import { ProductsEffects } from '../loyalty-data/store/effects/products.effects';
import { pointsAccountsReducer } from '../loyalty-data/store/reducers/points-accounts.reducer';
import { productsReducer } from '../loyalty-data/store/reducers/products.reducer';
import { LoyaltyProgramsEffects } from '../loyalty-programs/store/effects/loyalty-programs.effects';
import { loyaltyProgramsReducer } from '../loyalty-programs/store/reducers/loyalty-programs.reducer';
import { MaterialModule } from '../material/material.module';
import { McTenantsEffects } from '../mc-tenants/store/effects/mc-tenants.effects';
import { mcTenantsReducer } from '../mc-tenants/store/reducers/mc-tenants.reducer';
import { McTranslationsEffects } from '../mc-translations/store/effects/mc-translations.effects';
import { mcTranslationsReducer } from '../mc-translations/store/reducers/mc-translations.reducer';
import { BenefitsEffects } from '../memberships/store/effects/benefits.effects';
import { MembershipsEffects } from '../memberships/store/effects/memberships.effects';
import { RewardPreferencesEffects } from '../memberships/store/effects/reward-preferences.effects';
import { benefitsReducer } from '../memberships/store/reducers/benefits.reducer';
import { membershipsReducer } from '../memberships/store/reducers/memberships.reducer';
import { rewardPreferencesReducer } from '../memberships/store/reducers/reward-preferences.reducer';
import { MerchandiseCategoriesEffects } from '../merchandises/store/effects/merchandise-categories.effects';
import { MerchandisesEffects } from '../merchandises/store/effects/merchandises.effects';
import { merchandiseCategoriesReducer } from '../merchandises/store/reducers/merchandise-categories.reducer';
import { merchandisesReducer } from '../merchandises/store/reducers/merchandises.reducer';
import { MergeConfigsEffects } from '../merge-configs/store/effects/merge-configs.effects';
import { mergeConfigsReducer } from '../merge-configs/store/reducers/merge-configs.reducer';
import { NotesEffects } from '../notes/store/effects/notes.effects';
import { notesReducer } from '../notes/store/reducers/notes.reducer';
import { NotificationsEffects } from '../notifications/store/effects/notifications.effects';
import { notificationsReducer } from '../notifications/store/reducers/notifications.reducer';
import { OfferSourcesEffects } from '../offers/store/effects/offer-sources.effects';
import { OffersEffects } from '../offers/store/effects/offers.effects';
import { offerSourcesReducer, offersReducer } from '../offers/store/reducers';
import { CurrencyConversionEffects } from '../orders/store/effects/currency-conversion.effects';
import { OrderItemsEffects } from '../orders/store/effects/order-items.effects';
import { RedemptionsEffects } from '../orders/store/effects/redemptions.effects';
import { currencyConversionReducer } from '../orders/store/reducers/currency-conversion.reducer';
import { orderItemsReducer } from '../orders/store/reducers/order-items.reducer';
import { redemptionsReducer } from '../orders/store/reducers/redemptions.reducer';
import { PartnerConfigsEffects } from '../partner-configs/store/effects/partner-configs.effects';
import { partnerConfigsReducer } from '../partner-configs/store/reducers/partner-configs.reducer';
import { TenantPaymentTierEffects } from '../payment-tiers/store/effects/tenant-payment-tier.effects';
import { FileProcessingRequestsEffects } from '../pickup-zones/store/effects/file-processing-requests.effects';
import { PickupZonesEffects } from '../pickup-zones/store/effects/pickup-zones.effects';
import { RemoteFilesEffects } from '../pickup-zones/store/effects/remote-files.effects';
import { fileProcessingRequestsReducer, pickupZonesReducer, remoteFilesReducer } from '../pickup-zones/store/reducers';
import { PromoCodesEffects } from '../promo-codes/store/effects/promo-codes.effects';
import { promoCodesReducer } from '../promo-codes/store/reducers/promo-codes.reducer';
import { QuestionsEffects } from '../questions/store/effects/questions.effects';
import { questionsReducer } from '../questions/store/reducers/questions.reducer';
import {
  hideRecommendationFailure,
  unhideRecommendationFailure,
  unhideRecommendationSuccess
} from '../recommendations/store/actions/recommendations.actions';
import { RecommendationsEffects } from '../recommendations/store/effects/recommendations.effects';
import { recommendationReducers } from '../recommendations/store/reducers';
import { RedemptionRatesEffects } from '../redemption-rates/store/effects/redemption-rates.effects';
import { redemptionRatesReducer } from '../redemption-rates/store/reducers/redemption-rates.reducer';
import { RewardTriggersEffects } from '../reward-triggers/store/effects/reward-triggers.effects';
import { rewardTriggersReducer } from '../reward-triggers/store/reducers/reward-triggers.reducer';
import { CategorisedLoyaltyProgramsEffects } from '../rewards-offering/store/effects/categorised-loyalty-programs.effects';
import { ConfigurationChangesEffects } from '../rewards-offering/store/effects/configuration-changes.effects';
import { GiftCardsConfigEffects } from '../rewards-offering/store/effects/gift-cards-config.effects';
import { NydusNetworkPartnerConfigurationsEffects } from '../rewards-offering/store/effects/nydus-network-partner-configurations.effects';
import { TravelConfigsEffects } from '../rewards-offering/store/effects/travel-configs.effects';
import { categorisedLoyaltyProgramsReducer } from '../rewards-offering/store/reducers/categorised-loyalty-programs.reducer';
import { configurationChangesReducer } from '../rewards-offering/store/reducers/configuration-changes.reducer';
import { nydusNetworkPartnerConfigurationsReducer } from '../rewards-offering/store/reducers/nydus-network-partner-configurations.reducer';
import {
  popularDestinationsReducer,
  travelConfigsReducer
} from '../rewards-offering/store/reducers/travel-configs.reducers';
import { RewardsProgramEffects } from '../rewards-program/store/effects/rewards-program.effects';
import { rewardsProgramReducer } from '../rewards-program/store/reducers/rewards-program.reducer';
import { RolesEffects } from '../roles/store/effects/roles.effects';
import { rolesReducer } from '../roles/store/reducers/roles.reducer';
import { SegmentMemberCountComponent } from '../segments/components/segment-member-count/segment-member-count.component';
import { SegmentCreationEffects } from '../segments/store/effects/segment-creation.effects';
import { SegmentMembersEffects } from '../segments/store/effects/segment-members.effects';
import { SegmentsEffects } from '../segments/store/effects/segments.effects';
import { segmentCreationReducer } from '../segments/store/reducers/segment-creation.reducer';
import { segmentMembersReducer } from '../segments/store/reducers/segment-members.reducer';
import { segmentsReducer } from '../segments/store/reducers/segments.reducer';
import { TenantCampaignsEffects } from '../tenant-campaigns/store/effects/tenant-campaigns.effects';
import { tenantCampaignsReducer } from '../tenant-campaigns/store/reducers/tenant-campaigns.reducer';
import { LocalesEffects } from '../tenant-locales/store/effects/locales.effects';
import { TenantLocalesEffects } from '../tenant-locales/store/effects/tenant-locales.effects';
import { localesReducer } from '../tenant-locales/store/reducers/locales.reducer';
import { tenantLocalesReducer } from '../tenant-locales/store/reducers/tenant-locales.reducer';
import { LoyaltyProgramCatalogsEffects } from '../tenants/store/effects/loyalty-program-catalogs.effects';
import { PasswordPoliciesEffects } from '../tenants/store/effects/password-policies.effects';
import { TenantsEffects } from '../tenants/store/effects/tenants.effects';
import { TranslationsEffects } from '../tenants/store/effects/translations.effects';
import { loyaltyProgramCatalogsReducer } from '../tenants/store/reducers/loyalty-program-catalogs.reducer';
import { passwordPoliciesReducer } from '../tenants/store/reducers/password-policies.reducer';
import { tenantsReducer } from '../tenants/store/reducers/tenants.reducer';
import { translationsReducer } from '../tenants/store/reducers/translations.reducer';
import { TooltipComponent } from '../tooltips/components/tooltip/tooltip.component';
import { TooltipsEffects } from '../tooltips/store/effects/tooltips.effects';
import { tooltipsReducer } from '../tooltips/store/reducers/tooltips.reducer';
import { IdentitiesEffects } from '../users/store/effects/identities.effects';
import { ImpersonationsEffects } from '../users/store/effects/impersonations.effects';
import { MfaIdentitiesEffects } from '../users/store/effects/mfa-identities.effects';
import { TransactionsEffects } from '../users/store/effects/transactions.effects';
import { UserOfferActivitiesEffects } from '../users/store/effects/user-offer-activities.effects';
import { UserPasswordsEffects } from '../users/store/effects/user-passwords.effects';
import { UserTokensEffects } from '../users/store/effects/user-tokens.effects';
import { UsersEffects } from '../users/store/effects/users.effects';
import { identitiesReducer } from '../users/store/reducers/identities.reducer';
import { mfaIdentitiesReducer } from '../users/store/reducers/mfa-identities.reducer';
import { transactionReducer } from '../users/store/reducers/transactions.reducer';
import { userOfferActivitiesReducer } from '../users/store/reducers/user-offer-activities.reducer';
import { userPasswordsReducer } from '../users/store/reducers/user-passwords.reducer';
import { userTokensReducer } from '../users/store/reducers/user-tokens.reducer';
import { usersReducer } from '../users/store/reducers/users.reducer';
import { WatchlistsEffects } from '../watchlists/store/effects/watchlists.effects';
import { watchlistsReducer } from '../watchlists/store/reducers/watchlists.reducer';
import { WebhookEventsWithPaginationEffects } from '../webhooks/store/effects/webhook-events-with-pagination.effects';
import { WebhookEventsEffects } from '../webhooks/store/effects/webhook-events.effects';
import { WebhookSettingsEffects } from '../webhooks/store/effects/webhook-settings.effects';
import { webhookEventsWithPaginationReducer } from '../webhooks/store/reducers/webhook-events-with-pagination.reducer';
import { webhookEventsReducer } from '../webhooks/store/reducers/webhook-events.reducer';
import { webhookSettingsReducer } from '../webhooks/store/reducers/webhook-settings.reducer';
import { TicketsEffects } from '../zendesk-tickets/store/effects/tickets.effects';
import { ticketsReducer } from '../zendesk-tickets/store/reducers/tickets.reducer';
import { ChangesDetailDialogComponent } from './components/changes-detail-dialog/changes-detail-dialog.component';
import { ClearFormButtonComponent } from './components/clear-form-button/clear-form-button.component';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { ConfigPreviewComponent } from './components/config-preview/config-preview.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CopyCurrentUrlComponent } from './components/copy-current-url/copy-current-url.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { DetailsDialogComponent } from './components/details-dialog/details-dialog.component';
import { DrawerContainerComponent } from './components/drawer-container/drawer-container.component';
import { DrawerContentBodyComponent } from './components/drawer-content-body/drawer-content-body.component';
import { DrawerContentContainerComponent } from './components/drawer-content-container/drawer-content-container.component';
import { DrawerContentHeaderComponent } from './components/drawer-content-header/drawer-content-header.component';
import { DrawerSelectComponent } from './components/drawer-select/drawer-select.component';
import { EmptyStatePageComponent } from './components/empty-state/empty-state-page.component';
import { EmptyStateTableComponent } from './components/empty-state/empty-state-table.component';
import { FontSelectorInputComponent } from './components/font-selector-input/font-selector-input.component';
import { FullPageOverlayComponent } from './components/full-page-overlay/full-page-overlay.component';
import { InputRangeSliderComponent } from './components/input-range-slider/input-range-slider.component';
import { InputResetComponent } from './components/input-reset/input-reset.component';
import { InputTagsV2Component } from './components/input-tags-v2/input-tags-v2.component';
import { InputTagsComponent } from './components/input-tags/input-tags.component';
import { ItemComponent } from './components/item/item.component';
import { KafkaWidgetComponent } from './components/kafka-widget/kafka-widget.component';
import { LocaleSwitcherV2Component } from './components/locale-switcher-v2/locale-switcher-v2.component';
import { LocaleSwitcherV3Component } from './components/locale-switcher-v3/locale-switcher-v3.component';
import { LocaleSwitcherComponent } from './components/locale-switcher/locale-switcher.component';
import { LogicRuleAttributeOptionsDrawerComponent } from './components/logic-rule-attribute-options-drawer/logic-rule-attribute-options-drawer.component';
import { LogicRuleDateTimeInputFieldComponent } from './components/logic-rule-datetime-input-field/logic-rule-datetime-input-field.component';
import { LogicRuleComponent } from './components/logic-rule/logic-rule.component';
import { LogicRulesFormComponent } from './components/logic-rules-form/logic-rules-form.component';
import { LogicRulesSummaryComponent } from './components/logic-rules-summary/logic-rules-summary.component';
import { MenuV2Component } from './components/menu-v2/menu-v2.component';
import { MenuComponent } from './components/menu/menu-component';
import { MultiSelectInputComponent } from './components/multi-select-input/multi-select-input.component';
import { PointsAccountsSelectV2Component } from './components/points-accounts-select-v2/points-accounts-select-v2.component';
import { PointsAccountsSelectComponent } from './components/points-accounts-select/points-accounts-select.component';
import { PointsSummaryV2Component } from './components/points-summary-v2/points-summary-v2.component';
import { PointsSummaryComponent } from './components/points-summary/points-summary.component';
import { PreviewDialogComponent } from './components/preview/preview-dialog.component';
import { PreviewComponent } from './components/preview/preview.component';
import { RadioDescriptionGroupComponent } from './components/radio-description-group/radio-description-group.component';
import { RadioPillInputComponent } from './components/radio-pill-input/radio-pill-input.component';
import { RadioTileGroupComponent } from './components/radio-tile-group/radio-tile-group.component';
import { RecommendationReviewCardComponent } from './components/recommendation-review-card/recommendation-review-card.component';
import { RecommendationReviewComponent } from './components/recommendation-review/recommendation-review.component';
import { RecommendationSummaryListComponent } from './components/recommendation-summary-list/recommendation-summary-list.component';
import { RecommendationSummaryComponent } from './components/recommendation-summary/recommendation-summary.component';
import { RecommendationsDrawerComponent } from './components/recommendations-drawer/recommendations-drawer.component';
import { RecommendationsSideSheetComponent } from './components/recommendations-side-sheet/recommendations-side-sheet.component';
import { SegmentCreateReviewComponent } from './components/segment-create-review/segment-create-review.component';
import { SideDropdownMenuComponent } from './components/side-dropdown-menu';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { TableOfContentsComponent } from './components/table-of-contents/table-of-contents.component';
import { ColumnComponent } from './components/table/column.component';
import { TableComponent } from './components/table/table.component';
import { TagDialogV2Component } from './components/tag-dialog-v2/tag-dialog-v2.component';
import { TagDialogComponent } from './components/tag-dialog/tag-dialog.component';
import { ToggleAdvancedFiltersButtonComponent } from './components/toggle-advanced-filters-button/toggle-advanced-filters-button.component';
import { UploadFileDialogComponent } from './components/upload-file-dialog/upload-file-dialog.component';
import { ImageUploadInputComponent } from './components/upload-images/image-upload-input.component';
import { UploadImagesEffects } from './components/upload-images/store/effects/upload-images.effects';
import { uploadImagesReducer } from './components/upload-images/store/reducers/upload-images.reducer';
import {
  notifyIntentToUpgradeFailure,
  notifyIntentToUpgradeSuccess
} from './components/upsell-dialog/store/actions/upsell-dialog.actions';
import { UpsellDialogEffects } from './components/upsell-dialog/store/effects/upsell-dialog.effects';
import { UpsellDialogComponent } from './components/upsell-dialog/upsell-dialog.component';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { SpinnerComponent } from './containers/spinner/spinner.component';
import { DragAngDropFileUploadDirective } from './directives/drag-and-drop-file-upload.directive';
import { DrawerSelectOptionDirective } from './directives/drawer-select-option.directive';
import { FixedDecimalsDirective } from './directives/fixed-decimals.directive';
import { FormWithTextAreaDirective } from './directives/form-with-text-area.directive';
import { HandleErrorFieldsDirective } from './directives/handle-error-fields.directive';
import { HandleExternalRouteDirective } from './directives/handle-external-route.directive';
import { IfScopesIncludeDirective } from './directives/if-scopes-include.directive';
import { IntegerInputDirective } from './directives/integer-input.directive';
import { LogicRuleEmptyRuleDirective } from './directives/logic-rule-empty-rule.directive';
import { MustBeAfterStartDateValidatorDirective } from './directives/must-be-after-start-date-validator.directive';
import { MustDefineTogetherValidatorDirective } from './directives/must-define-together-validator.directive';
import { PaginatorDirective } from './directives/paginator.directive';
import { PristineFormDisabledDirective } from './directives/pristine-form-disabled.directive';
import { ScopesEnabledDirective } from './directives/scopes-enabled.directive';
import { SyncHostControlErrorsDirective } from './directives/sync-host-control-errors.directive';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {
  ArrayJoinPipe,
  CurrencyFormatPipe,
  CustomKeyValuePipe,
  CustomSentenceCasePipe,
  CustomTitleCasePipe,
  DateFormatPipe,
  DayjsDateFormatPipe,
  DeviceInfoPipe,
  FetchTagsCustomerIdsPipe,
  FormTransformPipe,
  LogicRuleCategoryKeyPipe,
  LogicRuleSummaryPipe,
  MetadataBeautifyPipe,
  PeriodPipe,
  RemoveNullishPipe,
  RenderAttributePipe,
  ReplacePipe,
  SafeHtmlPipe,
  TimeFromNowPipe,
  TimezoneDisplayPipe,
  UrlToLinkPipe,
  UserAgentInfoPipe,
  UserDetailTooltipKeysPipe,
  UserNamePipe,
  UtcTimePipe,
  ValidNumberPipe
} from './pipes';
import { FeatureAvailabilityCheckPipe } from './pipes/feature-availability-check.pipe';
import { I18nPipe } from './pipes/i18n.pipe';
import { RoundedNumberPipe } from './pipes/rounded-number.pipe';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const declarations = [
  /* Component */
  ChangesDetailDialogComponent,
  ClearFormButtonComponent,
  ColumnComponent,
  ColorInputComponent,
  ConfigPreviewComponent,
  ConfirmDialogComponent,
  CopyCurrentUrlComponent,
  CopyToClipboardComponent,
  DateTimeInputComponent,
  DetailsDialogComponent,
  DrawerContainerComponent,
  DrawerSelectComponent,
  EmptyStateTableComponent,
  FontSelectorInputComponent,
  FooterComponent,
  FullPageOverlayComponent,
  ToggleAdvancedFiltersButtonComponent,
  ImageUploadInputComponent,
  InputChipListComponent,
  InputRangeSliderComponent,
  InputResetComponent,
  InputTagsComponent,
  InputTagsV2Component,
  ItemComponent,
  KafkaWidgetComponent,
  LocaleSwitcherComponent,
  LocaleSwitcherV2Component,
  LocaleSwitcherV3Component,
  LogicRuleComponent,
  LogicRuleAttributeOptionsDrawerComponent,
  LogicRuleDateTimeInputFieldComponent,
  LogicRulesFormComponent,
  LogicRulesSummaryComponent,
  MenuComponent,
  MenuV2Component,
  MultiSelectInputComponent,
  PageNotFoundComponent,
  PointsAccountsSelectComponent,
  PointsAccountsSelectV2Component,
  PointsSummaryComponent,
  PointsSummaryV2Component,
  PreviewDialogComponent,
  PreviewComponent,
  RadioDescriptionGroupComponent,
  RadioPillInputComponent,
  RadioTileGroupComponent,
  RecommendationReviewCardComponent,
  RecommendationReviewComponent,
  RecommendationsDrawerComponent,
  RecommendationsSideSheetComponent,
  RecommendationSummaryListComponent,
  RecommendationSummaryComponent,
  SegmentCreateReviewComponent,
  SideDropdownMenuComponent,
  SlideToggleComponent,
  SnackBarComponent,
  SpinnerComponent,
  TableComponent,
  TableOfContentsComponent,
  TooltipComponent,
  VerifiedIndicatorComponent,
  WelcomePageComponent,
  YearPickerComponent,
  /* Directive */
  AmplitudeClickEventDirective,
  DragAngDropFileUploadDirective,
  DrawerSelectOptionDirective,
  FixedDecimalsDirective,
  FormWithTextAreaDirective,
  HandleErrorFieldsDirective,
  HandleExternalRouteDirective,
  IfScopesIncludeDirective,
  ScopesEnabledDirective,
  LogicRuleEmptyRuleDirective,
  MustBeAfterStartDateValidatorDirective,
  MustDefineTogetherValidatorDirective,
  PaginatorDirective,
  IntegerInputDirective,
  PristineFormDisabledDirective,
  SyncHostControlErrorsDirective,
  ScopesEnabledDirective,
  /* Pipe */
  ArrayJoinPipe,
  CurrencyFormatPipe,
  CustomKeyValuePipe,
  CustomSentenceCasePipe,
  CustomTitleCasePipe,
  DateFormatPipe,
  DayjsDateFormatPipe,
  DeviceInfoPipe,
  FeatureAvailabilityCheckPipe,
  FetchTagsCustomerIdsPipe,
  FormTransformPipe,
  I18nPipe,
  LogicRuleCategoryKeyPipe,
  LogicRuleSummaryPipe,
  MetadataBeautifyPipe,
  PeriodPipe,
  RemoveNullishPipe,
  RenderAttributePipe,
  ReplacePipe,
  RoundedNumberPipe,
  TimeFromNowPipe,
  TimezoneDisplayPipe,
  UserAgentInfoPipe,
  UserDetailTooltipKeysPipe,
  UserNamePipe,
  UtcTimePipe,
  UrlToLinkPipe,
  ValidNumberPipe
];

const standaloneComponents = [
  EmptyStatePageComponent,
  UpsellDialogComponent,
  DrawerContentContainerComponent,
  DrawerContentHeaderComponent,
  DrawerContentBodyComponent
];

const standalonePipes = [SafeHtmlPipe];

@NgModule({
  declarations: [
    ...declarations,
    UploadFileDialogComponent,
    TagDialogComponent,
    TagDialogV2Component,
    RecommendationSummaryListComponent
  ],
  imports: [
    ...standaloneComponents,
    ...standalonePipes,
    SegmentMemberCountComponent,
    ColorChromeModule,
    CommonModule,
    RouterModule,
    ClipboardModule,
    FeatureEnabledDirective,
    FeatureDisabledDirective,
    FlexLayoutModule.withConfig({
      addFlexToParent: false
    }),
    MaterialModule,
    ReactiveFormsModule,
    // not in exports
    NgxSliderModule,
    // setup whole redux store to be ready at once when component is loaded, otherwise, components that
    // depend on other module's store will fail in lazy-loaded modules.
    StoreModule.forFeature('ability', abilitiesReducer),
    StoreModule.forFeature('alfredTenant', alfredTenantsReducer),
    StoreModule.forFeature('alfredSupplier', alfredSuppliersReducer),
    StoreModule.forFeature('accessPolicy', accessPoliciesReducer),
    StoreModule.forFeature('allowedOrigin', allowedOriginsReducer),
    StoreModule.forFeature('apiSettings', apiSettingsReducer),
    StoreModule.forFeature('apiSettingsToken', apiSettingsTokenReducer),
    StoreModule.forFeature('app', appsReducer),
    StoreModule.forFeature('appConnection', appConnectionsReducer),
    StoreModule.forFeature('approvalRequest', approvalRequestsReducer),
    StoreModule.forFeature('analytic', analyticsReducer),
    StoreModule.forFeature('auditLog', auditLogsReducer),
    StoreModule.forFeature('authenticationProvider', authenticationProvidersReducer),
    StoreModule.forFeature('autoRedemption', autoRedemptionsReducer),
    StoreModule.forFeature('benefit', benefitsReducer),
    StoreModule.forFeature('bootstrap', bootstrapsReducer),
    StoreModule.forFeature('campaignProposal', campaignProposalsReducer),
    StoreModule.forFeature('campaign', campaignsReducer),
    StoreModule.forFeature('categorisedLoyaltyProgram', categorisedLoyaltyProgramsReducer),
    StoreModule.forFeature('cerberusConfig', cerberusConfigsReducer),
    StoreModule.forFeature('configurationChange', configurationChangesReducer),
    StoreModule.forFeature('connectionCredentials', connectionCredentialsReducer),
    StoreModule.forFeature('core', coreReducers),
    StoreModule.forFeature('currencyConversion', currencyConversionReducer),
    StoreModule.forFeature('customDomain', customDomainReducer),
    StoreModule.forFeature('dashboard', dashboardReducers),
    StoreModule.forFeature('domain', domainsReducer),
    StoreModule.forFeature('earnRule', earnRulesReducer),
    StoreModule.forFeature('enrollment', enrollmentsReducer),
    StoreModule.forFeature('event', eventsReducer),
    StoreModule.forFeature('eventEarnRule', eventEarnRulesReducer),
    StoreModule.forFeature('eventType', eventTypesReducer),
    StoreModule.forFeature('fileProcessingRequest', fileProcessingRequestsReducer),
    StoreModule.forFeature('fraudEvaluation', fraudEvaluationsReducer),
    StoreModule.forFeature('fraudList', fraudListsReducer),
    StoreModule.forFeature('fraudRule', fraudRulesReducer),
    StoreModule.forFeature('fraudRuleSchema', fraudRuleSchemasReducer),
    StoreModule.forFeature('ghCache', ghCacheReducer),
    StoreModule.forFeature('giftCard', giftCardsReducer),
    StoreModule.forFeature('giftCardOrder', giftCardOrdersReducer),
    StoreModule.forFeature('identity', identitiesReducer),
    StoreModule.forFeature('key', keysReducer),
    StoreModule.forFeature('locale', localesReducer),
    StoreModule.forFeature('loginAttempt', loginAttemptsReducer),
    StoreModule.forFeature('loyaltyProgram', loyaltyProgramsReducer),
    StoreModule.forFeature('loyaltyProgramCatalog', loyaltyProgramCatalogsReducer),
    StoreModule.forFeature('membership', membershipsReducer),
    StoreModule.forFeature('merchandise', merchandisesReducer),
    StoreModule.forFeature('merchandiseCategory', merchandiseCategoriesReducer),
    StoreModule.forFeature('merchantPortalBootstrap', merchantPortalBootstrapReducer),
    StoreModule.forFeature('mergeConfig', mergeConfigsReducer),
    StoreModule.forFeature('mcTenant', mcTenantsReducer),
    StoreModule.forFeature('mcTranslation', mcTranslationsReducer),
    StoreModule.forFeature('mfaIdentity', mfaIdentitiesReducer),
    StoreModule.forFeature('note', notesReducer),
    StoreModule.forFeature('notification', notificationsReducer),
    StoreModule.forFeature('nydusNetworkPartnerConfiguration', nydusNetworkPartnerConfigurationsReducer),
    StoreModule.forFeature('offer', offersReducer),
    StoreModule.forFeature('offerSource', offerSourcesReducer),
    StoreModule.forFeature('orderAttempt', orderAttemptsReducer),
    StoreModule.forFeature('orderItem', orderItemsReducer),
    StoreModule.forFeature('orderWebhook', orderWebhooksReducer),
    StoreModule.forFeature('partnerConfig', partnerConfigsReducer),
    StoreModule.forFeature('passwordPolicy', passwordPoliciesReducer),
    StoreModule.forFeature('pickupZone', pickupZonesReducer),
    StoreModule.forFeature('pointsAccount', pointsAccountsReducer),
    StoreModule.forFeature('popularDestination', popularDestinationsReducer),
    StoreModule.forFeature('product', productsReducer),
    StoreModule.forFeature('promoCode', promoCodesReducer),
    StoreModule.forFeature('question', questionsReducer),
    StoreModule.forFeature('recommendation', recommendationReducers),
    StoreModule.forFeature('redemption', redemptionsReducer),
    StoreModule.forFeature('redemptionRate', redemptionRatesReducer),
    StoreModule.forFeature('remoteFile', remoteFilesReducer),
    StoreModule.forFeature('rewardPreference', rewardPreferencesReducer),
    StoreModule.forFeature('rewardTrigger', rewardTriggersReducer),
    StoreModule.forFeature('rewardsProgram', rewardsProgramReducer),
    StoreModule.forFeature('role', rolesReducer),
    StoreModule.forFeature('segment', segmentsReducer),
    StoreModule.forFeature('segmentMember', segmentMembersReducer),
    StoreModule.forFeature('segmentCreation', segmentCreationReducer),
    StoreModule.forFeature('sentinelConfig', sentinelConfigsReducer),
    StoreModule.forFeature('tenant', tenantsReducer),
    StoreModule.forFeature('tenantCampaign', tenantCampaignsReducer),
    StoreModule.forFeature('tenantLocale', tenantLocalesReducer),
    StoreModule.forFeature('ticket', ticketsReducer),
    StoreModule.forFeature('tooltip', tooltipsReducer),
    StoreModule.forFeature('translation', translationsReducer),
    StoreModule.forFeature('transaction', transactionReducer),
    StoreModule.forFeature('travelConfig', travelConfigsReducer),
    StoreModule.forFeature('uploadImage', uploadImagesReducer),
    StoreModule.forFeature('user', usersReducer),
    StoreModule.forFeature('userPassword', userPasswordsReducer),
    StoreModule.forFeature('userToken', userTokensReducer),
    StoreModule.forFeature('userOfferActivity', userOfferActivitiesReducer),
    StoreModule.forFeature('watchlist', watchlistsReducer),
    StoreModule.forFeature('webhookSetting', webhookSettingsReducer),
    StoreModule.forFeature('webhookEvent', webhookEventsReducer),
    StoreModule.forFeature('webhookEventWithPagination', webhookEventsWithPaginationReducer),
    EffectsModule.forFeature([
      AbilitiesEffects,
      AccessPoliciesEffects,
      AlfredTenantsEffects,
      AlfredSuppliersEffects,
      AllowedOriginsEffects,
      AnalyticsEffects,
      ApiSettingsEffects,
      ApiSettingsTokenEffects,
      AppConnectionsEffects,
      AppsEffects,
      ApprovalRequestsEffects,
      AuditLogsEffects,
      AuthEffects,
      AuthenticationProvidersEffects,
      AutoRedemptionsEffects,
      BenefitsEffects,
      BootstrapsEffects,
      CampaignProposalsEffects,
      CampaignsEffects,
      CategorisedLoyaltyProgramsEffects,
      CerberusConfigsEffects,
      ConfigurationChangesEffects,
      ConnectionCredentialsEffects,
      CurrencyConversionEffects,
      CustomDomainEffects,
      DomainsEffects,
      EarnRulesEffects,
      EnrollmentsEffects,
      ErrorEffects,
      EventsEffects,
      EventEarnRulesEffects,
      EventTypesEffects,
      FileProcessingRequestsEffects,
      FraudEvaluationsEffects,
      FraudListsEffects,
      FraudRulesEffects,
      FraudRuleSchemasEffects,
      GhCacheEffects,
      GiftCardsEffects,
      GiftCardsConfigEffects,
      GiftCardOrdersEffects,
      IdentitiesEffects,
      ImpersonationsEffects,
      KeysEffects,
      LocalesEffects,
      LoginAttemptsEffects,
      LoyaltyDataPointsAccountEffects,
      LoyaltyProgramsEffects,
      LoyaltyProgramCatalogsEffects,
      McTenantsEffects,
      McTranslationsEffects,
      MembershipsEffects,
      MerchandisesEffects,
      MerchandiseCategoriesEffects,
      MergeConfigsEffects,
      MfaIdentitiesEffects,
      NoopEffects,
      NotesEffects,
      NotificationsEffects,
      NydusNetworkBootstrapEffects,
      NydusNetworkPartnerConfigurationsEffects,
      OfferSourcesEffects,
      OffersEffects,
      OrderAttemptsEffects,
      OrderItemsEffects,
      OrderWebhooksEffects,
      PartnerConfigsEffects,
      PasswordPoliciesEffects,
      PickupZonesEffects,
      PointsAccountsEffects,
      PointsActivitiesEffects,
      PointsAdjustmentsEffects,
      PointsStatementsEffects,
      ProductsEffects,
      PromoCodesEffects,
      QuestionsEffects,
      RecommendationsEffects,
      RedemptionsEffects,
      RedemptionRatesEffects,
      RemoteFilesEffects,
      RewardPreferencesEffects,
      RewardTriggersEffects,
      RewardsProgramEffects,
      RolesEffects,
      RouterEffects,
      SegmentMembersEffects,
      SegmentsEffects,
      SegmentCreationEffects,
      SentinelConfigsEffects,
      SharedConfigEffects,
      SuccessEffects,
      TenantBootstrapEffects,
      TenantPaymentTierEffects,
      TenantsEffects,
      TenantCampaignsEffects,
      TenantLocalesEffects,
      TicketsEffects,
      TooltipsEffects,
      TransactionsEffects,
      TranslationsEffects,
      TravelConfigsEffects,
      UploadImagesEffects,
      UpsellDialogEffects,
      UserAbilityEffects,
      UsersEffects,
      UserOfferActivitiesEffects,
      UserPasswordsEffects,
      UserTokensEffects,
      WatchlistsEffects,
      WebhookSettingsEffects,
      WebhookEventsEffects,
      WebhookEventsWithPaginationEffects
    ])
  ],
  exports: [
    CommonModule,
    RouterModule,
    ClipboardModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    // not in imports
    FormsModule,
    NgxJsonViewerModule,
    GenesisModule,
    MatStepperModule,
    NgxMatSelectSearchModule,
    DragDropModule,
    NgxSliderModule,
    ...declarations,
    ...standaloneComponents,
    ...standalonePipes,
    FeatureEnabledDirective,
    FeatureDisabledDirective
  ],
  providers: [CustomSentenceCasePipe, CustomTitleCasePipe, DecimalPipe, RoundedNumberPipe, TitleCasePipe]
})
export class SharedModule {
  constructor(
    private errorActionTypes: ErrorActionTypes,
    private successActionTypes: SuccessActionTypes
  ) {
    this.errorActionTypes.registerActions([
      notifyIntentToUpgradeFailure.type,
      hideRecommendationFailure.type,
      unhideRecommendationFailure.type
    ]);

    this.successActionTypes.registerActions([notifyIntentToUpgradeSuccess.type, unhideRecommendationSuccess.type]);
  }
}
