import { createAction, props } from '@ngrx/store';

import { Change, ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { PartnerConfig, ToggleableProduct, UiSettings, UpdateWebsiteStylingPayload } from '../../types';

export const loadPartnerConfig = createAction('[Partner Configs] Load Partner Config');
export const loadPartnerConfigSuccess = createAction(
  '[Partner Configs] Load Partner Config Success',
  props<{ partnerConfig: PartnerConfig }>()
);
export const loadPartnerConfigFailure = createAction(
  '[Partner Configs] Load Partner Config Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not load the website styling settings due to technical issues on our end. Please try again. ' +
        'If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateWebsiteStyling = createAction(
  '[Partner Configs] Update Website Styling',
  props<{ payload: UpdateWebsiteStylingPayload; redirectPath?: string }>()
);
export const updateWebsiteStylingSuccess = createAction(
  '[Partner Configs] Update Website Styling Success',
  props<{ redirectPath?: string }>()
);
export const updateWebsiteStylingFailure = createAction(
  '[Partner Configs] Update Website Styling Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not update the website styling settings due to technical issues on our end. Please try again. ' +
        'If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const activateProduct = createAction(
  '[Partner Configs] Toggle Product',
  props<{ product: ToggleableProduct; version: number; value: boolean }>()
);
export const activateProductSuccess = createAction(
  '[Partner Configs] Toggle Product Success',
  (
    version: number,
    product: ToggleableProduct,
    snackbarMessages: SnackbarMessages = {
      default: 'Your request is processing.'
    }
  ) => ({ version, product, snackbarMessages })
);
export const activateProductFailure = createAction(
  '[Partner Configs] Toggle Product Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not update the product config to technical issues on our end. Please try again. ' +
        'If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateUiSettings = createAction(
  '[Partner Configs] Update Ui Setting',
  props<{ uiSettings: UiSettings; changes: Change[] }>()
);
export const updateUiSettingsSuccess = createAction('[Partner Configs] Update Ui Setting Success');
export const updateUiSettingsFailure = createAction('[Partner Configs] Update Ui Setting Failure', props<ErrorState>());
