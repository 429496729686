import { catchError, map } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RefreshTokenExchangeResult } from '@kaligo/hermes';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CoreState } from '@core/store';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { uuidv4 } from '@utils';

import { TenantLocale } from '../../../tenant-locales/types';
import { MissionControlErrorHandlerService } from '../mission-control-error-handler/mission-control-error-handler.service';

type HeaderType = { [key: string]: string | string[] };

@Injectable({
  providedIn: 'root'
})
export class MissionControlService {
  refreshTokenExchangeResult: RefreshTokenExchangeResult;

  constructor(
    private http: HttpClient,
    private store: Store<CoreState>,
    private errorHandler: MissionControlErrorHandlerService,
    @Inject('missionControlApiBaseUrl') private missionControlApiBaseUrl: string
  ) {
    this.store.select(authQuery.getTokenResult).subscribe((refreshTokenExchangeResult: RefreshTokenExchangeResult) => {
      this.refreshTokenExchangeResult = refreshTokenExchangeResult;
    });
  }

  get<Response>(
    urlSuffix: string,
    params: HttpParams = new HttpParams(),
    header: { [key: string]: string | string[] } = {},
    responseType: string = 'json'
  ): Observable<Response> {
    const url = this.buildUrl(urlSuffix);

    return this.http
      .get(url, {
        headers: this.buildHeaders(header),
        responseType: responseType as 'json',
        params
      })
      .pipe(
        map(res => res),
        catchError(error => this.errorHandler.handleError(url, error))
      );
  }

  post<Response>(
    urlSuffix: string,
    body?: any,
    params: HttpParams = new HttpParams(),
    header: { [key: string]: string | string[] } = {}
  ): Observable<Response> {
    const url = this.buildUrl(urlSuffix);

    return this.http
      .post<Response>(url, body, {
        headers: this.buildHeaders(header),
        observe: 'body',
        params
      })
      .pipe(
        map(res => res),
        catchError(error => this.errorHandler.handleError(url, error))
      );
  }

  patch<Response>(
    urlSuffix: string,
    body?: any,
    params: HttpParams = new HttpParams(),
    header: { [key: string]: string | string[] } = {}
  ): Observable<Response> {
    const url = this.buildUrl(urlSuffix);

    return this.http
      .patch<Response>(url, body, {
        headers: this.buildHeaders(header),
        observe: 'body',
        params
      })
      .pipe(
        map(res => res),
        catchError(error => this.errorHandler.handleError(url, error))
      );
  }

  put<Response>(
    urlSuffix: string,
    body?: any,
    params: HttpParams = new HttpParams(),
    header: { [key: string]: string | string[] } = {}
  ): Observable<Response> {
    const url = this.buildUrl(urlSuffix);

    return this.http
      .put<Response>(url, body, {
        headers: this.buildHeaders(header),
        observe: 'body',
        params
      })
      .pipe(catchError(error => this.errorHandler.handleError(url, error)));
  }

  delete<Response>(
    urlSuffix: string,
    params: HttpParams = new HttpParams(),
    header: { [key: string]: string | string[] } = {}
  ): Observable<Response> {
    const url = this.buildUrl(urlSuffix);

    return this.http
      .delete<Response>(url, {
        headers: this.buildHeaders(header),
        params
      })
      .pipe(
        map(res => res),
        catchError(error => this.errorHandler.handleError(url, error))
      );
  }

  // keep X-Force-Locale for backward compatibility. NN prioritize X-Accept-Language over X-Force-Locale
  buildLocaleHeader(
    locale: string,
    tenantLocales?: TenantLocale[]
  ): { 'X-Accept-Language': string; 'X-Force-Locale': string } | null {
    if (tenantLocales && tenantLocales.length > 0) {
      const defaultLocale = tenantLocales.find(tenantLocale => tenantLocale.defaultForTenant)!.locale;

      // order: selected locale, tenant default locale, Ascenda default locale ('en-US)
      const locales = [locale, defaultLocale, 'en-US'].filter(Boolean);

      return {
        'X-Accept-Language': locales.join(','),
        'X-Force-Locale': locales[0]
      };
    } else if (locale) {
      return {
        'X-Accept-Language': locale,
        'X-Force-Locale': locale
      };
    } else {
      return null;
    }
  }

  private buildUrl(urlSuffix: string): string {
    return `${this.missionControlApiBaseUrl}/${urlSuffix}`;
  }

  private buildHeaders(header: HeaderType): HeaderType {
    const hermesAccessToken = this.refreshTokenExchangeResult
      ? { 'X-Access-Token': this.refreshTokenExchangeResult.accessToken }
      : undefined;

    return {
      'X-Request-Id': uuidv4(),
      ...hermesAccessToken,
      ...header
    };
  }
}
