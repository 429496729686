import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, RewardPreferenceState } from '../../types/reward-preferences.type';
import {
  loadRewardPreference,
  loadRewardPreferenceFailure,
  loadRewardPreferenceSuccess
} from '../actions/reward-preferences.actions';

export const rewardPreferenceTriggers: EntityTriggers = {
  single: {
    loading: [loadRewardPreference.type],
    resting: [loadRewardPreferenceSuccess.type],
    erroring: [loadRewardPreferenceFailure.type]
  }
};

export function rewardPreferencesReducer(
  state: RewardPreferenceState = initialState,
  action: Action
): RewardPreferenceState {
  return callStateReducer(baseReducer, rewardPreferenceTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadRewardPreferenceSuccess, (state, { rewardPreference }) => ({ ...state, rewardPreference })),
  on(loadRewardPreferenceFailure, state => ({ ...state, rewardPreference: null }))
);
