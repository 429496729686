import { SortDirection } from '@angular/material/sort';

export class LoyaltyProgramsFilter {
  name: string;
  loyaltyId: string;
  shortName: string;
  category: string;
  loyaltyCurrencyName: string;
  active: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortDirection?: SortDirection;

  constructor() {
    this.name = '';
    this.loyaltyId = '';
    this.shortName = '';
    this.category = '';
    this.loyaltyCurrencyName = '';
    this.active = '';
    this.page = 1;
    this.limit = 20;
    this.sortBy = '';
    this.sortDirection = '';
  }
}
