import { DayjsUtils } from '@utils';

import { Formatters } from './formatters';

export type NydusNetworkQueryOperator = '$eq';
export type NydusNetworkParamsConfig = {
  filter?: {
    key: string;
    operator?: NydusNetworkQueryOperator;
    type?: 'date' | 'date-range';
  }[];
  search?: string[];
};

export const NydusNetworkParamConverter = {
  convertToNydusNetworkParam(
    rawFilter: { [key: string]: any },
    paramsConfig: NydusNetworkParamsConfig,
    timezoneOffset?: number
  ): object {
    const filter = { ...rawFilter };
    const params = {} as any;
    const dateColumns = ['startAt', 'endAt'];

    // pagination
    if (typeof filter.page == 'object') {
      params.page = {
        size: filter.page.size,
        nextCursor: filter.page.nextCursor,
        prevCursor: filter.page.prevCursor
      };
    } else if (typeof filter.page == 'number') {
      params.page = {
        size: filter.limit,
        number: filter.page
      };
    }

    // sorting
    if (filter.sortBy) {
      const isDateColumnSort = dateColumns.includes(filter.sortBy);
      params.sort = Formatters.fromCamelToSnakeCase(filter.sortBy);

      if (filter.sortDirection === 'desc') {
        params.sort = isDateColumnSort ? params.sort + '-' : '-' + params.sort;
      }
    }

    // search
    paramsConfig.search?.forEach(key => {
      params.search = { ...params.search, [Formatters.fromCamelToSnakeCase(key)]: filter[key] };
    });

    // filter
    paramsConfig.filter?.forEach(({ key, operator, type }) => {
      let attribute;
      let dateFromFilter;
      let dateToFilter;

      if (type) {
        if (type === 'date-range') {
          dateFromFilter = filter[key]?.dateFrom;
          dateToFilter = filter[key]?.dateTo;
        } else if (type === 'date') {
          dateFromFilter = filter[key];
          dateToFilter = filter[key];
        }

        attribute = {
          [Formatters.fromCamelToSnakeCase(key)]: {
            $gte: dateFromFilter && DayjsUtils.getStartOfDay(dateFromFilter, timezoneOffset),
            $lte: dateToFilter && DayjsUtils.getEndOfDay(dateToFilter, timezoneOffset)
          }
        };
      } else {
        attribute = {
          [Formatters.fromCamelToSnakeCase(key)]: { [operator!]: filter[key] }
        };
      }

      params.filter = { ...params.filter, ...attribute };
    });

    return params;
  }
};
