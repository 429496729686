import { Action, ActionReducer } from '@ngrx/store';

import { CallState, CallStateUnion, EntityTriggers, FailureAction, Triggers } from '@core/types';

// Returns a reducer which manipulates the callState according to the EntityTriggers definition
// Example:
//  If the reducer receives an action which is defined as a "batch:loading" action,
//  it will change the callState.batch value to 'loading'.
//
//  someFeatureReducer = callStateReducer(someFeatureBaseReducer, entityTriggers)
//  someFeatureReducer(state, action) // manipulates callState according to entityTriggers
export function callStateReducer<S extends CallState, A extends Action>(
  baseReducer: ActionReducer<S, A>,
  entityTriggers: EntityTriggers
): ActionReducer<S, A> {
  return (state: S, action: A) => {
    const singleCallState = extractCallState(entityTriggers.single, action);
    const batchCallState = extractCallState(entityTriggers.batch, action);

    let newState: S = state;

    if (singleCallState || batchCallState) {
      newState = {
        ...state,
        callState: {
          single: singleCallState || state.callState.single,
          batch: batchCallState || state.callState.batch
        }
      };
    }

    return baseReducer(newState, action);
  };
}

function extractCallState(triggers: Triggers, action: Action): CallStateUnion | null {
  if (!triggers) {
    return null;
  }

  if (triggers.loading.includes(action.type)) {
    return 'loading';
  }
  if (triggers.resting.includes(action.type)) {
    return 'resting';
  }
  if (triggers.erroring.includes(action.type)) {
    return {
      error: (action as FailureAction).error
    };
  }

  return null;
}
