import { formatDate } from '@angular/common';

import { Nullable } from '@shared/types';

import { END_OF_DAY_MILLISECONDS } from './constants';
import { Formatters } from './formatters';

export const DateUtils = {
  formatAsUTC(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd HH:mm:ss +0000', 'en');
  },

  formatAsTimezone(date: string | number | Date, timezone: string): Nullable<string> {
    if (!date) {
      return null;
    }

    return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'en', timezone);
  },

  stripTimeFromDate(date: Date | string): Nullable<Date> {
    if (!date) {
      return null;
    }

    date = date instanceof Date ? date : new Date(date);
    return new Date(formatDate(date.toISOString(), 'MM/dd/yyyy', 'en'));
  },

  // return format: +0800
  getBrowserTimezone(): Nullable<string> {
    const data = new Date().toString().match(/([-+][0-9]+)\s/);
    return data ? data[1] : null;
  },

  getTenantStartOfDay(date: string, timezone: string): Nullable<string> {
    const data = DateUtils.datepickerAdaptor('DatepickerToUTC', date, timezone);
    return data ? data.toISOString() : null;
  },

  getTenantEndOfDay(date: string, timezone: string): Nullable<string> {
    if (!date) {
      return null;
    }

    const convertedDate = DateUtils.datepickerAdaptor('DatepickerToUTC', date, timezone);

    return convertedDate ? new Date(convertedDate.getTime() + END_OF_DAY_MILLISECONDS).toISOString() : null;
  },

  // Mat Datepicker will transform UTC time string to local time string automatically
  // this adaptor is to
  // 1. transform and display UTC time (from DB) on Mat Datepicker with tenant timezone offset
  // 2. transform Mat Datepicker datetime to UTC time (with tenant timezone offset)
  datepickerAdaptor(direction: 'DatepickerToUTC' | 'UTCToDatepicker', date: string, timezone?: string): Nullable<Date> {
    if (!date) {
      return null;
    }

    // calculate offset for tenant timezone (timezone format: '+0800')
    let offsetForTenantTimezone = 0;
    if (timezone?.length === 5) {
      const symbol = timezone.slice(0, 1);
      const timezoneHour = Number(timezone.slice(1, 3));
      const timezoneMin = Number(timezone.slice(3, 5));

      if ('+-'.includes(symbol)) {
        offsetForTenantTimezone = Number(symbol + (timezoneHour * 60 + timezoneMin));
      }
    }

    const time = new Date(date);

    return direction === 'DatepickerToUTC'
      ? new Date(time.getTime() - (time.getTimezoneOffset() + offsetForTenantTimezone) * 60 * 1000)
      : new Date(time.getTime() + (time.getTimezoneOffset() + offsetForTenantTimezone) * 60 * 1000);
  },

  // Take in date in UTC +0000 timezone and override the timezone based on timezone argument supplied
  overrideDateTimezone(date: string, timezone: string): Nullable<string> {
    if (!date) {
      return null;
    }

    const dateObj = new Date(date);
    dateObj.setHours(dateObj.getHours() - +timezone / 100);

    return dateObj.toISOString();
  },

  isoFormatAndOverrideTimezone(format: 12 | 24, time: string, date: string, timezone: string): Nullable<string> {
    const dateObj = Formatters.ngxTimepickerISOFormatter(format, time, date);
    return DateUtils.overrideDateTimezone(dateObj, timezone);
  },

  // when useUTCTime is true return time in UTC +0000 timezone
  // input format: "2021-02-28 23:59:00"
  // current timezone +0800
  // output format: "15:59"
  // when useUTCTime is false return time base on current time
  // input format: "2021-02-28 23:59:00"
  // current timezone +0800
  // output format: "23:59"
  getStringForNgxTimepicker(date: string, useUTCTime: boolean = true): Nullable<string> {
    if (!date) {
      return null;
    }

    const dateObj = new Date(date);

    const hours = useUTCTime ? dateObj.getUTCHours() : dateObj.getHours();
    const minutes = useUTCTime ? dateObj.getUTCMinutes() : dateObj.getMinutes();

    const formatDigit = (digit: number): string => digit.toString().padStart(2, '0');

    return `${formatDigit(hours)}:${formatDigit(minutes)}`;
  },

  addDays(date: Date, numberOfDays: number): string {
    date.setDate(date.getDate() + numberOfDays);
    return date.toISOString();
  },

  overrideTimezoneAndStripTime(format: 12 | 24, date: string, timezone: string): Nullable<string> {
    if (!date) {
      return null;
    }

    return DateUtils.isoFormatAndOverrideTimezone(format, '00:00', date, timezone);
  },

  getDateInTenantTimezone(date: Date, timezone: string): Nullable<Date> {
    // Get the current date in tenant timezone
    const currentTenantDate = DateUtils.datepickerAdaptor('UTCToDatepicker', date.toISOString(), timezone);

    if (currentTenantDate) {
      return DateUtils.stripTimeFromDate(currentTenantDate);
    }

    return null;
  }
};
