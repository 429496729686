import { MissingOptionsError } from '../errors';
var OptionsValidationUtils = /** @class */ (function () {
    function OptionsValidationUtils() {
    }
    OptionsValidationUtils.checkOptionPropertyPresent = function (options, propertyKeys) {
        var missingOptions = propertyKeys.filter(function (key) { return options[key] === null || options[key] === undefined; });
        if (missingOptions.length > 0) {
            throw new MissingOptionsError(missingOptions.join(', '));
        }
    };
    return OptionsValidationUtils;
}());
export { OptionsValidationUtils };
