import { ScopesUtils } from '@scopes-utils';
import { ObjectUtils } from '@utils';

// relationOperator: 'or'
export const SCOPES_OR = {
  viewLoyaltyDataMenu: ['points_accounts:index', 'products:index'],
  /* View user info */
  // view_users
  viewCustomers: ['users:index', 'view_users'],
  showCustomers: ['users:show', 'view_users'],
  // view_agents
  viewAgents: ['users:index', 'view_agents'],
  showAgents: ['users:show', 'view_agents'],
  // both
  viewUsers: ['users:index', 'view_users', 'view_agents'],
  showUsers: ['users:show', 'view_users', 'view_agents'],
  /* View insights page */
  viewPointsActivities: ['points_activities:index', 'view_insights'],
  showPointsActivities: ['points_activities:show', 'view_insights'],
  showPointsAccountStatements: ['points_account_statements:show', 'view_insights'],
  viewPointsAccounts: ['user_points_accounts:index', 'view_insights'],
  /* Adjust customer points */
  manualAdjustment: ['points_activities:deduct_points', 'points_activities:accrue_points', 'manual_adjustment'],
  /* Manage identities */
  // identities
  viewIdentities: ['identities:index', 'manage_identities'],
  createIdentities: ['identities:create', 'manage_identities'],
  deleteIdentities: ['identities:destroy', 'manage_identities'],
  verifyIdentities: ['identities:verify', 'manage_identities'],
  // mfa_identities
  viewMfaIdentities: ['mfa_identities:index', 'manage_identities'],
  updateMfaIdentities: ['mfa_identities:update', 'manage_identities'],
  createMfaIdentities: ['mfa_identities:create', 'manage_identities'],
  deleteMfaIdentities: ['mfa_identities:destroy', 'manage_identities'],
  /* Update user info */
  updateCustomers: ['users:update', 'update_users'],
  updateUsername: ['users:update_username', 'update_users:username'],
  updateAgents: ['users:update', 'update_agents'],
  /* Manage user state */
  // block
  blockUsers: ['users:block', 'manage_user_state'],
  blockLoginUsers: ['users:block_login', 'manage_user_state'],
  // unblock
  unblockUsers: ['users:unblock', 'manage_user_state'],
  unblockLoginUsers: ['users:unblock_login', 'manage_user_state'],
  // misc
  rollbackUsers: ['users:rollback', 'manage_user_state'],
  userSession: ['users:session', 'manage_user_state'],
  resetPassword: ['password:reset', 'reset_passwords'],
  viewPasswords: ['users:passwords', 'view_passwords'],
  /* User tokens */
  viewUserTokens: ['tokens:index', 'view_user_tokens'],
  invalidateUserTokens: ['tokens:destroy', 'manage_user_tokens'],
  /* View logs */
  viewAuditLogs: ['audit_logs:index', 'view_logs'],
  viewEvents: ['events:index', 'view_logs'],
  // login_attempts
  viewLoginAttempts: ['login_attempts:index', 'view_logs'],
  showLoginAttempts: ['login_attempts:show', 'view_logs'],
  /* View orders */
  // order_items
  viewOrderItems: ['order_items:index', 'view_orders'],
  showOrderItems: ['order_items:show', 'view_orders'],
  // user_orders
  viewUserOrders: ['user_orders:index', 'view_orders'],
  showUserOrders: ['user_orders:show', 'view_orders'],
  /* Manage orders */
  cancelOrderItems: ['order_items:cancel', 'manage_orders'],
  updateOrderItemStatus: ['reprocesses:create', 'update_order_item_status'],
  // create
  createDeliveries: ['deliveries:create', 'manage_orders', 'resend_order_email'],
  /* Manage tickets */
  submitTickets: ['zendesk_tickets:create', 'manage_tickets'],
  /* View auto-redemption settings */
  viewAutoRedemptionSettings: ['user_auto_redemption_settings:index', 'view_auto_redemption_settings'],
  /* View memberships & benefits */
  viewMemberships: ['memberships:index', 'view_memberships'],
  viewBenefitTrackers: ['benefit_trackers:index', 'view_memberships'],
  viewUserRewardPreference: ['users:reward_preference', 'view_memberships'],
  /* Manage memberships & benefits */
  deleteMemberships: ['memberships:destroy', 'manage_memberships'],
  /* View campaigns */
  viewCampaigns: ['manage_campaigns:index', 'view_campaigns'],
  showCampaigns: ['manage_campaigns:show', 'view_campaigns'],
  // user_campaigns
  viewUserCampaigns: ['user_campaigns:index', 'view_campaigns'],
  /* Manage campaigns */
  createCampaigns: ['manage_campaigns:create', 'manage_campaigns'],
  updateCampaigns: ['manage_campaigns:update', 'manage_campaigns'],
  uploadCampaignsImage: ['manage_campaigns:upload_image', 'manage_campaigns'],
  // viewCampaignsActions
  adjustCampaignsTime: ['manage_campaigns:time_adjustment', 'manage_campaigns'],
  startCampaigns: ['manage_campaigns:start', 'manage_campaigns'],
  stopCampaigns: ['manage_campaigns:stop', 'manage_campaigns'],
  /* View enrollments */
  viewEnrollments: ['enrollments:index', 'view_enrollments'],
  viewUserEnrollments: ['user_enrollments:index', 'view_enrollments'],
  /* Manage user & admin enrollments */
  // user
  createUserEnrollments: ['user_enrollments:create', 'manage_enrollments'],
  // admin
  createAdminEnrollments: ['user_enrollments:create_admin', 'manage_admins'],
  createAscendaAdminEnrollments: ['user_enrollments:ascenda_admin', 'manage_admins'],
  /* View offers */
  viewOffers: ['offers:index', 'view_offers'],
  showOffers: ['offers:show', 'view_offers'],
  downloadOffers: ['offers:download', 'view_offers'],
  viewUserOffers: ['user_offers:index', 'view_offers'],
  /* Manage offers */
  updateOffers: ['offers:update', 'manage_offers'],
  createOffers: ['offers:create', 'manage_offers'],
  uploadOffersImage: ['offers:upload_image', 'manage_offers'],
  /* View merchandises */
  viewMerchandises: ['merchandises:index', 'view_merchandises'],
  showMerchandises: ['merchandises:show', 'view_merchandises'],
  viewMerchandiseCategories: ['merchandises_categories:index', 'view_merchandises'],
  showMerchandiseCategories: ['merchandises_categories:show', 'view_merchandises'],
  /* Manage merchandises */
  updateMerchandises: ['merchandises:update', 'manage_merchandises'],
  /* View gift cards */
  // gift_cards
  viewGiftCards: ['gift_cards:index', 'view_gift_cards'],
  showGiftCards: ['gift_cards:show', 'view_gift_cards'],
  // orders
  showGiftCardOrders: ['gift_card_orders:show', 'view_gift_card_orders'],
  viewGiftCardOrders: ['gift_card_orders:index', 'view_gift_card_orders'],
  retryGiftCardOrder: ['gift_card_orders:retry', 'manage_gift_card_orders'],
  // order attempts
  viewGiftCardOrderAttempts: ['gift_card_orders:attempts', 'view_gift_card_orders'],
  // merge configs
  viewMergeConfigs: ['merge_configs:index', 'view_gift_cards'],
  showMergeConfigs: ['merge_configs:show', 'view_gift_cards'],
  // webhooks
  viewGiftCardOrderWebhooks: ['gift_card_orders:webhooks', 'view_gift_card_orders'],
  resendGiftCardOrderWebhooks: [
    'gift_card_orders:resend_webhook',
    'manage_gift_card_orders',
    'resend_gift_card_order_webhooks'
  ],
  /* Manage gift cards */
  // gift_cards
  updateGiftCards: ['gift_cards:update', 'manage_gift_cards'],
  // merge configs
  createMergeConfigs: ['merge_configs:create', 'manage_gift_cards'],
  deleteMergeConfigs: ['merge_configs:destroy', 'manage_gift_cards'],
  /* View loyalty programs */
  viewLoyaltyPrograms: ['loyalty_programs:index', 'view_loyalty_programs'],
  showLoyaltyPrograms: ['loyalty_programs:show', 'view_loyalty_programs'],
  /* Manage loyalty programs */
  createLoyaltyPrograms: ['loyalty_programs:create', 'manage_loyalty_programs'],
  updateLoyaltyPrograms: ['loyalty_programs:update', 'manage_loyalty_programs'],
  /* View and manage notes */
  ...ScopesUtils.generateTopLevelNoteScopes(),
  ...ScopesUtils.generateEntityNoteScopes('users'),
  ...ScopesUtils.generateEntityNoteScopes('offers'),
  ...ScopesUtils.generateEntityNoteScopes('campaigns'),
  /* View notifications */
  viewNotifications: ['notifications:index', 'view_notifications'],
  /* View pricing */
  viewPricing: ['redemption_rates:index', 'view_pricing'],
  showPricing: ['redemption_rates:show', 'view_pricing'],
  /* Manage pricing */
  createPricing: ['redemption_rates:create', 'manage_pricing'],
  updatePricing: ['redemption_rates:update', 'manage_pricing'],
  deletePricing: ['redemption_rates:destroy', 'manage_pricing'],
  /* View approval requests */
  viewApprovalRequests: ['approval_requests:index', 'view_approval_requests'],
  showApprovalRequests: ['approval_requests:show', 'view_approval_requests'],
  /* Manage approval requests */
  respondToApprovalRequests: ['approval_requests:respond', 'manage_approval_requests'],
  /* View apps information */
  // apps
  viewApps: ['apps:index', 'view_apps'],
  showApps: ['apps:show', 'view_apps'],
  viewCerberusConfig: ['apps:cerberus', 'view_apps'],
  viewSentinelConfig: ['apps:sentinel', 'view_apps'],
  viewAppsSecret: ['apps:secret', 'view_apps'],
  // domains
  viewDomains: ['domains:index', 'view_apps'],
  showDomains: ['domains:show', 'view_apps'],
  // allowed_origins
  viewAllowedOrigins: ['allowed_origins:index', 'view_apps'],
  // app_connections
  viewAppConnections: ['app_connections:index', 'view_apps'],
  showAppConnections: ['app_connections:show', 'view_apps'],
  // connection_credentials
  viewConnectionCredentials: ['connection_credentials:index', 'view_apps'],
  // keys
  viewKeys: ['keys:index', 'view_apps'],
  showKeys: ['keys:show', 'view_apps'],
  viewPrivateKeys: ['keys:private_keys', 'view_apps'],
  // tools
  exportTools: ['tools:export_all', 'view_apps'],
  /* Manage apps */
  // apps
  resetCerberusConfig: ['apps:reset_cerberus_config', 'manage_apps'],
  // domains
  createDomains: ['domains:create', 'manage_apps'],
  updateDomains: ['domains:update', 'manage_apps'],
  deleteDomains: ['domains:destroy', 'manage_apps'],
  // allowed_origins
  refreshAllowedOrigins: ['allowed_origins:refresh', 'manage_apps'],
  // app_connections
  createAppConnections: ['app_connections:create', 'manage_apps'],
  // keys
  createKeys: ['keys:create', 'manage_apps'],
  // MC tenants
  viewMcTenants: ['mc_tenants:index', 'view_mc_tenants'],
  showMcTenants: ['mc_tenants:show', 'view_mc_tenants'],
  updateMcTenants: ['mc_tenants:update', 'manage_mc_tenants'],
  createMcTenants: ['mc_tenants:create', 'manage_mc_tenants'],
  clearMcTenantBootstrapCache: ['mc_tenants:clear_bootstrap_cache', 'manage_mc_tenants'],
  /* View roles */
  viewRoles: ['roles:index', 'view_roles'],
  showRoles: ['roles:show', 'view_roles'],
  /* View permissions */
  viewPermissions: ['permissions:index', 'view_roles'],
  /* Manage roles */
  updateRole: ['roles:update', 'manage_roles'],
  batchUpdatePermissions: ['permissions:batch_update', 'manage_roles'],
  /* View auth tenant information */
  // tenants
  viewTenants: ['tenants:index', 'view_tenants'],
  showTenants: ['tenants:show', 'view_tenants'],
  // password_policies
  viewPasswordPolicies: ['password_policies:index', 'view_tenants'],
  showPasswordPolicies: ['password_policies:show', 'view_tenants'],
  // authentication_providers
  viewAuthenticationProviders: ['authentication_providers:index', 'view_tenants'],
  showAuthenticationProviders: ['authentication_providers:show', 'view_tenants'],
  /* Manage auth tenant */
  // tenants
  updateTenants: ['tenants:update', 'manage_tenants'],
  // password_policies
  createPasswordPolicies: ['password_policies:create', 'manage_tenants'],
  updatePasswordPolicies: ['password_policies:update', 'manage_tenants'],
  // authentication_providers
  createAuthenticationProviders: ['authentication_providers:create', 'manage_tenants'],
  updateAuthenticationProviders: ['authentication_providers:update', 'manage_tenants'],
  // rewards marketplace
  viewRewardsOffering: [
    'view_cashback_products',
    'view_crypto_products',
    'view_gift_cards_configs',
    'view_affiliate_offers_configs',
    'view_card_linked_offers_configs',
    'view_frequent_traveler_programs',
    'view_sustainability_products',
    'view_flights_configs',
    'view_hotels_configs',
    'view_cars_configs'
  ],
  manageRewardsOffering: [
    'manage_cashback_products',
    'manage_crypto_products',
    'manage_gift_cards_configs',
    'manage_affiliate_offers_configs',
    'manage_card_linked_offers_configs',
    'manage_frequent_traveler_programs',
    'manage_sustainability_products',
    'manage_flights_configs',
    'manage_hotels_configs',
    'manage_cars_configs'
  ],
  activateRewardsOffering: [
    'activate_cashback_products',
    'activate_crypto_products',
    'activate_gift_cards',
    'activate_affiliate_offers_configs',
    'activate_card_linked_offers_configs',
    'activate_frequent_traveler_programs',
    'activate_sustainability_products',
    'activate_flights',
    'activate_hotels',
    'activate_cars'
  ],
  /* View access policies */
  viewAccessPolicies: ['access_policies:index', 'view_abilities_and_access_policies'],
  showAccessPolicies: ['access_policies:show', 'view_abilities_and_access_policies'],
  /* Manage access policies */
  createAccessPolicies: ['access_policies:create', 'manage_abilities_and_access_policies'],
  updateAccessPolicies: ['access_policies:update', 'manage_abilities_and_access_policies'],
  deleteAccessPolicies: ['access_policies:destroy', 'manage_abilities_and_access_policies'],
  /* View abilities */
  viewAbilities: ['abilities:index', 'view_abilities_and_access_policies'],
  showAbilities: ['abilities:show', 'view_abilities_and_access_policies'],
  /* Manage abilities */
  createAbilities: ['abilities:create', 'manage_abilities_and_access_policies'],
  updateAbilities: ['abilities:update', 'manage_abilities_and_access_policies'],
  deleteAbilities: ['abilities:destroy', 'manage_abilities_and_access_policies'],
  /* View watchlists */
  viewWatchlists: ['watchlists:index', 'view_watchlists'],
  showWatchlists: ['watchlists:show', 'view_watchlists'],
  /* Manage watchlists */
  createWatchlists: ['watchlists:create', 'manage_watchlists'],
  updateWatchlists: ['watchlists:update', 'manage_watchlists'],
  deleteWatchlists: ['watchlists:destroy', 'manage_watchlists']
};

// dependent on `SCOPES_OR`
const removeArrayDuplicates = ObjectUtils.removeArrayDuplicates;

export const SCOPES_OR_GENERATED = {
  /* Update user info */
  updateCustomersAndUsername: [...SCOPES_OR.updateCustomers, ...SCOPES_OR.updateUsername],
  updateUsers: removeArrayDuplicates([
    ...SCOPES_OR.updateAgents,
    // Same as updateCustomersAndUsername
    ...SCOPES_OR.updateCustomers,
    ...SCOPES_OR.updateUsername
  ]),
  /* View memberships & benefits */
  viewMembershipsMenu: removeArrayDuplicates([
    ...SCOPES_OR.viewMemberships,
    ...SCOPES_OR.viewBenefitTrackers,
    ...SCOPES_OR.viewUserRewardPreference
  ]),
  /* Manage campaigns: viewCampaignsActions */
  viewCampaignsActions: removeArrayDuplicates([
    ...SCOPES_OR.startCampaigns,
    ...SCOPES_OR.stopCampaigns,
    ...SCOPES_OR.adjustCampaignsTime
  ]),
  /* Manage user & admin enrollments */
  createEnrollments: removeArrayDuplicates([
    ...SCOPES_OR.createUserEnrollments,
    ...SCOPES_OR.createAdminEnrollments,
    ...SCOPES_OR.createAscendaAdminEnrollments
  ]),
  /* View gift cards */
  viewGiftCardsMenu: removeArrayDuplicates([
    ...SCOPES_OR.viewGiftCards,
    ...SCOPES_OR.viewMergeConfigs,
    ...SCOPES_OR.viewGiftCardOrders
  ]),
  /* View tenants module */
  viewTenantsModule: [...SCOPES_OR.viewTenants, 'tenants:create'],
  /* Rewards marketplace*/
  viewRewardsOfferingListing: [
    ...SCOPES_OR.viewRewardsOffering,
    ...SCOPES_OR.manageRewardsOffering,
    ...SCOPES_OR.activateRewardsOffering,
    // The tiles that link to existing gift cards and offers listing are temporarily included in rewards offering product categories.
    'view_gift_cards',
    'view_offers'
  ],
  /* Manage access policies */
  viewAccessPolicyActionsColumn: removeArrayDuplicates([
    ...SCOPES_OR.showAccessPolicies,
    ...SCOPES_OR.updateAccessPolicies,
    ...SCOPES_OR.deleteAccessPolicies
  ]),
  viewMcTenantActionsColumn: removeArrayDuplicates([
    ...SCOPES_OR.showMcTenants,
    ...SCOPES_OR.updateMcTenants,
    ...SCOPES_OR.clearMcTenantBootstrapCache
  ])
};

export const SCOPES_GROUP = {
  // relationOperator: 'any-strict-groups'
  /* View insights page */
  viewDashboardPage: [['points_activities:index', 'user_points_accounts:index'], ['view_insights']],
  /* View roles */
  viewRolePermissionsPage: [['apps:index', 'roles:index', 'permissions:index'], ['view_roles']],
  viewRolePage: [['apps:index', 'roles:show'], ['view_roles']],
  /* Manage roles */
  viewCreateRolePage: [['apps:index', 'roles:create'], ['manage_roles']],
  viewUpdateRolePage: [
    ['apps:index', 'roles:show', 'roles:update'],
    ['apps:index', 'roles:show', 'permissions:batch_update'],
    ['manage_roles', 'view_roles']
  ],
  /* Manage abilities */
  viewAbilityActionsColumn: [
    ['abilities:destroy'],
    ['abilities:show', 'abilities:update'],
    ['manage_abilities_and_access_policies']
  ],

  // relationOperator: 'all-loose-groups'
  /* Update user info */
  viewUpdateCustomersPage: [SCOPES_OR_GENERATED.updateCustomersAndUsername, SCOPES_OR.showCustomers],
  viewUpdateAgentsPage: [SCOPES_OR.updateAgents, SCOPES_OR.showAgents],
  /* View auto-redemption settings */
  viewUserAutoRedemptionSettingsPage: [
    SCOPES_OR.viewAutoRedemptionSettings,
    ['user_points_accounts:index', 'view_auto_redemption_settings']
  ],
  /* View enrollments */
  viewEnrollmentsPage: [SCOPES_OR.viewEnrollments, SCOPES_OR.viewUsers],
  /* View apps information */
  viewAppConnectionsPage: [SCOPES_OR.viewAppConnections, SCOPES_OR.viewApps],
  viewUpdateAppConnectionPage: [
    ['app_connections:update', 'manage_apps'],
    SCOPES_OR.showAppConnections,
    SCOPES_OR.viewApps
  ],
  /* Manage apps */
  viewCreateAppPage: [['apps:create', 'manage_apps'], SCOPES_OR.viewTenants],
  viewUpdateAppPage: [['apps:update', 'manage_apps'], SCOPES_OR.viewTenants],
  /* Manage auth tenant */
  viewCreateAuthenticationProviderPage: [SCOPES_OR.createAuthenticationProviders, SCOPES_OR.viewTenants],
  /* Manage abilities */
  viewAbilityEditPage: [SCOPES_OR.showAbilities, SCOPES_OR.updateAbilities],
  /* Manage access policies */
  viewAccessPolicyEditPage: [SCOPES_OR.showAccessPolicies, SCOPES_OR.updateAccessPolicies],
  /* Manage MC tenants */
  viewMcTenantEditPage: [
    ['mc_tenants:show', 'view_mc_tenants'],
    ['mc_tenants:update', 'manage_mc_tenants']
  ]
};
