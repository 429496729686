import { Selector, createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { Transaction, TransactionsState, transactionAdapter } from '../../types';

const getTransactionsState = createFeatureSelector<TransactionsState>('transaction');

const { isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<TransactionsState>(getTransactionsState);

const { selectAll: getTransactions } = transactionAdapter.getSelectors(getTransactionsState);

const getFilter = createSelector(getTransactionsState, (state: TransactionsState) => state.filter);
const getMetadata = createSelector(getTransactionsState, (state: TransactionsState) => state.metadata);

const getTransactionsByUserId = (userId: string): Selector<TransactionsState, Transaction[]> =>
  createSelector(getTransactions, getTransactionsList =>
    getTransactionsList.filter(transaction => transaction.userId === userId)
  );

const isTransactionsLoaded = (userId: string): Selector<TransactionsState, boolean> =>
  createSelector(
    getTransactionsState,
    isBatchResting,
    (state, batchResting) => batchResting && state.userId === userId
  );

export const transactionsQuery = {
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getTransactions,
  getTransactionsByUserId,
  getMetadata,
  getFilter,
  isTransactionsLoaded
};
