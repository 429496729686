import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';

import { navItemsV2 } from '@core/constants/nav-item-v2.const';

import { SideNavV2HelperService } from '../side-nav-v2-helper.service';
import { SideNavV2Store } from '../side-nav-v2.store';

@Component({
  selector: 'admin-side-nav-v2-body',
  template: `
    <admin-slider-container>
      <admin-side-nav-v2-navigation-list *adminSliderItem [navItems]="navItems()" />

      @for (selectedGroup of selectedGroups(); let index = $index; track selectedGroup.id) {
        <admin-side-nav-v2-navigation-list
          *adminSliderItem
          class="h-full"
          [groupLevel]="index"
          [navItems]="selectedGroup.children"
        />
      }
    </admin-slider-container>
  `,
  styles: [
    `
      :host {
        @apply overflow-hidden;

        --icon-fill-color: var(--neutrals-100);
      }
    `
  ],
  animations: [
    trigger('slideOut', [
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate(`250ms 250ms cubic-bezier(0.80, 0.00, 0.67, 1.00)`, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavV2BodyComponent implements OnInit {
  //#region Dependencies

  private router = inject(Router);
  private sideNavV2Store = inject(SideNavV2Store);
  private sideNavV2Helper = inject(SideNavV2HelperService);

  //#endregion

  selectedGroups = this.sideNavV2Store.selectedGroups;

  navItems = this.sideNavV2Helper.availableNavItems;

  ngOnInit(): void {
    // Set supported nav items
    this.sideNavV2Helper.setNavItems(navItemsV2);

    // Update activated route on route change
    const routeChanged$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => true),
      // Run route check immediately after sidenav is initialized
      startWith(true)
    );

    this.sideNavV2Store.updateActivatedRouteOnRouteChanged(routeChanged$);
  }
}
