import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { initialState, userAdapter, UserState } from '../../types';
import { deleteIdentitySuccess } from '../actions/identities.actions';
import { deleteMfaIdentitySuccess } from '../actions/mfa-identities.actions';
import {
  enrollAdmin,
  enrollAdminFailure,
  enrollAdminSuccess,
  enrollAscendaAdmin,
  enrollAscendaAdminFailure,
  enrollAscendaAdminSuccess,
  enrollUser,
  enrollUserFailure,
  enrollUserSuccess,
  fetchUser,
  fetchUserFailure,
  fetchUsers,
  fetchUsersFailure,
  fetchUsersSuccess,
  fetchUserSuccess,
  loadUser,
  loadUserFailure,
  loadUsers,
  loadUsersCount,
  loadUsersCountFailure,
  loadUsersCountSuccess,
  loadUsersFailure,
  loadUsersSuccess,
  loadUserSuccess,
  setUsersFilter,
  unenrollUserSuccess,
  updateUser,
  updateUserFailure,
  updateUsernameSuccess,
  updateUserSuccess
} from '../actions/users.actions';

export const userTriggers: EntityTriggers = {
  single: {
    loading: [loadUser.type, updateUser.type, enrollUser.type, enrollAdmin.type, enrollAscendaAdmin.type],
    resting: [
      loadUserSuccess.type,
      updateUserSuccess.type,
      loadUsersCountSuccess.type,
      enrollUserSuccess.type,
      enrollAdminSuccess.type,
      enrollAscendaAdminSuccess.type
    ],
    erroring: [
      loadUserFailure.type,
      updateUserFailure.type,
      loadUsersCountFailure.type,
      enrollUserFailure.type,
      enrollAdminFailure.type,
      enrollAscendaAdminFailure.type
    ]
  },
  batch: {
    loading: [loadUsers.type],
    resting: [loadUsersSuccess.type],
    erroring: [loadUsersFailure.type]
  }
};

export const userFetchListTriggers: FetchListTriggers = {
  loading: [fetchUser.type, fetchUsers.type],
  resting: [fetchUserSuccess.type, fetchUsersSuccess.type],
  erroring: [fetchUserFailure.type, fetchUsersFailure.type]
};

export function usersReducer(state: UserState = initialState, action: Action): UserState {
  return {
    ...callStateReducer(baseReducer, userTriggers)(state, action),
    ...fetchStateReducer(userFetchListTriggers, 'user')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(fetchUserSuccess, (state, { user }) => ({ ...userAdapter.upsertOne(user, state), count: 1 })),
  on(fetchUsersSuccess, (state, { data }) => userAdapter.setMany(data, state)),
  on(loadUserSuccess, (state, { user }) => ({ ...userAdapter.upsertOne(user, state), count: 1 })),
  on(loadUser, loadUsers, setUsersFilter, (state, { filter }) => ({ ...state, filter: filter ?? state.filter })),
  on(loadUsersSuccess, (state, { result }) => userAdapter.setAll(result.data, state)),
  on(updateUsernameSuccess, (state, { updateUsernameResponseParams: { user } }) =>
    userAdapter.updateOne({ id: user.id, changes: user }, state)
  ),
  on(loadUserFailure, state => userAdapter.removeAll({ ...state, count: 0 })),
  on(loadUsersCount, state => ({ ...state, count: null })),
  on(loadUsersCountSuccess, (state, { result }) => ({ ...state, count: result.count })),
  on(unenrollUserSuccess, (state, { result }) => userAdapter.setOne(result.user, state)),
  on(deleteMfaIdentitySuccess, (state, { user, mfaIdentityId }) =>
    userAdapter.updateOne(
      {
        id: user.id,
        changes: { ...user, mfaIdentities: user.mfaIdentities.filter(identity => identity.id !== mfaIdentityId) }
      },
      state
    )
  ),
  on(deleteIdentitySuccess, (state, { user, reference }) =>
    userAdapter.updateOne(
      {
        id: user.id,
        changes: { ...user, identities: user.identities.filter(identity => identity.reference !== reference) }
      },
      state
    )
  )
);
