var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var TIMEOUT_ERROR_CODE = 'HM08';
var HermesError = /** @class */ (function (_super) {
    __extends(HermesError, _super);
    function HermesError(error, metadata) {
        var _this = _super.call(this, error.code + " " + error.message) || this;
        _this.code = error.code;
        _this.message = error.message;
        _this.metadata = metadata;
        return _this;
    }
    return HermesError;
}(Error));
export { HermesError };
var IdleCheckNotStartedError = /** @class */ (function (_super) {
    __extends(IdleCheckNotStartedError, _super);
    function IdleCheckNotStartedError() {
        return _super.call(this, { code: 'HM01', message: 'Idle check service is not initialized' }) || this;
    }
    return IdleCheckNotStartedError;
}(HermesError));
export { IdleCheckNotStartedError };
var TokenError = /** @class */ (function (_super) {
    __extends(TokenError, _super);
    function TokenError(metadata) {
        return _super.call(this, { code: 'HM02', message: 'Token is invalid or missing' }, metadata) || this;
    }
    return TokenError;
}(HermesError));
export { TokenError };
var StateMissingError = /** @class */ (function (_super) {
    __extends(StateMissingError, _super);
    function StateMissingError(metadata) {
        return _super.call(this, { code: 'HM03', message: 'State is missing' }, metadata) || this;
    }
    return StateMissingError;
}(HermesError));
export { StateMissingError };
var StateInvalidError = /** @class */ (function (_super) {
    __extends(StateInvalidError, _super);
    function StateInvalidError(requestState, responseState, metadata) {
        var _this = _super.call(this, { code: 'HM04', message: "Response state " + responseState + " does not match request state " + requestState }, metadata) || this;
        _this.requestState = requestState;
        _this.responseState = responseState;
        return _this;
    }
    return StateInvalidError;
}(HermesError));
export { StateInvalidError };
var SessionError = /** @class */ (function (_super) {
    __extends(SessionError, _super);
    function SessionError() {
        return _super.call(this, { code: 'HM05', message: 'Session is invalid' }) || this;
    }
    return SessionError;
}(HermesError));
export { SessionError };
// remove HM06 ServerError because it's not used anymore
var ExchangeTokenError = /** @class */ (function (_super) {
    __extends(ExchangeTokenError, _super);
    function ExchangeTokenError(message) {
        return _super.call(this, { code: 'HM07', message: message || 'Exchange token unsuccessful' }) || this;
    }
    return ExchangeTokenError;
}(HermesError));
export { ExchangeTokenError };
var TimeoutError = /** @class */ (function (_super) {
    __extends(TimeoutError, _super);
    function TimeoutError(metadata) {
        return _super.call(this, { code: TIMEOUT_ERROR_CODE, message: 'Timeout' }, metadata) || this;
    }
    return TimeoutError;
}(HermesError));
export { TimeoutError };
var MissingRefreshTokenError = /** @class */ (function (_super) {
    __extends(MissingRefreshTokenError, _super);
    function MissingRefreshTokenError() {
        return _super.call(this, { code: 'HM09', message: 'Missing refresh token from localStorage' }) || this;
    }
    return MissingRefreshTokenError;
}(HermesError));
export { MissingRefreshTokenError };
var MissingOptionsError = /** @class */ (function (_super) {
    __extends(MissingOptionsError, _super);
    function MissingOptionsError(message) {
        return _super.call(this, { code: 'HM10', message: "Missing options: " + message }) || this;
    }
    return MissingOptionsError;
}(HermesError));
export { MissingOptionsError };
