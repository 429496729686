import { first, map } from 'rxjs/operators';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { Params } from '@utils';

import { loadUserOfferActivities } from '../store/actions/user-offer-activities.actions';
import { userOfferActivitiesQuery } from '../store/selectors/user-offer-activities.selectors';
import { UserOfferActivitiesFilter, UserOfferActivityState } from '../types';

export const userOfferActivitiesResolver: ResolveFn<UserOfferActivitiesFilter> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<UserOfferActivityState> = inject(Store<UserOfferActivityState>),
  scopes: Scopes = inject(Scopes)
): Observable<UserOfferActivitiesFilter> => {
  if (scopes.lack('view_user_offer_activities')) return of(null);

  const userId: string = Params.find(route, 'userId');

  return combineLatest([
    store.select(userOfferActivitiesQuery.isUserOfferActivitiesLoaded(userId)),
    store.select(userOfferActivitiesQuery.getFilter)
  ]).pipe(
    first(),
    map(([isLoaded, filter]) => {
      if (!isLoaded) {
        filter ||= new UserOfferActivitiesFilter();
        store.dispatch(loadUserOfferActivities({ userId, filter }));
      }

      return filter;
    })
  );
};
