import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { GiftCardOrdersService } from '../../services/gift-card-orders.service';
import {
  loadOrderWebhooks,
  loadOrderWebhooksFailure,
  loadOrderWebhooksSuccess,
  resendOrderWebhook,
  resendOrderWebhookFailure,
  resendOrderWebhookSuccess
} from '../actions/order-webhooks.actions';

@Injectable()
export class OrderWebhooksEffects {
  loadOrderWebhooks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderWebhooks),
      exhaustMap(action =>
        this.giftCardOrdersService.getOrderWebhooks(action.orderId).pipe(
          map(webhooks => loadOrderWebhooksSuccess({ webhooks })),
          catchError(error => of(loadOrderWebhooksFailure({ error })))
        )
      )
    )
  );

  resendOrderWebhook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendOrderWebhook),
      exhaustMap(action =>
        this.giftCardOrdersService.resendWebhook(action.orderId).pipe(
          map(() => resendOrderWebhookSuccess({ orderId: action.orderId })),
          catchError(error => of(resendOrderWebhookFailure({ error })))
        )
      )
    )
  );

  resendOrderWebhookSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendOrderWebhookSuccess),
      map(action => loadOrderWebhooks({ orderId: action.orderId }))
    )
  );

  constructor(
    private actions$: Actions,
    private giftCardOrdersService: GiftCardOrdersService
  ) {}
}
