import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[adminFixedDecimals]'
})
export class FixedDecimalsDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('adminFixedDecimals') decimalPlace: number;

  constructor(
    private el: ElementRef,
    private ngControl: NgControl
  ) {}

  @HostListener('focusout', ['$event'])
  onFocusout(): void {
    const value = (+this.el.nativeElement.value).toFixed(this.decimalPlace);

    if (isNaN(Number.parseFloat(value))) {
      return;
    }

    const numericValue = Number(value);

    this.el.nativeElement.value = numericValue;
    this.ngControl.control!.setValue(numericValue);
  }
}
