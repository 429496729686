<ng-container *ngIf="!hideBackButton && !isV2style">
  <button
    *adminIfScopesInclude="backButtonScopes; relationOperator: 'or'"
    class="back-button"
    mat-button
    color="primary"
    routerLink=".."
  >
    <mat-icon>keyboard_backspace</mat-icon>
    Back to {{ previousPageLabel }}
  </button>
</ng-container>

<div *ngIf="isV2style; else isV1style">
  <admin-menu-v2 [tabs]="tabs" tooltipPrefix="users.menu.">
    <admin-user-header-v2 [tabs]="tabs" *ngIf="!(error$ | async)"></admin-user-header-v2>
  </admin-menu-v2>
</div>
<ng-template #isV1style>
  <admin-user-header></admin-user-header>
  <admin-menu [tabs]="tabs" tooltipPrefix="users.menu."></admin-menu>
</ng-template>

<ng-container [ngSwitch]="renderState$ | async">
  <div *ngSwitchCase="'content'">
    <router-outlet *ngIf="isV2style"></router-outlet>
  </div>

  <admin-spinner *ngSwitchCase="'loading'"></admin-spinner>

  <div *ngSwitchCase="'error'">
    <div *ngIf="isV2style; else isV1style">
      <admin-empty-state-page entity="User"></admin-empty-state-page>
    </div>
    <ng-template #isV1style>
      <mat-card appearance="outlined" fxLayout="row" fxLayoutAlign="center center" fxFlex>
        <div>
          <h4><mat-icon [inline]="true">warning</mat-icon>{{ errorText }}</h4>
        </div>
      </mat-card>
    </ng-template>
  </div>
</ng-container>
