<h1 mat-dialog-title>Edit MFA Identity</h1>

<form [formGroup]="editForm" (keydown.enter)="$event.preventDefault()">
  <div fxLayout="column">
    <mat-slide-toggle formControlName="verified">Verified</mat-slide-toggle>
    <mat-form-field class="slim-form-field">
      <mat-label>Login ID</mat-label>
      <input matInput placeholder="Login ID" formControlName="uid" #uid />
      <mat-error *ngIf="uidControl.hasError('required')">Required</mat-error>
      <mat-error *ngIf="uidControl.hasError('email') && providerId === 'email'">Invalid email</mat-error>
      <mat-error *ngIf="uidControl.hasError('invalidE164') && providerId === 'sms'">{{ invalidE164Message }}</mat-error>
    </mat-form-field>
  </div>
</form>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button
    mat-raised-button
    color="secondary"
    class="uppercase-button text-primary cancel-button"
    (click)="dialogRef.close(false)"
  >
    <span>Cancel</span>
  </button>

  <button
    mat-raised-button
    color="primary"
    class="uppercase-button"
    (click)="submitForm()"
    [disabled]="
      (uid.value === data.mfaIdentity.uid && editForm.controls.verified.value === data.mfaIdentity.verified) ||
      editForm.invalid
    "
  >
    <span>Update</span>
  </button>
</div>
