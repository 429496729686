var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { version } from '../../package.json';
import { StateInvalidError, StateMissingError, TokenError } from '../errors';
import { DecodedToken } from '../shared/decoded_token';
import { isString } from '../util';
import { OptionsValidationUtils } from '../utils/options-validation-utils';
import { IframeHandler } from './iframe_handler';
import Store from './store';
var RefreshToken = /** @class */ (function () {
    function RefreshToken(options) {
        this.options = options;
        OptionsValidationUtils.checkOptionPropertyPresent(this.options, ['authBaseUrl']);
    }
    RefreshToken.prototype.call = function (state) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, new IframeHandler({
                            authBaseUrl: this.options.authBaseUrl,
                            url: this.buildUrl(state)
                        }).call()];
                    case 1:
                        response = _b.sent();
                        if (!response.state) {
                            throw new StateMissingError(this.errorMetadata(response));
                        }
                        if (response.state !== state) {
                            throw new StateInvalidError(state, response.state, this.errorMetadata(response));
                        }
                        if (!isString((_a = response.accessToken) !== null && _a !== void 0 ? _a : response.idToken) ||
                            !isString(response.tokenType) || response.tokenType !== 'Bearer' ||
                            !Number.isInteger(response.expiresAt)) {
                            throw new TokenError(this.errorMetadata(response));
                        }
                        Store.result = response;
                        return [2 /*return*/, response];
                }
            });
        });
    };
    RefreshToken.prototype.errorMetadata = function (response) {
        var _a;
        try {
            var _b = new DecodedToken((_a = response.accessToken) !== null && _a !== void 0 ? _a : response.idToken), jti = _b.jti, sub = _b.sub;
            return { jti: jti, sub: sub };
        }
        catch (_c) {
            return {};
        }
    };
    RefreshToken.prototype.buildUrl = function (state) {
        return this.options.authBaseUrl + "/authorize?" +
            'response_type=web_message&' +
            ("state=" + state + "&") +
            ("hermes_version=" + version);
    };
    return RefreshToken;
}());
export { RefreshToken };
