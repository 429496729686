import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundedNumber'
})
export class RoundedNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | string | null | undefined, digits?: number, locale?: string): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    // Use floor rounding for numbers with 0 decimal places as default
    // TODO: support floor and ceil rounding with different decimals in the future
    if (digits === 0) {
      return Math.floor(Number(value)).toLocaleString(locale);
    }

    const digitsInfo = Number.isInteger(digits) ? `1.${digits}-${digits}` : '';

    return this.decimalPipe.transform(value, digitsInfo, locale);
  }
}
