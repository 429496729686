var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HermesError, TimeoutError } from '../errors';
import { Formatters } from '../utils/formatters';
import Store from './store';
var IframeHandler = /** @class */ (function () {
    function IframeHandler(options) {
        this.options = options;
        this.TIMEOUT = 5000; // 5 seconds
    }
    IframeHandler.prototype.call = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.iframe = window.document.createElement('iframe');
                        _this.iframe.style.display = 'none';
                        var eventHandler = function (event) {
                            if (!_this.isOriginValid(event) ||
                                event.source !== _this.iframe.contentWindow ||
                                event.data.response_type !== 'authorization_response' ||
                                !event.data.response) {
                                return;
                            }
                            _this.destroy(eventHandler);
                            var response = Formatters.fromSnakeToCamelCaseKeys(event.data.response);
                            if (response.errors) {
                                if (Array.isArray(response.errors) && response.errors.length > 0) {
                                    reject(new HermesError(response.errors[0]));
                                }
                                else {
                                    reject(new HermesError(response.errors));
                                }
                            }
                            resolve(response);
                        };
                        _this.timeoutId = window.setTimeout(function () {
                            var _a;
                            _this.destroy(eventHandler);
                            var _b = (_a = Store.decodedAccessOrIdToken) !== null && _a !== void 0 ? _a : {}, jti = _b.jti, sub = _b.sub;
                            reject(new TimeoutError({ jti: jti, sub: sub }));
                        }, _this.TIMEOUT);
                        window.addEventListener('message', eventHandler);
                        window.document.body.appendChild(_this.iframe);
                        _this.iframe.src = _this.options.url;
                    })];
            });
        });
    };
    IframeHandler.prototype.isOriginValid = function (event) {
        try {
            var originHost = new URL(event.origin).host;
            var authBaseHost = new URL(this.options.authBaseUrl).host;
            return originHost === authBaseHost;
        }
        catch (_a) {
            return false;
        }
    };
    IframeHandler.prototype.destroy = function (eventHandler) {
        clearTimeout(this.timeoutId);
        window.removeEventListener('message', eventHandler);
        var parentNode = this.iframe.parentNode;
        // handle the case when iframe is not in the tree / already removed from the tree
        if (parentNode) {
            parentNode.removeChild(this.iframe);
        }
    };
    return IframeHandler;
}());
export { IframeHandler };
