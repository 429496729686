import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { giftCardAdapter, GiftCardState, initialState } from '../../types/gift-cards.type';
import {
  loadGiftCard,
  loadGiftCardFailure,
  loadGiftCards,
  loadGiftCardsFailure,
  loadGiftCardsSuccess,
  loadGiftCardSuccess,
  setGiftCardsFilter,
  updateGiftCard,
  updateGiftCardFailure,
  updateGiftCardSuccess
} from '../actions/gift-cards.actions';

export const giftCardTriggers: EntityTriggers = {
  single: {
    loading: [loadGiftCard.type, updateGiftCard.type],
    resting: [loadGiftCardSuccess.type, updateGiftCardSuccess.type],
    erroring: [loadGiftCardFailure.type, updateGiftCardFailure.type]
  },
  batch: {
    loading: [loadGiftCards.type],
    resting: [loadGiftCardsSuccess.type],
    erroring: [loadGiftCardsFailure.type]
  }
};

export function giftCardsReducer(state: GiftCardState = initialState, action: Action): GiftCardState {
  return callStateReducer(baseReducer, giftCardTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadGiftCardSuccess, (state, { giftCard }) => giftCardAdapter.upsertOne(giftCard, state)),
  on(loadGiftCards, setGiftCardsFilter, (state, { filter }) => ({ ...state, filter })),
  on(loadGiftCardsSuccess, (state, { result }) => ({
    ...giftCardAdapter.setAll(result.data, state),
    count: result.metadata?.total || 0
  })),
  on(updateGiftCardSuccess, (state, { giftCard }) =>
    giftCardAdapter.updateOne({ id: giftCard.id, changes: giftCard }, state)
  )
);
