<mat-checkbox
  color="primary"
  class="checkbox"
  [attr.aria-required]="required"
  [aria-label]="fieldLabel"
  [checked]="value"
  [disabled]="disabled"
  [name]="formControlName"
  (change)="onChange($event.checked); onTouched(); touched = true"
>
  <span *ngIf="fieldLabel.length > 0" [innerHTML]="fieldLabel | customTitleCase: 'fromCamelCase'"></span>
</mat-checkbox>

<mat-error class="error-message" *ngIf="errors?.required && touched">Required</mat-error>
