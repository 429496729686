import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers, tenantBootstrapInitialState, TenantBootstrapState } from '@core/types';

import {
  loadTenantBootstrap,
  loadTenantBootstrapFailure,
  loadTenantBootstrapSuccess
} from '../actions/tenant-bootstrap.actions';

export const tenantBootstrapTriggers: EntityTriggers = {
  single: {
    loading: [loadTenantBootstrap.type],
    resting: [loadTenantBootstrapSuccess.type],
    erroring: [loadTenantBootstrapFailure.type]
  }
};

export function tenantBootstrapReducer(
  state: TenantBootstrapState = tenantBootstrapInitialState,
  action: Action
): TenantBootstrapState {
  return callStateReducer(baseReducer, tenantBootstrapTriggers)(state, action);
}

const baseReducer = createReducer(
  tenantBootstrapInitialState,
  on(loadTenantBootstrapSuccess, (state, { tenantBootstrap }) => ({ ...state, tenantBootstrap }))
);
