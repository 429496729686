import { formatDate } from '@angular/common';
import { AfterViewInit, DestroyRef, Directive, HostBinding, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';

import { ChangesUtils } from '@utils';

/**
 * Set the current button's "disabled" attribute when there is a change on the form value based on the initial from value.
 */
@Directive({ selector: '[adminPristineFormDisabled]' })
export class PristineFormDisabledDirective implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() dateFields: string[];

  @HostBinding('class.mat-button-disabled')
  @HostBinding('class.disabled-button')
  @HostBinding('disabled')
  disabled = true;

  destroyRef = inject(DestroyRef);

  ngAfterViewInit(): void {
    const initialFormValue = this.form.getRawValue();
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const currentFormValue = this.form.getRawValue();
      this.dateFields?.forEach(field => {
        const value = currentFormValue[field];
        if (value) {
          currentFormValue[field] = formatDate(value, 'yyyy-MM-dd', 'en');
        }
      });

      this.disabled = ChangesUtils.isUnchanged(initialFormValue, currentFormValue);
    });
  }
}
