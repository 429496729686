import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ImageUploadResponse } from '@shared/types';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { attributesToEncode, DownloadOffersFilter, Offer, OfferResult, OffersFilter } from '../types';

@Injectable({ providedIn: 'root' })
export class OffersService {
  constructor(private missionControlService: MissionControlService) {}

  getOffers(filter: OffersFilter): Observable<OfferResult> {
    filter = ObjectUtils.sanitizeRequestObject<OffersFilter>(filter);
    const { isFromGlobalView, sortDirection, sortBy, ...rest } = filter;

    let params = ObjectUtils.prepareQueryObject(rest);
    params = FilterUtils.appendHashQueryParam(params, 'sortBy', sortBy, sortDirection);

    return this.missionControlService.get<OfferResult>(
      'offers',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getOffer(id: string): Observable<Offer> {
    return this.missionControlService.get<Offer>(`offers/${id}`);
  }

  createOffer(offer: Offer): Observable<Offer> {
    offer = ObjectUtils.encodeAttributesAsBase64(offer, attributesToEncode);

    return this.missionControlService.post<Offer>('offers', { payload: offer });
  }

  updateOffer(offer: Offer): Observable<Offer> {
    offer = ObjectUtils.encodeAttributesAsBase64(offer, attributesToEncode);

    return this.missionControlService.patch<Offer>(`offers/${offer.id}`, { payload: offer });
  }

  downloadOffers(filter: DownloadOffersFilter): Observable<Blob> {
    filter = ObjectUtils.sanitizeRequestObject<DownloadOffersFilter>(filter);
    return this.missionControlService.get<Blob>(
      'offers/download',
      new HttpParams({ fromObject: { ...ObjectUtils.prepareQueryObject(filter) } }),
      {},
      'blob'
    );
  }

  getUserOffers(filter: OffersFilter): Observable<OfferResult> {
    filter = ObjectUtils.sanitizeRequestObject<OffersFilter>(filter);
    const { isFromGlobalView, sortBy, sortDirection, ...rest } = filter;

    let params = ObjectUtils.prepareQueryObject(rest);
    params = FilterUtils.appendHashQueryParam(params, 'sortBy', sortBy, sortDirection);

    return this.missionControlService.get<OfferResult>(
      `users/${filter.userId}/offers`,
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  uploadOfferImage(file: FormData, brand: string, supplier: string, offerId?: string): Observable<ImageUploadResponse> {
    const optionalParam = offerId ? { offerId } : {};
    return this.missionControlService.post<ImageUploadResponse>(
      'offers/upload_image',
      file,
      new HttpParams({
        fromObject: {
          ...optionalParam,
          brand,
          supplier
        }
      })
    );
  }
}
