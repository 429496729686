import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { Nullable, PropertiesType } from '@shared/types';
import { ObjectUtils, URL_REGEX } from '@utils';

@Component({
  selector: 'admin-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() label: string;
  @Input() copyButtonValue: Nullable<string>;
  @Input() hint: string;
  @Input() data: any;
  @Input() complexValueTitle: string; // used to display title for array of complex items

  readonly ObjectUtils = ObjectUtils;
  readonly decodeURIComponent = decodeURIComponent;
  readonly URL_REGEX = URL_REGEX;

  dataType: string;
  primitiveArrayItems: any[];
  complexArrayItems: any[];
  primitiveObjectEntries: PropertiesType;
  complexObjectEntries: PropertiesType;
  shouldLabelFloat: boolean;

  constructor(private cdRef: ChangeDetectorRef) {}

  @ViewChild('content') set content(content: ElementRef) {
    this.shouldLabelFloat = content?.nativeElement.innerText.trim().length > 0;
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    if (Array.isArray(this.data)) {
      this.dataType = 'array';
      this.primitiveArrayItems = this.data.filter(val => typeof val !== 'object' && !ObjectUtils.isNullish(val));
      this.complexArrayItems = this.data.filter(val => typeof val === 'object');
    } else if (ObjectUtils.isObject(this.data)) {
      this.dataType = 'object';
      this.primitiveObjectEntries = Object.fromEntries(
        Object.entries(this.data).filter(([_, value]) => typeof value !== 'object')
      );
      this.complexObjectEntries = Object.fromEntries(
        Object.entries(this.data).filter(([_, value]) => typeof value === 'object')
      );
    } else {
      this.dataType = 'primitive';
    }
  }
}
