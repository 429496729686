import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState } from '@core/types';

import { MerchandisesFilter } from './merchandises-filter.type';

export interface MerchandisesResult {
  data: Merchandise[];
  metadata: {
    allPages: number[];
    currentPage: number;
    total: number;
    totalPages: number;
  };
}

export interface Merchandise {
  id: string;
  name: string;
  prices: {
    cost: string;
    freight: string;
    recommendedRetailPrice: string;
  };
  categories: string[];
  description: string;
  shortDescription: string;
  maxPurchaseQuantity: number;
  maxDiscountPercentage: number;
  termsAndConditionsUrl: string;
  deliveryMethod: string;
  image: string;
  supplier: string;
  currency: string;
  brand: string;
  sku: string;
  createdAt: string;
  active: boolean;
  approved: boolean;
}

export interface UpdateMerchandiseParams {
  id: string;
  approved?: boolean;
  name?: string;
  description?: string;
  shortDescription?: string;
  categoryIds?: number[];
}

export const merchandiseAdapter = createEntityAdapter<Merchandise>();

export interface MerchandiseState extends EntityState<Merchandise>, CallState, FetchState {
  count: number;
  filter: MerchandisesFilter;
}

export const initialState: MerchandiseState = {
  ...merchandiseAdapter.getInitialState<CallState>(initialCallState),
  ...merchandiseAdapter.getInitialState<FetchState>(initialFetchState),
  count: 0,
  filter: new MerchandisesFilter()
};
