import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { Notification } from '../types/notifications.type';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private missionControlService: MissionControlService) {}

  getNotifications(userId: string): Observable<Notification[]> {
    return this.missionControlService.get<Notification[]>(`users/${userId}/notifications`);
  }
}
