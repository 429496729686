import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import {
  BenefitOrderItemData,
  CashRedemptionOrderItemData,
  CryptoOrderItemData,
  GiftCardOrderItemData,
  MerchandiseOrderItemData,
  PointOfSaleOrderItemData,
  PointsTransferOrderItemData,
  SustainabilityOrderItemData,
  TravelBookingOrderItemData
} from './index';
import { OrderItemsFilter } from './order-items-filter.type';
import { VoucherOrderItemData } from './voucher-order-items.type';

export type OrderItemDataType =
  | BenefitOrderItemData
  | CashRedemptionOrderItemData
  | CryptoOrderItemData
  | GiftCardOrderItemData
  | MerchandiseOrderItemData
  | PointOfSaleOrderItemData
  | PointsTransferOrderItemData
  | SustainabilityOrderItemData
  | TravelBookingOrderItemData
  | VoucherOrderItemData;

export interface OrderItem {
  id: string;
  type: string;
  description?: string;
  userId: string;
  status: string;
  quantity: number;
  cashAmount: number;
  pointsAmount: number;
  cashPaid: number;
  pointsPaid: number;
  orderId?: string;
  data: OrderItemDataType;
  createdAt: string;
  updatedAt: string;
  displayValue?: number;
  supplierReferenceId?: string;
  order?: {
    id: string;
    type: string;
    userId: string;
    status: string;
    cashAmount: number;
    pointsAmount: number;
    cashPaid?: number;
    pointsPaid?: number;
    stripePaymentIntentClientSecret: string;
    fulfillmentStatus: string;
    data: {
      recipient?: Recipient;
    };
    createdAt: string;
    updatedAt: string;
  };
  notifications?: Notification[];
}

export interface OrderItemResult {
  data: OrderItem[];
  meta: {
    count: number;
  };
}

export const TravelCategoryBookingOrderItemTypeCode = {
  FlightBookingOrderItem: 'flight_booking_order_item',
  HotelBookingOrderItem: 'hotel_booking_order_item'
};

export const BaseOrderItemTypeCode = {
  GiftCardOrderItem: 'gift_card_order_item',
  PhysicalGiftCardOrderItem: 'physical_gift_card_order_item',
  PointsTransferOrderItem: 'points_transfer_order_item',
  CashRedemptionOrderItem: 'cash_redemption_order_item',
  MerchandiseOrderItem: 'merchandise_order_item',
  BenefitOrderItem: 'benefit_order_item'
};

export const OrderItemTypeCode = {
  ...BaseOrderItemTypeCode,
  TravelBookingOrderItem: 'travel_booking_order_item'
};

export const OrderItemTypeCodeForFilter = {
  ...BaseOrderItemTypeCode,
  ...TravelCategoryBookingOrderItemTypeCode
};

export const RcSpecificOrderItemTypeCode = {
  CryptoOrderItem: 'crypto_order_item',
  SustainabilityOrderItem: 'sustainability_order_item',
  VoucherOrderItem: 'voucher_order_item',
  PointOfSaleOrderItem: 'point_of_sale_order_item'
};

export const AllOrderItemTypeCodes = {
  ...OrderItemTypeCode,
  ...RcSpecificOrderItemTypeCode
};

export const RcOrderItemTypeCodeForFilter = {
  PointsTransferOrderItem: 'points_transfer_order_item',
  GiftCardOrderItem: 'gift_card_order_item',
  CashRedemptionOrderItem: 'cash_redemption_order_item',
  FlightBookingOrderItem: 'flight_booking_order_item',
  HotelBookingOrderItem: 'hotel_booking_order_item',
  MerchandiseOrderItem: 'merchandise_order_item',
  ...RcSpecificOrderItemTypeCode
};

export enum OrderItemStatus {
  Processing = 'processing',
  Fulfilled = 'fulfilled',
  Failed = 'failed',
  Cancelled = 'cancelled',
  QueuedForProcessing = 'queued_for_processing',
  ProcessingCancellation = 'processing_cancellation'
}

export const orderItemStatusDisplayClass = {
  processing: 'status-pending',
  fulfilled: 'status-active',
  failed: 'status-inactive',
  cancelled: 'status-neutral',
  queued_for_processing: 'status-neutral',
  processing_cancellation: 'status-neutral'
};

export interface Notification {
  id: string;
  type: string;
  eventName: string;
  channel: string;
  delivery: string;
  subject: string;
  createdAt: string;
}

export interface NotificationResponse extends Notification {
  notificationTopic: {
    id: string;
    type: string;
  };
}

export interface RawNotificationResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      eventName: string;
      channel: string;
    };
    relationships: {
      notificationTopic: {
        id: string;
        type: string;
      };
    };
  };
}

export interface FulfillmentData {
  downloadUrl?: string;
  expiresAt?: string;
  fulfillmentUrl?: string;
  receiptCode?: string;
  redemptionUrl?: string;
  shippedStatus?: string;
  trackingId?: string;
  trackingUrl?: string;
  viewUrl?: string;
}

export interface Recipient {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  emailAddress?: string;
  message?: string;
  phoneNumber?: string;
  address?: RecipientAddress;
  email?: string;
  companyName?: string;
}

export interface RecipientAddress {
  line1?: string;
  line2?: string;
  line3?: string;
  fullAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  countryCode?: string;
}

export interface StripeReferenceData {
  stripeReference: string;
  totalCharge: string;
}

export interface VendorAttributes {
  config: {
    basePointMultiplier: number;
    maxPercentageAllowed: number;
    pointPurchaseMarkupFlat: number;
    pointPurchaseMarkupPercentage: number;
    roundTo: number;
  };
  pointsPurchased: number;
  pointsPurchasedCost: number;
}

export const orderItemAdapter = createEntityAdapter<OrderItem>();

export interface OrderItemState extends EntityState<OrderItem>, CallState {
  filter: OrderItemsFilter;
  count: number;
}

export const initialOrderItemState: OrderItemState = {
  ...orderItemAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  count: 0
};
