import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';

import { AdminPanelErrors } from '@core/errors/admin-panel-errors';
import { GuardhouseError } from '@core/errors/guardhouse-error';
import { UserAbilitiesService } from '@core/services/user-abilities/user-abilities.service';
import { CoreState } from '@core/store';
import { loginFail, logoutUser } from '@core/store/auth/actions/auth.actions';
import { ErrorState, HttpErrorDetails } from '@core/types';

import { RollbarService } from '../../../../../services/rollbar/rollbar.service';
import { SENTRY_LOGGER } from '../../../../../tokens/sentry.token';
import { captureGuardhouseException } from '../../../../../utils/sentry-utils';
import {
  loadUserAbilities,
  loadUserAbilitiesFailure,
  loadUserAbilitiesSuccess
} from '../actions/user-abilities.actions';
import { userAbilitiesQuery } from '../selectors/user-abilities.selectors';

@Injectable()
export class UserAbilityEffects {
  private sentryLogger = inject(SENTRY_LOGGER);

  loadUserAbilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserAbilities),
      exhaustMap(() =>
        this.userAbilitiesService.getUserAbilities().pipe(
          map(userAbilities => loadUserAbilitiesSuccess({ userAbilities })),
          catchError(error => of(loadUserAbilitiesFailure({ error })))
        )
      )
    )
  );

  loadUserAbilitiesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserAbilitiesFailure),
      withLatestFrom(this.store.select(userAbilitiesQuery.isLoaded)),
      exhaustMap(([action, loaded]) => {
        this.reportToMonitoringTools(action, AdminPanelErrors.LOAD_USER_ABILITIES_FAIL);
        // Log the user out only if already logged in. Otherwise we handle error separately.
        return loaded ? of(logoutUser()) : of(loginFail());
      })
    )
  );

  constructor(
    private actions$: Actions,
    private rollbarService: RollbarService,
    private userAbilitiesService: UserAbilitiesService,
    private store: Store<CoreState>
  ) {}

  private reportToMonitoringTools(action: ErrorState & Action, defaultError: HttpErrorDetails): void {
    const guardhouseError = new GuardhouseError(action.error.errors?.[0] || defaultError);

    this.rollbarService.manualGuardhouseError(guardhouseError, action);
    captureGuardhouseException(this.sentryLogger.error, { actionType: action.type, guardhouseError });
  }
}
