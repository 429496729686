import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'admin-points-adjustment-confirm-dialog-v2',
  templateUrl: './points-adjustment-confirm-dialog-v2.component.html',
  standalone: true,
  imports: [MatDialogModule]
})
export class PointsAdjustmentConfirmDialogV2Component {
  constructor(
    public dialogRef: MatDialogRef<PointsAdjustmentConfirmDialogV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: { actionLabel: string; statementDescriptorLabel: string }
  ) {}
}
