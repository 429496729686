import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { HttpError } from '@core/types';

import { ImpersonationsService } from '../../services/impersonations.service';
import { impersonate, impersonateFailure, impersonateSuccess } from '../actions/impersonations.actions';

@Injectable()
export class ImpersonationsEffects {
  impersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(impersonate),
      exhaustMap(action =>
        this.impersonationsService.impersonate(action.userId, action.readOnly).pipe(
          map(result => impersonateSuccess({ result })),
          catchError((error: HttpError) => of(impersonateFailure({ error })))
        )
      )
    )
  );

  impersonateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(impersonateSuccess),
        tap(({ result }) => {
          if (this.impersonateInNewTab) {
            // workaround instead of window.open() to make it work in Safari
            const a = document.createElement('a');
            a.target = '_blank';
            a.href = result.redirectUri;
            a.click();
          } else {
            window.location.href = result.redirectUri;
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private impersonationsService: ImpersonationsService,
    @Inject('impersonateInNewTab') private impersonateInNewTab: boolean
  ) {}
}
