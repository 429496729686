<div class="details-view">
  <div class="section-header">General</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Order ID">
      <a
        *adminIfScopesInclude="SCOPES.viewGiftCardOrders; relationOperator: 'or'; else: orderIdWithoutLink"
        routerLink="/gift-cards/orders/{{ orderItem.supplierReferenceId }}"
      >
        {{ orderItem.order.id }}
      </a>
      <ng-template #orderIdWithoutLink>
        {{ orderItem.order.id }}
      </ng-template>
    </admin-item>
    <admin-item label="Item ID">{{ orderItem.id }}</admin-item>
    <admin-item class="cross-two-column" label="Brand">{{ data?.productName || 'N/A' }}</admin-item>
    <admin-item label="Product type">{{ orderItem.type | orderItemType }}</admin-item>
    <admin-item label="Card type">{{ orderItem.type === 'gift_card_order_item' ? 'Digital' : 'Physical' }}</admin-item>
    <admin-item label="Order initiator">{{
      (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A'
    }}</admin-item>
    <admin-item label="Delivery method">{{ ((giftCard$ | async)?.deliveryMethod | titlecase) || 'N/A' }}</admin-item>

    <div class="cross-two-column unpadded-subsection-header">Customer details</div>
    <admin-item label="Customer ID">{{ customerId }}</admin-item>
    <admin-item label="Ascenda ID" [copyButtonValue]="orderItem.userId">
      <a
        *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
        [routerLink]="'/customers/' + orderItem.userId + detailsView"
      >
        {{ orderItem.userId }}
      </a>
      <ng-template #userIdWithoutLink>
        {{ orderItem.userId }}
      </ng-template>
    </admin-item>

    <div class="cross-two-column unpadded-subsection-header">Supplier details</div>
    <admin-item class="cross-two-column" label="Supplier">{{
      ((giftCard$ | async)?.supplier | customTitleCase: 'fromSnakeCase') || 'N/A'
    }}</admin-item>
    <admin-item label="Supplier reference ID" [copyButtonValue]="data.externalSupplierOrderReference">
      {{ data.externalSupplierOrderReference || 'N/A' }}
    </admin-item>
    <admin-item label="Supplier reference item ID" [copyButtonValue]="data.externalSupplierOrderItemReference">
      {{ data.externalSupplierOrderItemReference || 'N/A' }}
    </admin-item>
  </div>

  <div class="section-header">Delivery details</div>
  <div class="section admin-item-wrapper">
    <admin-item label="First name">{{ (recipient?.firstName | titlecase) || 'N/A' }}</admin-item>
    <admin-item label="Last name">{{ (recipient?.lastName | titlecase) || 'N/A' }}</admin-item>
    <admin-item label="Email address">{{ recipient?.emailAddress || recipient?.email || 'N/A' }}</admin-item>
    <admin-item label="Phone number">{{ recipient?.phoneNumber || 'N/A' }}</admin-item>
    <admin-item class="cross-two-column" label="Company">{{ recipient?.companyName || 'N/A' }}</admin-item>
    <admin-item class="cross-two-column" label="Message">{{ recipient?.message || 'N/A' }}</admin-item>
  </div>

  <div class="section-header">Item information</div>
  <div class="section admin-item-wrapper">
    <admin-item class="cross-two-column" label="SKU">
      {{ (giftCard$ | async)?.sku || 'N/A' }}
    </admin-item>
    <div class="sub-section">
      <admin-item [label]="'Card value' + (data?.currency ? ' (' + data.currency + ')' : '')">
        {{ data.displayValue ? (data.displayValue | number: '1.0-2') + ' per card' : 'N/A' }}
      </admin-item>
      <admin-item [label]="'Card price' + (data?.currency ? ' (' + data.currency + ')' : '')">
        {{ data.supplierPrice ? (data.supplierPrice | number: '1.0-2') + ' per card' : 'N/A' }}
      </admin-item>
    </div>
    <admin-item label="Quantity">{{ orderItem.quantity || 'N/A' }}</admin-item>
    <admin-item label="Total charge">
      {{ orderItem.pointsPaid | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
      }}{{ data?.currency === '$' ? '' : ' '
      }}{{ orderItem.cashPaid | formatCurrency: data?.currency | async | number }}
    </admin-item>
    <admin-item label="Original charge">
      {{ orderItem.pointsAmount | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
      }}{{ data?.currency === '$' ? '' : ' '
      }}{{ orderItem.cashAmount | formatCurrency: data?.currency | async | number }}
    </admin-item>
    <admin-item class="cross-two-column" label="Promo code">
      {{ data?.promoCode || 'N/A' }}
    </admin-item>
    <ng-container *ngIf="orderItem.type === 'gift_card_order_item' && data.fulfillmentData?.length">
      <ng-container *ngFor="let card of data.fulfillmentData; let i = index">
        <div class="cross-two-column flex justify-between">
          <div class="items-center unpadded-subsection-header flex">Card {{ i + 1 | number: '2.0-0' }}</div>
          <admin-side-dropdown-menu>
            <button type="button" class="dropdown-button" mat-menu-item (click)="openNewTab(card.viewUrl)">
              <mat-icon svgIcon="view" class="menu-icon"></mat-icon> View card
            </button>
            <button type="button" class="dropdown-button" mat-menu-item (click)="openNewTab(card.downloadUrl)">
              <mat-icon svgIcon="copy" class="menu-icon"></mat-icon> Download card
            </button>
          </admin-side-dropdown-menu>
        </div>
        <admin-item label="Expiry date">
          {{ (card.expiresAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone) || 'N/A' }}
        </admin-item>
        <admin-item label="Receipt number">
          {{ card.receiptCode || 'N/A' }}
        </admin-item>
      </ng-container>
    </ng-container>
  </div>

  <div class="section-header">Stripe reference</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Stripe reference">{{ stripeReferenceData.stripeReference }}</admin-item>
    <admin-item label="Total charge">{{ stripeReferenceData.totalCharge }}</admin-item>
  </div>
</div>

<admin-footer>
  <admin-refund-order-button-v2 [orderItem]="orderItem"></admin-refund-order-button-v2>
  <ng-container *ngIf="orderItem.status === 'processing'">
    <button
      *adminIfScopesInclude="SCOPES.updateOrderItemStatus; relationOperator: 'or'"
      class="primary-button"
      (click)="updateOrderItemStatus()"
    >
      Update status
    </button>
  </ng-container>
  <ng-container *ngIf="orderItem.type === 'gift_card_order_item' && orderItem.notifications.length">
    <button
      *adminIfScopesInclude="SCOPES.createDeliveries; relationOperator: 'or'"
      class="primary-button"
      (click)="openSendNotificationDialog()"
    >
      Resend email
    </button>
  </ng-container>
</admin-footer>
