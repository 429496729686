import { catchError, delay, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';
import { routerNavigate } from '@core/store/router/actions/router.actions';

import { AppConnectionsService } from '../../services/app-connections.service';
import {
  createAppConnection,
  createAppConnectionFailure,
  createAppConnectionSuccess,
  fetchAppConnection,
  fetchAppConnectionFailure,
  fetchAppConnectionSuccess,
  loadAppConnection,
  loadAppConnectionFailure,
  loadAppConnections,
  loadAppConnectionsFailure,
  loadAppConnectionsSuccess,
  loadAppConnectionSuccess,
  updateAppConnection,
  updateAppConnectionFailure,
  updateAppConnectionSigningKeys,
  updateAppConnectionSigningKeysFailure,
  updateAppConnectionSigningKeysSuccess,
  updateAppConnectionSuccess
} from '../actions/app-connections.actions';
import { refreshTokenFailure, resetCerberusConfigFailure } from '../actions/cerberus-configs.actions';

@Injectable()
export class AppConnectionsEffects {
  loadAppConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppConnections),
      exhaustMap(() =>
        this.appConnectionsService.getAppConnections().pipe(
          map(appConnections => loadAppConnectionsSuccess({ appConnections })),
          catchError(error => of(loadAppConnectionsFailure({ error })))
        )
      )
    )
  );

  loadAppConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppConnection),
      exhaustMap(action =>
        this.appConnectionsService.getAppConnection(action.id).pipe(
          map(appConnection => loadAppConnectionSuccess({ appConnection })),
          catchError(error => of(loadAppConnectionFailure({ error })))
        )
      )
    )
  );

  fetchAppConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAppConnection),
      mergeMap(action =>
        this.appConnectionsService.getAppConnection(action.id).pipe(
          map(appConnection => fetchAppConnectionSuccess({ appConnection })),
          catchError(() => of(fetchAppConnectionFailure({ id: action.id })))
        )
      )
    )
  );

  createAppConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAppConnection),
      exhaustMap(action =>
        this.appConnectionsService.createAppConnection(action.appConnection).pipe(
          map(appConnection => createAppConnectionSuccess({ ...action, appConnection })),
          catchError(error => of(createAppConnectionFailure({ error })))
        )
      )
    )
  );

  updateAppConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAppConnection),
      exhaustMap(action =>
        this.appConnectionsService.updateAppConnection(action.appConnection).pipe(
          map(appConnection => updateAppConnectionSuccess({ ...action, appConnection })),
          catchError(error => of(updateAppConnectionFailure({ error })))
        )
      )
    )
  );

  createUpdateAppConnectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAppConnectionSuccess, updateAppConnectionSuccess),
      map(action =>
        routerNavigate({
          path: `apps/${action.mode === 'resource' ? action.appConnection.clientId : action.appConnection.resourceId}`
        })
      )
    )
  );

  createAppConnectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAppConnectionSuccess),
      filter(action => !action.appConnection.configurationReset!.success),
      delay(1000), // to prevent matSnackBar from disappearing after redirecting back to app connection page
      map(action =>
        resetCerberusConfigFailure({
          error: {
            message: '',
            status: null,
            statusText: null,
            headers: null,
            url: null,
            errors: action.appConnection.configurationReset!.errors
          }
        })
      )
    )
  );

  updateAppConnectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAppConnectionSuccess),
      filter(action => !action.appConnection.refreshToken!.success),
      delay(1000), // to prevent matSnackBar from disappearing after redirecting back to app connection page
      map(action =>
        refreshTokenFailure({
          error: {
            message: null,
            status: null,
            statusText: null,
            headers: null,
            url: null,
            errors: action.appConnection.refreshToken!.errors.map(error => ({
              ...error,
              message: 'Refresh token failure - ' + error.message
            }))
          }
        })
      )
    )
  );

  updateAppConnectionSigningKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAppConnectionSigningKeys),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.appConnectionsService.updateSigningKeys(action.id).pipe(
          map(appConnection => updateAppConnectionSigningKeysSuccess({ appConnection })),
          catchError(error => of(updateAppConnectionSigningKeysFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private appConnectionsService: AppConnectionsService,
    private store: Store<any>
  ) {}
}
