<div class="filter-form-wrapper with-hint">
  <form [formGroup]="filterForm" *ngIf="filterForm" (keydown.enter)="$event.preventDefault()">
    <mat-form-field formGroupName="dateRange" class="date-field">
      <mat-label>Date Range</mat-label>
      <mat-datepicker-toggle matPrefix [for]="picker">
        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-date-range-input [rangePicker]="picker">
        <input matInput matStartDate formControlName="dateFrom" placeholder="Start date" (focus)="picker.open()" />
        <input matInput matEndDate formControlName="dateTo" placeholder="End date" (focus)="picker.open()" />
      </mat-date-range-input>
      <div matSuffix>
        <button mat-icon-button (click)="picker.open()">
          <mat-icon matDatepickerToggleIcon svgIcon="arrowdown"></mat-icon>
        </button>
        <ng-container *ngIf="!(dateFrom === null && dateTo === null)">
          <button
            mat-icon-button
            class="close-button"
            (click)="dateRangeControl.reset()"
            [disabled]="!dateFrom && !dateTo"
          >
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
      </div>
    </mat-form-field>

    <mat-form-field *ngIf="filterEntities">
      <mat-icon matPrefix svgIcon="filter"></mat-icon>
      <mat-label>Entity</mat-label>
      <mat-select formControlName="entity" disableOptionCentering>
        <mat-option *ngFor="let entity of filterEntities" [value]="entity">
          {{ entity | customTitleCase: 'fromSnakeCase' }}
        </mat-option>
      </mat-select>
      <admin-input-reset
        matSuffix
        [inputControl]="filterForm.controls.entity"
        [loading]="!!defaultEntity"
      ></admin-input-reset>
    </mat-form-field>

    <mat-form-field>
      <mat-icon matPrefix svgIcon="search"></mat-icon>
      <mat-label>Message action</mat-label>
      <input
        type="text"
        placeholder="Message action"
        aria-label="Message action"
        matInput
        formControlName="action"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let action of filteredActions$ | async" [value]="action">
          {{ action }}
        </mat-option>
      </mat-autocomplete>
      <admin-input-reset matSuffix [inputControl]="filterForm.controls.action" initialValue=""></admin-input-reset>
    </mat-form-field>

    <admin-input-tags-v2
      [tags]="tags"
      dialogTitle="Filter by tag"
      submitButtonText="Add tag to filter"
    ></admin-input-tags-v2>
  </form>

  <div class="filter-controls">
    <admin-clear-form-button [form]="filterForm" (click)="clearFilter()"></admin-clear-form-button>
  </div>

  <div *ngIf="isTopLevelView" class="quick-search">
    <mat-label>Quick search:</mat-label>
    <mat-chip-listbox aria-label="Tags">
      <mat-chip-option *ngFor="let tag of quickSearchTags" (click)="handleQuickSearch(tag.entity, tag.action)">
        {{ tag.label }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>

<div *ngIf="loading$ | async" class="progress-loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<table
  mat-table
  adminStickyHeaderTable
  matSort
  multiTemplateDataRows
  id="logs-table"
  class="multiple-line-tags-padding"
  [dataSource]="logs"
  [class.overlay]="loading$ | async"
  (matSortChange)="onSort($event)"
>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Timestamp</th>
    <td mat-cell *matCellDef="let log" [matTooltip]="'timezone: UTC ' + (timezone | timezoneDisplay)">
      {{ log.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
    </td>
  </ng-container>

  <ng-container matColumnDef="device info">
    <th mat-header-cell *matHeaderCellDef>Device info</th>
    <td mat-cell *matCellDef="let log">
      <div
        *ngIf="log | deviceInfo: 'exists?'; else noDeviceInfo"
        class="device-icons"
        [matTooltip]="(log | deviceInfo: 'userAgent').toString()"
      >
        <span [innerHTML]="log | deviceInfo: 'iconsAndFlag'"></span>
      </div>
      <ng-template #noDeviceInfo> No Device Info </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="entity">
    <th mat-header-cell *matHeaderCellDef>Entity</th>
    <td mat-cell *matCellDef="let log">{{ log.entity | customTitleCase: 'fromSnakeCase' }}</td>
  </ng-container>

  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef>Message</th>
    <td mat-cell *matCellDef="let log">{{ log.message }}</td>
  </ng-container>

  <ng-container matColumnDef="tags">
    <th mat-header-cell *matHeaderCellDef>Tags</th>
    <td mat-cell *matCellDef="let log">
      <mat-chip-listbox aria-label="Tags">
        <mat-chip-option *ngFor="let tag of log.tags" [routerLink]="tag.routeLink" [class]="tag.type">
          {{ tag.displayPrefix }}: {{ tag.displayValue }}
        </mat-chip-option>
      </mat-chip-listbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let log">
      <a [routerLink]="(detailsRouteOnCurrentPath ? '' : '/logs/') + log.id">
        <button mat-icon-button>
          <mat-icon svgIcon="view"></mat-icon>
        </button>
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="changes">
    <th mat-header-cell *matHeaderCellDef>Changes</th>
    <td mat-cell *matCellDef="let log">
      <button *ngIf="log.params.changes as changes" (click)="openChangesDetails(changes)" mat-icon-button>
        <mat-icon svgIcon="view"></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      <admin-empty-state-table></admin-empty-state-table>
    </td>
  </tr>
</table>
<mat-paginator
  *ngIf="!!(count$ | async)"
  tableId="logs-table"
  [disabled]="loading$ | async"
  [pageSizeOptions]="[10, 20, 50]"
  [pageIndex]="filter.page - 1"
  [pageSize]="filter.limit"
  [length]="count$ | async"
  (page)="onPage($event)"
  adminPaginator
></mat-paginator>
