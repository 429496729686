import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Nullable, Tag } from '@shared/types';
import { Params } from '@utils';

import { loadAuditLogs } from '../store/actions/audit-logs.actions';
import { AuditLogsFilter, AuditLogState } from '../types';

export const auditLogsResolver: ResolveFn<Nullable<Tag[]>> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<AuditLogState> = inject(Store<AuditLogState>),
  scopes: Scopes = inject(Scopes)
): Nullable<Tag[]> => {
  if (scopes.lackScopes(SCOPES_OR.viewAuditLogs)) {
    return null;
  }

  const { tagType, tagIdKey, entity } = route.data;
  const paramValue = Params.find(route, tagIdKey);
  const tags = [{ type: tagType, id: paramValue }];

  const filter = new AuditLogsFilter();
  if (tagType && tagIdKey) {
    filter.tags = tags;
  }
  if (entity) {
    filter.entity = entity;
  }

  store.dispatch(loadAuditLogs({ filter }));

  return tags;
};
