import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { CurrencyConversionService } from '../../services/currency-conversion.service';
import {
  convertCurrency,
  convertCurrencyFailure,
  convertCurrencySuccess
} from '../actions/currency-conversion.actions';

@Injectable()
export class CurrencyConversionEffects {
  convertCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(convertCurrency),
      exhaustMap(action =>
        this.currencyConversionService.convertCurrency(action.currencyConversionData).pipe(
          map(result => convertCurrencySuccess({ result })),
          catchError(error => of(convertCurrencyFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private currencyConversionService: CurrencyConversionService
  ) {}
}
