<mat-card appearance="outlined" class="mat-elevation-z1 points-expiring-card" [class.overlay]="loading" [class.with-error]="error">
  <ng-container [ngSwitch]="renderState">
    <admin-spinner *ngSwitchCase="'loading'"></admin-spinner>
    <div *ngSwitchCase="'error'" fxLayout="row" fxLayoutAlign="center center" fxFlex>
      <div>
        <h4><mat-icon [inline]="true">warning</mat-icon> Loading points summary failed</h4>
      </div>
    </div>
    <ng-container *ngSwitchCase="'content'">
      <div
        *ngIf="pointsSummaryTranches === null; else displayContent"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxFlex
      >
        <h4>
          <mat-icon [inline]="true">warning</mat-icon> Customer does not have points accounts loaded in the system.
          Please wait until customer accounts are loaded via files or contact tech support.
        </h4>
      </div>
      <ng-template #displayContent>
        <mat-card-content class="points-expiring-card__content">
          <mat-card-title class="points-expiring-card__title">Points expiring</mat-card-title>
          <table mat-table matSort class="data-table points-expiring-table" [dataSource]="pointsExpiryData">
            <ng-container matColumnDef="expiryDate">
              <th mat-header-cell *matHeaderCellDef class="expiry-date-col">
                <h4 class="text-primary">Date</h4>
              </th>
              <td mat-cell *matCellDef="let data" class="expiry-date-col">
                {{ data.expiryDate }}
              </td>
            </ng-container>

            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef class="balance-column">
                <h4 class="text-primary">{{ loyaltyCurrency | titlecase }} expiring</h4>
              </th>
              <td mat-cell *matCellDef="let data" class="balance-column">
                {{ data.balance | roundedNumber: pointsActivityDecimals }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns" class="bg-secondary"></tr>
            <tr mat-row *matRowDef="let row; columns: columns" class="data-table__row"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
        </mat-card-content>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-card>
