import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { SegmentMember, SegmentMembersFilter, SegmentMembersResponse, SegmentMemberStatus } from '../types';

@Injectable({
  providedIn: 'root'
})
export class SegmentMembersService {
  private missionControlService = inject(MissionControlService);

  getSegmentMembers(segmentId: string, segmentMembersFilter: SegmentMembersFilter): Observable<SegmentMembersResponse> {
    const { partnerUserId, sortBy, sortDirection, ...requestParams } = segmentMembersFilter;
    const sanitizedParams = ObjectUtils.sanitizeRequestObject(requestParams);

    // LD uses sortOrder currently we will keep this filter here so that AP will be forward compatible
    const filterParams = FilterUtils.appendHashQueryParam(sanitizedParams, 'sort[]', sortBy, sortDirection);

    // LD takes in array for potential future extension
    if (partnerUserId) {
      filterParams['partnerUserId[]'] = partnerUserId;
    }

    return this.missionControlService.get<SegmentMembersResponse>(
      `segments/${segmentId}/members`,
      new HttpParams({ fromObject: { ...filterParams, sortOrder: sortDirection }, encoder: new BracketParamsEncoder() })
    );
  }

  addSegmentMember(segmentId: string, userId: string): Observable<SegmentMember> {
    return this.missionControlService.post<SegmentMember>(`segments/${segmentId}/members`, {
      userId
    });
  }

  updateSegmentMember(segmentId: string, userId: string, status: SegmentMemberStatus): Observable<SegmentMember> {
    return this.missionControlService.patch<SegmentMember>(`segments/${segmentId}/members/${userId}`, {
      status
    });
  }
}
