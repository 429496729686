import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { Formatters, X_MULTIPLE_LOCALES_HEADER } from '@utils';

import {
  LoyaltyProgram,
  LoyaltyProgramRawResponse,
  LoyaltyProgramsRawResponse,
  LoyaltyProgramsResult
} from '../../loyalty-programs/types';
import { LoyaltyProgramHelper } from '../../loyalty-programs/utils/loyalty-program-helper';
import { UpdateCategorisedLoyaltyProgramsPrioritiesRawResponse } from '../types/categorised-loyalty-programs-result.type';
import {
  CategorisedLoyaltyProgramsPriorities,
  CategorisedLoyaltyProgramType
} from '../types/categorised-loyalty-programs.type';

@Injectable({ providedIn: 'root' })
export class CategorisedLoyaltyProgramsService {
  constructor(
    private missionControlService: MissionControlService,
    private loyaltyProgramsHelper: LoyaltyProgramHelper
  ) {}

  getCategorisedLoyaltyPrograms(loyaltyProgramType: CategorisedLoyaltyProgramType): Observable<LoyaltyProgramsResult> {
    return this.missionControlService
      .get<LoyaltyProgramsRawResponse>(`categorised_loyalty_programs/${this.formatType(loyaltyProgramType)}`)
      .pipe(map(rawResponse => this.loyaltyProgramsHelper.mergeLoyaltyProgramsAttributes(rawResponse)));
  }

  getCategorisedLoyaltyProgram(
    loyaltyProgramType: CategorisedLoyaltyProgramType,
    id: string
  ): Observable<LoyaltyProgram> {
    return this.missionControlService
      .get<LoyaltyProgramRawResponse>(
        `categorised_loyalty_programs/${this.formatType(loyaltyProgramType)}/${id}`,
        null,
        X_MULTIPLE_LOCALES_HEADER
      )
      .pipe(
        map(rawResponse =>
          this.loyaltyProgramsHelper.mergeLoyaltyProgramAttributes(rawResponse.included, rawResponse.data)
        )
      );
  }

  updateCategorisedLoyaltyProgram(
    loyaltyProgramType: CategorisedLoyaltyProgramType,
    params: LoyaltyProgram
  ): Observable<void> {
    const { id, ...payload } = params;
    return this.missionControlService.patch<void>(
      `categorised_loyalty_programs/${this.formatType(loyaltyProgramType)}/${id}`,
      payload
    );
  }

  updateCategorisedLoyaltyProgramsPriorities(
    loyaltyProgramType: CategorisedLoyaltyProgramType,
    priorities: CategorisedLoyaltyProgramsPriorities
  ): Observable<UpdateCategorisedLoyaltyProgramsPrioritiesRawResponse> {
    return this.missionControlService.patch<UpdateCategorisedLoyaltyProgramsPrioritiesRawResponse>(
      `categorised_loyalty_programs/${this.formatType(loyaltyProgramType)}/capabilities/bulk`,
      priorities
    );
  }

  synchroniseLoyaltyPrograms(tenantName: string): Observable<void> {
    return this.missionControlService.put<void>('loyalty_programs_synchronisation', { createNew: true, tenantName });
  }

  private formatType(type: CategorisedLoyaltyProgramType): string {
    return Formatters.fromCamelToSnakeCase(type);
  }
}
