import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';

import { ApiSettingsService } from '../../services/api-settings.service';
import { ApiSettingsState } from '../../types';
import { loadApiSettings, loadApiSettingsFailure, loadApiSettingsSuccess } from '../actions/api-settings.actions';

@Injectable()
export class ApiSettingsEffects {
  loadApiSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApiSettings),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(() =>
        this.apiSettingsService.getApiSettings().pipe(
          map(apiSettings => loadApiSettingsSuccess({ apiSettings })),
          catchError(error => of(loadApiSettingsFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiSettingsService: ApiSettingsService,
    private store: Store<ApiSettingsState>
  ) {}
}
