<div class="overflow-auto" [style.height]="tableHeight">
  <table cdk-table [dataSource]="data">
    @for (columnDef of columnDefs; track columnDef.key; let i = $index) {
      <ng-container [cdkColumnDef]="columnDef.key">
        <th cdk-header-cell class="h-14 text-nowrap text-left" *cdkHeaderCellDef>
          {{ columnDef.key === 'actions' ? '' : columnDef.name }}

          @if (columnDef.sortable && columnDef.key !== 'actions') {
            <mat-icon
              class="sort-icon"
              [svgIcon]="
                sortState.active === columnDef.key
                  ? sortState.direction === 'asc'
                    ? 'sort-ascending'
                    : 'sort-descending'
                  : 'sort-default'
              "
              (click)="handleSortClick(columnDef.key)"
            >
            </mat-icon>
          }
        </th>
        <td cdk-cell class="h-14" *cdkCellDef="let row">
          <admin-column [columnDef]="columnDef" [row]="row" />
        </td>
      </ng-container>
    }

    <tr cdk-header-row *cdkHeaderRowDef="columnDefKeys"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: columnDefKeys"></tr>
    <tr class="cdk-row" *matNoDataRow>
      <td class="cdk-cell" [attr.colspan]="columnDefs.length">No {{ entity }} available</td>
    </tr>
  </table>
</div>
