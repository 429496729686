import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { AuthenticationProvidersService } from '../../services/authentication-providers.service';
import {
  createAuthenticationProvider,
  createAuthenticationProviderFailure,
  createAuthenticationProviderSuccess,
  fetchAuthenticationProvider,
  fetchAuthenticationProviderFailure,
  loadAuthenticationProvider,
  loadAuthenticationProviderFailure,
  loadAuthenticationProviders,
  loadAuthenticationProvidersFailure,
  loadAuthenticationProvidersSuccess,
  loadAuthenticationProviderSuccess,
  updateAuthenticationProvider,
  updateAuthenticationProviderFailure,
  updateAuthenticationProviderSuccess
} from '../actions/authentication-providers.actions';

@Injectable()
export class AuthenticationProvidersEffects {
  loadAuthenticationProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuthenticationProviders),
      exhaustMap(() =>
        this.authenticationProvidersService.getAuthenticationProviders().pipe(
          map(authenticationProviders => loadAuthenticationProvidersSuccess({ authenticationProviders })),
          catchError(error => of(loadAuthenticationProvidersFailure({ error })))
        )
      )
    )
  );

  loadAuthenticationProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuthenticationProvider),
      exhaustMap(action =>
        this.authenticationProvidersService.getAuthenticationProvider(action.reference).pipe(
          map(authenticationProvider => loadAuthenticationProviderSuccess({ authenticationProvider })),
          catchError(error => of(loadAuthenticationProviderFailure({ error })))
        )
      )
    )
  );

  fetchAuthenticationProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAuthenticationProvider),
      mergeMap(action =>
        this.authenticationProvidersService.getAuthenticationProvider(action.reference).pipe(
          map(authenticationProvider => loadAuthenticationProviderSuccess({ authenticationProvider })),
          catchError(() => of(fetchAuthenticationProviderFailure({ reference: action.reference })))
        )
      )
    )
  );

  createAuthenticationProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAuthenticationProvider),
      exhaustMap(action =>
        this.authenticationProvidersService.createAuthenticationProvider(action.authenticationProvider).pipe(
          map(authenticationProvider => createAuthenticationProviderSuccess({ authenticationProvider })),
          catchError(error => of(createAuthenticationProviderFailure({ error })))
        )
      )
    )
  );

  updateAuthenticationProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAuthenticationProvider),
      exhaustMap(action =>
        this.authenticationProvidersService.updateAuthenticationProvider(action.authenticationProvider).pipe(
          map(authenticationProvider => updateAuthenticationProviderSuccess({ authenticationProvider })),
          catchError(error => of(updateAuthenticationProviderFailure({ error })))
        )
      )
    )
  );

  createOrUpdateAuthenticationProviderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAuthenticationProviderSuccess, updateAuthenticationProviderSuccess),
      map(() => routerNavigate({ path: 'authentication-providers' }))
    )
  );

  constructor(
    private actions$: Actions,
    private authenticationProvidersService: AuthenticationProvidersService
  ) {}
}
