import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import deepmerge from 'deepmerge';
import { BehaviorSubject } from 'rxjs';

import { CoreState } from '@core/store';
import { userAbilitiesQuery } from '@core/store/user-abilities/selectors/user-abilities.selectors';
import { AbilityGroups, AccessPolicySettings, AdminPanelAccessPolicyTarget, AttributesSettings } from '@core/types';

import { AccessPolicy } from '../../../access-policies/types/access-policies.type';

@Injectable()
export class AccessPolicies extends BehaviorSubject<AbilityGroups> {
  constructor(private store: Store<CoreState>) {
    // Initialize with empty object, then read the value loaded into the user abilities store.
    super({});
    this.store.select(userAbilitiesQuery.getUserAbilities).subscribe(this);
  }

  getResponseSettings(controller: string, action: string): AttributesSettings {
    return this.getMergedPolicySettings(controller, action, 'response', 'attributes');
  }

  getRequestValidationSettings(controller: string, action: string): AccessPolicySettings {
    const accessPolicies = this.getForGroup(controller, action, 'requestValidation');

    if (accessPolicies.length > 0) {
      // Abilities are ordered descending by priority within their groups
      // We're considering only the highest priority ability for now
      return accessPolicies[0].settings;
    } else {
      return null;
    }
  }

  getFrontendSettings(controller: string, action: string): AttributesSettings {
    return this.getMergedPolicySettings(controller, action, 'frontend');
  }

  private getMergedPolicySettings(
    controller: string,
    action: string,
    target: AdminPanelAccessPolicyTarget,
    settingsKey?: string
  ): AttributesSettings {
    const accessPolicies = this.getForGroup(controller, action, target);

    if (accessPolicies.length > 0) {
      return this.mergeSettings(accessPolicies, settingsKey);
    } else {
      return null;
    }
  }

  private mergeSettings(accessPolicies: AccessPolicy[], settingsKey: string): AttributesSettings {
    return accessPolicies
      .map(policy => (settingsKey ? policy.settings[settingsKey] : policy.settings))
      .reduce(
        (prev, cur) =>
          deepmerge(prev, cur, {
            customMerge: (key: string) => {
              if (prev[key] === true) {
                // if previous setting's attributes are allowed as a whole, the choose it over the current one
                return (allowedPrevious, _anythingCurrent) => allowedPrevious;
              }
            }
          }),
        {}
      );
  }

  private getForGroup(controller: string, action: string, target: AdminPanelAccessPolicyTarget): AccessPolicy[] {
    const groupKey = this.makeGroupKey([controller, action, target]);
    const abilities = this.value[groupKey];

    return (abilities || []).filter(ability => !ability.approvalRequired).map(ability => ability.accessPolicy);
  }

  private makeGroupKey(keys: string[]): string {
    return keys.join('+');
  }
}
