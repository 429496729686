import { Directive, Input, OnChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { Nullable } from '@shared/types';

@Directive({
  selector: '[adminMustBeAfterStartDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MustBeAfterStartDateValidatorDirective,
      multi: true
    }
  ]
})
export class MustBeAfterStartDateValidatorDirective implements OnChanges, Validator {
  @Input({ required: true }) startTime: string;

  onValidatorChange?: () => void;

  ngOnChanges(): void {
    this.onValidatorChange?.();
  }

  validate(control: AbstractControl<string>): Nullable<ValidationErrors> {
    if (!control.value) {
      return null;
    }

    const startTimeAsDate = new Date(this.startTime);
    const endTimeAsDate = new Date(control.value);

    if (startTimeAsDate.getTime() >= endTimeAsDate.getTime()) {
      return { mustBeAfterStartDate: true };
    }

    return null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}
