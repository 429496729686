import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { AccessPolicy } from '@core/types';
import { ObjectUtils } from '@utils';

@Injectable({
  providedIn: 'root'
})
export class AccessPoliciesService {
  constructor(private missionControlService: MissionControlService) {}

  getAccessPolicies(): Observable<AccessPolicy[]> {
    return this.missionControlService.get<AccessPolicy[]>('access_policies');
  }

  getAccessPolicy(id: string): Observable<AccessPolicy> {
    return this.missionControlService.get<AccessPolicy>(`access_policies/${id}`);
  }

  createAccessPolicy(accessPolicy: AccessPolicy): Observable<AccessPolicy> {
    accessPolicy = ObjectUtils.sanitizeRequestObject<AccessPolicy>(accessPolicy, { ignoredKeys: ['settings'] });

    return this.missionControlService.post<AccessPolicy>('access_policies', accessPolicy);
  }

  updateAccessPolicy(accessPolicy: AccessPolicy): Observable<AccessPolicy> {
    accessPolicy = ObjectUtils.sanitizeRequestObject<AccessPolicy>(accessPolicy, { ignoredKeys: ['settings'] });
    return this.missionControlService.patch<AccessPolicy>(`access_policies/${accessPolicy.id}`, accessPolicy);
  }

  deleteAccessPolicy(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`access_policies/${id}`);
  }
}
