import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { SegmentMember, SegmentMembersFilter, SegmentMemberStatus } from '../../types';

export const loadSegmentMembers = createAction(
  '[Segments] Load Segment Members',
  props<{ segmentId: string; filter: SegmentMembersFilter }>()
);
export const loadSegmentMembersSuccess = createAction(
  '[Segments] Load Segment Members Success',
  props<{ members: SegmentMember[]; total: number }>()
);
export const loadSegmentMembersFailure = createAction('[Segments] Load Segment Members Failure', props<ErrorState>());

export const addSegmentMember = createAction(
  '[Segments] Add Segment Member',
  props<{ segmentId: string; userId: string }>()
);
export const addSegmentMemberSuccess = createAction(
  '[Segments] Add Segment Member Success',
  props<{ segmentMember: SegmentMember }>()
);
export const addSegmentMemberFailure = createAction('[Segments] Add Segment Member Failure', props<ErrorState>());

export const updateSegmentMember = createAction(
  '[Segments] Update Segment Member',
  props<{ segmentId: string; userId: string; status: SegmentMemberStatus }>()
);
export const updateSegmentMemberSuccess = createAction(
  '[Segments] Update Segment Member Success',
  props<{ segmentMember: SegmentMember }>()
);
export const updateSegmentMemberFailure = createAction('[Segments] Update Segment Member Failure', props<ErrorState>());
