import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HermesError, RefreshTokenExchangeResult } from '@kaligo/hermes';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, from, merge, of } from 'rxjs';

import { ApprovalRequestedDialogComponent } from '@core/containers/approval-requested-dialog/approval-requested-dialog.component';
import { NoPermissionDialogComponent } from '@core/containers/no-permission-dialog/no-permission-dialog.component';
import { SessionExpiredDialogComponent } from '@core/containers/session-expired-dialog/session-expired-dialog.component';
import { StepUpDialogComponent } from '@core/containers/step-up-dialog/step-up-dialog.component';
import { AdminPanelErrors } from '@core/errors/admin-panel-errors';
import { GuardhouseErrorCodes, HermesErrorCodes } from '@core/errors/error-codes.type';
import { GuardhouseError } from '@core/errors/guardhouse-error';
import { GuardhouseService } from '@core/services/guardhouse/guardhouse.service';
import { HermesService, TransactionAppState } from '@core/services/hermes/hermes.service';
import { HttpErrorDetails } from '@core/types';

import { AmplitudeService } from '../../../../../services/amplitude/amplitude.service';
import { RollbarService } from '../../../../../services/rollbar/rollbar.service';
import { SENTRY_LOGGER } from '../../../../../tokens/sentry.token';
import { captureGuardhouseException } from '../../../../../utils/sentry-utils';
import {
  approvalRequestedFail,
  handleLoginRedirect,
  initiateStepUp,
  insufficientLevelFail,
  loadTokenFail,
  loadTokenOtherFail,
  loadTokenSuccess,
  loginFail,
  loginUser,
  logoutUser,
  logoutUserComplete,
  noPermissionFail,
  sessionCheckFail,
  sessionCheckOtherFail,
  sessionExpiredFail,
  startIdleCheck,
  startSessionCheck,
  startTokenRefresh
} from '../actions/auth.actions';
import { AuthState } from '../reducers/auth.reducer';
import { authQuery } from '../selectors/auth.selectors';

@Injectable()
export class AuthEffects {
  private sentryLogger = inject(SENTRY_LOGGER);
  private amplitudeService = inject(AmplitudeService);

  handleLoginRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleLoginRedirect),
      exhaustMap(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('redirect_required') === 'true') {
          this.guardhouseService.redirectToLoginUrl();
          return EMPTY;
        } else {
          return from(this.hermesService.handleRedirect()).pipe(
            map(transactionAppState => startTokenRefresh({ transactionAppState })),
            catchError(error => of(loadTokenFail(error)))
          );
        }
      })
    )
  );

  startTokenRefresh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startTokenRefresh),
        tap(({ transactionAppState }) => this.hermesService.startTokenRefresh(transactionAppState))
      ),
    { dispatch: false }
  );

  loadTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTokenSuccess),
      withLatestFrom(
        this.store.select(authQuery.getIsIdleCheckRunning),
        this.store.select(authQuery.getIsSessionCheckRunning)
      ),
      exhaustMap(([{ tokenResult, transactionAppState }, idleCheckRunning, sessionCheckRunning]) =>
        merge(this.getLoginActions(tokenResult, transactionAppState, idleCheckRunning, sessionCheckRunning))
      )
    )
  );

  loginUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginUser),
        tap(() => this.amplitudeService.track('User logged in'))
      ),
    { dispatch: false }
  );

  loadTokenFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTokenFail),
      withLatestFrom(this.store.select(authQuery.getIsLoggedIn)),
      map(([{ error }, isLoggedIn]) => {
        switch (error.code) {
          case HermesErrorCodes.MISSING_REFRESH_TOKEN:
          case HermesErrorCodes.INVALID_TOKEN: {
            return isLoggedIn ? loadTokenOtherFail({ error }) : logoutUser();
          }
          case GuardhouseErrorCodes.SESSION_EXPIRED: {
            return isLoggedIn ? sessionExpiredFail({ error }) : logoutUser();
          }
          case GuardhouseErrorCodes.UNAUTHENTICATED_APP: {
            return logoutUser();
          }
          default: {
            return loadTokenOtherFail({ error });
          }
        }
      })
    )
  );

  startSessionCheck$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startSessionCheck),
        tap(() => this.hermesService.startSessionCheck())
      ),
    { dispatch: false }
  );

  sessionCheckFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionCheckFail),
      map(({ error }) => {
        switch (error.code) {
          case GuardhouseErrorCodes.SESSION_EXPIRED: {
            return sessionExpiredFail({ error });
          }
          default: {
            return sessionCheckOtherFail({ error });
          }
        }
      })
    )
  );

  startIdleCheck$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startIdleCheck),
        tap(() => this.hermesService.startIdleCheck())
      ),
    { dispatch: false }
  );

  tokenOrSessionOtherFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTokenOtherFail, sessionCheckOtherFail),
      withLatestFrom(this.store.select(authQuery.getIsLoggedIn)),
      exhaustMap(([action, isLoggedIn]) => {
        this.reportToMonitoringTools(action, AdminPanelErrors.TOKEN_OR_SESSION_OTHER_FAIL);
        // Log the user out if already logged in. Otherwise we handle error separately.
        return isLoggedIn ? of(logoutUser()) : of(loginFail());
      })
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutUser),
      withLatestFrom(this.store.select(authQuery.getIsLoggedIn)),
      tap(([{ reason }, isLoggedIn]) => {
        this.hermesService.stopSessionCheck();
        this.hermesService.stopTokenRefresh();

        if (isLoggedIn) {
          this.guardhouseService.redirectToLogoutUrl(reason);
        }
      }),
      map(() => logoutUserComplete())
    )
  );

  noPermissionFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(noPermissionFail),
        tap(() => this.dialog.open(NoPermissionDialogComponent))
      ),
    { dispatch: false }
  );

  insufficientLevelFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insufficientLevelFail),
        tap(({ error, stepUpAction }) => this.dialog.open(StepUpDialogComponent, { data: { error, stepUpAction } }))
      ),
    { dispatch: false }
  );

  sessionExpiredFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sessionExpiredFail),
        tap(action => this.dialog.open(SessionExpiredDialogComponent, { data: action.error }))
      ),
    { dispatch: false }
  );

  approvalRequestedFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(approvalRequestedFail),
        tap(action =>
          this.dialog.open(ApprovalRequestedDialogComponent, {
            data: { approvalRequestedAction: action.approvalRequestedAction }
          })
        )
      ),
    { dispatch: false }
  );

  initiateStepUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateStepUp),
        tap(({ error, stepUpAction }) => this.guardhouseService.redirectToStepUpUrl(error, stepUpAction))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private hermesService: HermesService,
    private rollbarService: RollbarService,
    private guardhouseService: GuardhouseService,
    private store: Store<AuthState>,
    private dialog: MatDialog,
    private router: Router
  ) {}

  private getLoginActions(
    tokenResult: RefreshTokenExchangeResult,
    transactionAppState: TransactionAppState,
    isIdleCheckRunning: boolean,
    isSessionCheckRunning: boolean
  ): Action[] {
    const user = this.hermesService.parseDecodedTokens(tokenResult.decodedIdToken, tokenResult.decodedAccessToken);
    const actions = [loginUser({ user })] as Action[];
    const appState = this.restoreAppState(transactionAppState);

    if (appState?.stepUpAction && user.acr === appState?.acr) {
      actions.push(appState.stepUpAction);
    }
    if (!isIdleCheckRunning) {
      actions.push(startIdleCheck());
    }
    if (!isSessionCheckRunning) {
      actions.push(startSessionCheck());
    }
    return actions;
  }

  private reportToMonitoringTools(action: Action & { error: HermesError }, defaultError: HttpErrorDetails): void {
    const guardhouseError = new GuardhouseError(action.error || defaultError);

    this.rollbarService.manualGuardhouseError(guardhouseError, action);
    captureGuardhouseException(this.sentryLogger.error, { actionType: action.type, guardhouseError });
  }

  private restoreAppState(appState: TransactionAppState): TransactionAppState {
    if (!appState) {
      return null;
    }

    if (appState.currentUrl) {
      this.router.navigateByUrl(appState.currentUrl);
    }
    if (appState.isAscendaLogin) {
      localStorage.setItem('isAscendaLogin', 'true');
    }
    if (appState.stepUpAction) {
      return appState;
    }
  }
}
