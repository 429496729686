import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, RewardsProgramState } from '../../types';
import {
  updateRewardsProgram,
  updateRewardsProgramFailure,
  updateRewardsProgramSuccess
} from '../actions/rewards-program.actions';

export const rewardsProgramTriggers: EntityTriggers = {
  single: {
    loading: [updateRewardsProgram.type],
    resting: [updateRewardsProgramSuccess.type],
    erroring: [updateRewardsProgramFailure.type]
  }
};

export function rewardsProgramReducer(state: RewardsProgramState = initialState, action: Action): RewardsProgramState {
  return callStateReducer(baseReducer, rewardsProgramTriggers)(state, action);
}

export const baseReducer = createReducer(
  initialState,
  on(updateRewardsProgramSuccess, (state, { rewardsProgram }) => ({ ...state, rewardsProgram }))
);
