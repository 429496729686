import { Injectable } from '@angular/core';
import merger from 'json-schema-merge-allof';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { FraudRuleSchema, RawFraudRuleSchemas } from '../types/fraud-rule-schemas.type';

@Injectable({
  providedIn: 'root'
})
export class FraudRuleSchemasService {
  constructor(private missionControlService: MissionControlService) {}

  getFraudRuleSchemas(): Observable<FraudRuleSchema[]> {
    return this.missionControlService
      .get<RawFraudRuleSchemas>('fraud_rule_schemas')
      .pipe(map((data: RawFraudRuleSchemas) => this.formatFraudRuleSchemas(data)));
  }

  private formatFraudRuleSchemas(rawData: RawFraudRuleSchemas): FraudRuleSchema[] {
    return Object.entries(rawData.fraudRuleSchema.components.schemas).map(([key, value]) => ({
      id: key,
      schema: merger(value)
    }));
  }
}
