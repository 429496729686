import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { loadLoyaltyPrograms } from '../store/actions/loyalty-programs.actions';
import { loyaltyProgramsQuery } from '../store/selectors/loyalty-programs.selectors';
import { LoyaltyProgramsFilter, LoyaltyProgramState } from '../types';

export const loyaltyProgramsResolver: ResolveFn<LoyaltyProgramsFilter> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<LoyaltyProgramState> = inject(Store<LoyaltyProgramState>),
  scopes: Scopes = inject(Scopes)
): Observable<LoyaltyProgramsFilter> => {
  // adding manage_partner_configs scope for fetching data on homepage editor page
  if (scopes.lackScopes([...SCOPES_OR.viewLoyaltyPrograms, 'manage_partner_configs'])) {
    return of(null);
  }

  return combineLatest([
    store.select(loyaltyProgramsQuery.isLoyaltyProgramsLoaded),
    store.select(loyaltyProgramsQuery.getSingleLocale),
    store.select(loyaltyProgramsQuery.getBatchLocale),
    store.select(loyaltyProgramsQuery.getFilter)
  ]).pipe(
    first(),
    map(([isLoaded, singleLocale, batchLocale, filter]) => {
      if (!isLoaded || (singleLocale && singleLocale !== batchLocale)) {
        store.dispatch(loadLoyaltyPrograms({ locale: batchLocale }));
      }
      return filter || new LoyaltyProgramsFilter();
    })
  );
};
