import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils, uuidv4 } from '@utils';

import { EarnRule, EarnRuleResult, EarnRulesPriorityResult, EarnRulesResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class EventEarnRulesService {
  constructor(private missionControlService: MissionControlService) {}

  getEventEarnRules(): Observable<EarnRulesResult> {
    return this.missionControlService.get<EarnRulesResult>('event_earn_rules');
  }

  getEventEarnRule(groupId: string): Observable<EarnRuleResult> {
    return this.missionControlService.get<EarnRuleResult>(`event_earn_rules/${groupId}`);
  }

  createEventEarnRule(earnRule: EarnRule): Observable<EarnRule> {
    earnRule = ObjectUtils.sanitizeRequestObject<EarnRule>(earnRule);
    earnRule.groupId = uuidv4(); // TODO remove when LE handles creation of groupId

    return this.missionControlService.post<EarnRule>('event_earn_rules', earnRule);
  }

  updateEventEarnRulesPriorities(result: EarnRulesPriorityResult): Observable<EarnRulesResult> {
    return this.missionControlService.post<EarnRulesResult>('event_earn_rules/update_priority', result);
  }

  updateEventEarnRule(earnRule: Partial<EarnRule>, version: number): Observable<EarnRuleResult> {
    return this.missionControlService.patch<EarnRuleResult>(`event_earn_rules/${earnRule.groupId}`, {
      earnRule,
      version
    });
  }

  deactivateEventEarnRule(groupId: string, version: number): Observable<EarnRuleResult> {
    return this.missionControlService.post<EarnRuleResult>(`event_earn_rules/${groupId}/deactivate`, { version });
  }
}
