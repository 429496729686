import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { recommendedSegmentInitialState, recommendedSegmentAdapter, RecommendedSegmentState } from '../../types';
import {
  loadRecommendedSegments,
  loadRecommendedSegmentsFailure,
  loadRecommendedSegmentsSuccess
} from '../actions/recommendations.actions';

export const recommendedSegmentTriggers: EntityTriggers = {
  batch: {
    loading: [loadRecommendedSegments.type],
    resting: [loadRecommendedSegmentsSuccess.type],
    erroring: [loadRecommendedSegmentsFailure.type]
  }
};

export function recommendedSegmentsReducer(
  state: RecommendedSegmentState = recommendedSegmentInitialState,
  action: Action
): RecommendedSegmentState {
  return callStateReducer(baseReducer, recommendedSegmentTriggers)(state, action);
}

const baseReducer = createReducer(
  recommendedSegmentInitialState,
  on(loadRecommendedSegmentsSuccess, (state, { recommendations }) =>
    recommendedSegmentAdapter.setAll(recommendations, state)
  )
);
