import { HermesError } from '@kaligo/hermes';

import { HttpErrorDetails } from '../types';

export class GuardhouseError extends Error {
  constructor(public error: HermesError | HttpErrorDetails) {
    super(`${error.code} ${error.message}`);
    this.name = 'GuardhouseError'; // prevents sending a minified class name to Rollbar
  }

  get fingerprint(): string {
    return this.error.code;
  }
}
