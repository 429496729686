import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { configurationChangeAdapter, configurationChangeInitialState, ConfigurationChangeState } from '../../types';
import {
  loadConfigurationChanges,
  loadConfigurationChangesFailure,
  loadConfigurationChangesSuccess
} from '../actions/configuration-changes.actions';

export const configurationChangeTriggers: EntityTriggers = {
  batch: {
    loading: [loadConfigurationChanges.type],
    resting: [loadConfigurationChangesSuccess.type],
    erroring: [loadConfigurationChangesFailure.type]
  }
};

export function configurationChangesReducer(
  state: ConfigurationChangeState = configurationChangeInitialState,
  action: Action
): ConfigurationChangeState {
  return callStateReducer(baseReducer, configurationChangeTriggers)(state, action);
}

const baseReducer = createReducer(
  configurationChangeInitialState,
  on(loadConfigurationChangesSuccess, (state, { configurationChangesResult }) => ({
    ...configurationChangeAdapter.setAll(configurationChangesResult.data, state),
    count: configurationChangesResult.metadata.total
  }))
);
