import { DateRange } from '@core/types';

export class LoginAttemptsFilter {
  limit: number;
  page: number;
  sessionId?: string;
  userId?: string;
  createdAt?: DateRange;
  to?: string;
  sortBy?: string;
  sortDirection?: string;
  isFromUserView?: boolean;

  constructor(
    args: {
      isFromUserView?: boolean;
      userId?: string;
    } = {
      isFromUserView: false
    }
  ) {
    this.page = 1;
    this.limit = 20;
    this.isFromUserView = args.isFromUserView;
    this.userId = args.userId;
  }
}
