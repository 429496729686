import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';

import { Offer, offerAdapter, OfferState } from '../../types';

const getOfferState = createFeatureSelector<OfferState>('offer');
const offerDetector = createDetector<Offer>();

const { selectAll: getOffersList, selectEntities: getOffersDictionary } = offerAdapter.getSelectors(getOfferState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getBatchError,
  getSingleError
} = getCallStateSelectors<OfferState>(getOfferState);

const getFetchList = getFetchStateSelector<OfferState>(getOfferState);

const getOfferById = (id: string): Selector<OfferState, Offer> =>
  createSelector(getOffersDictionary, entities => entities[id]);

const isOfferLoaded = (id: string): Selector<OfferState, boolean> =>
  createSelector(getOffersDictionary, offers => offerDetector('oneLoaded')(offers, id));

const getOffersCount = createSelector(getOfferState, (state: OfferState) => state.count);

const getOffersFilter = createSelector(getOfferState, (state: OfferState) => state.filter);

export const offersQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getBatchError,
  getSingleError,
  getFetchList,
  getOfferById,
  getOffersList,
  getOffersDictionary,
  isOfferLoaded,
  getOffersFilter,
  getOffersCount
};
