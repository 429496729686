<admin-spinner *ngIf="loading$ | async"></admin-spinner>

<div class="main-container details-main-container" *ngIf="!(loading$ | async)">
  <div class="details-view">
    <div class="section-header">User profile</div>
    <form class="section" [formGroup]="userForm" (keydown.enter)="$event.preventDefault()">
      <div class="unpadded-top-subsection-header">Account</div>
      <div class="admin-form-fields-grid-wrapper">
        <mat-form-field>
          <mat-label>User ID</mat-label>
          <input matInput formControlName="id" />
          <admin-copy-to-clipboard
            [content]="userId"
            contentName="User ID"
            class="details-view-icon-button"
          ></admin-copy-to-clipboard>
          <mat-error>Required</mat-error>
        </mat-form-field>

        <ng-container *adminIfScopesInclude="SCOPES.viewRoles; relationOperator: 'or'">
          <mat-form-field
            *ngIf="(roleIds$ | async).length > 0"
            class="no-wrap-chip-input"
            [class.chip-filled]="userForm.get('roles').value.length"
          >
            <mat-label>Roles</mat-label>
            <admin-input-chip-list
              formControlName="roles"
              placeholder="New Role"
              [selectableValues]="selectableRoleIds"
              [nonRemovableValues]="undeletableRoleIds"
              [allowAnyInput]="false"
            ></admin-input-chip-list>
            <mat-hint>Roles are effective only if user's status is <span class="medium-text">admin</span></mat-hint>
            <mat-error *ngIf="userForm.get('roles').hasError('duplicateValueError')">Duplicate value</mat-error>
          </mat-form-field>
        </ng-container>

        <mat-form-field>
          <mat-label>Tenant status</mat-label>
          <mat-select formControlName="status" disableOptionCentering>
            <mat-option *ngFor="let status of statuses" [value]="status">
              {{ status | customTitleCase: 'fromSnakeCase' }}
            </mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Login mode</mat-label>
          <mat-select formControlName="loginMode" disableOptionCentering>
            <mat-option *ngFor="let loginMode of loginModes" [value]="loginMode">
              {{ loginMode | customTitleCase: 'fromSnakeCase' }}
            </mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Activation status</mat-label>
          <mat-select formControlName="activated" disableOptionCentering>
            <mat-option [value]="true">Activated</mat-option>
            <mat-option [value]="false">Not Activated</mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
      </div>

      <div class="unpadded-top-subsection-header">Personal</div>
      <div class="admin-form-fields-grid-wrapper">
        <mat-form-field>
          <mat-label>Salutation</mat-label>
          <mat-select formControlName="salutation" disableOptionCentering>
            <mat-option *ngFor="let salutation of salutationList" [value]="salutation">{{ salutation }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="admin-form-fields-grid-wrapper">
          <mat-form-field>
            <mat-label>First name</mat-label>
            <input matInput formControlName="firstName" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Last name</mat-label>
            <input matInput formControlName="lastName" />
          </mat-form-field>
        </div>
      </div>

      <div class="admin-form-fields-grid-wrapper">
        <mat-form-field>
          <mat-label>Date of birth</mat-label>
          <input
            matInput
            [matDatepicker]="birthdatePicker"
            [max]="nowTime"
            formControlName="birthdate"
            (focus)="birthdatePicker.open()"
          />
          <mat-datepicker-toggle [for]="birthdatePicker" class="details-view-icon-button">
            <mat-icon svgIcon="datetime" matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #birthdatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender" disableOptionCentering>
            <mat-option *ngFor="let gender of genders" [value]="gender">
              {{ gender | customTitleCase: 'fromSnakeCase' }}
            </mat-option>
          </mat-select>
          <admin-input-reset
            matSuffix
            initialValue=""
            *ngIf="isEditMode"
            [inputControl]="userForm.controls.gender"
            [loading]="loading$ | async"
          ></admin-input-reset>
        </mat-form-field>
      </div>

      <div class="unpadded-top-subsection-header">Contacts</div>
      <div class="admin-form-fields-grid-wrapper row-with-toggle-field">
        <mat-form-field [class.email-field]="isEditMode">
          <mat-label>Email address</mat-label>
          <input matInput formControlName="email" />
          <admin-tooltip
            *ngIf="isEditMode && !(loading$ | async) && isEmailEditable"
            [key]="'users.edit.button.editEmail?isEmailEditable=' + isEmailEditable"
            [displayIcon]="false"
          >
            <button mat-icon-button class="details-view-icon-button" (click)="openEmailDialog()">
              <mat-icon>create</mat-icon>
            </button>
          </admin-tooltip>
          <mat-error>{{ invalidEmailMessage }}</mat-error>
          <mat-hint *ngIf="isEditMode"
            >Be sure to toggle the “verified” flag of the user’s email MFA identity when changing the verified email
            toggle! You can find the list of email identities on the User info page.</mat-hint
          >

          <admin-verified-indicator
            *ngIf="isViewMode && (user$ | async) as user"
            [isVerified]="user.emailVerified"
            matSuffix
          ></admin-verified-indicator>
        </mat-form-field>

        <div class="mat-form-field mat-mdc-form-field-flex toggle-field" *ngIf="isEditMode">
          <mat-label>Email Verified</mat-label>
          <admin-slide-toggle formControlName="emailVerified" [toggleOptions]="toggleOptions"></admin-slide-toggle>
        </div>
      </div>

      <div class="admin-form-fields-grid-wrapper row-with-toggle-field">
        <mat-form-field>
          <mat-label>Phone number</mat-label>
          <input matInput formControlName="phoneNumber" />
          <mat-error>{{ invalidE164Message }}</mat-error>
          <admin-verified-indicator
            *ngIf="isViewMode && (user$ | async) as user"
            [isVerified]="user.phoneNumberVerified"
            matSuffix
          ></admin-verified-indicator>
        </mat-form-field>

        <div class="mat-form-field mat-mdc-form-field-flex toggle-field" *ngIf="isEditMode">
          <mat-label>Phone Verified</mat-label>
          <admin-slide-toggle
            formControlName="phoneNumberVerified"
            [toggleOptions]="toggleOptions"
          ></admin-slide-toggle>
        </div>
      </div>

      <div class="admin-form-fields-grid-wrapper phone-number-field">
        <ng-container *ngFor="let detail of details">
          <admin-item *ngIf="isViewMode && detail.key === 'otherPii' && user$ | async as user" [label]="detail.label">
            {{ Formatters.otherPiiFormatter(user.otherPii, detail.subKeys) }}
          </admin-item>
        </ng-container>

        <mat-form-field class="last-form-field">
          <mat-label>Locale</mat-label>
          <input matInput formControlName="locale" />
        </mat-form-field>

        <mat-form-field class="last-form-field">
          <mat-label>Zone</mat-label>
          <input matInput formControlName="zoneinfo" />
        </mat-form-field>
      </div>
    </form>

    <div class="section-header">Preferences</div>
    <form [formGroup]="userForm" class="section">
      <div class="admin-form-fields-grid-wrapper preferences-section">
        <ng-container *ngIf="(user$ | async)?.preferences as preferences">
          <admin-item label="Currency">{{ preferences.currency }}</admin-item>
          <admin-item label="Loyalty currency">{{ preferences.loyaltyCurrency }}</admin-item>
          <admin-item label="Points account ID">{{ preferences.pointsAccountId }}</admin-item>
          <admin-item label="Product ID">{{ preferences.productId }}</admin-item>
        </ng-container>

        <mat-form-field class="last-form-field">
          <mat-label>Digital rewards statement status</mat-label>
          <mat-select formControlName="eStatement" disableOptionCentering>
            <mat-option [value]="true">Opt in</mat-option>
            <mat-option [value]="false">Opt out</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngFor="let detail of details">
          <admin-item
            *ngIf="isViewMode && detail.key === 'loyalty_data' && user$ | async as user"
            [label]="detail.label"
          >
            {{ Formatters.loyaltyDataFormatter(user.loyalty_data, detail.subKeys) }}
          </admin-item>
        </ng-container>
      </div>
    </form>

    <ng-container *ngIf="isViewMode">
      <div class="header-outside-section" *adminIfScopesInclude="viewManageIdentities; relationOperator: 'or'">
        <div class="section-header">Identities</div>
        <admin-identities [user]="user$ | async"></admin-identities>
        <admin-mfa-identities-v2 [user]="user$ | async"></admin-mfa-identities-v2>
      </div>
    </ng-container>

    <div class="section-header" [class.header-outside-section]="isViewMode">Data</div>
    <div *ngIf="isViewMode && user$ | async as user" class="header-outside-section">
      <div *ngIf="displayLoyaltyDataPanel">
        <div class="subsection-header">Loyalty</div>
        <div class="section">
          <div class="json-data-block">
            {{ user.loyalty_data | json }}
          </div>
        </div>
      </div>

      <div *ngIf="displayCustomPanel">
        <div class="subsection-header">Custom</div>
        <div class="section">
          <div class="json-data-block">
            {{ user.custom | json }}
          </div>
        </div>
      </div>

      <ng-container *ngIf="user.pointsAccounts?.length">
        <div class="subsection-header">Points accounts IDs</div>
        <div class="section">
          <ul>
            <li *ngFor="let pointsAccount of user.pointsAccounts">
              {{ pointsAccount.id }}
            </li>
          </ul>
        </div>
      </ng-container>

      @if (user.enrolledSuppliers) {
        <div class="subsection-header">Enrolled suppliers</div>
        <div class="section">
          <ul>
            @for (supplier of user.enrolledSuppliers; track supplier) {
              <li>{{ supplier }}</li>
            } @empty {
              <div>No enrolled suppliers</div>
            }
          </ul>
        </div>
      }
    </div>

    <div *ngIf="isEditMode">
      <form [formGroup]="userForm" class="section">
        <mat-form-field class="textarea-field">
          <mat-label>Loyalty Data</mat-label>
          <textarea matInput formControlName="loyalty_data" rows="4"></textarea>
          <mat-error>Invalid JSON</mat-error>
        </mat-form-field>

        <mat-form-field class="textarea-field last-form-field">
          <mat-label>Custom</mat-label>
          <textarea matInput formControlName="custom" rows="4"></textarea>
          <mat-error *ngIf="userForm.get('custom').hasError('invalidJSON')">Invalid JSON</mat-error>
          <mat-error *ngIf="userForm.get('custom').hasError('invalidCustomDataFormat')">
            {{ invalidCustomDataMessage }}</mat-error
          >
          <mat-error *ngIf="userForm.get('custom').hasError('invalidEStatementDataType')"
            >digital_rewards_statement should be a boolean</mat-error
          >
        </mat-form-field>
      </form>
    </div>
  </div>

  <admin-footer>
    <ng-container *ngIf="isViewMode && user$ | async as user">
      <button
        *adminIfScopesInclude="SCOPES.resetPassword; relationOperator: 'or'"
        [disabled]="!(isResetPasswordEnabled$ | async)"
        [ngClass]="!(isResetPasswordEnabled$ | async) ? 'disabled-button' : 'secondary-button'"
        (click)="openSendResetPasswordDialog(user)"
      >
        Send reset password email
      </button>
      <button *ngIf="showEdit()" class="primary-button" [routerLink]="'../../edit/' + userId">Edit</button>
    </ng-container>
    <ng-container *ngIf="isEditMode">
      <button [routerLink]="cancelButtonLink" class="secondary-button">Cancel</button>
      <button
        adminHandleErrorFields
        [disabled]="userForm.invalid || isPristine"
        [ngClass]="userForm.invalid || isPristine ? 'disabled-button' : 'primary-button'"
        type="submit"
        [form]="userForm"
        (submitForm)="submitForm()"
      >
        Save Changes
      </button>
    </ng-container>
  </admin-footer>
</div>
