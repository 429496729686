import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Translations {
  [localeCode: string]: object;
}

export interface TranslationState extends CallState {
  translations: Translations;
}

export const initialState: TranslationState = {
  ...initialCallState,
  translations: {}
};
