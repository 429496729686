import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({ providedIn: 'root' })
export class DashedDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'en');
  }
}
