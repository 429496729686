import { Injectable } from '@angular/core';

import { Tag } from '@shared/types';

import { UserToken } from '../../../users/types';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class TokenTagDefs {
  readonly tagDefs: TagDefs<UserToken> = {
    token_id: {
      getDisplayPrefix: () => 'Token',
      getDisplayValue: (tag: Tag) => tag.id
    }
  };
}
