<h1 mat-dialog-title>{{ data.title }}</h1>
<div class="form-container">
  <h4>{{ data.label }}</h4>
  <mat-button-toggle-group [(ngModel)]="selectedType">
    <mat-button-toggle *ngFor="let type of data.types" [value]="type">
      {{ type | customTitleCase: 'fromCamelCase' }}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div mat-dialog-actions fxLayoutAlign="center">
    <button mat-raised-button color="secondary" class="text-primary cancel-button" (click)="dialogRef.close(false)">
      <span>Cancel</span>
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="!selectedType"
      [routerLink]="data.redirectPathPrepend + '/' + selectedType"
      (click)="dialogRef.close(false)"
    >
      <span>Create</span>
    </button>
  </div>
</div>
