import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialLocaleState, localeAdapter, LocaleState } from '../../types';
import { loadLocales, loadLocalesFailure, loadLocalesSuccess } from '../actions/locales.actions';

export const localeTriggers: EntityTriggers = {
  batch: {
    loading: [loadLocales.type],
    resting: [loadLocalesSuccess.type],
    erroring: [loadLocalesFailure.type]
  }
};

export function localesReducer(state: LocaleState = initialLocaleState, action: Action): LocaleState {
  return callStateReducer(baseReducer, localeTriggers)(state, action);
}

const baseReducer = createReducer(
  initialLocaleState,
  on(loadLocalesSuccess, (state, { locales }) => localeAdapter.setAll(locales, state))
);
