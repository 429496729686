import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { AlfredTenantsResult } from '../../types/alfred-tenants.type';

export const loadAlfredTenants = createAction('[Alfred Tenants] Load Alfred Tenants');
export const loadAlfredTenantsSuccess = createAction(
  '[Alfred Tenants] Load Alfred Tenants Success',
  props<{ alfredTenants: AlfredTenantsResult }>()
);
export const loadAlfredTenantsFailure = createAction(
  '[Alfred Tenants] Load Alfred Tenants Failure',
  props<ErrorState>()
);
