import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  webhookEventAdapter,
  webhookEventWithPaginationInitialState,
  WebhookEventWithPaginationState
} from '../../types';
import {
  loadWebhookEventsWithPagination,
  loadWebhookEventsWithPaginationSuccess,
  loadWebhookEventsWithPaginationFailure,
  setWebhookEventsFilterWithPagination
} from '../actions/webhook-events-with-pagination.actions';

export const webhookEventWithPaginationTriggers: EntityTriggers = {
  batch: {
    loading: [loadWebhookEventsWithPagination.type],
    resting: [loadWebhookEventsWithPaginationSuccess.type],
    erroring: [loadWebhookEventsWithPaginationFailure.type]
  }
};

export function webhookEventsWithPaginationReducer(
  state: WebhookEventWithPaginationState = webhookEventWithPaginationInitialState,
  action: Action
): WebhookEventWithPaginationState {
  return callStateReducer(baseReducer, webhookEventWithPaginationTriggers)(state, action);
}

const baseReducer = createReducer(
  webhookEventWithPaginationInitialState,
  on(loadWebhookEventsWithPaginationSuccess, (state, { webhookEvents, count, pagination }) => {
    return {
      ...webhookEventAdapter.setAll(webhookEvents, state),
      count,
      pagination
    };
  }),
  on(setWebhookEventsFilterWithPagination, (state, { filter }) => ({ ...state, filter }))
);
