import { startWith } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import {
  AttributesSettings,
  LoyaltyCurrency,
  LoyaltyProgram,
  ManualAdjustmentOption,
  Reason,
  RequestValidationSettings
} from '@core/types';
import { DayjsUtils, MAX_INTEGER } from '@utils';

import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS, MAT_DAYJS_DATE_FORMATS } from '../../../../adaptors';
import { makePointsAdjustment } from '../../store/actions/points-adjustments.actions';
import { pointsAdjustmentsQuery } from '../../store/selectors/points-adjustments.selectors';
import {
  PointsAdjustment,
  PointsAdjustmentDescription,
  PointsAdjustmentDialogData,
  PointsAdjustmentState,
  ReasonExplanation
} from '../../types';
import { PointsAdjustmentCreateForm, PointsAdjustmentMetadataForm } from '../../types/dashboard-forms.type';
import { PointsAdjustmentConfirmDialogComponent } from '../index';

enum PointsAdjustmentCategory {
  Accrual = 'accrual',
  Redemption = 'redemption',
  Reversal = 'reversal'
}

@Component({
  selector: 'admin-points-adjustment-dialog',
  templateUrl: './points-adjustment-dialog.component.html',
  styleUrls: ['./points-adjustment-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class PointsAdjustmentDialogComponent implements OnInit {
  pointsAdjustmentLoading$: Observable<boolean>;
  pointsAdjustmentForm: FormGroup<PointsAdjustmentCreateForm>;
  userId: string;
  minExpiryDate: Dayjs;

  min =
    Number.isInteger(this.pointsAdjustmentDecimals) && this.pointsAdjustmentDecimals > 0
      ? Math.pow(10, -this.pointsAdjustmentDecimals)
      : 1;
  max = MAX_INTEGER;
  step = this.min;
  additionAllowed = true;
  deductionAllowed = true;

  defaultDescriptions = Object.values(PointsAdjustmentDescription);

  defaultDescriptionLabels = {
    [PointsAdjustmentDescription.CustomerService]: 'Manual adjustment: customer service',
    [PointsAdjustmentDescription.CustomerRecognition]: 'Manual adjustment: customer recognition',
    [PointsAdjustmentDescription.SystemErrorCorrection]: 'Manual adjustment: system error correction'
  };

  reasonExplanation = ReasonExplanation;
  hasOnlyCustomOption: boolean;
  customDescriptions: string[];
  allowedOptions: ManualAdjustmentOption[];
  selectedOption: ManualAdjustmentOption;

  constructor(
    public dialogRef: MatDialogRef<PointsAdjustmentDialogComponent>,
    private pointsAdjustmentStore: Store<PointsAdjustmentState>,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private accessPolicies: AccessPolicies,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    @Inject('pointsAdjustmentDecimals') public pointsAdjustmentDecimals: number,
    @Inject(MAT_DIALOG_DATA) public data: PointsAdjustmentDialogData,
    @Inject('timezoneOffset') public timezoneOffset: number
  ) {}

  get adjustmentText(): string {
    const valid = this.pointsAdjustmentForm.valid;
    const { value: amount } = this.pointsAdjustmentForm.controls.amount;

    if (!valid) {
      return '';
    }

    const actionDescription = this.category === PointsAdjustmentCategory.Accrual ? 'added into' : 'deducted from';

    return `${amount} ${this.loyaltyCurrency} will be ${actionDescription} the customer's account.`;
  }

  get bootstrapLoyaltyProgram(): LoyaltyProgram {
    return this.data.nydusNetworkBootstrap.loyaltyPrograms[0];
  }

  get bootstrapLoyaltyCurrency(): LoyaltyCurrency {
    return this.data.nydusNetworkBootstrap.loyaltyCurrencies[0];
  }

  get reason(): string {
    return this.metadataControl.controls.reason.value;
  }

  get category(): string {
    return this.pointsAdjustmentForm.controls.category.value;
  }

  get metadataControl(): FormGroup<PointsAdjustmentMetadataForm> {
    return this.pointsAdjustmentForm.controls.metadata as FormGroup<PointsAdjustmentMetadataForm>;
  }

  get yearOfAccrual(): AbstractControl {
    return this.pointsAdjustmentForm.get('metadata.year_of_accrual');
  }

  get yearOfWriteOff(): AbstractControl {
    return this.pointsAdjustmentForm.get('metadata.year_of_writeoff');
  }

  get autoDescription(): string {
    return this.autoDescriptions.get(this.selectedOption.reason);
  }

  get autoDescriptions(): Map<Reason, string> {
    return new Map([
      [
        Reason.ExpiryReinstatement,
        `Cancelled ${this.bootstrapLoyaltyCurrency.name} Expiry ${
          dayjs(this.yearOfAccrual?.value)?.get('year') || ''
        }`.trim()
      ],
      [
        Reason.WriteoffReinstatement,
        `Cancelled ${this.bootstrapLoyaltyCurrency.name} Forfeiture ${
          dayjs(this.yearOfWriteOff?.value)?.get('year') || ''
        }`.trim()
      ],
      [Reason.PointsShare, `ANZ ${this.bootstrapLoyaltyCurrency.name} Pooling Transfer`]
    ]);
  }

  get reasonDescriptions(): string[] {
    switch (this.reason) {
      case Reason.Goodwill: {
        return ['Goodwill', 'Fraud', 'Ascenda-funded', 'Other reasons (ANZ-related)', 'Other reasons (ASC-related)'];
      }
      case Reason.PointsShare: {
        return [`${this.bootstrapLoyaltyCurrency.name} Pooling Transfer`];
      }
      case Reason.BonusPointsAdjustment: {
        switch (this.category) {
          case PointsAdjustmentCategory.Accrual: {
            return ['Credit Adjustment to Promotional Points'];
          }
          case PointsAdjustmentCategory.Redemption: {
            return ['Debit Adjustment to Promotional Points'];
          }
          default: {
            return [];
          }
        }
      }
      default: {
        return null;
      }
    }
  }

  ngOnInit(): void {
    this.minExpiryDate = DayjsUtils.getTenantLocalNowTime(this.timezoneOffset);
    this.pointsAdjustmentLoading$ = this.pointsAdjustmentStore.select(pointsAdjustmentsQuery.isSingleLoading);
    const policySettings = this.accessPolicies.getRequestValidationSettings('pointsAdjustment', 'create')
      ?.attributes as AttributesSettings;

    const options = this.data.nydusNetworkBootstrap.manualAdjustmentOptions;
    this.hasOnlyCustomOption = options.length === 1 && options[0].reason === 'custom';
    this.customDescriptions = this.hasOnlyCustomOption
      ? options[0].metadata.find(obj => obj.key === 'description')?.values
      : null;

    const reasonsPolicy = (policySettings?.reason as RequestValidationSettings)?.includes;
    this.allowedOptions = reasonsPolicy ? options.filter(option => reasonsPolicy.includes(option.reason)) : options;

    this.selectedOption = options[0];

    this.pointsAdjustmentForm = this.fb.group(
      {
        category: this.fb.control(null, [Validators.required]),
        amount: this.fb.control(null, [Validators.required, Validators.min(this.min)]),
        expiryDate: this.fb.control(null),
        expiryRule: this.fb.control(null),
        description: this.fb.control(null, [Validators.required]),
        metadata: this.getMetadataFormGroup()
      },
      {
        validators: (formGroup: UntypedFormGroup) => {
          const amount = formGroup.get('amount').value;
          const category = formGroup.get('category').value;
          const allowedDecimals = this.pointsAdjustmentDecimals || 0;

          if (amount && !isNaN(amount)) {
            const fractionDecimals = amount.toString().split('.')[1]?.length;

            if (fractionDecimals > allowedDecimals) {
              return { invalidAmount: true };
            }

            if (category === PointsAdjustmentCategory.Redemption && amount > this.data.pointsBalance) {
              return { invalidBalanceResult: true };
            }

            return null;
          }
        }
      }
    );

    this.pointsAdjustmentForm.controls.expiryRule.setValue('default');

    if (policySettings?.amount) {
      this.setAmountValidation(policySettings.amount as RequestValidationSettings);
    } else {
      this.pointsAdjustmentForm
        .get('amount')
        .setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
    }

    this.pointsAdjustmentForm.controls.expiryRule.valueChanges.subscribe(expiryRule => {
      if (expiryRule === 'custom') {
        this.pointsAdjustmentForm.controls.expiryDate.setValidators(Validators.required);
      } else {
        this.pointsAdjustmentForm.controls.expiryDate.clearValidators();
      }
      this.pointsAdjustmentForm.controls.expiryDate.updateValueAndValidity();
    });
  }

  confirmChangesClicked(): void {
    const { category, amount, expiryDate, expiryRule, metadata } = this.pointsAdjustmentForm.value;
    let { description } = this.pointsAdjustmentForm.getRawValue(); // register value for description even if disabled

    description = this.autoDescription ?? description;

    const dialogRef = this.matDialog.open(PointsAdjustmentConfirmDialogComponent, {
      data: {
        actionLabel: `${category === PointsAdjustmentCategory.Accrual ? 'add' : 'deduct'} ${amount} points`,
        // if there is a reason selected, the label is the same as the description value
        statementDescriptorLabel: this.reason ? description : this.defaultDescriptionLabels[description]
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const pointsAdjustment: PointsAdjustment = {
          amount: category === PointsAdjustmentCategory.Accrual ? amount : -amount,
          category,
          description,
          metadata
        };

        if (this.data.pointsAccountId) {
          pointsAdjustment.pointsAccountId = this.data.pointsAccountId;
        }

        let calculatedExpiryDate: string;

        if (this.selectedOption.customExpiry && expiryRule !== 'default') {
          if ([1, 2, 3].includes(expiryRule)) {
            const newExpiryDate = DayjsUtils.getUtcTime().startOf('hour').add(expiryRule, 'month');
            // Expiry Date is defined as the “last day that it is still valid for”, so here we set the time to be "end of the date"
            calculatedExpiryDate = DayjsUtils.getEndOfDay(newExpiryDate, this.timezoneOffset);
          } else {
            calculatedExpiryDate = DayjsUtils.getEndOfDay(expiryDate, this.timezoneOffset);
          }
          pointsAdjustment.expiryRule = 'custom_date';
          pointsAdjustment.expiryDate = calculatedExpiryDate;
        }

        this.selectedOption.metadata
          .filter(({ key, type }) => type === 'year' && metadata[key])
          .forEach(({ key }) => {
            metadata[key] = dayjs(metadata[key]).get('year');
          });

        const { nydusNetworkBootstrap, ...otherData } = this.data;
        this.pointsAdjustmentStore.dispatch(
          makePointsAdjustment({
            ...otherData,
            pointsAdjustment,
            dialogRefId: this.dialogRef.id
          })
        );
      }
    });
  }

  onReasonChange({ value: reason }: MatSelectChange): void {
    this.selectedOption = this.allowedOptions.find(option => option.reason === reason);

    const { category, expiryRule, description } = this.pointsAdjustmentForm.controls;

    category.reset();
    expiryRule.setValue('default');

    if (this.autoDescription) {
      description.setValue(this.autoDescription);
    } else {
      description.reset();
    }

    if (reason === Reason.BonusPointsAdjustment) {
      description.disable();
    } else {
      description.enable();
    }

    this.pointsAdjustmentForm.controls.metadata = this.getMetadataFormGroup();
  }

  onCategoryChange(): void {
    if (this.reason === Reason.BonusPointsAdjustment) {
      this.pointsAdjustmentForm.controls.description.setValue(this.reasonDescriptions[0]);
    }
  }

  getMetadataFormGroup(): FormGroup<PointsAdjustmentMetadataForm> {
    return this.fb.group({
      reason: [this.selectedOption.reason],
      ...Object.fromEntries(
        this.selectedOption.metadata.map(metadata => [
          metadata.key,
          [null, metadata.required ? Validators.required : null]
        ])
      )
    });
  }

  private setAmountValidation(settings: RequestValidationSettings): void {
    this.pointsAdjustmentForm.controls.category.valueChanges
      .pipe(startWith(PointsAdjustmentCategory.Accrual))
      .subscribe((category: PointsAdjustmentCategory) => {
        const amountControl = this.pointsAdjustmentForm.controls.amount;
        if (category === PointsAdjustmentCategory.Accrual) {
          this.setAdditionValidation(amountControl, settings);
        } else if ([PointsAdjustmentCategory.Redemption, PointsAdjustmentCategory.Reversal].includes(category)) {
          this.setDeductionValidation(amountControl, settings);
        }
        amountControl.updateValueAndValidity();
      });
  }

  private setAdditionValidation(amountControl: AbstractControl, settings: RequestValidationSettings): void {
    if (settings.lte < 0) {
      amountControl.disable();
      this.additionAllowed = false;
    } else {
      this.enableAmountControl(amountControl);

      if (settings.gte > this.min) {
        this.min = settings.gte;
      }
      this.max = settings.lte ?? this.max;

      amountControl.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
    }
  }

  private setDeductionValidation(amountControl: AbstractControl, settings: RequestValidationSettings): void {
    if (settings.gte > 0) {
      amountControl.disable();
      this.deductionAllowed = false;
    } else {
      this.enableAmountControl(amountControl);

      if (settings.lte < -this.min) {
        this.min = -settings.lte;
      }
      this.max = -settings.gte ?? Number.MAX_SAFE_INTEGER;

      amountControl.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
    }
  }

  private enableAmountControl(amountControl: AbstractControl): void {
    amountControl.enable();
    this.additionAllowed = true;
    this.deductionAllowed = true;
  }
}
