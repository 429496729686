import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface ConnectionCredential {
  id: string;
  appConnectionId: string;
  invalidated: boolean;
  createdAt: string;
  updatedAt: string;
  issuedAt: string;
  expiresAt: string;
  jwt: string;
}

export const connectionCredentialsAdapter = createEntityAdapter<ConnectionCredential>();

export interface ConnectionCredentialState extends EntityState<ConnectionCredential>, CallState {}

export const initialState: ConnectionCredentialState =
  connectionCredentialsAdapter.getInitialState<CallState>(initialCallState);
