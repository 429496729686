var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var SecureFieldsError = /** @class */ (function (_super) {
    __extends(SecureFieldsError, _super);
    function SecureFieldsError(error) {
        var _this = _super.call(this, error.code + " " + error.message) || this;
        _this.code = error.code;
        _this.message = error.message;
        return _this;
    }
    return SecureFieldsError;
}(Error));
export { SecureFieldsError };
var InvalidPanError = /** @class */ (function (_super) {
    __extends(InvalidPanError, _super);
    function InvalidPanError() {
        return _super.call(this, { code: 'SF01', message: 'Invalid value' }) || this;
    }
    return InvalidPanError;
}(SecureFieldsError));
export { InvalidPanError };
var ServerError = /** @class */ (function (_super) {
    __extends(ServerError, _super);
    function ServerError() {
        return _super.call(this, { code: 'SF02', message: 'PCI-Proxy server error' }) || this;
    }
    return ServerError;
}(SecureFieldsError));
export { ServerError };
// TODO Think whether handling this error separately is needed (e.g. for retry mechanism)
var RateLimitingError = /** @class */ (function (_super) {
    __extends(RateLimitingError, _super);
    function RateLimitingError() {
        return _super.call(this, { code: 'SF03', message: 'Exceeded request rate limit' }) || this;
    }
    return RateLimitingError;
}(SecureFieldsError));
export { RateLimitingError };
var ShortLengthError = /** @class */ (function (_super) {
    __extends(ShortLengthError, _super);
    function ShortLengthError(minLength) {
        var _this = _super.call(this, { code: 'SF04', message: "PAN is too short (minimum is " + minLength + " characters)" }) || this;
        _this.minLength = minLength;
        return _this;
    }
    return ShortLengthError;
}(SecureFieldsError));
export { ShortLengthError };
var LongLengthError = /** @class */ (function (_super) {
    __extends(LongLengthError, _super);
    function LongLengthError(maxLength) {
        var _this = _super.call(this, { code: 'SF05', message: "PAN is too long (maximum is " + maxLength + " characters)" }) || this;
        _this.maxLength = maxLength;
        return _this;
    }
    return LongLengthError;
}(SecureFieldsError));
export { LongLengthError };
var InvalidLengthError = /** @class */ (function (_super) {
    __extends(InvalidLengthError, _super);
    function InvalidLengthError() {
        return _super.call(this, { code: 'SF06', message: 'Invalid Length' }) || this;
    }
    return InvalidLengthError;
}(SecureFieldsError));
export { InvalidLengthError };
var InvalidNumberTypeError = /** @class */ (function (_super) {
    __extends(InvalidNumberTypeError, _super);
    function InvalidNumberTypeError() {
        return _super.call(this, { code: 'SF07', message: 'Input must contain number only' }) || this;
    }
    return InvalidNumberTypeError;
}(SecureFieldsError));
export { InvalidNumberTypeError };
var TimeoutError = /** @class */ (function (_super) {
    __extends(TimeoutError, _super);
    function TimeoutError() {
        return _super.call(this, { code: 'SF08', message: 'Tokenization timeout' }) || this;
    }
    return TimeoutError;
}(SecureFieldsError));
export { TimeoutError };
var IframeRenderingTimeoutError = /** @class */ (function (_super) {
    __extends(IframeRenderingTimeoutError, _super);
    function IframeRenderingTimeoutError() {
        return _super.call(this, { code: 'SF09', message: 'Iframe rendering timeout' }) || this;
    }
    return IframeRenderingTimeoutError;
}(SecureFieldsError));
export { IframeRenderingTimeoutError };
