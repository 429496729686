import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface AlfredTenant {
  id: string;
  minDenomination: number;
  maxDenomination: number;
  configs: object;
  preferredDenominations: number[];
  markup: object;
  productSuppliers: string[];
  giftCardSuppliers: string[];
  encryptionVersion: number;
}

export interface AlfredTenantsResult {
  data: AlfredTenant[];
  metadata: {
    total: number;
  };
}

export const alfredTenantsAdapter = createEntityAdapter<AlfredTenant>();

export interface AlfredTenantsState extends EntityState<AlfredTenant>, CallState {}

export const initialState: AlfredTenantsState = alfredTenantsAdapter.getInitialState<CallState>(initialCallState);
