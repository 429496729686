import { Component, Inject, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { HttpError, NydusNetworkBootstrap, SCOPES_OR } from '@core/types';
import { ComponentState, getRenderState, Params } from '@utils';

import { PointsAdjustmentDialogV2Component } from '../../../dashboard/components';
import { PointsAccount, PointsAdjustmentDialogData } from '../../../dashboard/types';

@Component({
  selector: 'admin-points-summary-v2',
  templateUrl: './points-summary-v2.component.html',
  styleUrls: ['./points-summary-v2.component.scss', '../../../dashboard/views/dashboard-v2/dashboard-v2.component.scss']
})
export class PointsSummaryV2Component implements OnChanges {
  @Input() displayPointsAdjustmentButton?: boolean = true;
  @Input() error: HttpError;
  @Input() loading: boolean;
  @Input() nydusNetworkBootstrap: NydusNetworkBootstrap;
  @Input() pointsSummaryData: PointsAccount;

  renderState: ComponentState;
  readonly SCOPES = SCOPES_OR;

  constructor(
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    @Inject('pointsBalanceDecimals') public pointsBalanceDecimals: number
  ) {}

  ngOnChanges(): void {
    this.renderState = getRenderState([this.loading, this.error]);
  }

  openPointsAdjustmentDialog(): void {
    const summaryData: any = this.pointsSummaryData;

    const data: PointsAdjustmentDialogData = {
      nydusNetworkBootstrap: this.nydusNetworkBootstrap,
      ...(summaryData.id && { pointsAccountId: summaryData.id }), // include pointsAccountId if it exists
      pointsBalance: summaryData.pointsBalance,
      userId: Params.find(this.route, 'userId')
    };

    this.matDialog.open(PointsAdjustmentDialogV2Component, { data });
  }
}
