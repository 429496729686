import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NydusNetworkBootstrap, RawNydusNetworkBootstrap, relationshipKeys } from '@core/types';
import { ObjectUtils, X_MULTIPLE_LOCALES_HEADER } from '@utils';

import { MissionControlService } from '../mission-control/mission-control.service';

interface TempNydusNetworkBootstrap extends NydusNetworkBootstrap {
  id: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class NydusNetworkBootstrapService {
  constructor(private missionControlService: MissionControlService) {}

  getBootstrap(): Observable<NydusNetworkBootstrap> {
    return this.missionControlService
      .get<RawNydusNetworkBootstrap>('nydus_network_bootstrap', null, X_MULTIPLE_LOCALES_HEADER)
      .pipe(
        map((result: RawNydusNetworkBootstrap) => {
          const flattenKeys = ['data', 'attributes', 'relationships'];
          const { data, included } = result;
          const formattedData = ObjectUtils.flattenObjectByKeys(data, flattenKeys) as TempNydusNetworkBootstrap;

          relationshipKeys.forEach(key => {
            formattedData[key] = formattedData[key].map(({ id, type }) => {
              const includedData = included.find(item => item.id === id && item.type === type);
              const { type: _, relationships, ...restIncludedData } = includedData;

              return ObjectUtils.flattenObjectByKeys(restIncludedData, flattenKeys);
            });
          });

          const { id: __, type: ___, ...restFormattedData } = formattedData;

          return restFormattedData;
        })
      );
  }
}
