import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { AnalyticsState } from '../../types/analytics.type';

const getAnalyticState = createFeatureSelector<AnalyticsState>('analytic');

const { isSingleLoading, isSingleResting, getSingleError } = getCallStateSelectors<AnalyticsState>(getAnalyticState);

const getToken = createSelector(getAnalyticState, (state: AnalyticsState) => state.customerToken?.token);

const isTokenLoaded = createSelector(getToken, (token: string) => !!token);

export const analyticsQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  getToken,
  isTokenLoaded
};
