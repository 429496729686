<div class="dialog-container relative my-2 gap-4">
  <a class="close-dialog absolute text-cloudburst-soft" (click)="dialogRef.close()">
    <mat-icon svgIcon="xmark-gray"></mat-icon>
  </a>
  <div class="text-center">
    <mat-icon svgIcon="shield-with-check-01" class="shield m-auto"></mat-icon>
    <h1 mat-dialog-title class="mt-2 mt-6 text-2xl font-bold text-cloudburst-dark">Step up required</h1>
    <div mat-dialog-content class="mat-dialog-content m-0 p-4 text-cloudburst-dark">
      This step up will require you to be redirected to another site to complete a MFA.
    </div>

    @if (messageKey == 'step-up') {
      <div class="note mx-auto my-4 flex rounded bg-info-sheer px-3 py-4 text-left text-sm text-cloudburst-dark">
        <mat-icon svgIcon="info-i" class="order-none"></mat-icon>
        <span>Note that the information will be unmasked for 3 hours.</span>
      </div>
    }
    <div mat-dialog-actions align="center">
      <button mat-raised-button color="primary" (click)="authenticate()" mat-dialog-close>Authenticate</button>
    </div>
  </div>
</div>
