import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';
import { ObjectUtils } from '@utils';

import { getCustomerId, User, userAdapter, UserState } from '../../types';

const usersDetector = createDetector<User>();
const getUserState = createFeatureSelector<UserState>('user');

const {
  selectIds: getUserIds,
  selectAll: getUsersList,
  selectEntities: getUsersDictionary
} = userAdapter.getSelectors(getUserState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError
} = getCallStateSelectors<UserState>(getUserState);

const getFetchList = getFetchStateSelector<UserState>(getUserState);

const isUsersLoaded = createSelector(
  getUsersDictionary,
  isBatchResting,
  (users, batchResting) => usersDetector('allLoaded')(users) && batchResting
);

const getUserById = (id: string): Selector<UserState, User> =>
  createSelector(getUsersDictionary, entities => entities[id]);

const getUserPropertyById = <T>(id: string, path: string[]): Selector<UserState, T> =>
  createSelector(getUserById(id), user => ObjectUtils.getNestedObjectDataWithPath<T>(user, path));

const isUserLoaded = (id: string): Selector<UserState, boolean> =>
  createSelector(
    getUsersDictionary,
    isSingleResting,
    (users, singleResting) => usersDetector('oneLoaded')(users, id) && singleResting
  );

const isLoading = createSelector(
  isBatchLoading,
  isSingleLoading,
  (batchLoading, singleLoading) => batchLoading || singleLoading
);

const getCount = createSelector(getUserState, (state: UserState) => state.count);

const getFilter = createSelector(getUserState, (state: UserState) => state.filter);

const getUserCustomerIds = (userIds: string[], providerId: string): Selector<UserState, string[]> =>
  createSelector(getUsersDictionary, entities =>
    userIds.map(userId => entities[userId] && getCustomerId(entities[userId], providerId))
  );

export const usersQuery = {
  getCount,
  getFilter,
  getUserIds,
  getUserById,
  isUsersLoaded,
  isUserLoaded,
  getFetchList,
  getUsersList,
  getUsersDictionary,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isLoading,
  getUserCustomerIds,
  getUserPropertyById
};
