import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { giftCardOrderAdapter, GiftCardOrderState, initialState } from '../../types/gift-card-orders.type';
import {
  loadGiftCardOrder,
  loadGiftCardOrderFailure,
  loadGiftCardOrders,
  loadGiftCardOrdersFailure,
  loadGiftCardOrdersSuccess,
  loadGiftCardOrderSuccess,
  retryGiftCardOrder,
  retryGiftCardOrderFailure,
  retryGiftCardOrderSuccess
} from '../actions/gift-card-orders.actions';

export const giftCardOrderTriggers: EntityTriggers = {
  single: {
    loading: [loadGiftCardOrder.type, retryGiftCardOrder.type],
    resting: [loadGiftCardOrderSuccess.type, retryGiftCardOrderSuccess.type],
    erroring: [loadGiftCardOrderFailure.type, retryGiftCardOrderFailure.type]
  },
  batch: {
    loading: [loadGiftCardOrders.type],
    resting: [loadGiftCardOrdersSuccess.type],
    erroring: [loadGiftCardOrdersFailure.type]
  }
};

export function giftCardOrdersReducer(state: GiftCardOrderState = initialState, action: Action): GiftCardOrderState {
  return callStateReducer(baseReducer, giftCardOrderTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadGiftCardOrderSuccess, (state, { order }) => giftCardOrderAdapter.upsertOne(order, state)),
  on(loadGiftCardOrders, (state, { filter }) => ({ ...state, filter })),
  on(loadGiftCardOrdersSuccess, (state, { result }) => ({
    ...giftCardOrderAdapter.setAll(result.data, state),
    count: result?.metadata?.total || 0
  }))
);
