import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';

import { AuditLog } from '../../types';

@Component({
  selector: 'admin-audit-log-details-dialog',
  templateUrl: './audit-log-details-dialog.component.html',
  styleUrls: ['./audit-log-details-dialog.component.scss']
})
export class AuditLogDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AuditLogDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AuditLog
  ) {}
}
