import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';

@Pipe({ name: 'arrayJoin' })
export class ArrayJoinPipe implements PipeTransform {
  transform(values: string[] | number[], separator: string = ', '): Nullable<string> {
    if (!values || !Array.isArray(values)) {
      return null;
    }

    return values.join(separator);
  }
}
