import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecureFieldsError } from '@kaligo/secure-fields-iframe';

@Injectable({
  providedIn: 'root'
})
export class SecureFieldsService {
  constructor(private snackBar: MatSnackBar) {}

  onTokenizeFailure(): Function {
    return (error: SecureFieldsError) => {
      const message = error.toString();
      this.snackBar.open(message, 'Dismiss');
    };
  }
}
