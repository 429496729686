import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { Watchlist, watchlistAdapter, WatchlistState } from '../../types';

const getWatchlistState = createFeatureSelector<WatchlistState>('watchlist');
const watchlistDetector = createDetector<Watchlist>();

const { selectAll: getWatchlistsList, selectEntities: getWatchlistsDictionary } =
  watchlistAdapter.getSelectors(getWatchlistState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<WatchlistState>(getWatchlistState);

const getCount = createSelector(getWatchlistState, state => state.count);

const getFilter = createSelector(getWatchlistState, state => state.filter);

const isWatchlistsLoaded = createSelector(
  getWatchlistsDictionary,
  isBatchResting,
  (watchlists, batchResting) => watchlistDetector('allLoaded')(watchlists) && batchResting
);

const isWatchlistLoaded = (id: string): Selector<WatchlistState, boolean> =>
  createSelector(getWatchlistsDictionary, entities => watchlistDetector('oneLoaded')(entities, id));

const getWatchlistById = (id: string): Selector<WatchlistState, Watchlist | undefined> =>
  createSelector(getWatchlistsDictionary, entities => entities[id]);

export const watchlistsQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getCount,
  getWatchlistsList,
  getWatchlistsDictionary,
  getFilter,
  isWatchlistsLoaded,
  getWatchlistById,
  isWatchlistLoaded
};
