import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Locale {
  code: string;
  name: string;
}

export function selectLocale(locale: Locale): string {
  return locale.code;
}

export const localeAdapter = createEntityAdapter<Locale>({
  selectId: selectLocale
});

export interface LocaleState extends EntityState<Locale>, CallState {}

export const initialLocaleState: LocaleState = localeAdapter.getInitialState<CallState>(initialCallState);
