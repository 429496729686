<div class="header-outside-section" *adminIfScopesInclude="SCOPES.viewIdentities; relationOperator: 'or'">
  <div class="flex items-center justify-between" *ngIf="user">
    <div class="subsection-header">Login</div>

    <button
      *adminIfScopesInclude="SCOPES.createIdentities; relationOperator: 'or'"
      class="borderless-button"
      (click)="openCreateIdentityDialog(user)"
    >
      <mat-icon svgIcon="add"></mat-icon>
      Create login identity
    </button>
  </div>

  <div class="sticky-table-wrapper">
    <table mat-table [dataSource]="userIdentity$ | async">
      <ng-container
        matColumnDef="tenantId"
        [sticky]="columnConfig['tenantId']?.isSticky"
        [stickyEnd]="columnConfig['tenantId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['tenantId']?.headerClass">Tenant ID</th>
        <td mat-cell *matCellDef="let identity" [ngClass]="columnConfig['tenantId']?.cellClass">
          {{ identity.tenantId }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="uid"
        [sticky]="columnConfig['uid']?.isSticky"
        [stickyEnd]="columnConfig['uid']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['uid']?.headerClass">Login ID</th>
        <td mat-cell *matCellDef="let identity" [ngClass]="columnConfig['uid']?.cellClass">
          {{ identity.uid }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="providerId"
        [sticky]="columnConfig['providerId']?.isSticky"
        [stickyEnd]="columnConfig['providerId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['providerId']?.headerClass">Provided by</th>
        <td mat-cell *matCellDef="let identity" [ngClass]="columnConfig['providerId']?.cellClass">
          {{ identity.providerId }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="createdAt"
        [sticky]="columnConfig['createdAt']?.isSticky"
        [stickyEnd]="columnConfig['createdAt']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['createdAt']?.headerClass">Created at</th>
        <td
          mat-cell
          matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
          *matCellDef="let identity"
          [ngClass]="columnConfig['createdAt']?.cellClass"
        >
          {{ identity.createdAt | dateFormat | date: 'yyyy-MM-dd HH:mm' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="updatedAt"
        [sticky]="columnConfig['updatedAt']?.isSticky"
        [stickyEnd]="columnConfig['updatedAt']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['updatedAt']?.headerClass">Updated at</th>
        <td
          mat-cell
          matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
          *matCellDef="let identity"
          [ngClass]="columnConfig['updatedAt']?.cellClass"
        >
          {{ identity.updatedAt | dateFormat | date: 'yyyy-MM-dd HH:mm' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="actions"
        [sticky]="columnConfig['actions']?.isSticky"
        [stickyEnd]="columnConfig['actions']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['actions']?.headerClass">Actions</th>
        <td mat-cell *matCellDef="let identity" [ngClass]="columnConfig['actions']?.cellClass">
          <a *ngIf="user">
            <button mat-icon-button (click)="openDeleteIdentityDialog(user, identity.reference)">
              <mat-icon svgIcon="remove"></mat-icon>
            </button>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <admin-empty-state-table></admin-empty-state-table>
        </td>
      </tr>
    </table>
  </div>
</div>
