import { map } from 'rxjs/operators';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { App } from '../types/apps.type';
import { CerberusConfig } from '../types/cerberus-configs.type';
import { SentinelConfig } from '../types/sentinel-configs.type';

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  constructor(private missionControlService: MissionControlService) {}

  getApps(tenantId?: string): Observable<App[]> {
    const params: Record<string, any> = tenantId ? { tenantId } : {};
    return this.missionControlService.get<App[]>('apps', new HttpParams({ fromObject: params }));
  }

  getApp(appId: string): Observable<App> {
    return this.missionControlService.get<App>(`apps/${appId}`);
  }

  getAppSecret(appId: string): Observable<string> {
    return this.missionControlService
      .get<{ secret: string }>(`apps/${appId}/secret`)
      .pipe(map(result => result.secret));
  }

  createApp(app: Partial<App>): Observable<App> {
    app = ObjectUtils.sanitizeRequestObject<Partial<App>>(app, {
      ignoredKeys: ['allowedWebOrigins', 'callbackUrls', 'oidcScopes', 'tenantId']
    });

    return this.missionControlService.post<App>('apps', app);
  }

  updateApp(app: Partial<App>): Observable<App> {
    app = ObjectUtils.sanitizeRequestObject<Partial<App>>(app, {
      ignoredKeys: [
        'allowedWebOrigins',
        'callbackUrls',
        'oidcScopes',
        'tenantId',
        'tokenExpirationTime',
        'config',
        'codeName'
      ]
    });

    return this.missionControlService.patch<App>(`apps/${app.id}`, app);
  }

  getCerberusConfig(appId: string): Observable<CerberusConfig> {
    const params = { id: appId };
    return this.missionControlService.get<CerberusConfig>(
      'apps/member/cerberus_configuration',
      new HttpParams({ fromObject: params })
    );
  }

  getSentinelConfig(appId: string): Observable<SentinelConfig> {
    const params = { id: appId };
    return this.missionControlService.get<SentinelConfig>(
      'apps/member/sentinel_configuration',
      new HttpParams({ fromObject: params })
    );
  }

  resetCerberusConfig(appId: string): Observable<void> {
    return this.missionControlService.post<void>('apps/reset_cerberus_configuration', { appId });
  }
}
