import { AbstractControl, ValidatorFn } from '@angular/forms';

import { RegExpUtils } from '@utils';

export function partialSearchValidator(): ValidatorFn {
  return ({ value }: AbstractControl): { [key: string]: any } | null => {
    if (!value) {
      return null;
    }

    value = value?.trim();

    if (!RegExpUtils.hasCJKCharacter(value) && value.length < 2) {
      return { invalidPartialSearch: true };
    }

    return null;
  };
}
