import { inject, Signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';

export interface CanCheckUnsavedChanges {
  unsavedChangesModalEntity: string;
  isPristine: boolean | Signal<boolean>;
}

export const unsavedChangesGuard: CanDeactivateFn<CanCheckUnsavedChanges> = (
  component: CanCheckUnsavedChanges
): Observable<boolean> => {
  const router: Router = inject(Router);
  const matDialog: MatDialog = inject(MatDialog);
  const isPristineValue = typeof component.isPristine === 'function' ? component.isPristine() : component.isPristine;

  return router.getCurrentNavigation()?.extras?.state?.bypassFormGuard || isPristineValue
    ? of(true)
    : matDialog
        .open(ConfirmDialogComponent, {
          disableClose: true,
          autoFocus: false,
          data: {
            dialogTitle: 'Unsaved changes',
            confirmText: `Are you sure you would like to leave the ${component.unsavedChangesModalEntity} page without saving your changes?`,
            confirmButtonText: 'Yes, leave this page',
            styleClassName: 'confirm-dialog'
          }
        })
        .afterClosed();
};
