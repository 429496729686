<div class="confirm-dialog">
  <div mat-dialog-title>Confirmation</div>
  <mat-dialog-content>
    Action: <span class="medium-text">{{ data.actionLabel }}</span> <br />
    Statement descriptor: <span class="medium-text">{{ data.statementDescriptorLabel }}</span> <br />
    Are you sure you want to apply the adjustment? This action cannot be undone.
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="secondary-button" (click)="dialogRef.close(false)">Cancel</button>
    <button class="primary-button" (click)="dialogRef.close(true)">Confirm</button>
  </mat-dialog-actions>
</div>
