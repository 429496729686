import { createSelector } from '@ngrx/store';

import { CoreState, getCoreState } from '@core/store';
import { getCallStateSelectors } from '@core/store/call-state';
import { SharedConfigState } from '@core/types';

const getSharedConfigState = createSelector(getCoreState, (state: CoreState) => state.sharedConfig);

const { isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<SharedConfigState>(getSharedConfigState);

const isSharedConfigStateLoaded = createSelector(
  getSharedConfigState,
  (state: SharedConfigState) => !!state.sharedConfig
);

const getSharedConfig = createSelector(getSharedConfigState, (state: SharedConfigState) => state.sharedConfig);

const getVersion = createSelector(getSharedConfigState, (state: SharedConfigState) => state.sharedConfig?.version);

export const sharedConfigQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isSharedConfigStateLoaded,
  getSharedConfig,
  getVersion
};
