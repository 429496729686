<div *ngIf="tenants?.length > 0" class="switch-tenant">
  <div [matMenuTriggerFor]="menu" #trigger="matMenuTrigger" class="switch-tenant__btn">
    <span class="header-text">Switch Tenant</span>
    <mat-icon class="switch-tenant__icon">{{ trigger.menuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
  </div>
  <mat-menu #menu="matMenu">
    <a [href]="tenant.url" mat-menu-item *ngFor="let tenant of tenants">{{ tenant.name }}</a>
  </mat-menu>
</div>
<div class="wrapper">
  <img [src]="logoUrl" alt="Logo" class="logo" [class.dark-filter]="isRc" routerLink="/" />
  <button mat-button aria-label="Login" class="login-btn" (click)="login()">
    <span>Login</span>
  </button>
</div>
