import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import {
  CreateUser,
  EnrollAdminRequest,
  Preferences,
  UnenrollUserResult,
  UpdateUsernameRequestParams,
  UpdateUsernameResponseParams,
  UpdateUserParams,
  User,
  UserAddress,
  UsersCountResult,
  UsersFilter,
  UsersResult
} from '../types';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private missionControlService: MissionControlService) {}

  getUsers(filter: UsersFilter): Observable<UsersResult> {
    const params = this.getUsersParamHandling(filter);

    return this.missionControlService.get<UsersResult>(
      'users',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getUsersCount(filter: UsersFilter): Observable<UsersCountResult> {
    const params = this.getUsersParamHandling(filter);

    return this.missionControlService.get<UsersCountResult>(
      'users/count',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getUser(id: string, skipAuditLog?: boolean): Observable<User> {
    return this.missionControlService.get<User>(
      `users/${id}`,
      skipAuditLog ? new HttpParams({ fromObject: { skipAuditLog } }) : null
    );
  }

  createUser(createRequest: CreateUser): Observable<User> {
    createRequest = ObjectUtils.sanitizeRequestObject<CreateUser>(createRequest);
    return this.missionControlService.post<User>('users', createRequest);
  }

  updateUser(user: Partial<User>, createdRoles: string[], deletedRoles: string[]): Observable<User> {
    const requestBody = this.getUpdateUserPayload(user, createdRoles, deletedRoles);

    return this.missionControlService.patch<User>(`users/${user.id}`, requestBody);
  }

  updatePersonalProfile(user: Partial<User>, createdRoles: string[], deletedRoles: string[]): Observable<User> {
    const requestBody = this.getUpdateUserPayload(user, createdRoles, deletedRoles);

    return this.missionControlService.patch<User>('users/update_personal_profile', requestBody);
  }

  requestResetPassword(userId: string, tenantId: string): Observable<void> {
    return this.missionControlService.post<void>('password/reset', { userId, tenantId });
  }

  blockUser(id: string): Observable<User> {
    return this.missionControlService.post<User>(`users/${id}/block`);
  }

  unblockUser(id: string): Observable<User> {
    return this.missionControlService.post<User>(`users/${id}/unblock`);
  }

  blockUserLogin(id: string): Observable<User> {
    return this.missionControlService.post<User>(`users/${id}/block_login`);
  }

  unblockUserLogin(id: string): Observable<User> {
    return this.missionControlService.post<User>(`users/${id}/unblock_login`);
  }

  enrollUser(enrollmentRequest: object): Observable<User> {
    enrollmentRequest = ObjectUtils.sanitizeRequestObject<object>(enrollmentRequest);
    return this.missionControlService.post<User>('users/enroll', enrollmentRequest);
  }

  unenrollUser(id: string): Observable<UnenrollUserResult> {
    return this.missionControlService.post<UnenrollUserResult>(`users/${id}/rollback`);
  }

  removeTeamMember(id: string): Observable<User> {
    return this.missionControlService.post<User>(`users/${id}/remove_team_member`);
  }

  updateUsername(updateUsernameRequest: UpdateUsernameRequestParams): Observable<UpdateUsernameResponseParams> {
    return this.missionControlService.patch<UpdateUsernameResponseParams>(
      `users/${updateUsernameRequest.id}/update_username`,
      updateUsernameRequest
    );
  }

  logoutUser(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`users/${id}/session_destroy`);
  }

  enrollAdmin(enrollAdminRequest: EnrollAdminRequest): Observable<User> {
    enrollAdminRequest = ObjectUtils.sanitizeRequestObject<EnrollAdminRequest>(enrollAdminRequest);
    return this.missionControlService.post<User>('users/create_admin', enrollAdminRequest);
  }

  enrollAscendaAdmin(email: string): Observable<User> {
    return this.missionControlService.post<User>('users/create_ascenda_admin', { email });
  }

  downloadAgents(): Observable<Blob> {
    return this.missionControlService.get<Blob>('users/download_agents', null, {}, 'blob');
  }

  private getUpdateUserPayload(user: Partial<User>, createdRoles: string[], deletedRoles: string[]): UpdateUserParams {
    const ignoredKeys: (keyof User)[] = [
      'birthdate',
      'email',
      'loyalty_data',
      'custom',
      'salutation',
      'firstName',
      'lastName',
      'middleName',
      'gender',
      'nickname',
      'phoneNumber',
      'picture',
      'preferredUsername',
      'locale',
      'profile',
      'zoneinfo',
      'address',
      'preferences'
    ];

    if (user.birthdate && user.birthdate instanceof Date) {
      user = {
        ...user,
        birthdate: new Date(Date.UTC(user.birthdate.getFullYear(), user.birthdate.getMonth(), user.birthdate.getDate()))
      };
    }

    if (user.address) {
      user = { ...user, address: ObjectUtils.sanitizeRequestObject<UserAddress>(user.address) };
    }

    if (user.preferences) {
      user = { ...user, preferences: ObjectUtils.sanitizeRequestObject<Preferences>(user.preferences) };
    }

    // exclude user id in the user payload
    const { id, ...rest } = user;

    return ObjectUtils.sanitizeRequestObject<UpdateUserParams>(
      { user: rest, createdRoles, deletedRoles },
      { ignoredKeys }
    );
  }

  private getUsersParamHandling(filter: UsersFilter): object {
    filter = ObjectUtils.sanitizeRequestObject<UsersFilter>(filter);
    const { sortBy, sortDirection, createdAt, updatedAt, isTeamMembersView, ...restFilterFields } = filter;

    let params = ObjectUtils.prepareQueryObject(restFilterFields);

    params = FilterUtils.appendHashQueryParam(params, 'sortBy', sortBy, sortDirection);
    params = FilterUtils.appendDateRange(params, createdAt, 'createdAt');
    params = FilterUtils.appendDateRange(params, updatedAt, 'updatedAt');

    return params;
  }
}
