var UserAgentUtils = /** @class */ (function () {
    function UserAgentUtils() {
    }
    UserAgentUtils.isAndroid = function () {
        return /Android/i.test(this.getUserAgent());
    };
    UserAgentUtils.isIPad = function () {
        return /iPad/i.test(this.getUserAgent());
    };
    UserAgentUtils.isIPhone = function () {
        return /iPhone/i.test(this.getUserAgent());
    };
    UserAgentUtils.isIPod = function () {
        return /iPod/i.test(this.getUserAgent());
    };
    UserAgentUtils.isIOS = function () {
        return this.isIPad() || this.isIPhone() || this.isIPod();
    };
    UserAgentUtils.isSafari = function () {
        var userAgent = this.getUserAgent().toLowerCase();
        return userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('chromium');
    };
    UserAgentUtils.getUserAgent = function () {
        return navigator.userAgent;
    };
    return UserAgentUtils;
}());
export { UserAgentUtils };
