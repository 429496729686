<div class="details-view">
  <div class="section-header">General</div>
  <div class="section admin-item-wrapper">
    <div class="product-image-wrapper">
      <div class="product-image-title">Product image</div>
      @if (merchandise$ | async; as merchandise) {
        <img [src]="merchandise.image" alt="Product Image" />
      } @else {
        <img [src]="data.productImageUrl" alt="Product Image" />
      }
    </div>
    <admin-item label="Order ID">{{ orderItem.order.id }}</admin-item>
    <admin-item label="Item ID">{{ orderItem.id }}</admin-item>
    @if (merchandise$ | async; as merchandise) {
      <admin-item label="Categories">
        <div *ngIf="merchandise.categories.length" class="chips-wrapper">
          <span class="chip" *ngFor="let category of merchandise.categories">
            {{ category | titlecase }}
          </span>
        </div>
      </admin-item>
    }
    <admin-item label="Product type">{{ orderItem.type | orderItemType }}</admin-item>
    <admin-item label="Order initiator">{{
      (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A'
    }}</admin-item>
    <admin-item label="Delivery method">{{ ((merchandise$ | async)?.deliveryMethod | titlecase) || 'N/A' }}</admin-item>

    <div class="section-header unpadded-subsection-header">Customer details</div>
    <admin-item label="Customer ID">{{ customerId }}</admin-item>
    <admin-item label="Ascenda ID" [copyButtonValue]="orderItem.userId">
      <a
        *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
        [routerLink]="'/customers/' + orderItem.userId + detailsView"
      >
        {{ orderItem.userId }}
      </a>
      <ng-template #userIdWithoutLink>
        {{ orderItem.userId }}
      </ng-template>
    </admin-item>

    @if (!isRc) {
      <div class="section-header unpadded-subsection-header">Supplier details</div>
      <admin-item label="Supplier">{{ (data.supplier | customTitleCase: 'fromSnakeCase') || 'N/A' }}</admin-item>
      <admin-item label="Supplier reference ID" [copyButtonValue]="data.externalSupplierOrderReference">
        {{ data.externalSupplierOrderReference || 'N/A' }}
      </admin-item>
      <admin-item label="Supplier reference item ID" [copyButtonValue]="data.externalSupplierOrderItemReference">
        {{ data.externalSupplierOrderItemReference || 'N/A' }}
      </admin-item>
    }
  </div>

  <div class="section-header">Delivery details</div>
  <div class="section admin-item-wrapper">
    @if (isRc) {
      <admin-item label="Full name">{{ (recipient?.fullName | titlecase) || 'N/A' }}</admin-item>
    } @else {
      <admin-item label="First name">{{ (recipient?.firstName | titlecase) || 'N/A' }}</admin-item>
      <admin-item label="Last name">{{ (recipient?.lastName | titlecase) || 'N/A' }}</admin-item>
    }
    <admin-item label="Phone number">{{ recipient?.phoneNumber || 'N/A' }}</admin-item>
    <admin-item label="Address">{{ formattedAddress }}</admin-item>
    <admin-item class="cross-two-column" label="Tracking id" [copyButtonValue]="data.fulfillmentData?.trackingId">
      <ng-container *ngIf="data.fulfillmentData?.trackingUrl; else elseBlock">
        <a [href]="data.fulfillmentData?.trackingUrl" target="_blank" rel="noopener noreferrer">
          {{ data.fulfillmentData?.trackingId }}
        </a>
      </ng-container>
      <ng-template #elseBlock>
        {{ data.fulfillmentData?.trackingId || 'N/A' }}
      </ng-template>
    </admin-item>
  </div>

  <div class="section-header">Item information</div>
  <div class="section admin-item-wrapper">
    <admin-item class="cross-two-column" label="SKU">{{ data.sku || 'N/A' }}</admin-item>
    <admin-item label="Quantity">{{ orderItem.quantity || 'N/A' }}</admin-item>
    <admin-item label="Remarks">{{ data?.remarks || 'N/A' }}</admin-item>
    <admin-item label="Total charge">
      {{ orderItem.pointsPaid | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
      }}{{ data?.currency === '$' ? '' : ' '
      }}{{ orderItem.cashPaid | formatCurrency: data?.currency | async | number }}
    </admin-item>
    <admin-item label="Original charge">
      {{ orderItem.pointsAmount | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
      }}{{ data?.currency === '$' ? '' : ' '
      }}{{ orderItem.cashAmount | formatCurrency: data?.currency | async | number }}
    </admin-item>
    @if (isRc) {
      <admin-item label="Point cost">{{ data.vendorAttributes!.pointsPurchasedCost }} {{ data.currency }}</admin-item>

      @if (data.vendorAttributes!.config) {
        <admin-item label="Markup"
          >{{ data.vendorAttributes!.config.pointPurchaseMarkupPercentage }}% +
          {{ data.vendorAttributes!.config.pointPurchaseMarkupFlat }} {{ data.currency }}</admin-item
        >
        <admin-item label="CPP">{{ data.vendorAttributes!.config.basePointMultiplier }}</admin-item>
      }
    } @else {
      <admin-item class="cross-two-column" label="Promo code">
        {{ data?.promoCode || 'N/A' }}
      </admin-item>
    }
  </div>

  <div class="section-header">Stripe reference</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Stripe reference">{{ stripeReferenceData.stripeReference }}</admin-item>
    <admin-item label="Total charge">{{ stripeReferenceData.totalCharge }}</admin-item>
  </div>
</div>
@if (!isRc) {
  <admin-footer>
    <admin-refund-order-button-v2 type="primary" [orderItem]="orderItem"></admin-refund-order-button-v2>
  </admin-footer>
}
