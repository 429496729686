import { first, map } from 'rxjs/operators';

import { inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { Nullable } from '@shared/types';

import { ResolverParams } from './resolver-params.type';

export const createResolver = <T>(params: ResolverParams<T>): Observable<Nullable<void>> => {
  const scopes = inject(Scopes);
  const store = inject(Store<T>);
  const scope = params.scope;

  if (
    scope &&
    ((typeof scope === 'string' && scopes.lack(scope)) || (Array.isArray(scope) && scopes.lackScopes(scope)))
  ) {
    return of(null);
  }

  return store.pipe(
    select(params.query),
    first(),
    map(isLoaded => {
      if (!isLoaded) {
        store.dispatch(params.action);
      }
    })
  );
};
