import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { PointsAccount } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class PointsAccountsService {
  constructor(private missionControlService: MissionControlService) {}

  getUserPointsAccounts(userId: string): Observable<PointsAccount[]> {
    return this.missionControlService.get<PointsAccount[]>(`users/${userId}/points_accounts`);
  }
}
