import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState, HttpErrorDetails } from '@core/types';
import { Tag, TagType } from '@shared/types';

import { EventsFilter } from './events-filter.type';

class EventBase {
  app: string;
  action: string;
  additionalInfo?: object;
  userId: string;
  logType: string;
  timestamp: string;
  event: string;
  message: string;
  deviceInfo: DeviceInfo;
  agentId: string;
  params: object;
  responseCode: number;
  tenantId: string;
  request: Partial<DeviceInfo>;
  errors?: HttpErrorDetails[];
  location: Location;
}

// represents an event as it's handled internally in AP

export interface Event extends EventBase {
  tags: Tag[];
}

// represents an audit log as it's stored in MC database
export class MissionControlEvent extends EventBase {
  tags: {
    [key in TagType]?: string;
  };
}

interface DeviceInfo {
  host: string;
  ip: string;
  sessionId: string;
  userAgent: string;
}

interface Location {
  city: string;
  country: string;
  countryCode: string;
  lon: number;
  lat: number;
}

export enum AppType {
  MissionControl = 'mission_control',
  NydusNetwork = 'nydus',
  Guardhouse = 'guardhouse',
  TravelEdge = 'travel_edge'
}

export function selectEventId(event: Event): string {
  return event.event + event.timestamp;
}

export const eventAdapter = createEntityAdapter<Event>({
  selectId: selectEventId
});

export interface EventState extends EntityState<Event>, CallState {
  filter: EventsFilter;
  lastPage: boolean;
}

export const initialState: EventState = {
  ...eventAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  lastPage: false
};
