import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Attempt {
  id: string;
  orderId: string;
  status: string;
  requestPayload: RequestPayload;
  responsePayload: ResponsePayload;
  createdAt: string;
  updatedAt: string;
}

interface RequestPayload {
  amount: number;
  notify: number;
  country: string;
  message: string;
  currency: string;
  brandCode: string;
  extraFields: object;
  referenceId: string;
  receiverName: string;
  receiverEmail: string;
  receiverPhone: string;
}

interface ResponsePayload {
  state: number;
  errors: {
    message: string;
    code: number;
  };
}

export const orderAttemptAdapter = createEntityAdapter<Attempt>();

export interface OrderAttemptState extends EntityState<Attempt>, CallState {}

export const initialState: OrderAttemptState = orderAttemptAdapter.getInitialState<CallState>(initialCallState);
