<mat-sidenav-container id="main-container" autosize="true">
  <mat-sidenav-content [ngClass]="navService.isMini ? 'collapsed' : 'expanded'">
    <admin-header [user]="user" [isLoggedIn]="isLoggedIn"></admin-header>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav mode="side" opened="true" [ngClass]="navService.isMini ? 'collapsed' : 'expanded'">
    <div *ngIf="isLoggedIn" [ngClass]="navService.isMini ? 'collapsed-logo-wrapper' : 'expanded-logo-wrapper'">
      <mat-icon svgIcon="home" *ngIf="navService.isMini; else tenantLogo" routerLink="/"></mat-icon>
      <ng-template #tenantLogo>
        <img [src]="logoUrl" alt="Logo" class="logo" routerLink="/" />
      </ng-template>
    </div>
    <mat-nav-list>
      <admin-menu-list-item
        *ngFor="let item of filteredNavItems$ | async"
        [item]="item"
        [depth]="0"
        [expandedItem]="expandedItem"
        (newExpandedItem)="expandedItem = $event"
      >
      </admin-menu-list-item>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>

<div
  class="sidenav-toggle-button"
  (click)="navService.toggleIsMini()"
  [ngClass]="navService.isMini ? 'collapse-position' : 'expand-position'"
>
  <mat-icon [ngClass]="isRC ? 'bordered' : 'non-bordered'">{{
    navService.isMini ? 'chevron_right' : 'chevron_left'
  }}</mat-icon>
</div>
