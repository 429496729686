import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';

type Period = 'day' | 'week' | 'month';

@Pipe({ name: 'period' })
export class PeriodPipe implements PipeTransform {
  transform(value: string | number, period: Period = 'day'): Nullable<string> {
    if (!(value || value === 0) || isNaN(Number(value))) {
      return null;
    }
    const numericValue = typeof value === 'string' ? Number.parseFloat(value) : value;
    const isMoreThanOne = Math.abs(numericValue) > 1;
    let result = numericValue.toString();

    switch (period) {
      case 'day': {
        result += ' day';
        break;
      }
      case 'week': {
        result += ' week';
        break;
      }
      case 'month': {
        result += ' month';
        break;
      }
      default: {
        return null;
      }
    }

    result += isMoreThanOne ? 's' : '';
    return result;
  }
}
