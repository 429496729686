import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { AutoRedemptionsResult } from '../types/auto-redemptions-result.type';
import { AutoRedemption } from '../types/auto-redemptions.type';

@Injectable({
  providedIn: 'root'
})
export class AutoRedemptionsService {
  constructor(private missionControlService: MissionControlService) {}

  getUserAutoRedemptions(id: string): Observable<AutoRedemption[]> {
    return this.missionControlService
      .get<AutoRedemptionsResult>(`users/${id}/auto_redemption_settings`)
      .pipe(map(autoRedemptions => autoRedemptions.data));
  }
}
