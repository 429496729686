import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import {
  OriginPage,
  RecommendedSegmentCreateRequest,
  Segment,
  SegmentActivationRequest,
  SegmentCreateRequest,
  SegmentEditRequest,
  SegmentsFilter
} from '../../types';

export const loadSegments = createAction(
  '[Segments] Load Segments',
  props<{
    filter: SegmentsFilter;
  }>()
);
export const loadSegmentsSuccess = createAction(
  '[Segments] Load Segments Success',
  props<{ segments: Segment[]; total: number }>()
);
export const loadSegmentsFailure = createAction('[Segments] Load Segments Failure', props<ErrorState>());

export const loadSegment = createAction('[Segments] Load Segment', props<{ id: string }>());
export const loadSegmentSuccess = createAction('[Segments] Load Segment Success', props<{ segment: Segment }>());
export const loadSegmentFailure = createAction('[Segments] Load Segment Failure', props<ErrorState>());

export const updateSegment = createAction('[Segments] Update Segment', props<{ request: SegmentEditRequest }>());
export const updateSegmentSuccess = createAction(
  '[Segments] Update Segment Success',
  (
    segment: Segment,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully saved your changes'
    }
  ) => ({ segment, snackbarMessages })
);
export const updateSegmentFailure = createAction(
  '[Segments] Update Segment Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your changes could not be saved due to technical issues on our end. Please try saving your changes again.' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const activateSegment = createAction(
  '[Segments] Activate Segment',
  props<{ request: SegmentActivationRequest; origin: OriginPage }>()
);
export const activateSegmentSuccess = createAction(
  '[Segments] Activate Segment Success',
  (
    segment: Segment,
    origin: OriginPage,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully activated the segment'
    }
  ) => ({ segment, origin, snackbarMessages })
);
export const activateSegmentFailure = createAction(
  '[Segments] Activate Segment Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your segment could not be activated due to technical issues on our end. Please try activating your segment again. ' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const deactivateSegment = createAction(
  '[Segments] Deactivate Segment',
  props<{ request: SegmentActivationRequest; origin: OriginPage }>()
);
export const deactivateSegmentSuccess = createAction(
  '[Segments] Deactivate Segment Success',
  (
    segment: Segment,
    origin: OriginPage,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully deactivated the segment'
    }
  ) => ({ segment, origin, snackbarMessages })
);
export const deactivateSegmentFailure = createAction(
  '[Segments] Deactivate Segment Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your segment could not be deactivated due to technical issues on our end. Please try deactivating your segment again. ' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const deleteSegment = createAction('[Segments] Delete Segment', props<{ id: string }>());
export const deleteSegmentSuccess = createAction(
  '[Segments] Delete Segment Success',
  (
    id: string,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully deleted the segment'
    }
  ) => ({ id, snackbarMessages })
);
export const deleteSegmentFailure = createAction(
  '[Segments] Delete Segment Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your segment could not be deleted due to technical issues on our end. Please try deleting your segment again.' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const createSegment = createAction(
  '[Segments] Create Segment',
  props<{ request: SegmentCreateRequest | RecommendedSegmentCreateRequest; recommended?: boolean }>()
);
export const createSegmentSuccess = createAction(
  '[Segments] Create Segment Success',
  (
    segment: Segment,
    recommended?: boolean,
    snackbarMessages: SnackbarMessages = {
      default: `You have successfully created a ${recommended ? 'recommended' : ''} segment`
    }
  ) => ({ segment, snackbarMessages })
);
export const createSegmentFailure = createAction(
  '[Segments] Create Segment Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your segment could not be created due to technical issues on our end. Please try creating your segment again.' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);
