import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { SegmentMember, segmentMemberAdapter, SegmentMemberState } from '../../types';

const getSegmentMemberState = createFeatureSelector<SegmentMemberState>('segmentMember');
const segmentMemberDetector = createDetector<SegmentMember>();

const { selectAll: getSegmentMembersList, selectEntities: getSegmentMembersDictionary } =
  segmentMemberAdapter.getSelectors(getSegmentMemberState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<SegmentMemberState>(getSegmentMemberState);

const getSegmentMembersSelectedSegmentId = createSelector(
  getSegmentMemberState,
  (state: SegmentMemberState) => state.selectedSegmentId
);

const getSegmentMembersTotal = createSelector(getSegmentMemberState, (state: SegmentMemberState) => state.total);

const getSegmentMembersFilter = createSelector(getSegmentMemberState, (state: SegmentMemberState) => state.filter);

const isSegmentMembersLoaded = (segmentId: string): Selector<SegmentMemberState, boolean> =>
  createSelector(
    getSegmentMembersDictionary,
    getSegmentMembersSelectedSegmentId,
    isBatchResting,
    (segmentMembers, selectedSegmentId, batchResting) =>
      selectedSegmentId === segmentId && segmentMemberDetector('allLoaded')(segmentMembers) && batchResting
  );

export const segmentMembersQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSegmentMembersLoaded,
  getSegmentMembersList,
  getSegmentMembersDictionary,
  getSegmentMembersSelectedSegmentId,
  getSegmentMembersTotal,
  getSegmentMembersFilter
};
