import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { CashRedemption, PointsTransferRedemption, Redemption } from '../types';

@Injectable({
  providedIn: 'root'
})
export class RedemptionsService {
  constructor(private missionControlService: MissionControlService) {}

  createCashRedemption(redemption: CashRedemption): Observable<Redemption> {
    const { userId, ...redemptionPayload } = redemption;
    return this.missionControlService
      .post<{ data: Redemption }>(`users/${userId}/cash_redemptions`, redemptionPayload)
      .pipe(map(redemptionResponse => redemptionResponse.data));
  }

  createPointsTransferRedemption(redemption: PointsTransferRedemption): Observable<Redemption> {
    const { userId, ...redemptionPayload } = redemption;
    return this.missionControlService
      .post<{ data: Redemption }>(`users/${userId}/points_transfers`, redemptionPayload)
      .pipe(map(redemptionResponse => redemptionResponse.data));
  }
}
