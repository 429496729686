import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { NydusNetworkPartnerConfigurationState, nydusNetworkPartnerConfigurationInitialState } from '../../types';
import { updateGiftCardsConfig, updateGiftCardsConfigFailure, updateGiftCardsConfigSuccess } from '../actions';
import {
  loadNydusNetworkPartnerConfiguration,
  loadNydusNetworkPartnerConfigurationFailure,
  loadNydusNetworkPartnerConfigurationSuccess
} from '../actions/nydus-network-partner-configurations.actions';

export const nydusNetworkPartnerConfigurationTriggers: EntityTriggers = {
  single: {
    loading: [loadNydusNetworkPartnerConfiguration.type, updateGiftCardsConfig.type],
    resting: [loadNydusNetworkPartnerConfigurationSuccess.type, updateGiftCardsConfigSuccess.type],
    erroring: [loadNydusNetworkPartnerConfigurationFailure.type, updateGiftCardsConfigFailure.type]
  }
};

export function nydusNetworkPartnerConfigurationsReducer(
  state: NydusNetworkPartnerConfigurationState = nydusNetworkPartnerConfigurationInitialState,
  action: Action
): NydusNetworkPartnerConfigurationState {
  return callStateReducer(baseReducer, nydusNetworkPartnerConfigurationTriggers)(state, action);
}

const baseReducer = createReducer(
  nydusNetworkPartnerConfigurationInitialState,
  on(loadNydusNetworkPartnerConfigurationSuccess, (state, { partnerConfiguration }) => ({
    ...state,
    partnerConfiguration
  }))
);
