import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  CampaignProposalState,
  campaignProposalInitialState,
  campaignProposalAdapter
} from '../../types/campaign-proposals.type';
import {
  createCampaignProposal,
  createCampaignProposalFailure,
  createCampaignProposalSuccess,
  loadCampaignProposal,
  loadCampaignProposalFailure,
  loadCampaignProposalSuccess,
  loadCampaignProposals,
  loadCampaignProposalsFailure,
  loadCampaignProposalsSuccess,
  updateCampaignProposal,
  updateCampaignProposalFailure,
  updateCampaignProposalSuccess
} from '../actions/campaign-proposals.actions';

export const campaignProposalTriggers: EntityTriggers = {
  single: {
    loading: [loadCampaignProposal.type, createCampaignProposal.type, updateCampaignProposal.type],
    resting: [loadCampaignProposalSuccess.type, createCampaignProposalSuccess.type, updateCampaignProposalSuccess.type],
    erroring: [loadCampaignProposalFailure.type, createCampaignProposalFailure.type, updateCampaignProposalFailure.type]
  },
  batch: {
    loading: [loadCampaignProposals.type],
    resting: [loadCampaignProposalsSuccess.type],
    erroring: [loadCampaignProposalsFailure.type]
  }
};

export function campaignProposalsReducer(
  state: CampaignProposalState = campaignProposalInitialState,
  action: Action
): CampaignProposalState {
  return callStateReducer(baseReducer, campaignProposalTriggers)(state, action);
}

const baseReducer = createReducer(
  campaignProposalInitialState,
  on(loadCampaignProposalsSuccess, (state, { campaignProposals }) =>
    campaignProposalAdapter.setAll(campaignProposals, state)
  ),
  on(loadCampaignProposalSuccess, (state, { campaignProposal }) =>
    campaignProposalAdapter.upsertOne(campaignProposal, state)
  ),
  on(createCampaignProposalSuccess, (state, { campaignProposal }) =>
    campaignProposalAdapter.addOne(campaignProposal, state)
  ),
  on(updateCampaignProposalSuccess, (state, { campaignProposal }) =>
    campaignProposalAdapter.setOne(campaignProposal, state)
  )
);
