import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { map, Observable } from 'rxjs';

import { AuthGuardService } from '@core/services/auth-guard/auth-guard.service';
import { GuardRedirectionService } from '@core/services/auth-guard/guard-redirection.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { Formatters } from '@utils';

interface HasAccessParameter {
  type: 'canMatch' | 'canActivate';
  route: Route | ActivatedRouteSnapshot;
  segments?: UrlSegment[];
  state?: RouterStateSnapshot;
}

export const rootAuthGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): Observable<boolean> =>
  hasAccess({ type: 'canMatch', route, segments });

export const componentAuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => hasAccess({ type: 'canActivate', route, state });

const hasAccess = ({ type, route, segments, state }: HasAccessParameter): Observable<boolean> => {
  const authGuardService: AuthGuardService = inject(AuthGuardService);
  const guardRedirectionService: GuardRedirectionService = inject(GuardRedirectionService);

  // injecting Scopes and AccessPolicies here instead of inside hasScopes and allowedByAccessPolicies
  // as inject doesn't work for async ref: https://netbasal.com/understanding-angular-injection-context-18a0780ede2d
  const scopes: Scopes = inject(Scopes);
  const accessPolicies: AccessPolicies = inject(AccessPolicies);

  return authGuardService.hasPersonalRouteAccess(route, segments).pipe(
    map(hasPersonalRouteAccess => {
      // only check access policies when canActivate is called, where we are able to retrieve user id
      // which enable us to block certain route but not block its personal route
      // e.g.,
      // we can block /agents but not block /agents/:userId/details route
      const hasAccessResult =
        type === 'canMatch'
          ? hasScopes(route, scopes)
          : hasScopes(route, scopes) && allowedByAccessPolicies(state, accessPolicies);

      const skipPageNotFoundRedirect = route.data?.skipPageNotFoundRedirect;

      if (hasPersonalRouteAccess || hasAccessResult) {
        return true;
      }

      return skipPageNotFoundRedirect ? false : guardRedirectionService.redirectToPageNotFound();
    })
  );
};

const hasScopes = (route: Route | ActivatedRouteSnapshot, scopes: Scopes): boolean => {
  const routeScopes = route.data?.scopes;
  return !routeScopes || scopes.hasScopes(routeScopes, route.data?.relationOperator);
};

const allowedByAccessPolicies = (state: RouterStateSnapshot, accessPolicies: AccessPolicies): boolean => {
  const visibleSettings = accessPolicies.getFrontendSettings('sidenav', 'show')?.visible;
  if (!visibleSettings) {
    return true;
  }

  const urlSegment = state.url
    .split('/')
    .map(segment => Formatters.fromKebabToCamelCase(segment))
    .find(item => item !== '');

  // block main path only, e.g.,
  // block /logs but not block /agents/xxx/logs
  return visibleSettings[urlSegment] !== false;
};
