import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { FraudRule } from '../../types/fraud-rules.type';

export const loadFraudRules = createAction('[Fraud Rules] Load Fraud Rules');
export const loadFraudRulesSuccess = createAction(
  '[Fraud Rules] Load Fraud Rules Success',
  props<{ fraudRules: FraudRule[] }>()
);
export const loadFraudRulesFailure = createAction('[Fraud Rules] Load Fraud Rules Failure', props<ErrorState>());

export const loadFraudRule = createAction('[Fraud Rules] Load Fraud Rule', props<{ id: string }>());
export const loadFraudRuleSuccess = createAction(
  '[Fraud Rules] Load Fraud Rule Success',
  props<{ fraudRule: FraudRule }>()
);
export const loadFraudRuleFailure = createAction('[Fraud Rules] Load Fraud Rule Failure', props<ErrorState>());

export const createFraudRule = createAction(
  '[Fraud Rules] Create Fraud Rule',
  props<{ fraudRule: Partial<FraudRule> }>()
);
export const createFraudRuleSuccess = createAction(
  '[Fraud Rules] Create Fraud Rule Success',
  props<{ fraudRule: FraudRule }>()
);
export const createFraudRuleFailure = createAction('[Fraud Rules] Create Fraud Rule Failure', props<ErrorState>());

export const updateFraudRule = createAction(
  '[Fraud Rules] Update Fraud Rule',
  props<{ fraudRule: Partial<FraudRule> }>()
);
export const updateFraudRuleSuccess = createAction(
  '[Fraud Rules] Update Fraud Rule Success',
  props<{ fraudRule: FraudRule }>()
);
export const updateFraudRuleFailure = createAction('[Fraud Rules] Update Fraud Rule Failure', props<ErrorState>());

export const fetchFraudRule = createAction('[Fraud Rules] Fetch Fraud Rule', props<{ id: string }>());
export const fetchFraudRuleSuccess = createAction(
  '[Fraud Rules] Fetch Fraud Rule Success',
  props<{ fraudRule: FraudRule }>()
);
export const fetchFraudRuleFailure = createAction('[Fraud Rules] Fetch Fraud Rule Failure', props<{ id: string }>());
