import { Component, Input } from '@angular/core';

@Component({
  selector: 'admin-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() static = false;
  @Input() isShownText = true;
  @Input() title = 'Loading in progress';
  @Input() body = "Sit tight - we're working on your request!";
}
