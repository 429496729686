import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { TooltipModuleKey } from '../../types/tooltip-keys.type';
import { Tooltip } from '../../types/tooltips.type';

export const loadTooltips = createAction('[Tooltips] Load Tooltips', props<{ module: TooltipModuleKey }>());
export const loadTooltipsSuccess = createAction('[Tooltips] Load Tooltips Success', props<{ tooltips: Tooltip[] }>());
export const loadTooltipsFailure = createAction('[Tooltips] Load Tooltips Failure', props<ErrorState>());

export const updateTooltips = createAction(
  '[Tooltips] Update Tooltips',
  props<{ tooltips: Tooltip[]; dialogRefId: string }>()
);
export const updateTooltipsSuccess = createAction(
  '[Tooltips] Update Tooltips Success',
  props<{ tooltips: Tooltip[]; dialogRefId: string }>()
);
export const updateTooltipsFailure = createAction('[Tooltips] Update Tooltips Failure', props<ErrorState>());
