import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';

import { AppsService } from '../../services/apps.service';
import {
  loadCerberusConfig,
  loadCerberusConfigFailure,
  loadCerberusConfigSuccess,
  resetCerberusConfig,
  resetCerberusConfigFailure,
  resetCerberusConfigSuccess
} from '../actions/cerberus-configs.actions';

@Injectable()
export class CerberusConfigsEffects {
  loadCerberusConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCerberusConfig),
      exhaustMap(action =>
        this.appsService.getCerberusConfig(action.appId).pipe(
          map(cerberusConfig => loadCerberusConfigSuccess({ cerberusConfig, appId: action.appId })),
          catchError(error => of(loadCerberusConfigFailure({ error })))
        )
      )
    )
  );

  resetCerberusConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetCerberusConfig),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.appsService.resetCerberusConfig(action.appId).pipe(
          map(() => resetCerberusConfigSuccess({ appId: action.appId })),
          catchError(error => of(resetCerberusConfigFailure({ error })))
        )
      )
    )
  );

  resetCerberusConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetCerberusConfigSuccess),
      map(action => loadCerberusConfig({ appId: action.appId }))
    )
  );

  constructor(
    private actions$: Actions,
    private appsService: AppsService,
    private store: Store<any>
  ) {}
}
