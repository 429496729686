import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { MergeConfigsFilter } from '../../types/merge-configs-filter.type';
import { MergeConfig, MergeConfigPayload, MergeConfigsResult } from '../../types/merge-configs.type';

export const loadMergeConfigs = createAction(
  '[Merge Configs] Load Merge Configs',
  props<{ filter: MergeConfigsFilter }>()
);
export const loadMergeConfigsSuccess = createAction(
  '[Merge Configs] Load Merge Configs Success',
  props<{ result: MergeConfigsResult }>()
);
export const loadMergeConfigsFailure = createAction('[Merge Configs] Load Merge Configs Failure', props<ErrorState>());

export const loadMergeConfig = createAction('[Merge Configs] Load Merge Config', props<{ id: string }>());
export const loadMergeConfigSuccess = createAction(
  '[Merge Configs] Load Merge Config Success',
  props<{ mergeConfig: MergeConfig }>()
);
export const loadMergeConfigFailure = createAction('[Merge Configs] Load Merge Config Failure', props<ErrorState>());

export const createMergeConfig = createAction(
  '[Merge Configs] Create Merge Config',
  props<{ mergeConfigPayload: MergeConfigPayload }>()
);
export const createMergeConfigSuccess = createAction(
  '[Merge Configs] Create Merge Config Success',
  props<{ mergeConfig: MergeConfig }>()
);
export const createMergeConfigFailure = createAction(
  '[Merge Configs] Create Merge Config Failure',
  props<ErrorState>()
);

export const deleteMergeConfig = createAction('[Merge Configs] Delete Merge Config', props<{ id: string }>());
export const deleteMergeConfigSuccess = createAction(
  '[Merge Configs] Delete Merge Config Success',
  props<{ id: string }>()
);
export const deleteMergeConfigFailure = createAction(
  '[Merge Configs] Delete Merge Config Failure',
  props<ErrorState>()
);
