import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { ConfigurationChangesRequestParams } from '../../types';
import { ConfigurationChangesResult } from '../../types/configuration-changes-result.type';

export const loadConfigurationChanges = createAction(
  '[Rewards Offering] Load Configuration Changes',
  props<{
    params: ConfigurationChangesRequestParams;
  }>()
);

export const loadConfigurationChangesSuccess = createAction(
  '[Rewards Offering] Load Configuration Changes Success',
  props<{
    configurationChangesResult: ConfigurationChangesResult;
  }>()
);
export const loadConfigurationChangesFailure = createAction(
  '[Rewards Offering] Load Configuration Changes Failure',
  props<ErrorState>()
);
