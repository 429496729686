import { FeatureSettingProductKeys } from './nydus-network-partner-configuration.type';

export const RewardsOfferingStatusDisplayClass = {
  live: 'status-active',
  inactive: 'status-neutral'
};

export interface RewardsOfferingFilter {
  name: string;
  category: string;
  active: boolean;
}

export type TileDisplayConfigs = {
  [key in FeatureSettingProductKeys]?: boolean;
};
