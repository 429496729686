import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { membershipAdapter, MembershipState } from '../../types/memberships.type';

const getMembershipState = createFeatureSelector<MembershipState>('membership');

const { selectAll: getMembershipsList, selectEntities: getMembershipsDictionary } =
  membershipAdapter.getSelectors(getMembershipState);

const { isSingleLoading, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<MembershipState>(getMembershipState);

const getLocale = createSelector(getMembershipState, (state: MembershipState) => state.locale);

export const membershipsQuery = {
  isSingleLoading,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getMembershipsList,
  getMembershipsDictionary,
  getLocale
};
