import { Inject, Injectable } from '@angular/core';
import Rollbar, { Dictionary } from 'rollbar';

import { GuardhouseError } from '@core/errors/guardhouse-error';
import { HostTenantMappingUtils } from '@utils';

import { versions } from '../../git-revision';

const ROLLBAR_READ_ACCESS_TOKEN = 'd03fe40facb8497790afab62142b8dcd';

export interface IRollbarService {
  handleError(err: any): void;
  manualError(...args: any[]): void;
  manualGuardhouseError(error: GuardhouseError, ...args: any[]): void;
}

export type RollbarPayload = Dictionary & {
  body: {
    trace: {
      frames?: { filename: string }[];
    };
  };
};
@Injectable()
export class RollbarService implements IRollbarService {
  instance: Rollbar;

  constructor(@Inject('env') private env: string) {
    this.instance = new Rollbar(this.createRollbarConfig());
  }

  // Default error handler should print out the console errors, while manually triggered error methods should not.
  handleError(err: any): void {
    console.error(err);
    this.instance.error(err);
  }

  manualError(...args: any[]): void {
    this.instance.error(...args);
  }

  manualGuardhouseError(guardhouseError: GuardhouseError, ...args: any[]): void {
    const config = {
      payload: {
        fingerprint: guardhouseError.fingerprint,
        metadata: guardhouseError.error?.metadata
      }
    };
    this.instance.configure(config).error([guardhouseError, ...args]);
  }

  getRCTransform(): (data: Dictionary, item: Dictionary) => void | Promise<void> {
    return (payload: RollbarPayload) => {
      const trace = payload.body.trace;
      let locRegex;

      switch (this.env) {
        case 'staging': {
          locRegex = /^(https):\/\/[a-zA-Z0-9._-]+(.admin.us.kaligo-staging.xyz.*)/;
          break;
        }
        case 'production': {
          locRegex = /^(https):\/\/[a-zA-Z0-9._-]+(.admin.us.ascenda.com.*)/;
          break;
        }
      }

      if (trace?.frames) {
        for (const frame of trace.frames) {
          const filename = frame.filename;
          if (filename) {
            const m = filename.match(locRegex);

            if (m) {
              frame.filename = m[1] + '://dynamichost' + m[2];
            }
          }
        }
      }
    };
  }

  private createRollbarConfig(): Rollbar.Configuration {
    return {
      accessToken: ROLLBAR_READ_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      scrubFields: ['pan'],
      payload: {
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: versions.revision.trim(),
            // Optionally have Rollbar guess which frames the error was thrown from
            // when the browser does not provide line and column numbers.
            guess_uncaught_frames: true
          }
        },
        environment: this.env
      },
      ...(HostTenantMappingUtils.isRC() ? { transform: this.getRCTransform() } : {})
    };
  }
}
