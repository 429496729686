import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Webhook } from '../../types/order-webhooks.type';

export const loadOrderWebhooks = createAction('[Order Webhooks] Load Order Webhooks', props<{ orderId: string }>());
export const loadOrderWebhooksSuccess = createAction(
  '[Order Webhooks] Load Order Webhooks Success',
  props<{ webhooks: Webhook[] }>()
);
export const loadOrderWebhooksFailure = createAction(
  '[Order Webhooks] Load Order Webhooks Failure',
  props<ErrorState>()
);

export const resendOrderWebhook = createAction('[Order Webhooks] Resend Order Webhook', props<{ orderId: string }>());
export const resendOrderWebhookSuccess = createAction(
  '[Order Webhooks] Resend Order Webhook Success',
  props<{ orderId: string }>()
);
export const resendOrderWebhookFailure = createAction(
  '[Order Webhooks] Resend Order Webhook Failure',
  props<ErrorState>()
);
