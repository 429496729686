import { createFeatureSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { RedemptionState } from '../../types';

const getRedemptionState = createFeatureSelector<RedemptionState>('redemption');

const { isSingleLoading, isSingleResting, getSingleError } = getCallStateSelectors<RedemptionState>(getRedemptionState);

export const redemptionQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError
};
