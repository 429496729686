import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { nydusNetworkBootstrapQuery } from '@core/store/nydus-network/selectors/nydus-network-bootstrap.selectors';
import { NydusNetworkBootstrapState } from '@core/types';
import { Formatters, ObjectUtils, or, Params } from '@utils';

import { loyaltyProgramsQuery } from '../../../loyalty-programs/store/selectors/loyalty-programs.selectors';
import {
  LoyaltyProgram,
  LoyaltyProgramCategory,
  LoyaltyProgramState,
  TransferRate
} from '../../../loyalty-programs/types';
import { clearConvertedCurrency, convertCurrency } from '../../store/actions/currency-conversion.actions';
import { currencyConversionQuery } from '../../store/selectors/currency-conversion.selectors';
import { ConversionForm, CurrencyConversionState, SelectedAmount } from '../../types';

@Component({
  selector: 'admin-two-way-currency-conversion',
  templateUrl: './two-way-currency-conversion.component.html',
  styleUrls: ['./two-way-currency-conversion.component.scss']
})
export class TwoWayCurrencyConversionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loyaltyProgramId: string;
  @Input() redemptionType: string;
  @Output() amountSelected = new EventEmitter<SelectedAmount>();

  destroyRef = inject(DestroyRef);

  currencyConversionLoading$: Observable<boolean>;
  loading$: Observable<boolean>;
  conversionForm: FormGroup<ConversionForm>;
  homeCurrencyId: string;
  loyaltyPrograms: LoyaltyProgram[];
  targetCurrency: TransferRate;
  targetCurrencyLabel: string;
  userId: string;

  constructor(
    private fb: FormBuilder,
    private loyaltyProgramStore: Store<LoyaltyProgramState>,
    private nydusNetworkBootstrapStore: Store<NydusNetworkBootstrapState>,
    private route: ActivatedRoute,
    private store: Store<CurrencyConversionState>,
    @Inject('showPointsAccountsSelector') public showPointsAccountsSelector: boolean
  ) {}

  ngOnInit(): void {
    this.userId = Params.find(this.route, 'userId');

    this.conversionForm = this.fb.group({
      transferAmount: this.fb.control(null, Validators.required),
      redemptionAmount: this.fb.control(null, Validators.required)
    });

    this.currencyConversionLoading$ = this.store.select(currencyConversionQuery.isSingleLoading);
    this.loading$ = or(
      this.nydusNetworkBootstrapStore.select(nydusNetworkBootstrapQuery.isSingleLoading),
      this.loyaltyProgramStore.select(loyaltyProgramsQuery.isBatchLoading)
    );

    this.loyaltyProgramStore
      .select(loyaltyProgramsQuery.getLoyaltyProgramsList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loyaltyPrograms => {
        this.loyaltyPrograms = loyaltyPrograms;
        this.setTargetCurrency();
      });

    this.nydusNetworkBootstrapStore
      .select(nydusNetworkBootstrapQuery.getNydusNetworkBootstrapLoyaltyCurrency)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loyaltyCurrency => (this.homeCurrencyId = loyaltyCurrency?.id));

    // will always be enable for tenants with showPointsAccountsSelector set to false
    if (this.showPointsAccountsSelector && !this.loyaltyProgramId) {
      this.conversionForm.disable();
    }

    this.updateInputs();
    this.subscribeToTransferAmtChanges();
    this.subscribeToRedemptionAmtChanges();
  }

  ngOnChanges(): void {
    if (this.loyaltyProgramId) {
      this.conversionForm?.enable();
      this.setTargetCurrency();
    }
  }

  subscribeToTransferAmtChanges(): void {
    this.conversionForm.controls.transferAmount.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000))
      .subscribe(transferAmount => this.convertCurrency(transferAmount, null));
  }

  subscribeToRedemptionAmtChanges(): void {
    this.conversionForm.controls.redemptionAmount.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000))
      .subscribe(redemptionAmount => this.convertCurrency(null, redemptionAmount));
  }

  convertCurrency(transferAmount: number, redemptionAmount: number): void {
    if (ObjectUtils.isDefined(transferAmount) || ObjectUtils.isDefined(redemptionAmount)) {
      const currencyConversionData = {
        userId: this.userId,
        currencyConversion: {
          homeCurrencyId: this.homeCurrencyId,
          targetCurrencyId: this.targetCurrency.loyaltyCurrencyId,
          transferAmount,
          redemptionAmount
        }
      };
      this.store.dispatch(convertCurrency({ currencyConversionData }));
    }
  }

  setTargetCurrency(): void {
    const targetLoyaltyProgram = this.loyaltyPrograms?.find(loyaltyProgram => {
      if (this.loyaltyProgramId) {
        return loyaltyProgram.id === this.loyaltyProgramId;
      } else if (this.redemptionType === 'redeem') {
        return loyaltyProgram.category === 'cashback_to_account';
      }
    });

    this.targetCurrency = targetLoyaltyProgram?.transferRate;
    if (targetLoyaltyProgram?.category === LoyaltyProgramCategory.Airline) {
      this.targetCurrencyLabel = Formatters.toSentenceCase(targetLoyaltyProgram?.loyaltyCurrency?.name);
    } else {
      this.targetCurrencyLabel = 'Cash ($)';
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearConvertedCurrency());
  }

  private updateInputs(): void {
    this.store
      .select(currencyConversionQuery.getCurrencyConversion)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(current => {
        const { transferAmount: previousTransferAmt, redemptionAmount: previousRedemptionAmt } =
          this.conversionForm.value;

        const currentTransferAmt = current?.attributes.transferAmountBase;
        const currentRedemptionAmt = current?.attributes.redemptionAmount;

        if (previousTransferAmt === currentTransferAmt && previousRedemptionAmt === currentRedemptionAmt) {
          return;
        }

        if (currentTransferAmt || currentRedemptionAmt) {
          this.conversionForm.patchValue(
            {
              transferAmount: currentTransferAmt,
              redemptionAmount: currentRedemptionAmt
            },
            { emitEvent: false }
          );

          this.amountSelected.emit({ transferAmount: currentTransferAmt, redemptionAmount: currentRedemptionAmt });
        }
      });
  }
}
