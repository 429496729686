import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { apiSettingsAdapter, ApiSettingsState, initialState } from '../../types';
import {
  clearApiSettings,
  loadApiSettings,
  loadApiSettingsFailure,
  loadApiSettingsSuccess
} from '../actions/api-settings.actions';

export const apiSettingsTriggers: EntityTriggers = {
  single: {
    loading: [loadApiSettings.type],
    resting: [loadApiSettingsSuccess.type],
    erroring: [loadApiSettingsFailure.type]
  }
};

export function apiSettingsReducer(state: ApiSettingsState = initialState, action: Action): ApiSettingsState {
  return callStateReducer(baseReducer, apiSettingsTriggers)(state, action);
}

export const baseReducer = createReducer(
  initialState,
  on(loadApiSettingsSuccess, (state, { apiSettings }) => apiSettingsAdapter.setAll(apiSettings, state)),
  on(clearApiSettings, state => apiSettingsAdapter.setAll([], state))
);
