import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GuardhouseErrorCodes } from '@core/errors/error-codes.type';
import { HttpError } from '@core/types';
import { Token, TokenRequest } from '@core/types/generate-token.type';
import { ASCENDA_AUTH_PROVIDER, uuidv4 } from '@utils';

import { HermesService } from '../hermes/hermes.service';
import { WindowRefService } from '../window-ref/window-ref.service';

export interface LoginParams {
  client_id: string;
  connection?: string;
  state: string;
  scope: string;
  redirect_uri: string;
  response_type: string;
}

export interface LogoutParams {
  post_logout_redirect_uri?: string;
  reason?: GuardhouseErrorCodes;
  state?: string;
}

export interface StepUpParams {
  redirect_uri: string;
  level: number;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
export class GuardhouseService {
  constructor(
    @Inject('guardhouseApiUrl') private guardhouseApiUrl: string,
    @Inject('redirectUri') private redirectUri: string,
    private windowRef: WindowRefService,
    private hermesService: HermesService,
    private location: Location,
    private http: HttpClient
  ) {}

  redirectToLogoutUrl(reason: GuardhouseErrorCodes): void {
    const nonce = this.generateRandomNonce();
    this.hermesService.saveState(nonce);

    this.hermesService.removeRefreshToken();
    this.windowRef.nativeWindow.location.href = this.getLogoutUrl(nonce, reason).toString();
  }

  redirectToLoginUrl({
    isAscendaLogin,
    isAscendaRoute
  }: { isAscendaLogin?: boolean; isAscendaRoute?: boolean } = {}): void {
    const nonce = this.generateRandomNonce();

    let currentUrl;
    let connection;

    // All Ascenda logins happen through Google.
    if (isAscendaLogin) {
      connection = ASCENDA_AUTH_PROVIDER;
    }

    // If user is on `/ascenda` route, then we shouldn't save the current path.
    if (!isAscendaRoute) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('redirect_required');
      currentUrl = window.location.pathname + (urlParams.toString() ? `?${urlParams.toString()}` : '');
    }

    this.hermesService.saveState(nonce, { currentUrl, isAscendaLogin });
    this.hermesService.login(connection, { nonce, appState: { currentUrl, isAscendaLogin } });
  }

  redirectToStepUpUrl(error?: HttpError, stepUpAction?: Action): void {
    const nonce = this.generateRandomNonce();
    const acr = error ? error.errors[0]?.metadata?.level : 2;

    this.hermesService.saveState(nonce, { currentUrl: this.location.path(), stepUpAction, acr });
    this.windowRef.nativeWindow.location.href = this.getStepUpUrl(nonce, acr).toString();
  }

  generateToken(requestParam: TokenRequest): Observable<Token> {
    return this.http.post<Token>(`${this.guardhouseApiUrl}/oauth/token`, requestParam);
  }

  private getLogoutUrl(nonce: string, reason: GuardhouseErrorCodes): URL {
    const params: LogoutParams = {
      post_logout_redirect_uri: this.redirectUri,
      state: nonce,
      reason
    };

    return new URL(`${this.guardhouseApiUrl}/logout?${this.convertToQueryString(params)}`);
  }

  private getStepUpUrl(nonce: string, level: number): URL {
    const params = {
      redirect_uri: this.redirectUri,
      level,
      state: nonce
    };

    return new URL(`${this.guardhouseApiUrl}/step_up?${this.convertToQueryString(params)}`);
  }

  // generates random value to prevent replay attack. We use time based UUID generation
  private generateRandomNonce(): string {
    return uuidv4();
  }

  private convertToQueryString(params: LoginParams | LogoutParams | StepUpParams): string {
    return Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');
  }
}
