import { AfterViewInit, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { IframeConfig, IframeHandler } from '@kaligo/secure-fields-iframe';

import { SecureFieldsService } from '@shared/services/secure-fields.service';
import { DashedDateAdapter } from '@utils';

import {
  EnrollUserFormCustomEvent,
  EnrollUserFormValidationErrorEvent
} from '../../../users/types/enroll-user-form-bootstraps.type';
import { FormOpenApiSchema } from '../../services/form-open-api-schema';

@Component({
  selector: 'admin-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DashedDateAdapter
    }
  ]
})
export class FormFieldsComponent implements AfterViewInit {
  @ViewChildren(FormFieldsComponent) formFieldsComponents: QueryList<FormFieldsComponent>;
  @Input() formGroup: UntypedFormGroup;
  @Input() schema: FormOpenApiSchema;
  @Input() secureFieldIframeConfig: IframeConfig;
  @Output() tokenizeSuccess = new EventEmitter<EnrollUserFormCustomEvent>();
  @Output() validationError = new EventEmitter<EnrollUserFormValidationErrorEvent>();
  @Output() secureFieldLoading = new EventEmitter<boolean>();

  today = new Date();
  iframeHandler: IframeHandler;

  constructor(private secureFieldsService: SecureFieldsService) {}

  get isSecureFieldsLoading(): boolean {
    const formFieldsLoading = this.formFieldsComponents?.some(component => component.isSecureFieldsLoading);

    return !!(this.iframeHandler?.loading || formFieldsLoading);
  }

  ngAfterViewInit(): void {
    if (this.schema.value.properties) {
      Object.keys(this.schema.value.properties).forEach(key => {
        if (this.schema.isSpecifiedFormat(key, 'pan')) {
          const iframeConfig = {
            ...this.secureFieldIframeConfig,
            parentElementId: `${this.secureFieldIframeConfig.parentElementId}-${key}`,
            required: this.schema.isRequired(key),
            onTokenize: this.onTokenizeSuccess(key),
            onError: this.secureFieldsService.onTokenizeFailure(),
            onValidationError: this.onValidationError(key)
          } as IframeConfig;
          this.iframeHandler = new IframeHandler(iframeConfig);
          this.iframeHandler.init();
        }
      });
    }
  }

  getFormFieldLabel(fieldName: string): string {
    return fieldName.replace('Confirmation', ' Confirmation').split('_').join(' ');
  }

  onTokenizeSuccess(fieldName: string): (token: string) => void {
    return (token: string) => this.tokenizeSuccess.emit({ fieldName, fieldValue: token });
  }

  onValidationError(fieldName: string): (error: object) => void {
    return (error: object) => this.validationError.emit({ fieldName, validationError: error });
  }
}
