import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, filter, first, map, Observable } from 'rxjs';

import { CoreState } from '@core/store';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { DecodedUser } from '@core/types';
import { UUID_REGEX } from '@utils';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  private readonly ALLOWED_PERSONAL_PATHS = [
    `agents/(${UUID_REGEX.source})/details`,
    `agents/edit/(${UUID_REGEX.source})`
  ];
  private readonly ALLOWED_PERSONAL_PATHS_REGEX = new RegExp(`${this.ALLOWED_PERSONAL_PATHS.join('|')}`, 'g');

  constructor(private store: Store<CoreState>) {}

  hasPersonalRouteAccess(route: Route | ActivatedRouteSnapshot, segments?: UrlSegment[]): Observable<boolean> {
    // 1. AP will load User Abilities right after user logged in, so we don't need to check authQuery.isLoggedIn here
    // 2. AuthGuard will continue the procedure only if User Abilities are loaded
    // 3. If MC does not respond and return User Abilities in time, UserAbilityEffects will throw timeout error and logout user
    return combineLatest([
      this.store.select(authQuery.getUser),
      this.store.select(authQuery.isUserAbilitiesLoaded).pipe(filter(loaded => !!loaded))
    ]).pipe(
      first(),
      map(([user]) => user && this.isPersonalRoute(route, segments, user))
    );
  }

  // return true when user is visiting permitted personal route even without required scopes
  private isPersonalRoute(
    route: Route | ActivatedRouteSnapshot,
    urlSegments: UrlSegment[],
    user: DecodedUser
  ): boolean {
    const segments =
      route instanceof ActivatedRouteSnapshot
        ? route.pathFromRoot.flatMap(parent => parent.url) // when using canActivate
        : urlSegments; // when using canMatch

    const fullPath = segments.map(segment => segment.path).join('/');
    const pathMatchArrays = Array.from(fullPath.matchAll(this.ALLOWED_PERSONAL_PATHS_REGEX));

    const personalRouteSegments = pathMatchArrays[0]; // We expect there to be maximum one match
    return personalRouteSegments?.includes(user.sub);
  }
}
