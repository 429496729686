import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, TranslationState } from '../../types/translations.type';
import { loadTranslation, loadTranslationFailure, loadTranslationSuccess } from '../actions/translations.actions';

export const translationTriggers: EntityTriggers = {
  single: {
    loading: [loadTranslation.type],
    resting: [loadTranslationSuccess.type],
    erroring: [loadTranslationFailure.type]
  }
};

export function translationsReducer(state: TranslationState = initialState, action: Action): TranslationState {
  return callStateReducer(baseReducer, translationTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadTranslationSuccess, (state, { translation }) => ({
    ...state,
    translations: {
      ...state.translations,
      ...translation
    }
  }))
);
