import { FormControl } from '@angular/forms';
import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';
import { ImageType } from '@shared/types';

import { Campaign, CampaignsFilter, TimeAdjustmentPayload } from '../../types';

export const loadUserCampaigns = createAction('[Campaigns] Load User Campaigns', props<{ userId: string }>());
export const loadUserCampaignsSuccess = createAction(
  '[Campaigns] Load User Campaigns Success',
  props<{ campaigns: Campaign[] }>()
);
export const loadUserCampaignsFailure = createAction('[Campaigns] Load User Campaigns Failure', props<ErrorState>());

export const loadCampaigns = createAction('[Campaigns] Load Campaigns');
export const loadCampaignsSuccess = createAction(
  '[Campaigns] Load Campaigns Success',
  props<{ campaigns: Campaign[] }>()
);
export const loadCampaignsFailure = createAction('[Campaigns] Load Campaigns Failure', props<ErrorState>());

export const loadCampaign = createAction('[Campaigns] Load Campaign', props<{ id: string }>());
export const loadCampaignSuccess = createAction('[Campaigns] Load Campaign Success', props<{ campaign: Campaign }>());
export const loadCampaignFailure = createAction('[Campaigns] Load Campaign Failure', props<ErrorState>());

export const fetchCampaign = createAction('[Campaigns] Fetch Campaign', props<{ id: string }>());
export const fetchCampaignSuccess = createAction('[Campaigns] Fetch Campaign Success', props<{ campaign: Campaign }>());
export const fetchCampaignFailure = createAction('[Campaigns] Fetch Campaign Failure', props<{ id: string }>());

export const createCampaign = createAction('[Campaigns] Create Campaign', props<{ campaign: Campaign }>());
export const createCampaignSuccess = createAction(
  '[Campaigns] Create Campaign Success',
  props<{ campaign: Campaign }>()
);
export const createCampaignFailure = createAction('[Campaigns] Create Campaign Failure', props<ErrorState>());

export const updateCampaign = createAction('[Campaigns] Update Campaign', props<{ campaign: Campaign }>());
export const updateCampaignSuccess = createAction(
  '[Campaigns] Update Campaign Success',
  props<{ campaign: Campaign }>()
);
export const updateCampaignFailure = createAction('[Campaigns] Update Campaign Failure', props<ErrorState>());

export const startCampaign = createAction(
  '[Campaigns] Start Campaign',
  props<{ campaignId: string; dialogRefId: string }>()
);
export const startCampaignSuccess = createAction(
  '[Campaigns] Start Campaign Success',
  props<{ campaign: Campaign; dialogRefId: string }>()
);
export const startCampaignFailure = createAction('[Campaigns] Start Campaign Failure', props<ErrorState>());

export const stopCampaign = createAction(
  '[Campaigns] Stop Campaign',
  props<{ campaignId: string; dialogRefId: string }>()
);
export const stopCampaignSuccess = createAction(
  '[Campaigns] Stop Campaign Success',
  props<{ campaign: Campaign; dialogRefId: string }>()
);
export const stopCampaignFailure = createAction('[Campaigns] Stop Campaign Failure', props<ErrorState>());

export const adjustCampaign = createAction(
  '[Campaigns] Adjust Campaign',
  props<{ timeAdjustmentPayload: TimeAdjustmentPayload }>()
);
export const adjustCampaignSuccess = createAction(
  '[Campaigns] Adjust Campaign Success',
  props<{ campaign: Campaign; dialogRefId: string }>()
);
export const adjustCampaignFailure = createAction('[Campaigns] Adjust Campaign Failure', props<ErrorState>());

export const adjustCampaignActivityAndTime = createAction(
  '[Campaigns] Adjust Campaign Activity And Time',
  props<{ timeAdjustmentPayload: TimeAdjustmentPayload; active: boolean }>()
);
export const adjustCampaignActivityAndTimeSuccess = createAction(
  '[Campaigns] Adjust Campaign Activity And Time Success',
  props<{ campaign: Campaign; dialogRefId: string }>()
);
export const adjustCampaignActivityAndTimeFailure = createAction(
  '[Campaigns] Adjust Campaign Activity And Time Failure',
  props<ErrorState>()
);

export const uploadCampaignImage = createAction(
  '[Campaigns] Upload Campaign Image',
  props<{
    partnerReferenceId: string;
    campaignId?: string;
    file: FormData;
    dialogRefId: string;
    imageType: ImageType;
    formControl: FormControl<string>;
  }>()
);
export const uploadCampaignImageSuccess = createAction(
  '[Campaigns] Upload Campaign Image Success',
  props<{
    imageUrl: string;
    dialogRefId: string;
    imageType: ImageType;
    formControl: FormControl<string>;
  }>()
);
export const uploadCampaignImageFailure = createAction(
  '[Campaigns] Upload Campaign Image Failure',
  props<ErrorState>()
);

export const setCampaignsFilter = createAction(
  '[Campaigns] Set Campaigns Filter',
  props<{ filter: CampaignsFilter }>()
);
