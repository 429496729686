import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AlfredTenantsService } from '../../services/alfred-tenants.service';
import {
  loadAlfredTenants,
  loadAlfredTenantsFailure,
  loadAlfredTenantsSuccess
} from '../actions/alfred-tenants.actions';

@Injectable()
export class AlfredTenantsEffects {
  loadAlfredTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAlfredTenants),
      exhaustMap(() =>
        this.alfredTenantsService.getTenants().pipe(
          map(alfredTenants => loadAlfredTenantsSuccess({ alfredTenants })),
          catchError(error => of(loadAlfredTenantsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private alfredTenantsService: AlfredTenantsService
  ) {}
}
