import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';

import { NavItemV2 } from '@core/types/nav-item-v2.type';
import { injectUntilDestroyed } from '@utils';

import { ObjectUtils } from '../../../../../utils/object-utils';
import { SideNavV2Store } from '../side-nav-v2.store';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[admin-nav-v2-list-item], a[admin-nav-v2-list-item][cdkAccordionItem]',
  template: `
    <div class="nav-item-content-container flex flex-1 items-center gap-4">
      @if (navItem.iconName) {
        <mat-icon class="flex-shrink-0" [svgIcon]="navItem.iconName" />
      } @else if (isCollapsed()) {
        <div class="flex h-5 w-5 flex-shrink-0 justify-center text-base">•</div>
      }

      @if (!isCollapsed()) {
        <span class="flex-1">
          <ng-content></ng-content>
        </span>
      }
    </div>

    @if (!isCollapsed()) {
      @if (navItem.isProFeature) {
        <span class="m-0 rounded bg-shakespeare-200 px-2 py-[0.125rem] text-xs text-shakespeare-700">Pro</span>
      }

      @if (navItem.type === 'submenu' && cdAccordionItem) {
        <mat-icon class="flex-shrink-0">
          {{ cdAccordionItem.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      }
    }
  `,
  host: {
    class: 'nav-list-item'
  },
  styleUrl: './side-nav-v2-list-item.component.scss'
})
export class SideNavV2ListItemComponent implements OnInit {
  //#region Dependencies

  private sideNavStore = inject(SideNavV2Store);
  protected cdAccordionItem? = inject(CdkAccordionItem, {
    optional: true
  });
  private untilDestroyed = injectUntilDestroyed();

  //#endregion

  //#region Side nav state

  activatedRoutePath$ = this.sideNavStore.activatedRoutePath$;

  isCollapsed = this.sideNavStore.isCollapsed;

  private isActive = false;

  //#endregion

  //#region Inputs and host bindings

  @Input({ required: true }) navItem: NavItemV2;

  @HostBinding('class.sub-nav-list-item')
  @Input()
  isSubItem: boolean = false;

  @HostBinding('class.nav-list-item-active')
  get isActiveNavItem(): boolean {
    return this.navItem.type !== 'submenu' && this.isActive;
  }

  @HostBinding('class.nav-list-item-no-icon')
  get isNoIconNavItem(): boolean {
    return ObjectUtils.isNullish(this.navItem.iconName);
  }

  @HostBinding('class.nav-list-item-collapsed')
  get isCollapsedNavItem(): boolean {
    return this.isCollapsed();
  }

  @HostBinding('attr.data-testid')
  get dataTestId(): string {
    return `sidenav-${this.navItem.id}`;
  }

  //#endregion

  //#region Lifecycle hooks

  ngOnInit(): void {
    this.activatedRoutePath$.pipe(this.untilDestroyed()).subscribe(activatedRoutePath => {
      this.isActive = activatedRoutePath.includes(this.navItem.id);

      // We should open/close the submenu based on the item's active state
      if (this.navItem.type === 'submenu') {
        if (this.isActive) {
          this.cdAccordionItem?.open();
        } else {
          this.cdAccordionItem?.close();
        }
      }
    });
  }

  //#endregion
}
