import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { transformAttributeResponses } from '../../../../utils/logic-rule.utils';
import { SegmentsService } from '../../services/segments.service';
import {
  loadSegmentAttributes,
  loadSegmentAttributesFailure,
  loadSegmentAttributesSuccess,
  loadSegmentPreview,
  loadSegmentPreviewFailure,
  loadSegmentPreviewSuccess
} from '../actions/segment-creation.actions';

@Injectable()
export class SegmentCreationEffects {
  loadAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSegmentAttributes),
      switchMap(() =>
        this.segmentsService.getAttributes().pipe(
          map(response =>
            loadSegmentAttributesSuccess({
              attributeMap: transformAttributeResponses(response)
            })
          ),
          catchError(error => of(loadSegmentAttributesFailure({ error })))
        )
      )
    )
  );

  loadPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSegmentPreview),
      switchMap(({ logics }) =>
        this.segmentsService.getPreview(logics).pipe(
          map(({ memberCount }) => loadSegmentPreviewSuccess({ memberCount })),
          catchError(error => of(loadSegmentPreviewFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private segmentsService: SegmentsService
  ) {}
}
