var UrlParamsUtils = /** @class */ (function () {
    function UrlParamsUtils() {
    }
    UrlParamsUtils.getUrlParam = function (key) {
        var queryString = window.location.search;
        if (queryString) {
            var urlParams = new URLSearchParams(queryString);
            return urlParams.get(key) || '';
        }
        return '';
    };
    UrlParamsUtils.buildUrlParam = function (params) {
        var urlParams = new URLSearchParams();
        Object.entries(params).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            return urlParams.append(key, value);
        });
        return urlParams.toString();
    };
    return UrlParamsUtils;
}());
export { UrlParamsUtils };
