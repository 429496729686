import { initialCallState } from '@core/store/call-state';
import { CallState, Change } from '@core/types';

import { ApplicationComponents, Features, Homepage, Theme, UiSettings } from './ui-settings.type';

export interface PartnerConfig {
  version: number;
  uiSettings: UiSettings;
  uiSettingsSchema?: object;
}

export const toggleableProducts = [
  'frequentTravelerPrograms',
  'cashback',
  'crypto',
  'sustainability',
  'giftCards'
] as const;
export type ToggleableProduct = (typeof toggleableProducts)[number];

export const productActivationTargets = [
  'frequent_traveler_programs',
  'cashback',
  'crypto',
  'sustainability',
  'gift_cards'
];
export type ProductActivationTarget = (typeof productActivationTargets)[number];

export const targetToFeatureKeyMapping = {
  cashback: 'cashRedemption',
  frequent_traveler_programs: 'pointsTransfer',
  crypto: 'crypto',
  sustainability: 'sustainability',
  gift_cards: 'giftCard'
};

export const targetToProductNameMapping = {
  cashback: 'cashback products',
  frequent_traveler_programs: 'frequent travler programs',
  crypto: 'crypto products',
  sustainability: 'sustainability products',
  gift_cards: 'gift cards'
};

export interface UpdateWebsiteStylingPayload {
  homepage?: Homepage;
  components?: ApplicationComponents;
  features?: Features;
  theme?: Theme;
}

export interface UpdateWebsiteStylingRequest extends UpdateWebsiteStylingPayload {
  changes: Change[];
  version: number;
}

export interface UpdateUiSettingsRequest {
  uiSettings: UiSettings;
  changes: Change[];
  version: number;
}

export interface PartnerConfigState extends CallState {
  partnerConfig: PartnerConfig;
}

export const initialState: PartnerConfigState = {
  ...initialCallState,
  partnerConfig: null
};

export const enum PartnerConfigImageType {
  BRANDING = 'branding',
  HOMEPAGE = 'homepage'
}
