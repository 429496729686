import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { EventType, EventTypeFilter } from '../../types';

export const loadEventTypes = createAction('[EventTypes] Load EventTypes');
export const loadEventTypesSuccess = createAction(
  '[EventTypes] Load EventTypes Success',
  props<{ eventTypes: EventType[] }>()
);
export const loadEventTypesFailure = createAction('[EventTypes] Load EventTypes Failure', props<ErrorState>());

export const loadEventType = createAction('[EventTypes] Load EventType', props<{ id: string }>());
export const loadEventTypeSuccess = createAction(
  '[EventTypes] Load EventType Success',
  props<{ eventType: EventType }>()
);
export const loadEventTypeFailure = createAction('[EventTypes] Load EventType Failure', props<ErrorState>());

export const createEventType = createAction('[EventTypes] Create EventType', props<{ eventType: EventType }>());
export const createEventTypeSuccess = createAction(
  '[EventTypes] Create EventType Success',
  (
    eventType: EventType,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully created an event'
    }
  ) => ({ eventType, snackbarMessages })
);
export const createEventTypeFailure = createAction(
  '[EventTypes] Create EventType Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not create your event due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateEventType = createAction('[EventTypes] Update EventType', props<{ eventType: EventType }>());
export const updateEventTypeSuccess = createAction(
  '[EventTypes] Update EventType Success',
  (
    eventType: EventType,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully.'
    }
  ) => ({ eventType, snackbarMessages })
);
export const updateEventTypeFailure = createAction(
  '[EventTypes] Update EventType Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not save your changes due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const deleteEventType = createAction('[EventTypes] Delete EventType', props<{ id: string }>());
export const deleteEventTypeSuccess = createAction(
  '[EventTypes] Delete EventType Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully deleted an event'
    }
  ) => ({ snackbarMessages })
);
export const deleteEventTypeFailure = createAction(
  '[EventTypes] Delete EventType Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not delete your event due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const setEventTypesFilter = createAction(
  '[EventTypes] Set EventTypes Filter',
  props<{ filter: EventTypeFilter }>()
);
