<div class="expiring-dialog-header">
  <div (click)="dialogRef.close()">
    <mat-icon class="close-dialog-button" svgIcon="xmark"></mat-icon>
  </div>
  <h2 class="medium-text">Expiring points</h2>
  <div class="account">
    <div class="account__balance medium-text">
      <mat-icon svgIcon="dollar-coin"></mat-icon>
      {{
        (selectedPointsAccount$ | async).pointsBalance - data.evergreenPoints
          | roundedNumber: data.decimalPointsToDisplay
      }}
      {{ loyaltyCurrency | titlecase }}
    </div>
    <div class="account__number medium-text" *ngIf="showPointsAccountsSelector">
      Ending with {{ (selectedPointsAccount$ | async).enrollments[0].groupReferenceId.slice(-4) }}
      {{ (selectedPointsAccount$ | async).status === 'active' ? '' : (selectedPointsAccount$ | async).status }}
    </div>
  </div>
</div>

<div class="table-container">
  <table mat-table [dataSource]="data.pointsSummaryTranches">
    <ng-container matColumnDef="expiryDate">
      <th mat-header-cell *matHeaderCellDef>Expiry date</th>
      <td mat-cell *matCellDef="let tranche">{{ [tranche.expiryDate] | dayjsDateFormat: 'DD MMM YY' }}</td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>Points</th>
      <td mat-cell *matCellDef="let tranche">{{ tranche.balance | roundedNumber: data.decimalPointsToDisplay }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
