import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { App } from '../../types/apps.type';

export const loadApps = createAction('[Apps] Load Apps', props<{ tenantId?: string }>());
export const loadAppsSuccess = createAction('[Apps] Load Apps Success', props<{ apps: App[] }>());
export const loadAppsFailure = createAction('[Apps] Load Apps Failure', props<ErrorState>());

export const loadApp = createAction('[Apps] Load App', props<{ appId: string }>());
export const loadAppSuccess = createAction('[Apps] Load App Success', props<{ app: App }>());
export const loadAppFailure = createAction('[Apps] Load App Failure', props<ErrorState>());

export const fetchApp = createAction('[Apps] Fetch App', props<{ id: string }>());
export const fetchAppSuccess = createAction('[Apps] Fetch App Success', props<{ app: App }>());
export const fetchAppFailure = createAction('[Apps] Fetch App Failure', props<{ id: string }>());

export const loadAppSecret = createAction('[Apps] Load App Secret', props<{ appId: string }>());
export const loadAppSecretSuccess = createAction(
  '[Apps] Load App Secret Success',
  props<{ appId: string; secret: string }>()
);
export const loadAppSecretFailure = createAction('[Apps] Load App Secret Failure', props<ErrorState>());

export const createApp = createAction('[Apps] Create App', props<{ app: Partial<App> }>());
export const createAppSuccess = createAction('[Apps] Create App Success', props<{ app: App }>());
export const createAppFailure = createAction('[Apps] Create App Failure', props<ErrorState>());

export const updateApp = createAction('[Apps] Update App', props<{ app: Partial<App> }>());
export const updateAppSuccess = createAction('[Apps] Update App Success', props<{ app: App }>());
export const updateAppFailure = createAction('[Apps] Update App Failure', props<ErrorState>());
