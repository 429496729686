import { Component, Input, OnInit } from '@angular/core';

import { SCOPES_OR } from '@core/types';

import {
  CarBookingData,
  FlightBookingData,
  HotelBookingData,
  OrderItem,
  TravelBookingOrderItemData
} from '../../types';

@Component({
  selector: 'admin-travel-booking-order-item',
  templateUrl: './travel-booking-order-item.component.html',
  styleUrls: ['../../stylesheets/order-item.styles.scss', './travel-booking-order-item.component.scss']
})
export class TravelBookingOrderItemComponent implements OnInit {
  @Input() orderItem: OrderItem;
  @Input() customerId: string;
  @Input() timezone: string;

  orderItemData: TravelBookingOrderItemData;
  hotelBookingData: HotelBookingData;
  carBookingData: CarBookingData;
  flightBookingData: FlightBookingData;
  readonly SCOPES = SCOPES_OR;

  ngOnInit(): void {
    this.orderItemData = this.orderItem.data as TravelBookingOrderItemData;
    const travelType = this.orderItemData.travelType;

    if (travelType === 'hotels') {
      this.hotelBookingData = this.orderItemData as HotelBookingData;
    } else if (travelType === 'cars') {
      this.carBookingData = this.orderItemData as CarBookingData;
    } else if (travelType === 'flights') {
      this.flightBookingData = this.orderItemData as FlightBookingData;
    }
  }
}
