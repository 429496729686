import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { GiftCardOrdersFilter } from './gift-card-orders-filter.type';

export interface GiftCardOrder {
  id: string;
  userId: string;
  tenantId: string;
  reference: string;
  quantity: number;
  status: string;
  customData: object;
  recipient: Recipient;
  supplierStatus?: GiftCardOrderStatus;
  updatedAt: string;
  giftCards: Card[];
  errors: string[];
}

export interface Recipient {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  message?: string;
}

export interface Card {
  code: string;
  downloadUrl: string;
  viewUrl: string;
  expiresAt: string;
}

export interface GiftCardOrderStatus {
  id: string;
  supplier: string;
  reference: string;
  supplierReference: string;
  status: string;
}

export const giftCardOrderAdapter = createEntityAdapter<GiftCardOrder>();

export interface GiftCardOrderState extends EntityState<GiftCardOrder>, CallState {
  count: number;
  filter: GiftCardOrdersFilter;
}

export const initialState: GiftCardOrderState = {
  ...giftCardOrderAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  filter: null
};
