import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { NydusNetworkPartnerConfigurationsService } from '../../services/nydus-network-partner-configurations.service';
import {
  loadNydusNetworkPartnerConfiguration,
  loadNydusNetworkPartnerConfigurationFailure,
  loadNydusNetworkPartnerConfigurationSuccess
} from '../actions/nydus-network-partner-configurations.actions';

@Injectable()
export class NydusNetworkPartnerConfigurationsEffects {
  loadNydusNetworkPartnerConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNydusNetworkPartnerConfiguration),
      exhaustMap(() =>
        this.service.getPartnerConfiguration().pipe(
          map(result => loadNydusNetworkPartnerConfigurationSuccess({ partnerConfiguration: result.data.attributes })),
          catchError(error => of(loadNydusNetworkPartnerConfigurationFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: NydusNetworkPartnerConfigurationsService
  ) {}
}
