<div class="header-outside-section" *adminIfScopesInclude="SCOPES.viewMfaIdentities; relationOperator: 'or'">
  <div class="flex items-center justify-between" *ngIf="user">
    <div class="subsection-header">MFA</div>

    <button
      *adminIfScopesInclude="SCOPES.createMfaIdentities; relationOperator: 'or'"
      class="borderless-button"
      (click)="openCreateMfaIdentityDialog()"
    >
      <mat-icon svgIcon="add"></mat-icon>
      Create MFA identity
    </button>
  </div>

  <div class="sticky-table-wrapper mfa-identities-table">
    <table mat-table [dataSource]="userMfaIdentity$ | async">
      <ng-container
        matColumnDef="tenantId"
        [sticky]="columnConfig['tenantId']?.isSticky"
        [stickyEnd]="columnConfig['tenantId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['tenantId']?.headerClass">Tenant ID</th>
        <td mat-cell *matCellDef="let mfaIdentity" [ngClass]="columnConfig['tenantId']?.cellClass">
          {{ mfaIdentity.tenantId }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="uid"
        [sticky]="columnConfig['uid']?.isSticky"
        [stickyEnd]="columnConfig['uid']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['uid']?.headerClass">Login ID</th>
        <td mat-cell *matCellDef="let mfaIdentity" [ngClass]="columnConfig['uid']?.cellClass">
          {{ mfaIdentity.uid }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="providerId"
        [sticky]="columnConfig['providerId']?.isSticky"
        [stickyEnd]="columnConfig['providerId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['providerId']?.headerClass">Provided by</th>
        <td mat-cell *matCellDef="let mfaIdentity" [ngClass]="columnConfig['providerId']?.cellClass">
          {{ mfaIdentity.providerId }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="verified"
        [sticky]="columnConfig['providerId']?.isSticky"
        [stickyEnd]="columnConfig['providerId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['providerId']?.headerClass">Verified</th>
        <td mat-cell *matCellDef="let mfaIdentity" [ngClass]="columnConfig['providerId']?.cellClass">
          {{ mfaIdentity.verified ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="actions"
        [sticky]="columnConfig['actions']?.isSticky"
        [stickyEnd]="columnConfig['actions']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['actions']?.headerClass">Actions</th>
        <td mat-cell *matCellDef="let mfaIdentity" [ngClass]="columnConfig['actions']?.cellClass">
          <ng-container *adminIfScopesInclude="SCOPES.updateMfaIdentities; relationOperator: 'or'">
            <button
              *ngIf="!(mfaIdentity | hideMfaIdentityButtonPipe: 'edit')"
              mat-icon-button
              (click)="openEditMfaIdentityDialog(mfaIdentity)"
            >
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
          </ng-container>

          <ng-container *adminIfScopesInclude="SCOPES.verifyIdentities; relationOperator: 'or'">
            <button
              *ngIf="mfaIdentity.providerId === 'email'"
              mat-icon-button
              (click)="onMfaActions({ actionType: 'sendVerification', mfaIdentity })"
            >
              <mat-icon svgIcon="send"></mat-icon>
            </button>
          </ng-container>

          <ng-container *adminIfScopesInclude="SCOPES.deleteMfaIdentities; relationOperator: 'or'">
            <admin-tooltip key="users.details.button.deleteMfaIdentity" [displayIcon]="false" class="inline-block">
              <button
                *ngIf="!(mfaIdentity | hideMfaIdentityButtonPipe: 'delete')"
                mat-icon-button
                (click)="onMfaActions({ actionType: 'deleteMfaIdentity', mfaIdentity })"
              >
                <mat-icon svgIcon="remove"></mat-icon>
              </button>
            </admin-tooltip>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <admin-empty-state-table></admin-empty-state-table>
        </td>
      </tr>
    </table>
  </div>
</div>
