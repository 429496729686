import { first, map } from 'rxjs/operators';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadUserTokens } from '../store/actions/user-tokens.actions';
import { userTokensQuery } from '../store/selectors/user-tokens.selectors';
import { UserTokensFilter, UserTokenState } from '../types';

export const userTokensResolver: ResolveFn<UserTokensFilter> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<UserTokenState> = inject(Store<UserTokenState>),
  scopes: Scopes = inject(Scopes)
): Observable<UserTokensFilter> => {
  if (scopes.lackScopes(SCOPES_OR.viewUserTokens)) {
    return of(null);
  }

  const userId = Params.find(route, 'userId');

  return combineLatest([
    store.select(userTokensQuery.isUserTokensLoaded(userId)),
    store.select(userTokensQuery.getFilter)
  ]).pipe(
    first(),
    map(([isLoaded, filter]) => {
      if (!isLoaded) {
        store.dispatch(loadUserTokens({ userId, filter: new UserTokensFilter() }));
        return new UserTokensFilter();
      }
      return filter || new UserTokensFilter();
    })
  );
};
