import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { FraudListsService } from '../../services/fraud-lists.service';
import {
  createFraudList,
  createFraudListFailure,
  createFraudListSuccess,
  deleteFraudList,
  deleteFraudListFailure,
  deleteFraudListSuccess,
  loadFraudList,
  loadFraudListFailure,
  loadFraudLists,
  loadFraudListsFailure,
  loadFraudListsSuccess,
  loadFraudListSuccess,
  resetFraudListCounter,
  resetFraudListCounterFailure,
  resetFraudListCounterSuccess,
  updateFraudList,
  updateFraudListFailure,
  updateFraudListSuccess
} from '../actions/fraud-lists.actions';

@Injectable()
export class FraudListsEffects {
  loadFraudLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFraudLists),
      exhaustMap(() =>
        this.fraudListsService.getFraudLists().pipe(
          map(fraudLists => loadFraudListsSuccess({ fraudLists })),
          catchError(error => of(loadFraudListsFailure({ error })))
        )
      )
    )
  );

  loadFraudList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFraudList),
      exhaustMap(action =>
        this.fraudListsService.getFraudList(action.id).pipe(
          map(fraudList => loadFraudListSuccess({ fraudList })),
          catchError(error => of(loadFraudListFailure({ error })))
        )
      )
    )
  );

  createFraudList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFraudList),
      mergeMap(action =>
        this.fraudListsService.createFraudList(action.fraudList).pipe(
          map(fraudList => createFraudListSuccess({ fraudList })),
          catchError(error => of(createFraudListFailure({ error })))
        )
      )
    )
  );

  updateFraudList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFraudList),
      mergeMap(action =>
        this.fraudListsService.updateFraudList(action.fraudList).pipe(
          map(fraudList => updateFraudListSuccess({ fraudList })),
          catchError(error => of(updateFraudListFailure({ error })))
        )
      )
    )
  );

  deleteFraudList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFraudList),
      exhaustMap(action =>
        this.fraudListsService.deleteFraudList(action.id).pipe(
          map(() => deleteFraudListSuccess({ id: action.id })),
          catchError(error => of(deleteFraudListFailure({ error })))
        )
      )
    )
  );

  createOrUpdateFraudListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFraudListSuccess, updateFraudListSuccess),
      map(() => routerNavigate({ path: 'fraud-lists' }))
    )
  );

  resetFraudListCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetFraudListCounter),
      exhaustMap(({ id, item }) =>
        this.fraudListsService.resetFraudListCounter(id, item).pipe(
          map(() => resetFraudListCounterSuccess({ id })),
          catchError(error => of(resetFraudListCounterFailure({ error })))
        )
      )
    )
  );

  resetFraudListCounterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetFraudListCounterSuccess),
      map(action => loadFraudList({ id: action.id }))
    )
  );

  constructor(
    private actions$: Actions,
    private fraudListsService: FraudListsService
  ) {}
}
