import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types/call-state.type';

export interface CurrencyConversionData {
  userId: string;
  currencyConversion: {
    homeCurrencyId: string;
    targetCurrencyId: string;
    redemptionAmount?: number;
    transferAmount?: number;
  };
}

export interface ConvertedCurrency {
  id: string;
  type: string;
  attributes: {
    redemptionAmount: number;
    transferAmountBase: number;
  };
}

export interface SelectedAmount {
  transferAmount: number;
  redemptionAmount: number;
}

export interface CurrencyConversionState extends CallState {
  currencyConversion: ConvertedCurrency;
}

export const currencyConversionInitialState: CurrencyConversionState = {
  ...initialCallState,
  currencyConversion: null
};
