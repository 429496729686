import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface PointsStatement {
  id: string;
  userId: string;
  pointsAccountId: string;
  description: string;
  statementDate: string;
  totalPoints: number;
  totalSpend: number;
  currency: string;
  transactions: Transaction[];
}

export interface Transaction {
  type: string;
  transactionDate: string;
  postingDate: string;
  merchant: string;
  amount: number;
  points: number;
  ruleDescription: RuleDescription[];
  metadata?: object;
  productReferenceId: string;
}

export interface RuleDescription {
  points: number;
  rule: string;
}

export interface PointsStatementState extends CallState {
  pointsStatement: PointsStatement;
}

export const pointsStatementInitialState: PointsStatementState = {
  ...initialCallState,
  pointsStatement: null
};
