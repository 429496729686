import { tap } from 'rxjs/operators';

import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AttributeUtils } from '@core/services/attribute-utils/attribute-utils.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AttributeDef, BooleanPolicySettings, SCOPES_OR } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { AccessPolicyUtils, Formatters } from '@utils';

import { verifyIdentity } from '../../store/actions/identities.actions';
import { deleteMfaIdentity } from '../../store/actions/mfa-identities.actions';
import { mfaIdentitiesQuery } from '../../store/selectors/mfa-identities.selectors';
import { usersQuery } from '../../store/selectors/users.selectors';
import { IdentityState, MfaIdentity, MfaIdentityState, User, UserState } from '../../types';
import { CreateMfaIdentityDialogComponent } from '../create-mfa-identity-dialog/create-mfa-identity-dialog.component';
import { EditMfaIdentityDialogComponent } from '../edit-mfa-identity-dialog/edit-mfa-identity-dialog.component';

interface MfaActionsParams {
  actionType: 'sendVerification' | 'deleteMfaIdentity';
  mfaIdentity: MfaIdentity;
}

@Component({
  selector: 'admin-mfa-identities',
  templateUrl: './mfa-identities.component.html',
  styleUrls: ['../../views/user-details/user-details.component.scss']
})
export class MfaIdentitiesComponent implements OnInit {
  @Input() user: User;

  destroyRef = inject(DestroyRef);

  readonly mfaIdentityDefs: AttributeDef<MfaIdentity>[] = [
    { key: 'tenantId', label: 'Tenant ID' },
    { key: 'uid', label: 'Login ID' },
    { key: 'providerId', label: 'Provided by' },
    { key: 'verified', label: 'Verified', formatter: Formatters.booleanFormatter },
    { key: 'customData', label: 'Custom data', formatter: Formatters.jsonFormatter }
  ];
  readonly SCOPES = SCOPES_OR;

  loading$: Observable<boolean>;
  userMfaIdentity$: Observable<MfaIdentity[]>;
  mfaIdentityColumns: AttributeDef<MfaIdentity>[];
  mfaIdentityColumnKeys = [];

  constructor(
    public attributeUtils: AttributeUtils,
    private accessPolicies: AccessPolicies,
    private matDialog: MatDialog,
    private scopes: Scopes,
    private store: Store<UserState>,
    private identityStore: Store<IdentityState>,
    private mfaIdentityStore: Store<MfaIdentityState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.mfaIdentityStore.select(mfaIdentitiesQuery.isSingleLoading);

    this.mfaIdentityStore
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        select(mfaIdentitiesQuery.isMfaIdentitiesLoaded(this.user.id)),
        tap(isLoaded => {
          this.userMfaIdentity$ = isLoaded
            ? this.identityStore.select(mfaIdentitiesQuery.getMfaIdentitiesList)
            : this.store.select(usersQuery.getUserPropertyById<MfaIdentity[]>(this.user.id, ['mfaIdentities']));
        })
      )
      .subscribe();

    if (
      this.scopes.hasAny(['identities:verify', 'mfa_identities:update', 'mfa_identities:destroy', 'manage_identities'])
    ) {
      this.mfaIdentityDefs.unshift({ key: 'id', label: '' });
    }

    const settings = this.accessPolicies.getResponseSettings('users', 'show');
    const usersShowMfaIdentitySettings = settings?.mfaIdentities as BooleanPolicySettings;
    const mfaIdentitiesIndexSettings = this.accessPolicies.getResponseSettings(
      'mfa_identities',
      'index'
    ) as BooleanPolicySettings;

    [this.mfaIdentityColumns, this.mfaIdentityColumnKeys] = this.attributeUtils.filterColumns(
      this.mfaIdentityDefs,
      AccessPolicyUtils.mergeBooleanPolicySettings(mfaIdentitiesIndexSettings, usersShowMfaIdentitySettings)
    );
  }

  openEditMfaIdentityDialog(mfaIdentity: MfaIdentity): void {
    this.matDialog.open(EditMfaIdentityDialogComponent, {
      data: { mfaIdentity, userId: this.user.id }
    });
  }

  openCreateMfaIdentityDialog(): void {
    this.matDialog.open(CreateMfaIdentityDialogComponent, {
      data: { userId: this.user.id }
    });
  }

  onMfaActions(params: MfaActionsParams): void {
    const { actionType, mfaIdentity } = params;
    const id = mfaIdentity.id;
    const agentCreated = mfaIdentity.customData.agentCreated ?? false;

    let dialogTitle: string;
    let confirmText: string;
    let confirmButtonText: string;
    let action: Action;

    switch (actionType) {
      case 'sendVerification': {
        dialogTitle = 'Send account verification';
        confirmText = 'Are you sure you want to send verification for this identity?';
        confirmButtonText = 'Yes, send verification';
        action = verifyIdentity({ mfaIdentityId: id });
        break;
      }
      case 'deleteMfaIdentity': {
        dialogTitle = 'Delete MFA Identity';
        confirmText = 'Are you sure you want to delete MFA identity?';
        confirmButtonText = 'Yes, delete MFA identity';
        action = deleteMfaIdentity({
          params: { id, agentCreated }, // pass agentCreated to MC for request validation
          user: this.user
        });
        break;
      }
      default: {
        return;
      }
    }

    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          dialogTitle,
          confirmText,
          confirmButtonText,
          styleClassName: 'content-centered-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(action);
        }
      });
  }
}
