import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { LoginAttemptsFilter } from '../types/login-attempts-filter.type';
import { LoginAttemptsResult } from '../types/login-attempts-result.type';
import { LoginAttempt } from '../types/login-attempts.type';

@Injectable({
  providedIn: 'root'
})
export class LoginAttemptsService {
  constructor(private missionControlService: MissionControlService) {}

  getLoginAttempts(filter: LoginAttemptsFilter): Observable<LoginAttemptsResult> {
    filter = ObjectUtils.sanitizeRequestObject<LoginAttemptsFilter>(filter);

    const { createdAt, sortDirection, sortBy, isFromUserView, ...rest } = filter;
    let params = ObjectUtils.prepareQueryObject(rest);

    params = FilterUtils.appendHashQueryParam(params, 'sortBy', sortBy, sortDirection);
    params = FilterUtils.appendDateRange(params, createdAt, 'createdAt');

    return this.missionControlService.get<LoginAttemptsResult>(
      'login_attempts',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getLoginAttempt(id: string): Observable<LoginAttempt> {
    return this.missionControlService.get<LoginAttempt>(`login_attempts/${id}`);
  }
}
