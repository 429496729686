import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { from, Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { SCOPES_OR } from '@core/types';
import { AccessPolicyUtils } from '@utils';

export const customersRedirectionGuard: CanMatchFn = (_route: Route, segments: UrlSegment[]): Observable<boolean> => {
  const accessPolicies: AccessPolicies = inject(AccessPolicies);
  const router: Router = inject(Router);
  const scopes: Scopes = inject(Scopes);

  // redirect users to /enrollments path when
  // 1. user has view_enrollments scenario scope, and
  // 2. user comes from /customers path, and
  // 3. user is not allowed to see /customers path
  const visibleSettings = accessPolicies.getFrontendSettings('sidenav', 'show');

  if (
    scopes.hasAny(SCOPES_OR.viewEnrollments) &&
    segments[0].path === 'customers' &&
    AccessPolicyUtils.isTargetPageInvisible(visibleSettings, 'customers')
  ) {
    const redirectPath = window.location.pathname.replace('customers', 'enrollments');
    router.navigateByUrl(redirectPath);
    return from(router.navigateByUrl(redirectPath));
  }

  return of(true);
};
