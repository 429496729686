import { map } from 'rxjs/operators';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { NotificationResponse, OrderItem, OrderItemResult, OrderItemsFilter, RawNotificationResponse } from '../types';

@Injectable({ providedIn: 'root' })
export class OrderItemsService {
  constructor(private missionControlService: MissionControlService) {}

  getOrderItems(filter: OrderItemsFilter): Observable<OrderItemResult> {
    filter = ObjectUtils.sanitizeRequestObject<OrderItemsFilter>(filter);

    const { sortDirection, sortBy, isTopLevelView, ...rest } = filter;
    let params = ObjectUtils.prepareQueryObject(rest);
    params = FilterUtils.appendHashQueryParam(params, 'sortBy', sortBy, sortDirection);

    return this.missionControlService.get<OrderItemResult>(
      'order_items',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getOrderItem(id: string): Observable<OrderItem> {
    return this.missionControlService.get<OrderItem>(`order_items/${id}`);
  }

  sendNotification(notificationId: string): Observable<NotificationResponse> {
    return this.missionControlService
      .post<RawNotificationResponse>(`notifications/${notificationId}/deliveries`)
      .pipe(
        map(
          (result: RawNotificationResponse) =>
            ObjectUtils.flattenObjectByKeys(result, ['data', 'attributes', 'relationships']) as NotificationResponse
        )
      );
  }

  updateOrderItemStatus(id: string): Observable<void> {
    return this.missionControlService.patch<void>(`order_items/${id}/status`);
  }

  refundOrderItem(id: string): Observable<void> {
    return this.missionControlService.patch<void>(`order_items/${id}/cancel`);
  }
}
