import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialUserOfferActivityState, userOfferActivityAdapter, UserOfferActivityState } from '../../types';
import {
  loadUserOfferActivities,
  loadUserOfferActivitiesFailure,
  loadUserOfferActivitiesSuccess
} from '../actions/user-offer-activities.actions';

export const userOfferActivityTriggers: EntityTriggers = {
  batch: {
    loading: [loadUserOfferActivities.type],
    resting: [loadUserOfferActivitiesSuccess.type],
    erroring: [loadUserOfferActivitiesFailure.type]
  }
};

export function userOfferActivitiesReducer(
  state: UserOfferActivityState = initialUserOfferActivityState,
  action: Action
): UserOfferActivityState {
  return callStateReducer(baseReducer, userOfferActivityTriggers)(state, action);
}

const baseReducer = createReducer(
  initialUserOfferActivityState,
  on(loadUserOfferActivities, (state, { filter }) => ({ ...state, filter })),
  on(loadUserOfferActivitiesSuccess, (state, { result: { data, metadata }, userId }) => ({
    ...userOfferActivityAdapter.setAll(data, state),
    userId,
    metadata
  }))
);
