import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { alfredTenantsAdapter, AlfredTenantsState, initialState } from '../../types/alfred-tenants.type';
import {
  loadAlfredTenants,
  loadAlfredTenantsFailure,
  loadAlfredTenantsSuccess
} from '../actions/alfred-tenants.actions';

export const alfredTenantsTriggers: EntityTriggers = {
  batch: {
    loading: [loadAlfredTenants.type],
    resting: [loadAlfredTenantsSuccess.type],
    erroring: [loadAlfredTenantsFailure.type]
  }
};

export function alfredTenantsReducer(state: AlfredTenantsState = initialState, action: Action): AlfredTenantsState {
  return callStateReducer(baseReducer, alfredTenantsTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadAlfredTenantsSuccess, (state, { alfredTenants }) => alfredTenantsAdapter.setAll(alfredTenants.data, state))
);
