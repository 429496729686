import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[adminDragAngDropFileUpload]'
})
export class DragAngDropFileUploadDirective {
  @HostBinding('class.file-hovering') fileHovering: boolean;
  @Output() fileChange = new EventEmitter<FileList>();

  @HostListener('dragover', ['$event']) onDragOver(dragoverEvent: DragEvent): void {
    dragoverEvent.preventDefault();
    dragoverEvent.stopPropagation();
    this.fileHovering = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(dragleaveEvent: DragEvent): void {
    dragleaveEvent.preventDefault();
    dragleaveEvent.stopPropagation();
    this.fileHovering = false;
  }

  @HostListener('drop', ['$event']) onDrop(dropEvent: DragEvent): void {
    dropEvent.preventDefault();
    dropEvent.stopPropagation();
    this.fileHovering = false;

    const files = dropEvent.dataTransfer.files;

    if (files.length > 0) {
      this.fileChange.emit(files);
    }
  }
}
