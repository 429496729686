import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[adminHandleErrorFields]'
})
export class HandleErrorFieldsDirective {
  @Input() form: UntypedFormGroup | UntypedFormGroup[];
  @Input() extraFormToTouch?: string;
  @Input() scrollToErrorFieldDelay?: number = 0;
  @Output() submitForm = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  onClick(): void {
    const forms = Array.isArray(this.form) ? this.form : [this.form];

    // skip validation if form is disabled
    if (forms.every(form => form.valid || form.disabled)) {
      this.submitForm.emit();
      return;
    }

    forms.forEach(form => {
      form.markAllAsTouched();
      // Trigger the form group custom validator
      form.updateValueAndValidity({
        emitEvent: false
      });
    });

    if (this.extraFormToTouch) {
      document
        .querySelectorAll(`${this.extraFormToTouch} input`)
        .forEach((inputField: HTMLElement) => inputField.focus());
    }

    setTimeout(() => {
      const invalidField = document.querySelector('.mat-form-field-invalid') as HTMLElement;
      const errorText = document.querySelector('.mat-error') as HTMLElement;
      const scrollablePart = invalidField || errorText;

      scrollablePart?.focus();
      scrollablePart?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, this.scrollToErrorFieldDelay);
  }
}
