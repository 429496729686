import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { ProductsFilter } from '../types/products-filter.type';
import { ProductsResult } from '../types/products-result.type';
import { Product } from '../types/products.type';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(private missionControlService: MissionControlService) {}

  getProducts(filter: ProductsFilter): Observable<ProductsResult> {
    filter = ObjectUtils.sanitizeRequestObject<ProductsFilter>(filter);
    const params = {
      ...filter,
      limit: filter.limit.toString(),
      page: filter.page.toString()
    };

    return this.missionControlService.get<ProductsResult>('products', new HttpParams({ fromObject: { ...params } }));
  }

  getUniqueAdminIds(products: Product[]): string[] {
    // some products migrated from other system may have empty userId, we want to filter them out
    return Array.from(new Set(products.map(product => product.userId))).filter(id => !!id);
  }
}
