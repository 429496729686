import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import merger from 'json-schema-merge-allof';

import { Formatters, ObjectUtils } from '@utils';

import { confirmationValidator, truthyCheckboxValidator } from '../../../validators';
import {
  OpenApiFormat,
  OpenApiNormalStringFormat,
  OpenApiSchema,
  OpenApiType,
  OpenApiXDisplayType
} from '../types/open-api-schema.type';

export class FormOpenApiSchema {
  constructor(public value: OpenApiSchema) {
    this.value = merger(value);
  }

  getProperty(fieldName: string): OpenApiSchema {
    return this.value.properties[fieldName];
  }

  getPropertyKeys(): string[] {
    return this.value.properties ? Object.keys(this.value.properties) : [];
  }

  getNestedSchema(fieldName: string): FormOpenApiSchema {
    const schema = this.getProperty(fieldName);

    return schema?.type === 'object' ? new FormOpenApiSchema(schema) : null;
  }

  getFieldAttribute(
    fieldName: string,
    attribute: 'example' | 'format' | 'pattern' | 'type' | 'x-display-type' | 'x-allow-empty'
  ): string {
    return this.getProperty(fieldName)?.[attribute];
  }

  getPanInputKeys(keys: string[] = []): string[] {
    for (const fieldName of this.getPropertyKeys()) {
      if (this.isSpecifiedFormat(fieldName, 'pan')) {
        keys = [...keys, fieldName];
      } else if (this.isSpecifiedType(fieldName, 'object')) {
        keys = [...keys, ...this.getNestedSchema(fieldName).getPanInputKeys()];
      }
    }
    return keys;
  }

  hasInputFormat(inputFormat: string): boolean {
    let result = false;
    for (const fieldName of this.getPropertyKeys()) {
      if (this.isSpecifiedType(fieldName, 'object')) {
        result ||= this.getNestedSchema(fieldName).hasInputFormat(inputFormat);
      } else {
        result ||= this.getFieldAttribute(fieldName, 'format') === inputFormat;
      }
    }
    return result;
  }

  isArrayInput(fieldName: string): boolean {
    return this.isSpecifiedType(fieldName, 'array');
  }

  isNormalStringInput(fieldName: string): boolean {
    const normalStringInputs = Object.values(OpenApiNormalStringFormat) as string[];
    return (
      this.isSpecifiedType(fieldName, 'string') &&
      !normalStringInputs.includes(this.getFieldAttribute(fieldName, 'format')) &&
      !this.getFieldAttribute(fieldName, 'x-display-type')
    );
  }

  isNumberInput(fieldName: string): boolean {
    return this.isSpecifiedType(fieldName, 'number') || this.isSpecifiedType(fieldName, 'integer');
  }

  isConfirmation(fieldName: string): boolean {
    return this.getFieldAttribute(fieldName, 'type') === 'string' && fieldName.includes('Confirmation');
  }

  isRequired(fieldName: string): boolean {
    const isRequired = this.value.required?.map(field => Formatters.fromSnakeToCamelCase(field)).includes(fieldName);
    const allowedEmpty = this.getFieldAttribute(fieldName, 'x-allow-empty');

    // TODO: handle allow-empty for other types of input in the future
    if (this.isArrayInput(fieldName) && allowedEmpty) {
      return isRequired && allowedEmpty !== 'allow-empty';
    }
    return isRequired;
  }

  isSpecifiedFormat(fieldName: string, format: OpenApiFormat): boolean {
    return this.getFieldAttribute(fieldName, 'format') === format;
  }

  isSpecifiedType(fieldName: string, type: OpenApiType): boolean {
    return this.getFieldAttribute(fieldName, 'type') === type;
  }

  isSpecifiedXDisplayType(fieldName: string, xDisplayType: OpenApiXDisplayType): boolean {
    return this.getFieldAttribute(fieldName, 'x-display-type') === xDisplayType;
  }

  /* build form, formControl, payload */
  createForm(): UntypedFormGroup {
    const fields = this.getPropertyKeys().reduce((formFields, fieldName) => {
      const nestedSchema = this.getNestedSchema(fieldName);

      if (nestedSchema?.value?.properties) {
        formFields[fieldName] = nestedSchema.createForm();
      } else {
        formFields[fieldName] = this.createFormControl(fieldName);
      }

      return formFields;
    }, {});

    return new FormGroup(fields);
  }

  createFormControl(fieldName: string): UntypedFormControl {
    const validators = [];

    if (this.isSpecifiedFormat(fieldName, 'email')) {
      validators.push(Validators.email);
    }

    if (this.isRequired(fieldName) && this.isSpecifiedXDisplayType(fieldName, 'checkbox')) {
      validators.push(truthyCheckboxValidator);
    }

    // for pan fields, we will expose validation result from SF Iframe, includes "required" validation
    if (this.isRequired(fieldName) && !this.isSpecifiedFormat(fieldName, 'pan')) {
      validators.push(Validators.required);
    }

    if (this.isConfirmation(fieldName)) {
      validators.push(confirmationValidator(fieldName.replace('Confirmation', '')));
    }

    const pattern = this.getFieldAttribute(fieldName, 'pattern');
    if (pattern) {
      validators.push(Validators.pattern(pattern));
    }

    if (this.isArrayInput(fieldName)) {
      return new FormControl([], validators);
    }

    return new FormControl(null, validators);
  }

  // TODO add better typing for formValues and return parameter
  buildPayload(formValues: object, customValues: object = {}): object {
    const formKeys = this.getPropertyKeys();

    return formKeys.reduce((payload, formField) => {
      const nestedSchema = this.getNestedSchema(formField);

      if (nestedSchema) {
        payload[formField] = nestedSchema.buildPayload(formValues[formField], customValues);
      } else {
        let fieldValue = formValues[formField];
        const customFieldValue = customValues[formField]; // used for optionally replacing fieldValue with a custom value

        if (customFieldValue) {
          fieldValue = customFieldValue;
        } else if (this.isSpecifiedFormat(formField, 'date')) {
          fieldValue = new Date(fieldValue);
          fieldValue = `${fieldValue.getDate()}/${fieldValue.getMonth() + 1}/${fieldValue.getFullYear()}`;

          if (fieldValue === 'Invalid Date') {
            fieldValue = null;
          }
        } else if (this.isSpecifiedXDisplayType(formField, 'checkbox') || this.isSpecifiedType(formField, 'boolean')) {
          fieldValue = !!fieldValue;
        }

        if (ObjectUtils.isDefined(fieldValue)) {
          payload[formField] =
            typeof fieldValue === 'string' && this.getFieldAttribute(formField, 'format') !== 'password'
              ? fieldValue.trim()
              : fieldValue;
        }
      }

      return payload;
    }, {});
  }
}
