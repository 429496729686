import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { EarnRule, EarnRuleResult, EarnRulesFilter, EarnRulesResult } from '../../types';

export const loadEarnRules = createAction('[Earn Rules] Load Earn Rules');
export const loadEarnRulesSuccess = createAction(
  '[Earn Rules] Load Earn Rules Success',
  props<{ result: EarnRulesResult }>()
);
export const loadEarnRulesFailure = createAction('[Earn Rules] Load Earn Rules Failure', props<ErrorState>());

export const loadEarnRule = createAction('[Earn Rules] Load Earn Rule', props<{ groupId: string }>());
export const loadEarnRuleSuccess = createAction(
  '[Earn Rules] Load Earn Rule Success',
  props<{ result: EarnRuleResult }>()
);
export const loadEarnRuleFailure = createAction('[Earn Rules] Load Earn Rule Failure', props<ErrorState>());

export const setEarnRulesFilter = createAction(
  '[Earn Rules] Set Earn Rules Filter',
  props<{ filter: EarnRulesFilter }>()
);

export const createEarnRule = createAction('[Earn Rules] Create Earn Rule', props<{ earnRule: EarnRule }>());
export const createEarnRuleSuccess = createAction(
  '[Earn Rules] Create Earn Rules Success',
  (
    earnRule: EarnRule,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully created a regular points rule'
    }
  ) => ({ earnRule, snackbarMessages })
);
export const createEarnRuleFailure = createAction(
  '[Earn Rules] Create Earn Rules Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not create your earn rule due to technical issues on our end. ' +
        'Please try creating your rule again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateEarnRulesPriorities = createAction(
  '[Earn Rules] Update Earn Rules Priorities',
  props<{ groupIds: string[] }>()
);
export const updateEarnRulesPrioritiesSuccess = createAction(
  '[Earn Rules] Update Earn Rules Priorities Success',
  (
    result: EarnRulesResult,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully updated your earn rule priority'
    }
  ) => ({ result, snackbarMessages })
);
export const updateEarnRulesPrioritiesFailure = createAction(
  '[Earn Rules] Update Earn Rules Priorities Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      LE1001:
        'We were not able to change the rank order for this rule as the page is outdated. ' +
        'Please refresh and try changing the rank order again. If the issue persists, contact our Help Center',
      default:
        'You were not able to change the priority for this rule due to technical issues on our end. ' +
        'Please try changing the priority again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateEarnRule = createAction('[Earn Rules] Update Earn Rule', props<{ earnRule: Partial<EarnRule> }>());
export const updateEarnRuleSuccess = createAction(
  '[Earn Rules] Update Earn Rule Success',
  (
    result: EarnRuleResult,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully.'
    }
  ) => ({ result, snackbarMessages })
);
export const updateEarnRuleFailure = createAction(
  '[Earn Rules] Update Earn Rule Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      LE1001:
        'We were not able to update the earn rule as the page is outdated. ' +
        'Please refresh and try updating the earn rule again. If the issue persists, contact our Help Center.',
      default:
        'You were not able to update this earn rule due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const deactivateEarnRule = createAction('[Earn Rules] Deactivate Earn Rule', props<{ groupId: string }>());
export const deactivateEarnRuleSuccess = createAction(
  '[Earn Rules] Deactivate Earn Rule Success',
  (
    result: EarnRuleResult,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully deactivated the rule'
    }
  ) => ({ result, snackbarMessages })
);
export const deactivateEarnRuleFailure = createAction(
  '[Earn Rules] Deactivate Earn Rule Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      LE1001:
        'We were not able to deactivate the earn rule as the page is outdated. ' +
        'Please refresh and try deactivating the earn rule again. If the issue persists, contact our Help Center.',
      default:
        'You were not able to deactivate this earn rule due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
