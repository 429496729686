import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Token } from '@core/types/generate-token.type';

interface DialogData {
  connectionCredentialToken: Token;
}

@Component({
  selector: 'admin-connection-credential-details-dialog',
  templateUrl: './connection-credential-details-dialog.component.html'
})
export class ConnectionCredentialDetailsDialogComponent {
  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConnectionCredentialDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  openSnackBar(): void {
    this.snackBar.open('Values copied to clipboard', 'Dismiss', { panelClass: 'mat-snack-bar-success' });
  }
}
