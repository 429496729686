import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import {
  Membership,
  MembershipRaw,
  MembershipRawResult,
  MembershipResult,
  UpdateMembershipParams
} from '../types/memberships.type';

@Injectable({
  providedIn: 'root'
})
export class MembershipsService {
  constructor(private missionControlService: MissionControlService) {}

  getMemberships(userId: string, locale: string): Observable<MembershipResult> {
    return this.missionControlService
      .get<MembershipRawResult>(
        `users/${userId}/memberships`,
        null,
        this.missionControlService.buildLocaleHeader(locale)
      )
      .pipe(
        map(memberships => {
          const data = memberships.data.map((membership: MembershipRaw) => {
            const { attributes, ...rest } = membership;
            return { ...attributes, ...rest };
          });

          return { data, meta: memberships.meta };
        })
      );
  }

  updateMembership(userId: string, membershipId: string, membership: UpdateMembershipParams): Observable<Membership> {
    return this.missionControlService
      .patch<{ data: MembershipRaw }>(`users/${userId}/memberships/${membershipId}`, membership)
      .pipe(
        map(({ data }) => {
          const { attributes, ...rest } = data;
          return { ...attributes, ...rest };
        })
      );
  }

  deleteMembership(userId: string, membershipId: string): Observable<Membership> {
    return this.missionControlService.delete<Membership>(`users/${userId}/memberships/${membershipId}`);
  }
}
