import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { logoutUser } from '@core/store/auth/actions/auth.actions';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';

@Component({
  selector: 'admin-no-permission-dialog',
  templateUrl: './no-permission-dialog.component.html'
})
export class NoPermissionDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<NoPermissionDialogComponent>,
    private store: Store<AuthState>
  ) {
    dialogRef.disableClose = true;
  }

  close(): void {
    this.store.dispatch(logoutUser());
  }
}
