import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, Directive, inject, QueryList, TemplateRef } from '@angular/core';
import { map, Observable, pairwise, startWith } from 'rxjs';

@Directive({
  standalone: true,
  selector: 'ng-template[adminSliderItem]'
})
export class SliderItemDirective {
  itemTemplate = inject(TemplateRef);
}

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'admin-slider-container',
  template: `
    <div
      class="flex h-full min-w-full"
      [ngStyle]="{
        transform: (containerTranslateX$ | async) ?? 'translateX(0%)',
        transition:
          ((isSlidingBackward$ | async) ?? false)
            ? 'transform 250ms cubic-bezier(0.80, 0.00, 0.67, 1.00)'
            : 'transform 300ms cubic-bezier(0.04, 0.00, 0.19, 1.00)'
      }"
    >
      @for (item of items; let index = $index; track item) {
        @if (index === 0) {
          <ng-container *ngTemplateOutlet="item.itemTemplate" />
        } @else {
          <!-- For some weird reason, animation only work when we wrap component with div -->
          <div class="h-full min-w-full" [@slideOut]>
            <ng-container *ngTemplateOutlet="item.itemTemplate" />
          </div>
        }
      }
    </div>
  `,
  styles: [
    `
      :host {
        @apply block h-full overflow-hidden;
      }
    `
  ],
  animations: [
    trigger('slideOut', [
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate(`250ms 250ms cubic-bezier(0.80, 0.00, 0.67, 1.00)`, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class SliderContainerComponent implements AfterContentInit {
  @ContentChildren(SliderItemDirective, {
    descendants: true
  })
  items!: QueryList<SliderItemDirective>;

  isSlidingBackward$!: Observable<boolean>;

  containerTranslateX$!: Observable<string>;

  ngAfterContentInit(): void {
    const visibleItems$ = this.items.changes.pipe(
      startWith(this.items),
      map(items => items.length)
    );

    this.isSlidingBackward$ = visibleItems$.pipe(
      // We need to get the previous and current length of the visible items
      // to determine if we are sliding backward
      pairwise(),
      // We need to get the most correct initial value for the animation
      // so we need to use startWith here
      startWith([0, this.items.length]),
      map(([prevLength, currLength]) => currLength < prevLength)
    );

    this.containerTranslateX$ = visibleItems$.pipe(
      map(itemsLength => {
        // if there is only one item or no item, we don't need to slide
        // else we will slide to the left with the amount of items -1
        // cause we already showing the first item
        return `translateX(${itemsLength === 0 || itemsLength === 1 ? '0' : `-${(itemsLength - 1) * 100}`}%)`;
      })
    );
  }
}
