import { captureException, isInitialized } from '@sentry/angular';
import { SeverityLevel } from '@sentry/types';

import { Environment } from '../../app-module-config';

export type SentryLoggerFn = (...args: Parameters<typeof captureException>) => void;

export type SentryLogger = Record<SeverityLevel, SentryLoggerFn>;

const sentryLogger: SentryLogger = {
  fatal: (exception, hint) => {
    captureException(exception, Object.assign(hint || {}, { level: 'fatal' }));
  },
  error: (exception, hint) => {
    captureException(exception, Object.assign(hint || {}, { level: 'error' }));
  },
  warning: (exception, hint) => {
    captureException(exception, Object.assign(hint || {}, { level: 'warning' }));
  },
  log: (exception, hint) => {
    captureException(exception, Object.assign(hint || {}, { level: 'log' }));
  },
  info: (exception, hint) => {
    captureException(exception, Object.assign(hint || {}, { level: 'info' }));
  },
  debug: (exception, hint) => {
    captureException(exception, Object.assign(hint || {}, { level: 'error' }));
  }
};

const localSentryLogger: SentryLogger = {
  fatal: console.error,
  error: console.error,
  warning: console.warn,
  log: console.log,
  info: console.info,
  debug: console.debug
};

export function getSentryLogger(environment: Environment['env']): SentryLogger {
  return isInitialized() && environment !== 'devstaging' ? sentryLogger : localSentryLogger;
}
