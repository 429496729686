import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { RewardTrigger, RewardTriggersFilter, RewardTriggersResult } from '../../types';

export const loadRewardTriggers = createAction(
  '[Reward Triggers] Load Reward Triggers',
  props<{ filter: RewardTriggersFilter }>()
);
export const loadRewardTriggersSuccess = createAction(
  '[Reward Triggers] Load Reward Triggers Success',
  props<{ rewardTriggersResult: RewardTriggersResult }>()
);
export const loadRewardTriggersFailure = createAction(
  '[Reward Triggers] Load Reward Triggers Failure',
  props<ErrorState>()
);

export const loadRewardTrigger = createAction('[Reward Triggers] Load Reward Trigger', props<{ id: string }>());
export const loadRewardTriggerSuccess = createAction(
  '[Reward Triggers] Load Reward Trigger Success',
  props<{ rewardTrigger: RewardTrigger }>()
);
export const loadRewardTriggerFailure = createAction(
  '[Reward Triggers] Load Reward Trigger Failure',
  props<ErrorState>()
);

export const resetRewardTrigger = createAction(
  '[Reward Triggers] Reset Reward Trigger',
  props<{ id: string; filter: RewardTriggersFilter }>()
);
export const resetRewardTriggerSuccess = createAction(
  '[Reward Triggers] Reset Reward Trigger Success',
  (
    rewardTrigger: RewardTrigger,
    filter: RewardTriggersFilter,
    snackbarMessages: SnackbarMessages = {
      default: "You have successfully updated the user's preferred reward."
    }
  ) => ({ rewardTrigger, filter, snackbarMessages })
);
export const resetRewardTriggerFailure = createAction(
  '[Reward Triggers] Reset Reward Trigger Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not retrigger the reward due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
