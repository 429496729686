import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Nullable } from '@shared/types';

export interface SentinelConfig {
  config: string;
  envVars: string;
}

export interface SentinelConfigState extends CallState {
  appId: string;
  sentinelConfig: Nullable<SentinelConfig>;
}

export const initialState: SentinelConfigState = {
  ...initialCallState,
  appId: '',
  sentinelConfig: null
};
