import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';

@UseV2Style
@Component({
  selector: 'admin-audit-logs-v2',
  templateUrl: './audit-logs-v2.component.html'
})
export class AuditLogsV2Component {
  isTopLevelView: boolean;

  constructor(private route: ActivatedRoute) {
    this.isTopLevelView = this.route.snapshot.data.isTopLevelView;
  }
}
