import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';
import { Token, TokenRequest } from '@core/types/generate-token.type';

export const createApiSettingsToken = createAction(
  '[Api Settings Token] Create ApiSettingsToken',
  props<{ tokenRequest: TokenRequest }>()
);
export const createApiSettingsTokenSuccess = createAction(
  '[Api Settings Token] Create ApiSettingsToken Success',
  props<{ apiSettingsToken: Token }>()
);
export const createApiSettingsTokenFailure = createAction(
  '[Api Settings Token] Create ApiSettingsToken Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not generate a token due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);
