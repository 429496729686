import { SortDirection } from '@angular/material/sort';

export class CampaignsFilter {
  partnerReferenceCode: string;
  brand: string;
  title: string;
  status: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortDirection?: SortDirection;

  constructor() {
    this.partnerReferenceCode = '';
    this.brand = '';
    this.title = '';
    this.status = '';
    this.page = 1;
    this.limit = 20;
    this.sortBy = 'createdAt';
    this.sortDirection = 'desc';
  }
}
