import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { MerchandisesService } from '../../../merchandises/services/merchandises.service';
import { fetchMerchandise } from '../../../merchandises/store/actions/merchandises.actions';
import { merchandisesQuery } from '../../../merchandises/store/selectors/merchandises.selectors';
import { Merchandise, MerchandiseState } from '../../../merchandises/types/merchandises.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({
  providedIn: 'root'
})
export class MerchandiseTagDefs {
  readonly tagDefs: TagDefs<Merchandise> = {
    merchandise_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showMerchandises)
          ? combineLatest([
              this.merchandisesStore.select(merchandisesQuery.getMerchandiseById(id)),
              this.merchandisesStore.select(merchandisesQuery.getFetchList)
            ]).pipe(
              first(),
              map(([merchandise, fetchList]) => {
                if (!merchandise && !fetchList.includes(id)) {
                  this.merchandisesStore.dispatch(fetchMerchandise({ id }));
                }
              })
            )
          : of(null),
      select: id => this.merchandisesStore.pipe(select(merchandisesQuery.getMerchandiseById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showMerchandises)
          ? this.merchandisesStore.pipe(
              select(merchandisesQuery.getMerchandiseById(id)),
              first(),
              exhaustMap(merchandise => (merchandise ? of(merchandise) : this.merchandisesService.getMerchandise(id)))
            )
          : of(null),
      getDisplayPrefix: () => 'Merchandise',
      getDisplayValue: (merchandise: Merchandise) => merchandise.name,
      getRouteLink: (merchandise: Merchandise) => `/merchandise/${merchandise.id}/details`
    }
  };

  constructor(
    private merchandisesStore: Store<MerchandiseState>,
    private merchandisesService: MerchandisesService,
    private scopes: Scopes
  ) {}
}
