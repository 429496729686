import { Pipe, PipeTransform } from '@angular/core';

import { Formatters } from '@utils';

// example
// input: "gift_card_order_item"
// output: "Gift Card"
@Pipe({ name: 'orderItemType' })
export class OrderItemTypePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const result = Formatters.fromSnakeToTitleCase(value.replace('_order_item', ''));
      return result === 'Gift Card' ? 'Digital Gift Card' : result;
    }
    return null;
  }
}
