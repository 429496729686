import { faUserAgent } from 'fontawesome-user-agent';
import { UAParser } from 'ua-parser-js';

import { fetchWithTimeoutAndRetry } from '../../bootstrap';

const sterlingSignCurrencies = new Set(['GBP', 'GIP', 'EGP', 'FKP', 'GGP', 'IMP', 'JEP', 'LBP', 'SHP', 'SYP']);
const yenSignCurrencies = new Set(['CNY', 'JPY', 'RMB']);

export const IconUtils = {
  async getIconFilenames(): Promise<object> {
    return fetchWithTimeoutAndRetry('assets/icon-filenames.json', 3000, 3, 'icons', {
      'cache-control': 'no-cache no-store'
    });
  },

  getOSIcon(userAgent: string): string {
    const icons = faUserAgent(userAgent || '_');
    return icons.os.name === 'question' ? '' : icons.os.html;
  },

  getBrowserIcon(userAgent: string): string {
    const parser = new UAParser(userAgent);
    const browser = parser.getBrowser().name;
    const icons = faUserAgent(userAgent || '_');
    if (browser?.includes('Edge')) {
      icons.browser = { ...icons.browser, html: '<i class="fab fa-edge"></i>', name: 'edge' };
    }
    return icons.browser.name === 'question' ? '' : icons.browser.html;
  },

  getCerberusIcon(userAgent: string): string {
    return userAgent?.includes('Cerberus') ? '<i class="fas fa-robot"></i>' : '';
  },

  getCountryIcon(countryCode: string): string {
    return (
      countryCode?.toUpperCase().replaceAll(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127_397)) || ''
    );
  },

  hasDeviceInfo(countryCode: string, userAgent: string): boolean {
    return !!(countryCode || this.getBrowserIcon(userAgent) || this.getOSIcon(userAgent));
  },

  getIconsAndFlag(countryCode: string, userAgent: string): string {
    return (
      this.getCerberusIcon(userAgent) +
      this.getCountryIcon(countryCode) +
      this.getOSIcon(userAgent) +
      this.getBrowserIcon(userAgent)
    );
  },

  getIconCurrency(currencyName: string = 'usd'): string {
    const currencyNameUppercase = currencyName.toUpperCase();
    if (sterlingSignCurrencies.has(currencyNameUppercase)) {
      return 'sterling-sign';
    }

    if (yenSignCurrencies.has(currencyNameUppercase)) {
      return 'yen-sign';
    }

    // TODO: in case we want to support specific symbol e.g: RUB, KRW
    switch (currencyNameUppercase) {
      case 'EUR': {
        return 'euro-sign';
      }
      case 'KRW': {
        return 'won-sign';
      }
      default: {
        return 'dollar-sign';
      }
    }
  }
};
