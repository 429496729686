import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { NydusNetworkPartnerConfiguration } from '../../types';

export const loadNydusNetworkPartnerConfiguration = createAction(
  '[NN Partner Configuration] Load Nydus Network Partner Configuration'
);
export const loadNydusNetworkPartnerConfigurationSuccess = createAction(
  '[NN Partner Configuration] Load Nydus Network Partner Configuration Success',
  props<{ partnerConfiguration: NydusNetworkPartnerConfiguration }>()
);
export const loadNydusNetworkPartnerConfigurationFailure = createAction(
  '[NN Partner Configuration] Load Nydus Network Partner Configuration Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not load the data due to technical issues on our end. Please try again. ' +
        'If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
