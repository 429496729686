import { SCOPES_OR } from '@core/types';
import { NavItemV2 } from '@core/types/nav-item-v2.type';

const helpCenterGroup: NavItemV2 = {
  id: 'helpCenter',
  type: 'submenu',
  label: 'Help center',
  iconName: 'info-question-mark',
  children: [
    {
      id: 'contactSupport',
      type: 'link',
      label: 'Contact support',
      route: '/zendesk-tickets/submit-ticket',
      scopes: SCOPES_OR.submitTickets,
      relationOperator: 'or'
    },
    {
      id: 'documentation',
      type: 'externalLink',
      label: 'Documentation',
      href: 'https://docs.ascenda.com/docs',
      target: '_blank'
    }
  ]
};

const logsGroup: NavItemV2 = {
  id: 'logs',
  type: 'submenu',
  label: 'Logs',
  iconName: 'search-document',
  children: [
    {
      id: 'auditLogs',
      type: 'link',
      label: 'Audit logs',
      route: '/logs',
      scopes: SCOPES_OR.viewAuditLogs,
      relationOperator: 'or'
    },
    {
      id: 'events',
      type: 'link',
      label: 'Audit events',
      route: '/events',
      scopes: SCOPES_OR.viewEvents,
      relationOperator: 'or'
    },
    {
      id: 'loginAttempts',
      type: 'link',
      label: 'Login attempts',
      route: '/login-attempts',
      scopes: SCOPES_OR.viewLoginAttempts,
      relationOperator: 'or'
    }
  ]
};

export const supportNavItems: NavItemV2[] = [
  helpCenterGroup,
  logsGroup,
  {
    id: 'notes',
    type: 'link',
    label: 'Notes',
    iconName: 'bullet-points-dots',
    route: '/notes',
    scopes: SCOPES_OR.viewNotes,
    relationOperator: 'or'
  }
];
