<admin-empty-state-page *ngIf="error; else content" entity="Points activity history"></admin-empty-state-page>

<ng-template #content>
  <div class="filter-form-wrapper">
    <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
      <mat-form-field>
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Description</mat-label>
        <input matInput aria-label="Search" type="search" placeholder="Description" formControlName="description" />
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix svgIcon="filter"></mat-icon>
        <mat-label>Type</mat-label>
        <mat-select (ngModelChange)="onCategoryChange()" formControlName="category" disableOptionCentering>
          <mat-option *ngFor="let category of categoryList | keyvalue" [value]="category.value">
            {{ category.key | customTitleCase: 'fromCamelCase' }}
          </mat-option>
        </mat-select>
        <admin-input-reset
          matSuffix
          [inputControl]="filterForm.controls.category"
          [loading]="loading"
        ></admin-input-reset>
      </mat-form-field>

      <mat-form-field formGroupName="transactionTime" class="date-field">
        <mat-label>Date range</mat-label>
        <mat-datepicker-toggle matPrefix [for]="picker">
          <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-date-range-input [rangePicker]="picker">
          <input matInput matStartDate formControlName="dateFrom" placeholder="Start date" (focus)="picker.open()" />
          <input matInput matEndDate formControlName="dateTo" placeholder="End date" (focus)="picker.open()" />
        </mat-date-range-input>
        <div matSuffix>
          <button mat-icon-button (click)="picker.open()">
            <mat-icon matDatepickerToggleIcon svgIcon="arrowdown"></mat-icon>
          </button>
          <ng-container *ngIf="!(dateFrom === null && dateTo === null)">
            <button
              mat-icon-button
              class="close-button"
              (click)="filterForm.controls.transactionTime.reset()"
              [disabled]="!dateFrom && !dateTo"
            >
              <mat-icon>close</mat-icon>
            </button>
          </ng-container>
        </div>
      </mat-form-field>
    </form>
  </div>

  <div *ngIf="loading" class="progress-loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="sticky-table-wrapper">
    <table mat-table [dataSource]="pointsActivities" [class.overlay]="loading">
      <ng-container
        matColumnDef="description"
        [sticky]="columnConfig['description']?.isSticky"
        [stickyEnd]="columnConfig['description']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['description']?.headerClass">Description</th>
        <td mat-cell *matCellDef="let pointsActivity" [ngClass]="columnConfig['description']?.cellClass">
          {{ pointsActivity.description }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="category"
        [sticky]="columnConfig['category']?.isSticky"
        [stickyEnd]="columnConfig['category']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['category']?.headerClass">Type</th>
        <td mat-cell *matCellDef="let pointsActivity" [ngClass]="columnConfig['category']?.cellClass">
          {{ invertedCategoryList[pointsActivity.category] | customTitleCase: 'fromCamelCase' }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="amount"
        [sticky]="columnConfig['amount']?.isSticky"
        [stickyEnd]="columnConfig['amount']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['amount']?.headerClass">
          {{ loyaltyCurrency | titlecase }}
        </th>
        <td mat-cell *matCellDef="let pointsActivity" [ngClass]="columnConfig['amount']?.cellClass">
          {{ getSign(pointsActivity.entryType) }}{{ pointsActivity.amount | roundedNumber: pointsActivityDecimals }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="balanceAsOf"
        [sticky]="columnConfig['balanceAsOf']?.isSticky"
        [stickyEnd]="columnConfig['balanceAsOf']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['balanceAsOf']?.headerClass">Balance</th>
        <td mat-cell *matCellDef="let pointsActivity" [ngClass]="columnConfig['balanceAsOf']?.cellClass">
          {{ pointsActivity.balanceAsOf | roundedNumber: pointsActivityDecimals }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="transactionTime"
        [sticky]="columnConfig['transactionTime']?.isSticky"
        [stickyEnd]="columnConfig['transactionTime']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['transactionTime']?.headerClass">Timestamp</th>
        <td
          mat-cell
          *matCellDef="let pointsActivity"
          [ngClass]="columnConfig['customerId']?.cellClass"
          matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
        >
          {{ pointsActivity.transactionTime | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="actions"
        [sticky]="columnConfig['actions']?.isSticky"
        [stickyEnd]="columnConfig['actions']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['actions']?.headerClass">Actions</th>
        <td mat-cell *matCellDef="let pointsActivity" [ngClass]="columnConfig['actions']?.cellClass">
          <a [routerLink]="'./points-activities/' + pointsActivity.id" queryParamsHandling="preserve">
            <button mat-icon-button>
              <mat-icon svgIcon="view"></mat-icon>
            </button>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <ng-container *ngIf="!loading">
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="isAddition(row.entryType) ? 'addition-row' : 'deduction-row'"
        ></tr>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <admin-empty-state-table></admin-empty-state-table>
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [disabled]="loading"
    [pageSizeOptions]="[10, 20]"
    [pageIndex]="filter.page - 1"
    [pageSize]="filter.limit"
    [length]="count"
    (page)="onPage($event)"
    adminPaginator
  ></mat-paginator>
</ng-template>
