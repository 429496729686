import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { WebhookSettingsService } from '../../services/webhook-settings.service';
import {
  createWebhookSettings,
  createWebhookSettingsFailure,
  createWebhookSettingsSuccess,
  loadWebhookSettings,
  loadWebhookSettingsFailure,
  loadWebhookSettingsSuccess,
  updateWebhookSettings,
  updateWebhookSettingsFailure,
  updateWebhookSettingsSuccess
} from '../actions/webhook-settings.actions';

@Injectable()
export class WebhookSettingsEffects {
  loadWebhookSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWebhookSettings),
      exhaustMap(() =>
        this.webhookSettingsService.getWebhookSettings().pipe(
          map(webhookSettingsResults => loadWebhookSettingsSuccess({ webhookSettingsResults })),
          catchError(error => of(loadWebhookSettingsFailure({ error })))
        )
      )
    )
  );

  createWebhookSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWebhookSettings),
      exhaustMap(action =>
        this.webhookSettingsService.createWebhookSettings(action.webhookSettings).pipe(
          map(webhookSettings => createWebhookSettingsSuccess(webhookSettings)),
          catchError(error => of(createWebhookSettingsFailure(error)))
        )
      )
    )
  );

  updateWebhookSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWebhookSettings),
      exhaustMap(action =>
        this.webhookSettingsService.updateWebhookSettings(action.webhookSettings).pipe(
          map(webhookSettings => updateWebhookSettingsSuccess(webhookSettings)),
          catchError(error => of(updateWebhookSettingsFailure(error)))
        )
      )
    )
  );

  createOrUpdateWebhookSettingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWebhookSettingsSuccess, updateWebhookSettingsSuccess),
      map(() => routerForceNavigate({ path: 'webhooks?tab=settings' }))
    )
  );

  constructor(
    private actions$: Actions,
    private webhookSettingsService: WebhookSettingsService
  ) {}
}
