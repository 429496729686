import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { BaseTranslationForm } from '@shared/types/common-form.type';

import { FormFromType } from '../../../utils/type-utils';
import { RewardsOfferingFilter } from './rewards-offering.type';

export interface RewardsOfferingActivationForm {
  cashback: FormControl<boolean>;
  crypto: FormControl<boolean>;
  giftCards: FormControl<boolean>;
  affiliateOffers: FormControl<boolean>;
  cardLinkedOffers: FormControl<boolean>;
  frequentTravelerPrograms: FormControl<boolean>;
  sustainability: FormControl<boolean>;
}

export type RewardsOfferingFilterForm = FormFromType<RewardsOfferingFilter>;

export const loyaltyProgramCategories = [
  'airline',
  'cashback_to_account',
  'cashback_to_card',
  'cashback_to_platform',
  'cashback_to_tenant',
  'crypto',
  'hotel',
  'sustainability'
];
export type LoyaltyProgramCategory = (typeof loyaltyProgramCategories)[number];

export interface LoyaltyProgramForm {
  id: FormControl<string>;
  category: FormControl<LoyaltyProgramCategory>;
  processingDelay: FormControl<number>;
  conditionsUrl: FormControl<string>;
  detailsUrl: FormControl<string>;
  enrollmentUrl: FormControl<string>;
  logoUrl: FormControl<string>;
  transferRate: FormGroup<TransferRateForm>;
  translations: FormArray<FormGroup<TranslationForm>>;
}

export interface TransferRateForm {
  id: FormControl<string>;
  denomination: FormControl<number>;
  targetDenomination: FormControl<number>;
  maximumTargetTransfer: FormControl<number>;
  minimumTargetTransfer: FormControl<number>;
}

export interface TranslationForm extends BaseTranslationForm {
  name: FormControl<string>;
  shortName: FormControl<string>;
  description: FormControl<string>;
}

export type LoyaltyProgramFormValue = Partial<ReturnType<FormGroup<LoyaltyProgramForm>['getRawValue']>>;
export type TransferRateFormValue = ReturnType<FormGroup<TransferRateForm>['getRawValue']>;
