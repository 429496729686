import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Nullable } from '@shared/types';

export interface CerberusConfig {
  config: string;
  envVars: string;
}

export interface CerberusConfigState extends CallState {
  appId: string;
  cerberusConfig: Nullable<CerberusConfig>;
}

export const initialState: CerberusConfigState = {
  ...initialCallState,
  appId: '',
  cerberusConfig: null
};
