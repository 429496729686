import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { PasswordPolicy } from '../types/password-policies.type';

@Injectable({
  providedIn: 'root'
})
export class PasswordPoliciesService {
  constructor(private missionControlService: MissionControlService) {}

  getPasswordPolicy(id: string): Observable<PasswordPolicy> {
    return this.missionControlService.get<PasswordPolicy>(`password_policies/${id}`);
  }

  getPasswordPolicies(tenantId: string): Observable<PasswordPolicy[]> {
    return this.missionControlService.get<PasswordPolicy[]>(`password_policies?tenantId=${tenantId}`);
  }

  createPasswordPolicy(passwordPolicy: PasswordPolicy): Observable<PasswordPolicy> {
    passwordPolicy = ObjectUtils.sanitizeRequestObject<PasswordPolicy>(passwordPolicy);
    passwordPolicy.data ??= {}; // set the required attribute to empty object if it gets removed after sanitising

    return this.missionControlService.post<PasswordPolicy>('password_policies', passwordPolicy);
  }

  updatePasswordPolicy(passwordPolicy: PasswordPolicy): Observable<PasswordPolicy> {
    passwordPolicy = ObjectUtils.sanitizeRequestObject<PasswordPolicy>(passwordPolicy);
    passwordPolicy.data ??= {}; // set the required attribute to empty object if it gets removed after sanitising

    return this.missionControlService.patch<PasswordPolicy>(`password_policies/${passwordPolicy.id}`, passwordPolicy);
  }

  deletePasswordPolicy(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`password_policies/${id}`);
  }

  downloadPasswordDictionary(): Observable<Blob> {
    return this.missionControlService.get<Blob>('password_policies/dictionary/default', new HttpParams(), {}, 'blob');
  }
}
