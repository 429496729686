import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { CustomDomain } from '../../types';

export const updateCustomDomain = createAction('[Custom Domains] Update Custom Domain', props<{ domain: string }>());
export const updateCustomDomainSuccess = createAction(
  '[Custom Domains] Update Custom Domain Success',
  (
    customDomain: CustomDomain,
    snackbarMessages: SnackbarMessages = {
      default: 'Your custom domain has been added'
    }
  ) => ({ customDomain, snackbarMessages })
);
export const updateCustomDomainFailure = createAction(
  '[Custom Domains] Update Custom Domain Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not save your changes due to technical issues on our end. ' +
        'Please try saving changes again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
