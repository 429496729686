import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';
import { HttpError } from '@core/types';

import { MfaIdentitiesService } from '../../services/mfa-identities.service';
import {
  createMfaIdentity,
  createMfaIdentityFailure,
  createMfaIdentitySuccess,
  deleteMfaIdentity,
  deleteMfaIdentityFailure,
  deleteMfaIdentitySuccess,
  loadMfaIdentities,
  loadMfaIdentitiesFailure,
  loadMfaIdentitiesSuccess,
  updateMfaIdentity,
  updateMfaIdentityFailure,
  updateMfaIdentitySuccess
} from '../actions/mfa-identities.actions';

@Injectable()
export class MfaIdentitiesEffects {
  loadMfaIdentities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMfaIdentities),
      exhaustMap(action =>
        this.mfaIdentitiesService.getMfaIdentities(action.userId).pipe(
          map(mfaIdentities => loadMfaIdentitiesSuccess({ mfaIdentities })),
          catchError((error: HttpError) => of(loadMfaIdentitiesFailure({ error })))
        )
      )
    )
  );

  createMfaIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMfaIdentity),
      exhaustMap(({ params, dialogRefId }) =>
        this.mfaIdentitiesService.createMfaIdentity(params).pipe(
          map(() => createMfaIdentitySuccess({ userId: params.userId, dialogRefId })),
          catchError(error => of(createMfaIdentityFailure({ error })))
        )
      )
    )
  );

  updateMfaIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMfaIdentity),
      exhaustMap(({ id, params, userId, dialogRefId }) =>
        this.mfaIdentitiesService.updateMfaIdentity(id, params).pipe(
          map(() => updateMfaIdentitySuccess({ userId, dialogRefId })),
          catchError(error => of(updateMfaIdentityFailure({ error })))
        )
      )
    )
  );

  createUpdateMfaIdentitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMfaIdentitySuccess, updateMfaIdentitySuccess),
      map(({ userId, dialogRefId }) => {
        this.matDialog.getDialogById(dialogRefId)?.close(true);
        return loadMfaIdentities({ userId });
      })
    )
  );

  deleteMfaIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMfaIdentity),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(({ user, params }) =>
        this.mfaIdentitiesService.deleteMfaIdentity(params).pipe(
          map(() => deleteMfaIdentitySuccess({ user, mfaIdentityId: params.id })),
          catchError(error => of(deleteMfaIdentityFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private matDialog: MatDialog,
    private mfaIdentitiesService: MfaIdentitiesService,
    private store: Store
  ) {}
}
