import { map } from 'rxjs/operators';

import { formatDate } from '@angular/common';
import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CanCheckUnsavedChanges } from '@core/guards/unsaved-changes.guard';
import { AttributeUtils } from '@core/services/attribute-utils/attribute-utils.service';
import { PiiAccess } from '@core/services/pii-access/pii-access.service';
import { ScopesCheckService } from '@core/services/scopes/scopes-check.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import {
  AccessPolicySettings,
  AttributeDef,
  AttributesSettings,
  RequestValidationSettings,
  RequestValidationTypes,
  SCOPES_OR
} from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ToggleOptions } from '@shared/components/slide-toggle/slide-toggle.component';
import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';
import { Nullable } from '@shared/types';
import {
  ChangesUtils,
  Formatters,
  FormGroupValue,
  handleRequestValidation,
  INVALID_E164_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  NullableFormControls,
  ObjectUtils,
  or,
  Params
} from '@utils';

import { EncryptedFields } from '../../../../app-module-config';
import { jsonValidator, phoneValidator } from '../../../../validators';
import { rolesQuery } from '../../../roles/store/selectors/roles.selectors';
import { RoleState } from '../../../roles/types';
import { EditEmailDialogComponent } from '../../components/edit-email-dialog/edit-email-dialog.component';
import { requestResetPassword, updateUser } from '../../store/actions/users.actions';
import { identitiesQuery } from '../../store/selectors/identities.selectors';
import { usersQuery } from '../../store/selectors/users.selectors';
import {
  Custom,
  getPartnerStatuses,
  IdentityState,
  isAdmin,
  User,
  UserCreateEditForm,
  UserLoginMode,
  UserState
} from '../../types';

@UseV2Style
@Component({
  selector: 'admin-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } }
  ]
})
export class UserComponent implements OnInit, CanCheckUnsavedChanges {
  destroyRef = inject(DestroyRef);

  userId: string;
  user$: Observable<User>;
  isResetPasswordEnabled$: Observable<boolean>;

  isViewMode: boolean;
  isEditMode: boolean;
  displayLoyaltyDataPanel: boolean;
  displayCustomPanel: boolean;

  details: AttributeDef<User>[];

  toggleOptions: ToggleOptions = {
    buttonText: {
      on: 'Verified',
      off: 'Unverified'
    },
    buttonTextColor: {
      on: 'white',
      off: 'white'
    },
    backgroundColor: {
      on: '#1a1e43',
      off: '#ce1818'
    }
  };

  nowTime = new Date();
  salutationList = ['Mr', 'Mrs', 'Ms', 'Dr'];
  displayedColumns = ['key', 'value'];

  isEditingPersonalProfile: boolean;
  cancelButtonLink: string;
  userForm: FormGroup<NullableFormControls<UserCreateEditForm>>;

  initialUserFormValue: FormGroupValue<NullableFormControls<UserCreateEditForm>>;
  loading$: Observable<boolean>;
  statuses: string[] = getPartnerStatuses(this.partnerPlatform);
  loginModes: string[] = Object.values(UserLoginMode);
  roleIds$: Observable<string[]>;
  creatableRoleIds$: Observable<string[]>;
  selectableRoleIds: string[];
  undeletableRoleIds: string[];
  existingRoleIds: string[];
  changeUsernameIdentityReference: string;
  allowedPersonalAttributes = [
    'firstName',
    'lastName',
    'salutation',
    'email',
    'birthdate',
    'locale',
    'phoneNumber',
    'zoneinfo',
    'emailVerified',
    'phoneNumberVerified'
  ];
  previousPath: string;
  isEmailEditable = false;
  invalidCustomDataMessage =
    'Invalid custom data. Please include { "agreements": { "digital_rewards_statement": true } } or { "agreements": { "digital_rewards_statement": false } }'; // eslint-disable-line max-len
  accessPolicySettings: AccessPolicySettings;
  invalidE164Message = INVALID_E164_MESSAGE;
  invalidEmailMessage = INVALID_EMAIL_MESSAGE;
  genders = ['female', 'male', 'non_binary'];

  unsavedChangesModalEntity = 'user';
  isPristine = true;

  showScopes = SCOPES_OR.showUsers;
  readonly SCOPES = SCOPES_OR;
  readonly viewManageIdentities = [...this.SCOPES.viewIdentities, ...this.SCOPES.viewMfaIdentities];
  readonly Formatters = Formatters;

  constructor(
    private scopesCheckService: ScopesCheckService,
    private store: Store<UserState>,
    private identityStore: Store<IdentityState>,
    private rolesStore: Store<RoleState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private accessPolicies: AccessPolicies,
    private piiAccess: PiiAccess,
    private scopes: Scopes,
    private router: Router,
    private matDialog: MatDialog,
    private attributeUtils: AttributeUtils,
    @Inject('tenantId') private tenantId: string,
    @Inject('customerBankIdentityProvider') private customerBankIdentityProvider: string,
    @Inject('partnerPlatform') public partnerPlatform: string,
    @Inject('encryptedFields') private encryptedFields: EncryptedFields
  ) {
    this.previousPath = this.router.getCurrentNavigation()?.previousNavigation?.extractedUrl.toString() || '';
  }

  get customFieldData(): Nullable<Custom> {
    const control = this.userForm.get('custom');
    try {
      return JSON.parse(control?.getRawValue());
    } catch {
      return null;
    }
  }

  get accessPolicyAttributeSettings(): AttributesSettings | undefined {
    return this.accessPolicySettings?.attributes;
  }

  ngOnInit(): void {
    this.setMode();

    this.isEditingPersonalProfile = this.isViewMode
      ? this.route.snapshot.parent?.data.prefetchUser.isOnCurrentUserRoute
      : this.route.snapshot.data.user.isOnCurrentUserRoute;

    this.userId = Params.find(this.route, 'userId');
    this.user$ = this.store.select(usersQuery.getUserById(this.userId));

    this.loading$ = or(
      this.store.select(usersQuery.isSingleLoading),
      this.identityStore.select(identitiesQuery.isSingleLoading)
    );
    this.roleIds$ = this.rolesStore.select(rolesQuery.getRoleIds);

    this.setIsPasswordEnabled();

    this.setupStaticDisplay();
    this.handleRequestValidationSettings();
    this.handleSelectableRoles();

    this.formHandling();
    this.subscribeToEStatementAndCustomChange();
    this.subscribeToFormChanges();
  }

  setMode(): void {
    const mode = this.route.snapshot.data.mode;
    switch (mode) {
      case 'view': {
        this.isViewMode = true;
        break;
      }
      case 'edit': {
        this.isEditMode = true;
        break;
      }
    }
  }

  setupStaticDisplay(): void {
    const detailDefs: AttributeDef<User>[] = [
      { key: 'otherPii', label: 'Phone number 1 (Mobile)', subKeys: ['phones', 'mobile_phone'] },
      { key: 'otherPii', label: 'Phone number 2 (Contact)', subKeys: ['phones', 'contact_phone'] },
      { key: 'otherPii', label: 'Phone number 3 (Home)', subKeys: ['phones', 'home_phone_number'] },
      { key: 'otherPii', label: 'Phone number 4 (Raw)', subKeys: ['phones', 'raw_mobile_phone'] }
    ];

    if (this.tenantId === 'AustraliaNewZealandBankBusinessRewards') {
      detailDefs.push(
        { key: 'loyalty_data', label: 'Company name', subKeys: ['company_name'] },
        { key: 'loyalty_data', label: 'VIP', subKeys: ['vip_flag'] }
      );
    } else if (this.tenantId === 'AustraliaNewZealandBankRewards') {
      detailDefs.push({ key: 'loyalty_data', label: 'VIP', subKeys: ['vip_flag'] });
    }

    const settings = this.accessPolicies.getResponseSettings('users', 'show');
    this.details = this.attributeUtils.filterAttributes(detailDefs, settings);
    this.displayLoyaltyDataPanel = this.attributeUtils.filterAttributes([{ key: 'loyaltyData' }], settings).length > 0;
    this.displayCustomPanel = this.attributeUtils.filterAttributes([{ key: 'custom' }], settings).length > 0;
  }

  createForm(): void {
    this.userForm = this.fb.group<NullableFormControls<UserCreateEditForm>>({
      id: this.fb.control(null, [Validators.required]),
      birthdate: this.fb.control(null),
      loyalty_data: this.fb.control('{}', [jsonValidator()]), // a non_formatted_keys key from MC to avoid formatting nested attributes
      custom: this.fb.control('{}', [jsonValidator(), this.customFieldValidator()]),
      email: this.fb.control(null, [Validators.email]),
      emailVerified: this.fb.control(null),
      firstName: this.fb.control(''),
      lastName: this.fb.control(''),
      gender: this.fb.control(null),
      locale: this.fb.control(''),
      phoneNumber: this.fb.control('', [phoneValidator()]),
      phoneNumberVerified: this.fb.control(null),
      salutation: this.fb.control(''),
      zoneinfo: this.fb.control(''),
      status: this.fb.control(null, [Validators.required]),
      loginMode: this.fb.control(null, [Validators.required]),
      activated: this.fb.control(null, [Validators.required]),
      roles: this.fb.control([]),
      eStatement: this.fb.control(null)
    });

    this.initialUserFormValue = this.userForm.getRawValue();
  }

  disableFormFields(): void {
    if (this.isViewMode) {
      this.userForm.disable();
    } else {
      this.userForm.get('id')?.disable();
    }
  }

  handleRequestValidationSettings(): void {
    this.accessPolicySettings = this.isEditingPersonalProfile
      ? (this.accessPolicies.getRequestValidationSettings('users', 'updatePersonalProfile') as AccessPolicySettings)
      : (this.accessPolicies.getRequestValidationSettings('users', 'update') as AccessPolicySettings);

    const userSettings = this.accessPolicyAttributeSettings?.user as AttributesSettings;

    this.statuses = userSettings?.status
      ? (userSettings.status as RequestValidationSettings).includes || []
      : this.statuses;
    this.creatableRoleIds$ = this.roleIds$.pipe(
      map(roleIds =>
        this.accessPolicyAttributeSettings?.createdRoles
          ? (this.accessPolicyAttributeSettings.createdRoles as RequestValidationSettings).includes
          : roleIds
      ),
      map(data => data || [])
    );
  }

  getEStatementValue(custom: Nullable<Custom>): boolean {
    return custom?.agreements?.digital_rewards_statement || false;
  }

  // reflect change to counterpart control when either eStatement or custom value changed
  subscribeToEStatementAndCustomChange(): void {
    const eStatementControl = this.userForm.get('eStatement');
    const customControl = this.userForm.get('custom');

    if (!eStatementControl || !customControl) {
      return;
    }

    eStatementControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      let data = this.customFieldData;

      if (data) {
        data = { ...data, agreements: { ...data.agreements, digital_rewards_statement: value || false } };
        customControl.setValue(JSON.stringify(data), { emitEvent: false });
      }
    });

    customControl.markAllAsTouched();
    customControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (customControl.valid) {
        eStatementControl?.setValue(this.getEStatementValue(this.customFieldData), { emitEvent: false });
      }
    });
  }

  subscribeToFormChanges(): void {
    this.userForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isPristine = ChangesUtils.isUnchanged(this.initialUserFormValue!, this.userForm.getRawValue());
    });
  }

  handleRequestValidationSpecialCases(): void {
    const { attributes, meta } = this.accessPolicySettings;
    const { createdRoles, deletedRoles, user } = attributes ?? ({} as AccessPolicySettings);
    const { loyaltyData, custom } = user ?? {};
    const { otherAttributes } = meta ?? {};

    if (
      createdRoles?.includes.length ||
      deletedRoles?.includes.length ||
      otherAttributes === RequestValidationTypes.Allow
    ) {
      this.userForm.get('roles')?.enable();
    } else {
      this.userForm.get('roles')?.disable();
    }

    if (loyaltyData) {
      this.userForm.get('loyalty_data')?.enable();
    } else if (loyaltyData === false) {
      this.userForm.get('loyalty_data')?.disable();
    }

    if (custom === true || custom?.agreements?.digitalRewardsStatement === true) {
      this.userForm.get('eStatement')?.enable();
    } else if (custom === false || custom?.agreements?.digitalRewardsStatement === false) {
      this.userForm.get('eStatement')?.disable();
    }
  }

  setupInitialFormValue(): void {
    const isCustomerView = this.route.snapshot.data.isCustomerView;

    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      if (user) {
        // Create a copy to allow mutation
        const userCopy = ObjectUtils.deepCopy(user);
        // if policy only defines `deletableValues` (e.g., `deletedRoles`) but no `selectableValues` (e.g. `creatableRoleIds$`),
        // send `nonRemovableValues` by removing `deletableValues` from current values
        if (this.accessPolicyAttributeSettings?.deletedRoles) {
          const deletableRoles =
            (this.accessPolicyAttributeSettings.deletedRoles as RequestValidationSettings).includes || [];
          this.undeletableRoleIds =
            userCopy.roles?.filter(role => !deletableRoles.includes(role.name)).map(role => role.name) || []; // can be undefined
        }

        // retrieve agreements and digital_rewards_statement value, or create digital_rewards_statement value if it does not exist
        const digital_rewards_statement = user.custom ? this.getEStatementValue(user.custom) : false;
        if (userCopy.custom) {
          userCopy.custom.agreements = { ...userCopy.custom.agreements, digital_rewards_statement };
        }
        this.existingRoleIds = user.roles?.map(role => role.name) || [];

        this.userForm.patchValue({
          ...userCopy,
          birthdate: this.handleBirthdate(userCopy?.birthdate as string),
          eStatement: digital_rewards_statement,
          loyalty_data: JSON.stringify(userCopy.loyalty_data) || '',
          custom: JSON.stringify(userCopy.custom) || '',
          roles: this.existingRoleIds
        });

        this.initialUserFormValue = this.userForm.getRawValue();

        this.cancelButtonLink = this.getCancelButtonPath(isCustomerView);

        const passwordIdentityReference = (userCopy.identities || []).find(identity =>
          identity.providerId.includes('password')
        )?.reference;
        const emailIdentityReference = (userCopy.identities || []).find(identity =>
          identity.providerId.includes('email')
        )?.reference;

        // Hotfix to avoid issue due to GH migration from password to email identities.
        // Remove this once GH updates the username update endpoint to support User ID instead.
        this.changeUsernameIdentityReference = emailIdentityReference || passwordIdentityReference || '';

        this.handleFormControlAbility();
        this.handleEmailFieldAndButton(userCopy.activated || false);
        this.handleEncryptedFields();
      } else {
        this.userForm.get('id')?.patchValue(this.userId);
      }
    });
  }

  handleFormControlAbility(): void {
    const otherAttributes = this.accessPolicySettings?.meta?.otherAttributes;

    if (this.accessPolicyAttributeSettings && otherAttributes) {
      handleRequestValidation(
        this.userForm,
        this.accessPolicyAttributeSettings.user as AttributesSettings,
        otherAttributes
      );
      this.handleRequestValidationSpecialCases();
    }

    if (this.isEditingPersonalProfile && this.scopes.lackScopes(SCOPES_OR.updateAgents)) {
      this.disableControlsExceptPersonal();
    }
  }

  handleBirthdate(birthDate: string): Date | null {
    if (!birthDate || this.piiAccess.isMasked()) {
      return null;
    }
    try {
      return new Date(formatDate(birthDate, 'yyyy/MM/dd', 'en'));
    } catch {
      return null;
    }
  }

  disableControlsExceptPersonal(): void {
    this.userForm.get('roles')?.disable();

    Object.entries(this.userForm.controls).forEach(([key, control]) => {
      if (control.enabled && !this.allowedPersonalAttributes.includes(key)) {
        control.disable();
      }
    });
  }

  handleEmailFieldAndButton(activatedUser: boolean): void {
    // Remove changeUsernameIdentityReference check once GH updates the username update endpoint to support User ID instead.
    if (this.scopes.hasAny(SCOPES_OR.updateUsername) && this.changeUsernameIdentityReference && activatedUser) {
      this.isEmailEditable = true;
      this.userForm.get('email')?.disable();
    }
  }

  handleEncryptedFields(): void {
    if (this.piiAccess.isMasked()) {
      this.encryptedFields['user'].forEach(field => this.userForm.get(field)?.disable());
    }
  }

  handleSelectableRoles(): void {
    this.creatableRoleIds$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(creatableRoleIds => (this.selectableRoleIds = creatableRoleIds.concat(this.existingRoleIds)))
      )
      .subscribe();
  }

  submitForm(): void {
    const updatedRoleList = this.userForm.get('roles')?.value;
    const createdRoles = (updatedRoleList || []).filter(role => !this.existingRoleIds.includes(role));
    const deletedRoles = this.existingRoleIds.filter(role => !(updatedRoleList || []).includes(role));

    // only retrieve enabled form field values
    const { roles, eStatement, ...userFields } = this.userForm.value;

    const changedUserFields: Partial<User> = Object.fromEntries(
      Object.entries(userFields).filter(([key, currentValue]) => {
        const initialValue = this.initialUserFormValue[key] ?? {};

        return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
      })
    );

    const user: Partial<User> = {
      id: this.userForm.get('id')?.value || '',
      ...changedUserFields
    };

    // transform string to object if key exists in user (changedUserFields)
    ['loyalty_data', 'custom'].forEach(key => {
      if (user[key]) {
        user[key] = ObjectUtils.parseAsObject(userFields[key]);
      }
    });

    // if custom is disabled and not exists in user payload, send eStatement result via custom
    if (eStatement !== undefined && !user.custom) {
      user.custom = { agreements: { digital_rewards_statement: eStatement || false } };
    }

    this.store.dispatch(
      updateUser({
        user,
        createdRoles,
        deletedRoles,
        previousPath: this.previousPath,
        message: 'Your changes have been successfully saved!',
        errorMessage: 'You have failed to save the changes made. Please try again.'
      })
    );
  }

  openEmailDialog(): void {
    this.matDialog.open(EditEmailDialogComponent, {
      width: '500px',
      data: {
        id: this.userForm.get('id')?.value,
        reference: this.changeUsernameIdentityReference,
        parentEmailControl: this.userForm.get('email')
      }
    });
  }

  openSendResetPasswordDialog(user: User): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          dialogTitle: 'Send reset password email',
          confirmText: `Are you sure you want to send the reset password email ${
            user.email ? 'to ' + user.email + '?' : '?'
          }`,
          confirmButtonText: 'Yes, send email',
          styleClassName: 'confirm-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(requestResetPassword({ userId: user.id }));
        }
      });
  }

  customFieldValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        const data = JSON.parse(control.value);

        if (data?.agreements?.digital_rewards_statement === undefined) {
          return { invalidCustomDataFormat: true };
        } else if (typeof data.agreements.digital_rewards_statement !== 'boolean') {
          return { invalidEStatementDataType: true };
        }

        return null;
      } catch {
        return null;
      }
    };
  }

  getCancelButtonPath(isCustomerView: boolean): string {
    const canAccessUsersView = isCustomerView
      ? this.scopes.hasAny(SCOPES_OR.viewCustomers)
      : this.scopes.hasAny(SCOPES_OR.viewAgents);
    return '../../' + (canAccessUsersView ? '' : this.userId + '/details');
  }

  showEdit(): boolean {
    return (
      this.isEditingPersonalProfile ||
      this.scopesCheckService.hasViewUpdatePageScopes(this.route.parent?.snapshot.data.isCustomerView)
    );
  }

  private setIsPasswordEnabled(): void {
    this.isResetPasswordEnabled$ = this.user$.pipe(
      map(user => {
        const providerIds = user.identities?.map(identity => identity.providerId);
        return (
          providerIds?.includes(this.customerBankIdentityProvider) || (isAdmin(user) && providerIds?.includes('email'))
        );
      }),
      map(data => data || false)
    );
  }

  private formHandling(): void {
    this.createForm();
    this.setupInitialFormValue();
    this.disableFormFields();
  }
}
