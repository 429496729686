<mat-radio-group [formControl]="formControl" class="flex flex-wrap gap-2">
  @for (option of options; track option.value) {
    <div
      class="w-fit cursor-pointer rounded-md px-5 py-3 text-cloudburst-500 hover:bg-cloudburst-150"
      [ngClass]="{
        border: formControl.value === option.value,
        'border-cloudburst-500': formControl.value === option.value,
        'pointer-events-none': isDisabled,
        'text-cloudburst-200': isDisabled
      }"
      (click)="formControl.setValue(option.value)"
    >
      <mat-radio-button #item color="primary" [value]="option.value" class="text-base leading-6">
        {{ option.label }}
      </mat-radio-button>
    </div>
  }
</mat-radio-group>
