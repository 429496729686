import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { ConfigurationChangesRequestParams, ConfigurationChangesResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationChangesService {
  constructor(private missionControlService: MissionControlService) {}

  getConfigurationChanges(params: ConfigurationChangesRequestParams): Observable<ConfigurationChangesResult> {
    return this.missionControlService.get<ConfigurationChangesResult>(
      'configuration_changes',
      new HttpParams({ fromObject: { ...ObjectUtils.prepareQueryObject(params) } })
    );
  }
}
