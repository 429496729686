import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { SCOPES_OR } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';

import { sendNotification } from '../../store/actions/order-items.actions';
import { OrderItem, StripeReferenceData, SustainabilityOrderItemData } from '../../types';

@UseV2Style
@Component({
  selector: 'admin-sustainability-order-item',
  templateUrl: './sustainability-order-item.component.html'
})
export class SustainabilityOrderItemComponent implements OnInit {
  @Input() customerId: string;
  @Input() detailsView: string;
  @Input() orderItem: OrderItem;
  @Input() stripeReferenceData: StripeReferenceData;
  data: SustainabilityOrderItemData;
  readonly SCOPES = SCOPES_OR;

  constructor(
    private matDialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.data = this.orderItem.data as SustainabilityOrderItemData;
  }

  openSendNotificationDialog(): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          confirmText: 'Are you sure to resend email notification?',
          confirmButtonText: 'Yes, confirm',
          styleClassName: 'confirm-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          // resend the latest notification that with xxx_email channel
          const notifications = this.orderItem.notifications.filter(notification =>
            notification.channel.includes('email')
          );
          if (notifications.length > 0) {
            this.store.dispatch(sendNotification({ notificationId: notifications.at(-1).id }));
          }
        }
      });
  }
}
