import { distinctUntilChanged, skip } from 'rxjs/operators';

import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { nydusNetworkBootstrapQuery } from '@core/store/nydus-network/selectors/nydus-network-bootstrap.selectors';
import { NydusNetworkBootstrapState } from '@core/types';
import { or } from '@utils';

@Component({
  selector: 'admin-locale-switcher-v2',
  templateUrl: './locale-switcher-v2.component.html',
  styleUrls: ['./locale-switcher-v2.component.scss']
})
export class LocaleSwitcherV2Component implements OnInit, OnChanges {
  @Input() initialLocale: string;
  @Input() localeOptions: string[]; // avaiable locales are prepared by parent component, instead of fetching from NN
  @Input() selectedLocale: string;
  @Input() parentElementLoading$: Observable<boolean>;
  @Output() localeChanged = new EventEmitter<string>();

  destroyRef = inject(DestroyRef);
  fb = inject(FormBuilder);

  localeControl: FormControl<string>;
  locales: string[];

  constructor(private nydusNetworkBootstrapStore: Store<NydusNetworkBootstrapState>) {}

  ngOnInit(): void {
    this.localeControl = this.fb.nonNullable.control<string>(this.initialLocale || this.localeOptions?.at(0) || '');

    if (this.localeOptions) {
      this.locales = this.localeOptions;
    } else {
      this.localeControl.disable();
      this.createDropdownList();
      this.handleFormControlAbility();
    }

    this.subscribeToLocaleChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { selectedLocale } = changes;

    if (selectedLocale && selectedLocale.currentValue !== selectedLocale.previousValue && this.localeControl) {
      this.localeControl.setValue(changes.selectedLocale.currentValue);
    }
  }

  createDropdownList(): void {
    this.nydusNetworkBootstrapStore
      .select(nydusNetworkBootstrapQuery.getNydusNetworkBootstrapLocales)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(locales => (this.locales = locales.map(locale => locale.code)));
  }

  subscribeToLocaleChange(): void {
    this.localeControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), skip(this.localeOptions ? 0 : 1))
      .subscribe(locale => {
        this.localeChanged.emit(locale);
      });
  }

  handleFormControlAbility(): void {
    or(this.nydusNetworkBootstrapStore.select(nydusNetworkBootstrapQuery.isSingleLoading), this.parentElementLoading$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isLoading => {
        if (isLoading) {
          this.localeControl.disable();
        } else if (this.locales.length > 0) {
          this.localeControl.enable();
        }
      });
  }
}
