import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { AlfredSupplier, AlfredSuppliersFilter, AlfredSuppliersResult } from '../../types';

export const loadAlfredSuppliers = createAction(
  '[Alfred Suppliers] Load AlfredSuppliers',
  props<{ filter: AlfredSuppliersFilter }>()
);
export const loadAlfredSuppliersSuccess = createAction(
  '[Alfred Suppliers] Load AlfredSuppliers Success',
  props<{ result: AlfredSuppliersResult }>()
);
export const loadAlfredSuppliersFailure = createAction(
  '[Alfred Suppliers] Load AlfredSuppliers Failure',
  props<ErrorState>()
);

export const loadAlfredSupplier = createAction(
  '[Alfred Suppliers] Load AlfredSupplier',
  props<{ supplierId: string }>()
);
export const loadAlfredSupplierSuccess = createAction(
  '[Alfred Suppliers] Load Alfred Supplier Success',
  props<{ alfredSupplier: AlfredSupplier }>()
);
export const loadAlfredSupplierFailure = createAction(
  '[Alfred Suppliers] Load Alfred Supplier Failure',
  props<ErrorState>()
);

export const refreshAlfredSupplier = createAction(
  '[Alfred Suppliers] Refresh Alfred Supplier',
  props<{ supplierId: string }>()
);
export const refreshAlfredSupplierSuccess = createAction(
  '[Alfred Suppliers] Refresh Alfred Supplier Success',
  (
    snackbarMessages: SnackbarMessages = {
      default:
        'You have successfully queued a catalog refresh. A slack notification will be sent once the refresh is completed'
    }
  ) => ({ snackbarMessages })
);
export const refreshAlfredSupplierFailure = createAction(
  '[Alfred Suppliers] Refresh Alfred Supplier Failure',
  (error: HttpError) => ({ error })
);
