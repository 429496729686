import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { enrollmentAdapter, EnrollmentState, initialState } from '../../types';
import {
  loadEnrollments,
  loadEnrollmentsAndUsers,
  loadEnrollmentsFailure,
  loadEnrollmentsSuccess,
  setEnrollmentsFilter
} from '../actions/enrollments.actions';

export const enrollmentTriggers: EntityTriggers = {
  batch: {
    loading: [loadEnrollments.type, loadEnrollmentsAndUsers.type],
    resting: [loadEnrollmentsSuccess.type],
    erroring: [loadEnrollmentsFailure.type]
  }
};

export function enrollmentsReducer(state: EnrollmentState = initialState, action: Action): EnrollmentState {
  return callStateReducer(baseReducer, enrollmentTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadEnrollments, loadEnrollmentsAndUsers, (state, { filter, isTopLevelView }) => ({
    ...state,
    filter,
    isTopLevelView
  })),
  on(setEnrollmentsFilter, (state, { filter }) => ({ ...state, filter })),
  on(loadEnrollmentsSuccess, (state, { enrollments }) => enrollmentAdapter.setAll(enrollments, state))
);
