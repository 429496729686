import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { ConvertedCurrency, CurrencyConversionData } from '../../types';

export const convertCurrency = createAction(
  '[Currency Conversion] Convert Currency',
  props<{ currencyConversionData: CurrencyConversionData }>()
);
export const convertCurrencySuccess = createAction(
  '[Currency Conversion] Convert Currency Success',
  props<{ result: ConvertedCurrency }>()
);
export const convertCurrencyFailure = createAction(
  '[Currency Conversion] Convert Currency Failure',
  props<ErrorState>()
);

export const clearConvertedCurrency = createAction('[Currency Conversion] Clear Convert Currency');
