import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { PointsStatement } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class PointsStatementsService {
  constructor(private missionControlService: MissionControlService) {}

  getPointsStatement(pointsAccountId: string, statementId: string): Observable<PointsStatement> {
    return this.missionControlService.get<PointsStatement>(
      `points_accounts/${pointsAccountId}/statements/${statementId}`
    );
  }
}
