import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Nullable } from '@shared/types';

import { AlfredSuppliersFilter } from './alfred-suppliers-filter.type';

interface CurrencyExchangeMarkup {
  type: string;
  value: number;
}

interface Config {
  product_billing_currency?: Record<string, string>;
}

export interface AlfredSupplier {
  id: string;
  name: string;
  active: boolean;
  stable: boolean;
  generic: boolean;
  supplierType: AlfredSupplierType;
  currencyExchangeMarkup: CurrencyExchangeMarkup;
  config: Config;
}

export interface AlfredSupplierColumn {
  id: string;
  name: string;
  active: boolean;
  stable: boolean;
  generic: boolean;
  supplierType: string;
  actions: string;
}

export type AlfredSupplierType = 'gift_card' | 'offer' | 'merchandise';

export interface AlfredSupplierState extends EntityState<AlfredSupplier>, CallState {
  filter: Nullable<AlfredSuppliersFilter>;
  count: number;
}

export const alfredSupplierAdapter = createEntityAdapter<AlfredSupplier>();

export const initialState: AlfredSupplierState = {
  ...alfredSupplierAdapter.getInitialState<CallState>(initialCallState),
  filter: new AlfredSuppliersFilter(),
  count: 0
};

export const initialAlfredSuppliersFilter = {
  page: 1,
  limit: 100
};
