import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'admin-empty-state-page',
  template: `
    <div class="render-state-error" [class.no-title]="!title">
      <mat-icon [svgIcon]="iconName"></mat-icon>
      @if (title) {
        <h2 class="state-title">{{ title }}</h2>
      }
      <div class="state-message" [innerHTML]="message"></div>
    </div>
  `,
  styleUrls: ['./empty-state.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule]
})
export class EmptyStatePageComponent implements OnInit {
  @Input() entity: string; // use sentence case
  @Input() message?: string;
  @Input() messageType: 'unsupported';
  @Input() title?: string | null = 'No details available';
  @Input() iconName = 'warning';

  ngOnInit(): void {
    if (!this.message) {
      switch (this.messageType) {
        case 'unsupported': {
          this.message = `${this.entity} is currently not supported.`;
          break;
        }
        default: {
          this.message = `${this.entity}'s details are currently unavailable, please give our servers time before trying again.`;
        }
      }
    }
  }
}
