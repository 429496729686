import { Action, createReducer } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { redemptionInitialState, RedemptionState } from '../../types';
import {
  createCashRedemption,
  createCashRedemptionFailure,
  createCashRedemptionSuccess,
  createPointsTransferRedemption,
  createPointsTransferRedemptionFailure,
  createPointsTransferRedemptionSuccess
} from '../actions/redemptions.actions';

export const redemptionTriggers: EntityTriggers = {
  single: {
    loading: [createCashRedemption.type, createPointsTransferRedemption.type],
    resting: [createCashRedemptionSuccess.type, createPointsTransferRedemptionSuccess.type],
    erroring: [createCashRedemptionFailure.type, createPointsTransferRedemptionFailure.type]
  }
};

export function redemptionsReducer(state: RedemptionState = redemptionInitialState, action: Action): RedemptionState {
  return callStateReducer(baseReducer, redemptionTriggers)(state, action);
}

const baseReducer = createReducer(redemptionInitialState);
