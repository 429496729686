import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { GiftCard } from './gift-cards.type';

export interface Webhook {
  id: string;
  eventName: string;
  orderId: string;
  status: string;
  requestPayload: RequestPayload;
  responsePayload: string;
  createdAt: string;
  updatedAt: string;
}

interface RequestPayload {
  id: string;
  cards: Partial<GiftCard>[];
  errors: string[];
  status: string;
  userId: string;
  reference: string;
  customData: object;
}

export const orderWebhookAdapter = createEntityAdapter<Webhook>();

export interface OrderWebhookState extends EntityState<Webhook>, CallState {}

export const initialState: OrderWebhookState = orderWebhookAdapter.getInitialState<CallState>(initialCallState);
