import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { appAdapter, AppState, initialState } from '../../types/apps.type';
import {
  createApp,
  createAppFailure,
  createAppSuccess,
  fetchApp,
  fetchAppFailure,
  fetchAppSuccess,
  loadApp,
  loadAppFailure,
  loadApps,
  loadAppSecret,
  loadAppSecretFailure,
  loadAppSecretSuccess,
  loadAppsFailure,
  loadAppsSuccess,
  loadAppSuccess,
  updateApp,
  updateAppFailure,
  updateAppSuccess
} from '../actions/apps.actions';

export const appTriggers: Required<EntityTriggers> = {
  single: {
    loading: [loadApp.type, createApp.type, updateApp.type, loadAppSecret.type],
    resting: [loadAppSuccess.type, createAppSuccess.type, updateAppSuccess.type, loadAppSecretSuccess.type],
    erroring: [loadAppFailure.type, createAppFailure.type, updateAppFailure.type, loadAppSecretFailure.type]
  },
  batch: {
    loading: [loadApps.type],
    resting: [loadAppsSuccess.type],
    erroring: [loadAppsFailure.type]
  }
};

export const appFetchListTriggers: FetchListTriggers = {
  loading: [fetchApp.type],
  resting: [fetchAppSuccess.type],
  erroring: [fetchAppFailure.type]
};

export function appsReducer(state: AppState = initialState, action: Action): AppState {
  return {
    ...callStateReducer(baseReducer, appTriggers)(state, action),
    ...fetchStateReducer(appFetchListTriggers, 'app')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(fetchAppSuccess, (state, { app }) => appAdapter.upsertOne(app, state)),
  on(loadAppSuccess, (state, { app }) => appAdapter.upsertOne(app, state)),
  on(loadAppSecretSuccess, (state, { appId, secret }) =>
    appAdapter.upsertOne({ ...state.entities[appId]!, id: appId, secret }, state)
  ),
  on(loadAppsSuccess, (state, { apps }) => appAdapter.upsertMany(apps, state)),
  on(createAppSuccess, (state, { app }) => appAdapter.addOne(app, state)),
  on(updateAppSuccess, (state, { app }) => appAdapter.setOne(app, state))
);
