import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { SUPPORTED_GOOGLE_FONTS } from '@utils';

@Component({
  selector: 'admin-font-selector-input',
  templateUrl: './font-selector-input.component.html',
  styleUrls: ['./font-selector-input.component.scss'],
  providers: [{ provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { overlayPanelClass: 'auto-complete-panel' } }]
})
export class FontSelectorInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() recentlyUsedFonts: string[];
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  defaultFonts: string[];
  filteredFonts: string[];
  isAutoCompleteOpened: boolean;
  value: string;

  readonly SUPPORTED_SECTION_HEADER = 'SUPPORTED TYPEFACES';
  readonly RECENTLY_USED_SECTION_HEADER = 'RECENTLY USED';

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.filteredFonts = this.defaultFonts;
    this.loadFonts();
  }

  ngOnDestroy(): void {
    document.querySelectorAll('link[usage=font-preview]').forEach(link => link.remove());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentRecentlyUsedFonts = changes.recentlyUsedFonts.currentValue;
    this.defaultFonts = [this.SUPPORTED_SECTION_HEADER, ...SUPPORTED_GOOGLE_FONTS];

    if (currentRecentlyUsedFonts.length > 0) {
      this.defaultFonts.unshift(this.RECENTLY_USED_SECTION_HEADER, ...currentRecentlyUsedFonts);
    }

    this.ngControl.control!.updateValueAndValidity();
  }

  loadFonts(currentFontIndex: number = 0): void {
    const startIndex = currentFontIndex < 10 ? 0 : currentFontIndex - 10;
    const endIndex = currentFontIndex + 10;
    const headElement = document.querySelector('head');

    this.filteredFonts.slice(startIndex, endIndex).forEach(font => {
      const currentFont = this.getFont(font);

      if (currentFont !== 'inherit' && !document.getElementById(currentFont)) {
        const link = document.createElement('link');
        link.id = currentFont;
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('href', `https://fonts.googleapis.com/css?family=${currentFont}&text=${currentFont}`);
        link.setAttribute('usage', 'font-preview');
        headElement!.append(link);
      }
    });
  }

  getFont(font: string): string {
    return [this.SUPPORTED_SECTION_HEADER, this.RECENTLY_USED_SECTION_HEADER].includes(font) ? 'inherit' : font;
  }

  autoCompleteOpened(): void {
    this.cdkVirtualScrollViewPort.scrollToIndex(0);
    this.cdkVirtualScrollViewPort.checkViewportSize();
  }

  toggleAutoCompleteDropdown(): void {
    if (this.isAutoCompleteOpened) {
      // setTimeout is used here so that closePanel action will execute after the auto complete open panel action (caused by clicking the form field)
      setTimeout(() => this.matAutocompleteTrigger.closePanel());
    }
  }

  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.ngControl.control!.setValue(value);
    this.updateFilteredFonts(value);
    this.loadFonts();
  }

  updateFilteredFonts(value: string): void {
    this.filteredFonts =
      value === ''
        ? this.defaultFonts
        : SUPPORTED_GOOGLE_FONTS.filter(font => font.toLowerCase().includes(value.toLowerCase()));
  }

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  /* eslint-enable*/

  /* interface implementations */
  onChange = (_value: string): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }
}
