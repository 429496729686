import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { LoyaltyProgramCatalogsService } from '../../services/loyalty-program-catalogs.service';
import {
  loadLoyaltyProgramCatalogs,
  loadLoyaltyProgramCatalogsFailure,
  loadLoyaltyProgramCatalogsSuccess
} from '../actions/loyalty-program-catalogs.actions';

@Injectable()
export class LoyaltyProgramCatalogsEffects {
  loadLoyaltyProgramCatalogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLoyaltyProgramCatalogs),
      exhaustMap(() =>
        this.loyaltyProgramCatalogsService.getCatalogs().pipe(
          map(loyaltyProgramCatalogs => loadLoyaltyProgramCatalogsSuccess({ loyaltyProgramCatalogs })),
          catchError(error => of(loadLoyaltyProgramCatalogsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private loyaltyProgramCatalogsService: LoyaltyProgramCatalogsService
  ) {}
}
