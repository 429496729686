import { catchError, map, withLatestFrom, switchMap, tap, delay } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { sharedConfigQuery } from '@core/store/shared-config/selectors/shared-config.selectors';
import { SharedConfigState } from '@core/types';

import { McTenantsService } from '../../../mc-tenants/services/mc-tenants.service';
import {
  updateTenantPaymentTier,
  updateTenantPaymentTierFailure,
  updateTenantPaymentTierSuccess
} from '../actions/tenant-payment-tier.actions';

@Injectable()
export class TenantPaymentTierEffects {
  updateTenantPaymentTier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTenantPaymentTier),
      withLatestFrom(this.store.select(sharedConfigQuery.getVersion)),
      switchMap(([{ tenantId, paymentTierId }, version]) =>
        this.mcTenantsService.updateTenantPaymentTier({ tenantId, paymentTierId, version }).pipe(
          map(paymentTier => updateTenantPaymentTierSuccess(paymentTier)),
          catchError(error => of(updateTenantPaymentTierFailure(error)))
        )
      )
    )
  );

  updateTenantPaymentTierSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTenantPaymentTierSuccess),
      delay(3000), // to allow the user to see the success snackbar message before reloading
      tap(() => of(window.location.reload()))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<SharedConfigState>,
    private mcTenantsService: McTenantsService
  ) {}
}
