<div class="main-container">
  <ng-container>
    <div class="filter-form-wrapper">
      <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
        <mat-form-field>
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-label>Offer title</mat-label>
          <input matInput aria-label="Offer title" type="search" formControlName="title" />
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="filterForm.get('title')"
            [loading]="loading$ | async"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field>
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-label>Offer brand</mat-label>
          <input matInput aria-label="Offer brand" type="search" formControlName="brand" />
          <admin-input-reset
            matSuffix
            initialValue=""
            [loading]="loading$ | async"
            [inputControl]="filterForm.get('brand')"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field>
          <mat-icon matPrefix svgIcon="filter"></mat-icon>
          <mat-label>Activity type</mat-label>
          <mat-select formControlName="activity" disableOptionCentering>
            @for (action of actions$ | async; track action) {
              <mat-option [value]="action">
                {{ action | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            }
          </mat-select>
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="filterForm.get('activity')"
            [loading]="loading$ | async"
          ></admin-input-reset>
        </mat-form-field>
      </form>
    </div>

    <div *ngIf="loading$ | async" class="progress-loader">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div id="user-offer-activities-table" *ngIf="!(loading$ | async)">
      @if (userActivities.length === 0) {
        <div class="empty-state-container">
          <admin-empty-state-table></admin-empty-state-table>
        </div>
      } @else {
        <div class="tile-section">
          @for (activity of userActivities; track activity) {
            <div class="tile">
              <h2>{{ activity.genericOffer.title }}</h2>

              <span class="schedule-icon">
                <mat-icon>schedule</mat-icon>
                <span>{{ activity.eventDate | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}</span>
              </span>

              <div class="display">
                <div class="medium-text">Offer ID</div>
                <span>{{ activity.genericOfferId }}</span>

                <div class="medium-text">Brand</div>
                <span>{{ activity.genericOffer.brand }}</span>

                <div class="medium-text">Action</div>
                <span>{{ activity.action | customTitleCase: 'fromSnakeCase' }}</span>

                <div class="medium-text">Supplier ID</div>
                <span>{{ activity.genericOffer.supplierId }}</span>
              </div>
              @if (activity.genericOffer.type === 'affiliate' && activity.metadata.affiliateLink) {
                <admin-copy-to-clipboard
                  [content]="activity.metadata.affiliateLink"
                  [contentName]="'Merchant Link'"
                ></admin-copy-to-clipboard>
                <span>Copy merchant link</span>
              }
            </div>
          }
        </div>
      }
    </div>

    <mat-paginator
      [pageSizeOptions]="[20, 50]"
      [pageIndex]="filter.page - 1"
      [pageSize]="filter.limit"
      [length]="totalCount"
      [tableId]="'user-offer-activities-table'"
      (page)="onPage($event)"
      adminPaginator
    ></mat-paginator>
  </ng-container>
</div>
