import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AttributesSettings, RequestValidationSettings, SCOPES_OR } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';

import { refundOrderItem } from '../../store/actions/order-items.actions';
import { GiftCardOrderItemData, MerchandiseOrderItemData, OrderItem, OrderItemTypeCode } from '../../types';

@Component({
  selector: 'admin-refund-order-button-v2',
  templateUrl: './refund-order-button-v2.component.html'
})
export class RefundOrderButtonV2Component implements OnInit {
  @Input() type: 'primary' | 'secondary';
  @Input() orderItem: OrderItem;

  data: GiftCardOrderItemData | MerchandiseOrderItemData;
  allowCancellationStatuses = ['fulfilled', 'processing'];
  enableRefundButton = true;
  readonly SCOPES = SCOPES_OR;

  constructor(
    private matDialog: MatDialog,
    private store: Store,
    private accessPolicies: AccessPolicies
  ) {}

  get styleClassName(): string {
    switch (this.type) {
      case 'primary': {
        return 'primary-button';
      }
      case 'secondary':
      default: {
        return 'secondary-button';
      }
    }
  }

  ngOnInit(): void {
    this.data = this.orderItem.data as GiftCardOrderItemData | MerchandiseOrderItemData;
    const policySettings = this.accessPolicies.getRequestValidationSettings('orderItems', 'cancel')
      ?.attributes as AttributesSettings;
    if (policySettings?.totalValue) {
      const orderTotalValue = this.data.displayValue * this.orderItem.quantity;
      this.enableRefundButton = orderTotalValue <= (policySettings.totalValue as RequestValidationSettings).lte;
    }
  }

  getType(orderItemType: string): string {
    switch (orderItemType) {
      case OrderItemTypeCode.GiftCardOrderItem:
      case OrderItemTypeCode.PhysicalGiftCardOrderItem: {
        return 'Gift Card';
      }
      case OrderItemTypeCode.MerchandiseOrderItem: {
        return 'Merchandise';
      }
    }
  }

  refundOrderItemDialog(): void {
    const type = this.getType(this.orderItem.type);
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          styleClassName: 'confirm-dialog',
          dialogTitle: `Refund ${type} Order`,
          confirmText: `<span class="medium-text">Please make sure that the supplier side has cancelled this ${type} Order.</span>
            <br><br>Are you sure you want to refund this ${type} Order?`,
          confirmButtonText: 'Yes, refund order'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(refundOrderItem({ id: this.orderItem.id }));
        }
      });
  }
}
