<div class="dialog-content details-view">
  <div mat-dialog-title>Edit MFA identity</div>

  <form [formGroup]="editForm" (keydown.enter)="$event.preventDefault()">
    <mat-slide-toggle formControlName="verified">Verified</mat-slide-toggle>
    <mat-form-field>
      <mat-label>Login ID</mat-label>
      <input matInput formControlName="uid" />
      <mat-error *ngIf="uidControl.hasError('required')">Required</mat-error>
      <mat-error *ngIf="uidControl.hasError('email') && providerId === 'email'">{{ invalidEmailMessage }}</mat-error>
      <mat-error *ngIf="uidControl.hasError('invalidE164') && providerId === 'sms'">{{ invalidE164Message }}</mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions>
    <button class="cancel-button" (click)="dialogRef.close(false)">
      <span>Cancel</span>
    </button>

    <button
      [disabled]="isFormSubmissionInvalid"
      [ngClass]="isFormSubmissionInvalid ? 'disabled-button' : 'primary-button'"
      (click)="submitForm()"
    >
      <span>Save changes</span>
    </button>
  </div>
</div>
