import { Nullable } from '@shared/types';
import { DropdownOption } from '@utils';

export const ObjectUtils = {
  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  },

  isNullish(value: any): boolean {
    return value === null || value === undefined || value === '';
  },

  isDefined(obj: any): boolean {
    return !this.isNullish(obj);
  },

  isNumber(value: any): boolean {
    return typeof value === 'number' && !Number.isNaN(value);
  },

  isEmptyArray(value: any): boolean {
    if (!Array.isArray(value)) {
      return false;
    }

    return value.length === 0 ? true : value.every(this.isNullish);
  },

  isEmptyObject(value: any): boolean {
    return this.isObject(value) && Object.getOwnPropertyNames(value).length === 0;
  },

  isNonEmptyObject(value: any): boolean {
    return this.isObject(value) && Object.getOwnPropertyNames(value).length > 0;
  },

  isObject(value: any): boolean {
    return !this.isNullish(value) && typeof value === 'object' && !Array.isArray(value);
  },

  isEmptyValueObject(value: any): boolean {
    const entries = Object.entries(value);

    return entries.reduce((acc, [_, val]) => {
      if (this.isObject(val) && !(val instanceof Date)) {
        return acc && this.isEmptyValueObject(val);
      } else if (Array.isArray(val)) {
        return acc && this.isEmptyArray(val);
      } else if (ObjectUtils.isBoolean(val) || ObjectUtils.isNumber(val)) {
        return acc && false;
      } else {
        return acc && this.isNullish(val);
      }
    }, true);
  },

  hasNonEmptyChildObject(obj: object): boolean {
    for (const key in obj) {
      if (this.isNonEmptyObject(obj[key])) {
        return true;
      }
    }
    return false;
  },

  hasDifferentValues(value1: any, value2: any): boolean {
    if (value1 === value2) {
      return false;
    }

    if (typeof value1 === 'number' && typeof value2 === 'number' && Number.isNaN(value1) && Number.isNaN(value2)) {
      return false;
    }

    if (this.isObject(value1) && this.isObject(value2)) {
      return Object.keys(value1).length === Object.keys(value2).length ? Object.entries(value1).reduce(
          (acc, [key, value]) => acc || this.hasDifferentValues(value, value2[key]),
          false
        ) : true;
    }

    if (Array.isArray(value1) && Array.isArray(value2)) {
      return value1.length === value2.length ? value1.reduce((acc, item, index) => acc || this.hasDifferentValues(item, value2[index]), false) : true;
    }

    return value1 !== value2;
  },

  isArrayOfObjects(value: any): value is Array<any> {
    return Array.isArray(value) && value.every(item => this.isObject(item));
  },

  sanitizeRequestObject<T>(
    value: T,
    config: SanitizeRequestObjectConfig = {
      ignoredKeys: []
    }
  ): T {
    const valueCopy = { ...value };

    const notIgnoredKeys = Object.getOwnPropertyNames(valueCopy).filter(key => !(config.ignoredKeys || []).includes(key));

    for (const key of notIgnoredKeys) {
      if (this.isObject(valueCopy[key])) {
        valueCopy[key] = this.sanitizeRequestObject(valueCopy[key], config);
      }

      if (this.isArrayOfObjects(valueCopy[key])) {
        valueCopy[key] = valueCopy[key].map(item => this.sanitizeRequestObject(item, config));
      }

      if (this.isNullish(valueCopy[key]) || this.isEmptyArray(valueCopy[key]) || this.isEmptyObject(valueCopy[key])) {
        delete valueCopy[key];
      }
    }

    return valueCopy;
  },

  prepareQueryObject(obj: object): object {
    const result = new Map();

    const traverseObj = (key: string, value: any): void => {
      if (Array.isArray(value)) {
        result.set(
          `${key}[]`,
          value.map(val => val.toString())
        );
      } else if (this.isObject(value)) {
        Object.keys(value).forEach(subkey => {
          traverseObj(`${key}[${subkey}]`, value[subkey]);
        });
      } else {
        result.set(`${key}`, value.toString());
      }
    };

    Object.keys(obj).forEach(key => {
      traverseObj(key, obj[key]);
    });

    return Object.fromEntries(result);
  },

  parseAsObject(json: string): object {
    try {
      return JSON.parse(json);
    } catch {
      return {};
    }
  },

  // reference: https://developer.mozilla.org/en-US/docs/Glossary/Base64
  encodeBase64(obj: object): string {
    return btoa(
      encodeURIComponent(JSON.stringify(obj)).replaceAll(/%([0-9A-F]{2})/g, (_, p1) =>
        String.fromCharCode(Number('0x' + p1))
      )
    );
  },

  encodeAttributesAsBase64<T>(value: T, keys: string[]): T {
    const valueCopy = { ...value };

    for (const key of keys) {
      if (valueCopy[key] !== undefined) {
        valueCopy[key] = this.encodeBase64(valueCopy[key]);
      }
    }

    return valueCopy;
  },

  deepCopy<T>(value: T): T {
    return structuredClone(value);
  },

  getObjectSubset(obj: object, keys: string[]): object {
    return keys.reduce((subset, key) => {
      if (obj[key] !== undefined) {
        subset[key] = obj[key];
      }
      return subset;
    }, {});
  },

  getNestedObjectDataWithPath<T>(obj: object, path: string[]): Nullable<T> {
    if (!Array.isArray(path)) {
      return null;
    }

    return path.reduce((acc: any, pathItem) => {
      if (!ObjectUtils.isObject(acc)) {
        return null;
      }

      return acc[pathItem];
    }, obj) as T;
  },

  // this will only work for primitive values
  removeArrayDuplicates<T>(arr: T[]): T[] {
    return [...new Set(arr)];
  },

  flattenObjectByKeys(data: object | object[], flattenKeys: string[]): object | object[] {
    if (Array.isArray(data)) {
      return data.map(item => ObjectUtils.flattenObjectByKeys(item, flattenKeys));
    }

    if (ObjectUtils.isObject(data)) {
      let result = {};
      Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
          return (result[key] = ObjectUtils.flattenObjectByKeys(data[key], flattenKeys));
        }

        if (ObjectUtils.isObject(data[key])) {
          const flattenedValue = ObjectUtils.flattenObjectByKeys(data[key], flattenKeys);
          return (result = flattenKeys.includes(key)
            ? { ...result, ...flattenedValue }
            : { ...result, [key]: flattenedValue });
        }

        return (result[key] = data[key]);
      });

      // flatten object for array value if possible
      // example: { foo: { data: [xxx] } }, returns { foo: [xxx] }
      const entries = Object.entries(result);

      if (entries.length === 1) {
        const [key, value] = entries[0];

        if (flattenKeys.includes(key) && Array.isArray(value)) {
          return value;
        }
      }

      return result;
    }

    return data;
  },

  invertKeyValue(data: Record<string, string>): Record<string, string> {
    return Object.fromEntries(Object.entries(data).map(([k, v]) => [v, k]));
  },

  renameKey(obj: any, oldKey: string, newKey: string): any {
    let objCopy = { ...obj };

    objCopy[newKey] = objCopy[oldKey];
    delete objCopy[oldKey];

    return objCopy;
  },

  convertRecordToDropdownOption<KeyType extends keyof any>(
    record: Record<KeyType, string>
  ): DropdownOption<KeyType>[] {
    return Object.entries(record).map(([value, label]) => ({
      value,
      label
    })) as DropdownOption<KeyType>[];
  },
};

interface SanitizeRequestObjectConfig {
  ignoredKeys?: string[];
}
