import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../types';

import { UserStateAttributes, UserStatesType } from './../types/users.type';
import { UsersUtils } from './../utils/users-utils';

@Pipe({ name: 'stateDisplayPipe' })
export class StateDisplayPipe implements PipeTransform {
  transform(user: User): UserStatesType | null {
    const { status, loginMode, activated } = user;
    return UsersUtils.convertAttributesToState({ status, loginMode, activated } as UserStateAttributes);
  }
}
