import { catchError, concatMap, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { updateApprovalRequestedAction } from '@core/store/interrupted/actions/interrupted.actions';
import { HttpError } from '@core/types';

import { usersQuery } from '../../../users/store/selectors/users.selectors';
import { isAdmin, UserState } from '../../../users/types';
import { PointsAdjustmentsService } from '../../services/points-adjustments/points-adjustments.service';
import { PointsActivitiesFilter } from '../../types';
import { loadPointsAccounts } from '../actions/points-accounts.actions';
import { loadPointsActivities } from '../actions/points-activities.actions';
import {
  makePointsAdjustment,
  makePointsAdjustmentFailure,
  makePointsAdjustmentSuccess
} from '../actions/points-adjustments.actions';

@Injectable()
export class PointsAdjustmentsEffects {
  makePointsAdjustment$ = createEffect(() => {
    const getSuccessAction = (action): Action =>
      makePointsAdjustmentSuccess({
        userId: action.userId,
        dialogRefId: action.dialogRefId,
        pointsAccountId: action.pointsAdjustment.pointsAccountId
      });

    return this.actions$.pipe(
      ofType(makePointsAdjustment),
      tap(action => this.store.dispatch(updateApprovalRequestedAction({ action: getSuccessAction(action) }))),
      exhaustMap(action =>
        this.pointsAdjustmentsService.makePointsAdjustment(action.userId, action.pointsAdjustment).pipe(
          map(() => getSuccessAction(action)),
          catchError((error: HttpError) => of(makePointsAdjustmentFailure({ error })))
        )
      )
    );
  });

  makePointsAdjustmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(makePointsAdjustmentSuccess),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.userStore.select(usersQuery.getUserById(action.userId))))
      ),
      exhaustMap(([{ dialogRefId }, user]) => {
        this.matDialog.getDialogById(dialogRefId)?.close(true);
        const userId = user.id;
        const actions: Action[] = [];

        // load the latest points account data after making points adjustment
        if (!isAdmin(user) && this.scopes.has('user_points_accounts:index')) {
          actions.push(loadPointsAccounts({ userId, pointsActivitiesLoad: 'force' }));
        } else {
          actions.push(
            loadPointsActivities({ userId, filter: { ...new PointsActivitiesFilter() } }),
            loadPointsAccounts({ userId, pointsActivitiesLoad: 'force' })
          );
        }

        return merge(actions);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private pointsAdjustmentsService: PointsAdjustmentsService,
    private store: Store<any>,
    private userStore: Store<UserState>,
    private matDialog: MatDialog,
    private scopes: Scopes,
    @Inject('customerBankIdentityProvider') private customerBankIdentityProvider: string
  ) {}
}
