import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { LoyaltyProgramCatalog } from '../types/loyalty-program-catalogs.type';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyProgramCatalogsService {
  constructor(private missionControlService: MissionControlService) {}

  getCatalogs(): Observable<LoyaltyProgramCatalog[]> {
    return this.missionControlService.get<LoyaltyProgramCatalog[]>('loyalty_programs/catalogs');
  }
}
