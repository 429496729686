import { debounceTime } from 'rxjs/operators';

import { trigger } from '@angular/animations';
import { AfterViewInit, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { expansionAnimation } from '@shared/animations/expansion-animations';
import { or, Params } from '@utils';

import { loadUserTokens } from '../../store/actions/user-tokens.actions';
import { userTokensQuery } from '../../store/selectors/user-tokens.selectors';
import { usersQuery } from '../../store/selectors/users.selectors';
import { UserState, UserToken, UserTokensFilter, UserTokensFilterForm, UserTokenState } from '../../types';
import { UserTokenInvalidateDialogComponent } from '../user-token-invalidate-dialog/user-token-invalidate-dialog.component';

@Component({
  selector: 'admin-user-tokens',
  templateUrl: './user-tokens.component.html',
  styleUrls: ['./user-tokens.component.scss'],
  animations: [trigger('detail_expand', expansionAnimation)]
})
export class UserTokensComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  destroyRef = inject(DestroyRef);

  userTokens$: Observable<UserToken[]>;
  userOrTokensLoading$: Observable<boolean>;
  userTokensTotalCount$: Observable<number>;
  filter: UserTokensFilter;
  userId: string;

  displayedColumns = ['id', 'type', 'issued_at', 'expires_at', 'invalidated', 'invalidated_reason', 'invalidated_at'];
  expandedTokenColumns: string[] = ['jwt', 'device_info'];

  filterForm: FormGroup<UserTokensFilterForm>;

  typeList: { [key: string]: string } = {
    Access: 'access',
    Id: 'id',
    Refresh: 'refresh',
    User: 'user'
  };

  constructor(
    private userTokenStore: Store<UserTokenState>,
    private userStore: Store<UserState>,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private scopes: Scopes
  ) {}

  ngOnInit(): void {
    this.initiateVariables();
    this.createFilterForm();

    if (this.scopes.hasAny(SCOPES_OR.invalidateUserTokens)) {
      this.displayedColumns.push('invalidate');
    }
  }

  initiateVariables(): void {
    this.userId = Params.find(this.route, 'userId');
    this.filter = this.route.snapshot.data.userTokensFilter;
    this.userTokens$ = this.userTokenStore.select(userTokensQuery.getUserTokensList);
    this.userTokensTotalCount$ = this.userTokenStore.select(userTokensQuery.getUserTokensTotalCount);
    this.userOrTokensLoading$ = or(
      this.userStore.select(usersQuery.isBatchLoading),
      this.userTokenStore.select(userTokensQuery.isBatchLoading)
    );
  }

  createFilterForm(): void {
    this.filterForm = this.fb.group({
      type: this.fb.control(this.filter.type),
      invalidated: this.fb.control(this.filter.invalidated),
      sessionId: this.fb.control(this.filter.sessionId)
    });
  }

  ngAfterViewInit(): void {
    this.subscribeToMatSortChange();
    this.subscribeToSessionIdChange();
  }

  subscribeToMatSortChange(): void {
    this.sort.sortChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      this.filter = {
        ...this.filter,
        page: 1,
        sortBy: event.direction ? event.active : '',
        sortDirection: event.direction
      };
      this.dispatchLoadUserTokens();
    });
  }

  onPage(pageEvent: PageEvent): void {
    this.filter = {
      ...this.filter,
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    };
    this.dispatchLoadUserTokens();
  }

  decodeJwt(jwt: string): string[] {
    const [header, body, signature] = jwt.split('.');

    const parsedParts = [header, body].map(part => {
      const decodedPart = atob(part);
      return JSON.parse(decodedPart);
    });

    return [...parsedParts, { signature }];
  }

  onTypeChange(): void {
    const type = this.filterForm.controls.type.value;
    this.filter = {
      ...this.filter,
      page: 1,
      type
    };
    this.dispatchLoadUserTokens();
  }

  onInvalidatedChange(): void {
    const invalidated = this.filterForm.controls.invalidated.value;
    this.filter = {
      ...this.filter,
      page: 1,
      invalidated
    };
    this.dispatchLoadUserTokens();
  }

  subscribeToSessionIdChange(): void {
    this.filterForm.controls.sessionId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000))
      .subscribe(sessionId => {
        this.filter = {
          ...this.filter,
          page: 1,
          sessionId
        };
        this.dispatchLoadUserTokens();
      });
  }

  dispatchLoadUserTokens(): void {
    this.userTokenStore.dispatch(loadUserTokens({ userId: this.userId, filter: this.filter }));
  }

  openInvalidateDialog(token: UserToken): void {
    this.dialog.open(UserTokenInvalidateDialogComponent, {
      height: '450px',
      width: '600px',
      data: {
        id: token.id,
        userId: token.userId
      }
    });
  }

  jwtTrackBy(index: number): number {
    return index;
  }
}
