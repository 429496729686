import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { TagDictionaryService } from '@shared/services/tag-dictionary.service';
import { Nullable, Tag } from '@shared/types';
import { DictionaryType, formatMessage, getTagDictionaries, isDataFetching } from '@tag-utils';

import { AuditLog, auditLogAdapter, AuditLogState } from '../../types';

const auditLogDetector = createDetector<AuditLog>();
const getAuditLogState = createFeatureSelector<AuditLogState>('auditLog');

const { selectAll: getAuditLogList, selectEntities: getAuditLogDictionary } =
  auditLogAdapter.getSelectors(getAuditLogState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError
} = getCallStateSelectors<AuditLogState>(getAuditLogState);

@Injectable({ providedIn: 'root' })
export class AuditLogsQuery {
  getCount = createSelector(getAuditLogState, state => state.count);
  getFilter = createSelector(getAuditLogState, state => state.filter);

  getAuditLogs = createSelector(getAuditLogList, getTagDictionaries, (auditLogs, tagDictionaries) =>
    auditLogs.map(auditLog => {
      const formattedTags = this.formattedTags(auditLog, tagDictionaries);

      return {
        ...auditLog,
        message: formatMessage(auditLog.message, formattedTags),
        tags: formattedTags
      };
    })
  );

  isBatchInitial = isBatchInitial;
  isBatchLoading = isBatchLoading;
  isBatchResting = isBatchResting;
  getBatchError = getBatchError;
  isSingleInitial = isSingleInitial;
  isSingleLoading = isSingleLoading;
  isSingleResting = isSingleResting;
  getSingleError = getSingleError;
  isDataFetching = isDataFetching;
  getAuditLogList = getAuditLogList;

  constructor(private tagDictionaryService: TagDictionaryService) {}

  getAuditLogById = (id: string): Selector<AuditLogState, Nullable<AuditLog>> =>
    createSelector(getAuditLogDictionary, getTagDictionaries, (entities, tagDictionaries) => {
      const entity = entities[id];

      return entity
        ? {
            ...entity,
            tags: this.formattedTags(entity, tagDictionaries)
          }
        : null;
    });

  isAuditLogLoaded = (id: string): Selector<AuditLogState, boolean> =>
    createSelector(getAuditLogDictionary, auditLogs => auditLogDetector('oneLoaded')(auditLogs, id));

  formattedTags = (
    auditLog: AuditLog,
    [
      appConnections,
      apps,
      authenticationProviders,
      campaigns,
      domains,
      fraudRules,
      loyaltyPrograms,
      merchandises,
      offers,
      redemptionRates,
      users
    ]: Dictionary<DictionaryType>[]
  ): Tag[] =>
    auditLog?.tags.map(tag => {
      switch (tag.type) {
        case 'app_connection_id': {
          return this.tagDictionaryService.formatTag(tag, appConnections[tag.id]);
        }
        case 'app_id': {
          return this.tagDictionaryService.formatTag(tag, apps[tag.id]);
        }
        case 'authentication_provider_reference': {
          return this.tagDictionaryService.formatTag(tag, authenticationProviders[tag.id]);
        }
        case 'campaign_id': {
          return this.tagDictionaryService.formatTag(tag, campaigns[tag.id]);
        }
        case 'domain_id': {
          return this.tagDictionaryService.formatTag(tag, domains[tag.id]);
        }
        case 'fraud_rule_id': {
          return this.tagDictionaryService.formatTag(tag, fraudRules[tag.id]);
        }
        case 'loyalty_program_id': {
          return this.tagDictionaryService.formatTag(tag, loyaltyPrograms[tag.id]);
        }
        case 'merchandise_id': {
          return this.tagDictionaryService.formatTag(tag, merchandises[tag.id]);
        }
        case 'offer_id': {
          return this.tagDictionaryService.formatTag(tag, offers[tag.id]);
        }
        case 'redemption_rate_id': {
          return this.tagDictionaryService.formatTag(tag, redemptionRates[tag.id]);
        }
        case 'agent_id':
        case 'user_id':
        case 'requester_id':
        case 'approver_id': {
          return this.tagDictionaryService.formatTag(tag, users[tag.id]);
        }
        case 'points_activity_id': {
          return this.tagDictionaryService.formatTag(tag, {
            tag,
            userId: auditLog.tags.find(tagItem => tagItem.type === 'user_id')!.id
          });
        }
        default: {
          return this.tagDictionaryService.formatTag(tag);
        }
      }
    });
}
