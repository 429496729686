import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { AuditLog, AuditLogsFilter, AuditLogsResult } from '../../types';

export const loadAuditLogs = createAction('[Audit Logs] Load Audit Logs', props<{ filter: AuditLogsFilter }>());
export const loadAuditLogsSuccess = createAction(
  '[Audit Logs] Load Audit Logs Success',
  props<{ result: AuditLogsResult }>()
);
export const loadAuditLogsFailure = createAction('[Audit Logs] Load Audit Logs Failure', props<ErrorState>());

export const loadAuditLog = createAction('[Audit Logs] Load Audit Log', props<{ id: string }>());
export const loadAuditLogSuccess = createAction('[Audit Logs] Load Audit Log Success', props<{ auditLog: AuditLog }>());
export const loadAuditLogFailure = createAction('[Audit Logs] Load Audit Log Failure', props<ErrorState>());
