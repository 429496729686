import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { FraudEvaluationsFilter, FraudEvaluationsResult } from '../../types';

export const loadFraudEvaluations = createAction(
  '[Fraud Evaluations] Load Fraud Evaluations',
  props<{ filter: FraudEvaluationsFilter }>()
);
export const loadFraudEvaluationsSuccess = createAction(
  '[Fraud Evaluations] Load Fraud Evaluations Success',
  props<{ result: FraudEvaluationsResult }>()
);
export const loadFraudEvaluationsFailure = createAction(
  '[Fraud Evaluations] Load Fraud Evaluations Failure',
  props<ErrorState>()
);
