import { Dialog, DialogConfig } from '@angular/cdk/dialog';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable, OnDestroy, RendererFactory2, TemplateRef } from '@angular/core';
import { take } from 'rxjs';

import { DRAWER_USER_CLASS } from '@shared/components/drawer-container/drawer-user-class.token';
import { DrawerRef } from '@shared/services/drawer/drawer-ref.type';
import { DRAWER_CONFIG, DRAWER_DATA, DRAWER_DEFAULT_CONFIG } from '@shared/tokens/drawer.tokens';
import { Nullable } from '@shared/types';
import { DrawerConfig } from '@shared/types/drawer.type';

import { DrawerContainerComponent } from '../../components/drawer-container/drawer-container.component';

/**
 * This is the main service that we will use to open drawer
 * We just need to inject this service and call `open` method
 * to open drawer, and we will receive `DrawerRef` that we can
 * use to close drawer or manage our drawer
 */
@Injectable({
  providedIn: 'root'
})
export class DrawerService implements OnDestroy {
  private document = inject(DOCUMENT);
  private renderer = inject(RendererFactory2).createRenderer(null, null);

  /** Reference to the currently opened drawer. */
  private drawerRef: Nullable<DrawerRef>;

  /** Default drawer config. */
  private defaultConfig = inject(DRAWER_DEFAULT_CONFIG);

  /** Dialog CDK that we will use to open overlay. */
  private dialog = inject(Dialog);

  /** Overlay CDK that we will use to get our position. */
  private overlay = inject(Overlay);

  /**
   * Get current drawerRef
   * @returns current drawerRef
   */
  get currentDrawerRef(): Nullable<DrawerRef> {
    return this.drawerRef;
  }

  /**
   * Open new drawer with componentRef or templateRef
   * @param drawerContent componentRef or templateRef of the drawer content that we want to open
   * @param additionalConfig additional config for the drawer beside default config
   * @returns drawerRef reference to the drawer that we just opened
   */
  open(drawerContent: ComponentType<any> | TemplateRef<any>, additionalConfig: DrawerConfig = {}): DrawerRef {
    // Close current drawer if there is one
    this.closeCurrentDrawer();

    // Add overflow-hidden class to body to prevent body scroll
    this.renderer.addClass(this.document.body, 'overflow-hidden');

    const drawerConfig: DrawerConfig = {
      ...this.defaultConfig,
      ...additionalConfig,
      drawerId: additionalConfig.drawerId ?? 'admin-drawer'
    };

    const dialogConfig: DialogConfig = this.getDialogConfigForDrawer(drawerConfig);

    // Open drawer using Dialog CDK
    this.dialog.open(drawerContent, {
      ...dialogConfig,
      // Setup position, and scroll strategy for the drawer
      positionStrategy: this.overlay.position().global().right(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      // Override container type to our custom drawer container
      // so we can have our custom animation
      container: {
        type: DrawerContainerComponent,
        providers: () => [
          // Drawer config is use for our custom drawer container
          { provide: DRAWER_CONFIG, useValue: drawerConfig },
          // Dialog config is use for CDK dialog since our custom drawer container
          // is extending CDK dialog container under the hood, and we will need to
          // pass the user config to CDK dialog container
          { provide: DialogConfig, useValue: dialogConfig },
          { provide: DRAWER_USER_CLASS, useValue: additionalConfig.drawerClass || '' }
        ]
      },
      // Pass additional drawerRef data to the drawer content if it is templateRef
      // CDK will pass the drawerRef to the componentRef automatically but
      // it will use CDK Ref instead of our custom Ref
      templateContext: () => ({ drawerRef: this.drawerRef, drawerData: drawerConfig.data }),
      // Setup our drawerRef and provide necessary data to the drawer container
      providers: (ref, _, dialogContainer) => {
        this.drawerRef = new DrawerRef(ref, drawerConfig, dialogContainer as DrawerContainerComponent);

        return [
          {
            provide: DrawerRef,
            useValue: this.drawerRef
          },
          {
            provide: DrawerContainerComponent,
            useValue: dialogContainer
          },
          {
            provide: DRAWER_DATA,
            useValue: drawerConfig.data
          }
        ];
      },
      // Disable all default dialog close event so that we can handle it ourself
      // except for closeOnNavigation
      disableClose: true,
      closeOnDestroy: false,
      closeOnOverlayDetachments: false
    });

    // Listen to drawer close event and remove overflow-hidden class
    this.currentDrawerRef!.closed$.pipe(take(1)).subscribe(() => {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
    });

    return this.drawerRef!;
  }

  /**
   * Close current drawer
   */
  closeCurrentDrawer(): void {
    if (this.drawerRef) {
      this.drawerRef.close();
      this.drawerRef = null;
    }
  }

  /**
   * Get dialog config for drawer
   * @param config drawer config
   * @returns dialog config
   */
  getDialogConfigForDrawer(config: DrawerConfig): DialogConfig {
    const dialogConfig = new DialogConfig();
    const { drawerId, ...otherConfigs } = config;

    return { ...dialogConfig, ...otherConfigs, id: drawerId };
  }

  ngOnDestroy(): void {
    this.closeCurrentDrawer();
  }
}
