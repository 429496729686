import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';

import { FeatureAvailabilityService } from '@shared/services/feature-availability/feature-availability.service';

export const featureAvailabilityGuard: CanMatchFn = (route: Route, _segments: UrlSegment[]) => {
  const featureAvailabilityService = inject(FeatureAvailabilityService);

  return featureAvailabilityService.isFeatureAllowed(route.data?.features, route.data?.allowedAvailabilityStatuses);
};
