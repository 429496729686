import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { AppConnectionsService } from '../../../apps/services/app-connections.service';
import { fetchAppConnection } from '../../../apps/store/actions/app-connections.actions';
import { appConnectionsQuery } from '../../../apps/store/selectors/app-connections.selectors';
import { AppConnection, AppConnectionState } from '../../../apps/types/app-connections.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class AppConnectionTagDefs {
  readonly tagDefs: TagDefs<AppConnection> = {
    app_connection_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showAppConnections)
          ? combineLatest([
              this.appConnectionStore.select(appConnectionsQuery.getAppConnectionById(id)),
              this.appConnectionStore.select(appConnectionsQuery.getFetchList)
            ]).pipe(
              first(),
              map(([appConnection, fetchList]) => {
                if (!appConnection && !fetchList.includes(id)) {
                  this.appConnectionStore.dispatch(fetchAppConnection({ id }));
                }
              })
            )
          : of(null),
      select: id => this.appConnectionStore.pipe(select(appConnectionsQuery.getAppConnectionById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showAppConnections)
          ? this.appConnectionStore.pipe(
              select(appConnectionsQuery.getAppConnectionById(id)),
              first(),
              exhaustMap(appConnection =>
                appConnection ? of(appConnection) : this.appConnectionsService.getAppConnection(id)
              )
            )
          : of(null),
      getDisplayPrefix: () => 'App Connection',
      getDisplayValue: (appConnection: AppConnection) => appConnection.name,
      getRouteLink: (appConnection: AppConnection) =>
        `/apps/${appConnection.resourceId}/details/connections/${appConnection.id}`
    }
  };

  constructor(
    private appConnectionStore: Store<AppConnectionState>,
    private appConnectionsService: AppConnectionsService,
    private scopes: Scopes
  ) {}
}
