import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { DomainsService } from '../../services/domains.service';
import { DomainsFilter, DomainState } from '../../types';
import {
  createDomain,
  createDomainFailure,
  createDomainSuccess,
  deleteDomain,
  deleteDomainFailure,
  deleteDomainSuccess,
  fetchDomain,
  fetchDomainFailure,
  fetchDomainSuccess,
  loadDomain,
  loadDomainFailure,
  loadDomains,
  loadDomainsFailure,
  loadDomainsSuccess,
  loadDomainSuccess,
  updateDomain,
  updateDomainFailure,
  updateDomainSuccess
} from '../actions/domains.actions';
import { domainsQuery } from '../selectors/domains.selectors';

@Injectable()
export class DomainsEffects {
  loadDomains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDomains),
      exhaustMap(action =>
        this.domainsService.getDomains(action.filter).pipe(
          map(result => loadDomainsSuccess({ result })),
          catchError(error => of(loadDomainsFailure({ error })))
        )
      )
    )
  );

  loadDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDomain),
      exhaustMap(action =>
        this.domainsService.getDomain(action.id).pipe(
          map(domain => loadDomainSuccess({ domain })),
          catchError(error => of(loadDomainFailure({ error })))
        )
      )
    )
  );

  fetchDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDomain),
      mergeMap(action =>
        this.domainsService.getDomain(action.id).pipe(
          map(domain => fetchDomainSuccess({ domain })),
          catchError(() => of(fetchDomainFailure({ id: action.id })))
        )
      )
    )
  );

  createDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDomain),
      exhaustMap(action =>
        this.domainsService.createDomain(action.domain).pipe(
          map(domain => createDomainSuccess({ domain })),
          catchError(error => of(createDomainFailure({ error })))
        )
      )
    )
  );

  updateDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDomain),
      exhaustMap(action =>
        this.domainsService.updateDomain(action.domain).pipe(
          map(domain => updateDomainSuccess({ domain })),
          catchError(error => of(updateDomainFailure({ error })))
        )
      )
    )
  );

  deleteDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDomain),
      exhaustMap(action =>
        this.domainsService.deleteDomain(action.id).pipe(
          map(() => deleteDomainSuccess()),
          catchError(error => of(deleteDomainFailure({ error })))
        )
      )
    )
  );

  createUpdateDomainSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDomainSuccess, updateDomainSuccess),
      exhaustMap(action => {
        const filter = new DomainsFilter();
        return merge([loadDomains({ filter }), routerNavigate({ path: `/domains/${action.domain.id}` })]);
      })
    )
  );

  deleteDomainSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDomainSuccess),
      withLatestFrom(this.store.select(domainsQuery.getFilter)),
      map(([_, filter]) => {
        filter ||= new DomainsFilter();
        return loadDomains({ filter });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private domainsService: DomainsService,
    private store: Store<DomainState>
  ) {}
}
