import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';

import { Nullable } from '@shared/types';
import { imageErrorMessage } from '@shared/types/image.type';
import { IMAGE_MEDIA_TYPE_TO_FILE_EXTENSION, IMAGE_UPLOAD_CONFIGS, ImageUtils } from '@utils';

import { setImage, uploadImage } from './store/actions/upload-images.actions';
import { uploadImagesQuery } from './store/selectors/upload-images.selectors';
import { ImageUploadResource, ImageValidationConfig, UploadImageState } from './types';

@Component({
  selector: 'admin-image-upload-input',
  templateUrl: './image-upload-input.component.html',
  styleUrls: ['./image-upload-input.component.scss']
})
export class ImageUploadInputComponent implements ControlValueAccessor, OnInit {
  static nextId = 0;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @Input() label: string;
  @Input() resource: ImageUploadResource;
  @Input() additionalParams: any;
  @Input() imageValidationConfig: ImageValidationConfig;
  @Input() missingValueErrorMessage: string;
  @Input() onlyShowErrorWhenTouched = false;

  destroyRef = inject(DestroyRef);

  allowedTypes: string[];
  allowedTypesHint: string;
  componentId = `${ImageUploadInputComponent.nextId++}`;
  dimensionDescription = '';
  fileSizeLimit: number;
  errorMessage: Nullable<string>;
  loading$: Observable<boolean>;
  imageUrl: string;
  currentUploadingId: Nullable<string>;
  instruction = 'Drag & drop file here or click to browse';
  imageErrorMessage = imageErrorMessage;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private store: Store<UploadImageState>
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostListener('focusout', ['$event'])
  onFocusout(): void {
    this.onTouched();
  }

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  /* eslint-enable */

  ngOnInit(): void {
    this.allowedTypes = this.imageValidationConfig.allowedTypes || IMAGE_UPLOAD_CONFIGS.allowedTypes;
    this.allowedTypesHint = [...new Set(this.allowedTypes.map(type => IMAGE_MEDIA_TYPE_TO_FILE_EXTENSION[type]))].join(
      ', '
    );
    this.loading$ = this.store.select(uploadImagesQuery.isSingleLoading);
    this.setDimensionDescription();
    this.setInitialValue();
    this.subscribeToValueChangeFromStore();

    this.fileSizeLimit = this.imageValidationConfig.fileSizeLimit || IMAGE_UPLOAD_CONFIGS.fileSizeLimit;
  }

  setInitialValue(): void {
    const initialValue = this.ngControl.value;
    if (initialValue) {
      this.store.dispatch(setImage({ imageUrl: initialValue, componentId: this.componentId }));
    }
  }

  subscribeToValueChangeFromStore(): void {
    this.store
      .select(uploadImagesQuery.getImageByComponentId(this.componentId))
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(value => !!value)
      )
      .subscribe(value => {
        this.imageUrl = value;
        this.onChange(value);
        this.currentUploadingId = null;
      });
  }

  onInputChange(event: any): void {
    this.onFileChange(event.target.files);
  }

  onFileChange(files: FileList): void {
    this.onTouched();
    this.currentUploadingId = this.componentId;
    const reader = new FileReader();

    if (files?.length) {
      const file = files[0];
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.errorMessage = null;

        if (this.isFileTypeInvalid(file) || this.isFileSizeInvalid(file)) {
          return;
        }

        // check dimensions
        const image = new Image();
        const objectUrl = URL.createObjectURL(file);
        // TODO: Verify the event `load` as using `addEventListener('load')` causes failed tests
        // eslint-disable-next-line unicorn/prefer-add-event-listener
        image.onload = () => {
          this.validateDimensions(image);
          URL.revokeObjectURL(objectUrl);

          if (!this.errorMessage) {
            this.uploadImage(file);
          }
        };

        image.src = objectUrl;
      };
    }
  }

  uploadImage(file: File): void {
    const formData = new FormData();
    formData.append('file', file);
    this.store.dispatch(
      uploadImage({
        resource: this.resource,
        payload: {
          file: formData,
          componentId: this.componentId,
          params: this.additionalParams
        }
      })
    );
  }

  isFileTypeInvalid(file: File): boolean {
    if (ImageUtils.isFileTypeInvalid(this.allowedTypes, file)) {
      this.errorMessage = imageErrorMessage.invalidImageFileType;
      this.currentUploadingId = null;
      return true;
    }

    return false;
  }

  isFileSizeInvalid(file: File): boolean {
    if (ImageUtils.isFileSizeInvalid(this.fileSizeLimit, file)) {
      this.errorMessage = imageErrorMessage.invalidImageFileSize;
      this.currentUploadingId = null;
      return true;
    }

    return false;
  }

  setDimensionDescription(): void {
    this.dimensionDescription = '';
    const { maxHeight, maxWidth } = this.imageValidationConfig;

    if (maxHeight && maxWidth) {
      this.dimensionDescription = `maximum ${maxWidth}px x ${maxHeight}px`;
    } else if (maxHeight) {
      this.dimensionDescription = `maximum height ${maxHeight}px`;
    } else if (maxWidth) {
      this.dimensionDescription = `maximum width ${maxWidth}px`;
    }

    this.dimensionDescription = this.dimensionDescription?.trim();
  }

  validateDimensions(image: any): void {
    if (ImageUtils.areDimensionsInvalid(image, this.imageValidationConfig)) {
      this.errorMessage = imageErrorMessage.invalidImageDimensions;
      this.currentUploadingId = null;
    }
  }

  /* interface implementations */
  onChange = (_value: string): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.imageUrl = value;
  }
}
