import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { GuardhouseService } from '@core/services/guardhouse/guardhouse.service';
import { TenantLink } from '@core/types';
import { HostTenantMappingUtils } from '@utils';

@Component({
  selector: 'admin-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  isRc = HostTenantMappingUtils.isRC();
  logoUrl = `/assets/${HostTenantMappingUtils.mapHostToTenantLogo()}`;

  constructor(
    private guardhouseService: GuardhouseService,
    private router: Router,
    @Inject('tenants') public tenants: TenantLink[]
  ) {}

  login(): void {
    const isAscendaRoute = this.router.isActive('/ascenda', true);
    const isAscendaLogin = isAscendaRoute || localStorage.getItem('isAscendaLogin') === 'true';

    this.guardhouseService.redirectToLoginUrl({ isAscendaLogin, isAscendaRoute });
  }
}
