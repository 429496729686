import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import {
  RecommendedSegmentsRawResult,
  RecommendedSegmentsResult,
  RecommendedCampaignsRawResult,
  RecommendedCampaignsResult
} from '../types';

@Injectable({
  providedIn: 'root'
})
export class RecommendationsService {
  constructor(private missionControlService: MissionControlService) {}

  getRecommendedSegments(): Observable<RecommendedSegmentsResult> {
    return this.missionControlService.get<RecommendedSegmentsRawResult>('recommendations/segments').pipe(
      map(({ recommendations, type }) => ({
        recommendations: recommendations.map(recommendation => ({
          ...recommendation,
          type
        }))
      }))
    );
  }

  getRecommendedCampaigns(): Observable<RecommendedCampaignsResult> {
    return this.missionControlService.get<RecommendedCampaignsRawResult>('recommendations/campaigns').pipe(
      map(({ recommendations, type }) => ({
        recommendations: recommendations.map(recommendation => ({
          ...recommendation,
          type
        }))
      }))
    );
  }

  getRecommendedCampaignsForSegment(segmentId: string): Observable<RecommendedCampaignsResult> {
    return this.missionControlService
      .get<RecommendedCampaignsRawResult>(`segments/${segmentId}/campaign_recommendations`)
      .pipe(
        map(({ recommendations, type }) => ({
          recommendations: recommendations.map(recommendation => ({
            ...recommendation,
            type
          }))
        }))
      );
  }

  hideRecommendation(id: string): Observable<void> {
    return this.missionControlService.post<void>(`recommendations/${id}/hide`);
  }

  unhideRecommendation(id: string): Observable<void> {
    return this.missionControlService.post<void>(`recommendations/${id}/unhide`);
  }
}
