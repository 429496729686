import {
  booleanAttribute,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { DateUtils, NullableFormControls, ObjectUtils } from '@utils';

import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS, MAT_DAYJS_DATE_FORMATS } from '../../../../adaptors';
import { DateTimeForm } from '../../types/date-time-form.type';

export const DEFAULT_ERRORS_MESSAGE: Record<string, string> = {
  required: 'This field is required',
  mustDefineTogether: 'Date and time should be defined together'
};

@Component({
  selector: 'admin-date-time-input',
  templateUrl: './date-time-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateTimeInputComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateTimeInputComponent,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS }
  ]
})
export class DateTimeInputComponent implements OnChanges, OnInit, ControlValueAccessor, Validators {
  @Input() dateLabel = 'Date';

  @Input() timeLabel = 'Time';

  @Input() errorMessages: Record<string, string> = {};

  @Input() minDate: Date | null = new Date();

  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  dateTimeForm: FormGroup<NullableFormControls<DateTimeForm>>;

  onTouched: () => void;

  onChange: (value: string) => void;

  onValidatorChange: () => void;

  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);

  constructor(@Inject('timezone') private timezone: string) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.disabled && this.dateTimeForm) {
      this.setDisabledState(simpleChanges.disabled.currentValue);
    }
  }

  ngOnInit(): void {
    this.setupForm();

    this.subscribeToFormChanges();
  }

  /**** Component methods ****/

  setupForm(): void {
    this.dateTimeForm = this.fb.group<NullableFormControls<DateTimeForm>>({
      date: this.fb.control(null, [Validators.required]),
      time: this.fb.control(null, [Validators.required])
    });

    if (this.disabled) {
      this.dateTimeForm.disable({ emitEvent: false });
    }
  }

  subscribeToFormChanges(): void {
    this.dateTimeForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ date, time }) => {
      const dateTimeAsISOFormat =
        date && time ? DateUtils.isoFormatAndOverrideTimezone(24, time, date.toString(), this.timezone) : null;

      this.onChange(dateTimeAsISOFormat!);
    });
  }

  getFormErrorsMessage(control: AbstractControl): string | null {
    if (!control.errors) {
      return null;
    }

    const errorMessages = {
      ...DEFAULT_ERRORS_MESSAGE,
      ...this.errorMessages
    };

    // Will prioritize mustDefineTogether error messages
    // over other error messages
    if (control.errors.mustDefineTogether) {
      return errorMessages.mustDefineTogether;
    }

    if (control.errors.matDatepickerMin) {
      return errorMessages.min;
    }

    return errorMessages[Object.keys(control.errors)[0]] ?? '';
  }

  /**** CVA Methods ****/

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(dateTime: string): void {
    let date: Date | null = null;
    let time: string | null = null;

    if (dateTime && typeof dateTime === 'string') {
      date = DateUtils.stripTimeFromDate(this.adaptDate(dateTime));
      time = this.adaptTime(dateTime);
    }

    this.dateTimeForm.patchValue(
      { date, time },
      {
        emitEvent: false
      }
    );
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateTimeForm.disable({
        emitEvent: false
      });
    } else {
      this.dateTimeForm.enable({
        emitEvent: false
      });
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(): ValidationErrors | null {
    if (
      ObjectUtils.isNonEmptyObject(this.dateTimeForm.controls.date.errors) ||
      ObjectUtils.isNonEmptyObject(this.dateTimeForm.controls.time.errors)
    ) {
      return { errors: this.getFormErrorsMessage(this.dateTimeForm) };
    }
    return null;
  }

  /**** Utils ****/

  private adaptDate(UTCDate: string): Date {
    return DateUtils.datepickerAdaptor('UTCToDatepicker', UTCDate, this.timezone)!;
  }

  private adaptTime(UTCDate: string): string {
    return DateUtils.getStringForNgxTimepicker(DateUtils.formatAsTimezone(UTCDate, this.timezone)!, false) || '';
  }
}
