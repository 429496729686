import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { HermesError } from '@kaligo/hermes';
import { Store } from '@ngrx/store';

import { logoutUser } from '@core/store/auth/actions/auth.actions';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';

@Component({
  selector: 'admin-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html'
})
export class SessionExpiredDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<SessionExpiredDialogComponent>,
    private store: Store<AuthState>,
    @Inject(MAT_DIALOG_DATA) public error: HermesError
  ) {
    dialogRef.disableClose = true;
  }

  get errorMessage(): string {
    return this.error?.message;
  }

  onOkClick(): void {
    this.store.dispatch(logoutUser());
  }
}
