import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { exhaustMap, filter, first } from 'rxjs/operators';

import { loadNydusNetworkBootstrap } from '@core/store/nydus-network/actions/nydus-network-bootstrap.actions';
import { nydusNetworkBootstrapQuery } from '@core/store/nydus-network/selectors/nydus-network-bootstrap.selectors';
import { Locale, NydusNetworkBootstrapState, Wrap } from '@core/types';

export const nydusNetworkBootstrapResolver: ResolveFn<Wrap<Locale[]>> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<NydusNetworkBootstrapState> = inject(Store<NydusNetworkBootstrapState>)
): Observable<Wrap<Locale[]>> =>
  store.pipe(
    select(nydusNetworkBootstrapQuery.isNydusNetworkBootstrapLoaded),
    first(),
    exhaustMap(isLoaded => {
      if (!isLoaded) {
        store.dispatch(loadNydusNetworkBootstrap());
      }

      return of({
        value: store
          .select(nydusNetworkBootstrapQuery.getNydusNetworkBootstrapLocales)
          .pipe(filter(locales => !!locales))
      });
    })
  );
