import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { INVALID_E164_MESSAGE } from '@utils';

import { phoneValidator } from '../../../../validators';
import { createMfaIdentity } from '../../store/actions/mfa-identities.actions';
import { CreateMfaIdentityParams, MfaIdentityState } from '../../types';

interface DialogData {
  userId: string;
}

@Component({
  selector: 'admin-create-mfa-identity-dialog',
  templateUrl: './create-mfa-identity-dialog.component.html',
  styleUrls: ['./create-mfa-identity-dialog.component.scss']
})
export class CreateMfaIdentityDialogComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  createForm: UntypedFormGroup;
  providerIdOptions = ['email', 'sms', 'fido', 'google_authenticator'];
  invalidE164Message = INVALID_E164_MESSAGE;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<MfaIdentityState>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CreateMfaIdentityDialogComponent>
  ) {}

  get uidControl(): AbstractControl {
    return this.createForm.get('uid');
  }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      providerId: ['', [Validators.required]],
      uid: ['', [Validators.required]]
    });

    this.subscribeToProviderIdChange();
  }

  subscribeToProviderIdChange(): void {
    this.createForm
      .get('providerId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.handleUidControl(value));
  }

  submitForm(): void {
    const params: CreateMfaIdentityParams = {
      ...this.createForm.value,
      userId: this.data.userId
    };

    this.store.dispatch(
      createMfaIdentity({
        params,
        dialogRefId: this.dialogRef.id
      })
    );
  }

  handleUidControl(value: string): void {
    this.uidControl.reset();

    this.uidControl.clearValidators();
    this.uidControl.addValidators(Validators.required);

    if (value === 'email') {
      this.uidControl.addValidators(Validators.email);
    } else if (value === 'sms') {
      this.uidControl.addValidators(phoneValidator());
    }
  }
}
