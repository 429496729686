<button
  class="m-0 flex h-6 items-center gap-1 rounded-md border-0 bg-white text-shakespeare-500"
  [style.backgroundColor]="'transparent'"
  cdkMenuItem
  [cdkMenuTriggerFor]="menu"
  [disabled]="(parentElementLoading$ | async) || localeOptions.length === 1"
  [ngClass]="{
    'cursor-pointer': !(parentElementLoading$ | async) && localeOptions.length > 1
  }"
>
  <mat-icon class="!h-[16px] !w-[16px]" svgIcon="globe-monocolor"></mat-icon>
  {{ localeMapping[selectedLocale] }}
  @if (localeOptions.length > 1) {
    <mat-icon class="!h-[16px] !w-[16px]" svgIcon="chevron-down-bold"></mat-icon>
  }
</button>

<ng-template #menu>
  <div cdkMenu>
    <div cdkMenuGroup class="menu grid w-52 gap-2 rounded-md bg-white p-2">
      @for (locale of localeOptions; track locale.value) {
        <button
          type="button"
          cdkMenuItemRadio
          class="h-11 cursor-pointer rounded-md border-0 px-4 text-left hover:bg-cloudburst-200"
          [ngClass]="{
            'bg-cloudburst-200': selectedLocale === locale.value,
            'bg-white': selectedLocale !== locale.value
          }"
          (click)="handleClick(locale.value)"
        >
          {{ localeMapping[locale.value] }}
        </button>
      }
    </div>
  </div>
</ng-template>
