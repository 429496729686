import { Inject, Injectable } from '@angular/core';

import { Tag } from '@shared/types';

import { TagDefs } from '../../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class TeUserTagDefs {
  readonly tagDefs: TagDefs<any> = {
    te_user_id: {
      getDisplayPrefix: () => 'User',
      getDisplayValue: (tag: Tag) => tag.id,
      getExternalRouteLink: (tags: Tag[]) => {
        const userTag = tags.find(tag => tag.type === 'te_user_id');
        return userTag ? `${this.travelEdgeAdminDomain}/kaligo_admin/user/${userTag.id}` : null;
      }
    }
  };

  constructor(@Inject('travelEdgeAdminDomain') private travelEdgeAdminDomain: string) {}
}
