import { ErrorHandler, inject, Injectable, InjectionToken } from '@angular/core';

export const ADMIN_ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ADMIN_ERROR_HANDLERS');

@Injectable()
export class AdminErrorHandler implements ErrorHandler {
  private errorHandlers = inject(ADMIN_ERROR_HANDLERS);

  handleError(error: any): void {
    this.errorHandlers.forEach(handler => handler.handleError(error));
  }
}
