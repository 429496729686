import { FormGroup, ValidatorFn } from '@angular/forms';

import { DateUtils } from '../utils/date-utils';
import { ObjectUtils } from '../utils/object-utils';

export function dateTimeRangeValidator(hasStarted: boolean, timezone: string): ValidatorFn {
  return (formGroup: FormGroup): { [key: string]: any } | null => {
    const { startDate, startTime, endDate, endTime } = formGroup.getRawValue();
    const nowTime = new Date();
    const validationErrors: any = {};
    const newStartTimeStr =
      startDate && startTime
        ? DateUtils.isoFormatAndOverrideTimezone(24, startTime, startDate.toString(), timezone)
        : null;
    const newEndTimeStr =
      endDate && endTime ? DateUtils.isoFormatAndOverrideTimezone(24, endTime, endDate.toString(), timezone) : null;

    if (newStartTimeStr) {
      // start time must be future date time
      if (new Date(newStartTimeStr).getTime() < new Date(nowTime).getTime() && !hasStarted) {
        validationErrors.invalidFutureStartTime = true;
      }
    } else if (startDate || startTime) {
      // start date and start time must be defined together
      validationErrors.invalidStartTime = true;
    }

    if (newEndTimeStr) {
      // end time must be future date time
      if (new Date(newEndTimeStr).getTime() < new Date(nowTime).getTime()) {
        validationErrors.invalidFutureEndTime = true;
      }

      // start time must before end time
      if (newStartTimeStr && new Date(newStartTimeStr).getTime() >= new Date(newEndTimeStr).getTime()) {
        validationErrors.invalidStartEndTime = true;
      }
    } else if (endDate || endTime) {
      // end date and end time must be defined together
      validationErrors.invalidEndTime = true;
    }

    return ObjectUtils.isEmptyObject(validationErrors) ? null : validationErrors;
  };
}
