import { Routes } from '@angular/router';

import { componentAuthGuard } from '@core/guards/auth.guard';
import { SCOPES_OR } from '@core/types';

import { auditLogsResolver } from '../audit-logs/resolvers/audit-logs.resolver';
import { AuditLogEntity } from '../audit-logs/types/audit-logs.type';
import { AuditLogsComponent } from '../audit-logs/views/audit-logs/audit-logs.component';

import { notesResolver } from './resolvers/notes.resolver';
import { NoteMenuComponent } from './views/note-menu/note-menu.component';
import { NotesComponent } from './views/notes/notes.component';

export const notesRoutes: Routes = [
  {
    path: '',
    component: NoteMenuComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'listing'
      },
      {
        path: 'listing',
        component: NotesComponent,
        canActivate: [componentAuthGuard],
        data: {
          scopes: SCOPES_OR.viewNotes,
          relationOperator: 'or',
          notesConfigs: {
            baseColumns: ['updatedAt', 'note', 'adminUserId', 'expiresAt', 'tags', 'entity'],
            isEntityDetailsView: false
          }
        },
        resolve: {
          notesFilter: notesResolver
        }
      },
      {
        path: 'logs',
        component: AuditLogsComponent,
        canActivate: [componentAuthGuard],
        data: {
          scopes: SCOPES_OR.viewAuditLogs,
          relationOperator: 'or',
          entity: 'notes',
          filterEntities: [AuditLogEntity.Notes]
        },
        resolve: {
          tags: auditLogsResolver
        }
      }
    ]
  }
];
