<div class="admin-container">
  <div class="header">
    <div class="title">Preview</div>
    <div *ngIf="displayToggle" class="toggle-button-wrapper">
      <div (click)="toggleDisplay('laptop')">
        <div class="laptop-button" [class.selected]="selectedDisplay === 'laptop'">
          <mat-icon svgIcon="laptop"></mat-icon>
        </div>
      </div>
      <div (click)="toggleDisplay('mobile')">
        <div class="mobile-button" [class.selected]="selectedDisplay === 'mobile'">
          <mat-icon svgIcon="phone-notch"></mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="image-wrapper" [class.mobile]="selectedDisplay === 'mobile'">
    <img draggable="false" [src]="selectedImage" />
  </div>
  <div class="zoom-button-wrapper" [class.activated]="isModalOpen" (click)="openDialog()">
    <mat-icon svgIcon="expand"></mat-icon>
  </div>
</div>
