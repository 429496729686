import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Enrollment, EnrollmentsFilter } from '../../types';

export const loadEnrollments = createAction(
  '[Enrollments] Load Enrollments',
  props<{ filter: EnrollmentsFilter; isTopLevelView: boolean }>()
);
export const loadEnrollmentsSuccess = createAction(
  '[Enrollments] Load Enrollments Success',
  props<{ enrollments: Enrollment[] }>()
);
export const loadEnrollmentsFailure = createAction('[Enrollments] Load Enrollments Failure', props<ErrorState>());

export const loadEnrollmentsAndUsers = createAction(
  '[Enrollments] Load Enrollments and Users',
  props<{ filter: EnrollmentsFilter; isTopLevelView: boolean }>()
);

export const setEnrollmentsFilter = createAction(
  '[Enrollments] Set Enrollments Filter',
  props<{ filter: EnrollmentsFilter }>()
);
