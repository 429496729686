import { UserLoginMode, UserStatus } from './users.type';

export class UsersFilter {
  limit?: number;
  page?: number;
  ids?: string[];
  skipAuditLog?: boolean;
  id?: string;
  firstName?: string;
  lastName?: string;
  identityUid?: string;
  partnerUid?: string;
  status?: UserStatus;
  loginMode?: UserLoginMode;
  activated?: boolean;
  email?: string;
  phoneNumber?: string;
  providerId?: string[];
  roleId?: string;
  createdAt?: {
    from: string;
    to: string;
  };
  updatedAt?: {
    from: string;
    to: string;
  };
  sortBy?: string;
  sortDirection?: string;
  productId?: string;
  accountId?: string;
  isTeamMembersView?: boolean;

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}
