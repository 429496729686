import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { routerNavigate } from '@core/store/router/actions/router.actions';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';

import { McTenantsService } from '../../services/mc-tenants.service';
import {
  clearMcTenantBootstrapCache,
  clearMcTenantBootstrapCacheFailure,
  clearMcTenantBootstrapCacheSuccess,
  createMcTenant,
  createMcTenantFailure,
  createMcTenantSuccess,
  loadMcTenant,
  loadMcTenantFailure,
  loadMcTenants,
  loadMcTenantsFailure,
  loadMcTenantsSuccess,
  loadMcTenantSuccess,
  updateMcTenant,
  updateMcTenantFailure,
  updateMcTenantSuccess
} from '../actions/mc-tenants.actions';

@Injectable()
export class McTenantsEffects {
  loadMcTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMcTenants),
      exhaustMap(() =>
        this.mcTenantsService.getMcTenants().pipe(
          map(response => loadMcTenantsSuccess({ response })),
          catchError(error => of(loadMcTenantsFailure({ error })))
        )
      )
    )
  );

  loadMcTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMcTenant),
      exhaustMap(({ id }) =>
        this.mcTenantsService.getMcTenant(id).pipe(
          map(response => loadMcTenantSuccess({ response })),
          catchError(error => of(loadMcTenantFailure({ error })))
        )
      )
    )
  );

  createMcTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMcTenant),
      exhaustMap(({ mcTenant }) =>
        this.mcTenantsService.createMcTenant(mcTenant).pipe(
          map(response => createMcTenantSuccess({ mcTenant: response.tenant })),
          catchError(error => of(createMcTenantFailure({ error })))
        )
      )
    )
  );

  updateMcTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMcTenant),
      exhaustMap(({ mcTenant }) =>
        this.mcTenantsService.updateMcTenant(mcTenant).pipe(
          map(response => updateMcTenantSuccess({ mcTenant: response.tenant })),
          catchError(error => of(updateMcTenantFailure({ error })))
        )
      )
    )
  );

  createMcTenantSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMcTenantSuccess),
      map(({ mcTenant }) => routerNavigate({ path: `/mc-tenants/${mcTenant.id}/details` }))
    )
  );

  updateMcTenantSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMcTenantSuccess),
        switchMap(({ mcTenant }) =>
          this.dialog
            .open(ConfirmDialogComponent, {
              disableClose: true,
              data: {
                dialogTitle: 'Successfully updated',
                confirmText: `<p>To apply new changes, do you want to reload the page?</p><p>Note: No will redirect back to the details page.</p>`,
                confirmButtonText: 'Reload page'
              }
            })
            .afterClosed()
            .pipe(
              tap(confirmed => {
                if (confirmed) {
                  window.location.reload();
                } else {
                  this.store.dispatch(routerNavigate({ path: `/mc-tenants/${mcTenant.id}/details` }));
                }
              })
            )
        )
      ),
    {
      dispatch: false
    }
  );

  clearMcTenantBootstrapCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearMcTenantBootstrapCache),
      exhaustMap(() =>
        this.mcTenantsService.clearMcTenantBootstrapCache().pipe(
          map(() => clearMcTenantBootstrapCacheSuccess()),
          catchError(error => of(clearMcTenantBootstrapCacheFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private mcTenantsService: McTenantsService,
    private dialog: MatDialog
  ) {}
}
