import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { NotesFilter } from '../../types/notes-filter.type';
import { NotesResult } from '../../types/notes-result.type';
import { Note } from '../../types/notes.type';

export const loadNotes = createAction('[Notes] Load Notes', props<{ filter: NotesFilter; isTopLevelView?: boolean }>());
export const loadNotesSuccess = createAction('[Notes] Load Notes Success', props<{ notesResult: NotesResult }>());
export const loadNotesFailure = createAction('[Notes] Load Notes Failure', props<ErrorState>());

export const createNote = createAction('[Notes] Create Note', props<{ note: Note }>());
export const createNoteSuccess = createAction('[Notes] Create Note Success', props<{ note: Note }>());
export const createNoteFailure = createAction('[Notes] Create Note Failure', props<ErrorState>());

export const updateNote = createAction('[Notes] Update Note', props<{ note: Note; dialogRefId: string }>());
export const updateNoteSuccess = createAction(
  '[Notes] Update Note Success',
  props<{ note: Note; dialogRefId: string }>()
);
export const updateNoteFailure = createAction('[Notes] Update Note Failure', props<ErrorState>());

export const deleteNote = createAction('[Notes] Delete Note', props<{ id: string; entity: string }>());
export const deleteNoteSuccess = createAction('[Notes] Delete Note Success', props<{ id: string }>());
export const deleteNoteFailure = createAction('[Notes] Delete Note Failure', props<ErrorState>());
