import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { PopularDestination, TravelConfig, TravelConfigUpdateParams, TravelType } from '../../types';

export const loadTravelConfig = createAction(
  '[Rewards Offering] Load Travel Config',
  props<{ travelType: TravelType }>()
);
export const loadTravelConfigSuccess = createAction(
  '[Rewards Offering] Load Travel Config Success',
  props<{ travelType: TravelType; travelConfig: TravelConfig }>()
);
export const loadTravelConfigFailure = createAction(
  '[Rewards Offering] Load Travel Config Failure',
  props<ErrorState>()
);

export const loadPopularDestinations = createAction('[Rewards Offering] Load Popular Destinations');
export const loadPopularDestinationsSuccess = createAction(
  '[Rewards Offering] Load Popular Destinations Success',
  props<{ popularDestinations: PopularDestination[] }>()
);
export const loadPopularDestinationsFailure = createAction(
  '[Rewards Offering] Load Popular Destinations Failure',
  props<ErrorState>()
);

export const updateTravelConfig = createAction(
  '[Rewards Offering] Update Travel Config',
  props<{ travelType: TravelType; travelConfig: TravelConfigUpdateParams }>()
);
export const updateTravelConfigSuccess = createAction(
  '[Rewards Offering] Update Travel Config Success',
  (
    travelType: TravelType,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully'
    }
  ) => ({ travelType, snackbarMessages })
);
export const updateTravelConfigFailure = createAction(
  '[Rewards Offering] Update Travel Config Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your changes could not be saved due to technical issues on our end. Please try saving your changes again.' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const updatePopularDestinations = createAction(
  '[Rewards Offering] Update Popular Destinations',
  props<{ popularDestinations: string[] }>()
);
export const updatePopularDestinationsSuccess = createAction(
  '[Rewards Offering] Update Popular Destinations Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully'
    }
  ) => ({ snackbarMessages })
);
export const updatePopularDestinationsFailure = createAction(
  '[Rewards Offering] Update Popular Destinations Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your changes could not be saved due to technical issues on our end. Please try saving your changes again.' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);
