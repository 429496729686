import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { FraudEvaluationsService } from '../../services/fraud-evaluations.service';
import {
  loadFraudEvaluations,
  loadFraudEvaluationsFailure,
  loadFraudEvaluationsSuccess
} from '../actions/fraud-evaluations.actions';

@Injectable()
export class FraudEvaluationsEffects {
  loadFraudEvaluations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFraudEvaluations),
      exhaustMap(action =>
        this.fraudEvaluationsService.getFraudEvaluations(action.filter).pipe(
          map(result => loadFraudEvaluationsSuccess({ result })),
          catchError(error => of(loadFraudEvaluationsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private fraudEvaluationsService: FraudEvaluationsService
  ) {}
}
