import { ObjectUtils } from '@utils';

import { DisplaySettings, UsersPagePolicySettings } from '../types';

import { UserStateAttributes, userStatesMapping, UserStatesType } from './../types/users.type';

export class UsersUtils {
  static convertStateToAttributes(state: UserStatesType): UserStateAttributes {
    return userStatesMapping[state] || ({} as UserStateAttributes);
  }

  static convertAttributesToState(attributes: UserStateAttributes): UserStatesType {
    const { status, loginMode, activated } = attributes;

    return Object.keys(userStatesMapping).find(key => {
      const { status: stateStatus, loginMode: stateLoginMode, activated: stateActivated } = userStatesMapping[key];

      return status === stateStatus && loginMode === stateLoginMode && activated === stateActivated;
    }) as UserStatesType;
  }

  static mergeSettings(
    frontendSettings: UsersPagePolicySettings | null,
    responseSettings: UsersPagePolicySettings | null,
    defaultSettings: DisplaySettings
  ): DisplaySettings {
    frontendSettings = frontendSettings ?? {};
    responseSettings = responseSettings ? this.coerceResponseSettings(responseSettings) : {};

    return this.mergeCoercedSettings(frontendSettings, responseSettings, defaultSettings);
  }

  // Force settings to be all boolean values for easier merging
  static coerceResponseSettings(settings: UsersPagePolicySettings): UsersPagePolicySettings {
    return {
      createdAt: !!settings.createdAt,
      customerId: !!settings.customerId,
      email: !!settings.email,
      id: !!settings.id,
      identities: { uid: !!settings.identities?.uid },
      name: !!(settings.name || settings.firstName || settings.lastName),
      pan: !!settings.pan,
      phoneNumber: !!settings.phoneNumber,
      pointsAccount: !!settings.pointsAccount,
      roles: !!settings.roles,
      state: !!settings.state,
      status: !!settings.status,
      loginMode: !!settings.loginMode,
      activated: !!settings.activated,
      updatedAt: !!settings.updatedAt
    };
  }

  static mergeCoercedSettings(
    frontendSettings: UsersPagePolicySettings,
    responseSettings: UsersPagePolicySettings,
    defaultSettings: DisplaySettings
  ): DisplaySettings {
    const merge = this.mergeValues;

    return {
      actions: defaultSettings.actions,
      createdAt: merge(frontendSettings.createdAt, responseSettings.createdAt, defaultSettings.createdAt),
      customerId: merge(frontendSettings.identities?.uid, responseSettings.identities?.uid, defaultSettings.customerId),
      email: merge(frontendSettings.email, responseSettings.email, defaultSettings.email),
      id: merge(frontendSettings.id, responseSettings.id, defaultSettings.id),
      identityId: merge(frontendSettings.identities?.uid, responseSettings.identities?.uid, defaultSettings.identityId),
      name: merge(frontendSettings.name, responseSettings.name, defaultSettings.name),
      pan: merge(frontendSettings.pan, responseSettings.pan, defaultSettings.pan),
      phoneNumber: merge(frontendSettings.phoneNumber, responseSettings.phoneNumber, defaultSettings.phoneNumber),
      pointsAccountColumn: merge(
        frontendSettings.pointsAccount,
        responseSettings.pointsAccount,
        defaultSettings.pointsAccountColumn,
        { requireDefault: true }
      ),
      pointsAccountFilter: merge(
        frontendSettings.pointsAccount,
        responseSettings.pointsAccount,
        defaultSettings.pointsAccountFilter
      ),
      roles: merge(frontendSettings.roles, responseSettings.roles, defaultSettings.roles, { requireDefault: true }),
      state: merge(frontendSettings.state, responseSettings.state, defaultSettings.state, { requireDefault: true }),
      status: merge(frontendSettings.status, responseSettings.status, defaultSettings.status),
      loginMode: merge(frontendSettings.loginMode, responseSettings.loginMode, defaultSettings.loginMode),
      activated: merge(frontendSettings.activated, responseSettings.activated, defaultSettings.activated),
      updatedAt: merge(frontendSettings.updatedAt, responseSettings.updatedAt, defaultSettings.updatedAt)
    };
  }

  static mergeValues(
    frontendValue: boolean | null,
    responseValue: boolean | null,
    defaultValue: boolean,
    options: { requireDefault: boolean } = { requireDefault: false }
  ): boolean {
    const frontendValueExists = ObjectUtils.isBoolean(frontendValue);
    const responseValueExists = ObjectUtils.isBoolean(responseValue);

    let result = false;

    if (!frontendValueExists && !responseValueExists) {
      result = defaultValue;
    }

    if (frontendValueExists && responseValueExists) {
      result = frontendValue && responseValue;
    }

    if (frontendValueExists && !responseValueExists) {
      result = frontendValue;
    }

    if (!frontendValueExists && responseValueExists) {
      result = responseValue;
    }

    if (options.requireDefault) {
      result = result && defaultValue;
    }

    return result;
  }
}
