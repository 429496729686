import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { PointsActivity } from '../../../dashboard/types';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class PointsActivityTagDefs {
  readonly tagDefs: TagDefs<PointsActivity> = {
    points_activity_id: {
      getDisplayPrefix: () => 'Points Activity',
      getDisplayValue: (tag: Tag) => tag.id,
      getRouteLink: (tags: Tag[]) => {
        if (!this.scopes.hasAny(SCOPES_OR.showPointsActivities)) {
          return null;
        }

        // handling the case when user manually enters tag details
        const userTag = tags.find(tag => tag.type === 'user_id');
        if (!userTag) {
          return null;
        }

        const pointsActivityTag = tags.find(tag => tag.type === 'points_activity_id');
        return pointsActivityTag
          ? `/customers/${userTag.id}/dashboard/points-activities/${pointsActivityTag.id}`
          : null;
      }
    }
  };

  constructor(private scopes: Scopes) {}
}
