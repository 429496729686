import { Component, Input, OnInit } from '@angular/core';

import { SCOPES_OR } from '@core/types';
import { ObjectUtils } from '@utils';

import { BenefitOrderItemData, OrderItem } from '../../types';

@Component({
  selector: 'admin-benefit-order-item-v2',
  templateUrl: './benefit-order-item-v2.component.html'
})
export class BenefitOrderItemV2Component implements OnInit {
  @Input() customerId: string;
  @Input() detailsView: string;
  @Input() orderItem: OrderItem;

  data: BenefitOrderItemData;

  readonly ObjectUtils = ObjectUtils;
  readonly SCOPES = SCOPES_OR;

  ngOnInit(): void {
    this.data = this.orderItem.data as BenefitOrderItemData;
  }
}
