import { Injectable, inject } from '@angular/core';

import { FEATURE_AVAILABILITY } from '@shared/tokens/feature-availability.token';
import { AvailabilityStatus } from '@shared/types';

@Injectable({
  providedIn: 'root'
})
export class FeatureAvailabilityService {
  private featureAvailability = inject(FEATURE_AVAILABILITY);

  getFeatureAvailabilityStatus(feature: string): AvailabilityStatus {
    return this.featureAvailability[feature] ?? AvailabilityStatus.HIDDEN;
  }

  isFeatureAvailable(feature: string): boolean {
    return this.getFeatureAvailabilityStatus(feature) === AvailabilityStatus.AVAILABLE;
  }

  isFeatureUpsell(feature: string): boolean {
    return this.getFeatureAvailabilityStatus(feature) === AvailabilityStatus.UPSELL;
  }

  isFeatureHidden(feature: string): boolean {
    return this.getFeatureAvailabilityStatus(feature) === AvailabilityStatus.HIDDEN;
  }

  /**
   * Checks if features are allowed based on its availability status.
   * If one of the features is allowed, the method returns true.
   * @param features - The name of the features to check.
   * @param allowedStatues - An array of availability statuses that are considered allowed. Defaults to [AvailabilityStatus.AVAILABLE, AvailabilityStatus.UPSELL].
   * @returns A boolean indicating whether the feature is allowed or not.
   */
  isFeatureAllowed(
    features: string | string[],
    allowedStatues: AvailabilityStatus[] = [AvailabilityStatus.AVAILABLE, AvailabilityStatus.UPSELL]
  ): boolean {
    const featuresToCheck = Array.isArray(features) ? features : [features];

    return featuresToCheck.some(feature => {
      const status = this.getFeatureAvailabilityStatus(feature);

      return allowedStatues.includes(status);
    });
  }
}
