/* eslint-disable max-len */
export const TEAM_MEMBER_ROLE_DESCRIPTION = {
  admin:
    'This role is for anyone who needs the same access as the master admin - they can see and manage other business users and have all access privileges.',
  agent_lead:
    'This role is the same as the Agent role, but with additional administrative permission to edit user profiles.',
  agent:
    'This role is for members who need to manage queries from customers. This role can view profiles and order information, perform points adjustments to user balances, and raise escalated support tickets to Ascenda for further assistance.',
  business_user:
    "This role is for members who manage the overall setup and operations of the rewards program.  This role will be given access to manage the overall website styling, rewards program settings, and the program's core earn rules."
};
/* eslint-enable max-len */
