import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { tooltipsQuery } from '../../../tooltips/store/selectors/tooltips.selectors';
import { TooltipState } from '../../../tooltips/types/tooltips.type';
import {
  blockUser,
  blockUserLogin,
  logoutUser,
  unblockUser,
  unblockUserLogin,
  unenrollUser
} from '../../store/actions/users.actions';
import { usersQuery } from '../../store/selectors/users.selectors';
import { User, UserState } from '../../types';

@Component({
  selector: 'admin-advanced-actions-dialog',
  templateUrl: './advanced-actions-dialog.component.html',
  styleUrls: ['./advanced-actions-dialog.component.scss']
})
export class AdvancedActionsDialogComponent implements OnInit {
  blockUserDescription$: Observable<string>;
  blockUserLoginDescription$: Observable<string>;
  unenrollUserDescription$: Observable<string>;
  loading$: Observable<boolean>;
  selectedAction = '';

  hasBlockUnblockScopes: boolean;
  hasBlockUnblockLoginScopes: boolean;
  readonly SCOPES = SCOPES_OR;

  blockUnblockUsersScopes = ['users:block', 'users:unblock'];
  blockUnblockLoginUsersScopes = ['users:block_login', 'users:unblock_login'];

  constructor(
    private scopes: Scopes,
    private store: Store<UserState>,
    private tooltipStore: Store<TooltipState>,
    public dialogRef: MatDialogRef<AdvancedActionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {}

  ngOnInit(): void {
    this.hasBlockUnblockScopes = this.scopes.hasScopes(
      [this.blockUnblockUsersScopes, ['manage_user_state']],
      'any-strict-groups'
    );
    this.hasBlockUnblockLoginScopes = this.scopes.hasScopes(
      [this.blockUnblockLoginUsersScopes, ['manage_user_state']],
      'any-strict-groups'
    );

    this.blockUserDescription$ = this.setDescription(
      'users.details.button.blockUser?userIsBlocked=' + (this.user.status === 'blocked')
    );
    this.blockUserLoginDescription$ = this.setDescription(
      'users.details.button.blockUserLogin?userLogin=' + (this.user.loginMode === 'normal')
    );
    this.unenrollUserDescription$ = this.setDescription(
      'users.details.button.unenrollUser?enabled=' + this.user.activated
    );

    this.loading$ = this.store.select(usersQuery.isSingleLoading);
  }

  onAccountActions(): void {
    let action: Action;
    const dialogRefId = this.dialogRef.id;

    switch (this.selectedAction) {
      case 'blockLogin': {
        action = blockUserLogin({ id: this.user.id, dialogRefId });
        break;
      }
      case 'unblockLogin': {
        action = unblockUserLogin({ id: this.user.id, dialogRefId });
        break;
      }
      case 'block': {
        action = blockUser({ id: this.user.id, dialogRefId });
        break;
      }
      case 'unblock': {
        action = unblockUser({ id: this.user.id, dialogRefId });
        break;
      }
      case 'logout': {
        action = logoutUser({ id: this.user.id, dialogRefId });
        break;
      }
      case 'unenroll': {
        action = unenrollUser({ id: this.user.id, dialogRefId });
        break;
      }
      default: {
        return;
      }
    }

    this.store.dispatch(action);
  }

  private setDescription(key: string): Observable<string> {
    return this.tooltipStore.select(tooltipsQuery.getTooltipValueByKey(key));
  }
}
