import { tap } from 'rxjs/operators';

import { AfterViewInit, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AttributeUtils } from '@core/services/attribute-utils/attribute-utils.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AttributeDef, BooleanPolicySettings, SCOPES_OR } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { AccessPolicyUtils, DomUtils, StickyColumnConfig, StickyTableUtils } from '@utils';

import { verifyIdentity } from '../../store/actions/identities.actions';
import { deleteMfaIdentity } from '../../store/actions/mfa-identities.actions';
import { mfaIdentitiesQuery } from '../../store/selectors/mfa-identities.selectors';
import { usersQuery } from '../../store/selectors/users.selectors';
import { IdentityState, MfaIdentity, MfaIdentityColumn, MfaIdentityState, User, UserState } from '../../types';
import { CreateMfaIdentityDialogV2Component } from '../create-mfa-identity-dialog-v2/create-mfa-identity-dialog-v2.component';
import { EditMfaIdentityDialogV2Component } from '../edit-mfa-identity-dialog-v2/edit-mfa-identity-dialog-v2.component';

interface MfaActionsParams {
  actionType: 'sendVerification' | 'deleteMfaIdentity';
  mfaIdentity: MfaIdentity;
}

@Component({
  selector: 'admin-mfa-identities-v2',
  templateUrl: './mfa-identities-v2.component.html',
  styleUrls: ['../../views/user/user.component.scss']
})
export class MfaIdentitiesV2Component implements OnInit, AfterViewInit {
  @Input() user: User;

  destroyRef = inject(DestroyRef);

  userMfaIdentity$: Observable<MfaIdentity[]>;
  displayedColumns: string[] = [];
  columnConfig: Record<string, StickyColumnConfig> = {};
  tableUtil: StickyTableUtils;

  readonly SCOPES = SCOPES_OR;
  readonly manageMfaScopes = [
    'identities:verify',
    'mfa_identities:update',
    'mfa_identities:destroy',
    'manage_identities'
  ];

  private readonly prefixColumnDefs: AttributeDef<MfaIdentityColumn>[] = [{ key: 'tenantId' }];

  private readonly scrollableColumnDefs: AttributeDef<MfaIdentityColumn>[] = [
    { key: 'uid' },
    { key: 'providerId' },
    { key: 'verified' }
  ];

  private readonly suffixColumnDefs: AttributeDef<MfaIdentityColumn>[] = [{ key: 'actions' }];

  constructor(
    public attributeUtils: AttributeUtils,
    private accessPolicies: AccessPolicies,
    private matDialog: MatDialog,
    private scopes: Scopes,
    private store: Store<UserState>,
    private identityStore: Store<IdentityState>,
    private mfaIdentityStore: Store<MfaIdentityState>
  ) {}

  ngOnInit(): void {
    this.mfaIdentityStore
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        select(mfaIdentitiesQuery.isMfaIdentitiesLoaded(this.user.id)),
        tap(isLoaded => {
          this.userMfaIdentity$ = isLoaded
            ? this.identityStore.select(mfaIdentitiesQuery.getMfaIdentitiesList)
            : this.store.select(usersQuery.getUserPropertyById<MfaIdentity[]>(this.user.id, ['mfaIdentities']));
        })
      )
      .subscribe();

    this.handleColumnDisplay();
  }

  ngAfterViewInit(): void {
    DomUtils.preventScrollToOtherPage('.mfa-identities-table', takeUntilDestroyed(this.destroyRef));
    DomUtils.dragAndScroll('.mfa-identities-table', takeUntilDestroyed(this.destroyRef));
  }

  handleColumnDisplay(): void {
    const settings = this.accessPolicies.getResponseSettings('users', 'show');
    const usersShowMfaIdentitySettings = settings?.mfaIdentities as BooleanPolicySettings;
    const mfaIdentitiesIndexSettings = this.accessPolicies.getResponseSettings(
      'mfa_identities',
      'index'
    ) as BooleanPolicySettings;
    const accessPoliciesCheck = AccessPolicyUtils.mergeBooleanPolicySettings(
      mfaIdentitiesIndexSettings,
      usersShowMfaIdentitySettings
    );

    const [[], prefixColumns] = this.attributeUtils.filterColumns(this.prefixColumnDefs, accessPoliciesCheck);
    const [[], scrollableColumns] = this.attributeUtils.filterColumns(this.scrollableColumnDefs, accessPoliciesCheck);
    let [[], suffixColumns] = this.attributeUtils.filterColumns(this.suffixColumnDefs, accessPoliciesCheck);
    suffixColumns = this.scopes.hasAny(this.manageMfaScopes) ? suffixColumns : [];

    this.tableUtil = new StickyTableUtils(prefixColumns, scrollableColumns, suffixColumns);
    this.displayedColumns = this.tableUtil.getDisplayedColumns();
    this.columnConfig = this.tableUtil.getStickyColumnConfig();
  }

  openEditMfaIdentityDialog(mfaIdentity: MfaIdentity): void {
    this.matDialog.open(EditMfaIdentityDialogV2Component, {
      data: { mfaIdentity, userId: this.user.id }
    });
  }

  openCreateMfaIdentityDialog(): void {
    this.matDialog.open(CreateMfaIdentityDialogV2Component, {
      data: { userId: this.user.id }
    });
  }

  onMfaActions(params: MfaActionsParams): void {
    const { actionType, mfaIdentity } = params;
    const id = mfaIdentity.id;
    const agentCreated = mfaIdentity.customData.agentCreated ?? false;

    let dialogTitle: string;
    let confirmText: string;
    let confirmButtonText: string;
    let action: Action;

    switch (actionType) {
      case 'sendVerification': {
        dialogTitle = 'Send account verification';
        confirmText = 'Are you sure you want to send verification for this identity?';
        confirmButtonText = 'Yes, send verification';
        action = verifyIdentity({ mfaIdentityId: id });
        break;
      }
      case 'deleteMfaIdentity': {
        dialogTitle = 'Delete MFA Identity';
        confirmText = 'Are you sure you want to delete MFA identity?';
        confirmButtonText = 'Yes, delete MFA identity';
        action = deleteMfaIdentity({
          params: { id, agentCreated }, // pass agentCreated to MC for request validation
          user: this.user
        });
        break;
      }
      default: {
        return;
      }
    }

    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          dialogTitle,
          confirmText,
          confirmButtonText,
          styleClassName: 'confirm-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(action);
        }
      });
  }
}
