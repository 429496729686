import { FormGroup } from '@angular/forms';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { TagVariant } from '@shared/components/tag/tag.component';

import { EarnRuleConfigCreateForm } from './earn-rule-forms.type';
import { EarnRulesFilter } from './earn-rules-filter.type';

interface Tier {
  tierId: string;
  pointsPerDenomination: number;
  denomination: number;
  cumulativeMinSpend?: number;
  cumulativeMaxSpend?: number;
  amountRounding?: PostPointsSplitRounding;
  pointsRounding?: PostPointsSplitRounding;
  multiplesOfDenominationRounding?: { type: string };
}

export interface ComputeTypeInterface extends Record<string, string> {
  PerTransaction?: 'per_transaction';
  PerEvent?: 'per_transaction';
  PerSpendAmount: 'spend_amount';
}

export enum TransactionComputeType {
  PerTransaction = 'per_transaction',
  PerSpendAmount = 'spend_amount'
}
export enum EventComputeType {
  PerEvent = 'per_transaction',
  PerSpendAmount = 'spend_amount'
}

export type ComputeType = TransactionComputeType | EventComputeType;

export enum DEFAULT_EXPIRY_MAPPING {
  evergreen = 'No expiry',
  three_months_end_of_month = '3 months',
  six_months_end_of_month = '6 months',
  twelve_months_end_of_month = '12 months',
  twenty_four_months_end_of_month = '24 months',
  thirty_six_months_end_of_month = '36 months'
}

export interface Compute {
  type: ComputeType;
  pointsPerTransaction?: number;
  tiers?: Tier[];
}

interface PostPointsSplitRounding {
  type: string;
  params: {
    decimalPlaces: number;
  };
}

export enum RuleType {
  Transaction = 'transaction',
  Event = 'event'
}

export enum TempRuleType {
  Transaction = 'transaction'
}

export type EarnRuleTranslation = { points_activity_descriptor: string };
export type EarnRuleTranslations = Record<string, EarnRuleTranslation>;

export interface EarnRule {
  groupId?: string;
  description: string;
  category: string;
  effectiveStart: string;
  compute: Compute;
  expiryRule: string;
  tenantId?: string;
  status?: string;
  firstCreated?: string;
  lastUpdated?: string;
  trackingCycle?: string;
  pointsCreditingDate?: string;
  postPointsSplitRounding?: PostPointsSplitRounding;
  eligibleForTrackingFilters?: string;
  eligibleForPointsFilters?: string;
  remark?: string;
  productCodes?: string[];
  trackingType?: string;
  creditTimeframe?: string;
  eligibleForTrackingSince?: string;
  effectiveEnd?: string;
  priorityNumber?: string;
  transactionFilters?: string;
  ruleType: RuleType;
  eventType?: string; // only exists on EventEarnRule
  eventTypeIdentifier?: string; // only exists on EventEarnRule
  pointsActivityDescriptor?: string;
  translations?: EarnRuleTranslations;
}

export type EarnRuleConfigurationFormValue = Omit<
  ReturnType<FormGroup<EarnRuleConfigCreateForm>['getRawValue']>,
  'type' | 'eventType'
>;

export const earnRuleStatusTagVariant: Record<string, TagVariant> = {
  active: 'success',
  scheduled: 'info',
  inactive: 'neutral'
};

export const earnRuleEffectiveStartErrorMessages = {
  mustDefineTogether: 'Start date and time must be defined together'
};

export const earnRuleEffectiveEndErrorMessages = {
  mustDefineTogether: 'End date and time must be defined together',
  mustBeFutureDate: 'The date has already passed',
  mustBeAfterStartDate: 'Start date must be before end date'
};

export const earnRuleAdapter = createEntityAdapter<EarnRule>({
  selectId: (earnRule: EarnRule): string => earnRule.groupId
});

export interface EarnRuleState extends EntityState<EarnRule>, CallState {
  filter: EarnRulesFilter;
  version: number;
}

export const initialState: EarnRuleState = {
  ...earnRuleAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  version: 0
};
