import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { EarnRulesService } from '../../services/earn-rules.service';
import { EarnRuleState } from '../../types';
import {
  createEarnRule,
  createEarnRuleFailure,
  createEarnRuleSuccess,
  deactivateEarnRule,
  deactivateEarnRuleFailure,
  deactivateEarnRuleSuccess,
  loadEarnRule,
  loadEarnRuleFailure,
  loadEarnRules,
  loadEarnRulesFailure,
  loadEarnRulesSuccess,
  loadEarnRuleSuccess,
  updateEarnRule,
  updateEarnRuleFailure,
  updateEarnRulesPriorities,
  updateEarnRulesPrioritiesFailure,
  updateEarnRulesPrioritiesSuccess,
  updateEarnRuleSuccess
} from '../actions/earn-rules.actions';
import { earnRulesQuery } from '../selectors/earn-rules.selectors';

@Injectable()
export class EarnRulesEffects {
  loadEarnRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEarnRules),
      exhaustMap(() =>
        this.earnRulesService.getEarnRules().pipe(
          map(result => loadEarnRulesSuccess({ result })),
          catchError(error => of(loadEarnRulesFailure({ error })))
        )
      )
    )
  );

  loadEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEarnRule),
      exhaustMap(action =>
        this.earnRulesService.getEarnRule(action.groupId).pipe(
          map(result => loadEarnRuleSuccess({ result })),
          catchError(error => of(loadEarnRuleFailure({ error })))
        )
      )
    )
  );

  createEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEarnRule),
      exhaustMap(action =>
        this.earnRulesService.createEarnRule(action.earnRule).pipe(
          map(earnRule => createEarnRuleSuccess(earnRule)),
          catchError(error => of(createEarnRuleFailure(error)))
        )
      )
    )
  );

  updateEarnRulesPriorities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEarnRulesPriorities),
      withLatestFrom(this.store.select(earnRulesQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.earnRulesService.updateEarnRulesPriorities({ stacks: { 1: action.groupIds }, version }).pipe(
          map(result => updateEarnRulesPrioritiesSuccess(result)),
          catchError(error => of(updateEarnRulesPrioritiesFailure(error)))
        )
      )
    )
  );

  updateEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEarnRule),
      withLatestFrom(this.store.select(earnRulesQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.earnRulesService.updateEarnRule(action.earnRule, version).pipe(
          map(result => updateEarnRuleSuccess(result)),
          catchError(error => of(updateEarnRuleFailure(error)))
        )
      )
    )
  );

  deactivateEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deactivateEarnRule),
      withLatestFrom(this.store.select(earnRulesQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.earnRulesService.deactivateEarnRule(action.groupId, version).pipe(
          map(result => deactivateEarnRuleSuccess(result)),
          catchError(error => of(deactivateEarnRuleFailure(error)))
        )
      )
    )
  );

  createUpdateDeactivateEarnRuleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEarnRuleSuccess, updateEarnRuleSuccess, deactivateEarnRuleSuccess),
      exhaustMap(() => {
        return [loadEarnRules(), routerForceNavigate({ path: 'earn-rules/transaction' })];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private earnRulesService: EarnRulesService,
    private store: Store<EarnRuleState>
  ) {}
}
