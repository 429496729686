<div [formGroup]="formGroup">
  <mat-form-field
    class="no-wrap-chip-input field"
    [ngClass]="errorMessage ? 'ng-invalid mat-form-field-invalid' : ''"
    [class.chip-filled]="formGroup.get(formControlName).value.length"
  >
    <mat-label>{{ fieldLabel | customTitleCase: 'fromCamelCase' }}</mat-label>
    <admin-input-chip-list
      #inputChipList
      [formControlName]="formControlName"
      [placeholder]="['New', fieldLabel | customTitleCase: 'fromCamelCase'].join(' ')"
      [required]="required"
      [valueFormattingObj]="valueFormattingObj"
      [allowAnyInput]="!autocompleteValues"
      [selectableValues]="autocompleteValues"
    >
    </admin-input-chip-list>
  </mat-form-field>

  <mat-error class="error-message" role="alert" *ngIf="errorMessage && ngControl.control.enabled">
    {{ errorMessage }}
  </mat-error>
</div>
