<ng-container [ngSwitch]="renderState$ | async">
  <mat-card appearance="outlined" *ngSwitchCase="'loading'" class="mat-elevation-z1 details-card overlay">
    <admin-spinner></admin-spinner>
  </mat-card>

  <mat-card appearance="outlined" *ngSwitchCase="'error'" fxLayout="row" fxLayoutAlign="center center" fxFlex>
    <div>
      <h4><mat-icon [inline]="true">warning</mat-icon> Loading enrollments failed</h4>
    </div>
  </mat-card>

  <ng-container *ngSwitchCase="'content'">
    <div *ngIf="(enrollments$ | async).length" class="filter">
      <mat-form-field class="slim-form-field select">
        <mat-label>Account Status</mat-label>
        <mat-select [formControl]="statusControl" disableOptionCentering panelClass="dropdown-panel">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status | customTitleCase: 'fromSnakeCase' }}
          </mat-option>
        </mat-select>
        <admin-input-reset
          [initialValue]="''"
          [inputControl]="statusControl"
          [loading]="false"
          matSuffix
        ></admin-input-reset>
      </mat-form-field>
    </div>

    <ng-container *ngIf="filteredEnrollments$ | async as enrollments">
      <ng-container *ngIf="enrollments.length === 0; else enrollmentsView">
        <mat-card appearance="outlined">
          <div fxLayoutAlign="center center">
            <h4>No enrollments found</h4>
          </div>
        </mat-card>
      </ng-container>
      <ng-template #enrollmentsView>
        <mat-card appearance="outlined" *ngFor="let enrollment of enrollments">
          <mat-card-content>
            <div class="headline">
              <mat-card-title>{{ enrollment.product.name }} Account</mat-card-title>
            </div>
            <div class="details-container">
              <div class="item">
                <div class="item__key">
                  <h4>Account name</h4>
                </div>
                <span class="item__value">{{ enrollment.product.name }}</span>
              </div>

              <div class="item">
                <div class="item__key">
                  <h4>Account status</h4>
                </div>
                <span class="item__value">{{
                  enrollment.product.partnerStatus | customTitleCase: 'fromSnakeCase'
                }}</span>
              </div>

              <div class="item">
                <div class="item__key">
                  <h4>Rewards ID</h4>
                </div>
                <span class="item__value">{{ enrollment.product.referenceId }}</span>
              </div>

              <div class="item">
                <div class="item__key">
                  <h4>Start date</h4>
                </div>
                <span class="item__value" matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">{{
                  enrollment.product.startTime | date: 'yyyy-MM-dd' : timezone
                }}</span>
              </div>

              <div class="item">
                <div class="item__key">
                  <h4>Account number</h4>
                </div>
                <span class="item__value">{{ enrollment.product.secondaryReferenceId }}</span>
              </div>

              <div class="item" *ngIf="enrollment.product.customFields.parentRewardsId as parentId">
                <div class="item__key">
                  <h4>Parent ID</h4>
                </div>
                <span class="item__value">
                  <a routerLink="/enrollments" (click)="searchParentId(parentId)">{{ parentId }}</a>
                </span>
              </div>
            </div>

            <!--    view more details      -->
            <div class="expansion-panel-wrapper">
              <mat-accordion>
                <mat-expansion-panel
                  class="enrollments-expansion-panel"
                  hideToggle
                  (opened)="detailPanelOpenState = true"
                  (closed)="detailPanelOpenState = false"
                  [expanded]="enrollment.product.tenantId === 'AustraliaNewZealandBankBusinessRewards'"
                >
                  <mat-expansion-panel-header class="enrollments-expansion-panel__header-wrapper">
                    <mat-panel-title>
                      <h4 class="enrollments-expansion-panel__header-title">
                        Account details<mat-icon>{{ detailPanelOpenState ? 'expand_less' : 'expand_more' }}</mat-icon>
                      </h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="details-container enrollments-expansion-panel__account_details">
                    <div class="item">
                      <div class="item__key">
                        <h4>Product code</h4>
                      </div>
                      <span class="item__value">{{ enrollment.product.code }}</span>
                    </div>

                    <div class="item">
                      <div class="item__key">
                        <h4>Closure date</h4>
                      </div>
                      <span
                        *ngIf="enrollment.product.endTime"
                        class="item__value"
                        matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
                        >{{ enrollment.product.endTime | date: 'yyyy-MM-dd' : timezone }}</span
                      >
                    </div>

                    <div class="item">
                      <div
                        class="item__key"
                        *ngIf="enrollment.product.tenantId === 'AustraliaNewZealandBankBusinessRewards'"
                      >
                        <h4>Business level</h4>
                      </div>
                      <span class="item__value">{{
                        redemptionLevel[enrollment.product.customFields.redemptionLevel]
                      }}</span>
                    </div>

                    <div class="item">
                      <div class="item__key">
                        <h4>Write off date</h4>
                      </div>
                      <span
                        *ngIf="enrollment.pointsAccount.closingTime"
                        class="item__value"
                        matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
                        >{{ enrollment.pointsAccount.closingTime | date: 'yyyy-MM-dd' : timezone }}</span
                      >
                    </div>

                    <ng-container *ngIf="enrollment.product.tenantId === 'AustraliaNewZealandBankBusinessRewards'">
                      <div class="item">
                        <div class="item__key">
                          <h4>Business rank</h4>
                        </div>
                        <span class="item__value">{{ accountType[enrollment.product.customFields.accountType] }}</span>
                      </div>

                      <div class="item">
                        <div class="item__key">
                          <h4>Loyalty flag</h4>
                        </div>
                        <span class="item__value">{{
                          enrollment.product.customFields.loyaltyFlag ? 'Yes' : 'No'
                        }}</span>
                      </div>
                    </ng-container>
                  </div>

                  <ng-container *ngFor="let ruleGroup of enrollment.product.accrualInfo.ruleGroups; let i = index">
                    <!--  always show non-bonus earns -->
                    <!--  only show bonus earns when showApidDetails is true -->
                    <div
                      *ngIf="ruleGroup.category !== 'bonus' || showApidDetails"
                      [ngClass]="i % 2 === 0 ? 'alternative-background-color' : ''"
                      class="enrollments-expansion-panel__account_accrual_info"
                    >
                      <h3 *ngIf="this.showApidDetails">{{ ruleGroup.category | titlecase }} Earns</h3>
                      <div class="details-container">
                        <div class="item">
                          <div class="item__key">
                            <h4>Current cumulative spend</h4>
                          </div>
                          <span class="item__value">{{ ruleGroup.currentCumulativeBalance | currency }}</span>
                        </div>

                        <div class="item flex-flow-column">
                          <div class="item">
                            <div class="item__key">
                              <h4>Accrual code</h4>
                            </div>
                            <span class="item__value">{{ ruleGroup.groupId }}</span>
                          </div>

                          <ng-container *ngIf="showApidDetails && ruleGroup.category === 'bonus'; else base">
                            <div class="item">
                              <div class="item__key">
                                <h4>Accrual start date</h4>
                              </div>
                              <span class="item__value" matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
                                {{ enrollment.product.customFields.apidActivatedAt | date: 'yyyy-MM-dd' : timezone }}
                              </span>
                            </div>

                            <div class="item">
                              <div class="item__key">
                                <h4>Accrual expiry date</h4>
                              </div>
                              <span class="item__value" matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
                                {{ ruleGroup.latestBucketEndTime | date: 'yyyy-MM-dd' : timezone }}
                              </span>
                            </div>

                            <div class="item">
                              <div class="item__key">
                                <h4>Accrual last reset date</h4>
                              </div>
                              <span class="item__value" matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
                                {{ ruleGroup.latestBucketStartTime | date: 'yyyy-MM-dd' : timezone }}
                              </span>
                            </div>
                          </ng-container>

                          <ng-template #base>
                            <div class="item">
                              <div class="item__key">
                                <h4>Last cycle date</h4>
                              </div>
                              <span class="item__value" matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
                                {{ ruleGroup.latestBucketStartTime | date: 'yyyy-MM-dd' : timezone }}
                              </span>
                            </div>
                          </ng-template>
                        </div>

                        <ng-container *ngFor="let tier of ruleGroup.tiers">
                          <div class="item">
                            <div class="item__key">
                              <h4>Tier level {{ tier.tierId }}</h4>
                            </div>
                            <span class="item__value">
                              Earn rate: {{ tier.pointsPerDenomination | number: '1.2-2' }} per
                              {{ tier.denomination | currency }} spend
                              <ng-container *ngIf="tier.cumulativeMaxSpend">
                                up to {{ tier.cumulativeMaxSpend | currency }}
                              </ng-container>
                            </span>
                          </div>

                          <div class="item">
                            <span>Current cumulative spend: {{ tier.currentCumulativeSpendAmount | currency }}</span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
