import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { initialLocaleState } from '@core/store/locale-state';
import { CallState, FetchState, LocaleState } from '@core/types';

import { LoyaltyProgramsFilter } from './loyalty-programs-filter.type';

export interface LoyaltyProgram {
  id: string;
  type?: string;
  processingDelay?: number;
  category?: string;
  name?: string;
  shortName?: string;
  description?: string;
  detailsUrl?: string;
  enrollmentUrl?: string;
  logoUrl?: string;
  conditionsUrl?: string;
  loyaltyCurrency?: LoyaltyCurrency;
  transferRate?: TransferRate;
  capabilities?: Capability[];
  transferConnectIdentifier?: string;
  active?: boolean;
  nameRequired?: boolean;
  sortIndex?: number;
  displayIndex?: number;
  manualVerificationRequired?: boolean;
  redeemable?: boolean;
  translations?: Translation[];
}

export interface Translation {
  locale: string;
  name: string;
  description: string;
  shortName: string;
  partnerName?: string;
  partnerShortName?: string;
}

export interface Capability {
  id: string;
  type: string;
  detailsUrl: string;
  logoUrl: string;
  conditionsUrl: string;
  enrollmentUrl: string;
  processingDelay: number;
  category: string;
  config: object;
  sortIndex: number;
}

export interface CapabilityRawResponse {
  id: string;
  type: string;
  attributes: {
    detailsUrl: string;
    logoUrl: string;
    conditionsUrl: string;
    enrollmentUrl: string;
    processingDelay: number;
    category: string;
    config: object;
    sortIndex: number;
  };
}

export interface TransferRateAttributes {
  id?: string;
  denomination?: number;
  exchangeRate?: number;
  loyaltyCurrencyId?: string;
  minimumTransfer?: number;
  maximumTransfer?: number;
  targetDenomination?: number;
  targetMaximumTransfer?: number;
  targetMinimumTransfer?: number;
}

export interface TransferRate extends TransferRateAttributes {
  id?: string;
  type?: string;
}

export interface TransferRateRawResponse {
  id: string;
  type: string;
  attributes?: TransferRateAttributes;
}

interface LoyaltyCurrencyAttributes {
  name?: string;
  shortName?: string;
}

interface LoyaltyCurrency extends LoyaltyCurrencyAttributes {
  id: string;
  type: string;
}

export interface LoyaltyCurrencyRawResponse {
  id: string;
  type: string;
  attributes?: LoyaltyCurrencyAttributes;
}

export interface LoyaltyProgramDataResponse {
  id: string;
  type?: string;
  attributes?: {
    name?: string;
    shortName?: string;
    processingDelay?: number;
    category?: string;
    description?: string;
    detailsUrl?: string;
    enrollmentUrl?: string;
    logoUrl?: string;
    conditionsUrl?: string;
    transferConnectIdentifier?: string;
    active?: boolean;
    nameRequired?: boolean;
  };
  relationships?: {
    capabilities: {
      data?: { id: string; type?: string }[];
    };
    loyaltyCurrency?: {
      data?: {
        id?: string;
        type?: string;
      };
    };
    transferRate?: {
      data?: {
        id?: string;
        type?: string;
      };
    };
  };
}

export interface LoyaltyProgramsRawResponse {
  data: LoyaltyProgramDataResponse[];
  included: Array<LoyaltyCurrencyRawResponse | TransferRateRawResponse | CapabilityRawResponse>;
  meta: {
    count: number;
  };
}

export interface LoyaltyProgramRawResponse {
  data: LoyaltyProgramDataResponse;
  included: Array<LoyaltyCurrencyRawResponse | TransferRateRawResponse | CapabilityRawResponse>;
}

export enum LoyaltyProgramCategory {
  Airline = 'airline',
  Benefit = 'benefit',
  Cash = 'cashback_to_account',
  CashbackToCard = 'cashback_to_card',
  Hotel = 'hotel',
  StatusUpgrade = 'status_upgrade'
}

export const loyaltyProgramAdapter = createEntityAdapter<LoyaltyProgram>();

export interface LoyaltyProgramState extends EntityState<LoyaltyProgram>, CallState, FetchState, LocaleState {
  count: number;
  filter: LoyaltyProgramsFilter;
}

export const initialState: LoyaltyProgramState = {
  ...loyaltyProgramAdapter.getInitialState<CallState>(initialCallState),
  ...loyaltyProgramAdapter.getInitialState<FetchState>(initialFetchState),
  ...loyaltyProgramAdapter.getInitialState<LocaleState>(initialLocaleState),
  count: 0,
  filter: null
};
