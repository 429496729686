export type OpenApiType = 'array' | 'boolean' | 'integer' | 'number' | 'object' | 'string';

export type OpenApiXDisplayType = 'checkbox' | 'radio' | 'select';

export type OpenApiFormat = 'country' | 'date' | 'email' | 'id' | 'pan' | 'password' | 'text';

export enum OpenApiNormalStringFormat {
  Country = 'country',
  Date = 'date',
  Pan = 'pan'
}

export interface OpenApiSchemaType {
  components?: {
    schemas: Record<string, OpenApiSchema>;
  };
  discriminator?: {
    propertyName: string;
    mapping: Record<string, string>;
  };
  schema?: OpenApiSchema;
  schema$?: string;
}

export interface OpenApiSchema {
  allOf?: OpenApiSchema[];
  discriminator?: {
    propertyName: string;
    mapping: {
      [key: string]: OpenApiSchemaType;
    };
  };
  enum?: string[];
  exclusiveMaximum?: number;
  exclusiveMinimum?: number;
  example?: string;
  format?: OpenApiFormat;
  items?: {
    type: OpenApiType;
    enum?: string[];
  };
  maximum?: number;
  minimum?: number;
  multipleOf?: number;
  pattern?: string;
  properties?: {
    [key: string]: OpenApiSchema;
  };
  required?: string[];
  type?: OpenApiType;
  'x-display-type'?: OpenApiXDisplayType;
  'x-allow-empty'?: 'allow-empty';
}
