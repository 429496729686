import { createSelector } from '@ngrx/store';

import { CoreState, getCoreState } from '@core/store';
import { UserAbilitiesState } from '@core/types';

const getUserAbilitiesState = createSelector(getCoreState, (state: CoreState) => state.userAbilities);
const isLoaded = createSelector(getUserAbilitiesState, (state: UserAbilitiesState) => state.loaded);
const getUserAbilities = createSelector(getUserAbilitiesState, (state: UserAbilitiesState) => state.userAbilities);

export const userAbilitiesQuery = {
  isLoaded,
  getUserAbilities
};
