import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ColorEvent } from 'ngx-color';

import { Nullable } from '@shared/types';

@Component({
  selector: 'admin-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss']
})
export class ColorInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() label: string;
  @Input() missingValueErrorMessage: string;

  value: Nullable<string>;
  isColorPickerOpen = false;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', this.handleClick());
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.handleClick());
  }

  handleClick(): (this: HTMLElement, event: MouseEvent) => void {
    const self = this;
    return () => {
      if (self.isColorPickerOpen) {
        self.isColorPickerOpen = false;
      }
    };
  }

  toggleColorPicker(event: Event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.isColorPickerOpen = !this.isColorPickerOpen;
    }
  }

  handleColorPickerChange(event: ColorEvent): void {
    const value = event.color.hex.toUpperCase();
    this.onChange(value);
    this.value = value;
  }

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  /* eslint-enable */

  /* interface implementations */
  onChange = (_value: string): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value ? value.toUpperCase() : null;
  }
}
