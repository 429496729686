import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { combineLatest, map, Observable, of } from 'rxjs';

import { nydusNetworkBootstrapResolver } from '@core/resolvers/nydus-network-bootstrap.resolver';
import { Scopes } from '@core/services/scopes/scopes.service';

import { pointsAccountsResolver } from '../../dashboard/resolvers/points-accounts.resolver';
import { loyaltyProgramsResolver } from '../../loyalty-programs/resolvers/loyalty-programs.resolver';
import { membershipsResolver } from '../../memberships/resolvers/memberships.resolver';

export const orderItemCreateResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  scopes: Scopes = inject(Scopes)
): Observable<void> => {
  if (scopes.has('admin_checkout')) {
    pointsAccountsResolver(route, state);
    membershipsResolver(route, state);

    // to ensure  that loyaltyProgramsResolver and nydusNetworkBootstrapResolver, which return observables,
    // are resolved successfully by route and then return null as an observable
    return combineLatest([loyaltyProgramsResolver(route, state), nydusNetworkBootstrapResolver(route, state)]).pipe(
      map(() => null)
    );
  }

  return of(null);
};
