import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { QueryParamService } from '@shared/services/query-param/query-param.service';
import { Params } from '@utils';

import { loadEvents } from '../store/actions/events.actions';
import { eventsQuery } from '../store/selectors/events.selectors';
import { EventsFilter } from '../types/events-filter.type';
import { EventState } from '../types/events.type';

export const userEventsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<EventState> = inject(Store<EventState>),
  scopes: Scopes = inject(Scopes),
  queryParamService: QueryParamService = inject(QueryParamService)
): Observable<void> => {
  if (scopes.lackScopes(SCOPES_OR.viewEvents)) {
    return of(null);
  }

  return store.pipe(
    select(eventsQuery.getFilter),
    first(),
    map(existingFilter => {
      let filter: EventsFilter;
      const queryValue = 'user_' + Params.find(route, 'userId');

      if (existingFilter?.isFromUserView && existingFilter.query.value === queryValue) {
        filter = existingFilter;
      } else {
        const queryParamsFilter = queryParamService.parseAsFilter(route.queryParams) as EventsFilter;
        filter = queryParamsFilter ?? new EventsFilter({ query: { entity: 'event', value: queryValue, limit: 20 } });
      }

      filter = { ...filter, isFromUserView: true };

      store.dispatch(loadEvents({ filter }));
    })
  );
};
