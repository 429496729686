import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { AuthenticationProvider } from '../../authentication-providers/types/authentication-providers.type';

import { BaseIdentity } from './identities.type';

export interface MfaIdentity extends BaseIdentity {
  id: string;
  userId: string;
  verified: boolean;
  provider?: AuthenticationProvider;
  customData: {
    agentCreated?: boolean;
    verified?: boolean;
  };
}

export const mfaIdentityAdapter = createEntityAdapter<MfaIdentity>();

export interface MfaIdentityState extends EntityState<MfaIdentity>, CallState {}

export const mfaIdentityInitialState: MfaIdentityState =
  mfaIdentityAdapter.getInitialState<CallState>(initialCallState);

export interface UpdateMfaIdentityParams {
  verified?: boolean;
  uid?: string;
  agentCreated: boolean;
}

export interface CreateMfaIdentityParams {
  userId: string;
  uid: string;
  providerId: string;
}

export interface DeleteMfaIdentityParams {
  id: string;
  agentCreated: boolean;
}

export interface MfaIdentityColumn {
  tenantId: string;
  uid: string;
  providerId: string;
  verified: string;
  actions: string;
}
