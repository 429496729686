import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: true,
  selector: 'admin-drawer-content-container',
  template: `
    <ng-content select="admin-drawer-content-header"></ng-content>
    <ng-content select="admin-drawer-content-body"></ng-content>
    <ng-content select="admin-drawer-content-footer"></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerContentContainerComponent {
  @HostBinding('class.admin-drawer-content-container') hostClass = true;

  @HostBinding('class') class = 'flex h-full flex-col';
}
