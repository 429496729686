import { Directive, HostListener } from '@angular/core';

import { Nullable } from '@shared/types';

/**
 * Allow text area to 'Enter' while prevent other input type to submit form when 'Enter'
 * Example usage: <form adminFormWithTextArea></form>
 */
@Directive({ selector: '[adminFormWithTextArea]' })
export class FormWithTextAreaDirective {
  @HostListener('keydown.enter', ['$event'])
  onKeyDown($event: Event): Nullable<void> {
    return ($event.target as HTMLElement).tagName === 'TEXTAREA' ? null : $event.preventDefault();
  }
}
