import { OpenApiSchema } from '@kaligo/genesis';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { FeatureAvailability, PropertiesType } from '@shared/types';

export interface McTenant {
  apDomains: string[];
  createdAt?: string;
  environment: PropertiesType;
  id: string;
  name: string;
  settings: object;
  updatedAt?: string;
  featureAvailability: FeatureAvailability;
}

export interface McTenantResponse {
  tenant: McTenant;
  environmentSchema: OpenApiSchema;
}

export interface McTenantsResponse {
  tenants: McTenant[];
  environmentSchema: OpenApiSchema;
}

export const mcTenantAdapter = createEntityAdapter<McTenant>();

export interface McTenantState extends EntityState<McTenant>, CallState {
  environmentSchema: OpenApiSchema;
}

export const initialState: McTenantState = {
  ...mcTenantAdapter.getInitialState<CallState>(initialCallState),
  environmentSchema: null
};
