import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import {
  CreateMfaIdentityParams,
  DeleteMfaIdentityParams,
  MfaIdentity,
  UpdateMfaIdentityParams,
  User
} from '../../types';

export const loadMfaIdentities = createAction('[Users] Load User MFA Identity', props<{ userId: string }>());
export const loadMfaIdentitiesSuccess = createAction(
  '[Users] Load User MFA Identity Success',
  props<{ mfaIdentities: MfaIdentity[] }>()
);
export const loadMfaIdentitiesFailure = createAction('[Users] Load User MfaIdentity Failure', props<ErrorState>());

export const createMfaIdentity = createAction(
  '[Users] Create MFA Identity',
  props<{ params: CreateMfaIdentityParams; dialogRefId: string }>()
);
export const createMfaIdentitySuccess = createAction(
  '[Users] Create MFA Identity Success',
  props<{ userId: string; dialogRefId: string }>()
);
export const createMfaIdentityFailure = createAction('[Users] Create MFA Identity Failure', props<ErrorState>());

export const updateMfaIdentity = createAction(
  '[Users] Update MFA Identity',
  props<{ id: string; params: UpdateMfaIdentityParams; userId: string; dialogRefId: string }>()
);
export const updateMfaIdentitySuccess = createAction(
  '[Users] Update MFA Identity Success',
  props<{ userId: string; dialogRefId: string }>()
);
export const updateMfaIdentityFailure = createAction('[Users] Update MFA Identity Failure', props<ErrorState>());

export const deleteMfaIdentity = createAction(
  '[Users] Delete MFA Identity',
  props<{ params: DeleteMfaIdentityParams; user: User }>()
);
export const deleteMfaIdentitySuccess = createAction(
  '[Users] Delete MFA Identity Success',
  props<{ mfaIdentityId: string; user: User }>()
);
export const deleteMfaIdentityFailure = createAction('[Users] Delete MFA Identity Failure', props<ErrorState>());
