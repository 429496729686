<div class="main-container">
  <div class="wrap page-title-wrapper justify-between">
    <h1>{{ isCustomersView ? 'Customers' : 'Agents' }}</h1>
    <div class="action-button-group">
      <admin-tooltip key="users.index.button.downloadAgents" [displayIcon]="false" *ngIf="isAgentsView">
        <button *adminIfScopesInclude="['users:download_agents']" (click)="downloadAgents()" class="secondary-button">
          Download Agents
        </button>
      </admin-tooltip>

      <admin-tooltip
        key="users.index.button.activateNewUser"
        [displayIcon]="false"
        *adminIfScopesInclude="SCOPES.createEnrollments; relationOperator: 'or'"
      >
        <button routerLink="./enroll" class="primary-button">
          <mat-icon svgIcon="add"></mat-icon>
          Activate New User
        </button>
      </admin-tooltip>

      <ng-container *ngIf="isCustomersView">
        <button routerLink="./create" class="primary-button" *adminIfScopesInclude="['create_user']">
          <mat-icon svgIcon="add"></mat-icon>
          Add User
        </button>
      </ng-container>
    </div>
  </div>

  <div class="filter-form-wrapper with-hint">
    <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
      <ng-container *ngIf="isAgentsView && displaySettings.roles">
        <mat-form-field *adminIfScopesInclude="rolesScopes; relationOperator: 'or'">
          <mat-icon matPrefix svgIcon="filter"></mat-icon>
          <mat-label>Role ID</mat-label>
          <mat-select formControlName="roleId" disableOptionCentering>
            <mat-option *ngFor="let role of roles$ | async" [value]="role.name">
              {{ role.name }}
            </mat-option>
          </mat-select>
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="filterForm.controls.roleId"
            [loading]="loading$ | async"
          ></admin-input-reset>
        </mat-form-field>
      </ng-container>

      <div class="flex" *ngIf="isCustomersView && displaySettings.customerId">
        <mat-form-field>
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-label>Search by Customer ID</mat-label>
          <input
            matInput
            aria-label="Partner UID"
            type="search"
            placeholder="Partner UID"
            formControlName="partnerUid"
          />
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="filterForm.controls.partnerUid"
            [loading]="loading$ | async"
          ></admin-input-reset>
        </mat-form-field>
        <admin-tooltip key="users.index.filter.customerId" styleClassName="filter-tooltip"></admin-tooltip>
      </div>

      <mat-form-field *ngIf="displaySettings.name">
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Search by first name</mat-label>
        <input matInput aria-label="First Name" type="search" placeholder="First Name" formControlName="firstName" />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.firstName"
          [loading]="loading$ | async"
        ></admin-input-reset>
        <mat-error *ngIf="filterForm.get('firstName').errors?.invalidPartialSearch"
          >Please enter at least 2 characters</mat-error
        >
      </mat-form-field>

      <mat-form-field *ngIf="displaySettings.name">
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Search by last name</mat-label>
        <input matInput aria-label="Last Name" type="search" placeholder="Last Name" formControlName="lastName" />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.lastName"
          [loading]="loading$ | async"
        ></admin-input-reset>
        <mat-error *ngIf="filterForm.get('lastName').errors?.invalidPartialSearch"
          >Please enter at least 2 characters</mat-error
        >
      </mat-form-field>

      <ng-container *ngIf="displaySettings.id">
        <div class="flex">
          <mat-form-field>
            <mat-icon matPrefix svgIcon="search"></mat-icon>
            <mat-label>Search by Ascenda ID</mat-label>
            <input
              matInput
              aria-label="ID"
              type="search"
              placeholder="Ascenda ID"
              formControlName="id"
              [value]="filterForm.get('id').value"
            />
            <mat-error *ngIf="filterForm.get('id').errors?.invalidUuid">Invalid UUID</mat-error>
            <admin-input-reset
              matSuffix
              initialValue=""
              [inputControl]="filterForm.controls.id"
              [loading]="loading$ | async"
            ></admin-input-reset>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.ascendaId" styleClassName="filter-tooltip"></admin-tooltip>
        </div>
      </ng-container>

      <mat-form-field *ngIf="displaySettings.phoneNumber">
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Search by phone number</mat-label>
        <input
          matInput
          aria-label="Phone Number"
          type="search"
          placeholder="Phone Number"
          formControlName="phoneNumber"
        />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.phoneNumber"
          [loading]="loading$ | async"
        ></admin-input-reset>
        <mat-error *ngIf="filterForm.get('phoneNumber').errors?.minlength"
          >Please enter at least 2 characters</mat-error
        >
      </mat-form-field>

      <mat-form-field *ngIf="displaySettings.email">
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Search by email</mat-label>
        <input matInput aria-label="Email" type="search" placeholder="Email" formControlName="email" />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.email"
          [loading]="loading$ | async"
        ></admin-input-reset>
        <mat-error *ngIf="filterForm.get('email').errors?.minlength">Please enter at least 2 characters</mat-error>
      </mat-form-field>

      <ng-container *ngIf="showAdvancedFilters$.value">
        <mat-form-field *ngIf="displaySettings.identityId">
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-label>Search by Identity ID</mat-label>
          <input
            matInput
            aria-label="Identity UID"
            type="search"
            placeholder="Identity UID"
            formControlName="identityUid"
          />
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="filterForm.controls.identityUid"
            [loading]="loading$ | async"
          ></admin-input-reset>
          <mat-error *ngIf="filterForm.get('identityUid').errors?.minlength"
            >Please enter at least 2 characters</mat-error
          >
        </mat-form-field>

        <div class="flex" *ngIf="extraUserFilters?.pointsAccount && displaySettings.pointsAccountFilter">
          <mat-form-field>
            <mat-icon matPrefix svgIcon="search"></mat-icon>
            <mat-label>Search by points account</mat-label>
            <input matInput aria-label="Email" type="search" placeholder="Points Account" formControlName="accountId" />
            <admin-input-reset
              matSuffix
              initialValue=""
              [inputControl]="filterForm.controls.accountId"
              [loading]="loading$ | async"
            ></admin-input-reset>
            <mat-error *ngIf="filterForm.get('accountId').hasError('invalidUuid')">Invalid UUID</mat-error>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.pointsAccount" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div class="flex" [class.overlay]="!iframeHandler?.isIframeReady || iframeHandler?.loading" *ngIf="isANZ()">
          <div
            class="users-component-secure-field"
            [id]="'users-component-secure-field-' + (isCustomersView ? 'customer' : 'agent')"
          ></div>
          <admin-tooltip key="users.index.filter.pan" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <mat-form-field formGroupName="createdAtDateRange" class="date-field" *ngIf="displaySettings.createdAt">
          <mat-label>Created date</mat-label>
          <mat-datepicker-toggle matPrefix [for]="createdAtPicker">
            <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #createdAtPicker></mat-date-range-picker>
          <mat-date-range-input [rangePicker]="createdAtPicker">
            <input
              matInput
              matStartDate
              formControlName="createdAtFrom"
              placeholder="Start date"
              (focus)="createdAtPicker.open()"
            />
            <input
              matInput
              matEndDate
              formControlName="createdAtTo"
              placeholder="End date"
              (focus)="createdAtPicker.open()"
            />
          </mat-date-range-input>
          <div matSuffix>
            <button mat-icon-button color="primary" (click)="createdAtPicker.open()">
              <mat-icon matDatepickerToggleIcon svgIcon="arrowdown"></mat-icon>
            </button>
            <ng-container
              *ngIf="
                !(
                  filterForm.get('createdAtDateRange').value.createdAtFrom === null &&
                  filterForm.get('createdAtDateRange').value.createdAtTo === null
                )
              "
            >
              <button
                mat-icon-button
                color="primary"
                class="close-button"
                (click)="filterForm.get('createdAtDateRange').reset()"
                [disabled]="
                  !filterForm.get('createdAtDateRange.createdAtFrom').value &&
                  !filterForm.get('createdAtDateRange.createdAtTo').value
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </mat-form-field>

        <mat-form-field formGroupName="updatedAtDateRange" class="date-field" *ngIf="displaySettings.updatedAt">
          <mat-label>Updated date</mat-label>
          <mat-datepicker-toggle matPrefix [for]="updatedAtPicker">
            <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #updatedAtPicker></mat-date-range-picker>
          <mat-date-range-input [rangePicker]="updatedAtPicker">
            <input
              matInput
              matStartDate
              formControlName="updatedAtFrom"
              placeholder="Start date"
              (focus)="updatedAtPicker.open()"
            />
            <input
              matInput
              matEndDate
              formControlName="updatedAtTo"
              placeholder="End date"
              (focus)="updatedAtPicker.open()"
            />
          </mat-date-range-input>
          <div matSuffix>
            <button mat-icon-button color="primary" (click)="updatedAtPicker.open()">
              <mat-icon matDatepickerToggleIcon svgIcon="arrowdown"></mat-icon>
            </button>
            <ng-container
              *ngIf="
                !(
                  filterForm.get('updatedAtDateRange').value.updatedAtFrom === null &&
                  filterForm.get('updatedAtDateRange').value.updatedAtTo === null
                )
              "
            >
              <button
                matSuffix
                mat-icon-button
                color="primary"
                class="close-button"
                (click)="filterForm.get('updatedAtDateRange').reset()"
                [disabled]="
                  !filterForm.get('updatedAtDateRange.updatedAtFrom').value &&
                  !filterForm.get('updatedAtDateRange.updatedAtTo').value
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </mat-form-field>

        <div class="flex" *ngIf="isCustomersView && displaySettings.state">
          <mat-form-field>
            <mat-icon matPrefix svgIcon="filter"></mat-icon>
            <mat-label>User state</mat-label>
            <mat-select [formControl]="stateControl" disableOptionCentering>
              <mat-option *ngFor="let state of states" [value]="state">
                {{ state | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.userState" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div class="flex" *ngIf="isCustomersView && displaySettings.status">
          <mat-form-field>
            <mat-icon matPrefix svgIcon="filter"></mat-icon>
            <mat-label>Tenant status</mat-label>
            <mat-select formControlName="status" disableOptionCentering>
              <mat-option *ngFor="let status of statuses" [value]="status">
                {{ status | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.tenantStatus" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div class="flex" *ngIf="displaySettings.loginMode">
          <mat-form-field>
            <mat-icon matPrefix svgIcon="filter"></mat-icon>
            <mat-label>Login mode</mat-label>
            <mat-select formControlName="loginMode" disableOptionCentering>
              <mat-option *ngFor="let loginMode of loginModes" [value]="loginMode">
                {{ loginMode | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            </mat-select>
            <admin-input-reset
              matSuffix
              initialValue=""
              [inputControl]="filterForm.controls.loginMode"
              [loading]="loading$ | async"
            ></admin-input-reset>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.loginMode" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div class="flex" *ngIf="displaySettings.activated">
          <mat-form-field>
            <mat-icon matPrefix svgIcon="filter"></mat-icon>
            <mat-label>Activation status</mat-label>
            <mat-select formControlName="activated" disableOptionCentering>
              <mat-option [value]="true">Activated</mat-option>
              <mat-option [value]="false">Not Activated</mat-option>
            </mat-select>
            <admin-input-reset
              matSuffix
              initialValue=""
              [inputControl]="filterForm.controls.activated"
              [loading]="loading$ | async"
            ></admin-input-reset>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.activationStatus" styleClassName="filter-tooltip"></admin-tooltip>
        </div>
      </ng-container>
    </form>

    <div class="filter-controls">
      <admin-clear-form-button [form]="filterForm" (click)="clearFilter()"></admin-clear-form-button>
      <admin-toggle-advanced-filters-button
        [showAdvancedFilters]="!showAdvancedFilters$.value"
        (click)="showAdvancedFilters$.next(!showAdvancedFilters$.value)"
      ></admin-toggle-advanced-filters-button>
    </div>

    <mat-hint *ngIf="hasPartialSearch && (usersTotalCount$ | async) > filter.limit && !(loading$ | async)">
      Note: This query has many results. Try more specific queries to get better results.
    </mat-hint>
  </div>

  <div *ngIf="loading$ | async" class="progress-loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="sticky-table-wrapper">
    <table mat-table matSort [dataSource]="users$ | async" [class.overlay]="loading$ | async">
      <ng-container
        matColumnDef="customerId"
        [sticky]="columnConfig['customerId']?.isSticky"
        [stickyEnd]="columnConfig['customerId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['customerId']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.customerId" position="above" [displayIcon]="false">
            Customer ID
          </admin-tooltip>
        </th>
        <td
          mat-cell
          *matCellDef="let user; let i = index"
          [attr.data-testid]="'user-id-' + i"
          [ngClass]="columnConfig['customerId']?.cellClass"
        >
          {{ getCustomerId(user) }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="identityId"
        [sticky]="columnConfig['identityId']?.isSticky"
        [stickyEnd]="columnConfig['identityId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['identityId']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.identityId" position="above" [displayIcon]="false">
            Identity ID
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['identityId']?.cellClass">
          {{ getIdentityId(user) }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="name"
        [sticky]="columnConfig['name']?.isSticky"
        [stickyEnd]="columnConfig['name']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['name']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.name" position="above" [displayIcon]="false">
            Name
          </admin-tooltip>
        </th>
        <td
          mat-cell
          *matCellDef="let user; let i = index"
          [attr.data-testid]="'user-name-' + i"
          [ngClass]="columnConfig['name']?.cellClass"
        >
          {{ user | userName }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="email"
        [sticky]="columnConfig['email']?.isSticky"
        [stickyEnd]="columnConfig['email']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['email']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.email" position="above" [displayIcon]="false">
            Email
          </admin-tooltip>
        </th>
        <td
          mat-cell
          *matCellDef="let user; let i = index"
          [attr.data-testid]="'user-email-' + i"
          [ngClass]="columnConfig['email']?.cellClass"
        >
          {{ user.email }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="id"
        [sticky]="columnConfig['id']?.isSticky"
        [stickyEnd]="columnConfig['id']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['id']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.ascendaId" position="above" [displayIcon]="false">
            Ascenda ID
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['id']?.cellClass">{{ user.id }}</td>
      </ng-container>

      <ng-container
        matColumnDef="roles"
        [sticky]="columnConfig['roles']?.isSticky"
        [stickyEnd]="columnConfig['roles']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['roles']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.roles" position="above" [displayIcon]="false">
            Roles
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['roles']?.cellClass">
          <ng-container *ngIf="user.roles">
            <span *ngFor="let role of user.roles; last as last">
              {{ role.name }}<ng-container *ngIf="!last">, </ng-container>
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="state"
        [sticky]="columnConfig['state']?.isSticky"
        [stickyEnd]="columnConfig['state']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['state']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.userState" position="above" [displayIcon]="false">
            User state
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['state']?.cellClass">
          <button
            *ngIf="user | stateDisplayPipe"
            mat-flat-button
            disabled
            class="status-button-in-table"
            [ngClass]="userStateDisplayClass[user | stateDisplayPipe] || 'status-neutral'"
          >
            <span class="dot"></span>{{ user | stateDisplayPipe | customTitleCase: 'fromSnakeCase' }}
          </button>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="pointsAccountColumn"
        [sticky]="columnConfig['pointsAccountColumn']?.isSticky"
        [stickyEnd]="columnConfig['pointsAccountColumn']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['pointsAccountColumn']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.pointsAccount" position="above" [displayIcon]="false">
            Points account
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['pointsAccountColumn']?.cellClass">
          {{ user.preferences?.pointsAccountId }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="phoneNumber"
        [sticky]="columnConfig['phoneNumber']?.isSticky"
        [stickyEnd]="columnConfig['phoneNumber']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['phoneNumber']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.phoneNumber" position="above" [displayIcon]="false">
            Phone no.
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['phoneNumber']?.headerClass">
          {{ user.phoneNumber }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="createdAt"
        [sticky]="columnConfig['createdAt']?.isSticky"
        [stickyEnd]="columnConfig['createdAt']?.isStickyEnd"
      >
        <th
          mat-header-cell
          mat-sort-header="createdAt"
          *matHeaderCellDef
          [disabled]="hasIdSearch || hasPartialSearch"
          [ngClass]="columnConfig['createdAt']?.headerClass"
        >
          <admin-tooltip key="users.index.tableHeader.createdAt" position="above" [displayIcon]="false">
            Created at
          </admin-tooltip>
        </th>
        <td
          mat-cell
          matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
          *matCellDef="let user"
          [ngClass]="columnConfig['createdAt']?.cellClass"
        >
          {{ user.createdAt | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="updatedAt"
        [sticky]="columnConfig['updatedAt']?.isSticky"
        [stickyEnd]="columnConfig['updatedAt']?.isStickyEnd"
      >
        <th
          mat-header-cell
          mat-sort-header="updatedAt"
          *matHeaderCellDef
          [disabled]="hasIdSearch || hasPartialSearch"
          [ngClass]="columnConfig['updatedAt']?.headerClass"
        >
          <admin-tooltip key="users.index.tableHeader.updatedAt" position="above" [displayIcon]="false">
            Updated at
          </admin-tooltip>
        </th>
        <td
          mat-cell
          matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
          *matCellDef="let user"
          [ngClass]="columnConfig['updatedAt']?.cellClass"
        >
          {{ user.updatedAt | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="actions"
        *ngIf="displaySettings.actions"
        [sticky]="columnConfig['actions']?.isSticky"
        [stickyEnd]="columnConfig['actions']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['actions']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.action" position="above" [displayIcon]="false">
            Actions
          </admin-tooltip>
        </th>
        <td
          mat-cell
          *matCellDef="let user; let i = index"
          [attr.data-testid]="'user-action-' + i"
          [ngClass]="columnConfig['actions']?.cellClass"
        >
          <a [routerLink]="'./' + user.id + detailsView">
            <button mat-icon-button [attr.data-testid]="'user-action-view-' + i">
              <mat-icon svgIcon="view"></mat-icon>
            </button>
          </a>
          <a [routerLink]="'edit/' + user.id" *ngIf="showEdit(currentUser$ | async, user.id)">
            <button mat-icon-button [attr.data-testid]="'user-action-edit-' + i">
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [attr.data-testid]="'user-row-' + i"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No customers available</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    *ngIf="!hasPartialSearch && !!(usersTotalCount$ | async)"
    [disabled]="loading$ | async"
    [pageSizeOptions]="[20, 50]"
    [pageIndex]="filter.page - 1"
    [pageSize]="filter.limit"
    [length]="usersTotalCount$ | async"
    (page)="onPage($event)"
    adminPaginator
  ></mat-paginator>
</div>
