export class MerchandisesFilter {
  id?: string;
  name?: string;
  supplier?: string;
  brand?: string;
  approved?: boolean;
  active?: boolean;
  categories?: string[];
  page: number;
  limit: number;
  sort?: object;

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}
