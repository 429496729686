import { TooltipModuleKey } from '../modules/tooltips/types/tooltip-keys.type';

export class ModuleUtils {
  static getCurrentModules(url: string): TooltipModuleKey[] {
    const pathSegments = new URL(url, window.location.href).pathname // remove query params e.g. ?state=...
      .split(/[\/?]+/); // eslint-disable-line no-useless-escape
    const currentModule = pathSegments[1];
    const modules: TooltipModuleKey[] = [];

    if (currentModule === '') {
      // page on login (no route)
      modules.push('sidenav');
    } else if (
      ['customers', 'agents'].includes(currentModule) || // customers and agents are using users module
      (currentModule === 'enrollments' && pathSegments.length > 3) // enrollments' children are the same as users'
    ) {
      modules.push('users');
    } else if (currentModule) {
      modules.push(currentModule as TooltipModuleKey);
    }

    // for embedded pages
    const embeddedModules: TooltipModuleKey[] = ['events', 'notes', 'password-policies'];
    embeddedModules.forEach(route => {
      if (pathSegments[3] === route) {
        modules.push(route);
      }
    });

    return modules;
  }
}
