import ISO6391 from 'iso-639-1';

import { Nullable } from '@shared/types';

export const CountryUtils = {
  isoCountryCodeToCountryName(isoCode: string): Nullable<string> {
    if (!isoCode || isoCode.length > 2) {
      return null;
    }
    const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
    return regionNamesInEnglish.of(isoCode.toUpperCase()) || null;
  },

  isoCountryCodeToFlagEmoji(isoCode: string): Nullable<string> {
    if (!isoCode || isoCode.length > 2) {
      return null;
    }

    const codePoints = isoCode
      .toUpperCase()
      .split('')
      .map(char => 127_397 + (char.codePointAt(0) || 0));

    const emoji = String.fromCodePoint(...codePoints);
    if (emoji.includes('🇽')) {
      return null;
    }
    return emoji;
  },

  formatLanguage(languageCountry: string): Nullable<string> {
    if (!languageCountry || !languageCountry.includes('-')) {
      return null;
    }

    const [languageCode, countryCode] = languageCountry.split('-');

    if (!languageCode || !countryCode) {
      return null;
    }

    return `${ISO6391.getName(languageCode)} (${countryCode})`;
  }
};
