import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';

import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';
import { ComponentState, getRenderState$, Params } from '@utils';

import { AuditLogsQuery } from '../../store/selectors/audit-logs.selectors';
import { AuditLog, AuditLogState } from '../../types';

@UseV2Style
@Component({
  selector: 'admin-audit-log-details',
  templateUrl: './audit-log-details.component.html',
  styleUrls: ['./audit-log-details.component.scss']
})
export class AuditLogDetailsComponent implements OnInit {
  isTopLevelView: boolean;
  auditLog$: Observable<AuditLog>;
  renderState$: Observable<ComponentState>;

  constructor(
    private store: Store<AuditLogState>,
    private route: ActivatedRoute,
    private auditLogsQuery: AuditLogsQuery
  ) {}

  ngOnInit(): void {
    this.isTopLevelView = this.route.snapshot.data.isTopLevelView;

    const auditLogId = Params.find(this.route, 'id');
    this.auditLog$ = this.store.select(this.auditLogsQuery.getAuditLogById(auditLogId)).pipe(filter(Boolean));

    this.renderState$ = getRenderState$([
      this.store.select(this.auditLogsQuery.isSingleLoading),
      this.store.select(this.auditLogsQuery.getSingleError)
    ]);
  }
}
