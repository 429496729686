import { IframeRenderingTimeoutError, SecureFieldsError } from '../../../shared';
var IframeHandler = /** @class */ (function () {
    function IframeHandler(iframeConfig) {
        this.iframeConfig = iframeConfig;
        this.isIframeReady = false;
    }
    IframeHandler.prototype.init = function () {
        this.parentElement = document.getElementById(this.iframeConfig.parentElementId);
        if (this.parentElement) {
            this.renderIframe();
            this.handleMessageEvents();
            if (this.iframeConfig.submitElementId) {
                this.listenToSubmitButtonClick(this.iframeConfig.submitElementId);
            }
        }
    };
    IframeHandler.prototype.changeTokenizationEnabled = function (tokenizationEnabled) {
        this.postMessage({ tokenizationEnabled: tokenizationEnabled });
    };
    IframeHandler.prototype.focus = function () {
        this.postMessage({ focused: true });
    };
    IframeHandler.prototype.touch = function () {
        this.postMessage({ touched: true });
    };
    IframeHandler.prototype.clearValue = function () {
        this.postMessage({ clearValue: true });
    };
    IframeHandler.prototype.setStyle = function (_a) {
        var _b = _a.border, border = _b === void 0 ? this.iframe.style.border : _b, _c = _a.width, width = _c === void 0 ? this.iframe.style.width : _c, _d = _a.height, height = _d === void 0 ? this.iframe.style.height : _d, _e = _a.overflow, overflow = _e === void 0 ? this.iframe.style.overflow : _e;
        this.iframe.style.border = border;
        this.iframe.style.width = width;
        this.iframe.style.height = height;
        this.iframe.style.overflow = overflow;
    };
    IframeHandler.prototype.renderIframe = function () {
        var _this = this;
        this.iframe = window.document.createElement('iframe');
        this.parentElement.appendChild(this.iframe);
        this.iframe.src = this.buildSecureFieldsUrl();
        this.setStyle({ border: 'none', width: '100%', height: '100%', overflow: 'hidden' });
        // throw error when rendering timeout
        setTimeout(function () {
            if (!_this.isIframeReady) {
                _this.iframeConfig.onError(new IframeRenderingTimeoutError());
            }
        }, 3000);
    };
    IframeHandler.prototype.handleMessageEvents = function () {
        var _this = this;
        window.addEventListener('message', function (event) {
            if (event.origin === _this.iframeConfig.pciProxyBaseUrl &&
                event.source === _this.iframe.contentWindow &&
                event.data.response_type === 'pci-proxy') {
                var _a = event.data.response, token = _a.token, error = _a.error, loading = _a.loading, validationError = _a.validationError, isIframeReady = _a.isIframeReady;
                if (token) {
                    _this.iframeConfig.onTokenize(token);
                }
                else if (error) {
                    _this.iframeConfig.onError(error);
                }
                else if (validationError && _this.iframeConfig.onValidationError) {
                    // we pass secure field errors from PCI-Proxy as { validationFailure: { code: xxx, message: xxx } } in order to get enough info
                    // so here we reconstruct SecureFieldsError with code and message, and pass it to onValidationError()
                    if (validationError.validationFailure) {
                        var validationFailure = new SecureFieldsError(validationError.validationFailure);
                        return _this.iframeConfig.onValidationError(validationFailure);
                    }
                    var payload = Object.keys(validationError).length > 0 ? validationError : null;
                    _this.iframeConfig.onValidationError(payload);
                }
                else if (isIframeReady === true) {
                    _this.isIframeReady = true;
                }
                else if (typeof loading === 'boolean') {
                    _this.loading = loading;
                }
            }
        });
    };
    IframeHandler.prototype.buildSecureFieldsUrl = function () {
        var _this = this;
        var pciProxyBaseUrl = this.iframeConfig.pciProxyBaseUrl;
        var params = new URLSearchParams();
        var keys = [
            'configKey', 'version', 'matFormFieldClasses', 'submitElementId', 'label', 'placeholder',
            'required', 'styleUrl', 'requiredMessage', 'validationFailureMessage', 'tenantId', 'clientId',
            'appearance'
        ];
        keys.forEach(function (key) {
            var value = _this.iframeConfig[key];
            if (value === undefined || value === null) {
                return;
            }
            if (typeof value === 'boolean') {
                return params.set(key, value.toString());
            }
            if (typeof value === 'string') {
                return params.set(key, value);
            }
            if (Array.isArray(value)) {
                return value.forEach(function (v) { return params.append(key + "[]", v); });
            }
        });
        return pciProxyBaseUrl + "/secure_fields?" + params.toString();
    };
    IframeHandler.prototype.postMessage = function (response) {
        this.iframe.contentWindow.postMessage({ response_type: 'secure-fields-iframe', response: response }, this.iframeConfig.pciProxyBaseUrl);
    };
    IframeHandler.prototype.listenToSubmitButtonClick = function (submitElementId) {
        var _this = this;
        var _a;
        this.submitButtonElement = document.getElementById(submitElementId);
        (_a = this.submitButtonElement) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () { return _this.postMessage({ submitButtonClicked: true }); });
    };
    return IframeHandler;
}());
export { IframeHandler };
