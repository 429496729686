<div *ngIf="memberships?.length > 0; else noMemberships" [class.overlay]="loading$ | async">
  <h3 class="title">Cashback</h3>

  <div *ngIf="showPointsAccountsSelector">
    <h4>Card / Account</h4>
    <mat-form-field class="filter-field slim-form-field">
      <mat-label>Select card / account</mat-label>
      <mat-select
        disableOptionCentering
        panelClass="dropdown-panel"
        [formControl]="cardSelection"
        (selectionChange)="onCardSelectionChange($event)"
      >
        <mat-option *ngFor="let card of cardSelectionDropdownList" [value]="card.identifier">
          {{ card.category === 'cash' ? 'Bank account' : 'Card ending with' }} {{ card.number }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
      <mat-hint *ngIf="processingDelay$ | async as processingDelay"
        >Redemption time: {{ processingDelay | timeFromNow }}</mat-hint
      >
    </mat-form-field>
  </div>

  <admin-two-way-currency-conversion
    [loyaltyProgramId]="cardSelection.value ? selectedLoyaltyProgramId : null"
    (amountSelected)="selectedAmount = $event"
    redemptionType="redeem"
    #twoWayCurrencyConversion
  ></admin-two-way-currency-conversion>

  <button
    mat-raised-button
    color="primary"
    type="submit"
    class="uppercase-button"
    [disabled]="(loading$ | async) || !cardSelection.value || twoWayCurrencyConversion.conversionForm.invalid"
    (click)="redeem()"
  >
    Redeem
  </button>
  <admin-spinner *ngIf="loading$ | async"></admin-spinner>
</div>

<ng-template #noMemberships>
  <h4 fxFlex fxLayout="row" fxLayoutAlign="start center">
    <mat-icon [inline]="true">warning</mat-icon>
    No Loyalty Program Memberships available for redemption
  </h4>
</ng-template>
