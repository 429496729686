<div>
  <h2 mat-dialog-title>Edit Tooltips</h2>

  <div *ngIf="loading$ | async" class="progress-loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div mat-dialog-content>
    <table *ngIf="tooltipForm" [formGroup]="tooltipForm" [class.overlay]="loading$ | async">
      <tr>
        <th>Tooltip Key</th>
        <th>Tooltip Value</th>
      </tr>

      <ng-container formArrayName="tooltips" *ngFor="let tooltip of tooltips.controls; let i = index">
        <tr [formGroupName]="i">
          <td>
            <div class="slim-form-field">
              <mat-form-field class="tooltip-key">
                <input matInput readonly formControlName="key" />
              </mat-form-field>
            </div>
          </td>

          <td>
            <div class="slim-form-field">
              <mat-form-field class="tooltip-value">
                <input matInput formControlName="value" />
              </mat-form-field>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      mat-raised-button
      color="secondary"
      class="uppercase-button text-primary cancel-button"
      (click)="dialogRef.close(false)"
    >
      <span class="uppercase-button__text">Cancel</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      class="uppercase-button"
      (click)="updateTooltips()"
      [disabled]="loading$ | async"
    >
      <span class="uppercase-button__text">Update</span>
    </button>
  </div>
</div>
