import { Action, ActionReducer } from '@ngrx/store';

import { FetchListTriggers, FetchState } from '@core/types';

// Returns the manipulated fetchState according to the FetchListTriggers definition and action attributes
// Example:
//  If the reducer receives an action which is defined as a "loading" action,
//  it will change the fetchList value to [...state.fetchList, id].
//  or [...state.fetchList, ...ids] if action contains batch attribute
//
//  someFeatureReducer = fetchStateReducer(fetchListTriggers)
//  someFeatureReducer(state, action) // manipulates fetchState according to fetchListTriggers
export function fetchStateReducer<S extends FetchState>(
  triggers: FetchListTriggers,
  entityKey: string
): ActionReducer<FetchState, FetchAction> {
  return (state: S, action: FetchAction) => {
    const fetchList = extractFetchList(triggers, action, state, entityKey);

    return {
      fetchList: fetchList || state.fetchList
    };
  };
}

function extractFetchList<S extends FetchState>(
  triggers: FetchListTriggers,
  action: FetchAction,
  state: S,
  entityKey: string
): string[] | null {
  if (Object.keys(triggers).length === 0) {
    return null;
  }

  if (triggers.loading.includes(action.type)) {
    if (action.batch) {
      return [...state.fetchList, ...action.ids];
    } else {
      return [...state.fetchList, action.id];
    }
  }

  if (triggers.resting.includes(action.type)) {
    if (action.batch) {
      return []; // MC won't throw error when any of the fetch results are not found, so return empty array by default
    } else if (action[entityKey]?.id) {
      return state.fetchList.filter(id => action[entityKey].id !== id);
    }
  }

  if (triggers.erroring.includes(action.type)) {
    if (action.batch) {
      return []; // if fetching by batch fails, all ids should be removed, so return empty array by default
    } else {
      return state.fetchList.filter(id => action.id !== id);
    }
  }

  return null;
}

interface FetchAction extends Action {
  id?: string;
  ids?: string[];
  batch?: true;
}
