import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { PiiAccess } from '@core/services/pii-access/pii-access.service';
import { Tag } from '@shared/types';
import { DashedDateAdapter, DateUtils, Params } from '@utils';

import { EncryptedFields } from '../../../../app-module-config';
import { EditNoteDialogComponent } from '../../components/edit-note-dialog/edit-note-dialog.component';
import { createNote, updateNote } from '../../store/actions/notes.actions';
import { NotesQuery } from '../../store/selectors/notes.selectors';
import { NoteCreateEditForm } from '../../types/note-forms.type';
import { Note, NoteState, NoteType } from '../../types/notes.type';

@Component({
  selector: 'admin-note-create-edit',
  templateUrl: './note-create-edit.component.html',
  styleUrls: ['./note-create-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DashedDateAdapter
    }
  ]
})
export class NoteCreateEditComponent implements OnInit {
  @Input() timezone: string;
  @Input() showType: boolean;
  @Input() isEditMode = false;
  // note & dialogRef inputs are only used in Edit mode
  @Input() note: Note;
  @Input() dialogRef: MatDialogRef<EditNoteDialogComponent>;
  @ViewChild('noteFormDirective') noteFormDirective: NgForm;

  noteForm: FormGroup<NoteCreateEditForm>;
  minExpiryDate: Date;

  types = Object.values(NoteType);
  datepickerAdapter = DateUtils.datepickerAdaptor;

  constructor(
    private store: Store<NoteState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notesQuery: NotesQuery,
    private piiAccess: PiiAccess,
    @Inject('encryptedFields') private encryptedFields: EncryptedFields
  ) {}

  get tags(): AbstractControl {
    return this.noteForm.get('tags');
  }

  ngOnInit(): void {
    this.minExpiryDate = this.datepickerAdapter('UTCToDatepicker', new Date().toISOString(), this.timezone);

    this.buildForm();

    this.store.select(this.notesQuery.isSingleResting).subscribe(isResting => {
      if (!this.isEditMode && isResting) {
        this.noteFormDirective?.resetForm();
        this.noteForm.reset({ type: NoteType.General });
      }

      if (this.isEditMode) {
        this.handleEncryptedFields();
      }
    });
  }

  buildForm(): void {
    if (this.isEditMode) {
      const expiryDate = this.datepickerAdapter('UTCToDatepicker', this.note.expiresAt, this.timezone);
      expiryDate?.setHours(0, 0, 0);

      this.noteForm = this.fb.group({
        note: this.fb.control(this.note.note, [Validators.required]),
        type: this.fb.control({ value: this.note.type, disabled: true }),
        expiresAt: this.fb.control(expiryDate),
        tags: this.fb.control(this.note.tags)
      });
    } else {
      this.noteForm = this.fb.group({
        note: this.fb.control(null, [Validators.required]),
        type: this.fb.control(NoteType.General, [Validators.required]),
        expiresAt: this.fb.control(null),
        tags: this.fb.control(null)
      });
    }
  }

  convertExpiryDate(expiryDate: Date): string {
    if (!expiryDate) {
      return null;
    }

    // get UTC time of the selected day
    const UTCExpiryDate = this.datepickerAdapter('DatepickerToUTC', expiryDate.toISOString(), this.timezone);

    // set expiryDate to end of the selected day
    return new Date(UTCExpiryDate.getTime() + (23 * 3600 + 59 * 60 + 59) * 1000).toISOString();
  }

  getFormExpiryDate(): string {
    return this.convertExpiryDate(this.noteForm.get('expiresAt').value);
  }

  getFormRawTags(): Tag[] {
    return this.tags.value ? this.tags.value.map((tag: Tag) => ({ id: tag.id, type: tag.type })) : [];
  }

  addNote(): void {
    const { entity, entityIdKey } = this.route.snapshot.data.notesConfigs;

    const note: Note = {
      ...this.noteForm.getRawValue(),
      expiresAt: this.getFormExpiryDate(),
      adminUserId: null,
      entity,
      entityId: Params.find(this.route, entityIdKey),
      tags: this.getFormRawTags()
    };
    this.store.dispatch(createNote({ note }));
  }

  updateNote(): void {
    const note: Note = {
      ...this.noteForm.getRawValue(),
      id: this.note.id,
      expiresAt: this.getFormExpiryDate(),
      entity: this.note.entity,
      entityId: this.note.entityId,
      tags: this.getFormRawTags()
    };
    this.store.dispatch(updateNote({ note, dialogRefId: this.dialogRef.id }));
  }

  handleEncryptedFields(): void {
    if (this.piiAccess.isMasked()) {
      this.encryptedFields['note'].forEach(field => this.noteForm.get(field)?.disable());
    }
  }
}
