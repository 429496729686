import { SortDirection } from '@angular/material/sort';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { LogicRule, LogicRuleConnector, LogicRulesData } from '@shared/types/logic-rule.type';

export const segmentStatusDisplayClass = {
  active: 'status-active',
  deactivated: 'status-neutral'
};

export type SegmentStatus = 'active' | 'deactivated' | 'deleted';

export const updateFrequencies = {
  one_time: 'OneTimeUpdate',
  rolling: 'RollingUpdate'
} as const;
export type SegmentUpdateFrequency = keyof typeof updateFrequencies;
export type SegmentRulesData = LogicRulesData<'and'>;

export interface SegmentsResponse {
  data: Segment[];
  metadata: {
    total: number;
  };
}

export interface SegmentsFilter {
  status: SegmentStatus;
  page: number;
  limit: number;
  sortBy: string;
  sortDirection: Exclude<SortDirection, ''>;
  name: string | null;
  id: string | null;
  description: string | null;
}

export interface Segment {
  id: string;
  name: string;
  status: SegmentStatus;
  description: string | null;
  activeMemberCount: number;
  removedMemberCount: number;
  updateFrequency: SegmentUpdateFrequency;
  createdAt: string;
  updatedAt: string;
  initializeAt: string | null;
  lastRefreshedAt: string | null;
  isMembersPopulated: boolean;
  rules: SegmentRulesData | Record<string, never>;
}

export interface SegmentCreateRequest extends Pick<Segment, 'name' | 'description' | 'updateFrequency'> {
  rules: LogicRule[];
}

export interface RecommendedSegmentCreateRequest extends Pick<Segment, 'name' | 'description' | 'updateFrequency'> {
  rules: {
    connector: LogicRuleConnector;
    conditions: LogicRule[];
  };
}

export interface SegmentEditRequest {
  id: string;
  name?: string;
  description?: string;
  status?: Exclude<SegmentStatus, 'deleted'>;
}

export interface SegmentActivationRequest {
  id: string;
  status: Extract<SegmentStatus, 'active' | 'deactivated'>;
}

export type OriginPage = 'listing-page' | 'details-page';

export const defaultSegmentsFilter: SegmentsFilter = {
  status: 'active',
  page: 1,
  limit: 20,
  sortBy: 'createdAt',
  sortDirection: 'desc',
  name: '',
  id: '',
  description: ''
};

export const segmentAdapter = createEntityAdapter<Segment>();

export interface SegmentState extends EntityState<Segment>, CallState {
  total: number;
  filter: SegmentsFilter;
}

export const initialState: SegmentState = {
  ...segmentAdapter.getInitialState<CallState>(initialCallState),
  total: 0,
  filter: defaultSegmentsFilter
};
