import { CookieUtils } from './cookie_utils';
import { SessionStorageUtils } from './session_storage_utils';
var StorageManager = /** @class */ (function () {
    function StorageManager() {
        this.storages = [new SessionStorageUtils(), new CookieUtils()];
    }
    StorageManager.prototype.get = function (key) {
        return this.storages.reduce(function (result, storage) { return result || storage.get(key); }, null);
    };
    StorageManager.prototype.getAllKeys = function () {
        var keys = this.storages.flatMap(function (storage) { return storage.getAllKeys(); });
        return Array.from(new Set(keys));
    };
    StorageManager.prototype.save = function (key, value) {
        this.storages.forEach(function (storage) { return storage.save(key, value); });
    };
    StorageManager.prototype.remove = function (key) {
        this.storages.forEach(function (storage) { return storage.remove(key); });
    };
    return StorageManager;
}());
export { StorageManager };
