import { AfterViewInit, Component, DestroyRef, inject, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import { AttributeUtils } from '@core/services/attribute-utils/attribute-utils.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AttributeDef, BooleanPolicySettings, SCOPES_OR } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { AccessPolicyUtils, DomUtils, StickyColumnConfig, StickyTableUtils } from '@utils';

import { deleteIdentity } from '../../store/actions/identities.actions';
import { identitiesQuery } from '../../store/selectors/identities.selectors';
import { usersQuery } from '../../store/selectors/users.selectors';
import { Identity, IdentityColumn, IdentityState, User, UserState } from '../../types';
import { CreateIdentityDialogV2Component } from '../create-identity-dialog-v2/create-identity-dialog-v2.component';

@Component({
  selector: 'admin-identities',
  templateUrl: './identities.component.html',
  styleUrls: ['../../views/user/user.component.scss']
})
export class IdentitiesComponent implements OnInit, AfterViewInit {
  @Input() user: User;

  destroyRef = inject(DestroyRef);

  userIdentity$: Observable<Identity[]>;
  displayedColumns: string[] = [];
  columnConfig: Record<string, StickyColumnConfig> = {};
  tableUtil: StickyTableUtils;

  readonly SCOPES = SCOPES_OR;

  private readonly identityPrefixColumnDefs: AttributeDef<IdentityColumn>[] = [{ key: 'tenantId' }];

  private readonly identityScrollableColumnDefs: AttributeDef<IdentityColumn>[] = [
    { key: 'uid' },
    { key: 'providerId' },
    { key: 'createdAt' },
    { key: 'updatedAt' }
  ];

  private readonly identitySuffixColumnDefs: AttributeDef<IdentityColumn>[] = [{ key: 'actions' }];

  constructor(
    public attributeUtils: AttributeUtils,
    private accessPolicies: AccessPolicies,
    private matDialog: MatDialog,
    private scopes: Scopes,
    private store: Store<UserState>,
    private identityStore: Store<IdentityState>,
    @Inject('timezone') public timezone: string
  ) {}

  ngOnInit(): void {
    this.identityStore
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        select(identitiesQuery.isIdentitiesLoaded(this.user?.id)),
        tap(isLoaded => {
          this.userIdentity$ = isLoaded
            ? this.identityStore.select(identitiesQuery.getIdentitiesList)
            : this.store.select(usersQuery.getUserPropertyById<Identity[]>(this.user.id, ['identities']));
        })
      )
      .subscribe();

    this.handleColumnDisplay();
  }

  ngAfterViewInit(): void {
    DomUtils.preventScrollToOtherPage('.sticky-table-wrapper', takeUntilDestroyed(this.destroyRef));
    DomUtils.dragAndScroll('.sticky-table-wrapper', takeUntilDestroyed(this.destroyRef));
  }

  handleColumnDisplay(): void {
    const settings = this.accessPolicies.getResponseSettings('users', 'show');
    const usersShowIdentitySettings = settings?.identities as BooleanPolicySettings;
    const identityIndexSettings = this.accessPolicies.getResponseSettings(
      'identities',
      'index'
    ) as BooleanPolicySettings;
    const accessPoliciesCheck = AccessPolicyUtils.mergeBooleanPolicySettings(
      identityIndexSettings,
      usersShowIdentitySettings
    );

    const [[], prefixColumns] = this.attributeUtils.filterColumns(this.identityPrefixColumnDefs, accessPoliciesCheck);
    const [[], scrollableColumns] = this.attributeUtils.filterColumns(
      this.identityScrollableColumnDefs,
      accessPoliciesCheck
    );
    let [[], suffixColumns] = this.attributeUtils.filterColumns(this.identitySuffixColumnDefs, accessPoliciesCheck);
    suffixColumns = this.scopes.hasAny(this.SCOPES.deleteIdentities) ? suffixColumns : [];

    this.tableUtil = new StickyTableUtils(prefixColumns, scrollableColumns, suffixColumns);
    this.displayedColumns = this.tableUtil.getDisplayedColumns();
    this.columnConfig = this.tableUtil.getStickyColumnConfig();
  }

  openCreateIdentityDialog(user: User): void {
    this.matDialog.open(CreateIdentityDialogV2Component, {
      data: { user }
    });
  }

  openDeleteIdentityDialog(user: User, reference: string): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          dialogTitle: 'Delete identity',
          confirmText: 'Are you sure you want to delete this identity?',
          confirmButtonText: 'Yes, delete identity',
          styleClassName: 'confirm-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(deleteIdentity({ reference, user }));
        }
      });
  }
}
