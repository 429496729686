<div class="note-form">
  <h3>{{ isEditMode ? 'Edit' : 'Add' }} note</h3>
  <mat-hint>Timezone: {{ timezone }}</mat-hint>

  <form class="form-container" [formGroup]="noteForm" #noteFormDirective="ngForm">
    <div class="note-row">
      <mat-form-field class="textarea-note">
        <mat-label>Note</mat-label>
        <textarea matInput formControlName="note"></textarea>
      </mat-form-field>
    </div>

    <div fxLayout="start center" fxLayoutGap="11px" class="slim-form-field">
      <mat-form-field *ngIf="showType">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" disableOptionCentering panelClass="dropdown-panel">
          <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="expiry-form-field">
        <mat-label>Expire at</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="expiresAt"
          (focus)="picker.open()"
          [min]="minExpiryDate"
        />
        <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>Optional</mat-hint>
      </mat-form-field>

      <admin-input-tags [tags]="tags" dialogTitle="Tag note" submitButtonText="Add tag to note"></admin-input-tags>
    </div>

    <div class="button-row" align="end" *ngIf="!isEditMode">
      <button
        mat-raised-button
        color="primary"
        class="uppercase-button"
        [disabled]="noteForm.invalid"
        (click)="addNote()"
      >
        <span class="uppercase-button__text">Add Note</span>
      </button>
    </div>

    <div mat-dialog-actions align="end" *ngIf="isEditMode">
      <button
        mat-raised-button
        color="secondary"
        class="uppercase-button text-primary cancel-button"
        (click)="dialogRef.close(false)"
      >
        <span class="uppercase-button__text">Cancel</span>
      </button>

      <button
        mat-raised-button
        color="primary"
        class="uppercase-button"
        [disabled]="noteForm.invalid"
        (click)="updateNote()"
      >
        <span class="uppercase-button__text">Update Note</span>
      </button>
    </div>
  </form>
</div>
