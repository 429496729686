import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import {
  EntityTriggers,
  nydusNetworkBootstrapInitialState,
  NydusNetworkBootstrapState,
  RedemptionType,
  RedemptionTypesEnum
} from '@core/types';

import {
  loadNydusNetworkBootstrap,
  loadNydusNetworkBootstrapFailure,
  loadNydusNetworkBootstrapSuccess
} from '../actions/nydus-network-bootstrap.actions';

export const nydusNetworkBootstrapTriggers: EntityTriggers = {
  single: {
    loading: [loadNydusNetworkBootstrap.type],
    resting: [loadNydusNetworkBootstrapSuccess.type],
    erroring: [loadNydusNetworkBootstrapFailure.type]
  }
};

export function nydusNetworkBootstrapReducer(
  state: NydusNetworkBootstrapState = nydusNetworkBootstrapInitialState,
  action: Action
): NydusNetworkBootstrapState {
  return callStateReducer(baseReducer, nydusNetworkBootstrapTriggers)(state, action);
}

const baseReducer = createReducer(
  nydusNetworkBootstrapInitialState,
  on(loadNydusNetworkBootstrapSuccess, (state, { nydusNetworkBootstrap }) => ({
    ...state,
    nydusNetworkBootstrap,
    redemptionTypes: Object.values(RedemptionTypesEnum).filter(
      (item: string) => nydusNetworkBootstrap.featureFlags[item]
    ) as RedemptionType[]
  }))
);
