import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { ImageValidationConfig } from '@shared/components/upload-images/types';
import { FILE_EXTENSION_TO_IMAGE_MEDIA_TYPE, IMAGE_UPLOAD_CONFIGS, ImageUtils } from '@utils';

export function imageUploadValidator(imageValidationConfig: ImageValidationConfig): AsyncValidatorFn {
  return (control: AbstractControl): Observable<Record<string, boolean> | null> => {
    if (!control.value) {
      return of(null);
    }

    const url = control.value;

    const allowedTypes = imageValidationConfig?.allowedTypes || IMAGE_UPLOAD_CONFIGS.allowedTypes;
    const fileSizeLimit = imageValidationConfig?.fileSizeLimit || IMAGE_UPLOAD_CONFIGS.fileSizeLimit;

    try {
      new URL(url); // check if it is a valid url

      const fileExtension = url.split('.').pop();
      const fileType = FILE_EXTENSION_TO_IMAGE_MEDIA_TYPE[fileExtension.toUpperCase()];
      const file = new File([new Blob([url])], url, { type: fileType });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (ImageUtils.isFileTypeInvalid(allowedTypes, file)) {
          control.setErrors({ invalidImageFileType: true });
          return of({ invalidImageFileType: true });
        }

        if (ImageUtils.isFileSizeInvalid(fileSizeLimit, file)) {
          control.setErrors({ invalidImageFileSize: true });
          return of({ invalidImageFileSize: true });
        }

        const image = new Image();
        image.src = url;
        if (ImageUtils.areDimensionsInvalid(image, imageValidationConfig)) {
          control.setErrors({ invalidImageDimensions: true });
          return of({ invalidImageDimensions: true });
        }
      };

      return of(null);
    } catch (error) {
      return of({ invalidImageUrl: true });
    }
  };
}
