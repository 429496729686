import { initialCallState } from '../store/call-state';
import { CallState } from './call-state.type';

export const relationshipKeys = [
  'currencies',
  'locales',
  'loyaltyPrograms',
  'loyaltyCurrencies',
  'transferRates',
  'redemptionRates',
  'rewardsPrograms'
] as const;
type RelationshipKeyType = (typeof relationshipKeys)[number];
type PointsPercentageKeyType = 'giftCard' | 'merchandise';

export interface RawNydusNetworkBootstrap {
  data: {
    id: string;
    type: string;
    attributes: {
      autoRedemptionRules: {
        attributes: { productSku: string; enabled: boolean; strategies: [] };
        errors: object;
      }[];
      customerSegments: string[];
      gtmContainerId: string;
      redemptionSetting: { attributes: { step: number }; error: object };
      roundingPreferences: { [key: string]: { direction: string; nearest: number } };
      minPointsPercentage: { [key in PointsPercentageKeyType]: number };
      maxPointsPercentage: { [key in PointsPercentageKeyType]: number };
      outgoingRequestEventNames: string[];
      timeZoneOffset: string;
      timeZoneOffsetInMinutes: number;
      paymentProcessingFee: { fixed: number; percentage: number };
      featureFlags: FeatureFlags;
      manualAdjustmentOptions: { attributes: Attributes; errors: object }[];
      relationships: { [key in RelationshipKeyType]: { data: { id: string; type: string }[] } };
    };
  };
  included: { id: string; type: string; attributes: object; relationships?: object }[];
}

export interface NydusNetworkBootstrap {
  currencies: Currency[];
  customerSegments: string[];
  defaultLocale: string;
  featureFlags: FeatureFlags;
  gtmContainerId: string;
  locales: Locale[];
  loyaltyCurrencies: LoyaltyCurrency[];
  loyaltyPrograms: LoyaltyProgram[];
  manualAdjustmentOptions: ManualAdjustmentOption[];
  outgoingRequestEventNames: string[];
  redemptionRates: RedemptionRate[];
  redemptionSetting: RedemptionSetting;
  timeZoneOffset: string;
  timeZoneOffsetInMinutes: number;
}

// benefit, purchaseEraser, travelBooking are not available
export enum RedemptionTypesEnum {
  cashRedemption,
  giftCards,
  pointsTransfer
}

export type RedemptionType = keyof typeof RedemptionTypesEnum;

interface RedemptionRate {
  id: string;
  cashCurrency: string;
  costPerPoint: number;
  productType: string;
  pricingRule: {
    conditions: Array<any>;
    priority: number;
  };
}

interface RedemptionSetting {
  step: number;
  errors: object;
}

export interface LoyaltyProgram {
  name: string;
  shortName: string;
  processingDelay: number;
  category: string;
  description: string;
  detailsUrl: string;
  enrollmentUrl: string;
  logoUrl: string;
  conditionsUrl: string;
  nameRequired: boolean;
  transferConnectIdentifier: string;
  active: boolean;
  manualVerificationRequired: boolean;
  autosweepable: boolean;
  id: string;
  translations?: Translation & { locale: string }[];
  translationsV2?: {
    [key: string]: Translation;
  };
}

export interface LoyaltyCurrency {
  name: string;
  shortName: string;
  id: string;
  translations?: { [key: string]: Translation };
}

interface Translation {
  name: string;
  shortName: string;
  partnerName: string;
  partnerShortName: string;
  description: string;
}

export interface Locale {
  code: string;
  name: string;
  dateFormat: string;
  timeFormat: string;
  id: string;
}

export interface Currency {
  code: string;
  symbol: string;
  exchangeRate: number;
  id: string;
}

interface FeatureFlags {
  cashPayments: boolean;
  giftCards: boolean;
  merchandises: boolean;
  loyaltyEngine: boolean;
  pointsBank: boolean;
  twilioIvr: boolean;
  reverseAccountLookup: boolean;
  autoRedemption: boolean;
  reward: boolean;
  benefit: boolean;
  cashRedemption: boolean;
  pointsTransfer: boolean;
  purchaseEraser: boolean;
  travelBooking: boolean;
}

export interface ManualAdjustmentOption extends Attributes {
  errors: object;
}

export interface Attributes {
  reason: Reason;
  categories: string[];
  customExpiry: boolean;
  metadata: Metadata[];
  defaultExpiry: string;
  defaultExpiryRule: string;
}

export enum Reason {
  Custom = 'custom',
  Goodwill = 'goodwill',
  PointsShare = 'points_share',
  ExpiryReinstatement = 'expiry_reinstatement',
  WriteoffReinstatement = 'writeoff_reinstatement',
  BonusPointsAdjustment = 'bonus_points_adjustment'
}

interface Metadata {
  key: string;
  values?: string[];
  type: string;
  required: boolean;
}

export interface NydusNetworkBootstrapState extends CallState {
  nydusNetworkBootstrap: NydusNetworkBootstrap;
  redemptionTypes: RedemptionType[];
}

export const nydusNetworkBootstrapInitialState: NydusNetworkBootstrapState = {
  ...initialCallState,
  nydusNetworkBootstrap: null,
  redemptionTypes: null
};
