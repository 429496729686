import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import {
  BaseRedemptionRate,
  MissionControlRedemptionRateResult,
  NewRedemptionRate,
  RedemptionRate,
  RedemptionRateResult,
  RedemptionRatesFilter
} from '../types';

@Injectable({
  providedIn: 'root'
})
export class RedemptionRatesService {
  constructor(private missionControlService: MissionControlService) {}

  getRedemptionRate(id: string): Observable<RedemptionRate> {
    return this.missionControlService
      .get<BaseRedemptionRate>(`redemption_rates/${id}`)
      .pipe(map(this.mapRedemptionRateToAdminPanelFormat));
  }

  getRedemptionRates(filter: RedemptionRatesFilter): Observable<RedemptionRateResult> {
    filter = ObjectUtils.sanitizeRequestObject<RedemptionRatesFilter>(filter);
    const { sortBy, ...restFilterFields } = filter;

    let params = ObjectUtils.prepareQueryObject(restFilterFields);
    params = FilterUtils.appendHashQueryParam(params, 'sortBy', 'priority', sortBy?.priority);

    return this.missionControlService
      .get<MissionControlRedemptionRateResult>(
        'redemption_rates',
        new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
      )
      .pipe(
        map(result => ({
          ...result,
          data: result.data.map(this.mapRedemptionRateToAdminPanelFormat)
        }))
      );
  }

  createRedemptionRate(redemptionRate: NewRedemptionRate): Observable<RedemptionRate> {
    return this.missionControlService
      .post<BaseRedemptionRate>('redemption_rates', ObjectUtils.sanitizeRequestObject(redemptionRate))
      .pipe(map(this.mapRedemptionRateToAdminPanelFormat));
  }

  updateRedemptionRate(redemptionRate: RedemptionRate): Observable<RedemptionRate> {
    return this.missionControlService
      .patch<BaseRedemptionRate>(`redemption_rates/${redemptionRate.id}`, redemptionRate)
      .pipe(map(this.mapRedemptionRateToAdminPanelFormat));
  }

  deleteRedemptionRate(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`redemption_rates/${id}`);
  }

  private findCondition = (redemptionRate, attribute, predicate): string =>
    redemptionRate.pricingRule.conditions.find(
      condition => condition.attribute === attribute && condition.predicate === predicate
    )?.value ?? null;

  private mapRedemptionRateToAdminPanelFormat = (redemptionRate): RedemptionRate => {
    // when display_value has $eq as predicate, we want to use the value as minPrice & maxPrice
    const price = this.findCondition(redemptionRate, 'display_value', '$eq');

    return {
      ...redemptionRate,
      type: this.findCondition(redemptionRate, 'display_type', '$eq') ?? '',
      brand: this.findCondition(redemptionRate, 'name', '$eq'),
      minPrice: price || this.findCondition(redemptionRate, 'display_value', '$gte'),
      maxPrice: price || this.findCondition(redemptionRate, 'display_value', '$lte')
    };
  };
}
