import { AbstractControl, ValidatorFn } from '@angular/forms';

type UuidValidatorOption = 'non-UUIDv4';

export function uuidValidator(option?: UuidValidatorOption): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    let isUuid: boolean;

    if (option === 'non-UUIDv4') {
      // This is a non RFC-compliant UUID. We allow it because the UUID generated by DevOps is not a valid UUIDv4.
      isUuid = control.value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);
    } else {
      // UUIDv4
      isUuid = control.value.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
    }

    return isUuid ? null : { invalidUuid: true };
  };
}
