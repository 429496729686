import { createReducer, on } from '@ngrx/store';

import { initialSegmentCreationState } from '../../types/segment-creation.type';
import {
  loadSegmentAttributes,
  loadSegmentAttributesFailure,
  loadSegmentAttributesSuccess,
  loadSegmentPreview,
  loadSegmentPreviewFailure,
  loadSegmentPreviewSuccess,
  resetSegmentPreview
} from '../actions/segment-creation.actions';

export const segmentCreationReducer = createReducer(
  initialSegmentCreationState,
  on(loadSegmentAttributes, state => ({
    ...state,
    attributes: { ...initialSegmentCreationState.attributes, state: 'loading' as const }
  })),
  on(loadSegmentAttributesSuccess, (state, { attributeMap }) => ({
    ...state,
    attributes: {
      attributeMap,
      state: 'loaded' as const,
      error: null
    }
  })),
  on(loadSegmentAttributesFailure, (state, { error }) => ({
    ...state,
    attributes: {
      ...state.attributes,
      state: 'error' as const,
      error
    }
  })),
  on(loadSegmentPreview, state => ({
    ...state,
    preview: {
      ...initialSegmentCreationState.preview,
      state: 'loading' as const
    }
  })),
  on(loadSegmentPreviewSuccess, (state, { memberCount }) => ({
    ...state,
    preview: {
      memberCount,
      state: 'loaded' as const,
      error: null
    }
  })),
  on(loadSegmentPreviewFailure, (state, { error }) => ({
    ...state,
    preview: {
      ...state.preview,
      state: 'error' as const,
      error
    }
  })),
  on(resetSegmentPreview, state => ({
    ...state,
    preview: initialSegmentCreationState.preview
  }))
);
