import { defaultNavItems } from '@core/types';
import { TooltipKeysUtils } from '@utils';

import { detailDefs, moreDetailsDefs, preferencesDefs } from '../../users/types/user-details-defs.type';

export type TooltipModuleKey =
  | 'enrollments'
  | 'events'
  | 'notes'
  | 'orders-items'
  | 'password-policies'
  | 'sidenav'
  | 'users';

export const tooltipKeys = [
  /* enrollments */
  'enrollments.index.button.activateNewUser',
  'enrollments.index.filter.accountType',
  'enrollments.index.filter.customerId',
  'enrollments.index.filter.pan',
  'enrollments.index.filter.parentId',
  'enrollments.index.filter.rewardId',
  'enrollments.index.filter.status',
  'enrollments.index.tableHeader.cardNumber',
  'enrollments.index.tableHeader.customerId',
  'enrollments.index.tableHeader.parentId',
  'enrollments.index.tableHeader.rewardId',
  'enrollments.index.tableHeader.name',
  'enrollments.index.tableHeader.dateOfBirth',
  'enrollments.index.tableHeader.status',
  'enrollments.index.tableHeader.updatedAt',
  'enrollments.index.tableHeader.businessRank',
  'enrollments.index.tableHeader.businessLevel',
  'enrollments.index.tableHeader.action',
  /* events */
  'events.index.tableHeader.deviceInfo',
  'events.index.tableHeader.app',
  'events.index.tableHeader.action',
  'events.index.tableHeader.logType',
  'events.index.tableHeader.message',
  'events.index.tableHeader.tags',
  'events.index.table.action?Api::Controllers::Enrollments::Create',
  'events.index.table.action?Api::Controllers::Enrollments::Create::Success',
  'events.index.table.action?Web::Controllers::Enrollments::Create',
  'events.index.table.action?Web::Controllers::Enrollments::Create::Success',
  'events.index.table.action?Web::Controllers::Enrollments::Create::Failed',
  'events.index.table.action?Web::Controllers::PasswordResets::Create::Success',
  'events.index.table.action?Web::Controllers::PasswordResets::Update',
  'events.index.table.action?Web::Controllers::PasswordResets::Validate',
  'events.index.table.action?Web::Controllers::Passwords::Update::Success',
  'events.index.table.action?Web::Controllers::Passwords::Update::Failed',
  'events.index.table.action?Web::Controllers::Token::Exchange::Success',
  /* notes */
  'notes.index.table.action.button.edit?expired=true',
  'notes.index.table.action.button.edit?expired=false',
  'notes.index.table.action.button.delete',
  /* orders-items */
  'orders-items.details.header.orderItemId',
  'orders-items.details.giftCardOrderItem.externalSupplierOrderReference',
  'orders-items.details.orderItem.refundOrder?allowed=false',
  /* password-policies */
  'password-policies.button.create?allowed=false',
  /* sidenav */
  ...TooltipKeysUtils.getNavItemKeys(defaultNavItems),
  /* users */
  'users.index.button.activateNewUser',
  'users.index.button.downloadAgents',
  'users.index.filter.ascendaId',
  'users.index.filter.customerId',
  'users.index.filter.pan',
  'users.index.filter.pointsAccount',
  'users.index.filter.userState',
  'users.index.filter.tenantStatus',
  'users.index.filter.loginMode',
  'users.index.filter.activationStatus',
  'users.header.customerId',
  'users.header.button.sign-in-as-user?allowed=false',
  'users.menu.dashboard',
  'users.menu.enrollments',
  'users.memberships.loyaltyProgramme.title',
  'users.passwords.tableHeader.expiringReminderCount',
  'users.passwords.tableHeader.reminderCount',
  'users.edit.button.editEmail?isEmailEditable=true',
  'users.details.button.blockUser?userIsBlocked=true',
  'users.details.button.blockUser?userIsBlocked=false',
  'users.details.button.blockUserLogin?userLogin=true',
  'users.details.button.blockUserLogin?userLogin=false',
  'users.details.button.deleteIdentity',
  'users.details.button.deleteMfaIdentity',
  'users.details.button.resetAscendaLogin',
  'users.details.button.sendResetPassword',
  'users.details.button.sendResetPassword?enabled=false',
  'users.details.button.unenrollUser?enabled=true',
  'users.details.button.unenrollUser?enabled=false',
  ...TooltipKeysUtils.getUserDetailsKeys([detailDefs, moreDetailsDefs, preferencesDefs]),
  'users.index.tableHeader.identityId',
  'users.index.tableHeader.customerId',
  'users.index.tableHeader.name',
  'users.index.tableHeader.ascendaId',
  'users.index.tableHeader.userState',
  'users.index.tableHeader.roles',
  'users.index.tableHeader.createdAt',
  'users.index.tableHeader.updatedAt',
  'users.index.tableHeader.action'
] as const;

/*
  convert keys in tooltipKeys array to type
  ref: https://stackoverflow.com/a/54061487/2506617
*/
export type TooltipKey = (typeof tooltipKeys)[number];
