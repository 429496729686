import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';
import { CountryUtils } from '@utils';

export type OperationType = 'fromLocaleToAlpha2CountryCode' | 'fromLocaleToEmoji';

@Pipe({
  name: 'i18n'
})
export class I18nPipe implements PipeTransform {
  transform(value: string, operationType: OperationType): Nullable<string> {
    if (!value || !operationType) {
      return null;
    }

    if (operationType === 'fromLocaleToAlpha2CountryCode') {
      return new Intl.Locale(value).region ?? null;
    }

    if (operationType === 'fromLocaleToEmoji') {
      return CountryUtils.isoCountryCodeToFlagEmoji(new Intl.Locale(value).region!);
    }

    return null;
  }
}
