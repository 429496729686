export const hosts = {
  anz: ['anz-ap-dev', 'anz-admin-panel'],
  'anz-biz': ['anz-biz-ap-dev', 'anz-biz-admin-panel'],
  ascenda: ['ascenda-ap-dev', 'e2e-admin-panel'],
  bnz: ['bnz-ap-dev', 'bnz-admin-panel'],
  bom: ['bom-ap-dev', 'bom-admin-panel'],
  bsa: ['bsa-ap-dev', 'bsa-admin-panel'],
  fab: ['fab-ap-dev', 'fab-admin-panel'],
  glb: ['glb-ap-dev', 'glb-admin-panel'],
  hyundai: ['hyundai-ap-dev', 'hyundai-admin-panel'],
  nexus: ['nexus-ap-dev', 'nexus-admin-panel'],
  'nexus-v4': ['nexus-v4-ap-dev', 'nexus-v4-admin-panel'],
  nexustc: ['nexustc-ap-dev', 'nexustc-admin-panel'],
  transfercentral: ['transfercentral-admin-panel'],
  rc: ['rc-ap-dev', 'abcrewards-ap-dev', 'admin.us'],
  star: ['star-ap-dev', 'star-admin-panel'],
  stg: ['stg-ap-dev', 'stg-admin-panel'],
  vma: ['vma-ap-dev', 'vma-admin-panel']
};
