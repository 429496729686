import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { BootstrapState, initialState } from '../../types/bootstraps.type';
import {
  clearBootstrap,
  loadBootstrap,
  loadBootstrapFailure,
  loadBootstrapSuccess
} from '../actions/bootstraps.actions';

export const bootstrapTriggers: EntityTriggers = {
  single: {
    loading: [loadBootstrap.type],
    resting: [loadBootstrapSuccess.type],
    erroring: [loadBootstrapFailure.type]
  }
};

export function bootstrapsReducer(state: BootstrapState = initialState, action: Action): BootstrapState {
  return callStateReducer(baseReducer, bootstrapTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadBootstrapSuccess, (state, { bootstrap }) => ({ ...state, ...bootstrap })),
  on(clearBootstrap, () => initialState)
);
