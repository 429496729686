<!-- show header in SideNavComponent when user is logged in -->
@if (isLoggedIn$ | async; as isLoggedIn) {
  @if (isRC && ((isSidenavV2Enabled$ | async) ?? false)) {
    <admin-side-nav-v2 />
  } @else {
    <admin-side-nav [user]="user$ | async" [isLoggedIn]="isLoggedIn" />
  }
} @else {
  <!-- show header here when user is not logged in -->
  <admin-login-page />
}
