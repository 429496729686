<admin-full-page-overlay [backButtonText]="'Back to ' + data.currentPage">
  <div class="main-container details-view !mt-8 !px-[104px]">
    <h1>Set up your {{ data.recommendation.type === 'segment' ? 'segment' : 'campaign' }}</h1>

    <div class="mt-10 flex items-start gap-10">
      @if (data.recommendation.type === 'segment') {
        @if (segmentAttributeRenderState() === 'loaded') {
          <admin-segment-create-review class="w-full" [rules]="rules" [data]="segmentBasicInfo" />
        } @else {
          <admin-spinner [static]="true" />
        }
      }

      <admin-recommendation-review-card [recommendation]="data.recommendation"></admin-recommendation-review-card>
    </div>
  </div>
</admin-full-page-overlay>
