import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { initialState, merchandiseAdapter, MerchandiseState } from '../../types/merchandises.type';
import {
  fetchMerchandise,
  fetchMerchandiseFailure,
  fetchMerchandiseSuccess,
  loadMerchandise,
  loadMerchandiseFailure,
  loadMerchandises,
  loadMerchandisesFailure,
  loadMerchandisesSuccess,
  loadMerchandiseSuccess,
  updateMerchandise,
  updateMerchandiseFailure,
  updateMerchandiseSuccess
} from '../actions/merchandises.actions';

export const merchandiseTriggers: EntityTriggers = {
  single: {
    loading: [loadMerchandise.type, updateMerchandise.type],
    resting: [loadMerchandiseSuccess.type, updateMerchandiseSuccess.type],
    erroring: [loadMerchandiseFailure.type, updateMerchandiseFailure.type]
  },
  batch: {
    loading: [loadMerchandises.type],
    resting: [loadMerchandisesSuccess.type],
    erroring: [loadMerchandisesFailure.type]
  }
};

export const merchandiseFetchListTriggers: FetchListTriggers = {
  loading: [fetchMerchandise.type],
  resting: [fetchMerchandiseSuccess.type],
  erroring: [fetchMerchandiseFailure.type]
};

export function merchandisesReducer(state: MerchandiseState = initialState, action: Action): MerchandiseState {
  return {
    ...callStateReducer(baseReducer, merchandiseTriggers)(state, action),
    ...fetchStateReducer(merchandiseFetchListTriggers, 'merchandise')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(loadMerchandises, (state, { filter }) => ({ ...state, filter })),
  on(fetchMerchandiseSuccess, (state, { merchandise }) => merchandiseAdapter.upsertOne(merchandise, state)),
  on(loadMerchandiseSuccess, (state, { merchandise }) => ({
    ...merchandiseAdapter.setAll([merchandise], state),
    filter: {
      id: merchandise.id,
      ...state.filter
    },
    count: 1
  })),
  on(loadMerchandisesSuccess, (state, { merchandises }) => ({
    ...merchandiseAdapter.setAll(merchandises.data, state),
    count: merchandises?.metadata?.total || 0
  })),
  on(updateMerchandiseSuccess, (state, { merchandise }) =>
    merchandiseAdapter.updateOne({ id: merchandise.id, changes: merchandise }, state)
  )
);
