import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, inject, Injector, makeEnvironmentProviders, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { UNLEASH_CONFIG, UnleashService } from '@karelics/angular-unleash-proxy-client';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { first, tap } from 'rxjs';

import { CoreModule } from '@core/core.module';
import { CustomReuseStrategy } from '@shared/route/custom-reuse-strategy';
import { SharedModule } from '@shared/shared.module';
import { UNLEASH_URL, PRODUCTION_UNLEASH_KEY, STAGING_UNLEASH_KEY } from '@utils';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuditLogsModule } from './modules/audit-logs/audit-logs.module';
import { externalModules } from './modules/external/dev-external.module';
import { AmplitudeService } from './services/amplitude/amplitude.service';
import { ADMIN_ERROR_HANDLERS, AdminErrorHandler } from './services/error-handler/admin-error-handler.service';
import { RollbarErrorHandler } from './services/rollbar/rollbar-error-handler';
import { rollbarFactory } from './services/rollbar/rollbar.factory';
import { RollbarService } from './services/rollbar/rollbar.service';
import { getSentryLogger } from './services/sentry/sentry.factory';
import { SENTRY_LOGGER } from './tokens/sentry.token';

@NgModule({
  declarations: [AppComponent],
  exports: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }
    ),
    AuditLogsModule,
    externalModules
  ],
  providers: [
    { provide: RollbarService, useFactory: rollbarFactory, deps: ['production', 'env'] },
    { provide: SENTRY_LOGGER, useFactory: getSentryLogger, deps: ['env'] },
    // Provide RollbarErrorHandler and SentryErrorHandler as ADMIN_ERROR_HANDLERS
    // so that we can inject them all in AdminErrorHandler with ADMIN_ERROR_HANDLERS
    // and call their handleError methods
    {
      provide: ADMIN_ERROR_HANDLERS,
      useClass: RollbarErrorHandler,
      multi: true
    },
    {
      provide: ADMIN_ERROR_HANDLERS,
      useValue: Sentry.createErrorHandler(),
      multi: true
    },
    { provide: ErrorHandler, useClass: AdminErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    AmplitudeService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 4000 } },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    provideLottieOptions({ player: () => player }),
    provideHttpClient(withInterceptorsFromDi()),
    makeEnvironmentProviders([
      {
        provide: UNLEASH_CONFIG,
        useFactory: (tenantId: string, env: string) => ({
          url: UNLEASH_URL,
          clientKey: env === 'production' ? PRODUCTION_UNLEASH_KEY : STAGING_UNLEASH_KEY,
          appName: 'admin-panel',
          refreshInterval: 60,
          environment: env === 'production' ? 'production' : env === 'uat' ? 'uat' : 'staging',
          context: {
            properties: {
              tenant_id: tenantId
            }
          }
        }),
        deps: ['tenantId', 'env']
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => {
          const unleashService = inject(UnleashService);
          return () =>
            unleashService.initialized$.pipe(
              first(),
              tap(() => unleashService.unleash.start())
            );
        },
        multi: true
      }
    ])
  ]
})
export class AppModule {
  static injector: Injector;

  // Expose injector in AppModule so that Dependency Injection works in decorators. Reference: https://stackoverflow.com/a/48634548
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
