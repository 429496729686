import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { AuthenticationProvider } from '../../types/authentication-providers.type';

export const loadAuthenticationProviders = createAction('[Authentication Providers] Load Authentication Providers');
export const loadAuthenticationProvidersSuccess = createAction(
  '[Authentication Providers] Load Authentication Providers Success',
  props<{ authenticationProviders: AuthenticationProvider[] }>()
);
export const loadAuthenticationProvidersFailure = createAction(
  '[Authentication Providers] Load Authentication Providers Failure',
  props<ErrorState>()
);

export const loadAuthenticationProvider = createAction(
  '[Authentication Providers] Load Authentication Provider',
  props<{ reference: string }>()
);
export const loadAuthenticationProviderSuccess = createAction(
  '[Authentication Providers] Load Authentication Provider Success',
  props<{ authenticationProvider: AuthenticationProvider }>()
);
export const loadAuthenticationProviderFailure = createAction(
  '[Authentication Providers] Load Authentication Provider Failure',
  props<ErrorState>()
);

export const fetchAuthenticationProvider = createAction(
  '[Authentication Providers] Fetch Authentication Provider',
  props<{ reference: string }>()
);
export const fetchAuthenticationProviderFailure = createAction(
  '[Authentication Providers] Fetch Authentication Provider Failure',
  props<{ reference: string }>()
);

export const createAuthenticationProvider = createAction(
  '[Authentication Providers] Create Authentication Provider',
  props<{ authenticationProvider: AuthenticationProvider }>()
);
export const createAuthenticationProviderSuccess = createAction(
  '[Authentication Providers] Create Authentication Provider Success',
  props<{ authenticationProvider: AuthenticationProvider }>()
);
export const createAuthenticationProviderFailure = createAction(
  '[Authentication Providers] Create Authentication Provider Failure',
  props<ErrorState>()
);

export const updateAuthenticationProvider = createAction(
  '[Authentication Providers] Update Authentication Provider',
  props<{ authenticationProvider: AuthenticationProvider }>()
);
export const updateAuthenticationProviderSuccess = createAction(
  '[Authentication Providers] Update Authentication Provider Success',
  props<{ authenticationProvider: AuthenticationProvider }>()
);
export const updateAuthenticationProviderFailure = createAction(
  '[Authentication Providers] Update Authentication Provider Failure',
  props<ErrorState>()
);
