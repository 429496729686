import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState, HttpErrorDetails } from '@core/types';

import { Scope } from './scopes.type';

export interface AppConnection {
  id: string;
  clientId: string;
  resourceId: string;
  name: string;
  signingAlgorithm?: string;
  signingPublicKey?: string;
  scopes: Scope[];
  expirationTime?: number;
  configurationReset?: CerberusRefreshResponse;
  refreshToken?: CerberusRefreshResponse;
}

interface CerberusRefreshResponse {
  success: boolean;
  errors: HttpErrorDetails[];
}

export const appConnectionAdapter = createEntityAdapter<AppConnection>();

export interface AppConnectionState extends EntityState<AppConnection>, CallState, FetchState {}

export const initialState: AppConnectionState = {
  ...appConnectionAdapter.getInitialState<CallState>(initialCallState),
  ...appConnectionAdapter.getInitialState<FetchState>(initialFetchState)
};
