import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { loadNotes } from '../store/actions/notes.actions';
import { NotesQuery } from '../store/selectors/notes.selectors';
import { NotesFilter } from '../types/notes-filter.type';
import { NoteState } from '../types/notes.type';

export const notesResolver: ResolveFn<NotesFilter> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<NoteState> = inject(Store<NoteState>),
  scopes: Scopes = inject(Scopes),
  notesQuery: NotesQuery = inject(NotesQuery)
): Observable<NotesFilter> => {
  if (scopes.lackScopes(SCOPES_OR.viewNotes)) {
    return of(null);
  }

  return combineLatest([store.select(notesQuery.isTopLevelView), store.select(notesQuery.getFilter)]).pipe(
    first(),
    map(([isTopLevelView, filter]) => {
      if (!isTopLevelView) {
        filter = new NotesFilter();
        store.dispatch(loadNotes({ filter, isTopLevelView: true }));
      }

      return filter;
    })
  );
};
