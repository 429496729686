import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { GiftCard } from '../../../gift-cards/types/gift-cards.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class GiftCardTagDefs {
  readonly tagDefs: TagDefs<GiftCard> = {
    gift_card_id: {
      getDisplayPrefix: () => 'Gift Card',
      getDisplayValue: (tag: Tag) => tag.id,
      getRouteLink: (tags: Tag[]) => {
        if (!this.scopes.hasAny(SCOPES_OR.showGiftCards)) {
          return null;
        }

        const giftCardTag = tags.find(tag => tag.type === 'gift_card_id');
        return giftCardTag ? `/gift-cards/cards/${giftCardTag.id}` : null;
      }
    }
  };

  constructor(private scopes: Scopes) {}
}
