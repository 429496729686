import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState, HttpErrorDetails } from '@core/types';

import { CampaignsFilter } from './campaigns-filter.type';

export interface Campaign {
  id: string;
  partnerReferenceCode: string;
  title: string;
  shortDescription: string;
  terms: string;
  deepLink: string;
  coverImageUrl: string;
  mainCategoryIconUrl: string;
  ctaButtonText: string;
  brand: string;
  displayType: DisplayTypeCodesValues;
  displayable: boolean;
  status?: string;
  ranking: number;
  logoUrl: string;
  startTime: string;
  endTime: string;
  eligibilityTrackings?: EligibilityTracking[];
  code: string;
  eligibleProductCodes?: string[];
  createdAt: string;
  metadata: object;
  remark: string;
  customFields: {
    customText1: string;
    customText2: string;
    customText3: string;
    customLink1: string;
    customLink2: string;
  };
}

interface EligibilityTracking {
  id: string;
  trackingType: string;
  trackingNumber: string;
  status: string;
  activationDate: string;
  qualifiedDate: string;
  fulfillments: Fulfillment[];
}

interface Fulfillment {
  userId: string;
  points: number;
  fulfillmentDate: string;
  pointsBankTransactionId: string;
  expiryRule: string;
  errors: HttpErrorDetails[];
}

export enum StatusTypeCodes {
  Active = 'active',
  Inactive = 'inactive'
}

export interface TimeAdjustmentPayload {
  dialogRefId: string;
  campaignDetails: {
    id: string;
    startTime?: string;
    endTime?: string;
  };
}

export enum DisplayTypeCodes {
  AllUsers = 'all_users',
  ActivatedUsers = 'activated_users'
}

type DisplayTypeCodesKeys = keyof typeof DisplayTypeCodes;
export type DisplayTypeCodesValues = (typeof DisplayTypeCodes)[DisplayTypeCodesKeys];

export const campaignAdapter = createEntityAdapter<Campaign>();

export interface CampaignState extends EntityState<Campaign>, CallState, FetchState {
  filter: CampaignsFilter;
  isGlobalCampaignsLoaded: boolean;
}

export const initialState: CampaignState = {
  ...campaignAdapter.getInitialState<CallState>(initialCallState),
  ...campaignAdapter.getInitialState<FetchState>(initialFetchState),
  filter: null,
  isGlobalCampaignsLoaded: false
};
