import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { createResolver } from '@shared/route/resolver-helper';
import { Nullable } from '@shared/types';

import { loadCustomerToken } from '../store/actions/analytics.actions';
import { analyticsQuery } from '../store/selectors/analytics.selectors';
import { AnalyticsState } from '../types/analytics.type';

export const tokenResolver: ResolveFn<Nullable<void>> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<Nullable<void>> =>
  createResolver<AnalyticsState>({
    query: analyticsQuery.isTokenLoaded,
    scope: 'view_analytics',
    action: loadCustomerToken()
  });
