import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';
import { Formatters } from '@utils';

type ConvertFn = 'fromCamelCase' | 'fromSnakeCase';

@Pipe({ name: 'customTitleCase' })
export class CustomTitleCasePipe implements PipeTransform {
  convertFns: { [key in ConvertFn]: (value: string) => string } = {
    fromCamelCase: Formatters.fromCamelToTitleCase,
    fromSnakeCase: Formatters.fromSnakeToTitleCase
  };

  transform(value: string, method: ConvertFn): Nullable<string> {
    if (!value) {
      return null;
    }

    const convertFn = this.convertFns[method];
    return convertFn(value);
  }
}
