import { WebhookEvent } from './webhook-events.type';

export interface WebhookEventsPagination {
  nextCursor?: string;
  prevCursor?: string;
}

export const defaultWebhookEventsPagination: WebhookEventsPagination = {
  nextCursor: '',
  prevCursor: ''
};
export interface WebhookEventsResult {
  data: WebhookEvent[];
  meta: {
    count: number;
  };
  pagination: WebhookEventsPagination;
}
