import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { campaignAdapter, CampaignState, initialState } from '../../types';
import {
  adjustCampaign,
  adjustCampaignActivityAndTime,
  adjustCampaignActivityAndTimeFailure,
  adjustCampaignActivityAndTimeSuccess,
  adjustCampaignFailure,
  adjustCampaignSuccess,
  createCampaign,
  createCampaignFailure,
  createCampaignSuccess,
  fetchCampaign,
  fetchCampaignFailure,
  fetchCampaignSuccess,
  loadCampaign,
  loadCampaignFailure,
  loadCampaigns,
  loadCampaignsFailure,
  loadCampaignsSuccess,
  loadCampaignSuccess,
  loadUserCampaigns,
  loadUserCampaignsFailure,
  loadUserCampaignsSuccess,
  setCampaignsFilter,
  startCampaign,
  startCampaignFailure,
  startCampaignSuccess,
  stopCampaign,
  stopCampaignFailure,
  stopCampaignSuccess,
  updateCampaign,
  updateCampaignFailure,
  updateCampaignSuccess,
  uploadCampaignImage,
  uploadCampaignImageFailure,
  uploadCampaignImageSuccess
} from '../actions/campaigns.actions';

export const campaignTriggers: EntityTriggers = {
  single: {
    loading: [
      loadCampaign.type,
      createCampaign.type,
      updateCampaign.type,
      startCampaign.type,
      stopCampaign.type,
      adjustCampaign.type,
      adjustCampaignActivityAndTime.type,
      uploadCampaignImage.type
    ],
    resting: [
      loadCampaignSuccess.type,
      createCampaignSuccess.type,
      updateCampaignSuccess.type,
      startCampaignSuccess.type,
      stopCampaignSuccess.type,
      adjustCampaignSuccess.type,
      adjustCampaignActivityAndTimeSuccess.type,
      updateCampaignSuccess.type,
      uploadCampaignImageSuccess.type
    ],
    erroring: [
      loadCampaignFailure.type,
      createCampaignFailure.type,
      updateCampaignFailure.type,
      startCampaignFailure.type,
      stopCampaignFailure.type,
      adjustCampaignFailure.type,
      adjustCampaignActivityAndTimeFailure.type,
      uploadCampaignImageFailure.type
    ]
  },
  batch: {
    loading: [loadUserCampaigns.type, loadCampaigns.type],
    resting: [loadUserCampaignsSuccess.type, loadCampaignsSuccess.type],
    erroring: [loadUserCampaignsFailure.type, loadCampaignsFailure.type]
  }
};

export const campaignFetchListTriggers: FetchListTriggers = {
  loading: [fetchCampaign.type],
  resting: [fetchCampaignSuccess.type],
  erroring: [fetchCampaignFailure.type]
};

export function campaignsReducer(state: CampaignState = initialState, action: Action): CampaignState {
  return {
    ...callStateReducer(baseReducer, campaignTriggers)(state, action),
    ...fetchStateReducer(campaignFetchListTriggers, 'campaign')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(setCampaignsFilter, (state, { filter }) => ({ ...state, filter })),
  on(loadUserCampaignsSuccess, (state, { campaigns }) => ({
    ...campaignAdapter.setAll(campaigns, state),
    isGlobalCampaignsLoaded: false
  })),
  on(loadCampaignsSuccess, (state, { campaigns }) => ({
    ...campaignAdapter.setAll(campaigns, state),
    isGlobalCampaignsLoaded: true
  })),
  on(loadCampaignSuccess, (state, { campaign }) => campaignAdapter.upsertOne(campaign, state)),
  on(fetchCampaignSuccess, (state, { campaign }) => campaignAdapter.upsertOne(campaign, state)),
  on(createCampaignSuccess, (state, { campaign }) => campaignAdapter.addOne(campaign, state)),
  on(
    updateCampaignSuccess,
    startCampaignSuccess,
    stopCampaignSuccess,
    adjustCampaignSuccess,
    adjustCampaignActivityAndTimeSuccess,
    (state, { campaign }) => campaignAdapter.updateOne({ id: campaign.id, changes: campaign }, state)
  )
);
