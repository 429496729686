import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { AuthenticationProvider } from '../types/authentication-providers.type';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationProvidersService {
  constructor(private missionControlService: MissionControlService) {}

  getAuthenticationProviders(): Observable<AuthenticationProvider[]> {
    return this.missionControlService.get<AuthenticationProvider[]>('authentication_providers');
  }

  getAuthenticationProvider(reference: string): Observable<AuthenticationProvider> {
    return this.missionControlService.get<AuthenticationProvider>(`authentication_providers/${reference}`);
  }

  createAuthenticationProvider(authenticationProvider: AuthenticationProvider): Observable<AuthenticationProvider> {
    authenticationProvider = ObjectUtils.sanitizeRequestObject<AuthenticationProvider>(authenticationProvider);

    return this.missionControlService.post<AuthenticationProvider>('authentication_providers', authenticationProvider);
  }

  updateAuthenticationProvider(authenticationProvider: AuthenticationProvider): Observable<AuthenticationProvider> {
    authenticationProvider = ObjectUtils.sanitizeRequestObject<AuthenticationProvider>(authenticationProvider);
    return this.missionControlService.patch<AuthenticationProvider>(
      `authentication_providers/${authenticationProvider.reference}`,
      authenticationProvider
    );
  }
}
