import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { Formatters } from '@utils';

import { CategorisedLoyaltyProgramsService } from '../../services/categorised-loyalty-programs.service';
import { CategorisedLoyaltyProgramState } from '../../types';
import {
  loadCategorisedLoyaltyProgram,
  loadCategorisedLoyaltyProgramFailure,
  loadCategorisedLoyaltyPrograms,
  loadCategorisedLoyaltyProgramsFailure,
  loadCategorisedLoyaltyProgramsSuccess,
  loadCategorisedLoyaltyProgramSuccess,
  updateCategorisedLoyaltyProgram,
  updateCategorisedLoyaltyProgramFailure,
  updateCategorisedLoyaltyProgramsPriorities,
  updateCategorisedLoyaltyProgramsPrioritiesFailure,
  updateCategorisedLoyaltyProgramsPrioritiesSuccess,
  updateCategorisedLoyaltyProgramSuccess,
  synchroniseLoyaltyPrograms,
  synchroniseLoyaltyProgramsFailure,
  synchroniseLoyaltyProgramsSuccess
} from '../actions';

@Injectable()
export class CategorisedLoyaltyProgramsEffects {
  loadCategorisedLoyaltyPrograms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCategorisedLoyaltyPrograms),
      switchMap(({ categorisedLoyaltyProgramType }) =>
        this.categorisedLoyaltyProgramsService.getCategorisedLoyaltyPrograms(categorisedLoyaltyProgramType).pipe(
          map(categorisedLoyaltyProgramsResult =>
            loadCategorisedLoyaltyProgramsSuccess({ categorisedLoyaltyProgramsResult })
          ),
          catchError(error => of(loadCategorisedLoyaltyProgramsFailure(error)))
        )
      )
    )
  );

  loadCategorisedLoyaltyProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCategorisedLoyaltyProgram),
      switchMap(({ categorisedLoyaltyProgramType, id }) =>
        this.categorisedLoyaltyProgramsService.getCategorisedLoyaltyProgram(categorisedLoyaltyProgramType, id).pipe(
          map(categorisedLoyaltyProgramResult =>
            loadCategorisedLoyaltyProgramSuccess({ categorisedLoyaltyProgramResult })
          ),
          catchError(error => of(loadCategorisedLoyaltyProgramFailure(error)))
        )
      )
    )
  );

  updateCategorisedLoyaltyProgramsPriorities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCategorisedLoyaltyProgramsPriorities),
      switchMap(({ priorities, categorisedLoyaltyProgramType }) =>
        this.categorisedLoyaltyProgramsService
          .updateCategorisedLoyaltyProgramsPriorities(categorisedLoyaltyProgramType, priorities)
          .pipe(
            map(() => updateCategorisedLoyaltyProgramsPrioritiesSuccess(categorisedLoyaltyProgramType)),
            catchError(error =>
              of(updateCategorisedLoyaltyProgramsPrioritiesFailure(error, categorisedLoyaltyProgramType))
            )
          )
      )
    )
  );

  updateCategorisedLoyaltyProgramsPrioritiesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCategorisedLoyaltyProgramsPrioritiesSuccess),
      switchMap(({ categorisedLoyaltyProgramType }) => [
        loadCategorisedLoyaltyPrograms({ categorisedLoyaltyProgramType })
      ])
    )
  );

  updateCategorisedLoyaltyProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCategorisedLoyaltyProgram),
      switchMap(({ categorisedLoyaltyProgramType, params, redirectToListingPage }) =>
        this.categorisedLoyaltyProgramsService
          .updateCategorisedLoyaltyProgram(categorisedLoyaltyProgramType, params)
          .pipe(
            map(() =>
              updateCategorisedLoyaltyProgramSuccess(params.id, categorisedLoyaltyProgramType, redirectToListingPage)
            ),
            catchError(error => of(updateCategorisedLoyaltyProgramFailure(error, categorisedLoyaltyProgramType)))
          )
      )
    )
  );

  updateCategorisedLoyaltyProgramSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCategorisedLoyaltyProgramSuccess),
      switchMap(({ id, categorisedLoyaltyProgramType, redirectToListingPage }) => {
        const path = `rewards-offering/${Formatters.fromCamelToSnakeCase(categorisedLoyaltyProgramType)}${redirectToListingPage ? '' : '/' + id}`;
        return [loadCategorisedLoyaltyProgram({ categorisedLoyaltyProgramType, id }), routerForceNavigate({ path })];
      })
    )
  );

  synchroniseLoyaltyPrograms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(synchroniseLoyaltyPrograms),
      withLatestFrom(this.store.select(authQuery.getUserTenantId)),
      switchMap(([_action, tenantId]) =>
        this.categorisedLoyaltyProgramsService.synchroniseLoyaltyPrograms(tenantId).pipe(
          map(() => synchroniseLoyaltyProgramsSuccess()),
          catchError(error => of(synchroniseLoyaltyProgramsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private categorisedLoyaltyProgramsService: CategorisedLoyaltyProgramsService,
    private store: Store<CategorisedLoyaltyProgramState>
  ) {}
}
