export enum ProductType {
  TravelBooking = 'travel_booking',
  PointsTransfer = 'points_transfer',
  CardSpend = 'card_spend',
  GiftCard = 'gift_card',
  SystemEvent = 'system_event',
  ManualAdjustment = 'manual_adjustment',
  CampaignPoints = 'campaign_points',
  CashRedemption = 'cash_redemption',
  Merchandise = 'merchandise',
  ShoppingCart = 'shopping_cart'
}
