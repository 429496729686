import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialTenantLocaleState, tenantLocaleAdapter, TenantLocaleState } from '../../types';
import {
  updateTenantLocales,
  updateTenantLocalesFailure,
  updateTenantLocalesSuccess,
  loadTenantLocales,
  loadTenantLocalesFailure,
  loadTenantLocalesSuccess
} from '../actions/tenant-locales.actions';

export const tenantLocaleTriggers: EntityTriggers = {
  batch: {
    loading: [loadTenantLocales.type, updateTenantLocales.type],
    resting: [loadTenantLocalesSuccess.type, updateTenantLocalesSuccess.type],
    erroring: [loadTenantLocalesFailure.type, updateTenantLocalesFailure.type]
  }
};

export function tenantLocalesReducer(
  state: TenantLocaleState = initialTenantLocaleState,
  action: Action
): TenantLocaleState {
  return callStateReducer(baseReducer, tenantLocaleTriggers)(state, action);
}

const baseReducer = createReducer(
  initialTenantLocaleState,
  on(loadTenantLocalesSuccess, (state, { tenantLocales }) => tenantLocaleAdapter.setAll(tenantLocales, state))
);
