import { Pipe, PipeTransform } from '@angular/core';

import { PropertiesType } from '@shared/types';
import { ObjectUtils } from '@utils';

@Pipe({ name: 'removeNullish' })
export class RemoveNullishPipe implements PipeTransform {
  transform(data: PropertiesType): PropertiesType {
    return Object.entries(data || []).reduce(
      (acc, [k, v]) => (ObjectUtils.isNullish(v) || ObjectUtils.isEmptyArray(v) ? acc : ((acc[k] = v), acc)),
      {}
    );
  }
}
