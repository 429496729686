import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { updateUsername } from '../../store/actions/users.actions';
import { usersQuery } from '../../store/selectors/users.selectors';
import { UserState } from '../../types';

interface DialogData {
  id: string;
  reference: string;
  parentEmailControl: FormControl;
}

@Component({
  selector: 'admin-edit-email-dialog',
  templateUrl: './edit-email-dialog.component.html',
  styleUrls: ['./edit-email-dialog.component.scss']
})
export class EditEmailDialogComponent implements OnInit {
  emailControl: FormControl;
  initialEmail: string;
  loading$: Observable<boolean>;

  constructor(
    private store: Store<UserState>,
    public dialogRef: MatDialogRef<EditEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.initialEmail = this.data.parentEmailControl.value;
    this.emailControl = new FormControl<string>(this.initialEmail, [Validators.required, Validators.email]);

    this.loading$ = this.store.select(usersQuery.isSingleLoading);
  }

  submitForm(): void {
    const email = this.emailControl.value;
    const updateUsernameRequestParams = { id: this.data.id, reference: this.data.reference, username: email };

    this.store.dispatch(
      updateUsername({
        updateUsernameRequestParams,
        dialogRefId: this.dialogRef.id
      })
    );
  }
}
