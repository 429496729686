import { RefreshTokenExchangeResult } from '@kaligo/hermes';
import { createReducer, on } from '@ngrx/store';

import { DecodedUser } from '@core/types';

import {
  handleLoginRedirect,
  loadTokenSuccess,
  loginFail,
  loginUser,
  logoutUserComplete,
  startIdleCheck,
  startSessionCheck,
  startTokenRefresh
} from '../actions/auth.actions';

export interface AuthState {
  user: DecodedUser;
  tokenResult: RefreshTokenExchangeResult;
  hermesLoading: boolean;
  isLoggedIn: boolean;
  scopes: string[];
  loginFail: boolean;
  isSessionCheckRunning: boolean;
  isIdleCheckRunning: boolean;
}

const initialState: AuthState = {
  user: null,
  tokenResult: null,
  hermesLoading: false,
  isLoggedIn: false,
  scopes: [],
  loginFail: false,
  isSessionCheckRunning: false,
  isIdleCheckRunning: false
};

export const authReducer = createReducer(
  initialState,
  on(handleLoginRedirect, state => ({ ...state, hermesLoading: true })),
  on(startTokenRefresh, (state, { transactionAppState }) => ({ ...state, transactionAppState })),
  on(loadTokenSuccess, (state, { tokenResult }) => ({ ...state, tokenResult, hermesLoading: false })),
  on(loginUser, (state, { user }) => ({ ...state, user, scopes: user.scopes, isLoggedIn: true })),
  // when logoutUserComplete, we set the loginFail back to false, for user to retry login
  on(logoutUserComplete, state => ({
    ...state,
    hermesLoading: false,
    user: null,
    tokenResult: null,
    isLoggedIn: false,
    loginFail: false,
    isSessionCheckRunning: false,
    isIdleCheckRunning: false
  })),
  on(loginFail, state => ({ ...state, loginFail: true })),
  on(startSessionCheck, state => ({ ...state, isSessionCheckRunning: true })),
  on(startIdleCheck, state => ({ ...state, isIdleCheckRunning: true }))
);
