import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable } from 'rxjs';

export type LocaleSwitcherV3Options = { label: string; value: string }[];

@Component({
  selector: 'admin-locale-switcher-v3',
  templateUrl: './locale-switcher-v3.component.html',
  styles: ['.menu { box-shadow: 0 8px 20px 0 rgba(20 20 20 / 10%) }']
})
export class LocaleSwitcherV3Component implements OnChanges {
  @Input() localeOptions: LocaleSwitcherV3Options;
  @Input() selectedLocale: string;
  @Input() parentElementLoading$: Observable<boolean>;
  @Output() localeChanged = new EventEmitter<string>();

  localeMapping: Record<string, string>;

  ngOnChanges(): void {
    this.localeMapping = this.localeOptions.reduce((acc, { label, value }) => {
      acc[value] = label;
      return acc;
    }, {});
  }

  handleClick(value: string): void {
    this.localeChanged.emit(value);
  }
}
