import { Component, Input, OnInit } from '@angular/core';

import { OrderItem, PointsTransferOrderItemData, StripeReferenceData } from '../../types';

@Component({
  selector: 'admin-points-transfer-order-item-v2',
  templateUrl: './points-transfer-order-item-v2.component.html'
})
export class PointsTransferOrderItemV2Component implements OnInit {
  @Input() customerId: string;
  @Input() orderItem: OrderItem;
  @Input() stripeReferenceData: StripeReferenceData;

  data: PointsTransferOrderItemData;

  ngOnInit(): void {
    this.data = this.orderItem.data as PointsTransferOrderItemData;
  }
}
