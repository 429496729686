import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { confirmationValidator } from '../../../../validators';
import { createIdentity } from '../../store/actions/identities.actions';
import { CreateIdentityParams, IdentityState, User } from '../../types';

interface DialogData {
  user: User;
}

@Component({
  selector: 'admin-create-identity-dialog',
  templateUrl: './create-identity-dialog.component.html',
  styleUrls: ['./create-identity-dialog.component.scss']
})
export class CreateIdentityDialogComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  createForm: UntypedFormGroup;
  displayPasswordFields = false;
  passwordProviderIdOptions = ['email', 'password'];
  providerIdOptions = [...this.passwordProviderIdOptions, 'qa_dummy'];

  subscribeToPasswordChanges: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<IdentityState>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CreateIdentityDialogComponent>
  ) {}

  get uidControl(): AbstractControl {
    return this.createForm.get('uid');
  }

  get passwordConfirmationControl(): AbstractControl {
    return this.createForm.get('passwordConfirmation');
  }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      providerId: ['', [Validators.required]],
      uid: ['']
    });

    this.subscribeToProviderIdChange();
  }

  subscribeToProviderIdChange(): void {
    this.createForm
      .get('providerId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.handlePasswordControls(value);
        this.handleUidControl(value);
      });
  }

  submitForm(): void {
    const params: CreateIdentityParams = {
      ...this.createForm.value,
      userId: this.data.user.id
    };

    this.store.dispatch(
      createIdentity({
        params,
        dialogRefId: this.dialogRef.id
      })
    );
  }

  private handlePasswordControls(value: string): void {
    if (this.passwordProviderIdOptions.includes(value)) {
      this.createForm.addControl('password', new FormControl<string>(null, [Validators.required]));
      this.createForm.addControl(
        'passwordConfirmation',
        new FormControl<string>(null, [Validators.required, confirmationValidator('password')])
      );
      this.displayPasswordFields = true;

      this.subscribeToPasswordChanges = this.createForm.controls.password.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.createForm.controls.passwordConfirmation.updateValueAndValidity());
    } else {
      this.subscribeToPasswordChanges?.unsubscribe();

      this.displayPasswordFields = false;
      this.createForm.removeControl('passwordConfirmation');
      this.createForm.removeControl('password');
    }
  }

  private handleUidControl(value: string): void {
    this.uidControl.reset();

    if (value === 'email') {
      this.uidControl.setValidators([Validators.required, Validators.email]);
    } else {
      this.uidControl.clearValidators();

      if (!this.data.user.uid) {
        this.uidControl.setValidators([Validators.required]);
      }
    }
    this.uidControl.updateValueAndValidity();
  }
}
