import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'admin-full-page-overlay',
  templateUrl: './full-page-overlay.component.html'
})
export class FullPageOverlayComponent implements OnInit, OnDestroy {
  @Input() backButtonText: string = 'Back';

  ngOnInit(): void {
    document.querySelector('.cdk-overlay-pane.mat-mdc-dialog-panel')?.classList.add('full-page-overlay');
    document.body.classList.add('overflow-hidden'); // Hide the scroll bar that would otherwise appear next to the overlay's scroll bar
  }

  ngOnDestroy(): void {
    document.querySelector('.cdk-overlay-pane.mat-mdc-dialog-panel')?.classList.remove('full-page-overlay');
    document.body.classList.remove('overflow-hidden');
  }
}
