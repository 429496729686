<div mat-dialog-title>Latest Connection Credential</div>
<div mat-dialog-content>
  <textarea rows="25" cols="80" disabled>{{ data.connectionCredentialToken | json }}</textarea>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="uppercase-button cancel-button" (click)="dialogRef.close(false)">
    <span class="uppercase-button__text">Close</span>
  </button>

  <button
    mat-raised-button
    [cdkCopyToClipboard]="data.connectionCredentialToken | json"
    (click)="openSnackBar()"
    color="primary"
    class="uppercase-button cancel-button"
  >
    <mat-icon class="uppercase-button__icon">content_copy</mat-icon>
    <span class="uppercase-button__text">Copy Values</span>
  </button>
</div>
