<div class="main-container">
  <ng-container [ngSwitch]="renderState$ | async">
    <admin-spinner *ngSwitchCase="'loading'"></admin-spinner>

    <admin-empty-state-page *ngSwitchCase="'error'" entity="Audit log"></admin-empty-state-page>

    <ng-container *ngSwitchCase="'content'">
      <div *ngIf="isTopLevelView" class="page-title-wrapper">
        <h1>Audit log item</h1>
      </div>

      <div class="details-view" *ngIf="auditLog$ | async as auditLog">
        <div class="section-header">General</div>
        <div class="section admin-item-wrapper">
          <admin-item label="Audit log item ID">{{ auditLog.id }}</admin-item>
          <admin-item label="Tenant ID">{{ auditLog.tenantId }}</admin-item>
          <admin-item label="Action">{{ auditLog.action | customTitleCase: 'fromSnakeCase' }}</admin-item>
          <admin-item label="Entity">{{ auditLog.entity | customTitleCase: 'fromSnakeCase' }}</admin-item>
          <admin-item class="cross-two-column">
            <mat-chip-listbox aria-label="Tags">
              <mat-chip-option *ngFor="let tag of auditLog.tags" [routerLink]="tag.routeLink" [class]="tag.type">
                {{ tag.displayPrefix }}: {{ tag.displayValue }}
              </mat-chip-option>
            </mat-chip-listbox>
          </admin-item>
        </div>

        <div *ngIf="auditLog.request">
          <div class="section-header">Request</div>
          <div class="section two-column-in-row">
            <div class="admin-item-wrapper">
              <admin-item class="cross-two-column" label="IP address">{{ auditLog.request.ip }}</admin-item>
              <div *ngIf="auditLog.request.location" class="admin-item-wrapper cross-two-column">
                <admin-item class="cross-two-column" label="City">{{ auditLog.request.location.city }}</admin-item>
                <admin-item class="cross-two-column" label="Country">{{
                  auditLog.request.location.country
                }}</admin-item>
                <admin-item label="Latitude">{{ auditLog.request.location.lat }}</admin-item>
                <admin-item label="Longitude">{{ auditLog.request.location.lon }}</admin-item>
              </div>

              <admin-item class="cross-two-column" label="Host">{{ auditLog.request.host }}</admin-item>
              <admin-item class="cross-two-column" label="Operating system">{{
                auditLog.request.userAgent | userAgentInfo: 'os'
              }}</admin-item>
              <admin-item class="cross-two-column" label="Browser">{{
                auditLog.request.userAgent | userAgentInfo: 'browser'
              }}</admin-item>
            </div>

            <div *ngIf="auditLog.request?.location" class="google-maps-wrapper">
              <google-map
                width="100%"
                height="100%"
                [center]="{ lat: auditLog.request.location.lat, lng: auditLog.request.location.lon }"
              >
                <map-marker
                  [position]="{ lat: auditLog.request.location.lat, lng: auditLog.request.location.lon }"
                ></map-marker>
              </google-map>
            </div>
          </div>
        </div>

        <div class="section-header">Parameters</div>
        <div class="section">
          <span class="json-data-block">{{ auditLog.params | json }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
