import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { UserTokensService } from '../../services/user-tokens.service';
import { UserTokenState } from '../../types';
import {
  invalidateUserToken,
  invalidateUserTokenFail,
  invalidateUserTokenSuccess,
  loadUserTokens,
  loadUserTokensFailure,
  loadUserTokensSuccess
} from '../actions/user-tokens.actions';
import { userTokensQuery } from '../selectors/user-tokens.selectors';

@Injectable()
export class UserTokensEffects {
  loadUserTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserTokens),
      exhaustMap(action =>
        this.userTokensService.getUserTokens(action.userId, action.filter).pipe(
          map(userTokensResult => loadUserTokensSuccess({ userTokensResult })),
          catchError(error => of(loadUserTokensFailure({ error })))
        )
      )
    )
  );

  invalidateUserToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invalidateUserToken),
      exhaustMap(action =>
        this.userTokensService.invalidateUserToken(action.params).pipe(
          map(() => invalidateUserTokenSuccess({ params: action.params, dialogRefId: action.dialogRefId })),
          catchError(error => of(invalidateUserTokenFail({ error })))
        )
      )
    )
  );

  invalidateUserTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invalidateUserTokenSuccess),
      withLatestFrom(this.store.select(userTokensQuery.getFilter)),
      map(([action, filter]) => {
        this.matDialog.getDialogById(action.dialogRefId)?.close();
        return loadUserTokens({ userId: action.params.userId, filter });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userTokensService: UserTokensService,
    private store: Store<UserTokenState>,
    private matDialog: MatDialog
  ) {}
}
