import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { ProductType } from '../../dashboard/types';

import { OrderItemDataType } from './index';

export interface Redemption {
  id: string;
  type: string;
  attributes: AttributesData;
}

export interface AttributesData {
  userId: string;
  pointsAccountId: string;
  transactionTime: string;
  description: string;
  category: string;
  entryType: 'credit' | 'debit';
  productType: ProductType;
  status: string;
  amount: number;
  balanceAsOf: number;
  createdAt: string;
  updatedAt: string;
  orderFulfillmentStatus: string;
  orderItemData: OrderItemDataType;
}

export interface CashRedemption {
  userId: string;
  pointsAccountId: string;
  cashRedemption: {
    cashAmount: number;
    loyaltyProgramId: string;
    redemptionAmount: number;
  };
}

export interface PointsTransferRedemption {
  userId: string;
  pointsAccountId: string;
  pointsTransfer: {
    loyaltyProgramId: string;
    transferAmount?: number;
    redemptionAmount?: number;
  };
}

export type RedemptionState = CallState;

export const redemptionInitialState: RedemptionState = initialCallState;
