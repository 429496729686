import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { Store } from '@ngrx/store';

import { INVALID_E164_MESSAGE, INVALID_EMAIL_MESSAGE } from '@utils';

import { phoneValidator } from '../../../../validators';
import { createMfaIdentity } from '../../store/actions/mfa-identities.actions';
import { CreateMfaIdentityDialogForm, CreateMfaIdentityParams, MfaIdentityState } from '../../types';

interface DialogData {
  userId: string;
}

@Component({
  selector: 'admin-create-mfa-identity-dialog-v2',
  templateUrl: './create-mfa-identity-dialog-v2.component.html',
  styleUrls: ['./create-mfa-identity-dialog-v2.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } }
  ]
})
export class CreateMfaIdentityDialogV2Component implements OnInit {
  destroyRef = inject(DestroyRef);

  createForm: FormGroup<CreateMfaIdentityDialogForm>;
  providerIdOptions = ['email', 'sms', 'fido', 'google_authenticator'];
  invalidE164Message = INVALID_E164_MESSAGE;
  invalidEmailMessage = INVALID_EMAIL_MESSAGE;

  constructor(
    private fb: FormBuilder,
    private store: Store<MfaIdentityState>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CreateMfaIdentityDialogV2Component>
  ) {}

  get uidControl(): AbstractControl {
    return this.createForm.get('uid');
  }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      providerId: this.fb.control('', [Validators.required]),
      uid: this.fb.control('', [Validators.required])
    });

    this.subscribeToProviderIdChange();
  }

  subscribeToProviderIdChange(): void {
    this.createForm
      .get('providerId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.handleUidControl(value));
  }

  submitForm(): void {
    const params: CreateMfaIdentityParams = {
      ...this.createForm.getRawValue(),
      userId: this.data.userId
    };

    this.store.dispatch(
      createMfaIdentity({
        params,
        dialogRefId: this.dialogRef.id
      })
    );
  }

  handleUidControl(value: string): void {
    this.uidControl.reset();

    this.uidControl.clearValidators();
    this.uidControl.addValidators(Validators.required);

    if (value === 'email') {
      this.uidControl.addValidators(Validators.email);
    } else if (value === 'sms') {
      this.uidControl.addValidators(phoneValidator());
    }
  }
}
