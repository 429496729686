import { delay, filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, DestroyRef, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTabNav } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR, Tab } from '@core/types';

@Component({
  selector: 'admin-menu-v2',
  templateUrl: './menu-v2.component.html',
  styleUrls: ['./menu-v2.component.scss']
})
export class MenuV2Component implements OnInit {
  @ViewChild(MatTabNav, { read: ElementRef, static: true }) matTabNav: ElementRef;

  @Input() tabs: Tab[];
  @Input() tooltipPrefix?: string;
  destroyRef = inject(DestroyRef);

  auditLogsTab: Tab = {
    path: 'logs',
    icon: 'batch_prediction',
    label: 'Audit logs',
    displayed: this.scopes.hasAny(SCOPES_OR.viewAuditLogs)
  };
  activeTab: Tab;
  isTabsOpen: boolean[];
  isSubTabOpen = false;

  constructor(
    public route: ActivatedRoute,
    protected router: Router,
    protected scopes: Scopes,
    private cdRef: ChangeDetectorRef
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        delay(100), // allow active tab highlighting on load
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.setActiveTab());
  }

  get lastUrlPathSegment(): string {
    return window.location.pathname.split('/').at(-1)!;
  }

  get thirdUrlPathSegment(): string {
    return window.location.pathname.split('/').at(3)!;
  }

  ngOnInit(): void {
    this.tabs.forEach(tab => {
      if (tab.subTabs?.every(subTab => !subTab.displayed)) {
        tab.displayed = false;
      } else if (!('displayed' in tab)) {
        tab.displayed = true;
      }
    });
    this.isTabsOpen = new Array<boolean>(this.tabs?.length).fill(false);
    this.setActiveTab();
  }

  setActiveTab(): void {
    this.activeTab = this.tabs.find(tab => {
      if (tab.path) {
        // if tab has path, it is the last navigation point and does not have child items
        const lastTabPathSegment = tab.path.split('/').at(-1);
        return this.lastUrlPathSegment === lastTabPathSegment;
      } else if (tab.subTabs) {
        // if tab has subpaths, currently subpath is always on the 3rd navigation level, e.g. /users/:id/order-items
        return tab.subTabs.map(subTab => subTab.path).includes(this.thirdUrlPathSegment);
      }
    })!;
  }

  closeAllTabs(event: MouseEvent): void {
    if ((event.target as HTMLElement).getAttribute('is-main-tab') !== 'true') {
      this.isTabsOpen.fill(false);
    }
  }

  toggleSubTab(index: number): void {
    const currentTabExpansionStatus = this.isTabsOpen[index];

    this.isTabsOpen.fill(false);
    this.isTabsOpen[index] = !currentTabExpansionStatus;
  }

  setIsSubTabOpen(isOpen: boolean): void {
    this.isSubTabOpen = isOpen;
    this.cdRef.detectChanges();
  }
}
