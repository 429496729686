import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';

import { Domain, domainAdapter, DomainState } from '../../types';

const getDomainState = createFeatureSelector<DomainState>('domain');
const domainDetector = createDetector<Domain>();

const { selectAll: getDomainsList, selectEntities: getDomainsDictionary } = domainAdapter.getSelectors(getDomainState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<DomainState>(getDomainState);

const getFilter = createSelector(getDomainState, state => state.filter);

const getCount = createSelector(getDomainState, state => state.count);

const isDomainsLoaded = createSelector(
  getDomainsDictionary,
  isBatchResting,
  (domains, batchResting) => domainDetector('allLoaded')(domains) && batchResting
);

const isDomainLoaded = (id: string): Selector<DomainState, boolean> =>
  createSelector(getDomainsDictionary, entities => domainDetector('oneLoaded')(entities, id));

const getDomainById = (id: string): Selector<DomainState, Domain> =>
  createSelector(getDomainsDictionary, entities => entities[id]);

const getFetchList = getFetchStateSelector<DomainState>(getDomainState);

export const domainsQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getDomainsList,
  getDomainsDictionary,
  getFilter,
  getCount,
  getFetchList,
  isDomainsLoaded,
  isDomainLoaded,
  getDomainById
};
