import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  authenticationProviderAdapter,
  AuthenticationProviderState,
  initialState
} from '../../types/authentication-providers.type';
import {
  createAuthenticationProvider,
  createAuthenticationProviderFailure,
  createAuthenticationProviderSuccess,
  fetchAuthenticationProvider,
  fetchAuthenticationProviderFailure,
  loadAuthenticationProvider,
  loadAuthenticationProviderFailure,
  loadAuthenticationProviders,
  loadAuthenticationProvidersFailure,
  loadAuthenticationProvidersSuccess,
  loadAuthenticationProviderSuccess,
  updateAuthenticationProvider,
  updateAuthenticationProviderFailure,
  updateAuthenticationProviderSuccess
} from '../actions/authentication-providers.actions';

export const authenticationProviderTriggers: EntityTriggers = {
  single: {
    loading: [loadAuthenticationProvider.type, createAuthenticationProvider.type, updateAuthenticationProvider.type],
    resting: [
      loadAuthenticationProviderSuccess.type,
      createAuthenticationProviderSuccess.type,
      updateAuthenticationProviderSuccess.type
    ],
    erroring: [
      loadAuthenticationProviderFailure.type,
      createAuthenticationProviderFailure.type,
      updateAuthenticationProviderFailure.type
    ]
  },
  batch: {
    loading: [loadAuthenticationProviders.type],
    resting: [loadAuthenticationProvidersSuccess.type],
    erroring: [loadAuthenticationProvidersFailure.type]
  }
};

export function authenticationProvidersReducer(
  state: AuthenticationProviderState = initialState,
  action: Action
): AuthenticationProviderState {
  return callStateReducer(baseReducer, authenticationProviderTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(fetchAuthenticationProvider, (state, { reference }) => ({ ...state, fetchList: [...state.fetchList, reference] })),
  on(fetchAuthenticationProviderFailure, (state, { reference }) => ({
    ...state,
    fetchList: state.fetchList.filter(id => reference !== id)
  })),
  on(loadAuthenticationProviderSuccess, (state, { authenticationProvider }) => ({
    ...authenticationProviderAdapter.upsertOne(authenticationProvider, state),
    fetchList: state.fetchList.filter(id => authenticationProvider.reference !== id)
  })),
  on(loadAuthenticationProvidersSuccess, (state, { authenticationProviders }) =>
    authenticationProviderAdapter.setAll(authenticationProviders, state)
  ),
  on(createAuthenticationProviderSuccess, (state, { authenticationProvider }) =>
    authenticationProviderAdapter.addOne(authenticationProvider, state)
  ),
  on(updateAuthenticationProviderSuccess, (state, { authenticationProvider }) =>
    authenticationProviderAdapter.updateOne(
      { id: authenticationProvider.reference, changes: authenticationProvider },
      state
    )
  )
);
