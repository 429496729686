import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState } from '@core/types';

import { DomainsFilter } from './domains-filter.type';

export interface Domain {
  id: string;
  domain: string;
  appId: string;
  config: object;
  tenantId: string;
  description: string;
}

export const domainAdapter = createEntityAdapter<Domain>();

export interface DomainState extends EntityState<Domain>, CallState, FetchState {
  filter: DomainsFilter;
  count: number;
}

export const initialState: DomainState = {
  ...domainAdapter.getInitialState<CallState>(initialCallState),
  ...domainAdapter.getInitialState<FetchState>(initialFetchState),
  filter: null,
  count: 0
};

export interface UpdateDomainParams {
  id: string;
  domain?: string;
  appId?: string;
  config?: object;
  tenantId?: string;
  description?: string;
}
