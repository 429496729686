import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { PasswordPoliciesService } from '../../services/password-policies.service';
import {
  createPasswordPolicy,
  createPasswordPolicyFailure,
  createPasswordPolicySuccess,
  deletePasswordPolicy,
  deletePasswordPolicyFailure,
  deletePasswordPolicySuccess,
  loadPasswordPolicies,
  loadPasswordPoliciesFailure,
  loadPasswordPoliciesSuccess,
  loadPasswordPolicy,
  loadPasswordPolicyFailure,
  loadPasswordPolicySuccess,
  updatePasswordPolicy,
  updatePasswordPolicyFailure,
  updatePasswordPolicySuccess
} from '../actions/password-policies.actions';

@Injectable()
export class PasswordPoliciesEffects {
  loadPasswordPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPasswordPolicy),
      exhaustMap(action =>
        this.passwordPolicyService.getPasswordPolicy(action.id).pipe(
          map(passwordPolicy => loadPasswordPolicySuccess({ passwordPolicy })),
          catchError(error => of(loadPasswordPolicyFailure({ error })))
        )
      )
    )
  );

  loadPasswordPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPasswordPolicies),
      exhaustMap(action =>
        this.passwordPolicyService.getPasswordPolicies(action.tenantId).pipe(
          map(passwordPolicies => loadPasswordPoliciesSuccess({ passwordPolicies })),
          catchError(error => of(loadPasswordPoliciesFailure({ error })))
        )
      )
    )
  );

  createPasswordPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPasswordPolicy),
      exhaustMap(action =>
        this.passwordPolicyService.createPasswordPolicy(action.passwordPolicy).pipe(
          map(passwordPolicy => createPasswordPolicySuccess({ passwordPolicy })),
          catchError(error => of(createPasswordPolicyFailure({ error })))
        )
      )
    )
  );

  updatePasswordPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePasswordPolicy),
      exhaustMap(action =>
        this.passwordPolicyService.updatePasswordPolicy(action.passwordPolicy).pipe(
          map(passwordPolicy => updatePasswordPolicySuccess({ passwordPolicy })),
          catchError(error => of(updatePasswordPolicyFailure({ error })))
        )
      )
    )
  );

  createOrUpdatePasswordPolicySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPasswordPolicySuccess, updatePasswordPolicySuccess),
      map(action => routerNavigate({ path: `/tenants/${action.passwordPolicy.tenantId}/password-policies` }))
    )
  );

  deletePasswordPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePasswordPolicy),
      exhaustMap(action =>
        this.passwordPolicyService.deletePasswordPolicy(action.id).pipe(
          map(() => deletePasswordPolicySuccess({ id: action.id })),
          catchError(error => of(deletePasswordPolicyFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private passwordPolicyService: PasswordPoliciesService
  ) {}
}
