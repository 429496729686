import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { TransactionsService } from '../../services/transactions.service';
import { loadTransactions, loadTransactionsFailure, loadTransactionsSuccess } from '../actions/transactions.actions';

@Injectable()
export class TransactionsEffects {
  loadTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransactions),
      exhaustMap(({ userId, filter }) =>
        this.transactionsService.getTransactions(userId, filter).pipe(
          map(result => loadTransactionsSuccess({ result, userId })),
          catchError(error => of(loadTransactionsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private transactionsService: TransactionsService
  ) {}
}
