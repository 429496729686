import { Pipe, PipeTransform } from '@angular/core';

import { FeatureAvailabilityService } from '@shared/services/feature-availability/feature-availability.service';

import { AvailabilityStatus } from './../types/feature-availability.type';

@Pipe({ name: 'featureAvailabilityCheck' })
export class FeatureAvailabilityCheckPipe implements PipeTransform {
  constructor(private featureAvailabilityService: FeatureAvailabilityService) {}

  transform(feature: string, allowedStatues?: AvailabilityStatus[]): boolean {
    return this.featureAvailabilityService.isFeatureAllowed(feature, allowedStatues);
  }
}
