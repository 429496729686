import { Tag } from '@shared/types/tags.type';

import { NoteEntity, NoteType } from './notes.type';

export class NotesFilter {
  page: number;
  limit: number;
  entityId?: string;
  entity?: NoteEntity;
  entityFilter?: NoteEntity;
  type?: NoteType[];
  note?: string;
  updatedAt?: {
    from?: string;
    to?: string;
  };
  expiresAt?: {
    from?: string;
    to?: string;
  };
  adminUserId?: string;
  tags?: Tag[];
  sortBy?: {
    updatedAt?: string;
    expiresAt?: string;
  };

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}
