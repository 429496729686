import { Component, inject, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { GuardhouseErrorCodes } from '@core/errors/error-codes.type';
import { PiiAccess } from '@core/services/pii-access/pii-access.service';
import { CoreState } from '@core/store';
import { logoutUser } from '@core/store/auth/actions/auth.actions';
import { DecodedUser, SCOPES_OR, TenantLink } from '@core/types';
import { HostTenantMappingUtils } from '@utils';

import { StepUpDialogComponent } from '../step-up-dialog/step-up-dialog.component';

@Component({
  selector: 'admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() user: DecodedUser;
  @Input() isLoggedIn: boolean;

  isEditButtonDisplayed: boolean;
  isRC = HostTenantMappingUtils.isRC();
  hideProfilePicture = true;

  readonly piiAccess = inject(PiiAccess);
  readonly SCOPES = SCOPES_OR;
  readonly SUBMIT_TICKET_ROUTE = '/zendesk-tickets/submit-ticket';

  constructor(
    private store: Store<CoreState>,
    private matDialog: MatDialog,
    public router: Router,
    @Inject('tenants') public tenants: TenantLink[],
    @Inject('piiMaskingEnabled') public piiMaskingEnabled: boolean
  ) {}

  // TODO: remove hostPrefix when we are allowed to remove tenant specific color
  get hostPrefix(): string {
    return HostTenantMappingUtils.getPrefix();
  }

  get isOnSubmitTicketRoute(): boolean {
    return this.router.url === this.SUBMIT_TICKET_ROUTE;
  }

  getUserName(user: DecodedUser): string {
    return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.name || 'Logged in';
  }

  hideProfilePictureToggle(state: boolean): void {
    this.hideProfilePicture = state;
  }

  stepUpForPiiDialog(): void {
    this.matDialog.open(StepUpDialogComponent, { data: { messageKey: 'step-up' } });
  }

  logout(): void {
    this.store.dispatch(logoutUser({ reason: GuardhouseErrorCodes.INTENTIONAL_LOGOUT }));
  }
}
