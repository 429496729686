import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { BulkUpdateMcTranslationsPayload, McTranslation } from '../types';

@Injectable({
  providedIn: 'root'
})
export class McTranslationsService {
  constructor(private missionControlService: MissionControlService) {}

  getMcTranslations(): Observable<McTranslation[]> {
    return this.missionControlService.get<McTranslation[]>('translations');
  }

  updateMcTranslations(payload: BulkUpdateMcTranslationsPayload): Observable<null> {
    return this.missionControlService.patch('translations/bulk_update', ObjectUtils.sanitizeRequestObject(payload));
  }
}
