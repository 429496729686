export enum GuardhouseErrorCodes {
  NO_PERMISSION = 'GH2020',
  UNAUTHORISED_ADMIN_PANEL_ACCESS = 'GH2023',
  SESSION_EXPIRED = 'GH2022',
  UNAUTHENTICATED_APP = 'GH2026',
  TOKEN_LEVEL_INSUFFICIENT = 'GH2027',
  INTENTIONAL_LOGOUT = 'GH2022',
  UNINTENTIONAL_LOGOUT = 'GH2030'
}

export enum HermesErrorCodes {
  MISSING_REFRESH_TOKEN = 'HM09',
  INVALID_TOKEN = 'HM07'
}

export enum MissionControlErrorCodes {
  APPORVAL_REQUESTED = 'MC0010'
}
