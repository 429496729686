import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { PointsAdjustment } from '../../types';

export const makePointsAdjustment = createAction(
  '[Dashboard] Make PointsAdjustment',
  props<{ userId: string; pointsAdjustment: PointsAdjustment; dialogRefId: string }>()
);
export const makePointsAdjustmentSuccess = createAction(
  '[Dashboard] Make PointsAdjustment Success',
  props<{ userId: string; dialogRefId: string; pointsAccountId: string }>()
);
export const makePointsAdjustmentFailure = createAction(
  '[Dashboard] Make PointsAdjustment Failure',
  props<ErrorState>()
);
