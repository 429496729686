import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadOrderItem } from '../store/actions/order-items.actions';
import { OrderItemState } from '../types';

export const orderItemResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<OrderItemState> = inject(Store<OrderItemState>),
  scopes: Scopes = inject(Scopes)
): void => {
  if (scopes.lackScopes(SCOPES_OR.showOrderItems)) {
    return;
  }

  const orderItemId = Params.find(route, 'orderItemId');

  store.dispatch(loadOrderItem({ id: orderItemId }));
};
