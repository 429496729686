import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';

@Component({
  selector: 'admin-display-full-note-dialog',
  templateUrl: './display-full-note-dialog.component.html',
  styleUrls: ['./display-full-note-dialog.component.scss']
})
export class DisplayFullNoteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DisplayFullNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}
}
