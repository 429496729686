import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { UpdateMembershipParams } from '../../../memberships/types/memberships.type';
import { CashRedemption, PointsTransferRedemption, Redemption } from '../../types';

export const createCashRedemption = createAction(
  '[Redemptions] Create Cash Redemption',
  props<{ redemption: CashRedemption }>()
);
export const createCashRedemptionSuccess = createAction(
  '[Redemptions] Create Cash Redemption Success',
  props<{ redemption: Redemption }>()
);
export const createCashRedemptionFailure = createAction(
  '[Redemptions] Create Cash Redemption Failure',
  props<ErrorState>()
);

export const updateMembershipAndCreateCashRedemption = createAction(
  '[Redemptions] Update Membership And Create Redemption',
  props<{ membershipId: string; membership: UpdateMembershipParams; redemption: CashRedemption }>()
);

export const createPointsTransferRedemption = createAction(
  '[Redemptions] Create Points Transfer Redemption',
  props<{ redemption: PointsTransferRedemption }>()
);
export const createPointsTransferRedemptionSuccess = createAction(
  '[Redemptions] Create Points Transfer Redemption Success',
  props<{ redemption: Redemption }>()
);
export const createPointsTransferRedemptionFailure = createAction(
  '[Redemptions] Create Points Transfer Redemption Failure',
  props<ErrorState>()
);
