export class WebhookEventsFilterWithPagination {
  id?: string;
  eventName?: string;
  status?: string;
  page?: {
    size?: number;
    prevCursor?: string;
    nextCursor?: string;
  };
  sortBy?: string;
  sortDirection?: 'desc' | 'asc';

  constructor() {
    this.page = { size: 20 };
    this.sortBy = 'createdAt';
    this.sortDirection = 'desc';
  }
}

export class WebhookEventsFilter {
  id?: string;
  eventName?: string;
  status?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortDirection?: 'desc' | 'asc';

  constructor() {
    this.page = 1;
    this.limit = 20;
    this.sortBy = 'createdAt';
    this.sortDirection = 'desc';
  }
}
