import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { PointsStatement } from '../../types';

export const loadPointsStatement = createAction(
  '[Dashboard] Load Points Statement',
  props<{ pointsAccountId: string; pointsStatementId: string }>()
);

export const loadPointsStatementSuccess = createAction(
  '[Dashboard] Load Points Statement Success',
  props<{ pointsStatement: PointsStatement }>()
);

export const loadPointsStatementFailure = createAction(
  '[Dashboard] Load Points Statement Failure',
  props<ErrorState>()
);
