import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { AlfredMetadata } from '@shared/types';

export enum TransactionStatus {
  PROCESSING = 'processing',
  SENT = 'sent',
  FAILED = 'failed',
  UNMATCHED = 'unmatched'
}

export enum TransactionType {
  ACCRUAL = 'accrual',
  REVERSAL = 'reversal'
}

export class TransactionsFilter {
  page?: number;
  limit?: number;

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}

export interface Transaction {
  id: string;
  userId: string;
  tenantId: string;
  status: TransactionStatus;
  transactionType: TransactionType;
  merchantDescriptor: string;
  currency: string;
  cardLast4: string;
  amount: number;
  postingDate: string;
  createdAt: string;
  updatedAt: string;
}

export const transactionStatusDisplayClass = {
  processing: 'status-pending',
  sent: 'status-active',
  failed: 'status-inactive',
  unmatched: 'status-neutral'
};

export interface TransactionsResult {
  data: Transaction[];
  metadata: AlfredMetadata;
}

export interface TransactionsState extends EntityState<Transaction>, CallState {
  userId: string;
  metadata: AlfredMetadata;
  filter?: TransactionsFilter;
}
export const transactionAdapter = createEntityAdapter<Transaction>();

export const initialTransactionsState: TransactionsState = {
  ...transactionAdapter.getInitialState<CallState>(initialCallState),
  userId: null,
  metadata: new AlfredMetadata(),
  filter: null
};
