import { AbstractControl, ValidatorFn } from '@angular/forms';

export function printableCharacterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    for (let i = 0; i < control.value.length; i++) {
      const ascii = control.value.charCodeAt(i);

      // Including printable and not space character
      if (ascii < 33 || ascii > 126) {
        return { invalidCharacter: true };
      }
    }

    return null;
  };
}
