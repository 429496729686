import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { PasswordPolicy } from '../../types/password-policies.type';

export const loadPasswordPolicies = createAction(
  '[Password Policies] Load Password Policies',
  props<{ tenantId: string }>()
);
export const loadPasswordPoliciesSuccess = createAction(
  '[Password Policies] Load Password Policies Success',
  props<{ passwordPolicies: PasswordPolicy[] }>()
);
export const loadPasswordPoliciesFailure = createAction(
  '[Password Policies] Load Password Policies Failure',
  props<ErrorState>()
);

export const loadPasswordPolicy = createAction('[Password Policies] Load Password Policy', props<{ id: string }>());
export const loadPasswordPolicySuccess = createAction(
  '[Password Policies] Load Password Policy Success',
  props<{ passwordPolicy: PasswordPolicy }>()
);
export const loadPasswordPolicyFailure = createAction(
  '[Password Policies] Load Password Policy Failure',
  props<ErrorState>()
);

export const createPasswordPolicy = createAction(
  '[Password Policies] Create Password Policy',
  props<{ passwordPolicy: PasswordPolicy }>()
);
export const createPasswordPolicySuccess = createAction(
  '[Password Policies] Create Password Policy Success',
  props<{ passwordPolicy: PasswordPolicy }>()
);
export const createPasswordPolicyFailure = createAction(
  '[Password Policies] Create Password Policy Failure',
  props<ErrorState>()
);

export const updatePasswordPolicy = createAction(
  '[Password Policies] Update Password Policy',
  props<{ passwordPolicy: PasswordPolicy }>()
);
export const updatePasswordPolicySuccess = createAction(
  '[Password Policies] Update Password Policy Success',
  props<{ passwordPolicy: PasswordPolicy }>()
);
export const updatePasswordPolicyFailure = createAction(
  '[Password Policies] Update Password Policy Failure',
  props<ErrorState>()
);

export const deletePasswordPolicy = createAction('[Password Policies] Delete Password Policy', props<{ id: string }>());
export const deletePasswordPolicySuccess = createAction(
  '[Password Policies] Delete Password Policy Success',
  props<{ id: string }>()
);
export const deletePasswordPolicyFailure = createAction(
  '[Password Policies] Delete Password Policy Failure',
  props<ErrorState>()
);
