import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { LoginAttemptsService } from '../../services/login-attempts.service';
import {
  loadLoginAttempt,
  loadLoginAttemptFailure,
  loadLoginAttempts,
  loadLoginAttemptsFailure,
  loadLoginAttemptsSuccess,
  loadLoginAttemptSuccess
} from '../actions/login-attempts.actions';

@Injectable()
export class LoginAttemptsEffects {
  loadLoginAttempts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLoginAttempts),
      exhaustMap(action =>
        this.loginAttemptsService.getLoginAttempts(action.filter).pipe(
          map(result => loadLoginAttemptsSuccess({ result })),
          catchError(error => of(loadLoginAttemptsFailure({ error })))
        )
      )
    )
  );

  loadLoginAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLoginAttempt),
      exhaustMap(action =>
        this.loginAttemptsService.getLoginAttempt(action.id).pipe(
          map(loginAttempt => loadLoginAttemptSuccess({ loginAttempt })),
          catchError(error => of(loadLoginAttemptFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private loginAttemptsService: LoginAttemptsService
  ) {}
}
