import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { MfaIdentity, mfaIdentityAdapter, MfaIdentityState } from '../../types';

const mfaIdentitiesDetector = createDetector<MfaIdentity>();
const getMfaIdentityState = createFeatureSelector<MfaIdentityState>('mfaIdentity');

const { selectAll: getMfaIdentitiesList, selectEntities: getMfaIdentitiesDictionary } =
  mfaIdentityAdapter.getSelectors(getMfaIdentityState);

const { isBatchInitial, isBatchLoading, isBatchResting, isSingleLoading, getBatchError } =
  getCallStateSelectors<MfaIdentityState>(getMfaIdentityState);

const isMfaIdentitiesLoaded = (userId: string): Selector<MfaIdentityState, boolean> =>
  createSelector(
    getMfaIdentitiesDictionary,
    getMfaIdentitiesList,
    isBatchResting,
    (mfaIdentities, mfaIdentityList, batchResting) =>
      mfaIdentitiesDetector('allLoaded')(mfaIdentities) &&
      batchResting &&
      mfaIdentityList.every(mfaIdentity => mfaIdentity.userId === userId)
  );

export const mfaIdentitiesQuery = {
  isSingleLoading,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getMfaIdentitiesList,
  getMfaIdentitiesDictionary,
  isMfaIdentitiesLoaded
};
