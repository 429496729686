<div [class.overlay]="loading$ | async">
  <h1 mat-dialog-title>Invalidate Token</h1>
  <div mat-dialog-content>
    <form class="form-container" [formGroup]="invalidateTokenForm" (keydown.enter)="$event.preventDefault()">
      <mat-form-field class="slim-form-field">
        <mat-label>ID</mat-label>
        <input matInput placeholder="ID" required formControlName="id" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field class="slim-form-field">
        <mat-label>User ID</mat-label>
        <input matInput placeholder="User ID" required formControlName="userId" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Reason</mat-label>
        <textarea matInput placeholder="Reason" formControlName="reason" rows="4"></textarea>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close class="uppercase-button">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="submitForm()"
      [disabled]="invalidateTokenForm.invalid || (loading$ | async)"
      class="uppercase-button"
    >
      Invalidate
    </button>
  </div>
</div>
<admin-spinner *ngIf="loading$ | async"></admin-spinner>
