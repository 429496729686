import { Component } from '@angular/core';

@Component({
  selector: 'admin-empty-state-table',
  template: `
    <div class="no-data-error">
      <mat-icon svgIcon="warning"></mat-icon>
      <div>No data available</div>
    </div>
  `,
  styleUrls: ['./empty-state.scss']
})
export class EmptyStateTableComponent {}
