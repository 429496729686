import { inject, Pipe, PipeTransform } from '@angular/core';

import { LogicRuleBuilderHelperService } from '@shared/services/logic-rule-builder-helper/logic-rule-builder-helper.service';
import { LogicRuleDateRangeValue, LogicRuleValue } from '@shared/types/logic-rule.type';

import { AttributeType, LogicRule, LogicRuleAttribute } from './../types/logic-rule.type';

export interface LogicRuleLabels {
  attributeName: string;
  operatorLabel: string;
  isCustomDateTimeOperator: boolean;
  value: LogicRuleValue;
  timeRange: string | null;
  type: AttributeType;
}

@Pipe({
  name: 'logicRuleSummary'
})
export class LogicRuleSummaryPipe implements PipeTransform {
  private logicRuleBuilderHelperService = inject(LogicRuleBuilderHelperService);

  transform(input: LogicRule, parentKey: string, parentNodeValue: LogicRuleValue | null): LogicRuleLabels | null {
    const { attribute: key } = input;
    let foundAttribute: LogicRuleAttribute | null = null;

    if (typeof parentNodeValue === 'string') {
      /**
       * Moving the `${parentKey}.${key}` into 1st place as this the base combination
       * where the find method is stop in the first place
       */
      [`${parentKey}.${key}`, `${parentKey}.${parentNodeValue}.${key}`, `${parentKey}.shared.${key}`].find(
        attributeKey => {
          foundAttribute = this.logicRuleBuilderHelperService.getAttribute(attributeKey);
          return !!foundAttribute;
        }
      );
    } else {
      foundAttribute = this.logicRuleBuilderHelperService.getAttribute(
        [parentKey, key].filter(item => !!item).join('.')
      );
    }

    if (!foundAttribute) {
      return null;
    }

    let value = input.value;
    if (foundAttribute.type === 'select') {
      value = foundAttribute.resources.data!.find(opt => opt.value === input.value)?.label ?? input.value;
    }

    if (foundAttribute.type === 'dependent_select') {
      value =
        this.logicRuleBuilderHelperService
          .getAllDependentSelectResource(foundAttribute.attribute)
          .find(option => option.value === input.value)?.label ?? input.value;
    }

    const isCustomDateTimeOperator = this.logicRuleBuilderHelperService.isCustomDateTimeOperator(input.operator);
    if (isCustomDateTimeOperator) {
      value = (input.value as LogicRuleDateRangeValue).join(' ');
    }

    return {
      type: foundAttribute.type,
      attributeName: foundAttribute.displayName,
      operatorLabel: foundAttribute.operators.find(op => op.value === input.operator)?.label!,
      isCustomDateTimeOperator,
      value,
      timeRange: foundAttribute.isCumulativeType
        ? foundAttribute.timeRangeResource?.data!.find(op => op.value === input.timeRange)!.label!
        : null
    };
  }
}
