import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { NewRedemptionRate, RedemptionRate, RedemptionRateResult, RedemptionRatesFilter } from '../../types';

export const loadRedemptionRates = createAction(
  '[Redemption Rates] Load Redemption Rates',
  props<{ filter: RedemptionRatesFilter }>()
);
export const loadRedemptionRatesSuccess = createAction(
  '[Redemption Rates] Load Redemption Rates Success',
  props<{ result: RedemptionRateResult }>()
);
export const loadRedemptionRatesFailure = createAction(
  '[Redemption Rates] Load Redemption Rates Failure',
  props<ErrorState>()
);

export const loadRedemptionRate = createAction('[Redemption Rates] Load Redemption Rate', props<{ id: string }>());
export const loadRedemptionRateSuccess = createAction(
  '[Redemption Rates] Load Redemption Rate Success',
  props<{ redemptionRate: RedemptionRate }>()
);
export const loadRedemptionRateFailure = createAction(
  '[Redemption Rates] Load Redemption Rate Failure',
  props<ErrorState>()
);

export const fetchRedemptionRate = createAction('[Redemption Rates] Fetch Redemption Rate', props<{ id: string }>());
export const fetchRedemptionRateSuccess = createAction(
  '[Redemption Rates] Fetch Redemption Rate Success',
  props<{ redemptionRate: RedemptionRate }>()
);
export const fetchRedemptionRateFailure = createAction(
  '[Redemption Rates] Fetch Redemption Rate Failure',
  props<{ id: string }>()
);

export const createRedemptionRate = createAction(
  '[Redemption Rates] Create Redemption Rate',
  props<{ redemptionRate: NewRedemptionRate }>()
);
export const createRedemptionRateSuccess = createAction(
  '[Redemption Rates] Create Redemption Rate Success',
  props<{ redemptionRate: RedemptionRate }>()
);
export const createRedemptionRateFailure = createAction(
  '[Redemption Rates] Create Redemption Rate Failure',
  props<ErrorState>()
);

export const updateRedemptionRate = createAction(
  '[Redemption Rates] Update Redemption Rate',
  props<{ redemptionRate: RedemptionRate }>()
);
export const updateRedemptionRateSuccess = createAction(
  '[Redemption Rates] Update Redemption Rate Success',
  props<{ redemptionRate: RedemptionRate }>()
);
export const updateRedemptionRateFailure = createAction(
  '[Redemption Rates] Update Redemption Rate Failure',
  props<ErrorState>()
);

export const deleteRedemptionRate = createAction('[Redemption Rates] Delete Redemption Rate', props<{ id: string }>());
export const deleteRedemptionRateSuccess = createAction('[Redemption Rates] Delete Redemption Rate Success');
export const deleteRedemptionRateFailure = createAction(
  '[Redemption Rates] Delete Redemption Rate Failure',
  props<ErrorState>()
);
