import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadPointsAccounts } from '../store/actions/points-accounts.actions';
import { PointsAccountState } from '../types';

export const pointsAccountsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<PointsAccountState> = inject(Store<PointsAccountState>),
  scopes: Scopes = inject(Scopes)
): void => {
  if (!route.parent.parent.data.isCustomerView) {
    return;
  }

  const userId = Params.find(route, 'userId');
  if (
    route.data?.isAutoRedemptionView &&
    scopes.hasAny(['view_auto_redemption_settings', ...SCOPES_OR.viewPointsAccounts])
  ) {
    return store.dispatch(loadPointsAccounts({ userId, pointsActivitiesLoad: 'disabled' }));
  } else if (scopes.hasAny(SCOPES_OR.viewPointsAccounts)) {
    return store.dispatch(loadPointsAccounts({ userId, pointsActivitiesLoad: 'only-initial' }));
  }
};
