import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { EarnRule, EarnRuleResult, EarnRulesFilter, EarnRulesResult } from '../../types';

export const loadEventEarnRules = createAction('[Event Earn Rules] Load Event Earn Rules');
export const loadEventEarnRulesSuccess = createAction(
  '[Event Earn Rules] Load Event Earn Rules Success',
  props<{ result: EarnRulesResult }>()
);
export const loadEventEarnRulesFailure = createAction(
  '[Event Earn Rules] Load Event Earn Rules Failure',
  props<ErrorState>()
);

export const loadEventEarnRule = createAction('[Event Earn Rules] Load Event Earn Rule', props<{ groupId: string }>());
export const loadEventEarnRuleSuccess = createAction(
  '[Event Earn Rules] Load Event Earn Rule Success',
  props<{ result: EarnRuleResult }>()
);
export const loadEventEarnRuleFailure = createAction(
  '[Event Earn Rules] Load Event Earn Rule Failure',
  props<ErrorState>()
);

export const setEventEarnRulesFilter = createAction(
  '[Event Earn Rules] Set Event Earn Rules Filter',
  props<{ filter: EarnRulesFilter }>()
);

export const createEventEarnRule = createAction(
  '[Event Earn Rules] Create Event Earn Rule',
  props<{ eventEarnRule: EarnRule }>()
);
export const createEventEarnRuleSuccess = createAction(
  '[Event Earn Rules] Create Event Earn Rules Success',
  (
    eventEarnRule: EarnRule,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully created a regular points rule'
    }
  ) => ({ eventEarnRule, snackbarMessages })
);
export const createEventEarnRuleFailure = createAction(
  '[Event Earn Rules] Create Event Earn Rules Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not create your Event Earn Rule due to technical issues on our end. ' +
        'Please try creating your rule again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateEventEarnRulesPriorities = createAction(
  '[Event Earn Rules] Update Event Earn Rules Priorities',
  props<{ groupIds: string[] }>()
);
export const updateEventEarnRulesPrioritiesSuccess = createAction(
  '[Event Earn Rules] Update Event Earn Rules Priorities Success',
  (
    result: EarnRulesResult,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully updated your Event Earn Rule priority'
    }
  ) => ({ result, snackbarMessages })
);
export const updateEventEarnRulesPrioritiesFailure = createAction(
  '[Event Earn Rules] Update Event Earn Rules Priorities Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      LE1001:
        'We were not able to change the rank order for this rule as the page is outdated. ' +
        'Please refresh and try changing the rank order again. If the issue persists, contact our Help Center',
      default:
        'You were not able to change the priority for this rule due to technical issues on our end. ' +
        'Please try changing the priority again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateEventEarnRule = createAction(
  '[Event Earn Rules] Update Event Earn Rule',
  props<{ eventEarnRule: Partial<EarnRule> }>()
);
export const updateEventEarnRuleSuccess = createAction(
  '[Event Earn Rules] Update Event Earn Rule Success',
  (
    result: EarnRuleResult,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully.'
    }
  ) => ({ result, snackbarMessages })
);
export const updateEventEarnRuleFailure = createAction(
  '[Event Earn Rules] Update Event Earn Rule Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      LE1001:
        'We were not able to update the Event Earn Rule as the page is outdated. ' +
        'Please refresh and try updating the Event Earn Rule again. If the issue persists, contact our Help Center.',
      default:
        'You were not able to update this Event Earn Rule due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const deactivateEventEarnRule = createAction(
  '[Event Earn Rules] Deactivate Event Earn Rule',
  props<{ groupId: string }>()
);
export const deactivateEventEarnRuleSuccess = createAction(
  '[Event Earn Rules] Deactivate Event Earn Rule Success',
  (
    result: EarnRuleResult,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully deactivated the rule'
    }
  ) => ({ result, snackbarMessages })
);
export const deactivateEventEarnRuleFailure = createAction(
  '[Event Earn Rules] Deactivate Event Earn Rule Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      LE1001:
        'We were not able to deactivate the Event Earn Rule as the page is outdated. ' +
        'Please refresh and try deactivating the Event Earn Rule again. If the issue persists, contact our Help Center.',
      default:
        'You were not able to deactivate this Event Earn Rule due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
