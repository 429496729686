import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { loadTenantBootstrap } from '@core/store/tenant-bootstrap/actions/tenant-bootstrap.actions';
import { tenantBootstrapQuery } from '@core/store/tenant-bootstrap/selectors/tenant-bootstrap.selectors';
import { TenantBootstrapState } from '@core/types';
import { createResolver } from '@shared/route/resolver-helper';

export const tenantBootstrapResolver: ResolveFn<void> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<void> =>
  createResolver<TenantBootstrapState>({
    query: tenantBootstrapQuery.isTenantBootstrapLoaded,
    action: loadTenantBootstrap()
  });
