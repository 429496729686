<mat-card
  appearance="outlined"
  class="mat-elevation-z1 mat-card data-table-container"
  *ngIf="userMfaIdentity$ | async as userMfaIdentities"
>
  <mat-card-content>
    <mat-card-title class="identity-product-card-title" fxLayout="row" fxLayoutAlign="space-between center">
      MFA identities
      <button
        *adminIfScopesInclude="SCOPES.createMfaIdentities; relationOperator: 'or'"
        mat-raised-button
        color="primary"
        class="uppercase-button__text"
        (click)="openCreateMfaIdentityDialog()"
      >
        Create MFA Identity
      </button>
    </mat-card-title>
    <table mat-table [dataSource]="userMfaIdentities" class="data-table">
      <ng-container *ngFor="let column of mfaIdentityColumns" [matColumnDef]="column.key">
        <th mat-header-cell *matHeaderCellDef>
          <h4 class="text-primary">{{ column.label }}</h4>
        </th>
        <ng-container *ngIf="column.key === 'id'; else elseBlock">
          <td mat-cell *matCellDef="let identity" class="cell action-col">
            <!-- Supporting only email verifications for now. -->
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
              <ng-container *adminIfScopesInclude="SCOPES.verifyIdentities; relationOperator: 'or'">
                <button
                  *ngIf="identity.providerId === 'email'"
                  mat-raised-button
                  color="primary"
                  class="uppercase-button__text"
                  (click)="onMfaActions({ actionType: 'sendVerification', mfaIdentity: identity })"
                >
                  Send verification
                </button>
              </ng-container>
              <ng-container *adminIfScopesInclude="SCOPES.updateMfaIdentities; relationOperator: 'or'">
                <button
                  *ngIf="!(identity | hideMfaIdentityButtonPipe: 'edit')"
                  mat-raised-button
                  color="primary"
                  class="uppercase-button__text"
                  (click)="openEditMfaIdentityDialog(identity)"
                >
                  Edit MFA Identity
                </button>
              </ng-container>
              <ng-container *adminIfScopesInclude="SCOPES.deleteMfaIdentities; relationOperator: 'or'">
                <admin-tooltip key="users.details.button.deleteMfaIdentity" [displayIcon]="false">
                  <button
                    *ngIf="!(identity | hideMfaIdentityButtonPipe: 'delete')"
                    mat-raised-button
                    color="warn"
                    class="uppercase-button__text"
                    (click)="onMfaActions({ actionType: 'deleteMfaIdentity', mfaIdentity: identity })"
                  >
                    Delete MFA Identity
                  </button>
                </admin-tooltip>
              </ng-container>
            </div>
          </td>
        </ng-container>
        <ng-template #elseBlock>
          <td mat-cell *matCellDef="let identity" [innerHTML]="identity | renderAttribute: column" class="cell"></td>
        </ng-template>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="mfaIdentityColumnKeys" class="bg-secondary"></tr>
      <tr mat-row *matRowDef="let row; columns: mfaIdentityColumnKeys"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No MFA identities available</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
