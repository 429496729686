/*
'routeReuseStrategy' is deprecated in the latest version of Angular.
In order to retain the same functionality we have to provide a custom class that override the shouldReuseRoute function.
This is to prevent displaying of incorrect data when navigate between the same route e.g. /app/admin-panel -> /app/mission-control
*/

import { BaseRouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(): boolean {
    return false;
  }
}
