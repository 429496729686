import { Identity, IdentityState } from '../types';

export const mockIdentity: Identity = {
  tenantId: 'AscendaGo',
  uid: 'ABC123456',
  providerId: 'ascenda_google_oauth2',
  userId: '1234-abcd-4321',
  reference: 'abcd1234',
  createdAt: '2021-08-05 08:09:28 +0000',
  updatedAt: '2021-08-06 06:26:48 +0000',
  customData: {}
};

export const mockIdentities: Identity[] = [
  {
    tenantId: 'AscendaGo',
    uid: 'ABC123459',
    providerId: 'ascenda_google_oauth2',
    userId: '1',
    reference: 'efgh5678',
    createdAt: '2021-08-05 08:09:28 +0000',
    updatedAt: '2021-08-06 06:26:48 +0000',
    customData: {}
  },
  {
    tenantId: 'AscendaGo',
    uid: 'ABC123454',
    userId: '1',
    reference: 'ijkl9012',
    providerId: 'fab',
    createdAt: '2021-08-21 07:20:39 +0000',
    updatedAt: '2021-08-25 10:00:44 +0000',
    customData: {}
  }
];

export const mockEmailIdentity: Identity = { ...mockIdentity, providerId: 'email' };
export const mockPasswordIdentity: Identity = { ...mockIdentity, providerId: 'password' };

export function generateIdentityState(identities: Identity[] = []): IdentityState {
  return {
    ids: identities.map(identity => identity.reference),
    entities: identities.reduce((dict, identity) => {
      dict[identity.reference] = identity;
      return dict;
    }, {}),
    callState: {
      single: 'resting',
      batch: 'resting'
    }
  };
}
