import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { FEATURE_AVAILABILITY } from '@shared/tokens/feature-availability.token';
import { DEMO_TENANT } from '@shared/tokens/tenant.tokens';
import { AvailabilityStatus } from '@shared/types';
import { HostTenantMappingUtils, StyleSheetUtils } from '@utils';

import { EncryptedFields, Environment, TimezoneMapping } from './app/app-module-config';
import { AppModule } from './app/app.module';
import { versions } from './app/git-revision';
import { PaymentTierId } from './app/modules/payment-tiers/types/payment-tiers.type';
import { RollbarService } from './app/services/rollbar/rollbar.service';
import { getSentryLogger } from './app/services/sentry/sentry.factory';
import { TimezoneUtils } from './app/utils/timezone-utils';
import { createEnvVariableProviders, getMcBootstrap, handleError } from './bootstrap';

let env: string;

declare global {
  interface Window {
    initMap: () => void;
  }
}

interface McBootstrap {
  id: string;
  apiDomain: string;
  environment: Environment;
  featureAvailability?: Record<string, AvailabilityStatus>;
  timezone_mapping: TimezoneMapping;
  supportedCountryCodes: string[];
  supportedCurrencyCodes: string[];
  paymentTierId: PaymentTierId;
  piiFields: EncryptedFields;
  maskingEnabled: boolean;
  isDemo: boolean;
  amplitudeApiKey?: string;
}

try {
  const mcEndpoint = HostTenantMappingUtils.mapHostToMcEndpoint();
  setupApp(mcEndpoint);
} catch (error) {
  handleError(error);
}

export function getEnv(): string {
  return env;
}

function setupSentry(appEnv?: Environment['env'], allowNetWorkDetailsUrls: string[] = []): void {
  // Won't initialize Sentry for development environment
  if (!appEnv || appEnv === 'devstaging') {
    return;
  }

  Sentry.init({
    dsn: 'https://e05caaa38853c3ab1f90931c78152d49@o4507248894541824.ingest.us.sentry.io/4507582127341568',
    environment: appEnv,
    // We will use long commit hash as release version
    // cause Sentry webpack plugin as use long commit hash
    // so we need to match those two to get correct source maps and commits
    release: versions.longRevision,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        // do not mask text and media on staging/uat to make debugging easier
        maskAllText: appEnv === 'production',
        blockAllMedia: appEnv === 'production',
        // only log network requests on staging/uat
        networkDetailAllowUrls: appEnv === 'production' ? [] : [window.location.host, ...allowNetWorkDetailsUrls],
        networkDetailDenyUrls: ['api.rollbar.com']
      })
    ],

    // Trace sample rate
    tracesSampleRate: 0.05,

    // Session sample rate
    replaysSessionSampleRate: appEnv === 'production' ? 0.1 : 0.05,
    replaysOnErrorSampleRate: appEnv === 'production' ? 0.02 : appEnv === 'uat' ? 0.01 : 0,

    // send PII in staging and UAT for debugging
    // PII will include headers and cookies of users
    sendDefaultPii: appEnv !== 'production',

    attachStacktrace: true
  });
}

function setupApp(mcEndpoint: string): void {
  getMcBootstrap(mcEndpoint)
    .then(
      ({
        environment,
        apiDomain,
        timezone_mapping: timezoneMapping,
        supportedCountryCodes,
        supportedCurrencyCodes,
        featureAvailability,
        paymentTierId,
        piiFields,
        maskingEnabled,
        isDemo,
        amplitudeApiKey
      }: McBootstrap) => {
        env = environment.env;

        window.initMap = () => {};
        const script: HTMLScriptElement = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapApiKey}&callback=initMap&libraries=visualization`;

        document.head.append(script);
        StyleSheetUtils.injectTenantStyleSheet();
        injectTenantFavicon();

        const host = HostTenantMappingUtils.getHost();

        if (host.includes('dev')) {
          environment.env = 'devstaging';
          environment.redirectUri = window.location.origin;

          if (environment.secureFieldsConfigs) {
            environment.secureFieldsConfigs.autoModeConfigKey =
              environment.secureFieldsConfigs.autoModeConfigKey.replace('staging', 'development');
            environment.secureFieldsConfigs.submitModeConfigKey =
              environment.secureFieldsConfigs.submitModeConfigKey.replace('staging', 'development');
          }
        } else if (environment.production) {
          enableProdMode();
        }

        // Setting up Sentry after we have finalized the environment
        // we may need to add mc and gh api endpoints to allow list as well
        setupSentry(environment.env, [environment.missionControlApiBaseUrl, environment.guardhouseApiUrl]);

        // fallback to Asia/Singapore timezone if environment.timezone is not defined
        // timezone: +0800
        // timezoneOffset: 28800
        let timezone = environment.timezone && TimezoneUtils.getTimezoneOffset(environment.timezone, timezoneMapping);

        if (timezone === undefined) {
          const rollbar = new RollbarService(environment.env);
          const sentryLogger = getSentryLogger(environment.env);

          rollbar.handleError('Timezone is undefined');
          sentryLogger.error('Timezone is undefined');

          timezone = '+0800';
        }

        // dayjs plugins that we want to apply to the entire codebase:
        dayjs.extend(isSameOrAfter);

        const providers = [
          ...createEnvVariableProviders(environment),
          { provide: 'timezone', useValue: timezone },
          { provide: 'timezoneMapping', useValue: timezoneMapping },
          {
            provide: 'timezoneOffset',
            useValue: timezoneMapping[environment?.timezone] ?? 28_800
          },
          { provide: 'apiDomain', useValue: apiDomain },
          { provide: 'supportedCountryCodes', useValue: supportedCountryCodes },
          { provide: 'supportedCurrencyCodes', useValue: supportedCurrencyCodes },
          { provide: 'paymentTierId', useValue: paymentTierId },
          { provide: 'encryptedFields', useValue: piiFields },
          { provide: 'piiMaskingEnabled', useValue: maskingEnabled },
          { provide: DEMO_TENANT, useValue: isDemo },
          { provide: 'amplitudeApiKey', useValue: amplitudeApiKey },
          {
            provide: FEATURE_AVAILABILITY,
            useValue: featureAvailability ?? {}
          }
        ];

        platformBrowserDynamic(providers)
          .bootstrapModule(AppModule)
          .catch(error => handleError(error));
      }
    )
    .catch(error => handleError(error));
}

function injectTenantFavicon(): void {
  const tenantFavicon = HostTenantMappingUtils.mapHostToTenantFavicon();
  const link = document.querySelector("link[href*='favicon.ico']") as HTMLLinkElement;

  link.href = link.href.replace('favicon.ico', `assets/${tenantFavicon}`);
}
