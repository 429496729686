<h1 mat-dialog-title>Create MFA Identity</h1>

<form [formGroup]="createForm" (keydown.enter)="$event.preventDefault()">
  <div fxLayout="column">
    <mat-form-field class="slim-form-field">
      <mat-label>Provider ID</mat-label>
      <mat-select required formControlName="providerId" disableOptionCentering panelClass="dropdown-panel">
        <mat-option *ngFor="let providerId of providerIdOptions" [value]="providerId">
          {{ providerId | customTitleCase: 'fromSnakeCase' }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
    </mat-form-field>
    <mat-form-field class="slim-form-field">
      <mat-label>UID</mat-label>
      <input matInput placeholder="UID" formControlName="uid" />
      <mat-error *ngIf="uidControl.hasError('required')">Required</mat-error>
      <mat-error *ngIf="uidControl.hasError('email')">Invalid email</mat-error>
      <mat-error *ngIf="uidControl.hasError('invalidE164')">{{ invalidE164Message }}</mat-error>
    </mat-form-field>
  </div>
</form>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button
    mat-raised-button
    color="accent"
    class="uppercase-button text-primary cancel-button"
    (click)="dialogRef.close(false)"
  >
    <span>Cancel</span>
  </button>

  <button
    mat-raised-button
    color="primary"
    class="uppercase-button"
    (click)="submitForm()"
    [disabled]="createForm.invalid"
  >
    <span>Create</span>
  </button>
</div>
