import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ImageUploadResponse } from '@shared/types';

import { ImageUploadResourceType, UploadImageActionPayload } from '../types';

@Injectable({
  providedIn: 'root'
})
export class UploadImagesService {
  private resourceEndpoints = {
    loyaltyPrograms: 'loyalty_programs/upload_image',
    partnerConfigs: 'partner_config/upload_image',
    tenantCampaigns: 'tenant_campaigns/upload_image'
  };

  constructor(private missionControlService: MissionControlService) {}

  uploadImage(resource: ImageUploadResourceType, payload: UploadImageActionPayload): Observable<ImageUploadResponse> {
    const { file, params } = payload;
    return this.missionControlService.post<ImageUploadResponse>(
      this.resourceEndpoints[resource],
      file,
      new HttpParams({ fromObject: { ...params } })
    );
  }
}
