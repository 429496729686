import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';

@Pipe({ name: 'urlToLink' })
export class UrlToLinkPipe implements PipeTransform {
  transform(value: string): Nullable<string> {
    if (!value || typeof value !== 'string') {
      return null;
    }

    const urlRegex = /(\bhttps?:\/\/(?!\/)[-A-Z0-9+{}&@#/%?=~_|!:,.;]*[-A-Z0-9+}&@#/%=~_|])/gim;
    const link = '<a color="primary" href="$1" target="_blank" rel="noreferrer noopener">LINK</a>';

    return value.replaceAll(urlRegex, link);
  }
}
