import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { OffersService } from '../../../offers/services/offers.service';
import { fetchOffer } from '../../../offers/store/actions/offers.actions';
import { offersQuery } from '../../../offers/store/selectors/offers.selectors';
import { Offer, OfferState } from '../../../offers/types';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({
  providedIn: 'root'
})
export class OfferTagDefs {
  readonly tagDefs: TagDefs<Offer> = {
    offer_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showOffers)
          ? combineLatest([
              this.offerStore.select(offersQuery.getOfferById(id)),
              this.offerStore.select(offersQuery.getFetchList)
            ]).pipe(
              first(),
              map(([offer, fetchList]) => {
                if (!offer && !fetchList.includes(id)) {
                  this.offerStore.dispatch(fetchOffer({ id }));
                }
              })
            )
          : of(null),
      select: id => this.offerStore.pipe(select(offersQuery.getOfferById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showOffers)
          ? this.offerStore.pipe(
              select(offersQuery.getOfferById(id)),
              first(),
              exhaustMap(offer => (offer ? of(offer) : this.offerService.getOffer(id)))
            )
          : of(null),
      getDisplayPrefix: () => 'Offer',
      getDisplayValue: (offer: Offer) => `"${offer.title} (${offer.brand})"`,
      getRouteLink: (offer: Offer) => `/offers/${offer.id}/details`
    }
  };

  constructor(
    private offerStore: Store<OfferState>,
    private offerService: OffersService,
    private scopes: Scopes
  ) {}
}
