/* eslint-disable no-underscore-dangle */
import { DecodedAccessToken, DecodedIdToken } from '../shared/decoded_token';
var Store = /** @class */ (function () {
    function Store() {
    }
    Object.defineProperty(Store.prototype, "result", {
        // eslint-disable-next-line accessor-pairs
        set: function (hermesTokenResult) {
            this._idToken = hermesTokenResult.idToken;
            this._accessToken = hermesTokenResult.accessToken;
            this._decodedIdToken = new DecodedIdToken(hermesTokenResult.idToken);
            this._decodedAccessToken = new DecodedAccessToken(hermesTokenResult.accessToken);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "decodedIdToken", {
        get: function () {
            return this._decodedIdToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "decodedAccessToken", {
        get: function () {
            return this._decodedAccessToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "idToken", {
        get: function () {
            return this._idToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "accessToken", {
        get: function () {
            return this._accessToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "decodedAccessOrIdToken", {
        get: function () {
            var _a;
            return (_a = this.decodedAccessToken) !== null && _a !== void 0 ? _a : this.decodedIdToken;
        },
        enumerable: false,
        configurable: true
    });
    return Store;
}());
// export as singleton
export default new Store();
