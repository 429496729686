import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { TenantLocale, tenantLocaleAdapter, TenantLocaleState } from '../../types';

const getTenantLocaleState = createFeatureSelector<TenantLocaleState>('tenantLocale');
const tenantLocaleDetector = createDetector<TenantLocale>();

const { selectAll: getTenantLocalesList, selectEntities: getTenantLocalesDictionary } =
  tenantLocaleAdapter.getSelectors(getTenantLocaleState);

const { isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<TenantLocaleState>(getTenantLocaleState);

const isTenantLocalesLoaded = createSelector(
  getTenantLocalesDictionary,
  isBatchResting,
  (tenantLocales, batchResting) => tenantLocaleDetector('allLoaded')(tenantLocales) && batchResting
);

const getEnabledTenantLocales = createSelector(getTenantLocalesList, tenantLocales =>
  tenantLocales.filter(locale => locale.enabled)
);

export const tenantLocalesQuery = {
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isTenantLocalesLoaded,
  getTenantLocalesList,
  getTenantLocalesDictionary,
  getEnabledTenantLocales
};
