import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { WebhookSettingsCreateRequest, WebhookSettingsResults, WebhookSettingsUpdateRequest } from '../../types';

export const loadWebhookSettings = createAction('[Webhook Settings] Load Webhook Settings');
export const loadWebhookSettingsSuccess = createAction(
  '[Webhook Settings] Load Webhook Settings Success',
  props<{ webhookSettingsResults: WebhookSettingsResults }>()
);
export const loadWebhookSettingsFailure = createAction(
  '[Webhook Settings] Load Webhook Settings Failure',
  props<ErrorState>()
);

export const createWebhookSettings = createAction(
  '[Webhook Settings] Create Webhook Settings',
  props<{ webhookSettings: WebhookSettingsCreateRequest }>()
);
export const createWebhookSettingsSuccess = createAction(
  '[Webhook Settings] Create Webhook Settings Success',
  (
    webhookSettingsResults: WebhookSettingsResults,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully.'
    }
  ) => ({ webhookSettingsResults, snackbarMessages })
);
export const createWebhookSettingsFailure = createAction(
  '[Webhook Settings] Create Webhook Settings Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not save your changes due to technical issues on our end. Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateWebhookSettings = createAction(
  '[Webhook Settings] Update Webhook Settings',
  props<{ webhookSettings: WebhookSettingsUpdateRequest }>()
);
export const updateWebhookSettingsSuccess = createAction(
  '[Webhook Settings] Update Webhook Settings Success',
  (
    webhookSettingsResults: WebhookSettingsResults,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully.'
    }
  ) => ({ webhookSettingsResults, snackbarMessages })
);
export const updateWebhookSettingsFailure = createAction(
  '[Webhook Settings] Update Webhook Settings Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not save your changes due to technical issues on our end. Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
