import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { HttpError } from '@core/types';

import { TicketsService } from '../../services/tickets.service';
import { submitTicket, submitTicketFailure, submitTicketSuccess } from '../actions/tickets.actions';

@Injectable()
export class TicketsEffects {
  submitTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitTicket),
      exhaustMap(({ ticket }) =>
        this.ticketService.submitTicket(ticket).pipe(
          map(() => submitTicketSuccess()),
          catchError((error: HttpError) => of(submitTicketFailure(error)))
        )
      )
    )
  );

  submitTicketSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitTicketSuccess),
      map(() => routerForceNavigate({ path: '/' }))
    )
  );

  constructor(
    private actions$: Actions,
    private ticketService: TicketsService
  ) {}
}
