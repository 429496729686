<mat-card
  appearance="outlined"
  class="mat-elevation-z1"
  [ngClass]="customClassName"
  [class.overlay]="loading"
  [class.with-error]="error"
>
  <ng-container [ngSwitch]="renderState">
    <admin-spinner *ngSwitchCase="'loading'"/>
    <div *ngSwitchCase="'error'" fxLayout="row" fxLayoutAlign="center center" fxFlex>
      <div>
        <h4><mat-icon [inline]="true">warning</mat-icon> Loading points summary failed</h4>
      </div>
    </div>
    <ng-container *ngSwitchCase="'content'">
      <div *ngIf="!pointsSummaryData; else displayContent" fxLayout="row" fxLayoutAlign="center center" fxFlex>
        <h4>
          <mat-icon [inline]="true">warning</mat-icon> Customer does not have points accounts loaded in the system.
          Please wait until customer accounts are loaded via files or contact tech support.
        </h4>
      </div>
      <ng-template #displayContent>
        <mat-card-content fxFlex>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="padded-row">
            <div class="item balance" fxLayout="start center">
              <h5 class="balance__title">Balance</h5>
              <h2 class="balance__amount">
                {{ pointsSummaryData.pointsBalance | roundedNumber: pointsBalanceDecimals }}
              </h2>
              <h3 class="balance__unit">{{ loyaltyCurrency | titlecase }}</h3>
            </div>
            <div class="item" *ngIf="displayPointsAdjustmentButton">
              <button
                *adminIfScopesInclude="SCOPES.manualAdjustment; relationOperator: 'or'"
                mat-raised-button
                class="uppercase-button"
                color="primary"
                (click)="openPointsAdjustmentDialog()"
              >
                <mat-icon class="uppercase-button__icon">tune</mat-icon>
                <span class="uppercase-button__text">Adjust balance</span>
              </button>
            </div>
          </div>
        </mat-card-content>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-card>
