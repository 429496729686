import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { RolesService } from '../../services/roles.service';
import {
  batchCreateDeletePermissions,
  batchCreateDeletePermissionsFailure,
  batchCreateDeletePermissionsSuccess,
  createRole,
  createRoleFailure,
  createRoleSuccess,
  loadRole,
  loadRoleFailure,
  loadRoles,
  loadRolesFailure,
  loadRolesSuccess,
  loadRoleSuccess,
  updateRolePermissions,
  updateRolePermissionsFailure,
  updateRolePermissionsSuccess
} from '../actions/roles.actions';

@Injectable()
export class RolesEffects {
  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRoles),
      exhaustMap(() =>
        this.rolesService.getRoles().pipe(
          map(roles => loadRolesSuccess({ roles })),
          catchError(error => of(loadRolesFailure({ error })))
        )
      )
    )
  );

  loadRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRole),
      exhaustMap(action =>
        this.rolesService.getRole(action.name).pipe(
          map(role => loadRoleSuccess({ role })),
          catchError(error => of(loadRoleFailure({ error })))
        )
      )
    )
  );

  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRole),
      exhaustMap(action =>
        this.rolesService.createRole(action.role).pipe(
          map(role => createRoleSuccess({ role })),
          catchError(error => of(createRoleFailure({ error })))
        )
      )
    )
  );

  updateRolePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRolePermissions),
      exhaustMap(action =>
        this.rolesService.updateRolePermissions(action.permissions, action.role).pipe(
          map(role => updateRolePermissionsSuccess({ role })),
          catchError(error => of(updateRolePermissionsFailure({ error })))
        )
      )
    )
  );

  createOrUpdateRoleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRoleSuccess, updateRolePermissionsSuccess),
      map(({ role }) => routerForceNavigate({ path: `roles/${role.name}` }))
    )
  );

  batchCreateDeletePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(batchCreateDeletePermissions),
      exhaustMap(({ permissions }) =>
        this.rolesService.updateRolePermissions(permissions).pipe(
          map(() => batchCreateDeletePermissionsSuccess()),
          catchError(error => of(batchCreateDeletePermissionsFailure({ error })))
        )
      )
    )
  );

  batchCreateDeletePermissionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(batchCreateDeletePermissionsSuccess),
      map(() => loadRoles())
    )
  );

  constructor(
    private actions$: Actions,
    private rolesService: RolesService
  ) {}
}
