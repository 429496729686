<div class="basic-info">
  <div class="header">{{ headerName }}</div>
  <div class="button-group">
    <ng-container *ngIf="headerName === 'User Information'">
      <button
        *adminIfScopesInclude="
          [].concat(blockUnblockUsersScopes, blockUnblockLoginUsersScopes, SCOPES.userSession);
          relationOperator: 'or'
        "
        (click)="openAdvancedActionsDialog()"
        class="secondary-button"
      >
        <mat-icon matPrefix svgIcon="advanced-actions"></mat-icon>
        Advanced actions
      </button>

      <button *ngIf="isPersonalRoute" (click)="openNewTab(isRC ? 'password/edit' : 'profile')" class="primary-button">
        <mat-icon matPrefix svgIcon="impersonate-user"></mat-icon>
        {{ isRC ? 'Change Password' : 'Go to My Profile' }}
      </button>
    </ng-container>

    <ng-container *ngIf="!isAdminUser && !(loading$ | async)">
      <admin-tooltip
        [key]="'users.header.button.sign-in-as-user?allowed=' + !disableImpersonation"
        [displayIcon]="false"
      >
        <button
          *adminIfScopesInclude="['impersonate_user']"
          (click)="impersonateUser()"
          [disabled]="disableImpersonation"
          [class.disabled-button]="disableImpersonation"
          class="primary-button"
        >
          <mat-icon matPrefix svgIcon="impersonate-user"></mat-icon>
          Impersonate user
        </button>
      </admin-tooltip>

      <admin-tooltip
        [key]="'users.header.button.sign-in-as-user?allowed=' + !disableImpersonation"
        [displayIcon]="false"
      >
        <button
          *adminIfScopesInclude="['impersonate_user:read_only']"
          (click)="impersonateUser(true)"
          [disabled]="disableImpersonation"
          [class.disabled-button]="disableImpersonation"
          class="primary-button"
        >
          <mat-icon matPrefix svgIcon="eye-open"></mat-icon>
          Impersonate user (read-only)
        </button>
      </admin-tooltip>
    </ng-container>
  </div>
</div>

<div class="user-info">
  <div>
    <span>{{ displayedUserName?.length ? displayedUserName : '' }}</span>
    <span *ngIf="displayedUserName?.length && providerUidText"> | </span>
    <span *ngIf="providerUidText">{{ providerUidText }}</span>
  </div>

  <div *ngIf="user">
    <button
      *ngIf="user | stateDisplayPipe"
      mat-flat-button
      disabled
      class="status-button-in-table"
      [ngClass]="userStateDisplayClass[user | stateDisplayPipe] || 'status-neutral'"
    >
      <span class="dot"></span>{{ user | stateDisplayPipe | customTitleCase: 'fromSnakeCase' }}
    </button>
  </div>
</div>

<hr />
