import { first, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { HttpError } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  constructor(
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  downloadFile(service: Observable<Blob>, fileName: string, confirmText: string): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          confirmText,
          confirmButtonText: 'Yes, confirm'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          service.subscribe(
            blob => {
              const fileURL = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = fileURL;
              link.download = fileName;
              link.click();
            },
            err => {
              const snackbarReference = this.snackBar.open(this.formatErrorMessage(err), 'Dismiss', {
                panelClass: 'multiline-snackbar'
              });
              this.router.events
                .pipe(
                  first(event => event instanceof NavigationEnd),
                  tap(() => snackbarReference.dismiss())
                )
                .subscribe();
            }
          );
        }
      });
  }

  private formatErrorMessage(error: HttpError): string {
    return error.errors?.length > 0
      ? `Error: ${error.errors[0].code} ${error.errors[0].message}`
      : `Error: ${error.status}`;
  }
}
