import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { EventType, EventTypeResult, EventTypesResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class EventTypesService {
  constructor(private missionControlService: MissionControlService) {}

  getEventType(id: string): Observable<EventType> {
    return this.missionControlService
      .get<EventTypeResult>(`event_types/${id}`)
      .pipe(map(evenTypeResult => evenTypeResult.eventType));
  }

  getEventTypes(): Observable<EventType[]> {
    return this.missionControlService
      .get<EventTypesResult>('event_types')
      .pipe(map(evenTypeResult => evenTypeResult.eventTypes));
  }

  createEventType(eventType: EventType): Observable<EventType> {
    return this.missionControlService.post<EventType>('event_types', eventType);
  }

  updateEventType(eventType: EventType): Observable<EventType> {
    return this.missionControlService
      .patch<EventTypeResult>(`event_types/${eventType.id}`, eventType)
      .pipe(map(evenTypeResult => evenTypeResult.eventType));
  }

  deleteEventType(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`event_types/${id}`);
  }
}
