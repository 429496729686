import { exhaustMap, first, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Nullable } from '@shared/types';

import { UsersService } from '../../../users/services/users.service';
import { fetchUser, fetchUsers, fetchUserSuccess } from '../../../users/store/actions/users.actions';
import { usersQuery } from '../../../users/store/selectors/users.selectors';
import { User, UserState } from '../../../users/types';

@Injectable({
  providedIn: 'root'
})
export class FetchUserService {
  constructor(
    private usersService: UsersService,
    private userStore: Store<UserState>,
    private scopes: Scopes
  ) {}

  // TODO: remove fetch function once audit logs & approval requests are refactored to combine entities from the store
  fetch(id: string): Observable<Nullable<User>> {
    return this.scopes.hasAny(SCOPES_OR.showUsers)
      ? this.userStore.pipe(
          select(usersQuery.getUserById(id)),
          first(),
          exhaustMap(user =>
            user
              ? of(user)
              : this.usersService
                  .getUser(id, true)
                  .pipe(tap(fetchedUser => this.userStore.dispatch(fetchUserSuccess({ user: fetchedUser }))))
          )
        )
      : of(null);
  }

  fetchUser(id: string): Observable<Nullable<void>> {
    if (this.scopes.lackScopes(SCOPES_OR.showUsers)) {
      return of(null);
    }
    return combineLatest([
      this.userStore.select(usersQuery.getUserById(id)),
      this.userStore.select(usersQuery.getFetchList)
    ]).pipe(
      first(),
      map(([user, fetchList]) => {
        if (!user && !fetchList.includes(id)) {
          this.userStore.dispatch(fetchUser({ id }));
        }
      })
    );
  }

  fetchUsers(ids: string[] = []): Observable<Nullable<void>> {
    if (this.scopes.lackScopes(SCOPES_OR.showUsers)) {
      return of(null);
    }

    return this.userStore.select(usersQuery.getUserIds).pipe(
      first(),
      map((existingIds: string[]) => {
        const newIds = ids.filter((id: string) => id && !existingIds.includes(id));
        if (newIds.length > 0) {
          return this.userStore.dispatch(fetchUsers({ ids: newIds, batch: true }));
        }
      })
    );
  }
}
