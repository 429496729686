import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { LoginAttemptsFilter } from '../../types/login-attempts-filter.type';
import {
  LoginAttempt,
  loginAttemptAdapter,
  LoginAttemptLocation,
  LoginAttemptState
} from '../../types/login-attempts.type';

const loginAttemptsDetector = createDetector<LoginAttempt>();
const getLoginAttemptState = createFeatureSelector<LoginAttemptState>('loginAttempt');

const { selectAll: getLoginAttemptsList, selectEntities: getLoginAttemptsDirectory } =
  loginAttemptAdapter.getSelectors(getLoginAttemptState);

const {
  isSingleLoading,
  isSingleInitial,
  isSingleResting,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError
} = getCallStateSelectors<LoginAttemptState>(getLoginAttemptState);

const isLoginAttemptsLoaded: Selector<LoginAttemptState, boolean> = createSelector(
  getLoginAttemptsDirectory,
  isBatchResting,
  (loginAttempts, batchResting) => loginAttemptsDetector('allLoaded')(loginAttempts) && batchResting
);

const getLoginAttemptById = (id: string): Selector<LoginAttemptState, LoginAttempt> =>
  createSelector(getLoginAttemptsDirectory, loginAttempts => loginAttempts[id]);

const isLoginAttemptLoaded = (id: string): Selector<LoginAttemptState, boolean> =>
  createSelector(getLoginAttemptsDirectory, loginAttempts => loginAttemptsDetector('oneLoaded')(loginAttempts, id));

const getFilter: Selector<LoginAttemptState, LoginAttemptsFilter> = createSelector(
  getLoginAttemptState,
  loginAttemptState => loginAttemptState.filter
);

const getCount: Selector<LoginAttemptState, number> = createSelector(
  getLoginAttemptState,
  loginAttemptState => loginAttemptState.count
);

const getLocations: Selector<LoginAttemptState, LoginAttemptLocation[]> = createSelector(
  getLoginAttemptsList,
  loginAttempts => loginAttempts.map(loginAttempt => loginAttempt.location)
);

export const loginAttemptsQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  isSingleLoading,
  isSingleInitial,
  isSingleResting,
  getBatchError,
  isLoginAttemptsLoaded,
  isLoginAttemptLoaded,
  getLoginAttemptById,
  getFilter,
  getLoginAttemptsList,
  getCount,
  getLocations
};
