import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { Enrollment, enrollmentAdapter, EnrollmentState } from '../../types';

const enrollmentsDetector = createDetector<Enrollment>();
const getEnrollmentState = createFeatureSelector<EnrollmentState>('enrollment');

const { selectAll: getEnrollmentsList, selectEntities: getEnrollmentsDictionary } =
  enrollmentAdapter.getSelectors(getEnrollmentState);

const { isBatchLoading, isBatchResting, getBatchError } = getCallStateSelectors<EnrollmentState>(getEnrollmentState);

const isEnrollmentsLoaded = createSelector(
  getEnrollmentsDictionary,
  isBatchResting,
  (enrollments, batchResting) => enrollmentsDetector('allLoaded')(enrollments) && batchResting
);

const isUserEnrollmentsLoaded = (userId: string): Selector<EnrollmentState, boolean> =>
  createSelector(
    getEnrollmentsDictionary,
    isBatchResting,
    (enrollments, batchResting) =>
      enrollmentsDetector('allLoaded')(enrollments) &&
      batchResting &&
      Object.values(enrollments).every((enrollment: Enrollment) => enrollment.user.id === userId)
  );

const getFilter = createSelector(getEnrollmentState, (state: EnrollmentState) => state.filter);

const isTopLevelView = createSelector(getEnrollmentState, enrollmentState => enrollmentState.isTopLevelView);

const getUserRewardsIds = createSelector(getEnrollmentState, enrollmentState =>
  Object.values(enrollmentState.entities).map(entity => entity.referenceId)
);

export const enrollmentsQuery = {
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getEnrollmentsList,
  getEnrollmentsDictionary,
  isEnrollmentsLoaded,
  isUserEnrollmentsLoaded,
  getFilter,
  isTopLevelView,
  getUserRewardsIds
};
