import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { fraudRuleAdapter, FraudRuleState, initialState } from '../../types/fraud-rules.type';
import {
  createFraudRule,
  createFraudRuleFailure,
  createFraudRuleSuccess,
  fetchFraudRule,
  fetchFraudRuleFailure,
  fetchFraudRuleSuccess,
  loadFraudRule,
  loadFraudRuleFailure,
  loadFraudRules,
  loadFraudRulesFailure,
  loadFraudRulesSuccess,
  loadFraudRuleSuccess,
  updateFraudRule,
  updateFraudRuleFailure,
  updateFraudRuleSuccess
} from '../actions/fraud-rules.actions';

export const fraudRuleTriggers: EntityTriggers = {
  single: {
    loading: [loadFraudRule.type, createFraudRule.type, updateFraudRule.type],
    resting: [
      loadFraudRuleSuccess.type,
      createFraudRuleSuccess.type,
      updateFraudRuleSuccess.type,
      // failure action types are added to resting state instead of erroring state
      // to prevent showing error banner when these actions fails
      createFraudRuleFailure.type,
      updateFraudRuleFailure.type
    ],
    erroring: [loadFraudRuleFailure.type]
  },
  batch: {
    loading: [loadFraudRules.type],
    resting: [loadFraudRulesSuccess.type],
    erroring: [loadFraudRulesFailure.type]
  }
};

export const fraudRuleFetchListTriggers: FetchListTriggers = {
  loading: [fetchFraudRule.type],
  resting: [fetchFraudRuleSuccess.type],
  erroring: [fetchFraudRuleFailure.type]
};

export function fraudRulesReducer(state: FraudRuleState = initialState, action: Action): FraudRuleState {
  return {
    ...callStateReducer(baseReducer, fraudRuleTriggers)(state, action),
    ...fetchStateReducer(fraudRuleFetchListTriggers, 'fraudRule')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(loadFraudRuleSuccess, (state, { fraudRule }) => fraudRuleAdapter.upsertOne(fraudRule, state)),
  on(loadFraudRulesSuccess, (state, { fraudRules }) => fraudRuleAdapter.setAll(fraudRules, state)),
  on(createFraudRuleSuccess, (state, { fraudRule }) => fraudRuleAdapter.addOne(fraudRule, state)),
  on(updateFraudRuleSuccess, (state, { fraudRule }) => fraudRuleAdapter.setOne(fraudRule, state)),
  on(fetchFraudRuleSuccess, (state, { fraudRule }) => fraudRuleAdapter.upsertOne(fraudRule, state))
);
