<!--   first section   -->
<h3>{{ orderItem.description }}</h3>
<div class="details-container padding-y-20">
  <!-- left  -->
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Status</h4>
      </div>
      <span class="item__value">{{ orderItem.order.fulfillmentStatus | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Payment Status</h4>
      </div>
      <span class="item__value">{{ orderItem.status | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Points Amount</h4>
      </div>
      <span class="item__value">{{ orderItem.pointsAmount | number: '1.0-2' }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Cash Amount</h4>
      </div>
      <span class="item__value">{{
        orderItem.cashAmount | formatCurrency: orderItemData.currencyCode | async | number
      }}</span>
    </div>
  </div>
  <!-- right  -->
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Customer ID</h4>
      </div>
      <span class="item__value">{{ customerId || 'N/A' }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Ascenda ID</h4>
      </div>
      <span class="item__value">
        <a
          *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
          [routerLink]="'/customers/' + orderItem.userId + '/dashboard'"
        >
          {{ orderItem.userId }}
        </a>
        <ng-template #userIdWithoutLink>
          {{ orderItem.userId }}
        </ng-template>
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Order initiator</h4>
      </div>
      <span class="item__value">{{
        ((hotelBookingData ?? flightBookingData)?.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A'
      }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Created at</h4>
      </div>
      <span class="item__value" attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
        {{ orderItem.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Updated at</h4>
      </div>
      <span class="item__value" attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
        {{ orderItem.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
      </span>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

<!--   second section   -->
<h3 class="title">Booking Summary</h3>
<!-- hotel booking data -->
<div *ngIf="hotelBookingData" class="details-container padding-y-20">
  <!-- left  -->
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Booking Reference</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.travelConfirmationId || 'N/A' }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>First Name</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.firstName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Last Name</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.lastName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Destination</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.destinationName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Hotel</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.hotelName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Room Type</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.roomType | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>No. of Room</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.numberOfRooms }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>No. of Guest(s)</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.numberOfGuests }}</span>
    </div>
  </div>
  <!-- right  -->
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Start Time</h4>
      </div>
      <span class="item__value" data-tooltip="Local time of booking">
        {{ hotelBookingData.startTime }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>End Time</h4>
      </div>
      <span class="item__value" data-tooltip="Local time of booking">
        {{ hotelBookingData.endTime }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Card Paid ({{ hotelBookingData.currencyCode | uppercase }})</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.cashPaid }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Card Paid in USD</h4>
      </div>
      <span class="item__value">{{ hotelBookingData.cashPaidInUsd }}</span>
    </div>
  </div>
</div>

<!-- car booking data -->
<div *ngIf="carBookingData" class="details-container padding-y-20">
  <!-- left  -->
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Booking Reference</h4>
      </div>
      <span class="item__value">{{ carBookingData.travelConfirmationId || 'N/A' }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>First Name</h4>
      </div>
      <span class="item__value">{{ carBookingData.firstName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Last Name</h4>
      </div>
      <span class="item__value">{{ carBookingData.lastName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Pick-up Location</h4>
      </div>
      <span class="item__value">{{ carBookingData.pickupLocation | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Drop-off Location</h4>
      </div>
      <span class="item__value">{{ carBookingData.returnLocation | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Car Model</h4>
      </div>
      <span class="item__value">{{ carBookingData.carModel | titlecase }}</span>
    </div>
  </div>
  <!-- right  -->
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Start Time</h4>
      </div>
      <span class="item__value" data-tooltip="Local time of booking">
        {{ carBookingData.startTime }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>End Time</h4>
      </div>
      <span class="item__value" data-tooltip="Local time of booking">
        {{ carBookingData.endTime }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Card Paid ({{ carBookingData.currencyCode | uppercase }})</h4>
      </div>
      <span class="item__value">{{ carBookingData.cashPaid }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Card Paid in USD</h4>
      </div>
      <span class="item__value">{{ carBookingData.cashPaidInUsd }}</span>
    </div>
  </div>
</div>

<!-- travel booking data -->
<div *ngIf="flightBookingData">
  <div class="details-container padding-y-20">
    <!-- left  -->
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Booking Reference</h4>
        </div>
        <span class="item__value">{{ flightBookingData.travelConfirmationId || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Cabin Class</h4>
        </div>
        <span class="item__value">{{ flightBookingData.cabinClass | titlecase }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Flight Type</h4>
        </div>
        <span class="item__value">{{ flightBookingData.flightType | titlecase }}</span>
      </div>
    </div>
    <!-- right  -->
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Card Paid ({{ flightBookingData.currencyCode | uppercase }})</h4>
        </div>
        <span class="item__value">{{ flightBookingData.cashPaid }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Card Paid in USD</h4>
        </div>
        <span class="item__value">{{ flightBookingData.cashPaidInUsd }}</span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="padding-y-20">
    <h3>Passengers</h3>
    <table mat-table [dataSource]="flightBookingData.passengers" class="passengers-table">
      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef>
          <h4>Passenger #</h4>
        </th>
        <td mat-cell *matCellDef="let passenger; let i = index">Passenger {{ i + 1 }}</td>
      </ng-container>
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>
          <h4>First Name</h4>
        </th>
        <td mat-cell *matCellDef="let passenger">{{ passenger.firstName | titlecase }}</td>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>
          <h4>Last Name</h4>
        </th>
        <td mat-cell *matCellDef="let passenger">{{ passenger.lastName | titlecase }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['serialNumber', 'firstName', 'lastName']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['serialNumber', 'firstName', 'lastName']"></tr>
    </table>
  </div>

  <mat-divider></mat-divider>

  <div class="details-container padding-y-20">
    <div class="column departure-column">
      <h3>Departure Flight</h3>
      <div class="item">
        <div class="item__key">
          <h4>Airline Name</h4>
        </div>
        <span class="item__value">{{ (flightBookingData.originSegments?.[0].airlineNames | arrayJoin) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Flight Number</h4>
        </div>
        <span class="item__value">{{
          (flightBookingData.originSegments?.[0].flightNumbers | arrayJoin) || 'N/A'
        }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Departure Airport</h4>
        </div>
        <span class="item__value">{{ flightBookingData.originSegments?.[0].fromAirport }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Departure Time</h4>
        </div>
        <span class="item__value" data-tooltip="Local time of booking">
          {{ flightBookingData.originSegments?.[0].departureTime }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Arrival Airport</h4>
        </div>
        <span class="item__value">{{ flightBookingData.originSegments?.[0].toAirport }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Arrival Time</h4>
        </div>
        <span class="item__value" data-tooltip="Local time of booking">
          {{ flightBookingData.originSegments?.[0].arrivalTime }}
        </span>
      </div>
    </div>
    <div class="column arrival-column">
      <h3>Return Flight</h3>
      <div class="item">
        <div class="item__key">
          <h4>Airline Name</h4>
        </div>
        <span class="item__value">{{
          (flightBookingData.returnSegments?.[0]?.airlineNames | arrayJoin) || 'N/A'
        }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Flight Number</h4>
        </div>
        <span class="item__value">{{
          (flightBookingData.returnSegments?.[0]?.flightNumbers | arrayJoin) || 'N/A'
        }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Departure Airport</h4>
        </div>
        <span class="item__value">{{ flightBookingData.returnSegments?.[0]?.fromAirport || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Departure Time</h4>
        </div>
        <span
          class="item__value"
          *ngIf="flightBookingData.returnSegments?.[0]?.departureTime"
          data-tooltip="Local time of booking"
        >
          {{ flightBookingData.returnSegments?.[0]?.departureTime || 'N/A' }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Arrival Airport</h4>
        </div>
        <span class="item__value">{{ flightBookingData.returnSegments?.[0]?.toAirport || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Arrival Time</h4>
        </div>
        <span
          class="item__value"
          *ngIf="flightBookingData.returnSegments?.[0]?.arrivalTime"
          data-tooltip="Local time of booking"
        >
          {{ flightBookingData.returnSegments?.[0]?.arrivalTime || 'N/A' }}
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!hotelBookingData && !carBookingData && !flightBookingData">N/A</div>
