import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { FraudRule } from '../types/fraud-rules.type';

@Injectable({
  providedIn: 'root'
})
export class FraudRulesService {
  constructor(private missionControlService: MissionControlService) {}

  getFraudRule(id: string): Observable<FraudRule> {
    return this.missionControlService.get<FraudRule>(`fraud_rules/${id}`);
  }

  getFraudRules(): Observable<FraudRule[]> {
    return this.missionControlService.get<FraudRule[]>('fraud_rules');
  }

  createFraudRule(fraudRule: Partial<FraudRule>): Observable<FraudRule> {
    return this.missionControlService.post<FraudRule>('fraud_rules', fraudRule);
  }

  updateFraudRule(fraudRule: Partial<FraudRule>): Observable<FraudRule> {
    return this.missionControlService.patch<FraudRule>(`fraud_rules/${fraudRule.id}`, fraudRule);
  }
}
