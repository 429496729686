import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

export class DayjsUtils {
  static getStartOfDay(date: Dayjs | string, timezoneOffset: number = 0): string {
    if (typeof date === 'string') {
      date = dayjs(date);
    }
    return date.startOf('day').subtract(timezoneOffset, 'seconds').toISOString();
  }

  static getEndOfDay(date: Dayjs | string, timezoneOffset: number = 0): string {
    if (typeof date === 'string') {
      date = dayjs(date);
    }
    return date.endOf('day').subtract(timezoneOffset, 'seconds').toISOString();
  }

  static getUtcTime(date?: string): Dayjs {
    dayjs.extend(utc);
    return dayjs.utc(date);
  }

  static getTenantLocalNowTime(tenantTimezoneOffset: number): Dayjs {
    return this.getUtcTime().add(tenantTimezoneOffset, 'second');
  }

  static mergeUTCDateTime(date: Dayjs | string, time: string): Dayjs {
    dayjs.extend(utc);
    if (typeof date === 'string') {
      date = dayjs(date).utc();
    }

    let hour = parseInt(time.split(':')[0]);
    let minute = parseInt(time.split(':')[1]);

    return date.set('hour', hour).set('minute', minute).startOf('minute');
  }
}
