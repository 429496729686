import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { WebhookEvent, WebhookEventsFilter } from '../../types';

export const loadWebhookEvents = createAction(
  '[Webhooks] Load Webhook Events',
  props<{ filter: WebhookEventsFilter }>()
);

export const loadWebhookEventsSuccess = createAction(
  '[Webhooks] Load Webhook Events Success',
  props<{ webhookEvents: WebhookEvent[]; count: number }>()
);

export const loadWebhookEventsFailure = createAction('[Webhooks] Load Webhook Events Failure', props<ErrorState>());

export const loadWebhookEvent = createAction('[Webhooks] Load Webhook Event', props<{ id: string }>());
export const loadWebhookEventSuccess = createAction(
  '[Webhooks] Load Webhook Event Success',
  props<{ webhookEvent: WebhookEvent }>()
);
export const loadWebhookEventFailure = createAction('[Webhooks] Load Webhook Event Failure', props<ErrorState>());

export const setWebhookEventsFilter = createAction(
  '[Webhooks] Set Webhook Events Filter',
  props<{ filter: WebhookEventsFilter }>()
);
