import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { ApprovalRequestedAction } from '@core/store/interrupted/reducers/interrupted.reducer';
import { RequestValidationSettings } from '@core/types';

import { PointsAdjustmentRequestParams } from '../../../approval-requests/types/approval-requests.type';
import { makePointsAdjustmentSuccess } from '../../../dashboard/store/actions/points-adjustments.actions';
import { updateTenantSuccess } from '../../../tenants/store/actions/tenants.actions';

interface DialogData {
  approvalRequestedAction: ApprovalRequestedAction;
}

@Component({
  selector: 'admin-approval-requested-dialog',
  templateUrl: './approval-requested-dialog.html'
})
export class ApprovalRequestedDialogComponent {
  DEFAULT_DISPLAY_MESSAGE = 'Your request has been submitted for approval. Please click OK to proceed.';
  tenantUpdatePending: boolean;

  constructor(
    private dialogRef: MatDialogRef<ApprovalRequestedDialogComponent>,
    private store: Store<AuthState>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string
  ) {
    dialogRef.disableClose = true;
  }

  get action(): ApprovalRequestedAction {
    return this.data.approvalRequestedAction;
  }

  onOkClick(): void {
    this.store.dispatch({ ...this.action });
    this.dialogRef.close();
    if (this.tenantUpdatePending) {
      this.router.navigate(['/approval-requests/listing'], {
        queryParams: { type: 'tenants', status: 'pending' }
      });
    }
  }

  getDisplayMessage(): string {
    // check for > 1 because newly created approval request will be counted in as well
    this.tenantUpdatePending = this.action.type === updateTenantSuccess.type && this.action.pendingCount > 1;

    if (this.action.type === makePointsAdjustmentSuccess.type) {
      const { gte: deductionLimit, lte: additionLimit } = this.action.accessPolicy.settings.attributes
        .amount as RequestValidationSettings;
      const limit =
        (this.action.payload as PointsAdjustmentRequestParams).amount >= 0 ? additionLimit : Math.abs(deductionLimit);

      return limit === 0
        ? this.DEFAULT_DISPLAY_MESSAGE
        : `Your request exceeded ${limit} ${this.loyaltyCurrency}, and it has been submitted for approval. Please click OK to proceed.`;
    } else if (this.tenantUpdatePending) {
      return `Your request has been submitted for approval and there are ${this.action.pendingCount} pending request(s).
      Please click OK to view them.`;
    }

    return this.DEFAULT_DISPLAY_MESSAGE;
  }
}
