import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState } from '@core/types';

import { ProductType } from '../../dashboard/types';

import { RedemptionRatesFilter } from './redemption-rates-filter.type';

interface Condition {
  attribute: string;
  predicate: string;
  value: string;
}

export interface BaseRedemptionRate {
  id: string;
  cashCurrency?: string;
  costPerPoint: number;
  productType?: ProductType;
  pricingRule: {
    conditions: Condition[];
    priority: number;
  };
}

export interface RedemptionRate extends BaseRedemptionRate {
  brand?: string;
  minPrice?: string;
  maxPrice?: string;
  type?: string;
}

export interface NewRedemptionRate extends Omit<BaseRedemptionRate, 'id'> {
  loyaltyCurrencyId: string;
}

export interface MissionControlRedemptionRateResult {
  data: BaseRedemptionRate[];
  meta: {
    count: number;
  };
}

export interface RedemptionRateResult {
  data: RedemptionRate[];
  meta: {
    count: number;
  };
}

export enum RedemptionRateDisplayType {
  All = '',
  Digital = 'digital',
  Physical = 'physical'
}

export const redemptionRateAdapter = createEntityAdapter<RedemptionRate>();

export interface RedemptionRateState extends EntityState<RedemptionRate>, CallState, FetchState {
  filter: RedemptionRatesFilter;
  count: number;
}

export const initialState: RedemptionRateState = {
  ...redemptionRateAdapter.getInitialState<CallState>(initialCallState),
  ...redemptionRateAdapter.getInitialState<FetchState>(initialFetchState),
  filter: null,
  count: 0
};
