<!-- Button with label -->
<button
  *ngIf="buttonLabel; else iconButton"
  mat-button
  [cdkCopyToClipboard]="content"
  [matTooltip]="tooltipText ?? 'Copy ' + contentName + ' to clipboard'"
  [disabled]="loading | async"
  [ngClass]="styleClassName"
  (click)="openSnackBar()"
>
  <mat-icon svgIcon="copy" [ngClass]="styleClassName">content_copy</mat-icon>
  {{ buttonLabel }}
</button>

<!-- Button with no label -->
<ng-template #iconButton>
  <button
    mat-icon-button
    [cdkCopyToClipboard]="content"
    [matTooltip]="tooltipText ?? 'Copy ' + contentName + ' to clipboard'"
    [disabled]="loading | async"
    [ngClass]="styleClassName"
    (click)="openSnackBar()"
  >
    <mat-icon svgIcon="copy" [ngClass]="styleClassName">content_copy</mat-icon>
  </button>
</ng-template>
