import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

@Injectable({
  providedIn: 'root'
})
export class AllowedOriginsService {
  constructor(private missionControlService: MissionControlService) {}

  getAllowedOrigins(): Observable<string[]> {
    return this.missionControlService.get<string[]>('allowed_origins');
  }

  refreshAllowedOrigins(): Observable<void> {
    return this.missionControlService.post<void>('allowed_origins/refresh');
  }
}
