import { filter, map } from 'rxjs/operators';

import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { HttpError, SCOPES_OR } from '@core/types';
import { ComponentState, getRenderState$, Params } from '@utils';

import { offersQuery } from '../../store/selectors/offers.selectors';
import { Offer, OfferRedemptionInterval, OfferState, OfferStatusTypeCodes } from '../../types';

@Component({
  selector: 'admin-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {
  @Input() userOffer: Offer;
  @Input() userOfferLoading$: Observable<boolean>;
  @Input() userOfferError$: Observable<HttpError>;

  destroyRef = inject(DestroyRef);

  offer$: Observable<Offer>;
  offerLoading$: Observable<boolean>;
  error$: Observable<HttpError>;
  renderState$: Observable<ComponentState>;
  merchantTerms: string[] = [];
  visaTerms: string[] = [];
  offerStatusTypeCodes = OfferStatusTypeCodes;
  offerRedemptionInterval = OfferRedemptionInterval;
  isUserRoute: boolean;
  readonly SCOPES = SCOPES_OR;

  constructor(
    private store: Store<OfferState>,
    private route: ActivatedRoute,
    private accessPolicies: AccessPolicies
  ) {}

  ngOnInit(): void {
    this.isUserRoute = this.route.snapshot.data.isUserRoute;

    if (this.isUserRoute) {
      const settings = this.accessPolicies.getResponseSettings('offers', 'index');
      this.offer$ = of(this.userOffer).pipe(
        map(offer =>
          settings ? (Object.fromEntries(Object.entries(offer).filter(([key, _]) => settings[key])) as Offer) : offer
        )
      );
      this.offerLoading$ = this.userOfferLoading$;
      this.error$ = this.userOfferError$;
    } else {
      const offerId = Params.find(this.route, 'offerId');
      this.offer$ = this.store.select(offersQuery.getOfferById(offerId));
      this.offerLoading$ = this.store.select(offersQuery.isSingleLoading);
      this.error$ = this.store.select(offersQuery.getSingleError);
    }

    this.offer$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(offer => Array.isArray(offer?.terms))
      )
      .subscribe(offer => {
        offer.terms.forEach(term => {
          if (term?.header === 'Merchant terms') {
            this.merchantTerms.push(term.body);
          } else if (term?.header === 'Visa terms') {
            this.visaTerms.push(term.body);
          }
        });
      });

    this.renderState$ = getRenderState$([this.offerLoading$, this.error$]);
  }
}
