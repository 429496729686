import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { UpdateGiftCardsConfigPayload } from '../types';

@Injectable({ providedIn: 'root' })
export class GiftCardsConfigService {
  constructor(private missionControlService: MissionControlService) {}

  updateGiftCardsConfig(params: UpdateGiftCardsConfigPayload, version: number): Observable<void> {
    return this.missionControlService.patch<void>('gift_cards_config', { ...params, version });
  }
}
