import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Nullable } from '@shared/types';

export interface Ability {
  id: Nullable<string>;
  roleId: string;
  accessPolicyId: string;
  priority?: Nullable<number>;
  approval_config: object;
}

export const abilityAdapter = createEntityAdapter<Ability>();

export interface AbilityState extends EntityState<Ability>, CallState {}

export const initialState: AbilityState = abilityAdapter.getInitialState<CallState>(initialCallState);
