import { Timestamp } from '@core/types';

export class EventsFilter {
  page?: number;
  timestamp?: Timestamp;
  query: EventQuery;
  isFromUserView?: boolean;

  constructor(
    args: { query?: EventQuery; isFromUserView?: boolean } = {
      query: {
        entity: 'app',
        value: 'guardhouse',
        limit: 20
      },
      isFromUserView: false
    }
  ) {
    this.page = 1;
    this.query = args.query;
    this.isFromUserView = args.isFromUserView;
  }
}

interface EventQuery {
  entity: string;
  value: string;
  limit?: number;
}
