import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { PointsAccount, PointsActivitiesLoad } from '../../types';

export const loadPointsAccounts = createAction(
  'Load Points Accounts',
  props<{ userId: string; pointsActivitiesLoad: PointsActivitiesLoad }>()
);
export const loadPointsAccountsSuccess = createAction(
  'Load Points Accounts Success',
  props<{ pointsAccounts: PointsAccount[]; userId: string; pointsActivitiesLoad: PointsActivitiesLoad }>()
);
export const loadPointsAccountsFailure = createAction('Load Points Accounts Failure', props<ErrorState>());

export const setSelectedPointsAccountId = createAction(
  'Set Selected Points Account Id',
  props<{ selectedId: string }>()
);
