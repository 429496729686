export class EnrollmentsFilter {
  customerId?: string;
  parentId?: string;
  userId?: string;
  productReferenceId?: string;
  rewardId?: string;
  accountType?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  postalCode?: string;
  status?: string;
  updatedFrom?: string;
  updatedTo?: string;
  email?: string;
  limit?: number;
  fetchGroupedEnrollments?: boolean;
}
