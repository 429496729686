import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AllowedOriginsService } from '../../services/allowed-origins.service';
import {
  loadAllowedOrigins,
  loadAllowedOriginsFailure,
  loadAllowedOriginsSuccess,
  refreshAllowedOrigins,
  refreshAllowedOriginsFailure,
  refreshAllowedOriginsSuccess
} from '../actions/allowed-origins.actions';

@Injectable()
export class AllowedOriginsEffects {
  loadAllowedOrigins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllowedOrigins),
      exhaustMap(() =>
        this.allowedOriginsService.getAllowedOrigins().pipe(
          map(allowedOrigins => loadAllowedOriginsSuccess({ allowedOrigins })),
          catchError(error => of(loadAllowedOriginsFailure({ error })))
        )
      )
    )
  );

  refreshAllowedOrigins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshAllowedOrigins),
      exhaustMap(() =>
        this.allowedOriginsService.refreshAllowedOrigins().pipe(
          map(() => refreshAllowedOriginsSuccess()),
          catchError(error => of(refreshAllowedOriginsFailure({ error })))
        )
      )
    )
  );

  refreshAllowedOriginsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshAllowedOriginsSuccess),
      map(() => loadAllowedOrigins())
    )
  );

  constructor(
    private actions$: Actions,
    private allowedOriginsService: AllowedOriginsService
  ) {}
}
