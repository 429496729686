import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { TooltipKey } from '../../types/tooltip-keys.type';
import { Tooltip, tooltipAdapter, TooltipState } from '../../types/tooltips.type';

const getTooltipState = createFeatureSelector<TooltipState>('tooltip');

const { selectAll: getTooltipsList, selectEntities: getTooltipsDictionary } =
  tooltipAdapter.getSelectors(getTooltipState);

const { isBatchLoading, isBatchResting, getBatchError } = getCallStateSelectors<TooltipState>(getTooltipState);

const getTooltipValueByKey = (key: TooltipKey): Selector<object, string> =>
  createSelector(getTooltipsDictionary, (tooltips: Dictionary<Tooltip>) => tooltips[key]?.value);

const getTooltipsByKeys = (keys: TooltipKey[]): Selector<object, Tooltip[]> =>
  createSelector(getTooltipsDictionary, (tooltips: Dictionary<Tooltip>) =>
    keys.map(key => ({ key, value: tooltips[key]?.value ?? '' }))
  );

const getFetchList = createSelector(getTooltipState, (state: TooltipState) => state.fetchList);

export const tooltipsQuery = {
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getFetchList,
  getTooltipsList,
  getTooltipsDictionary,
  getTooltipsByKeys,
  getTooltipValueByKey
};
