import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { ImageUploadResourceType, UploadImageActionPayload } from '../../types';

export const uploadImage = createAction(
  '[Upload Images] Upload Image',
  props<{
    payload: UploadImageActionPayload;
    resource: ImageUploadResourceType;
  }>()
);
export const uploadImageSuccess = createAction(
  '[Upload Images] Upload Image Success',
  props<{ imageUrl: string; componentId: string }>()
);
export const uploadImageFailure = createAction(
  '[Upload Images] Upload Images Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not upload the image due to technical issues on our end. Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const setImage = createAction('[Upload Images] Set Image', props<{ imageUrl: string; componentId: string }>());
