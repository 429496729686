import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface GhCacheState extends CallState {
  ghCaches: string[];
}

export const initialState: GhCacheState = {
  ...initialCallState,
  ghCaches: null
};
