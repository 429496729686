import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'validNumber' })
export class ValidNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, digitsInfo?: string, locale?: string): any {
    try {
      return this.decimalPipe.transform(value, digitsInfo, locale);
    } catch (error) {
      return value;
    }
  }
}
