import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { LogicRuleAttributeGroupOption } from '@shared/types/logic-rule.type';
import { ObjectUtils } from '@utils';

import { DrawerSelectComponent } from '../drawer-select/drawer-select.component';

@Component({
  selector: 'admin-logic-rule-attribute-options-drawer',
  template: `
    <admin-drawer-content-container>
      <admin-drawer-content-header headerTitle="Categories" class="pb-2" (closeButtonClicked)="closeDrawer()">
        <div class="filter-form-wrapper-in-drawer mt-8">
          <form class="filter-form">
            <mat-form-field>
              <mat-icon matPrefix svgIcon="search" />
              <mat-label>Search</mat-label>
              <input matInput [formControl]="searchControl" />
              <admin-input-reset matSuffix [inputControl]="searchControl" />
            </mat-form-field>
          </form>
        </div>
      </admin-drawer-content-header>
      <admin-drawer-content-body>
        @for (group of filteredAttributeGroupOptions() ?? []; track group.categoryLabel) {
          <mat-optgroup>
            {{ group.categoryLabel }}
            @for (option of group.options; track option.value) {
              <mat-option adminDrawerSelectOption [value]="option.value">{{ option.label }}</mat-option>
            }
          </mat-optgroup>
        }
      </admin-drawer-content-body>
    </admin-drawer-content-container>
  `,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogicRuleAttributeOptionsDrawerComponent {
  // Sine this component is used as ng-content projection in the DrawerSelectComponent
  // instead of being opened directly using DrawerService, so it won't have DrawerRef
  // therefore we need to inject the DrawerSelectComponent
  private drawerSelect = inject(DrawerSelectComponent, {
    optional: true
  });

  private attributeGroupOptionsSignal = signal<LogicRuleAttributeGroupOption[]>([]);

  @Input({ required: true })
  set attributeGroupOptions(attributeGroupOptions: LogicRuleAttributeGroupOption[]) {
    this.attributeGroupOptionsSignal.set(attributeGroupOptions);
  }

  get attributeGroupOptions(): LogicRuleAttributeGroupOption[] {
    return this.attributeGroupOptionsSignal();
  }

  searchControl = new FormControl<string>('');

  searchControlValue = toSignal(this.searchControl.valueChanges.pipe(debounceTime(400), distinctUntilChanged()), {
    initialValue: ''
  });

  filteredAttributeGroupOptions = computed(() => {
    const searchText = this.searchControlValue();

    if (ObjectUtils.isNullish(searchText)) {
      return this.attributeGroupOptionsSignal();
    }

    return this.attributeGroupOptionsSignal().reduce<LogicRuleAttributeGroupOption[]>((filteredGroups, group) => {
      const filteredGroupOptions = group.options.filter(option =>
        option.label.toLowerCase().includes(searchText!.toLowerCase())
      );

      return filteredGroupOptions.length > 0
        ? [...filteredGroups, { ...group, options: filteredGroupOptions }]
        : filteredGroups;
    }, []);
  });

  resetForm(): void {
    this.searchControl.reset();
  }

  closeDrawer(): void {
    this.drawerSelect?.closeDrawer();
  }
}
