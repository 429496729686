import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, orderWebhookAdapter, OrderWebhookState } from '../../types/order-webhooks.type';
import {
  loadOrderWebhooks,
  loadOrderWebhooksFailure,
  loadOrderWebhooksSuccess,
  resendOrderWebhook,
  resendOrderWebhookFailure,
  resendOrderWebhookSuccess
} from '../actions/order-webhooks.actions';

export const orderWebhookTriggers: EntityTriggers = {
  single: {
    loading: [resendOrderWebhook.type],
    resting: [resendOrderWebhookSuccess.type],
    erroring: [resendOrderWebhookFailure.type]
  },
  batch: {
    loading: [loadOrderWebhooks.type],
    resting: [loadOrderWebhooksSuccess.type],
    erroring: [loadOrderWebhooksFailure.type]
  }
};

export function orderWebhooksReducer(state: OrderWebhookState = initialState, action: Action): OrderWebhookState {
  return callStateReducer(baseReducer, orderWebhookTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadOrderWebhooksSuccess, (state, { webhooks }) => orderWebhookAdapter.setAll(webhooks, state))
);
