import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface MerchantPortalBootstrap {
  merchants: Merchant[];
  campaignSlots: CampaignSlot[];
}

export interface Merchant {
  id: string;
  name: string;
  category: 'airline' | 'hotel' | 'LP';
  logoUrl: string;
  externalId: string;
  createdAt: string;
  updatedAt: string;
}

export const campaignProposalStatusDisplayClass = {
  available: 'status-available',
  approved: 'status-active',
  pending: 'status-pending',
  unsuccessful: 'status-inactive',
  interested: 'status-pending',
  proposed: 'status-pending'
};

export interface CampaignSlot {
  id: string;
  isAvailable: boolean;
  opportunityId: string;
  quarter: string;
  year: string;
  campaignProposals: CampaignSlotProposal[];
  createdAt: string;
  updatedAt: string;
}

export const proposalStatuses = ['approved', 'pending', 'unsuccessful', 'interested', 'proposed', 'available'] as const;
export type ProposalStatus = (typeof proposalStatuses)[number];

export interface CampaignSlotProposal {
  campaignSlotId: string;
  merchantId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  bonusRule: string;
  notes: string;
  status: ProposalStatus;
  id: string;
  interested: boolean;
  source: 'merchant' | 'opportunity';
  createdAt: string;
  updatedAt: string;
}

export interface CampaignProposal extends CampaignSlotProposal {
  merchant: Merchant;
}

export interface CreateCampaignProposalParams {
  campaignSlotId: string;
  merchantId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  notes?: string;
}

export interface MerchantPortalBootstrapState extends CallState {
  merchantPortalBootstrap: MerchantPortalBootstrap;
  selectedYear: number;
}

export const merchantPortalBootstrapInitialState: MerchantPortalBootstrapState = {
  ...initialCallState,
  merchantPortalBootstrap: null,
  selectedYear: new Date().getFullYear()
};

export const campaignProposalAdapter = createEntityAdapter<CampaignProposal>({
  selectId: (campaignProposal: CampaignProposal): string => campaignProposal.id
});

export interface CampaignProposalState extends EntityState<CampaignProposal>, CallState {}

export const campaignProposalInitialState: CampaignProposalState =
  campaignProposalAdapter.getInitialState<CallState>(initialCallState);
