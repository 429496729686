import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { Tag, TagType } from '@shared/types';
import { ObjectUtils } from '@utils';

import {
  AuditLog,
  AuditLogsFilter,
  AuditLogsResult,
  MissionControlAuditLog,
  MissionControlAuditLogsFilter,
  MissionControlAuditLogsResult
} from '../types';

@Injectable({ providedIn: 'root' })
export class AuditLogsService {
  constructor(private missionControlService: MissionControlService) {}

  getAuditLog(id: string): Observable<AuditLog> {
    return this.missionControlService
      .get<MissionControlAuditLog>(`audit_logs/${id}`)
      .pipe(map(auditLog => ({ ...auditLog, tags: this.formatTags(auditLog.tags) })));
  }

  getAuditLogs(filter: AuditLogsFilter): Observable<AuditLogsResult> {
    const mappedFilter = this.mapFilterToMissionControlFormat(filter);
    const requestFilter = ObjectUtils.sanitizeRequestObject<MissionControlAuditLogsFilter>(mappedFilter);

    return this.missionControlService
      .post<MissionControlAuditLogsResult>('audit_logs/search', requestFilter)
      .pipe(map(result => this.mapResultToAdminPanelFormat(result)));
  }

  // maps tags of the audit logs result from MC to AP format
  // example: { agentId: '123', userId: '456' } --> [{ type: 'agentId', id: '123' }, { type: 'userId', id: '456' }]
  private formatTags(tags: { [key in TagType]?: string }): Tag[] {
    return Object.entries(tags || []).map(([type, id]: [TagType, string]) => ({ type, id }));
  }

  private mapResultToAdminPanelFormat(result: MissionControlAuditLogsResult): AuditLogsResult {
    return {
      ...result,
      data: result.data.map(auditLog => ({
        ...auditLog,
        tags: this.formatTags(auditLog.tags)
      }))
    };
  }

  // maps filter tags from AP to MC format
  // example: [{ type: 'agentId', id: '123' }, { type: 'userId', id: '456' }] --> { agentId: '123', userId: '456' }
  private mapFilterToMissionControlFormat(filter: AuditLogsFilter): MissionControlAuditLogsFilter {
    return {
      ...filter,
      tags:
        filter.tags?.reduce(
          (result, tag) => {
            result[tag.type] = tag.id;
            return result;
          },
          {} as { [key in TagType]: string }
        ) || {}
    };
  }
}
