import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { earnRuleAdapter, EarnRuleState, initialState } from '../../types';
import {
  createEventEarnRule,
  createEventEarnRuleFailure,
  createEventEarnRuleSuccess,
  deactivateEventEarnRule,
  deactivateEventEarnRuleFailure,
  deactivateEventEarnRuleSuccess,
  loadEventEarnRule,
  loadEventEarnRuleFailure,
  loadEventEarnRules,
  loadEventEarnRulesFailure,
  loadEventEarnRulesSuccess,
  loadEventEarnRuleSuccess,
  setEventEarnRulesFilter,
  updateEventEarnRule,
  updateEventEarnRuleFailure,
  updateEventEarnRulesPriorities,
  updateEventEarnRulesPrioritiesFailure,
  updateEventEarnRulesPrioritiesSuccess,
  updateEventEarnRuleSuccess
} from '../actions/event-earn-rules.actions';

export const eventEarnRuleTriggers: EntityTriggers = {
  single: {
    loading: [createEventEarnRule.type, deactivateEventEarnRule.type, loadEventEarnRule.type, updateEventEarnRule.type],
    resting: [
      createEventEarnRuleSuccess.type,
      deactivateEventEarnRuleSuccess.type,
      loadEventEarnRuleSuccess.type,
      updateEventEarnRuleSuccess.type
    ],
    erroring: [
      createEventEarnRuleFailure.type,
      deactivateEventEarnRuleFailure.type,
      loadEventEarnRuleFailure.type,
      updateEventEarnRuleFailure.type
    ]
  },
  batch: {
    loading: [loadEventEarnRules.type, updateEventEarnRulesPriorities.type],
    resting: [loadEventEarnRulesSuccess.type, updateEventEarnRulesPrioritiesSuccess.type],
    erroring: [loadEventEarnRulesFailure.type, updateEventEarnRulesPrioritiesFailure.type]
  }
};

export function eventEarnRulesReducer(state: EarnRuleState = initialState, action: Action): EarnRuleState {
  return callStateReducer(baseReducer, eventEarnRuleTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadEventEarnRulesSuccess, updateEventEarnRulesPrioritiesSuccess, (state, { result }) => ({
    ...earnRuleAdapter.setAll(result.earnRules, state),
    version: result.version
  })),
  on(loadEventEarnRuleSuccess, (state, { result }) => ({
    ...earnRuleAdapter.upsertOne(result.earnRule, state),
    version: result.version
  })),
  on(deactivateEventEarnRuleSuccess, updateEventEarnRuleSuccess, (state, { result }) => ({
    ...earnRuleAdapter.setOne(result.earnRule, state),
    version: result.version
  })),
  on(setEventEarnRulesFilter, (state, { filter }) => ({ ...state, filter }))
);
