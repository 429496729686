import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface MerchandiseCategory {
  id: number;
  name: string;
  level: number;
  code?: string;
  active?: boolean;
  brands?: string[];
  subCategories?: MerchandiseCategory[];
}

export interface MerchandiseCategoryResult {
  data: MerchandiseCategory[];
}

export const merchandiseCategoryAdapter = createEntityAdapter<MerchandiseCategory>();

export interface MerchandiseCategoryState extends EntityState<MerchandiseCategory>, CallState {}

export const initialState: MerchandiseCategoryState = {
  ...merchandiseCategoryAdapter.getInitialState<CallState>(initialCallState)
};
