import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { EventsFilter } from '../../types/events-filter.type';
import { EventsResult } from '../../types/events-result.type';

export const loadEvents = createAction('[Events] Load Events', props<{ filter: EventsFilter }>());
export const loadEventsSuccess = createAction('[Events] Load Events Success', props<{ eventsResult: EventsResult }>());
export const loadEventsFailure = createAction('[Events] Load Events Failure', props<ErrorState>());
