<div class="data-table-container">
  <div *ngIf="loading$ | async" class="progress-loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="table-wrapper offers-data-table">
    <table
      mat-table
      matSort
      class="data-table offers-data-table"
      multiTemplateDataRows
      [dataSource]="filteredOffers"
      [class.overlay]="loading$ | async"
    >
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header="status" *matHeaderCellDef class="text-primary status-col">
          <h4 class="text-primary">Status</h4>
        </th>
        <td mat-cell *matCellDef="let offer" class="status-col">
          {{ offerStatusTypeCodes[offer.status] | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="text-primary id-col">
          <h4 class="text-primary">Offer ID</h4>
        </th>
        <td mat-cell *matCellDef="let offer" class="id-col">{{ offer.id }}</td>
      </ng-container>

      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef class="text-primary brand-col">
          <h4 class="text-primary">Brand</h4>
        </th>
        <td mat-cell *matCellDef="let offer" class="brand-col" matTooltip="{{ offer.brand }}">{{ offer.brand }}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef class="text-primary title-col">
          <h4 class="text-primary">Title</h4>
        </th>
        <td mat-cell *matCellDef="let offer" class="title-col" matTooltip="{{ offer.title }}">{{ offer.title }}</td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell mat-sort-header="scoreByRecommended" *matHeaderCellDef class="text-primary score-col">
          <h4 class="text-primary">Score</h4>
        </th>
        <td mat-cell *matCellDef="let offer" class="score-col" matTooltip="{{ offer.scoreByRecommended }}">
          {{ offer.scoreByRecommended }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-primary action-col">
          <h4 class="text-primary">Action</h4>
        </th>
        <td mat-cell *matCellDef="let offer" class="action-col">
          <ng-container>
            <mat-icon>{{ offer === expandedColumns ? 'expand_less' : 'expand_more' }}</mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let offer" [attr.colspan]="displayedColumns.length" class="user-offer-details-cell">
          <div class="expanded-detail" [@detail_expand]="offer === expandedColumns ? 'expanded' : 'collapsed'">
            <div class="user-offer-expansion-panel-wrapper">
              <admin-offer-details
                [userOffer]="offer"
                [userOfferLoading$]="loading$"
                [userOfferError$]="error$"
              ></admin-offer-details>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-secondary"></tr>
      <ng-container *ngIf="!(loading$ | async)">
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="element-row"
          [class.expanded-row]="expandedColumns === row"
          (click)="expandedColumns = expandedColumns === row ? null : row"
        >
          /
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </ng-container>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[20, 50]"
    [pageIndex]="filter.page - 1"
    [pageSize]="filter.limit"
    [length]="offersCount$ | async"
    (page)="onPage($event)"
  >
  </mat-paginator>
</div>
