import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { pointsAccountAdapter, pointsAccountInitialState, PointsAccountState } from '../../types';
import {
  loadPointsAccounts,
  loadPointsAccountsFailure,
  loadPointsAccountsSuccess,
  setSelectedPointsAccountId
} from '../actions/points-accounts.actions';

export const pointsAccountTriggers: EntityTriggers = {
  batch: {
    loading: [loadPointsAccounts.type],
    resting: [loadPointsAccountsSuccess.type],
    erroring: [loadPointsAccountsFailure.type]
  }
};

export function pointsAccountsReducer(
  state: PointsAccountState = pointsAccountInitialState,
  action: Action
): PointsAccountState {
  return callStateReducer(baseReducer, pointsAccountTriggers)(state, action);
}

const baseReducer = createReducer(
  pointsAccountInitialState,
  on(loadPointsAccountsSuccess, (state, { pointsAccounts }) => pointsAccountAdapter.setAll(pointsAccounts, state)),
  on(setSelectedPointsAccountId, (state, { selectedId }) => ({ ...state, selectedId }))
);
