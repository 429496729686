import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { BatchEntityTriggers } from '@core/types';

import {
  connectionCredentialsAdapter,
  ConnectionCredentialState,
  initialState
} from '../../types/connection-credentials.type';
import {
  loadConnectionCredentials,
  loadConnectionCredentialsFailure,
  loadConnectionCredentialsSuccess
} from '../actions/connection-credentials.actions';

export const connectionCredentialTriggers: Required<BatchEntityTriggers> = {
  batch: {
    loading: [loadConnectionCredentials.type],
    resting: [loadConnectionCredentialsSuccess.type],
    erroring: [loadConnectionCredentialsFailure.type]
  }
};

export function connectionCredentialsReducer(
  state: ConnectionCredentialState = initialState,
  action: Action
): ConnectionCredentialState {
  return callStateReducer(baseReducer, connectionCredentialTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadConnectionCredentialsSuccess, (state, { connectionCredentials }) =>
    connectionCredentialsAdapter.setAll(connectionCredentials, state)
  )
);
