import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { FraudRuleSchema } from '../../types/fraud-rule-schemas.type';

export const loadFraudRuleSchemas = createAction('[Fraud Rule Schemas] Load Fraud Rule Schemas');
export const loadFraudRuleSchemasSuccess = createAction(
  '[Fraud Rule Schemas] Load Fraud Rule Schemas Success',
  props<{ fraudRuleSchemas: FraudRuleSchema[] }>()
);
export const loadFraudRuleSchemasFailure = createAction(
  '[Fraud Rule Schemas] Load Fraud Rule Schemas Failure',
  props<ErrorState>()
);
