import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { CampaignsService } from '../../services/campaigns.service';
import {
  adjustCampaign,
  adjustCampaignActivityAndTime,
  adjustCampaignActivityAndTimeFailure,
  adjustCampaignActivityAndTimeSuccess,
  adjustCampaignFailure,
  adjustCampaignSuccess,
  createCampaign,
  createCampaignFailure,
  createCampaignSuccess,
  fetchCampaign,
  fetchCampaignFailure,
  fetchCampaignSuccess,
  loadCampaign,
  loadCampaignFailure,
  loadCampaigns,
  loadCampaignsFailure,
  loadCampaignsSuccess,
  loadCampaignSuccess,
  loadUserCampaigns,
  loadUserCampaignsFailure,
  loadUserCampaignsSuccess,
  startCampaign,
  startCampaignFailure,
  startCampaignSuccess,
  stopCampaign,
  stopCampaignFailure,
  stopCampaignSuccess,
  updateCampaign,
  updateCampaignFailure,
  updateCampaignSuccess,
  uploadCampaignImage,
  uploadCampaignImageFailure,
  uploadCampaignImageSuccess
} from '../actions/campaigns.actions';

@Injectable()
export class CampaignsEffects {
  loadUserCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserCampaigns),
      exhaustMap(action =>
        this.campaignsService.getUserCampaigns(action.userId).pipe(
          map(campaigns => loadUserCampaignsSuccess({ campaigns })),
          catchError(error => of(loadUserCampaignsFailure({ error })))
        )
      )
    )
  );

  loadCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCampaigns),
      exhaustMap(() =>
        this.campaignsService.getCampaigns().pipe(
          map(campaigns => loadCampaignsSuccess({ campaigns })),
          catchError(error => of(loadCampaignsFailure({ error })))
        )
      )
    )
  );

  loadCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCampaign),
      exhaustMap(action =>
        this.campaignsService.getCampaign(action.id).pipe(
          map(campaign => loadCampaignSuccess({ campaign })),
          catchError(error => of(loadCampaignFailure({ error })))
        )
      )
    )
  );

  fetchCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCampaign),
      mergeMap(action =>
        this.campaignsService.getCampaign(action.id).pipe(
          map(campaign => fetchCampaignSuccess({ campaign })),
          catchError(() => of(fetchCampaignFailure({ id: action.id })))
        )
      )
    )
  );

  createCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCampaign),
      exhaustMap(action =>
        this.campaignsService.createCampaign(action.campaign).pipe(
          map(campaign => createCampaignSuccess({ campaign })),
          catchError(error => of(createCampaignFailure({ error })))
        )
      )
    )
  );

  updateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCampaign),
      exhaustMap(action =>
        this.campaignsService.updateCampaign(action.campaign).pipe(
          map(campaign => updateCampaignSuccess({ campaign })),
          catchError(error => of(updateCampaignFailure({ error })))
        )
      )
    )
  );

  createOrUpdateCampaignSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCampaignSuccess, updateCampaignSuccess),
      map(action => routerNavigate({ path: `/campaigns/${action.campaign.id}` }))
    )
  );

  startCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startCampaign),
      exhaustMap(action =>
        this.campaignsService.startCampaign(action.campaignId).pipe(
          map(campaign => startCampaignSuccess({ campaign, dialogRefId: action.dialogRefId })),
          catchError(error => of(startCampaignFailure({ error })))
        )
      )
    )
  );

  stopCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopCampaign),
      exhaustMap(action =>
        this.campaignsService.stopCampaign(action.campaignId).pipe(
          map(campaign => stopCampaignSuccess({ campaign, dialogRefId: action.dialogRefId })),
          catchError(error => of(stopCampaignFailure({ error })))
        )
      )
    )
  );

  adjustCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adjustCampaign),
      exhaustMap(action =>
        this.campaignsService.adjustCampaign(action.timeAdjustmentPayload).pipe(
          map(campaign => adjustCampaignSuccess({ campaign, dialogRefId: action.timeAdjustmentPayload.dialogRefId })),
          catchError(error => of(adjustCampaignFailure({ error })))
        )
      )
    )
  );

  adjustCampaignActivityAndTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adjustCampaignActivityAndTime),
      exhaustMap(({ active, timeAdjustmentPayload }) => {
        const adjustTimeRequest = this.campaignsService.adjustCampaign(timeAdjustmentPayload);
        const adjustActivityRequest = active
          ? this.campaignsService.startCampaign(timeAdjustmentPayload.campaignDetails.id)
          : this.campaignsService.stopCampaign(timeAdjustmentPayload.campaignDetails.id);

        return combineLatest([adjustTimeRequest, adjustActivityRequest]).pipe(
          map(([timeAdjustmentRes, activityAdjustmentRes]) =>
            adjustCampaignActivityAndTimeSuccess({
              dialogRefId: timeAdjustmentPayload.dialogRefId,
              campaign: {
                ...timeAdjustmentRes,
                status: activityAdjustmentRes.status
              }
            })
          ),
          catchError(error => of(adjustCampaignActivityAndTimeFailure({ error })))
        );
      })
    )
  );

  adjustCampaignSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startCampaignSuccess, stopCampaignSuccess, adjustCampaignSuccess, adjustCampaignActivityAndTimeSuccess),
        tap(({ campaign, dialogRefId }) => this.matDialog.getDialogById(dialogRefId)?.close(true))
      ),
    { dispatch: false }
  );

  uploadCampaignImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadCampaignImage),
      exhaustMap(({ partnerReferenceId, campaignId, file, formControl, dialogRefId, imageType }) =>
        this.campaignsService.uploadCampaignImage(partnerReferenceId, file, imageType, campaignId).pipe(
          map(({ imageUrl }) =>
            uploadCampaignImageSuccess({
              imageUrl,
              formControl,
              dialogRefId,
              imageType
            })
          ),
          catchError(error => of(uploadCampaignImageFailure({ error })))
        )
      )
    )
  );

  uploadCampaignImageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadCampaignImageSuccess),
        tap(({ formControl, imageType, imageUrl, dialogRefId }) => {
          formControl.setValue(imageUrl);
          this.matDialog.getDialogById(dialogRefId)?.close(true);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private campaignsService: CampaignsService,
    private matDialog: MatDialog
  ) {}
}
