import { FormControl } from '@angular/forms';

export interface TicketForm {
  subject: FormControl<string>;
  // topic field is visble to RC tenants
  topic?: FormControl<string>;
  comment: FormControl<string>;
  email: FormControl<string>;
}

export const topicOptions = [
  'dashboard support',
  'integration support',
  'redemption support',
  'report technical incident',
  'security and compliance',
  'billing and payments',
  'legal and contracting',
  'marketing and partnerships',
  'account upgrade',
  'account termination',
  'training and onboarding',
  'feedback and suggestions',
  'general inquiry'
];
