import { AppModule } from '../../../app.module';
import { AmplitudeService } from '../../../services/amplitude/amplitude.service';

export const logAmplitudeEvent =
  (message: string) => (_target: object, _propertyName: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const result = originalMethod.apply(this, args);

      const amplitudeService = AppModule.injector.get(AmplitudeService);
      amplitudeService.track(message);
      return result;
    };

    return descriptor;
  };
