import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { HttpError } from '@core/types';

import { tenantLocalesQuery } from '../../../tenant-locales/store/selectors/tenant-locales.selectors';
import { TenantLocaleState } from '../../../tenant-locales/types';
import { PointsActivitiesService } from '../../services/points-activities/points-activities.service';
import { PointsActivityState } from '../../types';
import {
  loadPointsActivities,
  loadPointsActivitiesFailure,
  loadPointsActivitiesSuccess,
  loadPointsActivity,
  loadPointsActivityFailure,
  loadPointsActivitySuccess
} from '../actions/points-activities.actions';
import { pointsActivitiesQuery } from '../selectors/points-activities.selectors';

@Injectable()
export class PointsActivitiesEffects {
  loadPointsActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPointsActivities),
      withLatestFrom(this.store.select(pointsActivitiesQuery.getLocale)),
      withLatestFrom(this.store.select(tenantLocalesQuery.getTenantLocalesList)),
      exhaustMap(([[{ userId, filter }, locale], tenantLocales]) =>
        this.pointsActivitiesService.getPointsActivities(userId, filter, locale, tenantLocales).pipe(
          map(result => loadPointsActivitiesSuccess({ result, userId })),
          catchError((error: HttpError) => of(loadPointsActivitiesFailure({ error })))
        )
      )
    )
  );

  loadPointsActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPointsActivity),
      withLatestFrom(this.store.select(pointsActivitiesQuery.getLocale)),
      withLatestFrom(this.store.select(tenantLocalesQuery.getTenantLocalesList)),
      exhaustMap(([[{ userId, pointsActivityId }, locale], tenantLocales]) =>
        this.pointsActivitiesService.getPointsActivity(userId, pointsActivityId, locale, tenantLocales).pipe(
          map(pointsActivity => loadPointsActivitySuccess({ pointsActivity })),
          catchError((error: HttpError) => of(loadPointsActivityFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pointsActivitiesService: PointsActivitiesService,
    private store: Store<PointsActivityState | TenantLocaleState>
  ) {}
}
