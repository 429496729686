<mat-radio-group [formControl]="formControl">
  @for (option of options; track option.value) {
    <div
      class="mb-2 flex cursor-pointer items-center rounded-md px-2 py-4 hover:bg-cloudburst-150"
      [ngClass]="{
        'bg-cloudburst-150': formControl.value === option.value,
        'pointer-events-none': isDisabled,
        'text-[#00000061]': isDisabled
      }"
      (click)="formControl.setValue(option.value)"
    >
      <mat-radio-button #item color="primary" [value]="option.value" class="min-w-[150px] px-4 py-2">
        {{ option.label }}
      </mat-radio-button>
      <div>
        {{ option.description }}
      </div>
    </div>
  }
</mat-radio-group>
