import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { NewWatchList, UpdateWatchlistParams, Watchlist, WatchlistsFilter, WatchlistsResult } from '../../types';

export const loadWatchlists = createAction('[Watchlists] Load Watchlists', props<{ filter: WatchlistsFilter }>());
export const loadWatchlistsSuccess = createAction(
  '[Watchlists] Load Watchlists Success',
  props<{ result: WatchlistsResult }>()
);
export const loadWatchlistsFailure = createAction('[Watchlists] Load Watchlists Failure', props<ErrorState>());

export const loadWatchlist = createAction('[Watchlists] Load Watchlist', props<{ id: string }>());
export const loadWatchlistSuccess = createAction(
  '[Watchlists] Load Watchlist Success',
  props<{ watchlist: Watchlist }>()
);
export const loadWatchlistFailure = createAction('[Watchlists] Load Watchlist Failure', props<ErrorState>());

export const createWatchlist = createAction('[Watchlists] Create Watchlist', props<{ watchlist: NewWatchList }>());
export const createWatchlistSuccess = createAction(
  '[Watchlists] Create Watchlist Success',
  props<{ watchlist: Watchlist }>()
);
export const createWatchlistFailure = createAction('[Watchlists] Create Watchlist Failure', props<ErrorState>());

export const updateWatchlist = createAction(
  '[Watchlists] Update Watchlist',
  props<{ params: UpdateWatchlistParams }>()
);
export const updateWatchlistSuccess = createAction(
  '[Watchlists] Update Watchlist Success',
  props<{ watchlist: Watchlist }>()
);
export const updateWatchlistFailure = createAction('[Watchlists] Update Watchlist Failure', props<ErrorState>());

export const deleteWatchlist = createAction('[Watchlists] Delete Watchlist', props<{ id: string }>());
export const deleteWatchlistSuccess = createAction('[Watchlists] Delete Watchlist Success');
export const deleteWatchlistFailure = createAction('[Watchlists] Delete Watchlist Failure', props<ErrorState>());
