import { ErrorHandler, Injectable } from '@angular/core';

import { RollbarService } from './rollbar.service';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private rollbarService: RollbarService) {}

  handleError(err: any): void {
    this.rollbarService.handleError(err.originalError || err);
  }
}
