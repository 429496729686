import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { AppConnectionAction, AppConnectionRequestAction } from '../../types/app-connection-action.type';
import { AppConnection } from '../../types/app-connections.type';

export const loadAppConnections = createAction('[App Connection] Load App Connections');
export const loadAppConnectionsSuccess = createAction(
  '[App Connection] Load App Connections Success',
  props<{ appConnections: AppConnection[] }>()
);
export const loadAppConnectionsFailure = createAction(
  '[App Connection] Load App Connections Failure',
  props<ErrorState>()
);

export const loadAppConnection = createAction('[App Connection] Load App Connection', props<{ id: string }>());
export const loadAppConnectionSuccess = createAction(
  '[App Connection] Load App Connection Success',
  props<{ appConnection: AppConnection }>()
);
export const loadAppConnectionFailure = createAction(
  '[App Connection] Load App Connection Failure',
  props<ErrorState>()
);

export const fetchAppConnection = createAction('[App Connection] Fetch App Connection', props<{ id: string }>());
export const fetchAppConnectionSuccess = createAction(
  '[App Connection] Fetch App Connection Success',
  props<{ appConnection: AppConnection }>()
);
export const fetchAppConnectionFailure = createAction(
  '[App Connection] Fetch App Connection Failure',
  props<{ id: string }>()
);

export const createAppConnection = createAction(
  '[App Connection] Create App Connection',
  props<AppConnectionRequestAction>()
);
export const createAppConnectionSuccess = createAction(
  '[App Connection] Create App Connection Success',
  props<AppConnectionAction>()
);
export const createAppConnectionFailure = createAction(
  '[App Connection] Create App Connection Failure',
  props<ErrorState>()
);

export const updateAppConnection = createAction(
  '[App Connection] Update App Connection',
  props<AppConnectionRequestAction>()
);
export const updateAppConnectionSuccess = createAction(
  '[App Connection] Update App Connection Success',
  props<AppConnectionAction>()
);
export const updateAppConnectionFailure = createAction(
  '[App Connection] Update App Connection Failure',
  props<ErrorState>()
);

export const updateAppConnectionSigningKeys = createAction(
  '[App Connection] Update App Connection Signing Keys',
  props<{ id: string }>()
);
export const updateAppConnectionSigningKeysSuccess = createAction(
  '[App Connection] Update App Connection Signing Keys Success',
  props<{ appConnection: AppConnection }>()
);
export const updateAppConnectionSigningKeysFailure = createAction(
  '[App Connection] Update App Connection Signing Keys Failure',
  props<ErrorState>()
);
