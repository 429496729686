import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';

import { initiateStepUp } from '@core/store/auth/actions/auth.actions';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { HttpError } from '@core/types';

interface DialogData {
  error: HttpError;
  stepUpAction: Action;
  messageKey?: string;
}

@Component({
  selector: 'admin-step-up-dialog',
  templateUrl: './step-up-dialog.component.html',
  styleUrls: ['./step-up-dialog.component.scss']
})
export class StepUpDialogComponent implements OnInit {
  messageKey: string;

  constructor(
    public dialogRef: MatDialogRef<StepUpDialogComponent>,
    private store: Store<AuthState>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.messageKey = this.data?.messageKey;
  }

  authenticate(): void {
    const { messageKey, ...data } = this.data;

    this.store.dispatch(initiateStepUp({ ...data }));
  }
}
