import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'admin-verified-indicator',
  templateUrl: './verified-indicator.component.html',
  styleUrls: ['./verified-indicator.component.scss']
})
export class VerifiedIndicatorComponent implements OnInit {
  @Input() isVerified: boolean;

  verifiedLabel: string;

  ngOnInit(): void {
    this.verifiedLabel = this.isVerified ? 'verified' : 'unverified';
  }
}
