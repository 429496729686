import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadUserPasswords } from '../store/actions/user-passwords.actions';
import { UserPasswordState } from '../types/user-passwords.type';

export const userPasswordsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<UserPasswordState> = inject(Store<UserPasswordState>),
  scopes: Scopes = inject(Scopes)
): void => {
  if (scopes.lackScopes(SCOPES_OR.viewPasswords)) {
    return;
  }

  const userId = Params.find(route, 'userId');
  store.dispatch(loadUserPasswords({ userId }));
};
