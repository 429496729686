import { JsonPipe } from '@angular/common';

import { Nullable } from '@shared/types';

import { RecipientAddress } from '../modules/orders/types';
import { OtherPii, UserAddress } from '../modules/users/types';

export class Formatters {
  static booleanFormatter(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  static jsonFormatter(value: object): string {
    return `<pre>${new JsonPipe().transform(value)}</pre>`;
  }

  // for conforming safari date format
  // transform "2020-01-04 16:10:00" to "2020/01/04 16:10:00"
  static dateFormatter(value: string): Nullable<string> {
    const validFormat = /^\d{4}-\d{2}-\d{2}/;

    if (!value || !validFormat.test(value)) {
      return null;
    }

    return value.slice(0, 11).replaceAll(/-/gi, '/') + value.slice(11);
  }

  static userAddressFormatter(address: UserAddress): string {
    if (address?.formatted) {
      return address.formatted;
    } else if (address) {
      const { streetAddress, locality, region, postalCode, country } = address;
      return [streetAddress, locality, region, postalCode, country].filter(item => !!item).join(', ');
    } else {
      return '';
    }
  }

  static recipientAddressFormatter(address: RecipientAddress): string {
    const { line1, line2, city, state, postalCode, countryCode } = address;
    return [
      [line1, line2].filter(item => !!item).join(' '),
      [city, state].filter(item => !!item).join(' '),
      postalCode,
      countryCode
    ]
      .filter(item => !!item)
      .join(', ');
  }

  static otherPiiFormatter(otherPii: OtherPii, subKeys: string[]): string {
    if (otherPii) {
      return otherPii[subKeys[0]]?.find(item => item.type === subKeys[1])?.value ?? 'N/A';
    }

    return 'N/A';
  }

  static loyaltyDataFormatter(loyaltyData: object, subKeys: string[]): string {
    if (loyaltyData) {
      switch (loyaltyData[subKeys[0]]) {
        case true: {
          return 'Yes';
        }
        case false: {
          return 'No';
        }
        default: {
          return loyaltyData[subKeys[0]] || 'N/A';
        }
      }
    }

    return 'N/A';
  }

  // if it's 12-hour format, the output string format from ngxTimepicker will be '01:26 PM'
  // if it's 24-hour format, the output string format from ngxTimepicker will be '13:26'
  static ngxTimepickerISOFormatter(format: 12 | 24, inputTime: string, inputDate: string): string {
    const year = new Date(inputDate).getFullYear();
    const month = new Date(inputDate).getMonth();
    const date = new Date(inputDate).getDate();

    const [hourStr, minStr] = inputTime.split(' ')[0].split(':');
    let hour = Number(hourStr);
    const minute = Number(minStr);

    // transform to 12-hour format to 24-hour format
    if (format === 12) {
      const unit = inputTime.split(' ')[1];
      if (unit === 'AM') {
        hour = hour === 12 ? 0 : hour;
      } else {
        hour = hour + 12 === 24 ? hour : hour + 12;
      }
    }

    // output format: '2021-01-31T23:30:00.000Z'
    return new Date(Date.UTC(year, month, date, hour, minute, 0)).toISOString();
  }

  static fromSnakeToTitleCase(value: string): string {
    return value
      ?.split('_')
      .map(newVal => Formatters.upperFirstChar(newVal) + newVal.slice(1))
      .join(' ');
  }

  static fromSnakeToSentenceCase(value: string): string {
    return Formatters.toSentenceCase(value?.split('_').join(' '));
  }

  static fromSnakeToCamelCase(value: string): string {
    return value?.toLowerCase().replaceAll(/[^a-zA-Z\d]+(.)/g, (_, chr) => chr.toUpperCase());
  }

  static fromCamelToSnakeCase(value: string): string {
    return value.replaceAll(/[A-Z]/g, str => `_${str.toLowerCase()}`);
  }

  static fromCamelToTitleCase(value: string): string {
    const result = value?.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    return Formatters.upperFirstChar(result) + result.slice(1);
  }

  static fromCamelToSentenceCase(value: string): string {
    const words = value
      ?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .split(' ')
      .map(word => {
        if (word === word.toUpperCase()) {
          return word;
        } else if (word === 'id') {
          return word.toUpperCase();
        } else {
          return word.toLowerCase();
        }
      });
    const result = words.join(' ');
    return Formatters.upperFirstChar(result) + result?.slice(1);
  }

  static fromKebabToCamelCase(value: string): string {
    return value?.replace(/-./g, x => x[1].toUpperCase());
  }

  static fromTitleToCamelCase(value: string): string {
    return value
      .replaceAll(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
      .replaceAll(/\s+/g, '');
  }

  static fromSentenceToSnakeCase(value: string): string {
    return value.toLowerCase().replace(' ', '_');
  }

  static toSentenceCase(value: string): string {
    return Formatters.upperFirstChar(value) + value?.slice(1).toLowerCase();
  }

  private static upperFirstChar(value: string): string {
    return value?.charAt(0).toUpperCase();
  }
}
