import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of, merge, exhaustMap } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { TravelConfigsService } from '../../services/travel-configs.service';
import {
  loadTravelConfig,
  loadTravelConfigFailure,
  loadTravelConfigSuccess,
  loadPopularDestinations,
  loadPopularDestinationsSuccess,
  loadPopularDestinationsFailure,
  updateTravelConfigFailure,
  updateTravelConfigSuccess,
  updatePopularDestinations,
  updatePopularDestinationsSuccess,
  updatePopularDestinationsFailure,
  updateTravelConfig
} from '../actions/travel-configs.actions';

@Injectable()
export class TravelConfigsEffects {
  loadTravelConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTravelConfig),
      switchMap(({ travelType }) =>
        this.travelConfigsService.getTravelConfig(travelType).pipe(
          map(travelConfig => loadTravelConfigSuccess({ travelType, travelConfig })),
          catchError(error => of(loadTravelConfigFailure({ error })))
        )
      )
    )
  );

  loadPopularDestinations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPopularDestinations),
      switchMap(() =>
        this.travelConfigsService.getPopularDestinations().pipe(
          map(popularDestinations => loadPopularDestinationsSuccess({ popularDestinations })),
          catchError(error => of(loadPopularDestinationsFailure({ error })))
        )
      )
    )
  );

  updateTravelConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTravelConfig),
      switchMap(({ travelType, travelConfig }) =>
        this.travelConfigsService.updateTravelConfig(travelType, travelConfig).pipe(
          map(() => updateTravelConfigSuccess(travelType)),
          catchError(error => of(updateTravelConfigFailure(error)))
        )
      )
    )
  );

  updatePopularDestinations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePopularDestinations),
      switchMap(({ popularDestinations }) =>
        this.travelConfigsService.updatePopularDestinations(popularDestinations).pipe(
          map(() => updatePopularDestinationsSuccess()),
          catchError(error => of(updatePopularDestinationsFailure(error)))
        )
      )
    )
  );

  updateTravelConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTravelConfigSuccess),
      exhaustMap(({ travelType }) =>
        merge([
          loadTravelConfig({ travelType }),
          routerForceNavigate({ path: `/rewards-offering/${travelType}-config` })
        ])
      )
    )
  );

  constructor(
    private actions$: Actions,
    private travelConfigsService: TravelConfigsService
  ) {}
}
