<div *ngIf="allowCancellationStatuses.includes(orderItem.status)">
  <admin-tooltip
    [key]="'orders-items.details.orderItem.refundOrder?allowed=' + enableRefundButton"
    [displayIcon]="false"
  >
    <button
      *adminIfScopesInclude="SCOPES.cancelOrderItems; relationOperator: 'or'"
      [ngClass]="styleClassName"
      [class.disabled-button]="!enableRefundButton"
      [disabled]="!enableRefundButton"
      (click)="refundOrderItemDialog()"
    >
      Refund
    </button>
  </admin-tooltip>
</div>
