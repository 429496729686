var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { StorageManager } from './storage_manager';
var TransactionManager = /** @class */ (function () {
    function TransactionManager(options) {
        var _this = this;
        this.prefix = options.prefix;
        this.transactions = {};
        this.storage = new StorageManager();
        this.transactions = this.storage.getAllKeys().reduce(function (transactions, key) {
            var _a;
            var state = key.replace(_this.prefix, '');
            return key.startsWith(_this.prefix) ? __assign(__assign({}, transactions), (_a = {}, _a[state] = _this.storage.get(key), _a)) : transactions;
        }, {});
    }
    TransactionManager.prototype.create = function (state, transaction) {
        this.transactions[state] = transaction;
        this.storage.save(this.getTransactionKey(state), transaction);
    };
    TransactionManager.prototype.get = function (state) {
        return this.transactions[state];
    };
    TransactionManager.prototype.getTransactionKey = function (state) {
        return "" + this.prefix + state;
    };
    TransactionManager.prototype.removeAllTransactions = function () {
        var _this = this;
        Object.keys(this.transactions).forEach(function (key) {
            delete _this.transactions[key];
            _this.storage.remove(_this.prefix + key);
        });
    };
    return TransactionManager;
}());
export { TransactionManager };
