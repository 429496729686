import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  initialState,
  merchandiseCategoryAdapter,
  MerchandiseCategoryState
} from '../../types/merchandise-categories.type';
import {
  loadMerchandiseCategories,
  loadMerchandiseCategoriesFailure,
  loadMerchandiseCategoriesSuccess,
  loadMerchandiseCategory,
  loadMerchandiseCategoryFailure,
  loadMerchandiseCategorySuccess
} from '../actions/merchandise-categories.actions';

export const merchandiseCategoryTriggers: EntityTriggers = {
  single: {
    loading: [loadMerchandiseCategory.type],
    resting: [loadMerchandiseCategorySuccess.type],
    erroring: [loadMerchandiseCategoryFailure.type]
  },
  batch: {
    loading: [loadMerchandiseCategories.type],
    resting: [loadMerchandiseCategoriesSuccess.type],
    erroring: [loadMerchandiseCategoriesFailure.type]
  }
};

export function merchandiseCategoriesReducer(
  state: MerchandiseCategoryState = initialState,
  action: Action
): MerchandiseCategoryState {
  return callStateReducer(baseReducer, merchandiseCategoryTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadMerchandiseCategorySuccess, (state, { merchandiseCategory }) =>
    merchandiseCategoryAdapter.upsertOne(merchandiseCategory, state)
  ),
  on(loadMerchandiseCategoriesSuccess, (state, { merchandiseCategories }) =>
    merchandiseCategoryAdapter.setAll(merchandiseCategories, state)
  )
);
