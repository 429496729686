import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { FetchUserService } from '@shared/services/fetch/fetch-user.service';
import { Nullable } from '@shared/types';

import { User } from '../../users/types';
import { ApprovalRequestsResult } from '../types/approval-requests-result.type';
import { ApprovalRequest, PointsAdjustmentRequestParams } from '../types/approval-requests.type';
import { ApprovalResponse } from '../types/approval-responses.type';

@Injectable({
  providedIn: 'root'
})
export class ApprovalRequestsFormatterService {
  constructor(private fetchUserService: FetchUserService) {}

  format(approvalRequest: ApprovalRequest): Observable<ApprovalRequest> {
    const userFetches = this.getUserFetches([approvalRequest]);

    return combineLatest(userFetches).pipe(map(users => this.mapRequest(approvalRequest, users)));
  }

  formatResult(approvalRequestsResult: ApprovalRequestsResult): Observable<ApprovalRequestsResult> {
    const userFetches = this.getUserFetches(approvalRequestsResult.data);

    return combineLatest(userFetches).pipe(
      map(users => ({
        ...approvalRequestsResult,
        data: approvalRequestsResult.data.map(request => this.mapRequest(request, users))
      }))
    );
  }

  private mapRequest(approvalRequest: ApprovalRequest, users: User[]): ApprovalRequest {
    return {
      ...approvalRequest,
      requester: users.find(user => user?.id === approvalRequest.requesterId),
      recipient: users.find(
        user => user?.id === (approvalRequest.requestParams as PointsAdjustmentRequestParams)?.userId
      ),
      approvalResponses: approvalRequest.approvalResponses.map(response => this.mapResponse(response, users))
    };
  }

  private mapResponse(approvalResponse: ApprovalResponse, users: User[]): ApprovalResponse {
    return {
      ...approvalResponse,
      responder: users.find(user => user?.id === approvalResponse.responderId)
    };
  }

  private getUserFetches(approvalRequests: ApprovalRequest[]): Observable<User>[] {
    return this.getUniqueUserIds(approvalRequests).map(id => this.fetchUser(id)) as Observable<User>[];
  }

  // gather all unique user IDs that can be found in approval requests
  private getUniqueUserIds(approvalRequests: ApprovalRequest[]): string[] {
    return Array.from(
      new Set(
        approvalRequests
          .flatMap(request => [
            request.requesterId,
            (request.requestParams as PointsAdjustmentRequestParams).userId,
            ...request.approvalResponses.map(response => response.responderId)
          ])
          .filter(Boolean)
      )
    );
  }

  private fetchUser(id: string): Observable<Nullable<User>> {
    return this.fetchUserService.fetch(id).pipe(
      catchError(() => of(null)) // gracefully handle cases when user is not found
    );
  }
}
