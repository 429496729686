import { catchError, concatMap, exhaustMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, of } from 'rxjs';

import { HttpError } from '@core/types';

import { PointsAccountsService } from '../../services/points-accounts/points-accounts.service';
import { PointsAccountState, PointsActivitiesFilter, PointsActivityState } from '../../types';
import {
  loadPointsAccounts,
  loadPointsAccountsFailure,
  loadPointsAccountsSuccess,
  setSelectedPointsAccountId
} from '../actions/points-accounts.actions';
import { loadPointsActivities } from '../actions/points-activities.actions';
import { pointsAccountsQuery } from '../selectors/points-accounts.selectors';
import { pointsActivitiesQuery } from '../selectors/points-activities.selectors';

@Injectable()
export class PointsAccountsEffects {
  loadPointsAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPointsAccounts),
      exhaustMap(action =>
        this.pointsAccountsService.getUserPointsAccounts(action.userId).pipe(
          map(pointsAccounts => loadPointsAccountsSuccess({ pointsAccounts, ...action })),
          catchError((error: HttpError) => of(loadPointsAccountsFailure({ error })))
        )
      )
    )
  );

  loadPointsAccountsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPointsAccountsSuccess),
      filter(({ pointsAccounts }) => pointsAccounts.length > 0),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.pointsActivityStore.select(
              pointsActivitiesQuery.isPointsActivitiesLoadedWithPointsAccount(action.pointsAccounts[0].id)
            ),
            this.pointsAccountStore.select(pointsAccountsQuery.getSelectedPointsAccountId)
          )
        )
      ),
      mergeMap(([{ pointsAccounts, userId, pointsActivitiesLoad }, isLoaded, selectedId]) => {
        const actions: Action[] = [];

        if (pointsActivitiesLoad) {
          // check if current selectedId belongs to one of points account that we just loaded
          // If not (e.g., when switching to another user), set selectedId as user's first points account's id
          const pointsAccountId = pointsAccounts.some(account => account.id === selectedId)
            ? selectedId
            : pointsAccounts[0].id;

          actions.push(setSelectedPointsAccountId({ selectedId: pointsAccountId }));

          if (pointsActivitiesLoad === 'force' || (pointsActivitiesLoad === 'only-initial' && !isLoaded)) {
            actions.push(
              loadPointsActivities({ userId, filter: { ...new PointsActivitiesFilter(), pointsAccountId } })
            );
          }
        }

        return merge(actions);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private pointsActivityStore: Store<PointsActivityState>,
    private pointsAccountStore: Store<PointsAccountState>,
    private pointsAccountsService: PointsAccountsService
  ) {}
}
