import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';
import { Nullable } from '@shared/types';

import { App, appAdapter, AppState } from '../../types/apps.type';

const appsDetector = createDetector<App>();
const getAppState = createFeatureSelector<AppState>('app');

const { selectAll: getAppsList, selectEntities: getAppsDictionary } = appAdapter.getSelectors(getAppState);

const {
  isBatchInitial,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError
} = getCallStateSelectors<AppState>(getAppState);

const getFetchList = getFetchStateSelector<AppState>(getAppState);

const isAppsLoaded = createSelector(
  getAppsDictionary,
  isBatchResting,
  (apps, batchResting) => appsDetector('allLoaded')(apps) && batchResting
);

const getAppById = (id: string): Selector<AppState, Nullable<App>> =>
  createSelector(getAppsDictionary, apps => apps[id] ?? null);

const isAppLoaded = (id: string): Selector<AppState, boolean> =>
  createSelector(getAppsDictionary, apps => appsDetector('oneLoaded')(apps, id));

const getAppSecret = (id: string): Selector<AppState, string> =>
  createSelector(getAppsDictionary, apps => apps[id]?.secret ?? '');

export const appsQuery = {
  getAppsList,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isAppsLoaded,
  isAppLoaded,
  getFetchList,
  getAppById,
  getAppSecret,
  getAppsDictionary
};
