import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { TooltipModuleKey } from '../types/tooltip-keys.type';
import { Tooltip } from '../types/tooltips.type';

@Injectable({
  providedIn: 'root'
})
export class TooltipsService {
  constructor(private missionControlService: MissionControlService) {}

  getTooltips(module: TooltipModuleKey): Observable<Tooltip[]> {
    return this.missionControlService.get<Tooltip[]>('tooltips', new HttpParams({ fromObject: { module } }));
  }

  updateTooltips(tooltips: Tooltip[]): Observable<Tooltip[]> {
    return this.missionControlService.patch<Tooltip[]>('tooltips/update', { tooltips });
  }
}
