import { StaticProvider } from '@angular/core';

import { uuidv4 } from '@utils';

import { AppModuleConfig } from './app/app-module-config';

export const fetchWithTimeoutAndRetry = async (
  endpoint: string,
  timeout: number,
  tries: number,
  entity: string,
  headers = {}
): Promise<any> => {
  for (let n = 0; n < tries; n++) {
    const controller = new AbortController();
    const timeoutID = setTimeout(() => controller.abort(), timeout);
    try {
      const response = await fetch(endpoint, { headers, signal: controller.signal });
      clearTimeout(timeoutID);
      return response?.json();
    } catch {
      clearTimeout(timeoutID);
    }
  }

  throw new Error(`Fetching ${entity} timed out after ${tries} requests.`);
};

export const getMcBootstrap = (baseUrl: string): Promise<any> =>
  fetchWithTimeoutAndRetry(`${baseUrl}/bootstrap`, 3000, 3, 'bootstrap', { 'X-Request-Id': uuidv4() });

export const handleError = (error: Error): void => {
  // print to console
  console.error(error);

  // create error page
  const body = document.querySelector('body')!;
  const div = document.createElement('div');

  body.style.cssText = 'display: flex; justify-content:center; align-items: center;';
  div.style.cssText = 'margin: 50px; padding: 50px; text-align: center';

  div.innerHTML = `
    <h1>Application Error</h1>
    <p>We can't create Admin Panel at the moment.</p>
    <p style="font-size: 0.8rem; color: grey">${error.toString()}</p>
    <hr>
    <div style="text-align: left; margin: auto">
      <p>Please refer to the following instructions: </p>
      <ul>
        <li>Check if the url is correct</li>
        <li>Connect to Ascenda VPN (if applicable)</li>
        <li>Check with manager to ensure that your account is valid</li>
        <li>Clear browser cache</li>
        <li>Disable adblock</li>
        <li>Try again later</li>
      </ul>
    </div>
  `;

  body.append(div);
};

const isLocalDev = (): boolean => window.location.hostname.split('.')[0].includes('ap-dev');

export const createEnvVariableProviders = (config: AppModuleConfig): StaticProvider[] => [
  {
    provide: 'env',
    useValue: config.env
  },
  {
    provide: 'production',
    useValue: config.production
  },
  {
    provide: 'guardhouseApiUrl',
    useValue: config.guardhouseApiUrl
  },
  {
    provide: 'connection',
    useValue: config.connection
  },
  {
    provide: 'customerBankIdentityProvider',
    useValue: config.customerBankIdentityProvider
  },
  {
    provide: 'scope',
    useValue: config.scope
  },
  {
    provide: 'redirectUri',
    useValue: config.redirectUri
  },
  {
    provide: 'responseType',
    useValue: config.responseType
  },
  {
    provide: 'missionControlApiBaseUrl',
    useValue: config.missionControlApiBaseUrl
  },
  {
    provide: 'callbackRedirects',
    useValue: config.callbackRedirects
  },
  {
    provide: 'idleCheckConfig',
    useValue: config.idleCheckConfig
  },
  {
    provide: 'sessionCheckPollInterval',
    useValue: config.sessionCheckPollInterval
  },
  {
    provide: 'loyaltyCurrency',
    useValue: config.loyaltyCurrency
  },
  {
    provide: 'tenants',
    useValue: config.tenants
  },
  {
    provide: 'isGlobalApp',
    useValue: config.isGlobalApp
  },
  {
    provide: 'pointsAdjustmentDecimals',
    useValue: config.pointsAdjustmentDecimals
  },
  {
    provide: 'pointsActivityDecimals',
    useValue: config.pointsActivityDecimals
  },
  {
    provide: 'pointsBalanceDecimals',
    useValue: config.pointsBalanceDecimals
  },
  {
    provide: 'tenantId',
    useValue: config.tenantId
  },
  {
    provide: 'extraUserFilters',
    useValue: config.extraUserFilters
  },
  {
    provide: 'extraEnrollmentFilters',
    useValue: config.extraEnrollmentFilters
  },
  {
    provide: 'secureFieldsConfigs',
    useValue: config.secureFieldsConfigs
  },
  {
    provide: 'impersonateInNewTab',
    useValue: config.impersonateInNewTab
  },
  {
    provide: 'partnerPlatform',
    useValue: config.partnerPlatform
  },
  {
    provide: 'showPointsAccountsSelector',
    useValue: config.showPointsAccountsSelector
  },
  {
    provide: 'showAgentDashboard',
    useValue: config.showAgentDashboard
  },
  {
    provide: 'showApidDetails', // TODO: remove this after UAT test finished
    useValue: config.showApidDetails
  },
  {
    provide: 'showRewardsIdSelection',
    useValue: config.showRewardsIdSelection
  },
  {
    provide: 'appId',
    useValue: isLocalDev() ? config.developmentAppId : config.appId
  },
  {
    provide: 'agentIdentitySource',
    useValue: config.agentIdentitySource
  },
  {
    provide: 'travelEdgeAdminDomain',
    useValue: config.travelEdgeAdminDomain
  },
  {
    provide: 'uiConfigs',
    useValue: config.uiConfigs
  },
  {
    provide: 'analytics',
    useValue: config.analytics
  }
];
