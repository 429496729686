import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Nullable, Tag, TagType } from '@shared/types';
import { Params } from '@utils';

import { loadAuditLogs } from '../store/actions/audit-logs.actions';
import { AuditLogsFilter, AuditLogState } from '../types';

export const userAuditLogsResolver: ResolveFn<Nullable<Tag[]>> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<AuditLogState> = inject(Store<AuditLogState>),
  scopes: Scopes = inject(Scopes)
): Nullable<Tag[]> => {
  if (scopes.lackScopes(SCOPES_OR.viewAuditLogs)) {
    return null;
  }

  const isCustomerView = route.parent!.data.isCustomerView;
  const tagType: TagType = isCustomerView ? 'user_id' : 'agent_id';
  const userId = Params.find(route, 'userId');

  const tags = [{ type: tagType, id: userId }];

  const filter = new AuditLogsFilter();
  filter.tags = [{ type: tagType, id: userId }];

  store.dispatch(loadAuditLogs({ filter }));

  return tags;
};
