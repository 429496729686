import { first, map } from 'rxjs/operators';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadOrderItems } from '../store/actions/order-items.actions';
import { orderItemsQuery } from '../store/selectors/order-items.selectors';
import { OrderItemsFilter, OrderItemState } from '../types';

export const orderItemsResolver: ResolveFn<OrderItemsFilter> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<OrderItemState> = inject(Store<OrderItemState>),
  scopes: Scopes = inject(Scopes)
): Observable<OrderItemsFilter> => {
  if (scopes.lackScopes(SCOPES_OR.viewOrderItems)) {
    return of(null);
  }

  const userId = Params.find(route, 'userId');
  const orderId = Params.findQueryParam(route, 'orderId');

  return combineLatest([
    store.select(orderItemsQuery.isOrderItemsLoaded),
    store.select(orderItemsQuery.getOrderItemsFilter)
  ]).pipe(
    first(),
    map(([isLoaded, filter]) => {
      const fromTopLevelView = filter?.isTopLevelView;
      const hasDifferentUserId = filter?.userId !== userId;
      const hasDifferentOrderId = orderId && filter?.orderId !== orderId;

      // user specific view (with userId in params)
      // reset filter and loadOrderItems when coming from global view, or from other user's or point activity's view
      if (userId && (fromTopLevelView || hasDifferentUserId || hasDifferentOrderId)) {
        filter = { ...new OrderItemsFilter(), ...(orderId && { orderId }), userId, isTopLevelView: false };
        store.dispatch(loadOrderItems({ filter }));
      }

      // global view (without userId in params)
      if (!userId) {
        filter ||= new OrderItemsFilter();

        if (!fromTopLevelView || !isLoaded) {
          filter = { ...new OrderItemsFilter(), isTopLevelView: true };
          store.dispatch(loadOrderItems({ filter }));
        }
      }

      return filter;
    })
  );
};
