import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Notification {
  channel: string;
  createdAt: string;
  delivery: string;
  eventName: string;
  id: string;
  subject: string;
  type: string;
}

export const notificationAdapter = createEntityAdapter<Notification>();

export interface NotificationState extends EntityState<Notification>, CallState {}

export const initialState: NotificationState = notificationAdapter.getInitialState<CallState>(initialCallState);
