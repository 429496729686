<mat-sidenav-container
  class="admin-side-nav-container"
  [ngClass]="{
    collapsed: isCollapsed()
  }"
>
  <mat-sidenav #sidenav class="admin-side-nav" mode="side" fixedInViewport opened>
    <div class="flex h-full flex-col bg-cloudburst-700 text-neutrals-white">
      <admin-side-nav-v2-header
        class="flex-shrink-0 transition-shadow duration-300"
        [ngClass]="{ 'z-[2] shadow-side-nav-scrolling': isCurrentSelectedGroupScrolling() }"
      />
      <admin-side-nav-v2-body class="flex-1" />
      <admin-side-nav-v2-footer class="flex-shrink-0" />
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="admin-side-nav-content">
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
