import { FormControl, ValidatorFn } from '@angular/forms';

export function confirmationValidator(siblingControlName: string): ValidatorFn {
  return (control: FormControl) => {
    if (!control.parent) {
      return null;
    }
    return control.parent.get(siblingControlName)?.value === control.value ? null : { mismatch: true };
  };
}
