import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';

@Pipe({ name: 'timezoneDisplay' })
export class TimezoneDisplayPipe implements PipeTransform {
  transform(value: string): Nullable<string> {
    const validFormat = /^[+-]\d{4}$/; // e.g. +1000 or -0830

    if (!value || !validFormat.test(value)) {
      return null;
    }

    return value.slice(0, 3) + ':' + value.slice(3, 5);
  }
}
