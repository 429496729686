import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  passwordPolicyAdapter,
  passwordPolicyInitialState,
  PasswordPolicyState
} from '../../types/password-policies.type';
import {
  createPasswordPolicy,
  createPasswordPolicyFailure,
  createPasswordPolicySuccess,
  deletePasswordPolicy,
  deletePasswordPolicyFailure,
  deletePasswordPolicySuccess,
  loadPasswordPolicies,
  loadPasswordPoliciesFailure,
  loadPasswordPoliciesSuccess,
  loadPasswordPolicy,
  loadPasswordPolicyFailure,
  loadPasswordPolicySuccess,
  updatePasswordPolicy,
  updatePasswordPolicyFailure,
  updatePasswordPolicySuccess
} from '../actions/password-policies.actions';

export const passwordPolicyTriggers: EntityTriggers = {
  single: {
    loading: [loadPasswordPolicy.type, createPasswordPolicy.type, deletePasswordPolicy.type, updatePasswordPolicy.type],
    resting: [
      loadPasswordPolicySuccess.type,
      createPasswordPolicySuccess.type,
      deletePasswordPolicySuccess.type,
      updatePasswordPolicySuccess.type
    ],
    erroring: [
      loadPasswordPolicyFailure.type,
      createPasswordPolicyFailure.type,
      deletePasswordPolicyFailure.type,
      updatePasswordPolicyFailure.type
    ]
  },
  batch: {
    loading: [loadPasswordPolicies.type],
    resting: [loadPasswordPoliciesSuccess.type],
    erroring: [loadPasswordPoliciesFailure.type]
  }
};

export function passwordPoliciesReducer(
  state: PasswordPolicyState = passwordPolicyInitialState,
  action: Action
): PasswordPolicyState {
  return callStateReducer(baseReducer, passwordPolicyTriggers)(state, action);
}

const baseReducer = createReducer(
  passwordPolicyInitialState,
  on(loadPasswordPolicySuccess, (state, { passwordPolicy }) => passwordPolicyAdapter.upsertOne(passwordPolicy, state)),
  on(loadPasswordPoliciesSuccess, (state, { passwordPolicies }) =>
    passwordPolicyAdapter.setAll(passwordPolicies, state)
  ),
  on(createPasswordPolicySuccess, (state, { passwordPolicy }) => passwordPolicyAdapter.addOne(passwordPolicy, state)),
  on(updatePasswordPolicySuccess, (state, { passwordPolicy }) => passwordPolicyAdapter.setOne(passwordPolicy, state)),
  on(deletePasswordPolicySuccess, (state, { id }) => passwordPolicyAdapter.removeOne(id, state))
);
