import * as amplitude from '@amplitude/analytics-browser';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { DecodedUser } from '@core/types';
import { HostTenantMappingUtils } from '@utils';

// Update this to `true` to enable Amplitude event tracking in development mode
const DEV_ENVIRONMENT_ENABLED = false;

type AmplitudeEventProperties = {
  environment: string;
  paymentTier: string;
  roles?: string[];
  tenant?: string;
};

@Injectable({ providedIn: 'root' })
export class AmplitudeService {
  isRC = HostTenantMappingUtils.isRC();
  isAutomationTenant = HostTenantMappingUtils.isAutomationTenant();
  currentUser$ = new BehaviorSubject<DecodedUser>(null);

  get enabled(): boolean {
    const devEnvironmentDisabled = this.env === 'devstaging' && !DEV_ENVIRONMENT_ENABLED;

    return this.isRC && !this.isAutomationTenant && !devEnvironmentDisabled;
  }

  constructor(
    @Inject('env') private readonly env: string,
    @Inject('amplitudeApiKey') private readonly amplitudeApiKey: string,
    @Inject(Store) private readonly authStore: Store<AuthState>,
    @Inject('paymentTierId') private readonly paymentTierId: string
  ) {
    if (this.enabled) {
      this.init();
    }
  }

  init(): void {
    amplitude.init(this.amplitudeApiKey, { autocapture: true });
    this.authStore.select(authQuery.getUser).subscribe(this.currentUser$);
  }

  track(eventMessage: string): void {
    if (!this.enabled) {
      return;
    }
    const identifyEvent = new amplitude.Identify();
    identifyEvent.setOnce('environment', this.env);
    identifyEvent.setOnce('paymentTier', this.paymentTierId);

    const eventProperties: AmplitudeEventProperties = {
      environment: this.env,
      paymentTier: this.paymentTierId
    };

    const user = this.currentUser$.value;
    if (user) {
      amplitude.setUserId(user.email ?? user.sub);

      identifyEvent.setOnce('tenant', user.tenantId);
      identifyEvent.setOnce('roles', user.roleIds);
      eventProperties.tenant = user.tenantId;
      eventProperties.roles = user.roleIds;
    }

    amplitude.identify(identifyEvent);
    amplitude.track(eventMessage, eventProperties);
  }
}
