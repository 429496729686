<div class="notice-container">
  <h1>Login Failed</h1>
  <p>Please refer to the following instructions:</p>
  <ul>
    <li>Connect to Ascenda VPN (if applicable)</li>
    <li>Check with manager to ensure that your account is valid</li>
    <li>Clear browser cache</li>
    <li>Disable adblock</li>
    <li>Try again later</li>
  </ul>
  <p>You can click <a [routerLink]="[]" (click)="redirectToLoginPage()">here</a> to retry login.</p>
</div>
