<div class="data-details-dialog-wrapper" [ngClass]="data.className">
  <h1 mat-dialog-title class="title">{{ data.title }}</h1>
  <div mat-dialog-content>
    <div class="details-container">
      <div class="column">
        <div class="item" *ngFor="let item of data.items">
          <div class="item__key">
            <h4>{{ item.key }}</h4>
          </div>
          <span *ngIf="item.value !== null" class="item__value">
            <ng-container [ngSwitch]="item.format">
              <span *ngSwitchCase="'titlecase'">{{ item.value | titlecase }}</span>
              <span *ngSwitchCase="'UTCDateTime'">{{ item.value | date: 'yyyy-MM-dd HH:mm:ss' : '+00:00' }}</span>
              <span *ngSwitchCase="'json'" class="json-data-block">{{ item.value | json }}</span>
              <span *ngSwitchDefault>{{ item.value }}</span>
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" class="text-primary uppercase-button" (click)="dialogRef.close(false)">
    <span class="uppercase-button__text">Close</span>
  </button>
</div>
