import { Component, Input, OnInit } from '@angular/core';

import { SCOPES_OR } from '@core/types';
import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';

import { CryptoOrderItemData, OrderItem, StripeReferenceData } from '../../types';

@UseV2Style
@Component({
  selector: 'admin-crypto-order-item',
  templateUrl: './crypto-order-item.component.html'
})
export class CryptoOrderItemComponent implements OnInit {
  @Input() customerId: string;
  @Input() detailsView: string;
  @Input() orderItem: OrderItem;
  @Input() stripeReferenceData: StripeReferenceData;
  data: CryptoOrderItemData;
  readonly SCOPES = SCOPES_OR;

  ngOnInit(): void {
    this.data = this.orderItem.data as CryptoOrderItemData;
  }
}
