import { Component, Input } from '@angular/core';

@Component({
  selector: 'admin-side-dropdown-menu',
  templateUrl: './side-dropdown-menu.component.html',
  styleUrls: ['./side-dropdown-menu.component.scss']
})
export class SideDropdownMenuComponent {
  @Input() inTable = false;
  @Input() panelClass: string;
  @Input() testid?: string;
}
