import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, roleAdapter, RoleState } from '../../types';
import {
  batchCreateDeletePermissions,
  batchCreateDeletePermissionsFailure,
  batchCreateDeletePermissionsSuccess,
  createRole,
  createRoleFailure,
  createRoleSuccess,
  loadRole,
  loadRoleFailure,
  loadRoles,
  loadRolesFailure,
  loadRolesSuccess,
  loadRoleSuccess,
  updateRolePermissions,
  updateRolePermissionsFailure,
  updateRolePermissionsSuccess
} from '../actions/roles.actions';

export const roleTriggers: EntityTriggers = {
  single: {
    loading: [loadRole.type, createRole.type, updateRolePermissions.type],
    resting: [loadRoleSuccess.type, createRoleSuccess.type, updateRolePermissionsSuccess.type],
    erroring: [loadRoleFailure.type, createRoleFailure.type, updateRolePermissionsFailure.type]
  },
  batch: {
    loading: [loadRoles.type, batchCreateDeletePermissions.type],
    resting: [loadRolesSuccess.type, batchCreateDeletePermissionsSuccess.type],
    erroring: [loadRolesFailure.type, batchCreateDeletePermissionsFailure.type]
  }
};

export function rolesReducer(state: RoleState = initialState, action: Action): RoleState {
  return callStateReducer(baseReducer, roleTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadRoleSuccess, (state, { role }) => roleAdapter.upsertOne(role, state)),
  on(loadRolesSuccess, (state, { roles }) => roleAdapter.setAll(roles, state)),
  on(createRoleSuccess, (state, { role }) => roleAdapter.addOne(role, state)),
  on(updateRolePermissionsSuccess, (state, { role }) => roleAdapter.updateOne({ id: role.name, changes: role }, state))
);
