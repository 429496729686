import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { localeStateReducer } from '@core/store/locale-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { initialState, loyaltyProgramAdapter, LoyaltyProgramState } from '../../types';
import {
  createLoyaltyProgram,
  createLoyaltyProgramFailure,
  createLoyaltyProgramSuccess,
  fetchLoyaltyProgram,
  fetchLoyaltyProgramFailure,
  fetchLoyaltyProgramSuccess,
  loadLoyaltyProgram,
  loadLoyaltyProgramFailure,
  loadLoyaltyPrograms,
  loadLoyaltyProgramsFailure,
  loadLoyaltyProgramsSuccess,
  loadLoyaltyProgramSuccess,
  setLoyaltyProgramBatchLocale,
  setLoyaltyProgramsFilter,
  setLoyaltyProgramSingleLocale,
  synchroniseLoyaltyPrograms,
  synchroniseLoyaltyProgramsFailure,
  synchroniseLoyaltyProgramsSuccess,
  updateLoyaltyProgram,
  updateLoyaltyProgramFailure,
  updateLoyaltyProgramSuccess
} from '../actions/loyalty-programs.actions';

export const loyaltyProgramTriggers: EntityTriggers = {
  single: {
    loading: [loadLoyaltyProgram.type, createLoyaltyProgram.type, updateLoyaltyProgram.type],
    resting: [
      loadLoyaltyProgramSuccess.type,
      createLoyaltyProgramSuccess.type,
      updateLoyaltyProgramSuccess.type,
      setLoyaltyProgramSingleLocale.type
    ],
    erroring: [loadLoyaltyProgramFailure.type, createLoyaltyProgramFailure.type, updateLoyaltyProgramFailure.type]
  },
  batch: {
    loading: [loadLoyaltyPrograms.type, synchroniseLoyaltyPrograms.type, synchroniseLoyaltyPrograms.type],
    resting: [
      loadLoyaltyProgramsSuccess.type,
      setLoyaltyProgramBatchLocale.type,
      synchroniseLoyaltyProgramsSuccess.type
    ],
    erroring: [loadLoyaltyProgramsFailure.type, synchroniseLoyaltyProgramsFailure.type]
  }
};

export const loyaltyProgramFetchListTriggers: FetchListTriggers = {
  loading: [fetchLoyaltyProgram.type],
  resting: [fetchLoyaltyProgramSuccess.type],
  erroring: [fetchLoyaltyProgramFailure.type]
};

export function loyaltyProgramsReducer(state: LoyaltyProgramState = initialState, action: Action): LoyaltyProgramState {
  return {
    ...callStateReducer(baseReducer, loyaltyProgramTriggers)(state, action),
    ...fetchStateReducer(loyaltyProgramFetchListTriggers, 'loyaltyProgram')(state, action),
    ...localeStateReducer(loyaltyProgramTriggers)(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(fetchLoyaltyProgramSuccess, (state, { loyaltyProgram }) => loyaltyProgramAdapter.upsertOne(loyaltyProgram, state)),
  on(loadLoyaltyProgramSuccess, (state, { loyaltyProgram }) => loyaltyProgramAdapter.upsertOne(loyaltyProgram, state)),
  on(loadLoyaltyProgramsSuccess, (state, { result }) => ({
    ...loyaltyProgramAdapter.setAll(result.data, state),
    count: result?.meta?.count || 0
  })),
  on(createLoyaltyProgramSuccess, (state, { loyaltyProgram }) => loyaltyProgramAdapter.addOne(loyaltyProgram, state)),
  on(updateLoyaltyProgramSuccess, (state, { loyaltyProgram }) =>
    loyaltyProgramAdapter.updateOne({ id: loyaltyProgram.id, changes: loyaltyProgram }, state)
  ),
  on(setLoyaltyProgramBatchLocale, (state, { locale }) => ({
    ...state,
    localeState: { ...state.localeState, batchLocale: locale }
  })),
  on(setLoyaltyProgramSingleLocale, (state, { locale }) => ({
    ...state,
    localeState: { ...state.localeState, singleLocale: locale }
  })),
  on(setLoyaltyProgramsFilter, (state, { filter }) => ({ ...state, filter }))
);
