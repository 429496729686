import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, ObjectUtils } from '@utils';

import { GiftCardOrdersFilter } from '../types/gift-card-orders-filter.type';
import { GiftCardOrdersResult } from '../types/gift-card-orders-result.type';
import { GiftCardOrder } from '../types/gift-card-orders.type';
import { Attempt } from '../types/order-attempts.type';
import { Webhook } from '../types/order-webhooks.type';

@Injectable({
  providedIn: 'root'
})
export class GiftCardOrdersService {
  constructor(private missionControlService: MissionControlService) {}

  getOrders(filter: GiftCardOrdersFilter): Observable<GiftCardOrdersResult> {
    filter = ObjectUtils.sanitizeRequestObject<GiftCardOrdersFilter>(filter);

    return this.missionControlService.get<GiftCardOrdersResult>(
      'gift_card_orders',
      new HttpParams({
        fromObject: {
          ...ObjectUtils.prepareQueryObject(filter),
          page: filter.page.toString(),
          limit: filter.limit.toString()
        },
        encoder: new BracketParamsEncoder()
      })
    );
  }

  getOrder(orderId: string): Observable<GiftCardOrder> {
    return this.missionControlService.get<GiftCardOrder>(`gift_card_orders/${orderId}`);
  }

  getOrderAttempts(orderId: string): Observable<Attempt[]> {
    return this.missionControlService.get<Attempt[]>(`gift_card_orders/${orderId}/attempts`);
  }

  getOrderWebhooks(orderId: string): Observable<Webhook[]> {
    return this.missionControlService.get<Webhook[]>(`gift_card_orders/${orderId}/webhooks`);
  }

  resendWebhook(orderId: string): Observable<void> {
    return this.missionControlService.post<void>(`gift_card_orders/${orderId}/resend_webhook`);
  }

  retryOrder(orderId: string): Observable<void> {
    return this.missionControlService.post<void>(`gift_card_orders/${orderId}/retry`);
  }
}
