import { Component, Inject, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';
import { Params, injectUntilDestroyed } from '@utils';

import { loadTransactions } from '../../store/actions/transactions.actions';
import { transactionsQuery } from '../../store/selectors/transactions.selectors';
import { Transaction, TransactionsFilter, TransactionsState, transactionStatusDisplayClass } from '../../types';

@UseV2Style
@Component({
  selector: 'admin-transactions',
  templateUrl: './transactions.component.html'
})
export class TransactionsComponent implements OnInit {
  loading$: Observable<boolean>;
  userId: string;
  transactions: MatTableDataSource<Transaction>;
  displayedColumns = [
    'id',
    'transaction type',
    'card last 4',
    'merchant name',
    'currency',
    'amount',
    'status',
    'posting date',
    'created at'
  ];
  transactionStatusDisplayClass = transactionStatusDisplayClass;
  filter: TransactionsFilter = this.route.snapshot.data.transactionsFilter;
  totalCount: number;

  private untilDestroyed = injectUntilDestroyed();

  constructor(
    @Inject('timezone') public timezone: string,
    private route: ActivatedRoute,
    private transactionStore: Store<TransactionsState>
  ) {}

  ngOnInit(): void {
    this.userId = Params.find(this.route, 'userId');
    this.loading$ = this.transactionStore.select(transactionsQuery.isBatchLoading);

    this.subscribeToTransactions();
  }

  onPage(pageEvent: PageEvent): void {
    this.updateFilter({
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    });
  }

  subscribeToTransactions(): void {
    combineLatest([
      this.transactionStore.select(transactionsQuery.getTransactionsByUserId(this.userId)),
      this.transactionStore.select(transactionsQuery.getMetadata)
    ])
      .pipe(this.untilDestroyed())
      .subscribe(([transactions, metadata]) => {
        this.transactions = new MatTableDataSource(transactions);
        this.totalCount = metadata?.total;
      });
  }

  updateFilter(params: object): void {
    this.filter = { ...this.filter, ...params };

    this.transactionStore.dispatch(loadTransactions({ userId: this.userId, filter: this.filter }));
  }
}
