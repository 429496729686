<div class="details-container audit-log-details-container">
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Action</h4>
      </div>
      <span class="item__value">
        {{ data.action }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Entity</h4>
      </div>
      <span class="item__value">
        {{ data.entity }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Tenant ID</h4>
      </div>
      <span class="item__value">
        {{ data.tenantId }}
      </span>
    </div>
    <div class="item request-items" *ngIf="data.request">
      <h3>Request</h3>
      <div class="item">
        <div class="item__key">
          <h4>IP address</h4>
        </div>
        <span class="item__value">
          {{ data.request.ip }}
        </span>
      </div>
      <div *ngIf="data.request.location">
        <div class="item">
          <div class="item__key">
            <h4>City</h4>
          </div>
          <span class="item__value">
            {{ data.request.location.city }}
          </span>
        </div>
        <div class="item">
          <div class="item__key">
            <h4>Country</h4>
          </div>
          <span class="item__value">
            {{ data.request.location.country }}
          </span>
        </div>
        <div class="item">
          <div class="item__key">
            <h4>Latitude</h4>
          </div>
          <span class="item__value">
            {{ data.request.location.lat }}
          </span>
        </div>
        <div class="item">
          <div class="item__key">
            <h4>Longitude</h4>
          </div>
          <span class="item__value">
            {{ data.request.location.lon }}
          </span>
        </div>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Host</h4>
        </div>
        <span class="item__value">
          {{ data.request.host }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Operating system</h4>
        </div>
        <span class="icon-text-wrapper">
          <span [innerHTML]="data.request.userAgent | userAgentInfo: 'osIcon'"></span>
          <span>{{ data.request.userAgent | userAgentInfo: 'os' }}</span>
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Browser</h4>
        </div>
        <span class="icon-text-wrapper">
          <span [innerHTML]="data.request.userAgent | userAgentInfo: 'browserIcon'"></span>
          <span>{{ data.request.userAgent | userAgentInfo: 'browser' }}</span>
        </span>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Params</h4>
      </div>
      <span class="item__value">
        <span class="json-data-block params-block">{{ data.params | json }}</span>
      </span>
    </div>
    <div class="item" *ngIf="data.request?.location">
      <google-map [center]="{ lat: data.request.location.lat, lng: data.request.location.lon }">
        <map-marker [position]="{ lat: data.request.location.lat, lng: data.request.location.lon }"> </map-marker>
      </google-map>
    </div>
  </div>
</div>
