import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SCOPES_OR } from '@core/types';
import { createResolver } from '@shared/route/resolver-helper';

import { Params } from '../../../utils/params';
import { loadIdentities } from '../store/actions/identities.actions';
import { identitiesQuery } from '../store/selectors/identities.selectors';
import { IdentityState } from '../types';

export const identitiesResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<void> => {
  const userId = Params.find(route, 'userId');

  return createResolver<IdentityState>({
    scope: SCOPES_OR.viewIdentities,
    query: identitiesQuery.isIdentitiesLoaded(userId),
    action: loadIdentities({ userId })
  });
};
