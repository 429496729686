import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { GiftCardOrdersService } from '../../services/gift-card-orders.service';
import {
  loadGiftCardOrder,
  loadGiftCardOrderFailure,
  loadGiftCardOrders,
  loadGiftCardOrdersFailure,
  loadGiftCardOrdersSuccess,
  loadGiftCardOrderSuccess,
  retryGiftCardOrder,
  retryGiftCardOrderFailure,
  retryGiftCardOrderSuccess
} from '../actions/gift-card-orders.actions';

@Injectable()
export class GiftCardOrdersEffects {
  loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGiftCardOrders),
      exhaustMap(action =>
        this.giftCardOrdersService.getOrders(action.filter).pipe(
          map(result => loadGiftCardOrdersSuccess({ result })),
          catchError(error => of(loadGiftCardOrdersFailure({ error })))
        )
      )
    )
  );

  loadOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGiftCardOrder),
      exhaustMap(action =>
        this.giftCardOrdersService.getOrder(action.orderId).pipe(
          map(order => loadGiftCardOrderSuccess({ order })),
          catchError(error => of(loadGiftCardOrderFailure({ error })))
        )
      )
    )
  );

  retryOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retryGiftCardOrder),
      exhaustMap(({ orderId }) =>
        this.giftCardOrdersService.retryOrder(orderId).pipe(
          map(() => retryGiftCardOrderSuccess({ orderId })),
          catchError(error => of(retryGiftCardOrderFailure({ error })))
        )
      )
    )
  );

  retryOrderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retryGiftCardOrderSuccess),
      map(({ orderId }) => loadGiftCardOrder({ orderId }))
    )
  );

  constructor(
    private actions$: Actions,
    private giftCardOrdersService: GiftCardOrdersService
  ) {}
}
