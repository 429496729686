import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AnalyticsService } from '../../services/analytics.service';
import { loadCustomerToken, loadCustomerTokenFailure, loadCustomerTokenSuccess } from '../actions/analytics.actions';

@Injectable()
export class AnalyticsEffects {
  loadCustomerToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerToken),
      exhaustMap(() =>
        this.analyticsService.getCustomerToken().pipe(
          map(customerToken => loadCustomerTokenSuccess({ customerToken })),
          catchError(error => of(loadCustomerTokenFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService
  ) {}
}
