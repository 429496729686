import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { UserPassword } from '../../types/user-passwords.type';

export const loadUserPasswords = createAction('[User Passwords] Load User Passwords', props<{ userId: string }>());
export const loadUserPasswordsSuccess = createAction(
  '[User Passwords] Load User Passwords Success',
  props<{ userPasswords: UserPassword[] }>()
);
export const loadUserPasswordsFailure = createAction(
  '[User Passwords] Load User Passwords Failure',
  props<ErrorState>()
);
