import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';

import { routerForceNavigate, routerNavigate } from '@core/store/router/actions/router.actions';

import { SegmentsService } from '../../services/segments.service';
import { defaultSegmentsFilter } from '../../types';
import { resetSegmentPreview } from '../actions/segment-creation.actions';
import {
  activateSegment,
  activateSegmentFailure,
  activateSegmentSuccess,
  createSegment,
  createSegmentFailure,
  createSegmentSuccess,
  deactivateSegment,
  deactivateSegmentFailure,
  deactivateSegmentSuccess,
  deleteSegment,
  deleteSegmentFailure,
  deleteSegmentSuccess,
  loadSegment,
  loadSegmentFailure,
  loadSegments,
  loadSegmentsFailure,
  loadSegmentsSuccess,
  loadSegmentSuccess,
  updateSegment,
  updateSegmentFailure,
  updateSegmentSuccess
} from '../actions/segments.actions';

@Injectable()
export class SegmentsEffects {
  loadSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSegments),
      switchMap(({ filter }) =>
        this.segmentsService.getSegments(filter).pipe(
          map(({ data, metadata }) => loadSegmentsSuccess({ segments: data, total: metadata.total || 0 })),
          catchError(error => of(loadSegmentsFailure({ error })))
        )
      )
    )
  );

  loadSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSegment),
      switchMap(({ id }) =>
        this.segmentsService.getSegment(id).pipe(
          map(segment => loadSegmentSuccess({ segment })),
          catchError(error => of(loadSegmentFailure({ error })))
        )
      )
    )
  );

  updateSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSegment),
      switchMap(({ request }) =>
        this.segmentsService.updateSegment(request).pipe(
          map(segment => updateSegmentSuccess(segment)),
          catchError(error => of(updateSegmentFailure(error)))
        )
      )
    )
  );

  activateSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateSegment),
      switchMap(({ request, origin }) =>
        this.segmentsService.updateSegment(request).pipe(
          map(segment => activateSegmentSuccess(segment, origin)),
          catchError(error => of(activateSegmentFailure(error)))
        )
      )
    )
  );

  activateSegmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateSegmentSuccess),
      switchMap(({ segment, origin }) =>
        origin === 'listing-page'
          ? merge([
              loadSegments({ filter: defaultSegmentsFilter }),
              routerNavigate({ path: '/segments?status=active' })
            ])
          : merge([loadSegment({ id: segment.id }), routerNavigate({ path: `/segments/${segment.id}` })])
      )
    )
  );

  deactivateSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deactivateSegment),
      switchMap(({ request, origin }) =>
        this.segmentsService.updateSegment(request).pipe(
          map(segment => deactivateSegmentSuccess(segment, origin)),
          catchError(error => of(deactivateSegmentFailure(error)))
        )
      )
    )
  );

  deactivateSegmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deactivateSegmentSuccess),
      switchMap(({ segment, origin }) =>
        origin === 'listing-page'
          ? merge([
              loadSegments({ filter: { ...defaultSegmentsFilter, status: 'deactivated' } }),
              routerNavigate({ path: '/segments?status=deactivated' })
            ])
          : merge([loadSegment({ id: segment.id }), routerNavigate({ path: `/segments/${segment.id}` })])
      )
    )
  );

  updateSegmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSegmentSuccess),
      map(({ segment }) => routerForceNavigate({ path: `segments/${segment.id}` }))
    )
  );

  deleteSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSegment),
      switchMap(({ id }) =>
        this.segmentsService.deleteSegment(id).pipe(
          map(() => deleteSegmentSuccess(id)),
          catchError(error => of(deleteSegmentFailure(error)))
        )
      )
    )
  );

  deleteSegmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSegmentSuccess),
      map(() => loadSegments({ filter: defaultSegmentsFilter }))
    )
  );

  createSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSegment),
      exhaustMap(({ request, recommended }) =>
        this.segmentsService.createSegment(request, recommended).pipe(
          map(segment => createSegmentSuccess(segment, recommended)),
          catchError(error => of(createSegmentFailure(error)))
        )
      )
    )
  );

  createSegmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSegmentSuccess),
      switchMap(() => {
        return [
          resetSegmentPreview(),
          loadSegments({ filter: defaultSegmentsFilter }),
          routerForceNavigate({ path: 'segments' })
        ];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private segmentsService: SegmentsService
  ) {}
}
