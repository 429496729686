import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, promoCodeAdapter, PromoCodeState } from '../../types/promo-codes.type';
import {
  createPromoCode,
  createPromoCodeFailure,
  createPromoCodeSuccess,
  deletePromoCode,
  deletePromoCodeFailure,
  deletePromoCodeSuccess,
  loadPromoCode,
  loadPromoCodeFailure,
  loadPromoCodes,
  loadPromoCodesFailure,
  loadPromoCodesSuccess,
  loadPromoCodeSuccess,
  pausePromoCode,
  pausePromoCodeFailure,
  pausePromoCodeSuccess,
  resumePromoCode,
  resumePromoCodeFailure,
  resumePromoCodeSuccess,
  updatePromoCode,
  updatePromoCodeFailure,
  updatePromoCodeSuccess,
  uploadPromoCodeImage,
  uploadPromoCodeImageFailure,
  uploadPromoCodeImageSuccess
} from '../actions/promo-codes.actions';

export const promoCodeTriggers: EntityTriggers = {
  single: {
    loading: [
      loadPromoCode.type,
      createPromoCode.type,
      updatePromoCode.type,
      pausePromoCode.type,
      resumePromoCode.type,
      deletePromoCode.type,
      uploadPromoCodeImage.type
    ],
    resting: [
      loadPromoCodeSuccess.type,
      createPromoCodeSuccess.type,
      updatePromoCodeSuccess.type,
      pausePromoCodeSuccess.type,
      resumePromoCodeSuccess.type,
      deletePromoCodeSuccess.type,
      uploadPromoCodeImageSuccess.type,
      // failure action types are added to resting state instead of erroring state
      // to prevent showing error banner when these actions fails
      createPromoCodeFailure.type,
      updatePromoCodeFailure.type,
      pausePromoCodeFailure.type,
      resumePromoCodeFailure.type,
      deletePromoCodeFailure.type,
      uploadPromoCodeImageFailure.type
    ],
    erroring: [loadPromoCodeFailure.type]
  },
  batch: {
    loading: [loadPromoCodes.type],
    resting: [loadPromoCodesSuccess.type],
    erroring: [loadPromoCodesFailure.type]
  }
};

export function promoCodesReducer(state: PromoCodeState = initialState, action: Action): PromoCodeState {
  return callStateReducer(baseReducer, promoCodeTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadPromoCodeSuccess, (state, { promoCode }) => promoCodeAdapter.upsertOne(promoCode, state)),
  on(loadPromoCodes, (state, { filter }) => ({ ...state, filter: filter ?? state.filter })),
  on(loadPromoCodesSuccess, (state, { promoCodesResult }) => ({
    ...promoCodeAdapter.setAll(promoCodesResult.data, state),
    count: promoCodesResult?.meta.count || 0
  })),
  on(createPromoCodeSuccess, (state, { promoCode }) => promoCodeAdapter.addOne(promoCode, state)),
  on(updatePromoCodeSuccess, pausePromoCodeSuccess, resumePromoCodeSuccess, (state, { promoCode }) =>
    promoCodeAdapter.updateOne({ id: promoCode.id, changes: promoCode }, state)
  ),
  on(deletePromoCodeSuccess, (state, { promoCodeId }) => ({
    ...promoCodeAdapter.removeOne(promoCodeId, state),
    count: state.count - 1
  }))
);
