import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface McTranslation {
  tenant_id: string;
  locale: string;
  key: string;
  content: string;
}

export const selectMcTranslation = (translation: McTranslation): string => `${translation.locale}_${translation.key}`;

export const mcTranslationAdapter = createEntityAdapter<McTranslation>({
  selectId: selectMcTranslation
});

export interface McTranslationState extends EntityState<McTranslation>, CallState {}

export const initialState: McTranslationState = mcTranslationAdapter.getInitialState<CallState>(initialCallState);

export interface BulkUpdateMcTranslationsPayload {
  created?: Omit<McTranslation, 'tenant_id'>[];
  updated?: Omit<McTranslation, 'tenant_id'>[];
  deleted?: Omit<McTranslation, 'content' | 'tenant_id'>[];
}
