import { AccessPolicy } from './access-policy.type';

export interface AbilityGroups {
  [key: string]: Ability[];
}

export interface Ability {
  id: string;
  roleId: string;
  accessPolicyId: string;
  priority?: number;
  accessPolicy: AccessPolicy;
  approvalRequired: boolean;
}

export interface UserAbilitiesState {
  userAbilities: AbilityGroups;
  loaded: boolean;
}

export const userAbilitiesInitialState: UserAbilitiesState = {
  userAbilities: {},
  loaded: false
};
