import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DecodedAccessToken, DecodedIdToken, Hermes, RedirectResult, Transaction } from '@kaligo/hermes';
import { Action, Store } from '@ngrx/store';

import { UserIdleDialogComponent } from '@core/containers/user-idle-dialog/user-idle-dialog.component';
import { AdminPanelErrors } from '@core/errors/admin-panel-errors';
import { loadTokenFail, loadTokenSuccess, logoutUser, sessionCheckFail } from '@core/store/auth/actions/auth.actions';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { DecodedUser } from '@core/types';

import { IdleCheckConfig } from '../../../../app-module-config';

export interface TransactionAppState {
  currentUrl?: string;
  stepUpAction?: Action;
  isAscendaLogin?: boolean;
  acr?: number;
}

@Injectable({
  providedIn: 'root'
})
export class HermesService {
  private readonly hermes: Hermes;
  private dialogRef: MatDialogRef<UserIdleDialogComponent>;

  constructor(
    private store: Store<AuthState>,
    private dialog: MatDialog,
    @Inject('connection') private connection: string,
    @Inject('guardhouseApiUrl') authBaseUrl: string,
    @Inject('idleCheckConfig') idleCheckConfig: IdleCheckConfig,
    @Inject('sessionCheckPollInterval') sessionCheckPollInterval: number,
    @Inject('redirectUri') redirectUri: string,
    @Inject('scope') scope: string,
    @Inject('appId') appId: string
  ) {
    this.hermes = new Hermes({
      authBaseUrl,
      sessionCheck: {
        pollInterval: sessionCheckPollInterval
      },
      idleCheck: {
        onTimeout: () => this.onIdleTimeout(),
        onActivityRestored: () => this.onIdleActivityRestored(),
        timeout: idleCheckConfig.timeout,
        promptTimeout: idleCheckConfig.promptTimeout,
        dialogElementId: 'user-idle-dialog'
      },
      mode: 'rtr',
      redirectUri,
      scope,
      appId
    });
  }

  async startTokenRefresh(transactionAppState: TransactionAppState): Promise<void> {
    const tokenResults = this.hermes.startRefreshTokenRotation();
    let includeTransaction = true;
    try {
      for await (const tokenResult of tokenResults) {
        if (tokenResult.idToken && tokenResult.accessToken) {
          // Only include the transactionAppState in the first loadTokenSuccess to avoid replaying the same action repeatedly
          const props = includeTransaction ? { tokenResult, transactionAppState } : { tokenResult };
          this.store.dispatch(loadTokenSuccess(props));
          includeTransaction = false;
        } else {
          throw AdminPanelErrors.TOKEN_UNDEFINED;
        }
      }
    } catch (error) {
      this.store.dispatch(loadTokenFail({ error }));
    }
  }

  stopTokenRefresh(): void {
    this.hermes.stopTokenRefresh();
  }

  async startSessionCheck(): Promise<void> {
    const sessions = this.hermes.startSessionCheck();
    try {
      for await (const session of sessions) {
        if (!session.loggedIn) {
          this.store.dispatch(logoutUser());
        }
      }
    } catch (error) {
      this.store.dispatch(sessionCheckFail({ error }));
    }
  }

  stopSessionCheck(): void {
    this.hermes.stopSessionCheck();
  }

  async handleRedirect(): Promise<TransactionAppState> {
    const result = await this.hermes.handleRedirect();
    return (result as RedirectResult)?.transaction || (result as Transaction)?.appState;
  }

  async login(connection: string = this.connection, transaction: Transaction): Promise<void> {
    await this.hermes.login(connection, transaction);
  }

  saveState(state: string, appState?: TransactionAppState): void {
    const transaction: Transaction = { nonce: state };

    if (appState) {
      transaction.appState = appState;
    }

    this.hermes.saveState(state, transaction);
  }

  parseDecodedTokens(decodedIdToken: DecodedIdToken, decodedAccessToken: DecodedAccessToken): DecodedUser {
    return {
      sub: decodedIdToken.sub,
      name: decodedIdToken.name,
      firstName: decodedIdToken.first_name,
      lastName: decodedIdToken.last_name,
      middleName: decodedIdToken.middle_name,
      nickname: decodedIdToken.nickname,
      preferredUsername: decodedIdToken.preferred_username,
      profile: decodedIdToken.profile,
      picture: decodedIdToken.picture,
      email: decodedIdToken.email,
      gender: decodedIdToken.gender,
      ...(decodedIdToken.birthdate && { birthdate: new Date(decodedIdToken.birthdate) }),
      zoneinfo: decodedIdToken.zoneinfo,
      locale: decodedIdToken.locale,
      scopes: decodedIdToken.scope.split(' '),
      tenantId: decodedIdToken.tenant_id,
      emailVerified: decodedIdToken.email_verified,
      address: decodedIdToken.address,
      phone: decodedIdToken.phone,
      phoneNumberVerified: decodedIdToken.phone_number_verified,
      country: decodedIdToken.country,
      salutation: decodedIdToken.salutation,
      custom: decodedIdToken.custom,
      status: decodedIdToken.status,
      acr: decodedIdToken.acr,
      roleIds: decodedAccessToken.roles
    };
  }

  startIdleCheck(): void {
    this.hermes.startIdleCheck();
  }

  removeRefreshToken(): void {
    this.hermes.removeRefreshToken();
  }

  private onIdleTimeout(): void {
    this.dialogRef = this.dialog.open(UserIdleDialogComponent, {
      data: {
        hermes: this.hermes
      },
      id: 'user-idle-dialog'
    });
  }

  private onIdleActivityRestored(): void {
    this.dialogRef.close();
  }
}
