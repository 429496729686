import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { sharedConfigQuery } from '@core/store/shared-config/selectors/shared-config.selectors';
import { SharedConfigState } from '@core/types';

import { CustomDomainService } from '../../services/custom-domain.service';
import {
  updateCustomDomain,
  updateCustomDomainFailure,
  updateCustomDomainSuccess
} from '../actions/custom-domain.actions';

@Injectable()
export class CustomDomainEffects {
  updateCustomDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomDomain),
      withLatestFrom(this.store.select(sharedConfigQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.customDomainsService.updateCustomDomain(action.domain, version).pipe(
          map(result => updateCustomDomainSuccess(result)),
          catchError(error => of(updateCustomDomainFailure(error)))
        )
      )
    )
  );

  updateCustomDomainSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomDomainSuccess),
      map(() => routerForceNavigate({ path: 'custom-domain' }))
    )
  );

  constructor(
    private actions$: Actions,
    private customDomainsService: CustomDomainService,
    private store: Store<SharedConfigState>
  ) {}
}
