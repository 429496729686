import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { SegmentRulesData, SegmentUpdateFrequency } from '../../segments/types';

export type RecommendationType = 'campaign' | 'segment';

interface Recommendation {
  recommendationId: string;
  setName: string;
  name: string;
  description: string;
}

export type RecommendedSegment = Recommendation & {
  type: Extract<RecommendationType, 'segment'>;
  defaultAttributes: {
    rules: SegmentRulesData;
    updateFrequency: SegmentUpdateFrequency;
  };
};

export type RecommendedCampaign = Recommendation & {
  type: Extract<RecommendationType, 'campaign'>;
  defaultAttributes: unknown; // TODO: update defaultAttributes when data shape is known
};

export function isRecommendedSegment(
  recommendation: RecommendedSegment | RecommendedCampaign
): recommendation is RecommendedSegment {
  return recommendation.type === 'segment';
}

export interface RecommendedSegmentState extends EntityState<RecommendedSegment>, CallState {}
export interface RecommendedCampaignState extends EntityState<RecommendedCampaign>, CallState {}

export const recommendedSegmentAdapter = createEntityAdapter<RecommendedSegment>({
  selectId: (recommendation: RecommendedSegment): string => recommendation.recommendationId
});
export const recommendedCampaignAdapter = createEntityAdapter<RecommendedCampaign>({
  selectId: (recommendation: RecommendedCampaign): string => recommendation.recommendationId
});

export interface RecommendationState {
  segment: RecommendedSegmentState;
  campaign: RecommendedCampaignState;
}

export const recommendedSegmentInitialState: RecommendedSegmentState =
  recommendedSegmentAdapter.getInitialState<CallState>(initialCallState);
export const recommendedCampaignInitialState: RecommendedCampaignState =
  recommendedCampaignAdapter.getInitialState<CallState>(initialCallState);

export const initialState: RecommendationState = {
  segment: recommendedSegmentInitialState,
  campaign: recommendedCampaignInitialState
};
