import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GuardRedirectionService {
  constructor(
    private router: Router,
    private location: Location
  ) {}

  redirectToPageNotFound(): boolean {
    // workaround for keeping the URL but showing the "Page Not Found" component for unauthorized access
    const originalUrl = this.router.serializeUrl(this.router.getCurrentNavigation().extractedUrl);

    this.router.navigateByUrl('/page-not-found', { skipLocationChange: true });
    this.location.replaceState(originalUrl);
    return false;
  }
}
