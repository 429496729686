import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState } from '@core/types';

import { OffersFilter } from './offers-filter.type';

export const attributesToEncode = ['terms', 'shortDescription', 'longDescription', 'howToUse'];

export interface Offer {
  id: string;
  sourceId: number;
  tenantId: string;
  brand: string;
  title: string;
  type: string;
  supplierId: string;
  categories: string[];
  shortDescription: string;
  longDescription: LongDescription[];
  terms: Term[];
  email: string;
  phone: string;
  website: string;
  logo: string;
  coverImage: string;
  gallery: string;
  startsAt: string;
  endsAt: string;
  status: string;
  scores: {
    age: {
      teens: number;
      adults: number;
      seniors: number;
      youngAdults: number;
    };
    gender: {
      male: number;
      female: number;
      nonBinary: number;
    };
    general: number;
    accountType?: {
      savings?: number;
      transaction?: number;
    };
  };
  channels: string[];
  howToUse: HowToUse[];
  createdAt: string;
  updatedAt: string;
  offerLocations?: OfferLocation[];
  createdLocations?: OfferLocation[];
  updatedLocations?: OfferLocation[];
  deletedLocations?: OfferLocation[];
  promocode: string;
  trackingUrl: string;
  offerTrackingUrls: {
    id?: string;
    offerId: string;
    baseUrl: string;
  };
  activationRequired?: boolean;
  rewardRate?: number;
  rewardValue?: number;
  rewardType?: string;
  maximumRewardPerTransaction?: number;
  maxTotalEarnings?: number;
  minimumSpend?: number;
  maxRedemptions?: MaxRedemptions;
  excludedDates?: Date[];
  validDays?: ValidDays;
}

export interface Term {
  header: string;
  body: string;
}

export interface HowToUse {
  header: string;
  body: string;
}

export interface OfferLocation {
  id?: string;
  name: string;
  address: string;
  city: string;
  country: string;
  email: string;
  phone: string;
  lonlat: string;
  active: boolean;
  offerId?: string;
}

interface Time {
  times: string[];
}

interface MaxRedemptions {
  interval: string;
  intervalNumber: number;
  usesPerInterval: number;
}

interface ValidDays {
  monday: Time;
  tuesday: Time;
  wednesday: Time;
  thursday: Time;
  friday: Time;
  saturday: Time;
  sunday: Time;
}

interface LongDescription {
  body: string;
  header: string;
}

export interface OfferResult {
  data: Offer[];
  meta: {
    total: number;
    totalPages: number;
    allPages: number[];
    currentPages: number;
  };
}

export enum OfferStatusTypeCodes {
  open = 'Active to Members',
  restricted = 'Invisible',
  public = 'Active to Public',
  unavailable = 'Unavailable',
  blocked = 'Disabled'
}

export enum OfferRedemptionInterval {
  Y = 'Year(s)',
  M = 'Month(s)',
  W = 'Week(s)',
  D = 'Day(s)'
}

export const offerAdapter = createEntityAdapter<Offer>();

export interface OfferState extends EntityState<Offer>, CallState, FetchState {
  filter: OffersFilter;
  count: number;
}

export const initialOfferState: OfferState = {
  ...offerAdapter.getInitialState<CallState>(initialCallState),
  ...offerAdapter.getInitialState<FetchState>(initialFetchState),
  filter: null,
  count: 0
};
