import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { LoyaltyProgramsResult } from '../types/loyalty-programs-result.type';
import { LoyaltyProgram, LoyaltyProgramRawResponse, LoyaltyProgramsRawResponse } from '../types/loyalty-programs.type';
import { LoyaltyProgramHelper } from '../utils/loyalty-program-helper';

@Injectable({ providedIn: 'root' })
export class LoyaltyProgramsService {
  constructor(
    private missionControlService: MissionControlService,
    private loyaltyProgramHelper: LoyaltyProgramHelper
  ) {}

  getLoyaltyPrograms(locale: string): Observable<LoyaltyProgramsResult> {
    return this.missionControlService
      .get<LoyaltyProgramsRawResponse>('loyalty_programs', null, this.missionControlService.buildLocaleHeader(locale))
      .pipe(map(loyaltyPrograms => this.loyaltyProgramHelper.mergeLoyaltyProgramsAttributes(loyaltyPrograms)));
  }

  getLoyaltyProgram(id: string, locale?: string): Observable<LoyaltyProgram> {
    return this.missionControlService
      .get<LoyaltyProgramRawResponse>(
        `loyalty_programs/${id}`,
        null,
        this.missionControlService.buildLocaleHeader(locale)
      )
      .pipe(
        map(loyaltyProgramResponse =>
          this.loyaltyProgramHelper.mergeLoyaltyProgramAttributes(
            loyaltyProgramResponse.included,
            loyaltyProgramResponse.data
          )
        )
      );
  }

  createLoyaltyProgram(loyaltyProgram: LoyaltyProgram): Observable<LoyaltyProgram> {
    loyaltyProgram = ObjectUtils.sanitizeRequestObject<LoyaltyProgram>(loyaltyProgram, {
      ignoredKeys: [
        'processingDelay',
        'name',
        'shortName',
        'description',
        'detailsUrl',
        'logoUrl',
        'enrollmentUrl',
        'conditionsUrl'
      ]
    });
    return this.missionControlService
      .post<LoyaltyProgramRawResponse>('loyalty_programs', loyaltyProgram)
      .pipe(
        map(loyaltyProgramResponse =>
          this.loyaltyProgramHelper.mergeLoyaltyProgramAttributes(
            loyaltyProgramResponse.included,
            loyaltyProgramResponse.data
          )
        )
      );
  }

  updateLoyaltyProgram(loyaltyProgram: LoyaltyProgram, locale?: string): Observable<void> {
    loyaltyProgram = ObjectUtils.sanitizeRequestObject<LoyaltyProgram>(loyaltyProgram, {
      ignoredKeys: ['processingDelay', 'detailsUrl', 'logoUrl', 'enrollmentUrl', 'conditionsUrl']
    });
    return this.missionControlService.patch<void>(
      `loyalty_programs/${loyaltyProgram.id}`,
      loyaltyProgram,
      null,
      this.missionControlService.buildLocaleHeader(locale)
    );
  }

  synchroniseLoyaltyPrograms(tenantName: string): Observable<void> {
    return this.missionControlService.put<void>('loyalty_programs_synchronisation', { createNew: true, tenantName });
  }
}
