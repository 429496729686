import { Subject } from 'rxjs/internal/Subject';
import { take } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import {
  booleanAttribute,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  numberAttribute,
  OnChanges,
  OnDestroy,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MAT_SELECT_TRIGGER, MatSelectTrigger } from '@angular/material/select';

import { DrawerRef } from '@shared/services/drawer/drawer-ref.type';
import { DrawerService } from '@shared/services/drawer/drawer.service';
import { Nullable } from '@shared/types';
import { injectUntilDestroyed } from '@utils';

/**
 * This component will serve as a select component but instead of a dropdown it will open a drawer
 * It will use `mat-option` same with `mat-select` but need to have `adminDrawerSelectOption` directive
 * so it can be used as a drawer option
 * @note This component should be used inside of `admin-drawer`
 * @example
 * <admin-drawer>
 *  <admin-drawer-select>
 *    <mat-option adminDrawerSelectOption value="1">Option 1</mat-option>
 *  </admin-drawer-select>
 * </admin-drawer>
 */
@Component({
  selector: 'admin-drawer-select',
  templateUrl: './drawer-select.component.html',
  providers: [{ provide: MatFormFieldControl, useExisting: DrawerSelectComponent }]
})
export class DrawerSelectComponent<T = any>
  implements OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<T>
{
  static nextId = 0;

  @HostBinding('class') class = 'mat-mdc-select';

  @HostBinding() id = `drawer-select-${DrawerSelectComponent.nextId++}`;

  @Input({ transform: numberAttribute }) tabIndex = 0;

  @Input() value: T | null;

  @Output() valueChange = new EventEmitter<Nullable<T>>();

  @Input() placeholder: string;

  @HostBinding('class.mat-mdc-select-disabled')
  @Input()
  disabled = false;

  @Input({ transform: booleanAttribute })
  required = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @Output() drawerClosed = new EventEmitter<void>();

  @ViewChild('drawerContent') drawerContent: TemplateRef<any>;

  @ContentChild(MAT_SELECT_TRIGGER) customTrigger: MatSelectTrigger;

  selectionModel = new SelectionModel<T>();

  selectedOption = signal<Nullable<MatOption>>(null);

  controlType = 'mat-select';

  stateChanges = new Subject<void>();

  focused = false;

  drawerRef: DrawerRef | null = null;

  ngControl = inject(NgControl, {
    optional: true,
    self: true
  });

  onChange: Nullable<(value: Nullable<T>) => void | null> = null;

  onTouched: Nullable<() => void | null> = null;

  private drawer = inject(DrawerService);

  private untilDestroyed = injectUntilDestroyed();

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Get the tabindex of the component
   */
  @HostBinding('attr.tabindex')
  get tabindex(): number | null {
    return this.disabled ? -1 : this.tabIndex;
  }

  /**
   * Check for required
   */
  @HostBinding('class.mat-mdc-select-required')
  get isRequired(): boolean {
    return this.required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  /**
   * MatFormField will use this to determine if component is empty
   */
  @HostBinding('class.mat-mdc-select-empty')
  get empty(): boolean {
    return this.selectionModel.isEmpty();
  }

  /**
   * MatFormField will use this to determine if component is in error state
   */
  @HostBinding('class.mat-mdc-select-invalid')
  get errorState(): boolean {
    return !!this.ngControl && !!this.ngControl.invalid && !!this.ngControl.touched;
  }

  /**
   * MatFormField will use this to determine if the label should float or not
   */
  get shouldLabelFloat(): boolean {
    // We will always float the label on these case
    // 1. If the placeholder is set and the component is focused
    // 2. If the component is not empty
    // 3. If the drawer is open
    return (this.focused && !!this.placeholder) || !this.empty || !!this.drawerRef;
  }

  /**
   * Handling focus event
   */
  @HostListener('focus')
  handleFocus(): void {
    // We won't allow the user to focus the component
    // if it is disabled
    if (!this.disabled) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  /**
   * Handling blur event
   */
  @HostListener('blur')
  handleBlur(): void {
    // We will only blur the component if it is not disabled
    // and the drawer is not open
    if (!this.disabled && !this.drawerRef) {
      this.focused = false;
      this.onTouched?.();
      this.stateChanges.next();
    }
  }

  /**
   * When the user press enter key, we will open the drawer
   */
  @HostListener('keydown.enter', ['$event'])
  handleEnter(): void {
    this.openDrawer();
  }

  /*** Lifecycle hooks ***/

  ngOnChanges(changes: SimpleChanges): void {
    Object.entries(changes).forEach(([key, { currentValue }]) => {
      if (key === 'value') {
        this.updateSelectionModel(currentValue);
        this.onChange?.(currentValue);
        this.stateChanges.next();
      } else if (['placeholder', 'required', 'disabled'].includes(key)) {
        this.stateChanges.next();
      }
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  /*** Event handlers ***/

  /**
   * Handle option selection, adminDrawerSelectOption directive will call this
   * function to notify the component that an option is selected
   * @param option The selected mat-option
   */
  handleSelectionChange(option: MatOption): void {
    // We will update the selected option then update the selection model
    // and notify control value
    this.selectedOption.set(option);
    this.updateSelectionModel(option.value);
    this.updateControlValue(option.value);

    // We will close the drawer after the user selected an option
    this.drawerRef?.close();
    this.drawerRef = null;

    this.stateChanges.next();
  }

  /**
   * Handle opening the drawer
   */
  openDrawer(): void {
    if (!this.drawerRef && !this.disabled) {
      this.drawerRef = this.drawer.open(this.drawerContent);

      this.drawerRef.closeStarted$.pipe(take(1), this.untilDestroyed()).subscribe(() => {
        this.drawerRef = null;
        // We will blur and mark the component as touched after the drawer is closed
        this.handleBlur();
        this.drawerClosed.emit();
      });
    }
  }

  /**
   * Handle closing the drawer.
   * Exposing this so that drawer select content can close the drawer
   * if needed without clicking on backdrop.
   */
  closeDrawer(): void {
    this.drawerRef?.close();
  }

  /*** Component specific functions ***/

  /**
   * Update the selection model so that we can get the selected option value
   * adminDrawerSelectOption directive will listen to selection model changes
   * to select/deselect the option
   * @param value selected value
   */
  updateSelectionModel(value: T | null): void {
    this.selectionModel.clear();

    // If we have a value, we will select the option
    if (value) {
      this.selectionModel.select(value);
    }
  }

  /**
   * Update the control value and emit the valueChange event
   * @param value The new value
   */
  updateControlValue(value: T | null): void {
    this.onChange?.(value);
    this.valueChange.emit(value);
  }

  /*** CVA methods ***/

  writeValue(value: T | null): void {
    this.updateSelectionModel(value);
    this.stateChanges.next();
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  /*** Form field control methods ***/

  /**
   * MatFormField will call this to set the aria describedBy
   * @param ids describedBy ids
   */
  setDescribedByIds(ids: string[]): void {
    this.userAriaDescribedBy = ids.join(' ');
  }

  /**
   * This function will be called by MatFormField
   * if the mat-form-field is clicked
   */
  onContainerClick(): void {
    this.handleFocus();
    this.openDrawer();
  }
}
