import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ArrayUtils } from '@utils';

@Component({
  selector: 'admin-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['./multi-select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiSelectInputComponent
    }
  ]
})
export class MultiSelectInputComponent implements ControlValueAccessor {
  @Input({ required: true }) options: Array<unknown>;
  @Input() prefix: string;

  values: Array<unknown> = [];

  isDisabled = false;

  onSelect(value: unknown): void {
    this.values = this.values.includes(value)
      ? this.values.filter(element => element !== value)
      : [...this.values, value];

    switch (typeof value) {
      case 'string': {
        this.values = ArrayUtils.sortByNonCaseSensitive(this.values as string[]);
        break;
      }
      case 'number': {
        this.values = this.values.sort((a, b) => (a as number) - (b as number));
        break;
      }
    }

    this.onTouched();
    this.onChange(this.values);
  }

  /* interface implementations */
  onChange(_value: Array<any>): void {}

  onTouched(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(_value: Array<any> | null): void {
    this.values = [...(_value || [])];
  }
}
