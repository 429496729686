import { FormGroup } from '@angular/forms';
import deepmerge from 'deepmerge';

import { AccessPolicySettings, BooleanPolicySettings } from '@core/types';

import { PropertiesType } from '../modules/shared/types';

export const AccessPolicyUtils = {
  isBothUsersPageInvisible: (settings: AccessPolicySettings): boolean =>
    AccessPolicyUtils.isTargetPageInvisible(settings, 'agents') &&
    AccessPolicyUtils.isTargetPageInvisible(settings, 'customers'),

  isTargetPageInvisible: (settings: AccessPolicySettings, targetPage: string): boolean =>
    !!settings?.visible && settings.visible[targetPage] === false,

  isTargetPageVisible: (settings: AccessPolicySettings, targetPage: string): boolean =>
    !AccessPolicyUtils.isTargetPageInvisible(settings, targetPage),

  mergeBooleanPolicySettings(setting1: BooleanPolicySettings, setting2: BooleanPolicySettings): BooleanPolicySettings {
    //  assuming the value will only be true for all keys, merge two settings and return
    return setting1 && setting2 ? deepmerge(setting1, setting2) : setting1 || setting2;
  },

  enableFormControlByPolicy(form: FormGroup, policyAttributes: PropertiesType): void {
    Object.keys(policyAttributes).forEach(key => {
      const control = form.get(key);
      const policyValue = policyAttributes[key];
      if (control instanceof FormGroup && policyValue instanceof Object) {
        this.enableFormControlByPolicy(control, policyValue);
      } else {
        if (policyValue) control?.enable();
        else control?.disable();
      }
    });
  }
};
