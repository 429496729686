import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'admin-input-reset',
  templateUrl: './input-reset.component.html',
  styleUrls: ['./input-reset.component.scss']
})
export class InputResetComponent {
  @Input() inputControl: AbstractControl;
  @Input() loading: boolean;
  @Input() initialValue?: any;
}
