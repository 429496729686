import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { UploadImageState } from '../../types';

const getUploadImageState = createFeatureSelector<UploadImageState>('uploadImage');

const { isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<UploadImageState>(getUploadImageState);

const getImageByComponentId = (componentId: string): Selector<UploadImageState, string> =>
  createSelector(getUploadImageState, state => state.images[componentId]);

export const uploadImagesQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  getImageByComponentId
};
