<div class="main-container details-main-container">
  <ng-container [ngSwitch]="renderState$ | async">
    <admin-spinner *ngSwitchCase="'loading'"></admin-spinner>

    <admin-empty-state-page *ngSwitchCase="'error'" entity="Order"></admin-empty-state-page>

    <ng-container *ngSwitchCase="'content'">
      <div *ngIf="orderItem$ | async as orderItem">
        <ng-template #OrderItemIdHeader>
          <div class="page-title-wrapper">
            <h1>
              Order: {{ orderItem.description }}
              {{ orderItem.type === orderItemType.SustainabilityOrderItem ? ' carbon offset' : '' }}
            </h1>
            <button
              mat-flat-button
              disabled
              [ngClass]="orderItemStatusDisplayClass[orderItem?.status] || 'status-neutral'"
            >
              <span class="dot"></span
              ><span class="text">{{ orderItem?.status | customTitleCase: 'fromSnakeCase' }}</span>
            </button>
          </div>
          <div class="page-title-datetime">
            <mat-icon svgIcon="calendar-add-event"></mat-icon>
            <span class="page-title-datetime__text"
              >Created at {{ orderItem.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }}</span
            >
            <mat-icon svgIcon="edit"></mat-icon>
            <span class="page-title-datetime__text"
              >Updated at {{ orderItem.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' }}</span
            >
          </div>
        </ng-template>

        <div *ngIf="!isUserView && isSupportedType$ | async">
          <ng-template [ngTemplateOutlet]="OrderItemIdHeader"></ng-template>
        </div>

        <div class="relative">
          @switch (orderItem.type) {
            @case (orderItemType.GiftCardOrderItem) {
              <admin-gift-card-order-item-v2
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
                [timezone]="timezone"
              >
              </admin-gift-card-order-item-v2>
            }
            @case (orderItemType.PhysicalGiftCardOrderItem) {
              <admin-gift-card-order-item-v2
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
                [timezone]="timezone"
              >
              </admin-gift-card-order-item-v2>
            }
            @case (orderItemType.PointsTransferOrderItem) {
              <admin-points-transfer-order-item-v2
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
              >
              </admin-points-transfer-order-item-v2>
            }
            @case (orderItemType.CashRedemptionOrderItem) {
              <admin-cash-redemption-order-item-v2
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
              >
              </admin-cash-redemption-order-item-v2>
            }
            @case (orderItemType.TravelBookingOrderItem) {
              <admin-travel-booking-order-item-v2
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
              >
              </admin-travel-booking-order-item-v2>
            }
            @case (orderItemType.MerchandiseOrderItem) {
              <admin-merchandise-order-item-v2
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
                [timezone]="timezone"
              >
              </admin-merchandise-order-item-v2>
            }
            @case (orderItemType.BenefitOrderItem) {
              <admin-benefit-order-item-v2
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
              >
              </admin-benefit-order-item-v2>
            }
            @case (orderItemType.CryptoOrderItem) {
              <admin-crypto-order-item
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
              >
              </admin-crypto-order-item>
            }
            @case (orderItemType.SustainabilityOrderItem) {
              <admin-sustainability-order-item
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
              >
              </admin-sustainability-order-item>
            }
            @case (orderItemType.VoucherOrderItem) {
              <admin-voucher-order-item
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
              >
              </admin-voucher-order-item>
            }
            @case (orderItemType.PointOfSaleOrderItem) {
              <admin-point-of-sale-order-item
                [detailsView]="detailsView"
                [customerId]="customerId$ | async"
                [orderItem]="orderItem"
                [stripeReferenceData]="stripeReferenceData$ | async"
              >
              </admin-point-of-sale-order-item>
            }
            @default {
              <admin-empty-state-page entity="Order type" messageType="unsupported"></admin-empty-state-page>
            }
          }
          <admin-copy-to-clipboard
            *ngIf="isSupportedType$ | async"
            [content]="getOrderItemsDetailsToCopy() | async"
            contentName="Order details"
            buttonLabel="Copy details"
            class="absolute right-0 top-[-7px]"
          ></admin-copy-to-clipboard>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
