import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { BenefitsService } from '../../services/benefits.service';
import { MembershipState } from '../../types/memberships.type';
import { loadBenefits, loadBenefitsFailure, loadBenefitsSuccess } from '../actions/benefits.actions';
import { membershipsQuery } from '../selectors/memberships.selectors';

@Injectable()
export class BenefitsEffects {
  loadBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBenefits),
      withLatestFrom(this.membershipStore.select(membershipsQuery.getLocale)),
      exhaustMap(([{ userId }, locale]) =>
        this.benefitsService.getBenefits(userId, locale).pipe(
          map(result => loadBenefitsSuccess({ result })),
          catchError(error => of(loadBenefitsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private benefitsService: BenefitsService,
    private membershipStore: Store<MembershipState>
  ) {}
}
