import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { MerchandiseCategory, MerchandiseCategoryResult } from '../types/merchandise-categories.type';

@Injectable({
  providedIn: 'root'
})
export class MerchandiseCategoriesService {
  constructor(private missionControlService: MissionControlService) {}

  getMerchandiseCategories(): Observable<MerchandiseCategoryResult> {
    return this.missionControlService.get<MerchandiseCategoryResult>('merchandises_categories');
  }

  getMerchandiseCategory(id: number): Observable<MerchandiseCategory> {
    return this.missionControlService.get<MerchandiseCategory>(`merchandises_categories/${id}`);
  }
}
