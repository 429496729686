import { ObjectUtils } from '@utils';

export const SetUtils = {
  getIntersection(set1: Set<string>, set2: Set<string>): Set<string> {
    const result: Set<string> = new Set();
    set1.forEach(item => {
      if (set2.has(item)) {
        result.add(item);
      }
    });
    return result;
  },

  getDifference(set1: Set<string>, set2: Set<string>): Set<string> {
    const result: Set<string> = new Set();
    set1.forEach(item => {
      if (!set2.has(item)) {
        result.add(item);
      }
    });
    return result;
  },

  findDuplicateValueSet(values: string[]): Set<string> {
    const tempSet = new Set();
    const duplicateValues: Set<string> = new Set();

    if (ObjectUtils.isNullish(values) || !Array.isArray(values)) {
      return duplicateValues;
    }

    values.forEach(val => {
      if (tempSet.has(val)) {
        duplicateValues.add(val);
      } else {
        tempSet.add(val);
      }
    });

    return duplicateValues;
  }
};
