import { Pipe, PipeTransform } from '@angular/core';

import { IconUtils } from '@utils';

import { AuditLog } from '../../audit-logs/types/audit-logs.type';
import { Event } from '../../events/types/events.type';

export type DeviceInfoSource = Event | AuditLog;
export type DeviceInfoType = 'userAgent' | 'iconsAndFlag' | 'exists?' | 'isShortInfo';

@Pipe({ name: 'deviceInfo' })
export class DeviceInfoPipe implements PipeTransform {
  convertFns: { [key in DeviceInfoType]: (userAgent: string, countryCode?: string) => string | boolean } = {
    userAgent: this.getUserAgentInfo,
    iconsAndFlag: this.getIconsAndFlagInfo,
    'exists?': this.hasDeviceInfo,
    isShortInfo: this.isShortInfo
  };

  static getUserAgent(deviceInfoSource: DeviceInfoSource): string {
    return 'app' in deviceInfoSource && deviceInfoSource.app === 'guardhouse'
      ? deviceInfoSource.deviceInfo?.userAgent! // event
      : deviceInfoSource.request?.userAgent!; // event/log
  }

  static getCountryCode(deviceInfoSource: DeviceInfoSource): string {
    return 'location' in deviceInfoSource
      ? deviceInfoSource.location?.countryCode
      : deviceInfoSource.request?.location?.countryCode;
  }

  transform(deviceInfoSource: DeviceInfoSource, infoType: DeviceInfoType): string | boolean {
    if (!deviceInfoSource) {
      return '';
    }

    const userAgent = DeviceInfoPipe.getUserAgent(deviceInfoSource);
    const countryCode = DeviceInfoPipe.getCountryCode(deviceInfoSource);

    const convertFn = this.convertFns[infoType];
    return convertFn(userAgent, countryCode);
  }

  getUserAgentInfo(userAgent: string): string {
    return userAgent ?? 'No Additional Info';
  }

  getIconsAndFlagInfo(userAgent: string, countryCode?: string): string {
    return IconUtils.getIconsAndFlag(countryCode!, userAgent);
  }

  hasDeviceInfo(userAgent: string, countryCode?: string): boolean {
    return IconUtils.hasDeviceInfo(countryCode!, userAgent);
  }

  isShortInfo(userAgent: string): boolean {
    return !userAgent || userAgent.length < 50;
  }
}
