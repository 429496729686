import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { ConfigurationChangesService } from '../../services/configuration-changes.service';
import {
  loadConfigurationChanges,
  loadConfigurationChangesFailure,
  loadConfigurationChangesSuccess
} from '../actions/configuration-changes.actions';

@Injectable()
export class ConfigurationChangesEffects {
  loadConfigurationChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConfigurationChanges),
      exhaustMap(({ params }) =>
        this.service.getConfigurationChanges(params).pipe(
          map(result => loadConfigurationChangesSuccess({ configurationChangesResult: result })),
          catchError(error => of(loadConfigurationChangesFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: ConfigurationChangesService
  ) {}
}
