import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, membershipAdapter, MembershipState } from '../../types/memberships.type';
import {
  deleteMembershipSuccess,
  loadMemberships,
  loadMembershipsFailure,
  loadMembershipsSuccess,
  setMembershipsLocale,
  updateMembershipSuccess
} from '../actions/memberships.actions';

export const membershipTriggers: EntityTriggers = {
  batch: {
    loading: [loadMemberships.type],
    resting: [loadMembershipsSuccess.type],
    erroring: [loadMembershipsFailure.type]
  }
};

export function membershipsReducer(state: MembershipState = initialState, action: Action): MembershipState {
  return callStateReducer(baseReducer, membershipTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadMembershipsSuccess, (state, { result }) => ({
    ...membershipAdapter.setAll(result.data, state),
    count: result.meta.count
  })),
  on(updateMembershipSuccess, (state, { result }) => membershipAdapter.setOne(result, state)),
  on(deleteMembershipSuccess, (state, { membershipId }) => ({
    ...membershipAdapter.removeOne(membershipId, state),
    count: state.count - 1
  })),
  on(setMembershipsLocale, (state, { locale }) => ({ ...state, locale }))
);
