<h3
  [innerHTML]="
    'Transfer Points: ' +
    data.programShortName +
    ', ' +
    orderItem.pointsAmount.toLocaleString() +
    ' ' +
    data.tenantCurrencyName
  "
></h3>
<div class="details-container padding-y-20">
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Status</h4>
      </div>
      <span class="item__value">{{ orderItem.status | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Amount transferred</h4>
      </div>
      <span
        class="item__value"
        [innerHTML]="orderItem.pointsPaid.toLocaleString() + ' ' + data.tenantCurrencyName"
      ></span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Amount received</h4>
      </div>
      <span class="item__value">{{ data.transferAmount.toLocaleString() }} {{ data.loyaltyCurrencyName }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Original amount transferred</h4>
      </div>
      <span
        class="item__value"
        [innerHTML]="orderItem.pointsAmount.toLocaleString() + ' ' + data.tenantCurrencyName"
      ></span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Promo code</h4>
      </div>
      <span class="item__value">{{ data.promoCode || 'N/A' }}</span>
    </div>
  </div>

  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Customer ID</h4>
      </div>
      <span class="item__value">{{ customerId || 'N/A' }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Order initiator</h4>
      </div>
      <span class="item__value">{{ (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A' }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Created at</h4>
      </div>
      <span
        *ngIf="orderItem.createdAt"
        class="item__value"
        attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
      >
        {{ orderItem.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Updated at</h4>
      </div>
      <span
        *ngIf="orderItem.updatedAt"
        class="item__value"
        attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
      >
        {{ orderItem.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
      </span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Autosweep?</h4>
      </div>
      <span class="item__value">{{ data.autosweep ? 'Yes' : 'No' }}</span>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

<!--   second section   -->
<h3 class="title">Membership details</h3>
<div class="details-container padding-y-20">
  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>First name</h4>
      </div>
      <span class="item__value">{{ data.membershipInformation.firstName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Last name</h4>
      </div>
      <span class="item__value">{{ data.membershipInformation.lastName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Membership no.</h4>
      </div>
      <span class="item__value">{{ data.membershipInformation.number }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Program name</h4>
      </div>
      <span class="item__value">{{ data.productName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Program short name</h4>
      </div>
      <span class="item__value">{{ data.programShortName | titlecase }}</span>
    </div>
  </div>

  <div class="column">
    <div class="item">
      <div class="item__key">
        <h4>Program ID</h4>
      </div>
      <span class="item__value">{{ data.loyaltyProgramId }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Loyalty currency name</h4>
      </div>
      <span class="item__value">{{ data.loyaltyCurrencyName | titlecase }}</span>
    </div>
    <div class="item">
      <div class="item__key">
        <h4>Loyalty currency short name</h4>
      </div>
      <span class="item__value">{{ data.loyaltyCurrencyShortName | titlecase }}</span>
    </div>
  </div>
</div>
