import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { OfferSourceResult, OfferSourcesFilter } from '../types';

@Injectable({ providedIn: 'root' })
export class OfferSourcesService {
  constructor(private missionControlService: MissionControlService) {}

  getOfferSources(filter: OfferSourcesFilter): Observable<OfferSourceResult> {
    filter = ObjectUtils.sanitizeRequestObject<OfferSourcesFilter>(filter);
    return this.missionControlService.get<OfferSourceResult>(
      'offer_sources',
      new HttpParams({ fromObject: { ...ObjectUtils.prepareQueryObject(filter) } })
    );
  }

  uploadOfferSources(file: FormData): Observable<void> {
    return this.missionControlService.post<void>('offer_sources/upload', file);
  }
}
