import { createFeatureSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { userPasswordAdapter, UserPasswordState } from '../../types/user-passwords.type';

const getUserPasswordState = createFeatureSelector<UserPasswordState>('userPassword');

const { selectAll: getUserPasswordsList, selectEntities: getUserPasswordsDictionary } =
  userPasswordAdapter.getSelectors(getUserPasswordState);

const { isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<UserPasswordState>(getUserPasswordState);

export const userPasswordsQuery = {
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getUserPasswordsList,
  getUserPasswordsDictionary
};
