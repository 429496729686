import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { RadioTileOption } from '@shared/types/radio-tile.type';
import { injectUntilDestroyed } from '@utils';

@Component({
  selector: 'admin-radio-tile-group',
  templateUrl: './radio-tile-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioTileGroupComponent),
      multi: true
    }
  ]
})
export class RadioTileGroupComponent implements ControlValueAccessor {
  @Input({ required: true }) options: RadioTileOption[];

  formControl = new FormControl();

  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  get isDisabled(): boolean {
    return this.formControl.disabled;
  }

  private untilDestroyed = injectUntilDestroyed();

  constructor() {
    this.formControl.valueChanges.pipe(this.untilDestroyed()).subscribe(value => {
      this.onChange(value);
    });
  }

  writeValue(value: string): void {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
}
