import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { TenantBootstrapService } from '@core/services/tenant-bootstrap/tenant-bootstrap.service';

import {
  loadTenantBootstrap,
  loadTenantBootstrapFailure,
  loadTenantBootstrapSuccess
} from '../actions/tenant-bootstrap.actions';

@Injectable()
export class TenantBootstrapEffects {
  loadTenantBootstrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenantBootstrap),
      exhaustMap(() =>
        this.tenantBootstrapService.getTenantBootstrap().pipe(
          map(tenantBootstrap => loadTenantBootstrapSuccess({ tenantBootstrap })),
          catchError(error => of(loadTenantBootstrapFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private tenantBootstrapService: TenantBootstrapService
  ) {}
}
