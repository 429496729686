import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { loadNydusNetworkBootstrap } from '@core/store/nydus-network/actions/nydus-network-bootstrap.actions';
import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { loadSharedConfig } from '@core/store/shared-config/actions/shared-config.actions';
import { sharedConfigQuery } from '@core/store/shared-config/selectors/shared-config.selectors';
import { SharedConfigState } from '@core/types';

import { loadTenantLocales } from '../../../tenant-locales/store/actions/tenant-locales.actions';
import { RewardsProgramService } from '../../services/rewards-program.service';
import {
  updateRewardsProgram,
  updateRewardsProgramFailure,
  updateRewardsProgramSuccess
} from '../actions/rewards-program.actions';

@Injectable()
export class RewardsProgramEffects {
  updateRewardsProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRewardsProgram),
      withLatestFrom(this.store.select(sharedConfigQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.rewardsProgramService.updateRewardsProgram(action.params, version).pipe(
          map(rewardsProgram => updateRewardsProgramSuccess(rewardsProgram)),
          catchError(error => of(updateRewardsProgramFailure(error)))
        )
      )
    )
  );

  updateRewardsProgramSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRewardsProgramSuccess),
      exhaustMap(() => {
        return [
          loadNydusNetworkBootstrap(),
          loadSharedConfig(),
          loadTenantLocales(),
          routerForceNavigate({ path: 'rewards-program' })
        ];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private rewardsProgramService: RewardsProgramService,
    private store: Store<SharedConfigState>
  ) {}
}
