import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { NydusNetworkBootstrapService } from '@core/services/nydus-network/nydus-network-bootstrap.service';

import {
  loadNydusNetworkBootstrap,
  loadNydusNetworkBootstrapFailure,
  loadNydusNetworkBootstrapSuccess
} from '../actions/nydus-network-bootstrap.actions';

@Injectable()
export class NydusNetworkBootstrapEffects {
  loadNydusNetworkBootstrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNydusNetworkBootstrap),
      exhaustMap(() =>
        this.nydusNetworkBootstrapService.getBootstrap().pipe(
          map(nydusNetworkBootstrap => loadNydusNetworkBootstrapSuccess({ nydusNetworkBootstrap })),
          catchError(error => of(loadNydusNetworkBootstrapFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private nydusNetworkBootstrapService: NydusNetworkBootstrapService
  ) {}
}
