import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';

import { RecommendedSegment, RecommendedCampaign, isRecommendedSegment } from '../../../recommendations/types';
import { SegmentCreationService } from '../../../segments/services/segment-creation.service';

@Component({
  selector: 'admin-recommendation-review-card',
  templateUrl: './recommendation-review-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationReviewCardComponent {
  @Input({ required: true }) recommendation: RecommendedSegment | RecommendedCampaign;

  canManageSegments: boolean = this.scopes.has('manage_segments');

  constructor(
    private scopes: Scopes,
    private segmentCreationService: SegmentCreationService
  ) {}

  setUpRecommendedSegment(): void {
    if (isRecommendedSegment(this.recommendation)) {
      const { name, description, defaultAttributes } = this.recommendation;

      this.segmentCreationService.createSegment(
        {
          name,
          description,
          updateFrequency: defaultAttributes.updateFrequency,
          rules: defaultAttributes.rules
        },
        true
      );
    }
  }
}
