export function isString(obj) {
    return obj && typeof obj === 'string';
}
export function isBoolean(obj) {
    return typeof obj === 'boolean';
}
export function isNullish(obj) {
    return obj === null || obj === undefined;
}
export function isVisible(el) {
    return el && el.offsetWidth > 0 && el.offsetHeight > 0;
}
// Broofa's RFC4122 compliant UUID generator function
// https://stackoverflow.com/a/2117523/2506617
export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line one-var
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
