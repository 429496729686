import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { FraudRuleSchemasService } from '../../services/fraud-rule-schemas.service';
import { FraudRuleSchema } from '../../types/fraud-rule-schemas.type';
import {
  loadFraudRuleSchemas,
  loadFraudRuleSchemasFailure,
  loadFraudRuleSchemasSuccess
} from '../actions/fraud-rule-schemas.actions';

@Injectable()
export class FraudRuleSchemasEffects {
  loadFraudRuleSchemas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFraudRuleSchemas),
      exhaustMap(() =>
        this.fraudRuleSchemasService.getFraudRuleSchemas().pipe(
          map((result: FraudRuleSchema[]) => loadFraudRuleSchemasSuccess({ fraudRuleSchemas: result })),
          catchError(error => of(loadFraudRuleSchemasFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private fraudRuleSchemasService: FraudRuleSchemasService
  ) {}
}
