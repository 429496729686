import { FormControl } from '@angular/forms';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { AlfredMetadata } from '@shared/types';

interface GenericOffer {
  brand: string;
  title: string;
  type: string;
  supplierId: string;
}

export interface UserOfferActivitiesForm {
  title: FormControl<string>;
  brand: FormControl<string>;
  activity: FormControl<string>;
}

export interface UserOfferActivity {
  id: string;
  action: string;
  userId: string;
  genericOfferId: string;
  metadata: {
    affiliateLink?: string;
  };
  eventDate: string;
  genericOffer: GenericOffer;
}

export class UserOfferActivitiesFilter {
  brand?: string;
  title?: string;
  activity?: string;
  page?: number;
  limit?: number;

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}

export interface UserOfferActivitiesResult {
  data: UserOfferActivity[];
  metadata: AlfredMetadata;
}
export interface UserOfferActivityState extends EntityState<UserOfferActivity>, CallState {
  userId: string;
  metadata: AlfredMetadata;
  filter?: UserOfferActivitiesFilter;
}

export const userOfferActivityAdapter = createEntityAdapter<UserOfferActivity>();

export const initialUserOfferActivityState: UserOfferActivityState = {
  ...userOfferActivityAdapter.getInitialState<CallState>(initialCallState),
  userId: null,
  metadata: new AlfredMetadata(),
  filter: null
};
