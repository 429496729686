import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { AlfredParamConverter, AlfredParamsConfig, BracketParamsEncoder, ObjectUtils } from '@utils';

import { MerchandisesFilter } from '../types/merchandises-filter.type';
import { Merchandise, MerchandisesResult, UpdateMerchandiseParams } from '../types/merchandises.type';

@Injectable({
  providedIn: 'root'
})
export class MerchandisesService {
  constructor(private missionControlService: MissionControlService) {}

  getMerchandise(id: string): Observable<Merchandise> {
    return this.missionControlService.get<Merchandise>(`merchandises/${id}`);
  }

  getMerchandises(filter: MerchandisesFilter): Observable<MerchandisesResult> {
    const paramsConfig: AlfredParamsConfig = {
      name: '$like',
      brand: '$like',
      categories: '$inc'
    };

    let alfredParam = AlfredParamConverter.convertToAlfredParam(filter, paramsConfig);
    alfredParam = ObjectUtils.sanitizeRequestObject(alfredParam);

    return this.missionControlService.get<MerchandisesResult>(
      'merchandises',
      new HttpParams({
        fromObject: {
          ...ObjectUtils.prepareQueryObject(alfredParam)
        },
        encoder: new BracketParamsEncoder()
      })
    );
  }

  updateMerchandise(params: UpdateMerchandiseParams): Observable<Merchandise> {
    params = ObjectUtils.encodeAttributesAsBase64(params, ['description']);
    return this.missionControlService.patch<Merchandise>(`merchandises/${params.id}`, params);
  }
}
