import { createSelector } from '@ngrx/store';

import { CoreState, getCoreState } from '@core/store';
import { getCallStateSelectors } from '@core/store/call-state';
import { NydusNetworkBootstrap, NydusNetworkBootstrapState } from '@core/types';

const getNydusNetworkBootstrapState = createSelector(getCoreState, (state: CoreState) => state.nydusNetworkBootstrap);

const { isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<NydusNetworkBootstrapState>(getNydusNetworkBootstrapState);

const getNydusNetworkBootstrap = createSelector(
  getNydusNetworkBootstrapState,
  (state: NydusNetworkBootstrapState) => state.nydusNetworkBootstrap
);

const getNydusNetworkBootstrapCustomerSegments = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.customerSegments
);

const getNydusNetworkBootstrapDefaultLocale = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.defaultLocale || bootstrap?.locales[0].code
);

const getNydusNetworkBootstrapLocales = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.locales
);

const getNydusNetworkBootstrapLoyaltyCurrency = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.loyaltyCurrencies[0] // assume that there is only 1 loyaltyCurrency per tenant
);

const getNydusNetworkBootstrapRedemptionRates = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.redemptionRates
);

const getNydusNetworkBootstrapRedemptionTypes = createSelector(
  getNydusNetworkBootstrapState,
  (state: NydusNetworkBootstrapState) => state.redemptionTypes
);

const getNydusNetworkBootstrapWebhookEventNames = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.outgoingRequestEventNames
);

const isNydusNetworkBootstrapLoaded = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => !!bootstrap
);

const getLoyaltyProgramTranslations = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.loyaltyPrograms[0].translationsV2
);

const getLoyaltyCurrenciesTranslations = createSelector(
  getNydusNetworkBootstrap,
  (bootstrap: NydusNetworkBootstrap) => bootstrap?.loyaltyCurrencies[0].translations
);

const getCurrencies = createSelector(getNydusNetworkBootstrap, (bootstrap: NydusNetworkBootstrap) =>
  bootstrap?.currencies ? [...bootstrap.currencies].sort((a, b) => a.code.localeCompare(b.code)) : []
);

export const nydusNetworkBootstrapQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  getNydusNetworkBootstrap,
  getNydusNetworkBootstrapCustomerSegments,
  getNydusNetworkBootstrapDefaultLocale,
  getNydusNetworkBootstrapLocales,
  getNydusNetworkBootstrapLoyaltyCurrency,
  getNydusNetworkBootstrapRedemptionRates,
  getNydusNetworkBootstrapRedemptionTypes,
  getNydusNetworkBootstrapWebhookEventNames,
  isNydusNetworkBootstrapLoaded,
  getLoyaltyProgramTranslations,
  getLoyaltyCurrenciesTranslations,
  getCurrencies
};
