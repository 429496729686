export class EarnRulesFilter {
  description?: string;
  groupId?: string;
  effectiveStart?: {
    dateFrom: string;
    dateTo: string;
  };
  effectiveEnd?: {
    dateFrom: string;
    dateTo: string;
  };
  page?: number;
  limit?: number;
  status: string;

  constructor() {
    this.page = 1;
    this.limit = 50;
    this.status = 'active';
  }
}
