import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { usersQuery } from '../../../users/store/selectors/users.selectors';
import { getUserRoute, isAdmin, User, UserState } from '../../../users/types';
import { TagDef, TagDefs } from '../../types/tag-defs.type';
import { FetchUserService } from '../fetch/fetch-user.service';

export const UserTagDefsKeys = ['agent_id', 'user_id', 'approver_id', 'requester_id'];

@Injectable({ providedIn: 'root' })
export class UserTagDefs {
  userTagDef: TagDef<User> = {
    fetchData: (ids: string[]) => this.fetchUserService.fetchUsers(ids),
    select: id => this.userStore.pipe(select(usersQuery.getUserById(id))),
    fetch: id => this.fetchUserService.fetch(id),
    getDisplayPrefix: (user: User) => (user ? (isAdmin(user) ? 'Agent' : 'Customer') : 'User'),
    getDisplayValue: ({ id, firstName, lastName }) =>
      firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName || id,
    getRouteLink: (user: User) => getUserRoute(user)
  };

  tagDefs: TagDefs<User> = UserTagDefsKeys.reduce(
    (acc, key) => Object.assign({ ...acc, [key]: { ...this.userTagDef } }),
    {}
  );

  constructor(
    private userStore: Store<UserState>,
    private fetchUserService: FetchUserService
  ) {}
}
