import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { ApprovalRequest } from '../../../approval-requests/types/approval-requests.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class ApprovalRequestTagDefs {
  readonly tagDefs: TagDefs<ApprovalRequest> = {
    approval_request_id: {
      getDisplayPrefix: () => 'Approval Request',
      getDisplayValue: (tag: Tag) => tag.id,
      getRouteLink: (tags: Tag[]) => {
        if (!this.scopes.hasAny(SCOPES_OR.showApprovalRequests)) {
          return null;
        }

        const approvalRequestTag = tags.find(tag => tag.type === 'approval_request_id');
        return approvalRequestTag ? `/approval-requests/${approvalRequestTag.id}` : null;
      }
    }
  };

  constructor(private scopes: Scopes) {}
}
