import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { ObjectUtils } from '../../../utils/object-utils';
import {
  CampaignProposal,
  CreateCampaignProposalParams,
  MerchantPortalBootstrap
} from '../types/campaign-proposals.type';

@Injectable({
  providedIn: 'root'
})
export class CampaignProposalsService {
  constructor(private missionControlService: MissionControlService) {}

  getMerchantPortalBootstrap(): Observable<MerchantPortalBootstrap> {
    return this.missionControlService.get<MerchantPortalBootstrap>('merchant_portal/bootstrap');
  }

  getCampaignProposals(): Observable<CampaignProposal[]> {
    return this.missionControlService.get<CampaignProposal[]>('campaign_proposals');
  }

  getCampaignProposal(id: string): Observable<CampaignProposal> {
    return this.missionControlService.get<CampaignProposal>(`campaign_proposals/${id}`);
  }

  createCampaignProposal(campaignProposal: CreateCampaignProposalParams): Observable<CampaignProposal> {
    campaignProposal = ObjectUtils.sanitizeRequestObject(campaignProposal);

    return this.missionControlService.post<CampaignProposal>('campaign_proposals', campaignProposal);
  }

  updateCampaignProposal(campaignProposal: CampaignProposal): Observable<CampaignProposal> {
    return this.missionControlService.patch<CampaignProposal>(
      `campaign_proposals/${campaignProposal.id}`,
      campaignProposal
    );
  }
}
