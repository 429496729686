import { createSelector } from '@ngrx/store';

import { CoreState, getCoreState } from '@core/store';

import { InterruptedState } from '../reducers/interrupted.reducer';

const getInterruptedState = createSelector(getCoreState, (state: CoreState) => state.interrupted);

const getApprovalRequestedAction = createSelector(
  getInterruptedState,
  (state: InterruptedState) => state.approvalRequestedAction
);
const getStepUpAction = createSelector(getInterruptedState, (state: InterruptedState) => state.stepUpAction);

export const interruptedQuery = {
  getApprovalRequestedAction,
  getStepUpAction
};
