import { Component, DestroyRef, inject, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, debounceTime, filter, map, Observable, startWith, switchMap } from 'rxjs';

import { Change } from '@core/types';
import { ChangesDetailDialogComponent } from '@shared/components/changes-detail-dialog/changes-detail-dialog.component';
import { TagsService } from '@shared/services/tags.service';
import { Tag } from '@shared/types';
import { DateForm } from '@shared/types/common-form.type';
import { convertTagsToTagIdsRecord } from '@tag-utils';
import { DayjsUtils, or } from '@utils';

import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS, MAT_DAYJS_DATE_FORMATS } from '../../../../adaptors';
import { loadAuditLogs } from '../../store/actions/audit-logs.actions';
import { AuditLogsQuery } from '../../store/selectors/audit-logs.selectors';
import { AuditLog, AuditLogEntity, AuditLogForm, AuditLogsFilter, AuditLogState } from '../../types';

type QuickSearchTag = {
  label: string;
  entity: string;
  action: string;
};

@Component({
  selector: 'admin-audit-logs-content-v2',
  templateUrl: './audit-logs-content-v2.component.html',
  styleUrls: ['./audit-logs-content-v2.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } },
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class AuditLogsContentV2Component implements OnInit {
  @Input() detailsRouteOnCurrentPath: boolean;

  @ViewChild(MatSort) sort: MatSort;

  destroyRef = inject(DestroyRef);

  logs: MatTableDataSource<AuditLog>;
  loading$: Observable<boolean>;
  count$: Observable<number>;
  filteredActions$: Observable<string[]>;
  displayedColumns = ['date', 'device info', 'entity', 'message', 'tags', 'details'];
  displayedActions = ['index', 'show', 'create', 'update', 'destroy'];
  quickSearchTags: QuickSearchTag[] = [
    { label: 'Impersonations', entity: 'users', action: 'impersonate' },
    { label: 'Username changes', entity: 'users', action: 'update_username' },
    { label: 'Unenrollments', entity: 'users', action: 'rollback' },
    { label: 'Admin creations', entity: 'users', action: 'create_admin' },
    { label: 'Cash redemptions', entity: 'users', action: 'cash_redemptions' },
    { label: 'Points transfers', entity: 'users', action: 'points_transfers' },
    // TODO: Update entity to `partner_config` after UI settings migration is done
    { label: 'Partner configuration updates', entity: 'partner_configuration', action: 'update' }
  ];

  filter: AuditLogsFilter;
  filterForm: FormGroup<AuditLogForm>;
  filterEntities: AuditLogEntity[];
  defaultTags: Tag[];
  defaultEntity: string;
  isTopLevelView: boolean;

  constructor(
    private store: Store<AuditLogState>,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private tagsService: TagsService,
    private auditLogsQuery: AuditLogsQuery,
    @Inject('timezone') public timezone: string,
    @Inject('timezoneOffset') readonly timezoneOffset: number
  ) {}

  get dateRangeControl(): FormGroup<DateForm> {
    return this.filterForm.controls.dateRange;
  }

  get dateFrom(): string {
    return this.dateRangeControl.value.dateFrom || '';
  }

  get dateTo(): string {
    return this.dateRangeControl.value.dateTo || '';
  }

  get tags(): AbstractControl {
    return this.filterForm.controls.tags;
  }

  ngOnInit(): void {
    this.filter = new AuditLogsFilter();
    this.filterEntities = this.route.snapshot.data.filterEntities;
    this.defaultEntity = this.route.snapshot.data.entity;
    this.isTopLevelView = this.route.snapshot.data.isTopLevelView;
    this.filter.entity = this.defaultEntity;

    const { isBatchLoading, isDataFetching, getCount, getAuditLogList } = this.auditLogsQuery;
    this.count$ = this.store.select(getCount);
    const auditLogsLoading$ = this.store.select(isBatchLoading);
    const dataLoading$ = this.store.select(getAuditLogList).pipe(
      switchMap(logs => {
        const tags = this.tagsService.getUniqueFetchableTags(logs);
        return this.store.select(isDataFetching(convertTagsToTagIdsRecord(tags)));
      })
    );
    this.loading$ = or(auditLogsLoading$, dataLoading$);

    const routeTags = this.route.snapshot.data.tags as Tag[];
    const routeTagFormats$ = routeTags.map(tag => this.tagsService.formatTag$(tag));

    this.setupForm();
    combineLatest(routeTagFormats$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formattedDefaultTags => {
        this.defaultTags = formattedDefaultTags;
        this.setDefaultTags();

        this.filteredActions$ = this.getFilteredActions$();

        this.subscribeToEntityChanges();
        this.subscribeToDateRangeChanges();
        this.subscribeToTagsChanges();
        this.subscribeToActionChanges();
      });

    this.store
      .select(this.auditLogsQuery.getAuditLogs)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(logs => {
        const changesFound = logs.some(auditLog => !!auditLog.params.changes);
        const changesColExist = this.displayedColumns.includes('changes');

        if (changesFound && !changesColExist) {
          this.displayedColumns.push('changes');
        }

        if (!changesFound && changesColExist) {
          this.displayedColumns.pop();
        }

        this.logs = new MatTableDataSource(logs);
        this.logs.sort = this.sort;
      });
  }

  onPage(pageEvent: PageEvent): void {
    this.filter = {
      ...this.filter,
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    };
    this.loadAuditLogs();
  }

  onSort(sortEvent: Sort): void {
    if (sortEvent.active === 'createdAt') {
      this.filter = {
        ...this.filter,
        page: 1,
        sortBy: {
          createdAt: sortEvent.direction
        }
      };
      this.loadAuditLogs();
    }
  }

  openChangesDetails(data: Change[]): void {
    this.matDialog.open(ChangesDetailDialogComponent, { data, maxHeight: '100vh', width: '80vw' });
  }

  setupForm(): void {
    this.filterForm = this.fb.group<AuditLogForm>({
      dateRange: this.fb.nonNullable.group({
        dateFrom: this.fb.nonNullable.control(''),
        dateTo: this.fb.nonNullable.control('')
      }),
      entity: this.fb.nonNullable.control(this.defaultEntity || ''),
      tags: this.fb.control(null),
      action: this.fb.control(null)
    });

    if (this.defaultEntity) {
      this.filterForm.controls.entity.disable();
    }
  }

  getFilteredActions$(): Observable<string[]> {
    return this.filterForm.controls.action.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(''),
      map(value => value ?? ''),
      map(value =>
        value ? this.displayedActions.filter(action => action.startsWith(value.toLowerCase())) : this.displayedActions
      )
    );
  }

  handleQuickSearch(entity: string, action: string): void {
    this.filterForm.patchValue({ action, entity });
  }

  clearFilter(): void {
    this.filter = new AuditLogsFilter();
    this.setDefaultTags();
  }

  private setDefaultTags(): void {
    if (this.defaultTags[0]?.id) {
      this.defaultTags[0].isDefaultTag = true;
      this.tags.setValue(this.defaultTags, { emitEvent: false });
    }
    this.filter.tags = this.defaultTags.map(tag => ({ id: tag.id, type: tag.type }));
  }

  private loadAuditLogs(): void {
    this.store.dispatch(loadAuditLogs({ filter: this.filter }));
  }

  private subscribeToEntityChanges(): void {
    this.filterForm.controls.entity.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000))
      .subscribe(entity => {
        this.filter = { ...this.filter, page: 1, entity };
        this.loadAuditLogs();
      });
  }

  private subscribeToDateRangeChanges(): void {
    this.dateRangeControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(1000),
        filter(() => this.dateRangeControl.valid)
      )
      .subscribe(({ dateFrom, dateTo }) => {
        this.filter = {
          ...this.filter,
          page: 1,
          dateFrom: dateFrom && DayjsUtils.getStartOfDay(dateFrom, this.timezoneOffset),
          dateTo: dateTo && DayjsUtils.getEndOfDay(dateTo, this.timezoneOffset)
        };
        this.loadAuditLogs();
      });
  }

  private subscribeToTagsChanges(): void {
    this.tags.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(1000),
        filter(tags => !!tags)
      )
      .subscribe((tags: Tag[]) => {
        this.filter = {
          ...this.filter,
          page: 1,
          tags: tags.map(tag => ({ id: tag.id, type: tag.type }))
        };
        this.loadAuditLogs();
      });
  }

  private subscribeToActionChanges(): void {
    this.filterForm.controls.action.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000))
      .subscribe(value => {
        this.filter = {
          ...this.filter,
          page: 1,
          action: value || ''
        };
        this.loadAuditLogs();
      });
  }
}
