import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { CoreState } from '@core/store';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { SCOPES_OR } from '@core/types';

import { loadUser } from '../store/actions/users.actions';
import { usersQuery } from '../store/selectors/users.selectors';
import { User, UserState } from '../types';

export interface UserResolverData {
  user$: Observable<User>;
  isOnCurrentUserRoute: boolean;
}

export const userResolver: ResolveFn<UserResolverData | Observable<null>> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  scopes: Scopes = inject(Scopes),
  store: Store<UserState> = inject(Store<UserState>),
  coreStore: Store<CoreState> = inject(Store<CoreState>)
): Observable<UserResolverData | Observable<null>> => {
  const userId = route.params.userId;

  return coreStore.select(authQuery.getUser).pipe(
    first(),
    map(currentUser => {
      if (scopes.lackScopes(SCOPES_OR.showUsers) && currentUser.sub !== userId) {
        return of(null);
      }
      store.dispatch(loadUser({ id: userId }));

      return {
        user$: store.select(usersQuery.getUserById(userId)).pipe(filter(user => !!user)),
        isOnCurrentUserRoute: currentUser.sub === userId
      };
    })
  );
};
