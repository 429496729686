import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MatChipUtils {
  addToChipList(input: HTMLInputElement, value: string, formControl: AbstractControl): void {
    if (formControl.value) {
      const sanitizedValue = (value || '').trim();
      if (sanitizedValue) {
        this.addValueToChipFormControl(sanitizedValue, formControl);
      }
    }

    input.value = '';
  }

  addValueToChipFormControl(value: string, formControl: AbstractControl): void {
    formControl.setValue([...formControl.value, value]);
  }

  removeFromChipList(index: number, formControl: AbstractControl): void {
    formControl.setValue(formControl.value.filter((_, i) => i !== index));
  }
}
