import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { Store } from '@ngrx/store';
import codes, { ICountryCodeItem } from 'country-calling-code';
import { combineLatest, map, Observable, startWith } from 'rxjs';

import { CanCheckUnsavedChanges } from '@core/guards/unsaved-changes.guard';
import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';
import { ChangesUtils, CountryUtils, INVALID_PHONE_NUMBER_MESSAGE } from '@utils';

import { phoneValidator } from '../../../../validators';
import { createUser } from '../../store/actions/users.actions';
import { UserState } from '../../types';
import { Nullable } from '@shared/types';

@UseV2Style
@Component({
  selector: 'admin-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', hideRequiredMarker: true } },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } }
  ]
})
export class UserCreateComponent implements OnInit, CanCheckUnsavedChanges {
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  destroyRef = inject(DestroyRef);

  loading$: Observable<boolean>;
  createUserForm: UntypedFormGroup;
  isPristine = true;

  unsavedChangesModalEntity = 'Add User';
  initialUserFormValue: object;

  countryCodes = codes;

  nowTime = new Date();
  genders = ['female', 'male', 'non_binary'];
  statuses = ['active', 'blocked', 'closed'];
  filteredCountryCodeOptions$: Observable<ICountryCodeItem[]>;
  filteredCountryNameOptions$: Observable<ICountryCodeItem[]>;

  isCodeAutoCompleteOpened: boolean;
  isCountryAutoCompleteOpened: boolean;
  isGenderSelectOpened = false;
  isSaveChangesDisabled = true;

  invalidPhoneMessage = INVALID_PHONE_NUMBER_MESSAGE;

  private fb = inject(FormBuilder);
  private usersStore = inject(Store<UserState>);

  get countryCodeControl(): AbstractControl {
    return this.createUserForm.get('countryCode')!;
  }

  get phonePrefixControl(): AbstractControl {
    return this.createUserForm.get('phonePrefix')!;
  }

  get phoneRawControl(): AbstractControl {
    return this.createUserForm.get('phoneRaw')!;
  }

  get phoneNumberControl(): AbstractControl {
    return this.createUserForm.get('phoneNumber')!;
  }

  get getEmojyByCountryCode(): Nullable<string> {
    const phonePrefixInput = this.phonePrefixControl.value;

    if (!phonePrefixInput) {
      return null;
    }

    const country = this.findCountriesByCountryCode(phonePrefixInput)[0];

    return country ? CountryUtils.isoCountryCodeToFlagEmoji(country.isoCode2) : null;
  }

  get getEmojyByCountryName(): Nullable<string> {
    const countryInput = this.countryCodeControl.value;

    if (!countryInput) {
      return null;
    }

    const country = this.filterCountriesByCountryName(countryInput)[0];

    return country ? CountryUtils.isoCountryCodeToFlagEmoji(country.isoCode2) : null;
  }

  ngOnInit(): void {
    this.createForm();
    this.subscribeToFormChanges();
  }

  createForm(): void {
    this.createUserForm = this.fb.group({
      partnerUserId: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email]),
      phoneNumber: this.fb.control('', [phoneValidator()]),
      phoneRaw: this.fb.control({ value: '', disabled: true }),
      phonePrefix: this.fb.control(''),
      status: this.fb.control(null, [Validators.required]),
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      birthdate: this.fb.control(null),
      gender: this.fb.control(''),
      countryCode: this.fb.control('', [this.countryCodeValidator.bind(this)])
    });

    this.initialUserFormValue = this.createUserForm.getRawValue();
  }

  formatCountryCode(countryCode: string): string {
    return `+${countryCode}`;
  }

  isoCountryCodeToFlagEmoji(isoCode2: string): Nullable<string> {
    return CountryUtils.isoCountryCodeToFlagEmoji(isoCode2);
  }

  subscribeToFormChanges(): void {
    this.createUserForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isPristine = ChangesUtils.isUnchanged(this.initialUserFormValue, this.createUserForm.getRawValue());
      this.isSaveChangesDisabled = this.createUserForm.invalid || this.isPristine;
    });

    this.filteredCountryNameOptions$ = this.countryCodeControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCountriesByCountryName(value || ''))
    );

    this.filteredCountryCodeOptions$ = this.phonePrefixControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCountriesByCountryCode(value || ''))
    );

    this.phonePrefixControl.valueChanges.pipe(startWith(''), takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        this.phoneRawControl.enable();
      } else {
        this.phoneRawControl.disable();
      }
    });

    combineLatest([
      this.phonePrefixControl.valueChanges.pipe(startWith(this.phonePrefixControl.value)),
      this.phoneRawControl.valueChanges.pipe(startWith(this.phoneRawControl.value))
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([phonePrefix, phoneRaw]) => {
        let phoneNumber = '';
        if (phonePrefix && phoneRaw) {
          phoneNumber = `${phonePrefix.includes('+') ? '' : '+'}${phonePrefix}${phoneRaw}`;
        }
        this.phoneNumberControl.setValue(phoneNumber);
        this.phoneNumberControl.updateValueAndValidity();
        this.phoneRawControl.setErrors(this.phoneNumberControl.errors);
      });
  }

  countryCodeValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value || this.countryCodes.length === 0) {
      return null;
    }

    return this.countryCodes.some(option => option.country.toLowerCase() == control.value.toLowerCase())
      ? null
      : { invalidCountryCode: true };
  }

  toggleAutoCompleteDropdown(): void {
    if (this.isCodeAutoCompleteOpened || this.isCountryAutoCompleteOpened) {
      // setTimeout is used here so that closePanel action will execute after the auto complete open panel action (caused by clicking the form field)
      setTimeout(() => this.matAutocompleteTrigger.closePanel());
    }
  }

  toggleSelectDisplay(): void {
    setTimeout(() => (this.isGenderSelectOpened = !this.isGenderSelectOpened));
  }

  submitForm(): void {
    const { phonePrefix, phoneRaw, ...user } = this.createUserForm.value;
    if (user.countryCode?.length) {
      user.countryCode = this.countryCodes.find(option => option.country == user.countryCode)?.isoCode2;
    }
    this.usersStore.dispatch(createUser({ user }));
  }

  private filterCountriesByCountryCode(value: string): ICountryCodeItem[] {
    const filterValue = value.replace('+', '');
    return filterValue.length > 0
      ? this.countryCodes.filter(option => option.countryCodes.find(country => country.includes(filterValue)))
      : this.countryCodes;
  }

  private findCountriesByCountryCode(value: string): ICountryCodeItem[] {
    const filterValue = value.replace('+', '');
    return filterValue.length > 0
      ? this.countryCodes.filter(option => option.countryCodes.find(country => country === filterValue))
      : this.countryCodes;
  }

  private filterCountriesByCountryName(value: string): ICountryCodeItem[] {
    const filterValue = value.toLowerCase();
    return this.countryCodes.filter(option => option.country.toLowerCase().includes(filterValue));
  }
}
