// Helper to ensure merging Partial with original options works
var asPartialOptions = function (t) { return t; };
// Time values are stored in seconds
export var defaultOptions = asPartialOptions({
    tokenRefresh: {
        pollAheadDuration: 30,
        retryInterval: 1,
        retryThreshold: 3
    },
    sessionCheck: {
        pollInterval: 60,
        retryInterval: 1,
        retryThreshold: 3
    },
    idleCheck: {
        timeout: 10 * 60,
        promptTimeout: 60,
        interrupts: ['input', 'scroll', 'nav'],
        pollInterval: 2
    },
    storagePrefix: 'hermes.txs.'
});
