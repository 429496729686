import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { UploadImagesService } from '../../services/upload-images.service';
import { uploadImage, uploadImageFailure, uploadImageSuccess } from '../actions/upload-images.actions';

@Injectable()
export class UploadImagesEffects {
  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadImage),
      exhaustMap(({ resource, payload }) =>
        this.uploadImagesService.uploadImage(resource, payload).pipe(
          map(({ imageUrl }) => uploadImageSuccess({ imageUrl, componentId: payload.componentId })),
          catchError(error => of(uploadImageFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private uploadImagesService: UploadImagesService
  ) {}
}
