import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { SCOPES_OR } from '@core/types';
import { createResolver } from '@shared/route/resolver-helper';
import { Params } from '@utils';

import { loadEnrollments } from '../store/actions/enrollments.actions';
import { enrollmentsQuery } from '../store/selectors/enrollments.selectors';
import { EnrollmentState } from '../types';

export const userEnrollmentsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<void> => {
  const userId = Params.find(route, 'userId');

  if (!userId) {
    // top-level view
    return of(null);
  }

  return createResolver<EnrollmentState>({
    scope: SCOPES_OR.viewUserEnrollments,
    query: enrollmentsQuery.isUserEnrollmentsLoaded(userId),
    action: loadEnrollments({
      filter: {
        userId,
        fetchGroupedEnrollments: true,
        limit: 999 // TODO: remove limit value when LE removes the default value = 10 for limit
      },
      isTopLevelView: false
    })
  });
};
