<ng-container *ngIf="showExpiringPointsBalance">
  <mat-divider></mat-divider>

  <ng-container [ngSwitch]="renderState">
    <admin-spinner *ngSwitchCase="'loading'"></admin-spinner>

    <admin-empty-state-page *ngSwitchCase="'error'" entity="Expiring points"></admin-empty-state-page>

    <ng-container *ngSwitchCase="'content'">
      <div class="expiring-content">
        <div class="expiring-title medium-text">
          Points breakdown:
          <button mat-icon-button *ngIf="expiringTranches?.length > 6" (click)="openExpiringDialog()">
            <mat-icon svgIcon="view"></mat-icon>View all
          </button>
        </div>
        <div *ngIf="evergreenPoints > 0" class="evergreen-points flex items-center">
          <mat-icon svgIcon="info-i"></mat-icon>{{ evergreenPoints | roundedNumber: pointsActivityDecimals }}
          {{ loyaltyCurrency }} will never expire
        </div>
        <div class="expiring-points-wrapper">
          <div class="expiring-point" *ngFor="let tranche of expiringTranches?.slice(0, 6)">
            <div>
              {{ [tranche.expiryDate] | dayjsDateFormat: 'DD MMM YY' }}
            </div>
            <div class="bold-text">
              {{ tranche.balance | roundedNumber: pointsActivityDecimals }} {{ loyaltyCurrency | titlecase }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
