import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { ApiSettings } from '../../types';

export const loadApiSettings = createAction('[Api Settings] Load ApiSettings');
export const loadApiSettingsSuccess = createAction(
  '[Api Settings] Load ApiSettings Success',
  props<{ apiSettings: ApiSettings[] }>()
);
export const loadApiSettingsFailure = createAction(
  '[Api Settings] Load ApiSettings Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      GH2027: 'To ensure security of your API keys, additional verification is required.',
      default:
        'We could not show the API information due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const clearApiSettings = createAction('[Api Settings] Clear ApiSettings');
