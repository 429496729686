import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SegmentCreationState } from '../../types/segment-creation.type';

const getSegmentState = createFeatureSelector<SegmentCreationState>('segmentCreation');

const getSegmentAttribute = createSelector(getSegmentState, ({ attributes }: SegmentCreationState) => ({
  ...attributes
}));
const getSegmentAttributeState = createSelector(getSegmentAttribute, state => state.state);
const getSegmentAttributeMap = createSelector(getSegmentAttribute, state => state.attributeMap);
const getSegmentAttributeError = createSelector(getSegmentAttribute, state => state.error);
const isAttributesLoaded = createSelector(getSegmentAttributeState, state => state === 'loaded');

const getSegmentPreview = createSelector(getSegmentState, ({ preview }: SegmentCreationState) => preview);
const getSegmentPreviewMemberCount = createSelector(getSegmentPreview, state => state.memberCount);
const getSegmentPreviewState = createSelector(getSegmentPreview, state => state.state);
const getSegmentPreviewError = createSelector(getSegmentPreview, state => state.error);

const getSegmentForm = createSelector(getSegmentState, state => state.form ?? null);
const getSegmentLogics = createSelector(getSegmentState, state => state.logics);

export const segmentCreationQuery = {
  getSegmentAttributeState,
  getSegmentAttributeMap,
  getSegmentAttributeError,
  getSegmentPreviewMemberCount,
  getSegmentPreviewState,
  getSegmentPreviewError,
  getSegmentForm,
  getSegmentLogics,
  isAttributesLoaded
};
