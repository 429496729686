import { Pipe, PipeTransform } from '@angular/core';

import { AttributeDef } from '@core/types';
import { TooltipKeysUtils } from '@utils';

@Pipe({ name: 'userDetailTooltipKey' })
export class UserDetailTooltipKeysPipe implements PipeTransform {
  transform(value: AttributeDef<any>): string {
    return value ? TooltipKeysUtils.getUserDetailsKey(value) : '';
  }
}
