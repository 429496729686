import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { identityAdapter, identityInitialState, IdentityState } from '../../types';
import {
  createIdentity,
  createIdentityFailure,
  createIdentitySuccess,
  deleteIdentity,
  deleteIdentityFailure,
  deleteIdentitySuccess,
  loadIdentities,
  loadIdentitiesFailure,
  loadIdentitiesSuccess
} from '../actions/identities.actions';

export const identityTriggers: EntityTriggers = {
  single: {
    loading: [deleteIdentity.type, createIdentity.type],
    resting: [deleteIdentitySuccess.type, createIdentitySuccess.type],
    erroring: [deleteIdentityFailure.type, createIdentityFailure.type]
  },
  batch: {
    loading: [loadIdentities.type],
    resting: [loadIdentitiesSuccess.type],
    erroring: [loadIdentitiesFailure.type]
  }
};

export function identitiesReducer(state: IdentityState = identityInitialState, action: Action): IdentityState {
  return callStateReducer(baseReducer, identityTriggers)(state, action);
}

const baseReducer = createReducer(
  identityInitialState,
  on(loadIdentitiesSuccess, (state, { identities }) => identityAdapter.setAll(identities, state)),
  on(deleteIdentitySuccess, (state, { reference }) => identityAdapter.removeOne(reference, state))
);
