import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { WatchlistsFilter } from './watchlists-filter.type';
import { Nullable } from '@shared/types';

export interface Watchlist {
  id: string;
  type: string;
  rule: string;
  weight: number;
  values?: string[];
  action?: WatchlistActionValueType;
  createdAt: string;
  updatedAt: string;
}

export type NewWatchList = Omit<Watchlist, 'id' | 'createdAt' | 'updatedAt'>;

export const watchlistAdapter = createEntityAdapter<Watchlist>();

export interface WatchlistState extends EntityState<Watchlist>, CallState {
  filter: Nullable<WatchlistsFilter>;
  count: number;
}

export enum WatchlistType {
  Blacklist = 'blacklist',
  Whitelist = 'whitelist'
}

export enum WatchlistRule {
  Email = 'email',
  Geolocation = 'geolocation',
  IP = 'ip'
}

export enum WatchlistAction {
  Block = 'block',
  Throttle = 'throttle'
}

export type WatchlistActionValueType = `${WatchlistAction}`;

export const initialState: WatchlistState = {
  ...watchlistAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  count: 0
};

export interface UpdateWatchlistParams {
  id: string;
  addValues?: string[];
  removeValues?: string[];
  action?: WatchlistActionValueType;
}
