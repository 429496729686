import { delay, filter } from 'rxjs/operators';

import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR, SubTab, Tab } from '@core/types';

@Component({
  selector: 'admin-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  @Input() tabs: Tab[];
  @Input() tooltipPrefix?: string;

  destroyRef = inject(DestroyRef);

  auditLogsTab: SubTab = {
    path: 'logs',
    icon: 'list-in-a-box-round',
    label: 'Audit logs',
    displayed: this.scopes.hasAny(SCOPES_OR.viewAuditLogs)
  };
  activeTab: Tab;

  constructor(
    public route: ActivatedRoute,
    protected router: Router,
    protected scopes: Scopes
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        delay(100), // allow active tab highlighting on load
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.setActiveTab());
  }

  ngOnInit(): void {
    this.setActiveTab();
  }

  setActiveTab(): void {
    const pathsArray = window.location.pathname.split('/');

    this.activeTab = this.tabs.find(
      tab => tab.path === (pathsArray.at(3)?.length ? pathsArray.at(3) : pathsArray.at(-1))
    )!;
  }
}
