import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { AlfredParamConverter, AlfredParamsConfig, ObjectUtils } from '@utils';

import { MergeConfigsFilter } from '../types/merge-configs-filter.type';
import { MergeConfig, MergeConfigPayload, MergeConfigsResult } from '../types/merge-configs.type';

@Injectable({
  providedIn: 'root'
})
export class MergeConfigsService {
  constructor(private missionControlService: MissionControlService) {}

  getMergeConfig(id: string): Observable<MergeConfig> {
    return this.missionControlService.get<MergeConfig>(`merge_configs/${id}`);
  }

  getMergeConfigs(filter: MergeConfigsFilter): Observable<MergeConfigsResult> {
    const paramsConfig: AlfredParamsConfig = {
      name: '$like'
    };

    let alfredParam = AlfredParamConverter.convertToAlfredParam(filter, paramsConfig);
    alfredParam = ObjectUtils.sanitizeRequestObject(alfredParam);

    return this.missionControlService.get<MergeConfigsResult>(
      'merge_configs',
      new HttpParams({ fromObject: { ...ObjectUtils.prepareQueryObject(alfredParam) } })
    );
  }

  createMergeConfig(payload: MergeConfigPayload): Observable<MergeConfig> {
    return this.missionControlService.post<MergeConfig>('merge_configs', payload);
  }

  deleteMergeConfig(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`merge_configs/${id}`);
  }
}
