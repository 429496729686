import { debounceTime, filter, first } from 'rxjs/operators';

import { AfterViewInit, Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { DashedDateAdapter, DateUtils } from '@utils';

import { uuidValidator } from '../../../../validators';
import { getUserDetailsRoute } from '../../../users/types';
import { LocationHeatmapDialogComponent } from '../../components/location-heatmap-dialog/location-heatmap-dialog.component';
import { loadLoginAttempts } from '../../store/actions/login-attempts.actions';
import { loginAttemptsQuery } from '../../store/selectors/login-attempts.selectors';
import { LoginAttemptForm } from '../../types/login-attempt-forms.type';
import { LoginAttemptsFilter } from '../../types/login-attempts-filter.type';
import { LatLonData, LoginAttempt, LoginAttemptLocation, LoginAttemptState } from '../../types/login-attempts.type';

@Component({
  selector: 'admin-login-attempts',
  templateUrl: './login-attempts.component.html',
  styleUrls: ['./login-attempts.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DashedDateAdapter
    }
  ]
})
export class LoginAttemptsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  destroyRef = inject(DestroyRef);

  loginAttempts$: Observable<LoginAttempt[]>;
  loading$: Observable<boolean>;
  loginAttemptsTotalCount$: Observable<number>;
  filter: LoginAttemptsFilter;
  filterForm: FormGroup<LoginAttemptForm>;
  isUserLoginAttemptsView: boolean;

  displayedColumns = ['created at', 'user name', 'uid', 'provider id', 'session id'];

  getUserDetailsRoute = getUserDetailsRoute;

  readonly SCOPES = SCOPES_OR;

  constructor(
    @Inject('timezone') public timezone: string,
    private store: Store<LoginAttemptState>,
    private route: ActivatedRoute,
    private scopes: Scopes,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.isUserLoginAttemptsView = this.route.snapshot.data.isUserLoginAttemptsView;
    this.loginAttempts$ = this.store.select(loginAttemptsQuery.getLoginAttemptsList);
    this.loading$ = this.store.select(loginAttemptsQuery.isBatchLoading);
    this.loginAttemptsTotalCount$ = this.store.select(loginAttemptsQuery.getCount);

    if (this.scopes.hasAny(SCOPES_OR.showLoginAttempts)) {
      this.displayedColumns.push('actions');
    }

    this.filter = { ...this.route.snapshot.data.routeFilters };
    this.createFilterForm();
  }

  createFilterForm(): void {
    this.filterForm = this.fb.group({
      sessionId: this.fb.control(this.filter.sessionId),
      userId: this.fb.control(this.filter.userId, uuidValidator()),
      createdAt: this.fb.group({
        dateFrom: this.fb.control(this.filter.createdAt?.from),
        dateTo: this.fb.control(this.filter.createdAt?.to)
      })
    });
  }

  ngAfterViewInit(): void {
    this.subscribeToMatSortChange();
    this.subscribeToSessionIdFieldChange();
    this.subscribeToUserIdFieldChange();
    this.onDateChange();
  }

  dispatchLoadLoginAttempts(): void {
    this.store.dispatch(loadLoginAttempts({ filter: this.filter }));
  }

  onPage(pageEvent: PageEvent): void {
    this.filter = {
      ...this.filter,
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    };
    this.dispatchLoadLoginAttempts();
  }

  subscribeToMatSortChange(): void {
    this.sort.sortChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      this.filter = {
        ...this.filter,
        page: 1,
        sortBy: event.direction ? event.active : '',
        sortDirection: event.direction
      };
      this.dispatchLoadLoginAttempts();
    });
  }

  subscribeToSessionIdFieldChange(): void {
    this.filterForm.controls.sessionId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000))
      .subscribe(sessionId => {
        this.filter = {
          ...this.filter,
          page: 1,
          sessionId
        };
        this.dispatchLoadLoginAttempts();
      });
  }

  subscribeToUserIdFieldChange(): void {
    this.filterForm.controls.userId.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(1000),
        filter(() => this.filterForm.controls.userId.valid)
      )
      .subscribe(userId => {
        this.filter = {
          ...this.filter,
          page: 1,
          userId
        };
        this.dispatchLoadLoginAttempts();
      });
  }

  onDateChange(): void {
    this.filterForm.controls.createdAt.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.filterForm.controls.createdAt.valid),
        debounceTime(1000)
      )
      .subscribe(({ dateFrom, dateTo }) => {
        this.filter = {
          ...this.filter,
          page: 1,
          createdAt: {
            from: dateFrom && DateUtils.getTenantStartOfDay(dateFrom, this.timezone),
            to: dateTo && DateUtils.getTenantEndOfDay(dateTo, this.timezone)
          }
        };
        this.dispatchLoadLoginAttempts();
      });
  }

  handleLocationHeatmapButton(): void {
    this.filter = {
      ...this.filter,
      page: 1,
      limit: 100,
      sortBy: 'created at',
      sortDirection: 'desc'
    };
    this.dispatchLoadLoginAttempts();

    combineLatest([this.store.select(loginAttemptsQuery.getLocations), this.loading$.pipe(filter(loading => !loading))])
      .pipe(first())
      .subscribe(([locationData]) => {
        const locations = this.extractValidLocations(locationData);

        // open dialog only if there is valid location data
        if (locations.length > 0) {
          this.matDialog.open(LocationHeatmapDialogComponent, { data: { locations } });
        } else {
          this.snackBar.open('No location data available', 'Dismiss', { panelClass: 'mat-simple-snackbar-action' });
        }
      });
  }

  extractValidLocations(locations: LoginAttemptLocation[]): LatLonData[] {
    return locations
      .map(({ lat, lon }) => ({ lat, lon }))
      .filter(({ lat, lon }) => (lat || lat === 0) && (lon || lon === 0));
  }
}
