import { createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import {
  RecommendedSegment,
  RecommendationState,
  RecommendedSegmentState,
  recommendedSegmentAdapter
} from '../../types';
import { getRecommendationState } from '../reducers';

const getRecommendedSegmentState = createSelector(
  getRecommendationState,
  (state: RecommendationState) => state.segment
);

const recommendedSegmentDetector = createDetector<RecommendedSegment>();

const { selectAll: getRecommendedSegmentsList, selectEntities: getRecommendedSegmentsDictionary } =
  recommendedSegmentAdapter.getSelectors(getRecommendedSegmentState);

const { isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<RecommendedSegmentState>(getRecommendedSegmentState);

const isRecommendedSegmentsLoaded = createSelector(
  getRecommendedSegmentsDictionary,
  isBatchResting,
  (recommendedSegments, batchResting) => recommendedSegmentDetector('allLoaded')(recommendedSegments) && batchResting
);

const isRecommendedSegmentLoaded = (id: string): Selector<RecommendedSegmentState, boolean> =>
  createSelector(getRecommendedSegmentsDictionary, entities => recommendedSegmentDetector('oneLoaded')(entities, id));

const getRecommendedSegmentById = (id: string): Selector<RecommendedSegmentState, RecommendedSegment> =>
  createSelector(getRecommendedSegmentsDictionary, entities => entities[id]);

export const recommendedSegmentsQuery = {
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isRecommendedSegmentsLoaded,
  isRecommendedSegmentLoaded,
  getRecommendedSegmentById,
  getRecommendedSegmentsList,
  getRecommendedSegmentsDictionary
};
