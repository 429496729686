import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';
import { ObjectUtils } from '@utils';

import { approvalRequestAdapter, ApprovalRequestState, initialState } from '../../types/approval-requests.type';
import {
  loadApprovalRequest,
  loadApprovalRequestFailure,
  loadApprovalRequests,
  loadApprovalRequestsFailure,
  loadApprovalRequestsSuccess,
  loadApprovalRequestStatisticsSuccess,
  loadApprovalRequestSuccess,
  respondToApprovalRequest,
  respondToApprovalRequestFailure,
  respondToApprovalRequestSuccess
} from '../actions/approval-requests.actions';

export const approvalRequestTriggers: EntityTriggers = {
  single: {
    loading: [loadApprovalRequest.type, respondToApprovalRequest.type],
    // respondToApprovalRequestFailure is registered as "resting" action so it doesn't affect the state of ApprovalRequestDetailsComponent
    resting: [
      loadApprovalRequestSuccess.type,
      respondToApprovalRequestSuccess.type,
      respondToApprovalRequestFailure.type
    ],
    erroring: [loadApprovalRequestFailure.type]
  },
  batch: {
    // approval request statistics types should not be listed here since it could affect the loading state of the approval requests table
    loading: [loadApprovalRequests.type],
    resting: [loadApprovalRequestsSuccess.type],
    erroring: [loadApprovalRequestsFailure.type]
  }
};

export function approvalRequestsReducer(
  state: ApprovalRequestState = initialState,
  action: Action
): ApprovalRequestState {
  return callStateReducer(baseReducer, approvalRequestTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadApprovalRequestSuccess, respondToApprovalRequestSuccess, (state, { approvalRequest }) =>
    approvalRequestAdapter.setOne(approvalRequest, state)
  ),
  on(loadApprovalRequests, (state, { filter }) => ({ ...state, filter })),
  on(loadApprovalRequestsSuccess, (state, { approvalRequestsResult }) => ({
    ...approvalRequestAdapter.setAll(approvalRequestsResult.data, state),
    ...ObjectUtils.renameKey(approvalRequestsResult.metadata, 'total', 'count')
  })),
  on(loadApprovalRequestsFailure, state => approvalRequestAdapter.removeAll({ ...state })),
  on(loadApprovalRequestStatisticsSuccess, (state, { approvalRequestStatistics }) => ({
    ...state,
    statistics: approvalRequestStatistics
  }))
);
