<div class="mat-mdc-select-trigger" (click)="$event.stopPropagation(); openDrawer()">
  <div class="mat-mdc-select-value">
    @if (selectionModel.isEmpty()) {
      <span class="mat-mdc-select-placeholder mat-mdc-select-min-line">{{ placeholder }}</span>
    } @else {
      <span class="mat-mdc-select-value-text">
        @if (customTrigger) {
          <ng-content select="mat-select-trigger"></ng-content>
        } @else {
          <span class="mat-mdc-select-min-line">{{ selectedOption()?.viewValue }}</span>
        }
      </span>
    }
  </div>

  <div class="mat-mdc-select-arrow-wrapper">
    <div class="mat-mdc-select-arrow"></div>
  </div>
</div>

<ng-template #drawerContent>
  <div class="drawer-select-panel"><ng-content></ng-content></div>
</ng-template>
