<div *ngFor="let linkSection of linkSections" class="admin-container">
  <div class="heading">{{ linkSection?.name }}</div>
  <nav [attr.aria-label]="linkSection?.name + ' Table of Contents'">
    <a
      [href]="rootUrl + '#' + link.id"
      *ngFor="let link of linkSection?.links; let i = index"
      [class.active]="link.active"
      (click)="updateScrollPosition()"
    >
      {{ link.name }}
    </a>
  </nav>
</div>
