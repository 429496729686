import { Action } from '@ngrx/store';

import { GuardhouseError } from '@core/errors/guardhouse-error';

import { SentryLoggerFn } from '../services/sentry/sentry.factory';

export function captureGuardhouseException(
  sentryLoggerFn: SentryLoggerFn,
  {
    actionType,
    guardhouseError
  }: {
    actionType: Action['type'];
    guardhouseError: GuardhouseError;
  }
): void {
  sentryLoggerFn(guardhouseError, {
    extra: { fingerprint: guardhouseError.fingerprint, metadata: guardhouseError.error?.metadata, actionType }
  });
}
