import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { RecommendationState } from '../../types';
import { recommendedCampaignsReducer } from './recommended-campaigns.reducer';
import { recommendedSegmentsReducer } from './recommended-segments.reducer';

export const recommendationReducers: ActionReducerMap<RecommendationState> = {
  segment: recommendedSegmentsReducer,
  campaign: recommendedCampaignsReducer
};

export const getRecommendationState = createFeatureSelector<RecommendationState>('recommendation');
