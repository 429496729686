<ng-container [ngSwitch]="renderState">
  <admin-spinner *ngSwitchCase="'loading'" [isShownText]="false" [static]="true"></admin-spinner>

  <admin-empty-state-page *ngSwitchCase="'error'" entity="Points summary"></admin-empty-state-page>

  <ng-container *ngSwitchCase="'content'">
    <div *ngIf="!pointsSummaryData; else displayContent" class="render-state-error">
      <mat-icon [inline]="true">warning</mat-icon> Customer does not have points accounts loaded in the system. Please
      wait until customer accounts are loaded via files or contact tech support.
    </div>

    <ng-template #displayContent>
      <div class="points-summary-content">
        <div class="balance-header">Total points balance:</div>
        <div class="balance">
          <div>
            <span class="balance__amount">{{
              pointsSummaryData.pointsBalance | roundedNumber: pointsBalanceDecimals
            }}</span>
            <span class="balance__unit">{{ loyaltyCurrency | titlecase }}</span>
          </div>

          <div *ngIf="displayPointsAdjustmentButton">
            <button
              *adminIfScopesInclude="SCOPES.manualAdjustment; relationOperator: 'or'"
              mat-raised-button
              class="secondary-button"
              (click)="openPointsAdjustmentDialog()"
            >
              <mat-icon matPrefix svgIcon="settings-no-color"></mat-icon> Adjust balance
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
