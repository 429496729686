import { Nullable } from '@shared/types';

import { RequestParams } from '../../approval-requests/types/approval-requests.type';
import { AccessPolicy } from './access-policy.type';

export interface HttpError {
  message: Nullable<string>;
  status: Nullable<number>;
  statusText: Nullable<string>;
  headers: Nullable<{ [key: string]: any[] }>;
  url: Nullable<string>;
  errors: HttpErrorDetails[];
}

export interface HttpErrorDetails {
  code: string;
  message: string | object;
  metadata?: HttpErrorMetadata;
  translation?: object;
}

export interface HttpErrorMetadata {
  level?: number;
  accessPolicy?: AccessPolicy;
  payload?: RequestParams;
  pendingCount?: number;
}

export const UNDEFINED_HTTP_ERROR: HttpErrorDetails = {
  code: '0',
  message: 'MC is blocked or unavailable'
};
