import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { Enrollment } from '../../users/types';
import { Accrual } from './accruals.type';
import { Tranche } from './tranches.type';

export interface PointsSummary {
  pointsBalance: number;
  accruals: Accrual[];
  tranches: Tranche[];
}

export interface PointsAccount extends PointsSummary {
  id: string;
  referenceId: string;
  status: string;
  enrollments: Enrollment[];
}

export type PointsActivitiesLoad = 'only-initial' | 'disabled' | 'force';

export const pointsAccountAdapter = createEntityAdapter<PointsAccount>();

export interface PointsAccountState extends EntityState<PointsAccount>, CallState {
  selectedId: string;
}

export const pointsAccountInitialState: PointsAccountState = {
  ...pointsAccountAdapter.getInitialState<CallState>(initialCallState),
  selectedId: null
};
