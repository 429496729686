import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { CustomAttributes, DataType } from './event-types.type';

export interface EventTypeFilterForm {
  description: FormControl<string>;
  eventType: FormControl<string>;
}

export interface EventTypeConditionForm {
  attribute: FormControl<string>;
  dataType: FormControl<DataType>;
  systemIdentifier: FormControl<string>;
}
export interface EventTypeForm {
  description: FormControl<string>;
  eventType: FormControl<string>;
  conditions: FormArray<FormGroup<EventTypeConditionForm>>;
}

export const DEFAULT_EVENT_TYPE_CUSTOM_ATTRIBUTES: CustomAttributes = {
  amount: { description: 'Amount', type: 'Number' },
  event_date: { description: 'Event date', type: 'Date' },
  account_type: { description: 'Account type', type: 'String' }
} as const;

export const DEFAULT_EVENT_TYPE_CONDITION_SYSTEM_IDENTIFIER = Object.keys(DEFAULT_EVENT_TYPE_CUSTOM_ATTRIBUTES);
