<mat-form-field class="field">
  <mat-label>{{ fieldLabel | customTitleCase: 'fromCamelCase' }}</mat-label>
  <input
    matInput
    autocorrect="off"
    autocomplete="off"
    ng-pattern="/^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/(19|20)\d\d/"
    spellcheck="false"
    [attr.aria-required]="required"
    [formControl]="dateControl"
    [id]="id"
    [matDatepicker]="picker"
    [max]="max"
    [placeholder]="placeholder"
    [required]="required"
    (dateChange)="onChange($event.value)"
    (focus)="picker.open()"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<mat-error class="error-message" role="alert">
  {{ getErrorMessage() }}
</mat-error>
