// store the operator configurations for param
// text: partial search; inc: includes; gteq: greater than equal; lteq: less than equal

export type AlfredOperator = '$like' | '$inc' | '$gteq' | '$lteq' | '$eq';
export type AlfredParamsConfig = Record<string, AlfredOperator>;

export const AlfredParamConverter = {
  convertToAlfredParam(filter: object, paramsConfig: AlfredParamsConfig): object {
    // create newFilter so it won't overwrite the original filter
    const newFilter = { ...filter };
    Object.entries(paramsConfig).forEach(([param, operator]) => {
      if (newFilter[param]) {
        newFilter[param] = Array.isArray(newFilter[param])
          ? { [operator]: newFilter[param].join(',') }
          : { [operator]: newFilter[param] };
      }
    });
    return newFilter;
  }
};
