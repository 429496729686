import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { SnackBarService } from '@shared/services/snack-bar-service/snack-bar.service';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'admin-snack-bar-demo',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './snack-bar-demo.component.html',
  styles: ['div { display: grid; grid-gap: 5px; grid-template-columns: repeat(4, auto); margin: 20px; }']
})
export class SnackBarDemoComponent {
  constructor(private snackBarService: SnackBarService) {}

  openVoucherSnackBar(type: 'success' | 'error'): void {
    const body = document.querySelector('body');
    body.classList.add('v1');
    body.classList.remove('v2');

    if (type === 'success') {
      this.snackBarService.openSuccessSnackBar({
        data: { innerHtml: 'Vouchers has been successfully deleted!' }
      });
    } else {
      this.snackBarService.openErrorSnackBar({
        data: { innerHtml: 'Vouchers has failed to delete.' }
      });
    }
  }

  openEarnRuleSnackBar(type: 'success' | 'error'): void {
    const body = document.querySelector('body');
    body.classList.add('v2');
    body.classList.remove('v1');

    if (type === 'success') {
      this.snackBarService.openSuccessSnackBar({
        data: { innerHtml: 'You have successfully created a regular points rule.' }
      });
    } else {
      this.snackBarService.openErrorSnackBar({
        data: {
          innerHtml: `We could not create your earn rule due to technical issues on our end. Please try creating your rule again.
                      If the issue persists, contact our <a target="_blank" href="https://docs.ascenda.com/docs">Help Center</a>.`
        }
      });
    }
  }
}
