import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Tag } from '@shared/types';

import { usersQuery } from '../../users/store/selectors/users.selectors';
import { UserState } from '../../users/types';

@Pipe({ name: 'fetchTagsCustomerIds' })
export class FetchTagsCustomerIdsPipe implements PipeTransform {
  constructor(
    private store: Store<UserState>,
    @Inject('customerBankIdentityProvider') private customerBankIdentityProvider: string
  ) {}

  transform(tags: Tag[]): Observable<string[]> {
    const userIds = tags.map(tag => tag.id);
    return this.store.select(usersQuery.getUserCustomerIds(userIds, this.customerBankIdentityProvider));
  }
}
