import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CallState, CallStateSelectors, CallStateUnion, HttpError } from '@core/types';

export const initialCallState: CallState = {
  callState: {
    batch: 'initial',
    single: 'initial'
  }
};

export function getCallStateSelectors<T extends CallState>(
  featureSelector: MemoizedSelector<object, T>
): CallStateSelectors<T> {
  const getCallState = createSelector(featureSelector, (featureState: T) => featureState.callState);

  const isSingleInitial = createSelector(getCallState, callState => callState.single === 'initial');

  const isSingleLoading = createSelector(getCallState, callState => callState.single === 'loading');

  const isSingleResting = createSelector(getCallState, callState => callState.single === 'resting');

  const getSingleError = createSelector(getCallState, callState => getCallStateError(callState.single));

  const isBatchInitial = createSelector(getCallState, callState => callState.batch === 'initial');

  const isBatchLoading = createSelector(getCallState, callState => callState.batch === 'loading');

  const isBatchResting = createSelector(getCallState, callState => callState.batch === 'resting');

  const getBatchError = createSelector(getCallState, callState => getCallStateError(callState.batch));

  return {
    isSingleInitial,
    isSingleLoading,
    isSingleResting,
    getSingleError,
    isBatchInitial,
    isBatchLoading,
    isBatchResting,
    getBatchError
  };
}

function getCallStateError(callState: CallStateUnion): HttpError | null {
  if (typeof callState === 'string') {
    return null;
  }

  return callState.error;
}
