<h1 mat-dialog-title>Create Identity</h1>

<form [formGroup]="createForm" (keydown.enter)="$event.preventDefault()" mat-dialog-content>
  <div fxLayout="column">
    <mat-form-field class="slim-form-field">
      <mat-label>Provider ID</mat-label>
      <mat-select required formControlName="providerId" disableOptionCentering panelClass="dropdown-panel">
        <mat-option *ngFor="let providerId of providerIdOptions" [value]="providerId">
          {{ providerId | titlecase }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
    </mat-form-field>
    <mat-form-field class="slim-form-field">
      <mat-label>UID</mat-label>
      <input matInput placeholder="UID" formControlName="uid" />
      <mat-error *ngIf="uidControl.hasError('required')">Required</mat-error>
      <mat-error *ngIf="uidControl.hasError('email')">Invalid email</mat-error>
    </mat-form-field>
    <ng-container *ngIf="displayPasswordFields">
      <mat-form-field class="slim-form-field">
        <mat-label>Password</mat-label>
        <input matInput placeholder="Password" formControlName="password" type="password" autocomplete="off" />
        <mat-error>Required</mat-error>
      </mat-form-field>
      <mat-form-field class="slim-form-field">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          placeholder="Password Confirmation"
          formControlName="passwordConfirmation"
          type="password"
          autocomplete="off"
        />
        <mat-error
          *ngIf="passwordConfirmationControl.hasError('required') && !passwordConfirmationControl.hasError('mismatch')"
          >Required</mat-error
        >
        <mat-error *ngIf="passwordConfirmationControl.hasError('mismatch')"
          >Should match the contents of Password</mat-error
        >
      </mat-form-field>
    </ng-container>
  </div>
</form>

<div mat-dialog-actions align="end">
  <button color="accent" class="uppercase-button text-primary secondary-button mr-2" (click)="dialogRef.close(false)">
    <span>Cancel</span>
  </button>

  <button
    mat-raised-button
    color="primary"
    class="uppercase-button"
    (click)="submitForm()"
    [disabled]="createForm.invalid"
  >
    <span>Create</span>
  </button>
</div>
