@if (!!widgetData && !areAllActionsSuccessful) {
  <div class="mb-10">
    <mat-expansion-panel class="mat-elevation-z0">
      <!-- Panel Header -->
      <mat-expansion-panel-header>
        <mat-panel-title class="gap-2">
          <strong>{{ isSomeActionFailed ? 'Submitting of changes has failed' : 'Processing changes' }}</strong>
          <mat-chip class="tag" [ngClass]="hasAnyActionError ? 'failed' : 'processing'">{{
            actionChipContent
          }}</mat-chip>
          @if (hasAnyActionError && !hasAnyActionProcessing) {
            <button
              mat-button
              *adminIfScopesInclude="this.widgetData.scopesForRetry; relationOperator: 'or'"
              (click)="retryAll($event)"
            >
              <span class="flex items-center gap-2 text-shakespeare-500"
                ><mat-icon>refresh</mat-icon>Retry {{ numberOfFailedActions > 1 ? 'all' : '' }}</span
              >
            </button>
          }
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- Panel Content -->
      @if (hasAnyActionError) {
        <p>Your changes have failed to be submitted. Please try submitting the failed changes again.</p>
      } @else {
        <p>
          Your changes have been saved and are currently processing in our system. If you choose to leave this page, the
          changes will continue to process while you conduct other tasks on the Admin Panel.
        </p>
      }

      @if (isMultipleActions) {
        @for (action of actions; track action.actionName; let idx = $index) {
          @if (idx !== 0) {
            <mat-divider></mat-divider>
          }
          <mat-expansion-panel
            [expanded]="shouldExpandTaskPanel(action)"
            class="mat-elevation-z0 remove-mat-expansion-panel-padding"
          >
            <mat-expansion-panel-header class="disable-hover-bg-color !p-0 !pr-1">
              <mat-panel-title class="justify-between">
                <strong>{{ action.actionName }}</strong>
                <mat-chip class="tag" [ngClass]="action.overallStatus">{{ taskChipContent[idx] }}</mat-chip>
              </mat-panel-title>
            </mat-expansion-panel-header>
            @for (task of action.tasks; track task.clientId) {
              <ng-container *ngTemplateOutlet="taskListItem; context: { $implicit: task }"></ng-container>
            }
          </mat-expansion-panel>
        }
      } @else if (actions && actions.length > 0) {
        @for (task of actions[0].tasks; track task.clientId; let idx = $index) {
          @if (idx !== 0) {
            <mat-divider></mat-divider>
          }
          <ng-container *ngTemplateOutlet="taskListItem; context: { $implicit: task }"></ng-container>
        }
      }

      <ng-template #taskListItem let-task>
        @switch (task.status) {
          @case ('successful') {
            <div class="flex flex-row items-center gap-4">
              <mat-icon mat-list-icon class="text-success-500">check_circle</mat-icon>
              <h4 mat-line>{{ task.taskName }}</h4>
            </div>
          }
          @case ('processing') {
            <div class="processing-action-container flex flex-row items-center gap-4">
              <mat-spinner mat-list-icon diameter="24"></mat-spinner>
              <h4 mat-line>{{ task.taskName }}</h4>
            </div>
          }
          @case ('failed') {
            @if (task.changes) {
              <mat-expansion-panel
                [expanded]="true"
                mat-line
                class="mat-elevation-z0 remove-mat-expansion-panel-padding"
              >
                <mat-expansion-panel-header class="disable-hover-bg-color !p-0 !pr-1">
                  <mat-panel-title class="gap-4">
                    <mat-icon mat-list-icon class="text-error-500">error_outline</mat-icon>
                    <div mat-line class="mb-5 mt-5 text-sm font-medium leading-6 text-error-500">
                      {{ task.taskName }}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                @for (change of task.changes; track change.was + change.is) {
                  <div class="mb-2 ml-10 rounded-md bg-cloudburst-100 p-4 text-cloudburst-400">
                    @switch (change.operation) {
                      @case ('UPDATED') {
                        <s>{{ change.was }}</s> → {{ change.is }}
                      }
                      @case ('REMOVED') {
                        <s>{{ change.was }}</s>
                      }
                      @case ('ADDED') {
                        + {{ change.is }}
                      }
                      @case ('UNCHANGED') {
                        {{ change.is }}
                      }
                    }
                  </div>
                }
              </mat-expansion-panel>
            } @else {
              <div class="flex flex-row items-center gap-4">
                <mat-icon mat-list-icon class="text-error-500">error_outline</mat-icon>
                <div mat-line class="mb-5 mt-5 text-sm font-medium leading-6 text-error-500">{{ task.taskName }}</div>
              </div>
            }
          }
        }
      </ng-template>
    </mat-expansion-panel>
  </div>
}
