import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml', standalone: true })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (!value) return '';

    // Encode dangerous characters
    const encoded = value
      .replaceAll('&', '&amp;')
      .replaceAll('<', '&lt;')
      .replaceAll('>', '&gt;')
      .replaceAll('"', '&quot;')
      .replaceAll("'", '&#039;');

    // Convert double line breaks to paragraphs
    const paragraphs = encoded.split(/\n\s*\n/);
    const withParagraphs = paragraphs.length > 1 ? paragraphs.map(p => `<p>${p}</p>`).join('') : encoded;

    // Convert remaining single line breaks to <br>
    const withLineBreaks = withParagraphs.replaceAll('\n', '<br>');

    // Sanitize the final HTML
    return this.sanitizer.bypassSecurityTrustHtml(withLineBreaks);
  }
}
