import { Injectable } from '@angular/core';

import { Tag } from '@shared/types';

import { Scope } from '../../../apps/types/scopes.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class ScopeTagDefs {
  readonly tagDefs: TagDefs<Scope> = {
    scope_id: {
      getDisplayPrefix: () => 'Scope',
      getDisplayValue: (tag: Tag) => tag.id
    }
  };
}
