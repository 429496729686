import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { RewardPreferencesService } from '../../services/reward-preferences.service';
import {
  loadRewardPreference,
  loadRewardPreferenceFailure,
  loadRewardPreferenceSuccess
} from '../actions/reward-preferences.actions';

@Injectable()
export class RewardPreferencesEffects {
  loadRewardPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRewardPreference),
      exhaustMap(action =>
        this.rewardPreferencesService.getRewardPreference(action.userId).pipe(
          map(rewardPreference => loadRewardPreferenceSuccess({ rewardPreference })),
          catchError(error => of(loadRewardPreferenceFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private rewardPreferencesService: RewardPreferencesService
  ) {}
}
