import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { EarnRule, earnRuleAdapter, EarnRuleState } from '../../types';

const getEventEarnRuleState = createFeatureSelector<EarnRuleState>('eventEarnRule');
const eventEarnRuleDetector = createDetector<EarnRule>();

const { selectAll: getEventEarnRulesList, selectEntities: getEventEarnRulesDictionary } =
  earnRuleAdapter.getSelectors(getEventEarnRuleState);

const { isBatchLoading, isBatchResting, getBatchError, isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<EarnRuleState>(getEventEarnRuleState);

const isEventEarnRulesLoaded = createSelector(
  getEventEarnRulesDictionary,
  isBatchResting,
  (earnRules, batchResting) => eventEarnRuleDetector('allLoaded')(earnRules) && batchResting
);

const isEventEarnRuleLoaded = (groupId: string): Selector<EarnRuleState, boolean> =>
  createSelector(getEventEarnRulesDictionary, isSingleResting, eventEarnRule =>
    eventEarnRuleDetector('oneLoaded')(eventEarnRule, groupId)
  );

const getEventEarnRuleById = (groupId: string): Selector<EarnRuleState, EarnRule> =>
  createSelector(getEventEarnRulesDictionary, entities => entities[groupId]);

const getFilter = createSelector(getEventEarnRuleState, (state: EarnRuleState) => state.filter);

const getVersion = createSelector(getEventEarnRuleState, (state: EarnRuleState) => state.version);

export const eventEarnRulesQuery = {
  isBatchLoading,
  isBatchResting,
  isSingleLoading,
  isSingleResting,
  getBatchError,
  getSingleError,
  isEventEarnRuleLoaded,
  isEventEarnRulesLoaded,
  getEventEarnRuleById,
  getFilter,
  getVersion,
  getEventEarnRulesList,
  getEventEarnRulesDictionary
};
