import { AbstractControl, ValidatorFn } from '@angular/forms';

export function characterCountInnerTextValidator(countLimit: number, className: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const element = document.getElementById(className);
    if (!control.value || !element) {
      // otherwise placeholder might be taken
      return null;
    }

    return element.innerText.trimStart().length > countLimit ? { exceedCharacterCount: true } : null;
  };
}
