import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { NewWatchList, UpdateWatchlistParams, Watchlist, WatchlistsFilter, WatchlistsResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class WatchlistsService {
  constructor(private missionControlService: MissionControlService) {}

  getWatchlist(id: string): Observable<Watchlist> {
    return this.missionControlService.get<Watchlist>(`watchlists/${id}`);
  }

  getWatchlists(filter: WatchlistsFilter): Observable<WatchlistsResult> {
    return this.missionControlService.get<WatchlistsResult>(
      'watchlists',
      new HttpParams({ fromObject: { ...filter } })
    );
  }

  createWatchlist(newWatchlist: NewWatchList): Observable<Watchlist> {
    return this.missionControlService.post<Watchlist>('watchlists', newWatchlist);
  }

  updateWatchlist(params: UpdateWatchlistParams): Observable<Watchlist> {
    return this.missionControlService.patch<Watchlist>(`watchlists/${params.id}`, params);
  }

  deleteWatchlist(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`watchlists/${id}`);
  }
}
