import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { routerForceNavigate, routerNavigate } from '@core/store/router/actions/router.actions';

import { PromoCodesService } from '../../services/promo-codes.service';
import {
  createPromoCode,
  createPromoCodeFailure,
  createPromoCodeSuccess,
  deletePromoCode,
  deletePromoCodeFailure,
  deletePromoCodeSuccess,
  loadPromoCode,
  loadPromoCodeFailure,
  loadPromoCodes,
  loadPromoCodesFailure,
  loadPromoCodesSuccess,
  loadPromoCodeSuccess,
  pausePromoCode,
  pausePromoCodeFailure,
  pausePromoCodeSuccess,
  resumePromoCode,
  resumePromoCodeFailure,
  resumePromoCodeSuccess,
  updatePromoCode,
  updatePromoCodeFailure,
  updatePromoCodeSuccess,
  uploadPromoCodeImage,
  uploadPromoCodeImageFailure,
  uploadPromoCodeImageSuccess
} from '../actions/promo-codes.actions';

@Injectable()
export class PromoCodesEffects {
  loadPromoCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPromoCodes),
      exhaustMap(({ filter }) =>
        this.promoCodesService.getPromoCodes(filter).pipe(
          map(promoCodesResult => loadPromoCodesSuccess({ promoCodesResult })),
          catchError(error => of(loadPromoCodesFailure({ error })))
        )
      )
    )
  );

  loadPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPromoCode),
      exhaustMap(action =>
        this.promoCodesService.getPromoCode(action.id).pipe(
          map(promoCode => loadPromoCodeSuccess({ promoCode })),
          catchError(error => of(loadPromoCodeFailure({ error })))
        )
      )
    )
  );

  createPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPromoCode),
      exhaustMap(action =>
        this.promoCodesService.createPromoCode(action.promoCode).pipe(
          map(promoCode => createPromoCodeSuccess(promoCode)),
          catchError(error => of(createPromoCodeFailure(error)))
        )
      )
    )
  );

  updatePromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePromoCode),
      exhaustMap(action =>
        this.promoCodesService.updatePromoCode(action.promoCode).pipe(
          map(promoCode => updatePromoCodeSuccess(promoCode)),
          catchError(error => of(updatePromoCodeFailure(error)))
        )
      )
    )
  );

  pausePromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pausePromoCode),
      exhaustMap(action =>
        this.promoCodesService.updatePromoCode(action.promoCode).pipe(
          map(promoCode => pausePromoCodeSuccess(promoCode)),
          catchError(error => of(pausePromoCodeFailure(error)))
        )
      )
    )
  );

  resumePromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resumePromoCode),
      exhaustMap(action =>
        this.promoCodesService.updatePromoCode(action.promoCode).pipe(
          map(promoCode => resumePromoCodeSuccess(promoCode)),
          catchError(error => of(resumePromoCodeFailure(error)))
        )
      )
    )
  );

  deletePromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePromoCode),
      exhaustMap(({ promoCodeId }) =>
        this.promoCodesService.deletePromoCode(promoCodeId).pipe(
          map(() => deletePromoCodeSuccess(promoCodeId)),
          catchError(error => of(deletePromoCodeFailure(error)))
        )
      )
    )
  );

  uploadPromoCodeImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadPromoCodeImage),
      exhaustMap(({ promoCodeId, file, formControl, dialogRefId }) =>
        this.promoCodesService.uploadPromoCodeImage(file, promoCodeId).pipe(
          map(({ imageUrl }) =>
            uploadPromoCodeImageSuccess({
              imageUrl,
              formControl,
              dialogRefId
            })
          ),
          catchError(error => of(uploadPromoCodeImageFailure({ error })))
        )
      )
    )
  );

  uploadPromoCodeImageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadPromoCodeImageSuccess),
        tap(({ formControl, imageUrl, dialogRefId }) => {
          formControl.setValue(imageUrl);
          this.matDialog.getDialogById(dialogRefId)?.close(true);
        })
      ),
    { dispatch: false }
  );

  createOrUpdatePromoCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPromoCodeSuccess, updatePromoCodeSuccess),
      map(({ promoCode }) => routerForceNavigate({ path: `promo-codes/${promoCode.id}` }))
    )
  );

  deletePromoCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePromoCodeSuccess),
      map(() => routerNavigate({ path: 'promo-codes' }))
    )
  );

  constructor(
    private actions$: Actions,
    private promoCodesService: PromoCodesService,
    private matDialog: MatDialog
  ) {}
}
