import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';
import { ArrayUtils } from '@utils';

import { BootstrapState } from '../../types/bootstraps.type';

const getBootstrapState = createFeatureSelector<BootstrapState>('bootstrap');

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError
} = getCallStateSelectors<BootstrapState>(getBootstrapState);

const getOffersBootstrap = createSelector(getBootstrapState, (state: BootstrapState) => state.offers);

const getGiftCardsBootstrap = createSelector(getBootstrapState, (state: BootstrapState) => state.giftCards);

const getGiftCardsRedeemableNames = createSelector(getBootstrapState, (state: BootstrapState) =>
  ArrayUtils.sortByNonCaseSensitive([...(state.giftCards?.redeemableNames || [])])
);

const isGiftCardsBootstrapLoaded = createSelector(getBootstrapState, (state: BootstrapState) => !!state.giftCards);

const getMerchandisesBootstrap = createSelector(getBootstrapState, (state: BootstrapState) => state.merchandise);

const getMerchandisesBrands = createSelector(getBootstrapState, (state: BootstrapState) =>
  ArrayUtils.sortByNonCaseSensitive([...(state.merchandise?.brands || [])])
);

export const bootstrapsQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError,
  getOffersBootstrap,
  getGiftCardsBootstrap,
  isGiftCardsBootstrapLoaded,
  getMerchandisesBootstrap,
  getGiftCardsRedeemableNames,
  getMerchandisesBrands
};
