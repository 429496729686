import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, merge, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ObjectUtils } from '@utils';

import { fetchUsers } from '../../../users/store/actions/users.actions';
import { usersQuery } from '../../../users/store/selectors/users.selectors';
import { UserState } from '../../../users/types';
import { EnrollmentsService } from '../../services/enrollments.service';
import {
  loadEnrollments,
  loadEnrollmentsAndUsers,
  loadEnrollmentsFailure,
  loadEnrollmentsSuccess
} from '../actions/enrollments.actions';

@Injectable()
export class EnrollmentsEffects {
  loadEnrollments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnrollments),
      switchMap(action =>
        this.enrollmentsService.getEnrollments(action.filter).pipe(
          map(enrollments => loadEnrollmentsSuccess({ enrollments })),
          catchError(error => of(loadEnrollmentsFailure({ error })))
        )
      )
    )
  );

  loadEnrollmentsAndUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnrollmentsAndUsers),
      withLatestFrom(this.userStore.select(usersQuery.getUserIds)),
      switchMap(([action, userIds]) =>
        this.enrollmentsService.getEnrollments(action.filter).pipe(
          exhaustMap(enrollments => {
            if (enrollments.length > 0) {
              return combineLatest([
                // find unique user id from enrollments result and call fetchUsers with them
                of(
                  fetchUsers({
                    ids: ObjectUtils.removeArrayDuplicates(enrollments.map(enrollment => enrollment.user.id)).filter(
                      id => !(userIds as string[]).includes(id)
                    ),
                    batch: true
                  })
                ),
                of(loadEnrollmentsSuccess({ enrollments }))
              ]);
            } else {
              return [of(loadEnrollmentsSuccess({ enrollments }))];
            }
          }),
          exhaustMap(actions => merge(actions)),
          catchError(error => of(loadEnrollmentsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userStore: Store<UserState>,
    private enrollmentsService: EnrollmentsService
  ) {}
}
