import { SortDirection } from '@angular/material/sort';

export class FraudEvaluationsFilter {
  userId?: string;
  limit: number;
  page: number;
  sortBy?: string;
  sortDirection?: SortDirection;

  constructor() {
    this.page = 1;
    this.limit = 20;
    this.sortBy = 'createdAt';
    this.sortDirection = 'desc';
  }
}
