import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { CerberusConfig } from '../../types/cerberus-configs.type';

export const loadCerberusConfig = createAction('[Cerberus Config] Load Cerberus Config', props<{ appId: string }>());
export const loadCerberusConfigSuccess = createAction(
  '[Cerberus Config] Load Cerberus Config Success',
  props<{ cerberusConfig: CerberusConfig; appId: string }>()
);
export const loadCerberusConfigFailure = createAction(
  '[Cerberus Config] Load Cerberus Config Failure',
  props<ErrorState>()
);

export const resetCerberusConfig = createAction('[Cerberus Config] Reset Cerberus Config', props<{ appId: string }>());
export const resetCerberusConfigSuccess = createAction(
  '[Cerberus Config] Reset Cerberus Config Success',
  props<{ appId: string }>()
);
export const resetCerberusConfigFailure = createAction(
  '[Cerberus Config] Reset Cerberus Config Failure',
  props<ErrorState>()
);

export const refreshTokenFailure = createAction('[Cerberus Config] Refresh Token Failure', props<ErrorState>());
