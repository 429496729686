import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';

@Pipe({ name: 'utcTime' })
export class UtcTimePipe implements PipeTransform {
  transform(value: string, hour: number = 0, minute: number = 0, second: number = 0): Nullable<Date> {
    if (!value) {
      return null;
    }

    const date = new Date(value);

    if (isNaN(date.getTime())) {
      return null;
    }

    date.setUTCHours(hour, minute, second);
    return date;
  }
}
