import { Component, Input, OnInit } from '@angular/core';

import { OrderItem, PointsTransferOrderItemData } from '../../types';

@Component({
  selector: 'admin-points-transfer-order-item',
  templateUrl: './points-transfer-order-item.component.html',
  styleUrls: ['./points-transfer-order-item.component.scss', '../../stylesheets/order-item.styles.scss']
})
export class PointsTransferOrderItemComponent implements OnInit {
  @Input() orderItem: OrderItem;
  @Input() customerId: string;
  @Input() timezone: string;

  data: PointsTransferOrderItemData;

  ngOnInit(): void {
    this.data = this.orderItem.data as PointsTransferOrderItemData;
  }
}
