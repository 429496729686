import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { WindowRefService } from '@core/services/window-ref/window-ref.service';
import { SCOPES_OR } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { Formatters } from '@utils';

import { giftCardsQuery } from '../../../gift-cards/store/selectors/gift-cards.selectors';
import { GiftCard } from '../../../gift-cards/types/gift-cards.type';
import { sendNotification, updateOrderItemStatus } from '../../store/actions/order-items.actions';
import { GiftCardOrderItemData, OrderItem, Recipient, StripeReferenceData } from '../../types';

@Component({
  selector: 'admin-gift-card-order-item-v2',
  templateUrl: './gift-card-order-item-v2.component.html',
  styleUrls: ['./gift-card-order-item-v2.component.scss']
})
export class GiftCardOrderItemV2Component implements OnInit {
  @Input() detailsView: string;
  @Input() orderItem: OrderItem;
  @Input() customerId: string;
  @Input() timezone: string;
  @Input() stripeReferenceData: StripeReferenceData;

  giftCard$: Observable<GiftCard>;
  data: GiftCardOrderItemData;
  recipient: Recipient;
  formattedAddress: string;
  expandedCardLinks = false;
  enableRefundButton = true;
  readonly SCOPES = SCOPES_OR;

  constructor(
    @Inject('customerBankIdentityProvider') private identityProvider: string,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    private store: Store,
    private matDialog: MatDialog,
    private windowService: WindowRefService
  ) {}

  ngOnInit(): void {
    this.data = this.orderItem.data as GiftCardOrderItemData;
    this.recipient = this.data?.recipient;
    this.giftCard$ = this.store.select(giftCardsQuery.getGiftCardById(this.data.cardId));
    this.formattedAddress = this.recipient?.address
      ? Formatters.recipientAddressFormatter(this.recipient.address)
      : 'N/A';
  }

  openSendNotificationDialog(): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          confirmText: 'Are you sure to resend email notification?',
          confirmButtonText: 'Yes, confirm',
          styleClassName: 'confirm-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          // resend the latest notification that with xxx_email channel
          const notifications = this.orderItem.notifications.filter(notification =>
            notification.channel.includes('email')
          );
          if (notifications.length > 0) {
            this.store.dispatch(sendNotification({ notificationId: notifications.at(-1).id }));
          }
        }
      });
  }

  updateOrderItemStatus(): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          confirmText: 'Please confirm your request to update the status of this order item',
          confirmButtonText: 'Yes, confirm',
          styleClassName: 'confirm-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(updateOrderItemStatus({ id: this.orderItem.id }));
        }
      });
  }

  openNewTab(url: string): void {
    this.windowService.openNewTab(url);
  }
}
