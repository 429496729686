import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export const rewardStatusDisplayClass = {
  fulfilled: 'status-active',
  processing: 'status-pending',
  cancelled: 'status-neutral',
  failed: 'status-inactive'
};

export type RewardStatus = keyof typeof rewardStatusDisplayClass;

export const rewardTriggersStatus = {
  ready: { label: 'Ready', displayClass: 'status-neutral' },
  successful: { label: 'Completed', displayClass: 'status-active' },
  pending_qualifying_action: { label: 'Pending', displayClass: 'status-pending' },
  failed: { label: 'Failed', displayClass: 'status-inactive' }
} as const;

export type RewardTriggerStatus = keyof typeof rewardTriggersStatus;

interface Reward {
  id: string;
  type: string;
  rewardType: string;
  userId: string;
  status: RewardStatus;
  createdAt: string;
}

interface BenefitDetails {
  currentFfpTier: string;
  tierUpgradeType: string;
}

export interface RewardTrigger {
  id: string;
  type: string;
  userId: string;
  status: RewardTriggerStatus;
  rewards?: Reward[];
  data: { benefitDetails: BenefitDetails };
  createdAt: string;
  updatedAt: string;
}

export const rewardTriggerAdapter = createEntityAdapter<RewardTrigger>();

export interface RewardTriggerState extends EntityState<RewardTrigger>, CallState {
  count: number;
}

export const initialState: RewardTriggerState = {
  ...rewardTriggerAdapter.getInitialState<CallState>(initialCallState),
  count: 0
};
