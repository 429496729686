@if (item.scopes?.length > 0) {
  <ng-template
    [ngTemplateOutlet]="navItem"
    *adminIfScopesInclude="item.scopes; relationOperator: item.relationOperator ?? 'or'"
  ></ng-template>
} @else {
  <ng-template [ngTemplateOutlet]="navItem"></ng-template>
}

<ng-template #navItem>
  <a (click)="onItemSelected(item)" [routerLink]="item.route" class="main-item no-underline">
    <!-- only display tooltip when navService.isMini is true -->
    <admin-tooltip
      [key]="navService.isMini && 'sidenav.menu.' + item.id"
      position="right"
      [displayIcon]="false"
      [defaultValue]="navService.isMini && item.displayName"
    >
      <div
        class="admin-mat-list-item"
        [class.active]="isActive(item) && !(item.children?.length > 0)"
        [class.active-font]="!!isActive(item)"
        [class.active-with-hidden-child]="
          !!isActive(item) &&
          item.children?.length > 0 &&
          !(expandedItem === item || expandedItem?.children?.includes(item))
        "
        [ngClass]="isRc ? 'add-left-border' : 'apply-circle-background'"
        [attr.data-testid]="'sidenav-' + item.id"
      >
        @if (icon) {
          <div class="img-wrapper">
            <mat-icon [svgIcon]="icon"></mat-icon>
          </div>
        }
        @if (!navService.isMini) {
          <span
            [matBadge]="item.badgeValue$ | async"
            [matBadgeHidden]="!(item.badgeValue$ | async)"
            class="item-text"
            matBadgeColor="warn"
            matBadgeOverlap="false"
          >
            {{ item.displayName }}
          </span>
        }
        @if (item.isProFeature) {
          <mat-chip class="tag successful ml-auto !py-0.5">Pro</mat-chip>
        }
        @if (item.children?.length) {
          <mat-icon class="main-item__arrow">
            {{ expandedItem === item ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        }
      </div>
    </admin-tooltip>
  </a>
</ng-template>

<div
  [@detailExpand]="expandedItem === item || expandedItem?.children?.includes(item) ? 'expanded' : 'collapsed'"
  class="children-items expanded-detail"
>
  @for (childItem of item.children; track childItem.id) {
    <admin-menu-list-item
      [item]="childItem"
      [depth]="depth + 1"
      [expandedItem]="expandedItem"
      (newExpandedItem)="newExpandedItem.emit($event)"
      [class.active-font]="!!isActive(item)"
    >
    </admin-menu-list-item>
  }
</div>
