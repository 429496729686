import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { initialState, redemptionRateAdapter, RedemptionRateState } from '../../types';
import {
  createRedemptionRate,
  createRedemptionRateFailure,
  createRedemptionRateSuccess,
  deleteRedemptionRate,
  deleteRedemptionRateFailure,
  deleteRedemptionRateSuccess,
  fetchRedemptionRate,
  fetchRedemptionRateFailure,
  fetchRedemptionRateSuccess,
  loadRedemptionRate,
  loadRedemptionRateFailure,
  loadRedemptionRates,
  loadRedemptionRatesFailure,
  loadRedemptionRatesSuccess,
  loadRedemptionRateSuccess,
  updateRedemptionRate,
  updateRedemptionRateFailure,
  updateRedemptionRateSuccess
} from '../actions/redemption-rates.actions';

export const redemptionRateTriggers: EntityTriggers = {
  single: {
    loading: [loadRedemptionRate.type, createRedemptionRate.type, updateRedemptionRate.type, deleteRedemptionRate.type],
    resting: [
      loadRedemptionRateSuccess.type,
      createRedemptionRateSuccess.type,
      updateRedemptionRateSuccess.type,
      deleteRedemptionRateSuccess.type
    ],
    erroring: [
      loadRedemptionRateFailure.type,
      createRedemptionRateFailure.type,
      updateRedemptionRateFailure.type,
      deleteRedemptionRateFailure.type
    ]
  },
  batch: {
    loading: [loadRedemptionRates.type],
    resting: [loadRedemptionRatesSuccess.type],
    erroring: [loadRedemptionRatesFailure.type]
  }
};

export const redemptionRateFetchListTriggers: FetchListTriggers = {
  loading: [fetchRedemptionRate.type],
  resting: [fetchRedemptionRateSuccess.type],
  erroring: [fetchRedemptionRateFailure.type]
};

export function redemptionRatesReducer(state: RedemptionRateState = initialState, action: Action): RedemptionRateState {
  return {
    ...callStateReducer(baseReducer, redemptionRateTriggers)(state, action),
    ...fetchStateReducer(redemptionRateFetchListTriggers, 'redemptionRate')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(fetchRedemptionRateSuccess, (state, { redemptionRate }) => redemptionRateAdapter.upsertOne(redemptionRate, state)),
  on(loadRedemptionRateSuccess, (state, { redemptionRate }) => redemptionRateAdapter.upsertOne(redemptionRate, state)),
  on(loadRedemptionRates, (state, { filter }) => ({ ...state, filter })),
  on(loadRedemptionRatesSuccess, (state, { result }) => ({
    ...redemptionRateAdapter.setAll(result.data, state),
    count: result?.meta.count || 0
  })),
  on(updateRedemptionRateSuccess, (state, { redemptionRate }) => redemptionRateAdapter.setOne(redemptionRate, state))
);
