import { Component, Input, OnInit } from '@angular/core';

import { SCOPES_OR } from '@core/types';

import { OrderItem, PointOfSaleOrderItemData, StripeReferenceData } from '../../types';

@Component({
  selector: 'admin-point-of-sale-order-item',
  templateUrl: './point-of-sale-order-item.component.html'
})
export class PointOfSaleOrderItemComponent implements OnInit {
  @Input() customerId: string;
  @Input() detailsView: string;
  @Input() orderItem: OrderItem;
  @Input() stripeReferenceData: StripeReferenceData;

  readonly SCOPES = SCOPES_OR;
  data: PointOfSaleOrderItemData;

  ngOnInit(): void {
    this.data = this.orderItem.data as PointOfSaleOrderItemData;
  }
}
