import { Observable } from 'rxjs/internal/Observable';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { AlfredSupplier, AlfredSuppliersFilter, AlfredSuppliersResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class AlfredSuppliersService {
  constructor(private missionControlService: MissionControlService) {}

  getSuppliers(filter: AlfredSuppliersFilter): Observable<AlfredSuppliersResult> {
    return this.missionControlService.get<AlfredSuppliersResult>(
      'alfred/suppliers',
      new HttpParams({ fromObject: { ...filter } })
    );
  }

  getSupplier(supplierId: string): Observable<AlfredSupplier> {
    return this.missionControlService.get<AlfredSupplier>(`alfred/suppliers/${supplierId}`);
  }

  refreshSupplier(supplierId: string): Observable<AlfredSupplier> {
    return this.missionControlService.post<AlfredSupplier>(`alfred/suppliers/${supplierId}/refresh_catalog`);
  }
}
