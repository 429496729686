import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';

import { Merchandise, merchandiseAdapter, MerchandiseState } from '../../types/merchandises.type';

const getMerchandiseState = createFeatureSelector<MerchandiseState>('merchandise');
const merchandiseDetector = createDetector<Merchandise>();

const { selectAll: getMerchandisesList, selectEntities: getMerchandisesDictionary } =
  merchandiseAdapter.getSelectors(getMerchandiseState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<MerchandiseState>(getMerchandiseState);

const isMerchandisesLoaded = createSelector(
  getMerchandisesDictionary,
  isBatchResting,
  (merchandises, batchResting) => merchandiseDetector('allLoaded')(merchandises) && batchResting
);

const isMerchandiseLoaded = (id: string): Selector<MerchandiseState, boolean> =>
  createSelector(
    getMerchandisesDictionary,
    isSingleResting,
    (entities, singleResting) => merchandiseDetector('oneLoaded')(entities, id) && singleResting
  );

const getMerchandiseById = (id: string): Selector<MerchandiseState, Merchandise> =>
  createSelector(getMerchandisesDictionary, entities => entities[id]);

const getFilter = createSelector(getMerchandiseState, state => state.filter);

const getCount = createSelector(getMerchandiseState, state => state.count);

const getFetchList = getFetchStateSelector<MerchandiseState>(getMerchandiseState);

export const merchandisesQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getMerchandisesList,
  getMerchandisesDictionary,
  isMerchandisesLoaded,
  isMerchandiseLoaded,
  getFilter,
  getCount,
  getMerchandiseById,
  getFetchList
};
