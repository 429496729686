<div class="advanced-actions-modal">
  <div mat-dialog-title>Advanced actions</div>

  <div mat-dialog-content>
    <!--    block/unblock user      -->
    <ng-container *ngIf="user.status">
      <ng-container *ngIf="hasBlockUnblockScopes; else elseSectionForBlock">
        <button
          *ngIf="user.status === 'blocked'"
          (click)="selectedAction = 'unblock'"
          [class.activated]="selectedAction === 'unblock'"
        >
          <div class="action-header">Unblock user</div>
          <div class="action-description" *ngIf="blockUserDescription$ | async as description">{{ description }}</div>
        </button>
        <button
          *ngIf="user.status !== 'blocked'"
          (click)="selectedAction = 'block'"
          [class.activated]="selectedAction === 'block'"
        >
          <div class="action-header">Block user</div>
          <div class="action-description" *ngIf="blockUserDescription$ | async as description">{{ description }}</div>
        </button>
      </ng-container>
      <ng-template #elseSectionForBlock>
        <button
          *adminIfScopesInclude="SCOPES.blockUsers; relationOperator: 'or'"
          (click)="selectedAction = 'block'"
          [class.activated]="selectedAction === 'block'"
          [disabled]="user.status === 'blocked'"
        >
          <div class="action-header" [class.disabled]="user.status === 'blocked'">Block user</div>
          <div
            class="action-description"
            [class.disabled]="user.status === 'blocked'"
            *ngIf="blockUserDescription$ | async as description"
          >
            {{ description }}
          </div>
        </button>
        <button
          *adminIfScopesInclude="SCOPES.unblockUsers; relationOperator: 'or'"
          (click)="selectedAction = 'unblock'"
          [class.activated]="selectedAction === 'unblock'"
          [disabled]="user.status !== 'blocked'"
        >
          <div class="action-header" [class.disabled]="user.status !== 'blocked'">Unblock user</div>
          <div
            class="action-description"
            [class.disabled]="user.status !== 'blocked'"
            *ngIf="blockUserDescription$ | async as description"
          >
            {{ description }}
          </div>
        </button>
      </ng-template>
    </ng-container>

    <!--    block/unblock user login      -->
    <ng-container *ngIf="hasBlockUnblockLoginScopes; else elseSectionForBlockLogin">
      <button
        *ngIf="user.loginMode === 'normal'"
        (click)="selectedAction = 'blockLogin'"
        [class.activated]="selectedAction === 'blockLogin'"
      >
        <div class="action-header">Block user login</div>
        <div class="action-description" *ngIf="blockUserLoginDescription$ | async as description">
          {{ description }}
        </div>
      </button>
      <button
        *ngIf="user.loginMode !== 'normal'"
        (click)="selectedAction = 'unblockLogin'"
        [class.activated]="selectedAction === 'unblockLogin'"
      >
        <div class="action-header">Remove login block</div>
        <div class="action-description" *ngIf="blockUserLoginDescription$ | async as description">
          {{ description }}
        </div>
      </button>
    </ng-container>
    <ng-template #elseSectionForBlockLogin>
      <button
        *adminIfScopesInclude="SCOPES.unblockLoginUsers; relationOperator: 'or'"
        (click)="selectedAction = 'unblockLogin'"
        [class.activated]="selectedAction === 'unblockLogin'"
        [disabled]="user.loginMode === 'normal'"
      >
        <div class="action-header" [class.disabled]="user.loginMode === 'normal'">Remove login block</div>
        <div
          class="action-description"
          [class.disabled]="user.loginMode === 'normal'"
          *ngIf="blockUserLoginDescription$ | async as description"
        >
          {{ description }}
        </div>
      </button>
      <button
        *adminIfScopesInclude="SCOPES.blockLoginUsers; relationOperator: 'or'"
        (click)="selectedAction = 'blockLogin'"
        [class.activated]="selectedAction === 'blockLogin'"
        [disabled]="user.loginMode !== 'normal'"
      >
        <div class="action-header" [class.disabled]="user.loginMode !== 'normal'">Block user login</div>
        <div
          class="action-description"
          [class.disabled]="user.loginMode !== 'normal'"
          *ngIf="blockUserLoginDescription$ | async as description"
        >
          {{ description }}
        </div>
      </button>
    </ng-template>

    <!--    unenroll user      -->
    <ng-container *ngIf="user.activated !== undefined">
      <button
        *adminIfScopesInclude="SCOPES.rollbackUsers; relationOperator: 'or'"
        [disabled]="!user.activated"
        (click)="selectedAction = 'unenroll'"
        [class.activated]="selectedAction === 'unenroll'"
      >
        <div class="action-header" [class.disabled]="!user.activated">Unenroll user</div>
        <div
          class="action-description"
          [class.disabled]="!user.activated"
          *ngIf="unenrollUserDescription$ | async as description"
        >
          {{ description }}
        </div>
      </button>
    </ng-container>

    <!--    logout user      -->
    <button
      *adminIfScopesInclude="SCOPES.userSession; relationOperator: 'or'"
      (click)="selectedAction = 'logout'"
      [class.activated]="selectedAction === 'logout'"
    >
      <div class="action-header">Logout user</div>
    </button>
  </div>

  <div mat-dialog-actions>
    <button class="secondary-button" (click)="dialogRef.close(true)">Cancel</button>
    <button
      [disabled]="selectedAction.length === 0"
      [ngClass]="selectedAction.length === 0 ? 'disabled-button' : 'primary-button'"
      (click)="onAccountActions()"
    >
      Confirm
    </button>
  </div>
</div>

<admin-spinner *ngIf="loading$ | async"></admin-spinner>
