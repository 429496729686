<mat-form-field class="tags-form-field slim-form-field" [class.overlay]="loading$ | async">
  <mat-label>Tags</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row
      *ngFor="let tag of tags.value"
      [class]="tag.type"
      [removable]="!tag.isDefaultTag"
      (removed)="removeFromTagsChipList(tag)"
    >
      {{ tag.displayPrefix }}: {{ tag.displayValue }}
      <mat-icon matChipRemove *ngIf="!tag.isDefaultTag">cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <input type="hidden" placeholder="New keyword..." [matChipInputFor]="chipGrid" />
  <button
    matSuffix
    mat-icon-button
    matTooltip="Add new tag to filter"
    matTooltipPosition="right"
    (click)="openTagDialog()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <mat-hint>Optional</mat-hint>
</mat-form-field>
