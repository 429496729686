<ng-container [ngSwitch]="renderState$ | async">
  <ng-container *ngSwitchCase="'loading'">
    <admin-spinner></admin-spinner>
  </ng-container>

  <ng-container *ngSwitchCase="'content'">
    <admin-main-container></admin-main-container>
  </ng-container>

  <ng-container *ngSwitchCase="'faq'">
    <admin-self-help-faq></admin-self-help-faq>
  </ng-container>
</ng-container>
