import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { FraudList } from '../../types/fraud-lists.type';

export const loadFraudLists = createAction('[Fraud Lists] Load Fraud Lists');
export const loadFraudListsSuccess = createAction(
  '[Fraud Lists] Load Fraud Lists Success',
  props<{ fraudLists: FraudList[] }>()
);
export const loadFraudListsFailure = createAction('[Fraud Lists] Load Fraud Lists Failure', props<ErrorState>());

export const loadFraudList = createAction('[Fraud Lists] Load Fraud List', props<{ id: string }>());
export const loadFraudListSuccess = createAction(
  '[Fraud Lists] Load Fraud List Success',
  props<{ fraudList: FraudList }>()
);
export const loadFraudListFailure = createAction('[Fraud Lists] Load Fraud List Failure', props<ErrorState>());

export const updateFraudList = createAction('[Fraud Lists] Update Fraud List', props<{ fraudList: FraudList }>());
export const updateFraudListSuccess = createAction(
  '[Fraud Lists] Update Fraud List Success',
  props<{ fraudList: FraudList }>()
);
export const updateFraudListFailure = createAction('[Fraud Lists] Update Fraud List Failure', props<ErrorState>());

export const createFraudList = createAction('[Fraud Lists] Create Fraud List', props<{ fraudList: FraudList }>());
export const createFraudListSuccess = createAction(
  '[Fraud Lists] Create Fraud List Success',
  props<{ fraudList: FraudList }>()
);
export const createFraudListFailure = createAction('[Fraud Lists] Create Fraud List Failure', props<ErrorState>());

export const deleteFraudList = createAction('[Fraud Lists] Delete Fraud List', props<{ id: string }>());
export const deleteFraudListSuccess = createAction('[Fraud Lists] Delete Fraud List Success', props<{ id: string }>());
export const deleteFraudListFailure = createAction('[Fraud Lists] Delete Fraud List Failure', props<ErrorState>());

export const resetFraudListCounter = createAction(
  '[Fraud Lists] Reset Fraud List Counter',
  props<{ id: string; item: string }>()
);
export const resetFraudListCounterSuccess = createAction(
  '[Fraud Lists] Reset Fraud List Counter Success',
  props<{ id: string }>()
);
export const resetFraudListCounterFailure = createAction(
  '[Fraud Lists] Reset Fraud List Counter Failure',
  props<ErrorState>()
);
