var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { version } from '../../package.json';
import { ExchangeTokenError, MissingRefreshTokenError, TimeoutError } from '../errors';
import { DecodedAccessToken, DecodedIdToken } from '../shared/decoded_token';
import { uuidv4 } from '../util';
import { generateCodeChallenge, generateCodeVerifier } from '../utils/crypto-utils';
import { Formatters } from '../utils/formatters';
import { LocalStorageQueue } from '../utils/local-storage-queue';
import { OptionsValidationUtils } from '../utils/options-validation-utils';
import { UrlParamsUtils } from '../utils/url-params-utils';
var RefreshTokenRotationHandler = /** @class */ (function () {
    function RefreshTokenRotationHandler(transactionManager, options) {
        this.transactionManager = transactionManager;
        this.options = options;
        OptionsValidationUtils.checkOptionPropertyPresent(this.options, ['authBaseUrl']);
    }
    RefreshTokenRotationHandler.prototype.login = function (connection, transaction) {
        return __awaiter(this, void 0, void 0, function () {
            var codeVerifier, codeChallenge;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        codeVerifier = generateCodeVerifier();
                        return [4 /*yield*/, generateCodeChallenge(codeVerifier)];
                    case 1:
                        codeChallenge = _a.sent();
                        this.saveStateAndCode(transaction, codeVerifier, codeChallenge);
                        window.location.assign(this.buildAuthorizeUrl(connection, transaction, codeChallenge));
                        return [2 /*return*/];
                }
            });
        });
    };
    RefreshTokenRotationHandler.prototype.handleRtrRedirect = function (transaction) {
        return __awaiter(this, void 0, void 0, function () {
            var code, body, response, tokenResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        code = UrlParamsUtils.getUrlParam('code');
                        body = {
                            grant_type: 'authorization_code',
                            client_id: this.options.appId,
                            redirect_uri: this.options.redirectUri,
                            code_verifier: transaction.code.verifier,
                            code: code,
                            hermes_version: version
                        };
                        return [4 /*yield*/, this.exchangeForToken(body)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, this.handleTokenResponse(response)];
                    case 2:
                        tokenResult = _a.sent();
                        return [2 /*return*/, __assign(__assign({}, tokenResult), { transaction: __assign({}, transaction.appState) })];
                }
            });
        });
    };
    RefreshTokenRotationHandler.prototype.refreshTokenExchange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var refreshToken, body, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        refreshToken = LocalStorageQueue.peek('refresh_token');
                        if (!(refreshToken === null || refreshToken === void 0 ? void 0 : refreshToken.token)) {
                            throw new MissingRefreshTokenError();
                        }
                        body = {
                            client_id: this.options.appId,
                            refresh_token: refreshToken.token,
                            grant_type: 'refresh_token',
                            nonce: uuidv4(),
                            hermes_version: version
                        };
                        return [4 /*yield*/, this.exchangeForToken(body)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleTokenResponse(response)];
                }
            });
        });
    };
    RefreshTokenRotationHandler.prototype.handleTokenResponse = function (response) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var tokenExchangeResult, _c, _d, errorMessage, res, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!response.ok) return [3 /*break*/, 2];
                        _d = (_c = Formatters).fromSnakeToCamelCaseKeys;
                        return [4 /*yield*/, response.json()];
                    case 1:
                        tokenExchangeResult = _d.apply(_c, [_f.sent()]);
                        LocalStorageQueue.dequeue('refresh_token');
                        LocalStorageQueue.enqueue('refresh_token', tokenExchangeResult.refreshToken);
                        return [2 /*return*/, __assign(__assign({}, tokenExchangeResult), { decodedAccessToken: new DecodedAccessToken(tokenExchangeResult.accessToken), decodedIdToken: new DecodedIdToken(tokenExchangeResult.idToken) })];
                    case 2:
                        errorMessage = void 0;
                        _f.label = 3;
                    case 3:
                        _f.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, response.json()];
                    case 4:
                        res = _f.sent();
                        errorMessage = (_b = (_a = res.errors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message;
                        return [3 /*break*/, 6];
                    case 5:
                        _e = _f.sent();
                        errorMessage = response.statusText;
                        return [3 /*break*/, 6];
                    case 6: throw new ExchangeTokenError(errorMessage);
                }
            });
        });
    };
    RefreshTokenRotationHandler.prototype.exchangeForToken = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetch(this.options.authBaseUrl + "/oauth/token", {
                                method: 'post',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify(body)
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_1 = _a.sent();
                        // throw Timeout error to enable retry mechanism
                        throw new TimeoutError();
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RefreshTokenRotationHandler.prototype.saveStateAndCode = function (transaction, codeVerifier, codeChallenge) {
        this.transactionManager.create(transaction.nonce, __assign(__assign({}, transaction), { code: {
                verifier: codeVerifier,
                challenge: codeChallenge
            } }));
    };
    RefreshTokenRotationHandler.prototype.buildAuthorizeUrl = function (connection, transaction, codeChallenge) {
        var urlParams = {
            response_type: 'code,id_token',
            redirect_uri: this.options.redirectUri,
            scope: this.options.scope,
            state: transaction.nonce,
            code_challenge: codeChallenge,
            code_challenge_method: 'S256',
            hermes_version: version,
            connection: connection
        };
        return this.options.authBaseUrl + '/authorize?' + UrlParamsUtils.buildUrlParam(urlParams);
    };
    return RefreshTokenRotationHandler;
}());
export { RefreshTokenRotationHandler };
