<div class="flex flex-col gap-6">
  <div class="review-page step-section">
    <h4 class="mb-4">General</h4>
    <h5>Basic</h5>
    <div class="item mb-4" data-testid="segment-review-name">
      <p class="item__key">Segment name</p>
      <p class="item__value">{{ data.name }}</p>
    </div>
    <div class="item" data-testid="segment-review-description">
      <p class="item__key">Description</p>
      <p class="item__value">
        {{ data.description || 'No description.' }}
      </p>
    </div>

    <h5>Members</h5>
    <div class="item" data-testid="segment-review-update-frequency">
      <p class="item__key">Update frequency of audience</p>
      <p class="item__value">{{ data.updateFrequency | customSentenceCase: 'fromSnakeCase' }} update</p>
    </div>

    <div class="rounded-md bg-cloudburst-100 p-4">
      <admin-segment-member-count [state]="segmentPreviewMemberState()" [memberCount]="segmentPreviewMemberCount()" />
    </div>
  </div>

  <div class="step-section">
    <h4>Configurations</h4>
    <admin-logic-rules-summary [attributeDataSource]="segmentAttributes()" [data]="rules" />
  </div>
</div>
