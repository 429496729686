<div *ngIf="memberships?.length > 0; else noMemberships" [class.overlay]="loading$ | async">
  <h3 class="title">Points Transfer</h3>

  <h4>Loyalty Program</h4>
  <mat-form-field *ngIf="(loyaltyPrograms$ | async)?.length > 0" class="filter-field slim-form-field">
    <mat-label>Select loyalty program</mat-label>
    <mat-select
      disableOptionCentering
      panelClass="dropdown-panel"
      [formControl]="loyaltyProgramSelection"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-option *ngFor="let loyaltyProgram of loyaltyPrograms$ | async" [value]="loyaltyProgram.id">
        {{ loyaltyProgram.name }}
      </mat-option>
    </mat-select>
    <mat-error>Required</mat-error>
    <mat-hint *ngIf="processingDelay$ | async as processingDelay"
      >Redemption time: {{ processingDelay | timeFromNow }}</mat-hint
    >
  </mat-form-field>

  <h4>Membership Account</h4>
  <form [formGroup]="validateMembershipForm" class="slim-form-field">
    <mat-form-field class="filter-field">
      <mat-label>Membership account no</mat-label>
      <input matInput formControlName="number" type="text" />
    </mat-form-field>

    <div fxLayoutGap="10px">
      <mat-form-field class="child-field">
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName" type="text" />
      </mat-form-field>

      <mat-form-field class="child-field">
        <mat-label>Last name</mat-label>
        <input matInput formControlName="lastName" type="text" />
      </mat-form-field>
    </div>

    <mat-error class="error-message" *ngIf="validateMembershipForm.getError('invalid')">
      Redemption is not available - no membership information found
    </mat-error>
  </form>

  <admin-two-way-currency-conversion
    [loyaltyProgramId]="loyaltyProgramSelection.value"
    (amountSelected)="selectedAmount = $event"
    redemptionType="transfer"
    #twoWayCurrencyConversion
  ></admin-two-way-currency-conversion>

  <button
    mat-raised-button
    color="primary"
    type="submit"
    class="uppercase-button"
    [disabled]="
      (loading$ | async) ||
      !loyaltyProgramSelection.value ||
      validateMembershipForm.getError('invalid') ||
      twoWayCurrencyConversion.conversionForm.invalid
    "
    (click)="redeem()"
  >
    Redeem
  </button>
  <admin-spinner *ngIf="loading$ | async"></admin-spinner>
</div>

<ng-template #noMemberships>
  <h4 fxFlex fxLayout="row" fxLayoutAlign="start center">
    <mat-icon [inline]="true">warning</mat-icon>
    No Loyalty Program Memberships available for redemption
  </h4>
</ng-template>
