import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  initialState,
  loyaltyProgramCatalogAdapter,
  LoyaltyProgramCatalogState
} from '../../types/loyalty-program-catalogs.type';
import {
  loadLoyaltyProgramCatalogs,
  loadLoyaltyProgramCatalogsFailure,
  loadLoyaltyProgramCatalogsSuccess
} from '../actions/loyalty-program-catalogs.actions';

export const loyaltyProgramCatalogTriggers: EntityTriggers = {
  batch: {
    loading: [loadLoyaltyProgramCatalogs.type],
    resting: [loadLoyaltyProgramCatalogsSuccess.type],
    erroring: [loadLoyaltyProgramCatalogsFailure.type]
  }
};

export function loyaltyProgramCatalogsReducer(
  state: LoyaltyProgramCatalogState = initialState,
  action: Action
): LoyaltyProgramCatalogState {
  return { ...callStateReducer(baseReducer, loyaltyProgramCatalogTriggers)(state, action) };
}

const baseReducer = createReducer(
  initialState,
  on(loadLoyaltyProgramCatalogsSuccess, (state, { loyaltyProgramCatalogs }) =>
    loyaltyProgramCatalogAdapter.setAll(loyaltyProgramCatalogs, state)
  )
);
