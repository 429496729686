import { Injectable } from '@angular/core';

// eslint-disable-next-line no-underscore-dangle
function _window(): Window {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {
  get nativeWindow(): Window {
    return _window();
  }

  openNewTab(url: string): void {
    this.nativeWindow.open(url, '_blank', 'noopener noreferrer');
  }
}
