import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, computed, Inject, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

import { SideNavV2Store } from '../side-nav-v2.store';

@Component({
  selector: 'admin-side-nav-v2-footer',
  templateUrl: './side-nav-v2-footer.component.html',
  styleUrl: './side-nav-v2-footer.component.scss'
})
export class SideNavV2FooterComponent {
  //#region Dependencies

  private sideNavStore = inject(SideNavV2Store);
  private router = inject(Router);

  //#endregion

  //#region Overlays configuration and state

  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom'
    }
  ];

  isProfileMenuOpened = signal(false);

  //#endregion

  //#region Current user details

  private currentUser = this.sideNavStore.currentUser;

  userName = computed(() => {
    const user = this.currentUser();

    return user ? (user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.name) : 'Logged in';
  });

  userProfilePicture = computed(() => {
    const user = this.currentUser();

    return user?.picture?.trim();
  });

  userProfileLink = computed(() => {
    const user = this.currentUser();

    return user ? `/agents/${user.sub}/details` : '';
  });

  isPiiUnmasked = this.sideNavStore.isPiiUnmasked;

  //#endregion

  //#region Tenant details

  private tenantIdentification = this.sideNavStore.tenantIdentification;

  tenantName = computed(() => {
    const tenant = this.tenantIdentification();

    return tenant?.name ?? '';
  });

  tenantLogo = computed(() => {
    const tenant = this.tenantIdentification();

    return tenant?.logo?.trim();
  });

  //#endregion

  //#region Side nav state

  isCollapsed = this.sideNavStore.isCollapsed;

  //#endregion

  constructor(@Inject('piiMaskingEnabled') public piiMaskingEnabled: boolean) {}

  //#region Events

  navigateToUserProfile(): void {
    this.router.navigateByUrl(this.userProfileLink());
  }

  toggleProfileMenu(): void {
    this.isProfileMenuOpened.update(isProfileMenuOpened => !isProfileMenuOpened);
  }

  stepUpForPiiDialog(event: MouseEvent): void {
    if (this.isPiiUnmasked()) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.sideNavStore.stepUpForPii();
  }

  logout(): void {
    this.sideNavStore.logout();
  }

  //#endregion
}
