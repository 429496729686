<div class="details-view">
  <div class="section-header">General</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Order ID">{{ orderItem.order.id }}</admin-item>
    <admin-item label="Item ID">{{ orderItem.id }}</admin-item>
    <admin-item label="Product type">{{ 'Travel - ' + (orderItemData.travelType | titlecase) }}</admin-item>
    <admin-item label="Order initiator">
      {{ ((hotelBookingData ?? flightBookingData)?.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A' }}
    </admin-item>

    <div class="cross-two-column unpadded-subsection-header">Customer details</div>
    <admin-item label="Customer ID">{{ customerId }}</admin-item>
    <admin-item label="Ascenda ID" [copyButtonValue]="orderItem.userId">
      <a
        *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
        [routerLink]="'/customers/' + orderItem.userId + '/dashboard'"
      >
        {{ orderItem.userId }}
      </a>
      <ng-template #userIdWithoutLink>
        {{ orderItem.userId }}
      </ng-template>
    </admin-item>
  </div>

  <ng-container *ngIf="hotelBookingData">
    <div class="section-header">Booking Summary</div>
    <div class="section admin-item-wrapper">
      <admin-item class="cross-two-column" label="Booking reference">{{
        hotelBookingData.travelConfirmationId || 'N/A'
      }}</admin-item>
      <admin-item label="First name">{{ hotelBookingData.firstName | titlecase }}</admin-item>
      <admin-item label="Last name">{{ hotelBookingData.lastName | titlecase }}</admin-item>
      <admin-item label="Destination">{{ hotelBookingData.destinationName | titlecase }}</admin-item>
      <admin-item label="Hotel">{{ hotelBookingData.hotelName | titlecase }}</admin-item>
      <admin-item label="Check-in date">{{ hotelBookingData.startTime }}</admin-item>
      <admin-item label="Check-out date">{{ hotelBookingData.endTime }}</admin-item>
      <admin-item label="Room type">{{ hotelBookingData.roomType | titlecase }}</admin-item>
      <div class="sub-section">
        <admin-item label="Number of guest(s)">{{ hotelBookingData.numberOfGuests }}</admin-item>
        <admin-item label="Number of room(s)">{{ hotelBookingData.numberOfRooms }}</admin-item>
      </div>
      <admin-item [label]="'Converted cash amount (' + (hotelBookingData.currencyCode | uppercase) + ')'">{{
        hotelBookingData.cashPaid
      }}</admin-item>
      <admin-item label="Converted cash amount (USD)">{{ hotelBookingData.cashPaidInUsd }}</admin-item>
    </div>
  </ng-container>

  <ng-container *ngIf="carBookingData">
    <div class="section-header">Booking Summary</div>
    <div class="section admin-item-wrapper">
      <admin-item class="cross-two-column" label="Booking reference">{{
        carBookingData.travelConfirmationId || 'N/A'
      }}</admin-item>
      <admin-item label="First name">{{ carBookingData.firstName | titlecase }}</admin-item>
      <admin-item label="Last name">{{ carBookingData.lastName | titlecase }}</admin-item>
      <admin-item class="cross-two-column" label="Car model">{{ carBookingData.carModel | titlecase }}</admin-item>
      <admin-item label="Pick-up location">{{ carBookingData.pickupLocation | titlecase }}</admin-item>
      <admin-item label="Pick up date & time">{{ carBookingData.startTime | date: 'yyyy-MM-dd HH:mm:ss' }}</admin-item>
      <admin-item label="Drop-off location">{{ carBookingData.returnLocation | titlecase }}</admin-item>
      <admin-item label="Drop-off date & time">{{ carBookingData.endTime | date: 'yyyy-MM-dd HH:mm:ss' }}</admin-item>
      <div class="sub-section">
        <admin-item label="Points amount paid">{{ orderItem.pointsAmount | number: '1.0-2' }}</admin-item>
        <admin-item [label]="'Cash amount paid' + ' (' + orderItemData.currencyCode + ')'">
          {{ orderItem.cashAmount | formatCurrency: orderItemData.currencyCode | async | number }}
        </admin-item>
      </div>
      <admin-item label="Payment status">{{ orderItem.status | titlecase }}</admin-item>
      <admin-item [label]="'Converted cash amount (' + (carBookingData.currencyCode | uppercase) + ')'">{{
        carBookingData.cashPaid
      }}</admin-item>
      <admin-item label="Converted cash amount (USD)">{{ carBookingData.cashPaidInUsd }}</admin-item>
    </div>
  </ng-container>

  <ng-container *ngIf="flightBookingData">
    <div class="section-header">Booking Summary</div>
    <div class="section admin-item-wrapper">
      <admin-item class="cross-two-column" label="Booking reference">{{
        flightBookingData.travelConfirmationId || 'N/A'
      }}</admin-item>
      <admin-item class="cross-two-column" label="Flight type">{{
        flightBookingData.flightType | titlecase
      }}</admin-item>
      <admin-item [label]="'Converted cash amount (' + (flightBookingData.currencyCode | uppercase) + ')'">{{
        flightBookingData.cashPaid
      }}</admin-item>
      <admin-item label="Converted cash amount (USD)">{{ flightBookingData.cashPaidInUsd }}</admin-item>
    </div>

    <div class="section-header">Passengers details</div>
    <div class="section admin-item-wrapper">
      <ng-container *ngFor="let passenger of flightBookingData.passengers; let i = index">
        <div class="cross-two-column">Passenger #{{ i + 1 }}</div>
        <admin-item label="First name">{{ passenger.firstName | titlecase }}</admin-item>
        <admin-item label="Last name">{{ passenger.lastName | titlecase }}</admin-item>
      </ng-container>
    </div>

    <div class="section-header">Departure flight</div>
    <div class="section admin-item-wrapper">
      <admin-item label="Airline name">{{
        (flightBookingData.originSegments?.[0].airlineNames | arrayJoin) || 'N/A'
      }}</admin-item>
      <admin-item label="Flight number">{{
        (flightBookingData.originSegments?.[0].flightNumbers | arrayJoin) || 'N/A'
      }}</admin-item>
      <admin-item label="Departure airport">{{ flightBookingData.originSegments?.[0].fromAirport }}</admin-item>
      <admin-item label="Departure date & time">{{
        flightBookingData.originSegments?.[0].departureTime | date: 'yyyy-MM-dd HH:mm:ss'
      }}</admin-item>
      <admin-item label="Arrival airport">{{ flightBookingData.originSegments?.[0].toAirport }}</admin-item>
      <admin-item label="Arrival date & time">{{
        flightBookingData.originSegments?.[0].arrivalTime | date: 'yyyy-MM-dd HH:mm:ss'
      }}</admin-item>
    </div>

    <div class="section-header">Return flight</div>
    <div class="section admin-item-wrapper">
      <admin-item label="Airline name">{{
        (flightBookingData.returnSegments?.[0]?.airlineNames | arrayJoin) || 'N/A'
      }}</admin-item>
      <admin-item label="Flight number">{{
        (flightBookingData.returnSegments?.[0]?.flightNumbers | arrayJoin) || 'N/A'
      }}</admin-item>
      <admin-item label="Departure airport">{{
        flightBookingData.returnSegments?.[0]?.fromAirport || 'N/A'
      }}</admin-item>
      <admin-item label="Departure date & time" hint="Time shown in local airport time">
        {{ (flightBookingData.returnSegments?.[0]?.departureTime | date: 'yyyy-MM-dd HH:mm:ss') || 'N/A' }}
      </admin-item>
      <admin-item *ngIf="flightBookingData.returnSegments?.[0].departureTime" label="Arrival airport">
        {{ flightBookingData.returnSegments?.[0]?.toAirport || 'N/A' }}
      </admin-item>
      <admin-item
        *ngIf="flightBookingData.returnSegments?.[0].arrivalTime"
        label="Arrival date & time"
        hint="Time shown in local airport time"
      >
        {{ (flightBookingData.returnSegments?.[0]?.arrivalTime | date: 'yyyy-MM-dd HH:mm:ss') || 'N/A' }}
      </admin-item>
    </div>
  </ng-container>

  <ng-container *ngIf="hotelBookingData || carBookingData || flightBookingData; else na">
    <div class="section-header">Stripe reference</div>
    <div class="section admin-item-wrapper">
      <admin-item label="Stripe reference">{{ stripeReferenceData.stripeReference }}</admin-item>
      <admin-item label="Total charge">{{ stripeReferenceData.totalCharge }}</admin-item>
    </div>
  </ng-container>

  <ng-template #na> N/A </ng-template>
</div>
