import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';
import { Formatters } from '@utils';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  // for conforming safari and firefox date format
  // transform "2021-05-01 08:00:00 -04:00" to "2021/05/01 08:00:00 -04:00"
  transform(value: string): Nullable<string> {
    return Formatters.dateFormatter(value);
  }
}
