import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { FileProcessingRequest, FileProcessingRequestsFilter } from '../../types';

export const loadFileProcessingRequests = createAction(
  '[Pickup Zones] Load File Processing Requests',
  props<{ filter: FileProcessingRequestsFilter }>()
);
export const loadFileProcessingRequestsSuccess = createAction(
  '[Pickup Zones] Load File Processing Requests Success',
  props<{
    fileProcessingRequests: FileProcessingRequest[];
    filter: FileProcessingRequestsFilter;
  }>()
);
export const loadFileProcessingRequestsFailure = createAction(
  '[Pickup Zones] Load File Processing Requests Failure',
  props<ErrorState>()
);

export const updateFileProcessingRequestsFilter = createAction(
  '[Pickup Zones] Update File Processing Requests Filter',
  props<{ filter: FileProcessingRequestsFilter }>()
);
