import {
  AttributesResponse,
  LogicRule,
  LogicRuleAttribute,
  LogicRuleAttributeRecord,
  LogicRulesData,
  SingleAttributeResponse
} from '@shared/types/logic-rule.type';

// TODO: add specs later
/**
 * Flatten logic rule data into a set of attribute key
 * @param logicRuleData logic rule data can be from logic-rule component or from API response
 * @param fromCampaignAPIResponse check if from API response only applicable for logic rule data from Campaign API response
 * @returns set of attribute key
 */
export function flattenLogicRuleData(
  logicRuleData: LogicRulesData,
  fromCampaignAPIResponse: boolean = false
): Set<string> {
  const flattenAttributesAsKeys = (rules: LogicRule[], parentKey?: string, parentValue?: string): string[] => {
    if (rules.length === 0) {
      return [];
    }

    return rules.reduce((attributeKeys, rule) => {
      if (!rule) {
        return attributeKeys;
      }

      return [
        ...attributeKeys,
        parentKey
          ? `${parentKey}${fromCampaignAPIResponse ? `.${parentValue}` : ''}.${rule.attribute}`
          : rule.attribute,
        ...flattenAttributesAsKeys(rule.conditions ?? [], rule.attribute, rule.value as string)
      ];
    }, []);
  };

  return new Set(flattenAttributesAsKeys(logicRuleData.conditions));
}

export function flattenAttributes(
  parentAttr: LogicRuleAttribute,
  attributeResponse: SingleAttributeResponse,
  resultMap: Map<string, LogicRuleAttribute>
): void {
  const { nestedAttributes, isRequired, attribute: key, ...attributeDetails } = attributeResponse;
  const { attribute: parentKey } = parentAttr;
  const attributeMapKey = `${parentKey}.${key}`;

  if (key === 'time_range' && isRequired) {
    parentAttr.isCumulativeType = true;
    parentAttr.timeRangeResource = attributeDetails.resources;
  } else {
    resultMap.set(attributeMapKey, {
      ...attributeDetails,
      attribute: key,
      parentAttribute: parentKey
    });
  }

  if (nestedAttributes) {
    nestedAttributes.forEach(nestedAttr => {
      const parentAttribute = resultMap.get(attributeMapKey);
      if (parentAttribute) {
        flattenAttributes(parentAttribute, nestedAttr, resultMap);
      }
    });
  }
}

export function transformAttributeResponses(response: AttributesResponse[]): Record<string, LogicRuleAttribute> {
  const attributeMap: Map<string, LogicRuleAttribute> = new Map();

  for (const group of response) {
    const rootCategoryKey = `root.${group.category}`;
    attributeMap.set(rootCategoryKey, {
      parentAttribute: 'root',
      attribute: group.category,
      displayName: group.displayName,
      type: 'text',
      operators: [],
      resources: {
        data: null,
        dataSource: null
      }
    });

    for (const attr of group.mainAttributes) {
      const rootCategory = attributeMap.get(rootCategoryKey);
      if (rootCategory) {
        flattenAttributes(rootCategory, attr, attributeMap);
      }
    }
  }

  return Object.fromEntries(attributeMap);
}

export function getAllMainAttributeValuesFromRuleData(logicRuleData: LogicRulesData): string[] {
  // Remove duplicates
  return [...new Set((logicRuleData?.conditions ?? []).filter(Boolean).map(condition => condition.value as string))];
}

/**
 * Get dependent_select resource value with it corresponding category
 * @param attributeMap Attributes map returned by LD.
 * @returns Values and category pair. Ex: { 'raw_spend_transaction': 'card_spend' }
 */
export function getValuesAndCategoryPair(attributeMap: LogicRuleAttributeRecord): Record<string, string> {
  const valueAndCategoryPair: Record<string, string> = {};

  for (const value of Object.values(attributeMap)) {
    const { attribute, parentAttribute, type, resources } = value;

    // Since all of root attributes for Campaign are event type, we only need to check
    // if the attribute is `event_type` and type is `dependent_select` then
    // we can get its resource value as key and its parent attribute which is category as value.
    if (attribute === 'event_type' && type === 'dependent_select') {
      (resources?.data ?? []).forEach(({ value: resourceValue }) => {
        if (resourceValue) {
          valueAndCategoryPair[resourceValue as string] = parentAttribute;
        }
      });
    }
  }

  return valueAndCategoryPair;
}

/**
 * Remove the parent attribute key (first element) from the attribute key
 * @param attributeKey attribute key
 * @returns attribute key without the parent attribute key.
 * @example
 * removeParentFromLogicRuleAttributeKey('parent_value.amount') // returns 'amount'
 * removeParentFromLogicRuleAttributeKey('shared.amount') // returns 'amount'
 * removeParentFromLogicRuleAttributeKey('parent_value.custom_attributes.amount') // returns 'custom_attributes.amount'
 * removeParentFromLogicRuleAttributeKey('amount') // returns 'amount'
 */
export function removeParentFromLogicRuleAttributeKey(attributeKey: string): string {
  const attributeFullPath = attributeKey.split('.');

  if (attributeFullPath.length <= 1) {
    return attributeKey;
  }

  attributeFullPath.splice(0, 1);
  return attributeFullPath.join('.');
}

/**
 * Formats the attribute names in logic rules by removing parent prefixes for inner child logic rules.
 *
 * @param {LogicRule[]} logics - An array of logic rules to format.
 * @param {string} [parentValue] - The parent value to consider when formatting attribute names.
 * @returns {LogicRule[]} An array of formatted logic rules.
 */
export function formatRuleName(logics: LogicRule[], parentValue?: string): LogicRule[] {
  return logics.map(logic => {
    let attributeName = logic.attribute;
    if (
      parentValue /**
       * There are 3 cases, we need to support:
       * shared.amount -> amount
       * parent_value.amount -> amount
       * parent_value.custom_attributes.amount -> custom_attributes.amount
       * */ &&
      (logic.attribute.startsWith(`${parentValue}.`) || logic.attribute.startsWith('shared.'))
    ) {
      attributeName = removeParentFromLogicRuleAttributeKey(logic.attribute);
    }

    const childLogics =
      logic.conditions?.length > 0 ? formatRuleName(logic.conditions, String(logic.value)) : undefined;
    return {
      ...logic,
      attribute: attributeName,
      conditions: childLogics
    };
  });
}
