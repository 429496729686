import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SharedConfig } from '@core/types';

import { MissionControlService } from '../mission-control/mission-control.service';

@Injectable({
  providedIn: 'root'
})
export class SharedConfigService {
  constructor(private missionControlService: MissionControlService) {}

  getSharedConfig(): Observable<SharedConfig> {
    return this.missionControlService.get<SharedConfig>('tenants/shared_config');
  }
}
