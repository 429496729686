<div #topAnchor></div>

@if (levelSelectedNavGroup(); as selectedNavGroup) {
  <button class="nav-list-back-button" (click)="returnToPreviousGroup()">
    <mat-icon svgIcon="chevron-left-bold" />
    @if (!isCollapsed()) {
      {{ selectedNavGroup.label }}
    }
  </button>
}

<ng-template
  *ngTemplateOutlet="
    navList;
    context: {
      $implicit: navItems,
      isSubItem: false
    }
  "
></ng-template>

<ng-template adminNavList #navList let-navItems let-isSubItem="isSubItem">
  <ul cdkAccordion class="m-0 list-none p-0">
    @for (item of navItems; track item.id) {
      <li>
        @switch (item.type) {
          @case ('group') {
            <a
              admin-nav-v2-list-item
              role="button"
              tabindex="0"
              [navItem]="item"
              [isSubItem]="isSubItem"
              (click)="selectNavGroup(item)"
            >
              {{ item.label }}
            </a>
          }
          @case ('submenu') {
            <a
              #accordionItem="cdkAccordionItem"
              role="button"
              tabindex="0"
              admin-nav-v2-list-item
              cdkAccordionItem
              [navItem]="item"
              [isSubItem]="isSubItem"
              (click)="accordionItem.toggle()"
            >
              {{ item.label }}
            </a>

            <div [@detailExpand]="accordionItem.expanded ? 'expanded' : 'collapsed'">
              <ng-template
                *ngTemplateOutlet="
                  navList;
                  context: {
                    $implicit: item.children,
                    isSubItem: true
                  }
                "
              ></ng-template>
            </div>
          }
          @case ('externalLink') {
            @if (item.href) {
              <a
                admin-nav-v2-list-item
                [navItem]="item"
                [isSubItem]="isSubItem"
                [href]="item.href"
                [target]="item.target"
              >
                {{ item.label }}
              </a>
            } @else {
              <a admin-nav-v2-list-item [navItem]="item" [isSubItem]="isSubItem">
                {{ item.label }}
              </a>
            }
          }
          @default {
            <a
              admin-nav-v2-list-item
              [navItem]="item"
              [isSubItem]="isSubItem"
              [routerLink]="item.route"
              (click)="selectNavItem(item.id)"
            >
              @if (item.type === 'action') {
                <span
                  [matBadge]="item.badgeValue$ | async"
                  [matBadgeHidden]="!(item.badgeValue$ | async)"
                  matBadgeColor="warn"
                  matBadgeOverlap="false"
                >
                  {{ item.label }}
                </span>
              } @else {
                {{ item.label }}
              }
            </a>
          }
        }
      </li>
    }
  </ul>
</ng-template>
