import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, DecodedUser, FetchState } from '@core/types';

import { ProductType } from '../../loyalty-data/types/products-filter.type';
import { Account } from './accounts.type';
import { Card } from './cards.type';
import { Identity } from './identities.type';
import { MfaIdentity } from './mfa-identities.type';
import { Role } from './roles.type';
import { UsersFilter } from './users-filter.type';

// represents a user managed by AP
export interface User extends DecodedUser {
  id: string;
  status: UserStatus;
  address?: UserAddress;
  birthdate?: Date | string;
  email?: string;
  emailVerified: boolean;
  firstName?: string;
  gender?: string;
  lastName?: string;
  locale?: string;
  middleName?: string;
  nickname?: string;
  phoneNumber?: string;
  phoneNumberVerified: boolean;
  picture?: string;
  preferredUsername?: string;
  profile?: string;
  zoneinfo?: string;
  salutation?: string;
  loyalty_data?: object;
  loyaltyData?: object; // for settings; due to lack of formatting for nested object in MC
  custom?: Custom;
  preferences?: Preferences;
  rewardsProgram?: string;
  identities?: Identity[];
  mfaIdentities?: MfaIdentity[];
  otherPii?: OtherPii;
  uid?: string;
  accounts?: Account[];
  cards?: Card[];
  roles?: Role[];
  createdAt: string;
  updatedAt: string;
  pointsAccounts?: { id: string }[];
  products?: Product[];
  loginMode?: UserLoginMode;
  activated?: boolean;
  enrolledSuppliers?: string[];
}

export interface CreateUser {
  partnerUserId: string;
  email: string;
  phoneNumber?: string;
  status: CreateUserStatus;
  firstName: string;
  lastName: string;
  birthdate?: Date;
  gender?: 'male' | 'female' | 'non_binary';
  countryCode?: string;
}

export type CreateUserStatus = 'active' | 'blocked' | 'closed';

export interface Custom {
  [key: string]: object;
  agreements?: {
    digital_rewards_statement?: boolean;
    tenant_campaign_consent_signed_off?: boolean;
  };
}

export interface UserAddress {
  country?: string;
  formatted?: string;
  locality?: string;
  postalCode?: string;
  region?: string;
  streetAddress?: string;
}

export interface Preferences {
  currency?: string;
  loyaltyCurrency?: string;
  pointsAccountId?: string;
  productId?: string;
}

export interface Product {
  value: string;
  type: ProductType;
}

export interface OtherPiiItem {
  type: string;
  value: string;
}

export interface OtherPii {
  emails?: OtherPiiItem[];
  phones?: OtherPiiItem[];
}

export const isAdmin = (user: User): boolean => user.status === UserStatus.Admin;
export const getUserRoute = (user: User): string => (isAdmin(user) ? `/agents/${user.id}` : `/customers/${user.id}`);
export const getUsersRoute = (user: User): string => (isAdmin(user) ? '/agents' : '/customers');
export const getUserDetailsRoute = (user: User): string => getUserRoute(user) + '/details';
export const getUserName = (user: User | DecodedUser): string => {
  // tested in UserNamePipe
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.name) {
    return user.name;
  }
};

export const getUserIdentifier = (providerId: string) => (user: User) =>
  getUserName(user) || getCustomerId(user, providerId) || user.id;

export const getCustomerIdentity = (user: User, providerId: string): { uid: string; providerId: string } =>
  user.identities?.find(identity => identity.providerId === providerId) || user.identities?.[0];

export const getCustomerId = (user: User, providerId: string): string =>
  user.uid || getCustomerIdentity(user, providerId)?.uid;

export const userAdapter = createEntityAdapter<User>();

export interface UserState extends EntityState<User>, CallState, FetchState {
  count: number;
  filter: UsersFilter;
}

export const initialState: UserState = {
  ...userAdapter.getInitialState<CallState>(initialCallState),
  ...userAdapter.getInitialState<FetchState>(initialFetchState),
  count: 0,
  filter: null
};

export enum UserStatus {
  Active = 'active',
  Admin = 'admin',
  Blocked = 'blocked',
  Closed = 'closed',
  Delinquent = 'delinquent',
  Late = 'late',
  WriteOff = 'write_off'
}

export enum UserStates {
  Active = 'active',
  Admin = 'admin',
  Blacklisted = 'blacklisted',
  Blocked = 'blocked',
  Closed = 'closed',
  Delinquent = 'delinquent',
  Late = 'late',
  Pending = 'pending',
  WriteOff = 'write_off',
  TemporaryDisabled = 'temporary_disabled'
}

export enum VmaUserStatus {
  Active = 'active',
  Admin = 'admin',
  Blocked = 'blocked',
  Closed = 'closed',
  WriteOff = 'write_off'
}

export enum VmaUserStates {
  Active = 'active',
  Admin = 'admin',
  Blocked = 'blocked',
  Closed = 'closed',
  WriteOff = 'write_off',
  TemporaryDisabled = 'temporary_disabled'
}

export enum RcUserStatus {
  Admin = 'admin',
  Active = 'active',
  Blocked = 'blocked',
  Closed = 'closed'
}

export enum RcUserStates {
  Admin = 'admin',
  Active = 'active',
  Blocked = 'blocked',
  Closed = 'closed',
  TemporaryDisabled = 'temporary_disabled'
}

export enum UserLoginMode {
  Disabled = 'disabled',
  Normal = 'normal',
  'Temporary Disabled' = 'temporary_disabled'
}

export type UserStatesType = `${UserStates}`;

export interface UserStateAttributes {
  status: UserStatus;
  loginMode: UserLoginMode;
  activated: boolean;
}

export const userStatesMapping: Record<UserStates, UserStateAttributes> = {
  active: { status: UserStatus.Active, loginMode: UserLoginMode.Normal, activated: true },
  admin: { status: UserStatus.Admin, loginMode: UserLoginMode.Normal, activated: true },
  blacklisted: { status: UserStatus.Active, loginMode: UserLoginMode.Disabled, activated: true },
  blocked: { status: UserStatus.Blocked, loginMode: UserLoginMode.Normal, activated: true },
  closed: { status: UserStatus.Closed, loginMode: UserLoginMode.Normal, activated: true },
  delinquent: { status: UserStatus.Delinquent, loginMode: UserLoginMode.Normal, activated: true },
  late: { status: UserStatus.Late, loginMode: UserLoginMode.Normal, activated: true },
  write_off: { status: UserStatus.WriteOff, loginMode: UserLoginMode.Normal, activated: true },
  pending: { status: UserStatus.Active, loginMode: UserLoginMode.Normal, activated: false },
  temporary_disabled: { status: UserStatus.Active, loginMode: UserLoginMode['Temporary Disabled'], activated: true }
};

export const userStateDisplayClass = {
  active: 'status-active',
  pending: 'status-pending',
  closed: 'status-inactive'
};

export const getPartnerStates = (partnerPlatform: string): string[] => {
  switch (partnerPlatform) {
    case 'vma': {
      return Object.values(VmaUserStates);
    }
    case 'rc-us': {
      return Object.values(RcUserStates);
    }
    default: {
      return Object.values(UserStates);
    }
  }
};

export const getPartnerStatuses = (partnerPlatform: string): string[] => {
  switch (partnerPlatform) {
    case 'vma': {
      return Object.values(VmaUserStatus);
    }
    case 'rc-us': {
      return Object.values(RcUserStatus);
    }
    default: {
      return Object.values(UserStatus);
    }
  }
};

export interface UpdateUserParams {
  user: Partial<User>;
  createdRoles?: string[];
  deletedRoles?: string[];
}

export interface UpdateUsernameRequestParams {
  id: string;
  reference: string;
  username: string;
}

export interface UpdateUsernameResponseParams {
  user: User;
  contact: string;
  validity: number;
}

export interface UnenrollUserResult {
  user: User;
  deletedIdentityCount: number;
  deletedPasswordCount: number;
  deletedMfaCount: number;
}

export interface EnrollAdminRequest {
  email: string;
  firstName: string;
  lastName: string;
  roles?: string[];
}

export interface UsersFilterFormValue {
  id?: string;
  firstName?: string;
  lastName?: string;
  identityUid?: string;
  partnerUid?: string;
  email?: string;
  phoneNumber?: string;
  roleId?: string;
  status?: UserStatus;
  loginMode?: UserLoginMode;
  activated?: boolean;
  accountId?: string;
  createdAtDateRange?: { createdAtFrom?: string; createdAtTo?: string };
  updatedAtDateRange?: { updatedAtFrom?: string; updatedAtTo?: string };
}

export interface UserColumn {
  actions: string;
  createdAt: string;
  customerId: string;
  email: string;
  id: string;
  identityId: string;
  name: string;
  phoneNumber: string;
  pointsAccountColumn: string;
  roles: Role[];
  state: string;
  status: boolean;
  loginMode: boolean;
  activated: boolean;
  updatedAt: string;
}

export interface UsersPagePolicySettings {
  createdAt?: boolean;
  customerId?: boolean;
  email?: boolean;
  firstName?: boolean;
  id?: boolean;
  identities?: { uid?: boolean };
  lastName?: boolean;
  name?: boolean;
  pan?: boolean;
  phoneNumber?: boolean;
  pointsAccount?: boolean;
  roles?: boolean;
  state?: boolean;
  status?: boolean;
  loginMode?: boolean;
  activated?: boolean;
  updatedAt?: boolean;
}

export type DisplaySettings = { [key in keyof UserColumn]: boolean } & { pan: boolean; pointsAccountFilter: boolean };
