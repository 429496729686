import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, merge, of } from 'rxjs';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { ApprovalRequestsFormatterService } from '../../services/approval-requests-formatter.service';
import { ApprovalRequestsService } from '../../services/approval-requests.service';
import {
  loadApprovalRequest,
  loadApprovalRequestFailure,
  loadApprovalRequests,
  loadApprovalRequestsFailure,
  loadApprovalRequestsSuccess,
  loadApprovalRequestStatistics,
  loadApprovalRequestStatisticsFailure,
  loadApprovalRequestStatisticsSuccess,
  loadApprovalRequestSuccess,
  respondToApprovalRequest,
  respondToApprovalRequestFailure,
  respondToApprovalRequestSuccess
} from '../actions/approval-requests.actions';

@Injectable()
export class ApprovalRequestsEffects {
  loadApprovalRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApprovalRequest),
      exhaustMap(action =>
        this.approvalRequestsService.getApprovalRequest(action.id).pipe(
          exhaustMap(approvalRequest => this.approvalRequestsFormatterService.format(approvalRequest)),
          map(approvalRequest => loadApprovalRequestSuccess({ approvalRequest })),
          catchError(error => of(loadApprovalRequestFailure({ error })))
        )
      )
    )
  );

  loadApprovalRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApprovalRequests),
      exhaustMap(action =>
        this.approvalRequestsService.getApprovalRequests(action.filter).pipe(
          exhaustMap(result =>
            result.data.length > 0 ? this.approvalRequestsFormatterService.formatResult(result) : of(result)
          ),
          map(approvalRequestsResult => loadApprovalRequestsSuccess({ approvalRequestsResult })),
          catchError(error => of(loadApprovalRequestsFailure({ error })))
        )
      )
    )
  );

  respondToApprovalRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(respondToApprovalRequest),
      exhaustMap(action =>
        this.approvalRequestsService.respondToApprovalRequest(action.respondParams).pipe(
          exhaustMap(approvalRequest => this.approvalRequestsFormatterService.format(approvalRequest)),
          map(approvalRequest => respondToApprovalRequestSuccess({ approvalRequest, dialogRefId: action.dialogRefId })),
          catchError(error => of(respondToApprovalRequestFailure({ error })))
        )
      )
    )
  );

  respondToApprovalRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(respondToApprovalRequestSuccess),
      exhaustMap(({ approvalRequest, dialogRefId }) => {
        this.matDialog.getDialogById(dialogRefId)?.close(true);
        return merge([
          loadApprovalRequestStatistics(),
          routerNavigate({ path: '/approval-requests/' + approvalRequest.id })
        ]);
      })
    )
  );

  loadApprovalRequestStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApprovalRequestStatistics),
      exhaustMap(() =>
        this.approvalRequestsService.getApprovalRequestStatistics().pipe(
          map(approvalRequestStatistics => loadApprovalRequestStatisticsSuccess({ approvalRequestStatistics })),
          catchError(error => of(loadApprovalRequestStatisticsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private approvalRequestsService: ApprovalRequestsService,
    private approvalRequestsFormatterService: ApprovalRequestsFormatterService,
    private matDialog: MatDialog
  ) {}
}
