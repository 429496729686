import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, pointsAccountAdapter, PointsAccountState } from '../../types/points-accounts.type';
import {
  loadPointsAccounts,
  loadPointsAccountsFailure,
  loadPointsAccountsSuccess
} from '../actions/points-accounts.actions';

export const pointsAccountTriggers: EntityTriggers = {
  batch: {
    loading: [loadPointsAccounts.type],
    resting: [loadPointsAccountsSuccess.type],
    erroring: [loadPointsAccountsFailure.type]
  }
};

export function pointsAccountsReducer(state: PointsAccountState = initialState, action: Action): PointsAccountState {
  return callStateReducer(baseReducer, pointsAccountTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadPointsAccounts, (state, { filter }) => ({ ...state, filter })),
  on(loadPointsAccountsSuccess, (state, { result }) => ({
    ...pointsAccountAdapter.setAll(result.data, state),
    count: result?.metadata?.total || 0
  }))
);
