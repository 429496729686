<mat-card appearance="outlined" class="button-wrapper">
  <mat-card-content>
    <button mat-button class="back-button" color="primary" routerLink="../..">
      <mat-icon>keyboard_backspace</mat-icon>
      Back to orders
    </button>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-elevation-z1">
  <mat-card-content>
    <mat-card-title>Create Redemption</mat-card-title>
    <div *ngIf="!(this.isInvalidRedemptionType$ | async) && !(pointsAccountLoading$ | async)">
      <div class="panel">
        <admin-points-summary
          class="points-summary"
          customClassName="display-without-card"
          [displayPointsAdjustmentButton]="false"
          [error]="pointsAccountError$ | async"
          [loading]="pointsAccountLoading$ | async"
          [pointsSummaryData]="pointsAccountData$ | async"
        ></admin-points-summary>
        <admin-points-accounts-select
          *ngIf="showPointsAccountsSelector && !(pointsAccountError$ | async) && !(pointsAccountLoading$ | async)"
          class="points-account-selector"
          [initialSelectedId]="selectedPointsAccountId$ | async"
          [pointsAccounts]="pointsAccounts$ | async"
          (pointsAccountIdChanged)="handlePointsAccountChange($event)"
        >
        </admin-points-accounts-select>
      </div>

      <div [ngSwitch]="currentRedemptionType">
        <admin-cash-redemption-create
          *ngSwitchCase="'cashRedemption'"
          [selectedPointsAccountId]="selectedPointsAccountId$ | async"
          [pointsAccounts]="pointsAccounts$ | async"
        ></admin-cash-redemption-create>

        <admin-points-transfer-create
          *ngSwitchCase="'pointsTransfer'"
          [selectedPointsAccountId]="selectedPointsAccountId$ | async"
        ></admin-points-transfer-create>
      </div>
    </div>
    <div *ngIf="this.isInvalidRedemptionType$ | async" class="error-card">
      <mat-icon [inline]="true">warning</mat-icon>
      <h4>Invalid Redemption Type</h4>
    </div>
  </mat-card-content>
</mat-card>
