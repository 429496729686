import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { SegmentMembersService } from '../../services/segment-members.service';
import { SegmentMemberState } from '../../types';
import {
  addSegmentMember,
  addSegmentMemberFailure,
  addSegmentMemberSuccess,
  loadSegmentMembers,
  loadSegmentMembersFailure,
  loadSegmentMembersSuccess,
  updateSegmentMember,
  updateSegmentMemberFailure,
  updateSegmentMemberSuccess
} from '../actions/segment-members.actions';
import { loadSegment } from '../actions/segments.actions';
import { segmentMembersQuery } from '../selectors/segment-members.selectors';

@Injectable()
export class SegmentMembersEffects {
  loadSegmentMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSegmentMembers),
      switchMap(({ segmentId, filter }) =>
        this.segmentMembersService.getSegmentMembers(segmentId, filter).pipe(
          map(({ data, metadata }) => loadSegmentMembersSuccess({ members: data, total: metadata.total || 0 })),
          catchError(error => of(loadSegmentMembersFailure({ error })))
        )
      )
    )
  );

  addSegmentMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSegmentMember),
      switchMap(({ segmentId, userId }) =>
        this.segmentMembersService.addSegmentMember(segmentId, userId).pipe(
          map(segmentMember => addSegmentMemberSuccess({ segmentMember })),
          catchError(error => of(addSegmentMemberFailure({ error })))
        )
      )
    )
  );

  updateSegmentMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSegmentMember),
      switchMap(({ segmentId, userId, status }) =>
        this.segmentMembersService.updateSegmentMember(segmentId, userId, status).pipe(
          map(segmentMember => updateSegmentMemberSuccess({ segmentMember })),
          catchError(error => of(updateSegmentMemberFailure({ error })))
        )
      )
    )
  );

  addOrUpdateSegmentMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSegmentMemberSuccess, updateSegmentMemberSuccess),
      concatLatestFrom(() => this.store.select(segmentMembersQuery.getSegmentMembersSelectedSegmentId)),
      switchMap(([_, selectedSegmentId]) => of(loadSegment({ id: selectedSegmentId })))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<SegmentMemberState>,
    private segmentMembersService: SegmentMembersService
  ) {}
}
