import { createAction } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

export const notifyIntentToUpgrade = createAction('[Upsell Dialog] Notify Intent to Upgrade');

export const notifyIntentToUpgradeSuccess = createAction(
  '[Upsell Dialog] Notify Intent to Upgrade Success',
  (snackbarMessages?: SnackbarMessages) => ({
    snackbarMessages: snackbarMessages ?? {
      default:
        'You have successfully indicated your interest to upgrade your plan. Our account managers will get back to you within 3 business days.'
    }
  })
);

export const notifyIntentToUpgradeFailure = createAction(
  '[Upsell Dialog] Notify Intent to Upgrade Failure',
  (error: HttpError, snackbarMessages?: SnackbarMessages) => ({
    error,
    snackbarMessages: snackbarMessages ?? {
      default:
        'We could not send your interest to upgrade due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Centre.'
    }
  })
);
