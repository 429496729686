import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Notification } from '../../types/notifications.type';

export const loadNotifications = createAction('[Notifications] Load User Notifications', props<{ userId: string }>());
export const loadNotificationsSuccess = createAction(
  '[Notifications] Load User Notifications Success',
  props<{ notifications: Notification[] }>()
);
export const loadNotificationsFailure = createAction(
  '[Notifications] Load User Notifications Failure',
  props<ErrorState>()
);
