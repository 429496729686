import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';

@Component({
  selector: 'admin-points-adjustment-confirm-dialog',
  templateUrl: './points-adjustment-confirm-dialog.component.html'
})
export class PointsAdjustmentConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PointsAdjustmentConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { actionLabel: string; statementDescriptorLabel: string }
  ) {}
}
