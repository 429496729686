import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { User } from '../../users/types';

import { LoginAttemptsFilter } from './login-attempts-filter.type';

export interface LoginAttemptLocation extends LatLonData {
  city: string;
  country: string;
  countryCode: string;
}

export interface LatLonData {
  lat: number;
  lon: number;
}

export interface LatLngData {
  lat: number;
  lng: number;
}

export interface LoginAttempt {
  id: string;
  sessionId: string;
  userId: string;
  createdAt: string;
  user: User;
  providerId: string;
  uid: string;
  ssoData: object;
  location: LoginAttemptLocation;
}

export const loginAttemptAdapter = createEntityAdapter<LoginAttempt>();

export interface LoginAttemptState extends EntityState<LoginAttempt>, CallState {
  count: number;
  filter: LoginAttemptsFilter;
}

export const initialState: LoginAttemptState = {
  ...loginAttemptAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  filter: null
};
