import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Bootstrap } from '../../types/bootstraps.type';

export const loadBootstrap = createAction('[Gift Cards] Load Bootstrap', props<{ tenantId?: string }>());
export const loadBootstrapSuccess = createAction(
  '[Gift Cards] Load Bootstrap Success',
  props<{ bootstrap: Bootstrap }>()
);
export const loadBootstrapFailure = createAction('[Gift Cards] Load Bootstrap Failure', props<ErrorState>());

export const clearBootstrap = createAction('[Gift Cards] Clear Bootstrap');
