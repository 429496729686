import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { alfredSupplierAdapter, AlfredSupplierState, initialState } from '../../types';
import {
  loadAlfredSupplier,
  loadAlfredSupplierSuccess,
  loadAlfredSupplierFailure,
  loadAlfredSuppliers,
  loadAlfredSuppliersFailure,
  loadAlfredSuppliersSuccess,
  refreshAlfredSupplier,
  refreshAlfredSupplierSuccess,
  refreshAlfredSupplierFailure
} from '../actions/alfred-suppliers.actions';

export const alfredSupplierTriggers: EntityTriggers = {
  single: {
    loading: [loadAlfredSupplier.type, refreshAlfredSupplier.type],
    resting: [loadAlfredSupplierSuccess.type, refreshAlfredSupplierSuccess.type],
    erroring: [loadAlfredSupplierFailure.type, refreshAlfredSupplierFailure.type]
  },
  batch: {
    loading: [loadAlfredSuppliers.type],
    resting: [loadAlfredSuppliersSuccess.type],
    erroring: [loadAlfredSuppliersFailure.type]
  }
};

export function alfredSuppliersReducer(state: AlfredSupplierState = initialState, action: Action): AlfredSupplierState {
  return callStateReducer(baseReducer, alfredSupplierTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadAlfredSupplierSuccess, (state, { alfredSupplier }) => ({
    ...alfredSupplierAdapter.upsertOne(alfredSupplier, state)
  })),
  on(loadAlfredSuppliers, (state, { filter }) => ({ ...state, filter })),
  on(loadAlfredSuppliersSuccess, (state, { result }) => ({
    ...alfredSupplierAdapter.setAll(result.data, state)
  }))
);
