import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { AppsService } from '../../../apps/services/apps.service';
import { fetchApp } from '../../../apps/store/actions/apps.actions';
import { appsQuery } from '../../../apps/store/selectors/apps.selectors';
import { App, AppState } from '../../../apps/types/apps.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class AppTagDefs {
  readonly tagDefs: TagDefs<App> = {
    app_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showApps)
          ? combineLatest([
              this.appStore.select(appsQuery.getAppById(id)),
              this.appStore.select(appsQuery.getFetchList)
            ]).pipe(
              first(),
              map(([app, fetchList]) => {
                if (!app && !fetchList.includes(id)) {
                  this.appStore.dispatch(fetchApp({ id }));
                }
              })
            )
          : of(null),
      select: id => this.appStore.pipe(select(appsQuery.getAppById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showApps)
          ? this.appStore.pipe(
              select(appsQuery.getAppById(id)),
              first(),
              exhaustMap(app => (app ? of(app) : this.appsService.getApp(id)))
            )
          : of(null),
      getDisplayPrefix: () => 'App',
      getDisplayValue: (app: App) => app.name,
      getRouteLink: (app: App) => `/apps/${app.id}`
    }
  };

  constructor(
    private appStore: Store<AppState>,
    private appsService: AppsService,
    private scopes: Scopes
  ) {}
}
