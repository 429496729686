<ng-container [ngSwitch]="renderState$ | async">
  <mat-card appearance="outlined" *ngSwitchCase="'loading'" class="mat-elevation-z1 details-card overlay">
    <admin-spinner></admin-spinner>
  </mat-card>

  <mat-card appearance="outlined" *ngSwitchCase="'error'" fxLayout="row" fxLayoutAlign="center center" fxFlex>
    <div>
      <h4><mat-icon [inline]="true">warning</mat-icon> Loading enrollments failed</h4>
    </div>
  </mat-card>

  <ng-container *ngSwitchCase="'content'">
    <div *ngIf="(enrollments$ | async).length" class="filter">
      <mat-form-field class="slim-form-field select">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="statusControl" disableOptionCentering panelClass="dropdown-panel">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status | customTitleCase: 'fromSnakeCase' }}
          </mat-option>
        </mat-select>
        <admin-input-reset
          [initialValue]="''"
          [inputControl]="statusControl"
          [loading]="false"
          matSuffix
        ></admin-input-reset>
      </mat-form-field>
    </div>

    <ng-container *ngIf="filteredEnrollments$ | async as enrollments">
      <ng-container *ngIf="enrollments.length === 0; else enrollmentsView">
        <mat-card appearance="outlined">
          <div fxLayoutAlign="center center">
            <h4>No enrollments found</h4>
          </div>
        </mat-card>
      </ng-container>
      <ng-template #enrollmentsView>
        <mat-card appearance="outlined" *ngFor="let enrollment of enrollments">
          <mat-card-content>
            <div class="headline">
              <mat-card-title *ngIf="vmaProductCodes[enrollment.product.code]">{{
                vmaProductCodes[enrollment.product.code]
              }}</mat-card-title>
              <mat-card-title *ngIf="fabProductCodes[enrollment.product.code]">{{
                fabProductCodes[enrollment.product.code]
              }}</mat-card-title>
              <mat-card-title
                *ngIf="!fabProductCodes[enrollment.product.code] && !vmaProductCodes[enrollment.product.code]"
                >{{ enrollment.product.code }}</mat-card-title
              >
            </div>
            <div class="details-container">
              <div class="item" *ngIf="enrollment.product.code">
                <div class="item__key">
                  <h4>Account type</h4>
                </div>
                <span class="item__value">{{ enrollment.product.code }}</span>
              </div>
              <div class="item" *ngIf="enrollment.pointsAccount.status">
                <div class="item__key">
                  <h4>Status</h4>
                </div>
                <span class="item__value">{{
                  enrollment.product.partnerStatus | customTitleCase: 'fromSnakeCase'
                }}</span>
              </div>
              <div class="item" *ngIf="enrollment.product.referenceId">
                <div class="item__key">
                  <h4>Account number</h4>
                </div>
                <span class="item__value">{{ enrollment.product.referenceId }}</span>
              </div>
              <div class="item" *ngIf="enrollment.product.startTime">
                <div class="item__key">
                  <h4>Start date</h4>
                </div>
                <span class="item__value">{{ enrollment.product.startTime | date: 'yyyy-MM-dd' : timezone }}</span>
              </div>
              <div class="item" *ngIf="enrollment.product.name">
                <div class="item__key">
                  <h4>Account name</h4>
                </div>
                <span class="item__value">{{ enrollment.product.name }}</span>
              </div>
              <div class="item" *ngIf="enrollment.product.endTime">
                <div class="item__key">
                  <h4>End date</h4>
                </div>
                <span class="item__value">{{ enrollment.product.endTime | date: 'yyyy-MM-dd' : timezone }}</span>
              </div>
            </div>

            <!--    view more details      -->
            <div class="expansion-panel-wrapper">
              <div class="expansion-panel">
                <mat-accordion>
                  <mat-expansion-panel
                    class="enrollments-expansion-panel"
                    hideToggle
                    [expanded]="fabProductCodesList.includes(enrollment.product.code)"
                    (opened)="detailPanelOpenState = true"
                    (closed)="detailPanelOpenState = false"
                  >
                    <mat-expansion-panel-header class="enrollments-expansion-panel__header-wrapper">
                      <mat-panel-title>
                        <h4 class="enrollments-expansion-panel__header-title">
                          Account details<mat-icon>{{ detailPanelOpenState ? 'expand_less' : 'expand_more' }}</mat-icon>
                        </h4>
                      </mat-panel-title>
                      <div
                        *ngIf="!fabProductCodesList.includes(enrollment.product.code)"
                        class="mat-caption h8 enrollments-page-mat-caption"
                        matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
                      >
                        Created at: {{ enrollment.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }} | Updated at:
                        {{ enrollment.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
                      </div>
                    </mat-expansion-panel-header>
                    <div class="details-container enrollments-expansion-panel__details">
                      <div
                        *ngIf="fabProductCodesList.includes(enrollment.product.code)"
                        class="mat-caption enrollments-expansion-panel__date h8 enrollments-page-mat-caption"
                        matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
                      >
                        Created at: {{ enrollment.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }} | Updated at:
                        {{ enrollment.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
                      </div>
                      <ng-container *ngFor="let item of enrollment.product.customFields | keyvalue">
                        <div
                          class="item"
                          *ngIf="fieldNames[item.key] && item.value !== undefined && item.value !== null"
                        >
                          <div class="item__key">
                            <h4>{{ fieldNames[item.key] }}</h4>
                          </div>
                          <ng-container [ngSwitch]="item.key">
                            <span *ngSwitchCase="'cifPrimaryFlag'" class="item__value">
                              {{ item.value ? 'Primary owner' : 'Owner' }}
                            </span>
                            <ng-container *ngSwitchDefault [ngSwitch]="item.value">
                              <span *ngSwitchCase="true" class="item__value">Yes</span>
                              <span *ngSwitchCase="false" class="item__value">{{ item.value === 0 ? 0 : 'No' }}</span>
                              <span *ngSwitchCase="'P'" class="item__value">Main Cardholder</span>
                              <span *ngSwitchCase="'S'" class="item__value">Supplementary</span>
                              <span *ngSwitchDefault class="item__value">{{ item.value }}</span>
                            </ng-container>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>

              <!--    group enrollments info      -->
              <ng-container *ngIf="enrollment.groupedEnrollments as groupedEnrollments">
                <ng-container *ngFor="let groupedEnrollment of groupedEnrollments; let i = index">
                  <div class="expansion-panel">
                    <mat-accordion>
                      <mat-expansion-panel
                        class="enrollments-expansion-panel"
                        hideToggle
                        (opened)="jointOwnersPanelOpenState[i] = true"
                        (closed)="jointOwnersPanelOpenState[i] = false"
                      >
                        <mat-expansion-panel-header class="enrollments-expansion-panel__header-wrapper">
                          <mat-panel-title>
                            <h4 class="enrollments-expansion-panel__header-title">
                              Joint {{ groupedEnrollment.groupRole }} {{ i + 1
                              }}<mat-icon>{{ jointOwnersPanelOpenState[i] ? 'expand_less' : 'expand_more' }}</mat-icon>
                            </h4>
                          </mat-panel-title>
                          <div
                            class="mat-caption h8 enrollments-page-mat-caption"
                            matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
                          >
                            Created at: {{ groupedEnrollment.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }} |
                            Updated at: {{ groupedEnrollment.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
                          </div>
                        </mat-expansion-panel-header>
                        <div class="mat-caption enrollments-expansion-panel__details-link">
                          <span class="h8">Customer id: {{ groupedEnrollment.userId }}</span>
                          <a class="mat-primary" [routerLink]="['/customers', groupedEnrollment.userId, 'enrollments']"
                            >View enrollment details <mat-icon class="launch-icon mat-primary">launch</mat-icon></a
                          >
                        </div>
                        <div class="details-container enrollments-expansion-panel__details">
                          <ng-container *ngFor="let item of groupedEnrollment.metadata | keyvalue">
                            <div
                              class="item"
                              *ngIf="fieldNames[item.key] && item.value !== undefined && item.value !== null"
                            >
                              <div class="item__key">
                                <h4>{{ fieldNames[item.key] }}</h4>
                              </div>
                              <ng-container [ngSwitch]="item.value">
                                <span *ngSwitchCase="true" class="item__value">Yes</span>
                                <span *ngSwitchCase="false" class="item__value">No</span>
                                <span *ngSwitchDefault class="item__value">{{ item.value }}</span>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
