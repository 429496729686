<div class="main-container details-main-container">
  @if (loading$ | async) {
    <div class="progress-loader">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  }

  <table
    id="transactions-table"
    mat-table
    adminStickyHeaderTable
    multiTemplateDataRows
    [dataSource]="transactions"
    [class.overlay]="loading$ | async"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
      <td mat-cell *matCellDef="let transaction">{{ transaction.id }}</td>
    </ng-container>

    <ng-container matColumnDef="transaction type">
      <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
      <td mat-cell *matCellDef="let transaction">
        {{ transaction.transactionType | titlecase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="card last 4">
      <th mat-header-cell *matHeaderCellDef>Card Last 4</th>
      <td mat-cell *matCellDef="let transaction">
        {{ transaction.cardLast4 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="merchant name">
      <th mat-header-cell *matHeaderCellDef>Merchant Name</th>
      <td mat-cell *matCellDef="let transaction">
        {{ transaction.merchantDescriptor }}
      </td>
    </ng-container>

    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef>Currency</th>
      <td mat-cell *matCellDef="let transaction">
        {{ transaction.currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let transaction">
        {{ transaction.amount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let transaction">
        <button
          mat-flat-button
          disabled
          class="status-button-in-table"
          [ngClass]="transactionStatusDisplayClass[transaction.status] || 'status-neutral'"
        >
          <span class="dot"></span>{{ transaction.status | titlecase }}
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="posting date">
      <th mat-header-cell *matHeaderCellDef>Posting Date</th>
      <td mat-cell *matCellDef="let transaction" matTooltip="timezone: UTC + {{ timezone | timezoneDisplay }}">
        {{ transaction.postingDate | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created at">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let transaction" matTooltip="timezone: UTC + {{ timezone | timezoneDisplay }}">
        {{ transaction.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <admin-empty-state-table></admin-empty-state-table>
      </td>
    </tr>
  </table>

  <mat-paginator
    tableId="transactions-table"
    [disabled]="loading$ | async"
    [pageSizeOptions]="[10, 20]"
    [pageIndex]="filter.page - 1"
    [pageSize]="filter.limit"
    [length]="totalCount"
    (page)="onPage($event)"
    adminPaginator
  ></mat-paginator>
</div>
