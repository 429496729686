import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { Role } from '../../../users/types';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class RoleTagDefs {
  readonly tagDefs: TagDefs<Role> = {
    role_id: {
      getDisplayPrefix: () => 'Role',
      getDisplayValue: (tag: Tag) => tag.id,
      getRouteLink: (tags: Tag[]) => {
        if (!this.scopes.hasAny(SCOPES_OR.showRoles)) {
          return null;
        }

        const roleTag = tags.find(tag => tag.type === 'role_id');
        return roleTag ? `/roles/${roleTag.id}` : null;
      }
    }
  };

  constructor(private scopes: Scopes) {}
}
