import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { UAParser } from 'ua-parser-js';

import { ErrorActionTypes } from '@core/store/errors';
import { StickyHeaderTableDirective } from '@shared/directives/sticky-header-table.directive';
import { SharedModule } from '@shared/shared.module';

import { AuditLogsRoutes } from './audit-logs.routes';
import { AuditLogDetailsDialogComponent } from './components/audit-log-details-dialog/audit-log-details-dialog.component';
import { AuditLogDetailsComponent } from './components/audit-log-details/audit-log-details.component';
import { AuditLogsCardV2Component } from './components/audit-logs-card-v2/audit-logs-card-v2.component';
import { AuditLogsCardComponent } from './components/audit-logs-card/audit-logs-card.component';
import { AuditLogsContentV2Component } from './components/audit-logs-content-v2/audit-logs-content-v2.component';
import { AuditLogsContentComponent } from './components/audit-logs-content/audit-logs-content.component';
import { auditLogTriggers } from './store/reducers/audit-logs.reducer';
import { AuditLogsV2Component } from './views/audit-logs-v2/audit-logs-v2.component';
import { AuditLogsComponent } from './views/audit-logs/audit-logs.component';

@NgModule({
  declarations: [
    AuditLogsComponent,
    AuditLogsCardComponent,
    AuditLogsContentComponent,
    AuditLogsV2Component,
    AuditLogsCardV2Component,
    AuditLogsContentV2Component,
    AuditLogDetailsComponent,
    AuditLogDetailsDialogComponent
  ],
  imports: [AuditLogsRoutes, SharedModule, GoogleMapsModule, StickyHeaderTableDirective],
  providers: [
    {
      provide: UAParser,
      useValue: new UAParser()
    }
  ],
  exports: [AuditLogsCardComponent, AuditLogsCardV2Component]
})
export class AuditLogsModule {
  constructor(private errorActionTypes: ErrorActionTypes) {
    this.errorActionTypes.registerActions([...auditLogTriggers.batch.erroring]);
  }
}
