<mat-card appearance="outlined" class="mat-elevation-z1 mat-card">
  <mat-card-content>
    <mat-card-title>User Tokens</mat-card-title>
    <form [formGroup]="filterForm">
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="15px" class="slim-form-field">
        <mat-form-field class="slim-form-field">
          <mat-label>
            <mat-icon>remove_red_eye</mat-icon>
            Type
          </mat-label>
          <mat-select
            (ngModelChange)="onTypeChange()"
            formControlName="type"
            disableOptionCentering
            panelClass="dropdown-panel"
          >
            <mat-option *ngFor="let type of typeList | keyvalue" [value]="type.value">
              {{ type.key }}
            </mat-option>
          </mat-select>
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.type"
            [loading]="userOrTokensLoading$ | async"
          ></admin-input-reset>
        </mat-form-field>
        <mat-form-field class="session-id-form-field slim-form-field">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search</mat-label>
          <input matInput aria-label="Session Id" type="search" placeholder="Session Id" formControlName="sessionId" />
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.sessionId"
            [loading]="userOrTokensLoading$ | async"
            [initialValue]="''"
          ></admin-input-reset>
        </mat-form-field>
        <mat-slide-toggle (change)="onInvalidatedChange()" formControlName="invalidated">
          Invalidated
        </mat-slide-toggle>
      </div>
    </form>
    <div fxLayout="column" fxLayoutGap="20px">
      <div *ngIf="userOrTokensLoading$ | async" class="progress-loader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div [class.overlay]="userOrTokensLoading$ | async" class="data-table-container w-full">
        <table
          mat-table
          [dataSource]="userTokens$ | async"
          class="mat-elevation-z1 data-table"
          matSort
          multiTemplateDataRows
        >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let token">{{ token.id }}</td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let token">{{ token.type }}</td>
          </ng-container>

          <ng-container matColumnDef="issued_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued At</th>
            <td mat-cell *matCellDef="let token">{{ token.issuedAt }}</td>
          </ng-container>

          <ng-container matColumnDef="expires_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Expires At</th>
            <td mat-cell *matCellDef="let token">{{ token.expiresAt }}</td>
          </ng-container>

          <ng-container matColumnDef="invalidated">
            <th mat-header-cell *matHeaderCellDef>Invalidated</th>
            <td mat-cell *matCellDef="let token">{{ token.invalidated }}</td>
          </ng-container>

          <ng-container matColumnDef="invalidated_reason">
            <th mat-header-cell *matHeaderCellDef>Invalidated Reason</th>
            <td mat-cell *matCellDef="let token">{{ token.invalidatedReason }}</td>
          </ng-container>

          <ng-container matColumnDef="invalidated_at">
            <th mat-header-cell *matHeaderCellDef>Invalidated At</th>
            <td mat-cell *matCellDef="let token">{{ token.invalidatedAt }}</td>
          </ng-container>

          <ng-container matColumnDef="invalidate">
            <th mat-header-cell *matHeaderCellDef>Invalidate</th>
            <td mat-cell *matCellDef="let token">
              <button
                mat-raised-button
                color="primary"
                class="uppercase-button"
                (click)="openInvalidateDialog(token); $event.stopPropagation()"
                [disabled]="token.invalidated"
              >
                Invalidate Token
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="detail_expand">
            <td mat-cell *matCellDef="let token" [attr.colspan]="displayedColumns.length">
              <div class="expanded-detail" [@detail_expand]="token === expandedTokenColumns ? 'expanded' : 'collapsed'">
                <h4>Device Info:</h4>
                <pre>{{ token.deviceInfo | json }}</pre>
                <h4>JWT:</h4>
                {{ token.jwt || 'Not available' }}
                <ng-container *ngIf="token.jwt as jwt">
                  <pre *ngFor="let part of decodeJwt(jwt); trackBy: jwtTrackBy">{{ part | json }}</pre>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <ng-container *ngIf="!(userOrTokensLoading$ | async)">
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="element-row"
              [class.expanded-row]="expandedTokenColumns === row"
              (click)="expandedTokenColumns = expandedTokenColumns === row ? null : row"
            ></tr>

            <tr mat-row *matRowDef="let row; columns: ['detail_expand']" class="detail-row"></tr>
          </ng-container>
        </table>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50]"
          [pageIndex]="filter.page - 1"
          [pageSize]="filter.limit"
          [length]="userTokensTotalCount$ | async"
          (page)="onPage($event)"
        >
        </mat-paginator>
        <div class="progress-loader table-loading-container" *ngIf="userOrTokensLoading$ | async">Loading....</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
