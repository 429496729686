import { HttpParams } from '@angular/common/http';
import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { AttributesResponse } from '@shared/types/logic-rule.type';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { SegmentsService } from '../../segments/services/segments.service';
import { SegmentsFilter, SegmentsResponse } from '../../segments/types';
import {
  TenantCampaign,
  TenantCampaignCreateUpdateRequest,
  TenantCampaignFundingType,
  TenantCampaignsFilter,
  TenantCampaignsResponse,
  TenantCampaignType
} from '../types';

@Injectable({
  providedIn: 'root'
})
export class TenantCampaignsService {
  private missionControlService = inject(MissionControlService);
  private segmentsService = inject(SegmentsService);
  private store = inject(Store);

  readonly loadedScopes$ = this.store.select(authQuery.getScopes).pipe(
    filter(scopes => scopes.length > 0),
    take(1)
  );

  readonly allowedCampaignTypes: Signal<TenantCampaignType[]> = toSignal(
    this.loadedScopes$.pipe(
      map(scopes => {
        const result: TenantCampaignType[] = ['accrual'];
        if (scopes.includes('manage_tenant_campaigns_gift_cards')) {
          result.push('gift_card');
        }
        if (scopes.includes('manage_tenant_campaigns_communications')) {
          result.push('comms');
        }
        return result;
      })
    )
  );

  readonly enabledFundingDistribution: Signal<boolean> = toSignal(
    this.loadedScopes$.pipe(map(scopes => scopes.includes('manage_tenant_campaigns_funding')))
  );

  readonly enabledCommunicationSettings: Signal<boolean> = toSignal(
    this.loadedScopes$.pipe(map(scopes => scopes.includes('manage_tenant_campaigns_communications')))
  );

  readonly fundingOptions: { label: string; value: TenantCampaignFundingType }[] = [
    { label: 'FI will fully fund the campaign', value: 'fi_only' },
    { label: 'Shared funding between both parties', value: 'fi_and_supplier' },
    { label: 'Supplier will fully fund the campaign', value: 'supplier_only' }
  ];

  getTenantCampaign(tenantCampaignId: string): Observable<TenantCampaign> {
    return this.missionControlService.get<TenantCampaign>(`tenant_campaigns/${tenantCampaignId}`);
  }

  getTenantCampaigns(tenantCampaignsFilter: TenantCampaignsFilter): Observable<TenantCampaignsResponse> {
    const { sortBy, sortDirection, campaignTypes, statuses, ...requestParams } = tenantCampaignsFilter;
    const sanitizedParams = ObjectUtils.sanitizeRequestObject({
      ...requestParams,
      campaignType: campaignTypes,
      status: statuses
    });
    const preparedRequestParams = ObjectUtils.prepareQueryObject(sanitizedParams);
    const filterParams = FilterUtils.appendHashQueryParam(preparedRequestParams, 'sortBy', sortBy, sortDirection);

    return this.missionControlService.get<TenantCampaignsResponse>(
      'tenant_campaigns',
      new HttpParams({ fromObject: { ...filterParams }, encoder: new BracketParamsEncoder() })
    );
  }

  createTenantCampaign(tenantCampaign: TenantCampaignCreateUpdateRequest): Observable<TenantCampaign> {
    return this.missionControlService.post<TenantCampaign>(
      'tenant_campaigns',
      ObjectUtils.sanitizeRequestObject(tenantCampaign)
    );
  }

  updateTenantCampaign(tenantCampaign: TenantCampaignCreateUpdateRequest): Observable<TenantCampaign> {
    return this.missionControlService.patch<TenantCampaign>(
      `tenant_campaigns/${tenantCampaign.id}`,
      ObjectUtils.sanitizeRequestObject(tenantCampaign, {
        ignoredKeys: ['pointsActivityDescriptor', 'endTime']
      })
    );
  }

  deleteTenantCampaign(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`tenant_campaigns/${id}`);
  }

  activateTenantCampaign(tenantCampaignId: string): Observable<void> {
    return this.missionControlService.post<void>(`tenant_campaigns/${tenantCampaignId}/activate`);
  }

  duplicateTenantCampaign(tenantCampaignId: string): Observable<TenantCampaign> {
    return this.missionControlService.post<TenantCampaign>(`tenant_campaigns/${tenantCampaignId}/duplicate`);
  }

  getAttributes(campaignType: TenantCampaignType = 'accrual'): Observable<AttributesResponse[]> {
    return this.missionControlService.get<AttributesResponse[]>(
      'tenant_campaigns/attributes',
      new HttpParams({
        fromObject: {
          ...(campaignType === 'comms' && { get_qualification_triggers: true })
        },
        encoder: new BracketParamsEncoder()
      })
    );
  }

  getSegments(): Observable<SegmentsResponse> {
    const segmentFilterBase: SegmentsFilter = {
      limit: 250,
      page: 1,
      sortBy: null,
      name: null,
      sortDirection: null,
      id: null,
      description: null,
      status: null
    };

    return this.segmentsService.getSegments(segmentFilterBase);
  }

  acceptTenantCampaignConsent(): Observable<void> {
    return this.missionControlService.patch<void>('tenant_campaigns/accept_consent');
  }
}
