import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Ability } from '../../types/abilities.type';

export const loadAbilities = createAction('[Abilities] Load Abilities');
export const loadAbilitiesSuccess = createAction(
  '[Abilities] Load Abilities Success',
  props<{ abilities: Ability[] }>()
);
export const loadAbilitiesFailure = createAction('[Abilities] Load Abilities Failure', props<ErrorState>());

export const loadAbility = createAction('[Abilities] Load Ability', props<{ id: string }>());
export const loadAbilitySuccess = createAction('[Abilities] Load Ability Success', props<{ ability: Ability }>());
export const loadAbilityFailure = createAction('[Abilities] Load Ability Failure', props<ErrorState>());

export const createAbility = createAction('[Abilities] Create Ability', props<{ ability: Ability }>());
export const createAbilitySuccess = createAction('[Abilities] Create Ability Success', props<{ ability: Ability }>());
export const createAbilityFailure = createAction('[Abilities] Create Ability Failure', props<ErrorState>());

export const updateAbility = createAction('[Abilities] Update Ability', props<{ ability: Ability }>());
export const updateAbilitySuccess = createAction('[Abilities] Update Ability Success', props<{ ability: Ability }>());
export const updateAbilityFailure = createAction('[Abilities] Update Ability Failure', props<ErrorState>());

export const deleteAbility = createAction('[Abilities] Delete Ability', props<{ id: string }>());
export const deleteAbilitySuccess = createAction('[Abilities] Delete Ability Success');
export const deleteAbilityFailure = createAction('[Abilities] Delete Ability Failure', props<ErrorState>());
