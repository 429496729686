import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

interface ProductConfig {
  ascendaMargin: number;
  clientMargin: number;
  pointsRoundTo: number;
  pointsRoundDirection: 'up' | 'down';
}

export type TravelType = 'cars' | 'flights' | 'hotels';
export type TravelConfig = CarsConfig | FlightsConfig | HotelsConfig;
export type TravelConfigUpdateParams = Partial<TravelConfig> & {
  currency?: string;
};

export interface CarsConfig {
  id: string;
  cppCars: number;
  carsConfig: ProductConfig;
  currency: string;
}

export interface FlightsConfig {
  id: string;
  cppFlights: {
    first: number;
    economy: number;
    business: number;
    premiumEconomy: number;
  };
  flightsConfig: ProductConfig;
  currency: string;
}

export interface HotelsConfig {
  id: string;
  cpp: number;
  hotelsConfig: ProductConfig;
  currency: string;
}

export interface TravelConfigState extends CallState {
  cars: CarsConfig;
  flights: FlightsConfig;
  hotels: HotelsConfig;
}

export const initialTravelConfigState: TravelConfigState = {
  ...initialCallState,
  cars: null,
  flights: null,
  hotels: null
};

export interface PopularDestination {
  cityId: string;
  name: string;
  description: string;
}

export interface PopularDestinationState extends CallState {
  popularDestinations: PopularDestination[];
}

export const initialPopularDestinationState = {
  ...initialCallState,
  popularDestinations: []
};
