import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { LoginAttempt } from '../../../login-attempts/types/login-attempts.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class LoginAttemptTagDefs {
  readonly tagDefs: TagDefs<LoginAttempt> = {
    login_attempt_id: {
      getDisplayPrefix: () => 'Login Attempt',
      getDisplayValue: (tag: Tag) => tag.id,
      getRouteLink: (tags: Tag[]) => {
        if (!this.scopes.hasAny(SCOPES_OR.showLoginAttempts)) {
          return null;
        }

        const loginAttemptTag = tags.find(tag => tag.type === 'login_attempt_id');
        return loginAttemptTag ? `/login-attempts/${loginAttemptTag.id}` : null;
      }
    }
  };

  constructor(private scopes: Scopes) {}
}
