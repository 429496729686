import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialOrderItemState, orderItemAdapter, OrderItemState } from '../../types';
import {
  loadOrderItem,
  loadOrderItemFailure,
  loadOrderItems,
  loadOrderItemsFailure,
  loadOrderItemsSuccess,
  loadOrderItemSuccess
} from '../actions/order-items.actions';

export const orderItemTriggers: EntityTriggers = {
  single: {
    loading: [loadOrderItem.type],
    resting: [loadOrderItemSuccess.type],
    erroring: [loadOrderItemFailure.type]
  },
  batch: {
    loading: [loadOrderItems.type],
    resting: [loadOrderItemsSuccess.type],
    erroring: [loadOrderItemsFailure.type]
  }
};

export function orderItemsReducer(state: OrderItemState = initialOrderItemState, action: Action): OrderItemState {
  return callStateReducer(baseReducer, orderItemTriggers)(state, action);
}

const baseReducer = createReducer(
  initialOrderItemState,
  on(loadOrderItemSuccess, (state, { orderItem }) => orderItemAdapter.upsertOne(orderItem, state)),
  on(loadOrderItem, (state, { filter }) => ({ ...state, filter: filter ?? state.filter })),
  on(loadOrderItems, (state, { filter }) => ({ ...state, filter })),
  on(loadOrderItemsSuccess, (state, { result }) => ({
    ...orderItemAdapter.setAll(result.data, state),
    count: result?.meta?.count || 0
  }))
);
