import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { KeysFilter } from '../../types/keys-filter.type';
import { Key, NewKey, PrivateKey } from '../../types/keys.type';

export const loadKeys = createAction('[Keys] Load Keys', props<{ filter: KeysFilter; isTopLevelView?: boolean }>());
export const loadKeysSuccess = createAction('[Keys] Load Keys Success', props<{ keys: Key[] }>());
export const loadKeysFailure = createAction('[Keys] Load Keys Failure', props<ErrorState>());

export const loadKey = createAction('[Keys] Load Key', props<{ id: string }>());
export const loadKeySuccess = createAction('[Keys] Load Key Success', props<{ key: Key }>());
export const loadKeyFailure = createAction('[Keys] Load Key Failure', props<ErrorState>());

export const loadPrivateKey = createAction('[Keys] Load Private Key', props<{ keyId: string }>());
export const loadPrivateKeySuccess = createAction(
  '[Keys] Load Private Key Success',
  props<{ privateKey: PrivateKey; keyId: string }>()
);
export const loadPrivateKeyFailure = createAction('[Keys] Load Private Key Failure', props<ErrorState>());

export const createKey = createAction('[Keys] Create Key', props<{ newKey: NewKey }>());
export const createKeySuccess = createAction('[Keys] Create Key Success', props<{ key: Key }>());
export const createKeyFailure = createAction('[Keys] Create Key Failure', props<ErrorState>());
