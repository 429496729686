import { Location } from '@angular/common';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { filter, Observable, tap } from 'rxjs';

import { GuardhouseErrorCodes } from '@core/errors/error-codes.type';
import { NavService } from '@core/services/nav/nav.service';
import { PiiAccess } from '@core/services/pii-access/pii-access.service';
import { logoutUser } from '@core/store/auth/actions/auth.actions';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { NavGroup, NavItemV2 } from '@core/types/nav-item-v2.type';

import { partnerConfigsQuery } from '../../../partner-configs/store/selectors/partner-configs.selectors';
import { StepUpDialogComponent } from '../step-up-dialog/step-up-dialog.component';
import { SideNavV2HelperService } from './side-nav-v2-helper.service';

interface SideNavV2State {
  selectedGroups: NavGroup[];
  activatedRoutePath: string[];
  isCollapsed: boolean;
  // Whether the current selected group is scrolling
  // we must keep scroll position when switching between groups
  // so that we can restore the scroll position when returning to the group
  selectedGroupsScrolling: boolean[];
}

@Injectable()
export class SideNavV2Store extends ComponentStore<SideNavV2State> {
  //#region Dependencies

  private store = inject(Store);
  private router = inject(Router);
  private location = inject(Location);
  private matDialog = inject(MatDialog);
  private navService = inject(NavService);
  private piiAccess = inject(PiiAccess);
  private sideNavV2Helper = inject(SideNavV2HelperService);

  //#endregion

  //#region Global State

  readonly currentUser = this.store.selectSignal(authQuery.getUser);

  readonly isLoggedIn = this.store.selectSignal(authQuery.getIsLoggedIn);

  readonly tenantIdentification = this.store.selectSignal(partnerConfigsQuery.getTenantIdentification);

  readonly isPiiUnmasked = computed(() => {
    return this.piiAccess.isUnmasked();
  });

  readonly userAcrLevel = computed(() => {
    const user = this.currentUser();

    return user?.acr ?? 1;
  });

  //#endregion

  constructor() {
    super({
      selectedGroups: [],
      activatedRoutePath: [],
      isCollapsed: false,
      // We always have at least one group which is the root group
      // so selectedGroupsScrolling will always have at least one boolean value
      selectedGroupsScrolling: [false]
    });
  }

  //#region Selectors

  readonly selectedGroups = this.selectSignal(state => state.selectedGroups);

  private selectedGroupsScrolling = this.selectSignal(state => state.selectedGroupsScrolling);

  readonly getSelectedGroupByLevel = (level: number): Signal<NavGroup> =>
    this.selectSignal(this.selectedGroups, selectedGroups => selectedGroups[level]);

  readonly isCollapsed = this.selectSignal(state => state.isCollapsed);

  // Need to expose isCollapsed as an observable as well
  // so we can react to it changes cause effects() is still under developer preview
  readonly isCollapsed$ = this.select(state => state.isCollapsed);

  readonly activatedRoutePath$ = this.select(state => state.activatedRoutePath);

  readonly isCurrentSelectedGroupScrolling = computed(() => {
    const selectedGroupsScrolling = this.selectedGroupsScrolling();
    const selectedGroups = this.selectedGroups();

    // The root group is always present and never destroyed
    // selectedGroupsScrolling array always has one more element than selectedGroups
    // This extra element represents the scrolling status of the current deepest selected group
    // selectedGroups.length gives us the index of the current group's scrolling status in selectedGroupsScrolling
    // When selectedGroups is empty (length 0), we're at the root group level
    return selectedGroupsScrolling[selectedGroups.length];
  });

  //#endregion

  //#region State Updaters

  private readonly toggleIsCollapse = this.updater(state => ({
    ...state,
    isCollapsed: !state.isCollapsed
  }));

  private readonly pushSelectedGroup = this.updater((state, group: NavGroup) => ({
    ...state,
    selectedGroups: [...state.selectedGroups, group],
    selectedGroupsScrolling: [...state.selectedGroupsScrolling, false]
  }));

  private readonly setSelectedGroups = this.updater((state, groups: NavGroup[]) => ({
    ...state,
    selectedGroups: groups,
    // We must keep the scrolling status of the root group
    // then append the scrolling statuses for the new groups
    selectedGroupsScrolling: [state.selectedGroupsScrolling[0], ...groups.map(() => false)]
  }));

  private readonly setActivatedRoutePath = this.updater((state, path: string[]) => ({
    ...state,
    activatedRoutePath: path
  }));

  //#endregion

  //#region Events

  readonly updateBodyScrolling = this.updater(
    (state, { isScrolling, groupLevel }: { isScrolling: boolean; groupLevel: number | null }) => ({
      ...state,
      selectedGroupsScrolling: state.selectedGroupsScrolling.map((groupScrolling, index) =>
        index === groupLevel ? isScrolling : groupScrolling
      )
    })
  );

  readonly closeSideNav = this.updater(state => ({
    ...state,
    isCollapsed: true
  }));

  readonly openSideNav = this.updater(state => ({
    ...state,
    isCollapsed: false
  }));

  readonly popSelectedGroup = this.updater(state => ({
    ...state,
    selectedGroups: state.selectedGroups.slice(0, -1),
    selectedGroupsScrolling: state.selectedGroupsScrolling.slice(0, -1)
  }));

  readonly selectNavGroup = this.effect((group$: Observable<NavGroup>) =>
    group$.pipe(
      filter(navGroup => this.selectedGroups().every(group => group.id !== navGroup.id)),
      tap((navGroup: NavGroup) => this.pushSelectedGroup(navGroup))
    )
  );

  readonly selectNavItem = this.effect((id$: Observable<string>) =>
    id$.pipe(
      tap(id => {
        const activatedRoutePath = this.sideNavV2Helper.findActivatedRoutePath(
          (navItem: NavItemV2) => navItem.id === id
        );

        this.updateActivatedRoute(activatedRoutePath);
      })
    )
  );

  readonly updateActivatedRouteOnRouteChanged = this.effect((routeData$: Observable<boolean>) =>
    routeData$.pipe(
      tap(() => {
        const activatedRoutePath = this.sideNavV2Helper.findActivatedRoutePath(this.isActiveByRoute.bind(this));

        this.updateActivatedRoute(activatedRoutePath);
      })
    )
  );

  readonly stepUpForPii = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      filter(() => this.userAcrLevel() <= 1),
      tap(() => this.matDialog.open(StepUpDialogComponent, { data: { messageKey: 'step-up' } }))
    )
  );

  readonly logout = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(tap(() => this.store.dispatch(logoutUser({ reason: GuardhouseErrorCodes.INTENTIONAL_LOGOUT }))))
  );

  readonly toggleSideNav = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      tap(() => {
        this.toggleIsCollapse();

        // TODO: once we migrate all to side nav v2, we can remove this
        // Must sync this with the nav service
        // so that side nav v2 can work well between v1 and v2
        // mostly for admin-footer
        this.navService.toggleIsMini();
      })
    )
  );

  //#endregion

  //#region Helper Methods

  private updateActivatedRoute(activatedRoutePath: NavItemV2[]): void {
    // We must set selected groups first so that the animation can be triggered
    this.setSelectedGroups(activatedRoutePath.filter((item): item is NavGroup => item.type === 'group'));

    // Set activated route path so that nav items can base on this to show active state
    this.setActivatedRoutePath(activatedRoutePath.map(item => item.id));
  }

  private isActiveByRoute(navItem: NavItemV2): boolean {
    const option: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };

    // For group or submenu item, it can't be active
    // - Cause it doesn't have a route
    // For external link, we don't need to check if it's active
    // - Cause it will navigate to a external page
    if (navItem.type === 'group' || navItem.type === 'submenu' || navItem.type === 'externalLink') {
      return false;
    }

    const { route, rootRoutes } = navItem;

    // We can consider a route active if:
    // 1. The item route is root path ('') and current location is root path ('')
    // 2. The item route is the current location and not a parent route
    // 3. The item has parent routes defined and current location is one of the parent routes
    return (
      (route === '' && this.location.path() === '') ||
      (route !== '' && this.router.isActive(route, option)) ||
      (rootRoutes && rootRoutes.some(rootRoute => this.router.isActive(rootRoute, option)))
    );
  }

  //#endregion
}
