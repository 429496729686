import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { CampaignsService } from '../../../campaigns/services/campaigns.service';
import { fetchCampaign } from '../../../campaigns/store/actions/campaigns.actions';
import { campaignsQuery } from '../../../campaigns/store/selectors/campaigns.selectors';
import { Campaign, CampaignState } from '../../../campaigns/types';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({
  providedIn: 'root'
})
export class CampaignTagDefs {
  readonly tagDefs: TagDefs<Campaign> = {
    campaign_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showCampaigns)
          ? combineLatest([
              this.campaignStore.select(campaignsQuery.getCampaignById(id)),
              this.campaignStore.select(campaignsQuery.getFetchList)
            ]).pipe(
              first(),
              map(([campaign, fetchList]) => {
                if (!campaign && !fetchList.includes(id)) {
                  this.campaignStore.dispatch(fetchCampaign({ id }));
                }
              })
            )
          : of(null),
      select: id => this.campaignStore.pipe(select(campaignsQuery.getCampaignById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showCampaigns)
          ? this.campaignStore.pipe(
              select(campaignsQuery.getCampaignById(id)),
              first(),
              exhaustMap(campaign => (campaign ? of(campaign) : this.campaignService.getCampaign(id)))
            )
          : of(null),
      getDisplayPrefix: () => 'Campaign',
      getDisplayValue: (campaign: Campaign) => campaign.title,
      getRouteLink: (campaign: Campaign) => `/campaigns/${campaign.id}/details`
    }
  };

  constructor(
    private campaignStore: Store<CampaignState>,
    private campaignService: CampaignsService,
    private scopes: Scopes
  ) {}
}
