import { Pipe, PipeTransform } from '@angular/core';

import { Formatters } from '@utils';

type ConvertFn = 'fromCamelCase' | 'fromSnakeCase';

@Pipe({ name: 'customSentenceCase' })
export class CustomSentenceCasePipe implements PipeTransform {
  convertFns: { [key in ConvertFn]: (value: string) => string } = {
    fromCamelCase: Formatters.fromCamelToSentenceCase,
    fromSnakeCase: Formatters.fromSnakeToSentenceCase
  };

  transform(value: string, method: ConvertFn): string {
    if (!value) {
      return '';
    }

    const convertFn = this.convertFns[method];
    return convertFn(value);
  }
}
