import { createAction, props } from '@ngrx/store';

import { ErrorState, SnackbarMessages } from '@core/types';

import { LoyaltyProgram, LoyaltyProgramsFilter, LoyaltyProgramsResult } from '../../types';

export const loadLoyaltyPrograms = createAction(
  '[Loyalty Programs] Load Loyalty Programs',
  props<{ locale: string }>()
);
export const loadLoyaltyProgramsSuccess = createAction(
  '[Loyalty Programs] Load Loyalty Programs Success',
  props<{ result: LoyaltyProgramsResult; locale: string }>()
);
export const loadLoyaltyProgramsFailure = createAction(
  '[Loyalty Programs] Load Loyalty Programs Failure',
  props<ErrorState>()
);

export const loadLoyaltyProgram = createAction(
  '[Loyalty Programs] Load Loyalty Program',
  props<{ id: string; locale: string }>()
);
export const loadLoyaltyProgramSuccess = createAction(
  '[Loyalty Programs] Load Loyalty Program Success',
  props<{ loyaltyProgram: LoyaltyProgram; locale?: string }>()
);
export const loadLoyaltyProgramFailure = createAction(
  '[Loyalty Programs] Load Loyalty Program Failure',
  props<ErrorState>()
);

export const fetchLoyaltyProgram = createAction('[Loyalty Programs] Fetch Loyalty Program', props<{ id: string }>());
export const fetchLoyaltyProgramSuccess = createAction(
  '[Loyalty Programs] Fetch Loyalty Program Success',
  props<{ loyaltyProgram: LoyaltyProgram }>()
);
export const fetchLoyaltyProgramFailure = createAction(
  '[Loyalty Programs] Fetch Loyalty Program Failure',
  props<{ id: string }>()
);

export const createLoyaltyProgram = createAction(
  '[Loyalty Programs] Create Loyalty Program',
  props<{ loyaltyProgram: LoyaltyProgram }>()
);
export const createLoyaltyProgramSuccess = createAction(
  '[Loyalty Programs] Create Loyalty Program Success',
  props<{ loyaltyProgram: LoyaltyProgram }>()
);
export const createLoyaltyProgramFailure = createAction(
  '[Loyalty Programs] Create Loyalty Program Failure',
  props<ErrorState>()
);

export const updateLoyaltyProgram = createAction(
  '[Loyalty Programs] Update Loyalty Program',
  props<{ loyaltyProgram: LoyaltyProgram; locale?: string }>()
);
export const updateLoyaltyProgramSuccess = createAction(
  '[Loyalty Programs] Update Loyalty Program Success',
  props<{ loyaltyProgram: LoyaltyProgram }>()
);
export const updateLoyaltyProgramFailure = createAction(
  '[Loyalty Programs] Update Loyalty Program Failure',
  props<ErrorState>()
);

export const synchroniseLoyaltyPrograms = createAction('[Loyalty Programs] Synchronise Loyalty Programs');
export const synchroniseLoyaltyProgramsSuccess = createAction(
  '[Loyalty Programs] Synchronise Loyalty Programs Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: 'Programs updated! Refresh the page in a few seconds to see the latest changes.'
    }
  ) => ({ snackbarMessages })
);
export const synchroniseLoyaltyProgramsFailure = createAction(
  '[Loyalty Programs] Synchronise Loyalty Programs Failure',
  props<ErrorState>()
);

export const setLoyaltyProgramSingleLocale = createAction(
  '[Loyalty Programs] Set Loyalty Program Single Locale',
  props<{ locale: string }>()
);
export const setLoyaltyProgramBatchLocale = createAction(
  '[Loyalty Programs] Set Loyalty Program Batch Locale',
  props<{ locale: string }>()
);

export const setLoyaltyProgramsFilter = createAction(
  '[Loyalty Programs] Set Loyalty Programs Filter',
  props<{ filter: LoyaltyProgramsFilter }>()
);
