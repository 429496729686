import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { currencyConversionInitialState, CurrencyConversionState } from '../../types';
import {
  clearConvertedCurrency,
  convertCurrency,
  convertCurrencyFailure,
  convertCurrencySuccess
} from '../actions/currency-conversion.actions';

export const currencyConversionTriggers: EntityTriggers = {
  single: {
    loading: [convertCurrency.type],
    resting: [convertCurrencySuccess.type],
    erroring: [convertCurrencyFailure.type]
  }
};

export function currencyConversionReducer(
  state: CurrencyConversionState = currencyConversionInitialState,
  action: Action
): CurrencyConversionState {
  return callStateReducer(baseReducer, currencyConversionTriggers)(state, action);
}

const baseReducer = createReducer(
  currencyConversionInitialState,
  on(convertCurrencySuccess, (state, { result: convertedCurrency }) => ({
    ...state,
    currencyConversion: convertedCurrency
  })),
  on(clearConvertedCurrency, () => currencyConversionInitialState)
);
