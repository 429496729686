import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { EnrollTenantParams, Tenant, TenantResponse } from '../types/tenants.type';

@Injectable({
  providedIn: 'root'
})
export class TenantsService {
  constructor(private missionControlService: MissionControlService) {}

  getTenants(): Observable<Tenant[]> {
    return this.missionControlService.get<Tenant[]>('tenants');
  }

  getTenant(id: string): Observable<Tenant> {
    return this.missionControlService.get<Tenant>(`tenants/${id}`);
  }

  updateTenant(tenant: Partial<Tenant>): Observable<Tenant> {
    if (tenant.translations) {
      tenant = ObjectUtils.encodeAttributesAsBase64(tenant, ['translations']);
    }
    tenant = ObjectUtils.sanitizeRequestObject<Partial<Tenant>>(tenant, {
      ignoredKeys: ['custom_data', 'custom_css', 'preferences', 'settings', 'translations', 'changes']
    });

    return this.missionControlService.patch<Tenant>(`tenants/${tenant.id}`, tenant);
  }

  enrollTenant(params: EnrollTenantParams): Observable<TenantResponse> {
    params = ObjectUtils.sanitizeRequestObject<EnrollTenantParams>(params);
    return this.missionControlService.post<TenantResponse>('tenants/enroll', params);
  }

  handoverToTenant(id: string): Observable<void> {
    return this.missionControlService.patch<void>(`tenants/${id}/handover`);
  }
}
