import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { EventTypesService } from '../../services/event-types.service';
import {
  createEventType,
  createEventTypeFailure,
  createEventTypeSuccess,
  deleteEventType,
  deleteEventTypeFailure,
  deleteEventTypeSuccess,
  loadEventType,
  loadEventTypeFailure,
  loadEventTypes,
  loadEventTypesFailure,
  loadEventTypesSuccess,
  loadEventTypeSuccess,
  updateEventType,
  updateEventTypeFailure,
  updateEventTypeSuccess
} from '../actions/event-types.actions';

@Injectable()
export class EventTypesEffects {
  loadEventTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEventTypes),
      exhaustMap(() =>
        this.eventTypesService.getEventTypes().pipe(
          map(eventTypes => loadEventTypesSuccess({ eventTypes })),
          catchError(error => of(loadEventTypesFailure({ error })))
        )
      )
    )
  );

  loadEventType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEventType),
      exhaustMap(action =>
        this.eventTypesService.getEventType(action.id).pipe(
          map(eventType => loadEventTypeSuccess({ eventType })),
          catchError(error => of(loadEventTypeFailure({ error })))
        )
      )
    )
  );

  createEventType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEventType),
      exhaustMap(action =>
        this.eventTypesService.createEventType(action.eventType).pipe(
          map(eventType => createEventTypeSuccess(eventType)),
          catchError(error => of(createEventTypeFailure(error)))
        )
      )
    )
  );

  createEventTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEventTypeSuccess),
      exhaustMap(() => {
        return [loadEventTypes(), routerForceNavigate({ path: 'event-types' })];
      })
    )
  );

  updateEventType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEventType),
      exhaustMap(action =>
        this.eventTypesService.updateEventType(action.eventType).pipe(
          map(eventType => updateEventTypeSuccess({ ...eventType })),
          catchError(error => of(updateEventTypeFailure(error)))
        )
      )
    )
  );

  updateEventTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEventTypeSuccess),
      map(({ eventType }) => routerForceNavigate({ path: `event-types/${eventType.id}` }))
    )
  );

  deleteEventType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEventType),
      exhaustMap(action =>
        this.eventTypesService.deleteEventType(action.id).pipe(
          map(() => deleteEventTypeSuccess()),
          catchError(error => of(deleteEventTypeFailure(error)))
        )
      )
    )
  );

  deleteEventTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEventTypeSuccess),
      map(() => loadEventTypes())
    )
  );

  constructor(
    private actions$: Actions,
    private eventTypesService: EventTypesService
  ) {}
}
