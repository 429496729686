import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { TranslationsService } from '../../services/translations.service';
import { loadTranslation, loadTranslationFailure, loadTranslationSuccess } from '../actions/translations.actions';

@Injectable()
export class TranslationsEffects {
  loadTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTranslation),
      mergeMap(action =>
        this.translationsService.getTranslation(action.localeCode).pipe(
          map(translation => loadTranslationSuccess({ translation: { [action.localeCode]: translation } })),
          catchError(error => of(loadTranslationFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private translationsService: TranslationsService
  ) {}
}
