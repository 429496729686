import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialLocaleState } from '@core/store/locale-state';
import { CallState, LocaleState } from '@core/types';

import { LoyaltyProgram } from '../../loyalty-programs/types';

export type CategorisedLoyaltyProgramType = 'cashback' | 'crypto' | 'frequentTravelerPrograms' | 'sustainability';

export interface CategorisedLoyaltyProgramsPriorities {
  data: { capabilityId: string; sortIndex: number }[];
}

export const categorisedLoyaltyProgramAdapter = createEntityAdapter<LoyaltyProgram>({
  selectId: (categorisedLoyaltyProgram: LoyaltyProgram): string => categorisedLoyaltyProgram.id
});

export interface CategorisedLoyaltyProgramState extends EntityState<LoyaltyProgram>, CallState, LocaleState {
  count: number;
}

export const categorisedLoyaltyProgramInitialState: CategorisedLoyaltyProgramState = {
  ...categorisedLoyaltyProgramAdapter.getInitialState<CallState>(initialCallState),
  ...categorisedLoyaltyProgramAdapter.getInitialState<LocaleState>(initialLocaleState),
  count: 0
};
