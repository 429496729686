import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { UserOfferActivitiesService } from '../../services/user-offer-activities.service';
import {
  loadUserOfferActivities,
  loadUserOfferActivitiesFailure,
  loadUserOfferActivitiesSuccess
} from '../actions/user-offer-activities.actions';

@Injectable()
export class UserOfferActivitiesEffects {
  loadUserOfferActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserOfferActivities),
      exhaustMap(({ userId, filter }) =>
        this.userOfferActivitiesService.getUserOfferActivities(userId, filter).pipe(
          map(result => loadUserOfferActivitiesSuccess({ result, userId })),
          catchError(error => of(loadUserOfferActivitiesFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userOfferActivitiesService: UserOfferActivitiesService
  ) {}
}
