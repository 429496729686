import { Component, Input, OnChanges } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { tooltipsQuery } from '../../store/selectors/tooltips.selectors';
import { TooltipKey } from '../../types/tooltip-keys.type';
import { TooltipState } from '../../types/tooltips.type';

@Component({
  selector: 'admin-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnChanges {
  @Input() defaultValue?: string; // to be displayed if tooltip value is not defined in store
  @Input() displayIcon = true;
  @Input() key: TooltipKey;
  @Input() position: TooltipPosition = 'below';
  @Input() styleClassName?: string;

  tooltipValue$: Observable<string>;

  constructor(private tooltipStore: Store<TooltipState>) {}

  // use ngOnChanges to handle dynamic key input values over time, for example,
  // when the key value depends on data from the store and may change over time
  ngOnChanges(): void {
    this.tooltipValue$ = this.tooltipStore.select(tooltipsQuery.getTooltipValueByKey(this.key));
  }
}
