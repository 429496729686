import { InjectionToken } from '@angular/core';

import { DrawerConfig } from '@shared/types/drawer.type';

export const DRAWER_DEFAULT_CONFIG = new InjectionToken<DrawerConfig>('DRAWER_DEFAULT_CONFIG', {
  factory: () => ({
    position: 'right',
    hasBackdrop: true,
    backdropClass: ['bg-neutrals-900', 'bg-opacity-50'],
    width: '600px',
    height: '100%'
  })
});

export const DRAWER_CONFIG = new InjectionToken<DrawerConfig>('DRAWER_CONFIG');

export const DRAWER_DATA = new InjectionToken<any>('DRAWER_DATA');
