import { createReducer, on } from '@ngrx/store';

import { userAbilitiesInitialState } from '@core/types';

import { loadUserAbilitiesSuccess } from '../actions/user-abilities.actions';

export const userAbilitiesReducer = createReducer(
  userAbilitiesInitialState,
  on(loadUserAbilitiesSuccess, (state, { userAbilities }) => ({ ...state, userAbilities, loaded: true }))
);
