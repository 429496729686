import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export const matchTypes = ['exactMatch', 'partialMatch'] as const;
export type MatchType = (typeof matchTypes)[number];

export interface PasswordPolicy {
  id: string;
  tenantId: string;
  data: object;
  type: PasswordPolicyTypeName;
}

export interface Complexity extends PasswordPolicy {
  data: {
    length?: {
      max: number;
      min: number;
    };
    lowercase?: boolean;
    uppercase?: boolean;
    digit?: boolean;
    specialCharacter?: boolean;
    identicalCharacterInARow?: number;
    composite?: {
      minApplied?: number;
      rules?: {
        lowercase?: boolean;
        uppercase?: boolean;
        digit?: boolean;
        specialCharacter?: boolean;
      };
    };
  };
}

export interface Dictionary extends PasswordPolicy {
  data: {
    exactMatch?: Array<string>;
    partialMatch?: Array<string>;
    userIdentifier?: {
      user?: Array<string>;
      identity?: Array<string>;
    };
  };
}

export interface History extends PasswordPolicy {
  data: {
    age?: number;
    count?: number;
  };
}

export enum PasswordPolicyTypeCodes {
  complexity = 'complexity',
  dictionary = 'dictionary',
  history = 'history'
}

export enum UserInfoIdentifiers {
  email = 'email',
  firstName = 'first_name',
  lastName = 'last_name',
  name = 'name'
}

export enum IdentityIdentifiers {
  uid = 'uid'
}

export type PasswordPolicyTypeName = 'complexity' | 'dictionary' | 'history';

export type PasswordPolicyType = Complexity | Dictionary | History;

export const passwordPolicyAdapter = createEntityAdapter<PasswordPolicy>();

export interface PasswordPolicyState extends EntityState<PasswordPolicy>, CallState {}

export const passwordPolicyInitialState: PasswordPolicyState =
  passwordPolicyAdapter.getInitialState<CallState>(initialCallState);
