import { exhaustMap, first, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Formatters } from '@utils';

import { RedemptionRatesService } from '../../../redemption-rates/services/redemption-rates.service';
import { fetchRedemptionRate } from '../../../redemption-rates/store/actions/redemption-rates.actions';
import { redemptionRatesQuery } from '../../../redemption-rates/store/selectors/redemption-rates.selectors';
import { RedemptionRate, RedemptionRateState } from '../../../redemption-rates/types';
import { RedemptionRateUtils } from '../../../redemption-rates/utils/redemption-rate-utils';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class RedemptionRateTagDefs {
  readonly tagDefs: TagDefs<RedemptionRate> = {
    redemption_rate_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showPricing)
          ? combineLatest([
              this.store.select(redemptionRatesQuery.getRedemptionRateById(id)),
              this.store.select(redemptionRatesQuery.getFetchList)
            ]).pipe(
              first(),
              map(([redemptionRate, fetchList]) => {
                if (!redemptionRate && !fetchList.includes(id)) {
                  this.store.dispatch(fetchRedemptionRate({ id }));
                }
              })
            )
          : of(null),
      select: id => this.store.pipe(select(redemptionRatesQuery.getRedemptionRateById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showPricing)
          ? this.store.pipe(
              select(redemptionRatesQuery.getRedemptionRateById(id)),
              first(),
              exhaustMap(redemptionRate =>
                redemptionRate ? of(redemptionRate) : this.redemptionRateService.getRedemptionRate(id)
              )
            )
          : of(null),
      getDisplayPrefix: () => 'Redemption Rate',
      getDisplayValue: (redemptionRate: RedemptionRate) =>
        Formatters.fromSnakeToTitleCase(redemptionRate.productType!) +
        ' ' +
        (redemptionRate.brand ?? 'All') +
        ': ' +
        RedemptionRateUtils.formatPrice(redemptionRate.minPrice!, redemptionRate.maxPrice!),
      getRouteLink: (redemptionRate: RedemptionRate) =>
        `/redemption-rates/${redemptionRate.productType!.replace('_', '-')}`
    }
  };

  constructor(
    private store: Store<RedemptionRateState>,
    private redemptionRateService: RedemptionRatesService,
    private scopes: Scopes
  ) {}
}
