import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { RewardsProgram, UpdateRewardsProgramParams } from '../../types';

export const updateRewardsProgram = createAction(
  '[Rewards Program] Update Rewards Program',
  props<{
    params: UpdateRewardsProgramParams;
  }>()
);
export const updateRewardsProgramSuccess = createAction(
  '[Rewards Program] Update Rewards Program Success',
  (
    rewardsProgram: RewardsProgram,
    snackbarMessages: SnackbarMessages = {
      default: 'Your request is processing. Please refresh the page after 5 seconds to view the updated status.'
    }
  ) => ({ rewardsProgram, snackbarMessages })
);
export const updateRewardsProgramFailure = createAction(
  '[Rewards Program] Update Rewards Program Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not update the rewards program due to technical issues on our end. ' +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
