import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';

import { LogicRuleEmptyRuleDirective } from '@shared/directives/logic-rule-empty-rule.directive';
import { LogicRuleBuilderHelperService } from '@shared/services/logic-rule-builder-helper/logic-rule-builder-helper.service';
import { Nullable } from '@shared/types';
import { LogicRulesForm } from '@shared/types/logic-rule-forms.type';
import {
  LogicRule,
  LogicRuleAttributeGroupOption,
  LogicRuleAttributeRecord,
  LogicRuleConnector,
  LogicRulesData
} from '@shared/types/logic-rule.type';
import { ObjectUtils, injectUntilDestroyed } from '@utils';

@Component({
  selector: 'admin-logic-rules-form',
  templateUrl: './logic-rules-form.component.html',
  styleUrls: ['./logic-rules-form.component.scss', '../../../../stylesheets/v2-styles/rule-builder.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LogicRulesFormComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LogicRulesFormComponent,
      multi: true
    },
    LogicRuleBuilderHelperService
  ]
})
export class LogicRulesFormComponent implements OnChanges, OnInit, ControlValueAccessor, Validator {
  private fb = inject(FormBuilder);
  private logicRuleBuilderHelperService = inject(LogicRuleBuilderHelperService);
  private untilDestroyed = injectUntilDestroyed();

  @ContentChild(LogicRuleEmptyRuleDirective) emptyRuleTemplate?: LogicRuleEmptyRuleDirective;

  @Input({ required: true }) attributeDataSource: LogicRuleAttributeRecord;

  @Input() connectors: LogicRuleConnector[] = ['and'];

  /**
   * Enable this option if main attribute always be `event_type`
   * applicable for Campaigns qualification
   */
  @Input() useEventTypeAsMainAttribute = false;

  attributeGroupOptions: LogicRuleAttributeGroupOption[] = [];

  rulesForm = this.fb.group<LogicRulesForm>({
    connector: this.fb.nonNullable.control('and', [Validators.required]),
    conditions: this.fb.array([] as FormControl<Nullable<LogicRule>>[])
  });

  onChange?: (value: LogicRulesData | null) => void;

  onTouched?: () => void;

  /*** Lifecycle hooks ***/

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attributeDataSource) {
      this.registerAttributeDataSource();

      this.attributeGroupOptions = this.attributeDataSource
        ? this.logicRuleBuilderHelperService.getMainAttributeOptionsWithCategory()
        : [];
    }
  }

  ngOnInit(): void {
    this.subscribeToFormChanges();

    // We will sync first condition value to all conditions
    // cause for Campaign, we only allow OR condition
    // and 1 type of main action
    if (this.useEventTypeAsMainAttribute) {
      this.subscribeToFirstMainActionChanges();
    }
  }

  registerAttributeDataSource(): void {
    this.logicRuleBuilderHelperService.registerAttributesDataSource(this.attributeDataSource);
  }

  subscribeToFormChanges(): void {
    this.rulesForm.valueChanges.pipe(this.untilDestroyed()).subscribe(() => {
      const value = this.rulesForm.getRawValue();

      this.onChange?.(
        this.isOnlyContainsPlaceholderRule()
          ? {
              connector: value.connector,
              conditions: []
            }
          : {
              connector: value.connector,
              conditions: value.conditions as LogicRule[]
            }
      );
      this.onTouched?.();
    });
  }

  subscribeToFirstMainActionChanges(): void {
    this.rulesForm.valueChanges
      .pipe(
        filter(({ conditions }) => conditions!.length > 0),
        map(() => this.rulesForm.controls.conditions.at(0).value),
        filter(Boolean),
        map(rule => ({
          attribute: rule.attribute,
          operator: rule.operator,
          value: rule.value
        })),
        distinctUntilChanged((prev, curr) => prev.value === curr.value),
        this.untilDestroyed()
      )
      .subscribe(rule => {
        for (let i = 1; i < this.rulesForm.controls.conditions.length; i++) {
          this.rulesForm.controls.conditions.at(i).patchValue({ ...rule }, { emitEvent: false });
        }

        this.rulesForm.updateValueAndValidity();
      });
  }

  /*** Event handlers ***/

  addCondition(): void {
    const { conditions } = this.rulesForm.controls;

    // When adding new condition, we will copy the first condition value
    // if we are in Campaigns qualification and the first condition is not empty
    // else  we will just add a new empty condition as usual
    if (
      this.useEventTypeAsMainAttribute &&
      conditions.length > 0 &&
      !(
        ObjectUtils.isNullish(conditions.at(0).getRawValue()) ||
        ObjectUtils.isEmptyValueObject(conditions.at(0).getRawValue())
      )
    ) {
      const { attribute, operator, value } = conditions.at(0).getRawValue()!;

      conditions.push(this.fb.nonNullable.control({ attribute, operator, value }, [Validators.required]));
    } else {
      conditions.push(this.fb.control(null, [Validators.required]));
    }
  }

  removeCondition(index: number): void {
    this.rulesForm.controls.conditions.removeAt(index);
  }

  /*** CVA functions ***/

  writeValue(value: LogicRulesData | null): void {
    const { connector, conditions } = value ?? {
      // We will default to the first supported connector if no connector is provided
      connector: this.connectors.length > 0 ? this.connectors[0] : 'and',
      conditions: []
    };

    this.rulesForm.controls.connector.setValue(connector, { emitEvent: false });

    // Clear all conditions first, then add new conditions
    // cause Angular doesn't support patching FormArray
    // if array length is different
    this.rulesForm.controls.conditions.clear({ emitEvent: false });

    if (conditions.length > 0) {
      conditions.forEach(condition => {
        this.rulesForm.controls.conditions.push(this.fb.control(condition, [Validators.required]), {
          emitEvent: false
        });
      });
    }
  }

  registerOnChange(fn: (value: LogicRulesData | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.rulesForm.disable({ emitEvent: false });
    } else {
      this.rulesForm.enable({ emitEvent: false });
    }
  }

  /*** Validator functions ***/

  validate(): Nullable<ValidationErrors> {
    return this.rulesForm.invalid ? { invalidLogicRuleConditions: true } : null;
  }

  /*** Helper functions ***/
  /** We still need this helper to verify if the 1st condition is no value */
  isOnlyContainsPlaceholderRule(): boolean {
    const { conditions } = this.rulesForm.getRawValue();

    return (
      conditions.length === 1 && (ObjectUtils.isNullish(conditions[0]) || ObjectUtils.isEmptyValueObject(conditions[0]))
    );
  }

  shouldShowAddConditionButton(): boolean {
    return !this.useEventTypeAsMainAttribute || !this.isOnlyContainsPlaceholderRule();
  }
}
