import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { Segment, segmentAdapter, SegmentState } from '../../types';

const getSegmentState = createFeatureSelector<SegmentState>('segment');
const segmentDetector = createDetector<Segment>();

const { selectAll: getSegmentsList, selectEntities: getSegmentsDictionary } =
  segmentAdapter.getSelectors(getSegmentState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<SegmentState>(getSegmentState);

const isSegmentsLoaded = createSelector(
  getSegmentsDictionary,
  isBatchResting,
  (segments, batchResting) => segmentDetector('allLoaded')(segments) && batchResting
);

const getSegmentById = (id: string): Selector<SegmentState, Segment> =>
  createSelector(getSegmentsDictionary, entities => entities[id]);

const isSegmentLoaded = (id: string): Selector<SegmentState, boolean> =>
  createSelector(getSegmentsDictionary, entities => segmentDetector('oneLoaded')(entities, id));

const getSegmentsTotal = createSelector(getSegmentState, (state: SegmentState) => state.total);

const getSegmentsFilter = createSelector(getSegmentState, (state: SegmentState) => state.filter);

export const segmentsQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getSegmentById,
  isSegmentLoaded,
  isSegmentsLoaded,
  getSegmentsList,
  getSegmentsDictionary,
  getSegmentsTotal,
  getSegmentsFilter
};
