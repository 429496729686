import { HttpParameterCodec } from '@angular/common/http';

export class BracketParamsEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    if (key.includes('[') || key.includes(']')) {
      return key;
    }
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
