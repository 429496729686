import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { MerchandisesService } from '../../services/merchandises.service';
import {
  fetchMerchandise,
  fetchMerchandiseFailure,
  fetchMerchandiseSuccess,
  loadMerchandise,
  loadMerchandiseFailure,
  loadMerchandises,
  loadMerchandisesFailure,
  loadMerchandisesSuccess,
  loadMerchandiseSuccess,
  updateMerchandise,
  updateMerchandiseFailure,
  updateMerchandiseSuccess
} from '../actions/merchandises.actions';

@Injectable()
export class MerchandisesEffects {
  loadMerchandises$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMerchandises),
      exhaustMap(({ filter }) =>
        this.merchandisesService.getMerchandises(filter).pipe(
          map(merchandises => loadMerchandisesSuccess({ merchandises })),
          catchError(error => of(loadMerchandisesFailure({ error })))
        )
      )
    )
  );

  loadMerchandise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMerchandise),
      exhaustMap(action =>
        this.merchandisesService.getMerchandise(action.id).pipe(
          map(merchandise => loadMerchandiseSuccess({ merchandise })),
          catchError(error => of(loadMerchandiseFailure({ error })))
        )
      )
    )
  );

  updateMerchandise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchandise),
      exhaustMap(action =>
        this.merchandisesService.updateMerchandise(action.params).pipe(
          map(merchandise => updateMerchandiseSuccess({ merchandise })),
          catchError(error => of(updateMerchandiseFailure({ error })))
        )
      )
    )
  );

  updateMerchandiseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchandiseSuccess),
      map(action => routerNavigate({ path: `/merchandises/${action.merchandise.id}` }))
    )
  );

  fetchMerchandise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMerchandise),
      mergeMap(action =>
        this.merchandisesService.getMerchandise(action.id).pipe(
          map(merchandise => fetchMerchandiseSuccess({ merchandise })),
          catchError(() => of(fetchMerchandiseFailure({ id: action.id })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private merchandisesService: MerchandisesService
  ) {}
}
