import { FormControl } from '@angular/forms';
import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError } from '@core/types';

import { Offer, OfferResult, OffersFilter } from '../../types';

export const loadOffers = createAction('[Offers] Load Offers', props<{ filter: OffersFilter }>());
export const loadOffersSuccess = createAction('[Offers] Load Offers Success', props<{ result: OfferResult }>());
export const loadOffersFailure = createAction('[Offers] Load Offers Failure', props<ErrorState>());

export const loadOffer = createAction('[Offers] Load Offer', props<{ id: string; filter?: OffersFilter }>());
export const loadOfferSuccess = createAction('[Offers] Load Offer Success', props<{ offer: Offer }>());
export const loadOfferFailure = createAction('[Offers] Load Offer Failure', props<ErrorState>());

export const fetchOffer = createAction('[Offers] Fetch Offer', props<{ id: string }>());
export const fetchOfferSuccess = createAction('[Offers] Fetch Offer Success', props<{ offer: Offer }>());
export const fetchOfferFailure = createAction('[Offers] Fetch Offer Failure', props<{ id: string }>());

export const createOffer = createAction('[Offers] Create Offer', props<{ offer: Offer }>());
export const createOfferSuccess = createAction('[Offers] Create Offer Success', props<{ offer: Offer }>());
export const createOfferFailure = createAction('[Offers] Create Offer Failure', props<ErrorState>());

export const updateOffer = createAction('[Offers] Update Offer', props<{ offer: Offer }>());
export const updateOfferSuccess = createAction('[Offers] Update Offer Success', props<{ offer: Offer }>());
export const updateOfferFailure = createAction(
  '[Offers] Update Offer Failure',
  props<{ error: HttpError; id: string }>()
);

export const loadUserOffers = createAction('[Offers] Load User Offers', props<{ filter: OffersFilter }>());
export const loadUserOffersSuccess = createAction(
  '[Offers] Load User Offers Success',
  props<{ result: OfferResult }>()
);
export const loadUserOffersFailure = createAction('[Offers] Load User Offers Failure', props<ErrorState>());

export const uploadOfferImage = createAction(
  '[Offers] Upload Offer Image',
  props<{
    offerId?: string;
    brand: string;
    supplier: string;
    file: FormData;
    dialogRefId: string;
    formControl: FormControl<string>;
  }>()
);
export const uploadOfferImageSuccess = createAction(
  '[Offers] Upload Offer Image Success',
  props<{
    imageUrl: string;
    dialogRefId: string;
    formControl: FormControl<string>;
  }>()
);
export const uploadOfferImageFailure = createAction('[Offers] Upload Offer Image Failure', props<ErrorState>());
