import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { MerchantPortalBootstrapState, merchantPortalBootstrapInitialState } from '../../types/campaign-proposals.type';
import {
  loadMerchantPortalBootstrap,
  loadMerchantPortalBootstrapFailure,
  loadMerchantPortalBootstrapSuccess,
  updateSelectedYear
} from '../actions/campaign-proposals.actions';

export const merchantPortalBootstrapTriggers: EntityTriggers = {
  single: {
    loading: [loadMerchantPortalBootstrap.type],
    resting: [loadMerchantPortalBootstrapSuccess.type],
    erroring: [loadMerchantPortalBootstrapFailure.type]
  }
};

export function merchantPortalBootstrapReducer(
  state: MerchantPortalBootstrapState = merchantPortalBootstrapInitialState,
  action: Action
): MerchantPortalBootstrapState {
  return callStateReducer(baseReducer, merchantPortalBootstrapTriggers)(state, action);
}

const baseReducer = createReducer(
  merchantPortalBootstrapInitialState,
  on(loadMerchantPortalBootstrapSuccess, (state, { merchantPortalBootstrap }) => ({
    ...state,
    merchantPortalBootstrap
  })),
  on(updateSelectedYear, (state, { selectedYear }) => ({
    ...state,
    selectedYear
  }))
);
