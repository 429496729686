import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  SliderContainerComponent,
  SliderItemDirective
} from '@shared/components/slider-container/slider-container.component';
import { SharedModule } from '@shared/shared.module';

import { TooltipsModule } from '../tooltips/tooltips.module';
import { ApprovalRequestedDialogComponent } from './containers/approval-requested-dialog/approval-requested-dialog.component';
import { HeaderComponent } from './containers/header/header.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { MainContainerComponent } from './containers/main-container/main-container.component';
import { MenuListItemComponent } from './containers/menu-list-item/menu-list-item.component';
import { SelfHelpFAQComponent } from './containers/self-help-faq/self-help-faq.component';
import { SessionExpiredDialogComponent } from './containers/session-expired-dialog/session-expired-dialog.component';
import { SideNavV2BodyComponent } from './containers/side-nav-v2/body/side-nav-v2-body.component';
import { SideNavV2FooterComponent } from './containers/side-nav-v2/footer/side-nav-v2-footer.component';
import { SideNavV2HeaderComponent } from './containers/side-nav-v2/header/side-nav-v2-header.component';
import { SideNavV2ListItemComponent } from './containers/side-nav-v2/list-item/side-nav-v2-list-item.component';
import { SideNavV2ListTypeGuardDirective } from './containers/side-nav-v2/navigation-list/side-nav-v2-nav-list-type-guard.directive';
import { SideNavV2NavigationListComponent } from './containers/side-nav-v2/navigation-list/side-nav-v2-navigation-list.component';
import { SideNavV2Component } from './containers/side-nav-v2/side-nav-v2.component';
import { SideNavComponent } from './containers/side-nav/side-nav.component';
import { StepUpDialogComponent } from './containers/step-up-dialog/step-up-dialog.component';
import { UserIdleDialogComponent } from './containers/user-idle-dialog/user-idle-dialog.component';
import { callbackGuard } from './guards/callback.guard';
import { AttributeUtils } from './services/attribute-utils/attribute-utils.service';
import { PiiAccess } from './services/pii-access/pii-access.service';
import { Scopes } from './services/scopes/scopes.service';
import { AccessPolicies } from './services/user-abilities/access-policies-helper.service';
import { ErrorActionTypes } from './store/errors';
import { SuccessActionTypes } from './store/success';
import { loadUserAbilitiesFailure } from './store/user-abilities/actions/user-abilities.actions';

const sideNavV2Components = [
  SideNavV2Component,
  SideNavV2HeaderComponent,
  SideNavV2BodyComponent,
  SideNavV2FooterComponent,
  SideNavV2NavigationListComponent,
  SideNavV2ListTypeGuardDirective,
  SideNavV2ListItemComponent
];

const declaredComponents = [
  ApprovalRequestedDialogComponent,
  HeaderComponent,
  LoginPageComponent,
  MainContainerComponent,
  SideNavComponent,
  MenuListItemComponent,
  SessionExpiredDialogComponent,
  UserIdleDialogComponent,
  StepUpDialogComponent,
  SelfHelpFAQComponent,
  ...sideNavV2Components
];

const coreRoutes: Routes = [
  {
    path: 'login/callback',
    canActivate: [callbackGuard],
    children: []
  },
  {
    path: 'ascenda',
    children: []
  }
];

@NgModule({
  declarations: [...declaredComponents],
  imports: [
    SharedModule,
    RouterModule.forRoot(coreRoutes, {}),
    TooltipsModule,
    CdkAccordionModule,
    SliderContainerComponent,
    SliderItemDirective
  ],
  providers: [
    PiiAccess,
    Scopes,
    AccessPolicies,
    AttributeUtils,
    {
      provide: ErrorActionTypes,
      useValue: new ErrorActionTypes(new Set([loadUserAbilitiesFailure.type]))
    },
    {
      provide: SuccessActionTypes,
      useValue: new SuccessActionTypes(new Set())
    }
  ],
  exports: [...declaredComponents]
})
export class CoreModule {}
