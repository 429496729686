import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { OpenApiSchema, OpenApiSchemaType } from '../../form-fields/types/open-api-schema.type';

export interface FraudRuleSchema {
  id: string;
  schema: OpenApiSchema;
}

export interface RawFraudRuleSchemas {
  fraudRuleSchema: OpenApiSchemaType;
}

export const fraudRuleSchemaAdapter = createEntityAdapter<FraudRuleSchema>();

export interface FraudRuleSchemaState extends EntityState<FraudRuleSchema>, CallState {}

export const initialState: FraudRuleSchemaState = {
  ...fraudRuleSchemaAdapter.getInitialState<CallState>(initialCallState)
};
