import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { TenantLocale } from '../../types';

export const loadTenantLocales = createAction('[Tenant Locales] Load Tenant Locales');
export const loadTenantLocalesSuccess = createAction(
  '[Tenant Locales] Load Tenant Locales Success',
  props<{ tenantLocales: TenantLocale[] }>()
);
export const loadTenantLocalesFailure = createAction(
  '[Tenant Locales] Load Tenant Locales Failure',
  props<ErrorState>()
);

export const updateTenantLocales = createAction(
  '[Tenant Locales] Update Tenant Locale',
  props<{ tenantLocales: TenantLocale[] }>()
);
export const updateTenantLocalesSuccess = createAction('[Tenant Locales] Update Tenant Locale Success');
export const updateTenantLocalesFailure = createAction(
  '[Tenant Locales] Update Tenant Locale Failure',
  props<ErrorState>()
);
