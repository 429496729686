import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { mfaIdentityAdapter, mfaIdentityInitialState, MfaIdentityState } from '../../types';
import {
  createMfaIdentity,
  createMfaIdentityFailure,
  createMfaIdentitySuccess,
  deleteMfaIdentity,
  deleteMfaIdentityFailure,
  deleteMfaIdentitySuccess,
  loadMfaIdentities,
  loadMfaIdentitiesFailure,
  loadMfaIdentitiesSuccess,
  updateMfaIdentity,
  updateMfaIdentityFailure,
  updateMfaIdentitySuccess
} from '../actions/mfa-identities.actions';

export const mfaIdentityTriggers: EntityTriggers = {
  single: {
    loading: [updateMfaIdentity.type, deleteMfaIdentity.type, createMfaIdentity.type],
    resting: [updateMfaIdentitySuccess.type, deleteMfaIdentitySuccess.type, createMfaIdentitySuccess.type],
    erroring: [updateMfaIdentityFailure.type, deleteMfaIdentityFailure.type, createMfaIdentityFailure.type]
  },
  batch: {
    loading: [loadMfaIdentities.type],
    resting: [loadMfaIdentitiesSuccess.type],
    erroring: [loadMfaIdentitiesFailure.type]
  }
};

export function mfaIdentitiesReducer(
  state: MfaIdentityState = mfaIdentityInitialState,
  action: Action
): MfaIdentityState {
  return callStateReducer(baseReducer, mfaIdentityTriggers)(state, action);
}

const baseReducer = createReducer(
  mfaIdentityInitialState,
  on(loadMfaIdentitiesSuccess, (state, { mfaIdentities }) => mfaIdentityAdapter.setAll(mfaIdentities, state)),
  on(deleteMfaIdentitySuccess, (state, { mfaIdentityId }) => mfaIdentityAdapter.removeOne(mfaIdentityId, state))
);
