import { Component, Input, OnInit } from '@angular/core';

import { SCOPES_OR } from '@core/types';

import {
  CarBookingData,
  FlightBookingData,
  HotelBookingData,
  OrderItem,
  StripeReferenceData,
  TravelBookingOrderItemData
} from '../../types';

@Component({
  selector: 'admin-travel-booking-order-item-v2',
  templateUrl: './travel-booking-order-item-v2.component.html',
  styleUrls: ['./travel-booking-order-item-v2.component.scss']
})
export class TravelBookingOrderItemV2Component implements OnInit {
  @Input() customerId: string;
  @Input() orderItem: OrderItem;
  @Input() stripeReferenceData: StripeReferenceData;

  orderItemData: TravelBookingOrderItemData;
  hotelBookingData: HotelBookingData;
  carBookingData: CarBookingData;
  flightBookingData: FlightBookingData;
  readonly SCOPES = SCOPES_OR;

  ngOnInit(): void {
    this.orderItemData = this.orderItem.data as TravelBookingOrderItemData;
    const travelType = this.orderItemData.travelType;

    switch (travelType) {
      case 'hotels': {
        this.hotelBookingData = this.orderItemData as HotelBookingData;

        break;
      }
      case 'cars': {
        this.carBookingData = this.orderItemData as CarBookingData;

        break;
      }
      case 'flights': {
        this.flightBookingData = this.orderItemData as FlightBookingData;

        break;
      }
      // No default
    }
  }
}
