import { NgModule } from '@angular/core';
import { RouterModule, Routes, ROUTES } from '@angular/router';

import { componentAuthGuard } from '@core/guards/auth.guard';
import { SCOPES_OR } from '@core/types';

import { UiConfigs } from '../../app-module-config';

import { AuditLogDetailsComponent } from './components/audit-log-details/audit-log-details.component';
import { auditLogResolver } from './resolvers/audit-log.resolver';
import { auditLogsResolver } from './resolvers/audit-logs.resolver';
import { AuditLogEntity } from './types';
import { AuditLogsComponent } from './views/audit-logs/audit-logs.component';
import { AuditLogsV2Component } from './views/audit-logs-v2/audit-logs-v2.component';

export function createAuditLogsRoutes(uiConfigs: UiConfigs): Routes {
  return [
    {
      path: '',
      canActivate: [componentAuthGuard],
      component: uiConfigs.logs?.list === 'v2' ? AuditLogsV2Component : AuditLogsComponent,
      data: {
        filterEntities: Object.values(AuditLogEntity),
        isTopLevelView: true
      },
      resolve: {
        tags: auditLogsResolver
      }
    },
    {
      path: ':id',
      component: AuditLogDetailsComponent,
      canActivate: [componentAuthGuard],
      data: {
        scopes: SCOPES_OR.viewAuditLogs,
        relationOperator: 'or',
        isTopLevelView: true
      },
      resolve: {
        prefetchOrderItem: auditLogResolver
      }
    }
  ];
}

@NgModule({
  imports: [RouterModule],
  providers: [{ provide: ROUTES, multi: true, useFactory: createAuditLogsRoutes, deps: ['uiConfigs'] }],
  exports: [RouterModule]
})
export class AuditLogsRoutes {}
