import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { DateRangeForm } from '@shared/types/common-form.type';

import { DiscountType, LimitUse, PredicateUnion } from './promo-codes.type';

export const AUDIENCE = 'user_eligibility->user_flag->customer_segment';

export interface PromoCodeForm {
  code: FormControl<string>;
  description: FormControl<string>;
  discountType: FormControl<DiscountType>;
  [AUDIENCE]: FormControl<string>;
  startAt: FormGroup<DateRangeForm>;
  createdAt: FormGroup<DateRangeForm>;
}

export interface EligibilityForm {
  attribute: FormControl<string>;
  predicate: FormControl<PredicateUnion>;
  value: FormControl<string[]>;
}

export interface PromoCodeCreateEditForm {
  id: FormControl<string>;
  code: FormControl<string>;
  discountType: FormControl<DiscountType>;
  discountOptions: FormGroup<{
    points: FormControl<number>;
    minPointsThreshold: FormControl<number>;
  }>;
  limitUse: FormControl<LimitUse>;
  quantity: FormControl<number>;
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
  startTime: FormControl<string>;
  endTime: FormControl<string>;
  productEligibility: FormArray<FormGroup<EligibilityForm>>;
  userEligibility: FormGroup<{
    userFlag: FormGroup<{
      customerSegment: FormControl<string>;
    }>;
  }>;
  display: FormControl<boolean>;
  description: FormControl<string>;
  ranking: FormControl<number>;
  promoCodeBanner: FormGroup<{
    id: FormControl<string>;
    title: FormControl<string>;
    subtitle: FormControl<string>;
    buttonCopy: FormControl<string>;
    buttonUrl: FormControl<string>;
    desktopImageUrl: FormControl<string>;
    mobileImageUrl: FormControl<string>;
  }>;
}
