import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { PointsAccountState, PointsActivityState, PointsAdjustmentState, PointsStatementState } from '../../types';
import { pointsAccountsReducer } from './points-accounts.reducer';
import { pointsActivitiesReducer } from './points-activities.reducer';
import { pointsAdjustmentsReducer } from './points-adjustments.reducer';
import { pointsStatementsReducer } from './points-statements.reducer';

export interface DashboardState {
  pointsAccount: PointsAccountState;
  pointsActivity: PointsActivityState;
  pointsAdjustment: PointsAdjustmentState;
  pointsStatement: PointsStatementState;
}

export const dashboardReducers: ActionReducerMap<DashboardState> = {
  pointsAccount: pointsAccountsReducer,
  pointsActivity: pointsActivitiesReducer,
  pointsAdjustment: pointsAdjustmentsReducer,
  pointsStatement: pointsStatementsReducer
};

export const getDashboardState = createFeatureSelector<DashboardState>('dashboard');
