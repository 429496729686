import * as Cookies from 'es-cookie';
import { UserAgentUtils } from '../utils/user-agent-utils';
var EXPIRY_TIME_DAYS = 0.5;
var CookieUtils = /** @class */ (function () {
    function CookieUtils() {
    }
    CookieUtils.prototype.get = function (key) {
        var value = Cookies.get(key);
        if (typeof value === 'undefined') {
            return null;
        }
        return JSON.parse(value);
    };
    CookieUtils.prototype.getAllKeys = function () {
        return Object.keys(Cookies.getAll() || {});
    };
    CookieUtils.prototype.save = function (key, value) {
        // Set SameSite=Lax for Safari to avoid cookie deletion bug
        var sameSite = UserAgentUtils.isSafari() ? 'lax' : 'strict';
        Cookies.set(key, JSON.stringify(value), { expires: EXPIRY_TIME_DAYS, sameSite: sameSite, secure: true });
    };
    CookieUtils.prototype.remove = function (key) {
        Cookies.remove(key, { sameSite: 'strict' }); // setting attributes to avoid unnecessary warning
    };
    return CookieUtils;
}());
export { CookieUtils };
