import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { PointsAdjustment, PointsAdjustmentResult } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class PointsAdjustmentsService {
  constructor(private missionControlService: MissionControlService) {}

  makePointsAdjustment(userId: string, pointsAdjustment: PointsAdjustment): Observable<PointsAdjustmentResult> {
    pointsAdjustment = ObjectUtils.sanitizeRequestObject<PointsAdjustment>(pointsAdjustment);

    return this.missionControlService.post<PointsAdjustmentResult>(
      `users/${userId}/points_adjustment`,
      pointsAdjustment
    );
  }
}
