<div class="multi-select-button-group">
  <button
    *ngFor="let option of options"
    class="multi-select-button"
    type="button"
    [disabled]="isDisabled"
    [class.selected]="values.includes(option)"
    [value]="option"
    (click)="onSelect(option)"
  >
    <span>{{ prefix }}{{ option }}</span>
    <mat-icon class="check-icon" svgIcon="checkmark-multi-select"></mat-icon>
  </button>
</div>
