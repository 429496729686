import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Params } from '@utils';

import { userPasswordsQuery } from '../../store/selectors/user-passwords.selectors';
import { UserPassword, UserPasswordState } from '../../types/user-passwords.type';

@Component({
  selector: 'admin-user-passwords',
  templateUrl: './user-passwords.component.html',
  styleUrls: ['./user-passwords.component.scss']
})
export class UserPasswordsComponent implements OnInit {
  loading$: Observable<boolean>;
  passwords$: Observable<UserPassword[]>;
  passwordId: string;

  columns = ['id', 'createdAt', 'updatedAt', 'reminderCount', 'expiringReminderCount'];

  constructor(
    private store: Store<UserPasswordState>,
    private route: ActivatedRoute,
    @Inject('timezone') public timezone: string
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(userPasswordsQuery.isBatchLoading);
    this.passwords$ = this.store.select(userPasswordsQuery.getUserPasswordsList);

    this.passwordId = Params.findQueryParam(this.route, 'id');
  }
}
