import { Component, Input } from '@angular/core';

import { ColumnDef } from '@shared/types';

@Component({
  selector: 'admin-column',
  template: `
    @if (columnDef?.key === 'actions') {
      <admin-side-dropdown-menu>
        <button
          *adminIfScopesInclude="columnDef['actions'].view.scope"
          type="button"
          class="dropdown-button"
          mat-menu-item
          [routerLink]="row.id"
        >
          View
        </button>
        <button
          *adminIfScopesInclude="columnDef['actions'].edit.scope"
          type="button"
          class="dropdown-button"
          mat-menu-item
        >
          Edit
        </button>
      </admin-side-dropdown-menu>
    } @else {
      <div>{{ row[this.columnDef.key] }}</div>
    }
  `
})
export class ColumnComponent {
  @Input({ required: true }) columnDef: ColumnDef;
  @Input({ required: true }) row: any;
}
