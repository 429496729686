import { Pipe, PipeTransform } from '@angular/core';

import { AttributeDef } from '@core/types';

@Pipe({ name: 'renderAttribute' })
export class RenderAttributePipe implements PipeTransform {
  transform(value: any, attribute: AttributeDef<any>): string {
    return attribute.formatter ? attribute.formatter(value[attribute.key], attribute.subKeys) : value[attribute.key];
  }
}
