import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface RewardPreference {
  data: RewardPreferenceDataResponse;
  included: RewardPreferenceIncludedResponse[];
}

interface RewardPreferenceDataResponse {
  attributes: {
    data: object;
    rewardType: string;
  };
  id: string;
  relationships: {
    membership: {
      data: {
        id: string;
        type: string;
      };
    };
  };
  type: string;
}

interface RewardPreferenceIncludedResponse {
  attributes: {
    autosweep: boolean;
    category: string;
    firstName: string;
    lastName: string;
    loyaltyProgramId: string;
    loyaltyProgramName: string;
    number: string;
    preferred: boolean;
    userId: string;
  };
  id: string;
  type: string;
}

export interface RewardPreferenceState extends CallState {
  rewardPreference: RewardPreference;
}

export const initialState: RewardPreferenceState = {
  ...initialCallState,
  rewardPreference: null
};
