import { AttributeDef } from '@core/types';
import { Formatters } from '@utils';

import { BaseIdentity, Preferences, Product, User } from '.';

export const detailDefs: AttributeDef<User>[] = [
  { key: 'id', label: 'ID' },
  { key: 'status', label: 'Status' },
  { key: 'salutation', label: 'Salutation' },
  { key: 'firstName', label: 'First name' },
  { key: 'middleName', label: 'Middle name' },
  { key: 'lastName', label: 'Last name' },
  { key: 'gender', label: 'Gender' },
  { key: 'birthdate', label: 'Birthdate' },
  { key: 'address', label: 'Address', formatter: Formatters.userAddressFormatter },
  { key: 'phoneNumber', label: 'Phone number' },
  { key: 'phoneNumberVerified', label: 'Phone number verified?', formatter: Formatters.booleanFormatter },
  { key: 'email', label: 'Email' },
  { key: 'emailVerified', label: 'Email verified?', formatter: Formatters.booleanFormatter },
  { key: 'zoneinfo', label: 'Zone info' },
  {
    key: 'otherPii',
    label: 'Phone number 1',
    formatter: Formatters.otherPiiFormatter,
    subKeys: ['phones', 'mobile_phone']
  },
  {
    key: 'otherPii',
    label: 'Phone number 2',
    formatter: Formatters.otherPiiFormatter,
    subKeys: ['phones', 'contact_phone']
  },
  {
    key: 'otherPii',
    label: 'Phone number 3',
    formatter: Formatters.otherPiiFormatter,
    subKeys: ['phones', 'home_phone_number']
  },
  {
    key: 'otherPii',
    label: 'Phone number 4',
    formatter: Formatters.otherPiiFormatter,
    subKeys: ['phones', 'raw_mobile_phone']
  },
  { key: 'locale', label: 'Locale' },
  { key: 'loginMode', label: 'Login Mode', formatter: Formatters.fromSnakeToTitleCase },
  { key: 'activated', label: 'Activated', formatter: Formatters.booleanFormatter }
];

export const moreDetailsDefs: AttributeDef<User>[] = [
  { key: 'nickname', label: 'Nickname' },
  { key: 'preferredUsername', label: 'Preferred username' },
  { key: 'picture', label: 'Picture' },
  { key: 'profile', label: 'Profile' }
];

export const preferencesDefs: AttributeDef<Preferences>[] = [
  { key: 'currency', label: 'Currency' },
  { key: 'loyaltyCurrency', label: 'Loyalty Currency' },
  { key: 'pointsAccountId', label: 'Points Account ID' },
  { key: 'productId', label: 'Product ID' }
];

export const loyaltyDataDefs: AttributeDef<User>[] = [{ key: 'loyaltyData', label: 'Loyalty Data' }];

export const customDefs: AttributeDef<User>[] = [{ key: 'custom', label: 'Custom' }];

export const baseIdentityDefs: AttributeDef<BaseIdentity>[] = [
  { key: 'tenantId', label: 'Tenant ID' },
  { key: 'uid', label: 'Login ID' },
  { key: 'providerId', label: 'Provided by' },
  { key: 'customData', label: 'Custom data', formatter: Formatters.jsonFormatter }
];

export const productDefs: AttributeDef<Product>[] = [
  { key: 'value', label: 'Value' },
  { key: 'type', label: 'Type' }
];
