export class UserTokensFilter {
  limit: number;
  page?: number;
  invalidated?: boolean;
  sessionId?: string;
  type?: string;
  sortBy?: string;
  sortDirection?: string;

  constructor() {
    this.page = 1;
    this.limit = 20;
    this.invalidated = false;
  }
}
