import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface WebhookSetting {
  id: string;
  eventName: string;
  authenticationType: 'no_auth' | 'basic' | 'api_key';
  tenantId: string;
  active: boolean;
  authenticationConfig: AuthenticationConfig;
  createdAt: string;
  updatedAt: string;
}

export interface AuthenticationConfig {
  url: string;
  verb: 'post'; // by default
  username?: string; // if authentication type is 'basic'
  password?: string; // if authentication type is 'basic'
  apiKeyName?: string; // if authentication type is 'api_key'
  apiKeyValue?: string; // if authentication type is 'api_key'
  signatureSharedSecret?: string; // if signature is enabled
}

export interface WebhookSettingsResults {
  data: WebhookSetting[];
  meta: {
    count: number;
  };
}

export type WebhookSettingsCreateRequest = Array<{
  active: boolean;
  authenticationConfig: WebhookSetting['authenticationConfig'];
  authenticationType: WebhookSetting['authenticationType'];
  eventName: string;
}>;

export type WebhookSettingsUpdateRequest = Array<{
  id: string;
  eventName: string;
  active?: boolean;
  authenticationConfig?: WebhookSetting['authenticationConfig'];
  authenticationType?: WebhookSetting['authenticationType'];
}>;

export const webhookSettingAdapter = createEntityAdapter<WebhookSetting>();

export interface WebhookSettingState extends EntityState<WebhookSetting>, CallState {}

export const initialState: WebhookSettingState = webhookSettingAdapter.getInitialState<CallState>(initialCallState);
