import { AbstractControl, ValidatorFn } from '@angular/forms';

import { SUPPORTED_GOOGLE_FONTS } from '@utils';

// we only use SUPPORTED_GOOGLE_FONTS for now
export function googleFontValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || SUPPORTED_GOOGLE_FONTS.includes(control.value)) {
      return null;
    }

    return { invalidTypeface: true };
  };
}
