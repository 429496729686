import { SortDirection } from '@angular/material/sort';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState, HttpError } from '@core/types';
import { ComponentRenderState, Nullable } from '@shared/types';
import { LogicRuleAttribute, LogicRulesData } from '@shared/types/logic-rule.type';

export type DeleteCampaignOriginPage = 'listing' | 'details';

export const tenantCampaignTypes = ['accrual', 'gift_card', 'comms'] as const;
export type TenantCampaignType = (typeof tenantCampaignTypes)[number];

export const activeTenantCampaignStatuses = ['active', 'scheduled', 'draft', 'launching'] as const;
export const inactiveTenantCampaignStatuses = ['expired'] as const;
export const qualificationRuleTypes = ['single', 'all', 'threshold', 'none'] as const;
export const tenantCampaignFundingTypes = ['fi_only', 'fi_and_supplier', 'supplier_only'] as const;

export type ActiveTenantCampaignStatus = (typeof activeTenantCampaignStatuses)[number];
export type InactiveTenantCampaignStatus = (typeof inactiveTenantCampaignStatuses)[number];
export type TenantCampaignStatus = 'initial' | ActiveTenantCampaignStatus | InactiveTenantCampaignStatus;
export type QualificationRuleType = (typeof qualificationRuleTypes)[number];
export type TenantCampaignFundingType = (typeof tenantCampaignFundingTypes)[number];

export type TenantCampaignStatusGroup = 'active' | 'inactive';

export const tenantCampaignTimingConstructTypes = ['fixed', 'trigger_based'] as const;
export type TenantCampaignTimingConstructType = (typeof tenantCampaignTimingConstructTypes)[number];

export const tenantCampaignExpiryLogicTypes = ['absolute', 'relative'] as const;
export type TenantCampaignExpiryLogicType = (typeof tenantCampaignExpiryLogicTypes)[number];

export const tenantCampaignsQualificationTypes = ['single', 'none', 'all', 'threshold'] as const;
export type TenantCampaignQualificationType = (typeof tenantCampaignsQualificationTypes)[number];

export const tenantCampaignsThresholdMetric = ['raw_count', 'cumulative_sum'] as const;
export type TenantCampaignThresholdMetric = (typeof tenantCampaignsThresholdMetric)[number];

export const supportedTenantCampaignThresholdOperators = ['==', '>='] as const;
export type TenantCampaignThresholdOperator = (typeof supportedTenantCampaignThresholdOperators)[number];

export type TenantCampaignThreshold = {
  metric: TenantCampaignThresholdMetric;
  operator: TenantCampaignThresholdOperator;
  value: number;
  attribute?: string;
};

export type TenantCampaignQualificationSettingsType<Type extends TenantCampaignQualificationType> = {
  type: Type;
};

export type TenantCampaignQualificationRuleDisplay = {
  qualifyingRuleDisplay: LogicRulesData;
};

export type TenantCampaignSingleQualificationType = TenantCampaignQualificationSettingsType<'single'> &
  TenantCampaignQualificationRuleDisplay;

export type TenantCampaignAllQualificationType = TenantCampaignQualificationSettingsType<'all'> &
  TenantCampaignQualificationRuleDisplay;

export type TenantCampaignThresholdQualificationType = TenantCampaignQualificationSettingsType<'threshold'> &
  TenantCampaignQualificationRuleDisplay & {
    thresholdSettings: TenantCampaignThreshold;
  };

export interface TenantCampaignQualificationTypeOption<CampaignType extends TenantCampaignQualificationType> {
  value: CampaignType;
  label: string;
  description: string;
}

export type TenantCampaignQualificationData =
  | TenantCampaignQualificationSettingsType<'none'>
  | TenantCampaignSingleQualificationType
  | TenantCampaignAllQualificationType
  | TenantCampaignThresholdQualificationType;

export interface TenantCampaignSettingsValue {
  campaignType: TenantCampaignType;
  qualification: TenantCampaignQualificationData;
}

export interface TenantCampaignsFilter {
  description: string | null;
  code: string | null;
  statuses: TenantCampaignStatus[] | null;
  campaignTypes: TenantCampaignType[] | null;
  page: number;
  limit: number;
  sortBy: string;
  sortDirection: Exclude<SortDirection, ''>;
}

export interface AccrualCampaignDetails {
  campaignType: Extract<TenantCampaignType, 'accrual'>;
  qualification: AccrualCampaignQualification;
  fulfillment?: Partial<AccrualCampaignFulfillment>;
  display?: TenantCampaignDisplay;
  displayable: boolean;
  pointsActivityDescriptor?: string;
}

export interface GiftCardCampaignDetails {
  campaignType: Extract<TenantCampaignType, 'gift_card'>;
  qualification: GiftCardCampaignQualification;
  displayable: boolean;
  display?: TenantCampaignDisplay;
  contentDisplayable: boolean;
  contentDisplay?: Pick<TenantCampaignDisplay, 'description' | 'termsAndConditions'>;
  fundingConstruct?: {
    fiRate: number;
    supplierRate: number;
  };
}

export interface CommunicationCampaignDetails {
  campaignType: Extract<TenantCampaignType, 'comms'>;
  qualification: CommunicationCampaignQualification;
}

export type TenantCampaignDetails = {
  id: string;
  createdAt: string;
  updatedAt: string;
  activationEndTime: string;
  qualificationEndTime: string;
  status: TenantCampaignStatus;
  // General settings
  code: string;
  description: string;
  endTime: Date;
  ranking: number;
  startTime: Date;
  // Communication settings
  communicationEnabled: boolean;
  communication?: TenantCampaignCommunicationSetting;
  // Group settings
  segmentation?: TenantCampaignSegmentation;
  timingConstruct?: TenantCampaignTimeConstruct;
} & (AccrualCampaignDetails | GiftCardCampaignDetails | CommunicationCampaignDetails);

export type TenantCampaignGeneralSettings = Pick<
  TenantCampaignDetails,
  'code' | 'description' | 'endTime' | 'startTime' | 'ranking' | 'timingConstruct'
>;

export interface TenantCampaign
  extends Pick<
    TenantCampaignDetails,
    | 'activationEndTime'
    | 'code'
    | 'createdAt'
    | 'updatedAt'
    | 'startTime'
    | 'endTime'
    | 'description'
    | 'id'
    | 'qualificationEndTime'
    | 'status'
  > {
  campaignType: TenantCampaignType;
}

export interface AccrualCampaignQualification {
  type: QualificationRuleType;
  qualifyingRuleDisplay?: LogicRulesData;
  disqualifyingRuleDisplay?: LogicRulesData;
  thresholdSettings?: TenantCampaignThresholdSettings;
}

export interface CommunicationCampaignQualification {
  type: Exclude<TenantCampaignQualificationType, 'threshold'>;
  triggerRuleDisplay?: LogicRulesData;
}

export interface TenantCampaignThresholdSettings {
  metric: 'raw_count' | 'cumulative_sum';
  attribute?: string;
  operator: '==' | '>=';
  value: number;
}

/**
 * - `pointsActivityDescriptor` will be displayed at `Communication` step
 * - `rewardCap` is de-scoped
 */
export interface AccrualCampaignFulfillment {
  fulfillmentTimeframe: 'immediate' | 'end_of_campaign';
  rewardType: 'lumpsum' | 'multiplier';
  rewardValue: number;
  baseAttribute: string;
  multiplierRate: number;
  rewardCap: number;
}

/**
 * `excludeSegmentIds` is de-scoped for the MVP
 */
export interface TenantCampaignSegmentation {
  isAllActiveUsersEligible: boolean;
  includeSegmentIds: string[];
  excludeSegmentIds?: string[];
}

export interface GiftCardCampaignDenominationPercentage {
  denomination: number;
  discountType: 'multiplier';
  discountPercentage: number;
}
export interface GiftCardCampaignDenominationAmount {
  denomination: number;
  discountType: 'lumpsum';
  discountAmount: number;
}
export interface GiftCardCampaignQualificationItems {
  giftCardId: string;
  appliedDenominations: (GiftCardCampaignDenominationPercentage | GiftCardCampaignDenominationAmount)[];
}

export interface GiftCardCampaignQualification {
  type: Extract<TenantCampaignQualificationType, 'single' | 'all'>;
  giftCards: GiftCardCampaignQualificationItems[];
}

export interface TenantCampaignDisplay {
  title: string;
  description: string;
  coverImageUrl: string;
  termsAndConditions: string;
  ctaButton: boolean;
  ctaButtonText?: string;
  ctaButtonUrl?: string;
}

// TODO: Remove the `qualificationPeriod` after merging with PR #4132
export interface FixedTenantCampaignTimeConstruct {
  type: Extract<TenantCampaignTimingConstructType, 'fixed'>;
  expiryLogic: Extract<TenantCampaignExpiryLogicType, 'absolute'>;
  qualificationPeriod?: number;
}

export interface TriggerBasedTenantCampaignTimeConstruct {
  type: Extract<TenantCampaignTimingConstructType, 'trigger_based'>;
  qualificationPeriod: number;
  expiryLogic: TenantCampaignExpiryLogicType;
}

export type TenantCampaignTimeConstruct = FixedTenantCampaignTimeConstruct | TriggerBasedTenantCampaignTimeConstruct;

export type AccrualCampaignCommunicationValue = Pick<
  Extract<TenantCampaignDetails, { campaignType: 'accrual' }>,
  'displayable' | 'communicationEnabled' | 'display' | 'pointsActivityDescriptor'
>;

export const tenantCampaignCommunicationTypes = ['webhook', 'email', 'ipaas'] as const;
export type TenantCampaignCommunicationType = (typeof tenantCampaignCommunicationTypes)[number];

/**
 * `triggerPurposes` accepts "activation", "qualification", "reminder", "comms" but only "activation" and "comms" are supported for MVP
 */
export const triggerPurposes = ['activation', 'comms', 'qualification'] as const;
export type TriggerPurpose = (typeof triggerPurposes)[number];

export interface TenantCampaignCommunicationSetting {
  enabledChannels: TenantCampaignCommunicationType[];
  email?: {
    triggeringPurposes?: TriggerPurpose[];
  } & {
    [key in TriggerPurpose]?: TenantCampaignsEmailTriggerSettings;
  };
  webhook?: {
    triggeringPurposes?: TriggerPurpose[];
  } & {
    [key in TriggerPurpose]?: TenantCampaignsCommunicationTriggerSettings;
  };
}

export interface TenantCampaignsEmailTriggerSettings extends TenantCampaignsCommunicationTriggerSettings {
  templateId?: string;
  eventName?: string;
  subject: string;
  termsAndConditions: Nullable<string>;
}

export interface TenantCampaignsCommunicationTriggerSettings {
  messageTitle: string;
  messageBody: string;
  ctaEnabled: boolean;
  ctaText?: string;
  ctaUrl?: string;
}

export interface TenantCampaignsResponse {
  data: TenantCampaign[];
  metadata: {
    total: number;
    totalPages: number;
    currentPage: number;
  };
}

export type TenantCampaignCreateUpdateRequest = Omit<Partial<TenantCampaignDetails>, 'startTime' | 'endTime'> & {
  startTime?: string | null;
  endTime?: string | null;
};

export const defaultTenantCampaignsFilter: TenantCampaignsFilter = {
  description: null,
  code: null,
  statuses: [],
  campaignTypes: [],
  page: 1,
  limit: 20,
  sortBy: 'createdAt',
  sortDirection: 'desc'
};

export const commsCampaignTriggerEvents = [
  'login_attempt',
  'added_to_segment',
  'removed_from_segment',
  'points_expiring',
  'accrual_earn_rule',
  'performs_event'
] as const;
export type CommsCampaignTriggerEvents = (typeof commsCampaignTriggerEvents)[number];

export const commsCampaignTriggerEventOptions: Array<{
  title: string;
  value: CommsCampaignTriggerEvents;
}> = [
  {
    title: 'Logs into Loyalty',
    value: 'login_attempt'
  },
  {
    title: 'Added to Segment',
    value: 'added_to_segment'
  },
  {
    title: 'Removed from Segment',
    value: 'removed_from_segment'
  },
  {
    title: 'Points Expiring',
    value: 'points_expiring'
  },
  {
    title: 'Earns from earn rule',
    value: 'accrual_earn_rule'
  },
  {
    title: 'Performs Event',
    value: 'performs_event'
  }
];

export const tenantCampaignAdapter = createEntityAdapter<TenantCampaign>();

export interface TenantCampaignState extends EntityState<TenantCampaign>, CallState {
  total: number;
  filter: TenantCampaignsFilter;
  attributes: Record<
    Exclude<TenantCampaignType, 'gift_card'>,
    {
      attributeMap: Record<string, LogicRuleAttribute>;
      state: ComponentRenderState;
      error: HttpError | null;
    }
  >;
  isConsentSignedOff: boolean | null;
  editingId: string | null;
  isActivateSuccess: boolean | null;
}

export const initialState: TenantCampaignState = {
  ...tenantCampaignAdapter.getInitialState<CallState>(initialCallState),
  total: 0,
  filter: defaultTenantCampaignsFilter,
  attributes: {
    accrual: {
      attributeMap: {},
      state: 'initial',
      error: null
    },
    comms: {
      attributeMap: {},
      state: 'initial',
      error: null
    }
  },
  isConsentSignedOff: null,
  editingId: null,
  isActivateSuccess: null
};
