import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { PointsAccountsFilter } from '../../types/points-accounts-filter.type';
import { PointsAccountsResult } from '../../types/points-accounts-result.type';

export const loadPointsAccounts = createAction(
  '[Loyalty Data] Load Points Accounts',
  props<{ filter: PointsAccountsFilter }>()
);
export const loadPointsAccountsSuccess = createAction(
  '[Loyalty Data] Load Points Accounts Success',
  props<{ result: PointsAccountsResult }>()
);
export const loadPointsAccountsFailure = createAction(
  '[Loyalty Data] Load Points Accounts Failure',
  props<ErrorState>()
);
