import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface UserPassword {
  id: string;
  createdAt: string;
  updatedAt: string;
  reminderCount: number;
  expiringReminderCount: number;
}

export const userPasswordAdapter = createEntityAdapter<UserPassword>();

export interface UserPasswordState extends EntityState<UserPassword>, CallState {}

export const initialState: UserPasswordState = userPasswordAdapter.getInitialState<CallState>(initialCallState);
