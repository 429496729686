import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { AttributesResponse, LogicRule } from '@shared/types/logic-rule.type';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import {
  RecommendedSegmentCreateRequest,
  Segment,
  SegmentCreateRequest,
  SegmentEditRequest,
  SegmentRulesData,
  SegmentsFilter,
  SegmentsResponse
} from '../types';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  private missionControlService = inject(MissionControlService);

  getSegments(segmentsFilter: SegmentsFilter): Observable<SegmentsResponse> {
    const { sortBy, sortDirection, ...requestParams } = segmentsFilter;
    const sanitizedParams = ObjectUtils.sanitizeRequestObject(requestParams);
    const filterParams = FilterUtils.appendHashQueryParam(sanitizedParams, 'sortBy', sortBy, sortDirection);

    return this.missionControlService.get<SegmentsResponse>(
      'segments',
      new HttpParams({ fromObject: { ...filterParams }, encoder: new BracketParamsEncoder() })
    );
  }

  getSegment(segmentId: string): Observable<Segment> {
    return this.missionControlService.get<Segment>(`segments/${segmentId}`);
  }

  updateSegment(request: SegmentEditRequest): Observable<Segment> {
    const { id, ...segmentRequest } = request;

    return this.missionControlService.patch<Segment>(
      `segments/${id}`,
      ObjectUtils.sanitizeRequestObject(segmentRequest)
    );
  }

  deleteSegment(segmentId: string): Observable<void> {
    return this.missionControlService.delete(`segments/${segmentId}`);
  }

  createSegment(
    request: SegmentCreateRequest | RecommendedSegmentCreateRequest,
    recommended: boolean = false
  ): Observable<Segment> {
    if (recommended) {
      return this.missionControlService.post<Segment>('segments', request);
    }

    const { rules: rawRules, ...requestDetails } = request;
    return this.missionControlService.post<Segment>('segments', {
      ...ObjectUtils.sanitizeRequestObject(requestDetails),
      rules: this.preFormatSegmentRules(rawRules as LogicRule[])
    });
  }

  getPreview(rawRules: LogicRule[]): Observable<{ memberCount: number }> {
    return this.missionControlService.post<{ memberCount: number }>('segments/preview', {
      rules: this.preFormatSegmentRules(rawRules)
    });
  }

  getAttributes(): Observable<AttributesResponse[]> {
    return this.missionControlService.get<AttributesResponse[]>('segments/attributes');
  }

  formatRuleName(logics: LogicRule[], parentValue?: string): LogicRule[] {
    return logics.map(logic => {
      let attributeName = logic.attribute;
      if (
        parentValue /**
         * There are 3 cases, we need to support:
         * shared.amount -> amount
         * parent_value.amount -> amount
         * parent_value.custom_attributes.amount -> custom_attributes.amount
         * */ &&
        (logic.attribute.startsWith(`${parentValue}.`) || logic.attribute.startsWith('shared.'))
      ) {
        const attributeFullPath = logic.attribute.split('.');
        attributeFullPath.splice(0, 1);
        attributeName = attributeFullPath.join('.');
      }

      const childLogics =
        logic.conditions?.length > 0 ? this.formatRuleName(logic.conditions, String(logic.value)) : undefined;
      return {
        ...logic,
        attribute: attributeName,
        conditions: childLogics
      };
    });
  }

  /**
   *
   * @param conditions
   * @returns combination with the pre-defined connector
   * We need to wrap the meaningful logics into this wrapper because LD expect
   * the first level connector is always `and`
   * ```json
   * {
   *  connector: 'and',
   *  conditions: []
   * }
   * ```
   */
  private preFormatSegmentRules(conditions: LogicRule[]): SegmentRulesData | {} {
    if (conditions && conditions.length > 0) {
      return {
        connector: 'and',
        conditions: this.formatRuleName(conditions)
      };
    }

    return {};
  }
}
