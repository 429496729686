import { trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HttpError } from '@core/types';
import { expansionAnimation } from '@shared/animations/expansion-animations';

import { loadUserOffers } from '../../../offers/store/actions/offers.actions';
import { offersQuery } from '../../../offers/store/selectors/offers.selectors';
import { Offer, OffersFilter, OfferState, OfferStatusTypeCodes } from '../../../offers/types';

@Component({
  selector: 'admin-user-offers',
  templateUrl: 'user-offers.component.html',
  animations: [trigger('detail_expand', expansionAnimation)],
  styleUrls: ['user-offers.component.scss']
})
export class UserOffersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  offers$: Observable<Offer[]>;
  loading$: Observable<boolean>;
  error$: Observable<HttpError>;
  filteredOffers: MatTableDataSource<Offer>;
  offersCount$: Observable<number>;
  offerStatusTypeCodes = OfferStatusTypeCodes;
  displayedColumns = ['status', 'id', 'title', 'brand', 'score', 'action'];
  filter: OffersFilter = this.route.snapshot.data.offerFilter;
  expandedColumns = ['detail_expand'];

  constructor(
    private store: Store<OfferState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.offers$ = this.store.select(offersQuery.getOffersList);
    this.offers$.subscribe(offersList => (this.filteredOffers = new MatTableDataSource<Offer>(offersList)));

    this.loading$ = this.store.select(offersQuery.isBatchLoading);
    this.error$ = this.store.select(offersQuery.getBatchError);
    this.offersCount$ = this.store.select(offersQuery.getOffersCount);
  }

  ngAfterViewInit(): void {
    this.subscribeToMatSortChange();
  }

  subscribeToMatSortChange(): void {
    this.sort.sortChange.subscribe(event => {
      this.updateFilter({
        ...this.filter,
        page: 1,
        sortBy: event.direction ? event.active : '',
        sortDirection: event.direction
      });
    });
  }

  onPage(pageEvent: PageEvent): void {
    this.updateFilter({
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    });
  }

  updateFilter(params: object): void {
    this.filter = {
      ...this.filter,
      ...params
    };
    this.store.dispatch(loadUserOffers({ filter: this.filter }));
  }
}
