import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { McTranslationsService } from '../../services/mc-translations.service';
import {
  loadMcTranslations,
  loadMcTranslationsFailure,
  loadMcTranslationsSuccess
} from '../actions/mc-translations.actions';
import {
  bulkUpdateMcTranslations,
  bulkUpdateMcTranslationsFailure,
  bulkUpdateMcTranslationsSuccess
} from './../actions/mc-translations.actions';

@Injectable()
export class McTranslationsEffects {
  loadMcTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMcTranslations),
      exhaustMap(() =>
        this.mcTranslationsService.getMcTranslations().pipe(
          map(mcTranslations => loadMcTranslationsSuccess({ mcTranslations })),
          catchError(error => of(loadMcTranslationsFailure({ error })))
        )
      )
    )
  );

  bulkUpdateMcTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bulkUpdateMcTranslations),
      exhaustMap(({ payload }) =>
        this.mcTranslationsService.updateMcTranslations(payload).pipe(
          map(() => bulkUpdateMcTranslationsSuccess()),
          catchError(error => of(bulkUpdateMcTranslationsFailure({ error })))
        )
      )
    )
  );

  bulkUpdateMcTranslationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bulkUpdateMcTranslationsSuccess),
      exhaustMap(() => merge([routerForceNavigate({ path: '/translations' }), loadMcTranslations()]))
    )
  );

  constructor(
    private actions$: Actions,
    private mcTranslationsService: McTranslationsService
  ) {}
}
