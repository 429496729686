import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { AlfredParamConverter, AlfredParamsConfig, BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { FilteredGiftCardResponse } from '../../tenant-campaigns/types/gift-card-tenant-campaign.type';
import { GiftCardsFilter } from '../types/gift-cards-filter.type';
import { GiftCardsResult } from '../types/gift-cards-result.type';
import { GiftCard, UpdateGiftCardParams } from '../types/gift-cards.type';

@Injectable({
  providedIn: 'root'
})
export class GiftCardsService {
  constructor(private missionControlService: MissionControlService) {}

  getGiftCards(filter: GiftCardsFilter): Observable<GiftCardsResult> {
    const paramsConfig: AlfredParamsConfig = {
      name: '$like',
      categories: '$inc',
      displayTypes: '$inc'
    };

    let alfredParam = AlfredParamConverter.convertToAlfredParam(filter, paramsConfig) as GiftCardsFilter;
    alfredParam = ObjectUtils.sanitizeRequestObject(alfredParam);

    const { sortDirection, sortBy, ...rest } = alfredParam;
    let params = ObjectUtils.prepareQueryObject(rest);
    params = FilterUtils.appendHashQueryParam(params, 'sort[]', sortBy, sortDirection === 'asc' ? '1' : '-1');

    return this.missionControlService.get<GiftCardsResult>(
      'gift_cards',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getFilteredGiftCards(filter: GiftCardsFilter): Observable<FilteredGiftCardResponse> {
    const paramsConfig: AlfredParamsConfig = {
      name: '$like'
    };

    let alfredParam = AlfredParamConverter.convertToAlfredParam(filter, paramsConfig) as GiftCardsFilter;
    alfredParam = ObjectUtils.sanitizeRequestObject(alfredParam);

    const params = ObjectUtils.prepareQueryObject(alfredParam);

    return this.missionControlService.get<FilteredGiftCardResponse>(
      'tenant_campaigns/gift_cards',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getFilteredGiftCardsByIds(ids: string[]): Observable<FilteredGiftCardResponse> {
    const params = ObjectUtils.prepareQueryObject({
      page: 1,
      limit: 5,
      ids
    });

    return this.missionControlService.get<FilteredGiftCardResponse>(
      'tenant_campaigns/gift_cards',
      new HttpParams({ encoder: new BracketParamsEncoder(), fromObject: { ...params } })
    );
  }

  getGiftCard(id: string): Observable<GiftCard> {
    return this.missionControlService.get<GiftCard>(`gift_cards/${id}`);
  }

  updateGiftCard(params: UpdateGiftCardParams): Observable<GiftCard> {
    params = ObjectUtils.encodeAttributesAsBase64(params, ['redemptionInstructions']);
    return this.missionControlService.patch<GiftCard>(`gift_cards/${params.id}`, params);
  }

  downloadGiftCards(): Observable<Blob> {
    return this.missionControlService.get<Blob>('gift_cards/download', new HttpParams(), {}, 'blob');
  }
}
