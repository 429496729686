import { Component, inject, OnInit } from '@angular/core';

import { HostTenantMappingUtils, ImageUtils } from '@utils';

import { SideNavV2Store } from '../side-nav-v2.store';

@Component({
  selector: 'admin-side-nav-v2-header',
  template: `
    <div
      class="flex items-center justify-between gap-4 py-10"
      [ngClass]="isCollapsed() ? 'px-8' : 'pl-8 pr-[1.125rem]'"
    >
      <a routerLink="/" class="h-8 max-w-28">
        <img
          [src]="logoUrl"
          alt="Logo"
          class="h-full w-full object-contain"
          [ngClass]="isCollapsed() ? 'hidden' : 'block'"
        />
        <img
          [src]="collapsedLogoUrl"
          alt="Collapsed Logo"
          class="h-full w-full object-contain"
          [ngClass]="isCollapsed() ? 'block' : 'hidden'"
        />
      </a>
      @if (!isCollapsed()) {
        <button
          mat-icon-button
          class="toggle-button"
          data-testid="toggle-sidenav-button"
          (click)="toggleSideNav()"
          [matTooltip]="isCollapsed() ? 'Expand' : 'Collapse'"
          matTooltipClass="sidenav-toggle-button-tooltip"
          matTooltipPosition="right"
          matTooltipShowDelay="2000"
        >
          <mat-icon [svgIcon]="isCollapsed() ? 'expand-nav' : 'collapse-nav'"></mat-icon>
        </button>
      }
    </div>
  `,
  styleUrl: 'side-nav-v2-header.component.scss'
})
export class SideNavV2HeaderComponent implements OnInit {
  //#region Dependencies

  private sideNavStore = inject(SideNavV2Store);

  //#endregion

  //#region Properties

  isCollapsed = this.sideNavStore.isCollapsed;

  logoUrl: string | null = null;

  collapsedLogoUrl: string | null = null;

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit(): void {
    this.getLogoUrl();
  }

  //#endregion

  //#region Event Handlers and logo fetching

  getLogoUrl(): void {
    ImageUtils.getLogoFilenames().then(logos => {
      const prefix = HostTenantMappingUtils.getPrefix();

      this.logoUrl = `/assets/${logos[prefix]}`;
      this.collapsedLogoUrl = `/assets/${logos[`${prefix}-compact`]}`;
    });
  }

  toggleSideNav(): void {
    this.sideNavStore.toggleSideNav();
  }

  //#endregion
}
