import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ErrorActionTypes } from '@core/store/errors';
import { SuccessActionTypes } from '@core/store/success';
import { SharedModule } from '@shared/shared.module';

import { DisplayFullNoteDialogComponent } from './components/display-full-note-dialog/display-full-note-dialog.component';
import { EditNoteDialogComponent } from './components/edit-note-dialog/edit-note-dialog.component';
import { notesRoutes } from './notes.routes';
import { createNoteSuccess, deleteNoteSuccess, updateNoteSuccess } from './store/actions/notes.actions';
import { noteTriggers } from './store/reducers/notes.reducer';
import { NoteCreateEditComponent } from './views/note-create-edit/note-create-edit.component';
import { NoteMenuComponent } from './views/note-menu/note-menu.component';
import { NotesComponent } from './views/notes/notes.component';

@NgModule({
  imports: [SharedModule, RouterModule.forChild(notesRoutes)],
  declarations: [
    NotesComponent,
    EditNoteDialogComponent,
    NoteCreateEditComponent,
    NoteMenuComponent,
    DisplayFullNoteDialogComponent
  ],
  exports: [NotesComponent]
})
export class NotesModule {
  constructor(
    private errorActionTypes: ErrorActionTypes,
    private successActionTypes: SuccessActionTypes
  ) {
    this.errorActionTypes.registerActions([...noteTriggers.batch.erroring, ...noteTriggers.single.erroring]);
    this.successActionTypes.registerActions([createNoteSuccess.type, updateNoteSuccess.type, deleteNoteSuccess.type]);
  }
}
