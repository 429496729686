import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils, uuidv4 } from '@utils';

import { EarnRule, EarnRuleResult, EarnRulesPriorityResult, EarnRulesResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class EarnRulesService {
  constructor(private missionControlService: MissionControlService) {}

  getEarnRules(): Observable<EarnRulesResult> {
    return this.missionControlService.get<EarnRulesResult>('earn_rules');
  }

  getEarnRule(groupId: string): Observable<EarnRuleResult> {
    return this.missionControlService.get<EarnRuleResult>(`earn_rules/${groupId}`);
  }

  createEarnRule(earnRule: EarnRule): Observable<EarnRule> {
    earnRule = ObjectUtils.sanitizeRequestObject<EarnRule>(earnRule);
    earnRule.groupId = uuidv4(); // TODO remove when LE handles creation of groupId

    return this.missionControlService.post<EarnRule>('earn_rules', earnRule);
  }

  updateEarnRulesPriorities(result: EarnRulesPriorityResult): Observable<EarnRulesResult> {
    return this.missionControlService.post<EarnRulesResult>('earn_rules/update_priority', result);
  }

  updateEarnRule(earnRule: Partial<EarnRule>, version: number): Observable<EarnRuleResult> {
    return this.missionControlService.patch<EarnRuleResult>(`earn_rules/${earnRule.groupId}`, { earnRule, version });
  }

  deactivateEarnRule(groupId: string, version: number): Observable<EarnRuleResult> {
    return this.missionControlService.post<EarnRuleResult>(`earn_rules/${groupId}/deactivate`, { version });
  }
}
