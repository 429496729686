<button
  *ngIf="isTooltipsEditable"
  mat-button
  class="w-full"
  aria-label="Edit Tooltips"
  (click)="editTooltips()"
  [disabled]="loading$ | async"
  data-testid="button-edit-tooltips"
>
  <mat-icon class="prefix-icon" svgIcon="info">info_outline</mat-icon>
  <span class="header-text">Edit Tooltips</span>
</button>
