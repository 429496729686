import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { ScopesUtils } from '@scopes-utils';
import { Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadNotes } from '../store/actions/notes.actions';
import { NotesConfigs } from '../types/notes-configs.type';
import { NotesFilter } from '../types/notes-filter.type';
import { NoteState } from '../types/notes.type';

export const entityNotesResolver: ResolveFn<NotesFilter> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<NoteState> = inject(Store<NoteState>),
  scopes: Scopes = inject(Scopes)
): Observable<NotesFilter> => {
  // e.g., 'users', 'userId', { type: ['POA'] }
  const { entity, entityIdKey, baseFilter }: NotesConfigs = route.data.notesConfigs;
  // e.g., viewUsersNotes
  const scenarioScopeKey = ScopesUtils.getNotesScopeKey('view', entity);

  if (scopes.lackScopes(SCOPES_OR[scenarioScopeKey])) {
    return of(null);
  }

  const entityId = Params.find(route, entityIdKey);

  const filter = { ...new NotesFilter(), entityId, entity, ...baseFilter };
  store.dispatch(loadNotes({ filter }));
  return of(filter);
};
