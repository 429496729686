import { ImageValidationConfig } from '@shared/components/upload-images/types';

import { fetchWithTimeoutAndRetry } from '../../bootstrap';

export const ImageUtils = {
  async getLogoFilenames(): Promise<object> {
    return fetchWithTimeoutAndRetry('assets/logo-filenames.json', 3000, 3, 'logo', {
      'cache-control': 'no-cache no-store'
    });
  },

  isFileTypeInvalid(allowedTypes: string[], file: File): boolean {
    return allowedTypes && allowedTypes.length > 0 && !allowedTypes.includes(file.type);
  },

  isFileSizeInvalid(fileSizeLimit: number, file: File): boolean {
    return fileSizeLimit > 0 && file.size > fileSizeLimit;
  },

  areDimensionsInvalid(image: HTMLImageElement, imageValidationConfig: ImageValidationConfig): boolean {
    const { maxHeight, maxWidth } = imageValidationConfig;
    const { height, width } = image;

    return (!!maxHeight && height > maxHeight) || (!!maxWidth && width > maxWidth);
  }
};
