import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, of } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { loadSharedConfig } from '@core/store/shared-config/actions/shared-config.actions';
import { ChangesUtils } from '@utils';

import { loadNydusNetworkPartnerConfiguration } from '../../../rewards-offering/store/actions/nydus-network-partner-configurations.actions';
import { PartnerConfigsService } from '../../services/partner-configs.service';
import { PartnerConfigState, UiSettings } from '../../types';
import {
  activateProduct,
  activateProductFailure,
  activateProductSuccess,
  loadPartnerConfig,
  loadPartnerConfigFailure,
  loadPartnerConfigSuccess,
  updateUiSettings,
  updateUiSettingsFailure,
  updateUiSettingsSuccess,
  updateWebsiteStyling,
  updateWebsiteStylingFailure,
  updateWebsiteStylingSuccess
} from '../actions/partner-configs.actions';
import { partnerConfigsQuery } from '../selectors/partner-configs.selectors';

@Injectable()
export class PartnerConfigsEffects {
  loadPartnerConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPartnerConfig),
      exhaustMap(() =>
        this.partnerConfigsService.getPartnerConfig().pipe(
          map(result => loadPartnerConfigSuccess({ partnerConfig: result })),
          catchError(error => of(loadPartnerConfigFailure(error)))
        )
      )
    )
  );

  updateWebsiteStyling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWebsiteStyling),
      withLatestFrom(
        this.store.select(partnerConfigsQuery.getUiSettings),
        this.store.select(partnerConfigsQuery.getVersion)
      ),
      exhaustMap(([{ payload, redirectPath }, originalUiSettings, version]) => {
        const updatedUiSettings: UiSettings = {
          ...originalUiSettings,
          application: {
            ...originalUiSettings.application,
            ...payload
          }
        };
        const changes = ChangesUtils.getChanges(originalUiSettings, updatedUiSettings);

        return this.partnerConfigsService.updateWebsiteStyling({ changes, version, ...payload }).pipe(
          map(() => updateWebsiteStylingSuccess({ redirectPath })),
          catchError(error => of(updateWebsiteStylingFailure(error)))
        );
      })
    )
  );

  updateWebsiteStylingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWebsiteStylingSuccess),
      exhaustMap(({ redirectPath }) =>
        merge([...(redirectPath ? [routerForceNavigate({ path: redirectPath })] : []), loadPartnerConfig()])
      )
    )
  );

  activateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateProduct),
      exhaustMap(({ product, version, value }) =>
        this.partnerConfigsService.activateProduct(product, version, value).pipe(
          map(_ => activateProductSuccess(version, product)),
          catchError(error => of(activateProductFailure(error)))
        )
      )
    )
  );

  activateProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateProductSuccess),
      exhaustMap(({ version, product }) =>
        merge([
          loadSharedConfig(),
          loadNydusNetworkPartnerConfiguration(),
          // the data object is for the component to determine whether to display a success snackbar on Kafka widget close
          routerForceNavigate({
            path: 'rewards-offering',
            data: { success: true, submitted: { version, product } }
          })
        ])
      )
    )
  );

  activateProductFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateProductFailure),
      map(() =>
        routerForceNavigate({
          path: 'rewards-offering',
          data: { success: false }
        })
      )
    )
  );

  updateUiSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUiSettings),
      withLatestFrom(this.store.select(partnerConfigsQuery.getVersion)),
      exhaustMap(([{ uiSettings, changes }, version]) =>
        this.partnerConfigsService.updateUiSettings({ changes, version, uiSettings }).pipe(
          map(() => updateUiSettingsSuccess()),
          catchError(error => of(updateUiSettingsFailure({ error })))
        )
      )
    )
  );

  updateUiSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUiSettingsSuccess),
      map(() => loadPartnerConfig())
    )
  );

  constructor(
    private actions$: Actions,
    private partnerConfigsService: PartnerConfigsService,
    private store: Store<PartnerConfigState>
  ) {}
}
