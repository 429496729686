import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import {
  AuthenticationProvider,
  authenticationProviderAdapter,
  AuthenticationProviderState
} from '../../types/authentication-providers.type';

const authenticationProvidersDetector = createDetector<AuthenticationProvider>();
const getAuthenticationProviderState = createFeatureSelector<AuthenticationProviderState>('authenticationProvider');

const { selectAll: getAuthenticationProvidersList, selectEntities: getAuthenticationProvidersDictionary } =
  authenticationProviderAdapter.getSelectors(getAuthenticationProviderState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError
} = getCallStateSelectors<AuthenticationProviderState>(getAuthenticationProviderState);

const isAuthenticationProvidersLoaded = createSelector(
  getAuthenticationProvidersDictionary,
  isBatchResting,
  (authenticationProviders, batchResting) =>
    authenticationProvidersDetector('allLoaded')(authenticationProviders) && batchResting
);

const isAuthenticationProviderLoaded = (id: string): Selector<AuthenticationProviderState, boolean> =>
  createSelector(getAuthenticationProvidersDictionary, authenticationProviders =>
    authenticationProvidersDetector('oneLoaded')(authenticationProviders, id)
  );

const getFetchList = createSelector(
  getAuthenticationProviderState,
  (state: AuthenticationProviderState) => state.fetchList
);

const getAuthenticationProviderByReference = (
  reference: string
): Selector<AuthenticationProviderState, AuthenticationProvider> =>
  createSelector(getAuthenticationProvidersDictionary, entities => entities[reference]);

export const authenticationProvidersQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isAuthenticationProvidersLoaded,
  isAuthenticationProviderLoaded,
  getFetchList,
  getAuthenticationProviderByReference,
  getAuthenticationProvidersList,
  getAuthenticationProvidersDictionary,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError
};
