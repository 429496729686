<mat-form-field class="field" [ngClass]="getErrorMessage() ? 'ng-invalid mat-form-field-invalid' : ''">
  <mat-label>{{ fieldLabel | customTitleCase: 'fromCamelCase' }}</mat-label>
  <input
    matInput
    autocorrect="off"
    autocapitalize="none"
    spellcheck="false"
    [attr.aria-required]="required"
    [disabled]="disabled"
    [id]="formControlName"
    [required]="required"
    [type]="inputType"
    [value]="value"
  />
</mat-form-field>

<mat-error class="error-message" role="alert" *ngIf="getErrorMessage()">
  {{ getErrorMessage() }}
</mat-error>
