import { createAction, props } from '@ngrx/store';

import { AbilityGroups, ErrorState } from '@core/types';

export const loadUserAbilities = createAction('[User Abilities] Load User Abilities');
export const loadUserAbilitiesSuccess = createAction(
  '[User Abilities] Load User Abilities Success',
  props<{ userAbilities: AbilityGroups }>()
);
export const loadUserAbilitiesFailure = createAction(
  '[User Abilities] Load User Abilities Failure',
  props<ErrorState>()
);
