import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { CustomDomain, CustomDomainResponse } from '../types';

@Injectable({
  providedIn: 'root'
})
export class CustomDomainService {
  constructor(private missionControlService: MissionControlService) {}

  updateCustomDomain(domain: string, version: number): Observable<CustomDomainResponse> {
    return this.missionControlService.patch<CustomDomain>('domain', { domain, version });
  }
}
