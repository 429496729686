<div
  class="flex cursor-pointer items-center gap-4 border-0 border-t-[0.5px] border-solid border-cloudburst-400 px-4 py-6"
  [ngClass]="{ 'justify-center': isCollapsed() }"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  (click)="navigateToUserProfile()"
>
  <img class="h-8 w-8 flex-shrink-0 rounded-full object-contain" alt="Profile Picture" [src]="userProfilePicture()" />

  @if (!isCollapsed()) {
    <div class="flex-1">
      <p class="footer-username">{{ userName() }}</p>
      <p class="footer-tenant-name">{{ tenantName() }}</p>
    </div>
    <button
      mat-icon-button
      data-testid="toggle-profile-button"
      class="toggle-profile-button"
      (click)="$event.stopPropagation(); toggleProfileMenu()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  }
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOpen]="isProfileMenuOpened()"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'bg-transparent'"
  (backdropClick)="isProfileMenuOpened.set(false)"
>
  <div
    class="box-border flex w-[280px] flex-col gap-2 overflow-auto border-0 border-t border-solid border-cloudburst-400 bg-cloudburst-900 py-2"
  >
    <div class="flex items-center gap-4 px-4">
      <div class="flex h-10 justify-center rounded-sm bg-white">
        <img [src]="tenantLogo()" class="max-h-10 w-14 overflow-hidden object-contain" />
      </div>
      <p class="profile-panel-tenant-name">{{ tenantName() }}</p>
    </div>
    <div class="flex flex-col px-1" (click)="isProfileMenuOpened.set(false)">
      <!-- TODO: add switch tenant here when we have the feature -->
      @if (piiMaskingEnabled) {
        <a
          *adminIfScopesInclude="['step_up_for_pii']"
          class="profile-menu-item"
          [ngClass]="{ disabled: isPiiUnmasked() }"
          data-testid="stepup-button"
          role="button"
          [tabindex]="isPiiUnmasked() ? -1 : 0"
          (click)="stepUpForPiiDialog($event)"
        >
          <span class="profile-menu-item-text">Unmask PII</span>
        </a>
      }
      <a class="profile-menu-item" data-testid="logout-button" role="button" tabindex="0" (click)="logout()">
        <span class="profile-menu-item-text">Log out</span>
      </a>
    </div>
  </div>
</ng-template>
