import { Component, Input } from '@angular/core';

import { NavService } from '@core/services/nav/nav.service';
import { HostTenantMappingUtils } from '@utils';

@Component({
  selector: 'admin-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  isRC = HostTenantMappingUtils.isRC();

  @Input() centerContentInViewPort = false; // False when the footer is displayed with the side nav, true when it is displayed over the full screen

  constructor(private navService: NavService) {}

  get isNavMini(): boolean {
    return this.navService.isMini;
  }
}
