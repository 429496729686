import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

import { ColumnDef, SortEvent } from '@shared/types';

@Component({
  selector: 'admin-table',
  templateUrl: './table.component.html',
  styles: [':host { width: 100%; }', 'tr.cdk-header-row { position: sticky; top: 0; z-index: 10; }']
})
export class TableComponent implements OnChanges {
  @Input({ required: true }) entity: string;
  @Input({ required: true }) columnDefs: ColumnDef[];
  @Input({ required: true }) data: any[];
  @Input() tableHeight: string = 'auto';
  @Output() sortChange = new EventEmitter<SortEvent>();

  columnDefKeys: string[];

  sortState = {
    active: null,
    direction: null
  };

  ngOnChanges(): void {
    this.columnDefKeys = this.columnDefs.map(column => column.key);
  }

  handleSortClick(key: string): void {
    const { active, direction } = this.sortState;

    if (active === key) {
      switch (direction) {
        case 'asc': {
          this.sortState.direction = 'desc';
          break;
        }
        case 'desc': {
          this.sortState.active = null;
          this.sortState.direction = null;
          break;
        }
        default: {
          this.sortState.direction = 'asc';
          break;
        }
      }
    } else {
      this.sortState.active = key;
      this.sortState.direction = 'asc';
    }

    this.sortChange.emit(this.sortState);
  }
}
