import { Inject, Pipe, PipeTransform } from '@angular/core';
import { UAParser } from 'ua-parser-js';

import { IconUtils } from '@utils';

export type UserAgentInfoType = 'browser' | 'browserIcon' | 'os' | 'osIcon';

@Pipe({ name: 'userAgentInfo' })
export class UserAgentInfoPipe implements PipeTransform {
  convertFns: { [key in UserAgentInfoType]: (userAgent: string, parser: UAParser) => string } = {
    browser: this.getBrowser,
    browserIcon: this.getBrowserIcon,
    os: this.getOS,
    osIcon: this.getOSIcon
  };

  constructor(@Inject(UAParser) private parser: UAParser) {}

  transform(userAgent: string, infoType: UserAgentInfoType): any {
    if (!userAgent) {
      return '';
    }

    const convertFn = this.convertFns[infoType];
    return convertFn(userAgent, this.parser);
  }

  getBrowser(userAgent: string, parser: UAParser): string {
    parser.setUA(userAgent);
    return Object.values(parser.getBrowser()).join(' ').trim();
  }

  getBrowserIcon(userAgent: string): string {
    return IconUtils.getBrowserIcon(userAgent);
  }

  getOS(userAgent: string, parser: UAParser): string {
    parser.setUA(userAgent);
    return Object.values(parser.getOS()).join(' ').trim();
  }

  getOSIcon(userAgent: string): string {
    return IconUtils.getOSIcon(userAgent);
  }
}
