import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';
import { startWith, Subject, takeUntil, tap } from 'rxjs';

import { MatChipUtils } from '@core/services/mat-chip-utils/mat-chip-utils';
import { ObjectUtils, SetUtils } from '@utils';

export function validateFields(control: AbstractControl): null | object {
  if (!control.value) {
    return null;
  }

  const duplicateValues = SetUtils.findDuplicateValueSet(control.value);
  return duplicateValues.size > 0 ? { duplicateValueError: true } : null;
}

@Component({
  selector: 'admin-input-chip-list',
  templateUrl: './input-chip-list.component.html',
  styleUrls: ['./input-chip-list.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputChipListComponent
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: { separatorKeyCodes: [ENTER, COMMA] }
    },
    {
      provide: NG_VALIDATORS,
      useValue: validateFields,
      multi: true
    }
  ]
})
export class InputChipListComponent
  implements ControlValueAccessor, MatFormFieldControl<string[]>, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('autocompleteTrigger', { read: MatAutocompleteTrigger }) autocompleteTrigger: MatAutocompleteTrigger;

  @Input() allowAnyInput = true;
  @Input() selectableValues: string[];
  @Input() nonRemovableValues: string[];
  @Input('aria-describedby') userAriaDescribedBy: string; // eslint-disable-line @angular-eslint/no-input-rename
  @Input() valueFormattingObj: Record<string, string>;

  focused = false;
  touched = false;
  options: string[] = [];
  value: string[];
  duplicateValues: Set<string> = new Set();
  valueFormattings: Record<string, string>;

  stateChanges = new Subject<void>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  readonly ObjectUtils = ObjectUtils;

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  static nextId = 0;
  @HostBinding() id = `admin-input-chip-list-${InputChipListComponent.nextId++}`;

  get empty(): boolean {
    return this.value?.length === 0;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(val: boolean | string) {
    this._required = coerceBooleanProperty(val);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(val: string) {
    this._placeholder = val;
    this.stateChanges.next();
  }

  private _placeholder: string;

  get searchValue(): string {
    return this.input?.nativeElement.value.toLowerCase();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return this.ngControl && this.ngControl.invalid && (this.ngControl.dirty || this.touched);
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public elementRef: ElementRef<HTMLElement>,
    public matChipUtils: MatChipUtils,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  /* eslint-enable */

  /* non-interface implementations */
  @HostListener('input')
  onInput(): void {
    this.setOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueFormattingObj?.currentValue) {
      this.valueFormattings = this.valueFormattingObj;
    } else if (changes.selectableValues?.currentValue && !this.valueFormattingObj) {
      this.valueFormattings = Object.fromEntries(this.selectableValues.map(value => [value, value]));
    }
  }

  ngAfterViewInit(): void {
    this.ngControl.control.valueChanges
      .pipe(
        startWith(null),
        takeUntil(this.destroy$), // there is an issue when using UntilDestroy in this component, so we use takeUntil
        tap(() => (this.duplicateValues = SetUtils.findDuplicateValueSet(this.value)))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setOptions(): void {
    if (this.disabled) {
      return;
    }

    let options = this.selectableValues?.filter(value => !this.ngControl.control.value.includes(value)); // non selected options

    if (this.searchValue) {
      options = options?.filter(val => this.valueFormattings[val]?.toLowerCase().includes(this.searchValue)); // autocomplete options
    }

    this.options = options;
  }

  onChipTokenEnd(value: string): void {
    if (this.ngControl.control.hasError('invalidValueType')) {
      return;
    }

    if (!this.allowAnyInput && !this.isSearchValueValid(this.searchValue)) {
      return;
    }

    value = this.convertToOriginalValue(value);
    this.matChipUtils.addToChipList(this.input.nativeElement, value, this.ngControl.control);
    this.closeAutocompletePanel();
    this.input.nativeElement.focus();
  }

  selectAutocompleteOption(val: string): void {
    this.matChipUtils.addValueToChipFormControl(val, this.ngControl.control);
    this.clearInputValue();
  }

  removeChip(index: number): void {
    this.matChipUtils.removeFromChipList(index, this.ngControl.control);
    this.cdRef.detectChanges();
    this.closeAutocompletePanel();
    this.input.nativeElement.focus();
  }

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
    this.clearInputValue();
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.focused = false;
      this.touched = true;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();

    const currentText = this.input.nativeElement.value;
    const pastedText = event.clipboardData.getData('Text').replaceAll(/\s*,\s*/g, ',') || '';

    const text = `${currentText}${pastedText}`;
    text.split(',').forEach(value => {
      if (this.input) this.input.nativeElement.value = value;
      this.onChipTokenEnd(value);
    });
  }

  clearInputValue(): void {
    this.input.nativeElement.value = '';
  }

  /* interface implementations */
  onChange = (_value: string[]): void => {};

  onTouched = (): void => {};

  onContainerClick(): void {
    this.setOptions(); // prevent delay in set options after add or remove chip
    this.input.nativeElement.focus(); // bring focus to input when area around it clicked
    this.autocompleteTrigger.openPanel();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDescribedByIds(ids: string[]): void {
    this.userAriaDescribedBy = ids.join(' ');
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string[]): void {
    this.value = value;
  }

  private closeAutocompletePanel(): void {
    this.setOptions();
    this.autocompleteTrigger.closePanel();
    this.input.nativeElement.blur();
  }

  private isSearchValueValid(value: string): boolean {
    return this.selectableValues?.map(val => this.valueFormattings[val]?.toLowerCase())?.includes(value);
  }

  private convertToOriginalValue(value: string): string {
    if (!this.valueFormattings) {
      return value;
    }

    const invertedValueFormattings = ObjectUtils.invertKeyValue(this.valueFormattings);
    for (const [formattedValue, originalValue] of Object.entries(invertedValueFormattings)) {
      if (value.toLowerCase() === formattedValue.toLowerCase()) {
        return originalValue;
      }
    }
  }
}
