import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, debounceTime, map } from 'rxjs';

import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';

import { injectUntilDestroyed } from '../../../../utils/observable-utils';
import { Params } from '../../../../utils/params';
import { bootstrapsQuery } from '../../../gift-cards/store/selectors/bootstraps.selectors';
import { BootstrapState } from '../../../gift-cards/types/bootstraps.type';
import { loadUserOfferActivities } from '../../store/actions/user-offer-activities.actions';
import { userOfferActivitiesQuery } from '../../store/selectors/user-offer-activities.selectors';
import {
  UserOfferActivityState,
  UserOfferActivitiesFilter,
  UserOfferActivitiesForm,
  UserOfferActivity
} from '../../types/user-offer-activities.type';

@UseV2Style
@Component({
  selector: 'admin-user-offer-activities',
  templateUrl: './user-offer-activities.component.html',
  styleUrls: ['./user-offer-activities.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'regular-dropdown-panel' }
    }
  ]
})
export class UserOfferActivitiesComponent implements OnInit, AfterViewInit {
  loading$: Observable<boolean>;
  actions$: Observable<Array<string>>;
  filterForm: FormGroup<UserOfferActivitiesForm>;
  filter: UserOfferActivitiesFilter = this.route.snapshot.data.userOfferActivitiesFilter;
  userId: string;
  userActivities: UserOfferActivity[];
  totalCount: number;

  private untilDestroyed = injectUntilDestroyed();

  constructor(
    @Inject('timezone') public timezone: string,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userActivityStore: Store<UserOfferActivityState>,
    private bootstrapStore: Store<BootstrapState>
  ) {}

  ngOnInit(): void {
    this.userId = Params.find(this.route, 'userId');
    this.loading$ = this.userActivityStore.select(userOfferActivitiesQuery.isBatchLoading);
    this.actions$ = this.bootstrapStore
      .select(bootstrapsQuery.getOffersBootstrap)
      .pipe(map(bootstrap => bootstrap?.actions || []));

    this.prepareFilterForm();
    this.subscribeToUserActivities();
  }

  ngAfterViewInit(): void {
    this.subscribeToInputChanges();
  }

  prepareFilterForm(): void {
    this.filterForm = this.fb.group({
      title: this.fb.control(''),
      brand: this.fb.control(''),
      activity: this.fb.control('')
    });

    this.filterForm.patchValue(this.filter);
  }

  onPage(pageEvent: PageEvent): void {
    this.updateFilter({
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    });
  }

  subscribeToInputChanges(): void {
    this.filterForm.valueChanges
      .pipe(
        debounceTime(1000), // 1 second interval
        this.untilDestroyed()
      )
      .subscribe(searchFields => {
        this.updateFilter({ ...searchFields, page: 1 });
      });
  }

  subscribeToUserActivities(): void {
    combineLatest([
      this.userActivityStore.select(userOfferActivitiesQuery.getUserOfferActivitiesByUserId(this.userId)),
      this.userActivityStore.select(userOfferActivitiesQuery.getMetadata)
    ])
      .pipe(this.untilDestroyed())
      .subscribe(([userActivities, metadata]) => {
        this.userActivities = userActivities;
        this.totalCount = metadata?.total;
      });
  }

  updateFilter(params: object): void {
    this.filter = {
      ...this.filter,
      ...params
    };

    this.userActivityStore.dispatch(loadUserOfferActivities({ userId: this.userId, filter: this.filter }));
  }
}
