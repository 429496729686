import { Component, Inject } from '@angular/core';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';

interface DialogData {
  title: string;
  label: string;
  types: string[];
  redirectPathPrepend: string;
}

@Component({
  selector: 'admin-select-type-dialog',
  templateUrl: './select-type-dialog.component.html',
  styleUrls: ['./select-type-dialog.component.scss'],
  providers: [{ provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { appearance: 'legacy' } }]
})
export class SelectTypeDialogComponent {
  selectedType: string;

  constructor(
    public dialogRef: MatDialogRef<SelectTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
