import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, fromEvent, NEVER, switchMap } from 'rxjs';

import { injectUntilDestroyed } from '@utils';

import { SideNavV2HelperService } from './side-nav-v2-helper.service';
import { SideNavV2Store } from './side-nav-v2.store';

@Component({
  selector: 'admin-side-nav-v2',
  templateUrl: 'side-nav-v2.component.html',
  styleUrl: 'side-nav-v2.component.scss',
  providers: [SideNavV2HelperService, SideNavV2Store]
})
export class SideNavV2Component implements OnInit {
  //#region Dependencies

  private sideNavStore = inject(SideNavV2Store);
  private router = inject(Router);
  private untilDestroyed = injectUntilDestroyed();

  //#endregion

  //#region Side Nav state

  @ViewChild('sidenav', { static: true, read: ElementRef }) sideNav: ElementRef;

  isCollapsed = this.sideNavStore.isCollapsed;

  isCurrentSelectedGroupScrolling = this.sideNavStore.isCurrentSelectedGroupScrolling;

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit(): void {
    this.scrollToTopWhenRouteChanged();
    this.reOpenSideNavWhenHovered();
  }

  //#endregion

  //#region Event Handlers

  scrollToTopWhenRouteChanged(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        // avoid overriding the scroll behaviour from TableOfContentsComponent
        filter((event: NavigationEnd) => !/tenants\/edit.*#/g.test(event.url)),
        this.untilDestroyed()
      )
      .subscribe(() => window.scrollTo({ top: 0, behavior: 'smooth' }));
  }

  reOpenSideNavWhenHovered(): void {
    this.sideNavStore.isCollapsed$
      .pipe(
        switchMap(isCollapsed => (isCollapsed ? fromEvent(this.sideNav.nativeElement, 'mouseenter') : NEVER)),
        this.untilDestroyed()
      )
      .subscribe(() => {
        this.sideNavStore.toggleSideNav();
      });
  }

  //#endregion
}
