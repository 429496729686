import { Component, Input, OnInit } from '@angular/core';

import { CashRedemptionOrderItemData, OrderItem } from '../../types';

@Component({
  selector: 'admin-cash-redemption-order-item',
  templateUrl: './cash-redemption-order-item.component.html',
  styleUrls: ['../../stylesheets/order-item.styles.scss']
})
export class CashRedemptionOrderItemComponent implements OnInit {
  @Input() orderItem: OrderItem;
  @Input() customerId: string;
  @Input() timezone: string;

  data: CashRedemptionOrderItemData;

  ngOnInit(): void {
    this.data = this.orderItem.data as CashRedemptionOrderItemData;
  }
}
