import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';
import { Token, TokenRequest } from '@core/types/generate-token.type';

import { ConnectionCredential } from '../../types/connection-credentials.type';

export const loadConnectionCredentials = createAction(
  '[Connection Credentials] Load ConnectionCredentials',
  props<{ appConnectionId: string; invalidated?: boolean }>()
);
export const loadConnectionCredentialsSuccess = createAction(
  '[Connection Credentials] Load ConnectionCredentials Success',
  props<{ connectionCredentials: ConnectionCredential[] }>()
);
export const loadConnectionCredentialsFailure = createAction(
  '[Connection Credentials] Load ConnectionCredentials Failure',
  props<ErrorState>()
);

export const createConnectionCredentials = createAction(
  '[Connection Credentials] Create ConnectionCredentials',
  props<{ connectionRequest: TokenRequest }>()
);
export const createConnectionCredentialsSuccess = createAction(
  '[Connection Credentials] Create ConnectionCredentials Success',
  props<{ connectionCredentialToken: Token }>()
);
export const createConnectionCredentialsFailure = createAction(
  '[Connection Credentials] Create ConnectionCredentials Failure',
  props<ErrorState>()
);
