import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { LogicRule } from '@shared/types/logic-rule.type';

import { loadSegmentAttributes, loadSegmentPreview } from '../store/actions/segment-creation.actions';
import { createSegment } from '../store/actions/segments.actions';
import { segmentCreationQuery } from '../store/selectors/segment-creation.selector';
import { segmentsQuery } from '../store/selectors/segments.selectors';
import { RecommendedSegmentCreateRequest, SegmentCreateRequest, SegmentState } from '../types';
import { SegmentCreationState } from '../types/segment-creation.type';

@Injectable({
  providedIn: 'root'
})
export class SegmentCreationService {
  private store = inject<Store<SegmentCreationState>>(Store);
  private segmentStore = inject<Store<SegmentState>>(Store);

  /**
   * The `segmentPreviewMemberState` has four states for presenting:
   * - `initial` - fresh state or untouched state
   * - `loaded` - finishing fetching process
   * - `loading` - is loading
   * - `error` - has error
   */
  segmentPreviewMemberState = this.store.selectSignal(segmentCreationQuery.getSegmentPreviewState);
  segmentPreviewMemberCount = this.store.selectSignal(segmentCreationQuery.getSegmentPreviewMemberCount);
  segmentPreviewHttpErrors = this.store.selectSignal(segmentCreationQuery.getSegmentPreviewError);
  segmentPreviewMemberLoadErrors = computed(() =>
    this.segmentPreviewHttpErrors() ? this.segmentPreviewHttpErrors().errors : null
  );

  segmentAttributes = this.store.selectSignal(segmentCreationQuery.getSegmentAttributeMap);
  segmentAttributesState = this.store.selectSignal(segmentCreationQuery.getSegmentAttributeState);
  segmentAttributesHttpErrors = this.store.selectSignal(segmentCreationQuery.getSegmentAttributeError);
  segmentAttributesLoadErrors = computed(() =>
    this.segmentAttributesHttpErrors() ? this.segmentAttributesHttpErrors().errors : null
  );

  segmentLoading$ = this.segmentStore.select(segmentsQuery.isSingleLoading);

  loadSegmentAttributes(): void {
    this.store.dispatch(loadSegmentAttributes());
  }

  loadSegmentPreview(logics: LogicRule[]): void {
    this.store.dispatch(loadSegmentPreview({ logics }));
  }

  createSegment(request: SegmentCreateRequest | RecommendedSegmentCreateRequest, recommended?: boolean): void {
    this.store.dispatch(createSegment({ request, recommended }));
  }
}
