import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { BootstrapsService } from '../../services/bootstraps.service';
import { loadBootstrap, loadBootstrapFailure, loadBootstrapSuccess } from '../actions/bootstraps.actions';

@Injectable()
export class BootstrapsEffects {
  getBootstrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBootstrap),
      exhaustMap(action =>
        this.bootstrapsService.getBootstrap(action.tenantId).pipe(
          map(bootstrap => loadBootstrapSuccess({ bootstrap })),
          catchError(error => of(loadBootstrapFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private bootstrapsService: BootstrapsService
  ) {}
}
