import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, first, map, Observable, of } from 'rxjs';

import { FetchUserService } from '@shared/services/fetch/fetch-user.service';
import { Params } from '@utils';

import { getUsersRoute } from '../../users/types';

export const customerAgentRedirectionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const fetchUserService: FetchUserService = inject(FetchUserService);
  const router: Router = inject(Router);

  // Redirect users based on status
  // If user status is admin, redirect to /agents path
  // If user status is not admin, redirect to /customers path
  // Note: If user is not found, redirect to /customers path (to show the error page)
  const userId = Params.find(route, 'userId');
  const currentSegments = state.url.split('/');
  const detailsPath = currentSegments.slice(2, currentSegments.length).join('/');
  return fetchUserService.fetch(userId).pipe(
    first(),
    map(user => {
      const usersPath = user ? getUsersRoute(user) : 'customers';
      router.navigateByUrl(`${usersPath}/${detailsPath}`);
      return true;
    }),
    catchError(() => {
      router.navigateByUrl(`customers/${detailsPath}`);
      return of(false);
    })
  );
};
