import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DestroyRef, inject, Input, OnInit, Optional, Self } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, ControlContainer, ControlValueAccessor, NgControl } from '@angular/forms';
import { debounceTime, startWith } from 'rxjs';

import {
  ImageRadioPillInputDimensions,
  RadioPillInputOption,
  RadioPillInputValue
} from '@shared/types/radio-pill-input.type';

@Component({
  selector: 'admin-radio-pill-input',
  templateUrl: './radio-pill-input.component.html',
  styleUrls: ['./radio-pill-input.component.scss']
})
export class RadioPillInputComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() options: RadioPillInputOption[] = [];
  @Input() pillWidth: string;
  @Input() imagePillDimensions: ImageRadioPillInputDimensions = {};
  @Input() highlightOnSelected = true;

  /**
   * 1. [customControlName] is a supplement to RadioPillInputComponent which allows user to input custom value via the other form field component.
   *    Please bind form control to the supplement form field component, and provide [customControlName] in RadioPillInputComponent  in this case.
   *
   * 2. Example:
   *  ```html
   *  <form [formGroup]="form">
   *    <admin-radio-pill-input label="Radius"
   *                            pillWidth="120px"
   *                            [imagePillDimensions]="{ pillWidth: '9px', pillHeight: '9px', imageWidth: '5px', imageHeight: '5px' }"
   *                            [highlightOnSelected]="false"
   *                            [options]="radiusOptions"
   *                            customControlName="radius"
   *    >
   *    </admin-radio-pill-input>
   *    <mat-form-field>
   *      <input matInput adminIntegerInput formControlName="radius" type="number" min="1">
   *    </mat-form-field>
   *  </form>
   *  ```
   *
   *  3. default value is the first option value
   */
  @Input() customControlName: string;

  destroyRef = inject(DestroyRef);

  customControl: AbstractControl;
  value: RadioPillInputValue;
  optionValues: RadioPillInputValue[];
  customControlValue: RadioPillInputValue;

  hasInitialRequiredError = false; // flag for displaying the initial required error when there is custom control

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private controlContainer: ControlContainer
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.optionValues = this.options.map(option => option.value);

    if (this.customControlName) {
      this.customControl = this.controlContainer.control!.get(this.customControlName)!;
      this.customControl.setValue(this.customControl.value || this.options[0].value); // set default value

      this.customControl.valueChanges
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          startWith(this.customControl.value),
          debounceTime(0) // delay to ensure it is executed after the custom control value is updated by parent component
        )
        .subscribe(value => {
          this.value = value;

          if (this.optionValues.includes(this.value)) {
            this.customControl.disable({ emitEvent: false });
          } else {
            this.customControlValue = this.value;
          }
        });
    } else if (!this.ngControl.control!.value) {
      this.ngControl.control!.setValue(this.value); // set default value
    }
  }

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  handleClick(value: RadioPillInputValue): void {
    this.hasInitialRequiredError = false;

    this.value = value;

    if (this.customControl) {
      this.customControl.setValue(this.value);
      this.customControl.disable();
      return;
    }

    this.onChange(value);
  }

  handleCustomPillClick(): void {
    const customControlElement: HTMLElement = document.querySelector(`[formControlName=${this.customControlName}]`)!;

    this.hasInitialRequiredError = false;

    this.value = this.customControlValue ?? null;
    this.customControl.setValue(this.value);
    this.customControl.enable();
    customControlElement.focus();
    customControlElement.click();
  }

  /* interface implementations */
  onChange = (_value: RadioPillInputValue): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: RadioPillInputValue): void {
    this.value = value || this.options[0].value;
  }
}
