import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { DomainsService } from '../../../domains/services/domains.service';
import { fetchDomain } from '../../../domains/store/actions/domains.actions';
import { domainsQuery } from '../../../domains/store/selectors/domains.selectors';
import { Domain, DomainState } from '../../../domains/types/domains.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({
  providedIn: 'root'
})
export class DomainTagDefs {
  readonly tagDefs: TagDefs<Domain> = {
    domain_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showDomains)
          ? combineLatest([
              this.domainsStore.select(domainsQuery.getDomainById(id)),
              this.domainsStore.select(domainsQuery.getFetchList)
            ]).pipe(
              first(),
              map(([domain, fetchList]) => {
                if (!domain && !fetchList.includes(id)) {
                  this.domainsStore.dispatch(fetchDomain({ id }));
                }
              })
            )
          : of(null),
      select: id => this.domainsStore.pipe(select(domainsQuery.getDomainById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showDomains)
          ? this.domainsStore.pipe(
              select(domainsQuery.getDomainById(id)),
              first(),
              exhaustMap(domain => (domain ? of(domain) : this.domainsService.getDomain(id)))
            )
          : of(null),
      getDisplayPrefix: () => 'Domain',
      getDisplayValue: (domain: Domain) => domain.domain,
      getRouteLink: (domain: Domain) => `/domains/${domain.id}/details`
    }
  };

  constructor(
    private domainsStore: Store<DomainState>,
    private domainsService: DomainsService,
    private scopes: Scopes
  ) {}
}
