import { AbstractControl, ValidatorFn } from '@angular/forms';

export function nameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    // Include only alphanumeric, dash and underscore
    return control.value.match(/^[a-zA-Z0-9-_]+$/) ? null : { invalidName: true };
  };
}
