import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState, Change } from '@core/types';
import { Nullable, Tag, TagType } from '@shared/types';

import { AuditLogsFilter } from './audit-logs-filter.type';

class AuditLogBase {
  createdAt: string;
  updatedAt: string;
  id: string;
  adminUserId: string;
  userId: string;
  message: string;
  action: string;
  entity: string;
  params: {
    [key: string]: any;
    changes?: Change[];
  };
  tenantId: string;
  request: {
    ip: string;
    host: string;
    userAgent: string;
    location: {
      city: string;
      country: string;
      countryCode: string;
      lon: number;
      lat: number;
    };
  };
}

// represents an audit log as it's handled internally in AP
export class AuditLog extends AuditLogBase {
  tags: Tag[];
}

// represents an audit log as it's stored in MC database
export class MissionControlAuditLog extends AuditLogBase {
  tags: {
    [key in TagType]?: string;
  };
}

export enum AuditLogEntity {
  Abilities = 'abilities',
  AccessPolicies = 'access_policies',
  AppConnections = 'app_connections',
  ApprovalRequests = 'approval_requests',
  Apps = 'apps',
  AuthenticationProviders = 'authentication_providers',
  CampaignProposals = 'campaign_proposals',
  Campaigns = 'campaigns',
  CategorisedLoyaltyPrograms = 'categorised_loyalty_programs',
  Domains = 'domains',
  EarnRules = 'earn_rules',
  EventBasedEarnRules = 'event_earn_rules',
  EventTypes = 'event_types',
  FraudLists = 'fraud_lists',
  FraudRules = 'fraud_rules',
  GhCache = 'cache_keys',
  GiftCardOrders = 'gift_card_orders',
  GiftCards = 'gift_cards',
  GiftCardsConfig = 'gift_cards_config',
  Identities = 'identities',
  Keys = 'keys',
  LoyaltyPrograms = 'loyalty_programs',
  McTenants = 'mc_tenants',
  Members = 'members', // Refers to segment members
  Memberships = 'memberships',
  Merchandises = 'merchandises',
  MergeConfigs = 'merge_configs',
  MfaIdentities = 'mfa_identities',
  Notes = 'notes',
  Notifications = 'notifications',
  Notify = 'notify',
  OfferSources = 'offer_sources',
  Offers = 'offers',
  OrderItems = 'order_items',
  OutgoingRequestSettings = 'outgoing_request_settings',
  // TODO: replace this with `partner_config` after UI settings migration is finished
  PartnerConfiguration = 'partner_configuration',
  PartnerConfig = 'partner_config',
  Passwords = 'passwords',
  PaymentTiers = 'payment_tiers',
  Permissions = 'permissions',
  PointsActivities = 'points_activities',
  PointsAdjustment = 'points_adjustment',
  PointsSummary = 'points_summary',
  PromoCodes = 'promo_codes',
  FAQ = 'questions',
  Recommendations = 'recommendations',
  RedemptionRates = 'redemption_rates',
  Roles = 'roles',
  Segments = 'segments',
  Suppliers = 'suppliers',
  TenantCampaigns = 'tenant_campaigns',
  Tenants = 'tenants',
  Tickets = 'tickets',
  Tokens = 'tokens',
  Translations = 'translations',
  UiSettings = 'ui_settings',
  Users = 'users',
  Watchlists = 'watchlists',
  // TODO: come up with a better mapping here to ensure all ticket-related audit logs can be searched by one entity
  ZendeskTickets = 'zendesk_tickets',
  ZendeskTicketsV2 = 'zendesk_tickets_v2'
}

export const auditLogAdapter = createEntityAdapter<AuditLog>();

export interface AuditLogState extends EntityState<AuditLog>, CallState {
  filter: Nullable<AuditLogsFilter>; // not used yet but can leave as-is for now
  count: number;
}

export const initialState: AuditLogState = {
  ...auditLogAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  count: 0
};
