import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Question } from '../../types';

export const loadQuestions = createAction('[Questions] Load Questions');
export const loadQuestionsSuccess = createAction(
  '[Questions] Load Questions Success',
  props<{ questions: Question[] }>()
);
export const loadQuestionsFailure = createAction('[Questions] Load Questions Failure', props<ErrorState>());

export const activateQuestions = createAction('[Questions] Activate Questions', props<{ ids: string[] }>());
export const activateQuestionsSuccess = createAction('[Questions] Activate Questions Success');
export const activateQuestionsFailure = createAction('[Questions] Activate Questions Failure', props<ErrorState>());
