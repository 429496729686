import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Dayjs } from 'dayjs';

import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS, MAT_DAYJS_YEAR_PICKER_FORMATS } from '../../../../adaptors';

@Component({
  selector: 'admin-year-picker',
  templateUrl: './year-picker.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_YEAR_PICKER_FORMATS },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class YearPickerComponent {
  @ViewChild('yearPicker') datepicker: MatDatepicker<any>;
  @Input() inputControl: UntypedFormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() required?: boolean;
  @Input() styleClassName?: string;
  @Output() yearSelected = new EventEmitter<Dayjs>();

  onYearSelected(date: Dayjs): void {
    this.inputControl.get(this.controlName)!.setValue(date);
    // Todo: update this and the spec later to check on datepicker close
    this.datepicker?.close();
    this.yearSelected.emit(date);
  }
}
