import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, PartnerConfigState } from '../../types';
import {
  activateProduct,
  activateProductFailure,
  activateProductSuccess,
  loadPartnerConfig,
  loadPartnerConfigFailure,
  loadPartnerConfigSuccess,
  updateWebsiteStyling,
  updateWebsiteStylingFailure,
  updateWebsiteStylingSuccess
} from '../actions/partner-configs.actions';

export const partnerConfigTriggers: EntityTriggers = {
  single: {
    loading: [loadPartnerConfig.type, updateWebsiteStyling.type, activateProduct.type],
    resting: [loadPartnerConfigSuccess.type, updateWebsiteStylingSuccess.type, activateProductSuccess.type],
    erroring: [loadPartnerConfigFailure.type, updateWebsiteStylingFailure.type, activateProductFailure.type]
  }
};

export function partnerConfigsReducer(state: PartnerConfigState = initialState, action: Action): PartnerConfigState {
  return callStateReducer(baseReducer, partnerConfigTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadPartnerConfigSuccess, (state, { partnerConfig }) => ({ ...state, partnerConfig }))
);
