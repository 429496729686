import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface RemoteFile {
  name: string;
  type: string;
  modifiedTime: string;
  sizeInBytes: number;
}

export interface RemoteFileState extends CallState {
  remoteFiles: RemoteFile[];
}

export const remoteFileInitialState: RemoteFileState = {
  ...initialCallState,
  remoteFiles: null
};
