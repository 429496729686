import { ChangeDetectorRef, DestroyRef, Directive, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl, ValidationErrors } from '@angular/forms';
import { filter, startWith } from 'rxjs';

@Directive({
  selector: '[adminSyncHostControlErrors]'
})
export class SyncHostControlErrorsDirective implements OnInit {
  @Input() syncErrorStatusOnly = false;

  private hostControl = inject(NgControl, {
    optional: true,
    skipSelf: true
  });

  private currentControl = inject(NgControl);

  private destroyRef = inject(DestroyRef);

  private prevHostControlErrors: ValidationErrors = {};

  private cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (!this.hostControl) {
      throw new Error('SyncHostControlErrorsDirective must be used with CVA component and it has inner form control');
    }

    this.syncHostControlErrorsWithControl();
  }

  syncHostControlErrorsWithControl(): void {
    this.hostControl!.statusChanges!.pipe(
      startWith(this.hostControl!.status),
      filter(status => ['VALID', 'INVALID'].includes(status)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      if (this.syncErrorStatusOnly) {
        this.applyOnlyHostErrorStatus();
      } else {
        this.applyHostErrors();
      }

      // Mark the control as touched so that the error message will be displayed
      if (this.hostControl!.touched) {
        this.currentControl.control!.markAsTouched();
      }
      this.cdr.detectChanges();
    });
  }

  private applyHostErrors(): void {
    const controlErrors = this.currentControl.errors ?? {};

    // Filter out all previous host errors on current control
    const filteredControlErrors = Object.entries(controlErrors).reduce((newError, [key, value]) => {
      if (this.prevHostControlErrors[key]) {
        return newError;
      }

      return {
        ...newError,
        [key]: value
      };
    }, {} as ValidationErrors);

    const hostControlErrors = this.hostControl!.errors ?? {};

    // Apply new errors from host control
    const newErrors = { ...filteredControlErrors, ...hostControlErrors };

    this.prevHostControlErrors = hostControlErrors;

    // Set new errors to current control
    this.currentControl.control!.setErrors(Object.keys(newErrors).length > 0 ? newErrors : null);
  }

  private applyOnlyHostErrorStatus(): void {
    if (this.hostControl!.invalid && this.currentControl.valid) {
      this.currentControl.control!.setErrors({});
      return;
    }

    if (
      this.hostControl!.valid &&
      this.currentControl.invalid &&
      Object.keys(this.prevHostControlErrors).length === 0
    ) {
      this.currentControl.control!.setErrors(null);
      return;
    }
  }
}
