import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicy, SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class AccessPolicyTagDefs {
  readonly tagDefs: TagDefs<AccessPolicy> = {
    access_policy_id: {
      getDisplayPrefix: () => 'Access Policy',
      getDisplayValue: (tag: Tag) => tag.id,
      getRouteLink: (tags: Tag[]) => {
        if (!this.scopes.hasAny(SCOPES_OR.showAccessPolicies)) {
          return null;
        }

        const accessPolicyTag = tags.find(tag => tag.type === 'access_policy_id');
        return accessPolicyTag ? `/access-policies/${accessPolicyTag.id}` : null;
      }
    }
  };

  constructor(private scopes: Scopes) {}
}
