import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface OfferBootstrap {
  tenants: string[];
  suppliers: string[];
  categories: string[];
  channels: string[];
  actions: string[];
  type: string[];
  status: string[];
  countries: string[];
}

export interface GiftCardBootstrap {
  brands: string[];
  categories: string[];
  countries: string[];
  currencies: string[];
  tenants: string[];
  marginTypes: string[];
  markupTypes: string[];
  orderStatuses: string[];
  redeemableNames: string[];
  redemptionChannels: string[];
  suppliers: string[];
}

export interface MerchandiseBootstrap {
  suppliers: string[];
  brands: string[];
}

export interface Bootstrap {
  offers?: OfferBootstrap;
  giftCards?: GiftCardBootstrap;
  merchandise?: MerchandiseBootstrap;
}

export interface BootstrapState extends Bootstrap, CallState {}

export const initialState: BootstrapState = { ...initialCallState };
