<mat-card appearance="outlined" class="mat-elevation-z1 mat-card">
  <mat-card-content>
    <mat-card-title>Passwords</mat-card-title>
    <div class="data-table-container">
      <div *ngIf="loading$ | async" class="progress-loader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <table mat-table [dataSource]="passwords$ | async" *ngIf="!(loading$ | async)" class="data-table">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Id</h4>
          </th>
          <td mat-cell *matCellDef="let password">{{ password.id }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Created at</h4>
          </th>
          <td mat-cell *matCellDef="let password">
            <span matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
              {{ password.createdAt | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Updated at</h4>
          </th>
          <td mat-cell *matCellDef="let password">
            <span matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
              {{ password.updatedAt | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="reminderCount">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <h4 class="text-primary">Reminder count</h4>
              <admin-tooltip key="users.passwords.tableHeader.reminderCount"></admin-tooltip>
            </div>
          </th>
          <td mat-cell *matCellDef="let password">{{ password.reminderCount }}</td>
        </ng-container>

        <ng-container matColumnDef="expiringReminderCount">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <h4 class="text-primary">Expiring reminder count</h4>
              <admin-tooltip key="users.passwords.tableHeader.expiringReminderCount"></admin-tooltip>
            </div>
          </th>
          <td mat-cell *matCellDef="let password">{{ password.expiringReminderCount }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns" class="bg-secondary"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columns; let entry"
          class="data-table__row"
          [ngClass]="{ highlight: entry.id === passwordId }"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No passwords available</td>
        </tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
