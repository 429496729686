import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { PointsAccountsService } from '../../services/points-accounts.service';
import {
  loadPointsAccounts,
  loadPointsAccountsFailure,
  loadPointsAccountsSuccess
} from '../actions/points-accounts.actions';

@Injectable()
export class PointsAccountsEffects {
  loadPointsAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPointsAccounts),
      exhaustMap(({ filter }) =>
        this.pointsAccountsService.getPointsAccounts(filter).pipe(
          map(result => loadPointsAccountsSuccess({ result })),
          catchError(error => of(loadPointsAccountsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pointsAccountsService: PointsAccountsService
  ) {}
}
