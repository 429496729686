import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';

import { FraudRulesService } from '../../../fraud-rules/services/fraud-rules.service';
import { fetchFraudRule } from '../../../fraud-rules/store/actions/fraud-rules.actions';
import { fraudRulesQuery } from '../../../fraud-rules/store/selectors/fraud-rules.selectors';
import { FraudRule, FraudRuleState } from '../../../fraud-rules/types/fraud-rules.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class FraudRuleTagDefs {
  readonly tagDefs: TagDefs<FraudRule> = {
    fraud_rule_id: {
      fetchData: (id: string) =>
        this.scopes.has('view_fraud_rules')
          ? combineLatest([
              this.fraudRuleStore.select(fraudRulesQuery.getFraudRuleById(id)),
              this.fraudRuleStore.select(fraudRulesQuery.getFetchList)
            ]).pipe(
              first(),
              map(([fraudRule, fetchList]) => {
                if (!fraudRule && !fetchList.includes(id)) {
                  this.fraudRuleStore.dispatch(fetchFraudRule({ id }));
                }
              })
            )
          : of(null),
      select: id => this.fraudRuleStore.pipe(select(fraudRulesQuery.getFraudRuleById(id))),
      fetch: id =>
        this.scopes.has('view_fraud_rules')
          ? this.fraudRuleStore.pipe(
              select(fraudRulesQuery.getFraudRuleById(id)),
              first(),
              exhaustMap(fraudRule => (fraudRule ? of(fraudRule) : this.fraudRulesService.getFraudRule(id)))
            )
          : of(null)
    }
  };

  constructor(
    private fraudRuleStore: Store<FraudRuleState>,
    private fraudRulesService: FraudRulesService,
    private scopes: Scopes
  ) {}
}
