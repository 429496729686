export const SCENARIO_SCOPES_GROUPS = {
  Analytics: ['view_analytics'],
  Users: [
    'view_users',
    'view_agents',
    'view_team_members',
    'view_insights',
    'manual_adjustment', // dashboard module
    'manage_identities',
    'update_users',
    'update_users:username',
    'manage_team_members',
    'manage_user_state',
    'impersonate_user',
    'impersonate_user:read_only',
    'view_auto_redemption_settings', // auto-redemptions module
    'view_memberships',
    'manage_memberships', // memberships module
    'view_reward_triggers',
    'manage_reward_triggers',
    'view_notifications', // notifications module
    'view_fraud_evaluations', // fraud-evaluations module
    'create_user',
    'view_user_transactions',
    'view_pii',
    'step_up_for_pii',
    'view_passwords',
    'reset_passwords',
    'view_user_tokens',
    'manage_user_tokens'
  ],
  Segments: ['view_segments', 'manage_segments', 'view_recommended_segments', 'manage_recommended_segments'],
  Enrollments: ['view_enrollments', 'manage_enrollments'],
  'Program Settings': [
    'view_rewards_program',
    'manage_rewards_program',
    'view_earn_rules',
    'manage_earn_rules',
    'view_custom_domains',
    'manage_custom_domains'
  ],
  'Website Styling': ['view_partner_configs', 'manage_partner_configs'],
  'RC Rewards Offering': [
    'view_cashback_products',
    'manage_cashback_products',
    'activate_cashback_products',
    'view_crypto_products',
    'manage_crypto_products',
    'activate_crypto_products',
    'view_gift_cards_configs',
    'manage_gift_cards_configs',
    'activate_gift_cards',
    'view_affiliate_offers_configs',
    'view_card_linked_offers_configs',
    'manage_affiliate_offers_configs',
    'manage_card_linked_offers_configs',
    'activate_affiliate_offers_configs',
    'activate_card_linked_offers_configs',
    'view_frequent_traveler_programs',
    'manage_frequent_traveler_programs',
    'activate_frequent_traveler_programs',
    'view_sustainability_products',
    'manage_sustainability_products',
    'activate_sustainability_products',
    'view_cars_configs',
    'manage_cars_configs',
    'manage_cars_pricing',
    'activate_cars',
    'view_flights_configs',
    'manage_flights_configs',
    'manage_flights_pricing',
    'activate_flights',
    'view_hotels_configs',
    'manage_hotels_configs',
    'manage_hotels_pricing',
    'activate_hotels'
  ],
  'Legacy Rewards Offering': [
    'view_gift_cards',
    'manage_gift_cards',
    'view_loyalty_programs',
    'manage_loyalty_programs',
    'view_merchandises',
    'manage_merchandises',
    'view_offers',
    'manage_offers',
    'view_gift_card_orders',
    'manage_gift_card_orders',
    'resend_gift_card_order_webhooks',
    'manage_loyalty_programs_synchronisations' // This applies for RC Rewards Offering too, but defining just here to avoid duplication
  ],
  Orders: ['view_orders', 'manage_orders', 'update_order_item_status', 'resend_order_email'],
  Pricing: ['view_pricing', 'manage_pricing'],
  'Suppliers Management': ['view_suppliers', 'manage_suppliers'],
  'Marketing Centre': [
    'view_event_types',
    'manage_event_types',
    'view_tenant_campaigns',
    'manage_tenant_campaigns',
    'view_recommended_tenant_campaigns',
    'manage_recommended_tenant_campaigns',
    'view_campaigns',
    'manage_campaigns',
    'view_campaign_proposals',
    'manage_campaign_proposals',
    'view_promo_codes',
    'manage_promo_codes'
  ],
  Logs: ['view_logs'],
  'Tenant Management': [
    'view_tenants',
    'manage_tenants',
    'manage_ui_settings',
    'manage_questions',
    'manage_tenant_handover',
    'manage_payment_tiers'
  ],
  'Fraud Services': [
    'view_fraud_rules',
    'manage_fraud_rules',
    'view_fraud_analytics',
    'view_watchlists',
    'manage_watchlists'
  ],
  'Access Control': [
    'manage_admins',
    'update_agents',
    'view_roles',
    'manage_roles',
    'view_abilities_and_access_policies',
    'manage_abilities_and_access_policies'
  ],
  'Apps Overview': ['view_apps', 'manage_apps', 'view_mc_tenants', 'manage_mc_tenants'],
  Developers: ['view_partner_app', 'view_webhook_settings', 'manage_webhook_settings'],
  Notes: [
    'view_notes',
    'manage_notes',
    'view_user_notes',
    'create_user_notes',
    'manage_user_notes',
    'view_campaign_notes',
    'manage_campaign_notes',
    'view_offer_notes',
    'manage_offer_notes'
  ],
  'Approval Requests': ['view_approval_requests', 'manage_approval_requests'],
  'Headless Points Bank': ['manage_account_upgrade']
};

export const SCENARIO_SCOPES = Object.values(SCENARIO_SCOPES_GROUPS).flat();
