import { catchError, exhaustMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { routerNavigate } from '@core/store/router/actions/router.actions';

import { CategorisedLoyaltyProgramState } from '../../../rewards-offering/types';
import { LoyaltyProgramsService } from '../../services/loyalty-programs.service';
import {
  createLoyaltyProgram,
  createLoyaltyProgramFailure,
  createLoyaltyProgramSuccess,
  fetchLoyaltyProgram,
  fetchLoyaltyProgramFailure,
  fetchLoyaltyProgramSuccess,
  loadLoyaltyProgram,
  loadLoyaltyProgramFailure,
  loadLoyaltyPrograms,
  loadLoyaltyProgramsFailure,
  loadLoyaltyProgramsSuccess,
  loadLoyaltyProgramSuccess,
  synchroniseLoyaltyPrograms,
  synchroniseLoyaltyProgramsFailure,
  synchroniseLoyaltyProgramsSuccess,
  updateLoyaltyProgram,
  updateLoyaltyProgramFailure,
  updateLoyaltyProgramSuccess
} from '../actions/loyalty-programs.actions';

@Injectable()
export class LoyaltyProgramsEffects {
  loadLoyaltyPrograms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLoyaltyPrograms),
      exhaustMap(({ locale }) =>
        this.loyaltyProgramsService.getLoyaltyPrograms(locale).pipe(
          map(result => loadLoyaltyProgramsSuccess({ result, locale })),
          catchError(error => of(loadLoyaltyProgramsFailure({ error })))
        )
      )
    )
  );

  loadLoyaltyProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLoyaltyProgram),
      exhaustMap(({ id, locale }) =>
        this.loyaltyProgramsService.getLoyaltyProgram(id, locale).pipe(
          map(loyaltyProgram => loadLoyaltyProgramSuccess({ loyaltyProgram, locale })),
          catchError(error => of(loadLoyaltyProgramFailure({ error })))
        )
      )
    )
  );

  fetchLoyaltyProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLoyaltyProgram),
      mergeMap(({ id }) =>
        this.loyaltyProgramsService.getLoyaltyProgram(id).pipe(
          map(loyaltyProgram => fetchLoyaltyProgramSuccess({ loyaltyProgram })),
          catchError(() => of(fetchLoyaltyProgramFailure({ id })))
        )
      )
    )
  );

  createLoyaltyProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLoyaltyProgram),
      exhaustMap(action =>
        this.loyaltyProgramsService.createLoyaltyProgram(action.loyaltyProgram).pipe(
          map(loyaltyProgram => createLoyaltyProgramSuccess({ loyaltyProgram })),
          catchError(error => of(createLoyaltyProgramFailure({ error })))
        )
      )
    )
  );

  updateLoyaltyProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLoyaltyProgram),
      exhaustMap(({ loyaltyProgram, locale }) =>
        this.loyaltyProgramsService.updateLoyaltyProgram(loyaltyProgram, locale).pipe(
          map(() => updateLoyaltyProgramSuccess({ loyaltyProgram })),
          catchError(error => of(updateLoyaltyProgramFailure({ error })))
        )
      )
    )
  );

  createOrUpdateLoyaltyProgramSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLoyaltyProgramSuccess, updateLoyaltyProgramSuccess),
      map(() => routerNavigate({ path: 'loyalty-programs' }))
    )
  );

  synchroniseLoyaltyPrograms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(synchroniseLoyaltyPrograms),
      withLatestFrom(this.store.select(authQuery.getUserTenantId)),
      exhaustMap(([_action, tenantId]) =>
        this.loyaltyProgramsService.synchroniseLoyaltyPrograms(tenantId).pipe(
          map(() => synchroniseLoyaltyProgramsSuccess()),
          catchError(error => of(synchroniseLoyaltyProgramsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private loyaltyProgramsService: LoyaltyProgramsService,
    private store: Store<CategorisedLoyaltyProgramState>
  ) {}
}
