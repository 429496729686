import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState } from '@core/types';

export interface FraudRule {
  id: string;
  name: string;
  enabled: boolean;
  data: object;
  description: string;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
}

export const fraudRuleAdapter = createEntityAdapter<FraudRule>();

export interface FraudRuleState extends EntityState<FraudRule>, CallState, FetchState {}

export const initialState: FraudRuleState = {
  ...fraudRuleAdapter.getInitialState<CallState>(initialCallState),
  ...fraudRuleAdapter.getInitialState<FetchState>(initialFetchState)
};
