import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { RewardsProgram, UpdateRewardsProgramParams } from '../types';

@Injectable({
  providedIn: 'root'
})
export class RewardsProgramService {
  constructor(private missionControlService: MissionControlService) {}

  updateRewardsProgram(params: UpdateRewardsProgramParams, version: number): Observable<RewardsProgram> {
    params = ObjectUtils.sanitizeRequestObject<UpdateRewardsProgramParams>(params, { ignoredKeys: ['gtmContainerId'] });
    return this.missionControlService.patch<RewardsProgram>('rewards_program', { ...params, version });
  }
}
