import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

export const loadGhCaches = createAction('[GH Cache] Load GH Caches');
export const loadGhCachesSuccess = createAction('[GH Cache] Load GH Caches Success', props<{ ghCaches: string[] }>());
export const loadGhCachesFailure = createAction('[GH Cache] Load GH Caches Failure', props<ErrorState>());

export const clearGhCache = createAction('[GH Cache] Clear GH Cache', props<{ key: string }>());
export const clearGhCacheSuccess = createAction('[GH Cache] Clear GH Cache Success');
export const clearGhCacheFailure = createAction('[GH Cache] Clear GH Cache Failure', props<ErrorState>());
