import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { LogicRuleBuilderHelperService } from '@shared/services/logic-rule-builder-helper/logic-rule-builder-helper.service';
import { Nullable } from '@shared/types';
import { LogicRuleDateRangeValue, LogicRuleValue } from '@shared/types/logic-rule.type';
import { DateUtils, injectUntilDestroyed } from '@utils';

import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS, MAT_DAYJS_DATE_FORMATS } from '../../../../adaptors';

@Component({
  selector: 'admin-logic-rule-datetime-input-field',
  template: `
    @if (isCustomDateTimeOperator) {
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput type="number" [formControl]="valueControl" min="1" />
        <span matSuffix>&nbsp;days</span>
        @if (valueControl.hasError('required')) {
          <mat-error>Please enter a value.</mat-error>
        }
        @if (valueControl.hasError('min')) {
          <mat-error>Must be greater than or equal to 1.</mat-error>
        }
      </mat-form-field>
    } @else if (isIncludedTime) {
      <admin-date-time-input [formControl]="valueControl" [minDate]="null" />
    } @else {
      <mat-form-field>
        <mat-label>Date</mat-label>
        <input matInput [formControl]="valueControl" [matDatepicker]="datePicker" (focus)="datePicker.open()" />
        <mat-datepicker-toggle [for]="datePicker" class="details-view-icon-button">
          <mat-icon svgIcon="datetime" matDatepickerToggleIcon></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        @if (valueControl.hasError('required')) {
          <mat-error>Please enter a valid date.</mat-error>
        }
      </mat-form-field>
    }
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LogicRuleDateTimeInputFieldComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LogicRuleDateTimeInputFieldComponent,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS }
  ]
})
export class LogicRuleDateTimeInputFieldComponent implements OnChanges, OnInit, ControlValueAccessor, Validator {
  @Input({ required: true }) operator: string;

  @Input() isIncludedTime = true;

  isCustomDateTimeOperator = false;

  valueControl = new FormControl<number | string | Date | null>(null, [Validators.required]);

  isComponentInitialized = false;

  onChange?: (value: Extract<LogicRuleValue, string | LogicRuleDateRangeValue>) => void;

  onTouched?: () => void;

  onValidatorChange?: () => void;

  private untilDestroyed = injectUntilDestroyed();

  private logicRuleBuilderHelperService = inject(LogicRuleBuilderHelperService);

  constructor(@Inject('timezone') private timezone: string) {}

  /// Lifecycle hooks ///

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.operator) {
      this.isCustomDateTimeOperator = this.logicRuleBuilderHelperService.isCustomDateTimeOperator(this.operator);

      this.handleOperatorChange();
    }
  }

  ngOnInit(): void {
    this.isComponentInitialized = true;

    this.subscribeToValueChanges();
  }

  /// Component specific functions ///

  // If the operator type isn't changed or component hasn't initialized yet, we don't need to do anything
  // else we need to reset the value to the default value
  handleOperatorChange(): void {
    if (!this.isComponentInitialized) {
      return;
    }

    // Must use setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.valueControl.reset(this.getDefaultDateTimeValue());
      this.onValidatorChange?.();
    });
  }

  subscribeToValueChanges(): void {
    this.valueControl.valueChanges.pipe(this.untilDestroyed()).subscribe(value => {
      // TODO: once we have support for 'month', we can remove this and we will extract
      // this to a separate component
      // We need to convert the value to the following format: [value, 'days' | 'month']
      // when the attribute is datetime or date and operator is a custom datetime operator
      let dateTimeValue: Nullable<Extract<LogicRuleValue, string | LogicRuleDateRangeValue>> = null;
      if (value) {
        if (this.isCustomDateTimeOperator) {
          dateTimeValue = [value as number, 'days'];
        } else if (this.isIncludedTime) {
          dateTimeValue = value as string;
        } else {
          dateTimeValue = formatDate(value as Date, 'yyyy-MM-dd', 'en');
        }
      }

      this.onChange?.(dateTimeValue!);
      this.onTouched?.();
    });
  }

  /// Control Value Accessor functions ///

  writeValue(value: string | LogicRuleDateRangeValue | null): void {
    const dateTimeValue = value && this.isCustomDateTimeOperator ? (value[0] as number) : (value as string);

    this.valueControl.setValue(dateTimeValue, {
      emitEvent: false
    });
  }

  registerOnChange(fn: (value: Extract<LogicRuleValue, string | LogicRuleDateRangeValue>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.valueControl.disable({
        emitEvent: false
      });
    } else {
      this.valueControl.enable({
        emitEvent: false
      });
    }
  }

  /// Validator functions ///

  validate(): Nullable<ValidationErrors> {
    return this.valueControl.invalid ? { invalidLogicRuleDateTimeValue: true } : null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  /// Utils ///

  getDefaultDateTimeValue(): string | null {
    if (this.isCustomDateTimeOperator) {
      return null;
    }

    return this.isIncludedTime
      ? DateUtils.overrideTimezoneAndStripTime(24, new Date().toISOString(), this.timezone)
      : formatDate(DateUtils.getDateInTenantTimezone(new Date(), this.timezone)!, 'yyyy-MM-dd', 'en');
  }
}
