import { Injectable } from '@angular/core';

import { GuardhouseError } from '@core/errors/guardhouse-error';

import { IRollbarService } from '../../services/rollbar/rollbar.service';

@Injectable()
export class MockRollbarService implements IRollbarService {
  handleError(err: any): void {
    console.error(err);
  }

  manualError(...args: any[]): void {
    console.log(...args);
  }

  manualGuardhouseError(error: GuardhouseError, ...args: any[]): void {
    console.log(error.fingerprint);
    console.log(error.error.metadata);
    console.log(...args);
  }
}
