import { Component } from '@angular/core';

import { SCOPES_OR, Tab } from '@core/types';
import { MenuComponent } from '@shared/components/menu/menu-component';

@Component({
  selector: 'admin-note-menu',
  templateUrl: './note-menu.component.html'
})
export class NoteMenuComponent extends MenuComponent {
  tabs: Tab[] = [
    {
      path: 'listing',
      icon: 'note',
      label: 'Notes',
      displayed: this.scopes.hasAny(SCOPES_OR.viewNotes)
    },
    this.auditLogsTab
  ];
}
