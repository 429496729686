import { debounceTime, filter, map } from 'rxjs/operators';

import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { QueryParamService } from '@shared/services/query-param/query-param.service';
import { DateUtils } from '@utils';

import { uuidValidator } from '../../../validators';
import { loadEvents } from '../store/actions/events.actions';
import { eventsQuery } from '../store/selectors/events.selectors';
import { EventForm } from '../types/event-forms.type';
import { EventsFilter } from '../types/events-filter.type';
import { AppType, Event, EventState } from '../types/events.type';
import { EventDetailsDialogComponent } from './event-details-dialog.component';

@Component({
  selector: 'admin-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  loading$: Observable<boolean>;
  events$: Observable<Event[]>;
  pageLength$: Observable<number>;
  filter: EventsFilter;
  userId: string;
  filterForm: FormGroup<EventForm>;
  isUserEventView: boolean;
  appTypes = Object.values(AppType);

  displayedColumns = ['timestamp', 'device info', 'app', 'action', 'log type', 'message', 'tags', 'details'];

  constructor(
    private eventsStore: Store<EventState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private queryParamService: QueryParamService,
    private matDialog: MatDialog,
    @Inject('timezone') public timezone: string
  ) {}

  ngOnInit(): void {
    this.isUserEventView = this.route.snapshot.data.isUserEventView;
    this.events$ = this.eventsStore.select(eventsQuery.getEventsList);
    this.loading$ = this.eventsStore.select(eventsQuery.isBatchLoading);

    this.eventsStore
      .select(eventsQuery.getFilter)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(currentFilter => {
          if (currentFilter) {
            return currentFilter;
          } else {
            const queryParamsFilter = this.queryParamService.parseAsFilter(
              this.route.snapshot.queryParams
            ) as EventsFilter;
            return queryParamsFilter ?? new EventsFilter();
          }
        })
      )
      .subscribe(eventFilter => {
        this.filter = eventFilter;
        this.filterForm = this.fb.group({
          query: this.fb.group({
            entity: this.fb.control(this.filter.query?.entity),
            value: this.fb.control(this.filter.query?.value.replace('user_', '')),
            limit: this.fb.control(this.filter.query?.limit)
          }),
          timestamp: this.fb.group({
            gte: this.fb.control(
              DateUtils.datepickerAdaptor('UTCToDatepicker', this.filter.timestamp?.gte, this.timezone)?.toJSON()
            ),
            lte: this.fb.control(
              DateUtils.datepickerAdaptor('UTCToDatepicker', this.filter.timestamp?.lte, this.timezone)?.toJSON()
            )
          }),
          page: this.fb.control(this.filter.page)
        });

        this.setValidator(this.filterForm.get('query.entity').value);
        this.subscribeToQueryValueChanges();
        this.subscribeToDateChanges();
        this.subscribeToLoadingChanges();
        this.pageLength$ = this.getPageLength$();
        this.appendQueryParamToUrl(this.filter);
      });
  }

  appendQueryParamToUrl(eventsFilter: EventsFilter): void {
    const { isFromUserView, ...restFilter } = eventsFilter;
    this.queryParamService.appendQueryParamToUrl(restFilter);
  }

  dispatchLoadEvents(): void {
    this.appendQueryParamToUrl(this.filter);
    this.eventsStore.dispatch(loadEvents({ filter: this.filter }));
  }

  getPageLength$(): Observable<number> {
    return this.eventsStore.select(eventsQuery.isLastPage).pipe(
      // Set page length to 0 to disable the next page button when it is the last page
      map(isLastPage => (isLastPage ? 0 : Number.MAX_SAFE_INTEGER))
    );
  }

  subscribeToQueryValueChanges(): void {
    this.filterForm
      .get('query.value')
      .valueChanges.pipe(
        filter(() => this.filterForm.get('query.value').valid),
        debounceTime(1000), // 1 second interval
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(value => {
        this.filter = {
          ...this.filter,
          page: 1,
          query: {
            ...this.filter.query,
            entity: this.filterForm.get('query.entity').value,
            value: this.filterForm.get('query.entity').value === 'event' ? 'user_' + value : value
          }
        };

        this.dispatchLoadEvents();
      });
  }

  onPage(pageEvent: PageEvent): void {
    this.filter = {
      // Set page to 1 if the current limit is different from the previous one
      // to avoid query expire error due to missing last evaluated key from the cache
      ...this.filter,
      page: this.filter.query.limit === pageEvent.pageSize ? pageEvent.pageIndex + 1 : 1,
      query: {
        ...this.filter.query,
        limit: pageEvent.pageSize
      }
    };
    this.dispatchLoadEvents();
    this.filterForm.get('page').setValue(this.filter.page);
    window.scrollTo(0, 0);
  }

  subscribeToDateChanges(): void {
    this.filterForm.controls.timestamp.valueChanges
      .pipe(
        filter(() => this.filterForm.controls.timestamp.valid),
        debounceTime(1000),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ gte, lte }) => {
        this.filter = {
          ...this.filter,
          page: 1,
          timestamp: {
            gte: gte && DateUtils.getTenantStartOfDay(gte, this.timezone),
            lte: lte && DateUtils.getTenantEndOfDay(lte, this.timezone)
          }
        };
        this.dispatchLoadEvents();
      });
  }

  setValidator(value: string): void {
    const validator = value === 'event' ? [uuidValidator(), Validators.required] : Validators.required;
    this.filterForm.get('query.value').setValidators(validator);
  }

  setDefaultValueForSelection(value: string): void {
    const defaultValue = value === 'app' ? 'guardhouse' : null;
    this.filterForm.get('query.value').setValue(defaultValue);
  }

  subscribeToLoadingChanges(): void {
    this.loading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loading => (loading ? this.filterForm.disable() : this.filterForm.enable({ emitEvent: false })));
  }

  openEventDetails(event: Event): void {
    this.matDialog.open(EventDetailsDialogComponent, { data: event, maxHeight: '100vh' });
  }
}
