import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { OrderItem, orderItemAdapter, OrderItemState } from '../../types';

const getOrderItemState = createFeatureSelector<OrderItemState>('orderItem');
const orderItemsDetector = createDetector<OrderItem>();

const { selectAll: getOrderItemsList, selectEntities: getOrderItemsDictionary } =
  orderItemAdapter.getSelectors(getOrderItemState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getBatchError,
  getSingleError
} = getCallStateSelectors<OrderItemState>(getOrderItemState);

const isOrderItemsLoaded = createSelector(
  getOrderItemsDictionary,
  isBatchResting,
  (orderItems, batchResting) => orderItemsDetector('allLoaded')(orderItems) && batchResting
);

const getOrderItemsFilter = createSelector(getOrderItemState, (state: OrderItemState) => state.filter);

const getOrderItemsCount = createSelector(getOrderItemState, (state: OrderItemState) => state.count);

const getOrderItemById = (id: string): Selector<object, OrderItem> =>
  createSelector(getOrderItemsDictionary, entities => entities[id]);

export const orderItemsQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getBatchError,
  getSingleError,
  isOrderItemsLoaded,
  getOrderItemsFilter,
  getOrderItemsList,
  getOrderItemsCount,
  getOrderItemById
};
