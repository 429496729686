import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, of } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { loadSharedConfig } from '@core/store/shared-config/actions/shared-config.actions';
import { SharedConfigState } from '@core/types';

import { TenantLocalesService } from '../../services/tenant-locales.service';
import {
  loadTenantLocales,
  loadTenantLocalesFailure,
  loadTenantLocalesSuccess,
  updateTenantLocales,
  updateTenantLocalesFailure,
  updateTenantLocalesSuccess
} from '../actions/tenant-locales.actions';
import { sharedConfigQuery } from './../../../core/store/shared-config/selectors/shared-config.selectors';

@Injectable()
export class TenantLocalesEffects {
  loadTenantLocales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenantLocales),
      exhaustMap(() =>
        this.tenantLocalesService.getTenantLocales().pipe(
          map(tenantLocales => loadTenantLocalesSuccess({ tenantLocales })),
          catchError(error => of(loadTenantLocalesFailure({ error })))
        )
      )
    )
  );

  updateTenantLocales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTenantLocales),
      withLatestFrom(this.store.select(sharedConfigQuery.getVersion)),
      exhaustMap(([{ tenantLocales }, version]) =>
        this.tenantLocalesService.updateTenantLocales({ tenantLocales, version }).pipe(
          map(_ => updateTenantLocalesSuccess()),
          catchError(error => of(updateTenantLocalesFailure({ error })))
        )
      )
    )
  );

  updateTenantLocalesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTenantLocalesSuccess),
      exhaustMap(() =>
        merge([routerForceNavigate({ path: '/tenant-locales' }), loadSharedConfig(), loadTenantLocales()])
      )
    )
  );

  constructor(
    private actions$: Actions,
    private tenantLocalesService: TenantLocalesService,
    private store: Store<SharedConfigState>
  ) {}
}
