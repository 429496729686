import { createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { PointsAdjustmentState } from '../../types';
import { DashboardState, getDashboardState } from '../reducers';

const getPointsAdjustmentState = createSelector(getDashboardState, (state: DashboardState) => state.pointsAdjustment);

const { isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<PointsAdjustmentState>(getPointsAdjustmentState);

export const pointsAdjustmentsQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError
};
