import { Injectable } from '@angular/core';

import { SCOPES_GROUP, SCOPES_OR } from '@core/types';

import { Scopes } from './scopes.service';

@Injectable({
  providedIn: 'root'
})
export class ScopesCheckService {
  constructor(private scopes: Scopes) {}

  hasViewUpdatePageScopes(isCustomerView: boolean): boolean {
    return this.scopes.hasScopes(
      isCustomerView ? SCOPES_GROUP.viewUpdateCustomersPage : SCOPES_GROUP.viewUpdateAgentsPage,
      'all-loose-groups'
    );
  }

  hasViewCustomersScope(): boolean {
    return this.scopes.hasAny(SCOPES_OR.viewCustomers);
  }

  hasViewAgentsScope(): boolean {
    return this.scopes.hasAny(SCOPES_OR.viewAgents);
  }
}
