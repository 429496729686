import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { pickupZoneInitialState, PickupZoneState } from '../../types';
import { loadPickupZones, loadPickupZonesFailure, loadPickupZonesSuccess } from '../actions/pickup-zones.actions';

export const pickupZoneTriggers: EntityTriggers = {
  batch: {
    loading: [loadPickupZones.type],
    resting: [loadPickupZonesSuccess.type],
    erroring: [loadPickupZonesFailure.type]
  }
};

export function pickupZonesReducer(state: PickupZoneState = pickupZoneInitialState, action: Action): PickupZoneState {
  return callStateReducer(baseReducer, pickupZoneTriggers)(state, action);
}

const baseReducer = createReducer(
  pickupZoneInitialState,
  on(loadPickupZonesSuccess, (state, { pickupZones }) => ({
    ...state,
    pickupZones
  }))
);
