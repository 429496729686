import { NgModule } from '@angular/core';
import { Route, ROUTER_CONFIGURATION, RouterModule, Routes, ROUTES } from '@angular/router';

import { rootAuthGuard } from '@core/guards/auth.guard';
import { customerAgentRedirectionGuard } from '@core/guards/customer-agent-redirection-guard';
import { featureAvailabilityGuard } from '@core/guards/feature-availability.guard';
import { nydusNetworkBootstrapResolver } from '@core/resolvers/nydus-network-bootstrap.resolver';
import { SCOPES_GROUP, SCOPES_OR, SCOPES_OR_GENERATED } from '@core/types';
import { PageNotFoundComponent } from '@shared/page-not-found/page-not-found.component';
import { WelcomePageComponent } from '@shared/welcome-page/welcome-page.component';
import { HostTenantMappingUtils, ObjectUtils } from '@utils';

import { Analytics } from './app-module-config';
import { tokenResolver } from './modules/analytics/resolvers/token.resolver';
import { selectedYearResolver } from './modules/campaign-proposals/resolvers/selected-year.resolver';
import { KafkaWidgetDemoComponent } from './modules/kafka-widget-demo/kafka-widget-demo.component';
import { NotesModule } from './modules/notes/notes.module';
import { SnackBarDemoComponent } from './modules/snack-bar-demo/snack-bar-demo.component';
import { generateUsersRoute } from './modules/users/users.routes';

const routes: Routes = [
  {
    path: 'tenants',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR_GENERATED.viewTenantsModule,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/tenants/tenants.module').then(module => module.TenantsModule)
  },
  {
    path: 'apps',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewApps,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/apps/apps.module').then(module => module.AppsModule)
  },
  {
    path: 'roles',
    canMatch: [rootAuthGuard],
    loadChildren: () => import('./modules/roles/roles.module').then(module => module.RolesModule)
  },
  {
    path: 'gift-cards',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: SCOPES_OR_GENERATED.viewGiftCardsMenu,
      relationOperator: 'or',
      features: ['giftCards']
    },
    loadChildren: () => import('./modules/gift-cards/gift-cards.module').then(module => module.GiftCardsModule)
  },
  {
    path: 'users/:userId',
    children: [
      {
        path: '**',
        canActivate: [customerAgentRedirectionGuard],
        redirectTo: '' // adding this line to avoid error on dev mode, we'll never reach this line because of CustomerAgentRedirectionGuard
      }
    ]
  },
  {
    ...generateUsersRoute('customers', SCOPES_OR.viewCustomers),
    loadChildren: () => import('./modules/users/users.module').then(module => module.UsersModule)
  },
  {
    ...generateUsersRoute('agents', SCOPES_OR.viewAgents),
    loadChildren: () => import('./modules/users/users.module').then(module => module.UsersModule)
  },
  {
    path: 'team-members',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: { scopes: ['view_team_members'], features: ['teamMembers'] },
    loadChildren: () => import('./modules/users/team-members.module').then(module => module.TeamMembersModule)
  },

  {
    path: 'suppliers',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: { scopes: ['view_suppliers'], features: ['suppliers'] },
    loadChildren: () =>
      import('./modules/alfred-suppliers/alfred-suppliers.module').then(module => module.AlfredSuppliersModule)
  },
  {
    path: 'enrollments',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: {
      scopes: SCOPES_GROUP.viewEnrollmentsPage,
      isCustomerView: true,
      relationOperator: 'all-loose-groups',
      features: ['enrollments']
    },
    loadChildren: () => import('./modules/enrollments/enrollments.module').then(module => module.EnrollmentsModule)
  },
  {
    path: 'access-policies',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewAccessPolicies,
      relationOperator: 'or'
    },
    loadChildren: () =>
      import('./modules/access-policies/access-policies.module').then(module => module.AccessPoliciesModule)
  },
  {
    path: 'abilities',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewAbilities,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/abilities/abilities.module').then(module => module.AbilitiesModule)
  },
  {
    path: 'loyalty-programs',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: SCOPES_OR.viewLoyaltyPrograms,
      relationOperator: 'or',
      features: ['pointsTransfers']
    },
    loadChildren: () =>
      import('./modules/loyalty-programs/loyalty-programs.module').then(module => module.LoyaltyProgramsModule)
  },
  {
    path: 'authentication-providers',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewAuthenticationProviders,
      relationOperator: 'or'
    },
    loadChildren: () =>
      import('./modules/authentication-providers/authentication-providers.module').then(
        module => module.AuthenticationProvidersModule
      )
  },
  {
    path: 'events',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewEvents,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/events/events.module').then(module => module.EventsModule)
  },
  {
    path: 'tools',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.exportTools,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/tools/tools.module').then(module => module.ToolsModule)
  },
  {
    path: 'login-attempts',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewLoginAttempts,
      relationOperator: 'or'
    },
    loadChildren: () =>
      import('./modules/login-attempts/login-attempts.module').then(module => module.LoginAttemptsModule)
  },
  {
    path: 'approval-requests',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewApprovalRequests,
      relationOperator: 'or'
    },
    loadChildren: () =>
      import('./modules/approval-requests/approval-requests.module').then(module => module.ApprovalRequestsModule)
  },
  {
    path: 'pickup-zones',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: {
      scopes: ['manage_pickup_zones:index'],
      features: ['batchProcessingHistory', 'batchProcessingOverview', 'batchProcessingRemoteFiles']
    },
    loadChildren: () => import('./modules/pickup-zones/pickup-zones.module').then(module => module.PickupZonesModule)
  },
  {
    path: 'offers',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: SCOPES_OR.viewOffers,
      relationOperator: 'or',
      features: ['offers']
    },
    loadChildren: () => import('./modules/offers/offers.module').then(module => module.OffersModule)
  },
  {
    path: 'orders-items',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: SCOPES_OR.viewOrderItems,
      relationOperator: 'or',
      features: ['orders']
    },
    loadChildren: () => import('./modules/orders/orders.module').then(module => module.OrdersModule)
  },
  {
    path: 'notes',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewNotes,
      relationOperator: 'or'
    },
    loadChildren: () => NotesModule
  },
  {
    path: 'loyalty-data',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewLoyaltyDataMenu,
      relationOperator: 'or',
      features: ['pointsAccounts', 'products']
    },
    loadChildren: () => import('./modules/loyalty-data/loyalty-data.module').then(module => module.LoyaltyDataModule)
  },
  {
    path: 'redemption-rates',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: SCOPES_OR.viewPricing,
      relationOperator: 'or',
      features: ['pricing']
    },
    loadChildren: () =>
      import('./modules/redemption-rates/redemption-rates.module').then(module => module.RedemptionRatesModule)
  },
  {
    path: 'mc-tenants',
    canMatch: [rootAuthGuard],
    data: { scopes: SCOPES_OR.viewMcTenants, relationOperator: 'or' },
    loadChildren: () => import('./modules/mc-tenants/mc-tenants.module').then(module => module.McTenantsModule)
  },
  {
    path: 'fraud-lists',
    canMatch: [rootAuthGuard],
    data: { scopes: ['fraud_lists:index'] },
    loadChildren: () => import('./modules/fraud-lists/fraud-lists.module').then(module => module.FraudListsModule)
  },
  {
    path: 'keys',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewKeys,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/keys/keys.module').then(module => module.KeysModule)
  },
  {
    path: 'zendesk-tickets',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.submitTickets,
      relationOperator: 'or'
    },
    loadChildren: () =>
      import('./modules/zendesk-tickets/zendesk-tickets.module').then(module => module.ZendeskTicketsModule)
  },
  {
    path: 'merchandises',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewMerchandises,
      relationOperator: 'or',
      features: ['merchandises']
    },
    loadChildren: () => import('./modules/merchandises/merchandises.module').then(module => module.MerchandisesModule)
  },
  {
    path: 'watchlists',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewWatchlists,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/watchlists/watchlists.module').then(module => module.WatchlistsModule)
  },
  {
    path: 'domains',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewDomains,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/domains/domains.module').then(module => module.DomainsModule)
  },
  {
    path: 'guardhouse-cache',
    canMatch: [rootAuthGuard],
    data: { scopes: ['cache:clear'] },
    loadChildren: () => import('./modules/gh-cache/gh-cache.module').then(module => module.GhCacheModule)
  },
  {
    path: 'promo-codes',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: { scopes: ['view_promo_codes'], features: ['promoCodes'] },
    resolve: {
      prefetchNydusNetworkBootstrap: nydusNetworkBootstrapResolver
    },
    loadChildren: () => import('./modules/promo-codes/promo-codes.module').then(module => module.PromoCodesModule)
  },
  {
    path: 'promotions',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: ['view_campaign_proposals'],
      features: ['promotions']
    },
    resolve: {
      updateSelectedYear: selectedYearResolver
    },
    loadChildren: () =>
      import('./modules/campaign-proposals/campaign-proposals.module').then(module => module.CampaignProposalsModule)
  },
  {
    path: 'event-types',
    canMatch: [rootAuthGuard],
    data: { scopes: ['view_event_types'] },
    loadChildren: () => import('./modules/event-types/event-types.module').then(module => module.EventTypesModule)
  },
  {
    path: 'fraud-rules',
    canMatch: [rootAuthGuard],
    data: { scopes: ['view_fraud_rules'] },
    loadChildren: () => import('./modules/fraud-rules/fraud-rules.module').then(module => module.FraudRulesModule)
  },
  {
    path: 'fraud-monitoring',
    canMatch: [rootAuthGuard],
    loadChildren: () =>
      import('./modules/fraud-monitoring/fraud-monitoring.module').then(module => module.FraudMonitoringModule)
  },
  {
    path: 'logs',
    canMatch: [rootAuthGuard],
    data: {
      scopes: SCOPES_OR.viewAuditLogs,
      relationOperator: 'or'
    },
    loadChildren: () => import('./modules/audit-logs/audit-logs.module').then(module => module.AuditLogsModule)
  },
  {
    path: 'partner-configs',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: { scopes: ['manage_partner_configs'], features: ['branding', 'siteEditor'] },
    loadChildren: () =>
      import('./modules/partner-configs/partner-configs.module').then(module => module.PartnerConfigsModule)
  },
  {
    path: 'rewards-offering',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: SCOPES_OR_GENERATED.viewRewardsOfferingListing,
      relationOperator: 'or',
      features: ['rcRewardsOffering']
    },
    loadChildren: () =>
      import('./modules/rewards-offering/rewards-offering.module').then(module => module.RewardsOfferingModule)
  },
  {
    path: 'rewards-program',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: { scopes: ['view_rewards_program'], features: ['rewardsProgram'] },
    loadChildren: () =>
      import('./modules/rewards-program/rewards-program.module').then(module => module.RewardsProgramModule)
  },
  {
    path: 'earn-rules',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: { scopes: ['view_earn_rules'], features: ['earnRule'] },
    loadChildren: () => import('./modules/earn-rules/earn-rules.module').then(module => module.EarnRulesModule)
  },
  {
    path: 'custom-domain',
    canMatch: [rootAuthGuard, featureAvailabilityGuard],
    data: {
      scopes: ['view_custom_domains'],
      features: ['customDomain']
    },
    loadChildren: () => import('./modules/custom-domain/custom-domain.module').then(module => module.CustomDomainModule)
  },
  {
    path: 'api-settings',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: { scopes: ['view_partner_app'], features: ['apiSettings'] },
    loadChildren: () => import('./modules/api-settings/api-settings.module').then(module => module.ApiSettingsModule)
  },
  {
    path: 'webhooks',
    canMatch: [featureAvailabilityGuard, rootAuthGuard],
    data: { scopes: ['view_webhook_settings'], features: ['webhooks'] },
    resolve: {
      prefetchNydusNetworkBootstrap: nydusNetworkBootstrapResolver
    },
    loadChildren: () => import('./modules/webhooks/webhooks.module').then(module => module.WebhooksModule)
  },
  {
    path: 'segments',
    canMatch: [rootAuthGuard],
    data: { scopes: ['view_segments'] },
    loadChildren: () => import('./modules/segments/segments.module').then(module => module.SegmentsModule)
  },
  {
    path: 'ui-settings',
    canMatch: [rootAuthGuard],
    data: { scopes: ['manage_ui_settings'] },
    loadChildren: () => import('./modules/ui-settings/ui-settings.module').then(module => module.UiSettingsModule)
  },
  {
    path: 'questions',
    canMatch: [rootAuthGuard],
    data: { scopes: ['manage_questions'] },
    loadChildren: () => import('./modules/questions/questions.module').then(module => module.QuestionsModule)
  },
  {
    path: 'payment-tiers',
    canMatch: [rootAuthGuard],
    data: { scopes: ['manage_payment_tiers'] },
    loadChildren: () => import('./modules/payment-tiers/payment-tiers.module').then(module => module.PaymentTiersModule)
  },
  {
    path: 'tenant-locales',
    canMatch: [rootAuthGuard],
    data: { scopes: ['view_localisation'] },
    loadChildren: () =>
      import('./modules/tenant-locales/tenant-locales.module').then(module => module.TenantLocalesModule)
  },
  {
    path: 'translations',
    canMatch: [rootAuthGuard],
    data: { scopes: ['view_localisation'] },
    loadChildren: () =>
      import('./modules/mc-translations/mc-translations.module').then(module => module.McTranslationsModule)
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'snack-bar-demo',
    component: SnackBarDemoComponent
  },
  {
    path: 'kafka-widget-demo',
    canMatch: [rootAuthGuard],
    data: { scopes: ['view_kafka_demo'] },
    component: KafkaWidgetDemoComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
  // For those modules which do not have route.ts yet, we park them here for Angular as import reference
  // so that it won't cause compilation error
  // Remember to provide exact path and move it up once route.ts is created
];

function createAppRoutes(analytics: Analytics): Routes {
  const defaultRootRoute = {
    path: '',
    component: WelcomePageComponent
  };

  const analyticsRootRoute = {
    path: '',
    canMatch: [rootAuthGuard],
    resolve: {
      fetchToken: tokenResolver
    },
    loadChildren: () => import('./modules/analytics/analytics.module').then(module => module.AnalyticsModule)
  };

  const campaignsRootRoute: Route = {
    path: 'campaigns',
    ...(HostTenantMappingUtils.isRC()
      ? {
          canMatch: [featureAvailabilityGuard, rootAuthGuard],
          data: { scopes: ['view_tenant_campaigns'], features: ['tenantCampaigns'] },
          loadChildren: () =>
            import('./modules/tenant-campaigns/tenant-campaigns.module').then(module => module.TenantCampaignsModule)
        }
      : {
          canMatch: [featureAvailabilityGuard, rootAuthGuard],
          data: { scopes: SCOPES_OR.viewCampaigns, relationOperator: 'or', features: ['campaigns'] },
          loadChildren: () => import('./modules/campaigns/campaigns.module').then(module => module.CampaignsModule)
        })
  };

  return ObjectUtils.isNonEmptyObject(analytics)
    ? [analyticsRootRoute, campaignsRootRoute, ...routes]
    : [defaultRootRoute, campaignsRootRoute, ...routes];
}

@NgModule({
  imports: [RouterModule],
  providers: [
    { provide: ROUTER_CONFIGURATION, useValue: { initialNavigation: 'disabled' } },
    { provide: ROUTES, multi: true, useFactory: createAppRoutes, deps: ['analytics'] }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
