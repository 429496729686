import { filter, map } from 'rxjs/operators';

import { AfterViewInit, Component, DestroyRef, inject, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NavService } from '@core/services/nav/nav.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import {
  analyticsNav,
  analyticsNavItems,
  AttributesSettings,
  ChildNavItem,
  DecodedUser,
  defaultNavItems,
  NavItem,
  overviewNav
} from '@core/types';
import { FeatureAvailabilityService } from '@shared/services/feature-availability/feature-availability.service';
import { AvailabilityStatus } from '@shared/types';
import { AccessPolicyUtils, HostTenantMappingUtils, ImageUtils, ObjectUtils } from '@utils';

import { Analytics } from '../../../../app-module-config';

@Component({
  selector: 'admin-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, AfterViewInit {
  @Input() user: DecodedUser;
  @Input() isLoggedIn: boolean;

  destroyRef = inject(DestroyRef);

  logoUrl: string;
  expandedItem: NavItem | ChildNavItem;
  filteredNavItems$: Observable<NavItem[]>;

  isRC = HostTenantMappingUtils.isRC();

  constructor(
    private accessPolicies: AccessPolicies,
    private authStore: Store<AuthState>,
    public navService: NavService,
    private router: Router,
    private scopes: Scopes,
    private store: Store<any>,
    private featureAvailabilityService: FeatureAvailabilityService,
    @Inject('isGlobalApp') public isGlobalApp: boolean,
    @Inject('analytics') private analytics: Analytics
  ) {}

  ngOnInit(): void {
    let navItems = this.analytics
      ? this.appendAnalyticsNavItems(defaultNavItems.map(item => ({ ...item })))
      : defaultNavItems.map(item => ({ ...item }));

    navItems = this.isRC
      ? navItems.filter(item => item.id !== 'rewardsOffering')
      : navItems.filter(item => item.id !== 'rcRewardsOffering' && item.id !== 'legacyRewardsOffering');

    navItems = this.modifyByFeatureAvailability(navItems);

    const frontendPolicySettings = this.accessPolicies.getFrontendSettings('sidenav', 'show');
    this.filteredNavItems$ = this.transformSpecialNavItems(navItems, frontendPolicySettings).pipe(
      map(items =>
        items
          .filter(
            navItem =>
              this.isVisibleWithScope(navItem) &&
              AccessPolicyUtils.isTargetPageVisible(frontendPolicySettings, navItem.id)
          )
          .map(navItem => {
            if (navItem.action && !navItem.isActionDispatched) {
              this.store.dispatch(navItem.action);
              navItem.isActionDispatched = true;
            }
            return navItem;
          })
      )
    );

    this.setLogoUrl();
  }

  ngAfterViewInit(): void {
    const sidenavContent: HTMLElement = document.querySelector('.mat-sidenav-content');
    sidenavContent.style.marginLeft = 'unset';
    this.subscribeToRouteChanges();
  }

  setLogoUrl(): void {
    ImageUtils.getLogoFilenames().then(logos => {
      this.logoUrl = `/assets/${logos[HostTenantMappingUtils.getPrefix()]}`;
    });
  }

  appendAnalyticsNavItems(navItems: NavItem[]): NavItem[] {
    let newNavItems = [...navItems];
    const finalAnalyticsNavItems: NavItem[] = [];

    for (const analyticsItem of analyticsNavItems) {
      if (this.analytics[analyticsItem.dashboardIdKey]) {
        finalAnalyticsNavItems.push(analyticsItem);
      }
    }

    if (finalAnalyticsNavItems.length > 0) {
      const finalAnalyticsNav: NavItem = {
        ...analyticsNav,
        children: finalAnalyticsNavItems
      };

      newNavItems = [finalAnalyticsNav, ...newNavItems];
    }

    if (this.analytics.mainDashboardId) {
      newNavItems = [overviewNav, ...newNavItems];
    }

    return newNavItems;
  }

  private modifyByFeatureAvailability(navItems: NavItem[]): NavItem[] {
    return navItems.reduce((acc, item) => {
      const itemAvailabilityStatus = this.getAvailabilityStatusForDisplay(item);

      if (itemAvailabilityStatus === AvailabilityStatus.HIDDEN) {
        return acc;
      }

      if (itemAvailabilityStatus === AvailabilityStatus.UPSELL) {
        let upsellItem: NavItem = { ...item, isProFeature: true };

        // handle special case for siteEditor where we want to upsell the homepage editor
        upsellItem =
          upsellItem.id === 'siteEditor'
            ? { ...upsellItem, route: '/partner-configs/site-editor/homepage' }
            : upsellItem;

        return [...acc, upsellItem];
      }

      if (item.children) {
        const children = this.modifyByFeatureAvailability(item.children);
        return children.length > 0 ? [...acc, { ...item, children }] : acc;
      }

      return [...acc, item];
    }, []);
  }

  private getAvailabilityStatusForDisplay(item: NavItem): AvailabilityStatus {
    return item?.checkFeatureAvailability
      ? this.featureAvailabilityService.getFeatureAvailabilityStatus(item.id)
      : AvailabilityStatus.AVAILABLE;
  }

  private isVisibleWithScope(navItem: NavItem): boolean {
    return !navItem.scopes?.length || this.scopes.hasScopes(navItem.scopes, navItem.relationOperator);
  }

  private subscribeToRouteChanges(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof NavigationEnd),
        // avoid overriding the scroll behaviour from TableOfContentsComponent
        filter((event: NavigationEnd) => !/tenants\/edit.*#/g.test(event.url))
      )
      .subscribe((event: NavigationEnd) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }

  private transformSpecialNavItems(
    items: NavItem[],
    frontendPolicySettings: AttributesSettings
  ): Observable<NavItem[]> {
    return this.authStore.select(authQuery.getUserTenantId).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(tenantId =>
        items.map(item => {
          if (item.children) {
            if (item.id === 'tenantManagement' && !this.isGlobalApp) {
              item.children
                .filter(child => child.id === 'tenants')
                .map(child => {
                  child.displayName = 'Configurations';
                  child.route = `/tenants/${tenantId}/details`;
                  child.rootRoutes = [`/tenants/${tenantId}`, `/tenants/edit/${tenantId}`];
                });
            } else if (
              item.id === 'userManagement' &&
              AccessPolicyUtils.isTargetPageInvisible(frontendPolicySettings, 'customers')
            ) {
              item.children = item.children.filter(child => child.id !== 'customers');
            } else if (item.id === 'marketingCentre') {
              item.children = item.children.filter(
                child => child.id !== (HostTenantMappingUtils.isRC() ? 'campaigns' : 'tenantCampaigns')
              );
            }

            return {
              ...item,
              scopes:
                item.scopes ||
                item.children.reduce(
                  (acc, child) => ObjectUtils.removeArrayDuplicates([...acc, ...child.scopes]).flat(),
                  []
                ),
              relationOperator: item.relationOperator || 'or'
            };
          } else if (item.id === 'approvalRequests') {
            return {
              ...item,
              badgeValue$: this.store.select(state => state?.approvalRequest?.statistics?.pending)
            };
            // display "Users" for enrollments navItem if both agents and customers page are invisible
          } else if (item.id === 'enrollments' && AccessPolicyUtils.isBothUsersPageInvisible(frontendPolicySettings)) {
            return {
              ...item,
              displayName: 'Users'
            };
          } else {
            return item;
          }
        })
      )
    );
  }
}
