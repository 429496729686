<div class="details-view">
  <div class="section-header">General</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Order ID">{{ orderItem.order.id }}</admin-item>
    <admin-item label="Item ID">{{ orderItem.id }}</admin-item>
    <admin-item label="Product type">{{ orderItem.type | orderItemType }}</admin-item>
    <admin-item label="Order initiator">{{
      (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A'
    }}</admin-item>
    <admin-item label="Amount received"
      >{{ data.transferAmount.toLocaleString() }} {{ data.loyaltyCurrencyName }}</admin-item
    >
    <admin-item label="Amount transferred">
      <span [innerHTML]="orderItem.pointsPaid.toLocaleString() + ' ' + data.tenantCurrencyName"></span>
    </admin-item>
    <admin-item label="Original amount transferred">
      <span [innerHTML]="orderItem.pointsAmount.toLocaleString() + ' ' + data.tenantCurrencyName"></span>
    </admin-item>
    <admin-item label="Promo code">{{ data.promoCode || 'N/A' }}</admin-item>

    <div class="cross-two-column unpadded-subsection-header">Customer details</div>
    <admin-item class="cross-two-column" label="Customer ID">{{ customerId }}</admin-item>
  </div>

  <div class="section-header">Membership information</div>
  <div class="section admin-item-wrapper">
    <admin-item label="First name">{{ data.membershipInformation.firstName | titlecase }}</admin-item>
    <admin-item label="Last name">{{ data.membershipInformation.lastName }}</admin-item>
    <admin-item label="Program name">{{ data.productName | titlecase }}</admin-item>
    <admin-item label="Program ID">{{ data.loyaltyProgramId }}</admin-item>
    <admin-item label="Membership number">{{ data.membershipInformation.number }}</admin-item>
    <admin-item label="Loyalty currency name">{{ data.loyaltyCurrencyName | titlecase }}</admin-item>
  </div>

  <div class="section-header">Stripe reference</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Stripe reference">{{ stripeReferenceData.stripeReference }}</admin-item>
    <admin-item label="Total charge">{{ stripeReferenceData.totalCharge }}</admin-item>
  </div>
</div>
