import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { domainAdapter, DomainState, initialState } from '../../types';
import {
  createDomain,
  createDomainFailure,
  createDomainSuccess,
  deleteDomain,
  deleteDomainFailure,
  deleteDomainSuccess,
  fetchDomain,
  fetchDomainFailure,
  fetchDomainSuccess,
  loadDomain,
  loadDomainFailure,
  loadDomains,
  loadDomainsFailure,
  loadDomainsSuccess,
  loadDomainSuccess,
  updateDomain,
  updateDomainFailure,
  updateDomainSuccess
} from '../actions/domains.actions';

export const domainTriggers: EntityTriggers = {
  single: {
    loading: [loadDomain.type, createDomain.type, updateDomain.type, deleteDomain.type],
    resting: [loadDomainSuccess.type, createDomainSuccess.type, updateDomainSuccess.type, deleteDomainSuccess.type],
    erroring: [loadDomainFailure.type, createDomainFailure.type, updateDomainFailure.type, deleteDomainFailure.type]
  },
  batch: {
    loading: [loadDomains.type],
    resting: [loadDomainsSuccess.type],
    erroring: [loadDomainsFailure.type]
  }
};

export const domainFetchListTriggers: FetchListTriggers = {
  loading: [fetchDomain.type],
  resting: [fetchDomainSuccess.type],
  erroring: [fetchDomainFailure.type]
};

export function domainsReducer(state: DomainState = initialState, action: Action): DomainState {
  return {
    ...callStateReducer(baseReducer, domainTriggers)(state, action),
    ...fetchStateReducer(domainFetchListTriggers, 'domain')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(loadDomains, (state, { filter }) => ({ ...state, filter })),
  on(loadDomainsSuccess, (state, { result }) => ({
    ...domainAdapter.setAll(result.data, state),
    count: result?.metadata.total || 0
  })),
  on(loadDomainSuccess, (state, { domain }) => domainAdapter.upsertOne(domain, state)),
  on(fetchDomainSuccess, (state, { domain }) => domainAdapter.upsertOne(domain, state)),
  on(updateDomainSuccess, (state, { domain }) => domainAdapter.setOne(domain, state))
);
