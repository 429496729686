import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { CustomDomainState, initialState } from '../../types';
import {
  updateCustomDomain,
  updateCustomDomainFailure,
  updateCustomDomainSuccess
} from '../actions/custom-domain.actions';

export const customDomainTriggers: EntityTriggers = {
  single: {
    loading: [updateCustomDomain.type],
    resting: [updateCustomDomainSuccess.type],
    erroring: [updateCustomDomainFailure.type]
  }
};

export function customDomainReducer(state: CustomDomainState = initialState, action: Action): CustomDomainState {
  return callStateReducer(baseReducer, customDomainTriggers)(state, action);
}

export const baseReducer = createReducer(
  initialState,
  on(updateCustomDomainSuccess, (state, { customDomain }) => ({ ...state, customDomain }))
);
