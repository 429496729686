import { NavItemV2 } from '@core/types/nav-item-v2.type';

import { programManagementNavItems } from './program-management-nav-items.const';
import { setupNavItems } from './setup-nav-items.const';
import { supportNavItems } from './support-nav-items.const';
import { toolsNavItems } from './tools-nav-items.const';

export const navItemsV2: NavItemV2[] = [
  {
    id: 'overview',
    type: 'analytics',
    label: 'Overview',
    iconName: 'grid-sharp',
    scopes: ['view_analytics'],
    route: '',
    dashboardIdKey: 'mainDashboardId'
  },
  {
    id: 'programManagement',
    type: 'group',
    iconName: 'reward-program',
    label: 'Program management',
    children: programManagementNavItems
  },
  {
    id: 'setup',
    type: 'group',
    iconName: 'settings',
    label: 'Setup',
    children: setupNavItems
  },
  {
    id: 'support',
    type: 'group',
    iconName: 'shield-with-check-01',
    label: 'Support',
    children: supportNavItems
  },
  {
    id: 'toolsGroup',
    type: 'group',
    iconName: 'tools',
    label: 'Tools',
    children: toolsNavItems
  }
];
