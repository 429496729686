import { Observable } from 'rxjs/internal/Observable';
import { Unsubscribable } from 'rxjs/internal/types';
import { isObservable } from 'rxjs/internal/util/isObservable';

import { computed, Injectable, OnDestroy, signal } from '@angular/core';

import {
  LogicRuleAttribute,
  LogicRuleAttributeGroupOption,
  LogicRuleAttributeOption,
  LogicRuleAttributeRecord
} from '@shared/types/logic-rule.type';
import { ObjectUtils } from '@utils';

import { getValuesAndCategoryPair } from '../../../../utils/logic-rule.utils';

export type AttributeDataSource = Observable<LogicRuleAttributeRecord> | LogicRuleAttributeRecord;

@Injectable()
export class LogicRuleBuilderHelperService implements OnDestroy {
  attributesMap = computed<Map<string, LogicRuleAttribute>>(() => {
    const attributes = this.logicRuleAttributes();

    if (ObjectUtils.isNullish(attributes) || Object.keys(attributes).length === 0) {
      return new Map();
    }

    return new Map(Object.entries(attributes));
  });

  // Mostly use for Campaign Logic Rule
  dependentValuesAndCategoryMap = computed<Map<string, string>>(() => {
    const attributes = this.logicRuleAttributes();

    if (ObjectUtils.isNullish(attributes) || Object.keys(attributes).length === 0) {
      return new Map();
    }

    const valuesAndCategoryPair = getValuesAndCategoryPair(attributes);

    return new Map(Object.entries(valuesAndCategoryPair));
  });

  private logicRuleAttributes = signal<LogicRuleAttributeRecord>({});

  private dataSourceSubscription?: Unsubscribable;

  ngOnDestroy(): void {
    this.unsubscribeFromCurrentDataSource();
  }

  /**
   * Register logic rule attributes data source
   * @param source Attribute data source
   */
  registerAttributesDataSource(dataSource: AttributeDataSource): void {
    this.unsubscribeFromCurrentDataSource();

    if (isObservable(dataSource)) {
      this.dataSourceSubscription = dataSource.subscribe(data => {
        this.logicRuleAttributes.set(data);
      });

      return;
    }

    this.logicRuleAttributes.set(dataSource);
  }

  /**
   * Get main attribute by key
   * @param mainAttributeKey - the key of the main attribute, it will follow the convention of `<attribute_key>`
   * @returns `LogicRuleAttribute` if found otherwise `null`
   */
  getMainAttribute(mainAttributeKey: string): LogicRuleAttribute | null {
    // Since main attribute is ensure to be unique, we can use find it in the map directly
    let result: LogicRuleAttribute | null = null;
    for (const value of this.attributesMap().values()) {
      if (value.attribute === mainAttributeKey) {
        result = value;
        break;
      }
    }

    return result;
  }

  /**
   * Get attribute by key
   * @param attributeKey - contains the combination with following convention `<parent_key>.<attribute_key>`,
   * without the `<parent_key>`, the attributes are at the `root` level e.g `root.<attribute_key>`
   * @returns LogicRuleAttribute | null
   */
  getAttribute(attributeKey: string): LogicRuleAttribute | null {
    let queryKey = attributeKey;
    if (attributeKey.split('.').length === 1) {
      queryKey = `root.${attributeKey}`;
    }

    return this.attributesMap().has(queryKey) ? this.attributesMap().get(queryKey)! : null;
  }

  /**
   * Get attribute options. Only support `select` and `dependent_select` type
   * and will return empty array if the attribute type is not supported
   * or the resources data is not available
   * @param attribute segment attribute to get the options from
   * @returns LogicRuleAttributeOption[]
   */
  getAttributeOptions(attribute: LogicRuleAttribute): LogicRuleAttributeOption[] {
    if (!['dependent_select', 'select'].includes(attribute.type) || !attribute.resources?.data) {
      return [];
    }

    // we can support `dataSource` when it's available
    return attribute.resources.data;
  }

  /**
   * Get cumulative time resource options. Only support cumulative type
   * and will return empty array if the resources data is not available
   * or the attribute is not cumulative type
   * @param attribute - LogicRule attribute to get the options from
   * @returns LogicRuleAttributeOption[]
   */
  getCumulativeTimeResourceOptions(attribute: LogicRuleAttribute): LogicRuleAttributeOption[] {
    if (!attribute.isCumulativeType || !attribute.timeRangeResource?.data) {
      return [];
    }

    return attribute.timeRangeResource.data;
  }

  /**
   * Get nested attributes as options
   * @param attribute - attribute key to get the nested attributes from
   * @returns LogicRuleAttributeOption[]
   */
  getNestedAttributesAsOptions(attribute: string): LogicRuleAttributeOption[] {
    const options: LogicRuleAttributeOption[] = [];
    this.attributesMap().forEach(item => {
      if (item.parentAttribute === attribute) {
        options.push({
          label: item.displayName,
          value: item.attribute
        });
      }
    });
    return options;
  }

  /**
   * Get all resource options from all main event types attribute.
   * This function will be mostly used for qualification rule
   * @param attribute category attribute
   * @returns all event type resource options
   */
  getAllEventTypeResourceOptions(attribute: string): LogicRuleAttributeOption[] {
    const options: LogicRuleAttributeOption[] = [];

    this.attributesMap().forEach(item => {
      if (item.attribute === 'event_type' && item.parentAttribute === attribute) {
        options.push(...this.getAttributeOptions(item));
      }
    });

    return options;
  }

  /**
   * Get main attribute options with category and category options
   * @returns LogicRuleAttributeGroupOption[]
   */
  getMainAttributeOptionsWithCategory(): LogicRuleAttributeGroupOption[] {
    const categories = this.getNestedAttributesAsOptions('root');

    return categories.map<LogicRuleAttributeGroupOption>(category => ({
      categoryLabel: category.label,
      options: this.getNestedAttributesAsOptions(category.value as string)
    }));
  }

  /**
   * Get main event type resource options with category and category options
   * @returns LogicRuleAttributeGroupOption[]
   */
  getMainEventTypeResourceOptions(): LogicRuleAttributeGroupOption[] {
    const categories = this.getNestedAttributesAsOptions('root');

    return categories.reduce<LogicRuleAttributeGroupOption[]>((currentCategories, category) => {
      const options = this.getAllEventTypeResourceOptions(category.value as string);

      return options.length > 0
        ? [...currentCategories, { categoryLabel: category.label, options }]
        : currentCategories;
    }, [] as LogicRuleAttributeGroupOption[]);
  }

  getAllDependentSelectResource(dependentSelectAttributeName: string): LogicRuleAttributeOption[] {
    const options: LogicRuleAttributeOption[] = [];

    this.attributesMap().forEach(item => {
      if (item.attribute === dependentSelectAttributeName && item.type === 'dependent_select') {
        options.push(...this.getAttributeOptions(item));
      }
    });

    return options;
  }

  getDependentValueCategory(dependentValue: string): string {
    return this.dependentValuesAndCategoryMap().get(dependentValue) ?? '';
  }

  /**
   * Check if the operator is custom datetime operator
   * @param operator - the operator to check
   * @returns boolean
   */
  isCustomDateTimeOperator(operator: string): boolean {
    return ['occurred_in_the_last', 'occurred_at_least'].includes(operator);
  }

  private unsubscribeFromCurrentDataSource(): void {
    if (this.dataSourceSubscription) {
      this.dataSourceSubscription.unsubscribe();
    }
  }
}
