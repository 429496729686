export type PromoCodeImageType = 'desktop_image_url' | 'mobile_image_url';
export type ImageType = 'cover_image_url' | 'logo_url' | 'main_category_icon_url' | PromoCodeImageType;

export interface ImageUploadResponse {
  imageUrl: string;
}

export const imageErrorMessage = {
  invalidImageFileType: 'The format of the image uploaded was not correct. Please try again.',
  invalidImageFileSize: 'The size of the image uploaded is too large. Please try another image.',
  invalidImageDimensions: 'The dimensions of the image uploaded are too large. Please try another image.'
};
