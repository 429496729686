<form class="form-fields-wrapper">
  <mat-form-field class="points-summary-content">
    <mat-label>Account</mat-label>
    <mat-select disableOptionCentering [formControl]="pointsAccountControl">
      <mat-option *ngFor="let account of pointsAccountsDropdownList" [value]="account.id">
        Ending with {{ account.groupReferenceId + ' ' + account.status }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
