import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { invalidateUserToken } from '../../store/actions/user-tokens.actions';
import { userTokensQuery } from '../../store/selectors/user-tokens.selectors';
import { InvalidateTokenForm, InvalidateTokenParams, UserTokenState } from '../../types';

@Component({
  selector: 'admin-user-token-invalidate-dialog',
  templateUrl: './user-token-invalidate-dialog.component.html'
})
export class UserTokenInvalidateDialogComponent implements OnInit {
  invalidateTokenForm: FormGroup<InvalidateTokenForm>;
  loading$: Observable<boolean>;

  constructor(
    private store: Store<UserTokenState>,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserTokenInvalidateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Partial<InvalidateTokenParams>
  ) {}

  ngOnInit(): void {
    this.invalidateTokenForm = this.fb.group({
      id: this.fb.control({ value: this.data.id, disabled: true }),
      userId: this.fb.control({ value: this.data.userId, disabled: true }),
      reason: this.fb.control(null)
    });

    this.loading$ = this.store.select(userTokensQuery.isSingleLoading);
  }

  submitForm(): void {
    const reason = this.invalidateTokenForm.value.reason;
    const params: InvalidateTokenParams = {
      ...this.invalidateTokenForm.getRawValue(),
      reason: reason || 'Autogenerated: Invalidated from Admin Panel'
    };

    this.store.dispatch(invalidateUserToken({ params, dialogRefId: this.dialogRef.id }));
  }
}
