import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { SharedConfigService } from '@core/services/shared-config/shared-config.service';

import { loadSharedConfig, loadSharedConfigError, loadSharedConfigSuccess } from '../actions/shared-config.actions';

@Injectable()
export class SharedConfigEffects {
  loadSharedConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSharedConfig),
      exhaustMap(_ =>
        this.sharedConfigService.getSharedConfig().pipe(
          map(sharedConfig => loadSharedConfigSuccess({ sharedConfig })),
          catchError(error => of(loadSharedConfigError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private sharedConfigService: SharedConfigService
  ) {}
}
