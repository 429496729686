import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const isE164 = isValidPhoneNumber(control.value);
    return isE164 ? null : { invalidE164: true };
  };
}
