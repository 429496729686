<div [ngClass]="data.styleClassName">
  <h1 mat-dialog-title>{{ data.dialogTitle || 'Confirm action' }}</h1>
  <div mat-dialog-content [innerHTML]="data.confirmText"></div>
  <div mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="secondary"
      class="uppercase-button text-primary cancel-button"
      (click)="dialogRef.close(false)"
    >
      <span class="uppercase-button__text">{{ data.cancelButtonText || 'No, cancel' }}</span>
    </button>
    <button mat-raised-button color="primary" class="uppercase-button confirm-button" (click)="dialogRef.close(true)">
      <span class="uppercase-button__text">{{ data.confirmButtonText }}</span>
    </button>
  </div>
</div>
