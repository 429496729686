<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
<nav
  mat-tab-nav-bar
  fitInkBarToContent
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  [class.menu-v2-open]="isSubTabOpen"
  [tabPanel]="tabPanel"
>
  <ng-container *ngFor="let tab of tabs; let i = index">
    <ng-container *ngIf="tooltipPrefix; else menuWithoutTooltips">
      <admin-tooltip *ngIf="tab.displayed" [key]="tooltipPrefix + tab.path" [displayIcon]="false">
        <ng-template [ngTemplateOutlet]="tabContent"></ng-template>
      </admin-tooltip>
    </ng-container>

    <ng-template #menuWithoutTooltips>
      <ng-template *ngIf="tab.displayed" [ngTemplateOutlet]="tabContent"></ng-template>
    </ng-template>

    <ng-template #tabContent>
      <a
        mat-tab-link
        [active]="activeTab === tab"
        [class.main-tab-selected]="isTabsOpen.at(i)"
        [routerLink]="tab.path"
        [attr.is-main-tab]="true"
        (click)="toggleSubTab(i)"
      >
        <mat-icon matPrefix class="nav-icon" [svgIcon]="tab.icon" [attr.is-main-tab]="true"></mat-icon>
        {{ tab.label }}
        <mat-icon *ngIf="tab.subTabs" class="main-tab-chevron-icon" [attr.is-main-tab]="true">{{
          isTabsOpen.at(i) ? 'expand_less' : 'expand_more'
        }}</mat-icon>
      </a>
    </ng-template>

    <ng-template
      *ngIf="tab.subTabs"
      cdkConnectedOverlay
      [cdkConnectedOverlayLockPosition]="true"
      [cdkConnectedOverlayOpen]="isTabsOpen.at(i)!"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayWidth]="matTabNav?.nativeElement.offsetWidth"
      (overlayOutsideClick)="closeAllTabs($event)"
      (attach)="setIsSubTabOpen(true)"
      (detach)="setIsSubTabOpen(false)"
    >
      <ul class="sub-tabs-overlay">
        <ng-container *ngFor="let subTab of tab.subTabs">
          <li *ngIf="subTab.displayed" [class.sub-tab-selected]="subTab.path === thirdUrlPathSegment">
            <div [routerLink]="subTab.path" (click)="isTabsOpen.fill(false)">
              <a mat-tab-link disableRipple>
                <mat-icon matPrefix class="nav-icon" [svgIcon]="subTab.icon"></mat-icon>
                {{ subTab.label }}
                <mat-icon matPrefix svgIcon="chevron-right" class="sub-tab-chevron-icon"></mat-icon>
              </a>
              <div *ngIf="subTab.description">{{ subTab.description }}</div>
            </div>
          </li>
        </ng-container>
      </ul>
    </ng-template>
  </ng-container>
</nav>

<ng-content></ng-content>
