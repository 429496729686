<div *ngIf="targetCurrency" [class.overlay]="loading$ | async" class="space-out">
  <h4>Enter amount of points to {{ redemptionType }}</h4>
  <form [formGroup]="conversionForm" (keydown.enter)="$event.preventDefault()">
    <div
      class="slim-form-field"
      fxLayout.gt-md="row wrap"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      fxLayoutGap="11px"
    >
      <mat-form-field class="filter-field" [class.overlay]="currencyConversionLoading$ | async">
        <mat-label>{{ targetCurrencyLabel }}</mat-label>
        <input
          matInput
          type="number"
          formControlName="transferAmount"
          [readonly]="currencyConversionLoading$ | async"
          [step]="targetCurrency.targetMinimumTransfer"
        />
        <mat-error>Required</mat-error>
        <mat-hint>
          Enter the amount in multiples of {{ targetCurrency.targetMinimumTransfer }}.<br />
          Min amount: {{ targetCurrency.targetMinimumTransfer }}. Max amount:
          {{ targetCurrency.targetMaximumTransfer }}.
        </mat-hint>
      </mat-form-field>

      <mat-icon fxHide fxShow.gt-md>arrow_forward</mat-icon>
      <mat-icon fxShow fxHide.gt-md class="center-icon">arrow_downward</mat-icon>

      <mat-form-field class="filter-field" [class.overlay]="currencyConversionLoading$ | async">
        <mat-label>Total points redeemed</mat-label>
        <input
          matInput
          type="number"
          formControlName="redemptionAmount"
          [readonly]="currencyConversionLoading$ | async"
          [step]="targetCurrency.denomination"
        />
        <mat-error>Required</mat-error>
        <mat-hint>
          Enter the amount in multiples of {{ targetCurrency.minimumTransfer }}.<br />
          Min amount: {{ targetCurrency.minimumTransfer }}. Max amount: {{ targetCurrency.maximumTransfer }}.
        </mat-hint>
      </mat-form-field>
    </div>
  </form>
</div>
