import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { EnrollmentsFilter, PointsAccount, Product, User } from '.';

export interface GroupedEnrollment {
  id: string;
  referenceId: string;
  partnerUserId: string;
  userId: string;
  productCode: string;
  groupReferenceId: string;
  groupRole: string;
  groupName: string;
  status: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  metadata: object;
}

export interface Enrollment {
  createdAt: string;
  groupRole: string;
  id: string;
  pointsAccount: PointsAccount;
  product: Product;
  referenceId: string;
  updatedAt: string;
  user: User;
  groupedEnrollments: GroupedEnrollment[];
}

export enum AccountType {
  B = 'Rewards Administrator',
  C = 'Cardholder'
}

export enum RedemptionLevel {
  C = 'Consolidated',
  I = 'Individual'
}

export const enrollmentAdapter = createEntityAdapter<Enrollment>();

export interface EnrollmentState extends EntityState<Enrollment>, CallState {
  filter: EnrollmentsFilter;
  isTopLevelView: boolean;
}

export const initialState: EnrollmentState = {
  ...enrollmentAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  isTopLevelView: false
};

interface EnrollmentStatusObject {
  [key: string]: {
    displayValue: string;
    displayClass?: 'status-active' | 'status-pending' | 'status-inactive' | 'status-neutral';
  };
}

export interface EnrollmentsFilterFormValue {
  customerId?: string;
  parentId?: string;
  productReferenceId?: string;
  accountType?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  postalCode?: string;
  status?: string;
  dateOfBirth?: string;
  updatedAtDateRange?: {
    updatedFrom?: string;
    updatedTo?: string;
  };
}

export const enrollmentStatus: EnrollmentStatusObject = {
  active: {
    displayValue: 'active',
    displayClass: 'status-active'
  },
  closing: {
    displayValue: 'closing',
    displayClass: 'status-neutral'
  },
  closed: {
    displayValue: 'closed (customer)',
    displayClass: 'status-inactive'
  },
  closed_by_partner: {
    displayValue: 'closed (client)',
    displayClass: 'status-inactive'
  },
  closed_by_deceased: {
    displayValue: 'closed (deceased)',
    displayClass: 'status-inactive'
  }
};
