var SessionStorageUtils = /** @class */ (function () {
    function SessionStorageUtils() {
    }
    SessionStorageUtils.prototype.get = function (key) {
        var value = sessionStorage.getItem(key);
        if (value === null) {
            return null;
        }
        return JSON.parse(value);
    };
    SessionStorageUtils.prototype.getAllKeys = function () {
        return Object.keys(sessionStorage);
    };
    SessionStorageUtils.prototype.save = function (key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    };
    SessionStorageUtils.prototype.remove = function (key) {
        sessionStorage.removeItem(key);
    };
    return SessionStorageUtils;
}());
export { SessionStorageUtils };
