<div class="data-table-container">
  <h2>Changes</h2>

  <table mat-table [dataSource]="changes" class="w-full">
    <ng-container matColumnDef="path" class="text-primary">
      <th mat-header-cell *matHeaderCellDef>
        <h4 class="text-primary">Path</h4>
      </th>
      <td mat-cell *matCellDef="let change">{{ change.path }}</td>
    </ng-container>

    <ng-container matColumnDef="operation">
      <th mat-header-cell *matHeaderCellDef class="text-primary">
        <h4 class="text-primary">Operation</h4>
      </th>
      <td mat-cell *matCellDef="let change" [class]="change.operation.toLowerCase()">{{ change.operation }}</td>
    </ng-container>

    <ng-container matColumnDef="was">
      <th mat-header-cell *matHeaderCellDef class="text-primary">
        <h4 class="text-primary">Was</h4>
      </th>
      <td mat-cell *matCellDef="let change">{{ change.was }}</td>
    </ng-container>

    <ng-container matColumnDef="is">
      <th mat-header-cell *matHeaderCellDef class="text-primary">
        <h4 class="text-primary">Is</h4>
      </th>
      <td mat-cell *matCellDef="let change">{{ change.is }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-secondary"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="6">No changes available</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
