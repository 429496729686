<ng-container [ngSwitch]="renderState$ | async">
  <mat-card appearance="outlined" *ngSwitchCase="'error'">
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
      <div>
        <h4><mat-icon [inline]="true">warning</mat-icon> Loading User Campaigns failed</h4>
      </div>
    </div>
  </mat-card>
  <mat-card appearance="outlined" *ngSwitchCase="'loading'" class="mat-card-elevation-z8 details-card">
    <admin-spinner></admin-spinner>
  </mat-card>
  <mat-card appearance="outlined" *ngSwitchCase="'content'" class="mat-elevation-z1 mat-card">
    <mat-card-content>
      <mat-card-title>Campaigns</mat-card-title>
      <div [class.overlay]="loading$ | async" class="data-table-container w-full">
        <table mat-table [dataSource]="campaigns$ | async" class="mat-elevation-z1 data-table" multiTemplateDataRows>
          <ng-container *ngFor="let columnName of displayedColumns" [matColumnDef]="columnName">
            <th mat-header-cell *matHeaderCellDef>{{ columnName | customTitleCase: 'fromCamelCase' }}</th>
            <td mat-cell *matCellDef="let campaign">{{ campaign[columnName] }}</td>
          </ng-container>

          <ng-container matColumnDef="detail_expand">
            <td mat-cell *matCellDef="let campaign" [attr.colspan]="displayedColumns.length">
              <div class="expanded-detail" [@detail_expand]="campaign === expandedColumns ? 'expanded' : 'collapsed'">
                <div class="details-container">
                  <div class="item">
                    <div class="item__key">
                      <h4>Logo</h4>
                    </div>
                    <span class="item__value">
                      <a [href]="campaign.logoUrl" *ngIf="campaign.logoUrl; else showNA">
                        <img mat-card-image class="logo-icon-image" [src]="campaign.logoUrl" />
                      </a>
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Cover Image</h4>
                    </div>
                    <span class="item__value">
                      <a [href]="campaign.coverImageUrl" *ngIf="campaign.coverImageUrl; else showNA">
                        <img mat-card-image class="logo-icon-image" [src]="campaign.coverImageUrl" />
                      </a>
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Main Category Icon</h4>
                    </div>
                    <span class="item__value">
                      <a [href]="campaign.mainCategoryIconUrl" *ngIf="campaign.mainCategoryIconUrl; else showNA">
                        <img mat-card-image class="logo-icon-image" [src]="campaign.mainCategoryIconUrl" />
                      </a>
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Deep Link</h4>
                    </div>
                    <span class="item__value">
                      <a [href]="campaign.deepLink">
                        {{ campaign.deepLink }}
                      </a>
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Short Description</h4>
                    </div>
                    <span class="item__value">
                      {{ campaign.shortDescription }}
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Terms</h4>
                    </div>
                    <span class="item__value">
                      {{ campaign.terms }}
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>CTA Button Text</h4>
                    </div>
                    <span class="item__value">
                      {{ campaign.ctaButtonText }}
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Display Type</h4>
                    </div>
                    <span class="item__value">
                      {{ campaign.displayType }}
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Ranking</h4>
                    </div>
                    <span class="item__value">
                      {{ campaign.ranking }}
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>Start Time</h4>
                    </div>
                    <span class="item__value">
                      {{ campaign.startTime }}
                    </span>
                  </div>
                  <div class="item">
                    <div class="item__key">
                      <h4>End Time</h4>
                    </div>
                    <span class="item__value">
                      {{ campaign.endTime }}
                    </span>
                  </div>
                </div>
                <h2>Eligibility Trackings</h2>
                <ng-container *ngIf="campaign.eligibilityTrackings?.length > 0; else showNA">
                  <table
                    mat-table
                    [dataSource]="campaign.eligibilityTrackings"
                    class="mat-elevation-z1 data-table"
                    multiTemplateDataRows
                  >
                    <ng-container
                      *ngFor="let columnName of eligibilityTrackingsDisplayedColumns"
                      [matColumnDef]="columnName"
                    >
                      <th mat-header-cell *matHeaderCellDef>{{ columnName | customTitleCase: 'fromCamelCase' }}</th>
                      <td mat-cell *matCellDef="let eligibilityTrackings">{{ eligibilityTrackings[columnName] }}</td>
                    </ng-container>

                    <ng-container matColumnDef="eligibility_trackings_detail_expand">
                      <td
                        mat-cell
                        *matCellDef="let eligibilityTrackings"
                        [attr.colspan]="eligibilityTrackingsDisplayedColumns.length"
                      >
                        <div
                          class="expanded-detail"
                          [@eligibility_trackings_detail_expand]="
                            eligibilityTrackings === expandedEligibilityColumns ? 'expanded' : 'collapsed'
                          "
                        >
                          <mat-card-content *ngIf="eligibilityTrackings.fulfillments">
                            <h2>Fulfillments</h2>
                            <table
                              mat-table
                              [dataSource]="eligibilityTrackings.fulfillments"
                              class="mat-elevation-z1 data-table"
                              multiTemplateDataRows
                            >
                              <ng-container matColumnDef="userId">
                                <th mat-header-cell *matHeaderCellDef>User ID</th>
                                <td mat-cell *matCellDef="let fulfillment">
                                  <a [routerLink]="'../../' + fulfillment.userId">
                                    {{ fulfillment.userId }}
                                  </a>
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="points">
                                <th mat-header-cell *matHeaderCellDef>Points</th>
                                <td mat-cell *matCellDef="let fulfillment">
                                  {{ fulfillment.points | roundedNumber: pointsActivityDecimals }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="fulfillmentDate">
                                <th mat-header-cell *matHeaderCellDef>Fulfillment Date</th>
                                <td mat-cell *matCellDef="let fulfillment">{{ fulfillment.fulfillmentDate }}</td>
                              </ng-container>

                              <ng-container matColumnDef="pointsBankTransactionId">
                                <th mat-header-cell *matHeaderCellDef>Points Bank Transaction ID</th>
                                <td mat-cell *matCellDef="let fulfillment">
                                  {{ fulfillment.pointsBankTransactionId }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="expiryRule">
                                <th mat-header-cell *matHeaderCellDef>Expiry Rule</th>
                                <td mat-cell *matCellDef="let fulfillment">{{ fulfillment.expiryRule }}</td>
                              </ng-container>

                              <ng-container matColumnDef="errors">
                                <th mat-header-cell *matHeaderCellDef>Errors</th>
                                <td mat-cell *matCellDef="let fulfillment">
                                  <div *ngFor="let error of fulfillment.errors">
                                    {{ error.code }}: {{ error.message }}
                                  </div>
                                </td>
                              </ng-container>

                              <tr mat-header-row *matHeaderRowDef="fulfillmentsDisplayedColumns"></tr>
                              <ng-container *ngIf="!(loading$ | async)">
                                <tr mat-row *matRowDef="let row; columns: fulfillmentsDisplayedColumns"></tr>
                              </ng-container>
                            </table>
                          </mat-card-content>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="eligibilityTrackingsDisplayedColumns"></tr>
                    <ng-container *ngIf="!(loading$ | async)">
                      <tr
                        mat-row
                        *matRowDef="let row; columns: eligibilityTrackingsDisplayedColumns"
                        class="element-row"
                        [class.expanded-row]="expandedEligibilityColumns === row"
                        (click)="expandedEligibilityColumns = expandedEligibilityColumns === row ? null : row"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: ['eligibility_trackings_detail_expand']"
                        class="detail-row"
                      ></tr>
                    </ng-container>
                  </table>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <ng-container *ngIf="!(loading$ | async)">
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="element-row"
              [class.expanded-row]="expandedColumns === row"
              (click)="expandedColumns = expandedColumns === row ? null : row"
            ></tr>

            <tr mat-row *matRowDef="let row; columns: ['detail_expand']" class="detail-row"></tr>
          </ng-container>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-template #showNA>
  <div class="user-campaigns-showNA">N/A</div>
</ng-template>
