import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { KeysFilter } from '../../types/keys-filter.type';
import { Key, keyAdapter, KeyState, PrivateKey } from '../../types/keys.type';

const getKeyState = createFeatureSelector<KeyState>('key');

const { selectAll: getKeysList, selectEntities: getKeysDictionary } = keyAdapter.getSelectors(getKeyState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<KeyState>(getKeyState);

const keyDetector = createDetector<Key>();

const isKeysLoaded: Selector<KeyState, boolean> = createSelector(
  getKeysDictionary,
  isBatchResting,
  (keys, batchResting) => keyDetector('allLoaded')(keys) && batchResting
);

// Key is not loaded after step up, this function check if the key details are loaded after step up by using algorithm and dispatch accordingly
const isKeyLoaded = (id: string): Selector<KeyState, boolean> =>
  createSelector(getKeysDictionary, entities => keyDetector('oneLoaded')(entities, id) && !!entities[id]?.algorithm);

const isTopLevelView: Selector<KeyState, boolean> = createSelector(getKeyState, state => state.isTopLevelView);

const getKeyById = (id: string): Selector<KeyState, Key> => createSelector(getKeysDictionary, entities => entities[id]);

const getPrivateKey = (id: string): Selector<KeyState, PrivateKey> =>
  createSelector(getKeysDictionary, entities => entities[id].privateKey);

const getFilter: Selector<KeyState, KeysFilter> = createSelector(getKeyState, state => state.filter);

export const keysQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getKeyById,
  getPrivateKey,
  getKeysList,
  getKeysDictionary,
  isKeyLoaded,
  isKeysLoaded,
  isTopLevelView,
  getFilter
};
