import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { appConnectionAdapter, AppConnectionState, initialState } from '../../types/app-connections.type';
import {
  createAppConnection,
  createAppConnectionFailure,
  createAppConnectionSuccess,
  fetchAppConnection,
  fetchAppConnectionFailure,
  fetchAppConnectionSuccess,
  loadAppConnection,
  loadAppConnectionFailure,
  loadAppConnections,
  loadAppConnectionsFailure,
  loadAppConnectionsSuccess,
  loadAppConnectionSuccess,
  updateAppConnection,
  updateAppConnectionFailure,
  updateAppConnectionSigningKeys,
  updateAppConnectionSigningKeysFailure,
  updateAppConnectionSigningKeysSuccess,
  updateAppConnectionSuccess
} from '../actions/app-connections.actions';

export const appConnectionTriggers: Required<EntityTriggers> = {
  single: {
    loading: [
      createAppConnection.type,
      updateAppConnection.type,
      updateAppConnectionSigningKeys.type,
      loadAppConnection.type
    ],
    resting: [
      createAppConnectionSuccess.type,
      updateAppConnectionSuccess.type,
      updateAppConnectionSigningKeysSuccess.type,
      loadAppConnectionSuccess.type
    ],
    erroring: [
      createAppConnectionFailure.type,
      updateAppConnectionFailure.type,
      updateAppConnectionSigningKeysFailure.type,
      loadAppConnectionFailure.type
    ]
  },
  batch: {
    loading: [loadAppConnections.type],
    resting: [loadAppConnectionsSuccess.type],
    erroring: [loadAppConnectionsFailure.type]
  }
};

export const appConnectionFetchListTriggers: FetchListTriggers = {
  loading: [fetchAppConnection.type],
  resting: [fetchAppConnectionSuccess.type],
  erroring: [fetchAppConnectionFailure.type]
};

export function appConnectionsReducer(state: AppConnectionState = initialState, action: Action): AppConnectionState {
  return {
    ...callStateReducer(baseReducer, appConnectionTriggers)(state, action),
    ...fetchStateReducer(appConnectionFetchListTriggers, 'appConnection')(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(fetchAppConnectionSuccess, (state, { appConnection }) => appConnectionAdapter.upsertOne(appConnection, state)),
  on(loadAppConnectionsSuccess, (state, { appConnections }) => appConnectionAdapter.setAll(appConnections, state)),
  on(loadAppConnectionSuccess, (state, { appConnection }) => appConnectionAdapter.upsertOne(appConnection, state)),
  on(createAppConnectionSuccess, (state, { appConnection }) => appConnectionAdapter.addOne(appConnection, state)),
  on(updateAppConnectionSuccess, (state, { appConnection }) => appConnectionAdapter.setOne(appConnection, state)),
  on(updateAppConnectionSigningKeysSuccess, (state, { appConnection }) =>
    appConnectionAdapter.setOne(appConnection, state)
  )
);
