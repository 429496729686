import { inject, ProviderToken } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { createResolver } from '@shared/route/resolver-helper';

import { loadBootstrap } from '../store/actions/bootstraps.actions';
import { bootstrapsQuery } from '../store/selectors/bootstraps.selectors';
import { GiftCardState } from '../types/gift-cards.type';

export const bootstrapResolver: ResolveFn<void> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  isGlobalApp: boolean = inject('isGlobalApp' as unknown as ProviderToken<boolean>)
): Observable<void> => {
  if (isGlobalApp) {
    return of(null);
  }

  return createResolver<GiftCardState>({
    query: bootstrapsQuery.isGiftCardsBootstrapLoaded,
    action: loadBootstrap({})
  });
};
