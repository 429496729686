import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Nullable } from '@shared/types';

export interface CustomerToken {
  token: string;
}

export interface AnalyticsState extends CallState {
  customerToken: Nullable<CustomerToken>;
}

export const initialState: AnalyticsState = {
  ...initialCallState,
  customerToken: null
};
