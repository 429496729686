import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { MerchandiseCategory } from '../../types/merchandise-categories.type';

export const loadMerchandiseCategories = createAction('[Merchandise Categories] Load Merchandise Categories');
export const loadMerchandiseCategoriesSuccess = createAction(
  '[Merchandise Categories] Load Merchandise Categories Success',
  props<{ merchandiseCategories: MerchandiseCategory[] }>()
);
export const loadMerchandiseCategoriesFailure = createAction(
  '[Merchandise Categories] Load Merchandise Categories Failure',
  props<ErrorState>()
);

export const loadMerchandiseCategory = createAction(
  '[Merchandise Categories] Load Merchandise Category',
  props<{ id: number }>()
);
export const loadMerchandiseCategorySuccess = createAction(
  '[Merchandise Categories] Load Merchandise Category Success',
  props<{ merchandiseCategory: MerchandiseCategory }>()
);
export const loadMerchandiseCategoryFailure = createAction(
  '[Merchandise Categories] Load Merchandise Category Failure',
  props<ErrorState>()
);
