import { Injectable } from '@angular/core';
import { OpenApiSchema } from '@kaligo/genesis';

import { Nullable, PropertiesType } from '@shared/types';

import { ObjectUtils } from './object-utils';

const keyValueSchema: OpenApiSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      key: {
        type: 'string'
      },
      value: {
        type: 'string'
      }
    }
  }
};

@Injectable({ providedIn: 'root' })
export class ConfigSchemaUtils {
  reorderSchemaProperties(configSchema: OpenApiSchema): Nullable<OpenApiSchema> {
    // Create a copy to allow mutation
    const configSchemaCopy = ObjectUtils.deepCopy(configSchema);
    // separate properties into 3 groups: "non array/boolean/object type", "array type", "boolean type", "object type"
    // aka move array, boolean and object type fields to the end
    if (configSchemaCopy?.properties) {
      const entryGroups = Object.entries(configSchemaCopy.properties)
        .reduce(
          ([head, upperMiddle, lowerMiddle, tail], [key, schema]) => {
            // convert schema which allows empty object to key value schema
            if (!schema.properties && schema.type === 'object') {
              schema = keyValueSchema;
            }
            // Recursive call to reorder inner object
            if (schema.properties && Object.keys(schema.properties).length > 0) {
              schema.properties = this.reorderSchemaProperties(schema)!.properties;
            }
            switch (schema.type) {
              case 'array': {
                return [head, [...upperMiddle, [key, schema]], lowerMiddle, tail];
              }
              case 'boolean': {
                return [head, upperMiddle, [...lowerMiddle, [key, schema]], tail];
              }
              case 'object': {
                return [head, upperMiddle, lowerMiddle, [...tail, [key, schema]]];
              }
              default: {
                return [[...head, [key, schema]], upperMiddle, lowerMiddle, tail];
              }
            }
          },
          [[], [], [], []]
        )
        .flat();

      // construct new properties object and return schema
      return {
        ...configSchemaCopy,
        properties: Object.fromEntries(
          entryGroups.map(([entryKey, entryValue]) => [entryKey, (typeof entryValue  === 'string') ? entryValue : this.updateConfigProperties(entryValue)])
        )
      };
    } else {
      return null;
    }
  }

  updateConfigProperties(entryValue: PropertiesType): PropertiesType {
    if (entryValue.default) {
      const defaultValue = ` (Default: ${entryValue.default})`;
      const description = (entryValue.description || '') + defaultValue;

      return { ...entryValue, description };
    }

    return entryValue;
  }
}
