import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { HttpError } from '@core/types';

import { PointsStatementsService } from '../../services/points-statements/points-statements.service';
import {
  loadPointsStatement,
  loadPointsStatementFailure,
  loadPointsStatementSuccess
} from '../actions/points-statements.actions';

@Injectable()
export class PointsStatementsEffects {
  loadPointsStatement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPointsStatement),
      exhaustMap(({ pointsAccountId, pointsStatementId }) =>
        this.pointsStatementsService.getPointsStatement(pointsAccountId, pointsStatementId).pipe(
          map(pointsStatement => loadPointsStatementSuccess({ pointsStatement })),
          catchError((error: HttpError) => of(loadPointsStatementFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pointsStatementsService: PointsStatementsService
  ) {}
}
