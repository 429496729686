import { createAction, props } from '@ngrx/store';

import { ErrorState, TenantBootstrap } from '@core/types';

export const loadTenantBootstrap = createAction('[Tenant Bootstrap] Load Tenant Bootstrap');
export const loadTenantBootstrapSuccess = createAction(
  '[Tenant Bootstrap] Load Tenant Bootstrap Success',
  props<{ tenantBootstrap: TenantBootstrap }>()
);
export const loadTenantBootstrapFailure = createAction(
  '[Tenant Bootstrap] Load Tenant Bootstrap Failure',
  props<ErrorState>()
);
