import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { TooltipKey } from './tooltip-keys.type';

export interface Tooltip {
  key: TooltipKey;
  value: string;
}

export const tooltipAdapter = createEntityAdapter<Tooltip>({
  selectId: (tooltip: Tooltip): string => tooltip.key
});

export interface TooltipState extends EntityState<Tooltip>, CallState {
  fetchList: string[];
}

export const initialState: TooltipState = {
  ...tooltipAdapter.getInitialState<CallState>(initialCallState),
  fetchList: []
};
