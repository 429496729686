<div
  class="admin-form-fields-grid-wrapper dynamic-rows"
  [ngClass]="{
    cumulative: attributeData?.isCumulativeType,
    'qualification-action': !parentKey && useEventTypeAsMainAttribute
  }"
>
  <!-- If it is main attribute, and we are in Campaigns, we will only need
  to show only the action, otherwise we will show full form   -->
  @if (!parentKey && useEventTypeAsMainAttribute) {
    <mat-form-field [attr.data-testid]="'segment-select-action-' + elementIndex">
      <mat-label>Action</mat-label>
      <admin-drawer-select [formControl]="ruleForm.controls.value" (drawerClosed)="dependentSelectDrawer.resetForm()">
        <admin-logic-rule-attribute-options-drawer
          #dependentSelectDrawer
          [attributeGroupOptions]="attributeValueAsGroupOptions()"
        />
      </admin-drawer-select>
      <mat-error>Please select an action.</mat-error>
    </mat-form-field>
  } @else {
    <mat-form-field [attr.data-testid]="'segment-select-attribute-' + elementIndex">
      <mat-label>Attribute</mat-label>
      @if (parentKey) {
        <mat-select
          [formControl]="ruleForm.controls.attribute"
          disableOptionCentering
          (selectionChange)="handleAttributeChanged($event.value)"
        >
          @for (option of logicRuleAttributeOptions(); track option.value) {
            <mat-option [value]="option.value">{{ option.label }}</mat-option>
          }
        </mat-select>
      } @else {
        <admin-drawer-select
          [formControl]="ruleForm.controls.attribute"
          (valueChange)="handleAttributeChanged($event)"
          (drawerClosed)="selectDrawer.resetForm()"
        >
          <admin-logic-rule-attribute-options-drawer
            #selectDrawer
            [attributeGroupOptions]="logicRuleAttributeGroupOptions()"
          />
        </admin-drawer-select>
      }

      <mat-error>Please select an attribute.</mat-error>
    </mat-form-field>

    <mat-form-field [attr.data-testid]="'segment-select-function-' + elementIndex">
      <mat-label>Function</mat-label>
      <mat-select [formControl]="ruleForm.controls.operator" disableOptionCentering>
        @for (operator of attributeData?.operators ?? []; track operator.value) {
          <mat-option [value]="operator.value">{{ operator.label }}</mat-option>
        }
      </mat-select>
      <mat-error>Please select a function.</mat-error>
    </mat-form-field>

    @switch (attributeData?.type) {
      @case ('tags') {
        <mat-form-field
          class="fixed-height-chip-input"
          [class.chip-filled]="isArray(ruleForm.controls.value.value) && ruleForm.controls.value.value.length > 0"
          [attr.data-testid]="'segment-value-' + elementIndex"
        >
          <mat-label>Value</mat-label>
          <admin-input-chip-list [formControl]="ruleForm.controls.value"></admin-input-chip-list>
          <mat-error>Please enter a value.</mat-error>
        </mat-form-field>
      }
      @case ('datetime') {
        <admin-logic-rule-datetime-input-field
          [operator]="ruleForm.controls.operator.value"
          [formControl]="ruleForm.controls.value"
          [attr.data-testid]="'segment-value-' + elementIndex"
        />
      }
      @case ('date') {
        <admin-logic-rule-datetime-input-field
          [isIncludedTime]="false"
          [operator]="ruleForm.controls.operator.value"
          [formControl]="ruleForm.controls.value"
          [attr.data-testid]="'segment-value-' + elementIndex"
        />
      }
      @case ('select') {
        <ng-container *ngTemplateOutlet="select"></ng-container>
      }
      @case ('dependent_select') {
        <ng-container *ngTemplateOutlet="select"></ng-container>
      }
      <!-- We must define a number case cause if we use [type]="attributeData?.type"
      then Angular will always convert it to string instead of a number
      Issues: https://github.com/angular/angular/issues/13243 -->
      @case ('number') {
        <mat-form-field [attr.data-testid]="'segment-value-' + elementIndex">
          <mat-label>Value</mat-label>
          <input matInput [formControl]="ruleForm.controls.value" type="number" />
          <mat-error>Please enter a value.</mat-error>
        </mat-form-field>
      }
      @default {
        <mat-form-field [attr.data-testid]="'segment-value-' + elementIndex">
          <mat-label>Value</mat-label>
          <input matInput [formControl]="ruleForm.controls.value" [type]="attributeData?.type" />
          <mat-error>Please enter a value.</mat-error>
        </mat-form-field>
      }
    }

    <ng-template #select>
      <mat-form-field [attr.data-testid]="'segment-value-' + elementIndex">
        <mat-label>Value</mat-label>
        <mat-select [formControl]="ruleForm.controls.value" disableOptionCentering>
          @for (selectOption of attributeValueAsOptions(); track selectOption.value) {
            <mat-option [value]="selectOption.value">{{ selectOption.label }}</mat-option>
          }
        </mat-select>
        <mat-error>Please enter a value.</mat-error>
      </mat-form-field>
    </ng-template>

    @if (attributeData?.isCumulativeType && ruleForm.controls.timeRange) {
      <mat-form-field [attr.data-testid]="'segment-timeframe-' + elementIndex">
        <mat-label>Timeframe</mat-label>
        <mat-select [formControl]="ruleForm.controls.timeRange" disableOptionCentering>
          @for (option of cumulativeTimeRangeResourceOptions; track option.value) {
            <mat-option [value]="option.value">{{ option.label }}</mat-option>
          }
        </mat-select>
        <mat-error>Please select a timeframe.</mat-error>
      </mat-form-field>
    }
  }

  <button
    mat-icon-button
    class="remove-button"
    type="button"
    (click)="ruleRemoved.emit()"
    [attr.data-testid]="'segment-remove-' + elementIndex"
  >
    <mat-icon class="prefix-icon" svgIcon="remove"></mat-icon>
  </button>
</div>

@if (attributeData && childAttributeOptions.length > 0) {
  <div class="sub-rules-container">
    <div class="rules-form-container">
      @if (ruleForm.controls.connector) {
        <mat-button-toggle-group class="connector-toggle" [formControl]="ruleForm.controls.connector">
          <mat-button-toggle value="and">AND</mat-button-toggle>
          @if (ruleForm.controls.conditions?.controls.length > 1) {
            <mat-button-toggle value="or">OR</mat-button-toggle>
          }
        </mat-button-toggle-group>
        <div class="padding"></div>
      }
      @for (control of ruleForm.controls.conditions?.controls ?? []; track control; let index = $index) {
        <div
          class="connect-line"
          [ngClass]="{
            'only-rule': ruleForm.controls.conditions.controls.length === 1,
            'first-rule': ruleForm.controls.conditions.controls.length !== 1 && index === 0,
            'last-rule':
              ruleForm.controls.conditions.controls.length !== 1 &&
              index === ruleForm.controls.conditions.controls.length - 1
          }"
        >
          <div class="vertical"></div>
          <div class="horizontal"></div>
        </div>

        <div class="rule-container">
          <admin-logic-rule
            [formControl]="control"
            [attributeOptions]="childAttributeOptions"
            [parentKey]="attributeData.attribute"
            (ruleRemoved)="removeRule(index)"
          />
        </div>
      }
    </div>
    <button type="button" mat-button (click)="addCondition()">
      <mat-icon svgIcon="add-outline" />
      Add nested condition
    </button>
  </div>
}
