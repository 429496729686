import { catchError, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';
import { routerForceNavigate, routerNavigate } from '@core/store/router/actions/router.actions';
import { SCOPES_OR } from '@core/types';

import { UsersService } from '../../services/users.service';
import { loadIdentities } from '../actions/identities.actions';
import { loadMfaIdentities } from '../actions/mfa-identities.actions';
import {
  blockUser,
  blockUserFailure,
  blockUserLogin,
  blockUserLoginFailure,
  blockUserLoginSuccess,
  blockUserSuccess,
  createUser,
  createUserFailure,
  createUserSuccess,
  enrollAdmin,
  enrollAdminFailure,
  enrollAdminSuccess,
  enrollAscendaAdmin,
  enrollAscendaAdminFailure,
  enrollAscendaAdminSuccess,
  enrollTeamMember,
  enrollTeamMemberFailure,
  enrollTeamMemberSuccess,
  enrollUser,
  enrollUserFailure,
  enrollUserSuccess,
  fetchUser,
  fetchUserFailure,
  fetchUsers,
  fetchUsersFailure,
  fetchUsersSuccess,
  fetchUserSuccess,
  loadUser,
  loadUserFailure,
  loadUsers,
  loadUsersCount,
  loadUsersCountFailure,
  loadUsersCountSuccess,
  loadUsersFailure,
  loadUsersSuccess,
  loadUserSuccess,
  logoutUser,
  logoutUserFailure,
  logoutUserSuccess,
  removeTeamMember,
  removeTeamMemberFailure,
  removeTeamMemberSuccess,
  requestResetPassword,
  requestResetPasswordFailure,
  requestResetPasswordSuccess,
  unblockUser,
  unblockUserFailure,
  unblockUserLogin,
  unblockUserLoginFailure,
  unblockUserLoginSuccess,
  unblockUserSuccess,
  unenrollUser,
  unenrollUserFailure,
  unenrollUserSuccess,
  updateUser,
  updateUserFailure,
  updateUsername,
  updateUsernameFailure,
  updateUsernameSuccess,
  updateUserSuccess
} from '../actions/users.actions';

@Injectable()
export class UsersEffects {
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUsers),
      switchMap(action =>
        this.userService.getUsers(action.filter).pipe(
          map(result => loadUsersSuccess({ result })),
          catchError(error => of(loadUsersFailure({ error })))
        )
      )
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      exhaustMap(action =>
        this.userService.getUser(action.id).pipe(
          map(user => loadUserSuccess({ user })),
          catchError(error => of(loadUserFailure({ error })))
        )
      )
    )
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUser),
      mergeMap(action =>
        // skip creating audit log when fetching user for tags
        this.userService.getUser(action.id, true).pipe(
          map(user => fetchUserSuccess({ user })),
          catchError(() => of(fetchUserFailure({ id: action.id })))
        )
      )
    )
  );

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUsers),
      mergeMap(({ ids }) =>
        this.userService.getUsers({ ids, skipAuditLog: true }).pipe(
          map(result => fetchUsersSuccess({ data: result.data, batch: true })),
          catchError(() => of(fetchUsersFailure({ batch: true })))
        )
      )
    )
  );

  loadUsersCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUsersCount),
      switchMap(action =>
        this.userService.getUsersCount(action.filter).pipe(
          map(result => loadUsersCountSuccess({ result })),
          catchError(error => of(loadUsersCountFailure({ error })))
        )
      )
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUser),
      switchMap(action =>
        this.userService.createUser(action.user).pipe(
          switchMap(() => of(createUserSuccess())),
          catchError(error => of(createUserFailure(error)))
        )
      )
    )
  );

  createUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserSuccess),
      map(_ => routerForceNavigate({ path: '/customers' }))
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      withLatestFrom(this.store.select(authQuery.getUserId)),
      exhaustMap(([{ user, createdRoles, deletedRoles, previousPath, message, errorMessage }, userId]) => {
        const result$ =
          user.id === userId
            ? this.userService.updatePersonalProfile(user, createdRoles, deletedRoles)
            : this.userService.updateUser(user, createdRoles, deletedRoles);

        return result$.pipe(
          map(result => updateUserSuccess({ user: result, previousPath, message })),
          catchError(error => of(updateUserFailure({ error, errorMessage })))
        );
      })
    )
  );

  updateUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserSuccess),
      map(action => {
        if (action.message) {
          this.snackBar.open(action.message, 'Dismiss', { panelClass: 'mat-snack-bar-success' });
        }
        return routerForceNavigate({ path: action.previousPath || this.router.url.replace('/edit', '') + '/details' });
      })
    )
  );

  updateUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserFailure),
        tap(action => this.snackBar.open(action.errorMessage || action.error.message, 'Dismiss'))
      ),
    { dispatch: false }
  );

  requestResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestResetPassword),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      withLatestFrom(this.store.select(authQuery.getUserTenantId)),
      exhaustMap(([action, tenantId]) =>
        this.userService.requestResetPassword(action.userId, tenantId).pipe(
          map(() => requestResetPasswordSuccess()),
          catchError(error => of(requestResetPasswordFailure(error)))
        )
      )
    )
  );

  blockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(blockUser),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.userService.blockUser(action.id).pipe(
          map(user => blockUserSuccess(user, action.dialogRefId)),
          catchError(error => of(blockUserFailure(error)))
        )
      )
    )
  );

  // To avoid racing condition between "loadUser" (when redirected from GH after step up) and block/unblock actions (see below)
  // and keep state consistent, we intentionally dispatch loadUser action again after block/unblock action success
  blockUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(blockUserSuccess),
        tap(({ user, dialogRefId }) => {
          this.closeDialog(dialogRefId);
          this.store.dispatch(loadUser({ id: user.id }));
        })
      ),
    { dispatch: false }
  );

  unblockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unblockUser),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.userService.unblockUser(action.id).pipe(
          map(user => unblockUserSuccess(user, action.dialogRefId)),
          catchError(error => of(unblockUserFailure(error)))
        )
      )
    )
  );

  unblockUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unblockUserSuccess),
        tap(({ user, dialogRefId }) => {
          this.closeDialog(dialogRefId);
          this.store.dispatch(loadUser({ id: user.id }));
        })
      ),
    { dispatch: false }
  );

  blockUserLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(blockUserLogin),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.userService.blockUserLogin(action.id).pipe(
          map(user => blockUserLoginSuccess(user, action.dialogRefId)),
          catchError(error => of(blockUserLoginFailure(error)))
        )
      )
    )
  );

  blockUserLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(blockUserLoginSuccess),
        tap(({ user, dialogRefId }) => {
          this.closeDialog(dialogRefId);
          this.store.dispatch(loadUser({ id: user.id }));
        })
      ),
    { dispatch: false }
  );

  unblockUserLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unblockUserLogin),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.userService.unblockUserLogin(action.id).pipe(
          map(user => unblockUserLoginSuccess(user, action.dialogRefId)),
          catchError(error => of(unblockUserLoginFailure(error)))
        )
      )
    )
  );

  unblockUserLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unblockUserLoginSuccess),
        tap(({ user, dialogRefId }) => {
          this.closeDialog(dialogRefId);
          this.store.dispatch(loadUser({ id: user.id }));
        })
      ),
    { dispatch: false }
  );

  enrollUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollUser),
      exhaustMap(action =>
        this.userService.enrollUser(action.enrollmentRequest).pipe(
          map(user => enrollUserSuccess({ id: user.id })),
          catchError(error => of(enrollUserFailure({ error })))
        )
      )
    )
  );

  // this.router.url will be /{path}/enroll
  // redirects to user details page
  enrollUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollUserSuccess),
      map(({ id }) => {
        this.snackBar.open('Success!', 'Dismiss', { panelClass: 'mat-snack-bar-success' });
        const path = this.router.url.split('/')[1] === 'agents' ? 'customers' : this.router.url.split('/')[1];
        return routerNavigate({ path: `/${path}/${id}/details` });
      })
    )
  );

  enrollAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollAdmin),
      exhaustMap(action =>
        this.userService.enrollAdmin(action.enrollAdminRequest).pipe(
          map(({ id }) => enrollAdminSuccess({ id })),
          catchError(error => of(enrollAdminFailure({ error })))
        )
      )
    )
  );

  enrollAscendaAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollAscendaAdmin),
      exhaustMap(({ email }) =>
        this.userService.enrollAscendaAdmin(email).pipe(
          map(({ id }) => enrollAscendaAdminSuccess({ id })),
          catchError(error => of(enrollAscendaAdminFailure({ error })))
        )
      )
    )
  );

  enrollAdminSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollAdminSuccess, enrollAscendaAdminSuccess),
      map(({ id }) => {
        this.snackBar.open('Success!', 'Dismiss', { panelClass: 'mat-snack-bar-success' });
        return routerNavigate({ path: `/agents/${id}/details` });
      })
    )
  );

  enrollTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollTeamMember),
      exhaustMap(action =>
        this.userService.enrollAdmin(action.enrollTeamMemberRequest).pipe(
          map(() => enrollTeamMemberSuccess()),
          catchError(error => of(enrollTeamMemberFailure(error)))
        )
      )
    )
  );

  enrollTeamMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollTeamMemberSuccess),
      map(() => routerForceNavigate({ path: '/team-members' }))
    )
  );

  unenrollUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unenrollUser),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(({ id, dialogRefId }) =>
        this.userService.unenrollUser(id).pipe(
          map(result => unenrollUserSuccess({ result, dialogRefId })),
          catchError(error => of(unenrollUserFailure({ error })))
        )
      )
    )
  );

  unenrollUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unenrollUserSuccess),
      exhaustMap(({ result, dialogRefId }) => {
        this.closeDialog(dialogRefId);

        const actions: Action[] = [];

        if (result.deletedIdentityCount > 0 && this.scopes.hasAny(SCOPES_OR.viewIdentities)) {
          actions.push(loadIdentities({ userId: result.user.id }));
        }

        if (result.deletedMfaCount > 0 && this.scopes.hasAny(SCOPES_OR.viewMfaIdentities)) {
          actions.push(loadMfaIdentities({ userId: result.user.id }));
        }

        return merge(actions);
      })
    )
  );

  removeTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTeamMember),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.userService.removeTeamMember(action.id).pipe(
          map(() => removeTeamMemberSuccess(action.filter)),
          catchError(error => of(removeTeamMemberFailure(error)))
        )
      )
    )
  );

  removeTeamMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTeamMemberSuccess),
      exhaustMap(({ filter }) => merge([loadUsers({ filter }), loadUsersCount({ filter })]))
    )
  );

  updateUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUsername),
      exhaustMap(action =>
        this.userService.updateUsername(action.updateUsernameRequestParams).pipe(
          map(updateUsernameResponseParams =>
            updateUsernameSuccess({
              updateUsernameResponseParams,
              dialogRefId: action.dialogRefId
            })
          ),
          catchError(error => of(updateUsernameFailure({ error })))
        )
      )
    )
  );

  updateUsernameSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUsernameSuccess),
        tap(({ updateUsernameResponseParams, dialogRefId }) => {
          this.snackBar.open(
            `Success! New email needs to be verified in ${updateUsernameResponseParams.validity} seconds`,
            'Dismiss',
            { panelClass: 'mat-snack-bar-success' }
          );
          this.matDialog.getDialogById(dialogRefId)?.close(true);
        })
      ),
    { dispatch: false }
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutUser),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.userService.logoutUser(action.id).pipe(
          map(() => logoutUserSuccess(action.dialogRefId)),
          catchError(error => of(logoutUserFailure(error)))
        )
      )
    )
  );

  logoutUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutUserSuccess),
        tap(({ dialogRefId }) => this.closeDialog(dialogRefId))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userService: UsersService,
    private store: Store,
    private snackBar: MatSnackBar,
    private router: Router,
    private scopes: Scopes,
    private matDialog: MatDialog
  ) {}

  private closeDialog(dialogRefId: string): void {
    this.matDialog.getDialogById(dialogRefId)?.close();
  }
}
