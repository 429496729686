import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { WebhookEventsService } from '../../services/webhook-events.service';
import {
  loadWebhookEvent,
  loadWebhookEventFailure,
  loadWebhookEvents,
  loadWebhookEventsFailure,
  loadWebhookEventsSuccess,
  loadWebhookEventSuccess,
  setWebhookEventsFilter
} from '../actions/webhook-events.actions';

@Injectable()
export class WebhookEventsEffects {
  loadWebhookEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWebhookEvents),
      switchMap(({ filter }) =>
        this.webhookEventsService.getWebhookEvents(filter).pipe(
          map(({ data, meta }) => {
            return loadWebhookEventsSuccess({
              webhookEvents: data,
              count: meta.count
            });
          }),
          catchError(error => of(loadWebhookEventsFailure({ error })))
        )
      )
    )
  );

  loadWebhookEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWebhookEvent),
      exhaustMap(({ id }) =>
        this.webhookEventsService.getWebhookEvent(id).pipe(
          map(webhookEvent => loadWebhookEventSuccess({ webhookEvent })),
          catchError(error => of(loadWebhookEventFailure({ error })))
        )
      )
    )
  );

  setWebhookEventsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setWebhookEventsFilter),
      map(filter => loadWebhookEvents(filter))
    )
  );

  constructor(
    private actions$: Actions,
    private webhookEventsService: WebhookEventsService
  ) {}
}
