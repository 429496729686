import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { LoyaltyProgramsService } from '../../../loyalty-programs/services/loyalty-programs.service';
import { fetchLoyaltyProgram } from '../../../loyalty-programs/store/actions/loyalty-programs.actions';
import { loyaltyProgramsQuery } from '../../../loyalty-programs/store/selectors/loyalty-programs.selectors';
import { LoyaltyProgram, LoyaltyProgramState } from '../../../loyalty-programs/types/loyalty-programs.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class LoyaltyProgramTagDefs {
  readonly tagDefs: TagDefs<LoyaltyProgram> = {
    loyalty_program_id: {
      fetchData: (id: string) =>
        this.scopes.hasAny(SCOPES_OR.showLoyaltyPrograms)
          ? combineLatest([
              this.loyaltyProgramStore.select(loyaltyProgramsQuery.getLoyaltyProgramById(id)),
              this.loyaltyProgramStore.select(loyaltyProgramsQuery.getFetchList)
            ]).pipe(
              first(),
              map(([loyaltyProgram, fetchList]) => {
                if (!loyaltyProgram && !fetchList.includes(id)) {
                  this.loyaltyProgramStore.dispatch(fetchLoyaltyProgram({ id }));
                }
              })
            )
          : of(null),
      select: id => this.loyaltyProgramStore.pipe(select(loyaltyProgramsQuery.getLoyaltyProgramById(id))),
      fetch: id =>
        this.scopes.hasAny(SCOPES_OR.showLoyaltyPrograms)
          ? this.loyaltyProgramStore.pipe(
              select(loyaltyProgramsQuery.getLoyaltyProgramById(id)),
              first(),
              exhaustMap(loyaltyProgram =>
                loyaltyProgram ? of(loyaltyProgram) : this.loyaltyProgramService.getLoyaltyProgram(id)
              )
            )
          : of(null),
      getDisplayPrefix: () => 'Loyalty Program',
      getDisplayValue: (loyaltyProgram: LoyaltyProgram) => loyaltyProgram.name || loyaltyProgram.id,
      getRouteLink: (loyaltyProgram: LoyaltyProgram) => `/loyalty-programs/${loyaltyProgram.id}`
    }
  };

  constructor(
    private loyaltyProgramStore: Store<LoyaltyProgramState>,
    private loyaltyProgramService: LoyaltyProgramsService,
    private scopes: Scopes
  ) {}
}
