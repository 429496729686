import { Change, ChangeOperation } from '@core/types';
import { ObjectUtils } from '@utils';

export const ChangesUtils = {
  isUnchanged(original: object, updated: object): boolean {
    return this.getChanges(original, updated).length === 0;
  },

  getChanges(original: object, updated: object): Change[] {
    const left = this.flattenObject(original);
    const right = this.flattenObject(updated);
    const diff = this.getDiff(left, right, []);

    return diff.filter(change => change.operation !== 'UNCHANGED');
  },

  getObjectSubsetChanges(originalObject: object, updatedObject: object): Change[] {
    const subset = ObjectUtils.getObjectSubset(originalObject, Object.keys(updatedObject));
    return this.getChanges(subset, updatedObject);
  },

  flattenObject(obj: object): object {
    const result = {};

    for (const key of Object.keys(obj)) {
      // If the key's value is an Object and not an Array
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        if (obj[key] === null || Object.keys(obj[key]).length === 0) {
          result[key] = obj[key];
        } else {
          const flattenedObject = this.flattenObject(obj[key]);
          for (const nestedKey of Object.keys(flattenedObject)) {
            result[`${key}.${nestedKey}`] = flattenedObject[nestedKey];
          }
        }
        continue;
      }

      // If the key's value is an Array
      if (Array.isArray(obj[key])) {
        if (obj[key].length === 0) {
          result[key] = obj[key];
        } else {
          obj[key].forEach((item, index) => {
            if (typeof item === 'object' && !Array.isArray(item)) {
              const flattenedObject = this.flattenObject(item);
              for (const nestedKey of Object.keys(flattenedObject)) {
                result[`${key}[${index}].${nestedKey}`] = flattenedObject[nestedKey];
              }
              return;
            }

            result[`${key}[${index}]`] = item;
          });
        }
        continue;
      }

      result[key] = obj[key];
    }

    return result;
  },

  getDiff(left: object, right: object, diff: Change[]): Change[] {
    for (const key of new Set([...Object.keys(left), ...Object.keys(right)])) {
      const keyInLeft = Object.keys(left).includes(key);
      const keyInRight = Object.keys(right).includes(key);

      // avoid logging parent element as 'REMOVED' when child elements are 'ADDED', only works for flattened object
      const hasFalseParentRemoval =
        keyInLeft && !keyInRight && Object.keys(right).find(rightKey => rightKey.startsWith(key));

      if (!hasFalseParentRemoval) {
        const diffOperation = this.getDiffOperation(left[key], right[key], keyInLeft, keyInRight);
        diff = this.addDiff(key, diffOperation, left, right, diff);
      }
    }

    return diff;
  },

  // Get change operation of ADDED, UPDATED, REMOVED, or UNCHANGED.
  getDiffOperation(was: any, is: any, keyInLeft: boolean, keyInRight: boolean): ChangeOperation {
    if (!keyInLeft && keyInRight) {
      return 'ADDED';
    }

    if (keyInLeft && !keyInRight) {
      return 'REMOVED';
    }

    if (JSON.stringify(was) === JSON.stringify(is)) {
      return 'UNCHANGED';
    }

    return 'UPDATED';
  },

  // Add the key's diff to the diff object
  addDiff(key: string, diffOperation: ChangeOperation, left: object, right: object, diff: Change[]): Change[] {
    diff.push({
      path: key,
      operation: diffOperation,
      was: left[key],
      is: right[key]
    });

    return diff;
  }
};
