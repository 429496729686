import { createAction, props } from '@ngrx/store';

import { AccessPolicy, ErrorState } from '@core/types';

export const loadAccessPolicies = createAction('[Access Policies] Load Access Policies');
export const loadAccessPoliciesSuccess = createAction(
  '[Access Policies] Load Access Policies Success',
  props<{ accessPolicies: AccessPolicy[] }>()
);
export const loadAccessPoliciesFailure = createAction(
  '[Access Policies] Load Access Policies Failure',
  props<ErrorState>()
);

export const loadAccessPolicy = createAction('[Access Policies] Load Access Policy', props<{ id: string }>());
export const loadAccessPolicySuccess = createAction(
  '[Access Policies] Load Access Policy Success',
  props<{ accessPolicy: AccessPolicy }>()
);
export const loadAccessPolicyFailure = createAction(
  '[Access Policies] Load Access Policy Failure',
  props<ErrorState>()
);

export const createAccessPolicy = createAction(
  '[Access Policies] Create Access Policy',
  props<{ accessPolicy: AccessPolicy }>()
);
export const createAccessPolicySuccess = createAction(
  '[Access Policies] Create Access Policy Success',
  props<{ accessPolicy: AccessPolicy }>()
);
export const createAccessPolicyFailure = createAction(
  '[Access Policies] Create Access Policy Failure',
  props<ErrorState>()
);

export const updateAccessPolicy = createAction(
  '[Access Policies] Update Access Policy',
  props<{ accessPolicy: AccessPolicy }>()
);
export const updateAccessPolicySuccess = createAction(
  '[Access Policies] Update Access Policy Success',
  props<{ accessPolicy: AccessPolicy }>()
);
export const updateAccessPolicyFailure = createAction(
  '[Access Policies] Update Access Policy Failure',
  props<ErrorState>()
);

export const deleteAccessPolicy = createAction('[Access Policies] Delete Access Policy', props<{ id: string }>());
export const deleteAccessPolicySuccess = createAction('[Access Policies] Delete Access Policy Success');
export const deleteAccessPolicyFailure = createAction(
  '[Access Policies] Delete Access Policy Failure',
  props<ErrorState>()
);
