import { Injectable } from '@angular/core';

import { Tag } from '@shared/types';

import { AuthenticationProvider } from '../../../authentication-providers/types/authentication-providers.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class ProviderTagDefs {
  readonly tagDefs: TagDefs<AuthenticationProvider> = {
    provider_id: {
      getDisplayPrefix: () => 'Auth Provider',
      getDisplayValue: (tag: Tag) => tag.id
    }
  };
}
