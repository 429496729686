var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import deepmerge from 'deepmerge';
import { defaultOptions } from './default-options';
import { IdleCheckNotStartedError } from './errors';
import { RefreshTokenRotationHandler } from './refresh_token_rotation/refresh-token-rotation-handler';
import { RefreshTokenRotationInterval } from './refresh_token_rotation/refresh-token-rotation-interval';
import { RefreshToken } from './silent_auth/refresh_token';
import { SessionCheck } from './silent_auth/session_check';
import { SessionCheckInterval } from './silent_auth/session_check_interval';
import { TokenRefreshInterval } from './silent_auth/token_refresh_interval';
import { TransactionManager } from './transaction_manager/transaction_manager';
import { UserInteractionsHandler } from './user_interactions/user_interactions_handler';
import { uuidv4 } from './util';
import { LocalStorageQueue } from './utils/local-storage-queue';
import { UrlParamsUtils } from './utils/url-params-utils';
import { UserAgentUtils } from './utils/user-agent-utils';
import { IOSWebBridge } from './web_bridge/ios_web_bridge';
export { HermesError } from './errors';
var Hermes = /** @class */ (function () {
    function Hermes(hermesOptions) {
        this.hermesOptions = hermesOptions;
        // map external functions to .<const_name>
        this.ua = UserAgentUtils;
        this.iOSWebBridge = IOSWebBridge;
        this.updateOptions(this.hermesOptions);
    }
    Hermes.prototype.updateOptions = function (hermesOptions) {
        var arrayMerge = function (target, source) { return source; };
        this.options = deepmerge(defaultOptions, hermesOptions, { arrayMerge: arrayMerge });
        this.transactionManager = new TransactionManager({ prefix: this.options.storagePrefix });
        if (this.isRtrMode) {
            this.refreshTokenRotationHandler = new RefreshTokenRotationHandler(this.transactionManager, this.options);
        }
    };
    Hermes.prototype.fetchToken = function (state) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new RefreshToken(this.options).call(state)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Hermes.prototype.startTokenRefresh = function () {
        this.tokenRefreshInterval = new TokenRefreshInterval(this.options);
        this.tokenRefreshInterval.start();
        return this.tokenRefreshInterval.getGenerator();
    };
    Hermes.prototype.startRefreshTokenRotation = function () {
        this.tokenRefreshInterval = new RefreshTokenRotationInterval(this.refreshTokenRotationHandler);
        this.tokenRefreshInterval.start();
        return this.tokenRefreshInterval.getGenerator();
    };
    Hermes.prototype.stopTokenRefresh = function () {
        if (this.tokenRefreshInterval) {
            this.tokenRefreshInterval.stop();
        }
    };
    Hermes.prototype.sessionCheck = function (state) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new SessionCheck(this.options).call(state)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Hermes.prototype.startSessionCheck = function () {
        this.sessionCheckInterval = new SessionCheckInterval(this.options);
        this.sessionCheckInterval.start();
        return this.sessionCheckInterval.getGenerator();
    };
    Hermes.prototype.stopSessionCheck = function () {
        if (this.sessionCheckInterval) {
            this.sessionCheckInterval.stop();
        }
    };
    Hermes.prototype.saveState = function (state, transaction) {
        this.transactionManager.create(state, transaction);
    };
    Hermes.prototype.handleRedirect = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var transaction;
            return __generator(this, function (_c) {
                transaction = this.getTransaction();
                if (transaction) {
                    this.transactionManager.removeAllTransactions();
                    // if code verifier and challenge exist in state then it will be for RTR redirect
                    if (((_a = transaction.code) === null || _a === void 0 ? void 0 : _a.verifier) && ((_b = transaction.code) === null || _b === void 0 ? void 0 : _b.challenge)) {
                        return [2 /*return*/, this.refreshTokenRotationHandler.handleRtrRedirect(transaction)];
                    }
                    else {
                        return [2 /*return*/, transaction];
                    }
                }
                return [2 /*return*/, null];
            });
        });
    };
    Hermes.prototype.startIdleCheck = function () {
        this.userInteractionsHandler = new UserInteractionsHandler(this.options);
        this.userInteractionsHandler.init();
    };
    Hermes.prototype.onIdleDialogClosed = function () {
        if (!this.userInteractionsHandler) {
            throw new IdleCheckNotStartedError();
        }
        this.userInteractionsHandler.onDialogClosed();
    };
    Hermes.prototype.getIdleDialogRemainingSeconds = function () {
        if (!this.userInteractionsHandler) {
            throw new IdleCheckNotStartedError();
        }
        return this.userInteractionsHandler.getDialogRemainingSeconds();
    };
    Hermes.prototype.login = function (connection, transaction) {
        if (transaction === void 0) { transaction = { nonce: uuidv4() }; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.refreshTokenRotationHandler.login(connection, transaction)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Hermes.prototype.refreshTokenExchange = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.refreshTokenRotationHandler.refreshTokenExchange()];
            });
        });
    };
    Object.defineProperty(Hermes.prototype, "isRtrMode", {
        get: function () {
            return this.options.mode === 'rtr';
        },
        enumerable: false,
        configurable: true
    });
    Hermes.prototype.removeRefreshToken = function () {
        LocalStorageQueue.clear('refresh_token');
    };
    Hermes.prototype.getTransaction = function () {
        var state = UrlParamsUtils.getUrlParam('state');
        return state ? this.transactionManager.get(state) : null;
    };
    return Hermes;
}());
export { Hermes };
