import { AbstractControl, FormGroup } from '@angular/forms';

import { AttributesSettings, RequestValidationTypes } from '@core/types';

import { Formatters } from './formatters';

const requestValidationSettingKeys = ['includes', 'eq', 'gte', 'lte'];
const isValidSettings = (settings: AttributesSettings | boolean): boolean =>
  requestValidationSettingKeys.includes(Object.keys(settings)[0]);

// the format of setting should be same as the format of the form
// the setting is should be same as "attributes" from request policy
export const handleRequestValidation = (
  formControl: AbstractControl,
  attributeSettings: AttributesSettings,
  type: RequestValidationTypes
): void => {
  if (!type) {
    return;
  }

  switch (type) {
    case RequestValidationTypes.Reject:
      formControl.disable();
      break;
    case RequestValidationTypes.Allow:
      formControl.enable();
      break;
  }
  traverseFormFields(formControl, attributeSettings);
};

const traverseFormFields = (formControl: AbstractControl, settings: AttributesSettings | boolean): void => {
  if (settings === undefined) {
    return;
  }

  if (typeof settings === 'boolean') {
    // update form group or control immediately and no need to traverse nested form group
    return settings ? formControl.enable() : formControl.disable();
  }

  const subControls = (formControl as FormGroup).controls;

  if (subControls) {
    if (Array.isArray(subControls)) {
      subControls.forEach(control => traverseFormFields(control, settings));
    } else {
      // traverse nested form group
      Object.entries(subControls).forEach(([key, control]) => {
        // As the setting key will be in camelCase, fallback to camelCase formatted form key when setting is not found
        const nestedSettings = (settings[key] ?? settings[Formatters.fromSnakeToCamelCase(key)]) as AttributesSettings;
        traverseFormFields(control, nestedSettings);
      });
    }
  } else {
    // update form control
    return isValidSettings(settings) ? formControl.enable() : formControl.disable();
  }
};
