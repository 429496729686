import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { OfferSourceResult, OfferSourcesFilter } from '../../types';

export const loadOfferSources = createAction('[Offers] Load Offer Sources', props<{ filter: OfferSourcesFilter }>());
export const loadOfferSourcesSuccess = createAction(
  '[Offers] Load Offer Sources Success',
  props<{ result: OfferSourceResult }>()
);
export const loadOfferSourcesFailure = createAction('[Offers] Load Offer Sources Failure', props<ErrorState>());

export const uploadOfferSources = createAction(
  '[Offers] Upload Offer Sources',
  props<{ file: FormData; dialogRefId: string }>()
);
export const uploadOfferSourcesSuccess = createAction(
  '[Offers] Upload Offer Sources Success',
  props<{ dialogRefId: string }>()
);
export const uploadOfferSourcesFailure = createAction('[Offers] Upload Offer Sources Failure', props<ErrorState>());
