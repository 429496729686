import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FullPageOverlayComponent } from '@shared/components/full-page-overlay/full-page-overlay.component';

import { RecommendedCampaign, RecommendedSegment } from '../../../recommendations/types';
import { SegmentCreationService } from '../../../segments/services/segment-creation.service';
import { Segment } from '../../../segments/types';

interface DialogData {
  recommendation: RecommendedSegment | RecommendedCampaign;
  currentPage: string;
}

@Component({
  selector: 'admin-recommendation-review',
  templateUrl: './recommendation-review.component.html'
})
export class RecommendationReviewComponent implements OnInit {
  private readonly segmentCreationService = inject(SegmentCreationService);

  segmentAttributeRenderState = this.segmentCreationService.segmentAttributesState;

  constructor(
    public dialogRef: MatDialogRef<FullPageOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  get rules(): Segment['rules'] {
    return this.data.recommendation.type === 'segment'
      ? {
          connector: 'and',
          conditions: this.data.recommendation.defaultAttributes.rules.conditions
        }
      : null;
  }

  get segmentBasicInfo(): Pick<Segment, 'name' | 'description' | 'updateFrequency'> {
    if (this.data.recommendation.type === 'campaign') {
      return null;
    }

    const { name, description, defaultAttributes } = this.data.recommendation;

    return {
      name,
      description,
      updateFrequency: defaultAttributes.updateFrequency
    };
  }

  ngOnInit(): void {
    // We need to load the segment preview to show the member count
    if (this.data.recommendation.type === 'segment') {
      this.segmentCreationService.loadSegmentPreview(this.rules?.conditions ?? []);
    }
  }
}
