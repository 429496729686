import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'admin-clear-form-button',
  templateUrl: './clear-form-button.component.html',
  styleUrls: ['./clear-form-button.component.scss']
})
export class ClearFormButtonComponent {
  @Input() form: UntypedFormGroup;
}
