import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  categorisedLoyaltyProgramAdapter,
  categorisedLoyaltyProgramInitialState,
  CategorisedLoyaltyProgramState
} from '../../types';
import {
  loadCategorisedLoyaltyProgram,
  loadCategorisedLoyaltyProgramFailure,
  loadCategorisedLoyaltyPrograms,
  loadCategorisedLoyaltyProgramsFailure,
  loadCategorisedLoyaltyProgramsSuccess,
  loadCategorisedLoyaltyProgramSuccess,
  synchroniseLoyaltyPrograms,
  synchroniseLoyaltyProgramsFailure,
  synchroniseLoyaltyProgramsSuccess,
  updateCategorisedLoyaltyProgram,
  updateCategorisedLoyaltyProgramFailure,
  updateCategorisedLoyaltyProgramsPriorities,
  updateCategorisedLoyaltyProgramsPrioritiesFailure,
  updateCategorisedLoyaltyProgramsPrioritiesSuccess,
  updateCategorisedLoyaltyProgramSuccess
} from '../actions';

export const categorisedLoyaltyProgramTriggers: EntityTriggers = {
  single: {
    loading: [loadCategorisedLoyaltyProgram.type, updateCategorisedLoyaltyProgram.type],
    resting: [loadCategorisedLoyaltyProgramSuccess.type, updateCategorisedLoyaltyProgramSuccess.type],
    erroring: [loadCategorisedLoyaltyProgramFailure.type, updateCategorisedLoyaltyProgramFailure.type]
  },
  batch: {
    loading: [
      loadCategorisedLoyaltyPrograms.type,
      updateCategorisedLoyaltyProgramsPriorities.type,
      synchroniseLoyaltyPrograms.type
    ],
    resting: [
      loadCategorisedLoyaltyProgramsSuccess.type,
      updateCategorisedLoyaltyProgramsPrioritiesSuccess.type,
      synchroniseLoyaltyProgramsSuccess.type
    ],
    erroring: [
      loadCategorisedLoyaltyProgramsFailure.type,
      updateCategorisedLoyaltyProgramsPrioritiesFailure.type,
      synchroniseLoyaltyProgramsFailure.type
    ]
  }
};

export function categorisedLoyaltyProgramsReducer(
  state: CategorisedLoyaltyProgramState = categorisedLoyaltyProgramInitialState,
  action: Action
): CategorisedLoyaltyProgramState {
  return callStateReducer(baseReducer, categorisedLoyaltyProgramTriggers)(state, action);
}

const baseReducer = createReducer(
  categorisedLoyaltyProgramInitialState,
  on(loadCategorisedLoyaltyProgramsSuccess, (state, { categorisedLoyaltyProgramsResult }) => ({
    ...categorisedLoyaltyProgramAdapter.setAll(categorisedLoyaltyProgramsResult.data, state),
    count: categorisedLoyaltyProgramsResult.meta.count
  })),
  on(loadCategorisedLoyaltyProgramSuccess, (state, { categorisedLoyaltyProgramResult }) =>
    categorisedLoyaltyProgramAdapter.upsertOne(categorisedLoyaltyProgramResult, state)
  )
);
