import { trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { expansionAnimation } from '@shared/animations/expansion-animations';
import { ComponentState, getRenderState$ } from '@utils';

import { campaignsQuery } from '../../store/selectors/campaigns.selectors';
import { Campaign, CampaignState } from '../../types';

@Component({
  selector: 'admin-user-campaigns',
  templateUrl: './user-campaigns.component.html',
  styleUrls: ['./user-campaigns.component.scss'],
  animations: [
    trigger('detail_expand', expansionAnimation),
    trigger('eligibility_trackings_detail_expand', expansionAnimation)
  ]
})
export class UserCampaignsComponent implements OnInit {
  campaigns$: Observable<Campaign[]>;
  loading$: Observable<boolean>;
  renderState$: Observable<ComponentState>;

  displayedColumns = ['id', 'title', 'partnerReferenceCode', 'brand', 'displayable'];
  eligibilityTrackingsDisplayedColumns = [
    'status',
    'qualifiedDate',
    'activationDate',
    'trackingType',
    'trackingNumber'
  ];
  fulfillmentsDisplayedColumns = [
    'userId',
    'points',
    'fulfillmentDate',
    'pointsBankTransactionId',
    'expiryRule',
    'errors'
  ];

  expandedColumns = [''];
  expandedEligibilityColumns = [''];

  constructor(
    private store: Store<CampaignState>,
    @Inject('pointsActivityDecimals') public pointsActivityDecimals: number
  ) {}

  ngOnInit(): void {
    this.campaigns$ = this.store.select(campaignsQuery.getCampaignsList);
    const loading$ = this.store.select(campaignsQuery.isBatchLoading);
    const error$ = this.store.select(campaignsQuery.getBatchError);
    this.renderState$ = getRenderState$([loading$, error$]);
  }
}
