import { createSelector, MemoizedSelector } from '@ngrx/store';

import { FetchState } from '@core/types';

export const initialFetchState: FetchState = {
  fetchList: []
};

export function getFetchStateSelector<T extends FetchState>(
  featureSelector: MemoizedSelector<object, T>
): MemoizedSelector<T, string[]> {
  return createSelector(featureSelector, (featureState: T) => featureState.fetchList);
}
