import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, keyAdapter, KeyState } from '../../types/keys.type';
import {
  createKey,
  createKeyFailure,
  createKeySuccess,
  loadKey,
  loadKeyFailure,
  loadKeys,
  loadKeysFailure,
  loadKeysSuccess,
  loadKeySuccess,
  loadPrivateKey,
  loadPrivateKeyFailure,
  loadPrivateKeySuccess
} from '../actions/keys.actions';

export const keyTriggers: EntityTriggers = {
  single: {
    loading: [createKey.type, loadKey.type, loadPrivateKey.type],
    resting: [createKeySuccess.type, loadKeySuccess.type, loadPrivateKeySuccess.type],
    erroring: [createKeyFailure.type, loadKeyFailure.type, loadPrivateKeyFailure.type]
  },
  batch: {
    loading: [loadKeys.type],
    resting: [loadKeysSuccess.type],
    erroring: [loadKeysFailure.type]
  }
};

export function keysReducer(state: KeyState = initialState, action: Action): KeyState {
  return callStateReducer(baseReducer, keyTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadKeySuccess, (state, { key }) => keyAdapter.upsertOne(key, state)),
  on(loadKeys, (state, { filter, isTopLevelView }) => ({ ...state, filter, isTopLevelView })),
  on(loadKeysSuccess, (state, { keys }) => keyAdapter.setAll(keys, state)),
  on(loadPrivateKeySuccess, (state, { privateKey, keyId }) =>
    keyAdapter.upsertOne({ ...state.entities[keyId], id: keyId, privateKey }, state)
  )
);
