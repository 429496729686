import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { Tenant } from '../../../tenants/types/tenants.type';
import { TagDef, TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class TenantTagDefs {
  tenantTagDefs: TagDef<Tenant> = {
    getDisplayPrefix: () => 'Tenant',
    getDisplayValue: (tag: Tag) => tag.id,
    getRouteLink: (tags: Tag[]) => {
      if (!this.scopes.hasAny(SCOPES_OR.showTenants)) {
        return null;
      }

      const tenantTag = tags.find(tag => tag.type === 'tenant_id');
      return tenantTag ? `/tenants/${tenantTag.id}` : null;
    }
  };

  tagDefs: TagDefs<Tenant> = {
    tenant_id: { ...this.tenantTagDefs }
  };

  constructor(private scopes: Scopes) {}
}
