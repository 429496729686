import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { eventTypeAdapter, EventTypeState, initialState } from '../../types';
import {
  createEventType,
  createEventTypeFailure,
  createEventTypeSuccess,
  deleteEventType,
  deleteEventTypeFailure,
  deleteEventTypeSuccess,
  loadEventType,
  loadEventTypeFailure,
  loadEventTypes,
  loadEventTypesFailure,
  loadEventTypesSuccess,
  loadEventTypeSuccess,
  setEventTypesFilter,
  updateEventType,
  updateEventTypeFailure,
  updateEventTypeSuccess
} from '../actions/event-types.actions';

export const eventTypeTriggers: EntityTriggers = {
  single: {
    loading: [loadEventType.type, createEventType.type, updateEventType.type, deleteEventType.type],
    resting: [
      loadEventTypeSuccess.type,
      createEventTypeSuccess.type,
      updateEventTypeSuccess.type,
      deleteEventTypeSuccess.type
    ],
    erroring: [
      loadEventTypeFailure.type,
      createEventTypeFailure.type,
      updateEventTypeFailure.type,
      deleteEventTypeFailure.type
    ]
  },
  batch: {
    loading: [loadEventTypes.type],
    resting: [loadEventTypesSuccess.type],
    erroring: [loadEventTypesFailure.type]
  }
};

export function eventTypesReducer(state: EventTypeState = initialState, action: Action): EventTypeState {
  return callStateReducer(baseReducer, eventTypeTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(setEventTypesFilter, (state, { filter }) => ({ ...state, filter })),
  on(loadEventTypeSuccess, (state, { eventType }) => eventTypeAdapter.upsertOne(eventType, state)),
  on(loadEventTypesSuccess, (state, { eventTypes }) => eventTypeAdapter.setAll(eventTypes, state)),
  on(createEventTypeSuccess, (state, { eventType }) => eventTypeAdapter.addOne(eventType, state)),
  on(updateEventTypeSuccess, (state, { eventType }) => eventTypeAdapter.setOne(eventType, state))
);
