import { Component, Input } from '@angular/core';

@Component({
  selector: 'admin-toggle-advanced-filters-button',
  templateUrl: './toggle-advanced-filters-button.component.html',
  styleUrls: ['./toggle-advanced-filters-button.component.scss']
})
export class ToggleAdvancedFiltersButtonComponent {
  @Input() showAdvancedFilters: boolean;
}
