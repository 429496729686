import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { TooltipsService } from '../../services/tooltips.service';
import {
  loadTooltips,
  loadTooltipsFailure,
  loadTooltipsSuccess,
  updateTooltips,
  updateTooltipsFailure,
  updateTooltipsSuccess
} from '../actions/tooltips.actions';

@Injectable()
export class TooltipsEffects {
  loadTooltips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTooltips),
      mergeMap(({ module }) =>
        this.tooltipsService.getTooltips(module).pipe(
          map(tooltips => loadTooltipsSuccess({ tooltips })),
          catchError(error => of(loadTooltipsFailure({ error })))
        )
      )
    )
  );

  updateTooltips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTooltips),
      exhaustMap(({ tooltips, dialogRefId }) =>
        this.tooltipsService.updateTooltips(tooltips).pipe(
          map(tooltipsResult => updateTooltipsSuccess({ tooltips: tooltipsResult, dialogRefId })),
          catchError(error => of(updateTooltipsFailure({ error })))
        )
      )
    )
  );

  updateTooltipsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTooltipsSuccess),
        tap(({ dialogRefId }) => this.matDialog.getDialogById(dialogRefId)?.close(true))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private matDialog: MatDialog,
    private tooltipsService: TooltipsService
  ) {}
}
