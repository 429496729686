import { map, tap, withLatestFrom } from 'rxjs/operators';

import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AttributeUtils } from '@core/services/attribute-utils/attribute-utils.service';
import { ScopesCheckService } from '@core/services/scopes/scopes-check.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { AttributeDef, BooleanPolicySettings, DecodedUser, SCOPES_OR } from '@core/types';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { AccessPolicyUtils, ASCENDA_AUTH_PROVIDER, Formatters, or, Params } from '@utils';

import { CreateIdentityDialogComponent } from '../../components/create-identity-dialog/create-identity-dialog.component';
import { deleteIdentity } from '../../store/actions/identities.actions';
import {
  blockUser,
  blockUserLogin,
  logoutUser,
  requestResetPassword,
  unblockUser,
  unblockUserLogin,
  unenrollUser
} from '../../store/actions/users.actions';
import { identitiesQuery } from '../../store/selectors/identities.selectors';
import { usersQuery } from '../../store/selectors/users.selectors';
import { Identity, IdentityState, isAdmin, MfaIdentity, Preferences, Product, User, UserState } from '../../types';
import {
  baseIdentityDefs,
  customDefs,
  detailDefs,
  loyaltyDataDefs,
  moreDetailsDefs,
  preferencesDefs,
  productDefs
} from '../../types/user-details-defs.type';

interface AccountActionsParams {
  actionType:
    | 'block'
    | 'unblock'
    | 'blockLogin'
    | 'unblockLogin'
    | 'resetPassword'
    | 'logout'
    | 'unenroll'
    | 'deleteIdentity';
  user?: User;
  identityReference?: string;
}

@Component({
  selector: 'admin-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  readonly identityDefs = Object.assign([], baseIdentityDefs);
  readonly mfaIdentityDefs: AttributeDef<MfaIdentity>[] = [
    ...baseIdentityDefs.slice(0, 3),
    { key: 'verified', label: 'Verified', formatter: Formatters.booleanFormatter },
    baseIdentityDefs[3]
  ];
  readonly SCOPES = SCOPES_OR;

  user$: Observable<User>;
  loading$: Observable<boolean>;
  isSameUserAndHasAscendaIdentity$: Observable<boolean>;
  hasRolesShowScope: boolean;
  currentUser$: Observable<DecodedUser>;
  userIdentity$: Observable<Identity[]>;
  isResetPasswordEnabled$: Observable<boolean>;

  isMoreDetailsPanelOpen = false;
  displayLoyaltyDataPanel: boolean;
  displayCustomPanel: boolean;

  hasBlockUnblockScopes: boolean;
  hasBlockUnblockLoginScopes: boolean;

  details: AttributeDef<User>[];
  detailDefs: AttributeDef<User>[] = [...detailDefs];
  moreDetails: AttributeDef<User>[];
  rootPreferences: AttributeDef<User>[];
  preferences: AttributeDef<Preferences>[];

  // added { key: 'buttons'; label: string } to fix type checking issue
  // where "column.key === 'buttons' will always return false" in html
  identityColumns: (AttributeDef<Identity> | { key: 'buttons'; label: string })[];
  identityColumnKeys = [];

  productColumns: AttributeDef<Product>[];
  productColumnKeys = [];

  private readonly blockUnblockUsersScopes = ['users:block', 'users:unblock'];
  private readonly blockUnblockLoginUsersScopes = ['users:block_login', 'users:unblock_login'];

  readonly accountStatusMenuScopes = [
    ...this.blockUnblockUsersScopes,
    ...this.blockUnblockLoginUsersScopes,
    ...this.SCOPES.userSession,
    ...this.SCOPES.resetPassword
  ];

  // relationOperator: 'all-loose-groups'
  readonly dividerScopes = [
    [...this.blockUnblockUsersScopes, ...this.blockUnblockLoginUsersScopes, ...this.SCOPES.rollbackUsers],
    this.SCOPES.resetPassword
  ];

  constructor(
    private store: Store<UserState>,
    private identityStore: Store<IdentityState>,
    private route: ActivatedRoute,
    private authStore: Store<AuthState>,
    private accessPolicies: AccessPolicies,
    private scopes: Scopes,
    private scopesCheckService: ScopesCheckService,
    private matDialog: MatDialog,
    public attributeUtils: AttributeUtils,
    @Inject('tenantId') private tenantId: string,
    @Inject('timezone') public timezone: string,
    @Inject('customerBankIdentityProvider') private customerBankIdentityProvider: string,
    @Inject('guardhouseApiUrl') public guardhouseApiUrl: string
  ) {}

  ngOnInit(): void {
    const userId = Params.find(this.route, 'userId');

    this.user$ = this.store.select(usersQuery.getUserById(userId));
    this.loading$ = or(
      this.store.select(usersQuery.isSingleLoading),
      this.identityStore.select(identitiesQuery.isSingleLoading)
    );

    this.hasRolesShowScope = this.scopes.hasAny(SCOPES_OR.showRoles);
    this.currentUser$ = this.authStore.select(authQuery.getUser);

    this.identityStore
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        select(identitiesQuery.isIdentitiesLoaded(userId)),
        tap(isLoaded => {
          this.userIdentity$ = isLoaded
            ? this.identityStore.select(identitiesQuery.getIdentitiesList)
            : this.store.select(usersQuery.getUserPropertyById<Identity[]>(userId, ['identities']));
        })
      )
      .subscribe();

    // createdAt and updatedAt data are only available in identity state
    if (this.scopes.hasAny(SCOPES_OR.viewIdentities)) {
      this.identityDefs.splice(
        3,
        0,
        { key: 'createdAt', label: 'Created at' },
        { key: 'updatedAt', label: 'Updated at' }
      );
    }

    if (this.scopes.hasAny(SCOPES_OR.deleteIdentities)) {
      this.identityDefs.unshift({ key: 'buttons', label: '' });
    }

    if (this.tenantId === 'AustraliaNewZealandBankBusinessRewards') {
      this.detailDefs.push(
        {
          key: 'loyalty_data',
          label: 'Company Name',
          formatter: Formatters.loyaltyDataFormatter,
          subKeys: ['company_name']
        },
        { key: 'loyalty_data', label: 'VIP', formatter: Formatters.loyaltyDataFormatter, subKeys: ['vip_flag'] }
      );
    } else if (this.tenantId === 'AustraliaNewZealandBankRewards') {
      this.detailDefs.push({
        key: 'loyalty_data',
        label: 'VIP',
        formatter: Formatters.loyaltyDataFormatter,
        subKeys: ['vip_flag']
      });
    }

    const settings = this.accessPolicies.getResponseSettings('users', 'show');
    const usersShowIdentitySettings = settings?.identities as BooleanPolicySettings;
    const identityIndexSettings = this.accessPolicies.getResponseSettings(
      'identities',
      'index'
    ) as BooleanPolicySettings;

    this.details = this.attributeUtils.filterAttributes(this.detailDefs, settings);
    this.moreDetails = this.attributeUtils.filterAttributes(moreDetailsDefs, settings);
    this.preferences = this.attributeUtils.filterAttributes(preferencesDefs, settings?.preferences);
    this.displayLoyaltyDataPanel = this.attributeUtils.filterAttributes(loyaltyDataDefs, settings).length > 0;
    this.displayCustomPanel = this.attributeUtils.filterAttributes(customDefs, settings).length > 0;

    [this.identityColumns, this.identityColumnKeys] = this.attributeUtils.filterColumns(
      this.identityDefs,
      AccessPolicyUtils.mergeBooleanPolicySettings(identityIndexSettings, usersShowIdentitySettings)
    );
    [this.productColumns, this.productColumnKeys] = this.attributeUtils.filterColumns(productDefs, settings?.products);

    this.isSameUserAndHasAscendaIdentity$ = this.user$.pipe(
      withLatestFrom(this.authStore.select(authQuery.getUser)),
      map(
        ([user, loggedInUser]) =>
          user.id === loggedInUser.sub &&
          user.identities?.map(identity => identity.providerId).includes(ASCENDA_AUTH_PROVIDER)
      )
    );

    this.isResetPasswordEnabled$ = this.user$.pipe(
      map(user => {
        const providerIds = user.identities?.map(identity => identity.providerId);
        return (
          providerIds?.includes(this.customerBankIdentityProvider) || (isAdmin(user) && providerIds?.includes('email'))
        );
      })
    );

    this.hasBlockUnblockScopes = this.scopes.hasScopes(
      [this.blockUnblockUsersScopes, ['manage_user_state']],
      'any-strict-groups'
    );
    this.hasBlockUnblockLoginScopes = this.scopes.hasScopes(
      [this.blockUnblockLoginUsersScopes, ['manage_user_state']],
      'any-strict-groups'
    );
  }

  resetAscendaLogin(): void {
    localStorage.removeItem('isAscendaLogin');
  }

  isAscendaLoginValueMissing(): boolean {
    return localStorage.getItem('isAscendaLogin') ? false : true;
  }

  openCreateIdentityDialog(user: User): void {
    this.matDialog.open(CreateIdentityDialogComponent, {
      data: { user }
    });
  }

  onAccountActions(params: AccountActionsParams): void {
    const { actionType, user, identityReference } = params;

    let dialogTitle: string;
    let confirmText: string;
    let confirmButtonText: string;
    let action: Action;

    switch (actionType) {
      case 'blockLogin': {
        dialogTitle = 'Block user';
        confirmText = 'User cannot log in once he or she is blocked. Are you sure you want to block user login?';
        confirmButtonText = 'Yes, block user login';
        action = blockUserLogin({ id: user.id });
        break;
      }
      case 'unblockLogin': {
        dialogTitle = 'Remove login block';
        confirmText = 'Are you sure you want to allow user to login?';
        confirmButtonText = 'Yes, remove login block';
        action = unblockUserLogin({ id: user.id });
        break;
      }
      case 'block': {
        dialogTitle = 'Block user';
        confirmText = 'Are you sure you want to block user?';
        confirmButtonText = 'Yes, block user';
        action = blockUser({ id: user.id });
        break;
      }
      case 'unblock': {
        dialogTitle = 'Unblock user';
        confirmText = 'Are you sure you want to unblock user?';
        confirmButtonText = 'Yes, unblock user';
        action = unblockUser({ id: user.id });
        break;
      }
      case 'resetPassword': {
        dialogTitle = 'Send reset password email';
        confirmText = `Are you sure you want to send the reset password email ${
          user.email ? 'to ' + user.email + '?' : '?'
        }`;
        confirmButtonText = 'Yes, send email';
        action = requestResetPassword({ userId: user.id });
        break;
      }
      case 'logout': {
        dialogTitle = 'Logout user';
        confirmText = 'Are you sure you want to logout user?';
        confirmButtonText = 'Yes, logout user';
        action = logoutUser({ id: user.id });
        break;
      }
      case 'unenroll': {
        dialogTitle = 'Unenroll user';
        confirmText = 'Are you sure you want to unenroll user?';
        confirmButtonText = 'Yes, unenroll user';
        action = unenrollUser({ id: user.id });
        break;
      }
      case 'deleteIdentity': {
        dialogTitle = 'Delete identity';
        confirmText = 'Are you sure you want to delete this identity?';
        confirmButtonText = 'Yes, delete identity';
        action = deleteIdentity({ reference: identityReference, user });
        break;
      }
      default: {
        return;
      }
    }

    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          dialogTitle,
          confirmText,
          confirmButtonText,
          styleClassName: 'content-centered-dialog'
        }
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(action);
        }
      });
  }

  showEdit(currentUser: DecodedUser, id: string): boolean {
    return (
      currentUser?.sub === id ||
      this.scopesCheckService.hasViewUpdatePageScopes(this.route.parent.snapshot.data.isCustomerView)
    );
  }
}
