import { initialCallState } from '@core/store/call-state';

import { CallState } from './call-state.type';

export interface SharedConfig {
  domain: string;
  countryCodes: string[];
  rcSubdomain: string;
  cashCurrency: string;
  locales: string[];
  gtmContainerId: string;
  tenantLoyaltyCurrency: string;
  tenantLoyaltyProgram: string;
  timeZone: string;
  version: number;
  customDomainStatus: 'successful' | 'processing' | 'failed';
}

export interface SharedConfigState extends CallState {
  sharedConfig: SharedConfig;
}

export const sharedConfigInitialState: SharedConfigState = {
  ...initialCallState,
  sharedConfig: null
};
