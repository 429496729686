import { createEntityAdapter } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Token } from '@core/types/generate-token.type';
import { Nullable } from '@shared/types';

export interface ApiSettingsToken {
  tokenId: string;
  token: string;
  tokenType: string;
  expiresAt: number;
  accessToken: string;
}

export interface ApiSettingsTokenRequest {
  // Attributes below use snake_case to match the API request
  // If not it will throw an error from the GH side
  client_id: string;
  client_secret: string;
  grant_type: string;
  audience: string;
}

export const apiSettingsTokenAdapter = createEntityAdapter<Token>();

export interface ApiSettingsTokenState extends CallState {
  apiSettingsToken: Nullable<Token>;
}

export const initialTokenState: ApiSettingsTokenState = {
  ...apiSettingsTokenAdapter.getInitialState<CallState>(initialCallState),
  apiSettingsToken: null
};
