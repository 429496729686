import { fromEvent, OperatorFunction } from 'rxjs';

import { Nullable } from '@shared/types';

export const DomUtils = {
  preventScrollToOtherPage(elementSelector: string, unsubscribeOperator: OperatorFunction<Event, Event>): void {
    const element = document.querySelector(elementSelector);

    if (element) {
      fromEvent(element, 'wheel')
        .pipe(unsubscribeOperator)
        .subscribe((event: WheelEvent) => {
          // prevent navigating to previous or next page on browser, but keep scrolling behaviour
          if (event.deltaX !== 0) {
            event.preventDefault();
            element.scrollLeft += event.deltaX;
          }
        });
    }
  },

  dragAndScroll(elementSelector: string, unsubscribeOperator: OperatorFunction<Event, Event>): void {
    const element = document.querySelector(elementSelector) as HTMLElement;
    let position: Nullable<number> = null;

    const handler = (event: MouseEvent): void => {
      element.scrollLeft += (position || 0) - event.clientX;
      position = event.clientX;
    };

    if (element) {
      fromEvent(element, 'mousedown')
        .pipe(unsubscribeOperator)
        .subscribe((event: WheelEvent) => {
          element.style.cursor = 'grabbing';
          position = event.clientX;
          element.addEventListener('mousemove', handler);
        });

      fromEvent(element, 'mouseup')
        .pipe(unsubscribeOperator)
        .subscribe(() => {
          element.style.cursor = 'grab';
          element.removeEventListener('mousemove', handler);
          position = null;
        });
    }
  }
};
