import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { FraudEvaluationsFilter } from './fraud-evaluations-filter.type';

export const fraudRuleEvaluationDisplayClass = {
  passed: 'status-active',
  failed: 'status-inactive',
  skipped: 'status-neutral'
};

export interface FraudRuleEvaluation {
  rule: string;
  status: string;
  riskValue?: number;
}

export interface FraudEvaluation {
  id: string;
  risk: string;
  results: FraudRuleEvaluation[];
  userId: string;
  sessionId: string;
  createdAt: string;
  updatedAt: string;
}

export const fraudEvaluationAdapter = createEntityAdapter<FraudEvaluation>();

export interface FraudEvaluationState extends EntityState<FraudEvaluation>, CallState {
  filter: FraudEvaluationsFilter;
  count: number;
}

export const initialState: FraudEvaluationState = {
  ...fraudEvaluationAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  count: 0
};
