import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'admin-input-range-slider',
  templateUrl: './input-range-slider.component.html',
  styleUrls: ['./input-range-slider.component.scss']
})
export class InputRangeSliderComponent implements AfterViewInit {
  @Input() highestFirst = true;
  @Input() label: string; // displayed as-is -> assumes correct casing e.g. Sentence case
  @Output() sliderPointerChange: EventEmitter<ChangeContext> = new EventEmitter();

  lowLimitLabel = this.highestFirst ? 'Highest' : 'Lowest';
  highLimitLabel = this.highestFirst ? 'Lowest' : 'Highest';

  lowPointer = 0;
  highPointer = 100;

  sliderOptions: Options = {
    floor: this.lowPointer,
    ceil: this.highPointer,
    hideLimitLabels: true,
    combineLabels: (minValue: string, maxValue: string): string =>
      minValue === maxValue ? minValue : minValue + ' - ' + maxValue
  };

  private limitTooltipClass = 'mat-tooltip';
  private pointerTooltipClasses = ['ngx-slider-model-value', 'ngx-slider-model-high', 'ngx-slider-combined'];

  private hideBoundTooltips: ReturnType<typeof setInterval>;

  ngAfterViewInit(): void {
    this.hideTooltips(); // because values start with NaN before load
  }

  onDataLoaded(data: number[]): void {
    this.setSlider(data);
    this.showTooltips();
  }

  filterDataByRange(changeContext: ChangeContext): void {
    this.sliderPointerChange.emit(changeContext);
  }

  setSlider(data: number[]): void {
    if (data.length > 0) {
      this.lowPointer = Math.min(...data);
      this.highPointer = Math.max(...data);
      this.sliderOptions = { ...this.sliderOptions, floor: this.lowPointer, ceil: this.highPointer };
    }
  }

  private hideTooltips(): void {
    this.pointerTooltipClasses.forEach(
      pointerTooltipClass => (this.getTooltip(pointerTooltipClass).style.visibility = 'hidden')
    );

    this.hideBoundTooltips = setInterval(() => {
      const pointerTooltip = this.getTooltip(this.limitTooltipClass);

      if (pointerTooltip && pointerTooltip.innerHTML !== 'NaN') {
        pointerTooltip.style.visibility = 'hidden';
      }
    });
  }

  private showTooltips(): void {
    const checkPointerTooltipsReady = setInterval(() => {
      this.pointerTooltipClasses.forEach(pointerTooltipClass => {
        const pointerTooltip = this.getTooltip(pointerTooltipClass);

        if (pointerTooltip.innerHTML !== 'NaN') {
          pointerTooltip.style.visibility = 'visible';
        }
      });

      clearInterval(checkPointerTooltipsReady);
    });

    const checkBoundTooltipsReady = setInterval(() => {
      const limitTooltip = this.getTooltip(this.limitTooltipClass);

      if (limitTooltip && !limitTooltip.innerHTML.includes('Infinity')) {
        limitTooltip.style.visibility = 'visible';

        clearInterval(checkBoundTooltipsReady);
        clearInterval(this.hideBoundTooltips);
      }
    });
  }

  private getTooltip(className: string): HTMLElement {
    return document.querySelectorAll(`.${className}`)[0] as HTMLElement;
  }
}
