import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, ObjectUtils } from '@utils';

import { Enrollment, EnrollmentsFilter } from '../types';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentsService {
  constructor(private missionControlService: MissionControlService) {}

  getEnrollments(filter: EnrollmentsFilter): Observable<Enrollment[]> {
    filter = ObjectUtils.sanitizeRequestObject<EnrollmentsFilter>(filter);

    const params = ObjectUtils.prepareQueryObject(filter);

    return this.missionControlService.get<Enrollment[]>(
      'enrollments',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }
}
