import { Injectable } from '@angular/core';

import { AttributeDef } from '@core/types';

@Injectable()
export class AttributeUtils {
  filterAttributes<T>(details: AttributeDef<T>[], settings: any): AttributeDef<T>[] {
    if (!settings) {
      return details;
    }

    return details.filter(detail => settings[detail.key]);
  }

  filterColumns<T>(columns: AttributeDef<T>[], settings: any): [AttributeDef<T>[], string[]] {
    const filteredColumns = this.filterAttributes(columns, settings);
    return [filteredColumns, filteredColumns.map(column => column.key as string)];
  }
}
