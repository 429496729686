import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { AlfredSuppliersService } from '../../services/alfred-suppliers.service';
import {
  loadAlfredSupplier,
  loadAlfredSupplierFailure,
  loadAlfredSupplierSuccess,
  loadAlfredSuppliers,
  loadAlfredSuppliersFailure,
  loadAlfredSuppliersSuccess,
  refreshAlfredSupplier,
  refreshAlfredSupplierFailure,
  refreshAlfredSupplierSuccess
} from '../actions/alfred-suppliers.actions';

@Injectable()
export class AlfredSuppliersEffects {
  loadAlfredSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAlfredSuppliers),
      exhaustMap(action =>
        this.alfredSuppliersService.getSuppliers(action.filter).pipe(
          map(result => loadAlfredSuppliersSuccess({ result })),
          catchError(error => of(loadAlfredSuppliersFailure({ error })))
        )
      )
    )
  );

  loadAlfredSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAlfredSupplier),
      exhaustMap(action =>
        this.alfredSuppliersService.getSupplier(action.supplierId).pipe(
          map(alfredSupplier => loadAlfredSupplierSuccess({ alfredSupplier })),
          catchError(error => of(loadAlfredSupplierFailure({ error })))
        )
      )
    )
  );

  refreshAlfredSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshAlfredSupplier),
      exhaustMap(action =>
        this.alfredSuppliersService.refreshSupplier(action.supplierId).pipe(
          map(_ => refreshAlfredSupplierSuccess()),
          catchError(error => of(refreshAlfredSupplierFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private alfredSuppliersService: AlfredSuppliersService
  ) {}
}
