import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { LoyaltyProgram, LoyaltyProgramsResult } from '../../../loyalty-programs/types';
import { CategorisedLoyaltyProgramsPriorities, CategorisedLoyaltyProgramType } from '../../types';

export const loadCategorisedLoyaltyPrograms = createAction(
  '[Rewards Offering] Load Categorised Loyalty Programs',
  props<{
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType;
  }>()
);
export const loadCategorisedLoyaltyProgramsSuccess = createAction(
  '[Rewards Offering] Load Categorised Loyalty Programs Success',
  props<{
    categorisedLoyaltyProgramsResult: LoyaltyProgramsResult;
  }>()
);
export const loadCategorisedLoyaltyProgramsFailure = createAction(
  '[Rewards Offering] Load Categorised Loyalty Programs Failure',
  props<ErrorState>()
);

export const loadCategorisedLoyaltyProgram = createAction(
  '[Rewards Offering] Load Categorised Loyalty Program',
  props<{
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType;
    id: string;
  }>()
);
export const loadCategorisedLoyaltyProgramSuccess = createAction(
  '[Rewards Offering] Load Categorised Loyalty Program Success',
  props<{
    categorisedLoyaltyProgramResult: LoyaltyProgram;
  }>()
);
export const loadCategorisedLoyaltyProgramFailure = createAction(
  '[Rewards Offering] Load Categorised Loyalty Program Failure',
  props<ErrorState>()
);

export const updateCategorisedLoyaltyProgramsPriorities = createAction(
  '[Rewards Offering] Update Categorised Loyalty Programs Priorities',
  props<{
    priorities: CategorisedLoyaltyProgramsPriorities;
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType;
  }>()
);
export const updateCategorisedLoyaltyProgramsPrioritiesSuccess = createAction(
  '[Rewards Offering] Update Categorised Loyalty Programs Priorities Success',
  (
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully'
    }
  ) => ({ categorisedLoyaltyProgramType, snackbarMessages })
);
export const updateCategorisedLoyaltyProgramsPrioritiesFailure = createAction(
  '[Rewards Offering] Update Categorised Loyalty Programs Priorities Failure',
  (
    error: HttpError,
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType,
    snackbarMessages: SnackbarMessages = {
      default:
        `We were not able to change the rank order for ${categorisedLoyaltyProgramType} due to technical issues on our end. ` +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, categorisedLoyaltyProgramType, snackbarMessages })
);

export const updateCategorisedLoyaltyProgram = createAction(
  '[Rewards Offering] Update Categorised Loyalty Program',
  props<{
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType;
    params: LoyaltyProgram;
    redirectToListingPage?: boolean;
  }>()
);
export const updateCategorisedLoyaltyProgramSuccess = createAction(
  '[Rewards Offering] Update Categorised Loyalty Program Success',
  (
    id: string,
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType,
    redirectToListingPage?: boolean,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been saved successfully'
    }
  ) => ({ categorisedLoyaltyProgramType, id, snackbarMessages, redirectToListingPage })
);
export const updateCategorisedLoyaltyProgramFailure = createAction(
  '[Rewards Offering] Update Categorised Loyalty Program Failure',
  (
    error: HttpError,
    categorisedLoyaltyProgramType: CategorisedLoyaltyProgramType,
    snackbarMessages: SnackbarMessages = {
      default:
        `We could not update ${categorisedLoyaltyProgramType} details due to technical issues on our end. ` +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ categorisedLoyaltyProgramType, error, snackbarMessages })
);

export const synchroniseLoyaltyPrograms = createAction('[Rewards Offering] Synchronise Loyalty Programs');
export const synchroniseLoyaltyProgramsSuccess = createAction(
  '[Rewards Offering] Synchronise Loyalty Programs Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: 'Programs updated! Refresh the page in a few seconds to see the latest changes.'
    }
  ) => ({ snackbarMessages })
);
export const synchroniseLoyaltyProgramsFailure = createAction(
  '[Rewards Offering] Synchronise Loyalty Programs Failure',
  props<ErrorState>()
);
