import { Pipe, PipeTransform } from '@angular/core';

import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AccessPolicySettings, AttributesSettings, RequestValidationSettings } from '@core/types';

import { MfaIdentity } from '../types';

type ButtonType = 'edit' | 'delete';

@Pipe({ name: 'hideMfaIdentityButtonPipe' })
export class HideMfaIdentityButtonPipe implements PipeTransform {
  constructor(private accessPolicies: AccessPolicies) {}

  transform(mfaIdentity: MfaIdentity, type: ButtonType): any {
    const agentCreatedSettings = this.getAgentCreatedSettings(type);
    const agentCreated = mfaIdentity.customData.agentCreated;

    // hide the button if policy exist and the identity is not created by agent
    return agentCreatedSettings && !agentCreated;
  }

  getAgentCreatedSettings(type: ButtonType): boolean {
    const customDataSettings = this.getPolicySettings(type)?.attributes?.customData as AttributesSettings;
    const agentCreated = customDataSettings?.agentCreated as RequestValidationSettings;

    return !!agentCreated?.eq;
  }

  getPolicySettings(type: ButtonType): AccessPolicySettings {
    if (type === 'edit') {
      return this.accessPolicies.getRequestValidationSettings('mfaIdentities', 'update');
    } else if (type === 'delete') {
      return this.accessPolicies.getRequestValidationSettings('mfaIdentities', 'destroy');
    }
  }
}
