import { first, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ActionWithSnackbarMessages, ErrorState, HttpErrorDetails } from '@core/types';
import { ObjectUtils } from '@utils';

import { ErrorActionTypes } from './error-action-types';

@Injectable()
export class ErrorEffects {
  errorSubscription: Subscription;
  error$: Observable<Action & ErrorState>;

  constructor(
    private actions: Actions,
    private errorActionTypes: ErrorActionTypes,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.errorActionTypes.subscribe(actionTypes => {
      if (this.errorSubscription) {
        // if new action types are added, unsubscribe and resubscribe with the new settings
        this.errorSubscription.unsubscribe();
      }

      this.error$ = this.createErrorEffect(actionTypes);
      this.errorSubscription = this.error$.subscribe();
    });
  }

  private createErrorEffect(actionTypes: Set<string>): Observable<Action & ErrorState> {
    return this.actions.pipe(
      ofType(...actionTypes),
      tap((action: ActionWithSnackbarMessages & ErrorState) => {
        const error: HttpErrorDetails = action.error.errors?.[0];
        const snackbarDuration = action.snackbarDuration;

        if (action.error.status === 0) {
          this.openErrorSnackbar('Server unavailable, please try again later.', snackbarDuration);
          return;
        }

        if (action.snackbarMessages) {
          const codeSpecificMessage = action.snackbarMessages[error?.code];
          const message = codeSpecificMessage || action.snackbarMessages.default;
          this.openErrorSnackbar(message, snackbarDuration);
        } else if (error) {
          this.openDefaultErrorSnackbar(error, snackbarDuration);
        } else {
          this.openErrorSnackbar(`Error: ${action.error.status}`, snackbarDuration);
        }
      })
    );
  }

  private openDefaultErrorSnackbar(error: HttpErrorDetails, snackBarDuration?: number): void {
    const message = ObjectUtils.isObject(error.message)
      ? this.formatObjectErrorMessage(error)
      : `Error: ${error.code} ${error.message}`;
    this.openErrorSnackbar(message, snackBarDuration);
  }

  private openErrorSnackbar(message: string, snackBarDuration?: number): void {
    const snackbarReference = this.snackBar.open(message, 'Dismiss', {
      panelClass: 'multiline-snackbar',
      ...(snackBarDuration !== undefined &&
        snackBarDuration !== null && {
          duration: snackBarDuration
        })
    });

    this.router.events
      .pipe(
        first(event => event instanceof NavigationEnd),
        tap(() => snackbarReference.dismiss())
      )
      .subscribe();
  }

  private formatObjectErrorMessage(error: HttpErrorDetails): string {
    const message = this.extractErrorMessageObject(error.message as object);
    return `Error: ${error.code} \n${message}`;
  }

  private extractErrorMessageObject(message: object): string {
    return Object.entries(message as object)
      .map(([key, value]) => {
        return ObjectUtils.isObject(value) ? this.extractErrorMessageObject(value) : key + ': ' + value.join('/');
      })
      .join('\n');
  }
}
