import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadMemberships } from '../store/actions/memberships.actions';
import { MembershipState } from '../types/memberships.type';

export const membershipsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<MembershipState> = inject(Store<MembershipState>),
  scopes: Scopes = inject(Scopes)
): void => {
  if (scopes.lackScopes(SCOPES_OR.viewMemberships)) {
    return;
  }

  const userId = Params.find(route, 'userId');
  store.dispatch(loadMemberships({ userId }));
};
