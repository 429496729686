import { Inject, Injectable } from '@angular/core';

import { Tag } from '@shared/types';

import { TagDefs } from '../../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class TeBookingTransactionTagDefs {
  readonly tagDefs: TagDefs<any> = {
    te_booking_transaction_uid: {
      getDisplayPrefix: () => 'Booking Transaction',
      getDisplayValue: (tag: Tag) => tag.id,
      getExternalRouteLink: (tags: Tag[]) => {
        const bookingTransactionTag = tags.find(tag => tag.type === 'te_booking_transaction_uid');
        return bookingTransactionTag
          ? `${this.travelEdgeAdminDomain}/kaligo_admin/booking_transaction/${bookingTransactionTag.id}`
          : null;
      }
    }
  };

  constructor(@Inject('travelEdgeAdminDomain') private travelEdgeAdminDomain: string) {}
}
