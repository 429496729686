import { ProductType } from '../../dashboard/types';

export class RedemptionRatesFilter {
  page: number;
  limit: number;
  productType?: ProductType;
  sortBy?: {
    priority?: string;
  };

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}
