import { CdkColumnDef, CdkHeaderRowDef } from '@angular/cdk/table';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  inject,
  QueryList
} from '@angular/core';
import { Observable } from 'rxjs';

import { injectUntilDestroyed } from '@utils';

@Directive({
  standalone: true,
  selector: 'table[mat-table][adminStickyHeaderTable]'
})
export class StickyHeaderTableDirective implements AfterContentInit, AfterViewInit {
  //#region Children queries

  @ContentChild(CdkHeaderRowDef) headerRowDef: CdkHeaderRowDef;

  @ContentChildren(CdkColumnDef, {
    descendants: true
  })
  columnDefs: QueryList<CdkColumnDef>;

  //#endregion

  //#region Inputs and host bindings

  @HostBinding('class.sticky-header')
  get isSticky(): boolean {
    return this.headerRowDef.sticky && !this.columnDefs.some(columnDef => columnDef.sticky);
  }

  @HostBinding('class.admin-sticky-header-table') hostClass = true;

  @HostBinding('class.scrolling-down') isScrollingDown = false;

  //#endregion

  //#region Dependencies

  private tableEl = inject<ElementRef<HTMLTableElement>>(ElementRef);

  private cdr = inject(ChangeDetectorRef);

  private untilDestroyed = injectUntilDestroyed();

  //#endregion

  //#region Lifecycle hooks

  ngAfterContentInit(): void {
    // Should enable sticky header if the header is not sticky yet
    // and there are no sticky columns cause it's not possible to have sticky header with sticky columns due to overflow issues
    if (!this.headerRowDef.sticky && !this.columnDefs.some(columnDef => columnDef.sticky)) {
      this.headerRowDef.sticky = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.isSticky) {
      this.listenToDocumentScrolled();
    }
  }

  //#endregion

  //#region Event handlers

  listenToDocumentScrolled(): void {
    const isTableScrollingDown$ = new Observable<boolean>(observer => {
      const intersectionObserver = new IntersectionObserver(
        entries => {
          observer.next(!entries[0].isIntersecting);
        },
        {
          root: document,
          threshold: 0
        }
      );

      // Query the header row
      intersectionObserver.observe(this.tableEl.nativeElement.querySelector('thead'));

      return {
        unsubscribe() {
          intersectionObserver.disconnect();
          observer.complete();
        }
      };
    });

    isTableScrollingDown$.pipe(this.untilDestroyed()).subscribe(isScrollingDown => {
      this.isScrollingDown = isScrollingDown;
      this.cdr.markForCheck();
    });
  }

  //#endregion
}
