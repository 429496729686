import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { UpsellDialogService } from '../../services/upsell-dialog.service';
import {
  notifyIntentToUpgrade,
  notifyIntentToUpgradeFailure,
  notifyIntentToUpgradeSuccess
} from '../actions/upsell-dialog.actions';

@Injectable()
export class UpsellDialogEffects {
  notifyIntentToUpgrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notifyIntentToUpgrade),
      exhaustMap(() =>
        this.upsellDialogService.notifyIntentToUpgrade().pipe(
          map(() => notifyIntentToUpgradeSuccess()),
          catchError(error => of(notifyIntentToUpgradeFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private upsellDialogService: UpsellDialogService
  ) {}
}
