import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { GuardhouseService } from '@core/services/guardhouse/guardhouse.service';

import {
  createApiSettingsToken,
  createApiSettingsTokenFailure,
  createApiSettingsTokenSuccess
} from '../actions/api-settings-token.actions';

@Injectable()
export class ApiSettingsTokenEffects {
  createApiSettingsToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createApiSettingsToken),
      exhaustMap(action =>
        this.guardhouseService.generateToken(action.tokenRequest).pipe(
          map(apiSettingsToken => createApiSettingsTokenSuccess({ apiSettingsToken })),
          catchError(error => of(createApiSettingsTokenFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private guardhouseService: GuardhouseService
  ) {}
}
