import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import {
  CarsConfig,
  FlightsConfig,
  HotelsConfig,
  PopularDestination,
  TravelConfigUpdateParams,
  TravelType
} from '../types';

@Injectable({ providedIn: 'root' })
export class TravelConfigsService {
  constructor(private missionControlService: MissionControlService) {}

  getTravelConfig(travelType: TravelType): Observable<CarsConfig | FlightsConfig | HotelsConfig> {
    // NOTE: keep travelType for future use
    return this.missionControlService.get<CarsConfig | FlightsConfig | HotelsConfig>('travel_edge/tenant');
  }

  getPopularDestinations(): Observable<PopularDestination[]> {
    return this.missionControlService.get<PopularDestination[]>('travel_edge/popular_destinations');
  }

  updateTravelConfig(travelType: TravelType, params: TravelConfigUpdateParams): Observable<void> {
    return this.missionControlService.patch<void>(`travel_edge/tenant?type=${travelType}`, params);
  }

  updatePopularDestinations(params: string[]): Observable<void> {
    return this.missionControlService.patch<void>(`travel_edge/tenant?type=popular_destinations`, params);
  }
}
