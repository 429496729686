import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { UserPasswordsService } from '../../services/user-passwords.service';
import {
  loadUserPasswords,
  loadUserPasswordsFailure,
  loadUserPasswordsSuccess
} from '../actions/user-passwords.actions';

@Injectable()
export class UserPasswordsEffects {
  loadUserPasswords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserPasswords),
      exhaustMap(({ userId }) =>
        this.userPasswordsService.getUserPasswords(userId).pipe(
          map(userPasswords => loadUserPasswordsSuccess({ userPasswords })),
          catchError(error => of(loadUserPasswordsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userPasswordsService: UserPasswordsService
  ) {}
}
