import { Component, DestroyRef, inject, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { or, Params } from '@utils';

import { PointsAccount } from '../../../dashboard/types';
import { loyaltyProgramsQuery } from '../../../loyalty-programs/store/selectors/loyalty-programs.selectors';
import { LoyaltyProgramState } from '../../../loyalty-programs/types';
import { membershipsQuery } from '../../../memberships/store/selectors/memberships.selectors';
import { Membership, MembershipState } from '../../../memberships/types/memberships.type';
import { createCashRedemption, updateMembershipAndCreateCashRedemption } from '../../store/actions/redemptions.actions';
import { currencyConversionQuery } from '../../store/selectors/currency-conversion.selectors';
import { redemptionQuery } from '../../store/selectors/redemptions.selectors';
import { CashRedemption, CurrencyConversionState, RedemptionState, SelectedAmount } from '../../types';

interface CardSelectionDropdownOption {
  identifier: number;
  enrollmentId: string;
  category: string;
  number: string;
  preferred: boolean;
  loyaltyProgramId: string;
}

@Component({
  selector: 'admin-cash-redemption-create',
  templateUrl: './cash-redemption-create.component.html'
})
export class CashRedemptionCreateComponent implements OnInit {
  @Input() selectedPointsAccountId: string;
  @Input() pointsAccounts: PointsAccount[];

  destroyRef = inject(DestroyRef);

  cardSelectionDropdownList: CardSelectionDropdownOption[] = [];
  cardSelection: FormControl<number>;
  loading$: Observable<boolean>;
  processingDelay$: Observable<number>;
  memberships: Membership[];
  selectedAmount: SelectedAmount;
  userId: string;
  selectedLoyaltyProgramId: string;

  constructor(
    private route: ActivatedRoute,
    private currencyConversionStore: Store<CurrencyConversionState>,
    private loyaltyProgramStore: Store<LoyaltyProgramState>,
    private membershipStore: Store<MembershipState>,
    private store: Store<RedemptionState>,
    @Inject('showPointsAccountsSelector') public showPointsAccountsSelector: boolean
  ) {}

  ngOnInit(): void {
    this.userId = Params.find(this.route, 'userId');
    this.cardSelection = new FormControl<number>(null, Validators.required);

    this.loading$ = or(
      this.membershipStore.select(membershipsQuery.isSingleLoading),
      this.membershipStore.select(membershipsQuery.isBatchLoading),
      this.currencyConversionStore.select(currencyConversionQuery.isSingleLoading),
      this.store.select(redemptionQuery.isSingleLoading)
    );

    this.membershipStore
      .select(membershipsQuery.getMembershipsList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(memberships => {
        this.memberships = memberships.filter(membership => membership.type === 'cashback_membership');
        this.createDropdownList();
      });

    this.processingDelay$ = this.loyaltyProgramStore.select(
      loyaltyProgramsQuery.getProcessingDelayById(this.selectedLoyaltyProgramId)
    );
  }

  createDropdownList(): void {
    if (this.showPointsAccountsSelector) {
      this.addPointsAccountToDropdown();
    }

    this.addCashMembershipToDropdown();
    this.setPreferredAccount();
  }

  addPointsAccountToDropdown(): void {
    const category = 'cashback_to_card';
    const loyaltyProgramId = this.memberships.find(
      (membership: Membership) => membership.category === category
    )?.loyaltyProgramId;

    if (loyaltyProgramId) {
      this.cardSelectionDropdownList = this.pointsAccounts
        .filter((pointAccount: PointsAccount) => pointAccount.status === 'active')
        .map((pointAccount: PointsAccount, index: number) => {
          const enrollmentId = pointAccount.enrollments[0].id;
          const preferred = !!this.memberships.find(membership => membership.enrollmentId === enrollmentId)?.preferred;

          let number = pointAccount.enrollments[0].metadata.accountOrCardNumber;
          number = number?.slice(-4); // get last 4 digits of card number for display

          return { identifier: index + 1, enrollmentId, category, number, preferred, loyaltyProgramId };
        });
    }
  }

  addCashMembershipToDropdown(): void {
    const cashMembership = this.memberships.find(membership => membership.category === 'cashback_to_account');
    if (cashMembership) {
      const identifier = this.cardSelectionDropdownList.length + 1;
      this.cardSelectionDropdownList.push({
        identifier,
        enrollmentId: null,
        category: 'cash',
        number: cashMembership.number,
        preferred: cashMembership.preferred ?? false,
        loyaltyProgramId: cashMembership.loyaltyProgramId
      });
    }
  }

  setPreferredAccount(): void {
    const preferredAcct = this.cardSelectionDropdownList.find(
      (selection: CardSelectionDropdownOption) => selection.preferred
    );
    if (preferredAcct) {
      this.cardSelection.setValue(preferredAcct.identifier);
      this.selectedLoyaltyProgramId = preferredAcct.loyaltyProgramId;
    }
  }

  onCardSelectionChange({ value: identifier }: MatSelectChange): void {
    const selectedAcct = this.cardSelectionDropdownList.find(cardSelection => cardSelection.identifier === identifier);
    this.selectedLoyaltyProgramId = selectedAcct?.loyaltyProgramId;
  }

  redeem(): void {
    const { loyaltyProgramId, category, enrollmentId, number } = this.cardSelectionDropdownList.find(
      cardSelection =>
        cardSelection.loyaltyProgramId === this.selectedLoyaltyProgramId &&
        cardSelection.identifier === this.cardSelection.value
    );
    const { id: membershipId, enrollmentId: membershipEnrollmentId } = this.memberships.find(
      membership => membership.loyaltyProgramId === this.selectedLoyaltyProgramId
    );
    const { transferAmount: cashAmount, redemptionAmount } = this.selectedAmount;

    const redemption: CashRedemption = {
      userId: this.userId,
      pointsAccountId: this.selectedPointsAccountId,
      cashRedemption: { cashAmount, loyaltyProgramId, redemptionAmount }
    };

    if (category === 'cashback_to_card' && enrollmentId !== membershipEnrollmentId) {
      this.store.dispatch(
        updateMembershipAndCreateCashRedemption({
          membershipId,
          membership: { enrollmentId, number: 'XXXX XXXX XXXX ' + number },
          redemption
        })
      );
    } else {
      this.store.dispatch(createCashRedemption({ redemption }));
    }
  }
}
