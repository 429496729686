<div
  class="flex select-none flex-col gap-4 rounded-md border border-cloudburst-200 bg-neutrals-100 p-4 text-cloudburst-500"
  (click)="openFullPageOverlay()"
>
  <div class="flex justify-between gap-4">
    <div class="text-base font-bold">{{ recommendation.name }}</div>
    <mat-icon svgIcon="xmark-thin" class="min-w-6 cursor-pointer" (click)="hideRecommendation($event)"></mat-icon>
  </div>

  <div>
    @if (recommendation.type === 'campaign') {
      <div class="text-cloudburst-300">How this campaign might benefit you:</div>
    }

    <div class="text-cloudburst-pure">
      {{ recommendation.description }}
    </div>
  </div>
</div>
