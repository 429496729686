import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { NydusNetworkPartnerConfigurationResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class NydusNetworkPartnerConfigurationsService {
  constructor(private missionControlService: MissionControlService) {}

  getPartnerConfiguration(): Observable<NydusNetworkPartnerConfigurationResult> {
    return this.missionControlService.get<NydusNetworkPartnerConfigurationResult>('partner_configuration');
  }
}
