import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface NydusNetworkPartnerConfigurationResult {
  data: {
    id: string;
    type: 'partner_configuration';
    attributes: NydusNetworkPartnerConfiguration;
  };
}

export interface NydusNetworkPartnerConfiguration {
  // intentionally ingore uiSettings from NN
  id: string;
  schemaId: string;
  appSettings: AppSettings;
  featureSettings: FeatureSettings;
  tenantLoyaltyCurrency?: object;
  tenantLoyaltyProgram: { id: string; name?: string; tenantCurrencyName?: string };
}

// With reference to NN codebase: app/schemas/partner_configuration_schema.rb
interface AppSettings {
  accountType?: AccountType;
  cashCurrency: string;
  countryCodes?: string[];
  domains: string[];
  links?: Link[];
  locales: { default: string; enabled: string[] };
  loyaltyCurrency?: { transferRateDefaultMatchingRule: string };
  manualAdjustments: ManualAdjustment[];
  notification?: Notification;
  redemptionSetting: { step: number };
  roundingPreferences: { [key: string]: { direction: string; nearest: number } };
  stripeStatementDescriptor: string;
  thirdPartySettings?: { googleTagManager?: { containerId?: string } };
  timeZone: string;
  validations: string[];
  version: number;
}

interface AccountType {
  defaultCategory: string;
  mapping: { partnerCode: string; category: string }[];
}

interface Link {
  contactUsUrl?: string;
  customerServiceSpecificTermsUrl?: string;
  loginUrl?: string;
  myOrderUrl?: string;
  portalHomeUrl?: string;
  privacyPolicyUrl?: string;
  termsAndConditionsUrl?: string;
}

interface ManualAdjustment {
  categories: string[];
  customExpiry: boolean;
  defaultExpiryRule: string;
  metadata: { key: string; type: string; required: boolean; values?: string[] }[];
  reason: string;
}

interface Notification {
  campaigns?: { campaignCode: string; eventName: string };
  delayTrigger: boolean;
  deliverable: { pointsTransactions: { systemEvent: { accrual: boolean } } };
  events?: string[];
  senderEmailAddress: string;
  skipBlockedUsers?: boolean;
  variables?: { portalName?: string; contactEmail?: string; contactPhone?: string; emailHeaderFilePath?: string };
}

const featureSettingKeys = [
  'giftCard',
  'crypto',
  'cashRedemption',
  'pointsTransfer',
  'travelBooking',
  'affiliateOffer',
  'cardLinkedOffer',
  'sustainability'
] as const;

const featureSettingProductKeys = [
  'giftCards',
  'crypto',
  'cashback',
  'affiliateOffers',
  'cardLinkedOffers',
  'sustainability',
  'frequentTravelerPrograms',
  'cars',
  'flights',
  'hotels'
] as const;

export type FeatureSettingKeys = (typeof featureSettingKeys)[number];
export type FeatureSettingProductKeys = (typeof featureSettingProductKeys)[number];

export type FeatureSettings = {
  [key in FeatureSettingKeys]: { enabled: boolean; settings?: object };
};

export interface NydusNetworkPartnerConfigurationState extends CallState {
  partnerConfiguration: NydusNetworkPartnerConfiguration;
}

export const nydusNetworkPartnerConfigurationInitialState: NydusNetworkPartnerConfigurationState = {
  ...initialCallState,
  partnerConfiguration: null
};
