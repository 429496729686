import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { QuestionsService } from '../../services/questions.service';
import {
  activateQuestionsFailure,
  activateQuestionsSuccess,
  loadQuestions,
  loadQuestionsFailure,
  loadQuestionsSuccess
} from '../actions/questions.actions';
import { activateQuestions } from './../actions/questions.actions';

@Injectable()
export class QuestionsEffects {
  loadQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadQuestions),
      exhaustMap(() =>
        this.questionsService.getQuestions().pipe(
          map(({ data }) => loadQuestionsSuccess({ questions: data })),
          catchError(error => of(loadQuestionsFailure({ error })))
        )
      )
    )
  );

  activateQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateQuestions),
      exhaustMap(({ ids }) =>
        this.questionsService.activateQuestions({ ids }).pipe(
          map(_ => activateQuestionsSuccess()),
          catchError(error => of(activateQuestionsFailure({ error })))
        )
      )
    )
  );

  activateQuestionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateQuestionsSuccess),
      map(() => loadQuestions())
    )
  );

  constructor(
    private actions$: Actions,
    private questionsService: QuestionsService
  ) {}
}
