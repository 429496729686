export class MergeConfigsFilter {
  page: number;
  limit: number;
  name?: string;

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}
