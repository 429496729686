import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import {
  PopularDestinationState,
  TravelConfigState,
  initialPopularDestinationState,
  initialTravelConfigState
} from '../../types';
import {
  loadTravelConfig,
  updateTravelConfig,
  loadTravelConfigSuccess,
  updateTravelConfigSuccess,
  loadTravelConfigFailure,
  updateTravelConfigFailure,
  loadPopularDestinations,
  loadPopularDestinationsSuccess,
  loadPopularDestinationsFailure,
  updatePopularDestinations,
  updatePopularDestinationsFailure,
  updatePopularDestinationsSuccess
} from '../actions';

export const travelConfigsTriggers: EntityTriggers = {
  single: {
    loading: [loadTravelConfig.type, updateTravelConfig.type],
    resting: [loadTravelConfigSuccess.type, updateTravelConfigSuccess.type],
    erroring: [loadTravelConfigFailure.type, updateTravelConfigFailure.type]
  }
};

export const popularDestinationsTriggers: EntityTriggers = {
  single: {
    loading: [loadPopularDestinations.type, updatePopularDestinations.type],
    resting: [loadPopularDestinationsSuccess.type, updatePopularDestinationsSuccess.type],
    erroring: [loadPopularDestinationsFailure.type, updatePopularDestinationsFailure.type]
  }
};

export function travelConfigsReducer(
  state: TravelConfigState = initialTravelConfigState,
  action: Action
): TravelConfigState {
  return callStateReducer(baseTravelConfigsReducer, travelConfigsTriggers)(state, action);
}

const baseTravelConfigsReducer = createReducer(
  initialTravelConfigState,
  on(loadTravelConfigSuccess, (state, { travelType, travelConfig }) => ({
    ...state,
    [travelType]: travelConfig
  }))
);

export function popularDestinationsReducer(
  state: PopularDestinationState = initialPopularDestinationState,
  action: Action
): PopularDestinationState {
  return callStateReducer(basePopularDestinationsReducer, popularDestinationsTriggers)(state, action);
}

const basePopularDestinationsReducer = createReducer(
  initialPopularDestinationState,
  on(loadPopularDestinationsSuccess, (state, { popularDestinations }) => ({
    ...state,
    popularDestinations
  }))
);
