<div class="details-container events-component">
  <div class="column">
    <div *ngIf="data.userId" class="item">
      <div class="item__key">
        <h3>User ID</h3>
      </div>
      <span class="item__value">
        {{ data.userId }}
      </span>
    </div>

    <div *ngIf="data.agentId" class="item">
      <div class="item__key">
        <h3>Agent ID</h3>
      </div>
      <span class="item__value">
        {{ data.agentId }}
      </span>
    </div>

    <div *ngIf="data.tenantId" class="item">
      <div class="item__key">
        <h3>Tenant ID</h3>
      </div>
      <span class="item__value">
        {{ data.tenantId }}
      </span>
    </div>

    <div *ngIf="data.params" class="item">
      <div class="item__key">
        <h3>Params</h3>
      </div>
      <span class="item__value">
        <span class="json-data-block">{{ data.params | json }}</span>
      </span>
    </div>

    <div *ngIf="data.responseCode" class="item">
      <div class="item__key">
        <h3>Response code</h3>
      </div>
      <span class="item__value">
        {{ data.responseCode }}
      </span>
    </div>

    <div *ngIf="data.deviceInfo">
      <h3>Device info <mat-icon>devices</mat-icon></h3>
      <div class="item">
        <div class="item__key">
          <h4>Host</h4>
        </div>
        <span class="item__value">
          {{ data.deviceInfo.host }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>IP address</h4>
        </div>
        <span class="item__value">
          {{ data.deviceInfo.ip }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Session ID</h4>
        </div>
        <span class="item__value">
          {{ data.deviceInfo.sessionId }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Operating system</h4>
        </div>
        <span class="icon-text-wrapper">
          <span [innerHTML]="data.deviceInfo.userAgent | userAgentInfo: 'osIcon'"></span>
          <span>{{ data.deviceInfo.userAgent | userAgentInfo: 'os' }}</span>
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Browser</h4>
        </div>
        <span class="icon-text-wrapper">
          <span [innerHTML]="data.deviceInfo.userAgent | userAgentInfo: 'browserIcon'"></span>
          <span>{{ data.deviceInfo.userAgent | userAgentInfo: 'browser' }}</span>
        </span>
      </div>
    </div>

    <div *ngIf="data.additionalInfo" class="item additional-info">
      <div class="item__key">
        <h3>Additional Info</h3>
      </div>
      <span class="item__value">
        <span class="json-data-block">{{ data.additionalInfo | json }}</span>
      </span>
    </div>

    <div *ngIf="data.request">
      <h3>Request <mat-icon>devices</mat-icon></h3>
      <div class="item">
        <div class="item__key">
          <h4>Host</h4>
        </div>
        <span class="item__value">
          {{ data.request.host }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>IP address</h4>
        </div>
        <span class="item__value">
          {{ data.request.ip }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Operating system</h4>
        </div>
        <span class="icon-text-wrapper">
          <span [innerHTML]="data.request.userAgent | userAgentInfo: 'osIcon'"></span>
          <span>{{ data.request.userAgent | userAgentInfo: 'os' }}</span>
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Browser</h4>
        </div>
        <span class="icon-text-wrapper">
          <span [innerHTML]="data.request.userAgent | userAgentInfo: 'browserIcon'"></span>
          <span>{{ data.request.userAgent | userAgentInfo: 'browser' }}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="column">
    <div *ngIf="data.location">
      <div class="item">
        <div class="item__key">
          <h4>City</h4>
        </div>
        <span class="item__value">
          {{ data.location.city }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Country</h4>
        </div>
        <span class="item__value">
          {{ data.location.country }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Latitude</h4>
        </div>
        <span class="item__value">
          {{ data.location.lat }}
        </span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Longitude</h4>
        </div>
        <span class="item__value">
          {{ data.location.lon }}
        </span>
      </div>
    </div>
    <div *ngIf="data.location" class="item">
      <google-map class="map-item" [center]="{ lat: data.location.lat, lng: data.location.lon }">
        <map-marker [position]="{ lat: data.location.lat, lng: data.location.lon }"> </map-marker>
      </google-map>
    </div>
  </div>

  <div *ngIf="data.errors" class="error-event">
    <h3>Errors <mat-icon>error_outline</mat-icon></h3>
    <div *ngFor="let error of data.errors" class="column">
      <div class="item">
        <div class="item__key">
          <h4>Code:</h4>
        </div>
        <span class="item__value">{{ error.code }}</span>
      </div>

      <div class="item">
        <div class="item__key">
          <h4>Message:</h4>
        </div>
        <span class="item__value">{{ error.message }}</span>
      </div>

      <div class="item" *ngIf="error.metadata">
        <div class="item__key">
          <h4>Metadata:</h4>
        </div>
        <span class="item__value">
          <span class="json-data-block">{{ error.metadata | json }}</span>
        </span>
      </div>

      <div class="item" *ngIf="error.translation">
        <div class="item__key">
          <h4>Translation:</h4>
        </div>
        <span class="item__value">
          <span class="json-data-block">{{ error.translation | json }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
