import { Component, Input, OnInit } from '@angular/core';

import { CashRedemptionOrderItemData, OrderItem, StripeReferenceData } from '../../types';

@Component({
  selector: 'admin-cash-redemption-order-item-v2',
  templateUrl: './cash-redemption-order-item-v2.component.html'
})
export class CashRedemptionOrderItemV2Component implements OnInit {
  @Input() customerId: string;
  @Input() orderItem: OrderItem;
  @Input() stripeReferenceData: StripeReferenceData;

  data: CashRedemptionOrderItemData;

  ngOnInit(): void {
    this.data = this.orderItem.data as CashRedemptionOrderItemData;
  }
}
