import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { TagsService } from '@shared/services/tags.service';

import { EventsService } from '../../services/events.service';
import { EventsResult } from '../../types/events-result.type';
import { loadEvents, loadEventsFailure, loadEventsSuccess } from '../actions/events.actions';

@Injectable()
export class EventsEffects {
  loadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEvents),
      exhaustMap(action =>
        this.eventsService.getEvents(action.filter).pipe(
          exhaustMap(result => (result.data.length > 0 ? this.tagsService.formatLogsResultTags(result) : of(result))),
          map(eventsResult => loadEventsSuccess({ eventsResult: eventsResult as EventsResult })),
          catchError(error => of(loadEventsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
    private tagsService: TagsService
  ) {}
}
