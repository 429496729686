<form [formGroup]="filterForm" *ngIf="filterForm">
  <div fxLayout="start center" fxLayoutGap="15px">
    <mat-form-field formGroupName="dateRange" class="date-form slim-form-field">
      <mat-label>Date Range</mat-label>
      <div class="date-form__wrapper">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-input [rangePicker]="picker">
          <input matInput matStartDate formControlName="dateFrom" placeholder="Start date" (focus)="picker.open()" />
          <input matInput matEndDate formControlName="dateTo" placeholder="End date" (focus)="picker.open()" />
        </mat-date-range-input>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <button
          matSuffix
          mat-icon-button
          color="primary"
          (click)="filterForm.get('dateRange').reset()"
          [disabled]="!filterForm.get('dateRange.dateFrom').value && !filterForm.get('dateRange.dateFrom').value"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-form-field>
    <mat-form-field *ngIf="filterEntities" class="slim-form-field">
      <mat-label>Entity</mat-label>
      <mat-select formControlName="entity" disableOptionCentering panelClass="dropdown-panel">
        <mat-option *ngFor="let entity of filterEntities" [value]="entity">
          {{ entity | customTitleCase: 'fromSnakeCase' }}
        </mat-option>
      </mat-select>
      <admin-input-reset
        matSuffix
        [inputControl]="filterForm.controls.entity"
        [loading]="!!defaultEntity"
      ></admin-input-reset>
    </mat-form-field>
    <mat-form-field class="slim-form-field">
      <mat-label>Action</mat-label>
      <input
        type="text"
        placeholder="Action"
        aria-label="Action"
        matInput
        formControlName="action"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let action of filteredActions$ | async" [value]="action">
          {{ action }}
        </mat-option>
      </mat-autocomplete>
      <admin-input-reset matSuffix [inputControl]="filterForm.controls.action" initialValue=""></admin-input-reset>
    </mat-form-field>
    <admin-input-tags [tags]="tags" dialogTitle="Filter by tag" submitButtonText="Add tag to filter"></admin-input-tags>
  </div>
  <div fxLayout="start center" fxLayoutGap="15px" *ngIf="isTopLevelView" class="quick-search">
    <mat-label class="quick-search-label">Quick search</mat-label>
    <mat-chip-listbox aria-label="Tags">
      <mat-chip-option *ngFor="let tag of quickSearchTags" (click)="handleQuickSearch(tag.entity, tag.action)">
        {{ tag.label }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</form>
<div fxLayout="column" fxLayoutGap="20px">
  <div *ngIf="loading$ | async" class="progress-loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div [class.overlay]="loading$ | async" class="data-table-container w-full">
    <table
      mat-table
      [dataSource]="logs"
      multiTemplateDataRows
      matSort
      (matSortChange)="onSort($event)"
      class="mat-elevation-z1 data-table multiple-line-tags-padding"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Date</th>
        <td mat-cell *matCellDef="let log" attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
          {{ log.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="device info">
        <th mat-header-cell *matHeaderCellDef>Device info</th>
        <td mat-cell *matCellDef="let log">
          <div
            *ngIf="log | deviceInfo: 'exists?'; else noDeviceInfo"
            class="device-icons"
            [ngClass]="{ 'short-tooltip': log | deviceInfo: 'isShortInfo' }"
            [attr.data-tooltip]="log | deviceInfo: 'userAgent'"
          >
            <span [innerHTML]="log | deviceInfo: 'iconsAndFlag'"></span>
          </div>
          <ng-template #noDeviceInfo> No Device Info </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let log">{{ log.message }}</td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef>Tags</th>
        <td mat-cell *matCellDef="let log">
          <mat-chip-listbox aria-label="Tags">
            <mat-chip-option *ngFor="let tag of log.tags" [routerLink]="tag.routeLink" [class]="tag.type">
              {{ tag.displayPrefix }}: {{ tag.displayValue }}
            </mat-chip-option>
          </mat-chip-listbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let log">
          <button (click)="openLogDetails(log)" mat-icon-button class="actions-button">
            <mat-icon>zoom_in</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="changes">
        <th mat-header-cell *matHeaderCellDef>Changes</th>
        <td mat-cell *matCellDef="let log">
          <button
            *ngIf="log.params.changes as changes"
            (click)="openChangesDetails(changes)"
            mat-icon-button
            class="actions-button"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <ng-container *ngIf="!(loading$ | async)">
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </ng-container>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50]"
      [pageIndex]="filter.page - 1"
      [pageSize]="filter.limit"
      [length]="count$ | async"
      (page)="onPage($event)"
    >
    </mat-paginator>
    <div *ngIf="loading$ | async" class="progress-loader table-loading-container">Loading...</div>
  </div>
</div>
