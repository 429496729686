import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { SingleEntityTriggers } from '@core/types';

import { CerberusConfigState, initialState } from '../../types/cerberus-configs.type';
import {
  loadCerberusConfig,
  loadCerberusConfigFailure,
  loadCerberusConfigSuccess,
  refreshTokenFailure,
  resetCerberusConfig,
  resetCerberusConfigFailure,
  resetCerberusConfigSuccess
} from '../actions/cerberus-configs.actions';

export const cerberusConfigTriggers: Required<SingleEntityTriggers> = {
  single: {
    loading: [loadCerberusConfig.type, resetCerberusConfig.type],
    resting: [loadCerberusConfigSuccess.type, resetCerberusConfigSuccess.type],
    erroring: [loadCerberusConfigFailure.type, resetCerberusConfigFailure.type, refreshTokenFailure.type]
  }
};

export function cerberusConfigsReducer(state: CerberusConfigState = initialState, action: Action): CerberusConfigState {
  return callStateReducer(baseReducer, cerberusConfigTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadCerberusConfigSuccess, (state, { cerberusConfig, appId }) => ({ ...state, cerberusConfig, appId }))
);
