import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import {
  MerchantPortalBootstrap,
  CampaignProposal,
  CreateCampaignProposalParams
} from '../../types/campaign-proposals.type';

export const loadMerchantPortalBootstrap = createAction('[Campaign Proposals] Load Merchant Portal Bootstrap');
export const loadMerchantPortalBootstrapSuccess = createAction(
  '[Campaign Proposals] Load Merchant Portal Bootstrap Success',
  props<{ merchantPortalBootstrap: MerchantPortalBootstrap }>()
);
export const loadMerchantPortalBootstrapFailure = createAction(
  '[Campaign Proposals] Load Merchant Portal Bootstrap Failure',
  props<ErrorState>()
);

export const loadCampaignProposals = createAction('[Campaign Proposals] Load Campaign Proposals');
export const loadCampaignProposalsSuccess = createAction(
  '[Campaign Proposals] Load Campaign Proposals Success',
  props<{ campaignProposals: CampaignProposal[] }>()
);
export const loadCampaignProposalsFailure = createAction(
  '[Campaign Proposals] Load Campaign Proposals Failure',
  props<ErrorState>()
);

export const loadCampaignProposal = createAction(
  '[Campaign Proposals] Load Campaign Proposal',
  props<{ id: string }>()
);
export const loadCampaignProposalSuccess = createAction(
  '[Campaign Proposals] Load Campaign Proposal Success',
  props<{ campaignProposal: CampaignProposal }>()
);
export const loadCampaignProposalFailure = createAction(
  '[Campaign Proposals] Load Campaign Proposal Failure',
  props<ErrorState>()
);

export const createCampaignProposal = createAction(
  '[Campaign Proposals] Create Campaign Proposal',
  props<{ campaignProposal: CreateCampaignProposalParams }>()
);
export const createCampaignProposalSuccess = createAction(
  '[Campaign Proposals] Create Campaign Proposal Success',
  (
    campaignProposal: CampaignProposal,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully submitted a promotion proposal'
    }
  ) => ({ campaignProposal, snackbarMessages })
);
export const createCampaignProposalFailure = createAction(
  '[Campaign Proposals] Create Campaign Proposal Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We were unable to submit the promotion proposal due to technical issues on our end. ' +
        'Please try saving your changes again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateCampaignProposal = createAction(
  '[Campaign Proposals] Update Campaign Proposal',
  props<{ campaignProposal: CampaignProposal; redirect?: boolean }>()
);
export const updateCampaignProposalSuccess = createAction(
  '[Campaign Proposals] Update Campaign Proposal Success',
  props<{ campaignProposal: CampaignProposal; redirect?: boolean }>()
);
export const updateCampaignProposalFailure = createAction(
  '[Campaign Proposals] Update Campaign Proposal Failure',
  props<ErrorState>()
);

export const updateSelectedYear = createAction(
  '[Campaign Proposals] Update Selected Year',
  props<{ selectedYear: number }>()
);
