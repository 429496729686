<div class="gift-card-order-item">
  <!--   first section   -->
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <h3>Gift Card: {{ data?.productName }} x{{ orderItem.quantity }}</h3>

    <div fxLayout="row wrap">
      <admin-refund-order-button
        [orderItem]="orderItem"
        fxLayoutAlign="center center"
        fxFlex
      ></admin-refund-order-button>

      <ng-container *ngIf="orderItem.status === 'processing'">
        <button
          *adminIfScopesInclude="SCOPES.updateOrderItemStatus; relationOperator: 'or'"
          mat-raised-button
          color="primary"
          class="uppercase-button"
          (click)="updateOrderItemStatus()"
        >
          <mat-icon class="uppercase-button__icon">refresh</mat-icon>
          <span class="uppercase-button__text">Update Status</span>
        </button>
      </ng-container>

      <ng-container *ngIf="orderItem.type === 'gift_card_order_item' && orderItem.notifications.length">
        <button
          *adminIfScopesInclude="SCOPES.createDeliveries; relationOperator: 'or'"
          mat-raised-button
          color="primary"
          class="uppercase-button"
          (click)="openSendNotificationDialog()"
        >
          <mat-icon class="uppercase-button__icon">send</mat-icon>
          <span class="uppercase-button__text">Resend Email</span>
        </button>
      </ng-container>
    </div>
  </div>

  <div class="details-container padding-y-20">
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Status</h4>
        </div>
        <span class="item__value">{{ (orderItem.status | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Card type</h4>
        </div>
        <span class="item__value">{{ ((giftCard$ | async)?.displayTypes | arrayJoin | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Delivery method</h4>
        </div>
        <span class="item__value">{{ ((giftCard$ | async)?.deliveryMethod | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Supplier</h4>
        </div>
        <span class="item__value">{{
          ((giftCard$ | async)?.supplier | customTitleCase: 'fromSnakeCase') || 'N/A'
        }}</span>
      </div>
      <div class="item">
        <div class="item__key" fxLayoutGap="5px" fxLayoutAlign="start start">
          <h4>Supplier Reference ID</h4>
          <admin-tooltip key="orders-items.details.giftCardOrderItem.externalSupplierOrderReference"></admin-tooltip>
        </div>
        <span class="item__value item-with-btn" fxLayout="row" fxLayoutAlign="start center">
          {{ data.externalSupplierOrderReference || 'N/A' }}
          <admin-copy-to-clipboard
            *ngIf="data.externalSupplierOrderReference"
            [content]="data.externalSupplierOrderReference"
            contentName="Supplier Reference ID"
            styleClassName="detail-copy-btn"
          ></admin-copy-to-clipboard>
        </span>
      </div>
      <div class="item">
        <div class="item__key" fxLayoutGap="5px" fxLayoutAlign="start start">
          <h4>Supplier Reference Item ID</h4>
        </div>
        <span class="item__value item-with-btn" fxLayout="row" fxLayoutAlign="start center">
          {{ data.externalSupplierOrderItemReference || 'N/A' }}
          <admin-copy-to-clipboard
            *ngIf="data.externalSupplierOrderItemReference"
            [content]="data.externalSupplierOrderItemReference"
            contentName="Supplier Reference Item ID"
            styleClassName="detail-copy-btn"
          ></admin-copy-to-clipboard>
        </span>
      </div>
    </div>

    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Brand</h4>
        </div>
        <span class="item__value">{{ data?.productName || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Customer ID</h4>
        </div>
        <span class="item__value">{{ customerId || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Ascenda ID</h4>
        </div>
        <span class="item__value">
          <a
            *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
            [routerLink]="'/customers/' + orderItem.userId + detailsView"
          >
            {{ orderItem.userId }}
          </a>
          <ng-template #userIdWithoutLink>
            {{ orderItem.userId }}
          </ng-template>
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Order initiator</h4>
        </div>
        <span class="item__value">{{ (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Created at</h4>
        </div>
        <span
          *ngIf="orderItem.createdAt; else showNA"
          class="item__value"
          attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
        >
          {{ orderItem.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Updated at</h4>
        </div>
        <span
          *ngIf="orderItem.updatedAt; else showNA"
          class="item__value"
          attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
        >
          {{ orderItem.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!--   second section   -->
  <div class="title"><p>Delivery details</p></div>
  <div class="details-container padding-y-20">
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>First name</h4>
        </div>
        <span class="item__value">{{ (recipient?.firstName | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Last name</h4>
        </div>
        <span class="item__value">{{ (recipient?.lastName | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Email</h4>
        </div>
        <span class="item__value">{{ recipient?.emailAddress || recipient?.email || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Company</h4>
        </div>
        <span class="item__value">{{ recipient?.companyName || 'N/A' }}</span>
      </div>
      <div
        class="item"
        *ngIf="
          orderItem.type === 'physical_gift_card_order_item' &&
          data?.fulfillmentData &&
          data.fulfillmentData[0]?.trackingId
        "
      >
        <div class="item__key">
          <h4>Tracking ID</h4>
        </div>
        <div class="item__value" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start start">
          <span>{{ data.fulfillmentData[0].trackingId }}</span>
          <a
            *ngIf="data.fulfillmentData[0].trackingUrl"
            [href]="data.fulfillmentData[0].trackingUrl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Track delivery <mat-icon class="launch-icon">launch</mat-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Message</h4>
        </div>
        <span class="item__value">{{ recipient?.message || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Phone</h4>
        </div>
        <span class="item__value">{{ recipient?.phoneNumber || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Address</h4>
        </div>
        <span class="item__value">{{ formattedAddress }}</span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!--   third section   -->
  <div class="title"><p>Item details</p></div>
  <div class="details-container padding-y-20">
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Card value {{ data?.currency ? '(' + data.currency + ')' : '' }}</h4>
        </div>
        <span class="item__value" *ngIf="data?.displayValue; else showNA">
          {{ (data.displayValue | number: '1.0-2') + ' per card' }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Card price {{ data?.currency ? '(' + data.currency + ')' : '' }}</h4>
        </div>
        <span class="item__value" *ngIf="data?.supplierPrice; else showNA">
          {{ (data.supplierPrice | number: '1.0-2') + ' per card' }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Quantity</h4>
        </div>
        <span class="item__value">{{ orderItem.quantity ? 'x' + orderItem.quantity : 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>SKU</h4>
        </div>
        <span class="item__value">{{ (giftCard$ | async)?.sku || 'N/A' }}</span>
      </div>
    </div>

    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Total charge</h4>
        </div>
        <span class="item__value">
          {{ orderItem.pointsPaid | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
          }}{{ data?.currency === '$' ? '' : ' '
          }}{{ orderItem.cashPaid | formatCurrency: data?.currency | async | number }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Original charge</h4>
        </div>
        <span class="item__value">
          {{ orderItem.pointsAmount | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
          }}{{ data?.currency === '$' ? '' : ' '
          }}{{ orderItem.cashAmount | formatCurrency: data?.currency | async | number }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Promo code</h4>
        </div>
        <span class="item__value">{{ data?.promoCode || 'N/A' }}</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="orderItem.type === 'gift_card_order_item' && data?.fulfillmentData">
    <mat-divider></mat-divider>

    <!--   fourth section   -->
    <div class="padding-y-20">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <span>Card links and details</span>
        <button mat-icon-button (click)="expandedCardLinks = !expandedCardLinks">
          <mat-icon>{{ expandedCardLinks ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="20px"
        class="expanded-details"
        [@detailExpand]="expandedCardLinks ? 'expanded' : 'collapsed'"
      >
        <table class="card-details">
          <tr>
            <th colspan="3"></th>
            <th>Expiry date</th>
            <th>Receipt number</th>
          </tr>
          <tr *ngFor="let card of data.fulfillmentData; let i = index">
            <td>Card {{ i + 1 | number: '2.0-0' }}</td>
            <td><a [href]="card.viewUrl" target="_blank" rel="noopener noreferrer">View</a></td>
            <td><a [href]="card.downloadUrl" target="_blank" rel="noopener noreferrer">Download</a></td>
            <td>
              <span
                *ngIf="card.expiresAt; else showNA"
                class="item__value"
                attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
              >
                {{ card.expiresAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
              </span>
            </td>
            <td>{{ card.receiptCode || 'N/A' }}</td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>

  <ng-template #showNA>
    <span class="item__value">N/A</span>
  </ng-template>
</div>
