import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { eventAdapter, EventState, initialState } from '../../types/events.type';
import { loadEvents, loadEventsFailure, loadEventsSuccess } from '../actions/events.actions';

export const eventTriggers: EntityTriggers = {
  batch: {
    loading: [loadEvents.type],
    resting: [loadEventsSuccess.type],
    erroring: [loadEventsFailure.type]
  }
};

export function eventsReducer(state: EventState = initialState, action: Action): EventState {
  return callStateReducer(baseReducer, eventTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadEvents, (state, { filter }) => ({ ...state, filter })),
  on(loadEventsSuccess, (state, { eventsResult }) => ({
    ...eventAdapter.setAll(eventsResult.data, state),
    lastPage: eventsResult?.meta?.lastPage
  }))
);
