import { DecodedUser } from '@core/types';

export const mockDecodedUser: DecodedUser = {
  sub: '112975090209018230022',
  name: 'Boon Seng Tan',
  firstName: 'Tan',
  lastName: 'Boon Seng',
  middleName: 'BS',
  nickname: 'bst',
  preferredUsername: 'bstan',
  profile: 'tbs',
  picture: 'http://kaligo.com/avatar.png',
  email: 'bstan@gmail.com',
  gender: 'male',
  birthdate: new Date('1990-02-28'),
  zoneinfo: 'America/Los_Angeles',
  locale: 'en-US',
  scopes: ['profile', 'address', 'openid'],
  tenantId: 'AscendaGo'
};

export const mockDecodedUser2: DecodedUser = {
  sub: '1',
  name: 'Jeremy Lim',
  firstName: 'Lim',
  lastName: 'Jeremy',
  middleName: 'JL',
  nickname: 'Jer',
  preferredUsername: 'jerlim',
  profile: 'jerl',
  picture: 'http://kaligo.com/avatar.png',
  email: 'jerl@gmail.com',
  gender: 'male',
  birthdate: new Date('1990-02-28'),
  zoneinfo: 'America/Los_Angeles',
  locale: 'en-US',
  scopes: ['profile', 'address', 'openid']
};

export const mockDecodedUserWithoutEmail: DecodedUser = {
  sub: '44ebcb8d-8bb0-45ac-a55c-f336acecf39a',
  name: 'John Doe',
  firstName: 'John',
  lastName: 'Doe',
  middleName: 'JD',
  nickname: 'Joe',
  preferredUsername: 'johndoe',
  profile: 'jonhd',
  picture: 'http://kaligo.com/avatar.png',
  gender: 'male',
  birthdate: new Date('1988-03-22'),
  zoneinfo: 'Asia/Singapore',
  locale: 'en-AU',
  scopes: ['profile', 'address'],
  tenantId: 'AscendaGo'
};

export const mockBackendToken = {
  ...mockDecodedUser,
  first_name: 'Tan',
  middle_name: 'BS',
  last_name: 'Boon Seng',
  preferred_username: 'bstan',
  scope: 'profile address openid'
};
