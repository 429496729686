import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { ObjectUtils } from '@utils';

import { PointsActivitiesFilter } from './points-activities-filter.type';
import { ProductType } from './products.type';

export enum PointsActivityCategory {
  Accrual = 'accrual',
  Redemption = 'redemption',
  Expiry = 'expiry',
  Refund = 'refund',
  Reversal = 'reversal',
  WriteOff = 'write_off',
  NegativeWriteOff = 'write_off_negative'
}

export const InvertedPointsActivityCategory = ObjectUtils.invertKeyValue(PointsActivityCategory);

export interface PointsActivityAttributes {
  userId: string;
  pointsAccountId: string;
  transactionTime: string;
  description: string;
  category: PointsActivityCategory;
  entryType: 'credit' | 'debit';
  productType: ProductType;
  status: string;
  amount: number;
  balanceAsOf: number;
  createdAt: string;
  updatedAt: string;
  metadata?: any;
}

export interface PointsActivityStatement {
  links: {
    related: string;
  };
}

export interface PointsActivity extends PointsActivityAttributes {
  id: string;
  pointsStatement?: PointsActivityStatement;
  orderId: string;
}

export const pointsActivityAdapter = createEntityAdapter<PointsActivity>();

export interface PointsActivityState extends EntityState<PointsActivity>, CallState {
  count: number;
  filter: PointsActivitiesFilter;
  userId: string;
  locale: string;
}

export const pointsActivityInitialState: PointsActivityState = {
  ...pointsActivityAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  filter: null,
  userId: null,
  locale: null
};
