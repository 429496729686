import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { AbilitiesService } from '../../services/abilities.service';
import {
  createAbility,
  createAbilityFailure,
  createAbilitySuccess,
  deleteAbility,
  deleteAbilityFailure,
  deleteAbilitySuccess,
  loadAbilities,
  loadAbilitiesFailure,
  loadAbilitiesSuccess,
  loadAbility,
  loadAbilityFailure,
  loadAbilitySuccess,
  updateAbility,
  updateAbilityFailure,
  updateAbilitySuccess
} from '../actions/abilities.actions';

@Injectable()
export class AbilitiesEffects {
  loadAbilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAbilities),
      exhaustMap(() =>
        this.abilitiesService.getAbilities().pipe(
          map(abilities => loadAbilitiesSuccess({ abilities })),
          catchError(error => of(loadAbilitiesFailure({ error })))
        )
      )
    )
  );

  loadAbility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAbility),
      exhaustMap(action =>
        this.abilitiesService.getAbility(action.id).pipe(
          map(ability => loadAbilitySuccess({ ability })),
          catchError(error => of(loadAbilityFailure({ error })))
        )
      )
    )
  );

  createAbility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAbility),
      exhaustMap(action =>
        this.abilitiesService.createAbility(action.ability).pipe(
          map(ability => createAbilitySuccess({ ability })),
          catchError(error => of(createAbilityFailure({ error })))
        )
      )
    )
  );

  updateAbility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAbility),
      exhaustMap(action =>
        this.abilitiesService.updateAbility(action.ability).pipe(
          map(ability => updateAbilitySuccess({ ability })),
          catchError(error => of(updateAbilityFailure({ error })))
        )
      )
    )
  );

  deleteAbility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAbility),
      exhaustMap(action =>
        this.abilitiesService.deleteAbility(action.id).pipe(
          map(() => deleteAbilitySuccess()),
          catchError(error => of(deleteAbilityFailure({ error })))
        )
      )
    )
  );

  deleteAbilitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAbilitySuccess),
      map(() => loadAbilities())
    )
  );

  createOrUpdateAbilitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAbilitySuccess, updateAbilitySuccess),
      map(() => routerNavigate({ path: 'abilities' }))
    )
  );

  constructor(
    private actions$: Actions,
    private abilitiesService: AbilitiesService
  ) {}
}
