import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { routerForceNavigate, routerNavigate } from '@core/store/router/actions/router.actions';
import { SnackBarService } from '@shared/services/snack-bar-service/snack-bar.service';

import { CampaignProposalsService } from '../../services/campaign-proposals.service';
import {
  loadMerchantPortalBootstrap,
  loadCampaignProposal,
  loadCampaignProposalFailure,
  loadCampaignProposalSuccess,
  loadCampaignProposals,
  loadCampaignProposalsFailure,
  loadCampaignProposalsSuccess,
  loadMerchantPortalBootstrapFailure,
  loadMerchantPortalBootstrapSuccess,
  createCampaignProposal,
  createCampaignProposalFailure,
  createCampaignProposalSuccess,
  updateCampaignProposal,
  updateCampaignProposalFailure,
  updateCampaignProposalSuccess
} from '../actions/campaign-proposals.actions';

@Injectable()
export class CampaignProposalsEffects {
  loadMerchantPortalBootstrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMerchantPortalBootstrap),
      switchMap(() =>
        this.campaignProposalsService.getMerchantPortalBootstrap().pipe(
          map(merchantPortalBootstrap => loadMerchantPortalBootstrapSuccess({ merchantPortalBootstrap })),
          catchError(error => of(loadMerchantPortalBootstrapFailure({ error })))
        )
      )
    )
  );

  loadCampaignProposals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCampaignProposals),
      switchMap(() =>
        this.campaignProposalsService.getCampaignProposals().pipe(
          map(campaignProposals => loadCampaignProposalsSuccess({ campaignProposals })),
          catchError(error => of(loadCampaignProposalsFailure({ error })))
        )
      )
    )
  );

  loadCampaignProposal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCampaignProposal),
      switchMap(({ id }) =>
        this.campaignProposalsService.getCampaignProposal(id).pipe(
          map(campaignProposal => loadCampaignProposalSuccess({ campaignProposal })),
          catchError(error => of(loadCampaignProposalFailure({ error })))
        )
      )
    )
  );

  createCampaignProposal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCampaignProposal),
      switchMap(({ campaignProposal }) =>
        this.campaignProposalsService.createCampaignProposal(campaignProposal).pipe(
          map(result => createCampaignProposalSuccess(result)),
          catchError(error => of(createCampaignProposalFailure(error)))
        )
      )
    )
  );

  createCampaignProposalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCampaignProposalSuccess),
      map(() => routerForceNavigate({ path: 'promotions/proposals' }))
    )
  );

  updateCampaignProposal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCampaignProposal),
      switchMap(({ campaignProposal, redirect }) =>
        this.campaignProposalsService.updateCampaignProposal(campaignProposal).pipe(
          map(result => updateCampaignProposalSuccess({ campaignProposal: result, redirect })),
          catchError(error => of(updateCampaignProposalFailure({ error })))
        )
      )
    )
  );

  updateCampaignProposalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCampaignProposalSuccess),
      map(({ campaignProposal, redirect }) => {
        if (campaignProposal.interested) {
          this.snackBarService.openSuccessSnackBar({
            data: { innerHtml: 'You have successfully indicated your interest for the promotion proposal' }
          });
        }
        return redirect ? routerNavigate({ path: 'promotions/proposals' }) : { type: 'NO_ACTION' };
      })
    )
  );

  createOrUpdateCampaignProposalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCampaignProposalSuccess, updateCampaignProposalSuccess),
      map(() => loadMerchantPortalBootstrap())
    )
  );

  constructor(
    private actions$: Actions,
    private campaignProposalsService: CampaignProposalsService,
    private snackBarService: SnackBarService
  ) {}
}
