import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { Locale } from '../types';

@Injectable({
  providedIn: 'root'
})
export class LocalesService {
  constructor(private missionControlService: MissionControlService) {}

  getLocales(): Observable<Locale[]> {
    return this.missionControlService.get<Locale[]>('locales');
  }
}
