import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { GiftCardsService } from '../../services/gift-cards.service';
import {
  loadGiftCard,
  loadGiftCardFailure,
  loadGiftCards,
  loadGiftCardsFailure,
  loadGiftCardsSuccess,
  loadGiftCardSuccess,
  updateGiftCard,
  updateGiftCardFailure,
  updateGiftCardSuccess
} from '../actions/gift-cards.actions';

@Injectable()
export class GiftCardsEffects {
  loadGiftCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGiftCards),
      exhaustMap(action =>
        this.giftCardsService.getGiftCards(action.filter).pipe(
          map(result => loadGiftCardsSuccess({ result })),
          catchError(error => of(loadGiftCardsFailure({ error })))
        )
      )
    )
  );

  loadGiftCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGiftCard),
      exhaustMap(action =>
        this.giftCardsService.getGiftCard(action.id).pipe(
          map(giftCard => loadGiftCardSuccess({ giftCard })),
          catchError(error => of(loadGiftCardFailure({ error })))
        )
      )
    )
  );

  updateGiftCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGiftCard),
      exhaustMap(action =>
        this.giftCardsService.updateGiftCard(action.params).pipe(
          map(giftCard => updateGiftCardSuccess({ giftCard })),
          catchError(error => of(updateGiftCardFailure({ error })))
        )
      )
    )
  );

  updateGiftCardSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGiftCardSuccess),
      map(action => routerNavigate({ path: `/gift-cards/cards/${action.giftCard.id}` }))
    )
  );

  constructor(
    private actions$: Actions,
    private giftCardsService: GiftCardsService
  ) {}
}
