import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, mergeConfigAdapter, MergeConfigState } from '../../types/merge-configs.type';
import {
  createMergeConfig,
  createMergeConfigFailure,
  createMergeConfigSuccess,
  deleteMergeConfig,
  deleteMergeConfigFailure,
  deleteMergeConfigSuccess,
  loadMergeConfig,
  loadMergeConfigFailure,
  loadMergeConfigs,
  loadMergeConfigsFailure,
  loadMergeConfigsSuccess,
  loadMergeConfigSuccess
} from '../actions/merge-configs.actions';

export const mergeConfigTriggers: EntityTriggers = {
  single: {
    loading: [createMergeConfig.type, deleteMergeConfig.type, loadMergeConfig.type],
    resting: [createMergeConfigSuccess.type, deleteMergeConfigSuccess.type, loadMergeConfigSuccess.type],
    erroring: [createMergeConfigFailure.type, deleteMergeConfigFailure.type, loadMergeConfigFailure.type]
  },
  batch: {
    loading: [loadMergeConfigs.type],
    resting: [loadMergeConfigsSuccess.type],
    erroring: [loadMergeConfigsFailure.type]
  }
};

export function mergeConfigsReducer(state: MergeConfigState = initialState, action: Action): MergeConfigState {
  return { ...callStateReducer(baseReducer, mergeConfigTriggers)(state, action) };
}

const baseReducer = createReducer(
  initialState,
  on(loadMergeConfigs, (state, { filter }) => ({ ...state, filter })),
  on(loadMergeConfigsSuccess, (state, { result }) => ({
    ...mergeConfigAdapter.setAll(result.data, state),
    count: result?.metadata.total || 0
  })),
  on(loadMergeConfigSuccess, (state, { mergeConfig }) => mergeConfigAdapter.upsertOne(mergeConfig, state)),
  on(createMergeConfigSuccess, (state, { mergeConfig }) => ({
    ...mergeConfigAdapter.addOne(mergeConfig, state),
    count: state.count + 1
  })),
  on(deleteMergeConfigSuccess, (state, { id }) => ({
    ...mergeConfigAdapter.removeOne(id, state),
    count: state.count - 1
  }))
);
