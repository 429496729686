import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { DrawerRef } from '@shared/services/drawer/drawer-ref.type';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  selector: 'admin-drawer-content-header',
  template: `
    @if (headerTitle) {
      <div class="flex items-center gap-2 text-cloudburst-500">
        @if (headerIcon) {
          <mat-icon class="drawer-content-header-icon" [svgIcon]="headerIcon" />
        }
        <h2 class="drawer-content-header-text">{{ headerTitle }}</h2>
        <button class="drawer-content-header-close-button ml-auto" mat-icon-button (click)="closeDrawer()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    }
    <ng-content></ng-content>
  `,
  styles: [
    `
      h2.drawer-content-header-text {
        @apply m-0 text-xl font-bold;
      }

      .drawer-content-header-icon {
        @apply h-5 w-5;
      }

      .drawer-content-header-close-button.mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 24px;
        --mat-icon-button-touch-target-display: none;
        @apply p-0;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerContentHeaderComponent {
  @HostBinding('class.admin-drawer-content-header') hostClass = true;

  @HostBinding('class') class = 'flex-shrink-0 p-8';

  @Input() headerTitle?: string;

  @Input() headerIcon?: string;

  @Output() closeButtonClicked = new EventEmitter<void>();

  // There may be a chance that we don't use in a drawer
  // that isn't being opened by DrawerService so make it optional
  // to be safe.
  // E.g: LogicRuleAttributeOptionsDrawerComponent
  private drawerRef? = inject(DrawerRef, {
    optional: true
  });

  closeDrawer(): void {
    this.closeButtonClicked.emit();
    this.drawerRef?.close();
  }
}
