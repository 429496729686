<div class="details-view">
  <div class="section-header">General</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Order ID">{{ orderItem.order.id }}</admin-item>
    <admin-item label="Item ID">{{ orderItem.id }}</admin-item>
    <admin-item label="Product type">{{ orderItem.type | orderItemType }}</admin-item>
    <admin-item label="Redemption type">{{
      (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A'
    }}</admin-item>
    <admin-item label="Points Redeemed">{{ orderItem.pointsAmount }}</admin-item>

    <div class="cross-two-column unpadded-subsection-header">Customer details</div>
    <admin-item label="Customer ID">{{ customerId }}</admin-item>
    <admin-item label="Ascenda ID" [copyButtonValue]="orderItem.userId">
      <a
        *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
        [routerLink]="'/customers/' + orderItem.userId + detailsView"
      >
        {{ orderItem.userId }}
      </a>
      <ng-template #userIdWithoutLink>
        {{ orderItem.userId }}
      </ng-template>
    </admin-item>
  </div>

  <div class="section-header">Transaction Details</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Location ID">{{ data.locationId || 'N/A' }}</admin-item>
    <admin-item label="Location Name">{{ data.locationName || 'N/A' }}</admin-item>
    <admin-item label="Merchant Code">{{ data.merchantCode || 'N/A' }}</admin-item>
    <admin-item label="Merchant Name">{{ data.merchantName || 'N/A' }}</admin-item>
    <admin-item label="Promotion Vertical">{{ data.promotionVertical || 'N/A' }}</admin-item>
  </div>

  <div class="section-header">Stripe reference</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Stripe reference">{{ stripeReferenceData.stripeReference }}</admin-item>
    <admin-item label="Total charge">{{ stripeReferenceData.totalCharge }}</admin-item>
  </div>
</div>
