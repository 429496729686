import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { McTenant, McTenantResponse, McTenantsResponse } from '../../types/mc-tenants.type';

export const loadMcTenants = createAction('[MC Tenants] Load MC Tenants');
export const loadMcTenantsSuccess = createAction(
  '[MC Tenants] Load MC Tenants Success',
  props<{ response: McTenantsResponse }>()
);
export const loadMcTenantsFailure = createAction('[MC Tenants] Load MC Tenants Failure', props<ErrorState>());

export const loadMcTenant = createAction('[MC Tenants] Load MC Tenant', props<{ id: string }>());
export const loadMcTenantSuccess = createAction(
  '[MC Tenants] Load MC Tenant Success',
  props<{ response: McTenantResponse }>()
);
export const loadMcTenantFailure = createAction('[MC Tenants] Load MC Tenant Failure', props<ErrorState>());

export const createMcTenant = createAction('[MC Tenants] Create MC Tenants', props<{ mcTenant: McTenant }>());
export const createMcTenantSuccess = createAction(
  '[MC Tenants] Create MC Tenant Success',
  props<{ mcTenant: McTenant }>()
);
export const createMcTenantFailure = createAction('[MC Tenants] Create MC Tenant Failure', props<ErrorState>());

export const updateMcTenant = createAction('[MC Tenants] Update MC Tenant', props<{ mcTenant: McTenant }>());
export const updateMcTenantSuccess = createAction(
  '[MC Tenants] Update MC Tenant Success',
  props<{ mcTenant: McTenant }>()
);
export const updateMcTenantFailure = createAction('[MC Tenants] Update MC Tenant Failure', props<ErrorState>());

export const clearMcTenantBootstrapCache = createAction('[MC Tenants] Clear MC Tenant Bootstrap Cache');
export const clearMcTenantBootstrapCacheSuccess = createAction('[MC Tenants] Clear MC Tenant Bootstrap Cache Success');
export const clearMcTenantBootstrapCacheFailure = createAction(
  '[MC Tenants] Clear MC Tenant Bootstrap Cache Failure',
  props<ErrorState>()
);
