import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { HttpError, SCOPES_GROUP, SCOPES_OR, SCOPES_OR_GENERATED, SubTab, Tab } from '@core/types';
import { FeatureAvailabilityService } from '@shared/services/feature-availability/feature-availability.service';
import { AccessPolicyUtils, ComponentState, getRenderState$, Params } from '@utils';

import { UiConfigs } from '../../../../app-module-config';
import { usersQuery } from '../../store/selectors/users.selectors';
import { UserState } from '../../types';

@Component({
  selector: 'admin-user-menu',
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent implements OnInit {
  previousPageLabel: string;
  error$: Observable<HttpError>;
  renderState$: Observable<ComponentState>;
  userId: string = Params.find(this.route, 'userId');
  backButtonScopes: string[];
  readonly SCOPES = SCOPES_OR;

  isV2style = this.uiConfigsSchema.users?.menu === 'v2' ?? false;

  auditLogsTab: SubTab = {
    path: 'logs',
    icon: 'list-in-a-box-round',
    label: 'Audit logs',
    displayed: this.scopes.hasAny(SCOPES_OR.viewAuditLogs)
  };

  v2Tabs: Tab[] = [
    {
      path: 'details',
      icon: 'user',
      label: 'User Information',
      displayed: this.scopes.hasAny(SCOPES_OR.showUsers) || this.isPersonalRoute()
    },
    {
      icon: 'bar-graph',
      label: 'Activity',
      subTabs: [
        {
          path: 'dashboard',
          icon: 'stock-chart',
          label: 'Points Activity',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('pointsActivity') &&
            this.scopes.hasScopes(SCOPES_GROUP.viewDashboardPage, 'any-strict-groups') &&
            (this.route.snapshot.data.isCustomerView || this.showAgentDashboard)
        },
        {
          path: 'orders-items',
          icon: 'shopping-cart-01',
          label: 'Orders',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('orders') && this.scopes.hasAny(SCOPES_OR.viewOrderItems)
        },
        {
          path: 'user-offer-activities',
          icon: 'stock-chart',
          label: 'Offer Activity',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('offers') && this.scopes.has('view_user_offer_activities')
        },
        {
          path: 'offer-eligibility-tracker',
          icon: 'dollar-coin',
          label: 'Offer Eligibility Tracker',
          displayed: this.scopes.has('view_user_transactions')
        },
        {
          path: 'auto-redemptions',
          icon: 'alarm-settings',
          label: 'Auto-redemption',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('autoRedemptions') &&
            this.scopes.hasScopes(SCOPES_GROUP.viewUserAutoRedemptionSettingsPage, 'all-loose-groups')
        }
      ]
    },
    {
      icon: 'folder',
      label: 'Accounts',
      subTabs: [
        {
          path: 'enrollments',
          icon: 'wallet',
          label: 'Financial Products',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('userProducts') &&
            this.scopes.hasAny(SCOPES_OR.viewUserEnrollments)
        },
        {
          path: 'memberships',
          icon: 'star',
          label: 'Memberships / Benefits',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('memberships') &&
            this.scopes.hasAny(SCOPES_OR_GENERATED.viewMembershipsMenu)
        },
        {
          path: 'reward-triggers',
          icon: 'star',
          label: 'Preferred Reward',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('rewardTriggers') &&
            this.scopes.has('view_reward_triggers')
        }
      ]
    },
    {
      icon: 'headset',
      label: 'Support',
      subTabs: [
        {
          path: 'notes',
          icon: 'notepad',
          label: 'Notes',
          displayed: this.scopes.hasAny(SCOPES_OR.viewUserNotes)
        }
      ]
    },
    {
      icon: 'dashboard',
      label: 'Marketing',
      subTabs: [
        {
          path: 'notifications',
          icon: 'alert-ringing',
          label: 'Notifications',
          displayed: this.scopes.hasAny(SCOPES_OR.viewNotifications)
        },
        {
          path: 'campaigns',
          icon: 'target-02',
          label: 'Campaigns',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('campaigns') &&
            this.scopes.hasAny(SCOPES_OR.viewUserCampaigns)
        },
        {
          path: 'offers',
          icon: 'tag-with-heart',
          label: 'Offers',
          displayed:
            this.featureAvailabilityService.isFeatureAllowed('offers') && this.scopes.hasAny(SCOPES_OR.viewUserOffers)
        }
      ]
    },
    {
      icon: 'laptop-locked',
      label: 'Security & Logs',
      subTabs: [
        {
          ...this.auditLogsTab,
          label: 'Audit Logs' // to remove and update in menu component once AP user menu completely switch to v2
        },
        {
          path: 'events',
          icon: 'target-02',
          label: 'Audit Events',
          displayed: this.scopes.hasAny(SCOPES_OR.viewEvents)
        },
        {
          path: 'login-attempts',
          icon: 'message-dots-right',
          label: 'Login Attempts',
          displayed: this.scopes.hasAny(SCOPES_OR.viewLoginAttempts)
        },
        {
          path: 'tokens',
          icon: 'user-pass',
          label: 'User Tokens',
          displayed: this.scopes.hasAny(SCOPES_OR.viewUserTokens)
        },
        {
          path: 'passwords',
          icon: 'lock',
          label: 'Passwords',
          displayed: this.scopes.hasAny(SCOPES_OR.viewPasswords)
        },
        {
          path: 'fraud-evaluations',
          icon: 'lock',
          label: 'Fraud Evaluations',
          displayed: this.scopes.has('view_fraud_evaluations')
        }
      ]
    }
  ];

  v1Tabs: Tab[] = [
    {
      path: 'dashboard',
      icon: 'insights',
      label: 'Insights',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('pointsActivity') &&
        this.scopes.hasScopes(SCOPES_GROUP.viewDashboardPage, 'any-strict-groups') &&
        (this.route.snapshot.data.isCustomerView || this.showAgentDashboard)
    },
    {
      path: 'details',
      icon: 'person',
      label: 'User info',
      displayed: this.scopes.hasAny(SCOPES_OR.showUsers) || this.isPersonalRoute()
    },
    {
      path: 'enrollments',
      icon: 'source',
      label: 'Products',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('userProducts') &&
        this.scopes.hasAny(SCOPES_OR.viewUserEnrollments)
    },
    {
      path: 'orders-items',
      icon: 'local_mall',
      label: 'Orders',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('orders') && this.scopes.hasAny(SCOPES_OR.viewOrderItems)
    },
    {
      path: 'auto-redemptions',
      icon: 'card_membership',
      label: 'Auto-redemption settings',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('autoRedemptions') &&
        this.scopes.hasScopes(SCOPES_GROUP.viewUserAutoRedemptionSettingsPage, 'all-loose-groups')
    },
    {
      path: 'memberships',
      icon: 'badge',
      label: 'Memberships/Benefits',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('memberships') &&
        this.scopes.hasAny(SCOPES_OR_GENERATED.viewMembershipsMenu)
    },
    {
      path: 'reward-triggers',
      icon: 'star',
      label: 'Preferred Reward',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('rewardTriggers') && this.scopes.has('view_reward_triggers')
    },
    {
      path: 'notes',
      icon: 'note',
      label: 'Notes',
      displayed: this.scopes.hasAny(SCOPES_OR.viewUserNotes)
    },
    {
      path: 'campaigns',
      icon: 'campaign',
      label: 'Campaigns',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('campaigns') && this.scopes.hasAny(SCOPES_OR.viewUserCampaigns)
    },
    {
      path: 'notifications',
      icon: 'notifications',
      label: 'Notifications',
      displayed: this.scopes.hasAny(SCOPES_OR.viewNotifications)
    },
    {
      path: 'offers',
      icon: 'local_offer',
      label: 'Offers',
      displayed:
        this.featureAvailabilityService.isFeatureAllowed('offers') && this.scopes.hasAny(SCOPES_OR.viewUserOffers)
    },
    {
      path: 'events',
      icon: 'history',
      label: 'Audit Events',
      displayed: this.scopes.hasAny(SCOPES_OR.viewEvents)
    },
    this.auditLogsTab,
    {
      path: 'tokens',
      icon: 'fingerprint',
      label: 'User tokens',
      displayed: this.scopes.hasAny(SCOPES_OR.viewUserTokens)
    },
    {
      path: 'login-attempts',
      icon: 'history_toggle_off',
      label: 'Login Activity',
      displayed: this.scopes.hasAny(SCOPES_OR.viewLoginAttempts)
    },
    {
      path: 'passwords',
      icon: 'key',
      label: 'Passwords',
      displayed: this.scopes.hasAny(SCOPES_OR.viewPasswords)
    },
    {
      path: 'fraud-evaluations',
      icon: 'lock',
      label: 'Fraud Evaluations',
      displayed: this.scopes.has('view_fraud_evaluations')
    }
  ];

  tabs: Tab[] = this.isV2style ? this.v2Tabs : this.v1Tabs;

  errorText: string;
  isCustomerView: boolean;
  hideBackButton: boolean;

  constructor(
    private store: Store<UserState>,
    public route: ActivatedRoute,
    protected router: Router,
    public scopes: Scopes,
    private authStore: Store<AuthState>,
    private accessPolicies: AccessPolicies,
    private featureAvailabilityService: FeatureAvailabilityService,
    @Inject('showAgentDashboard') private showAgentDashboard: string,
    @Inject('showRewardsIdSelection') private showRewardsIdSelection: boolean,
    @Inject('uiConfigs') private uiConfigsSchema: UiConfigs
  ) {}

  ngOnInit(): void {
    const loading$ = this.store.select(usersQuery.isSingleLoading);
    this.error$ = this.store.select(usersQuery.getSingleError);
    this.renderState$ = getRenderState$([loading$, this.error$]);
    this.isCustomerView = this.route.snapshot.data.isCustomerView;
    this.backButtonScopes = this.isCustomerView ? SCOPES_OR.viewCustomers : SCOPES_OR.viewAgents;
    this.errorText = this.isCustomerView ? 'Loading customer failed' : 'Loading agent failed';

    const { isBothUsersPageInvisible, isTargetPageInvisible } = AccessPolicyUtils;
    const frontendSettings = this.accessPolicies.getFrontendSettings('sidenav', 'show');
    const previousPath = this.route.parent.snapshot.url[0].path;

    // display 'customers' for enrollments page if both agents and customers page are invisible
    const shouldDisplayCustomers = isBothUsersPageInvisible(frontendSettings);
    this.previousPageLabel = shouldDisplayCustomers ? 'customers' : previousPath;

    // hide back button if previousPageLabel is agents and agentView is hidden by access policy
    this.hideBackButton = previousPath === 'agents' && isTargetPageInvisible(frontendSettings, 'agents');

    // only show user info tab if previous path is agents and agentView is hidden by access policy
    if (this.hideBackButton) {
      this.tabs = this.tabs.filter(tab => tab.path === 'details');
    }
  }

  isPersonalRoute(): boolean {
    let isPersonalRoute = false;

    this.authStore.select(authQuery.getUser).subscribe(currentUser => {
      isPersonalRoute = currentUser.sub === this.userId;
    });

    return isPersonalRoute;
  }
}
