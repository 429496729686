import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { SingleEntityTriggers } from '@core/types';

import { initialState, SentinelConfigState } from '../../types/sentinel-configs.type';
import {
  loadSentinelConfig,
  loadSentinelConfigFailure,
  loadSentinelConfigSuccess
} from '../actions/sentinel-configs.actions';

export const sentinelConfigTriggers: Required<SingleEntityTriggers> = {
  single: {
    loading: [loadSentinelConfig.type],
    resting: [loadSentinelConfigSuccess.type],
    erroring: [loadSentinelConfigFailure.type]
  }
};

export function sentinelConfigsReducer(state: SentinelConfigState = initialState, action: Action): SentinelConfigState {
  return callStateReducer(baseReducer, sentinelConfigTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadSentinelConfigSuccess, (state, { sentinelConfig, appId }) => ({ ...state, sentinelConfig, appId }))
);
