import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LatLngData, LatLonData } from '../../types/login-attempts.type';

interface DialogData {
  locations: LatLonData[];
}

interface LocationBound {
  neLat: number;
  neLng: number;
  swLat: number;
  swLng: number;
}

@Component({
  selector: 'admin-location-heatmap-dialog',
  templateUrl: './location-heatmap-dialog.component.html',
  styleUrls: ['./location-heatmap-dialog.component.scss']
})
export class LocationHeatmapDialogComponent implements OnInit {
  map: google.maps.Map;
  heatmap: google.maps.visualization.HeatmapLayer;
  currentZoom = 18;
  heatmapReady = false;

  constructor(
    public dialogRef: MatDialogRef<LocationHeatmapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    const locations: LatLngData[] = this.data.locations.map(({ lat, lon }) => ({ lat, lng: lon }));
    this.initHeatmap(locations);
  }

  initHeatmap(locationData: LatLngData[]): void {
    this.map = new google.maps.Map(document.getElementById('heatmap') as HTMLElement, {
      zoom: this.currentZoom,
      center: { ...this.getCenter(locationData) },
      mapTypeId: 'satellite'
    });

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: locationData.map(({ lat, lng }) => new google.maps.LatLng(lat, lng))
    });

    this.heatmap.setMap(this.map);

    google.maps.event.addListener(this.map, 'bounds_changed', this.adjustZoomLevel(locationData));
  }

  getCenter(locationData: LatLngData[]): LatLngData {
    const length = locationData.length;
    return locationData.reduce(
      (acc, location, index) =>
        index === length - 1
          ? { lat: (acc.lat + location.lat) / length, lng: (acc.lng + location.lng) / length }
          : { lat: acc.lat + location.lat, lng: acc.lng + location.lng },
      { lat: 0, lng: 0 }
    );
  }

  getDataBounds(locationData: LatLngData[]): LocationBound {
    const lat = locationData.map(location => location.lat).sort((a, b) => a - b);
    const lng = locationData.map(location => location.lng).sort((a, b) => a - b);

    return {
      neLat: lat.at(-1),
      neLng: lng.at(-1),
      swLat: lat[0],
      swLng: lng[0]
    };
  }

  adjustZoomLevel(locationData: LatLngData[]): Function {
    const { neLat, neLng, swLat, swLng } = this.getDataBounds(locationData);

    return () => {
      if (this.heatmapReady) {
        return;
      }

      const bounds = this.map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      // check if any data point is out of current bound
      const shouldZoomOut = neLat - swLat > ne.lat() - sw.lat() || neLng - swLng > ne.lng() - sw.lng();

      // if every data point is in view
      // 1. set heatmapReady true
      // 2. remove listener
      if (!shouldZoomOut) {
        this.heatmapReady = true;
        google.maps.event.clearListeners(this.map, 'bounds_changed');
      }

      // zoom out one level
      this.currentZoom--;
      this.map.setZoom(this.currentZoom);
    };
  }
}
