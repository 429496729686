import { debounceTime, filter } from 'rxjs/operators';

import { trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';

import { NavService } from '@core/services/nav/nav.service';
import { ChildNavItem, NavItem } from '@core/types';
import { expansionAnimation } from '@shared/animations/expansion-animations';
import { HostTenantMappingUtils } from '@utils';

@Component({
  selector: 'admin-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [trigger('detailExpand', expansionAnimation)]
})
export class MenuListItemComponent implements AfterViewInit {
  @Input() item: NavItem | ChildNavItem;
  @Input() depth: number;
  @Input() expandedItem: NavItem | ChildNavItem;
  @Output() newExpandedItem = new EventEmitter<NavItem | ChildNavItem>();

  destroyRef = inject(DestroyRef);

  isRc = HostTenantMappingUtils.isRC();

  constructor(
    private router: Router,
    private location: Location,
    public navService: NavService
  ) {}

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof NavigationEnd), // just pass NavigationEnd event
        debounceTime(500)
      )
      .subscribe(event => {
        if (this.isActive(this.item)) {
          this.newExpandedItem.emit(this.item);
        } else if ((event as NavigationEnd).url === '/') {
          this.newExpandedItem.emit(null);
        }
      });
  }

  get icon(): string | undefined {
    return Object.hasOwn(this.item, 'iconName') ? (this.item as NavItem).iconName : undefined;
  }

  async onItemSelected(inputItem: NavItem): Promise<void> {
    // Case 1: Item has children, decide whether to expand or close the item
    const isSelectedItemExpanded = this.expandedItem === inputItem;

    if (inputItem.children?.length > 0) {
      if (this.navService.isMini && !isSelectedItemExpanded) {
        this.navService.toggleIsMini();
      }
      this.newExpandedItem.emit(isSelectedItemExpanded ? null : inputItem);
      return;
    }

    // Case 2: Item does not have children, should close any expanded items & route if not already active
    if (this.isActive(inputItem)) {
      this.newExpandedItem.emit(null);
    } else {
      if (!this.expandedItem?.children?.includes(inputItem)) {
        this.newExpandedItem.emit(null);
      }
      await this.router.navigateByUrl(inputItem.route);
    }
  }

  isActive(inputItem: NavItem): boolean {
    // if item has children, check if any child is active; otherwise, check item itself
    const { children, route, rootRoutes } = inputItem;

    if (children?.length > 0) {
      return !!children.find(child => this.isActive(child));
    }
    if (route === '' && this.location.path() === '') {
      return true;
    }
    if (route && this.router.isActive(route, false)) {
      return true;
    }
    if (rootRoutes?.length > 0) {
      return rootRoutes.some(rootRoute => this.router.isActive(rootRoute, false));
    }
  }
}
