export const SUPPORTED_GOOGLE_FONTS = [
  'Poppins',
  'DM Sans',
  'Montserrat',
  'Mulish',
  'Nunito',
  'Open Sans',
  'Crimson Text',
  'Inter',
  'Lato',
  'Lexend',
  'Libre Baskerville',
  'Rubik',
  'Quicksand',
  'Roboto',
  'Raleway',
  'Noto Sans',
  'Merriweather'
];
