export interface StickyColumnConfig {
  isSticky: boolean;
  isStickyEnd: boolean;
  headerClass: string[];
  cellClass: string[];
}

export class StickyTableUtils {
  constructor(
    private readonly stickyStartColumns: string[],
    private readonly scrollableColumns: string[],
    private readonly stickyEndColumns: string[]
  ) {}

  isStickyStart(column: string): boolean {
    return this.stickyStartColumns.includes(column);
  }

  isStickyEnd(column: string): boolean {
    return this.stickyEndColumns.includes(column);
  }

  getDisplayedColumns(): string[] {
    return [...this.stickyStartColumns, ...this.scrollableColumns, ...this.stickyEndColumns];
  }

  getStickyColumnConfig(): Record<string, StickyColumnConfig> {
    return Object.fromEntries(
      this.getDisplayedColumns().map(column => [
        column,
        {
          isSticky: this.isStickyStart(column),
          isStickyEnd: this.isStickyEnd(column),
          headerClass: this.getStickyHeaderClass(column),
          cellClass: this.getStickyCellClass(column)
        }
      ])
    );
  }

  getStickyCellClass(column: string): string[] {
    const classes: string[] = [];

    if (this.isStickyStart(column)) {
      classes.push('sticky-column-start');

      if (this.stickyStartColumns.at(-1) === column) {
        classes.push('sticky-column-start-border');
      }
    }

    if (this.isStickyEnd(column)) {
      classes.push('sticky-column-end');

      if (this.stickyEndColumns.at(0) === column) {
        classes.push('sticky-column-end-border');
      }
    }

    return classes;
  }

  getStickyHeaderClass(column: string): string[] {
    const classes: string[] = [];

    if (this.stickyStartColumns.at(-1) === column) {
      classes.push('sticky-header-start-border');
    }

    if (this.stickyEndColumns.at(0) === column) {
      classes.push('sticky-header-end-border');
    }

    return classes;
  }
}
