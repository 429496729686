import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { WebhookEventsFilter, WebhookEventsFilterWithPagination } from './webhook-events-filter.type';
import { WebhookEventsPagination, defaultWebhookEventsPagination } from './webhook-events-result.type';

export type Tab = 'events' | 'settings';

export interface WebhookEvent {
  id: string;
  type: string;
  payload: WebhookEventPayload;
  notifiableType: string;
  notifiableId: string;
  tenantId: string;
  sourceType: string;
  eventName: string;
  createdAt: string;
  updatedAt: string;
  latestOutgoingRequestAttempt: WebhookEventAttempt;
}

interface WebhookEventPayload {
  createdAt: string;
  data: object;
  eventId: string;
  eventType: string;
  sourceType: string;
}

interface WebhookEventAttempt {
  id: string;
  response: object;
  status: string;
  outgoingRequestSettingId: string;
  createdAt: string;
  updatedAt: string;
}

export const webhookEventStatusDisplayClass = {
  successful: 'status-active',
  failed: 'status-neutral',
  pending: 'status-pending'
};

export const webhookEventAdapter = createEntityAdapter<WebhookEvent>({
  selectId: (webhookEvent: WebhookEvent): string => webhookEvent.id
});

export interface WebhookEventState extends EntityState<WebhookEvent>, CallState {
  filter: WebhookEventsFilter;
  count: number;
}
export interface WebhookEventWithPaginationState extends EntityState<WebhookEvent>, CallState {
  filter: WebhookEventsFilterWithPagination;
  count: number;
  pagination: WebhookEventsPagination;
}

export const defaultWebhookEventsFilter: WebhookEventsFilter = {
  page: 1,
  limit: 20,
  sortBy: 'createdAt',
  sortDirection: 'desc'
};

export const defaultWebhookEventsFilterWithPagination: WebhookEventsFilterWithPagination = {
  page: { size: 20 },
  sortBy: 'createdAt',
  sortDirection: 'desc'
};

export const webhookEventInitialState: WebhookEventState = {
  ...webhookEventAdapter.getInitialState<CallState>(initialCallState),
  filter: defaultWebhookEventsFilter,
  count: 0
};

export const webhookEventWithPaginationInitialState: WebhookEventWithPaginationState = {
  ...webhookEventAdapter.getInitialState<CallState>(initialCallState),
  filter: defaultWebhookEventsFilterWithPagination,
  count: 0,
  pagination: defaultWebhookEventsPagination
};
