import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { LoyaltyProgramCatalog } from '../../types/loyalty-program-catalogs.type';

export const loadLoyaltyProgramCatalogs = createAction('[Loyalty Program Catalogs] Load Loyalty Program Catalogs');
export const loadLoyaltyProgramCatalogsSuccess = createAction(
  '[Loyalty Program Catalogs] Load Loyalty Program Catalogs Success',
  props<{ loyaltyProgramCatalogs: LoyaltyProgramCatalog[] }>()
);
export const loadLoyaltyProgramCatalogsFailure = createAction(
  '[Loyalty Program Catalogs] Load Loyalty Program Catalogs Failure',
  props<ErrorState>()
);
