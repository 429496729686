import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { PointsActivitiesFilter, PointsActivitiesResult, PointsActivity } from '../../types';

export const loadPointsActivities = createAction(
  '[Dashboard] Load Points Activities',
  props<{ userId: string; filter: PointsActivitiesFilter }>()
);
export const loadPointsActivitiesSuccess = createAction(
  '[Dashboard] Load Points Activities Success',
  props<{ result: PointsActivitiesResult; userId: string }>()
);
export const loadPointsActivitiesFailure = createAction(
  '[Dashboard] Load Points Activities Failure',
  props<ErrorState>()
);

export const loadPointsActivity = createAction(
  '[Dashboard] Load Points Activity',
  props<{ userId: string; pointsActivityId: string }>()
);
export const loadPointsActivitySuccess = createAction(
  '[Dashboard] Load Points Activity Success',
  props<{ pointsActivity: PointsActivity }>()
);
export const loadPointsActivityFailure = createAction('[Dashboard] Load Points Activity Failure', props<ErrorState>());

export const setPointsActivitiesLocale = createAction(
  '[Dashboard] Set Points Activities Locale',
  props<{ locale: string }>()
);
