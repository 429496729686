import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { accessPolicyAdapter, AccessPolicyState, initialState } from '../../types/access-policies.type';
import {
  createAccessPolicy,
  createAccessPolicyFailure,
  createAccessPolicySuccess,
  deleteAccessPolicy,
  deleteAccessPolicyFailure,
  deleteAccessPolicySuccess,
  loadAccessPolicies,
  loadAccessPoliciesFailure,
  loadAccessPoliciesSuccess,
  loadAccessPolicy,
  loadAccessPolicyFailure,
  loadAccessPolicySuccess,
  updateAccessPolicy,
  updateAccessPolicyFailure,
  updateAccessPolicySuccess
} from '../actions/access-policies.actions';

export const accessPolicyTriggers: EntityTriggers = {
  single: {
    loading: [loadAccessPolicy.type, createAccessPolicy.type, updateAccessPolicy.type, deleteAccessPolicy.type],
    resting: [
      loadAccessPolicySuccess.type,
      createAccessPolicySuccess.type,
      updateAccessPolicySuccess.type,
      deleteAccessPolicySuccess.type
    ],
    erroring: [
      loadAccessPolicyFailure.type,
      createAccessPolicyFailure.type,
      updateAccessPolicyFailure.type,
      deleteAccessPolicyFailure.type
    ]
  },
  batch: {
    loading: [loadAccessPolicies.type],
    resting: [loadAccessPoliciesSuccess.type],
    erroring: [loadAccessPoliciesFailure.type]
  }
};

export function accessPoliciesReducer(state: AccessPolicyState = initialState, action: Action): AccessPolicyState {
  return callStateReducer(baseReducer, accessPolicyTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadAccessPolicySuccess, (state, { accessPolicy }) => accessPolicyAdapter.upsertOne(accessPolicy, state)),
  on(loadAccessPoliciesSuccess, (state, { accessPolicies }) => accessPolicyAdapter.setAll(accessPolicies, state)),
  on(createAccessPolicySuccess, (state, { accessPolicy }) => accessPolicyAdapter.addOne(accessPolicy, state)),
  on(updateAccessPolicySuccess, (state, { accessPolicy }) => accessPolicyAdapter.setOne(accessPolicy, state))
);
