import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { AuthenticationProvidersService } from '../../../authentication-providers/services/authentication-providers.service';
import { fetchAuthenticationProvider } from '../../../authentication-providers/store/actions/authentication-providers.actions';
import { authenticationProvidersQuery } from '../../../authentication-providers/store/selectors/authentication-providers.selectors';
import {
  AuthenticationProvider,
  AuthenticationProviderState
} from '../../../authentication-providers/types/authentication-providers.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class AuthenticationProviderTagDefs {
  readonly tagDefs: TagDefs<AuthenticationProvider> = {
    authentication_provider_reference: {
      fetchData: (reference: string) =>
        this.scopes.hasAny(SCOPES_OR.showAuthenticationProviders)
          ? combineLatest([
              this.authenticationProviderStore.select(
                authenticationProvidersQuery.getAuthenticationProviderByReference(reference)
              ),
              this.authenticationProviderStore.select(authenticationProvidersQuery.getFetchList)
            ]).pipe(
              first(),
              map(([authenticationProvider, fetchList]) => {
                if (!authenticationProvider && !fetchList.includes(reference)) {
                  this.authenticationProviderStore.dispatch(fetchAuthenticationProvider({ reference }));
                }
              })
            )
          : of(null),
      select: reference =>
        this.authenticationProviderStore.pipe(
          select(authenticationProvidersQuery.getAuthenticationProviderByReference(reference))
        ),
      fetch: reference =>
        this.scopes.hasAny(SCOPES_OR.showAuthenticationProviders)
          ? this.authenticationProviderStore.pipe(
              select(authenticationProvidersQuery.getAuthenticationProviderByReference(reference)),
              first(),
              exhaustMap(authenticationProvider =>
                authenticationProvider
                  ? of(authenticationProvider)
                  : this.authenticationProvidersService.getAuthenticationProvider(reference)
              )
            )
          : of(null),
      getDisplayPrefix: () => 'Authentication Provider',
      getDisplayValue: (authenticationProvider: AuthenticationProvider) =>
        `${authenticationProvider.tenantId} - ${authenticationProvider.providerId}`,
      getRouteLink: (authenticationProvider: AuthenticationProvider) =>
        `/authentication-providers/${authenticationProvider.reference}`
    }
  };

  constructor(
    private authenticationProviderStore: Store<AuthenticationProviderState>,
    private authenticationProvidersService: AuthenticationProvidersService,
    private scopes: Scopes
  ) {}
}
