import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { BatchCreateDeletePermissionsParams, Role, RoleGeneral } from '../../types';

export const loadRoles = createAction('[Roles] Load Roles');
export const loadRolesSuccess = createAction('[Roles] Load Roles Success', props<{ roles: Role[] }>());
export const loadRolesFailure = createAction('[Roles] Load Roles Failure', props<ErrorState>());

export const loadRole = createAction('[Roles] Load Role', props<{ name: string }>());
export const loadRoleSuccess = createAction('[Roles] Load Role Success', props<{ role: Role }>());
export const loadRoleFailure = createAction('[Roles] Load Role Failure', props<ErrorState>());

export const createRole = createAction('[Roles] Create Role', props<{ role: Role }>());
export const createRoleSuccess = createAction('[Roles] Create Role Success', props<{ role: Role }>());
export const createRoleFailure = createAction('[Roles] Create Role Failure', props<ErrorState>());

export const updateRolePermissions = createAction(
  '[Roles] Update Role and Create/Delete Permissions',
  props<{ role: RoleGeneral; permissions: BatchCreateDeletePermissionsParams }>()
);
export const updateRolePermissionsSuccess = createAction(
  '[Roles] Update Role and Create/Delete Permissions Success',
  props<{ role: Role }>()
);
export const updateRolePermissionsFailure = createAction(
  '[Roles] Update Role and Create/Delete Permissions Failure',
  props<ErrorState>()
);

export const batchCreateDeletePermissions = createAction(
  '[Permissions] Batch Create/Delete Permissions',
  props<{ permissions: BatchCreateDeletePermissionsParams }>()
);
export const batchCreateDeletePermissionsSuccess = createAction(
  '[Permissions] Batch Create/Delete Permissions Success'
);
export const batchCreateDeletePermissionsFailure = createAction(
  '[Permissions] Batch Create/Delete Permissions Failure',
  props<ErrorState>()
);
