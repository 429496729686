import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { FraudRulesService } from '../../services/fraud-rules.service';
import {
  createFraudRule,
  createFraudRuleFailure,
  createFraudRuleSuccess,
  fetchFraudRule,
  fetchFraudRuleFailure,
  fetchFraudRuleSuccess,
  loadFraudRule,
  loadFraudRuleFailure,
  loadFraudRules,
  loadFraudRulesFailure,
  loadFraudRulesSuccess,
  loadFraudRuleSuccess,
  updateFraudRule,
  updateFraudRuleFailure,
  updateFraudRuleSuccess
} from '../actions/fraud-rules.actions';

@Injectable()
export class FraudRulesEffects {
  loadFraudRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFraudRules),
      exhaustMap(() =>
        this.fraudRulesService.getFraudRules().pipe(
          map(fraudRules => loadFraudRulesSuccess({ fraudRules })),
          catchError(error => of(loadFraudRulesFailure({ error })))
        )
      )
    )
  );

  loadFraudRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFraudRule),
      exhaustMap(action =>
        this.fraudRulesService.getFraudRule(action.id).pipe(
          map(fraudRule => loadFraudRuleSuccess({ fraudRule })),
          catchError(error => of(loadFraudRuleFailure({ error })))
        )
      )
    )
  );

  createFraudRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFraudRule),
      exhaustMap(action =>
        this.fraudRulesService.createFraudRule(action.fraudRule).pipe(
          map(fraudRule => createFraudRuleSuccess({ fraudRule })),
          catchError(error => of(createFraudRuleFailure({ error })))
        )
      )
    )
  );

  updateFraudRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFraudRule),
      exhaustMap(action =>
        this.fraudRulesService.updateFraudRule(action.fraudRule).pipe(
          map(fraudRule => updateFraudRuleSuccess({ fraudRule })),
          catchError(error => of(updateFraudRuleFailure({ error })))
        )
      )
    )
  );

  createOrUpdateFraudRuleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFraudRuleSuccess, updateFraudRuleSuccess),
      map(({ fraudRule }) => routerForceNavigate({ path: `fraud-rules/${fraudRule.id}` }))
    )
  );

  fetchFraudRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFraudRule),
      mergeMap(action =>
        this.fraudRulesService.getFraudRule(action.id).pipe(
          map(fraudRule => fetchFraudRuleSuccess({ fraudRule })),
          catchError(() => of(fetchFraudRuleFailure({ id: action.id })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private fraudRulesService: FraudRulesService
  ) {}
}
