import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';

import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { HttpError } from '@core/types';

import { transformAttributeResponses } from '../../../../utils/logic-rule.utils';
import { UsersService } from '../../../users/services/users.service';
import { TenantCampaignsService } from '../../services/tenant-campaigns.service';
import { defaultTenantCampaignsFilter } from '../../types';
import {
  acceptTenantCampaignConsent,
  acceptTenantCampaignConsentFailure,
  acceptTenantCampaignConsentSuccess,
  activateTenantCampaign,
  activateTenantCampaignFailure,
  activateTenantCampaignSuccess,
  createTenantCampaign,
  createTenantCampaignFailure,
  createTenantCampaignSuccess,
  deleteTenantCampaign,
  deleteTenantCampaignFailure,
  deleteTenantCampaignSuccess,
  duplicateTenantCampaign,
  duplicateTenantCampaignFailure,
  duplicateTenantCampaignSuccess,
  loadTenantCampaign,
  loadTenantCampaignAttributes,
  loadTenantCampaignAttributesFailure,
  loadTenantCampaignAttributesSuccess,
  loadTenantCampaignConsent,
  loadTenantCampaignConsentFailure,
  loadTenantCampaignConsentSuccess,
  loadTenantCampaignFailure,
  loadTenantCampaignSuccess,
  loadTenantCampaigns,
  loadTenantCampaignsFailure,
  loadTenantCampaignsSuccess,
  updateTenantCampaign,
  updateTenantCampaignFailure,
  updateTenantCampaignSuccess
} from '../actions/tenant-campaigns.actions';

@Injectable()
export class TenantCampaignsEffects {
  loadAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenantCampaignAttributes),
      switchMap(({ campaignType: attributeCampaignType }) =>
        this.tenantCampaignsService.getAttributes(attributeCampaignType).pipe(
          map(response =>
            loadTenantCampaignAttributesSuccess({
              attributeCampaignType,
              attributeMap: transformAttributeResponses(response)
            })
          ),
          catchError(error =>
            of(
              loadTenantCampaignAttributesFailure({
                attributeCampaignType,
                errorState: error
              })
            )
          )
        )
      )
    )
  );

  loadTenantCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenantCampaigns),
      switchMap(({ filter }) =>
        this.tenantCampaignsService.getTenantCampaigns(filter).pipe(
          map(({ data, metadata }) =>
            loadTenantCampaignsSuccess({ tenantCampaigns: data, total: metadata.total || 0 })
          ),
          catchError(error => of(loadTenantCampaignsFailure({ error })))
        )
      )
    )
  );

  loadTenantCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenantCampaign),
      switchMap(({ id }) =>
        this.tenantCampaignsService.getTenantCampaign(id).pipe(
          map(tenantCampaign => loadTenantCampaignSuccess({ tenantCampaign })),
          catchError(error => of(loadTenantCampaignFailure({ error })))
        )
      )
    )
  );

  createTenantCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTenantCampaign),
      switchMap(({ request }) =>
        this.tenantCampaignsService.createTenantCampaign(request).pipe(
          map(tenantCampaign => createTenantCampaignSuccess(tenantCampaign)),
          catchError((error: HttpError) => {
            const isUniqueCampaignCodeError = (error?.errors ?? []).some(
              ({ message }) => typeof message === 'string' && message.includes('unique_campaign_code')
            );

            return of(
              createTenantCampaignFailure(
                error,
                // TODO: remove this once LD has finished campaign code validation API
                // We will return undefined if the error is not unique campaign code error
                // in this way we can fallback to the default message using default parameter
                isUniqueCampaignCodeError
                  ? {
                      default: `The Campaign ID ${request.code ?? ''} already exists, please use another Campaign ID.`
                    }
                  : undefined,
                isUniqueCampaignCodeError ? 10_000 : undefined
              )
            );
          })
        )
      )
    )
  );

  updateTenantCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTenantCampaign),
      switchMap(({ request }) =>
        this.tenantCampaignsService.updateTenantCampaign(request).pipe(
          map(tenantCampaign => updateTenantCampaignSuccess({ tenantCampaign })),
          catchError(error => of(updateTenantCampaignFailure({ error })))
        )
      )
    )
  );

  deleteTenantCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTenantCampaign),
      exhaustMap(action =>
        this.tenantCampaignsService.deleteTenantCampaign(action.id).pipe(
          map(() => deleteTenantCampaignSuccess(action.originPage)),
          catchError(error => of(deleteTenantCampaignFailure(error)))
        )
      )
    )
  );

  deleteTenantCampaignSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTenantCampaignSuccess),
      switchMap(({ originPage }) => {
        const successActions: Action[] = [loadTenantCampaigns({ filter: defaultTenantCampaignsFilter })];

        if (originPage === 'details') {
          successActions.push(
            routerForceNavigate({
              path: '/campaigns'
            })
          );
        }

        return successActions;
      })
    )
  );

  activateTenantCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateTenantCampaign),
      exhaustMap(({ id }) =>
        this.tenantCampaignsService.activateTenantCampaign(id).pipe(
          map(() => activateTenantCampaignSuccess()),
          catchError(error => of(activateTenantCampaignFailure({ error })))
        )
      )
    )
  );

  activateOrDuplicateOrCreateTenantCampaignSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateTenantCampaignSuccess, createTenantCampaignSuccess, duplicateTenantCampaignSuccess),
      exhaustMap(() => [loadTenantCampaigns({ filter: defaultTenantCampaignsFilter })])
    )
  );

  duplicateTenantCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicateTenantCampaign),
      exhaustMap(({ id }) =>
        this.tenantCampaignsService.duplicateTenantCampaign(id).pipe(
          map(() => duplicateTenantCampaignSuccess()),
          catchError(error => of(duplicateTenantCampaignFailure(error)))
        )
      )
    )
  );

  loadTenantCampaignConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenantCampaignConsent),
      withLatestFrom(this.authStore.select(authQuery.getUserId)),
      exhaustMap(([_, userId]) =>
        this.usersService.getUser(userId, true).pipe(
          map(user =>
            loadTenantCampaignConsentSuccess({
              consent: user.custom?.agreements?.tenant_campaign_consent_signed_off ?? null
            })
          ),
          catchError(error => of(loadTenantCampaignConsentFailure({ error })))
        )
      )
    )
  );

  acceptTenantCampaignConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptTenantCampaignConsent),
      exhaustMap(() =>
        this.tenantCampaignsService.acceptTenantCampaignConsent().pipe(
          map(() => acceptTenantCampaignConsentSuccess()),
          catchError(error => of(acceptTenantCampaignConsentFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private authStore: Store<AuthState>,
    private tenantCampaignsService: TenantCampaignsService
  ) {}
}
