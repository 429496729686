import { Dayjs } from 'dayjs';

export class PointsActivitiesFilter {
  pointsAccountId?: string;
  limit: number;
  page: number;
  description: string;
  category: string;
  transactionTime: {
    dateFrom: Dayjs;
    dateTo: Dayjs;
  };

  constructor() {
    this.limit = 10;
    this.page = 1;
  }
}
