<div class="data-table-container" [ngClass]="{ 'mat-elevation-z1 mat-mdc-card order-items-component-card': isUserView }">
  <div [ngClass]="{ 'mat-mdc-card-content': isUserView }">
    <div class="page-title-wrapper" fxLayoutGap="10px">
      <h2 [ngClass]="{ 'mat-mdc-card-title': isUserView }">Orders</h2>
      <div *adminIfScopesInclude="['admin_checkout']" [matTooltip]="tooltipMessage$ | async">
        <button
          *ngIf="isUserView"
          (click)="openNewRedemptionDialog()"
          [disabled]="!!(tooltipMessage$ | async)"
          mat-raised-button
          class="uppercase-button"
          color="primary"
        >
          <span class="uppercase-button__text">Create new redemption</span>
        </button>
      </div>
    </div>

    <div class="filter-form-wrapper with-hint slim-form-field">
      <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="11px">
          <mat-form-field class="filter-field">
            <mat-label>{{ filter.status ? 'Status' : 'All statuses' }}</mat-label>
            <mat-select formControlName="status" disableOptionCentering panelClass="dropdown-panel">
              <mat-option *ngFor="let status of statuses" [value]="status">
                {{ status | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            </mat-select>
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.status"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>

          <mat-form-field class="filter-field">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search by Order ID</mat-label>
            <input matInput aria-label="Order ID" type="search" placeholder="Order ID" formControlName="orderId" />
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.orderId"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>

          <mat-form-field class="filter-field">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search by description</mat-label>
            <input
              matInput
              aria-label="Description"
              type="search"
              placeholder="Description"
              formControlName="description"
            />
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.description"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>

          <mat-form-field class="filter-field">
            <mat-label>{{ filter.type ? 'Type' : 'All types' }}</mat-label>
            <mat-select formControlName="type" disableOptionCentering panelClass="dropdown-panel">
              <mat-option *ngFor="let type of typeCodeForFilter | keyvalue" [value]="type.key">
                {{ type.value | orderItemType }}
              </mat-option>
            </mat-select>
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.type"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>

          <mat-form-field class="filter-field">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search by Item ID</mat-label>
            <input matInput aria-label="Item ID" type="search" placeholder="Item ID" formControlName="id" />
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.id"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
            <mat-error *ngIf="filterForm.get('id').errors?.invalidUuid">Invalid UUID</mat-error>
          </mat-form-field>

          <div fxLayout="row">
            <mat-form-field class="filter-field">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Search by Supplier Reference ID</mat-label>
              <input
                matInput
                aria-label="Supplier Reference ID"
                type="search"
                placeholder="Supplier Reference ID"
                formControlName="externalSupplierOrderReference"
              />
              <admin-input-reset
                matSuffix
                [inputControl]="filterForm.controls.externalSupplierOrderReference"
                [loading]="loading$ | async"
                [initialValue]="''"
              ></admin-input-reset>
            </mat-form-field>
            <admin-tooltip
              key="orders-items.details.giftCardOrderItem.externalSupplierOrderReference"
              styleClassName="filter-tooltip"
            ></admin-tooltip>
          </div>

          <mat-form-field class="filter-field">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search by Supplier Reference Item ID</mat-label>
            <input
              matInput
              aria-label="Supplier Reference Item ID"
              type="search"
              placeholder="Supplier Reference Item ID"
              formControlName="externalSupplierOrderItemReference"
            />
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.externalSupplierOrderItemReference"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>

          <mat-form-field class="filter-field" *ngIf="!isUserView">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search by User ID</mat-label>
            <input matInput aria-label="User ID" type="search" placeholder="User ID" formControlName="userId" />
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.userId"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
            <mat-error *ngIf="filterForm.get('userId').errors?.invalidUuid">Invalid UUID</mat-error>
          </mat-form-field>

          <mat-form-field class="date-form">
            <mat-label>Created date</mat-label>
            <div class="date-form__wrapper">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matInput
                  matStartDate
                  formControlName="dateFrom"
                  placeholder="Start date"
                  (focus)="picker.open()"
                />
                <input matInput matEndDate formControlName="dateTo" placeholder="End date" (focus)="picker.open()" />
              </mat-date-range-input>
              <ng-container *ngIf="filterForm.get('dateFrom').value || filterForm.get('dateTo').value">
                <button
                  matSuffix
                  mat-icon-button
                  class="date-field-button"
                  (click)="filterForm.get('dateFrom').reset(); filterForm.get('dateTo').reset()"
                  [disabled]="
                    (loading$ | async) || (!filterForm.get('dateFrom').value && !filterForm.get('dateTo').value)
                  "
                >
                  <mat-icon class="datepicker-cancel-btn">close</mat-icon>
                </button>
              </ng-container>
            </div>
          </mat-form-field>
        </div>
        <mat-hint class="hint">
          <div class="hint__icon h8 text-primary bg-secondary">!</div>
          Note: Combined search by Order ID and Description is currently not supported.
        </mat-hint>
      </form>
    </div>

    <div *ngIf="loading$ | async" class="progress-loader">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div>
      <table
        mat-table
        matSort
        matSortActive="createdAt"
        matSortDirection="desc"
        class="data-table"
        [dataSource]="filteredOrderItems"
        [class.overlay]="loading$ | async"
      >
        <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Order ID</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">{{ orderItem.orderId || orderItem.order.id }}</td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Item ID</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">{{ orderItem.id }}</td>
        </ng-container>

        <ng-container matColumnDef="externalSupplierOrderReference">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Supplier Reference ID</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">{{ orderItem.data.externalSupplierOrderReference }}</td>
        </ng-container>

        <ng-container matColumnDef="externalSupplierOrderItemReference">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Supplier Reference Item ID</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">{{ orderItem.data.externalSupplierOrderItemReference }}</td>
        </ng-container>

        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">User ID</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">{{ orderItem.userId }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            <h4 class="text-primary">Type</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">{{ orderItem.type | orderItemType }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Description</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem" attr.data-tooltip="{{ orderItem.description }}">
            {{ orderItem.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <!--  disable sorting when user search with id (order item id) -->
          <th
            mat-header-cell
            *matHeaderCellDef
            [disabled]="!!filter.id"
            mat-sort-header="createdAt"
            class="text-primary"
          >
            <h4 class="text-primary">Created at</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem" attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
            {{ orderItem.createdAt | date: 'yyyy-MM-dd HH:mm' : timezone }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Status</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">
            <button
              mat-flat-button
              disabled
              class="status-button-in-table"
              [ngClass]="orderItemStatusDisplayClass[orderItem?.status] || 'status-neutral'"
            >
              <span class="dot"></span>{{ orderItem?.status | customTitleCase: 'fromSnakeCase' }}
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4 class="text-primary">Actions</h4>
          </th>
          <td mat-cell *matCellDef="let orderItem">
            <div fxLayout="row">
              <a
                [routerLink]="'./' + orderItem.id"
                *adminIfScopesInclude="SCOPES.showOrderItems; relationOperator: 'or'"
              >
                <button mat-icon-button>
                  <mat-icon>zoom_in</mat-icon>
                </button>
              </a>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns" class="bg-secondary"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="data-table__row"></tr>
      </table>
    </div>
    <!--  disable paginator when user search with id (order item id) -->
    <mat-paginator
      [pageSizeOptions]="[20, 50]"
      [pageIndex]="filter.page - 1"
      [pageSize]="filter.limit"
      [length]="orderItemsCount$ | async"
      (page)="onPage($event)"
      [disabled]="(loading$ | async) || !!filter.id"
    >
    </mat-paginator>
  </div>
</div>
