import { Component, Inject, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { AttributesSettings, HttpError } from '@core/types';
import { ComponentState, getRenderState } from '@utils';

import { Tranche } from '../../types';
import { PointsExpiringDialogComponent } from '../points-expiring-dialog/points-expiring-dialog.component';

@Component({
  selector: 'admin-points-expiring-v2',
  templateUrl: './points-expiring-v2.component.html',
  styleUrls: ['./points-expiring-v2.component.scss']
})
export class PointsExpiringV2Component implements OnChanges {
  @Input() loading: boolean;
  @Input() pointsSummaryTranches: Tranche[];
  @Input() error: HttpError;

  renderState: ComponentState;
  showExpiringPointsBalance: boolean;

  evergreenPoints: number;
  expiringTranches: Tranche[] = [];

  constructor(
    private accessPolicies: AccessPolicies,
    private matDialog: MatDialog,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    @Inject('pointsActivityDecimals') public pointsActivityDecimals: number
  ) {}

  ngOnChanges(): void {
    this.renderState = getRenderState([this.loading, this.error]);

    this.evergreenPoints = this.pointsSummaryTranches?.reduce(
      (acc, tranche) => (tranche.expiryDate === '9999-12-31' ? acc + tranche.balance : acc),
      0
    );
    this.expiringTranches = this.pointsSummaryTranches?.filter(tranche => tranche.expiryDate !== '9999-12-31');

    this.setShowExpiringPointsBalance();
  }

  setShowExpiringPointsBalance(): void {
    this.showExpiringPointsBalance = true; // reset to default value
    const policySettings = this.accessPolicies.getFrontendSettings('pointsSummary', 'index');
    const visible = policySettings?.visible as AttributesSettings;

    // check for literal boolean to avoid hiding panel when setting is nullish
    if (typeof visible?.expiringPointsBalance === 'boolean') {
      this.showExpiringPointsBalance = visible.expiringPointsBalance;
    }

    this.showExpiringPointsBalance = this.showExpiringPointsBalance && this.expiringTranches?.length > 0;
  }

  openExpiringDialog(): void {
    this.matDialog.open(PointsExpiringDialogComponent, {
      data: {
        pointsSummaryTranches: this.expiringTranches,
        decimalPointsToDisplay: this.pointsActivityDecimals,
        evergreenPoints: this.evergreenPoints
      },
      height: '595px',
      width: '600px'
    });
  }
}
