function decorateNgOnInit(ngOnInit: (() => void) | null | undefined) {
  return function (this: any) {
    if (ngOnInit) {
      ngOnInit.call(this);
    }
    const body = document.querySelector('body')!;
    body.classList.add('v2');
    body.classList.remove('v1');
  };
}

function decorateNgOnDestroy(ngOnDestroy: (() => void) | null | undefined) {
  return function (this: any) {
    if (ngOnDestroy) {
      ngOnDestroy.call(this);
    }
    const body = document.querySelector('body')!;
    body.classList.add('v1');
    body.classList.remove('v2');
  };
}

export const UseV2Style: ClassDecorator = (target: any): any => {
  target.prototype.ngOnInit = decorateNgOnInit(target.prototype.ngOnInit);
  target.prototype.ngOnDestroy = decorateNgOnDestroy(target.prototype.ngOnDestroy);
};
