<div class="dialog-content details-view">
  <div mat-dialog-title>Create MFA identity</div>

  <form [formGroup]="createForm" (keydown.enter)="$event.preventDefault()">
    <mat-form-field>
      <mat-label>Provider ID</mat-label>
      <mat-select formControlName="providerId" disableOptionCentering>
        <mat-option *ngFor="let providerId of providerIdOptions" [value]="providerId">
          {{ providerId | customTitleCase: 'fromSnakeCase' }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>UID</mat-label>
      <input matInput formControlName="uid" />
      <mat-error *ngIf="uidControl.hasError('required')">Required</mat-error>
      <mat-error *ngIf="uidControl.hasError('email')">{{ invalidEmailMessage }}</mat-error>
      <mat-error *ngIf="uidControl.hasError('invalidE164')">{{ invalidE164Message }}</mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions>
    <button class="cancel-button" (click)="dialogRef.close(false)">
      <span>Cancel</span>
    </button>

    <button
      [disabled]="createForm.invalid"
      [ngClass]="createForm.invalid ? 'disabled-button' : 'primary-button'"
      (click)="submitForm()"
    >
      <span>Create</span>
    </button>
  </div>
</div>
