<mat-card appearance="outlined" class="opaque main-header-card">
  <mat-card-content fxLayoutAlign="space-between center">
    <div class="user-info" fxLayout="column" fxLayoutAlign="start start">
      <ng-container>
        <h2 class="user-info__name">
          Customer<span>{{ displayedUserName?.length ? ': ' + displayedUserName : '' }}</span>
        </h2>
      </ng-container>

      <div *ngIf="providerUidText" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <p class="user-info__provider-uid">{{ providerUidText }}</p>
        <admin-tooltip [key]="'users.header.' + tooltipSuffix"></admin-tooltip>
      </div>

      <div *ngIf="!isAdminUser && !(loading$ | async)" class="button-group">
        <admin-tooltip
          [key]="'users.header.button.sign-in-as-user?allowed=' + !disableImpersonation"
          [displayIcon]="false"
        >
          <button
            *adminIfScopesInclude="['impersonate_user']"
            mat-raised-button
            color="accent"
            (click)="impersonateUser()"
            class="uppercase-button"
            [disabled]="disableImpersonation"
          >
            <mat-icon class="uppercase-button__icon">supervisor_account</mat-icon>
            <span class="uppercase-button__text">Sign in as user</span>
          </button>
        </admin-tooltip>

        <admin-tooltip
          [key]="'users.header.button.sign-in-as-user?allowed=' + !disableImpersonation"
          [displayIcon]="false"
        >
          <button
            *adminIfScopesInclude="['impersonate_user:read_only']"
            mat-raised-button
            color="accent"
            (click)="impersonateUser(true)"
            class="uppercase-button"
            [disabled]="disableImpersonation"
          >
            <mat-icon class="uppercase-button__icon">supervisor_account</mat-icon>
            <span class="uppercase-button__text">Sign in as user (read-only)</span>
          </button>
        </admin-tooltip>
      </div>
    </div>
  </mat-card-content>
</mat-card>
