import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { RewardTriggersService } from '../../services/reward-triggers.service';
import {
  loadRewardTrigger,
  loadRewardTriggerFailure,
  loadRewardTriggers,
  loadRewardTriggersFailure,
  loadRewardTriggersSuccess,
  loadRewardTriggerSuccess,
  resetRewardTrigger,
  resetRewardTriggerFailure,
  resetRewardTriggerSuccess
} from '../actions/reward-triggers.actions';

@Injectable()
export class RewardTriggersEffects {
  loadRewardTriggers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRewardTriggers),
      exhaustMap(({ filter }) =>
        this.rewardTriggersService.getRewardTriggers(filter).pipe(
          map(rewardTriggersResult => loadRewardTriggersSuccess({ rewardTriggersResult })),
          catchError(error => of(loadRewardTriggersFailure({ error })))
        )
      )
    )
  );

  loadRewardTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRewardTrigger),
      exhaustMap(action =>
        this.rewardTriggersService.getRewardTrigger(action.id).pipe(
          map(rewardTrigger => loadRewardTriggerSuccess({ rewardTrigger })),
          catchError(error => of(loadRewardTriggerFailure({ error })))
        )
      )
    )
  );

  resetRewardTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetRewardTrigger),
      exhaustMap(action =>
        this.rewardTriggersService.resetRewardTrigger(action.id).pipe(
          map(rewardTrigger => resetRewardTriggerSuccess(rewardTrigger, action.filter)),
          catchError(error => of(resetRewardTriggerFailure(error)))
        )
      )
    )
  );

  resetRewardTriggerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetRewardTriggerSuccess),
      exhaustMap(({ filter }) => [loadRewardTriggers({ filter })])
    )
  );

  constructor(
    private actions$: Actions,
    private rewardTriggersService: RewardTriggersService
  ) {}
}
