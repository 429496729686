import { OpenApiSchema } from '@kaligo/genesis';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState, Change } from '@core/types';
import { PropertiesType } from '@shared/types';

import { App } from '../../apps/types/apps.type';
import { McTenant } from '../../mc-tenants/types/mc-tenants.type';
import { PaymentTierId } from '../../payment-tiers/types/payment-tiers.type';

export interface Tenant {
  id: string;
  clientId?: string;
  custom_css?: string;
  custom_data: {
    [key: string]: any;
  };
  locales: string[];
  preferences?: object;
  preferredLoginApp?: App;
  settings?: PropertiesType;
  settingsSchema?: {
    schema: OpenApiSchema;
  };
  tokenExpiry: number;
  translations: {
    [key: string]: any;
  };
  ui_environment?: PropertiesType;
  ui_environment_schema?: {
    schema: OpenApiSchema;
  };
  changes?: Change[];
}

export interface EnrollTenantParams {
  id: string;
  name: string;
  authenticationProviderId: string;
  providerConfig: {
    requestPhaseUrl: string;
  };
  loyaltyProgramIds?: string[];
  countryCodes: string[];
  cashCurrency?: string;
  locales: string[];
  defaultLocale: string;
  admin: {
    email: string;
  };
  paymentTierId?: PaymentTierId;
  demo?: boolean;
}

export interface TenantResponse {
  tenant: McTenant;
}

export const tenantAdapter = createEntityAdapter<Tenant>();

export interface TenantState extends EntityState<Tenant>, CallState {}

export const initialState: TenantState = tenantAdapter.getInitialState<CallState>(initialCallState);

export interface IframeItem {
  name: string;
  path: string;
  key: string;
}

export interface PreviewConfig {
  ui_environment: PropertiesType;
}

export const guardhousePreviewPageItems: IframeItem[] = [
  {
    name: 'Profile',
    path: '/profile',
    key: 'profile'
  },
  {
    name: 'Password Forgot',
    path: '/forgot',
    key: 'passwordForgot'
  }
];
