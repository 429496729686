<div class="wrapper points-adjustment-dialog" [class.overlay]="pointsAdjustmentLoading$ | async">
  <admin-spinner *ngIf="pointsAdjustmentLoading$ | async"></admin-spinner>
  <h3 class="title">Adjust balance</h3>
  <form class="form-container" [formGroup]="pointsAdjustmentForm" adminFormWithTextArea>
    <div *ngIf="!hasOnlyCustomOption" fxLayoutAlign="start" class="description-field-wrapper">
      <mat-form-field class="reason-field slim-form-field" appearance="fill" [formGroup]="metadataControl">
        <mat-label>Reason</mat-label>
        <mat-select
          formControlName="reason"
          placeholder="Reason"
          disableOptionCentering
          panelClass="dropdown-panel"
          (selectionChange)="onReasonChange($event)"
        >
          <mat-option *ngFor="let option of allowedOptions" [value]="option.reason">
            {{ option.reason | customTitleCase: 'fromSnakeCase' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span class="h8 explanation-text" [innerHTML]="reasonExplanation[reason]"></span>
    </div>

    <div fxLayoutAlign="start" fxLayoutGap="20px">
      <mat-button-toggle-group
        formControlName="category"
        aria-label="Category"
        class="button-toggle-group"
        (change)="onCategoryChange()"
      >
        <mat-button-toggle
          *ngIf="selectedOption.categories.includes('accrual')"
          value="accrual"
          class="add-button uppercase-button"
        >
          <span class="uppercase-button__text">+ Add</span>
        </mat-button-toggle>
        <mat-button-toggle
          *ngIf="selectedOption.categories.includes('redemption')"
          value="redemption"
          class="deduct-button uppercase-button"
        >
          <span class="uppercase-button__text">- Deduct</span>
        </mat-button-toggle>
        <mat-button-toggle
          *ngIf="selectedOption.categories.includes('reversal')"
          value="reversal"
          class="deduct-button uppercase-button"
        >
          <span class="uppercase-button__text">- Deduct</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div fxLayoutAlign="start" fxLayoutGap="20px" class="field-row">
      <mat-form-field appearance="fill" class="slim-form-field amount-field">
        <mat-label>Amount ({{ loyaltyCurrency | titlecase }})</mat-label>
        <mat-icon
          matPrefix
          *ngIf="!additionAllowed || !deductionAllowed"
          [matTooltip]="
            !additionAllowed ? 'Addition is not allowed' : !deductionAllowed ? 'Deduction is not allowed' : ''
          "
          >block</mat-icon
        >

        <input
          matInput
          type="number"
          [min]="min"
          [step]="step"
          placeholder="Amount"
          required
          formControlName="amount"
        />
        <mat-error *ngIf="pointsAdjustmentForm.controls.amount.hasError('required')">Required</mat-error>
        <mat-error *ngIf="pointsAdjustmentForm.controls.amount.hasError('min')"
          >Must be greater than or equal to {{ min }}</mat-error
        >
        <mat-error *ngIf="pointsAdjustmentForm.controls.amount.hasError('max')"
          >Must be less than or equal to {{ max }}</mat-error
        >
        <mat-hint *ngIf="pointsAdjustmentForm.hasError('invalidAmount')" class="error-message">
          <ng-container *ngIf="pointsAdjustmentDecimals; else elseBlock">
            Fractions are supported up to {{ pointsAdjustmentDecimals }} digits
          </ng-container>
          <ng-template #elseBlock>Amount must be an integer</ng-template>
        </mat-hint>
        <mat-hint *ngIf="pointsAdjustmentForm.hasError('invalidBalanceResult')" class="error-message">
          Resulting points balance cannot be less than 0
        </mat-hint>
      </mat-form-field>
      <ng-container *ngIf="selectedOption.customExpiry">
        <mat-form-field class="expiry-date-field slim-form-field" appearance="fill">
          <mat-label>Expiry date</mat-label>
          <mat-select
            formControlName="expiryRule"
            placeholder="Expiry date"
            disableOptionCentering
            panelClass="dropdown-panel"
          >
            <mat-option [value]="'default'">Default</mat-option>
            <mat-option [value]="1">1 months</mat-option>
            <mat-option [value]="2">2 months</mat-option>
            <mat-option [value]="3">3 months</mat-option>
            <mat-option [value]="'custom'">Custom</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="pointsAdjustmentForm.get('expiryRule').value === 'custom'"
          class="expiry-rule-field slim-form-field"
          appearance="fill"
        >
          <mat-label>Expiry at</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="expiryDate"
            (focus)="picker.open()"
            [min]="minExpiryDate"
          />
          <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="pointsAdjustmentForm.controls.expiryDate.hasError('required')">Required</mat-error>
        </mat-form-field>
      </ng-container>
    </div>

    <div *ngIf="!autoDescription" fxLayoutAlign="start" class="description-field-wrapper">
      <mat-form-field class="description-field slim-form-field" appearance="fill">
        <mat-label>Loyalty statement descriptor</mat-label>
        <mat-select
          formControlName="description"
          required
          placeholder="Loyalty statement descriptor"
          disableOptionCentering
          panelClass="dropdown-panel"
        >
          <ng-container *ngIf="customDescriptions ?? reasonDescriptions as descriptions; else defaultDescription">
            <mat-option *ngFor="let description of descriptions" [value]="description">
              {{ description }}
            </mat-option>
          </ng-container>

          <ng-template #defaultDescription>
            <mat-option *ngFor="let description of defaultDescriptions" [value]="description">
              {{ defaultDescriptionLabels[description] }}
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngFor="let metadata of selectedOption.metadata">
      <!-- TODO Define new metadata types and keys here based on new NN information -->
      <ng-container [ngSwitch]="metadata.type">
        <ng-container *ngSwitchCase="'text'">
          <ng-container [ngSwitch]="metadata.key">
            <ng-container *ngSwitchCase="'notes'">
              <mat-form-field appearance="fill" [formGroup]="metadataControl">
                <mat-label>Notes for the changes</mat-label>
                <textarea
                  matInput
                  type="text"
                  placeholder="Notes for the changes"
                  (change)="pointsAdjustmentForm.updateValueAndValidity()"
                  [formControlName]="metadata.key"
                  [required]="metadata.required"
                  rows="5"
                  class="text-metadata-field"
                >
                </textarea>
                <mat-hint *ngIf="!metadata.required">Optional</mat-hint>
              </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'approver_name'">
              <mat-form-field appearance="fill" [formGroup]="metadataControl">
                <mat-label>Name of Approver</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Name of Approver"
                  (change)="pointsAdjustmentForm.updateValueAndValidity()"
                  [formControlName]="metadata.key"
                  [required]="metadata.required"
                  class="text-metadata-field"
                />
                <mat-hint *ngIf="!metadata.required">Optional</mat-hint>
                <mat-error *ngIf="metadata.required">Required</mat-error>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'year'">
          <admin-year-picker
            [inputControl]="metadataControl"
            [controlName]="metadata.key"
            [label]="metadata.key | customTitleCase: 'fromSnakeCase'"
            [required]="metadata.required"
            (yearSelected)="pointsAdjustmentForm.updateValueAndValidity()"
          ></admin-year-picker>
        </ng-container>
      </ng-container>
    </ng-container>

    <div fxLayoutAlign="space-between center">
      <div class="adjustment-text">
        {{ adjustmentText }}
      </div>
      <div fxLayoutAlign="end center" fxLayoutGap="16px">
        <button mat-raised-button class="uppercase-button cancel-button text-primary" (click)="dialogRef.close(false)">
          <span>Cancel</span>
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="uppercase-button"
          [disabled]="pointsAdjustmentForm.invalid"
          (click)="confirmChangesClicked()"
        >
          <span>Update balance</span>
        </button>
      </div>
    </div>
  </form>
</div>
