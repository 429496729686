import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostListener, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'admin-text-input',
  styleUrls: ['../form-field-shared.scss'],
  templateUrl: './text-input.component.html'
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() confirmationControl?: AbstractControl;
  @Input() errors: ValidationErrors | null;
  @Input() fieldLabel: string;
  @Input() formControlName: string;
  @Input() inputType: string;

  value: string;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get controlTouched(): boolean {
    return this.ngControl.control.touched;
  }

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  /* eslint-enable */

  @HostListener('focusout')
  onFocusout(): void {
    this.onTouched();
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    this.onChange((event.target as HTMLInputElement).value);
    this.ngControl.control.updateValueAndValidity();

    if (this.confirmationControl) {
      this.confirmationControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  /* non-interface implementations */
  getErrorMessage(): string {
    if (this.isInvalidRequiredField()) {
      return 'Required';
    }

    if (this.isInvalidEmailField()) {
      return 'Invalid email';
    }

    if (this.isMismatchEmailConfirmationField()) {
      return 'Please make sure email addresses match';
    }

    if (this.isInvalidInput()) {
      return 'Invalid input';
    }

    return null;
  }

  isInvalidEmailField(): boolean {
    return (
      (this.formControlName === 'email' || this.formControlName === 'emailConfirmation') &&
      this.isInvalidInput() &&
      !this.errors.required &&
      !this.errors.mismatch &&
      this.errors.email &&
      this.controlTouched
    );
  }

  isInvalidInput(): boolean {
    return this.controlTouched && this.ngControl.control.invalid;
  }

  isInvalidRequiredField(): boolean {
    return !!(this.errors?.required && this.controlTouched);
  }

  isMismatchEmailConfirmationField(): boolean {
    // this check is only to show email confirmation mismatch message
    return (
      this.formControlName === 'emailConfirmation' &&
      this.isInvalidInput() &&
      !this.errors.required &&
      this.errors.mismatch &&
      this.controlTouched
    );
  }

  /* interface implementations */
  onChange = (_value: string): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }
}
