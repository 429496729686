import { HostTenantMappingUtils } from './host-tenant-mapping-utils';

export class StyleSheetUtils {
  // fetch css-filenames.json via http request to get the latest content
  // add cache-control to avoid reading outdated file from cache
  static async getCssFilenames(): Promise<any> {
    return await fetch('assets/css-filenames.json', {
      headers: {
        'cache-control': 'no-cache no-store'
      }
    })
      .then(data => data.json())
      .catch(e => console.error(e));
  }

  static async getV2CssFilename(): Promise<string> {
    const cssFilenames = await this.getCssFilenames();
    return cssFilenames['admin-panel'];
  }

  static async injectTenantStyleSheet(): Promise<void> {
    const cssFilenames = await this.getCssFilenames();

    [HostTenantMappingUtils.getPrefix(), 'admin-panel'].forEach(prefix => {
      const linkElement = document.createElement('link');
      linkElement.href = cssFilenames[prefix] as string;
      linkElement.media = 'all';
      linkElement.rel = 'stylesheet';
      linkElement.type = 'text/css';
      document.head.append(linkElement);
    });
  }
}
