import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { GiftCardOrdersFilter } from '../../types/gift-card-orders-filter.type';
import { GiftCardOrdersResult } from '../../types/gift-card-orders-result.type';
import { GiftCardOrder } from '../../types/gift-card-orders.type';

export const loadGiftCardOrders = createAction(
  '[Gift Card Orders] Load Gift Card Orders',
  props<{ filter: GiftCardOrdersFilter }>()
);
export const loadGiftCardOrdersSuccess = createAction(
  '[Gift Card Orders] Load Gift Card Orders Success',
  props<{ result: GiftCardOrdersResult }>()
);
export const loadGiftCardOrdersFailure = createAction(
  '[Gift Card Orders] Load Gift Card Orders Failure',
  props<ErrorState>()
);

export const loadGiftCardOrder = createAction('[Gift Card Orders] Load Gift Card Order', props<{ orderId: string }>());
export const loadGiftCardOrderSuccess = createAction(
  '[Gift Card Orders] Load Gift Card Order Success',
  props<{ order: GiftCardOrder }>()
);
export const loadGiftCardOrderFailure = createAction(
  '[Gift Card Orders] Load Gift Card Order Failure',
  props<ErrorState>()
);

export const retryGiftCardOrder = createAction(
  '[Gift Card Orders] Retry Gift Card Order',
  props<{ orderId: string }>()
);
export const retryGiftCardOrderSuccess = createAction(
  '[Gift Card Orders] Retry Gift Card Order Success',
  props<{ orderId: string }>()
);
export const retryGiftCardOrderFailure = createAction(
  '[Gift Card Orders] Retry Gift Card Order Failure',
  props<ErrorState>()
);
