<mat-form-field class="w-full">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [matAutocomplete]="auto"
    (input)="onInputChange($event)"
    (focus)="updateFilteredFonts(value); ngControl.control!.markAsTouched()"
    placeholder="Search from supported fonts"
    [value]="value"
    [disabled]="disabled"
  />
  <mat-icon
    matSuffix
    [svgIcon]="isAutoCompleteOpened ? 'up-thin' : 'down-thin'"
    (click)="toggleAutoCompleteDropdown()"
  ></mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    (opened)="autoCompleteOpened(); isAutoCompleteOpened = true"
    (closed)="isAutoCompleteOpened = false"
  >
    <cdk-virtual-scroll-viewport
      itemSize="48"
      maxBufferPx="1000"
      [style.height.px]="5 * 48"
      (scrolledIndexChange)="loadFonts($event)"
    >
      <!-- only set control value when event is fired by user input to prevent setting form value again due to refreshing of autocomplete options after the initial setValue function call -->
      <mat-option
        *cdkVirtualFor="let font of filteredFonts"
        [value]="font"
        [disabled]="font === SUPPORTED_SECTION_HEADER || font === RECENTLY_USED_SECTION_HEADER"
        (onSelectionChange)="$event.isUserInput && ngControl.control!.setValue($event.source.value)"
      >
        <div [attr.style]="'font-family: ' + getFont(font) + ' !important'">{{ font }}</div>
      </mat-option>
      <mat-option *ngIf="filteredFonts?.length === 0" disabled class="font-not-found">
        <div>No matching typefaces found</div>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</mat-form-field>
<mat-error class="error-message" *ngIf="ngControl.control!.hasError('invalidTypeface')"
  >Please select a valid typeface</mat-error
>
<mat-error class="error-message" *ngIf="ngControl.control!.touched && ngControl.control!.hasError('required')"
  >Required</mat-error
>
