import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';
import { TagDefs } from '@shared/types/tag-defs.type';

import { UserPassword } from '../../../users/types/user-passwords.type';

@Injectable({ providedIn: 'root' })
export class PasswordTagDefs {
  readonly tagDefs: TagDefs<UserPassword> = {
    password_id: {
      getDisplayPrefix: () => 'Password',
      getDisplayValue: (userPassword: UserPassword) => userPassword.id,
      getRouteLink: (tags: Tag[]) => {
        if (this.scopes.lackScopes(SCOPES_OR.viewPasswords)) {
          return null;
        }

        const userTag = tags.find(tag => tag.type === 'user_id');
        return userTag ? `/users/${userTag.id}/passwords` : null;
      },
      getRouteParams: (tags: Tag[]) => {
        const passwordTag = tags.find(tag => tag.type === 'password_id');

        return { id: passwordTag!.id };
      }
    }
  };

  constructor(private scopes: Scopes) {}
}
