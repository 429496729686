import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { RolePermission } from '../types';

export const roleTypes = ['allowed', 'restricted'] as const;
export type RoleType = (typeof roleTypes)[number];

export interface RoleGeneral {
  name: string;
  type: RoleType;
  description: string;
}

export interface Role extends RoleGeneral {
  permissions: RolePermission[];
}

export function selectRoleId(role: Role): string {
  return role.name;
}

export const roleAdapter = createEntityAdapter<Role>({
  selectId: selectRoleId
});

export interface RoleState extends EntityState<Role>, CallState {}

export const initialState: RoleState = roleAdapter.getInitialState<CallState>(initialCallState);
