import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TenantBootstrap } from '@core/types';

import { MissionControlService } from '../mission-control/mission-control.service';

@Injectable({
  providedIn: 'root'
})
export class TenantBootstrapService {
  constructor(private missionControlService: MissionControlService) {}

  getTenantBootstrap(): Observable<TenantBootstrap> {
    return this.missionControlService.get<TenantBootstrap>('tenants_bootstrap');
  }
}
