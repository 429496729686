import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

export interface ToggleOptions {
  buttonText?: {
    on: string;
    off: string;
  };
  buttonTextColor?: {
    on: string;
    off: string;
  };
  backgroundColor?: {
    on?: string;
    off?: string;
  };
}

@Component({
  selector: 'admin-slide-toggle',
  templateUrl: './slide-toggle.component.html'
})
export class SlideToggleComponent implements ControlValueAccessor, AfterViewInit {
  static nextId = 0;

  @Input() toggleOptions: ToggleOptions = {};
  @Output() toggleChange = new EventEmitter<boolean>();

  componentId = `slide-toggle-${SlideToggleComponent.nextId++}`;
  value: boolean;
  addToggleVerticalBarRetries = 3;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  /* eslint-enable */

  ngAfterViewInit(): void {
    this.addToggleVerticalBar();
  }

  onToggleChange(checked: boolean): void {
    this.value = checked;
    this.onChange(checked);
    this.toggleChange.emit(checked);
  }

  addToggleVerticalBar(): void {
    const element = document.querySelector(`#${this.componentId} .mdc-switch__shadow`);

    if (element) {
      const verticalBar = document.createElement('div');
      verticalBar.classList.add('vertical-bar');
      element.append(verticalBar);
    } else if (this.addToggleVerticalBarRetries > 0) {
      this.addToggleVerticalBarRetries -= 1;
      setTimeout(() => this.addToggleVerticalBar(), 500);
    }
  }

  /* interface implementations */
  onChange = (_value: boolean): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.value = value;
  }
}
