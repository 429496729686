var LocalStorageQueue = /** @class */ (function () {
    function LocalStorageQueue() {
    }
    LocalStorageQueue.peek = function (key) {
        var queue = localStorage.getItem(key);
        if (!queue) {
            return null;
        }
        try {
            var items = JSON.parse(queue);
            if (Array.isArray(items) && items.length) {
                return items[0];
            }
            else {
                return null;
            }
        }
        catch (_a) {
            return null;
        }
    };
    LocalStorageQueue.enqueue = function (key, item) {
        var newQueue;
        var existingQueue = localStorage.getItem(key);
        if (existingQueue) {
            try {
                var items = JSON.parse(existingQueue);
                items.push(item);
                newQueue = JSON.stringify(items);
            }
            catch (_a) {
                return;
            }
        }
        else {
            newQueue = JSON.stringify([item]);
        }
        localStorage.setItem(key, newQueue);
    };
    LocalStorageQueue.dequeue = function (key) {
        var newQueue;
        var existingQueue = localStorage.getItem(key);
        if (existingQueue) {
            try {
                var items = JSON.parse(existingQueue);
                items.shift();
                newQueue = JSON.stringify(items);
                localStorage.setItem(key, newQueue);
            }
            catch (_a) { }
        }
    };
    LocalStorageQueue.clear = function (key) {
        localStorage.removeItem(key);
    };
    return LocalStorageQueue;
}());
export { LocalStorageQueue };
