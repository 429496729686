import { Injectable } from '@angular/core';

import { PasswordTagDefs } from '@shared/services/tag-defs/password-tag-defs.service';

import { AbilityTagDefs } from './tag-defs/ability-tag-defs.service';
import { AccessPolicyTagDefs } from './tag-defs/access-policy-tag-defs.service';
import { AppConnectionTagDefs } from './tag-defs/app-connection-tag-defs.service';
import { AppTagDefs } from './tag-defs/app-tag-defs.service';
import { ApprovalRequestTagDefs } from './tag-defs/approval-request-tag-defs.service';
import { AuthenticationProviderTagDefs } from './tag-defs/authentication-provider-tag-defs.service';
import { CampaignTagDefs } from './tag-defs/campaign-tag-defs.service';
import { DomainTagDefs } from './tag-defs/domain-tag-defs.service';
import { FraudRuleTagDefs } from './tag-defs/fraud-rule-tag-defs.service';
import { GiftCardOrderTagDefs } from './tag-defs/gift-card-order-tag-defs.service';
import { GiftCardTagDefs } from './tag-defs/gift-card-tag-defs.service';
import { LoginAttemptTagDefs } from './tag-defs/login-attempt-tag-defs.service';
import { LoyaltyProgramTagDefs } from './tag-defs/loyalty-program-tag-defs.service';
import { MerchandiseTagDefs } from './tag-defs/merchandise-tag-defs.service';
import { OfferTagDefs } from './tag-defs/offer-tag-defs.service';
import { PointsActivityTagDefs } from './tag-defs/points-activity-tag-defs.service';
import { ProviderTagDefs } from './tag-defs/provider-tag-defs.service';
import { RedemptionRateTagDefs } from './tag-defs/redemption-rate-tag-defs.service';
import { ReferenceTagDefs } from './tag-defs/reference-tag-defs.service';
import { RoleTagDefs } from './tag-defs/role-tag-defs.service';
import { ScopeTagDefs } from './tag-defs/scope-tag-defs.service';
import { TenantTagDefs } from './tag-defs/tenant-tag-defs.service';
import { TokenTagDefs } from './tag-defs/token-tag-defs.service';
import {
  TeBookingTagDefs,
  TeBookingTransactionTagDefs,
  TeIpTagDefs,
  TeSessionTagDefs,
  TeUserTagDefs
} from './tag-defs/travel-edge';
import { UserTagDefs } from './tag-defs/user-tag-defs.service';

@Injectable({ providedIn: 'root' })
export class TagDefsService {
  readonly tagDefs = {
    ...this.abilityTagDefs.tagDefs,
    ...this.accessPolicyTagDefs.tagDefs,
    ...this.appConnectionTagDefs.tagDefs,
    ...this.approvalRequestTagDefs.tagDefs,
    ...this.appTagDefs.tagDefs,
    ...this.authenticationProviderTagDefs.tagDefs,
    ...this.campaignTagDefs.tagDefs,
    ...this.domainTagDefs.tagDefs,
    ...this.fraudRuleTagDefs.tagDefs,
    ...this.loginAttemptTagDefs.tagDefs,
    ...this.loyaltyProgramTagDefs.tagDefs,
    ...this.merchandiseTagDefs.tagDefs,
    ...this.pointsActivityTagDefs.tagDefs,
    ...this.roleTagDefs.tagDefs,
    ...this.scopeTagDefs.tagDefs,
    ...this.tenantTagDefs.tagDefs,
    ...this.tokenTagDefs.tagDefs,
    ...this.userTagDefs.tagDefs,
    ...this.providerTagDefs.tagDefs,
    ...this.redemptionRateTagDefs.tagDefs,
    ...this.referenceTagDefs.tagDefs,
    ...this.giftCardTagDefs.tagDefs,
    ...this.giftCardOrderTagDefs.tagDefs,
    ...this.offerTagDefs.tagDefs,
    ...this.passwordTagDefs.tagDefs,
    ...this.teSessionTagDefs.tagDefs,
    ...this.teBookingTagDefs.tagDefs,
    ...this.teBookingTransactionTagDefs.tagDefs,
    ...this.teIpTagDefs.tagDefs,
    ...this.teUserTagDefs.tagDefs
  };

  constructor(
    private abilityTagDefs: AbilityTagDefs,
    private accessPolicyTagDefs: AccessPolicyTagDefs,
    private appConnectionTagDefs: AppConnectionTagDefs,
    private approvalRequestTagDefs: ApprovalRequestTagDefs,
    private appTagDefs: AppTagDefs,
    private authenticationProviderTagDefs: AuthenticationProviderTagDefs,
    private campaignTagDefs: CampaignTagDefs,
    private domainTagDefs: DomainTagDefs,
    private fraudRuleTagDefs: FraudRuleTagDefs,
    private loginAttemptTagDefs: LoginAttemptTagDefs,
    private loyaltyProgramTagDefs: LoyaltyProgramTagDefs,
    private merchandiseTagDefs: MerchandiseTagDefs,
    private pointsActivityTagDefs: PointsActivityTagDefs,
    private roleTagDefs: RoleTagDefs,
    private scopeTagDefs: ScopeTagDefs,
    private tenantTagDefs: TenantTagDefs,
    private tokenTagDefs: TokenTagDefs,
    private userTagDefs: UserTagDefs,
    private providerTagDefs: ProviderTagDefs,
    private redemptionRateTagDefs: RedemptionRateTagDefs,
    private referenceTagDefs: ReferenceTagDefs,
    private giftCardTagDefs: GiftCardTagDefs,
    private giftCardOrderTagDefs: GiftCardOrderTagDefs,
    private offerTagDefs: OfferTagDefs,
    private passwordTagDefs: PasswordTagDefs,
    private teSessionTagDefs: TeSessionTagDefs,
    private teBookingTagDefs: TeBookingTagDefs,
    private teBookingTransactionTagDefs: TeBookingTransactionTagDefs,
    private teIpTagDefs: TeIpTagDefs,
    private teUserTagDefs: TeUserTagDefs
  ) {}
}
