import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { Impersonation } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationsService {
  constructor(private missionControlService: MissionControlService) {}

  impersonate(userId: string, readOnly: boolean = false): Observable<Impersonation> {
    const path = readOnly ? 'impersonate_read_only' : 'impersonate';
    return this.missionControlService.post<Impersonation>(`users/${userId}/${path}`);
  }
}
