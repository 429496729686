<h1 mat-dialog-title>Change email</h1>
<form class="form-container" (keydown.enter)="$event.preventDefault()">
  <mat-form-field class="w-full">
    <mat-label>Email</mat-label>
    <input matInput [formControl]="emailControl" />
    <mat-error *ngIf="emailControl.errors">Invalid email</mat-error>
  </mat-form-field>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="secondary" class="text-primary cancel-button" (click)="dialogRef.close(false)">
      <span>Cancel</span>
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="emailControl.invalid || initialEmail === emailControl.value"
      (click)="submitForm()"
    >
      <span>Confirm and send verification email</span>
    </button>
  </div>
</form>
