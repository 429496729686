import { filter, map } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { ModuleUtils } from '@utils';

import { loadTooltips } from '../../store/actions/tooltips.actions';
import { tooltipsQuery } from '../../store/selectors/tooltips.selectors';
import { tooltipKeys } from '../../types/tooltip-keys.type';
import { TooltipState } from '../../types/tooltips.type';
import { TooltipsEditDialogComponent } from '../tooltips-edit-dialog/tooltips-edit-dialog.component';

@Component({
  selector: 'admin-tooltips-edit-button',
  templateUrl: './tooltips-edit-button.component.html',
  styleUrls: ['./tooltips-edit-button.component.scss']
})
export class TooltipsEditButtonComponent implements OnChanges {
  @Input() url: string;
  @Output() isEditButtonDisplayed = new EventEmitter<boolean>();

  loading$: Observable<boolean>;
  isTooltipsEditable: boolean;
  private currentTooltipKeys: string[];

  constructor(
    private scopes: Scopes,
    private store: Store<TooltipState>,
    private matDialog: MatDialog
  ) {}

  ngOnChanges(): void {
    const currentModules = ModuleUtils.getCurrentModules(this.url);
    const hasUpdateScope = this.scopes.has('tooltips:update');

    this.loading$ = this.store.select(tooltipsQuery.isBatchLoading);

    this.currentTooltipKeys =
      currentModules.length > 0 ? tooltipKeys.filter(key => currentModules.some(module => key.startsWith(module))) : [];
    const hasTooltips = this.currentTooltipKeys.length > 0;

    this.isTooltipsEditable = hasUpdateScope && hasTooltips;
    this.isEditButtonDisplayed.emit(this.isTooltipsEditable);

    if (hasTooltips) {
      this.store
        .pipe(
          select(tooltipsQuery.getFetchList),
          map(fetchList => currentModules.filter(module => !fetchList.includes(module))),
          filter(modules => modules.length > 0)
        )
        .subscribe(modules => {
          this.store.dispatch(loadTooltips({ module: modules[0] }));
        });
    }
  }

  editTooltips(): void {
    this.matDialog.open(TooltipsEditDialogComponent, {
      data: {
        tooltipKeys: this.currentTooltipKeys
      }
    });
  }
}
