import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageEvent } from '@angular/material/paginator';
import { fromEvent } from 'rxjs';

import { Nullable } from '@shared/types';

@Directive({
  selector: '[adminPaginator]'
})
export class PaginatorDirective implements AfterViewInit, OnChanges {
  @Input() length: number;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() disabled: boolean;
  @Input() tableId: string;
  @Output() page = new EventEmitter<PageEvent>();

  destroyRef = inject(DestroyRef);

  constructor(private element: ElementRef) {}

  get nextButton(): HTMLElement {
    return this.element.nativeElement.querySelector('.mat-mdc-paginator-navigation-next') as HTMLElement;
  }

  get previousButton(): HTMLElement {
    return this.element.nativeElement.querySelector('.mat-mdc-paginator-navigation-previous') as HTMLElement;
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    const id = (event.target as any).id;

    if (!this.disabled && id && Number.isInteger(Number(id))) {
      this.page.emit({
        length: this.length,
        pageIndex: Number(id) - 1,
        pageSize: this.pageSize
      });

      this.scrollToTableTop();
    }
  }

  ngAfterViewInit(): void {
    const paginatorRangeLabel = this.element.nativeElement.querySelector(
      '.mat-mdc-paginator-range-label'
    ) as HTMLElement;
    paginatorRangeLabel?.prepend(document.createTextNode('Showing '));

    [this.previousButton, this.nextButton].forEach(button => {
      button.classList.add('paginator-action-button');

      fromEvent(button, 'click')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.scrollToTableTop();
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousValue = changes.pageSize?.previousValue;
    if (previousValue && this.pageSize !== previousValue) {
      this.scrollToTableTop();
    }

    const totalPages = Math.ceil(this.length / this.pageSize);
    const pageItems = this.generatePageItems(this.pageIndex + 1, totalPages);
    this.createPageItemButtons(pageItems);
  }

  createPageItemButtons(pageItems: Nullable<number>[]): void {
    let pageItemsWrapper = this.element.nativeElement.querySelector('.paginator-page-items-wrapper');

    if (!pageItemsWrapper) {
      pageItemsWrapper = document.createElement('div');
      pageItemsWrapper.classList.add('paginator-page-items-wrapper');
    }

    pageItemsWrapper.innerHTML = '';

    const rangeActions = this.element.nativeElement.querySelector('.mat-mdc-paginator-range-actions');

    pageItems.forEach(item => {
      pageItemsWrapper.innerHTML += item
        ? `<span id="${item}" class="paginator-page-item${
            this.pageIndex + 1 === item ? ' paginator-active-page-item' : ''
          }">${item}</span>`
        : '<span class="paginator-page-item ellipsis-page-item">&#8230</span>';
    });

    rangeActions?.insertBefore(pageItemsWrapper, this.nextButton);
  }

  generatePageItems(currentItem: number, totalPages: number): Nullable<number>[] {
    if (totalPages <= 9) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentItem <= 5) {
      // example: at page 1 to 5, display page items as [1, 2, 3, 4, 5, 6, 7, null, 20]
      const pageItems = Array.from({ length: 7 }, (_, i) => i + 1);
      return [...pageItems, null, totalPages];
    } else if (currentItem >= totalPages - 4) {
      // example: at page 16 to 20, display page items as [1, null, 14, 15, 16, 17, 18, 19, 20]
      const pageItems = Array.from({ length: 7 }, (_, i) => totalPages - 6 + i);
      return [1, null, ...pageItems];
    } else {
      // example: at page 6 to 15, display page items as [1, null, 4, 5, 6, 7, 8, null, 20]
      const pageItems = Array.from({ length: 5 }, (_, i) => currentItem - 2 + i);
      return [1, null, ...pageItems, null, totalPages];
    }
  }

  private scrollToTableTop(): void {
    const selector = this.tableId ? `#${this.tableId}` : 'table';
    const top = document.querySelector(selector)!.getBoundingClientRect().top + window.scrollY - 10; // -10 so we can see the loading of table
    window.scroll({ top, behavior: 'smooth' });
  }
}
