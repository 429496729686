import { NoteEntity } from '../modules/notes/types/notes.type';
import { Formatters } from './formatters';

type ScopeAction = 'create' | 'delete' | 'show' | 'update' | 'view';
type RemoveLastChar<PluralStr extends string> = PluralStr extends `${infer SingularStr}s` ? SingularStr : never;

type NoteEntityKey = RemoveLastChar<Capitalize<NoteEntity>>; // e.g. "User", "Offer", ...
type NoteScope = `${ScopeAction}${NoteEntityKey | ''}Notes`;

export const ScopesUtils = {
  // e.g.,
  // users -> viewUserNote
  // null -> viewNote
  getNotesScopeKey(action: ScopeAction, entity: NoteEntity): NoteScope {
    const entityKey = entity ? Formatters.fromCamelToTitleCase(entity.slice(0, -1)) : '';
    return (action + entityKey + 'Notes') as NoteScope;
  },

  generateTopLevelNoteScopes(): Partial<Record<NoteScope, string[]>> {
    return this.generateEntityNoteScopes(null);
  },

  generateEntityNoteScopes(entity: NoteEntity): Partial<Record<NoteScope, string[]>> {
    const scope = entity ? entity.slice(0, -1) + '_notes' : 'notes';
    return {
      [this.getNotesScopeKey('view', entity)]: [`${scope}:index`, `view_${scope}`],
      [this.getNotesScopeKey('create', entity)]: [
        `${scope}:create`,
        `manage_${scope}`,
        ...(entity === 'users' ? [`create_${scope}`] : [])
      ],
      [this.getNotesScopeKey('delete', entity)]: [`${scope}:destroy`, `manage_${scope}`],
      [this.getNotesScopeKey('update', entity)]: [`${scope}:update`, `manage_${scope}`]
    };
  }
};
