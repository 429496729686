import { debounceTime, filter, skip, startWith, switchMap } from 'rxjs/operators';

import { AfterViewInit, Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import deepmerge from 'deepmerge';
import { combineLatest, Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { ScopesUtils } from '@scopes-utils';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { FetchUserService } from '@shared/services/fetch/fetch-user.service';
import { TagsService } from '@shared/services/tags.service';
import { TagIdsRecord } from '@shared/types';
import { convertTagsToTagIdsRecord } from '@tag-utils';
import { DashedDateAdapter, DateUtils, or } from '@utils';

import { uuidValidator } from '../../../../validators';
import { getUserDetailsRoute, getUserIdentifier } from '../../../users/types';
import { DisplayFullNoteDialogComponent } from '../../components/display-full-note-dialog/display-full-note-dialog.component';
import { EditNoteDialogComponent } from '../../components/edit-note-dialog/edit-note-dialog.component';
import { NotesService } from '../../services/notes.service';
import { deleteNote, loadNotes } from '../../store/actions/notes.actions';
import { NotesQuery } from '../../store/selectors/notes.selectors';
import { NoteForm } from '../../types/note-forms.type';
import { NotesFilter } from '../../types/notes-filter.type';
import { Metadata, Note, NoteEntity, noteEntityTypes, NoteState, NoteType } from '../../types/notes.type';

@Component({
  selector: 'admin-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DashedDateAdapter
    }
  ]
})
export class NotesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  destroyRef = inject(DestroyRef);

  loading: boolean;
  metadata$: Observable<Metadata>;
  notes$: Observable<Note[]>;
  filter: NotesFilter;
  filterForm: FormGroup<NoteForm>;
  currentUTCTime: string;
  entity: NoteEntity;
  isEntityDetailsView: boolean;
  isTopLevelView: boolean;
  lines = 3;

  getUserIdentifier = getUserIdentifier(this.customerBankIdentityProvider);
  getUserDetailsRoute = getUserDetailsRoute;

  columns: string[];
  types = Object.values(NoteType);
  entityTypes = noteEntityTypes;

  createNoteScopes: string[];
  deleteNoteScopes: string[];
  updateNoteScopes: string[];

  constructor(
    private fb: FormBuilder,
    private fetchUserService: FetchUserService,
    private matDialog: MatDialog,
    private notesService: NotesService,
    private route: ActivatedRoute,
    private scopes: Scopes,
    private store: Store<NoteState>,
    private tagsService: TagsService,
    private notesQuery: NotesQuery,
    @Inject('customerBankIdentityProvider') private customerBankIdentityProvider: string,
    @Inject('timezone') public timezone: string
  ) {}

  ngOnInit(): void {
    this.initializeVariables();
    this.initializeObservables();
    this.fetchDataAndInitializeLoading();
    this.setupForm();
    this.formatFilterTags();
  }

  ngAfterViewInit(): void {
    this.subscribeToDateRangeChanges();
    this.subscribeToInputChanges();
    this.subscribeToMatSortChange();
  }

  initializeVariables(): void {
    const getNotesScopeKey = ScopesUtils.getNotesScopeKey;
    this.filter = this.route.snapshot.data.notesFilter;
    this.entity = this.route.snapshot.data.notesConfigs.entity;
    this.columns = [...this.route.snapshot.data.notesConfigs.baseColumns];
    this.isEntityDetailsView = !!this.route.snapshot.data.notesConfigs.isEntityDetailsView;
    this.timezone ??= '+0000';
    this.currentUTCTime = new Date().toISOString();
    this.createNoteScopes = SCOPES_OR[getNotesScopeKey('create', this.entity)];
    this.deleteNoteScopes = SCOPES_OR[getNotesScopeKey('delete', this.entity)];
    this.updateNoteScopes = SCOPES_OR[getNotesScopeKey('update', this.entity)];

    // initialize columns
    this.isTopLevelView = !this.entity;

    if (this.scopes.hasAny([...this.deleteNoteScopes, ...this.updateNoteScopes]) && !this.isEntityDetailsView) {
      this.columns.push('actions');
    }

    // set number of lines that note will display in table
    document.documentElement.style.setProperty('--lines', `${this.lines}`);
  }

  initializeObservables(): void {
    const { getMetadata, getNotes } = this.notesQuery;

    this.metadata$ = this.store.select(getMetadata);
    this.notes$ = this.store.select(getNotes);
  }

  fetchDataAndInitializeLoading(): void {
    const { isBatchLoading, isDataFetching, getNotesList } = this.notesQuery;

    const notesLoading$ = this.store.select(isBatchLoading);
    const dataLoading$ = this.store.select(getNotesList).pipe(
      filter(notes => notes.length > 0),
      switchMap(notes => {
        const adminIds = this.notesService.getUniqueAdminIds(notes);
        const tags = this.tagsService.getUniqueFetchableTags(notes);
        return combineLatest([
          this.fetchUserService.fetchUsers(adminIds),
          ...this.tagsService.fetchTags(tags),
          of(null)
        ]).pipe(
          switchMap(() =>
            this.store.select(
              isDataFetching(deepmerge(convertTagsToTagIdsRecord(tags), { agent_id: adminIds } as TagIdsRecord))
            )
          )
        );
      })
    );

    or(notesLoading$, dataLoading$.pipe(startWith(false)))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loading => (this.loading = loading));
  }

  setupForm(): void {
    this.filterForm = this.fb.group({
      updatedAt: this.fb.group({
        dateFrom: this.fb.control(this.filter.updatedAt?.from),
        dateTo: this.fb.control(this.filter.updatedAt?.to)
      }),
      type: this.fb.control(this.filter.type),
      note: this.fb.control(this.filter.note),
      expiresAt: this.fb.group({
        dateFrom: this.fb.control(this.filter.expiresAt?.from),
        dateTo: this.fb.control(this.filter.expiresAt?.to)
      }),
      adminUserId: this.fb.control(this.filter.adminUserId, uuidValidator()),
      entityFilter: this.fb.control(this.filter.entityFilter),
      entityId: this.fb.control(this.filter.entityId, uuidValidator()),
      tags: this.fb.control(this.filter.tags)
    });
  }

  formatFilterTags(): void {
    const filterTags = this.filter.tags;

    if (filterTags?.length > 0) {
      const filterTagFormats$ = filterTags.map(tag => this.tagsService.formatTag$(tag));

      combineLatest(filterTagFormats$)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(formattedTags => {
          this.filterForm.get('tags').setValue(formattedTags, { emitEvent: false });
        });
    }
  }

  updateFilter(params: Partial<NotesFilter>): void {
    this.filter = {
      ...this.filter,
      ...params
    };
    this.store.dispatch(loadNotes({ filter: this.filter, isTopLevelView: this.isTopLevelView }));
  }

  subscribeToDateRangeChanges(): void {
    this.filterForm.controls.updatedAt.valueChanges
      .pipe(
        debounceTime(1000),
        filter(() => this.filterForm.get('updatedAt').valid),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ dateFrom, dateTo }) => {
        this.updateFilter({
          page: 1,
          updatedAt: {
            from: dateFrom && DateUtils.getTenantStartOfDay(dateFrom, this.timezone),
            to: dateTo && DateUtils.getTenantEndOfDay(dateTo, this.timezone)
          }
        });
      });

    this.filterForm.controls.expiresAt.valueChanges
      .pipe(
        debounceTime(1000),
        filter(() => this.filterForm.get('expiresAt').valid),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ dateFrom, dateTo }) => {
        this.updateFilter({
          page: 1,
          expiresAt: {
            from: dateFrom && DateUtils.getTenantStartOfDay(dateFrom, this.timezone),
            to: dateTo && DateUtils.getTenantEndOfDay(dateTo, this.timezone)
          }
        });
      });
  }

  subscribeToInputChanges(): void {
    combineLatest([
      this.filterForm.get('type').valueChanges.pipe(startWith(this.filter.type)),
      this.filterForm.get('note').valueChanges.pipe(startWith(this.filter.note), debounceTime(1000)),
      this.filterForm.get('adminUserId').valueChanges.pipe(
        startWith(this.filter.adminUserId),
        debounceTime(1000),
        filter(() => this.filterForm.controls.adminUserId.valid)
      ),
      this.filterForm.get('entityFilter').valueChanges.pipe(startWith(this.filter.entityFilter)),
      this.filterForm.get('entityId').valueChanges.pipe(
        startWith(this.filter.entityId),
        debounceTime(1000),
        filter(() => this.filterForm.controls.entityId.valid)
      ),
      this.filterForm.get('tags').valueChanges.pipe(startWith(this.filter.tags))
    ])
      .pipe(
        skip(1), // first emission should be skipped so we don't dispatch twice on page load
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([type, note, adminUserId, entityFilter, entityId, tags]) => {
        this.updateFilter({
          page: 1,
          type,
          note,
          adminUserId,
          entityFilter,
          entityId,
          tags: tags?.map(tag => ({ type: tag.type, id: tag.id }))
        });
      });
  }

  subscribeToMatSortChange(): void {
    this.sort.sortChange.subscribe(event => {
      this.updateFilter({
        page: 1,
        sortBy: {
          [event.active]: event.direction
        }
      });
    });
  }

  editNote(note: Note): void {
    this.matDialog.open(EditNoteDialogComponent, {
      width: '908px',
      data: {
        note,
        timezone: this.timezone
      }
    });
  }

  deleteNote(id: string, entity: string): void {
    this.matDialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          dialogTitle: 'Delete note',
          confirmText: 'This action cannot be undone. Are you sure you want to delete this note?',
          confirmButtonText: 'Yes, delete',
          styleClassName: 'delete-note'
        }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmed => {
        if (confirmed) {
          this.store.dispatch(deleteNote({ id, entity }));
        }
      });
  }

  onPage(pageEvent: PageEvent): void {
    this.updateFilter({
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    });
  }

  openModal(note: string): void {
    this.matDialog.open(DisplayFullNoteDialogComponent, {
      data: note
    });
  }
}
