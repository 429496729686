@if (rulesForm.controls.conditions.controls.length > 0) {
  <div class="rules-form-container">
    @if (rulesForm.controls.conditions.controls.length > 1) {
      <mat-button-toggle-group class="connector-toggle" [formControl]="rulesForm.controls.connector">
        @for (connector of connectors; track connector) {
          <mat-button-toggle [value]="connector">{{ connector | uppercase }}</mat-button-toggle>
        }
      </mat-button-toggle-group>
      <div class="padding"></div>
    }

    @for (control of rulesForm.controls.conditions.controls; let index = $index; track control) {
      @if (rulesForm.controls.conditions.controls.length > 1) {
        <div
          class="connect-line"
          [ngClass]="{
            'only-rule': rulesForm.controls.conditions.controls.length === 1,
            'first-rule': rulesForm.controls.conditions.controls.length !== 1 && index === 0,
            'last-rule':
              rulesForm.controls.conditions.controls.length !== 1 &&
              index === rulesForm.controls.conditions.controls.length - 1
          }"
        >
          <div class="vertical"></div>
          <div class="horizontal"></div>
        </div>
      }

      <div
        class="rule-container"
        [ngClass]="{
          'only-rule': rulesForm.controls.conditions.controls.length === 1
        }"
      >
        <admin-logic-rule
          [formControl]="control"
          [useEventTypeAsMainAttribute]="useEventTypeAsMainAttribute"
          [disableMainAttributeSelection]="useEventTypeAsMainAttribute && index !== 0"
          [attributeOptions]="attributeGroupOptions"
          [elementIndex]="index"
          (ruleRemoved)="removeCondition(index)"
        />
      </div>
    }
  </div>
} @else if (emptyRuleTemplate) {
  <ng-container *ngTemplateOutlet="emptyRuleTemplate.templateRef" />
}
@if (shouldShowAddConditionButton()) {
  <div>
    <button
      class="add-group-condition-button"
      type="button"
      mat-button
      (click)="addCondition()"
      data-testid="add-parent-condition"
    >
      <mat-icon svgIcon="add-outline" />
      Add parent condition
    </button>
  </div>
}
