<div [class.overlay]="loading$ | async" *ngIf="user$ | async as user">
  <mat-card class="mat-elevation-z1 details-card">
    <admin-spinner *ngIf="loading$ | async"></admin-spinner>
    <mat-card-content>
      <div class="headline">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-card-title>Details</mat-card-title>
        </div>
        <div class="edit-button" fxLayoutGap="16px">
          <a
            mat-raised-button
            color="secondary"
            class="uppercase-button text-primary"
            *ngIf="(currentUser$ | async).sub === user.id"
            [href]="guardhouseApiUrl + '/profile'"
            target="_blank"
            rel="noopener noreferrer"
          >
            <mat-icon class="uppercase-button__icon">launch</mat-icon>
            <span class="uppercase-button__text">My Profile</span>
          </a>
          <button
            mat-raised-button
            color="secondary"
            class="edit-button uppercase-button text-primary"
            *ngIf="showEdit(currentUser$ | async, user.id)"
            [routerLink]="'../../edit/' + user.id"
          >
            <mat-icon class="uppercase-button__icon">create</mat-icon>
            <span class="uppercase-button__text">Edit Details</span>
          </button>
          <button
            *adminIfScopesInclude="accountStatusMenuScopes; relationOperator: 'or'"
            mat-raised-button
            class="menu-button uppercase-button text-primary"
            color="secondary"
            [matMenuTriggerFor]="accountStatus"
            #trigger="matMenuTrigger"
          >
            <span class="uppercase-button__text">Account Status</span>
            <mat-icon class="uppercase-button__icon">{{ trigger.menuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
          <mat-menu #accountStatus="matMenu" xPosition="before" class="mat-elevation-z8">
            <!--    block/unblock user      -->
            <ng-container *ngIf="user.status">
              <admin-tooltip
                [displayIcon]="false"
                [key]="'users.details.button.blockUser?userIsBlocked=' + (user.status === 'blocked')"
              >
                <ng-container *ngIf="hasBlockUnblockScopes; else elseSectionForBlock">
                  <button
                    mat-menu-item
                    *ngIf="user.status === 'blocked'"
                    (click)="onAccountActions({ actionType: 'unblock', user: user })"
                  >
                    Unblock user
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="user.status !== 'blocked'"
                    (click)="onAccountActions({ actionType: 'block', user: user })"
                  >
                    Block user
                  </button>
                </ng-container>
                <ng-template #elseSectionForBlock>
                  <button
                    mat-menu-item
                    *adminIfScopesInclude="SCOPES.blockUsers; relationOperator: 'or'"
                    [disabled]="user.status === 'blocked'"
                    (click)="onAccountActions({ actionType: 'block', user: user })"
                  >
                    Block user
                  </button>
                  <button
                    mat-menu-item
                    *adminIfScopesInclude="SCOPES.unblockUsers; relationOperator: 'or'"
                    [disabled]="user.status !== 'blocked'"
                    (click)="onAccountActions({ actionType: 'unblock', user: user })"
                  >
                    Unblock user
                  </button>
                </ng-template>
              </admin-tooltip>
            </ng-container>

            <!--    block/unblock user login      -->
            <admin-tooltip
              [displayIcon]="false"
              [key]="'users.details.button.blockUserLogin?userLogin=' + (user.loginMode === 'normal')"
            >
              <ng-container *ngIf="hasBlockUnblockLoginScopes; else elseSectionForBlockLogin">
                <button
                  mat-menu-item
                  *ngIf="user.loginMode === 'normal'"
                  (click)="onAccountActions({ actionType: 'blockLogin', user: user })"
                >
                  Block user login
                </button>
                <button
                  mat-menu-item
                  *ngIf="user.loginMode !== 'normal'"
                  (click)="onAccountActions({ actionType: 'unblockLogin', user: user })"
                >
                  Remove login block
                </button>
              </ng-container>
              <ng-template #elseSectionForBlockLogin>
                <button
                  mat-menu-item
                  *adminIfScopesInclude="SCOPES.unblockLoginUsers; relationOperator: 'or'"
                  [disabled]="user.loginMode === 'normal'"
                  (click)="onAccountActions({ actionType: 'unblockLogin', user: user })"
                >
                  Remove login block
                </button>
                <button
                  mat-menu-item
                  *adminIfScopesInclude="SCOPES.blockLoginUsers; relationOperator: 'or'"
                  [disabled]="user.loginMode !== 'normal'"
                  (click)="onAccountActions({ actionType: 'blockLogin', user: user })"
                >
                  Block user login
                </button>
              </ng-template>
            </admin-tooltip>

            <!--    unenroll user      -->
            <ng-container *ngIf="user.activated !== undefined">
              <admin-tooltip
                [displayIcon]="false"
                [key]="'users.details.button.unenrollUser?enabled=' + user.activated"
                *adminIfScopesInclude="SCOPES.rollbackUsers; relationOperator: 'or'"
              >
                <button
                  mat-menu-item
                  [disabled]="!user.activated"
                  (click)="onAccountActions({ actionType: 'unenroll', user: user })"
                >
                  Unenroll user
                </button>
              </admin-tooltip>
            </ng-container>

            <!--    logout user      -->
            <div>
              <button
                mat-menu-item
                *adminIfScopesInclude="SCOPES.userSession; relationOperator: 'or'"
                (click)="onAccountActions({ actionType: 'logout', user: user })"
              >
                Logout user
              </button>
            </div>

            <!--    divider      -->
            <div *adminIfScopesInclude="dividerScopes; relationOperator: 'all-loose-groups'" class="divider"></div>

            <!--    reset password      -->
            <admin-tooltip
              [displayIcon]="false"
              [key]="'users.details.button.sendResetPassword'"
              *adminIfScopesInclude="SCOPES.resetPassword; relationOperator: 'or'"
            >
              <admin-tooltip
                [displayIcon]="false"
                [key]="'users.details.button.sendResetPassword?enabled=' + !!(isResetPasswordEnabled$ | async)"
                *adminIfScopesInclude="SCOPES.resetPassword; relationOperator: 'or'"
                [position]="'left'"
              >
                <button
                  mat-menu-item
                  [disabled]="!(isResetPasswordEnabled$ | async)"
                  (click)="onAccountActions({ actionType: 'resetPassword', user: user })"
                >
                  Send reset password email
                </button>
              </admin-tooltip>
            </admin-tooltip>
          </mat-menu>
        </div>
      </div>
      <div class="details-container">
        <div *ngFor="let detail of details">
          <div class="item">
            <div class="item__key">
              <admin-tooltip [key]="detail | userDetailTooltipKey" [displayIcon]="false">
                <h4>{{ detail.label }}</h4>
              </admin-tooltip>
            </div>
            <admin-tooltip
              [key]="detail | userDetailTooltipKey"
              [displayIcon]="false"
              *ngIf="user | renderAttribute: detail"
            >
              <span [innerText]="user | renderAttribute: detail" class="item__value"></span>
            </admin-tooltip>
          </div>
        </div>
      </div>
      <ng-container *ngIf="moreDetails.length > 0">
        <div class="more-details-toggle" (click)="isMoreDetailsPanelOpen = !isMoreDetailsPanelOpen">
          <h4>View more details</h4>
          <mat-icon>{{ isMoreDetailsPanelOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
        </div>
        <ng-container *ngIf="isMoreDetailsPanelOpen">
          <div class="details-container">
            <div *ngFor="let detail of moreDetails">
              <div class="item">
                <div class="item__key">
                  <admin-tooltip [key]="detail | userDetailTooltipKey" [displayIcon]="false">
                    <h4>{{ detail.label }}</h4>
                  </admin-tooltip>
                </div>
                <admin-tooltip
                  [key]="detail | userDetailTooltipKey"
                  [displayIcon]="false"
                  *ngIf="user | renderAttribute: detail"
                >
                  <span [innerText]="user | renderAttribute: detail" class="item__value"></span>
                </admin-tooltip>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>

  <admin-notes *adminIfScopesInclude="SCOPES.viewUserNotes; relationOperator: 'or'"></admin-notes>

  <mat-card *ngIf="user.preferences" class="mat-elevation-z1 mat-card">
    <mat-card-content class="preferences-card">
      <div class="headline">
        <mat-card-title>Preferences</mat-card-title>
      </div>
      <div class="details-container">
        <div *ngFor="let detail of rootPreferences">
          <div class="item">
            <div class="item__key">
              <h4>{{ detail.label }}</h4>
            </div>
            <span [innerText]="user | renderAttribute: detail" class="item__value"></span>
          </div>
        </div>
        <div *ngFor="let detail of preferences">
          <div class="item">
            <div class="item__key">
              <admin-tooltip [key]="detail | userDetailTooltipKey" [displayIcon]="false">
                <h4>{{ detail.label }}</h4>
              </admin-tooltip>
            </div>
            <admin-tooltip
              [key]="detail | userDetailTooltipKey"
              [displayIcon]="false"
              *ngIf="user.preferences | renderAttribute: detail"
            >
              <span [innerText]="user.preferences | renderAttribute: detail" class="item__value"></span>
            </admin-tooltip>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z1 mat-card data-table-container" *ngIf="userIdentity$ | async as identities">
    <mat-card-content>
      <mat-card-title class="identity-product-card-title" fxLayout="row" fxLayoutAlign="space-between center">
        Login identities
        <button
          *adminIfScopesInclude="SCOPES.createIdentities; relationOperator: 'or'"
          mat-raised-button
          color="primary"
          class="uppercase-button__text"
          (click)="openCreateIdentityDialog(user)"
        >
          Create Identity
        </button>
      </mat-card-title>
      <table mat-table [dataSource]="identities" class="data-table">
        <ng-container *ngFor="let column of identityColumns" [matColumnDef]="column.key">
          <th mat-header-cell *matHeaderCellDef>
            <h4 class="text-primary">{{ column.label }}</h4>
          </th>

          <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="column.key === 'buttons'">
              <td mat-cell *matCellDef="let identity" class="cell action-col">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
                  <admin-tooltip
                    *adminIfScopesInclude="SCOPES.deleteIdentities; relationOperator: 'or'"
                    key="users.details.button.deleteIdentity"
                    [displayIcon]="false"
                  >
                    <button
                      mat-raised-button
                      color="warn"
                      class="uppercase-button__text"
                      (click)="
                        onAccountActions({
                          actionType: 'deleteIdentity',
                          identityReference: identity.reference,
                          user: user
                        })
                      "
                    >
                      Delete identity
                    </button>
                  </admin-tooltip>
                </div>
              </td>
            </ng-container>
            <ng-container *ngSwitchCase="column.key === 'createdAt' || column.key === 'updatedAt'">
              <td mat-cell *matCellDef="let identity" class="cell">
                <span matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
                  {{ identity | renderAttribute: column | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
                </span>
              </td>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <td
                mat-cell
                *matCellDef="let identity"
                [innerHTML]="identity | renderAttribute: column"
                class="cell"
              ></td>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="identityColumnKeys" class="bg-secondary"></tr>
        <tr mat-row *matRowDef="let row; columns: identityColumnKeys"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No identities available</td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>

  <admin-mfa-identities [user]="user$ | async"></admin-mfa-identities>

  <mat-card *ngIf="user.status === 'admin' && user.roles" class="mat-elevation-z1 mat-card">
    <mat-card-content class="roles-card">
      <mat-card-title>Roles</mat-card-title>
      <div class="details-container">
        <div *ngFor="let role of user.roles">
          <div class="item role-item">
            <h5 class="role-item__value">
              {{ role.name }}
              <span *ngIf="hasRolesShowScope" [routerLink]="'/roles/' + role.name" class="text-primary role-item__link">
                View role details <mat-icon>open_in_new</mat-icon>
              </span>
            </h5>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="displayLoyaltyDataPanel" class="mat-elevation-z1 mat-card">
    <mat-card-content>
      <mat-card-title>Loyalty data</mat-card-title>
      <pre>{{ user.loyalty_data | json }}</pre>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="displayCustomPanel" class="mat-elevation-z1 mat-card">
    <mat-card-content>
      <mat-card-title>Custom data</mat-card-title>
      <pre>{{ user.custom | json }}</pre>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="user.pointsAccounts?.length" class="mat-elevation-z1 mat-card">
    <mat-card-content>
      <mat-card-title class="points-account-id-title">Points accounts IDs</mat-card-title>
      <div *ngFor="let pointsAccount of user.pointsAccounts">
        {{ pointsAccount.id }}
      </div>
    </mat-card-content>
  </mat-card>

  @if (user.enrolledSuppliers) {
    <mat-card class="mat-elevation-z1 mat-card">
      <mat-card-content>
        <mat-card-title class="points-account-id-title">Enrolled suppliers</mat-card-title>

        @for (supplier of user.enrolledSuppliers; track supplier) {
          <div>{{ supplier }}</div>
        } @empty {
          <div>No enrolled suppliers</div>
        }
      </mat-card-content>
    </mat-card>
  }

  <mat-card
    class="mat-elevation-z1 mat-card data-table-container"
    *ngIf="user.products?.length && productColumnKeys?.length"
  >
    <mat-card-content>
      <mat-card-title class="identity-product-card-title">Products</mat-card-title>
      <table mat-table [dataSource]="user.products" class="data-table">
        <ng-container *ngFor="let column of productColumns" [matColumnDef]="column.key">
          <th mat-header-cell *matHeaderCellDef class="text-primary">
            <h4>{{ column.label }}</h4>
          </th>
          <td
            mat-cell
            *matCellDef="let product"
            [innerHTML]="product | renderAttribute: column | customTitleCase: 'fromSnakeCase'"
          ></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="productColumnKeys" class="bg-secondary"></tr>
        <ng-container *ngIf="!(loading$ | async)">
          <tr mat-row *matRowDef="let row; columns: productColumnKeys"></tr>
        </ng-container>
      </table>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="isSameUserAndHasAscendaIdentity$ | async" class="mat-elevation-z1 mat-card">
    <mat-card-content>
      <mat-card-title>Actions</mat-card-title>
      <admin-tooltip [key]="'users.details.button.resetAscendaLogin'" [displayIcon]="false">
        <button
          mat-raised-button
          color="primary"
          (click)="resetAscendaLogin()"
          [disabled]="isAscendaLoginValueMissing()"
        >
          Reset Ascenda Login
        </button>
      </admin-tooltip>
    </mat-card-content>
  </mat-card>
</div>
