import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { WatchlistsService } from '../../services/watchlists.service';
import { WatchlistsFilter, WatchlistState } from '../../types';
import {
  createWatchlist,
  createWatchlistFailure,
  createWatchlistSuccess,
  deleteWatchlist,
  deleteWatchlistFailure,
  deleteWatchlistSuccess,
  loadWatchlist,
  loadWatchlistFailure,
  loadWatchlists,
  loadWatchlistsFailure,
  loadWatchlistsSuccess,
  loadWatchlistSuccess,
  updateWatchlist,
  updateWatchlistFailure,
  updateWatchlistSuccess
} from '../actions/watchlists.actions';
import { watchlistsQuery } from '../selectors/watchlists.selectors';

@Injectable()
export class WatchlistsEffects {
  loadWatchlists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWatchlists),
      exhaustMap(action =>
        this.watchlistsService.getWatchlists(action.filter).pipe(
          map(result => loadWatchlistsSuccess({ result })),
          catchError(error => of(loadWatchlistsFailure({ error })))
        )
      )
    )
  );

  loadWatchlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWatchlist),
      exhaustMap(action =>
        this.watchlistsService.getWatchlist(action.id).pipe(
          map(watchlist => loadWatchlistSuccess({ watchlist })),
          catchError(error => of(loadWatchlistFailure({ error })))
        )
      )
    )
  );

  createWatchlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWatchlist),
      exhaustMap(action =>
        this.watchlistsService.createWatchlist(action.watchlist).pipe(
          map(watchlist => createWatchlistSuccess({ watchlist })),
          catchError(error => of(createWatchlistFailure({ error })))
        )
      )
    )
  );

  updateWatchlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWatchlist),
      exhaustMap(action =>
        this.watchlistsService.updateWatchlist(action.params).pipe(
          map(watchlist => updateWatchlistSuccess({ watchlist })),
          catchError(error => of(updateWatchlistFailure({ error })))
        )
      )
    )
  );

  deleteWatchlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteWatchlist),
      exhaustMap(action =>
        this.watchlistsService.deleteWatchlist(action.id).pipe(
          map(() => deleteWatchlistSuccess()),
          catchError(error => of(deleteWatchlistFailure({ error })))
        )
      )
    )
  );

  manageWatchlistSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWatchlistSuccess, updateWatchlistSuccess, deleteWatchlistSuccess),
      withLatestFrom(this.store.select(watchlistsQuery.getFilter)),
      exhaustMap(([_, filter]) => {
        filter ||= new WatchlistsFilter();
        return merge([loadWatchlists({ filter }), routerNavigate({ path: 'watchlists' })]);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private watchlistsService: WatchlistsService,
    private store: Store<WatchlistState>
  ) {}
}
