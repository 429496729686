import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { pointsAccountsQuery } from '../../store/selectors/points-accounts.selectors';
import { PointsAccountState, Tranche } from '../../types';

interface DialogData {
  pointsSummaryTranches: Tranche[];
  decimalPointsToDisplay: number;
  evergreenPoints: number;
}

@Component({
  selector: 'admin-points-expiring-dialog',
  templateUrl: './points-expiring-dialog.component.html',
  styleUrls: ['./points-expiring-dialog.component.scss']
})
export class PointsExpiringDialogComponent {
  displayedColumns = ['expiryDate', 'balance'];
  selectedPointsAccount$ = this.pointsAccountStore.select(pointsAccountsQuery.getSelectedPointsAccount);

  constructor(
    private pointsAccountStore: Store<PointsAccountState>,
    public dialogRef: MatDialogRef<PointsExpiringDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    @Inject('showPointsAccountsSelector') public showPointsAccountsSelector: boolean
  ) {}
}
