import { CallState } from '@core/types';

import { OpenApiSchemaType } from '../../form-fields/types/open-api-schema.type';
import { initialCallState } from '../store/call-state';

export interface TenantBootstrap {
  enrollmentOpenApi: OpenApiSchemaType;
}

export interface TenantBootstrapState extends CallState {
  tenantBootstrap: TenantBootstrap;
}

export const tenantBootstrapInitialState: TenantBootstrapState = {
  ...initialCallState,
  tenantBootstrap: { enrollmentOpenApi: null }
};
