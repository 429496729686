<div class="flex w-[416px] flex-col gap-6 rounded-xl bg-neutrals-100 p-6 text-cloudburst-500">
  <div class="flex flex-col gap-4">
    <div class="align-start flex justify-between gap-4">
      <div class="text-base font-bold">{{ recommendation.name }}</div>
      <div class="flex h-fit items-center gap-1">
        <mat-icon svgIcon="sparkle" class="max-w-3"></mat-icon
        ><span class="text-xs leading-[18px] text-shakespeare-500">Recommended</span>
      </div>
    </div>

    <div class="text-cloudburst-pure">
      {{ recommendation.description }}
    </div>
  </div>

  @if (recommendation.type === 'segment') {
    <div class="flex justify-stretch gap-4">
      <button
        mat-dialog-close
        [routerLink]="'/segments/create/' + recommendation.recommendationId"
        [disabled]="!canManageSegments"
        class="!w-full"
        [ngClass]="canManageSegments ? 'secondary-button' : 'disabled-button'"
      >
        <span class="uppercase-button__text">Edit segment</span>
        <mat-icon svgIcon="pen" class="min-w-6 cursor-pointer"></mat-icon>
      </button>
      <button
        mat-dialog-close
        [disabled]="!canManageSegments"
        class="!w-full"
        [ngClass]="canManageSegments ? 'primary-button uppercase-button' : 'disabled-button'"
        (click)="setUpRecommendedSegment()"
      >
        <span class="uppercase-button__text">Set up</span>
        <mat-icon svgIcon="arrowright" class="min-w-6 cursor-pointer"></mat-icon>
      </button>
    </div>
  }
</div>
