import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, mcTenantAdapter, McTenantState } from '../../types/mc-tenants.type';
import {
  clearMcTenantBootstrapCache,
  clearMcTenantBootstrapCacheFailure,
  clearMcTenantBootstrapCacheSuccess,
  createMcTenant,
  createMcTenantFailure,
  createMcTenantSuccess,
  loadMcTenant,
  loadMcTenantFailure,
  loadMcTenants,
  loadMcTenantsFailure,
  loadMcTenantsSuccess,
  loadMcTenantSuccess,
  updateMcTenant,
  updateMcTenantFailure,
  updateMcTenantSuccess
} from '../actions/mc-tenants.actions';

export const mcTenantTriggers: EntityTriggers = {
  single: {
    loading: [createMcTenant.type, updateMcTenant.type, loadMcTenant.type, clearMcTenantBootstrapCache.type],
    resting: [
      createMcTenantSuccess.type,
      updateMcTenantSuccess.type,
      loadMcTenantSuccess.type,
      clearMcTenantBootstrapCacheSuccess.type
    ],
    erroring: [
      createMcTenantFailure.type,
      updateMcTenantFailure.type,
      loadMcTenantFailure.type,
      clearMcTenantBootstrapCacheFailure.type
    ]
  },
  batch: {
    loading: [loadMcTenants.type],
    resting: [loadMcTenantsSuccess.type],
    erroring: [loadMcTenantsFailure.type]
  }
};

export function mcTenantsReducer(state: McTenantState = initialState, action: Action): McTenantState {
  return { ...callStateReducer(baseReducer, mcTenantTriggers)(state, action) };
}

const baseReducer = createReducer(
  initialState,
  on(loadMcTenantsSuccess, (state, { response }) => ({
    ...mcTenantAdapter.setAll(response.tenants, state),
    environmentSchema: response.environmentSchema
  })),
  on(createMcTenantSuccess, (state, { mcTenant }) => mcTenantAdapter.addOne(mcTenant, state)),
  on(updateMcTenantSuccess, (state, { mcTenant }) => mcTenantAdapter.setOne(mcTenant, state)),
  on(loadMcTenantSuccess, (state, { response }) => ({
    ...mcTenantAdapter.setOne(response.tenant, state),
    environmentSchema: response.environmentSchema
  }))
);
