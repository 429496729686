import { initialCallState } from '@core/store/call-state';
import { CallState, NydusNetworkBootstrap } from '@core/types';

export enum PointsAdjustmentDescription {
  CustomerService = 'Customer service',
  CustomerRecognition = 'Customer recognition',
  SystemErrorCorrection = 'System error correction'
}

export interface PointsAdjustment {
  amount: number;
  category: string;
  description: string;
  metadata?: Metadata;
  expiryRule?: string;
  expiryDate?: string;
  pointsAccountId?: string;
}

export interface Metadata {
  [key: string]: any;
  reason?: string;
}

export interface PointsAdjustmentResult {
  id: string;
  userId: string;
  transactionDate: string;
  description: string;
  category: string;
  productType: string;
  status: string;
  amount: number;
  balanceAsOf: number;
  metadata: {
    description: string;
    notes: string;
  };
  entryType: string;
  orderItemData: {
    amount: number;
    productType: string;
    referenceId: string;
    metadata: {
      description: string;
      notes: string;
    };
  };
  orderStatus: string;
  pointsAccountId: string;
}

export interface PointsAdjustmentDialogData {
  nydusNetworkBootstrap: NydusNetworkBootstrap;
  pointsAccountId?: string;
  pointsBalance: number;
  userId: string;
}

export enum ReasonExplanation {
  goodwill = 'Goodwill points issued as service recovery, bank escalation matters, exceptions for write-offs, manually refunding points for MLS redemptions that Ascenda cannot resolve', // eslint-disable-line max-len
  points_share = 'An approved transfer of points from one user to another; relationship number deduplications',
  expiry_reinstatement = 'Reinstating expired points after their expiry date, when approved by ANZ',
  writeoff_reinstatement = 'Reinstating written off points due to a reactivation of an account, when approved by ANZ',
  negativewriteoff_reinstatement = 'Reinstating written off points due to a reactivation of an account, when approved by ANZ',
  bonus_points_adjustment = 'An approved modification of points due to errors from Bonus Accrual calculations. Only applies for APID earn'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PointsAdjustmentState extends CallState {}

export const pointsAdjustmentInitialState: PointsAdjustmentState = { ...initialCallState };
