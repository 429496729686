<h1 mat-dialog-title>{{ data.dialogTitle ? data.dialogTitle : 'Add tag' }}</h1>
<div mat-dialog-content>
  <form class="form-container" [formGroup]="tagForm" (keydown.enter)="$event.preventDefault()">
    <mat-form-field class="slim-form-field">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" required disableOptionCentering panelClass="dropdown-panel">
        <mat-option *ngFor="let type of types" [value]="type.key">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="slim-form-field">
      <mat-label>ID</mat-label>
      <input matInput type="text" placeholder="ID" required formControlName="id" />
      <mat-error *ngIf="tagForm.get('id').errors?.invalidUuid">Invalid UUID</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="dialogRef.close(false)" class="uppercase-button">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="tagForm.invalid"
    (click)="submitForm()"
    class="uppercase-button"
  >
    {{ data.submitButtonText ? data.submitButtonText : 'Submit' }}
  </button>
</div>
