import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material/snack-bar';

import { SnackBarComponent } from '../../components/snack-bar/snack-bar.component';

type PanelClassType = 'success-custom-snackbar' | 'error-custom-snackbar';
type MatSnackBarConfigWithInnerHtml = MatSnackBarConfig & { data: { innerHtml: string } };
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackbar: MatSnackBar) {}

  openSuccessSnackBar(config: MatSnackBarConfigWithInnerHtml): void {
    this.openSnackBar('success-custom-snackbar', 'snack-bar-success', config);
  }

  openErrorSnackBar(config: MatSnackBarConfigWithInnerHtml): void {
    this.openSnackBar('error-custom-snackbar', 'snack-bar-error', config);
  }

  private openSnackBar(panelClass: PanelClassType, icon: string, config: MatSnackBarConfig): void {
    this.snackbar.openFromComponent(SnackBarComponent, {
      panelClass,
      ...config,
      data: { ...config.data, icon }
    });
  }
}
