import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { RewardPreference } from '../../types/reward-preferences.type';

export const loadRewardPreference = createAction('[Memberships] Load Reward Preference', props<{ userId: string }>());
export const loadRewardPreferenceSuccess = createAction(
  '[Memberships] Load Reward Preference Success',
  props<{ rewardPreference: RewardPreference }>()
);
export const loadRewardPreferenceFailure = createAction(
  '[Memberships] Load Reward Preference Failure',
  props<ErrorState>()
);
