import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export type ConfigurationChangeAttemptStatus = 'successful' | 'processing' | 'failed';

export interface ConfigurationChange {
  id: string;
  type: string;
  payload: {
    data: any;
    eventType: string;
  };
  version: number;
  tenantId: string;
  target?: string;
  overallStatus: ConfigurationChangeAttemptStatus;
  configurationChangeAttempts: ConfigurationChangeAttempt[];
}

export interface ConfigurationChangeAttempt {
  id: string;
  clientId: string;
  status: ConfigurationChangeAttemptStatus;
  configurationChangeId: string;
  response: object;
}

export interface ConfigurationChangesRequestParams {
  targets?: string[];
  type?: string;
  onlyLatest?: boolean;
  limit?: number;
  page?: number;
}

export const configurationChangeAdapter = createEntityAdapter<ConfigurationChange>({
  selectId: (configurationChange: ConfigurationChange): string => configurationChange.id
});

export interface ConfigurationChangeState extends EntityState<ConfigurationChange>, CallState {
  count: number;
}

export const configurationChangeInitialState: ConfigurationChangeState = {
  ...configurationChangeAdapter.getInitialState<CallState>(initialCallState),
  count: 0
};
