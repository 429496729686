import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { SentinelConfig } from '../../types/sentinel-configs.type';

export const loadSentinelConfig = createAction('[Sentinel Config] Load Sentinel Config', props<{ appId: string }>());
export const loadSentinelConfigSuccess = createAction(
  '[Sentinel Config] Load Sentinel Config Success',
  props<{ sentinelConfig: SentinelConfig; appId: string }>()
);
export const loadSentinelConfigFailure = createAction(
  '[Sentinel Config] Load Sentinel Config Failure',
  props<ErrorState>()
);
