import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { BenefitResult } from '../types/benefits.type';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {
  constructor(private missionControlService: MissionControlService) {}

  getBenefits(userId: string, locale: string): Observable<BenefitResult> {
    return this.missionControlService.get<BenefitResult>(
      `users/${userId}/benefit_trackers`,
      null,
      this.missionControlService.buildLocaleHeader(locale)
    );
  }
}
