import { Tag, TagType } from '@shared/types';

class AuditLogsFilterBase {
  entity?: string;
  action?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: {
    createdAt: 'asc' | 'desc' | '';
  };
  limit: number;
  page: number;

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}

// represents a filter as it's handled internally in AP
export class AuditLogsFilter extends AuditLogsFilterBase {
  tags?: Tag[];
}

// represents a filter as it's sent in requests to MC
export class MissionControlAuditLogsFilter extends AuditLogsFilterBase {
  tags: {
    [key in TagType]?: string;
  };
}
