import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { CustomerToken } from '../types/analytics.type';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private missionControlService: MissionControlService) {}

  getCustomerToken(): Observable<CustomerToken> {
    return this.missionControlService.get<CustomerToken>('analytics/customer_token');
  }
}
