import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { GiftCardsFilter } from './gift-cards-filter.type';

export interface UpdateGiftCardParams {
  id: string;
  description?: string;
  shortDescription?: string;
  approved?: boolean;
  categories?: string[];
  redemptionInstructions?: string[];
  termsAndConditionsUrl?: string;
  tenantId?: string;
  name?: string;
  expiryInDays?: number;
}

export interface GiftCard {
  active: boolean;
  approved: boolean;
  categories: string[];
  children: ChildCard[];
  countryCode: string;
  currency: string;
  deliveryMethod: string;
  description: string;
  denominations: number[];
  displayTypes: string[];
  giftCardSourceId: string;
  id: string;
  images: string[];
  logo: string;
  margin: Margin;
  markup: Markup;
  maxDiscountPercentage: number;
  name: string;
  productImage: string;
  redemptionChannel: string;
  shortDescription: string;
  sku: string;
  supplier: string;
  termsAndConditions: string;
  termsAndConditionsUrl: string;
  validityInDays: number;
  redemptionInstructions: string[];
  tenantId: string;
  supplierDenominations: SupplierDenominations;
}

export type SupplierDenominations = FixedDenomination | RangedDenomination;

export interface FixedDenomination {
  fixed: number[];
}

export interface RangedDenomination {
  ranged: {
    min: number;
    max: number;
  };
}

export interface ChildCard {
  active: boolean;
  approved: boolean;
  deliveryMethod: string;
  denominations: number[];
  displayTypes: string[];
  id: string;
  maxDiscountPercentage: number;
  rebate: number;
  supplier: string;
  supplierSku: string;
  type: string;
  validityInDays: number;
}

export class Margin {
  giftCardSourceId: number;
  value: number;
  type: string;
}

export class Markup {
  value: number;
  type: string;
}

export type ConfigType = 'Margin' | 'Markup';

export enum DisplayType {
  Digital = 'digital',
  Physical = 'physical'
}

export enum DisplayIdType {
  Digital = 'digital',
  Physical = 'physical',
  Merged = 'merged'
}

export const giftCardAdapter = createEntityAdapter<GiftCard>();

export interface GiftCardState extends EntityState<GiftCard>, CallState {
  count: number;
  filter: GiftCardsFilter;
}

export const initialState: GiftCardState = {
  ...giftCardAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  filter: null
};
