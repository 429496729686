import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, HostListener, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { ObjectUtils } from '@utils';

import { OpenApiSchema } from '../../types/open-api-schema.type';

@Component({
  selector: 'admin-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['../form-field-shared.scss']
})
export class NumberInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() fieldLabel: string;
  @Input() formControlName: string;
  @Input() schema: OpenApiSchema;

  MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER;
  MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER;

  value: string;
  errorMessage: string;
  hint: string;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  /* eslint-enable */

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    const numericValue = Number(value);

    const isNotNumber = isNaN(numericValue) || ObjectUtils.isNullish(value);

    if (isNotNumber) {
      this.value = null;
      this.onChange(null);
    } else {
      this.value = value;
      this.onChange(numericValue);
    }

    this.validateNumber();
  }

  /* non-interface implementations */
  ngAfterViewInit(): void {
    // to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => this.validateNumber());
  }

  validateNumber(): void {
    this.hint = null;

    const { exclusiveMaximum, exclusiveMinimum, maximum, minimum, multipleOf } = this.schema;

    if (this.ngControl.control.errors?.required) {
      this.errorMessage = 'Required';
      return;
    }

    const value = parseFloat(this.value);

    if (!isNaN(exclusiveMaximum) && value >= exclusiveMaximum) {
      return this.markAsInvalid(`Value should be lesser than ${exclusiveMaximum}`);
    }

    if (!isNaN(exclusiveMinimum) && value <= exclusiveMinimum) {
      return this.markAsInvalid(`Value should be greater than ${exclusiveMinimum}`);
    }

    if (!isNaN(maximum) && value > maximum) {
      return this.markAsInvalid(`Value should be lesser or equal to ${maximum}`);
    }

    if (!isNaN(maximum) && value < minimum) {
      return this.markAsInvalid(`Value should be greater or equal to ${minimum}`);
    }

    if (!isNaN(multipleOf) && value % multipleOf !== 0) {
      return this.markAsInvalid(`Value should be multiple of ${multipleOf}`);
    }

    if (value > this.MAX_SAFE_INTEGER) {
      return this.markAsInvalid(`Value should be lesser or equal to ${this.MAX_SAFE_INTEGER}`);
    }

    if (value < this.MIN_SAFE_INTEGER) {
      return this.markAsInvalid(`Value should be greater or equal to ${this.MIN_SAFE_INTEGER}`);
    }

    // For handling the case when decimal value is too long
    // See more here: https://stackoverflow.com/a/54803254
    if (value.toString() !== this.value) {
      this.hint = `Value will be rounded to ${value.toString()}`;
    }

    this.ngControl.control.setErrors(null);
    this.errorMessage = null;
  }

  markAsInvalid(message: string): void {
    this.ngControl.control.setErrors({ invalidNumber: true });
    this.errorMessage = message;
  }

  /* interface implementations */
  onChange = (_value: number): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string | number): void {
    const numericValue = Number(value);
    this.value = isNaN(numericValue) || ObjectUtils.isNullish(value) ? null : numericValue.toString();
  }
}
