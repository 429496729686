var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var Formatters = /** @class */ (function () {
    function Formatters() {
    }
    Formatters.fromSnakeToCamelCase = function (str) {
        return str === null || str === void 0 ? void 0 : str.toLowerCase().replace(/[^a-zA-Z\d]+(.)/g, function (_, chr) { return chr.toUpperCase(); });
    };
    /**
     * assumes only snake_case keys
     * supports nested keys
     */
    Formatters.fromSnakeToCamelCaseKeys = function (obj) {
        var _this = this;
        if (!this.isObject(obj)) {
            return obj;
        }
        return Object.assign.apply(Object, __spreadArrays([{}], Object.entries(obj).map(function (_a) {
            var _b;
            var k = _a[0], v = _a[1];
            return (_b = {},
                _b[_this.fromSnakeToCamelCase(k)] = _this.isObject(obj) ? _this.fromSnakeToCamelCaseKeys(v) : v,
                _b);
        })));
    };
    Formatters.isObject = function (obj) {
        return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
    };
    return Formatters;
}());
export { Formatters };
