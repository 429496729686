export class OrderItemsFilter {
  id?: string; // order item id
  page: number;
  limit: number;
  orderId?: string;
  userId?: string;
  dateTo?: string;
  dateFrom?: string;
  type?: string;
  description?: string;
  status?: string;
  sortBy?: string;
  sortDirection?: string;
  isTopLevelView?: boolean;
  externalSupplierOrderReference?: string;
  externalSupplierOrderItemReference?: string;

  constructor() {
    this.page = 1;
    this.limit = 20;
    this.sortBy = 'createdAt';
    this.sortDirection = 'desc';
  }
}
