import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { recommendedSegmentsQuery } from '../../../recommendations/store/selectors/recommended-segments.selectors';
import { SegmentCreationService } from '../../../segments/services/segment-creation.service';

@Component({
  selector: 'admin-recommendation-summary-list',
  template: `
    <div class="mb-6 flex items-center gap-2">
      <mat-icon svgIcon="sparkle-hollow" class="!w-5"></mat-icon>
      <span class="mr-auto text-base font-bold text-cloudburst-500">{{ title }}</span>
      <button mat-icon-button (click)="closed.emit()">
        <div class="customized-close-icon">
          <ng-content select="close-icon" />
        </div>
        <mat-icon svgIcon="chevron-left-bold" class="scale-x-[-1]"></mat-icon>
      </button>
    </div>

    @if (loading()) {
      <admin-spinner></admin-spinner>
    } @else if (error()) {
      <admin-empty-state-page entity="Recommendation"></admin-empty-state-page>
    } @else if (recommendations() && recommendations().length > 0) {
      <div class="flex flex-col gap-3">
        @for (recommendation of recommendations(); track $index) {
          <admin-recommendation-summary
            [recommendation]="recommendation"
            (click)="closed.emit()"
          ></admin-recommendation-summary>
        }
      </div>
    }
  `,
  styles: `
    .customized-close-icon:not(:empty) + mat-icon {
      display: none;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationSummaryListComponent implements OnInit {
  @Input() title = 'Recommendations';

  @Output() closed = new EventEmitter<void>();

  readonly store = inject(Store);
  readonly recommendations = this.store.selectSignal(recommendedSegmentsQuery.getRecommendedSegmentsList);
  readonly loading = this.store.selectSignal(recommendedSegmentsQuery.isBatchLoading);
  readonly error = this.store.selectSignal(recommendedSegmentsQuery.getBatchError);
  private readonly segmentCreationService = inject(SegmentCreationService);

  ngOnInit(): void {
    // Need to load the segment attributes to show rules data
    // or else the logic rules summary will be empty
    this.segmentCreationService.loadSegmentAttributes();
  }
}
