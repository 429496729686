<div class="dialog-content details-view" [class.overlay]="pointsAdjustmentLoading$ | async">
  <admin-spinner *ngIf="pointsAdjustmentLoading$ | async"></admin-spinner>

  <div mat-dialog-title>Adjust balance</div>

  <form [formGroup]="pointsAdjustmentForm" (keydown.enter)="$event.preventDefault()">
    <ng-container *ngIf="!hasOnlyCustomOption">
      <mat-form-field [formGroup]="metadataControl">
        <mat-label>Reason</mat-label>
        <mat-select formControlName="reason" disableOptionCentering (selectionChange)="onReasonChange($event)">
          <mat-option *ngFor="let option of allowedOptions" [value]="option.reason">
            {{ option.reason | customTitleCase: 'fromSnakeCase' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="text-display" [innerHTML]="reasonExplanation[selectedOption.reason]"></div>
    </ng-container>

    <ng-container *ngIf="descriptions.length">
      <mat-form-field>
        <mat-label>Loyalty statement descriptor</mat-label>
        <mat-select formControlName="description" required disableOptionCentering>
          <mat-option *ngFor="let description of descriptions" [value]="description">
            {{ isDefaultDescription ? defaultDescriptionLabels[description] : description }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngFor="let metadata of selectedOption.metadata">
      <ng-container [ngSwitch]="metadata.type">
        <ng-container *ngSwitchCase="'year'">
          <admin-year-picker
            styleClassName="w-full"
            [inputControl]="metadataControl"
            [controlName]="metadata.key"
            [label]="metadata.key | customTitleCase: 'fromSnakeCase'"
            [required]="metadata.required"
            (yearSelected)="pointsAdjustmentForm.updateValueAndValidity()"
          ></admin-year-picker>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="admin-form-fields-grid-wrapper">
      <div class="admin-form-fields-grid-wrapper">
        <mat-button-toggle-group formControlName="category" aria-label="Category" class="button-toggle-group">
          <mat-button-toggle
            *ngIf="selectedOption.categories.includes('accrual')"
            value="accrual"
            class="add-button"
            [class.activated]="pointsAdjustmentForm.controls.category.value === 'accrual'"
          >
            <span class="medium-text">+ Add</span>
          </mat-button-toggle>
          <mat-button-toggle
            *ngIf="selectedOption.categories.includes('redemption')"
            value="redemption"
            class="deduct-button"
            [class.activated]="pointsAdjustmentForm.controls.category.value === 'redemption'"
          >
            <span class="medium-text">- Deduct</span>
          </mat-button-toggle>
          <mat-button-toggle
            *ngIf="selectedOption.categories.includes('reversal')"
            value="reversal"
            class="deduct-button"
            [class.activated]="pointsAdjustmentForm.controls.category.value === 'reversal'"
          >
            <span class="medium-text">- Deduct</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <mat-form-field>
        <mat-label>Amount of {{ loyaltyCurrency }}</mat-label>
        <mat-icon
          matPrefix
          *ngIf="!additionAllowed || !deductionAllowed"
          [matTooltip]="
            !additionAllowed ? 'Addition is not allowed' : !deductionAllowed ? 'Deduction is not allowed' : ''
          "
          >block</mat-icon
        >

        <input matInput type="number" [min]="min" [step]="step" required formControlName="amount" />
        <mat-error *ngIf="pointsAdjustmentForm.controls.amount.hasError('required')">Required</mat-error>
        <mat-error *ngIf="pointsAdjustmentForm.controls.amount.hasError('min')"
          >Must be greater than or equal to {{ min }}</mat-error
        >
        <mat-error *ngIf="pointsAdjustmentForm.controls.amount.hasError('max')"
          >Must be less than or equal to {{ max }}</mat-error
        >
        <mat-hint *ngIf="pointsAdjustmentForm.hasError('invalidAmount')" class="mat-error">
          <ng-container *ngIf="pointsAdjustmentDecimals; else elseBlock">
            Fractions are supported up to {{ pointsAdjustmentDecimals }} digits
          </ng-container>
          <ng-template #elseBlock>Amount must be an integer</ng-template>
        </mat-hint>
        <mat-hint *ngIf="pointsAdjustmentForm.hasError('invalidBalanceResult')" class="mat-error">
          Resulting points balance cannot be less than 0
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="selectedOption.customExpiry" class="admin-form-fields-grid-wrapper">
      <mat-form-field>
        <mat-label>Expiry rule</mat-label>
        <mat-select formControlName="expiryRule" disableOptionCentering>
          <mat-option [value]="'default'">{{ defaultExpiryRule ?? 'Default' }}</mat-option>
          <mat-option [value]="1">1 months</mat-option>
          <mat-option [value]="2">2 months</mat-option>
          <mat-option [value]="3">3 months</mat-option>
          <mat-option [value]="'custom'">Custom</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="
          (defaultExpiryRule === 'No expiry (Default)'
            ? pointsAdjustmentForm.controls.expiryRule.value !== 'default'
            : null) || defaultExpiryRule !== 'No expiry (Default)'
        "
      >
        <mat-label>Expiry date</mat-label>
        <input
          matInput
          formControlName="expiryDate"
          [matDatepicker]="picker"
          [min]="minExpiryDate"
          (focus)="picker.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="pointsAdjustmentForm.controls.expiryDate.hasError('required')">Required</mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngFor="let metadata of selectedOption.metadata">
      <ng-container [ngSwitch]="metadata.type">
        <ng-container *ngSwitchCase="'text'">
          <ng-container [ngSwitch]="metadata.key">
            <ng-container *ngSwitchCase="'notes'">
              <mat-form-field [formGroup]="metadataControl" class="textarea-field">
                <mat-label>Notes for the changes</mat-label>
                <textarea
                  matInput
                  type="text"
                  placeholder="Notes for the changes"
                  (change)="pointsAdjustmentForm.updateValueAndValidity()"
                  [formControlName]="metadata.key"
                  [required]="metadata.required"
                  rows="5"
                >
                </textarea>
                <mat-hint *ngIf="!metadata.required">Optional</mat-hint>
              </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'approver_name'">
              <mat-form-field [formGroup]="metadataControl">
                <mat-label>Name of approver</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Name of Approver"
                  (change)="pointsAdjustmentForm.updateValueAndValidity()"
                  [formControlName]="metadata.key"
                  [required]="metadata.required"
                />
                <mat-hint *ngIf="!metadata.required">Optional</mat-hint>
                <mat-error *ngIf="metadata.required">Required</mat-error>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>

  <div class="text-display" *ngIf="pointsAdjustmentForm.valid">
    {{ adjustmentText }}
  </div>

  <div mat-dialog-actions>
    <button class="secondary-button" (click)="dialogRef.close(false)">Cancel</button>
    <button
      type="submit"
      [disabled]="pointsAdjustmentForm.invalid"
      [ngClass]="pointsAdjustmentForm.invalid ? 'disabled-button' : 'primary-button'"
      (click)="confirmChangesClicked()"
    >
      Submit
    </button>
  </div>
</div>
