import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface FraudList {
  id: string;
  name: string;
  description?: string;
  metrics: string[];
  groupBy: string[];
  tags: string[];
  threshold: number;
  action: FraudListAction;
  topKData?: object;
  createdAt?: string;
  updatedAt?: string;
  size?: number;
}

export enum FraudListAction {
  'Alert Datadog' = 'alert_datadog',
  'Alert Slack' = 'send_slack_notification',
  'Blacklist User' = 'blacklist_user',
  'Block User' = 'block_user',
  'Email Alert' = 'send_email_alert',
  'Block IP' = 'block_ip',
  'No Action' = 'noop'
}

export const reverseFraudListAction = Object.fromEntries(Object.entries(FraudListAction).map(a => a.reverse()));
export const fraudListAdapter = createEntityAdapter<FraudList>();

export interface FraudListState extends EntityState<FraudList>, CallState {}

export const initialState: FraudListState = fraudListAdapter.getInitialState<CallState>(initialCallState);
