import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { AutoRedemption } from '../../types/auto-redemptions.type';

export const loadUserAutoRedemptions = createAction(
  '[Auto Redemptions] Load User Auto Redemptions',
  props<{ userId: string }>()
);
export const loadUserAutoRedemptionsSuccess = createAction(
  '[Auto Redemptions] Load User Auto Redemptions Success',
  props<{ autoRedemptions: AutoRedemption[] }>()
);
export const loadUserAutoRedemptionsFailure = createAction(
  '[Auto Redemptions] Load User Auto Redemptions Failure',
  props<ErrorState>()
);
