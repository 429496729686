import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { ApprovalRequestStatistics } from '../types/approval-request-statistics.type';
import { ApprovalRequestsFilter } from '../types/approval-requests-filter.type';
import { ApprovalRequestsResult } from '../types/approval-requests-result.type';
import { ApprovalRequest } from '../types/approval-requests.type';
import { ApprovalRequestResponse } from '../types/approval-responses.type';

@Injectable({
  providedIn: 'root'
})
export class ApprovalRequestsService {
  constructor(private missionControlService: MissionControlService) {}

  getApprovalRequest(id: string): Observable<ApprovalRequest> {
    return this.missionControlService.get<ApprovalRequest>(`approval_requests/${id}`);
  }

  getApprovalRequests(filter: ApprovalRequestsFilter): Observable<ApprovalRequestsResult> {
    filter = ObjectUtils.sanitizeRequestObject<ApprovalRequestsFilter>(filter);
    const { sortBy, pointsAdjustmentFilter, ...restFilterFields } = filter;

    let params = ObjectUtils.prepareQueryObject(restFilterFields);

    params = FilterUtils.appendHashQueryParam(params, 'sortBy', 'createdAt', sortBy?.createdAt!);
    params = FilterUtils.appendHashQueryParam(params, 'sortBy', 'status', sortBy?.status!);

    if (pointsAdjustmentFilter) {
      const { description, ...rest } = pointsAdjustmentFilter;
      params = {
        ...FilterUtils.appendHashQueryParam(params, 'requestParams', 'description', description!),
        ...rest
      };
    }

    return this.missionControlService.get<ApprovalRequestsResult>(
      'approval_requests',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  respondToApprovalRequest(respondParams: ApprovalRequestResponse): Observable<ApprovalRequest> {
    respondParams = ObjectUtils.sanitizeRequestObject<ApprovalRequestResponse>(respondParams);

    return this.missionControlService.post<ApprovalRequest>(
      `approval_requests/${respondParams.id}/respond`,
      respondParams
    );
  }

  getApprovalRequestStatistics(): Observable<ApprovalRequestStatistics> {
    return this.missionControlService.get<ApprovalRequestStatistics>('approval_requests/statistics');
  }
}
