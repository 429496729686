import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { QueryParamService } from '@shared/services/query-param/query-param.service';
import { TagType } from '@shared/types';
import { BracketParamsEncoder, ObjectUtils } from '@utils';

import { EventsFilter } from '../types/events-filter.type';
import { EventsResult, MissionControlEventsResult } from '../types/events-result.type';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private missionControlService: MissionControlService,
    private queryParamService: QueryParamService
  ) {}

  getEvents(filter: EventsFilter): Observable<EventsResult> {
    const { isFromUserView, ...restFilter } = filter;
    const queryParams = this.queryParamService.prepareQueryParamObject(restFilter);

    return this.missionControlService
      .get<MissionControlEventsResult>(
        'events',
        new HttpParams({ fromObject: { ...queryParams }, encoder: new BracketParamsEncoder() })
      )
      .pipe(map(result => this.mapResultToAdminPanelFormat(result)));
  }

  private mapResultToAdminPanelFormat(result: MissionControlEventsResult): EventsResult {
    return {
      ...result,
      data: result.data.map(event => ({
        ...event,
        tags: Object.entries(event.tags || [])
          .flatMap(([type, id]: [TagType, string]) => {
            // this is to ignore the legacy tags format of array e.g. identityId: ['google_auth', 'userID']
            if (Array.isArray(id)) {
              return null;
            } else if (ObjectUtils.isObject(id)) {
              return Object.entries(id).map(([innerType, innerId]: [TagType, string]) => ({
                type: innerType,
                id: innerId
              }));
            } else {
              return { type, id };
            }
          })
          .filter(tag => !!tag)
      }))
    };
  }
}
