// This is generated by https://transform.tools/json-schema-to-typescript
// Intentionally NOT TO extract shared types to make it easier to maintain for now
// We can create shared types for AP use but not replace the type in UiSettings
// TODO: Think about how to make it easier to manage and maintain
import { SUPPORTED_GOOGLE_FONTS } from '../../../utils/google-fonts';

export const productTypes = [
  'gift-card',
  'carbon',
  'points-transfer',
  'crypto',
  'cashback',
  'offer',
  'travel'
] as const;
export type ProductType = (typeof productTypes)[number];

export enum ProductTypeCategoriesMapping {
  sustainability = 'carbon',
  crypto = 'crypto',
  cashback_to_card = 'cashback',
  cashback_to_account = 'cashback',
  cashback_to_platform = 'cashback',
  cashback_to_tenant = 'cashback',
  airline = 'points-transfer',
  hotel = 'points-transfer'
}

export const carouselVariants = ['carousel-1', 'carousel-2'] as const;
export type CarouselVariantType = (typeof carouselVariants)[number];

export type MarketingBannerVariantType = 'marketing-banner-1' | 'marketing-banner-2';

export type TextAlignType = 'text-left' | 'text-center' | 'text-right' | 'text-justify';

export type TextTransformType = 'uppercase' | 'lowercase' | 'capitalize' | 'normal-case' | 'none';

export type ImageStyleType = 'fit' | 'fill';

export interface MarketingBanner {
  order: number;
  schemaType: 'marketing-banner';
  data: {
    image: {
      url: string;
      alt?: string;
    };
    title: {
      content: string;
      textTransform?: TextTransformType;
    };
    body: {
      content: string;
      textTransform?: TextTransformType;
    };
    label?: string;
    cta?: {
      content: string;
      href: string;
    };
    config: {
      variant: MarketingBannerVariantType;
      textAlign: TextAlignType;
      imageStyle?: ImageStyleType;
    };
  }[];
}

export interface QuickLinks {
  order: number;
  schemaType: 'quick-links';
  data: {
    dataType: 'listing' | 'detail';
    id?: string;
    image: {
      url: string;
      alt?: string;
    };
    productType: ProductType;
    url?: string;
    openInNewTab?: boolean;
    title: string;
  }[];
  config: {
    title: string;
    tileBackgroundColor?: string;
    backgroundColor?: string;
  };
}

export interface Carousel {
  order: number;
  schemaType: 'carousel';
  data: {
    image: {
      url: string;
      alt?: string;
    };
    title: {
      content: string;
      textTransform?: TextTransformType;
    };
    body: {
      content: string;
      textTransform?: TextTransformType;
    };
    label?: string;
    variant: CarouselVariantType;
  }[];
}

export interface FeaturedProducts {
  order: number;
  schemaType: 'featured-products';
  data: {
    id?: string;
    image: {
      url: string;
      alt?: string;
    };
    productType: ProductType;
    url?: string;
    openInNewTab?: boolean;
    title?: string;
  }[];
  config: {
    title: string;
    tileBackgroundColor?: string;
    backgroundColor?: string;
  };
}

export interface Personalization {
  order?: number;
  schemaType: 'personalization';
  config: {
    title: string;
    tileBackgroundColor?: string;
    backgroundColor?: string;
  };
  fallbackData: {
    orderItemType:
      | 'PointsTransfer'
      | 'GiftCard'
      | 'Sustainability'
      | 'Crypto'
      | 'TravelBooking'
      | 'Voucher'
      | 'Merchandise';
    id: string;
    score: number;
  }[];
}

export interface RedeemAgain {
  order?: number;
  schemaType: 'redeem-again';
}

export interface SpendXPoints {
  order?: number;
  schemaType: 'spend-x-points';
}

type TextSchema = {
  text: string;
  color: string | null;
  transform: TextTransformType | null;
  className?: string | undefined;
};

export type FeatureSchema = {
  enabled: boolean;
  title: TextSchema;
  description: TextSchema | null;
  listingImageUrl: string | null;
  productImageUrl: string | null;
};

export type HomepageItem =
  | QuickLinks
  | FeaturedProducts
  | Carousel
  | MarketingBanner
  | Personalization
  | RedeemAgain
  | SpendXPoints;
export interface Homepage {
  'quick-links'?: QuickLinks[];
  'featured-products'?: FeaturedProducts[];
  carousel?: Carousel[];
  'marketing-banner'?: MarketingBanner[];
  personalization?: Personalization[];
  'redeem-again'?: RedeemAgain[];
  'spend-x-points'?: SpendXPoints[];
}

export interface ApplicationComponents {
  breadcrumbs?: {
    enabled: boolean;
  };
  links?: {
    openExternalLinksInSameTab: boolean;
  };
  header: {
    backgroundColor: string;
    showLogo: boolean;
    color: string;
    logo: 'primary' | 'inverted';
    enabled?: boolean;
    avatar?: 'primary' | 'inverted';
  };
  footer: {
    variant: 'footer-1' | 'footer-2' | 'footer-3' | 'footer-4' | 'footer-5' | 'footer-6';
    content?: string;
    backgroundColor: string;
    color: string;
    footerLogo: string;
    logo: 'primary' | 'inverted';
    textAlign: 'text-left' | 'text-center' | 'text-right' | 'text-justify';
    textDecoration: 'underline' | 'overline' | 'line-through' | 'no-underline';
    textTransform: TextTransformType;
    enabled?: boolean;
    hideBranding: boolean;
  };
  buttons: {
    primary: {
      borderRadius: string;
      borderWidth: string;
    };
    secondary: {
      borderRadius: string;
      borderWidth: string;
    };
    config?: {
      padding?: {
        large?: string;
        medium?: string;
      };
    };
  };
  navigation?: {
    hoverBackgroundColor?: string;
    descriptionColor?: string;
    orderedMenuItems?: {
      name:
        | 'giftCard'
        | 'sustainability'
        | 'pointsTransfer'
        | 'crypto'
        | 'cashback'
        | 'travel'
        | 'travelEarn'
        | 'offer'
        | 'featureFlag';
      iconVisible?: boolean;
    }[];
  };
  spinner?: {
    loadingImage?: {
      alt?: string;
      height?: string;
      width?: string;
      src: string;
    };
  };
}

export interface Features {
  sustainability: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  giftCard: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  cashback: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  crypto: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  offer: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  pointsTransfer: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  affiliateOffer: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  cardLinkedOffer: {
    enabled: boolean;
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    description?: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    productImageUrl: string;
  };
  rewardsSummary: {
    title: {
      text: string;
      color: string;
      transform: TextTransformType;
      className?: string;
    };
    listingImageUrl: string;
    pointsActivity?: {
      enabled?: boolean;
    };
  };
  helpCenter: {
    listing: {
      title: {
        text: string;
        color: string;
        transform: TextTransformType;
        className?: string;
      };
      imageUrl: string;
    };
    contactUs: {
      title: {
        text: string;
        color: string;
        transform: TextTransformType;
        className?: string;
      };
      imageUrl: string;
      externalContactUsUrl: string;
    };
    customerService: {
      imageUrl: string;
    };
    privacyPolicy: {
      imageUrl: string;
    };
    termsAndConditions: {
      imageUrl: string;
    };
    email: {
      enabled: boolean;
      emailAddress: string;
    };
    phone: {
      enabled: boolean;
      contactNumber: string;
      hours: string;
    };
  };
  travel: {
    enabled: boolean;
    features: {
      flights: {
        enabled: boolean;
        title: {
          text: string;
          color: string;
          transform: TextTransformType;
          className?: string;
        };
        description?: {
          text: string;
          color: string;
          transform: TextTransformType;
          className?: string;
        };
        listingImageUrl: string;
      };
      hotels: {
        enabled: boolean;
        title: {
          text: string;
          color: string;
          transform: TextTransformType;
          className?: string;
        };
        description?: {
          text: string;
          color: string;
          transform: TextTransformType;
          className?: string;
        };
        listingImageUrl: string;
      };
      cars: {
        enabled: boolean;
        title: {
          text: string;
          color: string;
          transform: TextTransformType;
          className?: string;
        };
        description?: {
          text: string;
          color: string;
          transform: TextTransformType;
          className?: string;
        };
        listingImageUrl: string;
      };
    };
  };
  travelEarn: {
    enabled: boolean;
  };
  pointsEarn?: {
    enabled?: boolean;
  };
  termsAndConditions: {
    enabled: boolean;
  };
}

export type SupportedFonts = (typeof SUPPORTED_GOOGLE_FONTS)[number];

export interface Theme {
  contentBackgroundColor?: string;
  brandLogo: string;
  favicon: string;
  bodyFont: SupportedFonts;
  headingFont: SupportedFonts;
  invertedLogo: string;
  colors: {
    primary: string;
    secondary: string;
    accent: string;
    body?: string;
  };
  iconStyle:
    | 'fa-solid'
    | 'fa-regular'
    | 'solid'
    | 'light'
    | 'regular'
    | 'sharp-light'
    | 'sharp-regular'
    | 'sharp-solid'
    | 'duotone'
    | 'thin';
  overlayStyle?: string;
  customBorderRadius?: string;
}

export interface UiSettings {
  application: {
    homepage: Homepage;
    tenantName: string;
    components: ApplicationComponents;
    features: Features;
    theme: Theme;
  };
  platform: {
    auth: {
      sessionCheckPollInterval: number;
      idleCheckConfig: {
        timeout: number;
        promptTimeout: number;
      };
      scope: string;
      appId: string;
      connection: string;
    };
  };
}
