import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AppsService } from '../../services/apps.service';
import {
  loadSentinelConfig,
  loadSentinelConfigFailure,
  loadSentinelConfigSuccess
} from '../actions/sentinel-configs.actions';

@Injectable()
export class SentinelConfigsEffects {
  loadSentinelConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSentinelConfig),
      exhaustMap(action =>
        this.appsService.getSentinelConfig(action.appId).pipe(
          map(sentinelConfig => loadSentinelConfigSuccess({ sentinelConfig, appId: action.appId })),
          catchError(error => of(loadSentinelConfigFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private appsService: AppsService
  ) {}
}
