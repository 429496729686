import { distinctUntilChanged, skip } from 'rxjs/operators';

import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { nydusNetworkBootstrapQuery } from '@core/store/nydus-network/selectors/nydus-network-bootstrap.selectors';
import { NydusNetworkBootstrapState } from '@core/types';
import { or } from '@utils';

@Component({
  selector: 'admin-locale-switcher',
  templateUrl: './locale-switcher.component.html',
  styleUrls: ['./locale-switcher.component.scss']
})
export class LocaleSwitcherComponent implements OnInit {
  @Input() initialLocale: string;
  @Input() parentElementLoading$: Observable<boolean>;
  @Output() localeChanged = new EventEmitter<string>();
  @Input() showLabel = true;

  destroyRef = inject(DestroyRef);
  fb = inject(FormBuilder);

  localeControl: FormControl<string>;
  locales: string[];

  constructor(private nydusNetworkBootstrapStore: Store<NydusNetworkBootstrapState>) {}

  ngOnInit(): void {
    this.localeControl = this.fb.nonNullable.control<string>({ value: this.initialLocale, disabled: true });

    this.createDropdownList();
    this.subscribeToLocaleChange();
    this.handleFormControlAbility();
  }

  createDropdownList(): void {
    this.nydusNetworkBootstrapStore
      .select(nydusNetworkBootstrapQuery.getNydusNetworkBootstrapLocales)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(locales => (this.locales = locales.map(locale => locale.code)));
  }

  subscribeToLocaleChange(): void {
    this.localeControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), skip(1))
      .subscribe(locale => {
        this.localeChanged.emit(locale);
      });
  }

  handleFormControlAbility(): void {
    or(this.nydusNetworkBootstrapStore.select(nydusNetworkBootstrapQuery.isSingleLoading), this.parentElementLoading$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isLoading => {
        if (isLoading) {
          this.localeControl.disable();
        } else if (this.locales.length > 0) {
          this.localeControl.enable();
        }
      });
  }
}
