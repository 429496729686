import { AbstractControl, ValidatorFn } from '@angular/forms';

export function characterCountValidator(countLimit: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    if (control.value.trimStart().length > countLimit) {
      return { exceedCharacterCount: true };
    } else {
      return null;
    }
  };
}
