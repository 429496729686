import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';

import {
  hideRecommendation,
  unhideRecommendation
} from '../../../recommendations/store/actions/recommendations.actions';
import { RecommendationState, RecommendedCampaign, RecommendedSegment } from '../../../recommendations/types';
import { RecommendationReviewComponent } from '../recommendation-review/recommendation-review.component';

@Component({
  selector: 'admin-recommendation-summary',
  templateUrl: './recommendation-summary.component.html',
  styleUrl: './recommendation-summary.component.scss'
})
export class RecommendationSummaryComponent {
  @Input({ required: true }) recommendation: RecommendedSegment | RecommendedCampaign;

  constructor(
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<RecommendationState>
  ) {}

  hideRecommendation(event: MouseEvent): void {
    event.stopPropagation();

    const id = this.recommendation.recommendationId;

    this.store.dispatch(hideRecommendation({ id, recommendationType: 'segment' }));

    const snackbarReference = this.snackBar.open('Recommendation hidden successfully', 'Undo', {
      panelClass: 'mat-snack-bar-success'
    });

    snackbarReference.onAction().subscribe(() => {
      this.store.dispatch(unhideRecommendation({ id, recommendationType: 'segment' }));
    });
  }

  openFullPageOverlay(): void {
    const currentPathWithChildren = window.location.pathname.split('/');
    const currentPage = currentPathWithChildren[1] === '' ? 'overview' : currentPathWithChildren[1];

    this.matDialog.open(RecommendationReviewComponent, {
      autoFocus: false,
      data: { recommendation: this.recommendation, currentPage }
    });
  }
}
