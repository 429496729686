import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { DayJsConfig } from '@shared/types/dayjs-config.type';

dayjs.extend(relativeTime, DayJsConfig.relativeTimeConfig);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', DayJsConfig.enLocaleConfig);

@Pipe({ name: 'timeFromNow' })
export class TimeFromNowPipe implements PipeTransform {
  transform(timeInSeconds: number): string {
    if (timeInSeconds === 0) {
      return 'instant';
    } else {
      const isFutureTime = timeInSeconds > 0;
      const calculatedTime = dayjs().add(timeInSeconds, 'second');

      return isFutureTime ? 'within ' + calculatedTime.fromNow(true) : calculatedTime.fromNow();
    }
  }
}
