import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { AccessPoliciesService } from '../../services/access-policies.service';
import {
  createAccessPolicy,
  createAccessPolicyFailure,
  createAccessPolicySuccess,
  deleteAccessPolicy,
  deleteAccessPolicyFailure,
  deleteAccessPolicySuccess,
  loadAccessPolicies,
  loadAccessPoliciesFailure,
  loadAccessPoliciesSuccess,
  loadAccessPolicy,
  loadAccessPolicyFailure,
  loadAccessPolicySuccess,
  updateAccessPolicy,
  updateAccessPolicyFailure,
  updateAccessPolicySuccess
} from '../actions/access-policies.actions';

@Injectable()
export class AccessPoliciesEffects {
  loadAccessPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAccessPolicies),
      exhaustMap(() =>
        this.accessPoliciesService.getAccessPolicies().pipe(
          map(accessPolicies => loadAccessPoliciesSuccess({ accessPolicies })),
          catchError(error => of(loadAccessPoliciesFailure({ error })))
        )
      )
    )
  );

  loadAccessPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAccessPolicy),
      exhaustMap(action =>
        this.accessPoliciesService.getAccessPolicy(action.id).pipe(
          map(accessPolicy => loadAccessPolicySuccess({ accessPolicy })),
          catchError(error => of(loadAccessPolicyFailure({ error })))
        )
      )
    )
  );

  createAccessPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAccessPolicy),
      exhaustMap(action =>
        this.accessPoliciesService.createAccessPolicy(action.accessPolicy).pipe(
          map(accessPolicy => createAccessPolicySuccess({ accessPolicy })),
          catchError(error => of(createAccessPolicyFailure({ error })))
        )
      )
    )
  );

  updateAccessPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAccessPolicy),
      exhaustMap(action =>
        this.accessPoliciesService.updateAccessPolicy(action.accessPolicy).pipe(
          map(accessPolicy => updateAccessPolicySuccess({ accessPolicy })),
          catchError(error => of(updateAccessPolicyFailure({ error })))
        )
      )
    )
  );

  deleteAccessPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAccessPolicy),
      exhaustMap(action =>
        this.accessPoliciesService.deleteAccessPolicy(action.id).pipe(
          map(() => deleteAccessPolicySuccess()),
          catchError(error => of(deleteAccessPolicyFailure({ error })))
        )
      )
    )
  );

  deleteAccessPolicySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAccessPolicySuccess),
      map(() => loadAccessPolicies())
    )
  );

  createOrUpdateAccessPolicySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAccessPolicySuccess, updateAccessPolicySuccess),
      map(() => routerNavigate({ path: 'access-policies' }))
    )
  );

  constructor(
    private actions$: Actions,
    private accessPoliciesService: AccessPoliciesService
  ) {}
}
