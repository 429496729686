import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { Tenant, tenantAdapter, TenantState } from '../../types/tenants.type';

const tenantsDetector = createDetector<Tenant>();
const getTenantState = createFeatureSelector<TenantState>('tenant');

const { selectAll: getTenantsList, selectEntities: getTenantsDirectory } = tenantAdapter.getSelectors(getTenantState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError
} = getCallStateSelectors<TenantState>(getTenantState);

const isTenantsLoaded = createSelector(
  getTenantsDirectory,
  isBatchResting,
  (tenants, batchResting) => tenantsDetector('allLoaded')(tenants) && batchResting
);

const getTenantById = (id: string): Selector<TenantState, Tenant> =>
  createSelector(getTenantsDirectory, entities => entities[id]);

const isTenantLoaded = (id: string): Selector<TenantState, boolean> =>
  createSelector(getTenantsDirectory, tenants => !!(tenantsDetector('oneLoaded')(tenants, id) && isSingleResting));

const getSettingsSchema = createSelector(getTenantsList, tenants => tenants[0]?.settingsSchema.schema);

const getUiEnvironmentSchema = createSelector(getTenantsList, tenants => tenants[0]?.ui_environment_schema.schema);

export const tenantsQuery = {
  getTenantById,
  isTenantLoaded,
  isTenantsLoaded,
  getTenantsList,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError,
  getSettingsSchema,
  getUiEnvironmentSchema
};
