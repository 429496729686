import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { BatchCreateDeletePermissionsParams, Role, RoleGeneral } from '../types';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  constructor(private missionControlService: MissionControlService) {}

  getRoles(): Observable<Role[]> {
    return this.missionControlService.get<Role[]>('roles');
  }

  getRole(name: string): Observable<Role> {
    return this.missionControlService.get<Role>(`roles/${name}`);
  }

  createRole(role: Role): Observable<Role> {
    role = ObjectUtils.sanitizeRequestObject<Role>(role);

    return this.missionControlService.post<Role>('roles', role);
  }

  updateRolePermissions(permissions: BatchCreateDeletePermissionsParams, role?: RoleGeneral): Observable<Role> {
    permissions = ObjectUtils.sanitizeRequestObject<BatchCreateDeletePermissionsParams>(permissions);

    return this.missionControlService.patch<Role>('roles/update', { role, permissions });
  }
}
