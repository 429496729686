<div class="main-container">
  <div class="page-title-wrapper" [class.user-view-page-title-wrapper]="isUserView">
    <h1 *ngIf="!isUserView">Orders</h1>
    <div *adminIfScopesInclude="['admin_checkout']" [matTooltip]="tooltipMessage$ | async">
      <button
        *ngIf="isUserView"
        (click)="openNewRedemptionDialog()"
        [disabled]="!!(tooltipMessage$ | async)"
        mat-raised-button
        class="primary-button"
      >
        <span class="uppercase-button__text">Create new redemption</span>
      </button>
    </div>
  </div>

  <div class="filter-form-wrapper with-hint">
    <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
      <mat-form-field>
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Order ID</mat-label>
        <input matInput aria-label="Order ID" type="search" placeholder="Order ID" formControlName="orderId" />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.orderId"
          [loading]="loading$ | async"
        ></admin-input-reset>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix svgIcon="filter"></mat-icon>
        <mat-label>{{ filter.status ? 'Status' : 'All statuses' }}</mat-label>
        <mat-select formControlName="status" disableOptionCentering>
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status | customTitleCase: 'fromSnakeCase' }}
          </mat-option>
        </mat-select>
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.status"
          [loading]="loading$ | async"
        ></admin-input-reset>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Description</mat-label>
        <input
          matInput
          aria-label="Description"
          type="search"
          placeholder="Description"
          formControlName="description"
        />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.description"
          [loading]="loading$ | async"
        ></admin-input-reset>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix svgIcon="filter"></mat-icon>
        <mat-label>{{ filter.type ? 'Product type' : 'All product types' }}</mat-label>
        <mat-select formControlName="type" disableOptionCentering>
          <mat-option *ngFor="let type of filterTypeCodes | keyvalue" [value]="type.key">
            {{ type.value | orderItemType }}
          </mat-option>
        </mat-select>
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.type"
          [loading]="loading$ | async"
        ></admin-input-reset>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Item ID</mat-label>
        <input matInput aria-label="Item ID" type="search" placeholder="Item ID" formControlName="id" />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.id"
          [loading]="loading$ | async"
        ></admin-input-reset>
        <mat-error *ngIf="filterForm.get('id').errors?.invalidUuid">Invalid UUID</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-icon matPrefix svgIcon="search"></mat-icon>
        <mat-label>Supplier Reference ID</mat-label>
        <input
          matInput
          aria-label="Supplier Reference ID"
          type="search"
          placeholder="Supplier Reference ID"
          formControlName="externalSupplierOrderReference"
        />
        <admin-input-reset
          matSuffix
          initialValue=""
          [inputControl]="filterForm.controls.externalSupplierOrderReference"
          [loading]="loading$ | async"
        ></admin-input-reset>
      </mat-form-field>

      <ng-container *ngIf="showAdvancedFilters">
        <mat-form-field>
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-label>Supplier Reference Item ID</mat-label>
          <input
            matInput
            aria-label="Supplier Reference Item ID"
            type="search"
            placeholder="Supplier Reference Item ID"
            formControlName="externalSupplierOrderItemReference"
          />
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="filterForm.controls.externalSupplierOrderItemReference"
            [loading]="loading$ | async"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field *ngIf="!isUserView">
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-label>User ID</mat-label>
          <input matInput aria-label="User ID" type="search" placeholder="User ID" formControlName="userId" />
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="filterForm.controls.userId"
            [loading]="loading$ | async"
          ></admin-input-reset>
          <mat-error *ngIf="filterForm.get('userId').errors?.invalidUuid">Invalid UUID</mat-error>
        </mat-form-field>

        <mat-form-field class="date-field">
          <mat-label>Created date</mat-label>
          <mat-datepicker-toggle matIcon matPrefix [for]="picker">
            <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-date-range-input matInput [rangePicker]="picker">
            <input matStartDate formControlName="dateFrom" placeholder="Start date" (focus)="picker.open()" />
            <input matEndDate formControlName="dateTo" placeholder="End date" (focus)="picker.open()" />
          </mat-date-range-input>
          <div matSuffix>
            <button mat-icon-button color="primary" (click)="picker.open()">
              <mat-icon matDatepickerToggleIcon svgIcon="arrowdown"></mat-icon>
            </button>
            <ng-container *ngIf="filterForm.get('dateFrom').value || filterForm.get('dateTo').value">
              <button
                mat-icon-button
                class="close-button"
                (click)="filterForm.get('dateFrom').reset(); filterForm.get('dateTo').reset()"
                [disabled]="
                  (loading$ | async) || (!filterForm.get('dateFrom').value && !filterForm.get('dateTo').value)
                "
                mat-button
                matSuffix
                aria-label="Clear"
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </mat-form-field>
      </ng-container>
    </form>

    <div class="filter-controls">
      <admin-clear-form-button [form]="filterForm" (click)="updateFilter()"></admin-clear-form-button>
      <admin-toggle-advanced-filters-button
        [showAdvancedFilters]="!showAdvancedFilters"
        (click)="showAdvancedFilters = !showAdvancedFilters"
      ></admin-toggle-advanced-filters-button>
    </div>

    <mat-hint> Note: Combined search by Order ID and Description is currently not supported. </mat-hint>
  </div>

  <div *ngIf="loading$ | async" class="progress-loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="sticky-table-wrapper">
    <table
      mat-table
      matSort
      matSortActive="createdAt"
      matSortDirection="desc"
      [dataSource]="filteredOrderItems"
      [class.overlay]="loading$ | async"
    >
      <ng-container
        matColumnDef="orderId"
        [sticky]="columnConfig['orderId']?.isSticky"
        [stickyEnd]="columnConfig['orderId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['orderId']?.headerClass">Order ID</th>
        <td mat-cell *matCellDef="let orderItem" [ngClass]="columnConfig['orderId']?.cellClass">
          {{ orderItem.orderId || orderItem.order.id }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="id"
        [sticky]="columnConfig['id']?.isSticky"
        [stickyEnd]="columnConfig['id']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['id']?.headerClass">Item ID</th>
        <td mat-cell *matCellDef="let orderItem" [ngClass]="columnConfig['id']?.cellClass">{{ orderItem.id }}</td>
      </ng-container>

      <ng-container
        matColumnDef="externalSupplierOrderReference"
        [sticky]="columnConfig['externalSupplierOrderReference']?.isSticky"
        [stickyEnd]="columnConfig['externalSupplierOrderReference']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['externalSupplierOrderReference']?.headerClass">
          Supplier Reference ID
        </th>
        <td mat-cell *matCellDef="let orderItem" [ngClass]="columnConfig['externalSupplierOrderReference']?.cellClass">
          {{ orderItem.data.externalSupplierOrderReference }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="externalSupplierOrderItemReference"
        [sticky]="columnConfig['externalSupplierOrderItemReference']?.isSticky"
        [stickyEnd]="columnConfig['externalSupplierOrderItemReference']?.isStickyEnd"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="columnConfig['externalSupplierOrderItemReference']?.headerClass"
        >
          Supplier Reference Item ID
        </th>
        <td
          mat-cell
          *matCellDef="let orderItem"
          [ngClass]="columnConfig['externalSupplierOrderItemReference']?.cellClass"
        >
          {{ orderItem.data.externalSupplierOrderItemReference }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="userId"
        [sticky]="columnConfig['userId']?.isSticky"
        [stickyEnd]="columnConfig['userId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['userId']?.headerClass">User ID</th>
        <td mat-cell *matCellDef="let orderItem" [ngClass]="columnConfig['userId']?.cellClass">
          {{ orderItem.userId }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="type"
        [sticky]="columnConfig['type']?.isSticky"
        [stickyEnd]="columnConfig['type']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="columnConfig['type']?.headerClass">Type</th>
        <td mat-cell *matCellDef="let orderItem" [ngClass]="columnConfig['type']?.cellClass">
          {{ orderItem.type | orderItemType }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="description"
        [sticky]="columnConfig['description']?.isSticky"
        [stickyEnd]="columnConfig['description']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['description']?.headerClass">Description</th>
        <td
          mat-cell
          *matCellDef="let orderItem"
          attr.data-tooltip="{{ orderItem.description }}"
          [ngClass]="columnConfig['description']?.cellClass"
        >
          {{ orderItem.description }}
          {{ orderItem.type === rcTypeCodesForFilter.SustainabilityOrderItem ? ' carbon offset' : '' }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="createdAt"
        [sticky]="columnConfig['createdAt']?.isSticky"
        [stickyEnd]="columnConfig['createdAt']?.isStickyEnd"
      >
        <!--  disable sorting when user search with id (order item id) -->
        <th
          mat-header-cell
          *matHeaderCellDef
          [disabled]="!!filter.id"
          mat-sort-header="createdAt"
          [ngClass]="columnConfig['createdAt']?.headerClass"
        >
          Created date
        </th>
        <td
          mat-cell
          *matCellDef="let orderItem"
          [matTooltip]="'timezone: UTC ' + (timezone | timezoneDisplay)"
          [ngClass]="columnConfig['createdAt']?.cellClass"
        >
          {{ orderItem.createdAt | date: 'yyyy-MM-dd HH:mm' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="status"
        [sticky]="columnConfig['status']?.isSticky"
        [stickyEnd]="columnConfig['status']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['status']?.headerClass">Status</th>
        <td mat-cell *matCellDef="let orderItem" [ngClass]="columnConfig['status']?.cellClass">
          <button
            mat-flat-button
            disabled
            class="status-button-in-table"
            [ngClass]="orderItemStatusDisplayClass[orderItem?.status] || 'status-neutral'"
          >
            <span class="dot"></span>{{ orderItem?.status | customTitleCase: 'fromSnakeCase' }}
          </button>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="action"
        [sticky]="columnConfig['action']?.isSticky"
        [stickyEnd]="columnConfig['action']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['action']?.headerClass">Actions</th>
        <td mat-cell *matCellDef="let orderItem" [ngClass]="columnConfig['action']?.cellClass">
          <div>
            <a [routerLink]="'./' + orderItem.id" *adminIfScopesInclude="SCOPES.showOrderItems; relationOperator: 'or'">
              <button mat-icon-button>
                <mat-icon matPrefix svgIcon="view"></mat-icon>
              </button>
            </a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <!--  disable paginator when user search with id (order item id) -->
  <mat-paginator
    [disabled]="(loading$ | async) || !!filter.id"
    [pageSizeOptions]="[20, 50]"
    [pageIndex]="filter.page - 1"
    [pageSize]="filter.limit"
    [length]="orderItemsCount$ | async"
    (page)="onPage($event)"
    adminPaginator
  ></mat-paginator>
</div>
