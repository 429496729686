import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';

import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';
import { routerNavigate } from '@core/store/router/actions/router.actions';

import { AppsService } from '../../services/apps.service';
import {
  createApp,
  createAppFailure,
  createAppSuccess,
  fetchApp,
  fetchAppFailure,
  fetchAppSuccess,
  loadApp,
  loadAppFailure,
  loadApps,
  loadAppSecret,
  loadAppSecretFailure,
  loadAppSecretSuccess,
  loadAppsFailure,
  loadAppsSuccess,
  loadAppSuccess,
  updateApp,
  updateAppFailure,
  updateAppSuccess
} from '../actions/apps.actions';

@Injectable()
export class AppsEffects {
  loadApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApps),
      exhaustMap(action =>
        this.appsService.getApps(action.tenantId).pipe(
          map(apps => loadAppsSuccess({ apps })),
          catchError(error => of(loadAppsFailure({ error })))
        )
      )
    )
  );

  loadApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApp),
      exhaustMap(action =>
        this.appsService.getApp(action.appId).pipe(
          map(app => loadAppSuccess({ app })),
          catchError(error => of(loadAppFailure({ error })))
        )
      )
    )
  );

  fetchApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchApp),
      mergeMap(action =>
        this.appsService.getApp(action.id).pipe(
          map(app => fetchAppSuccess({ app })),
          catchError(() => of(fetchAppFailure({ id: action.id })))
        )
      )
    )
  );

  loadAppSecret$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppSecret),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(({ appId }) =>
        this.appsService.getAppSecret(appId).pipe(
          map(secret => loadAppSecretSuccess({ appId, secret })),
          catchError(error => of(loadAppSecretFailure({ error })))
        )
      )
    )
  );

  createApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createApp),
      exhaustMap(action =>
        this.appsService.createApp(action.app).pipe(
          map(app => createAppSuccess({ app })),
          catchError(error => of(createAppFailure({ error })))
        )
      )
    )
  );

  updateApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApp),
      exhaustMap(action =>
        this.appsService.updateApp(action.app).pipe(
          map(app => updateAppSuccess({ app })),
          catchError(error => of(updateAppFailure({ error })))
        )
      )
    )
  );

  createOrUpdateAppSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAppSuccess, updateAppSuccess),
      map(() => routerNavigate({ path: 'apps' }))
    )
  );

  constructor(
    private actions$: Actions,
    private appsService: AppsService,
    private store: Store<any>
  ) {}
}
