import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { PartnerConfigImageType } from '../../../../partner-configs/types';

export interface UploadImageState extends CallState {
  images: { [key: string]: string };
}

export const initialState: UploadImageState = {
  ...initialCallState,
  images: {}
};

export interface UploadImageActionPayload {
  file: FormData;
  componentId: string;
  params?: PartnerConfigsImageUploadParams;
}

export interface PartnerConfigsImageUploadParams {
  imageType: `${PartnerConfigImageType}`;
}

export interface ImageValidationConfig {
  acceptedFiles?: string[];
  allowedTypes?: string[];
  fileSizeLimit?: number; // bytes
  maxHeight?: number; // px
  maxWidth?: number; // px
}

export enum ImageUploadResource {
  LOYALTY_PROGRAMS = 'loyaltyPrograms',
  PARTNER_CONFIGS = 'partnerConfigs',
  TENANT_CAMPAIGNS = 'tenantCampaigns'
}

export type ImageUploadResourceType = `${ImageUploadResource}`;
