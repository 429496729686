import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState, Change } from '@core/types';
import { Nullable } from '@shared/types';

import { User } from '../../users/types';
import { ApprovalRequestStatistics } from './approval-request-statistics.type';
import { ApprovalRequestsFilter } from './approval-requests-filter.type';
import { ApprovalResponse } from './approval-responses.type';

export enum ApprovalRequestType {
  PointsAdjustment = 'points_adjustment',
  Tenants = 'tenants'
}

export enum ApprovalRequestStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Approved = 'approved',
  Invalidated = 'invalidated'
}

export interface ApprovalRequest {
  id: string;
  abilityId?: string;
  type: ApprovalRequestType;
  status: ApprovalRequestStatus;
  requesterId: string;
  createdAt: string;
  updatedAt: string;
  requestParams: RequestParams;
  userEligibleToRespond: boolean;
  approvalResponses: ApprovalResponse[];
  // partial ability details
  ability?: {
    id: string;
    approvalConfig: {
      approverRoles: string[];
      minRequired?: number;
    };
  };
  // only added after user details are fetched based on requesterId
  requester?: User;
  recipient?: User;
}

export type RequestParams = PointsAdjustmentRequestParams | TenantsRequestParams;

export interface PointsAdjustmentRequestParams {
  amount: number;
  identityUid?: string;
  agentId?: string;
  description: string;
  userId: string;
  notes?: string;
  expiryRule?: string;
  expiryDate?: string;
}

export interface TenantsRequestParams {
  changes: Change[];
}

export function getMinRequired(approvalRequest: ApprovalRequest): number {
  const { minRequired, approverRoles } = approvalRequest.ability!.approvalConfig;

  return (Number.isInteger(minRequired) ? minRequired : (approverRoles || []).length) || 0;
}

export const approvalRequestAdapter = createEntityAdapter<ApprovalRequest>();

export interface ApprovalRequestState extends EntityState<ApprovalRequest>, CallState {
  count: number;
  currentPage: number;
  totalPages: number;
  filter: Nullable<ApprovalRequestsFilter>;
  statistics: Nullable<ApprovalRequestStatistics>;
}

export const initialState: ApprovalRequestState = {
  ...approvalRequestAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  currentPage: 0,
  totalPages: 0,
  filter: null,
  statistics: null
};
