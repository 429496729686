import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { OffersService } from '../../services/offers.service';
import {
  createOffer,
  createOfferFailure,
  createOfferSuccess,
  fetchOffer,
  fetchOfferFailure,
  fetchOfferSuccess,
  loadOffer,
  loadOfferFailure,
  loadOffers,
  loadOffersFailure,
  loadOffersSuccess,
  loadOfferSuccess,
  loadUserOffers,
  loadUserOffersFailure,
  loadUserOffersSuccess,
  updateOffer,
  updateOfferFailure,
  updateOfferSuccess,
  uploadOfferImage,
  uploadOfferImageFailure,
  uploadOfferImageSuccess
} from '../actions/offers.actions';

@Injectable()
export class OffersEffects {
  loadOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOffers),
      exhaustMap(action =>
        this.offersService.getOffers(action.filter).pipe(
          map(result => loadOffersSuccess({ result })),
          catchError(error => of(loadOffersFailure({ error })))
        )
      )
    )
  );

  loadUserOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserOffers),
      exhaustMap(action =>
        this.offersService.getUserOffers(action.filter).pipe(
          map(result => loadUserOffersSuccess({ result })),
          catchError(error => of(loadUserOffersFailure({ error })))
        )
      )
    )
  );

  // on updateOfferFailure: reload offer if error occurs when creating/updating/deleting offer location or updating offer
  loadOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOffer, updateOfferFailure),
      exhaustMap(action =>
        this.offersService.getOffer(action.id).pipe(
          map(offer => loadOfferSuccess({ offer })),
          catchError(error => of(loadOfferFailure({ error })))
        )
      )
    )
  );

  fetchOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchOffer),
      mergeMap(action =>
        this.offersService.getOffer(action.id).pipe(
          map(offer => fetchOfferSuccess({ offer })),
          catchError(() => of(fetchOfferFailure({ id: action.id })))
        )
      )
    )
  );

  createOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOffer),
      exhaustMap(action => {
        // do not send offerLocations (only created/updated/deletedLocations should be sent)
        const { offerLocations, ...rest } = action.offer;
        return this.offersService.createOffer({ ...rest }).pipe(
          map(offer => createOfferSuccess({ offer })),
          catchError(error => of(createOfferFailure({ error })))
        );
      })
    )
  );

  updateOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOffer),
      exhaustMap(action => {
        // do not send offerLocations (only created/updated/deletedLocations should be sent)
        const { offerLocations, ...rest } = action.offer;
        return this.offersService.updateOffer({ ...rest }).pipe(
          map(offer => updateOfferSuccess({ offer })),
          catchError(error => of(updateOfferFailure({ error, id: action.offer.id })))
        );
      })
    )
  );

  createUpdateOfferSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOfferSuccess, updateOfferSuccess),
      map(action => routerNavigate({ path: `/offers/${action.offer.id}` }))
    )
  );

  uploadOfferImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadOfferImage),
      exhaustMap(({ brand, supplier, offerId, file, formControl, dialogRefId }) =>
        this.offersService.uploadOfferImage(file, brand, supplier, offerId).pipe(
          map(({ imageUrl }) =>
            uploadOfferImageSuccess({
              imageUrl,
              formControl,
              dialogRefId
            })
          ),
          catchError(error => of(uploadOfferImageFailure({ error })))
        )
      )
    )
  );

  uploadOfferImageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadOfferImageSuccess),
        tap(({ formControl, imageUrl, dialogRefId }) => {
          formControl.setValue(imageUrl);
          this.matDialog.getDialogById(dialogRefId)?.close(true);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private offersService: OffersService,
    private matDialog: MatDialog
  ) {}
}
