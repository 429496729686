import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { GiftCardOrdersService } from '../../services/gift-card-orders.service';
import {
  loadOrderAttempts,
  loadOrderAttemptsFailure,
  loadOrderAttemptsSuccess
} from '../actions/order-attempts.actions';

@Injectable()
export class OrderAttemptsEffects {
  loadOrderAttempts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderAttempts),
      exhaustMap(action =>
        this.giftCardOrdersService.getOrderAttempts(action.orderId).pipe(
          map(attempts => loadOrderAttemptsSuccess({ attempts })),
          catchError(error => of(loadOrderAttemptsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private giftCardOrdersService: GiftCardOrdersService
  ) {}
}
