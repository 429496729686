import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Nullable } from '@shared/types';
import { ObjectUtils } from '@utils';

@Injectable({
  providedIn: 'root'
})
export class QueryParamService {
  constructor(private router: Router) {}

  prepareQueryParamObject(filter: object): object {
    const requestObject = ObjectUtils.sanitizeRequestObject(filter);
    return ObjectUtils.prepareQueryObject(requestObject);
  }

  appendQueryParamToUrl(filter: object): void {
    const queryObject = this.prepareQueryParamObject(filter);
    // setTimeout to ensure the router navigate event is called after any other router navigate event e.g. user clicked on the sidenav tab
    setTimeout(() => this.router.navigate([], { queryParams: queryObject }));
  }

  parseAsFilter(queryParams: object): Nullable<object> {
    if (!ObjectUtils.isObject(queryParams) || Object.getOwnPropertyNames(queryParams).length === 0) {
      return null;
    }

    const queryObject = {};
    const entries = Object.entries(queryParams);

    for (const [key, value] of entries) {
      const objectKeys = key
        .replaceAll(/[[\]']+/g, ' ')
        .split(' ')
        .filter(item => item !== '');

      objectKeys.reduce((result, objectKey, index) => {
        result[objectKey] ??= {};

        if (index === objectKeys.length - 1) {
          result[objectKey] = value?.includes(',') ? value.split(',') : value;
        }

        return result[objectKey];
      }, queryObject);
    }
    return queryObject;
  }
}
