import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';

import { EventEarnRulesService } from '../../services/event-earn-rules.service';
import { EarnRuleState } from '../../types';
import {
  createEventEarnRule,
  createEventEarnRuleFailure,
  createEventEarnRuleSuccess,
  deactivateEventEarnRule,
  deactivateEventEarnRuleFailure,
  deactivateEventEarnRuleSuccess,
  loadEventEarnRule,
  loadEventEarnRuleFailure,
  loadEventEarnRules,
  loadEventEarnRulesFailure,
  loadEventEarnRulesSuccess,
  loadEventEarnRuleSuccess,
  updateEventEarnRule,
  updateEventEarnRuleFailure,
  updateEventEarnRulesPriorities,
  updateEventEarnRulesPrioritiesFailure,
  updateEventEarnRulesPrioritiesSuccess,
  updateEventEarnRuleSuccess
} from '../actions/event-earn-rules.actions';
import { eventEarnRulesQuery } from '../selectors/event-earn-rules.selectors';

@Injectable()
export class EventEarnRulesEffects {
  loadEventEarnRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEventEarnRules),
      exhaustMap(() =>
        this.eventEarnRulesService.getEventEarnRules().pipe(
          map(result => loadEventEarnRulesSuccess({ result })),
          catchError(error => of(loadEventEarnRulesFailure({ error })))
        )
      )
    )
  );

  loadEventEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEventEarnRule),
      exhaustMap(action =>
        this.eventEarnRulesService.getEventEarnRule(action.groupId).pipe(
          map(result => loadEventEarnRuleSuccess({ result })),
          catchError(error => of(loadEventEarnRuleFailure({ error })))
        )
      )
    )
  );

  createEventEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEventEarnRule),
      exhaustMap(action =>
        this.eventEarnRulesService.createEventEarnRule(action.eventEarnRule).pipe(
          map(earnRule => createEventEarnRuleSuccess(earnRule)),
          catchError(error => of(createEventEarnRuleFailure(error)))
        )
      )
    )
  );

  updateEventEarnRulesPriorities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEventEarnRulesPriorities),
      withLatestFrom(this.store.select(eventEarnRulesQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.eventEarnRulesService.updateEventEarnRulesPriorities({ stacks: { 1: action.groupIds }, version }).pipe(
          map(result => updateEventEarnRulesPrioritiesSuccess(result)),
          catchError(error => of(updateEventEarnRulesPrioritiesFailure(error)))
        )
      )
    )
  );

  updateEventEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEventEarnRule),
      withLatestFrom(this.store.select(eventEarnRulesQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.eventEarnRulesService.updateEventEarnRule(action.eventEarnRule, version).pipe(
          map(result => updateEventEarnRuleSuccess(result)),
          catchError(error => of(updateEventEarnRuleFailure(error)))
        )
      )
    )
  );

  deactivateEventEarnRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deactivateEventEarnRule),
      withLatestFrom(this.store.select(eventEarnRulesQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.eventEarnRulesService.deactivateEventEarnRule(action.groupId, version).pipe(
          map(result => deactivateEventEarnRuleSuccess(result)),
          catchError(error => of(deactivateEventEarnRuleFailure(error)))
        )
      )
    )
  );

  createUpdateDeactivateEventEarnRuleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEventEarnRuleSuccess, updateEventEarnRuleSuccess, deactivateEventEarnRuleSuccess),
      exhaustMap(() => {
        return [loadEventEarnRules(), routerForceNavigate({ path: 'earn-rules/event' })];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private eventEarnRulesService: EventEarnRulesService,
    private store: Store<EarnRuleState>
  ) {}
}
