import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';
import { TagDictionaryService } from '@shared/services/tag-dictionary.service';
import { getTagDictionaries, isDataFetching } from '@tag-utils';

import { noteAdapter, NoteState } from '../../types/notes.type';

const getNoteState = createFeatureSelector<NoteState>('note');

const { selectAll: getNotesList } = noteAdapter.getSelectors(getNoteState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError
} = getCallStateSelectors<NoteState>(getNoteState);

@Injectable({ providedIn: 'root' })
export class NotesQuery {
  isTopLevelView = createSelector(getNoteState, noteState => noteState.isTopLevelView);

  getMetadata = createSelector(getNoteState, state => state.metadata);

  getFilter = createSelector(getNoteState, state => state.filter);

  getNotes = createSelector(
    getNotesList,
    getTagDictionaries,
    (
      notes,
      [
        appConnections,
        apps,
        authenticationProviders,
        campaigns,
        domains,
        fraudRules,
        loyaltyPrograms,
        merchandises,
        offers,
        redemptionRates,
        users
      ]
    ) =>
      notes.map(note => {
        const formattedTags = note.tags.map(tag => {
          switch (tag.type) {
            case 'app_connection_id':
              return this.tagDictionaryService.formatTag(tag, appConnections[tag.id]);
            case 'app_id':
              return this.tagDictionaryService.formatTag(tag, apps[tag.id]);
            case 'authentication_provider_reference':
              return this.tagDictionaryService.formatTag(tag, authenticationProviders[tag.id]);
            case 'campaign_id':
              return this.tagDictionaryService.formatTag(tag, campaigns[tag.id]);
            case 'domain_id':
              return this.tagDictionaryService.formatTag(tag, domains[tag.id]);
            case 'fraud_rule_id':
              return this.tagDictionaryService.formatTag(tag, fraudRules[tag.id]);
            case 'loyalty_program_id':
              return this.tagDictionaryService.formatTag(tag, loyaltyPrograms[tag.id]);
            case 'merchandise_id':
              return this.tagDictionaryService.formatTag(tag, merchandises[tag.id]);
            case 'offer_id':
              return this.tagDictionaryService.formatTag(tag, offers[tag.id]);
            case 'redemption_rate_id':
              return this.tagDictionaryService.formatTag(tag, redemptionRates[tag.id]);
            case 'agent_id':
            case 'user_id':
            case 'requester_id':
            case 'approver_id':
              return this.tagDictionaryService.formatTag(tag, users[tag.id]);
            case 'points_activity_id':
              return this.tagDictionaryService.formatTag(tag, {
                tag,
                userId: note.tags.find(tagItem => tagItem.type === 'user_id').id
              });
            default:
              return this.tagDictionaryService.formatTag(tag);
          }
        });

        return {
          ...note,
          agent: users[note.adminUserId],
          tags: formattedTags
        };
      })
  );

  isBatchInitial = isBatchInitial;
  isBatchLoading = isBatchLoading;
  isBatchResting = isBatchResting;
  isDataFetching = isDataFetching;
  getBatchError = getBatchError;
  isSingleInitial = isSingleInitial;
  isSingleLoading = isSingleLoading;
  isSingleResting = isSingleResting;
  getSingleError = getSingleError;
  getNotesList = getNotesList;

  constructor(private tagDictionaryService: TagDictionaryService) {}
}
