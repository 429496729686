import { AbstractControl, ValidatorFn } from '@angular/forms';

export function trimmedRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || control.value.trim().length === 0) {
      return { trimmedRequired: true };
    } else {
      return null;
    }
  };
}
