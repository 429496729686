import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { initialFetchState } from '@core/store/fetch-state';
import { CallState, FetchState } from '@core/types';
import { Nullable } from '@shared/types';

export interface App {
  id: string;
  name: string;
  secret: string;
  config: object;
  domain: string;
  loginUrl: string;
  allowedWebOrigins: string[];
  callbackUrls: string[];
  tenantId?: string;
  type: string;
  tokenSigningAlgorithm: string;
  tokenSigningPublicKey: string;
  tokenExpirationTime: number;
  oidcScopes: string[];
  codeName: Nullable<string>;
}

export const appAdapter = createEntityAdapter<App>();

export interface AppState extends EntityState<App>, CallState, FetchState {}

export const initialState: AppState = {
  ...appAdapter.getInitialState<CallState>(initialCallState),
  ...appAdapter.getInitialState<FetchState>(initialFetchState)
};
