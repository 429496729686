import { Injectable } from '@angular/core';

import { LoyaltyProgramsResult } from '../types/loyalty-programs-result.type';
import {
  LoyaltyCurrencyRawResponse,
  LoyaltyProgram,
  LoyaltyProgramDataResponse,
  LoyaltyProgramsRawResponse,
  TransferRateRawResponse,
  Capability,
  CapabilityRawResponse
} from '../types/loyalty-programs.type';

@Injectable({ providedIn: 'root' })
export class LoyaltyProgramHelper {
  mergeLoyaltyProgramsAttributes(loyaltyPrograms: LoyaltyProgramsRawResponse): LoyaltyProgramsResult {
    const data = loyaltyPrograms.data.map((loyaltyProgram: LoyaltyProgramDataResponse) =>
      this.mergeLoyaltyProgramAttributes(loyaltyPrograms.included, loyaltyProgram)
    );

    return {
      data,
      meta: loyaltyPrograms.meta
    };
  }

  mergeLoyaltyProgramAttributes(
    dataIncluded: Array<LoyaltyCurrencyRawResponse | TransferRateRawResponse | CapabilityRawResponse>,
    loyaltyProgram: LoyaltyProgramDataResponse
  ): LoyaltyProgram {
    const loyaltyCurrency = (dataIncluded as LoyaltyCurrencyRawResponse[]).find(
      (singleLoyaltyCurrency: LoyaltyCurrencyRawResponse) =>
        singleLoyaltyCurrency.id === loyaltyProgram.relationships?.loyaltyCurrency?.data?.id
    );

    const transferRate = (dataIncluded as TransferRateRawResponse[]).find(
      (singleTransferRate: TransferRateRawResponse) =>
        singleTransferRate.id === loyaltyProgram.relationships?.transferRate?.data?.id
    );

    const capabilitiesIds = loyaltyProgram.relationships?.capabilities?.data?.map(capability => capability.id);
    const capabilities = (dataIncluded as CapabilityRawResponse[]).filter((capability: CapabilityRawResponse) =>
      capabilitiesIds?.includes(capability.id)
    );

    let result = {
      id: loyaltyProgram.id,
      type: loyaltyProgram.type,
      ...loyaltyProgram.attributes
    } as LoyaltyProgram;
    result = this.mergeAttributes('loyaltyCurrency', loyaltyCurrency, result);
    result = this.mergeAttributes('transferRate', transferRate, result);
    result = this.mergeCapabilities(capabilities, result);

    return result;
  }

  private mergeAttributes(
    type: string,
    data: LoyaltyCurrencyRawResponse | TransferRateRawResponse,
    result: LoyaltyProgram
  ): LoyaltyProgram {
    if (data) {
      return {
        ...result,
        [type]: {
          id: data.id,
          type: data.type,
          ...data.attributes
        }
      };
    }
    return result;
  }

  private formatCapabilites(rawResponse: CapabilityRawResponse[]): Capability[] {
    return rawResponse.map((item: CapabilityRawResponse) => ({ id: item.id, type: item.type, ...item.attributes }));
  }

  private mergeCapabilities(capabilities: CapabilityRawResponse[], result: LoyaltyProgram): LoyaltyProgram {
    if (capabilities.length === 0) {
      return result;
    }

    const formattedCapabilities = this.formatCapabilites(capabilities);
    return {
      ...result,
      capabilities: formattedCapabilities
    };
  }
}
