import { Directive, Input, OnChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { Nullable } from '@shared/types';

@Directive({
  selector: '[adminMustDefineTogetherValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MustDefineTogetherValidatorDirective,
      multi: true
    }
  ]
})
export class MustDefineTogetherValidatorDirective implements OnChanges, Validator {
  @Input({ required: true }) anotherValue: string;

  onValidatorChange?: () => void;

  ngOnChanges(): void {
    this.onValidatorChange?.();
  }

  validate(control: AbstractControl<string>): Nullable<ValidationErrors> {
    const controlValue = control.value;

    if (!controlValue || !this.anotherValue) {
      return { mustDefineTogether: true };
    }

    return null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}
