import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { CreateMfaIdentityParams, DeleteMfaIdentityParams, MfaIdentity, UpdateMfaIdentityParams } from '../types';

@Injectable({
  providedIn: 'root'
})
export class MfaIdentitiesService {
  constructor(private missionControlService: MissionControlService) {}

  getMfaIdentities(userId: string): Observable<MfaIdentity[]> {
    return this.missionControlService.get<MfaIdentity[]>(`users/${userId}/mfa_identities`);
  }

  createMfaIdentity(requestBody: CreateMfaIdentityParams): Observable<void> {
    return this.missionControlService.post<void>('users/mfa_identities', requestBody);
  }

  updateMfaIdentity(id: string, requestBody: UpdateMfaIdentityParams): Observable<void> {
    return this.missionControlService.patch<void>(`users/mfa_identities/${id}`, requestBody);
  }

  deleteMfaIdentity(requestBody: DeleteMfaIdentityParams): Observable<void> {
    const { id, agentCreated } = requestBody;

    // send agentCreated in body to trigger MC request validation
    return this.missionControlService.post<void>(`users/mfa_identities/${id}`, { agentCreated });
  }
}
