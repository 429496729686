import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface BaseIdentity {
  uid: string;
  tenantId: string;
  providerId: string;
  customData: object;
}

export interface Identity extends BaseIdentity {
  userId?: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
}

export function selectIdentityId(identity: Identity): string {
  return identity.reference;
}

export const identityAdapter = createEntityAdapter<Identity>({
  selectId: selectIdentityId
});

export interface IdentityState extends EntityState<Identity>, CallState {}

export const identityInitialState: IdentityState = identityAdapter.getInitialState<CallState>(initialCallState);

export interface CreateIdentityParams {
  userId: string;
  uid?: string;
  providerId: string;
  password?: string;
  passwordConfirmation?: string;
}

export interface IdentityColumn {
  tenantId: string;
  uid: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  actions: string;
}
