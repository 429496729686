import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { abilityAdapter, AbilityState, initialState } from '../../types/abilities.type';
import {
  createAbility,
  createAbilityFailure,
  createAbilitySuccess,
  deleteAbility,
  deleteAbilityFailure,
  deleteAbilitySuccess,
  loadAbilities,
  loadAbilitiesFailure,
  loadAbilitiesSuccess,
  loadAbility,
  loadAbilityFailure,
  loadAbilitySuccess,
  updateAbility,
  updateAbilityFailure,
  updateAbilitySuccess
} from '../actions/abilities.actions';

export const abilityTriggers: EntityTriggers = {
  single: {
    loading: [loadAbility.type, createAbility.type, deleteAbility.type, updateAbility.type],
    resting: [loadAbilitySuccess.type, createAbilitySuccess.type, deleteAbilitySuccess.type, updateAbilitySuccess.type],
    erroring: [loadAbilityFailure.type, createAbilityFailure.type, deleteAbilityFailure.type, updateAbilityFailure.type]
  },
  batch: {
    loading: [loadAbilities.type],
    resting: [loadAbilitiesSuccess.type],
    erroring: [loadAbilitiesFailure.type]
  }
};

export function abilitiesReducer(state: AbilityState = initialState, action: Action): AbilityState {
  return callStateReducer(baseReducer, abilityTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadAbilitySuccess, (state, { ability }) => abilityAdapter.upsertOne(ability, state)),
  on(loadAbilitiesSuccess, (state, { abilities }) => abilityAdapter.setAll(abilities, state)),
  on(createAbilitySuccess, (state, { ability }) => abilityAdapter.addOne(ability, state)),
  on(updateAbilitySuccess, (state, { ability }) => abilityAdapter.setOne(ability, state))
);
