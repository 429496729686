<p id="label">{{ label }}</p>
<div class="pill-wrapper">
  <ng-container *ngFor="let option of options">
    <button
      class="pill"
      [class.selected]="option.value === value"
      [class.highlight]="highlightOnSelected"
      [class.disabled]="disabled"
      [style.max-width]="pillWidth || '100%'"
      [style.width]="imagePillDimensions.pillWidth"
      [style.height]="imagePillDimensions.pillHeight"
      [style.flex]="!!imagePillDimensions.pillWidth ? '' : '1 0 0'"
      [disabled]="disabled"
      (click)="handleClick(option.value)"
    >
      <mat-icon
        *ngIf="!highlightOnSelected && option.value === value"
        svgIcon="check-in-a-circle"
        class="checkmark"
      ></mat-icon>
      <img
        *ngIf="option.image"
        [src]="option.image"
        [style.max-width]="imagePillDimensions.imageWidth"
        [style.max-height]="imagePillDimensions.imageHeight"
      />
      <mat-icon *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
      {{ option.label }}
    </button>
  </ng-container>
  <button
    *ngIf="customControl"
    class="pill"
    [class.selected]="!optionValues.includes(value) && !hasInitialRequiredError"
    [class.highlight]="highlightOnSelected"
    [class.disabled]="disabled"
    [style.max-width]="pillWidth"
    [style.width]="imagePillDimensions.pillWidth"
    [style.height]="imagePillDimensions.pillHeight"
    [style.flex]="!!imagePillDimensions.pillWidth ? '' : '1 0 0'"
    [disabled]="disabled"
    (click)="handleCustomPillClick()"
  >
    Custom
  </button>
</div>
