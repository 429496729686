import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { fraudListAdapter, FraudListState, initialState } from '../../types/fraud-lists.type';
import {
  createFraudList,
  createFraudListFailure,
  createFraudListSuccess,
  deleteFraudList,
  deleteFraudListFailure,
  deleteFraudListSuccess,
  loadFraudList,
  loadFraudListFailure,
  loadFraudLists,
  loadFraudListsFailure,
  loadFraudListsSuccess,
  loadFraudListSuccess,
  resetFraudListCounter,
  resetFraudListCounterFailure,
  resetFraudListCounterSuccess,
  updateFraudList,
  updateFraudListFailure,
  updateFraudListSuccess
} from '../actions/fraud-lists.actions';

export const fraudListTriggers: EntityTriggers = {
  single: {
    loading: [
      loadFraudList.type,
      createFraudList.type,
      updateFraudList.type,
      deleteFraudList.type,
      resetFraudListCounter.type
    ],
    resting: [
      loadFraudListSuccess.type,
      createFraudListSuccess.type,
      updateFraudListSuccess.type,
      deleteFraudListSuccess.type,
      resetFraudListCounterSuccess.type
    ],
    erroring: [
      loadFraudListFailure.type,
      createFraudListFailure.type,
      updateFraudListFailure.type,
      deleteFraudListFailure.type,
      resetFraudListCounterFailure.type
    ]
  },
  batch: {
    loading: [loadFraudLists.type],
    resting: [loadFraudListsSuccess.type],
    erroring: [loadFraudListsFailure.type]
  }
};

export function fraudListsReducer(state: FraudListState = initialState, action: Action): FraudListState {
  return {
    ...callStateReducer(baseReducer, fraudListTriggers)(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(loadFraudListSuccess, (state, { fraudList }) => fraudListAdapter.upsertOne(fraudList, state)),
  on(loadFraudListsSuccess, (state, { fraudLists }) => fraudListAdapter.setAll(fraudLists, state)),
  on(createFraudListSuccess, (state, { fraudList }) => fraudListAdapter.addOne(fraudList, state)),
  on(updateFraudListSuccess, (state, { fraudList }) => fraudListAdapter.setOne(fraudList, state)),
  on(deleteFraudListSuccess, (state, { id }) => fraudListAdapter.removeOne(id, state))
);
