import { hosts } from '../../tenant-hosts';

export const HostTenantMappingUtils = {
  getHost: (): string => window.location.hostname,

  getPrefix: (): string => {
    const host = HostTenantMappingUtils.getHost();

    const [prefix] =
      Object.entries(hosts).find(([_, hostPartials]) => hostPartials.some(hostPartial => host.includes(hostPartial))) ??
      [];

    return prefix ?? '';
  },

  mapHostToMcEndpoint: (): string => {
    const host = HostTenantMappingUtils.getHost();
    const partner = HostTenantMappingUtils.getPrefix();

    // RC
    if (host.includes('rc-ap-dev.kaligo-staging.xyz')) {
      return 'https://ascendago.admin.us.kaligo-staging.xyz/api';
    }

    if (host.includes('dbsrewards-dev.admin.us.kaligo-staging.xyz')) {
      return 'https://dbsrewards.admin.us.kaligo-staging.xyz/api';
    }

    if (host.includes('abcrewards-ap-dev.kaligo-staging.xyz')) {
      return 'https://abcrewards.admin.us.kaligo-staging.xyz/api';
    }

    if (partner === 'rc') {
      return `https://${host}/api`;
    }

    // E2E
    const envSuffix = ['.kaligo.com', '-uat.kaligo-staging.xyz', '.kaligo-staging.xyz'].find(suffix =>
      host.includes(suffix)
    );
    const mcName = partner === 'ascenda' ? 'mc' : `${partner}-mc`;

    return `https://${mcName}${envSuffix}`;
  },

  mapHostToTenantFavicon: (): string => `${HostTenantMappingUtils.getPrefix()}-favicon.ico`,

  mapHostToTenantLogo: (): string => `${HostTenantMappingUtils.getPrefix()}-logo.png`,

  getTenantStylesheetUrl: (): string => {
    // stylesheet url format
    // local: ascenda-css.css
    // staging/production: ascenda-css.xxxxxxx.css
    const prefix = HostTenantMappingUtils.getPrefix();
    const link = document.querySelector(`link[href*="${prefix}-css"]`) as HTMLLinkElement;
    return link?.href;
  },

  isRC: (): boolean => HostTenantMappingUtils.getPrefix() === 'rc',

  isAutomationTenant: (): boolean =>
    ['qaautomation2', 'qacnamestg', 'qaautomationuat', 'qacnameuat'].some(hostname =>
      HostTenantMappingUtils.getHost().includes(hostname)
    )
};
