import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { UserPassword } from '../types/user-passwords.type';

@Injectable({
  providedIn: 'root'
})
export class UserPasswordsService {
  constructor(private missionControlService: MissionControlService) {}

  getUserPasswords(userId: string): Observable<UserPassword[]> {
    return this.missionControlService.get<UserPassword[]>(`users/${userId}/passwords`);
  }
}
