import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { WebhookEventsService } from '../../services/webhook-events.service';
import {
  loadWebhookEventsWithPagination,
  loadWebhookEventsWithPaginationSuccess,
  loadWebhookEventsWithPaginationFailure,
  setWebhookEventsFilterWithPagination
} from '../actions/webhook-events-with-pagination.actions';

@Injectable()
export class WebhookEventsWithPaginationEffects {
  loadWebhookEventsWithPagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWebhookEventsWithPagination),
      switchMap(({ filter }) =>
        this.webhookEventsService.getWebhookEvents(filter).pipe(
          map(({ data, meta, pagination }) => {
            return loadWebhookEventsWithPaginationSuccess({
              webhookEvents: data,
              count: meta.count,
              pagination: pagination
            });
          }),
          catchError(error => of(loadWebhookEventsWithPaginationFailure({ error })))
        )
      )
    )
  );

  setWebhookEventsWithPaginationFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setWebhookEventsFilterWithPagination),
      map(filter => {
        return loadWebhookEventsWithPagination(filter);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private webhookEventsService: WebhookEventsService
  ) {}
}
