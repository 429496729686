import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { Recipient } from '../../orders/types';

export interface AutoRedemption {
  id: string;
  type: AutoRedemptionType;
  attributes: AutoRedemptionAttributes;
}

export enum AutoRedemptionType {
  CashRedemptionSetting = 'cash_redemption_setting',
  PointsTransferSetting = 'points_transfer_setting',
  PhysicalGiftCardSetting = 'physical_gift_card_setting',
  DigitalGiftCardSetting = 'digital_gift_card_setting'
}

export interface AutoRedemptionAttributes {
  id: string;
  userId: string;
  pointsAccountId: string;
  data: AttributesData;
  strategy: AutoRedemptionStrategy;
  strategyOptions: {
    startDate?: string;
    monthFrequency?: number;
  };
  pointsRequired: number;
  description: string;
  productSku: string;
  redeemAt: string;
}

export interface AttributesData {
  recipient?: Recipient;
  firstName: string;
  lastName: string;
  loyaltyProgramId: string;
  membershipId: string;
  minimumTransfer: number;
  productCategory: string;
  productImageUrl: string;
  productName: string;
  redemptionLimit: string;
  tenantCurrencyName: string;
  userId: string;
  number?: string;
  transferAmount?: number;
  enrollment?: {
    groupName?: string;
  };
  details?: {
    groupName?: string;
    bsbNumber?: string;
    productCode?: string;
  };
}

export enum AutoRedemptionStrategy {
  EarliestOpportunity = 'earliest_opportunity',
  EveryFewMonths = 'every_few_months'
}

export const autoRedemptionAdapter = createEntityAdapter<AutoRedemption>();

export interface AutoRedemptionState extends EntityState<AutoRedemption>, CallState {}

export const initialState: AutoRedemptionState = autoRedemptionAdapter.getInitialState<CallState>(initialCallState);
