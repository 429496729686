import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { UpdateGiftCardsConfigPayload } from '../../types';

export const updateGiftCardsConfig = createAction(
  '[Rewards Offering] Update Gift Cards Config',
  props<{
    payload: UpdateGiftCardsConfigPayload;
  }>()
);
export const updateGiftCardsConfigSuccess = createAction(
  '[Rewards Offering] Update Gift Cards Config Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: `Your request is processing.`
    }
  ) => ({ snackbarMessages })
);
export const updateGiftCardsConfigFailure = createAction(
  '[Rewards Offering] Update Gift Cards Config Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not update the gift cards config due to technical issues on our end. Please try again. ' +
        'If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
