import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, startWith, switchMap } from 'rxjs';

import { ComponentState, getRenderState$, ObjectUtils } from '@utils';

import { enrollmentsQuery } from '../../store/selectors/enrollments.selectors';
import { Enrollment, EnrollmentState, GroupedEnrollment } from '../../types';

@Component({
  selector: 'admin-default-user-enrollments',
  templateUrl: 'default-user-enrollments.component.html',
  styleUrls: ['./default-user-enrollments.component.scss']
})
export class DefaultUserEnrollmentsComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  enrollments$: Observable<Enrollment[]>;
  filteredEnrollments$: Observable<Enrollment[]>;
  renderState$: Observable<ComponentState>;
  statuses: string[];
  statusControl: FormControl<string>;
  fieldNames: object = {
    accountId: 'Account id',
    cardToken: 'Card token',
    cardType: 'Card type',
    offerCode: 'Offer code',
    arrangementId: 'Arrangement id',
    accountBalance: 'Account balance',
    postingRestriction: 'Posting restriction',
    bonusFlagOnAccount: 'Bonus in the present month',
    nextMonthBonusFlag: 'Bonus in the next month',
    prevMonthBonusFlag: 'Bonus in the previous month',
    accountsFileProductionDate: 'Accounts file production date',
    primaryCard: 'Primary card?',
    specialDate: 'Special date',
    statementDate: 'Statement date',
    activationDate: 'Activation date',
    maskedCardNumber: 'Card number',
    cardActivationFlag: 'Card activated?',
    paymentCycleDueCode: 'Payment cycle due code',
    cifPrimaryFlag: 'Owner type',
    disbursementDate: 'Settlement date',
    loanPurpose: 'Loan purpose',
    secondaryLoanPurpose: 'Secondary loan purpose',
    newToBankLoanFlag: 'New to bank',
    lvr: 'Loan to value ratio',
    homeloansOpenBalance: 'Account open balance',
    homeloansCurrentBalance: 'Account current balance',
    offsetFlag: 'Offset flag',
    totalAmountPaid: 'Loan amount paid',
    splitLoanFlag: 'Split loan',
    arrearsFlag: 'Account in arrears',
    topupAmount: 'Top up amount',
    topupDate: 'Date of top up',
    repaymentType: 'Repayment type',
    repaymentTypeChangeDate: 'Repayment type change date',
    principalInterestVsInterestOnly: 'Principle interest or interest only',
    dischargedFlag: 'Loan repaid',
    dischargedDate: 'Discharged date',
    sourceChannel: 'Marketing channel',
    applicationDate: 'Application date'
  };

  vmaProductCodes: object = {
    'VMA.TRANSAC.ACCOUNT': 'VMA Transaction Account',
    'VMA.BONUS.SAVER': 'VMA Bonus Saver',
    'VMA.FULL.FEATURE.LOAN': 'VMA Fully Featured Home Loan',
    'VMA.BASIC.HOMELOAN': 'VMA Basic Home Loan',
    cc_flex: 'Virgin Money Flex Rewards Card',
    cc_flyer: 'Virgin Australia Velocity Flyer Card',
    cc_high_flyer: 'Virgin Australia Velocity High Flyer Card',
    citi_vma_card: 'Virgin Money Credit Card',
    cc_vma_card: 'Virgin Money Credit Card'
  };

  fabProductCodes: object = {
    670: 'FAB Signature Travel Card Account',
    671: 'FAB Infinite Travel Card Account',
    673: 'FAB Elite Travel Card Account'
  };

  fabProductCodesList = Object.keys(this.fabProductCodes);
  detailPanelOpenState = false;
  jointOwnersPanelOpenState = [];

  constructor(
    @Inject('timezone') public timezone: string,
    private store: Store<EnrollmentState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.enrollments$ = this.store.select(enrollmentsQuery.getEnrollmentsList).pipe(
      map(enrollments =>
        enrollments.map(enrollment => ({
          ...enrollment,
          groupedEnrollments: this.filterGroupedEnrollments(
            enrollment.groupedEnrollments,
            enrollment.product.referenceId
          )
        }))
      )
    );
    const enrollmentsLoading$ = this.store.select(enrollmentsQuery.isBatchLoading);
    const enrollmentsErrors$ = this.store.select(enrollmentsQuery.getBatchError);

    this.renderState$ = getRenderState$([enrollmentsLoading$, enrollmentsErrors$]);

    this.createStatusFilter();
  }

  createStatusFilter(): void {
    this.enrollments$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(enrollments => {
      this.statuses = enrollments.map(enrollment => enrollment.product.partnerStatus);

      // to support StarA
      if (!this.statuses.includes('active') && this.statuses.includes('open')) {
        this.statusControl = new FormControl<string>('open');
        this.statuses = ObjectUtils.removeArrayDuplicates(this.statuses).sort();
      } else {
        this.statusControl = new FormControl<string>('active');
        this.statuses = ObjectUtils.removeArrayDuplicates([...this.statuses, 'active']).sort();
      }

      this.filteredEnrollments$ = this.statusControl.valueChanges.pipe(
        startWith(this.statusControl.value),
        switchMap(status =>
          this.enrollments$.pipe(
            map(allEnrollments =>
              status ? allEnrollments.filter(enrollment => enrollment.product.partnerStatus === status) : enrollments
            )
          )
        )
      );
    });
  }

  filterGroupedEnrollments(groupedEnrollments: GroupedEnrollment[], productReferenceId: string): GroupedEnrollment[] {
    return groupedEnrollments.filter(groupedEnrollment => groupedEnrollment.groupReferenceId === productReferenceId);
  }
}
