<mat-card appearance="outlined" class="mat-elevation-z1 mat-card">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <mat-card-title>Login Attempts</mat-card-title>
      <button
        *ngIf="isUserLoginAttemptsView"
        (click)="handleLocationHeatmapButton()"
        mat-raised-button
        color="primary"
        class="view-location-btn uppercase-button"
      >
        <mat-icon>location_on</mat-icon>
        View Locations
      </button>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="data-table-container w-full">
        <form [formGroup]="filterForm">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px" class="slim-form-field">
              <mat-form-field formGroupName="createdAt" class="date-form">
                <mat-label>Date Range</mat-label>
                <div class="date-form__wrapper">
                  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matInput
                      matStartDate
                      formControlName="dateFrom"
                      placeholder="Start date"
                      (focus)="picker.open()"
                    />
                    <input
                      matInput
                      matEndDate
                      formControlName="dateTo"
                      placeholder="End date"
                      (focus)="picker.open()"
                    />
                  </mat-date-range-input>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <button
                    matSuffix
                    mat-icon-button
                    color="primary"
                    (click)="filterForm.get('createdAt').reset()"
                    [disabled]="
                      !filterForm.get('createdAt.dateFrom').value && !filterForm.get('createdAt.dateTo').value
                    "
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </mat-form-field>

              <mat-form-field *ngIf="!isUserLoginAttemptsView">
                <mat-icon matPrefix>perm_identity</mat-icon>
                <mat-label>User ID</mat-label>
                <input matInput aria-label="Search" type="search" placeholder="User ID" formControlName="userId" />
                <admin-input-reset
                  matSuffix
                  [inputControl]="filterForm.controls.userId"
                  [loading]="loading$ | async"
                ></admin-input-reset>
                <mat-error *ngIf="filterForm.get('userId').hasError('invalidUuid')">Invalid UUID</mat-error>
              </mat-form-field>

              <mat-form-field class="session-id-form-field">
                <mat-icon matPrefix>perm_identity</mat-icon>
                <mat-label>Session ID</mat-label>
                <input
                  matInput
                  aria-label="Search"
                  type="search"
                  placeholder="Session ID"
                  formControlName="sessionId"
                />
                <admin-input-reset
                  matSuffix
                  [inputControl]="filterForm.controls.sessionId"
                  [loading]="loading$ | async"
                ></admin-input-reset>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div *ngIf="loading$ | async" class="progress-loader">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <table
          mat-table
          matSort
          multiTemplateDataRows
          [dataSource]="loginAttempts$ | async"
          class="mat-elevation-z1 data-table"
          [class.overlay]="loading$ | async"
        >
          <ng-container matColumnDef="session id">
            <th mat-header-cell *matHeaderCellDef>Session ID</th>
            <td mat-cell *matCellDef="let loginAttempt">{{ loginAttempt.sessionId }}</td>
          </ng-container>

          <ng-container matColumnDef="user name">
            <th mat-header-cell *matHeaderCellDef>User Name</th>
            <td mat-cell *matCellDef="let loginAttempt">
              <a [routerLink]="getUserDetailsRoute(loginAttempt.user)">
                {{ loginAttempt.user?.name }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="provider id">
            <th mat-header-cell *matHeaderCellDef>Provider ID</th>
            <td mat-cell *matCellDef="let loginAttempt">{{ loginAttempt.providerId }}</td>
          </ng-container>

          <ng-container matColumnDef="uid">
            <th mat-header-cell *matHeaderCellDef>UID</th>
            <td mat-cell *matCellDef="let loginAttempt">{{ loginAttempt.uid }}</td>
          </ng-container>

          <ng-container matColumnDef="created at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
            <td mat-cell *matCellDef="let loginAttempt" matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
              {{ loginAttempt.createdAt | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let loginAttempt">
              <a
                class="link-icon"
                [routerLink]="isUserLoginAttemptsView ? '/login-attempts/' + loginAttempt.id : loginAttempt.id"
                *adminIfScopesInclude="SCOPES.showLoginAttempts; relationOperator: 'or'"
              >
                <button mat-icon-button class="actions-button">
                  <mat-icon>zoom_in</mat-icon>
                </button>
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <ng-container *ngIf="!(loading$ | async)">
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </ng-container>
        </table>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          [pageIndex]="filter.page - 1"
          [pageSize]="filter.limit"
          [length]="loginAttemptsTotalCount$ | async"
          (page)="onPage($event)"
        >
        </mat-paginator>
        <div class="progress-loader table-loading-container" *ngIf="loading$ | async">Loading....</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
