import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UnleashService } from '@karelics/angular-unleash-proxy-client';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CoreState } from '@core/store';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { DecodedUser } from '@core/types';
import { HostTenantMappingUtils, UnleashFlags } from '@utils';

@Component({
  selector: 'admin-main-container',
  templateUrl: './main-container.component.html'
})
export class MainContainerComponent implements OnInit {
  user$: Observable<DecodedUser>;
  isLoggedIn$: Observable<boolean>;

  isRC = HostTenantMappingUtils.isRC();

  isSidenavV2Enabled$ = this.unleashService.isEnabled$(UnleashFlags.RC_SIDENAV_V2);

  constructor(
    private unleashService: UnleashService,
    private store: Store<CoreState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.user$ = this.store.select(authQuery.getUser);
    this.isLoggedIn$ = this.store.select(authQuery.getIsLoggedIn);

    this.isLoggedIn$.subscribe(isLoggedIn => {
      // Only start router navigation after either internal login/logout happens, so that the AuthGuard
      // gets the most updated isLoggedIn value from the reducer when the route is about to be activated.
      if (isLoggedIn !== null) {
        this.router.initialNavigation();
      }
    });
  }
}
