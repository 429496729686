import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';

import { Component, DestroyRef, inject, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { WindowRefService } from '@core/services/window-ref/window-ref.service';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { SCOPES_OR, Tab } from '@core/types';
import { HostTenantMappingUtils } from '@utils';

import { pointsActivitiesQuery } from '../../../dashboard/store/selectors/points-activities.selectors';
import { PointsActivityState } from '../../../dashboard/types';
import { impersonate } from '../../store/actions/impersonations.actions';
import { usersQuery } from '../../store/selectors/users.selectors';
import {
  getCustomerId,
  getCustomerIdentity,
  getUserName,
  isAdmin,
  User,
  UserState,
  userStateDisplayClass
} from '../../types';
import { AdvancedActionsDialogComponent } from '../advanced-actions-dialog/advanced-actions-dialog.component';

@Component({
  selector: 'admin-user-header-v2',
  templateUrl: './user-header-v2.component.html',
  styleUrls: ['./user-header-v2.component.scss']
})
export class UserHeaderV2Component implements OnInit {
  @Input() tabs: Tab[];

  destroyRef = inject(DestroyRef);

  user: User;
  headerName: string;
  providerUidText = '';
  userId: string;
  displayedUserName: string;
  isAdminUser: boolean;
  disableImpersonation = true;
  loading$: Observable<boolean>;
  userStateDisplayClass = userStateDisplayClass;
  readonly SCOPES = SCOPES_OR;

  blockUnblockUsersScopes = ['users:block', 'users:unblock'];
  blockUnblockLoginUsersScopes = ['users:block_login', 'users:unblock_login'];

  isRC = HostTenantMappingUtils.isRC();
  isPersonalRoute = false;

  constructor(
    @Inject('customerBankIdentityProvider') private customerBankIdentityProvider: string,
    @Inject('guardhouseApiUrl') public guardhouseApiUrl: string,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    protected router: Router,
    private authStore: Store<AuthState>,
    private usersStore: Store<UserState>,
    private impersonationStore: Store<any>,
    private pointsActivityStore: Store<PointsActivityState>,
    private windowRefService: WindowRefService
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.updateHeaderName());
  }

  get urlPathSegmentForMenu(): string {
    return window.location.pathname.split('/').at(3);
  }

  get routeSnapshotFromGrandChild(): ActivatedRouteSnapshot {
    return this.route.snapshot.firstChild.firstChild;
  }

  ngOnInit(): void {
    this.route.url
      .pipe(
        map(url => url[0].path),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(userId => {
        this.userId = userId;
        this.usersStore
          .pipe(
            select(usersQuery.getUserById(this.userId)),
            takeUntilDestroyed(this.destroyRef),
            filter(user => !!user),
            withLatestFrom(this.authStore.pipe(select(authQuery.getUserId)))
          )
          .subscribe(([user, agentId]) => {
            this.isPersonalRoute = user.id === agentId;
            this.displayedUserName = getUserName(user) || '';
            this.isAdminUser = isAdmin(user);
            this.disableImpersonation = !user.activated;

            const customerIdentity = getCustomerIdentity(user, this.customerBankIdentityProvider);
            if (user.uid) {
              this.providerUidText = `Customer ID: ${user.uid}`;
            } else {
              if (!customerIdentity) {
                this.providerUidText = 'Customer ID not found';
              } else if (customerIdentity.providerId === this.customerBankIdentityProvider) {
                this.providerUidText = `Customer ID: ${getCustomerId(user, customerIdentity.providerId)}`;
              } else if (customerIdentity.providerId && customerIdentity.uid) {
                this.providerUidText = `${customerIdentity.providerId} ID: ${customerIdentity.uid}`;
              }
            }

            this.updateHeaderName();
            this.user = user;
          });

        this.loading$ = this.usersStore.select(usersQuery.isSingleLoading);
      });
  }

  updateHeaderName(): void {
    this.headerName = this.tabs
      .map(tab => tab.subTabs ?? tab)
      .flat()
      .find(tab => tab.path === this.urlPathSegmentForMenu)?.label;

    const pointsActivityId = this.routeSnapshotFromGrandChild?.params?.pointsActivityId;
    if (pointsActivityId) {
      this.pointsActivityStore
        .pipe(
          select(pointsActivitiesQuery.getPointsActivityById(pointsActivityId)),
          takeUntilDestroyed(this.destroyRef),
          filter(data => !!data)
        )
        .subscribe(pointsActivity => (this.headerName += ' | ' + pointsActivity.description));
    }
  }

  impersonateUser(readOnly: boolean = false): void {
    this.impersonationStore.dispatch(impersonate({ userId: this.userId, readOnly }));
  }

  openAdvancedActionsDialog(): void {
    this.matDialog.open(AdvancedActionsDialogComponent, { data: this.user, maxHeight: '500px' });
  }

  openNewTab(path: string): void {
    this.windowRefService.openNewTab(`${this.guardhouseApiUrl}/${path}`);
  }
}
