import { Pipe, PipeTransform } from '@angular/core';

import { DecodedUser } from '@core/types';

import { getUserName, User } from '../../users/types';

@Pipe({ name: 'userName' })
export class UserNamePipe implements PipeTransform {
  transform(user: User | DecodedUser): string {
    return getUserName(user);
  }
}
