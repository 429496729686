import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { WebhookEvent, WebhookEventsPagination, WebhookEventsFilterWithPagination } from '../../types';

export const loadWebhookEventsWithPagination = createAction(
  '[Webhooks] Load Webhook Events',
  props<{ filter: WebhookEventsFilterWithPagination }>()
);

export const loadWebhookEventsWithPaginationSuccess = createAction(
  '[Webhooks] Load Webhook Events Success',
  props<{ webhookEvents: WebhookEvent[]; count: number; pagination: WebhookEventsPagination }>()
);

export const loadWebhookEventsWithPaginationFailure = createAction(
  '[Webhooks] Load Webhook Events Failure',
  props<ErrorState>()
);

export const setWebhookEventsFilterWithPagination = createAction(
  '[Webhooks] Set Webhook Events Filter',
  props<{ filter: WebhookEventsFilterWithPagination }>()
);
