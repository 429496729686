<div class="data-table-container users-component">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="page-title-wrapper">
    <h2>{{ isCustomersView ? 'Customers' : 'Agents' }}</h2>
    <div class="action-button-group">
      <admin-tooltip
        key="users.index.button.activateNewUser"
        [displayIcon]="false"
        *adminIfScopesInclude="SCOPES.createEnrollments; relationOperator: 'or'"
      >
        <button mat-raised-button color="primary" routerLink="./enroll" class="uppercase-button">
          <span class="uppercase-button__text">Activate New User</span>
        </button>
      </admin-tooltip>

      <admin-tooltip key="users.index.button.downloadAgents" [displayIcon]="false" *ngIf="isAgentsView">
        <button
          *adminIfScopesInclude="['users:download_agents']"
          mat-raised-button
          color="primary"
          (click)="downloadAgents()"
          class="uppercase-button"
        >
          <span class="uppercase-button__text">Download Agents</span>
        </button>
      </admin-tooltip>
    </div>
  </div>
  <admin-clear-form-button
    class="clear-form-button"
    [form]="filterForm"
    (click)="clearFilter()"
  ></admin-clear-form-button>

  <div class="filter-form-wrapper with-hint">
    <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()" class="slim-form-field">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="11px">
        <ng-container *ngIf="isAgentsView && displaySettings.roles">
          <mat-form-field
            *adminIfScopesInclude="rolesScopes; relationOperator: 'or'"
            class="filter-field slim-form-field"
          >
            <mat-label>Role ID</mat-label>
            <mat-select formControlName="roleId" disableOptionCentering panelClass="dropdown-panel">
              <mat-option *ngFor="let role of roles$ | async" [value]="role.name">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.roleId"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>
        </ng-container>

        <div fxLayout="row" *ngIf="isCustomersView && displaySettings.customerId">
          <mat-form-field class="filter-field">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search by Customer ID</mat-label>
            <input
              matInput
              aria-label="Partner UID"
              type="search"
              placeholder="Partner UID"
              formControlName="partnerUid"
            />
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.partnerUid"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.customerId" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <mat-form-field class="filter-field" *ngIf="displaySettings.name">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search by first name</mat-label>
          <input matInput aria-label="First Name" type="search" placeholder="First Name" formControlName="firstName" />
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.firstName"
            [loading]="loading$ | async"
            [initialValue]="''"
          ></admin-input-reset>
          <mat-error *ngIf="filterForm.get('firstName').errors?.invalidPartialSearch"
            >Please enter at least 2 characters</mat-error
          >
        </mat-form-field>

        <mat-form-field class="filter-field" *ngIf="displaySettings.name">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search by last name</mat-label>
          <input matInput aria-label="Last Name" type="search" placeholder="Last Name" formControlName="lastName" />
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.lastName"
            [loading]="loading$ | async"
            [initialValue]="''"
          ></admin-input-reset>
          <mat-error *ngIf="filterForm.get('lastName').errors?.invalidPartialSearch"
            >Please enter at least 2 characters</mat-error
          >
        </mat-form-field>

        <ng-container *ngIf="displaySettings.id">
          <div fxLayout="row">
            <mat-form-field class="filter-field">
              <mat-icon matPrefix class="users-component-id-search__field-icon">search</mat-icon>
              <mat-label>Search by Ascenda ID</mat-label>
              <input
                matInput
                aria-label="ID"
                type="search"
                placeholder="Ascenda ID"
                formControlName="id"
                class="users-component-id-search__field-input"
                [value]="filterForm.get('id').value"
              />
              <mat-error *ngIf="filterForm.get('id').errors?.invalidUuid">Invalid UUID</mat-error>
              <admin-input-reset
                matSuffix
                [inputControl]="filterForm.controls.id"
                [loading]="loading$ | async"
                [initialValue]="''"
              ></admin-input-reset>
            </mat-form-field>
            <admin-tooltip key="users.index.filter.ascendaId" styleClassName="filter-tooltip"></admin-tooltip>
          </div>
        </ng-container>

        <mat-form-field class="filter-field" *ngIf="displaySettings.phoneNumber">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search by phone number</mat-label>
          <input
            matInput
            aria-label="Phone Number"
            type="search"
            placeholder="Phone Number"
            formControlName="phoneNumber"
          />
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.phoneNumber"
            [loading]="loading$ | async"
            [initialValue]="''"
          ></admin-input-reset>
          <mat-error *ngIf="filterForm.get('phoneNumber').errors?.minlength"
            >Please enter at least 2 characters</mat-error
          >
        </mat-form-field>

        <mat-form-field class="filter-field" *ngIf="displaySettings.email">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search by email</mat-label>
          <input matInput aria-label="Email" type="search" placeholder="Email" formControlName="email" />
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.email"
            [loading]="loading$ | async"
            [initialValue]="''"
          ></admin-input-reset>
          <mat-error *ngIf="filterForm.get('email').errors?.minlength">Please enter at least 2 characters</mat-error>
        </mat-form-field>

        <div fxLayout="row" *ngIf="isCustomersView && displaySettings.state">
          <mat-form-field class="filter-field">
            <mat-label>User state</mat-label>
            <mat-select [formControl]="stateControl" disableOptionCentering panelClass="dropdown-panel">
              <mat-option *ngFor="let state of states" [value]="state">
                {{ state | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.userState" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div fxLayout="row" *ngIf="isCustomersView && displaySettings.status">
          <mat-form-field class="filter-field">
            <mat-label>Tenant status</mat-label>
            <mat-select formControlName="status" disableOptionCentering panelClass="dropdown-panel">
              <mat-option *ngFor="let status of statuses" [value]="status">
                {{ status | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.tenantStatus" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div fxLayout="row" *ngIf="displaySettings.loginMode">
          <mat-form-field class="filter-field">
            <mat-label>Login mode</mat-label>
            <mat-select formControlName="loginMode" disableOptionCentering panelClass="dropdown-panel">
              <mat-option *ngFor="let loginMode of loginModes" [value]="loginMode">
                {{ loginMode | customTitleCase: 'fromSnakeCase' }}
              </mat-option>
            </mat-select>
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.loginMode"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.loginMode" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div fxLayout="row" *ngIf="displaySettings.activated">
          <mat-form-field class="filter-field">
            <mat-label>Activation status</mat-label>
            <mat-select formControlName="activated" disableOptionCentering panelClass="dropdown-panel">
              <mat-option [value]="true">Activated</mat-option>
              <mat-option [value]="false">Not Activated</mat-option>
            </mat-select>
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.activated"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.activationStatus" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <mat-form-field class="filter-field" *ngIf="displaySettings.identityId">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search by Identity ID</mat-label>
          <input
            matInput
            aria-label="Identity UID"
            type="search"
            placeholder="Identity UID"
            formControlName="identityUid"
          />
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.identityUid"
            [loading]="loading$ | async"
            [initialValue]="''"
          ></admin-input-reset>
          <mat-error *ngIf="filterForm.get('identityUid').errors?.minlength"
            >Please enter at least 2 characters</mat-error
          >
        </mat-form-field>

        <div fxLayout="row" *ngIf="extraUserFilters?.pointsAccount && displaySettings.pointsAccountFilter">
          <mat-form-field class="filter-field">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search by points account</mat-label>
            <input matInput aria-label="Email" type="search" placeholder="Points Account" formControlName="accountId" />
            <admin-input-reset
              matSuffix
              [inputControl]="filterForm.controls.accountId"
              [loading]="loading$ | async"
              [initialValue]="''"
            ></admin-input-reset>
            <mat-error *ngIf="filterForm.get('accountId').hasError('invalidUuid')">Invalid UUID</mat-error>
          </mat-form-field>
          <admin-tooltip key="users.index.filter.pointsAccount" styleClassName="filter-tooltip"></admin-tooltip>
        </div>

        <div
          fxLayout="row"
          [class.overlay]="!iframeHandler?.isIframeReady || iframeHandler?.loading"
          *ngIf="displaySettings.pan"
        >
          <div
            class="users-component-secure-field"
            [id]="'users-component-secure-field-' + (isCustomersView ? 'customer' : 'agent')"
            [hidden]="!displayPanField"
          ></div>
          <admin-tooltip
            key="users.index.filter.pan"
            styleClassName="filter-tooltip"
            [hidden]="!displayPanField"
          ></admin-tooltip>
        </div>

        <mat-form-field formGroupName="createdAtDateRange" class="date-form" *ngIf="displaySettings.createdAt">
          <mat-label>Created date</mat-label>
          <div class="date-form__wrapper">
            <mat-datepicker-toggle matSuffix [for]="createdAtPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #createdAtPicker></mat-date-range-picker>
            <mat-date-range-input [rangePicker]="createdAtPicker">
              <input
                matInput
                matStartDate
                formControlName="createdAtFrom"
                placeholder="Start date"
                (focus)="createdAtPicker.open()"
              />
              <input
                matInput
                matEndDate
                formControlName="createdAtTo"
                placeholder="End date"
                (focus)="createdAtPicker.open()"
              />
            </mat-date-range-input>
            <ng-container
              *ngIf="
                !(
                  filterForm.get('createdAtDateRange').value.createdAtFrom === null &&
                  filterForm.get('createdAtDateRange').value.createdAtTo === null
                )
              "
            >
              <button
                matSuffix
                mat-icon-button
                color="primary"
                (click)="filterForm.get('createdAtDateRange').reset()"
                [disabled]="
                  !filterForm.get('createdAtDateRange.createdAtFrom').value &&
                  !filterForm.get('createdAtDateRange.createdAtTo').value
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </mat-form-field>

        <mat-form-field formGroupName="updatedAtDateRange" class="date-form" *ngIf="displaySettings.updatedAt">
          <mat-label>Updated date</mat-label>
          <div class="date-form__wrapper">
            <mat-datepicker-toggle matSuffix [for]="updatedAtPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #updatedAtPicker></mat-date-range-picker>
            <mat-date-range-input [rangePicker]="updatedAtPicker">
              <input
                matInput
                matStartDate
                formControlName="updatedAtFrom"
                placeholder="Start date"
                (focus)="updatedAtPicker.open()"
              />
              <input
                matInput
                matEndDate
                formControlName="updatedAtTo"
                placeholder="End date"
                (focus)="updatedAtPicker.open()"
              />
            </mat-date-range-input>
            <ng-container
              *ngIf="
                !(
                  filterForm.get('updatedAtDateRange').value.updatedAtFrom === null &&
                  filterForm.get('updatedAtDateRange').value.updatedAtTo === null
                )
              "
            >
              <button
                matSuffix
                mat-icon-button
                color="primary"
                (click)="filterForm.get('updatedAtDateRange').reset()"
                [disabled]="
                  !filterForm.get('updatedAtDateRange.updatedAtFrom').value &&
                  !filterForm.get('updatedAtDateRange.updatedAtTo').value
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </mat-form-field>
      </div>
      <mat-hint
        class="hint"
        *ngIf="hasPartialSearch && (usersTotalCount$ | async) > filter.limit && !(loading$ | async)"
      >
        <div class="hint__icon text-primary bg-secondary">!</div>
        Note: This query has many results. Try more specific queries to get better results.
      </mat-hint>
    </form>
  </div>

  <div *ngIf="loading$ | async" class="progress-loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="sticky-table-wrapper">
    <table
      mat-table
      matSort
      class="mat-elevation-z1 data-table scrollable-table"
      [dataSource]="users$ | async"
      [ngClass]="hasPartialSearch ? 'table-margin-bottom' : ''"
      [class.overlay]="loading$ | async"
    >
      <ng-container
        matColumnDef="customerId"
        [sticky]="columnConfig['customerId']?.isSticky"
        [stickyEnd]="columnConfig['customerId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['customerId']?.headerClass">
          <admin-tooltip
            key="users.index.tableHeader.customerId"
            position="above"
            styleClassName="table-header-tooltip"
          >
            <h4 class="text-primary">Customer ID</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['customerId']?.cellClass">
          {{ getCustomerId(user) }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="identityId"
        [sticky]="columnConfig['identityId']?.isSticky"
        [stickyEnd]="columnConfig['identityId']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['identityId']?.headerClass">
          <admin-tooltip
            key="users.index.tableHeader.identityId"
            position="above"
            styleClassName="table-header-tooltip"
          >
            <h4 class="text-primary">Identity ID</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['identityId']?.cellClass">
          {{ getIdentityId(user) }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="name"
        [sticky]="columnConfig['name']?.isSticky"
        [stickyEnd]="columnConfig['name']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['name']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.name" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">Name</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['name']?.cellClass">
          {{ user | userName }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="email"
        [sticky]="columnConfig['email']?.isSticky"
        [stickyEnd]="columnConfig['email']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['email']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.email" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">Email</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['email']?.cellClass">{{ user.email }}</td>
      </ng-container>

      <ng-container
        matColumnDef="id"
        [sticky]="columnConfig['id']?.isSticky"
        [stickyEnd]="columnConfig['id']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['id']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.ascendaId" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">Ascenda ID</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['id']?.cellClass">{{ user.id }}</td>
      </ng-container>

      <ng-container
        matColumnDef="roles"
        [sticky]="columnConfig['roles']?.isSticky"
        [stickyEnd]="columnConfig['roles']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['roles']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.roles" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">Roles</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['roles']?.cellClass">
          <ng-container *ngIf="user.roles">
            <span *ngFor="let role of user.roles; last as last">
              {{ role.name }}<ng-container *ngIf="!last">, </ng-container>
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="state"
        [sticky]="columnConfig['state']?.isSticky"
        [stickyEnd]="columnConfig['state']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['state']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.userState" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">User state</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['state']?.cellClass">
          <button
            *ngIf="user | stateDisplayPipe"
            mat-flat-button
            disabled
            class="status-button-in-table"
            [ngClass]="userStateDisplayClass[user | stateDisplayPipe] || 'status-neutral'"
          >
            <span class="dot"></span>{{ user | stateDisplayPipe | customTitleCase: 'fromSnakeCase' }}
          </button>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="pointsAccountColumn"
        [sticky]="columnConfig['pointsAccountColumn']?.isSticky"
        [stickyEnd]="columnConfig['pointsAccountColumn']?.isStickyEnd"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-primary"
          [ngClass]="columnConfig['pointsAccountColumn']?.headerClass"
        >
          <admin-tooltip
            key="users.index.tableHeader.pointsAccount"
            position="above"
            styleClassName="table-header-tooltip"
          >
            <h4 class="text-primary">Points account</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['pointsAccountColumn']?.cellClass">
          {{ user.preferences?.pointsAccountId }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="phoneNumber"
        [sticky]="columnConfig['phoneNumber']?.isSticky"
        [stickyEnd]="columnConfig['phoneNumber']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef class="text-primary" [ngClass]="columnConfig['phoneNumber']?.headerClass">
          <admin-tooltip
            key="users.index.tableHeader.phoneNumber"
            position="above"
            styleClassName="table-header-tooltip"
          >
            <h4 class="text-primary">Phone number</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['phoneNumber']?.headerClass">
          {{ user.phoneNumber }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="createdAt"
        [sticky]="columnConfig['createdAt']?.isSticky"
        [stickyEnd]="columnConfig['createdAt']?.isStickyEnd"
      >
        <th
          mat-header-cell
          mat-sort-header="createdAt"
          class="text-primary"
          *matHeaderCellDef
          [disabled]="hasIdSearch || hasPartialSearch"
          [ngClass]="columnConfig['createdAt']?.headerClass"
        >
          <admin-tooltip key="users.index.tableHeader.createdAt" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">Created at</h4>
          </admin-tooltip>
        </th>
        <td
          mat-cell
          matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
          *matCellDef="let user"
          [ngClass]="columnConfig['createdAt']?.cellClass"
        >
          {{ user.createdAt | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="updatedAt"
        [sticky]="columnConfig['updatedAt']?.isSticky"
        [stickyEnd]="columnConfig['updatedAt']?.isStickyEnd"
      >
        <th
          mat-header-cell
          mat-sort-header="updatedAt"
          class="text-primary"
          *matHeaderCellDef
          [disabled]="hasIdSearch || hasPartialSearch"
          [ngClass]="columnConfig['updatedAt']?.headerClass"
        >
          <admin-tooltip key="users.index.tableHeader.updatedAt" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">Updated at</h4>
          </admin-tooltip>
        </th>
        <td
          mat-cell
          matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
          *matCellDef="let user"
          [ngClass]="columnConfig['updatedAt']?.cellClass"
        >
          {{ user.updatedAt | dateFormat | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="actions"
        *ngIf="displaySettings.actions"
        [sticky]="columnConfig['actions']?.isSticky"
        [stickyEnd]="columnConfig['actions']?.isStickyEnd"
      >
        <th mat-header-cell *matHeaderCellDef [ngClass]="columnConfig['actions']?.headerClass">
          <admin-tooltip key="users.index.tableHeader.action" position="above" styleClassName="table-header-tooltip">
            <h4 class="text-primary">Actions</h4>
          </admin-tooltip>
        </th>
        <td mat-cell *matCellDef="let user" [ngClass]="columnConfig['actions']?.cellClass">
          <a class="link-icon" [routerLink]="'./' + user.id + detailsView">
            <button mat-icon-button class="actions-button">
              <mat-icon>zoom_in</mat-icon>
            </button>
          </a>
          <a class="link-icon" [routerLink]="'edit/' + user.id" *ngIf="showEdit(currentUser$ | async, user.id)">
            <button mat-icon-button class="actions-button">
              <mat-icon>edit</mat-icon>
            </button>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-secondary"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="data-table__row"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No customers available</td>
      </tr>
    </table>
  </div>
  <mat-paginator
    *ngIf="!hasPartialSearch && !!(usersTotalCount$ | async)"
    [pageSizeOptions]="[20, 50]"
    [pageIndex]="filter.page - 1"
    [pageSize]="filter.limit"
    [length]="usersTotalCount$ | async"
    (page)="onPage($event)"
    [disabled]="loading$ | async"
  >
  </mat-paginator>
</div>
