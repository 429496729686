import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, productAdapter, ProductState } from '../../types/products.type';
import { loadProducts, loadProductsFailure, loadProductsSuccess } from '../actions/products.actions';

export const productTriggers: EntityTriggers = {
  batch: {
    loading: [loadProducts.type],
    resting: [loadProductsSuccess.type],
    erroring: [loadProductsFailure.type]
  }
};

export function productsReducer(state: ProductState = initialState, action: Action): ProductState {
  return callStateReducer(baseReducer, productTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadProducts, (state, { filter }) => ({ ...state, filter })),
  on(loadProductsSuccess, (state, { result }) => ({
    ...productAdapter.setAll(result.data, state),
    count: result?.metadata?.total || 0
  }))
);
