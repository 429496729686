import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';
import { LogicRuleAttribute } from '@shared/types/logic-rule.type';

import {
  DeleteCampaignOriginPage,
  TenantCampaign,
  TenantCampaignCreateUpdateRequest,
  TenantCampaignDetails,
  TenantCampaignType,
  TenantCampaignsFilter
} from '../../types';

export const loadTenantCampaigns = createAction(
  '[Tenant Campaigns] Load Tenant Campaigns',
  props<{
    filter: TenantCampaignsFilter;
  }>()
);
export const loadTenantCampaignsSuccess = createAction(
  '[Tenant Campaigns] Load Tenant Campaigns Success',
  props<{ tenantCampaigns: TenantCampaign[]; total: number }>()
);
export const loadTenantCampaignsFailure = createAction(
  '[Tenant Campaigns] Load Tenant Campaigns Failure',
  props<ErrorState>()
);

export const loadTenantCampaign = createAction('[Tenant Campaigns] Load Tenant Campaign', props<{ id: string }>());
export const loadTenantCampaignSuccess = createAction(
  '[Tenant Campaigns] Load Tenant Campaign Success',
  props<{ tenantCampaign: TenantCampaign }>()
);
export const loadTenantCampaignFailure = createAction(
  '[Tenant Campaigns] Load Tenant Campaign Failure',
  props<ErrorState>()
);

export const createTenantCampaign = createAction(
  '[Tenant Campaigns] Create Tenant Campaign',
  props<{ request: TenantCampaignCreateUpdateRequest }>()
);
export const createTenantCampaignSuccess = createAction(
  '[Tenant Campaigns] Create Tenant Campaign Success',
  (
    tenantCampaign: Partial<TenantCampaignDetails>,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully created a campaign'
    }
  ) => ({ tenantCampaign, snackbarMessages })
);
export const createTenantCampaignFailure = createAction(
  '[Tenant Campaigns] Create Tenant Campaign Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your campaign could not be created due to technical issues on our end. Please try creating your campaign again.' +
        'If the issue persists, contact our Help Centre.'
    },
    snackbarDuration?: number
  ) => ({ error, snackbarMessages, snackbarDuration })
);

export const updateTenantCampaign = createAction(
  '[Tenant Campaigns] Update Tenant Campaign',
  props<{ request: TenantCampaignCreateUpdateRequest }>()
);
export const updateTenantCampaignSuccess = createAction(
  '[Tenant Campaigns] Update Tenant Campaign Success',
  props<{ tenantCampaign: Partial<TenantCampaignDetails> }>()
);
export const updateTenantCampaignFailure = createAction(
  '[Tenant Campaigns] Update Tenant Campaign Failure',
  props<ErrorState>()
);

export const activateTenantCampaign = createAction(
  '[Tenant Campaigns] Activate Tenant Campaign',
  props<{ id: string }>()
);
export const activateTenantCampaignSuccess = createAction('[Tenant Campaigns] Activate Tenant Campaign Success');
export const activateTenantCampaignFailure = createAction(
  '[Tenant Campaigns] Activate Tenant Campaign Failure',
  props<ErrorState>()
);

export const deleteTenantCampaign = createAction(
  '[Tenant Campaigns] Delete Tenant Campaign',
  props<{ id: string; originPage: DeleteCampaignOriginPage }>()
);
export const deleteTenantCampaignSuccess = createAction(
  '[Tenant Campaigns] Delete Tenant Campaign Success',
  (
    originPage: DeleteCampaignOriginPage,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully deleted the campaign'
    }
  ) => ({ originPage, snackbarMessages })
);
export const deleteTenantCampaignFailure = createAction(
  '[Tenant Campaigns] Delete Tenant Campaign Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your campaign could not be deleted due to technical issues on our end. Please try deleting your campaign again.' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const duplicateTenantCampaign = createAction(
  '[Tenant Campaigns] Duplicate Tenant Campaign',
  props<{ id: string }>()
);
export const duplicateTenantCampaignSuccess = createAction(
  '[Tenant Campaigns] Duplicate Tenant Campaign Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully duplicated a campaign'
    }
  ) => ({ snackbarMessages })
);
export const duplicateTenantCampaignFailure = createAction(
  '[Tenant Campaigns] Duplicate Tenant Campaign Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your campaign could not be duplicated due to technical issues on our end. Please try duplicating your campaign again.' +
        'If the issue persists, contact our Help Centre.'
    }
  ) => ({ error, snackbarMessages })
);

export const loadTenantCampaignAttributes = createAction(
  '[Tenant Campaigns] Load Attributes',
  props<{ campaignType: TenantCampaignType }>()
);

export const loadTenantCampaignAttributesSuccess = createAction(
  '[Tenant Campaigns] Load Attributes Success',
  props<{
    attributeCampaignType: TenantCampaignType;
    attributeMap: Record<string, LogicRuleAttribute>;
  }>()
);
export const loadTenantCampaignAttributesFailure = createAction(
  '[Tenant Campaigns] Load Attributes Failure',
  props<{ attributeCampaignType: TenantCampaignType; errorState: ErrorState }>()
);

export const loadTenantCampaignConsent = createAction('[Tenant Campaigns] Load Consent');
export const loadTenantCampaignConsentSuccess = createAction(
  '[Tenant Campaigns] Load Consent Success',
  props<{ consent: boolean | null }>()
);
export const loadTenantCampaignConsentFailure = createAction(
  '[Tenant Campaigns] Load Consent Failure',
  props<ErrorState>()
);

export const acceptTenantCampaignConsent = createAction('[Tenant Campaigns] Accept Consent');
export const acceptTenantCampaignConsentSuccess = createAction('[Tenant Campaigns] Accept Consent Success');
export const acceptTenantCampaignConsentFailure = createAction(
  '[Tenant Campaigns] Accept Consent Failure',
  props<ErrorState>()
);
