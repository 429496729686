import { catchError, concatMap, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, of } from 'rxjs';

import { updateApprovalRequestedAction } from '@core/store/interrupted/actions/interrupted.actions';
import { routerForceNavigate, routerNavigate } from '@core/store/router/actions/router.actions';
import { ChangesUtils } from '@utils';

import { TenantsService } from '../../services/tenants.service';
import { TenantState } from '../../types/tenants.type';
import {
  enrollTenant,
  enrollTenantFailure,
  enrollTenantSuccess,
  handoverToTenant,
  handoverToTenantFailure,
  handoverToTenantSuccess,
  loadTenant,
  loadTenantFailure,
  loadTenants,
  loadTenantsFailure,
  loadTenantsSuccess,
  loadTenantSuccess,
  updateTenant,
  updateTenantFailure,
  updateTenantSuccess
} from '../actions/tenants.actions';
import { tenantsQuery } from '../selectors/tenants.selectors';

@Injectable()
export class TenantsEffects {
  loadTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenants),
      exhaustMap(() =>
        this.tenantService.getTenants().pipe(
          map(tenants => loadTenantsSuccess({ tenants })),
          catchError(error => of(loadTenantsFailure({ error })))
        )
      )
    )
  );

  loadTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTenant),
      exhaustMap(action =>
        this.tenantService.getTenant(action.id).pipe(
          map(tenant => loadTenantSuccess({ tenant })),
          catchError(error => of(loadTenantFailure({ error })))
        )
      )
    )
  );

  updateTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTenant),
      concatMap(({ tenant }) =>
        of(tenant).pipe(
          withLatestFrom(this.store.select(tenantsQuery.getTenantById(tenant.id))),
          map(([updatedTenant, originalTenant]) => ({
            ...updatedTenant,
            changes: ChangesUtils.getObjectSubsetChanges(originalTenant, updatedTenant)
          }))
        )
      ),
      tap(updatedTenant =>
        this.store.dispatch(updateApprovalRequestedAction({ action: updateTenantSuccess({ tenant: updatedTenant }) }))
      ),
      exhaustMap(updatedTenant =>
        this.tenantService.updateTenant(updatedTenant).pipe(
          map(tenant => updateTenantSuccess({ tenant })),
          catchError(error => of(updateTenantFailure({ error })))
        )
      )
    )
  );

  updateTenantSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTenantSuccess),
      exhaustMap(({ tenant: { id } }) => merge([loadTenant({ id }), routerNavigate({ path: '/tenants/' + id })]))
    )
  );

  enrollTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollTenant),
      exhaustMap(({ params }) =>
        this.tenantService.enrollTenant(params).pipe(
          map(({ tenant }) => enrollTenantSuccess({ mcTenant: tenant })),
          catchError(error => of(enrollTenantFailure({ error })))
        )
      )
    )
  );

  enrollTenantSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollTenantSuccess),
      map(({ mcTenant }) => routerForceNavigate({ path: `/mc-tenants/${mcTenant.id}/details` }))
    )
  );

  handoverToTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handoverToTenant),
      exhaustMap(({ id }) =>
        this.tenantService.handoverToTenant(id).pipe(
          concatMap(() => [handoverToTenantSuccess(), loadTenant({ id })]),
          catchError(error => of(handoverToTenantFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<TenantState>,
    private tenantService: TenantsService
  ) {}
}
