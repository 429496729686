import { filter, switchMap } from 'rxjs/operators';

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';

import { TagsService } from '@shared/services/tags.service';
import { FETCHABLE_TAG_TYPES, Tag, TagIdsRecord } from '@shared/types';
import { isDataFetching } from '@tag-utils';

import { TagDialogV2Component } from '../tag-dialog-v2/tag-dialog-v2.component';

@Component({
  selector: 'admin-input-tags-v2',
  templateUrl: './input-tags-v2.component.html',
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }]
})
export class InputTagsV2Component {
  @Input() tags: AbstractControl;
  @Input() dialogTitle: string;
  @Input() submitButtonText: string;

  loading$: Observable<boolean>;

  constructor(
    private matDialog: MatDialog,
    private store: Store,
    private tagsService: TagsService
  ) {}

  openTagDialog(): void {
    this.matDialog
      .open(TagDialogV2Component, {
        width: '500px',
        data: {
          dialogTitle: this.dialogTitle,
          submitButtonText: this.submitButtonText
        }
      })
      .afterClosed()
      .pipe(
        filter(tag => !!tag),
        switchMap(tag => {
          this.loading$ = FETCHABLE_TAG_TYPES.includes(tag.type)
            ? this.store.select(isDataFetching({ [tag.type]: [tag.id] } as TagIdsRecord))
            : of(false);
          // add of(null) here to resolve combineLatest not emitting any value in case of empty array
          return combineLatest([...this.tagsService.fetchTags([tag]), of(null)]).pipe(
            switchMap(() => this.tagsService.formatTag$(tag))
          );
        })
      )
      .subscribe(formattedTag => {
        const tagIndex = this.tags.value?.findIndex(tag => this.tagsService.equals(tag, formattedTag)) ?? -1;
        const isNewTag = formattedTag && tagIndex === -1;

        if (isNewTag && this.tags.value) {
          this.tags.setValue([...this.tags.value, formattedTag]);
        } else if (isNewTag) {
          this.tags.setValue([formattedTag]);
        } else if (tagIndex !== -1) {
          const replacedTags = [...this.tags.value];
          replacedTags[tagIndex] = formattedTag;
          this.tags.setValue(replacedTags, { emitEvent: false });
        }
      });
  }

  removeFromTagsChipList(removedTag: Tag): void {
    this.tags.setValue(this.tags.value.filter((tag: Tag) => !this.tagsService.equals(tag, removedTag)));
  }
}
