import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, rewardTriggerAdapter, RewardTriggerState } from '../../types';
import {
  loadRewardTrigger,
  loadRewardTriggerFailure,
  loadRewardTriggers,
  loadRewardTriggersFailure,
  loadRewardTriggersSuccess,
  loadRewardTriggerSuccess,
  resetRewardTrigger,
  resetRewardTriggerFailure,
  resetRewardTriggerSuccess
} from '../actions/reward-triggers.actions';

export const rewardTriggerTriggers: EntityTriggers = {
  single: {
    loading: [loadRewardTrigger.type, resetRewardTrigger.type],
    resting: [loadRewardTriggerSuccess.type, resetRewardTriggerSuccess.type],
    erroring: [loadRewardTriggerFailure.type, resetRewardTriggerFailure.type]
  },
  batch: {
    loading: [loadRewardTriggers.type],
    resting: [loadRewardTriggersSuccess.type],
    erroring: [loadRewardTriggersFailure.type]
  }
};

export function rewardTriggersReducer(state: RewardTriggerState = initialState, action: Action): RewardTriggerState {
  return callStateReducer(baseReducer, rewardTriggerTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadRewardTriggerSuccess, (state, { rewardTrigger }) => rewardTriggerAdapter.upsertOne(rewardTrigger, state)),
  on(loadRewardTriggersSuccess, (state, { rewardTriggersResult }) => ({
    ...rewardTriggerAdapter.setAll(rewardTriggersResult.data, state),
    count: rewardTriggersResult?.meta.count || 0
  })),
  on(resetRewardTriggerSuccess, (state, { rewardTrigger }) =>
    rewardTriggerAdapter.updateOne({ id: rewardTrigger.id, changes: { status: rewardTrigger.status } }, state)
  )
);
