import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { CustomerToken } from '../../types/analytics.type';

export const loadCustomerToken = createAction('[Analytics] Load Customer Token');
export const loadCustomerTokenSuccess = createAction(
  '[Analytics] Load Customer Token Success',
  props<{ customerToken: CustomerToken }>()
);
export const loadCustomerTokenFailure = createAction('[Analytics] Load Customer Token Failure', props<ErrorState>());
