import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { PaymentTier, UpdatePaymentTierPayload } from '../../payment-tiers/types/payment-tiers.type';
import { McTenant, McTenantResponse, McTenantsResponse } from '../types/mc-tenants.type';

@Injectable({
  providedIn: 'root'
})
export class McTenantsService {
  constructor(private missionControlService: MissionControlService) {}

  getMcTenants(): Observable<McTenantsResponse> {
    return this.missionControlService.get<McTenantsResponse>('mc_tenants');
  }

  getMcTenant(id: string): Observable<McTenantResponse> {
    return this.missionControlService.get<McTenantResponse>(`mc_tenants/${id}`);
  }

  createMcTenant(mcTenant: McTenant): Observable<McTenantResponse> {
    return this.missionControlService.post<McTenantResponse>('mc_tenants', mcTenant);
  }

  updateMcTenant(mcTenant: McTenant): Observable<McTenantResponse> {
    return this.missionControlService.patch<McTenantResponse>(`mc_tenants/${mcTenant.id}`, mcTenant);
  }

  clearMcTenantBootstrapCache(): Observable<void> {
    return this.missionControlService.post<void>('clear_bootstrap_cache');
  }

  updateTenantPaymentTier(payload: UpdatePaymentTierPayload): Observable<PaymentTier> {
    const { tenantId, paymentTierId, version } = payload;
    return this.missionControlService.patch<PaymentTier>(`mc_tenants/${tenantId}/update_payment_tier`, {
      paymentTierId,
      version
    });
  }
}
