import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { CreateIdentityParams, Identity, User } from '../../types';

export const loadIdentities = createAction('[Users] Load User Identities', props<{ userId: string }>());
export const loadIdentitiesSuccess = createAction(
  '[Users] Load User Identities Success',
  props<{ identities: Identity[] }>()
);
export const loadIdentitiesFailure = createAction('[Users] Load User Identities Failure', props<ErrorState>());

export const createIdentity = createAction(
  '[Users] Create Identity',
  props<{ params: CreateIdentityParams; dialogRefId: string }>()
);
export const createIdentitySuccess = createAction(
  '[Users] Create Identity Success',
  props<{ userId: string; dialogRefId: string }>()
);
export const createIdentityFailure = createAction('[Users] Create Identity Failure', props<ErrorState>());

export const verifyIdentity = createAction('[Users] Verify User Identity', props<{ mfaIdentityId: string }>());
export const verifyIdentitySuccess = createAction('[Users] Verify User Identity Success');
export const verifyIdentityFailure = createAction('[Users] Verify User Identity Failure', props<ErrorState>());

export const deleteIdentity = createAction('[Users] Delete User Identity', props<{ reference: string; user: User }>());
export const deleteIdentitySuccess = createAction(
  '[Users] Delete User Identity Success',
  props<{ reference: string; user: User }>()
);
export const deleteIdentityFailure = createAction('[Users] Delete User Identity Failure', props<ErrorState>());
