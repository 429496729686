import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { AllowedOriginState, initialState } from '../../types/allowed-origins.type';
import {
  loadAllowedOrigins,
  loadAllowedOriginsFailure,
  loadAllowedOriginsSuccess,
  refreshAllowedOrigins,
  refreshAllowedOriginsFailure,
  refreshAllowedOriginsSuccess
} from '../actions/allowed-origins.actions';

export const allowedOriginTriggers: EntityTriggers = {
  single: {
    loading: [refreshAllowedOrigins.type],
    resting: [refreshAllowedOriginsSuccess.type],
    erroring: [refreshAllowedOriginsFailure.type]
  },
  batch: {
    loading: [loadAllowedOrigins.type],
    resting: [loadAllowedOriginsSuccess.type],
    erroring: [loadAllowedOriginsFailure.type]
  }
};

export function allowedOriginsReducer(state: AllowedOriginState = initialState, action: Action): AllowedOriginState {
  return callStateReducer(baseReducer, allowedOriginTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadAllowedOriginsSuccess, (state, { allowedOrigins }) => ({ ...state, allowedOrigins }))
);
