import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { BulkUpdateMcTranslationsPayload, McTranslation } from '../../types';

export const loadMcTranslations = createAction('[MC Translations] Load MC Translations');
export const loadMcTranslationsSuccess = createAction(
  '[MC Translations] Load MC Translations Success',
  props<{ mcTranslations: McTranslation[] }>()
);
export const loadMcTranslationsFailure = createAction(
  '[MC Translations] Load MC Translations Failure',
  props<ErrorState>()
);

export const bulkUpdateMcTranslations = createAction(
  '[MC Translations] Bulk update MC Translations',
  props<{ payload: BulkUpdateMcTranslationsPayload }>()
);

export const bulkUpdateMcTranslationsSuccess = createAction('[MC Translations] Bulk update MC Translations Success');

export const bulkUpdateMcTranslationsFailure = createAction(
  '[MC Translations] Bulk update MC Translations Failure',
  props<ErrorState>()
);
