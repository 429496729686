import { Component, ElementRef, Inject, Input, OnChanges, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'admin-config-preview',
  styleUrls: ['./config-preview.component.scss'],
  templateUrl: './config-preview.component.html'
})
export class ConfigPreviewComponent implements OnChanges {
  @ViewChild('containerRef', { static: true }) containerRef: ElementRef<HTMLDivElement>;
  @Input() path: string;
  @Input() config = {};
  iframe: HTMLIFrameElement;

  constructor(
    private domSanitizer: DomSanitizer,
    @Inject('guardhouseApiUrl') private guardhouseApiUrl: string
  ) {}

  generateSafeUrl(url: string): string {
    const safeSourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    return this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, safeSourceUrl) || '';
  }

  createIframe<T>(message: { type: string; data: T }): void {
    const src = this.guardhouseApiUrl + this.path;

    this.iframe = document.createElement('iframe');
    this.iframe.src = this.generateSafeUrl(src);
    this.iframe.width = '100%';
    this.iframe.height = '100%';

    this.iframe.addEventListener('load', () => {
      this.iframe.contentWindow!.postMessage(message, this.guardhouseApiUrl);
    });

    this.containerRef.nativeElement.append(this.iframe);
  }

  ngOnChanges(): void {
    if (this.iframe) {
      this.iframe.remove();
    }
    // use type 'init_test_mode' so that iframe won't trigger reload
    this.createIframe<Object>({
      type: 'init_test_mode',
      data: { ...this.config, test_mode: true }
    });
  }
}
