import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface TenantLocale {
  locale: string;
  tenant_id?: string;
  enabled: boolean;
  defaultForTenant: boolean;
  localeName?: string;
}

export interface BulkUpdateTenantLocalesPayload {
  version: number;
  tenantLocales: TenantLocale[];
}

export function selectTenantLocale(tenant_locale: TenantLocale): string {
  return tenant_locale.locale;
}

export const tenantLocaleAdapter = createEntityAdapter<TenantLocale>({
  selectId: selectTenantLocale
});

export interface TenantLocaleState extends EntityState<TenantLocale>, CallState {}

export const initialTenantLocaleState: TenantLocaleState =
  tenantLocaleAdapter.getInitialState<CallState>(initialCallState);
