import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { TransactionsFilter, TransactionsResult } from '../../types';

export const loadTransactions = createAction(
  '[Transactions] Load Transactions',
  props<{ userId: string; filter: TransactionsFilter }>()
);
export const loadTransactionsSuccess = createAction(
  '[Transactions] Load Transactions Success',
  props<{ result: TransactionsResult; userId: string }>()
);
export const loadTransactionsFailure = createAction('[Transactions] Load Transactions Failure', props<ErrorState>());
