import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { CurrencyConversionState } from '../../types';

const getCurrencyConversionState = createFeatureSelector<CurrencyConversionState>('currencyConversion');

const { isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<CurrencyConversionState>(getCurrencyConversionState);

const getCurrencyConversion = createSelector(
  getCurrencyConversionState,
  currencyConversionState => currencyConversionState.currencyConversion
);

export const currencyConversionQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  getCurrencyConversion
};
