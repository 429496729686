import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { User } from '../../users/types';

import { PointsAccountsFilter } from './points-accounts-filter.type';

export interface PointsAccount {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  user?: User;
}

export const pointsAccountAdapter = createEntityAdapter<PointsAccount>();

export interface PointsAccountState extends EntityState<PointsAccount>, CallState {
  count: number;
  filter: PointsAccountsFilter;
}

export const initialState: PointsAccountState = {
  ...pointsAccountAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  filter: null
};
