import { Signal, inject, Inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { map, startWith } from 'rxjs';

import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { DecodedUser } from '@core/types';
import { DEMO_TENANT } from '@shared/tokens/tenant.tokens';

import { Scopes } from '../scopes/scopes.service';

@Injectable()
export class PiiAccess {
  acrLevel: Signal<number>;

  readonly scopes: Scopes = inject(Scopes);

  constructor(
    private store: Store<AuthState>,
    @Inject('piiMaskingEnabled') private piiMaskingEnabled: boolean,
    @Inject(DEMO_TENANT) private isDemoTenant: boolean = false
  ) {
    this.acrLevel = toSignal(
      this.store.select(authQuery.getUser).pipe(
        map((user: DecodedUser) => user?.acr),
        startWith(1)
      )
    );
  }

  isUnmasked(): boolean {
    if (this.piiMaskingEnabled) {
      const steppedUp = this.scopes.has('step_up_for_pii') && this.acrLevel() > 1;

      return this.isDemoTenant || this.scopes.has('view_pii') || steppedUp;
    }

    return true;
  }

  isMasked(): boolean {
    return !this.isUnmasked();
  }
}
