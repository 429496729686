<mat-card appearance="outlined" class="mat-elevation-z1">
  <div *ngIf="error" fxLayout="row" fxLayoutAlign="center center" fxFlex>
    <div>
      <h4><mat-icon [inline]="true">warning</mat-icon> Loading points activity history failed</h4>
    </div>
  </div>
  <mat-card-content *ngIf="!error">
    <mat-card-title>Points Activity History</mat-card-title>
    <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
      <div fxLayout="start center" fxLayoutGap="5px" class="slim-form-field">
        <mat-form-field>
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Description</mat-label>
          <input
            matInput
            aria-label="Search"
            type="search"
            placeholder="Search description"
            formControlName="description"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>
            <mat-icon>remove_red_eye</mat-icon>
            Type
          </mat-label>
          <mat-select
            (ngModelChange)="onCategoryChange()"
            formControlName="category"
            disableOptionCentering
            panelClass="dropdown-panel"
          >
            <mat-option *ngFor="let category of categoryList | keyvalue" [value]="category.value">
              {{ category.key | customTitleCase: 'fromCamelCase' }}
            </mat-option>
          </mat-select>
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.category"
            [loading]="loading"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field formGroupName="transactionTime" class="date-form">
          <mat-label>Date Range</mat-label>
          <div class="date-form__wrapper">
            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matInput
                matStartDate
                formControlName="dateFrom"
                placeholder="Start date"
                (focus)="picker.open()"
              />
              <input matInput matEndDate formControlName="dateTo" placeholder="End date" (focus)="picker.open()" />
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <button
              matSuffix
              mat-icon-button
              color="primary"
              (click)="filterForm.controls.transactionTime.reset()"
              [disabled]="!dateFrom && !dateTo"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-form-field>
      </div>
    </form>
    <div class="data-table-container w-full">
      <div *ngIf="loading" class="progress-loader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <table mat-table [dataSource]="pointsActivities" class="data-table" [class.overlay]="loading">
        <ng-container matColumnDef="transactionTime">
          <th mat-header-cell *matHeaderCellDef class="uppercase-header">Date</th>
          <td mat-cell *matCellDef="let pointsActivity" matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
            {{ pointsActivity.transactionTime | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="uppercase-header">Description</th>
          <td mat-cell *matCellDef="let pointsActivity">{{ pointsActivity.description }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef class="uppercase-header">Type</th>
          <td mat-cell *matCellDef="let pointsActivity">
            {{ invertedCategoryList[pointsActivity.category] | customTitleCase: 'fromCamelCase' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef class="uppercase-header">{{ loyaltyCurrency }}</th>
          <td mat-cell *matCellDef="let pointsActivity">
            {{ getSign(pointsActivity.entryType) }}{{ pointsActivity.amount | roundedNumber: pointsActivityDecimals }}
          </td>
        </ng-container>

        <ng-container matColumnDef="balanceAsOf">
          <th mat-header-cell *matHeaderCellDef class="uppercase-header">Balance</th>
          <td mat-cell *matCellDef="let pointsActivity">
            {{ pointsActivity.balanceAsOf | roundedNumber: pointsActivityDecimals }}
          </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef>DETAIL</th>
          <td mat-cell *matCellDef="let pointsActivity">
            <a
              class="link-icon"
              [routerLink]="'./points-activities/' + pointsActivity.id"
              queryParamsHandling="preserve"
            >
              <button mat-icon-button class="actions-button">
                <mat-icon>zoom_in</mat-icon>
              </button>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!loading">
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.deduction-row]="isDeduction(row.entryType)"
            [class.addition-row]="isAddition(row.entryType)"
          ></tr>
        </ng-container>
      </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="filter.limit"
      [pageIndex]="filter.page - 1"
      [length]="count"
      (page)="onPage($event)"
      [disabled]="loading"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
