import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, NydusNetworkParamConverter, ObjectUtils } from '@utils';

import { RewardTrigger, RewardTriggersFilter, RewardTriggersResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class RewardTriggersService {
  constructor(private missionControlService: MissionControlService) {}

  getRewardTriggers(filter: RewardTriggersFilter): Observable<RewardTriggersResult> {
    const params = NydusNetworkParamConverter.convertToNydusNetworkParam(filter, { search: ['userId'] });

    return this.missionControlService
      .get<RewardTriggersResult>(
        'reward_triggers',
        new HttpParams({
          fromObject: {
            ...ObjectUtils.prepareQueryObject(ObjectUtils.sanitizeRequestObject(params))
          },
          encoder: new BracketParamsEncoder()
        })
      )
      .pipe(map(rewardTriggers => this.sortRewardTriggersByCreated(rewardTriggers)));
  }

  getRewardTrigger(id: string): Observable<RewardTrigger> {
    return this.missionControlService.get<RewardTrigger>(`reward_triggers/${id}`);
  }

  resetRewardTrigger(id: string): Observable<RewardTrigger> {
    const newStatus = { rewardTrigger: { status: 'ready' } };
    return this.missionControlService.patch<RewardTrigger>(`reward_triggers/${id}`, newStatus);
  }

  private sortRewardTriggersByCreated(rewardTriggers: RewardTriggersResult): RewardTriggersResult {
    const { data, meta } = rewardTriggers;
    const sortedRewardTriggers = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const sortedRewards: RewardTrigger[] = sortedRewardTriggers.map(rewardTrigger => {
      const { rewards, ...rest } = rewardTrigger;
      const sortRewards = rewards.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      return { rewards: sortRewards, ...rest };
    });

    return { data: sortedRewards, meta };
  }
}
