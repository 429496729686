import { DecodedIdToken, HermesError, HermesSession, RefreshTokenExchangeResult, Transaction } from '@kaligo/hermes';
import { DecodedAccessToken } from '@kaligo/hermes/dist/lib/shared/decoded_token';

import { GuardhouseErrorCodes } from '@core/errors/error-codes.type';
import { DecodedUser } from '@core/types';

// eslint-disable-next-line max-len
export const mockToken =
  'eyJhbGciOiJFRDI1NTE5In0.eyJzdGF0dXMiOiJhY3RpdmUiLCJ1aWQiOiIxMDgyOTYwOTYyNDU4NTkzMDk4MDciLCJzdWIiOiJlMTBhMmViNy03YzNmLTQxNjAtODExZi1kOWIzZDdhMDg1NTQiLCJpc3MiOiJnaC1kZXYua2FsaWdvLmNvbSIsImF1ZCI6ImFwLWRldi5rYWxpZ28uY29tIiwiZXhwIjoxNTg1MDQ0NjE4LCJpYXQiOjE1ODUwNDQzMTgsIm5vbmNlIjoiM2ZhNDI1M2EtZWUxMS00NjBiLTgxNDgtMzdkMTAwMmFiYTBhIiwianRpIjoiNmQ4NDA4ODYtNmVhOC00MWYxLWFkZmItY2U4ZGY2YjEzOGY5Iiwic2NvcGUiOiJwcm9maWxlIGFkZHJlc3Mgb3BlbmlkIn0.PeDOwxYmfP8cKLbnfYe3lL9ON9RV3XGCwyxQ1ZR-2pcKGlfI5LaBnRU81QXn1gdoEHDT-7MmOyZ_p36yghFxCw';
export const mockDecodedToken: DecodedIdToken = {
  status: 'active',
  uid: '108296096245859309807',
  sub: 'e10a2eb7-7c3f-4160-811f-d9b3d7a08554',
  iss: 'gh-dev.kaligo.com',
  aud: 'ap-dev.kaligo.com',
  exp: 1_585_044_618,
  iat: 1_585_044_318,
  nonce: '3fa4253a-ee11-460b-8148-37d1002aba0a',
  jti: '6d840886-6ea8-41f1-adfb-ce8df6b138f9',
  scope: 'profile address openid',
  acr: 1,
  act: '',
  name: 'Boon Seng Tan',
  nickname: 'bst',
  tenant_id: 'premium_rewards',
  first_name: 'Tan',
  last_name: 'Boon Seng',
  middle_name: 'BS',
  preferred_username: 'bstan',
  profile: 'tbs',
  picture: 'http://kaligo.com/avatar.png',
  email: 'bstan@gmail.com',
  gender: 'male',
  zoneinfo: 'America/Los_Angeles',
  locale: 'en-US',
  birthdate: '1990-02-28',
  salutation: 'mr',
  phone: '88888888',
  phone_number_verified: false,
  address: {},
  country: 'US',
  custom: {},
  email_verified: false
};

export const mockDecodedAccessToken: DecodedAccessToken = {
  acr: 1,
  act: '',
  aud: 'dbsrewards.admin.us.kaligo-staging.xyz',
  exp: 1_726_534_931,
  iat: 1_726_448_531,
  iss: 'rc-guardhouse.stg-rc-us.int.kaligo.com',
  jti: 'fd7d8e2a-ab0c-4dfb-84fe-1a6bf8617b47',
  nonce: '38d43d24-2f29-4916-81a3-6acb91735139',
  scope: 'openid address phone email profile custom audit_logs:index create_user',
  sub: '44ebcb8d-8bb0-45ac-a55c-f336acecf39a',
  tenant_id: 'AscendaGo',
  uid: 'bstan@gmail.com',
  roles: ['ascenda_admin', 'tenants_manager']
};

export const mockDecodedTokenResult: DecodedUser = {
  acr: 1,
  status: 'active',
  sub: 'e10a2eb7-7c3f-4160-811f-d9b3d7a08554',
  name: 'Boon Seng Tan',
  firstName: 'Tan',
  lastName: 'Boon Seng',
  middleName: 'BS',
  nickname: 'bst',
  preferredUsername: 'bstan',
  profile: 'tbs',
  picture: 'http://kaligo.com/avatar.png',
  email: 'bstan@gmail.com',
  gender: 'male',
  zoneinfo: 'America/Los_Angeles',
  locale: 'en-US',
  scopes: ['profile', 'address', 'openid'],
  tenantId: 'premium_rewards',
  birthdate: new Date('1990-02-28'),
  salutation: 'mr',
  phone: '88888888',
  phoneNumberVerified: false,
  address: {},
  country: 'US',
  custom: {},
  emailVerified: false,
  roleIds: ['ascenda_admin', 'tenants_manager']
};

export const mockTokenResult: RefreshTokenExchangeResult = {
  refreshToken: { token: mockToken, expiresAt: 1_600_003_417 },
  idToken: mockToken,
  accessToken: mockToken,
  decodedIdToken: mockDecodedToken,
  decodedAccessToken: mockDecodedToken,
  expiresAt: 1_600_003_417,
  preferences: {}
};

export const mockTokenResultWithoutAccessToken: RefreshTokenExchangeResult = {
  ...mockTokenResult,
  accessToken: undefined
};
export const mockTokenResultWithExpiresAtChanged: RefreshTokenExchangeResult = {
  ...mockTokenResult,
  expiresAt: 2_600_003_417
};

export const mockLoggedInSession: HermesSession = { loggedIn: true, state: '123' };
export const mockLoggedInSession2: HermesSession = { ...mockLoggedInSession, state: '456' };
export const mockLoggedOutSession: HermesSession = { loggedIn: false, state: '123' };

export const mockHermesSessionExpiredError = new HermesError({
  code: GuardhouseErrorCodes.SESSION_EXPIRED,
  message: 'Session Expired.'
});
export const mockHermesOtherError = new HermesError({ code: 'GH0000', message: 'something broke' });
export const mockHermesUnauthenticatedAppError = new HermesError({ code: 'GH2026', message: 'something broke' });
export const mockHermesMissingRefreshTokenError = new HermesError({
  code: 'HM09',
  message: 'Missing refresh token from localStorage.'
});
export const mockHermesInvalidTokenError = new HermesError({ code: 'HM07', message: 'Invalid refresh token.' });

export const mockTransaction: Transaction = { nonce: '32f4c3ff-3ae5-418a-9fad-c223749d6b91' };
