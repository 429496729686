import { AbstractControl, ValidatorFn } from '@angular/forms';

import { URL_REGEX } from '@utils';

export function urlValidator(regex: RegExp = URL_REGEX): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    try {
      const isUrlValid = decodeURIComponent(control.value).match(regex);
      return isUrlValid ? null : { invalidUrl: true };
    } catch {
      // catch URIError error
      return { invalidUrl: true };
    }
  };
}
