import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Question {
  id: string;
  type: string;
  contentType: string;
  topicId: string;
  subTopic: string;
  topicName: string;
  question: string;
  answer: string;
  activated: boolean;
}

export interface QuestionsResponse {
  data: Question[];
  meta: {
    count: number;
  };
}

export interface ActivateQuestionsPayload {
  ids: string[];
}

export interface QuestionState extends CallState {
  questions: Question[];
}

export const initialState: QuestionState = {
  ...initialCallState,
  questions: null
};
