import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { InvalidateTokenParams, UserTokensFilter, UserTokensResult } from '../../types';

export const loadUserTokens = createAction(
  '[User Tokens] Load User Tokens',
  props<{ userId: string; filter: UserTokensFilter }>()
);
export const loadUserTokensSuccess = createAction(
  '[User Tokens] Load User Tokens Success',
  props<{ userTokensResult: UserTokensResult }>()
);
export const loadUserTokensFailure = createAction('[User Tokens] Load User Tokens Failure', props<ErrorState>());

export const invalidateUserToken = createAction(
  '[User Tokens] Invalidate User Token',
  props<{ params: InvalidateTokenParams; dialogRefId: string }>()
);
export const invalidateUserTokenSuccess = createAction(
  '[User Tokens] Invalidate User Token Success',
  props<{ params: InvalidateTokenParams; dialogRefId: string }>()
);
export const invalidateUserTokenFail = createAction('[User Tokens] Invalidate User Token Fail', props<ErrorState>());
