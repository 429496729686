import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';

import { updateSelectedYear } from '../store/actions/campaign-proposals.actions';

export const selectedYearResolver: ResolveFn<void> = (): void => {
  const store = inject(Store);

  store.dispatch(updateSelectedYear({ selectedYear: new Date().getFullYear() }));
};
