import { first } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { updateTooltips } from '../../store/actions/tooltips.actions';
import { tooltipsQuery } from '../../store/selectors/tooltips.selectors';
import { TooltipArrayForm, TooltipEditDialogForm } from '../../types/tooltip-forms.type';
import { TooltipKey } from '../../types/tooltip-keys.type';
import { TooltipState } from '../../types/tooltips.type';

interface DialogData {
  tooltipKeys: TooltipKey[];
}

@Component({
  selector: 'admin-tooltips-edit-dialog',
  templateUrl: './tooltips-edit-dialog.component.html',
  styleUrls: ['./tooltips-edit-dialog.component.scss']
})
export class TooltipsEditDialogComponent implements OnInit {
  loading$: Observable<boolean>;
  tooltipForm: FormGroup<TooltipEditDialogForm>;

  constructor(
    private fb: FormBuilder,
    private store: Store<TooltipState>,
    public dialogRef: MatDialogRef<TooltipsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  get tooltips(): FormArray<FormGroup<TooltipArrayForm>> {
    return this.tooltipForm.controls.tooltips;
  }

  ngOnInit(): void {
    const { getTooltipsByKeys, isBatchLoading } = tooltipsQuery;

    this.loading$ = this.store.select(isBatchLoading);

    this.store.pipe(select(getTooltipsByKeys(this.data.tooltipKeys)), first()).subscribe(tooltips => {
      this.tooltipForm = this.fb.group({
        tooltips: this.fb.array(tooltips.map(tooltip => this.fb.group(tooltip)))
      });
    });
  }

  updateTooltips(): void {
    this.store.dispatch(updateTooltips({ ...this.tooltipForm.getRawValue(), dialogRefId: this.dialogRef.id }));
  }
}
