import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export type AuthProviderType = 'sso' | 'api' | 'mfa';

export interface AuthenticationProvider {
  reference?: string;
  tenantId: string;
  providerId: string;
  type?: AuthProviderType;
  config?: object;
  componentTranslations?: object;
  hidden?: boolean;
}

export function selectAuthenticationProviderReference(authenticationProvider: AuthenticationProvider): string {
  return authenticationProvider.reference;
}

export const authenticationProviderAdapter = createEntityAdapter<AuthenticationProvider>({
  selectId: selectAuthenticationProviderReference
});

export interface AuthenticationProviderState extends EntityState<AuthenticationProvider>, CallState {
  fetchList: string[];
}

export const initialState: AuthenticationProviderState = {
  ...authenticationProviderAdapter.getInitialState<CallState>(initialCallState),
  fetchList: []
};
