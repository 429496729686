import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { fileProcessingRequestInitialState, FileProcessingRequestState } from '../../types';
import {
  loadFileProcessingRequests,
  loadFileProcessingRequestsFailure,
  loadFileProcessingRequestsSuccess,
  updateFileProcessingRequestsFilter
} from '../actions/file-processing-requests.actions';

export const fileProcessingTriggers: EntityTriggers = {
  batch: {
    loading: [loadFileProcessingRequests.type],
    resting: [loadFileProcessingRequestsSuccess.type],
    erroring: [loadFileProcessingRequestsFailure.type]
  }
};

export function fileProcessingRequestsReducer(
  state: FileProcessingRequestState = fileProcessingRequestInitialState,
  action: Action
): FileProcessingRequestState {
  return callStateReducer(baseReducer, fileProcessingTriggers)(state, action);
}

const baseReducer = createReducer(
  fileProcessingRequestInitialState,
  on(loadFileProcessingRequestsSuccess, (state, { fileProcessingRequests, filter }) => ({
    ...state,
    fileProcessingRequests,
    filter
  })),
  on(updateFileProcessingRequestsFilter, (state, { filter }) => ({ ...state, filter }))
);
