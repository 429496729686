import { SCOPES_OR, SCOPES_GROUP } from '@core/types';
import { NavItemV2 } from '@core/types/nav-item-v2.type';

const tenantManagementGroup: NavItemV2 = {
  id: 'tenantManagement',
  type: 'submenu',
  label: 'Tenant management',
  iconName: 'building',
  children: [
    {
      id: 'tenants',
      type: 'link',
      label: 'Configurations',
      route: '/tenants', // NOTE: this will be transformed in SideNavComponent
      scopes: SCOPES_OR.viewTenants,
      relationOperator: 'or'
    },
    {
      id: 'authenticationProviders',
      type: 'link',
      label: 'Authentication providers',
      route: '/authentication-providers',
      scopes: SCOPES_OR.viewAuthenticationProviders,
      relationOperator: 'or'
    },
    {
      id: 'enrollTenant',
      type: 'link',
      label: 'Enroll tenant',
      route: '/tenants/enroll',
      scopes: ['tenants:create']
    },
    {
      id: 'uiSettings',
      type: 'link',
      label: 'Update UI',
      route: '/ui-settings',
      scopes: ['manage_ui_settings']
    },
    {
      id: 'questions',
      type: 'link',
      label: 'Update FAQ',
      route: '/questions',
      scopes: ['manage_questions']
    },
    {
      id: 'tenantPaymentTier',
      type: 'link',
      label: 'Update payment tier',
      route: '/payment-tiers/tenant-config',
      scopes: ['manage_payment_tiers']
    },
    {
      id: 'tenantLocales',
      type: 'link',
      label: 'Tenant locales',
      route: '/tenant-locales',
      scopes: ['view_localisation']
    },
    {
      id: 'translations',
      type: 'link',
      label: 'Translations',
      route: '/translations',
      scopes: ['view_localisation']
    }
  ]
};

const appOverviewGroup: NavItemV2 = {
  id: 'appsOverview',
  type: 'submenu',
  label: 'Apps overview',
  iconName: 'grid-view',
  children: [
    {
      id: 'apps',
      type: 'link',
      label: 'Apps',
      route: '/apps',
      scopes: SCOPES_OR.viewApps,
      relationOperator: 'or'
    },
    {
      id: 'keys',
      type: 'link',
      label: 'Keys',
      route: '/keys',
      scopes: SCOPES_OR.viewKeys,
      relationOperator: 'or'
    },
    {
      id: 'tools',
      type: 'link',
      label: 'Tools',
      route: '/tools',
      scopes: SCOPES_OR.exportTools,
      relationOperator: 'or'
    },
    {
      id: 'mcTenants',
      type: 'link',
      label: 'MC tenants',
      route: '/mc-tenants',
      scopes: SCOPES_OR.viewMcTenants,
      relationOperator: 'or'
    },
    {
      id: 'domains',
      type: 'link',
      label: 'Domains',
      route: '/domains',
      scopes: SCOPES_OR.viewDomains,
      relationOperator: 'or'
    },
    {
      id: 'guardhouseCache',
      type: 'link',
      label: 'Guardhouse cache',
      route: '/guardhouse-cache',
      scopes: ['cache:clear']
    }
  ]
};

const loyaltyDataGroup: NavItemV2 = {
  id: 'loyaltyData',
  type: 'submenu',
  label: 'Loyalty data',
  iconName: 'database-star',
  scopes: SCOPES_OR.viewLoyaltyDataMenu,
  relationOperator: 'or',
  children: [
    {
      id: 'pointsAccounts',
      type: 'link',
      label: 'Points accounts',
      route: '/loyalty-data/points-accounts',
      scopes: ['points_accounts:index'],
      checkFeatureAvailability: true
    },
    {
      id: 'products',
      type: 'link',
      label: 'Products',
      route: '/loyalty-data/products',
      scopes: ['products:index'],
      checkFeatureAvailability: true
    }
  ]
};

const accessControlGroup: NavItemV2 = {
  id: 'accessControl',
  type: 'submenu',
  label: 'Access Control',
  iconName: 'key',
  relationOperator: 'any-strict-groups',
  children: [
    {
      id: 'agents',
      type: 'link',
      label: 'Agents',
      route: '/agents',
      scopes: SCOPES_OR.viewAgents,
      relationOperator: 'or'
    },
    {
      id: 'teamMembers',
      type: 'link',
      label: 'Team members',
      route: '/team-members',
      scopes: ['view_team_members'],
      checkFeatureAvailability: true
    },
    {
      id: 'roles',
      type: 'link',
      label: 'Roles',
      route: '/roles',
      scopes: SCOPES_GROUP.viewRolePermissionsPage,
      relationOperator: 'any-strict-groups'
    },
    {
      id: 'accessPolicies',
      type: 'link',
      label: 'Access policies',
      route: '/access-policies',
      scopes: SCOPES_OR.viewAccessPolicies,
      relationOperator: 'or'
    },
    {
      id: 'abilities',
      type: 'link',
      label: 'Abilities',
      route: '/abilities',
      scopes: SCOPES_OR.viewAbilities,
      relationOperator: 'or'
    },
    {
      id: 'suppliers',
      type: 'link',
      label: 'Suppliers',
      route: '/suppliers',
      scopes: ['view_suppliers'],
      checkFeatureAvailability: true
    }
  ]
};

export const toolsNavItems: NavItemV2[] = [
  tenantManagementGroup,
  appOverviewGroup,
  loyaltyDataGroup,
  accessControlGroup
];
