import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { map, Observable } from 'rxjs';

import { AuthGuardService } from '@core/services/auth-guard/auth-guard.service';
import { GuardRedirectionService } from '@core/services/auth-guard/guard-redirection.service';
import { ScopesCheckService } from '@core/services/scopes/scopes-check.service';

export const userEditAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const authGuardService: AuthGuardService = inject(AuthGuardService);
  const guardRedirectionService: GuardRedirectionService = inject(GuardRedirectionService);
  const scopesCheckService: ScopesCheckService = inject(ScopesCheckService);

  return authGuardService.hasPersonalRouteAccess(route).pipe(
    map(hasPersonalRouteAccess => {
      if (scopesCheckService.hasViewUpdatePageScopes(route.data.isCustomerView) || hasPersonalRouteAccess) {
        return true;
      } else {
        return guardRedirectionService.redirectToPageNotFound();
      }
    })
  );
};
