import { createEntityAdapter } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { McTenant } from '../../mc-tenants/types/mc-tenants.type';

export const rewardsProgramsAdapter = createEntityAdapter<RewardsProgram>();

export interface RewardsProgram {
  tenant: McTenant;
}

export interface UpdateRewardsProgramParams {
  timeZone: string;
  gtmContainerId: string;
  defaultTenantLocale: string;
  translations: {
    locale: string;
    rewardsProgramName: string;
    rewardsCurrency: string;
  }[];
}

export interface RewardsProgramState extends CallState {
  rewardsProgram: RewardsProgram;
}

export const initialState: RewardsProgramState = {
  ...rewardsProgramsAdapter.getInitialState<CallState>(initialCallState),
  rewardsProgram: {
    tenant: null
  }
};
