import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SCOPES_OR } from '@core/types';
import { Formatters } from '@utils';

import { merchandisesQuery } from '../../../merchandises/store/selectors/merchandises.selectors';
import { Merchandise } from '../../../merchandises/types/merchandises.type';
import { MerchandiseOrderItemData, OrderItem, Recipient } from '../../types';

@Component({
  selector: 'admin-merchandise-order-item',
  templateUrl: './merchandise-order-item.component.html',
  styleUrls: ['../../stylesheets/order-item.styles.scss', './merchandise-order-item.component.scss']
})
export class MerchandiseOrderItemComponent implements OnInit {
  @Input() detailsView: string;
  @Input() orderItem: OrderItem;
  @Input() customerId: string;
  @Input() timezone: string;

  data: MerchandiseOrderItemData;
  formattedAddress: string;
  merchandise$: Observable<Merchandise>;
  recipient: Recipient;
  readonly SCOPES = SCOPES_OR;

  constructor(
    private store: Store,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string
  ) {}

  ngOnInit(): void {
    this.data = this.orderItem.data as MerchandiseOrderItemData;
    this.recipient = this.data?.recipient;
    this.merchandise$ = this.store.select(merchandisesQuery.getMerchandiseById(this.data.merchandiseId));
    if (this.recipient?.address) {
      this.formattedAddress = Formatters.recipientAddressFormatter(this.recipient.address);
    }
  }
}
