import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadOffer, loadOffers, loadUserOffers } from '../store/actions/offers.actions';
import { offersQuery } from '../store/selectors/offers.selectors';
import { OffersFilter, OfferState } from '../types';

export const offersResolver: ResolveFn<OffersFilter> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<OfferState> = inject(Store<OfferState>),
  scopes: Scopes = inject(Scopes)
): Observable<OffersFilter> => {
  if (scopes.lackScopes(SCOPES_OR.viewOffers) && scopes.lackScopes(SCOPES_OR.viewUserOffers)) {
    return of(null);
  }

  const isUserRoute = route.data.isUserRoute;
  const filter$ = store.select(offersQuery.getOffersFilter);
  const isBatchResting$ = store.select(offersQuery.isBatchResting);

  return combineLatest([filter$, isBatchResting$]).pipe(
    first(),
    map(([filter, isBatchResting]) => {
      filter ||= new OffersFilter();

      const canLoadUserOffers = isUserRoute && scopes.hasAny(SCOPES_OR.viewUserOffers);
      const isNotLoadedOrFromUserView = !isBatchResting || (isBatchResting && !filter.isFromGlobalView);
      const canLoadGlobalOffers = isNotLoadedOrFromUserView && !isUserRoute && scopes.hasAny(SCOPES_OR.viewOffers);
      const { sortBy, sortDirection, id, ...rest } = filter;

      if (canLoadUserOffers) {
        const userId = Params.find(route, 'userId');
        filter = { ...rest, userId, isFromGlobalView: false };

        store.dispatch(loadUserOffers({ filter }));
      } else if (canLoadGlobalOffers) {
        if (id) {
          // loadOffer if user search with offer id previously
          store.dispatch(loadOffer({ id }));
        } else {
          filter = { ...rest, id: null, isFromGlobalView: true };
          store.dispatch(loadOffers({ filter }));
        }
      }

      return filter;
    })
  );
};
