import { createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { PointsActivity, pointsActivityAdapter, PointsActivityState } from '../../types';
import { DashboardState, getDashboardState } from '../reducers';

const pointsActivityDetector = createDetector<PointsActivity>();

const getPointsActivityState = createSelector(getDashboardState, (state: DashboardState) => state.pointsActivity);

const { selectAll: getPointsActivitiesList, selectEntities: getPointsActivitiesDictionary } =
  pointsActivityAdapter.getSelectors(getPointsActivityState);

const getCount = createSelector(getPointsActivityState, (state: PointsActivityState) => state.count);

const getFilter = createSelector(getPointsActivityState, (state: PointsActivityState) => state.filter);

const getLocale = createSelector(getPointsActivityState, (state: PointsActivityState) => state.locale);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleLoading,
  isSingleResting,
  getSingleError
} = getCallStateSelectors<PointsActivityState>(getPointsActivityState);

const isPointsActivityLoaded = (id: string): Selector<PointsActivityState, boolean> =>
  createSelector(getPointsActivitiesDictionary, entities => pointsActivityDetector('oneLoaded')(entities, id));

const isPointsActivitiesLoadedWithUser = (userId: string): Selector<PointsActivityState, boolean> =>
  createSelector(
    getPointsActivityState,
    isBatchResting,
    (state, batchResting) => batchResting && state.userId === userId
  );

const isPointsActivitiesLoadedWithPointsAccount = (pointsAccountId: string): Selector<PointsActivityState, boolean> =>
  createSelector(
    getPointsActivitiesList,
    isBatchResting,
    (pointsActivitiesList, batchResting) =>
      batchResting &&
      pointsActivitiesList.length > 0 &&
      pointsActivitiesList.every(pointsActivity => pointsActivity.pointsAccountId === pointsAccountId)
  );

const getPointsActivityById = (id: string): Selector<PointsActivityState, PointsActivity> =>
  createSelector(getPointsActivitiesDictionary, entities => entities[id]);

const getPointsActivitiesListByUserId = (userId: string): Selector<PointsActivityState, PointsActivity[]> =>
  createSelector(getPointsActivitiesList, pointsActivitiesList =>
    pointsActivitiesList.filter(pointsActivities => pointsActivities.userId === userId)
  );

export const pointsActivitiesQuery = {
  getPointsActivitiesList,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleLoading,
  isSingleResting,
  isPointsActivityLoaded,
  isPointsActivitiesLoadedWithUser,
  isPointsActivitiesLoadedWithPointsAccount,
  getSingleError,
  getPointsActivityById,
  getPointsActivitiesListByUserId,
  getCount,
  getFilter,
  getLocale
};
