import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { SCOPES_OR } from '@core/types';
import { Formatters, HostTenantMappingUtils } from '@utils';

import { merchandisesQuery } from '../../../merchandises/store/selectors/merchandises.selectors';
import { Merchandise } from '../../../merchandises/types/merchandises.type';
import { MerchandiseOrderItemData, OrderItem, Recipient, StripeReferenceData } from '../../types';

@Component({
  selector: 'admin-merchandise-order-item-v2',
  templateUrl: './merchandise-order-item-v2.component.html',
  styleUrls: ['./merchandise-order-item-v2.component.scss']
})
export class MerchandiseOrderItemV2Component implements OnInit {
  @Input() detailsView: string;
  @Input() customerId: string;
  @Input() orderItem: OrderItem;
  @Input() stripeReferenceData: StripeReferenceData;
  @Input() timezone: string;
  data: MerchandiseOrderItemData;
  formattedAddress: string;
  merchandise$: Observable<Merchandise>;
  recipient: Recipient;
  readonly SCOPES = SCOPES_OR;
  readonly isRc = HostTenantMappingUtils.isRC();

  constructor(
    private store: Store,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string
  ) {}

  ngOnInit(): void {
    this.data = this.orderItem.data as MerchandiseOrderItemData;
    this.recipient = this.data?.recipient;
    this.merchandise$ = this.data.merchandiseId
      ? this.store.select(merchandisesQuery.getMerchandiseById(this.data.merchandiseId))
      : of(null);
    if (this.recipient?.address) {
      this.formattedAddress = Formatters.recipientAddressFormatter(this.recipient.address);
    }
  }
}
