import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Membership {
  id: string;
  type: string;
  userId: string;
  loyaltyProgramId: string;
  loyaltyProgramName: string;
  number: string;
  autosweep: boolean;
  firstName: string;
  lastName: string;
  preferred: boolean;
  category: string;
  enrollmentId: string;
}

export interface MembershipResult {
  data: Membership[];
  meta: {
    count: number;
  };
}

export interface MembershipRaw {
  id: string;
  type: string;
  attributes: {
    userId: string;
    loyaltyProgramId: string;
    loyaltyProgramName: string;
    number: string;
    autosweep: boolean;
    firstName: string;
    lastName: string;
    preferred: boolean;
    category: string;
    enrollmentId: string;
  };
}

export interface MembershipRawResult {
  data: MembershipRaw[];
  meta: {
    count: number;
  };
}

export interface UpdateMembershipParams {
  number?: string;
  enrollmentId?: string;
}

export const membershipAdapter = createEntityAdapter<Membership>();

export interface MembershipState extends EntityState<Membership>, CallState {
  count: number;
  locale: string;
}

export const initialState: MembershipState = {
  ...membershipAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  locale: null
};
