import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, loginAttemptAdapter, LoginAttemptState } from '../../types/login-attempts.type';
import {
  loadLoginAttempt,
  loadLoginAttemptFailure,
  loadLoginAttempts,
  loadLoginAttemptsFailure,
  loadLoginAttemptsSuccess,
  loadLoginAttemptSuccess
} from '../actions/login-attempts.actions';

export const loginAttemptTriggers: EntityTriggers = {
  single: {
    loading: [loadLoginAttempt.type],
    resting: [loadLoginAttemptSuccess.type],
    erroring: [loadLoginAttemptFailure.type]
  },
  batch: {
    loading: [loadLoginAttempts.type],
    resting: [loadLoginAttemptsSuccess.type],
    erroring: [loadLoginAttemptsFailure.type]
  }
};

export function loginAttemptsReducer(state: LoginAttemptState = initialState, action: Action): LoginAttemptState {
  return callStateReducer(baseReducer, loginAttemptTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadLoginAttemptSuccess, (state, { loginAttempt }) => loginAttemptAdapter.upsertOne(loginAttempt, state)),
  on(loadLoginAttempts, (state, { filter }) => ({ ...state, filter })),
  on(loadLoginAttemptsSuccess, (state, { result }) => ({
    ...loginAttemptAdapter.setAll(result.data, state),
    count: result?.metadata?.total || 0
  }))
);
