import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'admin-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() errors: ValidationErrors | null;
  @Input() fieldLabel: string;
  @Input() formControlName: string;

  touched = false;
  value: boolean;

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;
  /* eslint-enable*/

  /* interface implementations */
  onChange = (_value: boolean): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.value = value;
  }
}
