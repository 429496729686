import { filter } from 'rxjs/operators';

import { AfterContentInit, Directive, inject, OnInit } from '@angular/core';
import { MatOption } from '@angular/material/core';

import { injectUntilDestroyed } from '../../../utils/observable-utils';
import { DrawerSelectComponent } from './../components/drawer-select/drawer-select.component';

/**
 * This directive is used to provide option for `admin-drawer-select` component.
 * It extends `mat-option` component and provides additional functionality
 * so that it can be used inside of `admin-drawer-select`.
 * @note This directive must be used inside of `admin-drawer-select` component.
 * @example
 * // Use directly inside of `admin-drawer-select` component
 * <admin-drawer-select>
 *  <mat-option adminDrawerSelectOption [value]="option">Option</mat-option>
 * </admin-drawer-select>
 * @example
 * // Use with a component wrapped inside admin-drawer-select
 *
 * // app-options-component
 * <mat-option adminDrawerSelectOption [value]="option">Option</mat-option>
 *
 * <admin-drawer-select>
 *  <app-options-component />
 * </admin-drawer-select>
 */
@Directive({
  selector: 'mat-option[adminDrawerSelectOption]'
})
export class DrawerSelectOptionDirective implements OnInit, AfterContentInit {
  private option = inject(MatOption);

  private drawerSelect = inject(DrawerSelectComponent, {
    optional: true
  });

  private untilDestroyed = injectUntilDestroyed();

  constructor() {
    if (!this.drawerSelect) {
      throw new Error('mat-option[adminDrawerSelectOption] should be used inside of admin-drawer-select');
    }
  }

  ngOnInit(): void {
    this.subscribeToSelectionChange();
    this.subscribeToDrawerSelectionChange();
  }

  ngAfterContentInit(): void {
    this.setupInitialSelection();
  }

  /**
   * Select option if it is selected in drawer select.
   */
  setupInitialSelection(): void {
    if (this.drawerSelect.selectionModel.isSelected(this.option.value)) {
      this.selectOption();
    }
  }

  /**
   * Subscribe to drawer select selection change then select/deselect option accordingly.
   */
  subscribeToDrawerSelectionChange(): void {
    this.drawerSelect.selectionModel.changed.pipe(this.untilDestroyed()).subscribe(({ added, removed }) => {
      const isAdded = added.find(value => value === this.option.value);
      const isRemoved = removed.find(value => value === this.option.value);

      // If option is added to drawer select selection then select it.
      // If option is removed from drawer select selection then deselect it.
      if (isAdded) {
        this.selectOption();
      } else if (isRemoved) {
        this.option.deselect();
      }
    });
  }

  /**
   * Subscribe to option selection change then update drawer select selection accordingly.
   */
  subscribeToSelectionChange(): void {
    this.option.onSelectionChange
      .pipe(
        filter(({ isUserInput }) => isUserInput),
        this.untilDestroyed()
      )
      .subscribe(({ source }) => {
        this.drawerSelect.handleSelectionChange(source);
      });
  }

  /**
   * Select option and update drawer select selection.
   */
  selectOption(): void {
    this.option.select();
    this.drawerSelect.selectedOption.set(this.option);
  }
}
