import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { NotificationsService } from '../../services/notifications.service';
import {
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess
} from '../actions/notifications.actions';

@Injectable()
export class NotificationsEffects {
  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotifications),
      exhaustMap(({ userId }) =>
        this.notificationsService.getNotifications(userId).pipe(
          map(notifications => loadNotificationsSuccess({ notifications })),
          catchError(error => of(loadNotificationsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService
  ) {}
}
