import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ImageUploadResponse } from '@shared/types';
import { BracketParamsEncoder, NydusNetworkParamConverter, NydusNetworkParamsConfig, ObjectUtils } from '@utils';

import { AUDIENCE } from '../types/promo-code-forms.type';
import { PromoCodesFilter } from '../types/promo-codes-filter.type';
import { PromoCodeResultRaw, PromoCodesResult, PromoCodesResultRaw } from '../types/promo-codes-result.type';
import { PromoCode, PromoCodeBanner, PromoCodeBannerRaw, PromoCodeRaw, Relationship } from '../types/promo-codes.type';

@Injectable({
  providedIn: 'root'
})
export class PromoCodesService {
  constructor(
    @Inject('timezoneOffset') public timezoneOffset: number,
    private missionControlService: MissionControlService
  ) {}

  getPromoCode(promoCodeId: string): Observable<PromoCode> {
    return this.missionControlService
      .get<PromoCodeResultRaw>(`promo_codes/${promoCodeId}`)
      .pipe(map(({ data, included }) => this.formatPromoCodeResult(data, included)));
  }

  getPromoCodes(filter: PromoCodesFilter): Observable<PromoCodesResult> {
    const paramsConfig: NydusNetworkParamsConfig = {
      filter: [
        { key: 'discountType', operator: '$eq' },
        { key: AUDIENCE, operator: '$eq' },
        { key: 'startAt', type: 'date-range' },
        { key: 'createdAt', type: 'date-range' }
      ],
      search: ['code', 'description']
    };

    const params = NydusNetworkParamConverter.convertToNydusNetworkParam(filter, paramsConfig, this.timezoneOffset);

    return this.missionControlService
      .get<PromoCodesResultRaw>(
        'promo_codes',
        new HttpParams({
          fromObject: {
            ...ObjectUtils.prepareQueryObject(ObjectUtils.sanitizeRequestObject(params))
          },
          encoder: new BracketParamsEncoder()
        })
      )
      .pipe(map(promoCodesResultRaw => this.formatPromoCodesResult(promoCodesResultRaw)));
  }

  createPromoCode(promoCode: Partial<PromoCode>): Observable<PromoCode> {
    promoCode = ObjectUtils.sanitizeRequestObject<Partial<PromoCode>>(promoCode, {
      ignoredKeys: ['productEligibility']
    });

    return this.missionControlService
      .post<PromoCodeResultRaw>('promo_codes', promoCode)
      .pipe(map(({ data, included }) => this.formatPromoCodeResult(data, included)));
  }

  updatePromoCode(promoCode: Partial<PromoCode>): Observable<PromoCode> {
    promoCode = ObjectUtils.sanitizeRequestObject<Partial<PromoCode>>(promoCode, {
      ignoredKeys: ['description', 'productEligibility', 'userEligibility', 'endAt']
    });

    return this.missionControlService
      .patch<PromoCodeResultRaw>(`promo_codes/${promoCode.id}`, promoCode)
      .pipe(map(({ data, included }) => this.formatPromoCodeResult(data, included)));
  }

  deletePromoCode(promoCodeId: string): Observable<void> {
    return this.missionControlService.delete<void>(`promo_codes/${promoCodeId}`);
  }

  uploadPromoCodeImage(file: FormData, promoCodeId?: string): Observable<ImageUploadResponse> {
    return this.missionControlService.post<ImageUploadResponse>(
      'promo_codes/upload_image',
      file,
      new HttpParams({ fromObject: { ...(promoCodeId && { promoCodeId }) } })
    );
  }

  private extractPromoCodeBanner(
    promoCodeBannerRaw: PromoCodeBannerRaw[],
    relationships: Relationship
  ): PromoCodeBanner {
    const promoCodeBanner = promoCodeBannerRaw.find(
      (bannerRaw: PromoCodeBannerRaw) => bannerRaw.id === relationships.promoCodeBanner.data?.id
    );
    if (promoCodeBanner) {
      return { id: promoCodeBanner.id, type: promoCodeBanner.type, ...promoCodeBanner.attributes };
    } else {
      return null;
    }
  }

  private formatPromoCodeResult(promoCodeRaw: PromoCodeRaw, included: PromoCodeBannerRaw[]): PromoCode {
    const { id, type, attributes, relationships } = promoCodeRaw;
    const promoCodeBanner = this.extractPromoCodeBanner(included, relationships);
    return { id, type, promoCodeBanner, ...attributes };
  }

  private formatPromoCodesResult(promoCodesResultRaw: PromoCodesResultRaw): PromoCodesResult {
    const { data, meta, included } = promoCodesResultRaw;
    const formattedData = data.map((promoCodeRaw: PromoCodeRaw) => this.formatPromoCodeResult(promoCodeRaw, included));
    return { data: formattedData, meta };
  }
}
