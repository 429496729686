import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { autoRedemptionAdapter, AutoRedemptionState, initialState } from '../../types/auto-redemptions.type';
import {
  loadUserAutoRedemptions,
  loadUserAutoRedemptionsFailure,
  loadUserAutoRedemptionsSuccess
} from '../actions/auto-redemptions.actions';

export const autoRedemptionTriggers: EntityTriggers = {
  batch: {
    loading: [loadUserAutoRedemptions.type],
    resting: [loadUserAutoRedemptionsSuccess.type],
    erroring: [loadUserAutoRedemptionsFailure.type]
  }
};

export function autoRedemptionsReducer(state: AutoRedemptionState = initialState, action: Action): AutoRedemptionState {
  return callStateReducer(baseReducer, autoRedemptionTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadUserAutoRedemptionsSuccess, (state, { autoRedemptions }) =>
    autoRedemptionAdapter.setAll(autoRedemptions, state)
  )
);
