import { FormArray, FormGroup, AbstractControl } from '@angular/forms';

import { BaseTranslationForm } from '@shared/types/common-form.type';

export const FormUtils = {
  /*
   * Check the validity of the translation form array
   * @param form - the form array to check, the form group should contain a locale control
   * @param currentLocale - the current locale
   * @returns the invalid locale or the current locale if the form is valid
   */
  checkTranslationControlValidity: <T extends { [K in keyof T]: AbstractControl<any, any> } & BaseTranslationForm>(
    form: FormArray<FormGroup<T>>,
    currentLocale: string
  ): string => {
    form.updateValueAndValidity();
    const invalidForm = form.controls.find(control => control.invalid);

    return invalidForm ? invalidForm.getRawValue().locale : currentLocale;
  }
};
