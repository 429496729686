import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { TransactionsFilter, TransactionsResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  constructor(private missionControlService: MissionControlService) {}

  getTransactions(userId: string, filter: TransactionsFilter): Observable<TransactionsResult> {
    return this.missionControlService.get<TransactionsResult>(
      'users/transactions',
      new HttpParams({ fromObject: { userId, ...filter } })
    );
  }
}
