<div class="label">{{ label }}</div>

<div
  adminDragAngDropFileUpload
  *ngIf="!(loading$ | async) || currentUploadingId !== componentId; else loadingTemplate"
  class="drag-and-drop-zone"
  (fileChange)="onFileChange($event)"
  [class.has-value]="ngControl.value || imageUrl"
  [class.invalid]="
    onlyShowErrorWhenTouched ? ngControl.control!.touched && ngControl.control!.invalid : ngControl.control!.invalid
  "
  [class.disabled]="disabled"
>
  <button *ngIf="ngControl.value" class="action-button secondary-button">Change image</button>
  <input
    type="file"
    [attr.aria-required]="required"
    [accept]="allowedTypes.join(',')"
    [disabled]="disabled"
    [required]="required"
    (change)="onInputChange($event)"
  />
  <mat-icon [style.opacity]="ngControl.value ? 0 : 1" class="icon" svgIcon="table-image"></mat-icon>
  <div [style.opacity]="ngControl.value ? 0 : 1" class="text">{{ instruction }}</div>
  <img *ngIf="imageUrl" [src]="imageUrl" [alt]="label" class="image" />
</div>

<ng-template #loadingTemplate>
  <div class="drag-and-drop-zone loading">
    <div class="loading-spinner-wrapper">
      <mat-progress-spinner mode="indeterminate" [diameter]="40"></mat-progress-spinner>
    </div>
    <div class="text">{{ instruction }}</div>
  </div>
</ng-template>

<div class="hints">
  <mat-hint *ngIf="dimensionDescription.length" class="hint">Dimensions: {{ dimensionDescription }}</mat-hint>
  <mat-hint *ngIf="fileSizeLimit" class="hint">File size: maximum {{ fileSizeLimit / 1000000 }}MB</mat-hint>
  <mat-hint class="hint">Supported format: {{ allowedTypesHint }}</mat-hint>
  @if (onlyShowErrorWhenTouched ? ngControl.control!.touched : true) {
    <mat-error *ngIf="errorMessage; else validationErrors">{{ errorMessage }}</mat-error>
    <ng-template #validationErrors>
      <mat-error *ngIf="ngControl.control!.hasError('required')">{{
        missingValueErrorMessage || 'Required'
      }}</mat-error>
      <mat-error *ngIf="ngControl.control!.hasError('invalidImageFileType')">
        {{ imageErrorMessage.invalidImageFileType }}
      </mat-error>
      <mat-error *ngIf="ngControl.control!.hasError('invalidImageFileSize')">
        {{ imageErrorMessage.invalidImageFileSize }}
      </mat-error>
      <mat-error *ngIf="ngControl.control!.hasError('invalidImageDimensions')">
        {{ imageErrorMessage.invalidImageDimensions }}
      </mat-error>
    </ng-template>
  }
</div>
