import { createSelector, MemoizedSelector } from '@ngrx/store';

import { LocaleState, LocaleStateSelectors } from '@core/types';

export const initialLocaleState: LocaleState = {
  localeState: {
    batchLocale: null,
    singleLocale: null
  }
};

export function getLocaleStateSelectors<T extends LocaleState>(
  featureSelector: MemoizedSelector<object, T>
): LocaleStateSelectors<T> {
  const getBatchLocale = createSelector(featureSelector, (featureState: T) => featureState.localeState.batchLocale);

  const getSingleLocale = createSelector(featureSelector, (featureState: T) => featureState.localeState.singleLocale);

  return {
    getBatchLocale,
    getSingleLocale
  };
}
