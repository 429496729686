import { Subject } from 'rxjs/internal/Subject';

import { AnimationEvent } from '@angular/animations';
import { CdkDialogContainer } from '@angular/cdk/dialog';
import { AfterViewInit, Component, HostBinding, inject, OnDestroy, signal, ViewEncapsulation } from '@angular/core';

import { slideInAndOut } from '@shared/animations/drawer-animations';
import { DrawerConfig, DrawerState } from '@shared/types/drawer.type';

import { DRAWER_USER_CLASS } from './drawer-user-class.token';

/**
 * This component will be use as our container
 * for our drawer content when we open drawer with `DrawerService`
 */
@Component({
  selector: 'admin-drawer-container',
  template: `
    <div
      class="flex-1 overflow-auto rounded-bl-lg rounded-tl-lg border border-solid border-neutrals-300 bg-white shadow-[0_8px_10px_-5px_#0003,0_16px_24px_2px_#00000024,0_6px_30px_5px_#0000001f]"
      [ngClass]="userClass"
      [@slideInAndOut]="drawerState()"
      (@slideInAndOut.start)="drawerAnimationEvent.next($event)"
      (@slideInAndOut.done)="drawerAnimationEvent.next($event)"
    >
      <ng-template cdkPortalOutlet></ng-template>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  animations: [slideInAndOut]
})
export class DrawerContainerComponent extends CdkDialogContainer<DrawerConfig> implements AfterViewInit, OnDestroy {
  @HostBinding('class') containerClasses = 'flex h-full w-full flex-col';

  drawerState = signal<DrawerState>('closed');

  drawerAnimationEvent = new Subject<AnimationEvent>();

  userClass = inject(DRAWER_USER_CLASS, {
    optional: true
  });

  ngAfterViewInit(): void {
    // Will start animation after all view has completely initialized
    this.startDrawerOpenAnimation();
  }

  startDrawerOpenAnimation(): void {
    this.drawerState.set('opened');
  }

  startDrawerCloseAnimation(): void {
    this.drawerState.set('closed');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.drawerAnimationEvent.complete();
  }
}
