import { createAction, props } from '@ngrx/store';

import { ErrorState, SnackbarMessages } from '@core/types';

import { McTenant } from '../../../mc-tenants/types/mc-tenants.type';
import { EnrollTenantParams, Tenant } from '../../types/tenants.type';

export const loadTenants = createAction('[Tenants] Load Tenants');
export const loadTenantsSuccess = createAction('[Tenants] Load Tenants Success', props<{ tenants: Tenant[] }>());
export const loadTenantsFailure = createAction('[Tenants] Load Tenants Failure', props<ErrorState>());

export const loadTenant = createAction('[Tenants] Load Tenant', props<{ id: string }>());
export const loadTenantSuccess = createAction('[Tenants] Load Tenant Success', props<{ tenant: Tenant }>());
export const loadTenantFailure = createAction('[Tenants] Load Tenant Failure', props<ErrorState>());

export const updateTenant = createAction(
  '[Tenants] Update Tenant',
  props<{ tenant: Partial<Tenant> & Pick<Tenant, 'id'> }>()
);
export const updateTenantSuccess = createAction(
  '[Tenants] Update Tenant Success',
  props<{ tenant: Partial<Tenant> & Pick<Tenant, 'id'> }>()
);
export const updateTenantFailure = createAction('[Tenants] Update Tenant Failure', props<ErrorState>());

export const enrollTenant = createAction('[Tenants] Enroll Tenant', props<{ params: EnrollTenantParams }>());
export const enrollTenantSuccess = createAction('[Tenants] Enroll Tenant Success', props<{ mcTenant: McTenant }>());
export const enrollTenantFailure = createAction('[Tenants] Enroll Tenant Failure', props<ErrorState>());

export const handoverToTenant = createAction('[Tenants] Handover To Tenant', props<{ id: string }>());
export const handoverToTenantSuccess = createAction(
  '[Tenants] Handover To Tenant Success',
  (snackbarMessages?: SnackbarMessages) => ({
    snackbarMessages: snackbarMessages ?? {
      default: 'Your request is processing. Please refresh the page after 5 seconds to view the updated status.'
    }
  })
);
export const handoverToTenantFailure = createAction('[Tenants] Handover To Tenant Failure', props<ErrorState>());
