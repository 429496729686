import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { PickupZonesService } from '../../services/pickup-zones.service';
import { loadRemoteFiles, loadRemoteFilesFailure, loadRemoteFilesSuccess } from '../actions/remote-files.actions';

@Injectable()
export class RemoteFilesEffects {
  loadRemoteFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRemoteFiles),
      exhaustMap(action =>
        this.pickupZonesService.getRemoteFiles(action.id).pipe(
          map(remoteFiles => loadRemoteFilesSuccess({ remoteFiles })),
          catchError(error => of(loadRemoteFilesFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pickupZonesService: PickupZonesService
  ) {}
}
