import { filter, map } from 'rxjs/operators';

import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { WindowRefService } from '@core/services/window-ref/window-ref.service';

import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';

@Component({
  selector: 'admin-copy-current-url',
  templateUrl: './copy-current-url.component.html'
})
export class CopyCurrentUrlComponent extends CopyToClipboardComponent implements OnInit {
  @Input() loading$: Observable<boolean>;

  destroyRef = inject(DestroyRef);

  currentUrl$: Observable<string>;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private windowRefService: WindowRefService
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.currentUrl$ = this.router.events.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(event => event instanceof NavigationEnd),
      map(() => this.windowRefService.nativeWindow.location.href)
    );
  }
}
