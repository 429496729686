<div class="dropdown-list-card">
  <form>
    <mat-form-field class="slim-outline-selection-field points-account-field" appearance="outline">
      <mat-select disableOptionCentering panelClass="dropdown-panel" required [formControl]="pointsAccountControl">
        <mat-option *ngFor="let account of pointsAccountsDropdownList" [value]="account.id">
          <span class="text-primary">{{
            'Ending with ' + account.groupReferenceId + ' ' + account.status | uppercase
          }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
