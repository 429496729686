import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { MerchandisesFilter } from '../../types/merchandises-filter.type';
import { Merchandise, MerchandisesResult, UpdateMerchandiseParams } from '../../types/merchandises.type';

export const loadMerchandises = createAction(
  '[Merchandises] Load Merchandises',
  props<{ filter: MerchandisesFilter }>()
);
export const loadMerchandisesSuccess = createAction(
  '[Merchandises] Load Merchandises Success',
  props<{ merchandises: MerchandisesResult }>()
);
export const loadMerchandisesFailure = createAction('[Merchandises] Load Merchandises Failure', props<ErrorState>());

export const loadMerchandise = createAction('[Merchandises] Load Merchandise', props<{ id: string }>());
export const loadMerchandiseSuccess = createAction(
  '[Merchandises] Load Merchandise Success',
  props<{ merchandise: Merchandise }>()
);
export const loadMerchandiseFailure = createAction('[Merchandises] Load Merchandise Failure', props<ErrorState>());

export const updateMerchandise = createAction(
  '[Merchandises] Update Merchandise',
  props<{ params: UpdateMerchandiseParams }>()
);
export const updateMerchandiseSuccess = createAction(
  '[Merchandises] Update Merchandise Success',
  props<{ merchandise: Merchandise }>()
);
export const updateMerchandiseFailure = createAction('[Merchandises] Update Merchandise Failure', props<ErrorState>());

export const fetchMerchandise = createAction('[Merchandises] Fetch Merchandise', props<{ id: string }>());
export const fetchMerchandiseSuccess = createAction(
  '[Merchandises] Fetch Merchandise Success',
  props<{ merchandise: Merchandise }>()
);
export const fetchMerchandiseFailure = createAction(
  '[Merchandises] Fetch Merchandise Failure',
  props<{ id: string }>()
);
