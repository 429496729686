import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { OfferSourcesFilter } from './offer-sources-filter.type';

export interface OfferSource {
  id: number;
  supplierName: string;
  supplierId: string;
  rawData: object;
  active: boolean;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface OfferSourceResult {
  data: OfferSource[];
  meta: {
    total: number;
    totalPages: number;
    allPages: number[];
    currentPages: number;
  };
}

export const offerSourceAdaptor = createEntityAdapter<OfferSource>();

export interface OfferSourceState extends EntityState<OfferSource>, CallState {
  filter: OfferSourcesFilter;
}

export const initialOfferSourceState: OfferSourceState = {
  ...offerSourceAdaptor.getInitialState<CallState>(initialCallState),
  filter: null
};
