import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { MergeConfigsService } from '../../services/merge-configs.service';
import {
  createMergeConfig,
  createMergeConfigFailure,
  createMergeConfigSuccess,
  deleteMergeConfig,
  deleteMergeConfigFailure,
  deleteMergeConfigSuccess,
  loadMergeConfig,
  loadMergeConfigFailure,
  loadMergeConfigs,
  loadMergeConfigsFailure,
  loadMergeConfigsSuccess,
  loadMergeConfigSuccess
} from '../actions/merge-configs.actions';

@Injectable()
export class MergeConfigsEffects {
  loadMergeConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMergeConfigs),
      exhaustMap(({ filter }) =>
        this.mergeConfigsService.getMergeConfigs(filter).pipe(
          map(result => loadMergeConfigsSuccess({ result })),
          catchError(error => of(loadMergeConfigsFailure({ error })))
        )
      )
    )
  );

  loadMergeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMergeConfig),
      exhaustMap(action =>
        this.mergeConfigsService.getMergeConfig(action.id).pipe(
          map(mergeConfig => loadMergeConfigSuccess({ mergeConfig })),
          catchError(error => of(loadMergeConfigFailure({ error })))
        )
      )
    )
  );

  createMergeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMergeConfig),
      exhaustMap(({ mergeConfigPayload }) =>
        this.mergeConfigsService.createMergeConfig(mergeConfigPayload).pipe(
          map(mergeConfig => createMergeConfigSuccess({ mergeConfig })),
          catchError(error => of(createMergeConfigFailure({ error })))
        )
      )
    )
  );

  createMergeConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMergeConfigSuccess),
      map(() => routerNavigate({ path: '/gift-cards/merge-configs' }))
    )
  );

  deleteMergeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMergeConfig),
      exhaustMap(({ id }) =>
        this.mergeConfigsService.deleteMergeConfig(id).pipe(
          map(() => deleteMergeConfigSuccess({ id })),
          catchError(error => of(deleteMergeConfigFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private mergeConfigsService: MergeConfigsService
  ) {}
}
