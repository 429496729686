import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

@Injectable({
  providedIn: 'root'
})
export class GhCacheService {
  constructor(private missionControlService: MissionControlService) {}

  getGhCaches(): Observable<string[]> {
    return this.missionControlService.get<{ cacheKeys: string[] }>('cache_keys').pipe(map(keys => keys.cacheKeys));
  }

  clearGhCache(key: string): Observable<void> {
    return this.missionControlService.delete<void>('cache_keys', new HttpParams({ fromObject: { key } }));
  }
}
