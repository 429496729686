import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { GhCacheService } from '../../services/gh-cache.service';
import {
  clearGhCache,
  clearGhCacheFailure,
  clearGhCacheSuccess,
  loadGhCaches,
  loadGhCachesFailure,
  loadGhCachesSuccess
} from '../actions/gh-cache.actions';

@Injectable()
export class GhCacheEffects {
  loadGhCaches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGhCaches),
      exhaustMap(() =>
        this.ghCacheService.getGhCaches().pipe(
          map(ghCaches => loadGhCachesSuccess({ ghCaches })),
          catchError(error => of(loadGhCachesFailure({ error })))
        )
      )
    )
  );

  clearGhCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearGhCache),
      exhaustMap(action =>
        this.ghCacheService.clearGhCache(action.key).pipe(
          map(() => clearGhCacheSuccess()),
          catchError(error => of(clearGhCacheFailure({ error })))
        )
      )
    )
  );

  clearGhCacheSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearGhCacheSuccess),
      map(() => loadGhCaches())
    )
  );

  constructor(
    private actions$: Actions,
    private ghCacheService: GhCacheService
  ) {}
}
