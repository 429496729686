import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { AlfredParamConverter, AlfredParamsConfig } from '../../../utils/alfred-param-converter';
import { ObjectUtils } from '../../../utils/object-utils';
import { UserOfferActivitiesFilter, UserOfferActivitiesResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class UserOfferActivitiesService {
  constructor(private missionControlService: MissionControlService) {}

  getUserOfferActivities(userId: string, filter: UserOfferActivitiesFilter): Observable<UserOfferActivitiesResult> {
    const paramsConfig: AlfredParamsConfig = {
      title: '$like',
      brand: '$like',
      activity: '$eq'
    };
    const alfredParam = AlfredParamConverter.convertToAlfredParam(filter, paramsConfig);
    const params = ObjectUtils.prepareQueryObject(ObjectUtils.sanitizeRequestObject(alfredParam));

    return this.missionControlService.get<UserOfferActivitiesResult>(
      `users/offers/user_activities`,
      new HttpParams({ fromObject: { userId, ...params } })
    );
  }
}
