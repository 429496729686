import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { recommendedCampaignInitialState, recommendedCampaignAdapter, RecommendedCampaignState } from '../../types';
import {
  loadRecommendedCampaigns,
  loadRecommendedCampaignsFailure,
  loadRecommendedCampaignsSuccess,
  loadRecommendedCampaignsForSegment,
  loadRecommendedCampaignsForSegmentFailure,
  loadRecommendedCampaignsForSegmentSuccess
} from '../actions/recommendations.actions';

export const recommendedCampaignTriggers: EntityTriggers = {
  batch: {
    loading: [loadRecommendedCampaigns.type, loadRecommendedCampaignsForSegment.type],
    resting: [loadRecommendedCampaignsSuccess.type, loadRecommendedCampaignsForSegmentSuccess.type],
    erroring: [loadRecommendedCampaignsFailure.type, loadRecommendedCampaignsForSegmentFailure.type]
  }
};

export function recommendedCampaignsReducer(
  state: RecommendedCampaignState = recommendedCampaignInitialState,
  action: Action
): RecommendedCampaignState {
  return callStateReducer(baseReducer, recommendedCampaignTriggers)(state, action);
}

const baseReducer = createReducer(
  recommendedCampaignInitialState,
  on(loadRecommendedCampaignsSuccess, (state, { recommendations }) =>
    recommendedCampaignAdapter.setAll(recommendations, state)
  ),
  on(loadRecommendedCampaignsForSegmentSuccess, (state, { recommendations }) =>
    recommendedCampaignAdapter.setAll(recommendations, state)
  )
);
