import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { SCOPES_OR } from '@core/types';

import { impersonate } from '../../store/actions/impersonations.actions';
import { usersQuery } from '../../store/selectors/users.selectors';
import { getCustomerId, getCustomerIdentity, getUserName, isAdmin, User, UserState } from '../../types';

@Component({
  selector: 'admin-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  providerUidText = '';
  userId: string;
  displayedUserName: string;
  isAdminUser: boolean;
  disableImpersonation = true;
  loading$: Observable<boolean>;
  tooltipSuffix: string;
  readonly SCOPES = SCOPES_OR;

  constructor(
    @Inject('customerBankIdentityProvider') private customerBankIdentityProvider: string,
    private route: ActivatedRoute,
    private usersStore: Store<UserState>,
    private impersonationStore: Store<any>
  ) {}

  ngOnInit(): void {
    this.route.url
      .pipe(
        map(url => url[0].path),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(userId => {
        this.userId = userId;
        this.usersStore
          .pipe(
            select(usersQuery.getUserById(this.userId)),
            takeUntilDestroyed(this.destroyRef),
            filter(user => !!user)
          )
          .subscribe((user: User) => {
            this.displayedUserName = getUserName(user) || '';
            this.isAdminUser = isAdmin(user);
            this.disableImpersonation = !user.activated;

            const customerIdentity = getCustomerIdentity(user, this.customerBankIdentityProvider);
            if (user.uid) {
              this.providerUidText = `Customer ID: ${user.uid}`;
              this.tooltipSuffix = 'customerId';
            } else {
              if (!customerIdentity) {
                this.providerUidText = 'Customer ID not found';
              } else if (customerIdentity.providerId === this.customerBankIdentityProvider) {
                this.providerUidText = `Customer ID: ${getCustomerId(user, customerIdentity.providerId)}`;
                this.tooltipSuffix = 'customerId';
              } else if (customerIdentity.providerId && customerIdentity.uid) {
                this.providerUidText = `${customerIdentity.providerId} ID: ${customerIdentity.uid}`;
                this.tooltipSuffix = customerIdentity.providerId;
              }
            }
          });

        this.loading$ = this.usersStore.select(usersQuery.isSingleLoading);
      });
  }

  impersonateUser(readOnly: boolean = false): void {
    this.impersonationStore.dispatch(impersonate({ userId: this.userId, readOnly }));
  }
}
