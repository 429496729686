<ng-container *ngIf="entity && !isEntityDetailsView">
  <mat-card
    appearance="outlined"
    class="mat-elevation-z1 add-note mat-card notes-component"
    *adminIfScopesInclude="createNoteScopes; relationOperator: 'or'"
  >
    <mat-card-content>
      <admin-note-create-edit [timezone]="timezone" [showType]="columns.includes('type')"></admin-note-create-edit>
    </mat-card-content>
  </mat-card>
</ng-container>

<mat-card appearance="outlined" class="mat-elevation-z1 mat-card notes-component">
  <mat-card-content>
    <mat-card-title>Notes</mat-card-title>

    <form
      [formGroup]="filterForm"
      *ngIf="filterForm && !isEntityDetailsView"
      class="filter-form slim-form-field"
      (keydown.enter)="$event.preventDefault()"
    >
      <div fxLayout="row wrap" fxLayoutGap="15px">
        <mat-form-field formGroupName="updatedAt" class="date-form">
          <mat-label>Last modified</mat-label>
          <div class="date-form__wrapper">
            <mat-datepicker-toggle matSuffix [for]="updatedAtPicker"></mat-datepicker-toggle>
            <mat-date-range-input [rangePicker]="updatedAtPicker">
              <input
                matInput
                matStartDate
                formControlName="dateFrom"
                placeholder="Start date"
                (focus)="updatedAtPicker.open()"
              />
              <input
                matInput
                matEndDate
                formControlName="dateTo"
                placeholder="End date"
                (focus)="updatedAtPicker.open()"
              />
            </mat-date-range-input>
            <mat-date-range-picker #updatedAtPicker></mat-date-range-picker>
            <button
              matSuffix
              mat-icon-button
              color="primary"
              (click)="filterForm.get('updatedAt').reset()"
              [disabled]="!filterForm.get('updatedAt.dateFrom').value && !filterForm.get('updatedAt.dateTo').value"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-form-field>

        <mat-form-field class="dropdown-field" *ngIf="!entity || columns.includes('type')">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" multiple disableOptionCentering panelClass="dropdown-panel">
            <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
          </mat-select>
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.type"
            [loading]="loading"
            [initialValue]="''"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field class="w-350">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Note content</mat-label>
          <input matInput aria-label="Note content" type="search" placeholder="Note content" formControlName="note" />
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.note"
            [loading]="loading"
            [initialValue]="''"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field formGroupName="expiresAt" class="date-form">
          <mat-label>Expiry At</mat-label>
          <div class="date-form__wrapper">
            <mat-datepicker-toggle matSuffix [for]="expiresAtPicker"></mat-datepicker-toggle>
            <mat-date-range-input [rangePicker]="expiresAtPicker">
              <input
                matInput
                matStartDate
                formControlName="dateFrom"
                placeholder="Start date"
                (focus)="expiresAtPicker.open()"
              />
              <input
                matInput
                matEndDate
                formControlName="dateTo"
                placeholder="End date"
                (focus)="expiresAtPicker.open()"
              />
            </mat-date-range-input>
            <mat-date-range-picker #expiresAtPicker></mat-date-range-picker>
            <button
              matSuffix
              mat-icon-button
              color="primary"
              (click)="filterForm.get('expiresAt').reset()"
              [disabled]="!filterForm.get('expiresAt.dateFrom').value && !filterForm.get('expiresAt.dateTo').value"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-form-field>

        <mat-form-field class="w-350">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Agent ID</mat-label>
          <input matInput aria-label="Agent ID" type="search" placeholder="Agent ID" formControlName="adminUserId" />
          <mat-error *ngIf="filterForm.controls.adminUserId.errors?.invalidUuid">Invalid UUID</mat-error>
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.adminUserId"
            [loading]="loading"
            [initialValue]="''"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field *ngIf="isTopLevelView">
          <mat-label>Entity</mat-label>
          <mat-select formControlName="entityFilter" disableOptionCentering panelClass="dropdown-panel">
            <mat-option *ngFor="let entityType of entityTypes" [value]="entityType">
              {{ entityType | titlecase }}
            </mat-option>
          </mat-select>
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.entityFilter"
            [loading]="loading"
            [initialValue]="''"
          ></admin-input-reset>
        </mat-form-field>

        <mat-form-field *ngIf="isTopLevelView" class="w-350">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Entity ID</mat-label>
          <input matInput aria-label="Entity ID" type="search" placeholder="Entity ID" formControlName="entityId" />
          <mat-error *ngIf="filterForm.controls.entityId.errors?.invalidUuid">Invalid UUID</mat-error>
          <admin-input-reset
            matSuffix
            [inputControl]="filterForm.controls.entityId"
            [loading]="loading"
            [initialValue]="''"
          ></admin-input-reset>
        </mat-form-field>

        <admin-input-tags
          [tags]="filterForm.controls.tags"
          dialogTitle="Filter by tag"
          submitButtonText="Add tag to filter"
        ></admin-input-tags>
      </div>
    </form>

    <div *ngIf="loading" class="progress-loader">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div [class.overlay]="loading" class="notes-table">
      <table mat-table matSort [dataSource]="notes$ | async" class="data-table" [class.overlay]="loading">
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt">
            <h4 class="text-primary">Last modified</h4>
          </th>
          <td mat-cell *matCellDef="let note" attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
            {{ note.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            <h4 class="text-primary">Type</h4>
          </th>
          <td mat-cell *matCellDef="let note">{{ note.type }}</td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef>
            <h4 class="text-primary">Notes</h4>
          </th>
          <td mat-cell *matCellDef="let note">
            <div #noteContainer class="note-container" [innerHTML]="note.note | urlToLink"></div>
            <span *ngIf="noteContainer.scrollHeight > noteContainer.clientHeight" class="btn-wrapper">
              <span class="see-more-btn text-primary" (click)="openModal(note.note)">more</span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="expiresAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="expiresAt">
            <h4 class="text-primary">Expiry at</h4>
          </th>
          <td mat-cell *matCellDef="let note">{{ note.expiresAt | date: 'yyyy-MM-dd' : timezone }}</td>
        </ng-container>

        <ng-container matColumnDef="adminUserId">
          <th mat-header-cell *matHeaderCellDef>
            <h4 class="text-primary">Agent</h4>
          </th>
          <td mat-cell *matCellDef="let note">
            <ng-container *ngIf="note.agent; else noAgent">
              <a [routerLink]="getUserDetailsRoute(note.agent)">
                {{ getUserIdentifier(note.agent) }}
              </a>
            </ng-container>
            <ng-template #noAgent>
              {{ note.adminUserId }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef>
            <h4 class="text-primary">Tags</h4>
          </th>
          <td mat-cell *matCellDef="let note">
            <mat-chip-listbox aria-label="Tags">
              <mat-chip-option *ngFor="let tag of note.tags" [routerLink]="tag.routeLink" [class]="tag.type">
                {{ tag.displayPrefix }}: {{ tag.displayValue }}
              </mat-chip-option>
            </mat-chip-listbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <h4 class="text-primary">Action</h4>
          </th>
          <td mat-cell *matCellDef="let note">
            <div fxLayout="row">
              <admin-tooltip
                [key]="'notes.index.table.action.button.edit?expired=' + (note.expiresAt < currentUTCTime)"
                [displayIcon]="false"
              >
                <button
                  mat-icon-button
                  class="actions-button"
                  *adminIfScopesInclude="updateNoteScopes; relationOperator: 'or'"
                  [disabled]="note.expiresAt < currentUTCTime"
                  (click)="editNote(note)"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </admin-tooltip>
              <admin-tooltip [key]="'notes.index.table.action.button.delete'" [displayIcon]="false">
                <button
                  mat-icon-button
                  class="actions-button"
                  *adminIfScopesInclude="deleteNoteScopes; relationOperator: 'or'"
                  (click)="deleteNote(note.id, note.entity)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </admin-tooltip>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="entity">
          <th mat-header-cell *matHeaderCellDef>
            <h4 class="text-primary">Entity</h4>
          </th>
          <td mat-cell *matCellDef="let note">{{ note.entity | titlecase }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns" class="bg-secondary"></tr>
        <ng-container *ngIf="!loading">
          <tr mat-row *matRowDef="let row; columns: columns" class="data-table__row"></tr>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No notes available</td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 20, 50, 100]"
        [pageIndex]="(metadata$ | async)?.currentPage - 1"
        [pageSize]="this.filter.limit"
        [length]="(metadata$ | async)?.count"
        (page)="onPage($event)"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
