<div class="dialog-content details-view">
  <div mat-dialog-title>Create identity</div>

  <form [formGroup]="createForm" (keydown.enter)="$event.preventDefault()">
    <mat-form-field>
      <mat-label>Provider ID</mat-label>
      <mat-select formControlName="providerId" disableOptionCentering>
        <mat-option *ngFor="let providerId of providerIdOptions" [value]="providerId">
          {{ providerId | titlecase }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>UID</mat-label>
      <input matInput formControlName="uid" />
      <mat-error *ngIf="uidControl.hasError('required')">Required</mat-error>
      <mat-error *ngIf="uidControl.hasError('email')">{{ invalidEmailMessage }}</mat-error>
    </mat-form-field>

    <ng-container *ngIf="displayPasswordFields">
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" autocomplete="off" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Confirm Password</mat-label>
        <input matInput formControlName="passwordConfirmation" type="password" autocomplete="off" />
        <mat-error
          *ngIf="passwordConfirmationControl.hasError('required') && !passwordConfirmationControl.hasError('mismatch')"
          >Required</mat-error
        >
        <mat-error *ngIf="passwordConfirmationControl.hasError('mismatch')"
          >Should match the contents of Password</mat-error
        >
      </mat-form-field>
    </ng-container>
  </form>

  <div mat-dialog-actions>
    <button class="secondary-button" (click)="dialogRef.close(false)">
      <span>Cancel</span>
    </button>

    <button
      [disabled]="createForm.invalid"
      [ngClass]="createForm.invalid ? 'disabled-button' : 'primary-button'"
      (click)="submitForm()"
    >
      <span>Create</span>
    </button>
  </div>
</div>
