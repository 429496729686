import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, UploadImageState } from '../../types';
import { setImage, uploadImage, uploadImageFailure, uploadImageSuccess } from '../actions/upload-images.actions';

export const uploadImageTriggers: EntityTriggers = {
  single: {
    loading: [uploadImage.type],
    resting: [uploadImageSuccess.type],
    erroring: [uploadImageFailure.type]
  }
};

export function uploadImagesReducer(state: UploadImageState = initialState, action: Action): UploadImageState {
  return callStateReducer(baseReducer, uploadImageTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(uploadImageSuccess, setImage, (state, { imageUrl, componentId }) => ({
    ...state,
    images: { ...state.images, [componentId]: imageUrl }
  }))
);
