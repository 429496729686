<div [ngClass]="static ? 'loading-container-static' : 'loading-container'">
  <div class="grid h-[100px] w-full place-items-center">
    <div class="flare-container">
      <div class="flare"></div>
    </div>
  </div>
  @if (isShownText) {
    <b class="text-cloud m-0 mx-0 my-6 text-2xl font-bold leading-8">{{ title }}</b>
    @if (body) {
      <p class="text-cloud">{{ body }}</p>
    }
  }
</div>
