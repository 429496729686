import { Nullable } from './nullable.type';

export type TagType =
  | 'ability_id'
  | 'access_policy_id'
  | 'agent_id'
  | 'app_connection_id'
  | 'app_id'
  | 'approval_request_id'
  | 'approver_id'
  | 'authentication_provider_reference'
  | 'campaign_id'
  | 'domain_id'
  | 'fraud_rule_id'
  | 'gift_card_id'
  | 'gift_card_order_id'
  | 'provider_id'
  | 'reference'
  | 'login_attempt_id'
  | 'loyalty_program_id'
  | 'merchandise_id'
  | 'note_id'
  | 'offer_id'
  | 'order_item_id'
  | 'password_id'
  | 'points_activity_id'
  | 'redemption_rate_id'
  | 'role_id'
  | 'scope_id'
  | 'te_booking_id'
  | 'te_booking_transaction_uid'
  | 'te_user_id'
  | 'te_session_id'
  | 'te_ip'
  | 'tenant_id'
  | 'token_id'
  | 'user_id'
  | 'requester_id';

export const tagTypes: string[] = [
  'ability_id',
  'access_policy_id',
  'agent_id',
  'app_connection_id',
  'app_id',
  'approval_request_id',
  'approver_id',
  'authentication_provider_reference',
  'campaign_id',
  'domain_id',
  'fraud_rule_id',
  'gift_card_id',
  'gift_card_order_id',
  'password_id',
  'provider_id',
  'reference',
  'login_attempt_id',
  'loyalty_program_id',
  'merchandise_id',
  'note_id',
  'offer_id',
  'order_item_id',
  'points_activity_id',
  'redemption_rate_id',
  'role_id',
  'scope_id',
  'te_user_id',
  'te_session_id',
  'te_ip',
  'tenant_id',
  'token_id',
  'user_id',
  'requester_id',
  'tenant_id'
];

export const FETCHABLE_TAG_TYPES: TagType[] = [
  'app_connection_id',
  'app_id',
  'agent_id',
  'approver_id',
  'authentication_provider_reference',
  'campaign_id',
  'domain_id',
  'fraud_rule_id',
  'loyalty_program_id',
  'merchandise_id',
  'offer_id',
  'redemption_rate_id',
  'requester_id',
  'user_id'
];

export const SELECTABLE_TAG_TYPES: TagType[] = [...FETCHABLE_TAG_TYPES, 'points_activity_id'];

export class Tag {
  id: string;
  type: TagType;
  displayPrefix?: string;
  displayValue?: string;
  routeLink?: Nullable<string>;
  externalRouteLink?: Nullable<string>;
  routeParams?: Nullable<object>;
  isDefaultTag?: boolean;
}

export type TagIdsRecord = Record<TagType, string[]>;
