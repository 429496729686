import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { ApprovalRequestStatistics } from '../../types/approval-request-statistics.type';
import { ApprovalRequestsFilter } from '../../types/approval-requests-filter.type';
import { ApprovalRequestsResult } from '../../types/approval-requests-result.type';
import { ApprovalRequest } from '../../types/approval-requests.type';
import { ApprovalRequestResponse } from '../../types/approval-responses.type';

export const loadApprovalRequest = createAction('[Approval Requests] Load Approval Request', props<{ id: string }>());
export const loadApprovalRequestSuccess = createAction(
  '[Approval Requests] Load Approval Request Success',
  props<{ approvalRequest: ApprovalRequest }>()
);
export const loadApprovalRequestFailure = createAction(
  '[Approval Requests] Load Approval Request Failure',
  props<ErrorState>()
);

export const loadApprovalRequests = createAction(
  '[Approval Requests] Load Approval Requests',
  props<{ filter: ApprovalRequestsFilter }>()
);
export const loadApprovalRequestsSuccess = createAction(
  '[Approval Requests] Load Approval Requests Success',
  props<{ approvalRequestsResult: ApprovalRequestsResult }>()
);
export const loadApprovalRequestsFailure = createAction(
  '[Approval Requests] Load Approval Requests Failure',
  props<ErrorState>()
);

export const loadApprovalRequestStatistics = createAction('[Approval Requests] Load Approval Request Statistics');
export const loadApprovalRequestStatisticsSuccess = createAction(
  '[Approval Requests] Load Approval Request Statistics Success',
  props<{ approvalRequestStatistics: ApprovalRequestStatistics }>()
);
export const loadApprovalRequestStatisticsFailure = createAction(
  '[Approval Requests] Load Approval Request Statistics Failure',
  props<ErrorState>()
);

export const respondToApprovalRequest = createAction(
  '[Approval Requests] Respond To Approval Request',
  props<{ respondParams: ApprovalRequestResponse; dialogRefId: string }>()
);
export const respondToApprovalRequestSuccess = createAction(
  '[Approval Requests] Respond To Approval Request Success',
  props<{ approvalRequest: ApprovalRequest; dialogRefId: string }>()
);
export const respondToApprovalRequestFailure = createAction(
  '[Approval Requests] Respond To Approval Request Failure',
  props<ErrorState>()
);
