import { SCOPES_OR, SCOPES_OR_GENERATED } from '@core/types';
import { NavItemV2 } from '@core/types/nav-item-v2.type';

const programSettingsGroup: NavItemV2 = {
  id: 'programSettings',
  type: 'submenu',
  label: 'Program settings',
  iconName: 'rewards-program-settings',
  children: [
    {
      id: 'rewardsProgram',
      type: 'link',
      label: 'General set up',
      route: '/rewards-program',
      scopes: ['view_rewards_program'],
      checkFeatureAvailability: true
    },
    {
      id: 'eventTypes',
      type: 'link',
      label: 'Events',
      route: '/event-types',
      scopes: ['view_event_types']
    },
    {
      id: 'earnRule',
      type: 'link',
      label: 'Earn rules',
      route: '/earn-rules',
      scopes: ['view_earn_rules'],
      checkFeatureAvailability: true
    },
    {
      id: 'customDomain',
      type: 'link',
      label: 'Custom domains',
      route: '/custom-domain',
      scopes: ['view_custom_domains'],
      checkFeatureAvailability: true
    }
  ]
};

const legacyRewardsOfferingGroup: NavItemV2 = {
  id: 'legacyRewardsOffering',
  type: 'submenu',
  label: 'Legacy rewards',
  iconName: 'added-to-playlist',
  children: [
    {
      id: 'giftCards',
      type: 'link',
      label: 'Gift cards',
      route: '/gift-cards/cards',
      rootRoutes: ['/gift-cards'],
      scopes: SCOPES_OR_GENERATED.viewGiftCardsMenu,
      relationOperator: 'or',
      checkFeatureAvailability: true
    },
    {
      id: 'merchandises',
      type: 'link',
      label: 'Merchandise',
      route: '/merchandises',
      scopes: SCOPES_OR.viewMerchandises,
      relationOperator: 'or',
      checkFeatureAvailability: true
    },
    {
      id: 'pointsTransfers',
      type: 'link',
      label: 'Points transfers',
      route: '/loyalty-programs',
      scopes: SCOPES_OR.viewLoyaltyPrograms,
      relationOperator: 'or',
      checkFeatureAvailability: true
    },
    {
      id: 'offers',
      type: 'link',
      label: 'Offers',
      route: '/offers',
      scopes: SCOPES_OR.viewOffers,
      relationOperator: 'or',
      checkFeatureAvailability: true
    }
  ]
};

const websiteStylingGroup: NavItemV2 = {
  id: 'websiteStyling',
  type: 'submenu',
  label: 'Website styling',
  iconName: 'color-palette',
  children: [
    {
      id: 'branding',
      type: 'link',
      label: 'Branding',
      route: '/partner-configs/branding',
      scopes: ['manage_partner_configs'],
      checkFeatureAvailability: true
    },
    {
      id: 'siteEditor',
      type: 'link',
      label: 'Site editor',
      route: '/partner-configs/site-editor',
      scopes: ['manage_partner_configs'],
      checkFeatureAvailability: true
    }
  ]
};

const integrationsGroup: NavItemV2 = {
  id: 'integrations',
  type: 'submenu',
  label: 'Integrations',
  iconName: 'integration',
  children: [
    {
      id: 'apiSettings',
      type: 'link',
      label: 'API settings',
      route: '/api-settings',
      scopes: ['view_partner_app'],
      checkFeatureAvailability: true
    },
    {
      id: 'webhooks',
      type: 'link',
      label: 'Webhooks',
      route: '/webhooks',
      scopes: ['view_webhook_settings'],
      checkFeatureAvailability: true
    }
  ]
};

export const setupNavItems: NavItemV2[] = [
  programSettingsGroup,
  {
    id: 'rcRewardsOffering',
    type: 'link',
    label: 'Rewards',
    iconName: 'added-to-playlist',
    route: '/rewards-offering',
    scopes: SCOPES_OR.viewRewardsOffering,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  legacyRewardsOfferingGroup,
  websiteStylingGroup,
  integrationsGroup
];
