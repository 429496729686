import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';

import { Campaign, campaignAdapter, CampaignState } from '../../types';

const campaignsDetector = createDetector<Campaign>();

const getCampaignState = createFeatureSelector<CampaignState>('campaign');

const { selectAll: getCampaignsList, selectEntities: getCampaignsDictionary } =
  campaignAdapter.getSelectors(getCampaignState);

const { isBatchInitial, isBatchLoading, isBatchResting, isSingleLoading, getBatchError, getSingleError } =
  getCallStateSelectors<CampaignState>(getCampaignState);

const getFetchList = getFetchStateSelector<CampaignState>(getCampaignState);

const isGlobalCampaignsLoaded = createSelector(getCampaignState, state => state.isGlobalCampaignsLoaded);

const getFilter = createSelector(getCampaignState, (state: CampaignState) => state.filter);

const getCampaignById = (id: string): Selector<CampaignState, Campaign> =>
  createSelector(getCampaignsDictionary, entities => entities[id]);

const isCampaignLoaded = (id: string): Selector<CampaignState, boolean> =>
  createSelector(getCampaignsDictionary, campaigns => campaignsDetector('oneLoaded')(campaigns, id));

export const campaignsQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  isSingleLoading,
  getBatchError,
  getSingleError,
  getFetchList,
  getFilter,
  getCampaignsList,
  getCampaignsDictionary,
  getCampaignById,
  isGlobalCampaignsLoaded,
  isCampaignLoaded
};
