<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
  Action: <strong>{{ data.actionLabel }}</strong> <br />
  Statement descriptor: <strong>{{ data.statementDescriptorLabel }}</strong> <br />
  Are you sure you want to apply the adjustment? This action cannot be undone.
</div>
<div mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="dialogRef.close(false)" class="uppercase-button">Cancel</button>
  <button mat-raised-button color="primary" (click)="dialogRef.close(true)" class="uppercase-button">Confirm</button>
</div>
