import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Benefit {
  id: string;
  status: string;
  membershipNumber: string;
  createdAt: string;
  updatedAt: string;
  benefit: {
    id: string;
    type: string;
    loyaltyProgramName: string;
    name: string;
    description: string;
    logoUrl: string;
    transferConnectIdentifier: string;
    checkoutParamsSchema: object;
  };
  loyaltyProgram: {
    id: string;
    type: string;
    name: string;
    shortName: string;
    processingDelay: number;
    category: string;
    description: string;
    detailsUrl: string;
    enrollmentUrl: string;
    logoUrl: string;
    conditionsUrl: string;
    nameRequired: boolean;
    transferConnectIdentifier: string;
    active: boolean;
    manualVerificationRequired: boolean;
    autosweepable: boolean;
    loyaltyCurrency: {
      data: string;
    };
  };
}

export interface BenefitResult {
  data: Benefit[];
  meta: {
    count: number;
  };
}

export const benefitAdapter = createEntityAdapter<Benefit>();

export interface BenefitState extends EntityState<Benefit>, CallState {
  count: number;
}

export const initialState: BenefitState = {
  ...benefitAdapter.getInitialState<CallState>(initialCallState),
  count: 0
};
