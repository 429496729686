import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { RecommendationType, RecommendedSegment, RecommendedCampaign } from '../../types';

export const loadRecommendedSegments = createAction('[Recommendations] Load Recommended Segments');
export const loadRecommendedSegmentsSuccess = createAction(
  '[Recommendations] Load Recommended Segments Success',
  props<{ recommendations: RecommendedSegment[] }>()
);
export const loadRecommendedSegmentsFailure = createAction(
  '[Recommendations] Load Recommended Segments Failure',
  props<ErrorState>()
);

export const loadRecommendedCampaigns = createAction('[Recommendations] Load Recommended Campaigns');
export const loadRecommendedCampaignsSuccess = createAction(
  '[Recommendations] Load Recommended Campaigns Success',
  props<{ recommendations: RecommendedCampaign[] }>()
);
export const loadRecommendedCampaignsFailure = createAction(
  '[Recommendations] Load Recommended Campaigns Failure',
  props<ErrorState>()
);

export const loadRecommendedCampaignsForSegment = createAction(
  '[Recommendations] Load Recommended Campaigns for Segment',
  props<{ id: string }>()
);
export const loadRecommendedCampaignsForSegmentSuccess = createAction(
  '[Recommendations] Load Recommended Campaigns for Segment Success',
  props<{ recommendations: RecommendedCampaign[] }>()
);
export const loadRecommendedCampaignsForSegmentFailure = createAction(
  '[Recommendations] Load Recommended Campaigns Failure',
  props<ErrorState>()
);

export const hideRecommendation = createAction(
  '[Recommendations] Hide Recommendation',
  props<{
    id: string;
    recommendationType: RecommendationType;
  }>()
);
export const hideRecommendationSuccess = createAction(
  '[Recommendations] Hide Recommendation Success',
  (
    recommendationType: RecommendationType,
    snackbarMessages: SnackbarMessages = { default: 'Recommendation hidden successfully' }
  ) => ({ recommendationType, snackbarMessages })
);
export const hideRecommendationFailure = createAction(
  '[Recommendations] Hide Recommendation Failure',
  (
    error: HttpError,
    recommendationType: RecommendationType,
    snackbarMessages: SnackbarMessages = {
      default:
        `We were not able to hide the recommended ${recommendationType} due to technical issues on our end` +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, recommendationType, snackbarMessages })
);

export const unhideRecommendation = createAction(
  '[Recommendations] Unhide Recommendation',
  props<{
    id: string;
    recommendationType: RecommendationType;
  }>()
);
export const unhideRecommendationSuccess = createAction(
  '[Recommendations] Unhide Recommendation Success',
  (
    recommendationType: RecommendationType,
    snackbarMessages: SnackbarMessages = {
      default: 'Action has been undone'
    }
  ) => ({ recommendationType, snackbarMessages })
);

export const unhideRecommendationFailure = createAction(
  '[Recommendations] Unhide Recommendation Failure',
  (
    error: HttpError,
    recommendationType: RecommendationType,
    snackbarMessages: SnackbarMessages = {
      default:
        `We were not able to hide the recommended ${recommendationType} due to technical issues on our end` +
        'Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, recommendationType, snackbarMessages })
);
