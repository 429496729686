import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { webhookEventAdapter, webhookEventInitialState, WebhookEventState } from '../../types';
import {
  loadWebhookEvent,
  loadWebhookEventFailure,
  loadWebhookEvents,
  loadWebhookEventsFailure,
  loadWebhookEventsSuccess,
  loadWebhookEventSuccess,
  setWebhookEventsFilter
} from '../actions/webhook-events.actions';

export const webhookEventTriggers: EntityTriggers = {
  single: {
    loading: [loadWebhookEvent.type],
    resting: [loadWebhookEventSuccess.type],
    erroring: [loadWebhookEventFailure.type]
  },
  batch: {
    loading: [loadWebhookEvents.type],
    resting: [loadWebhookEventsSuccess.type],
    erroring: [loadWebhookEventsFailure.type]
  }
};

export function webhookEventsReducer(
  state: WebhookEventState = webhookEventInitialState,
  action: Action
): WebhookEventState {
  return callStateReducer(baseReducer, webhookEventTriggers)(state, action);
}

const baseReducer = createReducer(
  webhookEventInitialState,
  on(loadWebhookEventsSuccess, (state, { webhookEvents, count }) => ({
    ...webhookEventAdapter.setAll(webhookEvents, state),
    count
  })),
  on(loadWebhookEventSuccess, (state, { webhookEvent }) => webhookEventAdapter.upsertOne(webhookEvent, state)),
  on(setWebhookEventsFilter, (state, { filter }) => ({ ...state, filter }))
);
