import { Action, ActionReducer } from '@ngrx/store';

import { EntityLocaleTriggers, LocaleState, LocaleTriggers } from '@core/types';

export function localeStateReducer<S extends LocaleState>(
  entityTriggers: EntityLocaleTriggers
): ActionReducer<LocaleState, LocaleAction> {
  return (state: S, action: LocaleAction) => {
    const singleLocaleState = extractLocaleState(entityTriggers.single, action);
    const batchLocaleState = extractLocaleState(entityTriggers.batch, action);

    return {
      localeState: {
        singleLocale: singleLocaleState || state.localeState.singleLocale,
        batchLocale: batchLocaleState || state.localeState.batchLocale
      }
    };
  };
}

function extractLocaleState(triggers: LocaleTriggers, action: LocaleAction): string {
  if (!triggers) {
    return null;
  }

  if (triggers.resting.includes(action.type)) {
    return action.locale;
  }
}

interface LocaleAction extends Action {
  locale?: string;
}
