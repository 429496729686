import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, notificationAdapter, NotificationState } from '../../types/notifications.type';
import {
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess
} from '../actions/notifications.actions';

export const notificationTriggers: EntityTriggers = {
  batch: {
    loading: [loadNotifications.type],
    resting: [loadNotificationsSuccess.type],
    erroring: [loadNotificationsFailure.type]
  }
};

export function notificationsReducer(state: NotificationState = initialState, action: Action): NotificationState {
  return {
    ...callStateReducer(baseReducer, notificationTriggers)(state, action)
  };
}

const baseReducer = createReducer(
  initialState,
  on(loadNotificationsSuccess, (state, { notifications }) => notificationAdapter.setAll(notifications, state))
);
