<button
  *ngIf="inputControl.value?.toString().length"
  aria-label="Clear"
  class="close-button"
  mat-icon-button
  matSuffix
  [disabled]="loading"
  (click)="inputControl.reset(initialValue); $event.stopPropagation()"
>
  <mat-icon>close</mat-icon>
</button>
