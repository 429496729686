import { map, takeWhile } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { CoreState } from '@core/store';
import { handleLoginRedirect } from '@core/store/auth/actions/auth.actions';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { loadUserAbilities } from '@core/store/user-abilities/actions/user-abilities.actions';
import { userAbilitiesQuery } from '@core/store/user-abilities/selectors/user-abilities.selectors';
import { HostTenantMappingUtils, IconUtils } from '@utils';

import { loadPartnerConfig } from './modules/partner-configs/store/actions/partner-configs.actions';

type ComponentState = 'content' | 'loading' | 'faq';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  renderState$: Observable<ComponentState>;
  iconsLoadingFinished$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store<CoreState>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.store.dispatch(handleLoginRedirect());

    // Fetch partner config for RC
    // to display in tenant detail in sidenav
    if (HostTenantMappingUtils.isRC()) {
      this.store.dispatch(loadPartnerConfig());
    }

    // unsubscribe once we check the first value, no more loading spinner for refresh token
    const hermesLoading$ = this.store.select(authQuery.getHermesLoading).pipe(takeWhile(loading => loading, true));

    const userReady$ = this.store.select(authQuery.getUser).pipe(takeWhile(user => !user, true));

    const tokenReady$ = this.store.select(authQuery.getTokenResult).pipe(takeWhile(tokenResult => !tokenResult, true));

    userReady$.subscribe(user => {
      if (user) {
        this.store.dispatch(loadUserAbilities());
      }
    });

    const userAbilitiesLoaded$ = this.store
      .select(userAbilitiesQuery.isLoaded)
      .pipe(takeWhile(loaded => !loaded, true));

    const loginFail$ = this.store.select(authQuery.getLoginFail);

    // the flow of state change is:
    // once direct back to AP from GH login
    // 1. hermesLoading true, token null, user null, accessPoliciesLoaded false
    // 2. hermesLoading false, token smth, user null, accessPoliciesLoaded false
    // 3. hermesLoading false, token smth, user smth, accessPoliciesLoaded false
    // 4. hermesLoading false, token smth, user smth, userAbilitiesLoaded true
    // we want the loading spinner to show in 1,2,3
    // but not in 4, and not shown when user is not logged in
    this.renderState$ = combineLatest([
      hermesLoading$,
      userReady$,
      tokenReady$,
      userAbilitiesLoaded$,
      loginFail$,
      this.iconsLoadingFinished$
    ]).pipe(
      map(([hermesLoading, userReady, tokenReady, userAbilitiesLoaded, loginFail, iconsLoadingFinished]) => {
        // login fail to be checked first to avoid infinite loading
        if (loginFail) {
          return 'faq';
        } else if (!iconsLoadingFinished) {
          return 'loading';
        } else if (tokenReady && !userReady) {
          return 'loading';
        } else if (hermesLoading && !tokenReady) {
          return 'loading';
        } else if (userReady && !userAbilitiesLoaded) {
          return 'loading';
        } else {
          return 'content';
        }
      })
    );

    this.registerIcons();
  }

  registerIcons(): void {
    IconUtils.getIconFilenames()
      .then(iconsObject => {
        Object.entries(iconsObject).forEach(([iconName, iconPath]) => {
          this.matIconRegistry.addSvgIcon(
            iconName,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${iconPath}`)
          );
        });
        this.iconsLoadingFinished$.next(true);
      })
      .catch(() => {
        // We want to ensure that AP is still accessible, even if loading icons ultimately failed
        this.iconsLoadingFinished$.next(true);
      });
  }
}
