import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { MergeConfigsFilter } from './merge-configs-filter.type';

export interface MergeConfigsResult {
  data: MergeConfig[];
  metadata: {
    allPages: number[];
    currentPage: number;
    total: number;
    totalPages: number;
  };
}

export interface MergeConfig {
  id: number;
  name: string;
  mergeCard: MergeCard;
  childCard: ChildCard[];
}

interface MergeCard {
  id: string;
  name: string;
  active: boolean;
  approved: boolean;
  displayTypes: string[];
  logo: string;
  images: string;
  productImage: string;
}

interface ChildCard {
  id: string;
  name: string;
  supplier: string;
  supplierSku: string;
  deliveryMethod: string;
  validityInDays: number;
  maxDiscountPercentage: number;
  rebate: number;
  brand: string;
  denominations: Denomination[];
  rangedDenominations: RangedDenomination[];
  active: boolean;
  approved: boolean;
  displayTypes: string[];
  type: string;
}

interface Denomination {
  id: string;
  displayValue: number;
  cost: number;
  price: number;
  displayType: string;
  deliveryMethod: string;
  validityInDays: number;
}

interface RangedDenomination {
  id: string;
  min: number;
  max: number;
  displayType: string;
  deliveryMethod: string;
  validityInDays: number;
}

export interface MergeConfigPayload {
  name: string;
  sourceIds: number[];
}

export const mergeConfigAdapter = createEntityAdapter<MergeConfig>();

export interface MergeConfigState extends EntityState<MergeConfig>, CallState {
  filter: MergeConfigsFilter;
  count: number;
}

export const initialState: MergeConfigState = {
  ...mergeConfigAdapter.getInitialState<CallState>(initialCallState),
  filter: new MergeConfigsFilter(),
  count: 0
};
