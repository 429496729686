import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { routerForceNavigate, routerNavigate } from '../actions/router.actions';

@Injectable()
export class RouterEffects {
  routerNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigate),
        tap(action => this.router.navigateByUrl(action.path))
      ),
    { dispatch: false }
  );

  routerForceNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerForceNavigate),
        tap(action => this.router.navigateByUrl(action.path, { state: { bypassFormGuard: true, data: action.data } }))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}
