import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { Ticket } from '../../types';

export const submitTicket = createAction('[Zendesk Tickets] Submit Ticket', props<{ ticket: Ticket }>());
export const submitTicketSuccess = createAction(
  '[Zendesk Tickets] Submit Ticket Success',
  (
    snackbarMessages: SnackbarMessages = {
      default:
        'Your message has been sent to our customer service team. We will get in touch with you within 7 business days.'
    }
  ) => ({ snackbarMessages })
);
export const submitTicketFailure = createAction(
  '[Zendesk Tickets] Submit Ticket Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'We could not send this message due to technical issues on our end. Please try again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);
