import { NgModule } from '@angular/core';
import { Route, RouterModule, ROUTES, Routes } from '@angular/router';

import { componentAuthGuard, rootAuthGuard } from '@core/guards/auth.guard';
import { customersRedirectionGuard } from '@core/guards/customers-redirection.guard';
import { featureAvailabilityGuard } from '@core/guards/feature-availability.guard';
import { unsavedChangesGuard } from '@core/guards/unsaved-changes.guard';
import { userEditAuthGuard } from '@core/guards/user-edit-auth.guard';
import { nydusNetworkBootstrapResolver } from '@core/resolvers/nydus-network-bootstrap.resolver';
import { SCOPES_OR, SCOPES_OR_GENERATED } from '@core/types';

import { UiConfigs } from '../../app-module-config';
import { AuditLogDetailsComponent } from '../audit-logs/components/audit-log-details/audit-log-details.component';
import { auditLogResolver } from '../audit-logs/resolvers/audit-log.resolver';
import { userAuditLogsResolver } from '../audit-logs/resolvers/user-audit-logs.resolver';
import { AuditLogEntity } from '../audit-logs/types';
import { AuditLogsV2Component } from '../audit-logs/views/audit-logs-v2/audit-logs-v2.component';
import { AuditLogsComponent } from '../audit-logs/views/audit-logs/audit-logs.component';
import { userCampaignsResolver } from '../campaigns/resolvers/user-campaigns.resolver';
import { UserCampaignsComponent } from '../campaigns/views/user-campaigns/user-campaigns.component';
import { userEnrollmentsResolver } from '../enrollments/resolvers/user-enrollments.resolver';
import { UserEnrollmentsWrapperComponent } from '../enrollments/views/user-enrollments-wrapper/user-enrollments-wrapper.component';
import { userEventsResolver } from '../events/resolvers/user-events.resolver';
import { EventsComponent } from '../events/views/events.component';
import { bootstrapResolver } from '../gift-cards/resolvers/bootstraps.resolver';
import { loginAttemptsFilterResolver } from '../login-attempts/resolvers/login-attempts-filter.resolver';
import { userLoginAttemptsResolver } from '../login-attempts/resolvers/user-login-attempts.resolver';
import { LoginAttemptsComponent } from '../login-attempts/views/login-attempts/login-attempts.component';
import { entityNotesResolver } from '../notes/resolvers/entity-notes.resolver';
import { NotesComponent } from '../notes/views/notes/notes.component';
import { offersResolver } from '../offers/resolvers/offers.resolver';
import { orderItemCreateResolver } from '../orders/resolvers/order-item-create.resolver';
import { orderItemResolver } from '../orders/resolvers/order-item.resolver';
import { orderItemsResolver } from '../orders/resolvers/order-items.resolver';
import { OrderItemCreateComponent } from '../orders/views/order-item-create/order-item-create.component';
import { OrderItemDetailsV2Component } from '../orders/views/order-item-details-v2/order-item-details-v2.component';
import { OrderItemDetailsComponent } from '../orders/views/order-item-details/order-item-details.component';
import { OrderItemsV2Component } from '../orders/views/order-items-v2/order-items-v2.component';
import { OrderItemsComponent } from '../orders/views/order-items/order-items.component';
import { rolesResolver } from '../roles/resolvers/roles.resolver';
import { identitiesResolver } from './resolvers/identities.resolver';
import { mfaIdentitiesResolver } from './resolvers/mfa-identities.resolver';
import { tenantBootstrapResolver } from './resolvers/tenant-bootstrap.resolver';
import { transactionsResolver } from './resolvers/transactions.resolver';
import { userOfferActivitiesResolver } from './resolvers/user-offer-activities.resolver';
import { userPasswordsResolver } from './resolvers/user-passwords.resolver';
import { userTokensResolver } from './resolvers/user-tokens.resolver';
import { userResolver } from './resolvers/user.resolver';
import { usersFilterResolver } from './resolvers/users-filter.resolver';
import { EnrollUserComponent } from './views/enroll-user/enroll-user.component';
import { TransactionsComponent } from './views/transactions/transactions.component';
import { UserCreateComponent } from './views/user-create/user-create.component';
import { UserDetailsComponent } from './views/user-details/user-details.component';
import { UserEditComponent } from './views/user-edit/user-edit.component';
import { UserMenuComponent } from './views/user-menu/user-menu.component';
import { UserOfferActivitiesComponent } from './views/user-offer-activities/user-offer-activities.component';
import { UserOffersComponent } from './views/user-offers/user-offers.component';
import { UserPasswordsComponent } from './views/user-passwords/user-passwords.component';
import { UserTokensComponent } from './views/user-tokens/user-tokens.component';
import { UserComponent } from './views/user/user.component';
import { UsersV2Component } from './views/users-v2/users-v2.component';
import { UsersComponent } from './views/users/users.component';

export function generateUsersRoute(path: string, scopes: string[], additionalConfig?: object): Route {
  return {
    path,
    canMatch: [customersRedirectionGuard, rootAuthGuard],
    data: {
      ...additionalConfig,
      isCustomerView: path === 'customers',
      scopes,
      relationOperator: 'or'
    }
  };
}

export function usersChildrenRoutes(uiConfigs: UiConfigs): Routes {
  return [
    {
      path: 'enroll',
      component: EnrollUserComponent,
      canActivate: [componentAuthGuard],
      data: {
        scopes: SCOPES_OR_GENERATED.createEnrollments,
        relationOperator: 'or'
      },
      resolve: {
        prefetchTenantBootstrap: tenantBootstrapResolver
      }
    },
    {
      path: 'create',
      component: UserCreateComponent,
      canActivate: [componentAuthGuard],
      canDeactivate: [unsavedChangesGuard],
      data: {
        scopes: ['create_user']
      }
    },
    {
      path: ':userId',
      component: UserMenuComponent,
      resolve: {
        prefetchUser: userResolver
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'details'
        },
        {
          path: 'dashboard',
          canMatch: [featureAvailabilityGuard],
          data: {
            features: ['pointsActivity']
          },
          resolve: {
            prefetchNydusNetworkBootstrap: nydusNetworkBootstrapResolver
          },
          loadChildren: () => import('../../modules/dashboard/dashboard.module').then(module => module.DashboardModule)
        },
        {
          path: 'details',
          component: uiConfigs.users?.details === 'v2' ? UserComponent : UserDetailsComponent,
          canActivate: [componentAuthGuard],
          resolve: {
            prefetchIdentities: identitiesResolver,
            prefetchMfaIdentities: mfaIdentitiesResolver,
            notesFilter: entityNotesResolver
          },
          data: {
            scopes: SCOPES_OR.showUsers,
            relationOperator: 'or',
            notesConfigs: {
              baseColumns: ['updatedAt', 'type', 'note', 'adminUserId'],
              baseFilter: { type: ['POA', 'Alternative phone number'] },
              entity: 'users',
              entityIdKey: 'userId',
              isEntityDetailsView: true
            },
            mode: 'view'
          }
        },
        {
          path: 'tokens',
          component: UserTokensComponent,
          canActivate: [componentAuthGuard],
          data: {
            scopes: SCOPES_OR.viewUserTokens,
            relationOperator: 'or'
          },
          resolve: {
            userTokensFilter: userTokensResolver
          }
        },
        {
          path: 'events',
          component: EventsComponent,
          canActivate: [componentAuthGuard],
          data: {
            scopes: SCOPES_OR.viewEvents,
            relationOperator: 'or',
            isUserEventView: true
          },
          resolve: {
            prefetchEvents: userEventsResolver
          }
        },
        {
          path: 'logs',
          resolve: {
            tags: userAuditLogsResolver
          },
          children: [
            {
              path: '',
              component: uiConfigs.users?.logs === 'v2' ? AuditLogsV2Component : AuditLogsComponent,
              canActivate: [componentAuthGuard],
              data: {
                scopes: SCOPES_OR.viewAuditLogs,
                relationOperator: 'or',
                filterEntities: Object.values(AuditLogEntity)
              }
            },
            {
              path: ':id',
              component: AuditLogDetailsComponent,
              canActivate: [componentAuthGuard],
              data: {
                scopes: SCOPES_OR.viewAuditLogs,
                relationOperator: 'or'
              },
              resolve: {
                prefetchAuditLog: auditLogResolver
              }
            }
          ]
        },
        {
          path: 'campaigns',
          component: UserCampaignsComponent,
          canMatch: [featureAvailabilityGuard],
          canActivate: [componentAuthGuard],
          data: {
            scopes: SCOPES_OR.viewUserCampaigns,
            relationOperator: 'or',
            features: ['campaigns']
          },
          resolve: {
            routeFilters: userCampaignsResolver
          }
        },
        {
          path: 'notes',
          component: NotesComponent,
          canActivate: [componentAuthGuard],
          data: {
            scopes: SCOPES_OR.viewUserNotes,
            relationOperator: 'or',
            notesConfigs: {
              baseColumns: ['updatedAt', 'type', 'note', 'adminUserId', 'expiresAt', 'tags'],
              entity: 'users',
              entityIdKey: 'userId',
              isEntityDetailsView: false
            }
          },
          resolve: {
            notesFilter: entityNotesResolver
          }
        },
        {
          path: 'auto-redemptions',
          canMatch: [featureAvailabilityGuard],
          data: {
            features: ['autoRedemptions'],
            isAutoRedemptionView: true
          },
          loadChildren: () =>
            import('../../modules/auto-redemptions/auto-redemptions.module').then(
              module => module.AutoRedemptionsModule
            )
        },
        {
          path: 'memberships',
          canMatch: [featureAvailabilityGuard],
          data: {
            features: ['memberships']
          },
          loadChildren: () =>
            import('../../modules/memberships/memberships.module').then(module => module.MembershipsModule)
        },
        {
          path: 'reward-triggers',
          canMatch: [featureAvailabilityGuard],
          data: {
            features: ['rewardTriggers']
          },
          loadChildren: () =>
            import('../../modules/reward-triggers/reward-triggers.module').then(module => module.RewardTriggersModule)
        },
        {
          path: 'notifications',
          loadChildren: () =>
            import('../../modules/notifications/notifications.module').then(module => module.NotificationsModule)
        },
        {
          path: 'passwords',
          component: UserPasswordsComponent,
          canActivate: [componentAuthGuard],
          data: {
            scopes: SCOPES_OR.viewPasswords,
            relationOperator: 'or'
          },
          resolve: {
            resolve: userPasswordsResolver
          }
        },
        {
          path: 'enrollments',
          component: UserEnrollmentsWrapperComponent,
          canMatch: [featureAvailabilityGuard],
          canActivate: [componentAuthGuard],
          data: {
            scopes: SCOPES_OR.viewUserEnrollments,
            relationOperator: 'or',
            features: ['userProducts']
          },
          resolve: {
            prefetchUserEnrollments: userEnrollmentsResolver
          }
        },
        {
          path: 'login-attempts',
          component: LoginAttemptsComponent,
          canActivate: [componentAuthGuard],
          data: {
            scopes: SCOPES_OR.viewLoginAttempts,
            relationOperator: 'or',
            isUserLoginAttemptsView: true
          },
          resolve: {
            prefetchLoginAttempts: userLoginAttemptsResolver,
            routeFilters: loginAttemptsFilterResolver
          }
        },
        {
          path: 'user-offer-activities',
          component: UserOfferActivitiesComponent,
          canMatch: [featureAvailabilityGuard],
          data: {
            features: ['offers'],
            scopes: ['view_user_offer_activities']
          },
          resolve: {
            userOfferActivitiesFilter: userOfferActivitiesResolver,
            prefetchBootstrap: bootstrapResolver
          }
        },
        {
          path: 'offer-eligibility-tracker',
          component: TransactionsComponent,
          data: {
            scopes: ['view_user_transactions']
          },
          resolve: {
            transactionsFilter: transactionsResolver
          }
        },
        {
          path: 'offers',
          component: UserOffersComponent,
          canMatch: [featureAvailabilityGuard],
          canActivate: [componentAuthGuard],
          data: {
            features: ['offers'],
            scopes: SCOPES_OR.viewUserOffers,
            relationOperator: 'or',
            isUserRoute: true
          },
          resolve: {
            offerFilter: offersResolver
          }
        },
        {
          path: 'orders-items',
          canMatch: [featureAvailabilityGuard],
          data: {
            features: ['orders'],
            isUserOrdersItemsView: true
          },
          resolve: {
            prefetchOrderItemCreationData: orderItemCreateResolver
          },
          children: [
            {
              path: '',
              component: uiConfigs.users?.orderItems === 'v2' ? OrderItemsV2Component : OrderItemsComponent,
              canActivate: [componentAuthGuard],
              data: {
                scopes: SCOPES_OR.viewOrderItems,
                relationOperator: 'or'
              },
              resolve: {
                orderItemsFilter: orderItemsResolver
              }
            },
            {
              path: 'create/:redemptionType',
              component: OrderItemCreateComponent,
              canActivate: [componentAuthGuard],
              data: {
                scopes: ['admin_checkout']
              }
            },
            {
              path: ':orderItemId',
              component: uiConfigs.users?.orderItems === 'v2' ? OrderItemDetailsV2Component : OrderItemDetailsComponent,
              canActivate: [componentAuthGuard],
              data: {
                scopes: SCOPES_OR.showOrderItems,
                relationOperator: 'or'
              },
              resolve: {
                prefetchOrderItem: orderItemResolver
              }
            }
          ]
        },
        {
          path: 'fraud-evaluations',
          loadChildren: () =>
            import('../../modules/fraud-evaluations/fraud-evaluations.module').then(
              module => module.FraudEvaluationsModule
            )
        }
      ]
    },
    {
      path: 'edit/:userId',
      component: uiConfigs.users?.details === 'v2' ? UserComponent : UserEditComponent,
      canActivate: [userEditAuthGuard],
      canDeactivate: [unsavedChangesGuard],
      resolve: {
        user: userResolver,
        prefetchRoles: rolesResolver
      },
      data: {
        mode: 'edit'
      }
    }
  ];
}

export function createUsersRoutes(uiConfigs: UiConfigs): Routes {
  return [
    {
      path: '',
      component: uiConfigs.users?.list === 'v2' ? UsersV2Component : UsersComponent,
      canActivate: [componentAuthGuard],
      data: {
        scopes: SCOPES_OR.viewUsers,
        relationOperator: 'or'
      },
      resolve: {
        routeFilters: usersFilterResolver,
        prefetchRoles: rolesResolver
      }
    },
    ...usersChildrenRoutes(uiConfigs)
  ];
}

@NgModule({
  imports: [RouterModule],
  providers: [{ provide: ROUTES, multi: true, useFactory: createUsersRoutes, deps: ['uiConfigs'] }],
  exports: [RouterModule]
})
export class UsersRoutes {}
