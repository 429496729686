import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { GiftCard, giftCardAdapter, GiftCardState } from '../../types/gift-cards.type';

const giftCardsDetector = createDetector<GiftCard>();
const getGiftCardState = createFeatureSelector<GiftCardState>('giftCard');

const { selectAll: getGiftCardsList, selectEntities: getGiftCardsDictionary } =
  giftCardAdapter.getSelectors(getGiftCardState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError
} = getCallStateSelectors<GiftCardState>(getGiftCardState);

const isGiftCardsLoaded = createSelector(
  getGiftCardsDictionary,
  isBatchResting,
  (giftCards, batchResting) => giftCardsDetector('allLoaded')(giftCards) && batchResting
);

const getGiftCardById = (id: string): Selector<GiftCardState, GiftCard> =>
  createSelector(getGiftCardsDictionary, entities => entities[id]);

const getCount = createSelector(getGiftCardState, (state: GiftCardState) => state.count);

const getFilter = createSelector(getGiftCardState, (state: GiftCardState) => state.filter);

export const giftCardsQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getGiftCardsList,
  getGiftCardById,
  isGiftCardsLoaded,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError,
  getFilter,
  getCount
};
