<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <ng-container *ngFor="let tab of tabs">
    <ng-container *ngIf="tooltipPrefix; else menuWithoutTooltips">
      <admin-tooltip *ngIf="tab.displayed" [key]="tooltipPrefix + tab.path" [displayIcon]="false">
        <a
          mat-tab-link
          *ngIf="tab.displayed"
          [routerLink]="tab.path"
          (click)="activeTab = tab"
          [active]="activeTab === tab"
        >
          <mat-icon>{{ tab.icon }}</mat-icon>
          {{ tab.label }}
        </a>
      </admin-tooltip>
    </ng-container>

    <ng-template #menuWithoutTooltips>
      <a
        mat-tab-link
        *ngIf="tab.displayed"
        [routerLink]="tab.path"
        (click)="activeTab = tab"
        [active]="activeTab === tab"
      >
        <mat-icon>{{ tab.icon }}</mat-icon>
        {{ tab.label }}
      </a>
    </ng-template>
  </ng-container>
</nav>

<router-outlet></router-outlet>
