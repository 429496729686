<mat-form-field class="slim-outline-selection-field locale-switcher" appearance="outline">
  @if (showLabel) {
    <mat-label>Locale</mat-label>
  }

  <mat-select disableOptionCentering panelClass="dropdown-panel" [formControl]="localeControl">
    <mat-option *ngFor="let locale of locales" [value]="locale">
      <span class="text-primary">{{ locale }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
