import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { Ability } from '../types/abilities.type';

@Injectable({
  providedIn: 'root'
})
export class AbilitiesService {
  constructor(private missionControlService: MissionControlService) {}

  getAbility(id: string): Observable<Ability> {
    return this.missionControlService.get<Ability>(`abilities/${id}`);
  }

  getAbilities(): Observable<Ability[]> {
    return this.missionControlService.get<Ability[]>('abilities');
  }

  createAbility(ability: Ability): Observable<Ability> {
    return this.missionControlService.post<Ability>('abilities', ability);
  }

  updateAbility(ability: Ability): Observable<Ability> {
    return this.missionControlService.patch<Ability>(`abilities/${ability.id}`, ability);
  }

  deleteAbility(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`abilities/${id}`);
  }
}
