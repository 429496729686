import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { ConnectionCredential } from '../types/connection-credentials.type';

@Injectable({
  providedIn: 'root'
})
export class ConnectionCredentialsService {
  constructor(private missionControlService: MissionControlService) {}

  getConnectionCredentials(appConnectionId: string, invalidated: boolean): Observable<ConnectionCredential[]> {
    const invalidatedParam = ObjectUtils.sanitizeRequestObject<object>({ invalidated });

    return this.missionControlService.get<ConnectionCredential[]>(
      `connections/${appConnectionId}/credentials`,
      new HttpParams({ fromObject: { ...invalidatedParam } })
    );
  }
}
