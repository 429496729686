import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { or, Params } from '@utils';

import { loyaltyProgramsQuery } from '../../../loyalty-programs/store/selectors/loyalty-programs.selectors';
import { LoyaltyProgram, LoyaltyProgramState } from '../../../loyalty-programs/types';
import { membershipsQuery } from '../../../memberships/store/selectors/memberships.selectors';
import { Membership, MembershipState } from '../../../memberships/types/memberships.type';
import { createPointsTransferRedemption } from '../../store/actions/redemptions.actions';
import { currencyConversionQuery } from '../../store/selectors/currency-conversion.selectors';
import { redemptionQuery } from '../../store/selectors/redemptions.selectors';
import {
  CurrencyConversionState,
  PointsTransferRedemption,
  RedemptionState,
  SelectedAmount,
  ValidateMembershipForm
} from '../../types';

@Component({
  selector: 'admin-points-transfer-create',
  templateUrl: './points-transfer-create.component.html',
  styleUrls: ['./points-transfer-create.component.scss']
})
export class PointsTransferCreateComponent implements OnInit {
  @Input() selectedPointsAccountId: string;

  destroyRef = inject(DestroyRef);

  loyaltyProgramSelection: FormControl<string>;
  validateMembershipForm: FormGroup<ValidateMembershipForm>;
  loading$: Observable<boolean>;
  loyaltyPrograms$: Observable<LoyaltyProgram[]>;
  processingDelay$: Observable<number>;
  memberships: Membership[];
  selectedAmount: SelectedAmount;
  userId: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private loyaltyProgramStore: Store<LoyaltyProgramState>,
    private currencyConversionStore: Store<CurrencyConversionState>,
    private membershipStore: Store<MembershipState>,
    private store: Store<RedemptionState>
  ) {}

  ngOnInit(): void {
    this.userId = Params.find(this.route, 'userId');
    this.loyaltyProgramSelection = new FormControl<string>(null, Validators.required);
    this.validateMembershipForm = this.fb.group({
      number: this.fb.control(null),
      firstName: this.fb.control(null),
      lastName: this.fb.control(null)
    });
    this.validateMembershipForm.disable();

    this.loading$ = or(
      this.membershipStore.select(membershipsQuery.isBatchLoading),
      this.loyaltyProgramStore.select(loyaltyProgramsQuery.isBatchLoading),
      this.currencyConversionStore.select(currencyConversionQuery.isSingleLoading),
      this.store.select(redemptionQuery.isSingleLoading)
    );

    this.membershipStore
      .select(membershipsQuery.getMembershipsList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(memberships => {
        this.memberships = memberships.filter(membership => membership.type === 'points_transfer_membership');
      });

    this.loyaltyPrograms$ = this.loyaltyProgramStore.select(loyaltyProgramsQuery.getActiveAirlinePrograms);
  }

  onSelectionChange({ value: loyaltyProgramId }: MatSelectChange): void {
    const selectedMembership = this.memberships.find(membership => membership.loyaltyProgramId === loyaltyProgramId);
    this.processingDelay$ = this.loyaltyProgramStore.select(
      loyaltyProgramsQuery.getProcessingDelayById(loyaltyProgramId)
    );

    if (selectedMembership) {
      this.validateMembershipForm.patchValue(selectedMembership);
      this.validateMembershipForm.setErrors(null);
    } else {
      this.validateMembershipForm.reset();
      this.validateMembershipForm.setErrors({ invalid: true });
    }
  }

  redeem(): void {
    const { transferAmount, redemptionAmount } = this.selectedAmount;

    const redemption: PointsTransferRedemption = {
      userId: this.userId,
      pointsAccountId: this.selectedPointsAccountId,
      pointsTransfer: { loyaltyProgramId: this.loyaltyProgramSelection.value, transferAmount, redemptionAmount }
    };

    this.store.dispatch(createPointsTransferRedemption({ redemption }));
  }
}
