import { TimezoneMapping } from '../app-module-config';

export class TimezoneUtils {
  static timezoneDisplay = {
    'Europe/Dublin': 'Dublin, Edinburgh, Lisbon, London',
    'Africa/Monrovia': 'Monrovia, Reykjavik',
    'Europe/Berlin': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
    'Europe/Warsaw': 'Sarajevo, Skopje, Warsaw, Zagreb',
    'Africa/Windhoek': 'Windhoek',
    'Asia/Amman': 'Amman',
    'Europe/Athens': 'Athens, Bucharest',
    'Asia/Beirut': 'Beirut',
    'Africa/Cairo': 'Cairo',
    'Asia/Damascus': 'Damascus',
    'Africa/Johannesburg': 'Harare, Pretoria',
    'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    'Europe/Istanbul': 'Istanbul',
    'Asia/Jerusalem': 'Jerusalem',
    'Asia/Nicosia': 'Nicosia',
    'Asia/Baghdad': 'Baghdad',
    'Europe/Kaliningrad': 'Kaliningrad, Minsk',
    'Asia/Riyadh': 'Kuwait, Riyadh',
    'Africa/Nairobi': 'Nairobi',
    'Asia/Tehran': 'Tehran',
    'Asia/Dubai': 'Abu Dhabi, Muscat',
    'Asia/Baku': 'Baku',
    'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
    'Asia/Tbilisi': 'Tbilisi',
    'Asia/Yerevan': 'Yerevan',
    'Asia/Kabul': 'Kabul',
    'Asia/Karachi': 'Islamabad, Karachi',
    'Asia/Tashkent': 'Tashkent',
    'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
    'Asia/Kathmandu': 'Kathmandu',
    'Asia/Dhaka': 'Dhaka',
    'Asia/Yangon': 'Yangon (Rangoon)',
    'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
    'Asia/Novosibirsk': 'Novosibirsk',
    'Asia/Hong_Kong': 'Beijing, Chongqing, Hong Kong, Urumqi',
    'Asia/Krasnoyarsk': 'Krasnoyarsk',
    'Asia/Singapore': 'Kuala Lumpur, Singapore',
    'Australia/Perth': 'Perth',
    'Asia/Taipei': 'Taipei',
    'Asia/Ulaanbaatar': 'Ulaanbaatar',
    'Asia/Irkutsk': 'Irkutsk',
    'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
    'Asia/Seoul': 'Seoul',
    'Australia/Adelaide': 'Adelaide',
    'Australia/Darwin': 'Darwin',
    'Australia/Brisbane': 'Brisbane',
    'Australia/Melbourne': 'Canberra, Melbourne, Sydney',
    'Pacific/Guam': 'Guam, Port Moresby',
    'Australia/Hobart': 'Hobart',
    'Asia/Yakutsk': 'Yakutsk',
    'Asia/Vladivostok': 'Vladivostok',
    'Pacific/Auckland': 'Auckland, Wellington',
    'Pacific/Fiji': 'Fiji',
    'Asia/Magadan': 'Magadan',
    'Atlantic/Azores': 'Azores',
    'Atlantic/Cape_Verde': 'Cape Verde Is.',
    'America/Argentina/Buenos_Aires': 'Buenos Aires',
    'America/Cayenne': 'Cayenne, Fortaleza',
    'America/Montevideo': 'Montevideo',
    'America/Toronto': 'Canada',
    'America/Vancouver': 'Pacific Time (US & Canada)',
    'America/Cuiaba': 'Cuiaba',
    'America/La_Paz': 'Georgetown, La Paz, Manaus, San Juan',
    'America/Santiago': 'Santiago',
    'America/Caracas': 'Caracas',
    'America/Bogota': 'Bogota, Lima, Quito',
    'America/New_York': 'Eastern Time',
    'America/Indiana/Indianapolis': 'Indiana (East)',
    'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
    'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
    'America/Phoenix': 'Mountain Time (US & Canada)',
    'America/Anchorage': 'Alaska',
    'Pacific/Honolulu': 'Hawaii'
  };

  static getTimezoneOffset = (timezone: string, timezoneMapping: TimezoneMapping): string | undefined => {
    const offset = timezoneMapping[timezone];

    if (offset !== undefined) {
      const hours = Math.floor(offset / 3600);
      const minutes = Math.floor((offset % 3600) / 60);
      return `${hours >= 0 ? '+' : '-'}${Math.abs(hours).toString().padStart(2, '0')}${Math.abs(minutes)
        .toString()
        .padStart(2, '0')}`;
    } else {
      return undefined;
    }
  };
}
