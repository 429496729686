import { Injectable } from '@angular/core';

import { Tag } from '@shared/types';

import { TagDefs } from '../../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class TeIpTagDefs {
  readonly tagDefs: TagDefs<any> = {
    te_ip: {
      getDisplayPrefix: () => 'IP address',
      getDisplayValue: (tag: Tag) => tag.id
    }
  };
}
