export class OffersFilter {
  id?: string;
  tenantId?: string;
  status?: string;
  brand?: string;
  title?: string;
  channels?: string[];
  categories?: string[];
  suppliers?: string[];
  userId?: string;
  isFromGlobalView?: boolean;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortDirection?: string;

  constructor(isFromGlobalView: boolean = false) {
    this.page = 1;
    this.limit = 20;
    this.isFromGlobalView = isFromGlobalView;
  }
}
