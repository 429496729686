import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent {
  @Input() content: string;
  @Input() contentName: string;
  @Input() tooltipText?: string;
  @Input() loading?: Observable<boolean>;
  @Input() styleClassName?: string;
  @Input() buttonLabel?: string;

  constructor(public snackBar: MatSnackBar) {}

  openSnackBar(): void {
    this.snackBar.open(`${this.contentName} copied to clipboard`, 'Dismiss', { panelClass: 'mat-snack-bar-success' });
  }
}
