import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';

import { FraudRule, fraudRuleAdapter, FraudRuleState } from '../../types/fraud-rules.type';

const getFraudRuleState = createFeatureSelector<FraudRuleState>('fraudRule');
const fraudRuleDetector = createDetector<FraudRule>();

const { selectAll: getFraudRulesList, selectEntities: getFraudRulesDictionary } =
  fraudRuleAdapter.getSelectors(getFraudRuleState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<FraudRuleState>(getFraudRuleState);

const getFetchList = getFetchStateSelector<FraudRuleState>(getFraudRuleState);

const isFraudRulesLoaded = createSelector(
  getFraudRulesDictionary,
  isBatchResting,
  (fraudRules, batchResting) => fraudRuleDetector('allLoaded')(fraudRules) && batchResting
);

const isFraudRuleLoaded = (id: string): Selector<FraudRuleState, boolean> =>
  createSelector(getFraudRulesDictionary, entities => fraudRuleDetector('oneLoaded')(entities, id));

const getFraudRuleById = (id: string): Selector<FraudRuleState, FraudRule> =>
  createSelector(getFraudRulesDictionary, entities => entities[id]);

export const fraudRulesQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isFraudRuleLoaded,
  isFraudRulesLoaded,
  getFraudRulesList,
  getFraudRulesDictionary,
  getFraudRuleById,
  getFetchList
};
