<ng-container [formGroup]="formGroup">
  <ng-container *ngFor="let fieldName of schema.getPropertyKeys()">
    <!--   nested form fields   -->
    <section *ngIf="schema.isSpecifiedType(fieldName, 'object'); else nonNestedField">
      <h3 *ngIf="schema.getNestedSchema(fieldName).value?.properties">
        {{ getFormFieldLabel(fieldName) | customTitleCase: 'fromCamelCase' }}
      </h3>
      <admin-form-fields
        [formGroup]="formGroup.get(fieldName) | formTransform: 'formGroup'"
        [schema]="schema.getNestedSchema(fieldName)"
        [secureFieldIframeConfig]="secureFieldIframeConfig"
        (tokenizeSuccess)="tokenizeSuccess.emit($event)"
        (validationError)="validationError.emit($event)"
      >
      </admin-form-fields>
    </section>

    <!--   non-nested form fields   -->
    <ng-template #nonNestedField>
      <!--    date field    -->
      <div *ngIf="schema.isSpecifiedFormat(fieldName, 'date')" class="date-time-wrapper__sub-wrapper">
        <div class="date">
          <admin-date-selector
            [formControlName]="fieldName"
            [fieldLabel]="getFormFieldLabel(fieldName)"
            [max]="fieldName === 'birthdate' ? today : null"
            [placeholder]="getFormFieldLabel(fieldName)"
            [required]="schema.isRequired(fieldName)"
          >
          </admin-date-selector>
        </div>
      </div>

      <!--    checkbox field    -->
      <div
        *ngIf="schema.isSpecifiedXDisplayType(fieldName, 'checkbox') || schema.isSpecifiedType(fieldName, 'boolean')"
      >
        <admin-checkbox
          [errors]="formGroup.get(fieldName).errors"
          [fieldLabel]="getFormFieldLabel(fieldName)"
          [formControlName]="fieldName"
          [required]="schema.isRequired(fieldName)"
        >
        </admin-checkbox>
      </div>

      <!--    pan field    -->
      <div *ngIf="schema.isSpecifiedFormat(fieldName, 'pan')">
        <div class="secure-field" [attr.id]="'secure-field-' + fieldName"></div>
      </div>

      <!--    string input    -->
      <div *ngIf="schema.isNormalStringInput(fieldName)">
        <admin-text-input
          [confirmationControl]="formGroup.get(fieldName + 'Confirmation')"
          [errors]="formGroup.get(fieldName).errors"
          [inputType]="schema.getFieldAttribute(fieldName, 'format')"
          [fieldLabel]="getFormFieldLabel(fieldName)"
          [formControlName]="fieldName"
          [required]="schema.isRequired(fieldName)"
        >
        </admin-text-input>
      </div>

      <!--    number input    -->
      <div *ngIf="schema.isNumberInput(fieldName)">
        <admin-number-input
          [fieldLabel]="getFormFieldLabel(fieldName)"
          [formControlName]="fieldName"
          [required]="schema.isRequired(fieldName)"
          [schema]="schema.value.properties[fieldName]"
        >
        </admin-number-input>
      </div>

      <!--    array input    -->
      <div *ngIf="schema.isArrayInput(fieldName)">
        <admin-array-input
          [fieldLabel]="getFormFieldLabel(fieldName)"
          [formControlName]="fieldName"
          [required]="schema.isRequired(fieldName)"
          [schema]="schema.value.properties[fieldName]"
          [formGroup]="formGroup"
        >
        </admin-array-input>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
