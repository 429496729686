import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { PickupZonesService } from '../../services/pickup-zones.service';
import {
  loadFileProcessingRequests,
  loadFileProcessingRequestsFailure,
  loadFileProcessingRequestsSuccess
} from '../actions/file-processing-requests.actions';

@Injectable()
export class FileProcessingRequestsEffects {
  loadFileProcessingRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFileProcessingRequests),
      exhaustMap(action =>
        this.pickupZonesService.getFileProcessingRequests(action.filter.pickupZone).pipe(
          map(fileProcessingRequests =>
            loadFileProcessingRequestsSuccess({
              fileProcessingRequests,
              filter: {
                ...action.filter
              }
            })
          ),
          catchError(error => of(loadFileProcessingRequestsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pickupZonesService: PickupZonesService
  ) {}
}
