import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, webhookSettingAdapter, WebhookSettingState } from '../../types';
import {
  createWebhookSettings,
  createWebhookSettingsFailure,
  createWebhookSettingsSuccess,
  loadWebhookSettings,
  loadWebhookSettingsFailure,
  loadWebhookSettingsSuccess,
  updateWebhookSettings,
  updateWebhookSettingsFailure,
  updateWebhookSettingsSuccess
} from '../actions/webhook-settings.actions';

export const webhookSettingTriggers: EntityTriggers = {
  single: {
    loading: [createWebhookSettings.type, updateWebhookSettings.type],
    resting: [createWebhookSettingsSuccess.type, updateWebhookSettingsSuccess.type],
    erroring: [createWebhookSettingsFailure.type, updateWebhookSettingsFailure.type]
  },
  batch: {
    loading: [loadWebhookSettings.type],
    resting: [loadWebhookSettingsSuccess.type],
    erroring: [loadWebhookSettingsFailure.type]
  }
};

export function webhookSettingsReducer(state: WebhookSettingState = initialState, action: Action): WebhookSettingState {
  return callStateReducer(baseReducer, webhookSettingTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadWebhookSettingsSuccess, (state, { webhookSettingsResults }) =>
    webhookSettingAdapter.setAll(webhookSettingsResults.data, state)
  ),
  on(createWebhookSettingsSuccess, (state, { webhookSettingsResults }) =>
    webhookSettingAdapter.setAll(webhookSettingsResults.data, state)
  ),
  on(updateWebhookSettingsSuccess, (state, { webhookSettingsResults }) =>
    webhookSettingAdapter.upsertMany(webhookSettingsResults.data, state)
  )
);
