import { HttpError } from '@core/types';
import { ComponentRenderState } from '@shared/types';
import { LogicRule, LogicRuleAttribute } from '@shared/types/logic-rule.type';

import { Segment } from './segments.type';

export interface SegmentCreationState {
  form: Pick<Segment, 'name' | 'description' | 'updateFrequency'> | null;
  logics: LogicRule[];
  preview: {
    memberCount: number;
    state: ComponentRenderState;
    error: HttpError | null;
  };
  attributes: {
    attributeMap: Record<string, LogicRuleAttribute>;
    state: ComponentRenderState;
    error: HttpError | null;
  };
}

export const initialSegmentCreationState: SegmentCreationState = {
  form: null,
  logics: [],
  preview: {
    memberCount: 0,
    state: 'initial',
    error: null
  },
  attributes: {
    attributeMap: {},
    state: 'initial',
    error: null
  }
};
