import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppModuleConfig } from '../../../app-module-config';
import { loadApprovalRequestStatistics } from '../../approval-requests/store/actions/approval-requests.actions';
import { SCOPES_GROUP, SCOPES_OR, SCOPES_OR_GENERATED } from './scenario-scopes.type';
import { ScopesRelationOperator } from './scopes-relation-operator.type';

export interface NavItem {
  id: string;
  displayName: string;
  iconName?: string;
  route?: string; // exact route for navigation
  rootRoutes?: string[]; // root route of this item
  scopes?: string[] | string[][];
  relationOperator?: ScopesRelationOperator;
  action?: Action;
  isActionDispatched?: boolean;
  badgeValue$?: Observable<number>;
  children?: ChildNavItem[];
  checkFeatureAvailability?: boolean;
  isProFeature?: boolean;
}

export interface ChildNavItem extends Omit<NavItem, 'iconName'> {}

interface AnalyticsNavItem extends ChildNavItem {
  dashboardIdKey: keyof AppModuleConfig['analytics'];
}

export const overviewNav = {
  id: 'overview',
  displayName: 'Overview',
  iconName: 'bpm-monitor',
  scopes: ['view_analytics'],
  route: '',
  checkFeatureAvailability: true
};

export const analyticsNav: NavItem = {
  id: 'analytics',
  displayName: 'Analytics',
  iconName: 'chart-arrow',
  scopes: ['view_analytics']
};

export const analyticsNavItems: AnalyticsNavItem[] = [
  {
    id: 'programPerformance',
    displayName: 'Program performance',
    route: '/analytics/program-performance',
    dashboardIdKey: 'programPerformanceDashboardId',
    checkFeatureAvailability: true
  },
  {
    id: 'user',
    displayName: 'User',
    route: '/analytics/user',
    dashboardIdKey: 'userDashboardId',
    checkFeatureAvailability: true
  },
  {
    id: 'customerValue',
    displayName: 'Customer Value',
    route: '/analytics/customer-value',
    dashboardIdKey: 'customerValueDashboardId',
    checkFeatureAvailability: true
  },
  {
    id: 'redemption',
    displayName: 'Redemption',
    route: '/analytics/redemption',
    dashboardIdKey: 'redemptionDashboardId',
    checkFeatureAvailability: true
  },
  {
    id: 'engagement',
    displayName: 'Engagement',
    route: '/analytics/engagement',
    dashboardIdKey: 'engagementDashboardId',
    checkFeatureAvailability: true
  },
  {
    id: 'campaign',
    displayName: 'Campaign',
    route: '/analytics/campaign',
    dashboardIdKey: 'campaignDashboardId',
    checkFeatureAvailability: true
  }
];

const rewardsOfferingChildren: ChildNavItem[] = [
  {
    id: 'giftCards',
    displayName: 'Gift Cards',
    route: '/gift-cards/cards',
    rootRoutes: ['/gift-cards'],
    scopes: SCOPES_OR_GENERATED.viewGiftCardsMenu,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  {
    id: 'merchandises',
    displayName: 'Merchandise',
    route: '/merchandises',
    scopes: SCOPES_OR.viewMerchandises,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  {
    id: 'pointsTransfers',
    displayName: 'Points Transfers',
    route: '/loyalty-programs',
    scopes: SCOPES_OR.viewLoyaltyPrograms,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  {
    id: 'offers',
    displayName: 'Offers',
    route: '/offers',
    scopes: SCOPES_OR.viewOffers,
    relationOperator: 'or',
    checkFeatureAvailability: true
  }
];

export const defaultNavItems: NavItem[] = [
  {
    id: 'userManagement',
    displayName: 'User management',
    iconName: 'user-grid',
    children: [
      {
        id: 'customers',
        displayName: 'Users',
        route: '/customers',
        scopes: SCOPES_OR.viewCustomers,
        relationOperator: 'or'
      },
      {
        id: 'segments',
        displayName: 'Segments',
        route: '/segments',
        scopes: ['view_segments']
      }
    ]
  },
  {
    id: 'enrollments',
    displayName: 'Enrollments',
    iconName: 'credit-card',
    route: '/enrollments',
    scopes: SCOPES_GROUP.viewEnrollmentsPage,
    relationOperator: 'all-loose-groups',
    checkFeatureAvailability: true
  },
  // in nested nav-item, remember to remove parent item's route
  // and if needed, add additional scope if not a children's scope
  {
    id: 'programSettings',
    displayName: 'Program Settings',
    iconName: 'settings',
    children: [
      {
        id: 'rewardsProgram',
        displayName: 'General Set Up',
        route: '/rewards-program',
        scopes: ['view_rewards_program'],
        checkFeatureAvailability: true
      },
      {
        id: 'earnRule',
        displayName: 'Earn Rules',
        route: '/earn-rules',
        scopes: ['view_earn_rules'],
        checkFeatureAvailability: true
      },
      {
        id: 'customDomain',
        displayName: 'Custom Domain',
        route: '/custom-domain',
        scopes: ['view_custom_domains'],
        checkFeatureAvailability: true
      }
    ]
  },
  {
    id: 'websiteStyling',
    displayName: 'Website Styling',
    iconName: 'screen-image',
    children: [
      {
        id: 'branding',
        displayName: 'Branding',
        route: '/partner-configs/branding',
        scopes: ['manage_partner_configs'],
        checkFeatureAvailability: true
      },
      {
        id: 'siteEditor',
        displayName: 'Site Editor',
        route: '/partner-configs/site-editor',
        scopes: ['manage_partner_configs'],
        checkFeatureAvailability: true
      }
    ]
  },
  {
    id: 'rewardsOffering',
    displayName: 'Rewards Offering',
    iconName: 'bag-of-cash',
    children: rewardsOfferingChildren
  },
  {
    id: 'rcRewardsOffering',
    displayName: 'Rewards Offering',
    iconName: 'bag-of-cash',
    route: '/rewards-offering',
    scopes: SCOPES_OR.viewRewardsOffering,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  {
    id: 'legacyRewardsOffering',
    displayName: 'Legacy Rewards Offering',
    iconName: 'atm-dollar',
    children: rewardsOfferingChildren
  },
  {
    id: 'orders',
    displayName: 'Orders',
    iconName: 'shopping-cart-01',
    route: '/orders-items',
    scopes: SCOPES_OR.viewOrderItems,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  {
    id: 'pricing',
    displayName: 'Pricing',
    iconName: 'dollar-coin',
    route: '/redemption-rates',
    scopes: SCOPES_OR.viewPricing,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  {
    id: 'suppliers',
    displayName: 'Suppliers Management',
    iconName: 'credit-card',
    route: '/suppliers',
    scopes: ['view_suppliers'],
    checkFeatureAvailability: true
  },
  {
    id: 'marketingCentre',
    displayName: 'Marketing Centre',
    iconName: 'megaphone',
    children: [
      {
        id: 'eventTypes',
        displayName: 'Events',
        route: '/event-types',
        scopes: ['view_event_types']
      },
      {
        id: 'campaigns',
        displayName: 'Campaigns',
        route: '/campaigns',
        scopes: SCOPES_OR.viewCampaigns,
        relationOperator: 'or',
        checkFeatureAvailability: true
      },
      {
        id: 'tenantCampaigns',
        displayName: 'Campaigns',
        route: '/campaigns',
        scopes: ['view_tenant_campaigns'],
        checkFeatureAvailability: true
      },
      {
        id: 'promoCodes',
        displayName: 'Promos',
        route: '/promo-codes',
        scopes: ['view_promo_codes'],
        checkFeatureAvailability: true
      },
      {
        id: 'promotions',
        displayName: 'Promotions',
        route: '/promotions',
        scopes: ['view_campaign_proposals'],
        checkFeatureAvailability: true
      }
    ]
  },
  {
    id: 'batchProcessing',
    displayName: 'Batch Processing',
    iconName: 'book',
    children: [
      {
        id: 'batchProcessingOverview',
        displayName: 'Overview',
        route: '/pickup-zones/zones',
        scopes: ['manage_pickup_zones:index'],
        checkFeatureAvailability: true
      },
      {
        id: 'batchProcessingHistory',
        displayName: 'History',
        route: '/pickup-zones/file-processing-requests',
        scopes: ['manage_file_processing_requests:index'],
        checkFeatureAvailability: true
      }
    ]
  },
  {
    id: 'logs',
    displayName: 'Logs',
    iconName: 'bullet-points-dots',
    children: [
      {
        id: 'auditLogs',
        displayName: 'Audit Logs',
        route: '/logs',
        scopes: SCOPES_OR.viewAuditLogs,
        relationOperator: 'or'
      },
      {
        id: 'events',
        displayName: 'Audit Events',
        route: '/events',
        scopes: SCOPES_OR.viewEvents,
        relationOperator: 'or'
      },
      {
        id: 'loginAttempts',
        displayName: 'Login Attempts',
        route: '/login-attempts',
        scopes: SCOPES_OR.viewLoginAttempts,
        relationOperator: 'or'
      }
    ]
  },
  {
    id: 'tenantManagement',
    displayName: 'Tenant Management',
    iconName: 'building',
    children: [
      {
        id: 'tenants',
        displayName: 'Tenants',
        route: '/tenants', // NOTE: this will be transformed in SideNavComponent
        scopes: SCOPES_OR.viewTenants,
        relationOperator: 'or'
      },
      {
        id: 'authenticationProviders',
        displayName: 'Authentication Providers',
        route: '/authentication-providers',
        scopes: SCOPES_OR.viewAuthenticationProviders,
        relationOperator: 'or'
      },
      {
        id: 'enrollTenant',
        displayName: 'Enroll Tenant',
        route: '/tenants/enroll',
        scopes: ['tenants:create']
      },
      {
        id: 'uiSettings',
        displayName: 'Update UI Settings',
        route: '/ui-settings',
        scopes: ['manage_ui_settings']
      },
      {
        id: 'questions',
        displayName: 'Update FAQ',
        route: '/questions',
        scopes: ['manage_questions']
      },
      {
        id: 'tenantPaymentTier',
        displayName: 'Update Payment Tier',
        route: '/payment-tiers/tenant-config',
        scopes: ['manage_payment_tiers']
      },
      {
        id: 'tenantLocales',
        displayName: 'Tenant locales',
        route: '/tenant-locales',
        scopes: ['view_localisation']
      },
      {
        id: 'translations',
        displayName: 'Translations',
        route: '/translations',
        scopes: ['view_localisation']
      }
    ]
  },
  {
    id: 'fraudServices',
    displayName: 'Fraud Services',
    iconName: 'lock',
    children: [
      {
        id: 'fraudLists',
        displayName: 'Fraud Settings',
        route: '/fraud-lists',
        scopes: ['fraud_lists:index']
      },
      {
        id: 'fraudDetection',
        displayName: 'Fraud Detection',
        route: '/fraud-monitoring',
        scopes: ['view_fraud_analytics']
      },
      {
        id: 'fraudRules',
        displayName: 'Fraud Rules',
        route: '/fraud-rules',
        scopes: ['view_fraud_rules']
      },
      {
        id: 'watchlists',
        displayName: 'Watchlists',
        route: '/watchlists',
        scopes: SCOPES_OR.viewWatchlists,
        relationOperator: 'or'
      }
    ]
  },
  {
    id: 'accessControl',
    displayName: 'Access Control',
    iconName: 'key',
    scopes: [
      SCOPES_OR.viewAgents,
      ['view_team_members'],
      ...SCOPES_GROUP.viewRolePermissionsPage,
      SCOPES_OR.viewAccessPolicies,
      SCOPES_OR.viewAbilities
    ],
    relationOperator: 'any-strict-groups',
    children: [
      {
        id: 'agents',
        displayName: 'Agents',
        route: '/agents',
        scopes: SCOPES_OR.viewAgents,
        relationOperator: 'or'
      },
      {
        id: 'teamMembers',
        displayName: 'Team Members',
        route: '/team-members',
        scopes: ['view_team_members'],
        checkFeatureAvailability: true
      },
      {
        id: 'roles',
        displayName: 'Roles',
        route: '/roles',
        scopes: SCOPES_GROUP.viewRolePermissionsPage,
        relationOperator: 'any-strict-groups'
      },
      {
        id: 'accessPolicies',
        displayName: 'Access Policies',
        route: '/access-policies',
        scopes: SCOPES_OR.viewAccessPolicies,
        relationOperator: 'or'
      },
      {
        id: 'abilities',
        displayName: 'Abilities',
        route: '/abilities',
        scopes: SCOPES_OR.viewAbilities,
        relationOperator: 'or'
      }
    ]
  },
  {
    id: 'appsOverview',
    displayName: 'Apps Overview',
    iconName: 'grid-view',
    children: [
      {
        id: 'apps',
        displayName: 'Apps',
        route: '/apps',
        scopes: SCOPES_OR.viewApps,
        relationOperator: 'or'
      },
      {
        id: 'keys',
        displayName: 'Keys',
        route: '/keys',
        scopes: SCOPES_OR.viewKeys,
        relationOperator: 'or'
      },
      {
        id: 'tools',
        displayName: 'Tools',
        route: '/tools',
        scopes: SCOPES_OR.exportTools,
        relationOperator: 'or'
      },
      {
        id: 'mcTenants',
        displayName: 'MC Tenants',
        route: '/mc-tenants',
        scopes: SCOPES_OR.viewMcTenants,
        relationOperator: 'or'
      },
      {
        id: 'domains',
        displayName: 'Domains',
        route: '/domains',
        scopes: SCOPES_OR.viewDomains,
        relationOperator: 'or'
      },
      {
        id: 'guardhouseCache',
        displayName: 'Guardhouse Cache',
        route: '/guardhouse-cache',
        scopes: ['cache:clear']
      }
    ]
  },
  {
    id: 'developers',
    displayName: 'Developers',
    iconName: 'code-arrows',
    children: [
      {
        id: 'apiSettings',
        displayName: 'API Settings',
        route: '/api-settings',
        scopes: ['view_partner_app'],
        checkFeatureAvailability: true
      },
      {
        id: 'webhooks',
        displayName: 'Webhooks',
        route: '/webhooks',
        scopes: ['view_webhook_settings'],
        checkFeatureAvailability: true
      }
    ]
  },
  {
    id: 'loyaltyData',
    displayName: 'Loyalty Data',
    iconName: 'heart-monitor',
    scopes: SCOPES_OR.viewLoyaltyDataMenu,
    relationOperator: 'or',
    children: [
      {
        id: 'pointsAccounts',
        displayName: 'Points Accounts',
        route: '/loyalty-data/points-accounts',
        scopes: ['points_accounts:index'],
        checkFeatureAvailability: true
      },
      {
        id: 'products',
        displayName: 'Products',
        route: '/loyalty-data/products',
        scopes: ['products:index'],
        checkFeatureAvailability: true
      }
    ]
  },
  {
    id: 'notes',
    displayName: 'Notes',
    iconName: 'notepad',
    route: '/notes',
    scopes: SCOPES_OR.viewNotes,
    relationOperator: 'or'
  },
  {
    id: 'approvalRequests',
    displayName: 'Approval Requests',
    iconName: 'tablet-locked',
    route: '/approval-requests',
    scopes: SCOPES_OR.viewApprovalRequests,
    relationOperator: 'or',
    action: loadApprovalRequestStatistics(),
    isActionDispatched: false
  }
];
