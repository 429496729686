import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { MembershipsService } from '../../../memberships/services/memberships.service';
import { updateMembershipSuccess } from '../../../memberships/store/actions/memberships.actions';
import { RedemptionsService } from '../../services/redemptions.service';
import { OrderItemsFilter } from '../../types';
import { loadOrderItems } from '../actions/order-items.actions';
import {
  createCashRedemption,
  createCashRedemptionFailure,
  createCashRedemptionSuccess,
  createPointsTransferRedemption,
  createPointsTransferRedemptionFailure,
  createPointsTransferRedemptionSuccess,
  updateMembershipAndCreateCashRedemption
} from '../actions/redemptions.actions';

@Injectable()
export class RedemptionsEffects {
  createCashRedemption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCashRedemption),
      exhaustMap(action =>
        this.redemptionsService.createCashRedemption(action.redemption).pipe(
          map(redemption => createCashRedemptionSuccess({ redemption })),
          catchError(error => of(createCashRedemptionFailure({ error })))
        )
      )
    )
  );

  updateMembershipAndCreateCashRedemption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMembershipAndCreateCashRedemption),
      exhaustMap(({ membershipId, membership, redemption }) =>
        this.membershipsService.updateMembership(redemption.userId, membershipId, membership).pipe(
          exhaustMap(result => merge([updateMembershipSuccess({ result }), createCashRedemption({ redemption })])),
          catchError(error => of(createCashRedemptionFailure({ error })))
        )
      )
    )
  );

  createPointsTransferRedemption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPointsTransferRedemption),
      exhaustMap(action =>
        this.redemptionsService.createPointsTransferRedemption(action.redemption).pipe(
          map(redemption => createPointsTransferRedemptionSuccess({ redemption })),
          catchError(error => of(createPointsTransferRedemptionFailure({ error })))
        )
      )
    )
  );

  createRedemptionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCashRedemptionSuccess, createPointsTransferRedemptionSuccess),
      exhaustMap(action => {
        const userId = action.redemption.attributes.userId;
        return merge([
          loadOrderItems({ filter: { ...new OrderItemsFilter(), userId } }),
          routerNavigate({ path: `/customers/${userId}/orders-items` })
        ]);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private membershipsService: MembershipsService,
    private redemptionsService: RedemptionsService
  ) {}
}
