import { inject, ProviderToken } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { Formatters } from '@utils';

export const callbackGuard: CanActivateFn = (route: ActivatedRouteSnapshot): UrlTree | boolean => {
  const router: Router = inject(Router);
  const callbackRedirects: object = inject('callbackRedirects' as unknown as ProviderToken<object>);

  let redirect: string = callbackRedirects?.[Formatters.fromSnakeToCamelCase(route.queryParams.redirect)];
  if (!redirect) {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  const paramMatches = redirect.match(/:[^\/]+/g) || [];

  for (const match of paramMatches) {
    const paramKey = match.substring(1, match.length); // remove initial ":" character
    const paramValue = route.queryParamMap.get(paramKey);

    if (!paramValue) {
      return false;
    }

    redirect = redirect.replace(match, paramValue);
  }

  return router.parseUrl(redirect);
};
