import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { CreateIdentityParams, Identity } from '../types';

@Injectable({
  providedIn: 'root'
})
export class IdentitiesService {
  constructor(private missionControlService: MissionControlService) {}

  getIdentities(userId: string): Observable<Identity[]> {
    return this.missionControlService.get<Identity[]>(`users/${userId}/identities`);
  }

  createIdentity(requestBody: CreateIdentityParams): Observable<void> {
    return this.missionControlService.post<void>('users/identities', ObjectUtils.sanitizeRequestObject(requestBody));
  }

  verifyIdentity(id: string): Observable<void> {
    return this.missionControlService.post<void>('identity_verification', { id });
  }

  deleteIdentity(reference: string): Observable<void> {
    return this.missionControlService.delete<void>(`users/identities/${reference}`);
  }
}
