import { Pipe, PipeTransform } from '@angular/core';

import { Nullable } from '@shared/types';

@Pipe({ name: 'customKeyValue' })
export class CustomKeyValuePipe implements PipeTransform {
  transform(obj: any): Nullable<{ key: string; value: any }[]> {
    if (!obj || !(obj instanceof Object) || Array.isArray(obj)) {
      return null;
    }
    return Object.entries(obj).reduce((result, [key, value]) => [...result, { key, value }], []);
  }
}
