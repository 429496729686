import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Tag } from '@shared/types';

import { GiftCardOrder } from '../../../gift-cards/types/gift-card-orders.type';
import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class GiftCardOrderTagDefs {
  readonly tagDefs: TagDefs<GiftCardOrder> = {
    gift_card_order_id: {
      getDisplayPrefix: () => 'Gift Card Order',
      getDisplayValue: (tag: Tag) => tag.id,
      getRouteLink: (tags: Tag[]) => {
        if (!this.scopes.hasAny(SCOPES_OR.showGiftCardOrders)) {
          return null;
        }

        const giftCardOrderTag = tags.find(tag => tag.type === 'gift_card_order_id');
        return giftCardOrderTag ? `/gift-cards/orders/${giftCardOrderTag.id}` : null;
      }
    }
  };
  constructor(private scopes: Scopes) {}
}
