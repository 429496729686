import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, tenantAdapter, TenantState } from '../../types/tenants.type';
import {
  enrollTenant,
  enrollTenantFailure,
  enrollTenantSuccess,
  handoverToTenant,
  handoverToTenantFailure,
  handoverToTenantSuccess,
  loadTenant,
  loadTenantFailure,
  loadTenants,
  loadTenantsFailure,
  loadTenantsSuccess,
  loadTenantSuccess,
  updateTenant,
  updateTenantFailure,
  updateTenantSuccess
} from '../actions/tenants.actions';

export const tenantTriggers: EntityTriggers = {
  single: {
    loading: [loadTenant.type, updateTenant.type, enrollTenant.type, handoverToTenant.type],
    resting: [loadTenantSuccess.type, updateTenantSuccess.type, enrollTenantSuccess.type, handoverToTenantSuccess.type],
    erroring: [loadTenantFailure.type, updateTenantFailure.type, enrollTenantFailure.type, handoverToTenantFailure.type]
  },
  batch: {
    loading: [loadTenants.type],
    resting: [loadTenantsSuccess.type],
    erroring: [loadTenantsFailure.type]
  }
};

export function tenantsReducer(state: TenantState = initialState, action: Action): TenantState {
  return callStateReducer(baseReducer, tenantTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadTenantSuccess, (state, { tenant }) => tenantAdapter.upsertOne(tenant, state)),
  on(loadTenantsSuccess, (state, { tenants }) => tenantAdapter.setAll(tenants, state)),
  on(updateTenantSuccess, (state, { tenant }) => tenantAdapter.updateOne({ id: tenant.id, changes: tenant }, state))
);
