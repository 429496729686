<mat-card appearance="outlined" class="mat-elevation-z1 mat-card">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="header">
      <mat-card-title>Audit Events</mat-card-title>
      <admin-copy-current-url [loading$]="loading$"></admin-copy-current-url>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="data-table-container w-full">
        <form [formGroup]="filterForm" class="slim-form-field">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <mat-form-field formGroupName="timestamp" class="date-form">
                <mat-label>Date Range</mat-label>
                <div class="date-form__wrapper">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matInput
                      matStartDate
                      formControlName="gte"
                      placeholder="Start date"
                      (focus)="picker.open()"
                    />
                    <input matInput matEndDate formControlName="lte" placeholder="End date" (focus)="picker.open()" />
                  </mat-date-range-input>
                  <button
                    matSuffix
                    mat-icon-button
                    color="primary"
                    class="date-field-button"
                    (click)="filterForm.controls.timestamp.reset()"
                    [disabled]="
                      (loading$ | async) ||
                      (!filterForm.get('timestamp.gte').value && !filterForm.get('timestamp.lte').value)
                    "
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </mat-form-field>

              <mat-form-field formGroupName="query" *ngIf="!isUserEventView" class="query-field">
                <mat-label>
                  <mat-icon>view_compact</mat-icon>
                  Entity
                </mat-label>
                <mat-select
                  formControlName="entity"
                  (selectionChange)="setDefaultValueForSelection($event.value); setValidator($event.value)"
                  disableOptionCentering
                  panelClass="dropdown-panel"
                >
                  <mat-option value="app">App</mat-option>
                  <mat-option value="event">User Event</mat-option>
                  <mat-option value="action">Action</mat-option>
                </mat-select>
              </mat-form-field>

              <ng-container [ngSwitch]="filterForm.get('query.entity').value" *ngIf="!isUserEventView">
                <mat-form-field class="user-id-form-field" *ngSwitchCase="'event'" formGroupName="query">
                  <mat-icon matPrefix>perm_identity</mat-icon>
                  <mat-label>User ID</mat-label>
                  <input matInput aria-label="Search" type="search" placeholder="User ID" formControlName="value" />
                  <admin-input-reset
                    matSuffix
                    [inputControl]="filterForm.get('query.value')"
                    [loading]="loading$ | async"
                  ></admin-input-reset>
                  <mat-error *ngIf="filterForm.get('query.value').hasError('invalidUuid')">Invalid UUID</mat-error>
                  <mat-error>Required</mat-error>
                </mat-form-field>

                <mat-form-field *ngSwitchCase="'app'" formGroupName="query" class="query-field">
                  <mat-label>
                    <mat-icon>apps</mat-icon>
                    Value
                  </mat-label>
                  <mat-select formControlName="value" disableOptionCentering panelClass="dropdown-panel">
                    <mat-option *ngFor="let type of appTypes" [value]="type">
                      {{ type | titlecase }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>

                <mat-form-field *ngSwitchCase="'action'" formGroupName="query" class="query-field">
                  <mat-icon matPrefix>api</mat-icon>
                  <mat-label>Action</mat-label>
                  <input matInput aria-label="Search" type="search" placeholder="Action" formControlName="value" />
                  <admin-input-reset
                    matSuffix
                    [inputControl]="filterForm.get('query.value')"
                    [loading]="loading$ | async"
                  ></admin-input-reset>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </form>

        <div *ngIf="loading$ | async" class="progress-loader">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <table
          mat-table
          multiTemplateDataRows
          [dataSource]="events$ | async"
          class="mat-elevation-z1 data-table multiple-line-tags-padding"
          [class.overlay]="loading$ | async"
        >
          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Timestamp</th>
            <td mat-cell *matCellDef="let event" attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
              {{ event.timestamp | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
            </td>
          </ng-container>

          <ng-container matColumnDef="device info">
            <th mat-header-cell *matHeaderCellDef>
              <admin-tooltip key="events.index.tableHeader.deviceInfo" [displayIcon]="false">
                Device info
              </admin-tooltip>
            </th>
            <td mat-cell *matCellDef="let event">
              <div
                *ngIf="event | deviceInfo: 'exists?'; else noDeviceInfo"
                class="device-icons"
                [ngClass]="{ 'short-tooltip': event | deviceInfo: 'isShortInfo' }"
                [attr.data-tooltip]="event | deviceInfo: 'userAgent'"
              >
                <span [innerHTML]="event | deviceInfo: 'iconsAndFlag'"></span>
              </div>
              <ng-template #noDeviceInfo> No Device Info </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="app">
            <th mat-header-cell *matHeaderCellDef>
              <admin-tooltip key="events.index.tableHeader.app" [displayIcon]="false"> App </admin-tooltip>
            </th>
            <td mat-cell *matCellDef="let event">{{ event.app }}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              <admin-tooltip key="events.index.tableHeader.action" [displayIcon]="false"> Action </admin-tooltip>
            </th>
            <td mat-cell *matCellDef="let event">
              <admin-tooltip [key]="'events.index.table.action?' + event.action" [displayIcon]="false">
                {{ event.action }}
              </admin-tooltip>
            </td>
          </ng-container>

          <ng-container matColumnDef="log type">
            <th mat-header-cell *matHeaderCellDef>
              <admin-tooltip key="events.index.tableHeader.logType" [displayIcon]="false"> Log Type </admin-tooltip>
            </th>
            <td mat-cell *matCellDef="let event">{{ event.logType }}</td>
          </ng-container>

          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>
              <admin-tooltip key="events.index.tableHeader.message" [displayIcon]="false"> Message </admin-tooltip>
            </th>
            <td mat-cell *matCellDef="let event">{{ event.message }}</td>
          </ng-container>

          <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef>
              <admin-tooltip key="events.index.tableHeader.tags" [displayIcon]="false"> Tags </admin-tooltip>
            </th>
            <td mat-cell *matCellDef="let event">
              <mat-chip-listbox aria-label="Tags">
                <mat-chip-option
                  *ngFor="let tag of event.tags"
                  adminHandleExternalRoute
                  [routerLink]="tag.routeLink"
                  [queryParams]="tag.routeParams"
                  [class]="tag.type"
                  [externalRoute]="tag.externalRouteLink"
                >
                  {{ tag.displayPrefix }}: {{ tag.displayValue }}
                  <mat-icon *ngIf="tag.externalRouteLink" class="external-link">open_in_new</mat-icon>
                </mat-chip-option>
              </mat-chip-listbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let event">
              <button (click)="openEventDetails(event)" mat-icon-button class="actions-button">
                <mat-icon>zoom_in</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <ng-container *ngIf="!(loading$ | async)">
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [class.warning-row]="row.logType === 'warn'"
              [class.error-row]="row.logType === 'error'"
            ></tr>
          </ng-container>
        </table>
        <mat-paginator
          class="disable-mat-paginator-range-label"
          [pageSizeOptions]="[20, 50, 100]"
          [pageIndex]="filter.page - 1"
          [pageSize]="filter.query.limit"
          [length]="pageLength$ | async"
          [disabled]="loading$ | async"
          (page)="onPage($event)"
        >
        </mat-paginator>
        <div class="progress-loader table-loading-container" *ngIf="loading$ | async">Loading....</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
