import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { BenefitResult } from '../../types/benefits.type';

export const loadBenefits = createAction('[Memberships] Load Benefits', props<{ userId: string }>());
export const loadBenefitsSuccess = createAction(
  '[Memberships] Load Benefits Success',
  props<{ result: BenefitResult }>()
);
export const loadBenefitsFailure = createAction('[Memberships] Load Benefits Failure', props<ErrorState>());
