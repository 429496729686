import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { PromoCodesFilter } from './promo-codes-filter.type';

export enum PromoCodeStatus {
  Live = 'live',
  Scheduled = 'scheduled',
  Expired = 'expired',
  Paused = 'paused'
}

export const promoCodeStatusDisplayClass = {
  live: 'status-active',
  scheduled: 'promo-code-status-scheduled',
  expired: 'status-neutral',
  paused: 'status-pending'
};

export enum DiscountType {
  'Discount amount' = 'amount',
  'Discount percentage' = 'percentage'
}

export enum LimitUse {
  Single = 'single',
  Multiple = 'multiple'
}

export enum ProductEligibilityTypes {
  Product = 'product_type'
}

export enum ProductEligibilityApplicableTypes {
  brand_gift_card = 'brands',
  brand_merchandise = 'brands',
  transfer_connect_identifier = 'brands',
  product_type = 'products'
}

export enum PredicateTypes {
  IS = '$inc',
  'IS NOT' = '$exc'
}

export type PredicateUnion = `${PredicateTypes}`;

export enum ApplicableProductTypes {
  'Cash Redemption' = 'cash_redemption',
  'Digital Gift Card' = 'gift_card',
  Merchandise = 'merchandise',
  'Physical Gift Card' = 'physical_gift_card',
  'Points Transfer' = 'points_transfer'
}

export interface PromoCodeBannerAttributes {
  title: string;
  subtitle: string;
  buttonCopy: string;
  buttonUrl: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
}

export interface PromoCodeBanner extends PromoCodeBannerAttributes {
  id: string;
  type?: string;
}

export interface PromoCodeBannerRaw {
  id: string;
  type: string;
  attributes: PromoCodeBannerAttributes;
}

export interface Condition {
  attribute: string;
  predicate: PredicateUnion;
  value: string[];
}

export interface ConditionWithValueInSet {
  attribute: string;
  predicate: PredicateUnion;
  value: Set<string>;
}

export interface DiscountOptions {
  cash?: number;
  points: number;
  minCashThreshold?: number;
  minPointsThreshold: number;
}

export interface PromoCodeAttributes {
  id: string;
  code: string;
  suspended: boolean;
  discountType: DiscountType;
  discountOptions: DiscountOptions;
  limitUse: LimitUse;
  quantity: number;
  startAt: string;
  endAt: string;
  createdAt: string;
  productEligibility: {
    conditions: Condition[];
    priority: number;
  };
  userEligibility: {
    userFlag?: {
      customerSegment: string;
    };
  };
  display: boolean;
  description: string;
  ranking: number;
}

export interface PromoCode extends PromoCodeAttributes {
  type: string;
  promoCodeBanner: PromoCodeBanner;
}

export interface Relationship {
  promoCodeBanner: {
    data: {
      id: string;
      type: string;
    };
  };
}

export interface PromoCodeRaw {
  id: string;
  type: string;
  attributes: PromoCodeAttributes;
  relationships: Relationship;
}

export const promoCodeAdapter = createEntityAdapter<PromoCode>();

export interface PromoCodeState extends EntityState<PromoCode>, CallState {
  filter: PromoCodesFilter;
  count: number;
}

export const initialState: PromoCodeState = {
  ...promoCodeAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  count: 0
};
