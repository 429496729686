import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, first, map, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { Params } from '@utils';

import { loadTransactions } from '../store/actions/transactions.actions';
import { transactionsQuery } from '../store/selectors/transactions.selectors';
import { TransactionsFilter, TransactionsState } from '../types';

export const transactionsResolver: ResolveFn<TransactionsFilter> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  scopes: Scopes = inject(Scopes),
  store: Store<TransactionsState> = inject(Store<TransactionsState>)
): Observable<TransactionsFilter> => {
  if (scopes.lack('view_user_transactions')) return of(null);

  const userId: string = Params.find(route, 'userId');

  return combineLatest([
    store.select(transactionsQuery.isTransactionsLoaded(userId)),
    store.select(transactionsQuery.getFilter)
  ]).pipe(
    first(),
    map(([isLoaded, filter]) => {
      if (!isLoaded) {
        filter = new TransactionsFilter();
        store.dispatch(loadTransactions({ userId, filter }));
      }

      return filter;
    })
  );
};
