import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { Role, roleAdapter, RoleState } from '../../types';

const rolesDetector = createDetector<Role>();
const getRoleState = createFeatureSelector<RoleState>('role');

const {
  selectIds,
  selectAll: getRolesList,
  selectEntities: getRolesDictionary
} = roleAdapter.getSelectors(getRoleState);

const {
  isBatchInitial,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError
} = getCallStateSelectors<RoleState>(getRoleState);

const isRolesLoaded = createSelector(
  getRolesDictionary,
  isBatchResting,
  (roles, batchResting) => rolesDetector('allLoaded')(roles) && batchResting
);

const getRoleById = (name: string): Selector<RoleState, Role> =>
  createSelector(getRolesDictionary, entities => entities[name]);

const isRoleLoaded = (name: string): Selector<RoleState, boolean> =>
  createSelector(getRolesDictionary, role => rolesDetector('oneLoaded')(role, name));

const getRoleIds = createSelector(selectIds, roleIds => roleIds as string[]);

export const rolesQuery = {
  getRolesList,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isRolesLoaded,
  isRoleLoaded,
  getRoleById,
  getRolesDictionary,
  getRoleIds
};
