import { DateRange, Timestamp } from '@core/types';
import { ObjectUtils } from '@utils';

export class FilterUtils {
  static appendHashQueryParam(params: object, hashParam: string, key: string, value: string): object {
    const newParams = { ...params };
    if (key && value) {
      newParams[`${hashParam}[${key}]`] = value;
    }
    return newParams;
  }

  static appendDateRange(params: object, dateRange: DateRange, newParamKey: string): object {
    const newParams = { ...params };
    if (dateRange?.from) {
      newParams[`${newParamKey}[from]`] = dateRange.from;
    }
    if (dateRange?.to) {
      newParams[`${newParamKey}[to]`] = dateRange.to;
    }
    return newParams;
  }

  static appendTimestamp(params: object, timestamp: Timestamp): object {
    const newParams = { ...params };
    if (timestamp?.gte) {
      newParams['timestamp[gte]'] = timestamp.gte;
    }
    if (timestamp?.lte) {
      newParams['timestamp[lte]'] = timestamp.lte;
    }
    return newParams;
  }

  // below methods are used for FE filtering
  // dates should be in UTC format
  static isDateAttributeFound(dateFrom: string, dateTo: string, searchValue: string): boolean {
    if (ObjectUtils.isNullish(dateFrom) && ObjectUtils.isNullish(dateTo)) {
      return true;
    }

    const startDate = new Date(dateFrom).setHours(0, 0, 0, 0);
    const compareDate = FilterUtils.setDate(searchValue);
    const endDate = FilterUtils.setDate(dateTo);

    if (ObjectUtils.isNullish(dateFrom) && !ObjectUtils.isNullish(dateTo)) {
      return compareDate <= endDate;
    }

    return compareDate <= endDate && compareDate >= startDate;
  }

  static isAttributeFound(
    searchValue: string,
    attributeData: string,
    option: { hasEmptyValue: boolean } = { hasEmptyValue: false }
  ): boolean {
    if (option.hasEmptyValue) {
      return (
        FilterUtils.isEmptySearch(searchValue, attributeData) ||
        attributeData?.toLowerCase().includes(searchValue?.toLowerCase())
      );
    } else {
      return searchValue === '' || attributeData.toLowerCase() === searchValue?.toLowerCase();
    }
  }

  static setDate(value: string): number {
    return ObjectUtils.isNullish(value)
      ? new Date(9999, 11, 31).setHours(0, 0, 0, 0)
      : new Date(value).setHours(0, 0, 0, 0);
  }

  static isEmptySearch(searchValue: string, attributeData: string): boolean {
    return ObjectUtils.isNullish(searchValue?.trim()) && ObjectUtils.isNullish(attributeData);
  }
}
