import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { DEFAULT_RELATION_OPERATOR, ScopesRelationOperator } from '@core/types';

@Injectable()
export class Scopes extends BehaviorSubject<string[]> {
  constructor(private store: Store<AuthState>) {
    // Initialize with empty array, then read whatever comes from scopes stored in store.
    super([]);
    this.store.select(authQuery.getScopes).subscribe(this);
  }

  hasScopes(
    scopes: string[] | string[][],
    relationOperator: ScopesRelationOperator = DEFAULT_RELATION_OPERATOR
  ): boolean {
    switch (relationOperator) {
      case 'and': {
        return (scopes as string[]).every(scope => this.has(scope));
      }
      case 'or': {
        return (scopes as string[]).some(scope => this.has(scope));
      }
      // [[A and B] or [C and D]]
      case 'any-strict-groups': {
        return (scopes as string[][]).reduce((result, groupScopes) => result || this.hasScopes(groupScopes), false);
      }
      // [[A or B] and [C or D]]
      case 'all-loose-groups': {
        return (scopes as string[][]).reduce((result, groupScopes) => result && this.hasAny(groupScopes), true);
      }
    }
  }

  has(scope: string): boolean {
    const globalScope = scope?.split(':')[0];
    const scopes = this.getValue();

    return scopes?.includes(scope) || scopes?.includes(globalScope);
  }

  hasAny(scopes: string[]): boolean {
    return this.hasScopes(scopes, 'or');
  }

  lack(scope: string): boolean {
    return !this.has(scope);
  }

  lackScopes(scopes: string[]): boolean {
    return scopes.every(scope => this.lack(scope));
  }
}
