import { IframeConfig } from '@kaligo/secure-fields-iframe';

import { mockDecodedUser } from '@core/mocks';
import { CallStateUnion } from '@core/types';
import { StickyColumnConfig } from '@utils';

import {
  CreateUser,
  UnenrollUserResult,
  User,
  UserLoginMode,
  UsersCountResult,
  UsersFilter,
  UsersFilterFormValue,
  UsersResult,
  UserState,
  UserStatus
} from '../types';
import { mockIdentities, mockIdentity } from './identities.mocks';
import { mockMfaIdentities } from './mfa-identities.mocks';

export const mockOtherPii = {
  emails: [
    {
      type: 'default_email',
      value: 'tarsha_walsh@email.com'
    }
  ],
  phones: [
    {
      type: 'mobile_phone',
      value: '09 1905 6298'
    },
    {
      type: 'contact_phone',
      value: '01 4002 4499'
    },
    {
      type: 'home_phone_number',
      value: '02 8926 6463'
    }
  ]
};

export const mockUsers: User[] = [
  {
    // decodedUser attributes
    ...mockDecodedUser,
    id: '1',
    address: {
      country: null,
      formatted: null,
      locality: null,
      postalCode: '1111',
      region: null,
      streetAddress: null
    },
    salutation: 'Mr',
    status: UserStatus.Active,
    loginMode: UserLoginMode.Normal,
    activated: true,
    phoneNumber: '123',
    preferences: {
      currency: null,
      loyaltyCurrency: null,
      pointsAccountId: null,
      productId: '1'
    },
    loyalty_data: {},
    custom: {
      segments: {
        user_segment: 'CARD_SIGNATURE',
        campaign_segments: ['CAMPAIGN_SEGMENT_A', 'CAMPAIGN_SEGMENT_B']
      }
    },
    emailVerified: false,
    phoneNumberVerified: false,
    cards: [
      {
        activatedAt: '2020-02-03 12:00:01',
        statementDate: '2020-03-03',
        cardId: '0123456789',
        accountNumber: '0123456788',
        type: 'infinity',
        status: 'active',
        primary: true,
        active: true,
        custom: {}
      }
    ],
    accounts: [
      {
        accountNumber: '0123456788'
      }
    ],
    identities: [mockIdentity],
    mfaIdentities: mockMfaIdentities,
    otherPii: mockOtherPii,
    createdAt: '2020-01-01 12:00:01',
    updatedAt: '2020-01-01 12:00:01'
  },
  {
    // decodedUser attributes
    ...mockDecodedUser,
    id: '2',
    address: null,
    salutation: 'Mr',
    status: UserStatus.Active,
    loginMode: UserLoginMode.Normal,
    activated: true,
    phoneNumber: '123',
    preferences: null,
    loyalty_data: {},
    custom: {},
    emailVerified: false,
    phoneNumberVerified: false,
    cards: [
      {
        activatedAt: '2020-02-03 12:00:01',
        statementDate: '2020-03-03',
        cardId: '2123456789',
        accountNumber: '0123456788',
        type: 'infinity',
        status: 'active',
        primary: true,
        active: true,
        custom: {}
      }
    ],
    accounts: [
      {
        accountNumber: '2123456788'
      }
    ],
    identities: mockIdentities,
    roles: [
      {
        name: 'ascenda_admin'
      }
    ],
    createdAt: '2020-01-01 12:00:01',
    updatedAt: '2020-01-01 12:00:01'
  }
];

export const mockUser = mockUsers[0];

export const mockFailedUser: User = {
  ...mockDecodedUser,
  id: '',
  status: null,
  salutation: '',
  phoneNumber: '0',
  preferences: {},
  loyalty_data: {},
  custom: {},
  address: null,
  emailVerified: false,
  cards: [],
  accounts: [],
  identities: [],
  firstName: '',
  lastName: '',
  phoneNumberVerified: false,
  createdAt: '',
  updatedAt: ''
};

export const mockCreateUserRequest: CreateUser = {
  partnerUserId: '810000',
  email: 'foo@bar.com',
  status: 'active',
  firstName: 'test',
  lastName: 'test'
};

export const mockUsersResult: UsersResult = {
  data: mockUsers
};

export const mockUpdatedUser: User = {
  ...mockDecodedUser,
  id: '1',
  status: UserStatus.Active,
  loginMode: UserLoginMode.Normal,
  activated: true,
  salutation: 'Mr',
  phoneNumber: '123',
  preferences: {
    currency: null,
    loyaltyCurrency: null,
    pointsAccountId: null,
    productId: '1'
  },
  loyalty_data: {},
  custom: {},
  address: {},
  emailVerified: false,
  phoneNumberVerified: false,
  cards: [
    {
      activatedAt: '2020-02-03 12:00:01',
      statementDate: '2020-03-03',
      cardId: '0123456789',
      accountNumber: '0123456788',
      type: 'infinity',
      status: 'active',
      primary: true,
      active: true,
      custom: {}
    }
  ],
  accounts: [
    {
      accountNumber: '0123456788'
    }
  ],
  identities: mockIdentities,
  mfaIdentities: mockMfaIdentities,
  otherPii: mockOtherPii,
  createdAt: '2020-01-01 12:00:01',
  updatedAt: '2020-01-01 12:00:01'
};

export const mockUsersFilter: UsersFilter = {
  page: 3,
  limit: 20,
  firstName: 'tan',
  lastName: 'john',
  identityUid: 'fab',
  status: UserStatus.Active,
  email: 'email',
  roleId: 'active'
};

export const mockTeamMemberFilter: UsersFilter = {
  status: UserStatus.Admin,
  loginMode: UserLoginMode.Normal,
  providerId: ['email'],
  isTeamMembersView: true,
  page: 1,
  limit: 20
};

export const mockUsersCount: UsersCountResult = {
  count: 10
};

export const mockSecureFieldIframeConfig: IframeConfig = {
  pciProxyBaseUrl: 'https://anz-tokenizer.kaligo-staging.xyz',
  configKey: 'anz-admin-panel-staging-submit',
  parentElementId: 'secure-field',
  version: '0_7_2',
  submitElementId: 'submitBtn',
  matFormFieldClasses: ['slim-form-field', 'enroll-user-component-iframe-field'],
  onTokenize: () => {},
  onError: () => {}
};

const { email, identities, mfaIdentities, ...mockUnenrolledUser }: User = mockUser;

export const mockUnenrollUserResult: UnenrollUserResult = {
  user: {
    ...mockUnenrolledUser,
    activated: false
  },
  deletedIdentityCount: 1,
  deletedPasswordCount: 0,
  deletedMfaCount: 1
};

export function generateUserState(
  users: User[] = [],
  filter: UsersFilter = null,
  callState: CallStateUnion = 'resting'
): UserState {
  return {
    count: null,
    fetchList: [],
    filter,
    ids: users.map(user => user.id),
    entities: users.reduce((dict, user) => {
      dict[user.id] = user;
      return dict;
    }, {}),
    callState: {
      single: callState,
      batch: 'resting'
    }
  };
}

export const mockEnrollmentRequest = {
  accountDetails: {
    pan: 'tokenized 1',
    birthdate: '3/2/1989'
  },
  loginDetails: {
    email: 'john@wick.com',
    emailConfirmation: 'john@wick.com'
  },
  agreements: { digitalRewardsStatement: true }
};

export const mockEnrollAdminRequest = {
  email: 'foo@bar.com',
  firstName: 'foo',
  lastName: 'bar'
};

export const emptyFilterFormValues: UsersFilterFormValue = {
  id: null,
  firstName: null,
  lastName: null,
  identityUid: null,
  partnerUid: null,
  email: null,
  phoneNumber: null,
  roleId: null,
  status: null,
  loginMode: null,
  activated: null,
  accountId: null,
  createdAtDateRange: { createdAtFrom: null, createdAtTo: null },
  updatedAtDateRange: { updatedAtFrom: null, updatedAtTo: null }
};

export const mockStickyStartColumnConfig = {
  isSticky: true,
  isStickyEnd: false,
  headerClass: ['sticky-header-start-border'],
  cellClass: ['sticky-column-start', 'sticky-column-start-border']
};
export const mockStickyColumnConfig = { isSticky: false, isStickyEnd: false, headerClass: [], cellClass: [] };
export const mockStickyEndColumnConfig = {
  isSticky: false,
  isStickyEnd: true,
  cellClass: ['sticky-column-end', 'sticky-column-end-border'],
  headerClass: ['sticky-header-end-border']
};

export const mockUsersColumnConfig: Record<string, StickyColumnConfig> = {
  customerId: mockStickyStartColumnConfig,
  roles: mockStickyColumnConfig,
  email: mockStickyColumnConfig,
  identityId: mockStickyColumnConfig,
  updatedAt: mockStickyColumnConfig,
  state: mockStickyColumnConfig
};
