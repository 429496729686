export class AlfredMetadata {
  total: number;
  totalPages: number;
  currentPage: number;
  allPages: number[];

  constructor() {
    this.total = 0;
    this.totalPages = 0;
    this.currentPage = 0;
    this.allPages = [];
  }
}
