import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';

import { RedemptionRate, redemptionRateAdapter, RedemptionRateState } from '../../types';

const redemptionRatesDetector = createDetector<RedemptionRate>();
const getRedemptionRateState = createFeatureSelector<RedemptionRateState>('redemptionRate');

const { selectAll: getRedemptionRatesList, selectEntities: getRedemptionRatesDictionary } =
  redemptionRateAdapter.getSelectors(getRedemptionRateState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<RedemptionRateState>(getRedemptionRateState);

const getFetchList = getFetchStateSelector<RedemptionRateState>(getRedemptionRateState);

const getFilter = createSelector(getRedemptionRateState, state => state.filter);

const getCount = createSelector(getRedemptionRateState, state => state.count);

const getRedemptionRateById = (id: string): MemoizedSelector<object, RedemptionRate> =>
  createSelector(getRedemptionRatesDictionary, entities => entities[id]);

const isRedemptionRateLoaded = (id: string): MemoizedSelector<object, boolean> =>
  createSelector(getRedemptionRatesDictionary, redemptionRates =>
    redemptionRatesDetector('oneLoaded')(redemptionRates, id)
  );

const isRedemptionRatesLoaded = (productType: string): MemoizedSelector<object, boolean> =>
  createSelector(
    getRedemptionRatesDictionary,
    isBatchResting,
    (entities, batchResting) =>
      batchResting &&
      redemptionRatesDetector('allLoaded')(entities) &&
      Object.values(entities).every((redemptionRate: RedemptionRate) => redemptionRate.productType === productType)
  );

export const redemptionRatesQuery = {
  isBatchLoading,
  isBatchResting,
  isSingleLoading,
  isSingleResting,
  isRedemptionRatesLoaded,
  getBatchError,
  getCount,
  getFetchList,
  getFilter,
  getRedemptionRateById,
  getRedemptionRatesDictionary,
  getRedemptionRatesList,
  isRedemptionRateLoaded,
  getSingleError
};
