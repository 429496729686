import { delay, first, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ActionWithSnackbarMessages } from '@core/types';

import { SuccessActionTypes } from './success-action-types';

@Injectable()
export class SuccessEffects {
  successSubscription: Subscription;
  success$: Observable<Action>;

  constructor(
    private actions: Actions,
    private successActionTypes: SuccessActionTypes,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.successActionTypes.subscribe(actionTypes => {
      if (this.successSubscription) {
        // if new action types are added, unsubscribe and resubscribe with the new settings
        this.successSubscription.unsubscribe();
      }

      this.success$ = this.createSuccessEffect(actionTypes);
      this.successSubscription = this.success$.subscribe();
    });
  }

  private createSuccessEffect(actionTypes: Set<string>): Observable<Action> {
    return this.actions.pipe(
      ofType(...actionTypes),
      delay(1000),
      tap((action: ActionWithSnackbarMessages) => {
        const { snackbarMessages, snackbarDuration } = action;
        const message = snackbarMessages?.default || 'Success!';

        this.openSuccessSnackbar(message, snackbarDuration);
      })
    );
  }

  private openSuccessSnackbar(message: string, snackBarDuration?: number): void {
    const snackbarReference = this.snackBar.open(message, 'Dismiss', {
      panelClass: 'mat-snack-bar-success',
      ...(snackBarDuration !== undefined &&
        snackBarDuration !== null && {
          duration: snackBarDuration
        })
    });
    this.router.events
      .pipe(
        first(event => event instanceof NavigationEnd),
        tap(() => snackbarReference.dismiss())
      )
      .subscribe();
  }
}
