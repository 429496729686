import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'admin-radio-description-group',
  templateUrl: './radio-description-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioDescriptionGroupComponent),
      multi: true
    }
  ]
})
export class RadioDescriptionGroupComponent implements ControlValueAccessor {
  @Input({ required: true }) options: { label: string; value: string; description: string }[];

  formControl = new FormControl();

  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  get isDisabled(): boolean {
    return this.formControl.disabled;
  }

  constructor() {
    this.formControl.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  writeValue(value: string): void {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
}
