import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { LocalesService } from '../../services/locales.service';
import { loadLocales, loadLocalesSuccess, loadLocalesFailure } from '../actions/locales.actions';

@Injectable()
export class LocalesEffects {
  loadLocales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLocales),
      exhaustMap(() =>
        this.localesService.getLocales().pipe(
          map(locales => loadLocalesSuccess({ locales })),
          catchError(error => of(loadLocalesFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private localesService: LocalesService
  ) {}
}
