import { Component } from '@angular/core';

import { UseV2Style } from '@shared/decorators/use-v2-style.decorator';

@UseV2Style
@Component({
  selector: 'admin-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent {}
