import { catchError, exhaustMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { RecommendationsService } from '../../services/recommendations.service';
import {
  loadRecommendedSegments,
  loadRecommendedSegmentsSuccess,
  loadRecommendedCampaignsFailure,
  loadRecommendedCampaigns,
  loadRecommendedCampaignsSuccess,
  loadRecommendedSegmentsFailure,
  hideRecommendation,
  hideRecommendationSuccess,
  hideRecommendationFailure,
  unhideRecommendation,
  unhideRecommendationSuccess,
  unhideRecommendationFailure,
  loadRecommendedCampaignsForSegment,
  loadRecommendedCampaignsForSegmentFailure,
  loadRecommendedCampaignsForSegmentSuccess
} from '../actions/recommendations.actions';

@Injectable()
export class RecommendationsEffects {
  loadRecommendedSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecommendedSegments),
      exhaustMap(() =>
        this.recommendationsService.getRecommendedSegments().pipe(
          map(({ recommendations }) => loadRecommendedSegmentsSuccess({ recommendations })),
          catchError(error => of(loadRecommendedSegmentsFailure({ error })))
        )
      )
    )
  );

  loadRecommendedCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecommendedCampaigns),
      exhaustMap(() =>
        this.recommendationsService.getRecommendedCampaigns().pipe(
          map(({ recommendations }) => loadRecommendedCampaignsSuccess({ recommendations })),
          catchError(error => of(loadRecommendedCampaignsFailure({ error })))
        )
      )
    )
  );

  loadRecommendedCampaignsForSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecommendedCampaignsForSegment),
      exhaustMap(({ id }) =>
        this.recommendationsService.getRecommendedCampaignsForSegment(id).pipe(
          map(({ recommendations }) => loadRecommendedCampaignsForSegmentSuccess({ recommendations })),
          catchError(error => of(loadRecommendedCampaignsForSegmentFailure({ error })))
        )
      )
    )
  );

  hideRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hideRecommendation),
      exhaustMap(({ id, recommendationType }) =>
        this.recommendationsService.hideRecommendation(id).pipe(
          map(() => hideRecommendationSuccess(recommendationType)),
          catchError(error => of(hideRecommendationFailure(error, recommendationType)))
        )
      )
    )
  );

  unhideRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unhideRecommendation),
      exhaustMap(({ id, recommendationType }) =>
        this.recommendationsService.unhideRecommendation(id).pipe(
          map(() => unhideRecommendationSuccess(recommendationType)),
          catchError(error => of(unhideRecommendationFailure(error, recommendationType)))
        )
      )
    )
  );

  hideUnhideRecommendationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hideRecommendationSuccess, unhideRecommendationSuccess),
      map(({ recommendationType }) => {
        return recommendationType === 'segment' ? loadRecommendedSegments() : loadRecommendedCampaigns();
      })
    )
  );

  constructor(
    private actions$: Actions,
    private recommendationsService: RecommendationsService
  ) {}
}
