import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { AccessPolicy, CallState } from '@core/types';

// re-export these types, so other files can refer to this file instead of just core

export const accessPolicyAdapter = createEntityAdapter<AccessPolicy>();

export interface AccessPolicyState extends EntityState<AccessPolicy>, CallState {}

export const initialState: AccessPolicyState = accessPolicyAdapter.getInitialState<CallState>(initialCallState);

export { AccessPolicyTarget, AccessPolicy } from '@core/types';
