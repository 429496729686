import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  isMini: boolean;

  toggleIsMini(): void {
    this.isMini = !this.isMini;
  }

  open(): void {
    this.isMini = false;
  }

  close(): void {
    this.isMini = true;
  }
}
