import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { auditLogAdapter, AuditLogState, initialState } from '../../types';
import {
  loadAuditLog,
  loadAuditLogFailure,
  loadAuditLogs,
  loadAuditLogsFailure,
  loadAuditLogsSuccess,
  loadAuditLogSuccess
} from '../actions/audit-logs.actions';

export const auditLogTriggers: Required<EntityTriggers> = {
  single: {
    loading: [loadAuditLog.type],
    resting: [loadAuditLogSuccess.type],
    erroring: [loadAuditLogFailure.type]
  },
  batch: {
    loading: [loadAuditLogs.type],
    resting: [loadAuditLogsSuccess.type],
    erroring: [loadAuditLogsFailure.type]
  }
};

export function auditLogsReducer(state: AuditLogState = initialState, action: Action): AuditLogState {
  return callStateReducer(baseReducer, auditLogTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadAuditLogs, (state, { filter }) => ({ ...state, filter })),
  on(loadAuditLogsSuccess, (state, { result }) => ({
    ...auditLogAdapter.setAll(result.data, state),
    count: result?.metadata?.total || 0
  })),
  on(loadAuditLogSuccess, (state, { auditLog }) => ({ ...auditLogAdapter.upsertOne(auditLog, state), count: 1 }))
);
