export interface AccessPolicy {
  id: string;
  controller: string;
  action: string;
  settings: AccessPolicySettings;
  target?: AccessPolicyTarget;
  description?: string;
}

// the snake_case-version of targets, as we send it to MC
export type AccessPolicyTarget = 'request_validation' | 'request_extension' | 'response' | 'frontend' | 'notification';

// the camelCase-version of targets, as we receive it from MC
export type AdminPanelAccessPolicyTarget =
  | 'requestValidation'
  | 'requestExtension'
  | 'response'
  | 'frontend'
  | 'notification';

export enum RequestValidationTypes {
  Allow = 'allow',
  Reject = 'reject'
}

export interface AccessPolicySettings {
  [key: string]: any;
  attributes?: AttributesSettings;
  meta?: MetaSettings;
}

export type BooleanPolicySettings = Record<string, boolean>;

export class AttributesSettings {
  [key: string]: boolean | AttributesSettings | RequestValidationSettings;
}

export class MetaSettings {
  otherAttributes: RequestValidationTypes;
}

export class RequestValidationSettings {
  includes?: string[];
  eq?: any;
  gte?: number;
  lte?: number;
}
