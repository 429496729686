import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { LoginAttemptsFilter } from '../../types/login-attempts-filter.type';
import { LoginAttemptsResult } from '../../types/login-attempts-result.type';
import { LoginAttempt } from '../../types/login-attempts.type';

export const loadLoginAttempts = createAction(
  '[Login Attempts] Load Login Attempts',
  props<{ filter: LoginAttemptsFilter }>()
);
export const loadLoginAttemptsSuccess = createAction(
  '[Login Attempts] Load Login Attempts Success',
  props<{ result: LoginAttemptsResult }>()
);
export const loadLoginAttemptsFailure = createAction(
  '[Login Attempts] Load Login Attempts Failure',
  props<ErrorState>()
);

export const loadLoginAttempt = createAction('[Login Attempts] Load Login Attempt', props<{ id: string }>());
export const loadLoginAttemptSuccess = createAction(
  '[Login Attempts] Load Login Attempt Success',
  props<{ loginAttempt: LoginAttempt }>()
);
export const loadLoginAttemptFailure = createAction('[Login Attempts] Load Login Attempt Failure', props<ErrorState>());
