import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';
import { getLocaleStateSelectors } from '@core/store/locale-state';

import {
  LoyaltyProgram,
  loyaltyProgramAdapter,
  LoyaltyProgramCategory,
  LoyaltyProgramState
} from '../../types/loyalty-programs.type';

const loyaltyProgramsDetector = createDetector<LoyaltyProgram>();
const getLoyaltyProgramState = createFeatureSelector<LoyaltyProgramState>('loyaltyProgram');

const { selectAll: getLoyaltyProgramsList, selectEntities: getLoyaltyProgramsDictionary } =
  loyaltyProgramAdapter.getSelectors(getLoyaltyProgramState);

const {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError
} = getCallStateSelectors<LoyaltyProgramState>(getLoyaltyProgramState);

const { getBatchLocale, getSingleLocale } = getLocaleStateSelectors<LoyaltyProgramState>(getLoyaltyProgramState);

const getFetchList = getFetchStateSelector<LoyaltyProgramState>(getLoyaltyProgramState);

const isLoyaltyProgramsLoaded = createSelector(
  getLoyaltyProgramsDictionary,
  isBatchResting,
  (loyaltyPrograms, batchResting) => loyaltyProgramsDetector('allLoaded')(loyaltyPrograms) && batchResting
);

const getLoyaltyProgramById = (id: string): Selector<LoyaltyProgramState, LoyaltyProgram> =>
  createSelector(getLoyaltyProgramsDictionary, entities => entities[id]);

const getProcessingDelayById = (id: string): Selector<LoyaltyProgramState, number> =>
  createSelector(getLoyaltyProgramById(id), (loyaltyProgram: LoyaltyProgram) => loyaltyProgram?.processingDelay);

const getCount = createSelector(getLoyaltyProgramState, (state: LoyaltyProgramState) => state.count);

const getFilter = createSelector(getLoyaltyProgramState, (state: LoyaltyProgramState) => state.filter);

const getActiveAirlinePrograms = createSelector(getLoyaltyProgramsList, (loyaltyPrograms: LoyaltyProgram[]) =>
  loyaltyPrograms.filter(program => program.category === LoyaltyProgramCategory.Airline && program.active)
);

export const loyaltyProgramsQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getFetchList,
  getLoyaltyProgramsList,
  getLoyaltyProgramsDictionary,
  getLoyaltyProgramById,
  getProcessingDelayById,
  isLoyaltyProgramsLoaded,
  isSingleLoading,
  isSingleResting,
  isSingleInitial,
  getSingleError,
  getCount,
  getFilter,
  getBatchLocale,
  getSingleLocale,
  getActiveAirlinePrograms
};
