import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, NydusNetworkParamConverter, NydusNetworkParamsConfig, ObjectUtils } from '@utils';

import { TenantLocale } from '../../../tenant-locales/types';
import {
  PointsActivitiesFilter,
  PointsActivitiesRawResult,
  PointsActivitiesResult,
  PointsActivity,
  PointsActivityRawData,
  PointsActivityRawResult
} from '../../types';

@Injectable({
  providedIn: 'root'
})
export class PointsActivitiesService {
  constructor(
    private missionControlService: MissionControlService,
    @Inject('timezoneOffset') public timezoneOffset: number
  ) {}

  getPointsActivities(
    userId: string,
    filter: PointsActivitiesFilter,
    locale: string,
    tenantLocales?: TenantLocale[]
  ): Observable<PointsActivitiesResult> {
    const paramsConfig: NydusNetworkParamsConfig = {
      filter: [
        { key: 'pointsAccountId', operator: '$eq' },
        { key: 'category', operator: '$eq' },
        { key: 'transactionTime', type: 'date-range' }
      ],
      search: ['description']
    };

    const params = NydusNetworkParamConverter.convertToNydusNetworkParam(filter, paramsConfig, this.timezoneOffset);

    return this.missionControlService
      .get<PointsActivitiesRawResult>(
        `users/${userId}/points_transactions`,
        new HttpParams({
          fromObject: {
            ...ObjectUtils.prepareQueryObject(ObjectUtils.sanitizeRequestObject(params))
          },
          encoder: new BracketParamsEncoder()
        }),
        this.missionControlService.buildLocaleHeader(locale, tenantLocales)
      )
      .pipe(
        map(results => ({
          data: results.data.map(this.formatPointsActivityResult),
          meta: results.meta
        }))
      );
  }

  getPointsActivity(
    userId: string,
    pointsActivityId: string,
    locale: string,
    tenantLocales?: TenantLocale[]
  ): Observable<PointsActivity> {
    return this.missionControlService
      .get<PointsActivityRawResult>(
        `users/${userId}/points_transactions/${pointsActivityId}`,
        null,
        this.missionControlService.buildLocaleHeader(locale, tenantLocales)
      )
      .pipe(map(result => this.formatPointsActivityResult(result.data)));
  }

  private formatPointsActivityResult(rawData: PointsActivityRawData): PointsActivity {
    const { attributes, relationships, type, ...rest } = rawData;
    return {
      ...rest,
      ...attributes,
      pointsStatement: relationships.pointsStatement,
      orderId: relationships.order?.data?.id
    };
  }
}
