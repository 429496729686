import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { fetchStateReducer } from '@core/store/fetch-state';
import { EntityTriggers, FetchListTriggers } from '@core/types';

import { initialOfferState, offerAdapter, OfferState } from '../../types';
import {
  createOffer,
  createOfferFailure,
  createOfferSuccess,
  fetchOffer,
  fetchOfferFailure,
  fetchOfferSuccess,
  loadOffer,
  loadOfferFailure,
  loadOffers,
  loadOffersFailure,
  loadOffersSuccess,
  loadOfferSuccess,
  loadUserOffers,
  loadUserOffersFailure,
  loadUserOffersSuccess,
  updateOffer,
  updateOfferFailure,
  updateOfferSuccess,
  uploadOfferImage,
  uploadOfferImageFailure,
  uploadOfferImageSuccess
} from '../actions/offers.actions';

export const offerTriggers: EntityTriggers = {
  single: {
    loading: [loadOffer.type, createOffer.type, updateOffer.type, uploadOfferImage.type],
    resting: [loadOfferSuccess.type, createOfferSuccess.type, updateOfferSuccess.type, uploadOfferImageSuccess.type],
    erroring: [loadOfferFailure.type, createOfferFailure.type, updateOfferFailure.type, uploadOfferImageFailure.type]
  },
  batch: {
    loading: [loadOffers.type, loadUserOffers.type],
    resting: [loadOffersSuccess.type, loadUserOffersSuccess.type],
    erroring: [loadOffersFailure.type, loadUserOffersFailure.type]
  }
};

export const offerFetchListTriggers: FetchListTriggers = {
  loading: [fetchOffer.type],
  resting: [fetchOfferSuccess.type],
  erroring: [fetchOfferFailure.type]
};

export function offersReducer(state: OfferState = initialOfferState, action: Action): OfferState {
  return {
    ...callStateReducer(baseReducer, offerTriggers)(state, action),
    ...fetchStateReducer(offerFetchListTriggers, 'offer')(state, action)
  };
}

const baseReducer = createReducer(
  initialOfferState,
  on(fetchOfferSuccess, (state, { offer }) => offerAdapter.upsertOne(offer, state)),
  on(loadOffer, (state, { filter }) => ({ ...state, filter: filter ?? state.filter })),
  on(loadOffers, loadUserOffers, (state, { filter }) => ({ ...state, filter })),
  on(loadOfferSuccess, (state, { offer }) => ({
    ...offerAdapter.setAll([offer], state),
    count: 1
  })),
  on(loadOffersSuccess, loadUserOffersSuccess, (state, { result }) => ({
    ...offerAdapter.setAll(result.data, state),
    count: result?.meta.total || 0
  })),
  on(updateOfferSuccess, (state, { offer }) => offerAdapter.updateOne({ id: offer.id, changes: offer }, state))
);
