import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadUserCampaigns } from '../store/actions/campaigns.actions';
import { CampaignState } from '../types';

export const userCampaignsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  scopes: Scopes = inject(Scopes),
  store: Store<CampaignState> = inject(Store<CampaignState>)
): Observable<void> => {
  if (scopes.lackScopes(SCOPES_OR.viewUserCampaigns)) {
    return of(null);
  }

  const userId = Params.find(route, 'userId');
  store.dispatch(loadUserCampaigns({ userId }));
};
