import { createSelector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { PointsAccount, pointsAccountAdapter, PointsAccountState } from '../../types';
import { DashboardState, getDashboardState } from '../reducers';

const pointsAccountsDetector = createDetector<PointsAccount>();

const getPointsAccountState = createSelector(getDashboardState, (state: DashboardState) => state.pointsAccount);

const { selectAll: getPointsAccountsList, selectEntities: getPointsAccountsDictionary } =
  pointsAccountAdapter.getSelectors(getPointsAccountState);

const { isBatchInitial, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<PointsAccountState>(getPointsAccountState);

const isPointsAccountsLoaded = createSelector(
  getPointsAccountsDictionary,
  isBatchResting,
  (pointsAccounts, batchResting) => pointsAccountsDetector('allLoaded')(pointsAccounts) && batchResting
);

const getSelectedPointsAccountId = createSelector(
  getPointsAccountState,
  (state: PointsAccountState) => state.selectedId
);

const getSelectedPointsAccount = createSelector(
  getPointsAccountsList,
  getSelectedPointsAccountId,
  (accounts, selectedId) => accounts?.find(account => account.id === selectedId)
);

export const pointsAccountsQuery = {
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getPointsAccountsList,
  getPointsAccountsDictionary,
  isPointsAccountsLoaded,
  getSelectedPointsAccountId,
  getSelectedPointsAccount
};
