var IOSWebBridge = /** @class */ (function () {
    function IOSWebBridge() {
    }
    IOSWebBridge.callHandler = function (name, params, callback) {
        this.setupWebViewJavascriptBridge(function (bridge) {
            bridge.callHandler(name, params, callback);
        });
    };
    IOSWebBridge.setupWebViewJavascriptBridge = function (callback) {
        if (window.WebViewJavascriptBridge) {
            return callback(window.WebViewJavascriptBridge);
        }
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }
        window.WVJBCallbacks = [callback];
        this.createIframe('wvjbscheme://__bridge_loaded__');
    };
    IOSWebBridge.createIframe = function (src) {
        var iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = src;
        document.documentElement.appendChild(iframe);
        setTimeout(function () {
            document.documentElement.removeChild(iframe);
        }, 0);
    };
    return IOSWebBridge;
}());
export { IOSWebBridge };
