import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, ObjectUtils } from '@utils';

import { KeysFilter } from '../types/keys-filter.type';
import { Key, NewKey, PrivateKey } from '../types/keys.type';

@Injectable({
  providedIn: 'root'
})
export class KeysService {
  constructor(private missionControlService: MissionControlService) {}

  getKey(id: string): Observable<Key> {
    return this.missionControlService.get<Key>(`keys/${id}`);
  }

  getKeys(filter: KeysFilter): Observable<Key[]> {
    filter = ObjectUtils.sanitizeRequestObject<KeysFilter>(filter);

    const params = ObjectUtils.prepareQueryObject(filter);

    return this.missionControlService.get<Key[]>(
      'keys',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  getPrivateKey(id: string): Observable<PrivateKey> {
    return this.missionControlService.get<PrivateKey>(`keys/${id}/private_keys`);
  }

  createKey(newKey: NewKey): Observable<Key> {
    newKey = ObjectUtils.sanitizeRequestObject<NewKey>(newKey);
    return this.missionControlService.post<Key>('keys', newKey);
  }
}
