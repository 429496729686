import { filter, take, takeWhile } from 'rxjs/operators';

import { DialogRef } from '@angular/cdk/dialog';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { Observable, Subject, merge } from 'rxjs';

import { DrawerContainerComponent } from '@shared/components/drawer-container/drawer-container.component';

import { DrawerConfig } from '../../types/drawer.type';

/**
 * This will act as a proxy between our component and the drawer component.
 * It will be responsible for most of the interactions with the drawer component.
 * We also can listen to the drawer events.
 */
export class DrawerRef {
  private drawerOpened = new Subject<void>();
  private drawerCloseStarted = new Subject<void>();

  constructor(
    private cdkDialogRef: DialogRef,
    private config: DrawerConfig,
    private drawerContainer: DrawerContainerComponent
  ) {
    if (!this.config.disableClose) {
      this.subscribeToBackdropCloseEvent();
    }

    this.subscribeToOpenedEvent();
  }

  get closeStarted$(): Observable<void> {
    return this.drawerCloseStarted.asObservable();
  }

  get opened$(): Observable<void> {
    return this.drawerOpened.asObservable();
  }

  get closed$(): DialogRef['closed'] {
    return this.cdkDialogRef.closed;
  }

  get backdropClick$(): DialogRef['backdropClick'] {
    return this.cdkDialogRef.backdropClick;
  }

  get keydownEvents$(): DialogRef['keydownEvents'] {
    return this.cdkDialogRef.keydownEvents;
  }

  subscribeToOpenedEvent(): void {
    this.drawerContainer.drawerAnimationEvent
      .pipe(
        filter(event => event.toState === 'opened' && event.phaseName === 'done'),
        take(1)
      )
      .subscribe(() => {
        this.drawerOpened.next();
        this.drawerOpened.complete();
      });
  }

  subscribeToBackdropCloseEvent(): void {
    merge(
      this.backdropClick$,
      this.keydownEvents$.pipe(filter(event => event.key === 'Escape' && !hasModifierKey(event)))
    )
      .pipe(take(1))
      .subscribe(() => {
        this.close();
      });
  }

  /** Close the drawer. We can close the drawer
   *  with or without a result value same as the dialog.
   */
  close(dialogResult?: any): void {
    // We will listen to the animation done event and close the dialog.
    this.drawerContainer.drawerAnimationEvent
      .pipe(
        filter(event => event.toState === 'closed'),
        takeWhile(event => event.phaseName !== 'done', true)
      )
      .subscribe(event => {
        // We will remove the backdrop when the animation starting.
        if (event.phaseName === 'start') {
          this.cdkDialogRef.overlayRef.detachBackdrop();
        } else {
          // And we will close the dialog once the animation is done.
          this.cdkDialogRef.close(dialogResult);
        }
      });

    // We will start the drawer close animation.
    // This will trigger the animation done event from the drawerContainer.
    this.drawerContainer.startDrawerCloseAnimation();

    // Emit the close started event.
    this.drawerCloseStarted.next();
    this.drawerCloseStarted.complete();
  }
}
