import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Nullable } from '@shared/types';

export interface AllowedOriginState extends CallState {
  allowedOrigins: Nullable<string[]>;
}

export const initialState: AllowedOriginState = {
  ...initialCallState,
  allowedOrigins: null
};
