export class FileProcessingRequestsFilter {
  constructor(
    public pickupZone: string = null,
    public status: string = null,
    public createdAt: string = null,
    public id: string = null,
    public description: string = null,
    public parameters: string = null
  ) {}
}
