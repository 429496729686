<div class="details-view">
  <div class="section-header">General</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Order ID">{{ orderItem.order.id }}</admin-item>
    <admin-item label="Item ID">{{ orderItem.id }}</admin-item>
    <admin-item label="Product type">{{ orderItem.type | orderItemType }}</admin-item>
    <admin-item label="Order initiator">{{
      (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A'
    }}</admin-item>
    <admin-item label="Amount received"
      >{{ data.currency }}{{ data.currency === '$' ? '' : ' ' }}{{ data.amount | number }}</admin-item
    >
    <admin-item label="Amount transferred"
      >{{ orderItem.pointsPaid | number }} {{ data.tenantCurrencyShortName }}</admin-item
    >
    <admin-item label="Original amount transferred"
      >{{ orderItem.pointsAmount | number }} {{ data.tenantCurrencyShortName }}</admin-item
    >
    <admin-item label="Promo code">{{ data.promoCode || 'N/A' }}</admin-item>

    <div class="cross-two-column unpadded-subsection-header">Customer details</div>
    <admin-item class="cross-two-column" label="Customer ID">{{ customerId }}</admin-item>
  </div>

  <div class="section-header">Account information</div>
  <div class="section admin-item-wrapper">
    <admin-item label="First name">{{ (data?.membership?.firstName | titlecase) || 'N/A' }}</admin-item>
    <admin-item label="Last name">{{ (data?.membership?.lastName | titlecase) || 'N/A' }}</admin-item>
    <admin-item label="Account name">{{ data?.membership?.enrollment?.groupName || 'N/A' }}</admin-item>
    <admin-item label="Card number">{{ data?.membership?.number }}</admin-item>
  </div>

  <div class="section-header">Stripe reference</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Stripe reference">{{ stripeReferenceData.stripeReference }}</admin-item>
    <admin-item label="Total charge">{{ stripeReferenceData.totalCharge }}</admin-item>
  </div>
</div>
