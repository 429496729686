import { SCOPES_OR } from '@core/types';
import { NavItemV2 } from '@core/types/nav-item-v2.type';

const analyticsGroup: NavItemV2 = {
  id: 'analytics',
  type: 'submenu',
  label: 'Analytics',
  iconName: 'chart-arrow',
  scopes: ['view_analytics'],
  children: [
    {
      id: 'user',
      type: 'analytics',
      label: 'User',
      route: '/analytics/user',
      dashboardIdKey: 'userDashboardId',
      checkFeatureAvailability: true
    },
    {
      id: 'customerValue',
      type: 'analytics',
      label: 'Customer Value',
      route: '/analytics/customer-value',
      dashboardIdKey: 'customerValueDashboardId',
      checkFeatureAvailability: true
    },
    {
      id: 'redemption',
      type: 'analytics',
      label: 'Redemption',
      route: '/analytics/redemption',
      dashboardIdKey: 'redemptionDashboardId',
      checkFeatureAvailability: true
    },
    {
      id: 'engagement',
      type: 'analytics',
      label: 'Engagement',
      route: '/analytics/engagement',
      dashboardIdKey: 'engagementDashboardId',
      checkFeatureAvailability: true
    },
    {
      id: 'programPerformance',
      type: 'analytics',
      label: 'Program performance',
      route: '/analytics/program-performance',
      dashboardIdKey: 'programPerformanceDashboardId',
      checkFeatureAvailability: true
    },
    {
      id: 'campaign',
      type: 'analytics',
      label: 'Campaign',
      route: '/analytics/campaign',
      dashboardIdKey: 'campaignDashboardId',
      checkFeatureAvailability: true
    }
  ]
};

const marketingCentralGroup: NavItemV2 = {
  id: 'marketingCentre',
  type: 'submenu',
  label: 'Marketing',
  iconName: 'megaphone',
  children: [
    {
      id: 'segments',
      type: 'link',
      label: 'Segments',
      route: '/segments',
      scopes: ['view_segments']
    },
    {
      id: 'tenantCampaigns',
      type: 'link',
      label: 'Campaigns',
      route: '/campaigns',
      scopes: ['view_tenant_campaigns'],
      checkFeatureAvailability: true
    },
    {
      id: 'promotions',
      type: 'link',
      label: 'Proposals',
      route: '/promotions',
      scopes: ['view_campaign_proposals'],
      checkFeatureAvailability: true
    }
  ]
};

const fraudServicesGroup: NavItemV2 = {
  id: 'fraudServices',
  type: 'submenu',
  label: 'Fraud services',
  iconName: 'lock',
  children: [
    {
      id: 'fraudLists',
      type: 'link',
      label: 'Fraud Settings',
      route: '/fraud-lists',
      scopes: ['fraud_lists:index']
    },
    {
      id: 'fraudDetection',
      type: 'link',
      label: 'Fraud Detection',
      route: '/fraud-monitoring',
      scopes: ['view_fraud_analytics']
    },
    {
      id: 'fraudRules',
      type: 'link',
      label: 'Fraud Rules',
      route: '/fraud-rules',
      scopes: ['view_fraud_rules']
    },
    {
      id: 'watchlists',
      type: 'link',
      label: 'Watchlists',
      route: '/watchlists',
      scopes: SCOPES_OR.viewWatchlists,
      relationOperator: 'or'
    }
  ]
};

export const programManagementNavItems: NavItemV2[] = [
  analyticsGroup,
  {
    id: 'customers',
    type: 'link',
    iconName: 'users',
    label: 'Customers',
    route: '/customers',
    scopes: SCOPES_OR.viewCustomers,
    relationOperator: 'or'
  },
  {
    id: 'orders',
    type: 'link',
    label: 'Orders',
    iconName: 'shopping-cart-01',
    route: '/orders-items',
    scopes: SCOPES_OR.viewOrderItems,
    relationOperator: 'or',
    checkFeatureAvailability: true
  },
  marketingCentralGroup
];
