import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { segmentMemberAdapter, segmentMembersInitialState, SegmentMemberState } from '../../types';
import {
  addSegmentMember,
  addSegmentMemberFailure,
  addSegmentMemberSuccess,
  loadSegmentMembers,
  loadSegmentMembersFailure,
  loadSegmentMembersSuccess,
  updateSegmentMember,
  updateSegmentMemberFailure,
  updateSegmentMemberSuccess
} from '../actions/segment-members.actions';

export const segmentMemberTriggers: EntityTriggers = {
  single: {
    loading: [addSegmentMember.type, updateSegmentMember.type],
    resting: [addSegmentMemberSuccess.type, updateSegmentMemberSuccess.type],
    erroring: [addSegmentMemberFailure.type, updateSegmentMemberFailure.type]
  },
  batch: {
    loading: [loadSegmentMembers.type],
    resting: [loadSegmentMembersSuccess.type],
    erroring: [loadSegmentMembersFailure.type]
  }
};

export function segmentMembersReducer(
  state: SegmentMemberState = segmentMembersInitialState,
  action: Action
): SegmentMemberState {
  return callStateReducer(baseReducer, segmentMemberTriggers)(state, action);
}

const baseReducer = createReducer(
  segmentMembersInitialState,
  on(loadSegmentMembers, (state, { segmentId, filter }) => ({ ...state, selectedSegmentId: segmentId, filter })),
  on(loadSegmentMembersSuccess, (state, { members, total }) => ({
    ...segmentMemberAdapter.setAll(members, state),
    total
  })),
  on(addSegmentMemberSuccess, (state, { segmentMember }) => ({
    ...segmentMemberAdapter.upsertOne(segmentMember, state),
    total: state.total + 1
  })),
  on(updateSegmentMemberSuccess, (state, { segmentMember }) =>
    segmentMemberAdapter.updateOne({ id: segmentMember.id, changes: segmentMember }, state)
  )
);
