import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { eventAdapter, EventState } from '../../types/events.type';

const getEventState = createFeatureSelector<EventState>('event');

const { selectAll: getEventsList } = eventAdapter.getSelectors(getEventState);

const { isBatchInitial, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<EventState>(getEventState);

const getFilter = createSelector(getEventState, eventState => eventState.filter);

const isLastPage = createSelector(getEventState, (state: EventState) => state.lastPage);

export const eventsQuery = {
  getEventsList,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  isLastPage,
  getFilter
};
