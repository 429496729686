import { HermesError, RefreshTokenExchangeResult } from '@kaligo/hermes';
import { Action, createAction, props } from '@ngrx/store';

import { GuardhouseErrorCodes } from '@core/errors/error-codes.type';
import { TransactionAppState } from '@core/services/hermes/hermes.service';
import { ApprovalRequestedAction } from '@core/store/interrupted/reducers/interrupted.reducer';
import { DecodedUser, HttpError } from '@core/types';

export const handleLoginRedirect = createAction('[Auth] Handle Login Redirect');

export const startTokenRefresh = createAction(
  '[Auth] Start Token Refresh',
  props<{ transactionAppState: TransactionAppState }>()
);
export const loadTokenFail = createAction('[Auth] Load Token Fail', props<{ error: HermesError }>());

export const startSessionCheck = createAction('[Auth] Start Session Check');
export const sessionCheckFail = createAction('[Auth] Session Check Fail', props<{ error: HermesError }>());

export const startIdleCheck = createAction('[Auth] Start Idle Check');

export const loadTokenSuccess = createAction(
  '[Auth] Load Token Success',
  props<{ tokenResult: RefreshTokenExchangeResult; transactionAppState?: TransactionAppState }>()
);
export const loginUser = createAction('[Auth] Login User', props<{ user: DecodedUser }>());
export const loginFail = createAction('[Auth] Login Fail');

export const logoutUser = createAction(
  '[Auth] Logout User',
  (payload: { reason: GuardhouseErrorCodes } = { reason: GuardhouseErrorCodes.UNINTENTIONAL_LOGOUT }) => payload
);
export const logoutUserComplete = createAction('[Auth] Logout User Complete');

export const sessionExpiredFail = createAction('[Auth] Session Expired Fail', props<{ error: HermesError }>());

// Covers all other token refresh/session check failure cases:
// - Invalid Guardhouse endpoint params
// - Invalid Hermes input params
// - Invalid response state/token/session in Hermes
export const loadTokenOtherFail = createAction('[Auth] Load Token Other Fail', props<{ error: HermesError }>());
export const sessionCheckOtherFail = createAction('[Auth] Session Check Other Fail', props<{ error: HermesError }>());

export const noPermissionFail = createAction('[Auth] No Permission Fail');
export const insufficientLevelFail = createAction(
  '[Auth] Insufficient Level Fail',
  props<{ error: HttpError; stepUpAction: Action }>()
);
export const initiateStepUp = createAction(
  '[Auth] Initiate Step Up',
  props<{ error: HttpError; stepUpAction: Action }>()
);

export const approvalRequestedFail = createAction(
  '[Auth] Approval Requested Fail',
  props<{ approvalRequestedAction: ApprovalRequestedAction }>()
);
