import { Directive, HostListener, inject, Input } from '@angular/core';

import { AmplitudeService } from '../../../services/amplitude/amplitude.service';

@Directive({ selector: '[adminAmplitudeClickEvent]' })
export class AmplitudeClickEventDirective {
  amplitudeService = inject(AmplitudeService);

  @Input() adminAmplitudeClickEvent: string;

  @HostListener('click')
  onClick(): void {
    this.amplitudeService.track(this.adminAmplitudeClickEvent);
  }
}
