import { first, map } from 'rxjs/operators';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Params } from '@utils';

import { loadLoginAttempts } from '../store/actions/login-attempts.actions';
import { loginAttemptsQuery } from '../store/selectors/login-attempts.selectors';
import { LoginAttemptsFilter } from '../types/login-attempts-filter.type';
import { LoginAttemptState } from '../types/login-attempts.type';

export const userLoginAttemptsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<LoginAttemptState> = inject(Store<LoginAttemptState>),
  scopes: Scopes = inject(Scopes)
): Observable<void> => {
  if (scopes.lackScopes(SCOPES_OR.viewLoginAttempts)) {
    return of(null);
  }

  return store.pipe(
    select(loginAttemptsQuery.getFilter),
    first(),
    map(existingFilter => {
      let filter: LoginAttemptsFilter;
      const userId = Params.find(route, 'userId');

      filter =
        existingFilter?.isFromUserView && existingFilter.userId === userId
          ? existingFilter
          : new LoginAttemptsFilter({ isFromUserView: true, userId });
      store.dispatch(loadLoginAttempts({ filter }));
    })
  );
};
