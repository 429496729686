import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { ApiSettingsTokenState, initialTokenState } from '../../types/api-settings-token.type';
import {
  createApiSettingsToken,
  createApiSettingsTokenFailure,
  createApiSettingsTokenSuccess
} from '../actions/api-settings-token.actions';

export const apiSettingsTokenTriggers: EntityTriggers = {
  single: {
    loading: [createApiSettingsToken.type],
    resting: [createApiSettingsTokenSuccess.type],
    erroring: [createApiSettingsTokenFailure.type]
  }
};

export function apiSettingsTokenReducer(
  state: ApiSettingsTokenState = initialTokenState,
  action: Action
): ApiSettingsTokenState {
  return callStateReducer(baseReducer, apiSettingsTokenTriggers)(state, action);
}

export const baseReducer = createReducer(
  initialTokenState,
  on(createApiSettingsTokenSuccess, (state, { apiSettingsToken }) => ({ ...state, apiSettingsToken }))
);
