<div class="conditions-summary-container" data-testid="segment-review-conditions">
  @if (data.hasOwnProperty('conditions') && data.conditions.length > 0) {
    <div class="condition-row">
      <div class="condition-connector">
        <span>{{ data.connector | uppercase }}</span>
      </div>
      <div class="condition-row-nesting-container">
        @for (condition of data.conditions; track $index) {
          <div class="condition-row-nesting">
            <ng-container
              [ngTemplateOutlet]="conditionNode"
              [ngTemplateOutletContext]="{
                conditionItem: condition,
                parentNodeName: condition.attribute | logicRuleCategoryKey,
                parentNodeValue: null,
                connector: null
              }"
            >
            </ng-container>
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="conditions-summary-no-content">No conditions added</div>
  }
</div>

<ng-template
  #conditionNode
  let-conditionItem="conditionItem"
  let-parentNodeName="parentNodeName"
  let-parentNodeValue="parentNodeValue"
  let-connector="connector"
>
  @if (conditionItem | logicRuleSummary: parentNodeName : parentNodeValue; as transformedCondition) {
    <div class="condition-row">
      <div class="condition-row-content">
        <div class="condition-name">
          {{ transformedCondition.attributeName }}
        </div>
        <div class="condition-operator">{{ transformedCondition.operatorLabel | uppercase }}</div>
        <div class="condition-value">
          <mat-chip-set>
            @switch (transformedCondition.type) {
              @case ('tags') {
                <mat-chip *ngFor="let tag of conditionItem.value">
                  {{ tag }}
                </mat-chip>
              }
              @case ('number') {
                {{ transformedCondition.value }}
              }
              @case ('text') {
                {{ transformedCondition.value }}
              }
              @case ('datetime') {
                @if (transformedCondition.isCustomDateTimeOperator) {
                  {{ transformedCondition.value }}
                } @else {
                  <span matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
                    {{ conditionItem.value | date: 'MMM dd, YYYY HH:mm' : timezone }}
                  </span>
                }
              }
              @case ('date') {
                @if (transformedCondition.isCustomDateTimeOperator) {
                  {{ transformedCondition.value }}
                } @else {
                  <span matTooltip="timezone: UTC {{ timezone | timezoneDisplay }}">
                    {{ conditionItem.value | date: 'MMM dd, YYYY' : timezone }}
                  </span>
                }
              }
              @default {
                <mat-chip>
                  {{ transformedCondition.value }}
                </mat-chip>
              }
            }

            @if (transformedCondition.timeRange) {
              <mat-chip class="condition-timeframe">{{ transformedCondition.timeRange }}</mat-chip>
            }
          </mat-chip-set>
        </div>
      </div>

      @if (conditionItem.connector && conditionItem.conditions && conditionItem.conditions.length > 0) {
        <div class="nested-condition-container">
          <div class="condition-connector">
            <span>{{ conditionItem.connector | uppercase }}</span>
          </div>

          <div class="condition-row-nesting-container">
            @for (nestedItem of conditionItem.conditions; track $index) {
              <div class="condition-row-nesting">
                <ng-container
                  [ngTemplateOutlet]="conditionNode"
                  [ngTemplateOutletContext]="{
                    conditionItem: nestedItem,
                    parentNodeName: conditionItem.attribute,
                    parentNodeValue: conditionItem.value,
                    connector: conditionItem.connector
                  }"
                ></ng-container>
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
</ng-template>
