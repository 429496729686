import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { NotificationResponse, OrderItem, OrderItemResult, OrderItemsFilter } from '../../types';

export const loadOrderItems = createAction('[Order Items] Load Order Items', props<{ filter: OrderItemsFilter }>());
export const loadOrderItemsSuccess = createAction(
  '[Order Items] Load Order Items Success',
  props<{ result: OrderItemResult }>()
);
export const loadOrderItemsFailure = createAction('[Order Items] Load Order Items Failure', props<ErrorState>());

export const loadOrderItem = createAction(
  '[Order Items] Load Order Item',
  props<{ id: string; filter?: OrderItemsFilter }>()
);
export const loadOrderItemSuccess = createAction(
  '[Order Items] Load Order Item Success',
  props<{ orderItem: OrderItem }>()
);
export const loadOrderItemFailure = createAction('[Order Items] Load Order Item Failure', props<ErrorState>());

export const sendNotification = createAction('[Order Items] Send Notification', props<{ notificationId: string }>());
export const sendNotificationSuccess = createAction(
  '[Order Items] Send Notification Success',
  props<{ notification: NotificationResponse }>()
);
export const sendNotificationFailure = createAction('[Order Items] Send Notification Failure', props<ErrorState>());

export const updateOrderItemStatus = createAction('[Order Items] Update Order Item Status', props<{ id: string }>());
export const updateOrderItemStatusSuccess = createAction('[Order Items] Update Order Item Status Success');
export const updateOrderItemStatusFailure = createAction(
  '[Order Items] Update Order Item Status Failure',
  props<ErrorState>()
);

export const refundOrderItem = createAction('[Order Items] Refund Order Item', props<{ id: string }>());
export const refundOrderItemSuccess = createAction('[Order Items] Refund Order Item Success');
export const refundOrderItemFailure = createAction('[Order Items] Refund Order Item Failure', props<ErrorState>());
