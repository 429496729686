import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { AuthState } from '@core/store/auth/reducers/auth.reducer';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';

import { NotesService } from '../../services/notes.service';
import { NoteState } from '../../types/notes.type';
import {
  createNote,
  createNoteFailure,
  createNoteSuccess,
  deleteNote,
  deleteNoteFailure,
  deleteNoteSuccess,
  loadNotes,
  loadNotesFailure,
  loadNotesSuccess,
  updateNote,
  updateNoteFailure,
  updateNoteSuccess
} from '../actions/notes.actions';
import { NotesQuery } from '../selectors/notes.selectors';

@Injectable()
export class NotesEffects {
  loadNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotes),
      exhaustMap(action =>
        this.notesService.getNotes(action.filter).pipe(
          map(notesResult => loadNotesSuccess({ notesResult })),
          catchError(error => of(loadNotesFailure({ error })))
        )
      )
    )
  );

  createNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNote),
      withLatestFrom(this.authStore.select(authQuery.getUserId)),
      exhaustMap(([action, adminUserId]) =>
        this.notesService.createNote({ ...action.note, adminUserId }).pipe(
          map(note => createNoteSuccess({ note })),
          catchError(error => of(createNoteFailure({ error })))
        )
      )
    )
  );

  updateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNote),
      exhaustMap(action =>
        this.notesService.updateNote(action.note).pipe(
          map(note => updateNoteSuccess({ note, dialogRefId: action.dialogRefId })),
          catchError(error => of(updateNoteFailure({ error })))
        )
      )
    )
  );

  deleteNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteNote),
      exhaustMap(action =>
        this.notesService.deleteNote(action.id, action.entity).pipe(
          map(() => deleteNoteSuccess({ id: action.id })),
          catchError(error => of(deleteNoteFailure({ error })))
        )
      )
    )
  );

  updateNoteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateNoteSuccess),
        tap(({ note, dialogRefId }) => this.matDialog.getDialogById(dialogRefId)?.close(true))
      ),
    { dispatch: false }
  );

  manageNoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNoteSuccess, deleteNoteSuccess, createNoteSuccess),
      withLatestFrom(
        this.noteStore.select(this.notesQuery.getFilter),
        this.noteStore.select(this.notesQuery.isTopLevelView)
      ),
      map(([_, filter, isTopLevelView]) => loadNotes({ filter, isTopLevelView }))
    )
  );

  constructor(
    private actions$: Actions,
    private notesService: NotesService,
    private authStore: Store<AuthState>,
    private noteStore: Store<NoteState>,
    private notesQuery: NotesQuery,
    private matDialog: MatDialog
  ) {}
}
