import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { ActivateQuestionsPayload, QuestionsResponse } from '../types';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  constructor(private missionControlService: MissionControlService) {}

  getQuestions(): Observable<QuestionsResponse> {
    return this.missionControlService.get<QuestionsResponse>('questions');
  }

  activateQuestions(payload: ActivateQuestionsPayload): Observable<QuestionsResponse> {
    return this.missionControlService.post<QuestionsResponse>('questions/activate', payload);
  }
}
