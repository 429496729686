import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface Ticket {
  subject: string;
  comment: string;
  email: string;
}

export type TicketState = CallState;

export const ticketInitialState: TicketState = { ...initialCallState };
