import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import { TagsService } from '@shared/services/tags.service';

import { AuditLogsService } from '../../services/audit-logs.service';
import {
  loadAuditLog,
  loadAuditLogFailure,
  loadAuditLogs,
  loadAuditLogsFailure,
  loadAuditLogsSuccess,
  loadAuditLogSuccess
} from '../actions/audit-logs.actions';

@Injectable()
export class AuditLogsEffects {
  loadAuditLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuditLog),
      exhaustMap(({ id }) =>
        this.auditLogsService.getAuditLog(id).pipe(
          switchMap(auditLog => {
            const tags = this.tagsService.getUniqueFetchableTags([auditLog]);

            // add of(null) here to resolve combineLatest not emitting any value in case of empty array
            return combineLatest([...this.tagsService.fetchTags(tags), of(null)]).pipe(
              map(() => loadAuditLogSuccess({ auditLog }))
            );
          }),
          catchError(error => of(loadAuditLogFailure({ error })))
        )
      )
    )
  );

  loadAuditLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuditLogs),
      switchMap(action =>
        this.auditLogsService.getAuditLogs(action.filter).pipe(
          switchMap(result => {
            const tags = this.tagsService.getUniqueFetchableTags(result.data);

            // add of(null) here to resolve combineLatest not emitting any value in case of empty array
            return combineLatest([...this.tagsService.fetchTags(tags), of(null)]).pipe(
              map(() => loadAuditLogsSuccess({ result }))
            );
          }),
          catchError(error => of(loadAuditLogsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private auditLogsService: AuditLogsService,
    private tagsService: TagsService
  ) {}
}
