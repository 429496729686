var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var getCryptoSubtle = function () {
    var crypto = window.crypto;
    // safari 10.x uses webkitSubtle
    return crypto.subtle || crypto.webkitSubtle;
};
var encodeAsBase64Url = function (input) {
    var base64Map = { '+': '-', '/': '_', '=': '' };
    return input.replace(/[+/=]/g, function (char) { return base64Map[char]; });
};
var sha256 = function (input) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getCryptoSubtle().digest({ name: 'SHA-256' }, new TextEncoder().encode(input))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var bufferToBase64UrlEncoded = function (input) {
    var bufferAsNumbers = Array.from(new Uint8Array(input));
    return encodeAsBase64Url(window.btoa(String.fromCharCode.apply(String, bufferAsNumbers)));
};
export var generateCodeVerifier = function () {
    var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
    var result = '';
    var randomValues = Array.from(window.crypto.getRandomValues(new Uint8Array(43)));
    randomValues.forEach(function (randomValue) { return (result += charset[randomValue % charset.length]); });
    return result;
};
export var generateCodeChallenge = function (codeVerifier) { return __awaiter(void 0, void 0, void 0, function () {
    var codeChallengeBuffer;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, sha256(codeVerifier)];
            case 1:
                codeChallengeBuffer = _a.sent();
                return [2 /*return*/, bufferToBase64UrlEncoded(codeChallengeBuffer)];
        }
    });
}); };
