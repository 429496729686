@if (!loading && !sideSheet.opened) {
  <button
    [@.disabled]="isRecommendationButtonFirstRendered"
    [@expandedRecommendationButton]
    (@expandedRecommendationButton.start)="isButtonAnimationDone = false"
    (@expandedRecommendationButton.done)="isButtonAnimationDone = true"
    class="primary-button ml-auto"
    (click)="sideSheet.open(); isRecommendationButtonFirstRendered = false"
  >
    <span [ngClass]="isRecommendationButtonFirstRendered || isButtonAnimationDone ? 'opacity-100' : 'opacity-0'">
      {{
        recommendations && recommendations.length > 0 ? recommendations.length + ' recommendations' : 'Recommendations'
      }}
    </span>
  </button>
}

<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav
    #sideSheet
    mode="side"
    position="end"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
    class="min-w-96 max-w-96 border-cloudburst-200 !bg-cloudburst-100 px-6 py-10"
  >
    <admin-recommendation-summary-list (closed)="sideSheet.close()" />
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
