import { catchError, concatMap, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';

import { loadGiftCard } from '../../../gift-cards/store/actions/gift-cards.actions';
import { loadMerchandise } from '../../../merchandises/store/actions/merchandises.actions';
import { fetchUser } from '../../../users/store/actions/users.actions';
import { usersQuery } from '../../../users/store/selectors/users.selectors';
import { OrderItemsService } from '../../services/order-items.service';
import { GiftCardOrderItemData, MerchandiseOrderItemData, OrderItem, OrderItemTypeCode } from '../../types';
import {
  loadOrderItem,
  loadOrderItemFailure,
  loadOrderItems,
  loadOrderItemsFailure,
  loadOrderItemsSuccess,
  loadOrderItemSuccess,
  refundOrderItem,
  refundOrderItemFailure,
  refundOrderItemSuccess,
  sendNotification,
  sendNotificationFailure,
  sendNotificationSuccess,
  updateOrderItemStatus,
  updateOrderItemStatusFailure,
  updateOrderItemStatusSuccess
} from '../actions/order-items.actions';

@Injectable()
export class OrderItemsEffects {
  loadOrderItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderItems),
      switchMap(action =>
        this.orderItemsService.getOrderItems(action.filter).pipe(
          map(result => loadOrderItemsSuccess({ result })),
          catchError(error => of(loadOrderItemsFailure({ error })))
        )
      )
    )
  );

  loadOrderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderItem),
      exhaustMap(action =>
        this.orderItemsService.getOrderItem(action.id).pipe(
          concatMap(orderItem =>
            of(orderItem).pipe(withLatestFrom(this.store.select(usersQuery.getUserById(orderItem.userId))))
          ),
          exhaustMap(([orderItem, user]) =>
            merge([loadOrderItemSuccess({ orderItem }), ...this.getLoadDetailActions(orderItem, user?.id)])
          ),
          catchError(error => of(loadOrderItemFailure({ error })))
        )
      )
    )
  );

  sendNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendNotification),
      exhaustMap(action =>
        this.orderItemsService.sendNotification(action.notificationId).pipe(
          map(notification => sendNotificationSuccess({ notification })),
          catchError(error => of(sendNotificationFailure({ error })))
        )
      )
    )
  );

  updateOrderItemStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrderItemStatus),
      exhaustMap(({ id }) =>
        this.orderItemsService.updateOrderItemStatus(id).pipe(
          map(() => updateOrderItemStatusSuccess()),
          catchError(error => of(updateOrderItemStatusFailure({ error })))
        )
      )
    )
  );

  updateOrderItemStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateOrderItemStatusSuccess),
        tap(() => {
          this.snackBar.open(
            'Success! Please wait for a few seconds and reload this page to see the latest changes.',
            'Dismiss',
            { panelClass: 'mat-snack-bar-success' }
          );
        })
      ),
    { dispatch: false }
  );

  refundOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refundOrderItem),
      exhaustMap(({ id }) =>
        this.orderItemsService.refundOrderItem(id).pipe(
          map(() => refundOrderItemSuccess()),
          catchError(error => of(refundOrderItemFailure({ error })))
        )
      )
    )
  );

  refundOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refundOrderItemSuccess),
        tap(() => {
          this.snackBar.open(
            'Success! Please wait for a few seconds and reload this page to see the latest changes.',
            'Dismiss',
            { panelClass: 'mat-snack-bar-success' }
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private scopes: Scopes,
    private snackBar: MatSnackBar,
    private orderItemsService: OrderItemsService
  ) {}

  private getLoadDetailActions(orderItem: OrderItem, userId: string): Action[] {
    const actions = [];
    if (this.scopes.hasAny(SCOPES_OR.showUsers) && !userId) {
      actions.push(fetchUser({ id: orderItem.userId }));
    }

    switch (orderItem.type) {
      case OrderItemTypeCode.GiftCardOrderItem:
      case OrderItemTypeCode.PhysicalGiftCardOrderItem: {
        if (this.scopes.hasAny(SCOPES_OR.showGiftCards)) {
          actions.push(loadGiftCard({ id: (orderItem.data as GiftCardOrderItemData).cardId }));
        }
        break;
      }
      case OrderItemTypeCode.MerchandiseOrderItem: {
        const id = (orderItem.data as MerchandiseOrderItemData).merchandiseId;

        if (id && this.scopes.hasAny(SCOPES_OR.showMerchandises)) {
          actions.push(loadMerchandise({ id }));
        }
        break;
      }
    }
    return actions;
  }
}
