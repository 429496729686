import { startWith } from 'rxjs/operators';

import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  MAT_SELECT_CONFIG,
  MatSelectChange
} from '@angular/material/select';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import {
  AttributesSettings,
  LoyaltyCurrency,
  ManualAdjustmentOption,
  Reason,
  RequestValidationSettings
} from '@core/types';
import { DayjsUtils, MAX_INTEGER } from '@utils';

import { PointsAdjustmentConfirmDialogV2Component } from '..';
import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS, MAT_DAYJS_DATE_FORMATS } from '../../../../adaptors';
import { makePointsAdjustment } from '../../store/actions/points-adjustments.actions';
import { pointsAdjustmentsQuery } from '../../store/selectors/points-adjustments.selectors';
import {
  PointsAdjustment,
  PointsAdjustmentDescription,
  PointsAdjustmentDialogData,
  PointsAdjustmentState,
  ReasonExplanation
} from '../../types';
import { PointsAdjustmentCreateForm, PointsAdjustmentMetadataForm } from '../../types/dashboard-forms.type';

enum PointsAdjustmentCategory {
  Accrual = 'accrual',
  Redemption = 'redemption',
  Reversal = 'reversal'
}

const DEFAULT_EXPIRY_MAPPING = {
  three_years_end_of_year: 'End of 3 years (Default)',
  evergreen: 'No expiry (Default)',
  expire_in_three_years_end_of_month: '3 years (Default)',
  expire_in_thirty_days: '1 month (Default)'
};

@Component({
  selector: 'admin-points-adjustment-dialog-v2',
  templateUrl: './points-adjustment-dialog-v2.component.html',
  styleUrls: ['./points-adjustment-dialog-v2.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } }
  ]
})
export class PointsAdjustmentDialogV2Component implements OnInit {
  destroyRef = inject(DestroyRef);

  pointsAdjustmentLoading$: Observable<boolean>;
  pointsAdjustmentForm: FormGroup<PointsAdjustmentCreateForm>;
  minExpiryDate: Dayjs;
  defaultExpiryDate: Dayjs;
  defaultExpiryRule: string;
  adjustmentText: string;
  isDefaultDescription: boolean;
  descriptions: string[] = [];

  min =
    Number.isInteger(this.pointsAdjustmentDecimals) && this.pointsAdjustmentDecimals > 0
      ? Math.pow(10, -this.pointsAdjustmentDecimals)
      : 1;
  max = MAX_INTEGER;
  step = this.min;
  additionAllowed = true;
  deductionAllowed = true;

  defaultDescriptionLabels = {
    [PointsAdjustmentDescription.CustomerService]: 'Manual adjustment: customer service',
    [PointsAdjustmentDescription.CustomerRecognition]: 'Manual adjustment: customer recognition',
    [PointsAdjustmentDescription.SystemErrorCorrection]: 'Manual adjustment: system error correction'
  };

  reasonExplanation = ReasonExplanation;
  hasOnlyCustomOption: boolean;
  allowedOptions: ManualAdjustmentOption[];
  selectedOption: ManualAdjustmentOption;

  constructor(
    public dialogRef: MatDialogRef<PointsAdjustmentDialogV2Component>,
    private pointsAdjustmentStore: Store<PointsAdjustmentState>,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private accessPolicies: AccessPolicies,
    @Inject(MAT_DIALOG_DATA) public data: PointsAdjustmentDialogData,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    @Inject('pointsAdjustmentDecimals') public pointsAdjustmentDecimals: number,
    @Inject('timezoneOffset') public timezoneOffset: number
  ) {}

  get bootstrapLoyaltyCurrency(): LoyaltyCurrency {
    return this.data.nydusNetworkBootstrap.loyaltyCurrencies[0];
  }

  get category(): string {
    return this.pointsAdjustmentForm.controls.category.value;
  }

  get expiryDateControl(): FormControl<Dayjs> {
    return this.pointsAdjustmentForm.controls.expiryDate;
  }

  get metadataControl(): FormGroup<PointsAdjustmentMetadataForm> {
    return this.pointsAdjustmentForm.controls.metadata as FormGroup<PointsAdjustmentMetadataForm>;
  }

  ngOnInit(): void {
    this.minExpiryDate = DayjsUtils.getTenantLocalNowTime(this.timezoneOffset);
    this.pointsAdjustmentLoading$ = this.pointsAdjustmentStore.select(pointsAdjustmentsQuery.isSingleLoading);
    const policySettings = this.accessPolicies.getRequestValidationSettings('pointsAdjustment', 'create')
      ?.attributes as AttributesSettings;

    const options = this.data.nydusNetworkBootstrap.manualAdjustmentOptions;
    this.hasOnlyCustomOption = options.length === 1 && options[0].reason === 'custom';

    const reasonsPolicy = (policySettings?.reason as RequestValidationSettings)?.includes;
    this.allowedOptions = reasonsPolicy ? options.filter(option => reasonsPolicy.includes(option.reason)) : options;

    this.selectedOption = options[0];

    this.pointsAdjustmentForm = this.fb.group(
      {
        category: this.fb.control(null, [Validators.required]),
        amount: this.fb.control(null, [Validators.required, Validators.min(this.min)]),
        expiryDate: this.fb.control(null),
        expiryRule: this.fb.control(null),
        description: this.fb.control(null, [Validators.required]),
        metadata: this.getMetadataFormGroup()
      },
      {
        validators: (formGroup: UntypedFormGroup) => {
          const amount = formGroup.get('amount').value;
          const category = formGroup.get('category').value;
          const allowedDecimals = this.pointsAdjustmentDecimals || 0;

          if (amount && !isNaN(amount)) {
            const fractionDecimals = amount.toString().split('.')[1]?.length;

            if (fractionDecimals > allowedDecimals) {
              return { invalidAmount: true };
            }

            if (category === PointsAdjustmentCategory.Redemption && amount > this.data.pointsBalance) {
              return { invalidBalanceResult: true };
            }

            return null;
          }
        }
      }
    );

    this.descriptions = this.setDescriptions();

    this.pointsAdjustmentForm.controls.expiryRule.setValue('default');
    this.setDefaultExpiryDateAndRule();
    this.setExpiryDateControl();

    if (policySettings?.amount) {
      this.setAmountValidation(policySettings.amount as RequestValidationSettings);
    } else {
      this.pointsAdjustmentForm
        .get('amount')
        .setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
    }

    this.subscribeToExpiryRuleChanges();
    this.subscribeToFormStatusChanges();
  }

  setDescriptions(): string[] {
    const options = this.data.nydusNetworkBootstrap.manualAdjustmentOptions;

    this.isDefaultDescription = false;
    const customDescriptions = options[0].metadata.find(obj => obj.key === 'description')?.values;
    if (this.hasOnlyCustomOption && customDescriptions) {
      return customDescriptions;
    }

    switch (this.selectedOption.reason) {
      case Reason.Goodwill: {
        return ['Goodwill', 'Fraud', 'Ascenda-funded', 'Other reasons (ANZ-related)', 'Other reasons (ASC-related)'];
      }
      case Reason.BonusPointsAdjustment:
      case Reason.ExpiryReinstatement:
      case Reason.WriteoffReinstatement:
      case Reason.PointsShare: {
        // We won't display the description field for these reasons (by returning an empty array), set a placeholder value to description control.
        // We will call formatDescription to get the expected value before sending data to confirm dialog
        this.pointsAdjustmentForm.controls.description.setValue('default'); // set for required field
        return [];
      }
      default: {
        this.isDefaultDescription = true;
        return Object.values(PointsAdjustmentDescription);
      } // default descriptions
    }
  }

  subscribeToExpiryRuleChanges(): void {
    this.pointsAdjustmentForm.controls.expiryRule.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(expiryRule => {
        if (expiryRule === 'custom') {
          this.expiryDateControl.setValidators(Validators.required);
        } else {
          this.expiryDateControl.clearValidators();
        }

        this.setExpiryDateControl();

        this.expiryDateControl.updateValueAndValidity();
      });
  }

  subscribeToFormStatusChanges(): void {
    this.pointsAdjustmentForm.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(status => {
      if (status === 'VALID') {
        const { value: amount } = this.pointsAdjustmentForm.controls.amount;
        const actionDescription = this.category === PointsAdjustmentCategory.Accrual ? 'added into' : 'deducted from';

        this.adjustmentText = `${amount} ${this.loyaltyCurrency} will be ${actionDescription} the customer's account.`;
      }
    });
  }

  formatDescription(description: string): string {
    const yearOfAccrual = this.pointsAdjustmentForm.get('metadata.year_of_accrual')?.value?.get('year') || '';
    const yearOfWriteOff = this.pointsAdjustmentForm.get('metadata.year_of_writeoff')?.value?.get('year') || '';

    switch (this.selectedOption.reason) {
      case Reason.ExpiryReinstatement: {
        return `Cancelled ${this.bootstrapLoyaltyCurrency.name} Expiry ${yearOfAccrual}`.trim();
      }
      case Reason.WriteoffReinstatement: {
        return `Cancelled ${this.bootstrapLoyaltyCurrency.name} Forfeiture ${yearOfWriteOff}`.trim();
      }
      case Reason.PointsShare: {
        return `ANZ ${this.bootstrapLoyaltyCurrency.name} Pooling Transfer`;
      }
      case Reason.BonusPointsAdjustment: {
        switch (this.category) {
          case PointsAdjustmentCategory.Accrual: {
            return 'Credit Adjustment to Promotional Points';
          }
          case PointsAdjustmentCategory.Redemption: {
            return 'Debit Adjustment to Promotional Points';
          }
          default: {
            return '';
          }
        }
      }
      default: {
        return description;
      }
    }
  }

  confirmChangesClicked(): void {
    const { category, amount, expiryDate, expiryRule, metadata } = this.pointsAdjustmentForm.getRawValue();
    let { description } = this.pointsAdjustmentForm.getRawValue(); // register value for description even if disabled

    description = this.formatDescription(description);

    const dialogRef = this.matDialog.open(PointsAdjustmentConfirmDialogV2Component, {
      data: {
        actionLabel: `${category === PointsAdjustmentCategory.Accrual ? 'add' : 'deduct'} ${amount} points`,
        // if there is a reason selected, the label is the same as the description value
        statementDescriptorLabel: this.isDefaultDescription ? this.defaultDescriptionLabels[description] : description
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const pointsAdjustment: PointsAdjustment = {
          amount: category === PointsAdjustmentCategory.Accrual ? amount : -amount,
          category,
          description,
          metadata
        };

        if (this.data.pointsAccountId) {
          pointsAdjustment.pointsAccountId = this.data.pointsAccountId;
        }

        let calculatedExpiryDate: string;

        if (this.selectedOption.customExpiry && expiryRule !== 'default') {
          calculatedExpiryDate = DayjsUtils.getEndOfDay(expiryDate, this.timezoneOffset);
          pointsAdjustment.expiryRule = 'custom_date';
          pointsAdjustment.expiryDate = calculatedExpiryDate;
        }

        this.selectedOption.metadata
          .filter(({ key, type }) => type === 'year' && metadata[key])
          .forEach(({ key }) => {
            metadata[key] = metadata[key].get('year');
          });

        const { nydusNetworkBootstrap, ...otherData } = this.data;
        this.pointsAdjustmentStore.dispatch(
          makePointsAdjustment({
            ...otherData,
            pointsAdjustment,
            dialogRefId: this.dialogRef.id
          })
        );
      }
    });
  }

  onReasonChange({ value: reason }: MatSelectChange): void {
    this.selectedOption = this.allowedOptions.find(option => option.reason === reason);

    const { category, expiryRule } = this.pointsAdjustmentForm.controls;

    category.reset();
    expiryRule.setValue('default');

    this.descriptions = this.setDescriptions();

    this.setDefaultExpiryDateAndRule();
    this.setExpiryDateControl();

    this.pointsAdjustmentForm.controls.metadata = this.getMetadataFormGroup();
  }

  getMetadataFormGroup(): FormGroup<PointsAdjustmentMetadataForm> {
    return this.fb.group({
      reason: [this.selectedOption.reason],
      ...Object.fromEntries(
        this.selectedOption.metadata.map(metadata => [
          metadata.key,
          [null, metadata.required ? Validators.required : null]
        ])
      )
    });
  }

  private setAmountValidation(settings: RequestValidationSettings): void {
    this.pointsAdjustmentForm.controls.category.valueChanges
      .pipe(startWith(PointsAdjustmentCategory.Accrual))
      .subscribe((category: PointsAdjustmentCategory) => {
        const amountControl = this.pointsAdjustmentForm.controls.amount;
        if (category === PointsAdjustmentCategory.Accrual) {
          this.setAdditionValidation(amountControl, settings);
        } else if ([PointsAdjustmentCategory.Redemption, PointsAdjustmentCategory.Reversal].includes(category)) {
          this.setDeductionValidation(amountControl, settings);
        }
        amountControl.updateValueAndValidity();
      });
  }

  private setAdditionValidation(amountControl: AbstractControl, settings: RequestValidationSettings): void {
    if (settings.lte < 0) {
      amountControl.disable();
      this.additionAllowed = false;
    } else {
      this.enableAmountControl(amountControl);

      if (settings.gte > this.min) {
        this.min = settings.gte;
      }
      this.max = settings.lte ?? this.max;

      amountControl.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
    }
  }

  private setDeductionValidation(amountControl: AbstractControl, settings: RequestValidationSettings): void {
    if (settings.gte > 0) {
      amountControl.disable();
      this.deductionAllowed = false;
    } else {
      this.enableAmountControl(amountControl);

      if (settings.lte < -this.min) {
        this.min = -settings.lte;
      }
      this.max = -settings.gte ?? Number.MAX_SAFE_INTEGER;

      amountControl.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
    }
  }

  private enableAmountControl(amountControl: AbstractControl): void {
    amountControl.enable();
    this.additionAllowed = true;
    this.deductionAllowed = true;
  }

  private setDefaultExpiryDateAndRule(): void {
    const options = this.data.nydusNetworkBootstrap.manualAdjustmentOptions;
    const selectedReasonOption = options.find(option => option.reason === this.selectedOption.reason);

    this.defaultExpiryDate = dayjs(selectedReasonOption?.defaultExpiry);
    this.defaultExpiryRule = DEFAULT_EXPIRY_MAPPING[selectedReasonOption?.defaultExpiryRule];
  }

  private setExpiryDateControl(): void {
    const { expiryRule } = this.pointsAdjustmentForm.getRawValue();

    if (expiryRule === 'default') {
      this.expiryDateControl.setValue(this.defaultExpiryDate);
      this.expiryDateControl.disable();
    } else if ([1, 2, 3].includes(expiryRule)) {
      const newExpiryDate = DayjsUtils.getUtcTime().startOf('hour').add(expiryRule, 'month');

      this.expiryDateControl.setValue(newExpiryDate);
      this.expiryDateControl.disable();
    } else {
      this.expiryDateControl.setValue(null);
      this.expiryDateControl.enable();
    }
  }
}
