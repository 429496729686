import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { PartnerConfig, ToggleableProduct, UpdateUiSettingsRequest, UpdateWebsiteStylingRequest } from '../types';

@Injectable({
  providedIn: 'root'
})
export class PartnerConfigsService {
  constructor(private missionControlService: MissionControlService) {}

  getPartnerConfig(): Observable<PartnerConfig> {
    return this.missionControlService.get<PartnerConfig>('partner_config');
  }

  updateWebsiteStyling(data: UpdateWebsiteStylingRequest): Observable<void> {
    return this.missionControlService.patch<void>('partner_config/update_website_styling', data);
  }

  activateProduct(product: ToggleableProduct, version: number, value: boolean): Observable<void> {
    return this.missionControlService.patch<void>('partner_config/activate_product', {
      product,
      version,
      value
    });
  }

  updateUiSettings(data: UpdateUiSettingsRequest): Observable<void> {
    return this.missionControlService.patch<void>('partner_config/update_ui_settings', data);
  }
}
