import { DecimalPipe } from '@angular/common';

export class RedemptionRateUtils {
  static formatPrice = (minPrice: string, maxPrice: string): string => {
    const decimalPipe = new DecimalPipe('en-US');

    return minPrice && maxPrice
      ? minPrice === maxPrice
        ? decimalPipe.transform(minPrice)
        : decimalPipe.transform(minPrice) + ' - ' + decimalPipe.transform(maxPrice)
      : 'All';
  };
}
