import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PreviewDialogComponent } from './preview-dialog.component';

@Component({
  selector: 'admin-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  @Input() laptopImage: string;
  @Input() mobileImage: string;

  selectedDisplay = 'laptop';
  isModalOpen = false;
  displayToggle: boolean;

  constructor(public dialog: MatDialog) {}

  get selectedImage(): string {
    return this.selectedDisplay === 'laptop' && this.laptopImage ? this.laptopImage : this.mobileImage;
  }

  ngOnInit(): void {
    this.displayToggle = !!this.laptopImage && !!this.mobileImage;
  }

  toggleDisplay(value: 'laptop' | 'mobile'): void {
    this.selectedDisplay = value;
  }

  openDialog(): void {
    this.isModalOpen = true;

    this.dialog
      .open(PreviewDialogComponent, {
        data: this.selectedImage,
        width: '85vw',
        minWidth: this.selectedDisplay === 'laptop' ? '828px' : '526px',
        maxHeight: '100vh'
      })
      .afterClosed()
      .subscribe(() => {
        this.isModalOpen = false;
      });
  }
}
