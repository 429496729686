import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';

@Component({
  selector: 'admin-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['../form-field-shared.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DateSelectorComponent
    }
  ]
})
export class DateSelectorComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnInit {
  @ViewChild(MatInput, { read: ElementRef, static: true }) input: ElementRef;

  @Input() fieldLabel: string;
  @Input('aria-describedby') userAriaDescribedBy: string; // eslint-disable-line @angular-eslint/no-input-rename

  dateControl: FormControl;
  focused = false;

  stateChanges = new Subject<void>();

  /* eslint-disable no-underscore-dangle */
  /* eslint-disable @typescript-eslint/member-ordering */
  static nextId = 0;
  @HostBinding() id = `date-selector-${DateSelectorComponent.nextId++}`;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }

    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  set max(value: Date) {
    this._max = value;
    this.stateChanges.next();
  }

  get max(): Date {
    return this._max;
  }

  private _max: Date;

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get value(): string {
    return this.dateControl.value;
  }

  set value(value: string) {
    this.dateControl.setValue(value);
    this.stateChanges.next();
  }

  @HostListener('input')
  onInput(): void {
    this.onChange(this.value);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get empty(): boolean {
    return !this.value;
  }

  get errorState(): boolean {
    return this.dateControl.touched && !!this.dateControl.errors;
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    this.dateControl = new FormControl('');

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  /* eslint-enable */

  /* non-interface implementations */
  ngOnInit(): void {
    if (this.required) {
      this.dateControl.addValidators([Validators.required]);
    }
  }

  getErrorMessage(): string {
    if (this.isInvalidDateField()) {
      return 'Invalid date';
    }

    if (this.isInvalidRequiredField()) {
      return 'Required';
    }

    return null;
  }

  isInvalidDateField(): boolean {
    return (
      this.errorState && !!(this.dateControl.errors.matDatepickerParse || this.dateControl.errors.matDatepickerMax)
    );
  }

  isInvalidRequiredField(): boolean {
    return (
      this.errorState &&
      this.dateControl.errors.required &&
      !this.dateControl.errors.matDatepickerParse &&
      !this.dateControl.errors.matDatepickerMax
    );
  }

  /* interface implementations */
  onContainerClick(): void {}

  onChange = (_value: string): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDescribedByIds(ids: string[]): void {
    this.userAriaDescribedBy = ids.join(' ');
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }
}
