import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { GhCacheState, initialState } from '../../types/gh-cache.type';
import {
  clearGhCache,
  clearGhCacheFailure,
  clearGhCacheSuccess,
  loadGhCaches,
  loadGhCachesFailure,
  loadGhCachesSuccess
} from '../actions/gh-cache.actions';

export const ghCacheTriggers: EntityTriggers = {
  single: {
    loading: [clearGhCache.type],
    resting: [clearGhCacheSuccess.type],
    erroring: [clearGhCacheFailure.type]
  },
  batch: {
    loading: [loadGhCaches.type],
    resting: [loadGhCachesSuccess.type],
    erroring: [loadGhCachesFailure.type]
  }
};

export function ghCacheReducer(state: GhCacheState = initialState, action: Action): GhCacheState {
  return callStateReducer(baseReducer, ghCacheTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadGhCachesSuccess, (state, { ghCaches }) => ({ ...state, ghCaches }))
);
