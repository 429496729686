import { map } from 'rxjs/operators';

import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MatChipUtils } from '@core/services/mat-chip-utils/mat-chip-utils';
import { PiiAccess } from '@core/services/pii-access/pii-access.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import {
  AccessPolicySettings,
  AttributesSettings,
  RequestValidationSettings,
  RequestValidationTypes,
  SCOPES_OR
} from '@core/types';
import { Nullable } from '@shared/types';
import { handleRequestValidation, INVALID_E164_MESSAGE, ObjectUtils } from '@utils';

import { EncryptedFields } from '../../../../app-module-config';
import { jsonValidator, phoneValidator } from '../../../../validators';
import { rolesQuery } from '../../../roles/store/selectors/roles.selectors';
import { RoleState } from '../../../roles/types';
import { EditEmailDialogComponent } from '../../components/edit-email-dialog/edit-email-dialog.component';
import { updateUser } from '../../store/actions/users.actions';
import { usersQuery } from '../../store/selectors/users.selectors';
import { Custom, getPartnerStatuses, getUserName, User, UserLoginMode, UserState } from '../../types';

@Component({
  selector: 'admin-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  isEditingPersonalProfile: boolean;
  cancelButtonLink: string;
  userForm: UntypedFormGroup;
  initialUserFormValue: Partial<User>;
  loading$: Observable<boolean>;
  statuses: string[] = getPartnerStatuses(this.partnerPlatform);
  loginModes: string[] = Object.values(UserLoginMode);
  roleIds$: Observable<string[]>;
  creatableRoleIds$: Observable<string[]>;
  deletableRoleIds$: Observable<string[]>;
  selectableRoleIds: string[];
  undeletableRoleIds: string[];
  existingRoleIds: string[];
  changeUsernameIdentityReference: Nullable<string>;
  allowedPersonalAttributes = [
    'firstName',
    'middleName',
    'lastName',
    'salutation',
    'email',
    'nickname',
    'preferredUsername',
    'address',
    'birthdate',
    'gender',
    'locale',
    'phoneNumber',
    'picture',
    'profile',
    'zoneinfo'
  ];
  previousPath: string;
  isEmailEditable = false;
  invalidCustomDataMessage =
    'Invalid custom data. Please include { "agreements": { "digital_rewards_statement": true } } or { "agreements": { "digital_rewards_statement": false } }'; // eslint-disable-line max-len
  accessPolicySettings: Nullable<AccessPolicySettings>;
  invalidE164Message = INVALID_E164_MESSAGE;
  readonly SCOPES = SCOPES_OR;

  constructor(
    private store: Store<UserState>,
    private rolesStore: Store<RoleState>,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private accessPolicies: AccessPolicies,
    private piiAccess: PiiAccess,
    private scopes: Scopes,
    private router: Router,
    public matChipUtils: MatChipUtils,
    private matDialog: MatDialog,
    @Inject('partnerPlatform') public partnerPlatform: string,
    @Inject('encryptedFields') private encryptedFields: EncryptedFields
  ) {
    this.previousPath = this.router.getCurrentNavigation()?.previousNavigation?.extractedUrl.toString() || '';
  }

  get customFieldData(): Nullable<Custom> {
    const control = this.userForm.get('custom');
    try {
      return JSON.parse(control?.value);
    } catch {
      return null;
    }
  }

  get accessPolicyAttributeSettings(): Nullable<AttributesSettings> {
    return this.accessPolicySettings?.attributes || null;
  }

  ngOnInit(): void {
    this.isEditingPersonalProfile = this.route.snapshot.data.user.isOnCurrentUserRoute;

    this.loading$ = this.store.select(usersQuery.isSingleLoading);
    this.roleIds$ = this.rolesStore.select(rolesQuery.getRoleIds);

    this.handleRequestValidationSettings();
    this.createForm();
    this.setupInitialFormValue();

    this.handleSelectableRoles();
    this.subscribeToEStatementValueChanges();
  }

  createForm(): void {
    this.userForm = this.fb.group({
      id: [null, [Validators.required]],
      name: [null],
      address: this.fb.group({
        formatted: [''],
        streetAddress: [''],
        locality: [''],
        region: [''],
        postalCode: [''],
        country: ['']
      }),
      birthdate: [null],
      loyalty_data: ['{}', [jsonValidator()]], // loyalty_data is a non_formatted_keys key from MC to avoid formatting nested attributes
      custom: ['{}', [jsonValidator(), this.customFieldValidator()]],
      email: ['', [Validators.email]],
      emailVerified: [],
      firstName: [''],
      gender: [''],
      lastName: [''],
      locale: [''],
      middleName: [''],
      nickname: [''],
      phoneNumber: ['', [phoneValidator()]],
      phoneNumberVerified: [],
      picture: [''],
      preferredUsername: [''],
      profile: [''],
      salutation: [''],
      zoneinfo: [''],
      status: [null, [Validators.required]],
      loginMode: [null, [Validators.required]],
      activated: [null, [Validators.required]],
      roles: [[]],
      eStatement: []
    });
  }

  handleRequestValidationSettings(): void {
    this.accessPolicySettings = this.isEditingPersonalProfile
      ? (this.accessPolicies.getRequestValidationSettings('users', 'updatePersonalProfile') as AccessPolicySettings)
      : (this.accessPolicies.getRequestValidationSettings('users', 'update') as AccessPolicySettings);

    const userSettings = this.accessPolicyAttributeSettings?.user as AttributesSettings;

    this.statuses = userSettings?.status
      ? (userSettings.status as RequestValidationSettings).includes || []
      : this.statuses;
    this.creatableRoleIds$ = this.roleIds$.pipe(
      map(roleIds =>
        this.accessPolicyAttributeSettings?.createdRoles
          ? (this.accessPolicyAttributeSettings.createdRoles as RequestValidationSettings).includes
          : roleIds
      ),
      map(data => data || [])
    );
    this.deletableRoleIds$ = this.roleIds$.pipe(
      map(roleIds =>
        this.accessPolicyAttributeSettings?.deletedRoles
          ? (this.accessPolicyAttributeSettings.deletedRoles as RequestValidationSettings).includes
          : roleIds
      ),
      map(data => data || [])
    );
  }

  getEStatementValue(custom: Nullable<Custom>): boolean {
    return custom?.agreements?.digital_rewards_statement || false;
  }

  // reflect change to counterpart control when either eStatement or custom value changed
  subscribeToEStatementValueChanges(): void {
    const eStatementControl = this.userForm.get('eStatement');
    const customControl = this.userForm.get('custom');

    eStatementControl?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      let data = this.customFieldData;

      if (data) {
        data = { ...data, agreements: { ...data.agreements, digital_rewards_statement: value } };
        customControl?.setValue(JSON.stringify(data), { emitEvent: false });
      }
    });

    customControl?.markAllAsTouched();
    customControl?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (customControl.valid) {
        eStatementControl?.setValue(this.getEStatementValue(this.customFieldData), { emitEvent: false });
      }
    });
  }

  handleRequestValidationSpecialCases(): void {
    if (!this.accessPolicySettings) {
      return;
    }

    const { attributes, meta } = this.accessPolicySettings;
    const { createdRoles, deletedRoles, user } = attributes ?? ({} as AccessPolicySettings);
    const { loyaltyData, custom } = user ?? {};
    const { otherAttributes } = meta ?? {};

    if (
      createdRoles?.includes.length ||
      deletedRoles?.includes.length ||
      otherAttributes === RequestValidationTypes.Allow
    ) {
      this.userForm.get('roles')?.enable();
    } else {
      this.userForm.get('roles')?.disable();
    }

    if (loyaltyData) {
      this.userForm.get('loyalty_data')?.enable();
    } else if (loyaltyData === false) {
      this.userForm.get('loyalty_data')?.disable();
    }

    if (custom === true || custom?.agreements?.digitalRewardsStatement === true) {
      this.userForm.get('eStatement')?.enable();
    } else if (custom === false || custom?.agreements?.digitalRewardsStatement === false) {
      this.userForm.get('eStatement')?.disable();
    }
  }

  setupInitialFormValue(): void {
    const user$: Observable<User> = this.route.snapshot.data.user.user$;
    const isCustomerView = this.route.snapshot.data.isCustomerView;

    user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      // Create a copy to allow mutation
      const userCopy = ObjectUtils.deepCopy(user);
      // if policy only defines `deletableValues` (e.g., `deletedRoles`) but no `selectableValues` (e.g. `creatableRoleIds$`),
      // send `nonRemovableValues` by removing `deletableValues` from current values
      if (this.accessPolicyAttributeSettings?.deletedRoles) {
        const deletableRoles =
          (this.accessPolicyAttributeSettings.deletedRoles as RequestValidationSettings).includes || [];
        this.undeletableRoleIds =
          userCopy.roles?.filter(role => !deletableRoles.includes(role.name)).map(role => role.name) || [];
      }

      // retrieve agreements and digital_rewards_statement value, or create digital_rewards_statement value if it does not exist
      const digital_rewards_statement = this.getEStatementValue(user.custom || null);
      if (userCopy.custom) {
        userCopy.custom.agreements = { ...userCopy.custom?.agreements, digital_rewards_statement };
      }
      this.existingRoleIds = user.roles?.map(role => role.name) || [];

      this.userForm.patchValue({
        ...userCopy,
        name: getUserName(userCopy),
        eStatement: digital_rewards_statement,
        loyalty_data: JSON.stringify(userCopy.loyalty_data) || '',
        custom: JSON.stringify(userCopy.custom) || '',
        roles: this.existingRoleIds
      });

      ['id', 'name'].forEach(key => {
        this.userForm.get(key)?.disable();
      });

      this.initialUserFormValue = this.userForm.getRawValue();

      this.cancelButtonLink = this.getCancelButtonPath(isCustomerView);

      const passwordIdentityReference = (userCopy.identities || []).find(identity =>
        identity.providerId.includes('password')
      )?.reference;
      const emailIdentityReference = (userCopy.identities || []).find(identity =>
        identity.providerId.includes('email')
      )?.reference;

      // Hotfix to avoid issue due to GH migration from password to email identities.
      // Remove this once GH updates the username update endpoint to support User ID instead.
      this.changeUsernameIdentityReference = (emailIdentityReference ?? passwordIdentityReference) || '';

      this.handleFormControlAbility();
      this.handleEmailFieldAndButton(userCopy.activated || false);
      this.handleEncryptedFields();
    });
  }

  handleFormControlAbility(): void {
    const otherAttributes = this.accessPolicySettings?.meta?.otherAttributes;

    if (this.accessPolicyAttributeSettings && otherAttributes) {
      handleRequestValidation(
        this.userForm,
        this.accessPolicyAttributeSettings.user as AttributesSettings,
        otherAttributes
      );
      this.handleRequestValidationSpecialCases();
    }

    if (this.isEditingPersonalProfile && this.scopes.lackScopes(SCOPES_OR.updateAgents)) {
      this.disableControlsExceptPersonal();
    }
  }

  disableControlsExceptPersonal(): void {
    this.userForm.get('roles')?.disable();

    Object.entries(this.userForm.controls).forEach(([key, control]) => {
      if (control.enabled && !this.allowedPersonalAttributes.includes(key)) {
        control.disable();
      }
    });
  }

  handleEmailFieldAndButton(activatedUser: boolean): void {
    // Remove changeUsernameIdentityReference check once GH updates the username update endpoint to support User ID instead.
    if (this.scopes.hasAny(SCOPES_OR.updateUsername) && this.changeUsernameIdentityReference && activatedUser) {
      this.isEmailEditable = true;
      this.userForm.get('email')?.disable();
    }
  }

  handleEncryptedFields(): void {
    if (this.piiAccess.isMasked()) {
      this.encryptedFields['user'].forEach(field => this.userForm.get(field)?.disable());
    }
  }

  handleSelectableRoles(): void {
    this.creatableRoleIds$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(creatableRoleIds => (this.selectableRoleIds = creatableRoleIds.concat(this.existingRoleIds)))
      )
      .subscribe();
  }

  submitForm(): void {
    const updatedRoleList = this.userForm.get('roles')?.value;
    const createdRoles = updatedRoleList.filter(role => !this.existingRoleIds.includes(role));
    const deletedRoles = this.existingRoleIds.filter(role => !updatedRoleList.includes(role));

    // only retrieve enabled form field values
    const { roles, eStatement, ...userFields } = this.userForm.value;

    const changedUserFields: Partial<User> = Object.fromEntries(
      Object.entries(userFields).filter(([key, currentValue]) => {
        const initialValue = this.initialUserFormValue[key];

        return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
      })
    );

    const user: Partial<User> = {
      id: this.userForm.get('id')?.value,
      ...changedUserFields
    };

    // transform string to object if key exists in user (changedUserFields)
    ['loyalty_data', 'custom'].forEach(key => {
      if (user[key]) {
        user[key] = ObjectUtils.parseAsObject(userFields[key]);
      }
    });

    // if custom is disabled and not exists in user payload, send eStatement result via custom
    if (eStatement !== undefined && !user.custom) {
      user.custom = { agreements: { digital_rewards_statement: eStatement } };
    }

    this.store.dispatch(updateUser({ user, createdRoles, deletedRoles, previousPath: this.previousPath }));
  }

  openEmailDialog(): void {
    this.matDialog.open(EditEmailDialogComponent, {
      width: '500px',
      data: {
        id: this.userForm.get('id')?.value,
        reference: this.changeUsernameIdentityReference,
        parentEmailControl: this.userForm.get('email')
      }
    });
  }

  customFieldValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        const data = JSON.parse(control.value);

        if (!data?.agreements || data?.agreements?.digital_rewards_statement === undefined) {
          return { invalidCustomDataFormat: true };
        } else if (typeof data.agreements.digital_rewards_statement !== 'boolean') {
          return { invalidEStatementDataType: true };
        }
      } catch {
        return null;
      }

      return null;
    };
  }

  getCancelButtonPath(isCustomerView: boolean): string {
    const canAccessUsersView = isCustomerView
      ? this.scopes.hasAny(SCOPES_OR.viewCustomers)
      : this.scopes.hasAny(SCOPES_OR.viewAgents);
    return '../../' + (canAccessUsersView ? '' : this.userForm.get('id')?.value + '/details');
  }
}
