import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { UserOfferActivitiesFilter, UserOfferActivitiesResult } from './../../types';

export const loadUserOfferActivities = createAction(
  '[User Offer Activities] Load User Offer Activities',
  props<{ userId: string; filter: UserOfferActivitiesFilter }>()
);
export const loadUserOfferActivitiesSuccess = createAction(
  '[User Offer Activities] Load User Offer Activities Success',
  props<{ result: UserOfferActivitiesResult; userId: string }>()
);
export const loadUserOfferActivitiesFailure = createAction(
  '[User Offer Activities] Load User Offer Activities Failure',
  props<ErrorState>()
);
