import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  constructor(
    private http: HttpClient,
    @Inject('guardhouseApiUrl') private guardhouseApiUrl: string
  ) {}

  getTranslation(localeCode: string): Observable<object> {
    return this.http.get(`${this.guardhouseApiUrl}/assets/i18n/${localeCode}.json`);
  }
}
