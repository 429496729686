import { createSelector, Selector } from '@ngrx/store';

import { Tag, TagIdsRecord } from '@shared/types';

import { appConnectionsQuery } from '../modules/apps/store/selectors/app-connections.selectors';
import { appsQuery } from '../modules/apps/store/selectors/apps.selectors';
import { AppConnection } from '../modules/apps/types/app-connections.type';
import { App } from '../modules/apps/types/apps.type';
import { authenticationProvidersQuery } from '../modules/authentication-providers/store/selectors/authentication-providers.selectors';
import { AuthenticationProvider } from '../modules/authentication-providers/types/authentication-providers.type';
import { campaignsQuery } from '../modules/campaigns/store/selectors/campaigns.selectors';
import { Campaign } from '../modules/campaigns/types';
import { domainsQuery } from '../modules/domains/store/selectors/domains.selectors';
import { Domain } from '../modules/domains/types';
import { fraudRulesQuery } from '../modules/fraud-rules/store/selectors/fraud-rules.selectors';
import { FraudRule } from '../modules/fraud-rules/types/fraud-rules.type';
import { loyaltyProgramsQuery } from '../modules/loyalty-programs/store/selectors/loyalty-programs.selectors';
import { LoyaltyProgram } from '../modules/loyalty-programs/types';
import { merchandisesQuery } from '../modules/merchandises/store/selectors/merchandises.selectors';
import { Merchandise } from '../modules/merchandises/types/merchandises.type';
import { offersQuery } from '../modules/offers/store/selectors/offers.selectors';
import { Offer } from '../modules/offers/types';
import { redemptionRatesQuery } from '../modules/redemption-rates/store/selectors/redemption-rates.selectors';
import { RedemptionRate } from '../modules/redemption-rates/types';
import { usersQuery } from '../modules/users/store/selectors/users.selectors';
import { User } from '../modules/users/types';

const { getAppConnectionsDictionary, getFetchList: getAppConnectionsFetchList } = appConnectionsQuery;
const { getAppsDictionary, getFetchList: getAppsFetchList } = appsQuery;
const { getAuthenticationProvidersDictionary, getFetchList: getAuthenticationProvidersFetchList } =
  authenticationProvidersQuery;
const { getCampaignsDictionary, getFetchList: getCampaignsFetchList } = campaignsQuery;
const { getDomainsDictionary, getFetchList: getDomainsFetchList } = domainsQuery;
const { getFraudRulesDictionary, getFetchList: getFraudRulesFetchList } = fraudRulesQuery;
const { getLoyaltyProgramsDictionary, getFetchList: getLoyaltyProgramsFetchList } = loyaltyProgramsQuery;
const { getMerchandisesDictionary, getFetchList: getMerchandisesFetchList } = merchandisesQuery;
const { getOffersDictionary, getFetchList: getOffersFetchList } = offersQuery;
const { getRedemptionRatesDictionary, getFetchList: getRedemptionRatesFetchList } = redemptionRatesQuery;
const { getUsersDictionary, getFetchList: getUsersFetchList } = usersQuery;

export const formatMessage = (message: string, tags: Tag[]): string => {
  let newMessage = message.slice();
  [...message.matchAll(/%{([a-z_]+)}/g)].forEach(matchArray => {
    const tagPlaceholder = matchArray[0]; // e.g. %{user_id}
    const tagKey = matchArray[1]; // e.g. user_id
    const replacementValue = tags.find(tag => tag.type === tagKey)?.displayValue;

    if (replacementValue) {
      newMessage = newMessage.replace(tagPlaceholder, replacementValue);
    }
  });

  return newMessage;
};

export const convertTagsToTagIdsRecord = (tags: Tag[]): TagIdsRecord =>
  tags.reduce((result, tag) => {
    result[tag.type] = result[tag.type] ? [...result[tag.type], tag.id] : [tag.id];
    return result;
  }, {}) as TagIdsRecord;

export type DictionaryType =
  | AppConnection
  | App
  | AuthenticationProvider
  | Campaign
  | Domain
  | FraudRule
  | LoyaltyProgram
  | Merchandise
  | Offer
  | RedemptionRate
  | User;

export const getTagDictionaries = createSelector(
  getAppConnectionsDictionary,
  getAppsDictionary,
  getAuthenticationProvidersDictionary,
  getCampaignsDictionary,
  getDomainsDictionary,
  getFraudRulesDictionary,
  getLoyaltyProgramsDictionary,
  getMerchandisesDictionary,
  getOffersDictionary,
  getRedemptionRatesDictionary,
  getUsersDictionary,
  (
    appConnections,
    apps,
    authenticationProviders,
    campaigns,
    domains,
    fraudRules,
    loyaltyPrograms,
    merchandises,
    offers,
    redemptionRates,
    users
  ) => [
    appConnections,
    apps,
    authenticationProviders,
    campaigns,
    domains,
    fraudRules,
    loyaltyPrograms,
    merchandises,
    offers,
    redemptionRates,
    users
  ]
);

export const isDataFetching = (idList: TagIdsRecord): Selector<object, boolean> =>
  createSelector(
    getAppConnectionsFetchList,
    getAppsFetchList,
    getAuthenticationProvidersFetchList,
    getCampaignsFetchList,
    getDomainsFetchList,
    getFraudRulesFetchList,
    getLoyaltyProgramsFetchList,
    getMerchandisesFetchList,
    getOffersFetchList,
    getRedemptionRatesFetchList,
    getUsersFetchList,
    (
      appConnectionsFetchList,
      appsFetchList,
      authenticationProvidersFetchList,
      campaignsFetchList,
      domainsFetchList,
      fraudRulesFetchList,
      loyaltyProgramsFetchList,
      merchandisesFetchList,
      offersFetchList,
      redemptionRatesFetchList,
      usersFetchList
    ) => {
      const {
        app_connection_id: appConnectionIds,
        app_id: appIds,
        agent_id: agentIds = [],
        authentication_provider_reference: authenticationProviderReferences,
        campaign_id: campaignIds,
        domain_id: domainIds,
        fraud_rule_id: fraudRuleIds,
        loyalty_program_id: loyaltyProgramIds,
        merchandise_id: merchandiseIds,
        offer_id: offerIds,
        redemption_rate_id: redemptionRadeIds,
        user_id: userIds = []
      } = idList;

      return [
        [appConnectionsFetchList, appConnectionIds],
        [appsFetchList, appIds],
        [authenticationProvidersFetchList, authenticationProviderReferences],
        [campaignsFetchList, campaignIds],
        [domainsFetchList, domainIds],
        [fraudRulesFetchList, fraudRuleIds],
        [loyaltyProgramsFetchList, loyaltyProgramIds],
        [merchandisesFetchList, merchandiseIds],
        [offersFetchList, offerIds],
        [redemptionRatesFetchList, redemptionRadeIds],
        [usersFetchList, [...agentIds, ...userIds]]
      ].some(([fetchList, tagIds]) => fetchList?.some(id => tagIds?.includes(id)));
    }
  );
