import { formatDate, KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { Formatters } from '../../../utils/formatters';

@Pipe({ name: 'metadataBeautify' })
export class MetadataBeautifyPipe implements PipeTransform {
  transform(metadataArray: KeyValue<any, any>[]): KeyValue<string, any>[] {
    return metadataArray.map(metadata => ({
      key: this.transformKey(metadata.key),
      value: this.transformValue(metadata)
    }));
  }

  transformValue(metadata: KeyValue<string, any>): any {
    if (!metadata.value) {
      return metadata.value;
    }

    switch (metadata.key) {
      case 'errors':
      case 'passengers':
      case 'redemption':
      case 'accrualBreakdown':
        return metadata.value;
      case 'originSegments':
      case 'returnSegments':
      case 'membershipInformation':
        return Object.entries(metadata.value)
          .map(([key, value]: [string, string]) => {
            if (['arrivalTime', 'departureTime'].includes(key)) {
              value = formatDate(value, 'yyyy-MM-dd HH:mm:ss', 'en');
            }
            return `${this.transformKey(key)}: ${value}`;
          })
          .join('<br>');
      case 'endTime':
      case 'startTime':
        return formatDate(metadata.value, 'yyyy-MM-dd HH:mm:ss', 'en');
      case 'cashPaidInUsd':
        return '$' + metadata.value;
      case 'mealInformation':
        if (typeof metadata.value === 'string') {
          return metadata.value.replace(/_/g, ' ');
        }
      // eslint-disable-next-line no-fallthrough
      default:
        if (typeof metadata.value === 'string') {
          return metadata.value;
        }

        return JSON.stringify(metadata.value, null, '\t');
    }
  }

  transformKey(key: string): string {
    if (key.slice(-2) === 'Id') {
      return Formatters.fromCamelToSentenceCase(key.slice(0, -2)) + ' ' + key.slice(-2).toUpperCase();
    }
    return Formatters.fromCamelToSentenceCase(key);
  }
}
