import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { CustomTitleCasePipe } from '@shared/pipes';
import { UserTagDefsKeys } from '@shared/services/tag-defs/user-tag-defs.service';
import { FETCHABLE_TAG_TYPES, Nullable, Tag } from '@shared/types';
import { formatMessage } from '@tag-utils';

import { AuditLogsFilter } from '../../audit-logs/types/audit-logs-filter.type';
import { AuditLogsResult } from '../../audit-logs/types/audit-logs-result.type';
import { AuditLog } from '../../audit-logs/types/audit-logs.type';
import { EventsResult } from '../../events/types/events-result.type';
import { Event } from '../../events/types/events.type';
import { Note } from '../../notes/types/notes.type';
import { TagDefs } from '../types/tag-defs.type';
import { TagDefsService } from './tag-defs.service';
import { TagDictionaryService } from './tag-dictionary.service';

type LogsResult = AuditLogsResult | EventsResult;
type Log = AuditLog | Event;
type TaggedEntity = Log | AuditLogsFilter | Note;

interface FetchableTagsAndTagData {
  fetchableTags: Tag[];
  tagDataFetches: Observable<any>[];
}

@Injectable({ providedIn: 'root' })
export class TagsService {
  tagDefs: TagDefs<any>;

  constructor(
    private tagDefsService: TagDefsService,
    private tagDictionaryService: TagDictionaryService,
    private customTitleCasePipe: CustomTitleCasePipe
  ) {
    this.tagDefs = this.tagDefsService.tagDefs;
  }

  formatTag$(tag: Tag): Observable<Tag> {
    if (FETCHABLE_TAG_TYPES.includes(tag.type)) {
      const tagData$ = this.tagDefs[tag.type]!.select!(tag.id);

      return tagData$.pipe(map(tagData => this.tagDictionaryService.formatTag(tag, tagData)));
    } else {
      return of(this.tagDictionaryService.formatTag(tag));
    }
  }

  fetchTags(tags: Tag[]): Observable<Nullable<void>>[] {
    const userOrAgentIds = new Set<string>();
    const otherTags: Observable<Nullable<void>>[] = [];
    let allTags: Observable<Nullable<void>>[] = [];

    tags
      .filter(tag => FETCHABLE_TAG_TYPES.includes(tag.type))
      .map((tag: Tag) => {
        if (UserTagDefsKeys.includes(tag.type)) {
          userOrAgentIds.add(tag.id);
        } else {
          otherTags.push(this.tagDefs[tag.type]!.fetchData!(tag.id));
        }
      });

    if (userOrAgentIds.size > 0) {
      allTags = [this.tagDefs.user_id!.fetchData!([...userOrAgentIds])];
    }
    return allTags.concat(otherTags);
  }

  // replace the old tags with the formatted tags
  formatLogsResultTags(logsResult: LogsResult): Observable<LogsResult> {
    const { fetchableTags, tagDataFetches } = this.fetchFetchableTags(logsResult.data);
    // add of(null) here to resolve combineLatest not emitting any value in case of empty array
    return combineLatest([...tagDataFetches, of(null)]).pipe(
      map(
        tagsResults =>
          ({
            ...logsResult,
            data: this.mapEntitiesTags(logsResult.data, fetchableTags, tagsResults)
          }) as LogsResult
      )
    );
  }

  formatEntitiesTags(entities: TaggedEntity[]): Observable<TaggedEntity[]> {
    const { fetchableTags, tagDataFetches } = this.fetchFetchableTags(entities);
    // add of(null) here to resolve combineLatest not emitting any value in case of empty array
    return combineLatest([...tagDataFetches, of(null)]).pipe(
      map(tagsResults => this.mapEntitiesTags(entities, fetchableTags, tagsResults))
    );
  }

  formatEntityTags(entity: TaggedEntity): Observable<TaggedEntity> {
    return this.formatEntitiesTags([entity]).pipe(map(entities => entities[0]));
  }

  equals(tag1: Tag, tag2: Tag): boolean {
    return tag1.id === tag2.id && tag1.type === tag2.type;
  }

  getUniqueLabelledTags(tags: Tag[]): Tag[] {
    return [...new Map(tags.map(tag => [tag.type + tag.id, tag])).values()];
  }

  getUniqueFetchableTags(taggedEntities: TaggedEntity[]): Tag[] {
    return taggedEntities
      .flatMap(taggedEntity => taggedEntity.tags!)
      .filter(tag => FETCHABLE_TAG_TYPES.includes(tag!.type))
      .filter((currentTag, index, tags) => {
        const firstIndex = tags.findIndex(tag => this.equals(currentTag!, tag!));
        return firstIndex === index;
      });
  }

  // fetch all unique fetchable tags
  private fetchFetchableTags(taggedEntities: TaggedEntity[]): FetchableTagsAndTagData {
    const fetchableTags = this.getUniqueFetchableTags(taggedEntities);
    const tagDataFetches = fetchableTags.map(tag =>
      this.tagDefs[tag.type]!.fetch!(tag.id).pipe(
        // when encounted error in tag fetch, return null
        catchError(() => of(null))
      )
    );
    return { fetchableTags, tagDataFetches };
  }

  // return formatted tags base on tagDefsService
  private mapEntitiesTags(entities: TaggedEntity[], fetchableTags: Tag[], tagsResults: any[]): TaggedEntity[] {
    const formattedFetchedTags = tagsResults.length > 0 ? this.formatFetchedTags(fetchableTags, tagsResults) : [];
    return entities.map(entity => {
      const labelledTags = entity.tags!.flatMap((tag, _, tags) => {
        return FETCHABLE_TAG_TYPES.includes(tag.type)
          ? formattedFetchedTags.find(labelledTag => this.equals(labelledTag, tag))!
          : this.formatNonFetchableTag(tag, tags);
      });

      const entityWithFormattedTag = { ...entity, tags: this.getUniqueLabelledTags(labelledTags) };
      if ('message' in entity) {
        (entityWithFormattedTag as Log).message = formatMessage(entity.message, labelledTags);
      }
      return entityWithFormattedTag;
    });
  }

  // Formats a tag based on just the tags information, no fetched data is necessary.
  private formatNonFetchableTag(tag: Tag, tags: Tag[]): Tag {
    const tagDef = this.tagDefs[tag.type];

    if (tagDef) {
      const { getDisplayPrefix, getDisplayValue, getRouteLink, getRouteParams, getExternalRouteLink } =
        this.tagDefs[tag.type]!;
      return {
        ...tag,
        displayPrefix: getDisplayPrefix!(tag),
        displayValue: getDisplayValue!(tag),
        routeLink: getRouteLink ? getRouteLink(tags) : null,
        routeParams: getRouteParams ? getRouteParams(tags) : null,
        externalRouteLink: getExternalRouteLink ? getExternalRouteLink(tags) : null
      } as Tag;
    } else {
      return {
        ...tag,
        displayPrefix: this.customTitleCasePipe.transform(tag.type, 'fromSnakeCase'),
        displayValue: tag.id,
        routeLink: null,
        routeParams: null,
        externalRouteLink: null
      } as Tag;
    }
  }

  private formatFetchableTag(tag: Tag, tagResultValue: any): Tag {
    const { getDisplayPrefix, getDisplayValue, getRouteLink } = this.tagDefs[tag.type]!;
    return {
      ...tag,
      displayPrefix: getDisplayPrefix!(tagResultValue),
      displayValue: tagResultValue ? getDisplayValue!(tagResultValue) : tag.id,
      routeLink: tagResultValue ? getRouteLink!(tagResultValue) : null
    } as Tag;
  }

  // Formats tags whose data was fetched via store or API call.
  // An important assumption is that the tags[] array and the tagsResults[] array contain tags and their fetch results in the same order.
  private formatFetchedTags(tags: Tag[], tagsResults: any[]): Tag[] {
    return tags.map((tag, index) => {
      const tagResultValue = tagsResults[index];
      return this.formatFetchableTag(tag, tagResultValue);
    });
  }
}
