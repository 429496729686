import { MfaIdentity, MfaIdentityState } from '../types';

export const mockMfaIdentity: MfaIdentity = {
  tenantId: 'AscendaGo',
  uid: '+6587373781',
  providerId: 'sms',
  userId: '1234-abcd-4321',
  id: '96a75f84-b3bb-4407-bb63-6492b0cd2ce0',
  customData: {
    verified: true,
    agentCreated: true
  },
  verified: true
};

export const mockMfaIdentities: MfaIdentity[] = [mockMfaIdentity];

export function generateMfaIdentityState(mfaIdentities: MfaIdentity[] = []): MfaIdentityState {
  return {
    ids: mfaIdentities.map(mfaIdentity => mfaIdentity.id),
    entities: mfaIdentities.reduce((dict, mfaIdentity) => {
      dict[mfaIdentity.id] = mfaIdentity;
      return dict;
    }, {}),
    callState: {
      single: 'resting',
      batch: 'resting'
    }
  };
}
