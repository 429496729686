// ref:
// https://stackoverflow.com/questions/21109011/javascript-unicode-string-chinese-character-but-no-punctuation/21113538
// https://stackoverflow.com/questions/15033196/using-javascript-to-check-whether-a-string-contains-japanese-characters-includi/15034560#15034560
// https://stackoverflow.com/questions/38156300/regex-how-do-you-match-korean-hangul-letters-in-javascript-es6?noredirect=1&lq=1

const REGEX_CHINESE = new RegExp(
  [
    '[\u4E00-\u9Fa5\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|',
    '[\ud840-\ud868][\udc00-\udfff]|',
    '\ud869[\udc00-\uded6\udf00-\udfff]|',
    '[\ud86a-\ud86c][\udc00-\udfff]|',
    '\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]|',
    '[\\u{2B820}-\\u{2CEAF}]|',
    '[\\u{2CEB0}-\\u{2EBEF}]'
  ].join(''),
  'ui'
);
const REGEX_JAPANESE =
  /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/iu;
const REGEX_KOREAN = /[\u3131-\uD79D]/iu;

export class RegExpUtils {
  static hasCJKCharacter(str: string): boolean {
    return REGEX_CHINESE.test(str) || REGEX_JAPANESE.test(str) || REGEX_KOREAN.test(str);
  }
}
