import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { NotesFilter } from '../types/notes-filter.type';
import { NotesResult } from '../types/notes-result.type';
import { Note } from '../types/notes.type';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(private missionControlService: MissionControlService) {}

  getNotes(filter: NotesFilter): Observable<NotesResult> {
    filter = ObjectUtils.sanitizeRequestObject<NotesFilter>(filter);

    return this.missionControlService.post<NotesResult>('notes/search', filter);
  }

  createNote(note: Note): Observable<Note> {
    note = ObjectUtils.sanitizeRequestObject<Note>(note);

    return this.missionControlService.post<Note>('notes', note);
  }

  updateNote(note: Note): Observable<Note> {
    note = ObjectUtils.sanitizeRequestObject<Note>(note, { ignoredKeys: ['expiresAt', 'tags'] });

    return this.missionControlService.patch<Note>(`notes/${note.id}`, note);
  }

  deleteNote(id: string, entity: string): Observable<void> {
    return this.missionControlService.delete<void>(`notes/${id}`, new HttpParams({ fromObject: { entity } }));
  }

  getUniqueAdminIds(notes: Note[]): string[] {
    // some notes migrated from other system may have empty adminUserId, we want to filter them out
    return Array.from(new Set(notes.map(note => note.adminUserId))).filter(id => !!id);
  }
}
