import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, tooltipAdapter, TooltipState } from '../../types/tooltips.type';
import {
  loadTooltips,
  loadTooltipsFailure,
  loadTooltipsSuccess,
  updateTooltips,
  updateTooltipsFailure,
  updateTooltipsSuccess
} from '../actions/tooltips.actions';

export const tooltipTriggers: EntityTriggers = {
  batch: {
    loading: [loadTooltips.type, updateTooltips.type],
    resting: [loadTooltipsSuccess.type, updateTooltipsSuccess.type],
    erroring: [loadTooltipsFailure.type, updateTooltipsFailure.type]
  }
};

export function tooltipsReducer(state: TooltipState = initialState, action: Action): TooltipState {
  return callStateReducer(baseReducer, tooltipTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadTooltips, (state, { module }) => ({ ...state, fetchList: [...state.fetchList, module] })),
  on(loadTooltipsSuccess, (state, { tooltips }) => tooltipAdapter.upsertMany(tooltips, state)),
  on(updateTooltipsSuccess, (state, { tooltips }) => tooltipAdapter.upsertMany(tooltips, state))
);
