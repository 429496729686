import { getLastActivityTimestamp, updateLastActivityTimestamp } from '../local-storage-util';
import { isVisible } from '../util';
var UserInteractionsHandler = /** @class */ (function () {
    function UserInteractionsHandler(options) {
        this.onTimeout = options.idleCheck.onTimeout;
        this.onActivityRestored = options.idleCheck.onActivityRestored;
        this.dialogElementId = options.idleCheck.dialogElementId;
        this.timeoutMs = options.idleCheck.timeout * 1000;
        this.promptTimeoutMs = options.idleCheck.promptTimeout * 1000;
        this.pollIntervalMs = options.idleCheck.pollInterval * 1000;
        this.interrupts = options.idleCheck.interrupts;
    }
    UserInteractionsHandler.prototype.init = function () {
        var _this = this;
        var listenerTypes = [];
        if (this.interrupts.includes('input')) {
            listenerTypes.push('mousemove', 'mousedown', 'touchstart', 'touchmove', 'keydown');
        }
        if (this.interrupts.includes('scroll')) {
            listenerTypes.push('scroll', 'wheel');
        }
        if (this.interrupts.includes('nav')) {
            listenerTypes.push('popstate');
        }
        listenerTypes.forEach(function (type) {
            window.addEventListener(type, function () {
                if (!_this.isDialogOpen()) {
                    // don't start checking for idleness when dialog is open
                    // because user has to close the dialog by an exact action (e.g. Continue button)
                    _this.startTimer();
                }
            });
        });
        this.startTimer();
    };
    UserInteractionsHandler.prototype.startTimer = function () {
        window.clearTimeout(this.idleLoopTimer);
        updateLastActivityTimestamp();
        this.checkIdleLoop();
    };
    UserInteractionsHandler.prototype.checkIdleLoop = function () {
        var _this = this;
        this.checkIdleTimeout();
        this.idleLoopTimer = window.setTimeout(function () { return _this.checkIdleLoop(); }, this.pollIntervalMs);
    };
    UserInteractionsHandler.prototype.checkIdleTimeout = function () {
        var isIdleTimeout = Date.now() > (getLastActivityTimestamp() + this.timeoutMs);
        if (isIdleTimeout && !this.isDialogOpen()) {
            this.onTimeout();
        }
        else if (!isIdleTimeout && this.isDialogOpen()) {
            this.onActivityRestored();
        }
    };
    UserInteractionsHandler.prototype.getDialogRemainingSeconds = function () {
        var remainingMs = getLastActivityTimestamp() + this.timeoutMs + this.promptTimeoutMs - Date.now();
        return Math.round(remainingMs / 1000);
    };
    UserInteractionsHandler.prototype.isDialogOpen = function () {
        var dialogElement = document.getElementById(this.dialogElementId);
        return dialogElement ? isVisible(dialogElement) : false;
    };
    UserInteractionsHandler.prototype.onDialogClosed = function () {
        updateLastActivityTimestamp();
    };
    return UserInteractionsHandler;
}());
export { UserInteractionsHandler };
