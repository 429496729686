import { createSelector } from '@ngrx/store';

import { CoreState, getCoreState } from '@core/store';
import { getCallStateSelectors } from '@core/store/call-state';
import { TenantBootstrapState } from '@core/types';

import { OpenApiSchema } from '../../../../form-fields/types/open-api-schema.type';

const getTenantBootstrapState = createSelector(getCoreState, (state: CoreState) => state.tenantBootstrap);

const { isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<TenantBootstrapState>(getTenantBootstrapState);

const getTenantBootstrap = createSelector(
  getTenantBootstrapState,
  (state: TenantBootstrapState) => state.tenantBootstrap
);

const getEnrollmentOpenApiSchema = createSelector(
  getTenantBootstrapState,
  (state: TenantBootstrapState) => state.tenantBootstrap.enrollmentOpenApi?.schema
);

const isTenantBootstrapLoaded = createSelector(
  getEnrollmentOpenApiSchema,
  (enrollmentOpenApi: OpenApiSchema) => !!enrollmentOpenApi
);

export const tenantBootstrapQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  getTenantBootstrap,
  isTenantBootstrapLoaded,
  getEnrollmentOpenApiSchema
};
