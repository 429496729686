import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, mcTranslationAdapter, McTranslationState } from '../../types';
import {
  bulkUpdateMcTranslations,
  bulkUpdateMcTranslationsFailure,
  bulkUpdateMcTranslationsSuccess,
  loadMcTranslations,
  loadMcTranslationsFailure,
  loadMcTranslationsSuccess
} from '../actions/mc-translations.actions';

export const mcTranslationTriggers: EntityTriggers = {
  batch: {
    loading: [loadMcTranslations.type, bulkUpdateMcTranslations.type],
    resting: [loadMcTranslationsSuccess.type, bulkUpdateMcTranslationsSuccess.type],
    erroring: [loadMcTranslationsFailure.type, bulkUpdateMcTranslationsFailure.type]
  }
};

export function mcTranslationsReducer(state: McTranslationState = initialState, action: Action): McTranslationState {
  return callStateReducer(baseReducer, mcTranslationTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadMcTranslationsSuccess, (state, { mcTranslations }) => mcTranslationAdapter.setAll(mcTranslations, state))
);
