import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { AnalyticsState, initialState } from '../../types/analytics.type';
import { loadCustomerToken, loadCustomerTokenFailure, loadCustomerTokenSuccess } from '../actions/analytics.actions';

export const analyticsTriggers: EntityTriggers = {
  single: {
    loading: [loadCustomerToken.type],
    resting: [loadCustomerTokenSuccess.type],
    erroring: [loadCustomerTokenFailure.type]
  }
};

export function analyticsReducer(state: AnalyticsState = initialState, action: Action): AnalyticsState {
  return callStateReducer(baseReducer, analyticsTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadCustomerTokenSuccess, (state, { customerToken }) => ({ ...state, customerToken }))
);
