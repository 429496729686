import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Hermes } from '@kaligo/hermes';
import { Store } from '@ngrx/store';

import { logoutUser } from '@core/store/auth/actions/auth.actions';

@Component({
  selector: 'admin-user-idle-dialog',
  templateUrl: './user-idle-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserIdleDialogComponent implements OnInit, OnDestroy {
  remainingSeconds: number;

  timeUpdateInterval: number;

  constructor(
    private dialogRef: MatDialogRef<UserIdleDialogComponent>,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { hermes: Hermes }
  ) {
    dialogRef.disableClose = true;
  }

  onContinueClick(): void {
    this.stopRemainingSecondsUpdate();
    this.data.hermes.onIdleDialogClosed();
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.remainingSeconds = this.data.hermes.getIdleDialogRemainingSeconds();
    this.timeUpdateInterval = window.setInterval(() => this.updateRemainingSeconds(), 1000);
  }

  ngOnDestroy(): void {
    this.stopRemainingSecondsUpdate();
  }

  updateRemainingSeconds(): void {
    this.remainingSeconds = this.data.hermes.getIdleDialogRemainingSeconds();

    if (this.remainingSeconds <= 0) {
      this.remainingSeconds = 0;
      this.stopRemainingSecondsUpdate();
      this.logout();
    }

    this.cdRef.markForCheck();
  }

  logout(): void {
    this.store.dispatch(logoutUser());
  }

  stopRemainingSecondsUpdate(): void {
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
      this.timeUpdateInterval = null;
    }
  }
}
