<mat-chip-grid #list [required]="required" [disabled]="disabled">
  <mat-chip-row
    *ngFor="let item of value; let i = index"
    color="primary"
    [class.duplicate-chip]="duplicateValues.has(item)"
    [disabled]="nonRemovableValues?.includes(item) || (selectableValues && !selectableValues.includes(item))"
    (removed)="removeChip(i)"
  >
    {{ valueFormattings?.[item] || item }}
    <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
  </mat-chip-row>
  <input
    #input
    #autocompleteTrigger
    [placeholder]="disabled ? '' : placeholder"
    [matAutocomplete]="autocomplete"
    [matChipInputFor]="list"
    [matChipInputAddOnBlur]="false"
    (matChipInputTokenEnd)="onChipTokenEnd($event.value)"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)"
    (paste)="onPaste($event)"
  />
</mat-chip-grid>
<mat-autocomplete #autocomplete (optionSelected)="selectAutocompleteOption($event.option.value)">
  <ng-container *ngFor="let value of ObjectUtils.removeArrayDuplicates(options)">
    <mat-option *ngIf="options.includes(value)" [value]="value">{{ valueFormattings?.[value] }}</mat-option>
  </ng-container>
</mat-autocomplete>
