import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ErrorActionTypes extends BehaviorSubject<Set<string>> {
  constructor(value: Set<string>) {
    super(value);
  }

  registerActions(actionTypes: string[]): void {
    this.next(new Set([...this.value, ...actionTypes]));
  }
}
