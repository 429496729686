import { Directive } from '@angular/core';

import { NavItemV2 } from '@core/types/nav-item-v2.type';

@Directive({
  selector: 'ng-template[adminNavList]'
})
export class SideNavV2ListTypeGuardDirective {
  static ngTemplateContextGuard(
    _dir: SideNavV2ListTypeGuardDirective,
    ctx: unknown
  ): ctx is { $implicit: NavItemV2[]; isSubItem: boolean } {
    return true;
  }
}
