<div class="merchandise-order-item">
  <!--   first section   -->
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <h3>Merchandise: {{ data?.productName }} x{{ orderItem.quantity }}</h3>
    <admin-refund-order-button [orderItem]="orderItem" fxLayout="row wrap"></admin-refund-order-button>
  </div>
  <div class="details-container padding-y-20">
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Status</h4>
        </div>
        <span class="item__value">{{ (orderItem.status | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Categories</h4>
        </div>
        <span class="item__value">{{ ((merchandise$ | async)?.categories | arrayJoin | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Delivery method</h4>
        </div>
        <span class="item__value">{{ ((merchandise$ | async)?.deliveryMethod | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Supplier</h4>
        </div>
        <span class="item__value">{{ (data.supplier | customTitleCase: 'fromSnakeCase') || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key" fxLayoutGap="5px" fxLayoutAlign="start start">
          <h4>Supplier Reference ID</h4>
          <admin-tooltip key="orders-items.details.giftCardOrderItem.externalSupplierOrderReference"></admin-tooltip>
        </div>
        <span class="item__value item-with-btn" fxLayout="row" fxLayoutAlign="start center">
          {{ data.externalSupplierOrderReference || 'N/A' }}
          <admin-copy-to-clipboard
            *ngIf="data.externalSupplierOrderReference"
            [content]="data.externalSupplierOrderReference"
            contentName="Supplier Reference ID"
            styleClassName="detail-copy-btn"
          ></admin-copy-to-clipboard>
        </span>
      </div>
      <div class="item">
        <div class="item__key" fxLayoutGap="5px" fxLayoutAlign="start start">
          <h4>Supplier Reference Item ID</h4>
        </div>
        <span class="item__value item-with-btn" fxLayout="row" fxLayoutAlign="start center">
          {{ data.externalSupplierOrderItemReference || 'N/A' }}
          <admin-copy-to-clipboard
            *ngIf="data.externalSupplierOrderItemReference"
            [content]="data.externalSupplierOrderItemReference"
            contentName="Supplier Reference Item ID"
            styleClassName="detail-copy-btn"
          ></admin-copy-to-clipboard>
        </span>
      </div>
    </div>
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Customer ID</h4>
        </div>
        <span class="item__value">{{ customerId || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Ascenda ID</h4>
        </div>
        <span class="item__value">
          <a
            *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
            [routerLink]="'/customers/' + orderItem.userId + detailsView"
          >
            {{ orderItem.userId }}
          </a>
          <ng-template #userIdWithoutLink>
            {{ orderItem.userId }}
          </ng-template>
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Order initiator</h4>
        </div>
        <span class="item__value">{{ (data.orderSourceType | customTitleCase: 'fromSnakeCase') || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Created at</h4>
        </div>
        <span
          *ngIf="orderItem.createdAt; else showNA"
          class="item__value"
          attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
        >
          {{ orderItem.createdAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Updated at</h4>
        </div>
        <span
          *ngIf="orderItem.updatedAt; else showNA"
          class="item__value"
          attr.data-tooltip="timezone: UTC {{ timezone | timezoneDisplay }}"
        >
          {{ orderItem.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' : timezone }}
        </span>
      </div>
      <div class="item">
        <div class="item__key"><h4>Product Image</h4></div>
        <span class="item__value">
          <a [href]="(merchandise$ | async)?.image" target="_blank" class="product-image">
            <img mat-card-image class="logo-icon-image" [src]="(merchandise$ | async)?.image" alt="Product Image" />
          </a>
        </span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!--   second section   -->
  <div class="title"><p>Delivery details</p></div>
  <div class="details-container padding-y-20">
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>First name</h4>
        </div>
        <span class="item__value">{{ (recipient?.firstName | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Last name</h4>
        </div>
        <span class="item__value">{{ (recipient?.lastName | titlecase) || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Email</h4>
        </div>
        <span class="item__value">{{ recipient?.email || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Tracking ID</h4>
        </div>
        <div
          class="item__value"
          fxLayout="row wrap"
          fxLayoutGap="10px"
          fxLayoutAlign="start start"
          *ngIf="data.fulfillmentData?.trackingId; else showNA"
        >
          <span>{{ data.fulfillmentData?.trackingId }}</span>
          <a
            *ngIf="data.fulfillmentData?.trackingUrl"
            [href]="data.fulfillmentData?.trackingUrl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Track delivery <mat-icon class="launch-icon">launch</mat-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Message</h4>
        </div>
        <span class="item__value">{{ recipient?.message || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Phone</h4>
        </div>
        <span class="item__value">{{ recipient?.phoneNumber || 'N/A' }}</span>
      </div>
      <div class="item" *ngIf="recipient?.address">
        <div class="item__key">
          <h4>Address</h4>
        </div>
        <span class="item__value">{{ formattedAddress }}</span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!--   third section   -->
  <div class="title"><p>Item details</p></div>
  <div class="details-container padding-y-20">
    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Quantity</h4>
        </div>
        <span class="item__value">{{ orderItem.quantity ? 'x' + orderItem.quantity : 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>SKU</h4>
        </div>
        <span class="item__value">{{ data.sku || 'N/A' }}</span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Remarks</h4>
        </div>
        <span class="item__value">{{ data?.remarks || 'N/A' }}</span>
      </div>
    </div>

    <div class="column">
      <div class="item">
        <div class="item__key">
          <h4>Total charge</h4>
        </div>
        <span class="item__value">
          {{ orderItem.pointsPaid | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
          }}{{ data?.currency === '$' ? '' : ' '
          }}{{ orderItem.cashPaid | formatCurrency: data?.currency | async | number }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Original charge</h4>
        </div>
        <span class="item__value">
          {{ orderItem.pointsAmount | number: '1.0-2' }} {{ loyaltyCurrency }} + {{ data?.currency
          }}{{ data?.currency === '$' ? '' : ' '
          }}{{ orderItem.cashAmount | formatCurrency: data?.currency | async | number }}
        </span>
      </div>
      <div class="item">
        <div class="item__key">
          <h4>Promo code</h4>
        </div>
        <span class="item__value">{{ data?.promoCode || 'N/A' }}</span>
      </div>
    </div>
  </div>

  <ng-template #showNA>
    <span class="item__value">N/A</span>
  </ng-template>
</div>
