import { Action, createReducer, on } from '@ngrx/store';

import { AccessPolicy } from '../../../../access-policies/types/access-policies.type';
import { RequestParams } from '../../../../approval-requests/types/approval-requests.type';
import { updateApprovalRequestedAction, updateStepUpAction } from '../actions/interrupted.actions';

export interface ApprovalRequestedAction extends Action {
  accessPolicy?: AccessPolicy;
  payload?: RequestParams;
  pendingCount?: number;
}
// Stores actions that are interrupted (stalled) until some user interaction (e.g. closing dialog) is done.
export interface InterruptedState {
  stepUpAction: Action;
  approvalRequestedAction: ApprovalRequestedAction;
}

const initialState: InterruptedState = {
  stepUpAction: null,
  approvalRequestedAction: null
};

export const interruptedReducer = createReducer(
  initialState,
  on(updateApprovalRequestedAction, (state, { action }) => ({ ...state, approvalRequestedAction: action })),
  on(updateStepUpAction, (state, { action }) => ({ ...state, stepUpAction: action }))
);
