import { Action, createReducer } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { pointsAdjustmentInitialState, PointsAdjustmentState } from '../../types';
import {
  makePointsAdjustment,
  makePointsAdjustmentFailure,
  makePointsAdjustmentSuccess
} from '../actions/points-adjustments.actions';

const pointsAdjustmentTriggers: EntityTriggers = {
  single: {
    loading: [makePointsAdjustment.type],
    resting: [makePointsAdjustmentSuccess.type],
    erroring: [makePointsAdjustmentFailure.type]
  }
};

export function pointsAdjustmentsReducer(
  state: PointsAdjustmentState = pointsAdjustmentInitialState,
  action: Action
): PointsAdjustmentState {
  return callStateReducer(baseReducer, pointsAdjustmentTriggers)(state, action);
}

const baseReducer = createReducer(pointsAdjustmentInitialState);
