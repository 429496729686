import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export const customDomainStatusDisplayClass = {
  successful: 'status-active',
  processing: 'status-pending',
  failed: 'status-inactive'
};

export const customDomainAdapter = createEntityAdapter<CustomDomain>();

export interface CustomDomain {
  domain: string;
}

export interface CustomDomainResponse {
  // reponse body to be updated later
  domain: string;
}

export interface CustomDomainState extends EntityState<CustomDomain>, CallState {
  customDomain: CustomDomain;
}

export const initialState: CustomDomainState = {
  ...customDomainAdapter.getInitialState<CallState>(initialCallState),
  customDomain: {
    domain: ''
  }
};
