import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { loginAttemptsQuery } from '../store/selectors/login-attempts.selectors';
import { LoginAttemptsFilter } from '../types/login-attempts-filter.type';
import { LoginAttemptState } from '../types/login-attempts.type';

export const loginAttemptsFilterResolver: ResolveFn<LoginAttemptsFilter> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<LoginAttemptState> = inject(Store<LoginAttemptState>)
): Observable<LoginAttemptsFilter> =>
  store.pipe(
    select(loginAttemptsQuery.getFilter),
    first(),
    map(filter => filter || new LoginAttemptsFilter())
  );
