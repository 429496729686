import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';
import { HttpError } from '@core/types';

import { IdentitiesService } from '../../services/identities.service';
import {
  createIdentity,
  createIdentityFailure,
  createIdentitySuccess,
  deleteIdentity,
  deleteIdentityFailure,
  deleteIdentitySuccess,
  loadIdentities,
  loadIdentitiesFailure,
  loadIdentitiesSuccess,
  verifyIdentity,
  verifyIdentityFailure,
  verifyIdentitySuccess
} from '../actions/identities.actions';

@Injectable()
export class IdentitiesEffects {
  loadIdentities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadIdentities),
      exhaustMap(action =>
        this.identitiesService.getIdentities(action.userId).pipe(
          map(identities => loadIdentitiesSuccess({ identities })),
          catchError((error: HttpError) => of(loadIdentitiesFailure({ error })))
        )
      )
    )
  );

  createIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createIdentity),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(({ params, dialogRefId }) =>
        this.identitiesService.createIdentity(params).pipe(
          map(() => createIdentitySuccess({ userId: params.userId, dialogRefId })),
          catchError(error => of(createIdentityFailure({ error })))
        )
      )
    )
  );

  createIdentitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createIdentitySuccess),
      map(({ userId, dialogRefId }) => {
        this.matDialog.getDialogById(dialogRefId)?.close(true);
        return loadIdentities({ userId });
      })
    )
  );

  verifyIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyIdentity),
      exhaustMap(action =>
        this.identitiesService.verifyIdentity(action.mfaIdentityId).pipe(
          map(() => verifyIdentitySuccess()),
          catchError((error: HttpError) => of(verifyIdentityFailure({ error })))
        )
      )
    )
  );

  verifyIdentitySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyIdentitySuccess),
        tap(() =>
          this.snackBar.open('Trigger account verification successfully!', 'Dismiss', {
            panelClass: 'mat-snack-bar-success'
          })
        )
      ),
    { dispatch: false }
  );

  verifyIdentityFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyIdentityFailure),
        tap(action => this.snackBar.open(action.error.message, 'Dismiss'))
      ),
    { dispatch: false }
  );

  deleteIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIdentity),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(({ reference, user }) =>
        this.identitiesService.deleteIdentity(reference).pipe(
          map(() => deleteIdentitySuccess({ reference, user })),
          catchError(error => of(deleteIdentityFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private matDialog: MatDialog,
    private identitiesService: IdentitiesService,
    private snackBar: MatSnackBar,
    private store: Store
  ) {}
}
