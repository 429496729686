import { map, mergeMap } from 'rxjs/operators';

import { AfterViewInit, Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, debounceTime, filter, Observable, of, pairwise, startWith, tap } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { nydusNetworkBootstrapQuery } from '@core/store/nydus-network/selectors/nydus-network-bootstrap.selectors';
import { RedemptionType, SCOPES_OR } from '@core/types';
import { DashedDateAdapter, DateUtils, FilterFormUtils, or, Params } from '@utils';

import { uuidValidator } from '../../../../validators';
import { pointsAccountsQuery } from '../../../dashboard/store/selectors/points-accounts.selectors';
import { PointsAccountState } from '../../../dashboard/types';
import { SelectTypeDialogComponent } from '../../../shared/components/select-type-dialog/select-type-dialog.component';
import { usersQuery } from '../../../users/store/selectors/users.selectors';
import { UserState } from '../../../users/types';
import { loadOrderItems } from '../../store/actions/order-items.actions';
import { orderItemsQuery } from '../../store/selectors/order-items.selectors';
import {
  OrderItem,
  OrderItemForm,
  OrderItemsFilter,
  OrderItemState,
  OrderItemStatus,
  orderItemStatusDisplayClass,
  OrderItemTypeCodeForFilter
} from '../../types';

interface OrderItemFilterFormValue {
  id: string; // order item id
  orderId: string;
  userId: string;
  type: string;
  description: string;
  status: string;
  dateTo: Date;
  dateFrom: Date;
}

@Component({
  selector: 'admin-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DashedDateAdapter
    }
  ]
})
export class OrderItemsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  destroyRef = inject(DestroyRef);

  orderItemsCount$: Observable<number>;
  loading$: Observable<boolean>;

  tooltipMessage$: Observable<string>;
  redemptionTypes$: Observable<RedemptionType[]>;
  redemptionTypes: RedemptionType[];

  columns = [
    'orderId',
    'description',
    'type',
    'id',
    'externalSupplierOrderReference',
    'externalSupplierOrderItemReference',
    'userId',
    'createdAt',
    'status'
  ];
  filter: OrderItemsFilter = this.route.snapshot.data.orderItemsFilter;
  filterForm: FormGroup<OrderItemForm>;
  filteredOrderItems: MatTableDataSource<OrderItem>;
  isUserView: boolean;
  userId: string;
  orderItemStatusDisplayClass = orderItemStatusDisplayClass;
  statuses = Object.values(OrderItemStatus);
  typeCodeForFilter = OrderItemTypeCodeForFilter;

  datepickerAdaptor = DateUtils.datepickerAdaptor;
  getTenantEndOfDay = DateUtils.getTenantEndOfDay;

  readonly SCOPES = SCOPES_OR;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private scopes: Scopes,
    private matDialog: MatDialog,
    private store: Store<OrderItemState>,
    private pointsAccountStore: Store<PointsAccountState>,
    private userStore: Store<UserState>,
    @Inject('timezone') public timezone: string
  ) {}

  ngOnInit(): void {
    this.isUserView = this.route.snapshot.data.isUserOrdersItemsView;

    if (this.isUserView) {
      this.userId = Params.find(this.route, 'userId');
    }

    this.timezone ??= '+0000';
    this.loading$ = or(
      this.store.select(orderItemsQuery.isSingleLoading),
      this.store.select(orderItemsQuery.isBatchLoading),
      this.userStore.select(usersQuery.isSingleLoading)
    );

    if (this.scopes.hasAny(SCOPES_OR.showOrderItems)) {
      this.columns.push('action');
    }

    if (this.scopes.has('admin_checkout')) {
      this.setupRedemptionButton();
    }

    this.prepareFilterForm();
    this.disableExclusiveControls();

    this.subscribeToOrderItems();
  }

  ngAfterViewInit(): void {
    this.subscribeToMatSortChange();
    this.subscribeToInputChanges();
  }

  prepareFilterForm(): void {
    this.filterForm = this.fb.group({
      id: this.fb.control('', uuidValidator()), // order item id
      orderId: this.fb.control(''),
      externalSupplierOrderReference: this.fb.control(''),
      externalSupplierOrderItemReference: this.fb.control(''),
      userId: this.fb.control('', uuidValidator()),
      type: this.fb.control(''),
      description: this.fb.control(''),
      status: this.fb.control(''),
      dateTo: this.fb.control(''),
      dateFrom: this.fb.control('')
    });

    this.filterForm.patchValue(this.filter);
  }

  subscribeToOrderItems(): void {
    // force to execute code below when there is single error from loadOrderItem action
    combineLatest([
      this.store.select(orderItemsQuery.getOrderItemsList),
      this.store.select(orderItemsQuery.getSingleError).pipe(startWith(null))
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([orderItems]) => {
        if (this.filter.id) {
          const orderItem = orderItems.find(item => item.id === this.filter.id);
          const orderItemData = orderItem ? [orderItem] : [];
          this.filteredOrderItems = new MatTableDataSource(orderItemData);
          this.orderItemsCount$ = of(orderItemData.length);
        } else {
          this.filteredOrderItems = new MatTableDataSource(orderItems);
          this.orderItemsCount$ = this.store.select(orderItemsQuery.getOrderItemsCount);
        }
      });
  }

  subscribeToInputChanges(): void {
    const handleIdSearch = FilterFormUtils.createIdSearchHandler<OrderItemFilterFormValue>(id => this.searchWithId(id));
    const handleRestValueSearch = FilterFormUtils.createOtherValueSearchHandler<OrderItemFilterFormValue>(
      filterFormValue => this.searchWithoutId(filterFormValue)
    );

    this.filterForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.filter),
        tap(() => {
          this.disableExclusiveControls();
        }),
        filter(() => !!this.filterForm.valid),
        debounceTime(1000),
        pairwise() // will skip the first value since there is no value to compare
      )
      .pipe(
        mergeMap(pairValues => handleIdSearch(pairValues)),
        mergeMap(pairValues => handleRestValueSearch(pairValues))
      )
      .subscribe();
  }

  subscribeToMatSortChange(): void {
    this.sort.sortChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      this.updateFilter({
        ...this.filter,
        page: 1,
        sortBy: event.direction ? event.active : '',
        sortDirection: event.direction
      });
    });
  }

  searchWithoutId(filterFormValue: OrderItemFilterFormValue): void {
    const { dateTo, dateFrom, id, ...rest } = filterFormValue;
    this.filterForm.patchValue({ id: '' });

    this.enableExclusiveControls();
    this.updateFilter({
      ...rest,
      id: '',
      page: 1,
      dateTo: this.getTenantEndOfDay(dateTo?.toString(), this.timezone),
      dateFrom: this.datepickerAdaptor('DatepickerToUTC', dateFrom?.toString(), this.timezone)?.toISOString()
    });
  }

  searchWithId(id: string): void {
    this.filterForm.enable({ emitEvent: false });
    const expectedFilter = {
      id,
      orderId: '',
      externalSupplierOrderReference: '',
      externalSupplierOrderItemReference: '',
      userId: '',
      type: '',
      description: '',
      status: '',
      dateTo: '',
      dateFrom: ''
    };

    this.updateFilter({ ...new OrderItemsFilter(), ...expectedFilter });
    this.filterForm.patchValue({ ...expectedFilter });
  }

  onPage(pageEvent: PageEvent): void {
    this.updateFilter({
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    });
  }

  updateFilter(params: object): void {
    this.filter = {
      ...this.filter,
      ...params,
      ...(this.userId && { userId: this.userId }),
      isTopLevelView: !!this.filter.isTopLevelView
    };

    this.store.dispatch(loadOrderItems({ filter: this.filter }));
  }

  setupRedemptionButton(): void {
    this.redemptionTypes$ = this.store.select(nydusNetworkBootstrapQuery.getNydusNetworkBootstrapRedemptionTypes);

    this.tooltipMessage$ = combineLatest([
      this.userStore.select(usersQuery.getUserPropertyById(this.filter.userId, ['status'])),
      this.pointsAccountStore.select(pointsAccountsQuery.getSelectedPointsAccount),
      this.pointsAccountStore.select(pointsAccountsQuery.isBatchLoading),
      this.pointsAccountStore.select(pointsAccountsQuery.getBatchError),
      this.redemptionTypes$
    ]).pipe(
      map(([userState, data, loading, error, redemptionTypes]) => {
        this.redemptionTypes = redemptionTypes;

        if (userState !== 'active') {
          return 'Redemption is not supported for non-active customer';
        } else if (error) {
          return 'Loading data failed';
        } else if (loading) {
          return 'Loading data';
        } else if (!data) {
          return (
            'Customer does not have points accounts loaded in the system. ' +
            'Please wait until customer accounts are loaded via files or contact tech support.'
          );
        } else if (redemptionTypes?.length === 0) {
          return 'No redemption type available';
        } else {
          return null;
        }
      })
    );
  }

  openNewRedemptionDialog(): void {
    this.matDialog.open(SelectTypeDialogComponent, {
      data: {
        title: 'Create new redemption',
        label: 'Redemption Type',
        types: this.redemptionTypes,
        redirectPathPrepend: window.location.pathname + '/create'
      }
    });
  }

  private disableExclusiveControls(): void {
    const { description: descriptionControl, orderId: orderIdControl } = this.filterForm.controls;

    if (descriptionControl.value && orderIdControl.enabled) {
      orderIdControl.disable({ emitEvent: false });
    } else if (orderIdControl.value && descriptionControl.enabled) {
      descriptionControl.disable({ emitEvent: false });
    }
  }

  private enableExclusiveControls(): void {
    const { description: descriptionControl, orderId: orderIdControl } = this.filterForm.controls;

    if (!descriptionControl.value && orderIdControl.disabled) {
      orderIdControl.enable({ emitEvent: false });
    } else if (!orderIdControl.value && descriptionControl.disabled) {
      descriptionControl.enable({ emitEvent: false });
    }
  }
}
