import { ImageType } from '@shared/types/image.type';

export class UploadUtils {
  static getImageUploadTypeTitle = (type: ImageType): string => {
    switch (type) {
      // Campaign, Offer
      case 'cover_image_url':
        return 'Upload Cover Image';
      case 'logo_url':
        return 'Upload Brand Logo';
      case 'main_category_icon_url':
        return 'Upload Main Category Icon';
      // Promo Code
      case 'desktop_image_url':
        return 'Upload Desktop Banner Image';
      case 'mobile_image_url':
        return 'Upload Mobile Banner Image';
    }
  };
}
