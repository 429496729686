import { createAction, props } from '@ngrx/store';

import { ErrorState, SharedConfig } from '@core/types';

export const loadSharedConfig = createAction('[Shared Config] Load Shared Config');
export const loadSharedConfigSuccess = createAction(
  '[Shared Config] Load Shared Config Success',
  props<{ sharedConfig: SharedConfig }>()
);
export const loadSharedConfigError = createAction('[Shared Config] Load Shared Config Error', props<ErrorState>());
