import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { UserToken, userTokenAdapter, UserTokenState } from '../../types';

const userTokensDetector = createDetector<UserToken>();
const getUserTokenState = createFeatureSelector<UserTokenState>('userToken');

const { selectAll: getUserTokensList, selectEntities: getUserTokensDirectory } =
  userTokenAdapter.getSelectors(getUserTokenState);

const {
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError
} = getCallStateSelectors<UserTokenState>(getUserTokenState);

const isUserTokensLoaded = (userId: string): Selector<UserTokenState, boolean> =>
  createSelector(
    getUserTokensDirectory,
    getUserTokensList,
    isBatchResting,
    (userTokens, userTokenList, batchResting) =>
      userTokensDetector('allLoaded')(userTokens) &&
      batchResting &&
      userTokenList.some(token => token.userId === userId)
  );

const getFilter = createSelector(getUserTokenState, (state: UserTokenState) => state.filter);

const getUserTokensTotalCount = createSelector(getUserTokenState, (state: UserTokenState) => state.count);

export const userTokensQuery = {
  isSingleInitial,
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getUserTokensList,
  isUserTokensLoaded,
  getUserTokensTotalCount,
  getFilter
};
