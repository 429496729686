import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { PointsAccountsFilter } from '../types/points-accounts-filter.type';
import { PointsAccountsResult } from '../types/points-accounts-result.type';
import { PointsAccount } from '../types/points-accounts.type';

@Injectable({
  providedIn: 'root'
})
export class PointsAccountsService {
  constructor(private missionControlService: MissionControlService) {}

  getPointsAccounts(filter: PointsAccountsFilter): Observable<PointsAccountsResult> {
    filter = ObjectUtils.sanitizeRequestObject<PointsAccountsFilter>(filter);
    const params = {
      limit: filter.limit.toString(),
      page: filter.page.toString()
    };

    return this.missionControlService.get<PointsAccountsResult>(
      'points_accounts',
      new HttpParams({ fromObject: { ...params } })
    );
  }

  getUniqueAdminIds(pointsAccounts: PointsAccount[]): string[] {
    // some points accounts migrated from other system may have empty userId, we want to filter them out
    return Array.from(new Set(pointsAccounts.map(pointsAccount => pointsAccount.userId))).filter(id => !!id);
  }
}
