import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { remoteFileInitialState, RemoteFileState } from '../../types';
import { loadRemoteFiles, loadRemoteFilesFailure, loadRemoteFilesSuccess } from '../actions/remote-files.actions';

export const remoteFileTriggers: EntityTriggers = {
  batch: {
    loading: [loadRemoteFiles.type],
    resting: [loadRemoteFilesSuccess.type],
    erroring: [loadRemoteFilesFailure.type]
  }
};

export function remoteFilesReducer(state: RemoteFileState = remoteFileInitialState, action: Action): RemoteFileState {
  return callStateReducer(baseReducer, remoteFileTriggers)(state, action);
}

const baseReducer = createReducer(
  remoteFileInitialState,
  on(loadRemoteFilesSuccess, (state, { remoteFiles }) => ({
    ...state,
    remoteFiles
  }))
);
