import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, retry } from 'rxjs/operators';

import { AbilityGroups } from '@core/types';

import { MissionControlService } from '../mission-control/mission-control.service';

const ABILITIES_RETRY_INTERVAL = 5000;
const ABILITIES_MAX_RETRIES = 3;

@Injectable({
  providedIn: 'root'
})
export class UserAbilitiesService {
  constructor(private missionControlService: MissionControlService) {}

  getUserAbilities(): Observable<AbilityGroups> {
    return this.missionControlService.get<AbilityGroups>('user_abilities').pipe(
      retry({
        delay: (error: any, retryCount: number) => {
          if (retryCount > ABILITIES_MAX_RETRIES) {
            return throwError(() => error);
          }
          return of(error).pipe(delay(ABILITIES_RETRY_INTERVAL));
        }
      })
    );
  }
}
