import { debounceTime, filter } from 'rxjs/operators';

import { DOCUMENT, TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';

interface LinkSection {
  name: string;
  links: Link[];
}

interface Link {
  id: string /* id of the section*/;
  type: string /* header type h3/h4 */;
  active: boolean /* If the anchor is in view of the page */;
  name: string /* name of the anchor */;
  top: number /* top offset px of the anchor */;
}

@Component({
  selector: 'admin-table-of-contents',
  styleUrls: ['./table-of-contents.component.scss'],
  templateUrl: './table-of-contents.component.html'
})
export class TableOfContentsComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input({ required: true }) childComponent: HTMLElement;
  @Input({ required: true }) header: string;
  @Input({ required: true }) selector: string;
  @Input() container: string | undefined;

  linkSections: LinkSection[] = [];
  links: Link[] = [];
  rootUrl = this.router.url.split('#')[0];

  readonly navigationEndEvents = this.router.events.pipe(
    filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
  );

  private scrollContainer: HTMLElement | Window | null = null;
  private urlFragment = '';
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    public titleCasePipe: TitleCasePipe
  ) {
    this.subscriptions.add(
      this.navigationEndEvents.subscribe(() => {
        const rootUrl = router.url.split('#')[0];
        if (rootUrl !== this.rootUrl) {
          this.rootUrl = rootUrl;
        }
      })
    );

    this.subscriptions.add(
      this.route.fragment.subscribe(fragment => {
        if (fragment != null) {
          this.urlFragment = fragment;

          const target = this.getHeaderWithFragment();
          if (target) {
            target.scrollIntoView();
          }
        }
      })
    );
  }

  ngOnInit(): void {
    // On init, the sidenav content element doesn't yet exist, so it's not possible
    // to subscribe to its scroll event until next tick (when it does exist).
    this.ngZone.runOutsideAngular(() => {
      Promise.resolve().then(() => {
        this.scrollContainer = this.container ? (this.document.querySelector(this.container) as HTMLElement) : window;

        if (this.scrollContainer) {
          this.subscriptions.add(
            fromEvent(this.scrollContainer, 'scroll')
              .pipe(debounceTime(10))
              .subscribe(() => this.onScroll())
          );
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.renderContent();
  }

  renderContent(): void {
    this.addHeaders(this.header, this.childComponent);
    this.updateScrollPosition();
  }

  ngOnDestroy(): void {
    this.resetHeaders();
    this.subscriptions.unsubscribe();
  }

  updateScrollPosition(): void {
    if (this.urlFragment) {
      const header = this.getHeaderWithFragment();
      if (header) {
        header.scrollIntoView();
      }
    }
  }

  resetHeaders(): void {
    this.linkSections = [];
    this.links = [];

    this.changeDetectorRef.detectChanges();
  }

  addHeaders(sectionName: string, docViewerContent: HTMLElement, sectionIndex: number = 0): void {
    const links = Array.from(docViewerContent.querySelectorAll(this.selector), header => {
      // remove the 'link' icon name from the inner text
      const name = ((header as HTMLElement).innerText || (header as HTMLElement).textContent)!
        .trim()
        .replace(/^link/, '');
      const { top } = header.getBoundingClientRect();
      return {
        name,
        type: header.tagName.toLowerCase(),
        top,
        id: name
          .replaceAll(/([a-z])([A-Z])/g, '$1-$2')
          .replaceAll(/[\s_]+/g, '-')
          .toLowerCase(), // convert to kebab-case for neater display on url
        active: false
      };
    });

    this.linkSections[sectionIndex] = { name: sectionName, links };
    this.links.push(...links);
  }

  getHeaderWithFragment(): Element {
    const toFind = this.titleCasePipe.transform(this.urlFragment.replaceAll('-', ' ')); // convert back to Title-case
    // eslint-disable-next-line unicorn/prefer-spread
    return Array.from(document.querySelectorAll(this.selector)).find(i => i.innerHTML.trim() === toFind.trim())!;
  }

  /** Gets the scroll offset of the scroll container */
  private getScrollOffset(): number | void {
    let { top } = this.element.nativeElement.getBoundingClientRect();
    top += 60; // add 60px to offset because distance from top
    const container = this.scrollContainer;

    if (container instanceof HTMLElement) {
      return container.scrollTop + top;
    }

    if (container) {
      return container.scrollY + top;
    }
  }

  private onScroll(): void {
    const scrollOffset = this.getScrollOffset();
    let hasChanged = false;

    for (let i = 0; i < this.links.length; i++) {
      // A link is considered active if the page is scrolled past the
      // anchor without also being scrolled passed the next link.
      const currentLink = this.links[i];
      const nextLink = this.links[i + 1];
      // Deduct 1 pixel to make clicking on link highlight correctly
      const isActive = +scrollOffset >= currentLink.top - 1 && (!nextLink || nextLink.top - 1 >= +scrollOffset);

      if (isActive !== currentLink.active) {
        currentLink.active = isActive;
        hasChanged = true;
      }
    }

    if (hasChanged) {
      // The scroll listener runs outside of the Angular zone so
      // we need to bring it back in only when something has changed.
      this.ngZone.run(() => this.changeDetectorRef.markForCheck());
    }
  }
}
