import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, ObjectUtils } from '@utils';

import { InvalidateTokenParams, UserTokensFilter, UserTokensResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class UserTokensService {
  constructor(private missionControlService: MissionControlService) {}

  getUserTokens(userId: string, filter: UserTokensFilter): Observable<UserTokensResult> {
    filter = ObjectUtils.sanitizeRequestObject<UserTokensFilter>(filter);
    const params = {
      ...filter,
      invalidated: filter.invalidated.toString(),
      limit: filter.limit.toString(),
      page: filter.page.toString()
    };

    if (filter.sortDirection && filter.sortBy) {
      params[`sortBy[${filter.sortBy}]`] = filter.sortDirection;
      delete params.sortBy;
      delete params.sortDirection;
    }

    return this.missionControlService.get<UserTokensResult>(
      `users/${userId}/tokens`,
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }

  invalidateUserToken(params: InvalidateTokenParams): Observable<void> {
    return this.missionControlService.post<void>(`users/${params.userId}/tokens/${params.id}/invalidate`, params);
  }
}
