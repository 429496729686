import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, exhaustMap, map, catchError, of, merge } from 'rxjs';

import { routerForceNavigate } from '@core/store/router/actions/router.actions';
import { loadSharedConfig } from '@core/store/shared-config/actions/shared-config.actions';
import { sharedConfigQuery } from '@core/store/shared-config/selectors/shared-config.selectors';
import { SharedConfigState } from '@core/types';

import { loadPartnerConfig } from '../../../partner-configs/store/actions/partner-configs.actions';
import { GiftCardsConfigService } from '../../services/gift-cards-config.service';
import { updateGiftCardsConfig, updateGiftCardsConfigSuccess, updateGiftCardsConfigFailure } from '../actions';
import { loadConfigurationChanges } from '../actions/configuration-changes.actions';

@Injectable()
export class GiftCardsConfigEffects {
  updateGiftCardsConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGiftCardsConfig),
      withLatestFrom(this.store.select(sharedConfigQuery.getVersion)),
      exhaustMap(([action, version]) =>
        this.giftCardsConfigService.updateGiftCardsConfig(action.payload, version).pipe(
          map(_ => updateGiftCardsConfigSuccess()),
          catchError(error => of(updateGiftCardsConfigFailure(error)))
        )
      )
    )
  );

  updateGiftCardsConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGiftCardsConfigSuccess),
      exhaustMap(() =>
        merge([
          loadSharedConfig(),
          loadPartnerConfig(),
          loadConfigurationChanges({
            params: {
              targets: ['gift_cards_config'],
              onlyLatest: true
            }
          }),
          // the data object is for the component to determine whether to display a success snackbar on Kafka widget close
          // and whether to reload gift cards config when switching to edit mode
          routerForceNavigate({
            path: 'rewards-offering/gift-cards-config',
            data: { success: true, reloadGiftCardsConfig: true }
          })
        ])
      )
    )
  );

  updateGiftCardsConfigFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateGiftCardsConfigFailure),
        map(() =>
          routerForceNavigate({
            path: 'rewards-offering/gift-cards-config',
            data: { success: false, reloadGiftCardsConfig: false }
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private giftCardsConfigService: GiftCardsConfigService,
    private store: Store<SharedConfigState>
  ) {}
}
