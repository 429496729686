import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { FraudList } from '../types/fraud-lists.type';

@Injectable({
  providedIn: 'root'
})
export class FraudListsService {
  constructor(private missionControlService: MissionControlService) {}

  getFraudList(id: string): Observable<FraudList> {
    return this.missionControlService.get<FraudList>(`fraud_lists/${id}`);
  }

  getFraudLists(): Observable<FraudList[]> {
    return this.missionControlService.get<FraudList[]>('fraud_lists');
  }

  createFraudList(fraudList: FraudList): Observable<FraudList> {
    fraudList = ObjectUtils.sanitizeRequestObject<FraudList>(fraudList);
    return this.missionControlService.post<FraudList>('fraud_lists', fraudList);
  }

  updateFraudList(fraudList: FraudList): Observable<FraudList> {
    fraudList = ObjectUtils.sanitizeRequestObject<FraudList>(fraudList);
    return this.missionControlService.patch<FraudList>(`fraud_lists/${fraudList.id}`, fraudList);
  }

  deleteFraudList(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`fraud_lists/${id}`);
  }

  resetFraudListCounter(id: string, item: string): Observable<void> {
    return this.missionControlService.post<void>(`fraud_lists/${id}/reset_counter`, { item });
  }
}
