import { computed, Inject, inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { AnalyticsNavItem, NavItemV2 } from '@core/types/nav-item-v2.type';
import { FeatureAvailabilityService } from '@shared/services/feature-availability/feature-availability.service';
import { AvailabilityStatus } from '@shared/types/feature-availability.type';
import { AccessPolicyUtils } from '@utils';

import { Analytics } from '../../../../app-module-config';

export interface SideNavV2HelperState {
  navItems: NavItemV2[];
}

@Injectable()
export class SideNavV2HelperService extends ComponentStore<SideNavV2HelperState> {
  //#region Dependencies

  private store = inject(Store);
  private featureAvailabilityService = inject(FeatureAvailabilityService);
  private accessPolicies = inject(AccessPolicies);
  private scopes = inject(Scopes);

  //#endregion

  //#region Store related properties

  userTenantId = this.store.selectSignal(authQuery.getUserTenantId);

  frontendPolicySettings = this.accessPolicies.getFrontendSettings('sidenav', 'show');

  //#endregion

  constructor(
    @Inject('isGlobalApp') private isGlobalApp: boolean,
    @Inject('analytics') private analytics: Analytics
  ) {
    super({ navItems: [] });
  }

  //#region Selectors

  private readonly navItems = this.selectSignal(state => state.navItems);

  readonly availableNavItems = computed(() => this.filterNavItems(this.navItems()));

  //#endregion

  //#region State Updaters

  readonly setNavItems = this.updater((state, navItems: NavItemV2[]) => ({
    ...state,
    navItems
  }));

  //#endregion

  //#region Utils

  findActivatedRoutePath(isActive: (item: NavItemV2) => boolean): NavItemV2[] {
    const transverseAndFind = (items: NavItemV2[]): NavItemV2[] => {
      for (const item of items) {
        if (isActive(item)) {
          return [item];
        }

        if (item.type === 'group' || item.type === 'submenu') {
          const result = transverseAndFind(item.children);

          if (result.length > 0) {
            return [item, ...result];
          }
        }
      }

      return [];
    };

    return transverseAndFind(this.availableNavItems());
  }

  //#endregion

  //#region Nav items Calculation methods

  private filterNavItems(navItems: NavItemV2[]): NavItemV2[] {
    return navItems.reduce((acc, item) => {
      // Handling feature availability check and adding
      // additional information to the item (mostly for upsell)
      let navItem = this.transformItemWithFeatureAvailability(item);

      // Checking if an item should be hidden
      // To do this we should check for the following:
      // - If the item is hidden by feature availability
      // - If the item is an analytics dashboard and it's not available
      // - If the item is hidden by scope
      // - If the item is hidden by access policy
      if (
        !navItem ||
        (navItem.type === 'analytics' && !this.isAnalyticsDashboardAvailable(navItem)) ||
        !this.isVisibleWithScope(navItem) ||
        !this.isVisibleWithAccessPolicy(navItem)
      ) {
        return acc;
      }

      // Handling special cases for nav item
      navItem = this.transformSpecialNavItems(navItem);

      // If the item is a group or submenu, we should check its children
      if (navItem.type === 'group' || navItem.type === 'submenu') {
        const children = this.filterNavItems(navItem.children);

        return children.length > 0 ? [...acc, { ...navItem, children }] : acc;
      }

      // If the item is an action and it's not dispatched yet, we should dispatch it
      if (navItem.type === 'action' && !navItem.isActionDispatched) {
        this.store.dispatch(navItem.action);

        return [...acc, { ...navItem, isActionDispatched: true }];
      }

      // Just return the item if it's not a special nav item
      return [...acc, navItem];
    }, []);
  }

  //#endregion

  //#region Nav items transformation methods

  private transformItemWithFeatureAvailability(item: NavItemV2): NavItemV2 | null {
    const availabilityStatus = this.getAvailabilityStatusForDisplay(item);

    switch (availabilityStatus) {
      case AvailabilityStatus.AVAILABLE: {
        return item;
      }
      case AvailabilityStatus.UPSELL: {
        return {
          ...item,
          isProFeature: true,
          ...(item.type === 'externalLink' && { href: null })
        };
      }
      default: {
        return null;
      }
    }
  }

  private transformSpecialNavItems(navItem: NavItemV2): NavItemV2 {
    switch (navItem.type) {
      case 'link': {
        if (navItem.id === 'tenants' && !this.isGlobalApp) {
          const tenantId = this.userTenantId()!;

          return {
            ...navItem,
            label: 'Configurations',
            route: `/tenants/${tenantId}/details`,
            rootRoutes: [`/tenants/${tenantId}`, `/tenants/edit/${tenantId}`]
          };
        }

        // If this is site editor and it's upsell, we should redirect it to site editor home page
        // because we want to upsell the homepage editor
        if (navItem.id === 'siteEditor' && navItem.isProFeature) {
          return {
            ...navItem,
            route: '/partner-configs/site-editor/homepage'
          };
        }

        break;
      }
      // TODO: check if we need this case
      case 'action': {
        if (navItem.id === 'approvalRequests' && navItem.type === 'action') {
          return {
            ...navItem,
            badgeValue$: this.store.select(state => state?.approvalRequest?.statistics?.pending)
          };
        }

        break;
      }
    }

    return navItem;
  }

  //#endregion

  //#region Nav items filtering methods

  private isAnalyticsDashboardAvailable(navItem: AnalyticsNavItem): boolean {
    return !!(this.analytics && this.analytics[navItem.dashboardIdKey]);
  }

  private isVisibleWithScope(navItem: NavItemV2): boolean {
    return !navItem.scopes?.length || this.scopes.hasScopes(navItem.scopes, navItem.relationOperator);
  }

  private isVisibleWithAccessPolicy(navItem: NavItemV2): boolean {
    return AccessPolicyUtils.isTargetPageVisible(this.frontendPolicySettings, navItem.id);
  }

  private getAvailabilityStatusForDisplay(item: NavItemV2): AvailabilityStatus {
    return item?.checkFeatureAvailability
      ? this.featureAvailabilityService.getFeatureAvailabilityStatus(item.id)
      : AvailabilityStatus.AVAILABLE;
  }

  //#endregion
}
