<div class="full-page-overlay-inner-container">
  <div mat-dialog-title class="h-[72px] bg-neutrals-100">
    <button
      mat-dialog-close
      class="absolute inset-y-6 left-[104px] flex cursor-pointer gap-2 border-none bg-neutrals-100 p-0"
    >
      <mat-icon svgIcon="full-arrow-left"></mat-icon>
      <div class="text-cloud text-base font-bold text-cloudburst-500">{{ backButtonText }}</div>
    </button>
  </div>

  <div mat-dialog-content>
    <ng-content></ng-content>
  </div>
</div>
