import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface ApiSettings {
  id: string;
  name: string;
  config: Object;
  domain: string;
  loginUrl: string;
  allowedWebOrigins: string[];
  callbackUrls: string[];
  tenantId: string;
  type: string;
  tokenSigningAlgorithm: string;
  tokenSigningPublicKey: string;
  oidcScopes: string[];
  secret: string;
}

export const apiSettingsAdapter = createEntityAdapter<ApiSettings>();

export interface ApiSettingsState extends EntityState<ApiSettings>, CallState {}

export const initialState: ApiSettingsState = apiSettingsAdapter.getInitialState<CallState>(initialCallState);
