import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  multipleErrorActionsData,
  multipleSuccessActionsData,
  oneErrorActionData,
  oneSuccessActionData
} from '../shared/components/kafka-widget/mocks/kafka-widget.mocks';
import {
  KafkaWidgetActionData,
  KafkaWidgetData,
  Status
} from '../shared/components/kafka-widget/types/kafka-widget.type';
import { SharedModule } from '../shared/shared.module';

interface KafkaWidgetDataRef {
  data: KafkaWidgetData;
}

@Component({
  selector: 'admin-kafka-widget-demo',
  standalone: true,
  templateUrl: './kafka-widget-demo.component.html',
  imports: [CommonModule, SharedModule],
  styles: ['div { margin: 20px; margin-bottom: 50px; }']
})
export class KafkaWidgetDemoComponent implements OnInit, OnDestroy {
  oneActionDataRef: KafkaWidgetDataRef;
  multipleActionsDataRef: KafkaWidgetDataRef;
  oneSuccessfulActionDataRef: KafkaWidgetDataRef;
  multipleSuccessfulActionsDataRef: KafkaWidgetDataRef;

  private timeoutIds: number[] = [];

  constructor() {
    this.initializeDataToProcessing();
  }

  ngOnInit(): void {
    this.scheduleStatusUpdates();
  }

  ngOnDestroy(): void {
    this.cancelScheduledUpdates();
  }

  resetTimeouts(): void {
    this.cancelScheduledUpdates();
    this.initializeDataToProcessing();
    this.scheduleStatusUpdates();
  }

  private scheduleStatusUpdates(): void {
    this.scheduleTaskStatusUpdates(this.oneActionDataRef, [[1500, 3000, 4500]], [['successful', 'failed', 'failed']]);
    this.scheduleTaskStatusUpdates(
      this.multipleActionsDataRef,
      [[5500, 7000, 7500], [6000]],
      [['successful', 'failed', 'failed'], ['failed']]
    );
    this.scheduleTaskStatusUpdates(
      this.oneSuccessfulActionDataRef,
      [[9000, 10_500, 12_000]],
      [['successful', 'successful', 'successful']]
    );
    this.scheduleTaskStatusUpdates(
      this.multipleSuccessfulActionsDataRef,
      [[12_500, 13_000, 14_000], [13_500]],
      [['successful', 'successful', 'successful'], ['successful']]
    );
  }

  private cancelScheduledUpdates(): void {
    this.timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
    this.timeoutIds = [];
  }

  private initializeDataToProcessing(): void {
    this.oneActionDataRef = this.setAllTasksToProcessing({ data: oneErrorActionData });
    this.multipleActionsDataRef = this.setAllTasksToProcessing({ data: multipleErrorActionsData });
    this.oneSuccessfulActionDataRef = this.setAllTasksToProcessing({ data: oneSuccessActionData });
    this.multipleSuccessfulActionsDataRef = this.setAllTasksToProcessing({ data: multipleSuccessActionsData });
  }

  /**
   * Updates task statuses in widget data after specified delays.
   *
   * @param widgetDataRef Reference to the widget data to be updated.
   * @param timeouts Delays (ms) for updating each task's status, indexed by [action][task].
   * @param statuses New statuses for each task, indexed similarly to `timeouts`.
   */
  private scheduleTaskStatusUpdates(
    widgetDataRef: KafkaWidgetDataRef,
    timeouts: number[][],
    statuses: Status[][]
  ): void {
    timeouts.forEach((actionTimeouts, actionIndex) => {
      actionTimeouts.forEach((taskTimeout, taskIndex) => {
        const timeoutId = setTimeout(() => {
          this.applyTaskStatusUpdate(widgetDataRef, actionIndex, taskIndex, statuses[actionIndex][taskIndex]);
        }, taskTimeout) as unknown as number;
        this.timeoutIds.push(timeoutId);
      });
    });
  }

  private applyTaskStatusUpdate(
    widgetDataRef: KafkaWidgetDataRef,
    actionIndex: number,
    taskIndex: number,
    status: Status
  ): void {
    const actions = [...widgetDataRef.data.actions];
    const tasks = [...actions[actionIndex].tasks];
    tasks[taskIndex] = { ...tasks[taskIndex], status };
    actions[actionIndex] = { ...actions[actionIndex], tasks };
    actions[actionIndex].overallStatus = this.determineOverallStatus(actions[actionIndex]);
    widgetDataRef.data = { ...widgetDataRef.data, actions };
  }

  private determineOverallStatus(action: KafkaWidgetActionData): Status {
    return action.tasks.some(task => task.status === 'failed') &&
      action.tasks.every(task => task.status != 'processing')
      ? 'failed'
      : // eslint-disable-next-line unicorn/no-nested-ternary
        action.tasks.every(task => task.status === 'successful')
        ? 'successful'
        : 'processing';
  }

  private setAllTasksToProcessing(widgetRef: KafkaWidgetDataRef): KafkaWidgetDataRef {
    const newWidgetRef: KafkaWidgetDataRef = {
      ...widgetRef,
      data: {
        ...widgetRef.data,
        actions: widgetRef.data.actions.map(action => ({
          ...action,
          overallStatus: 'processing',
          tasks: action.tasks.map(task => ({
            ...task,
            status: 'processing'
          }))
        }))
      }
    };
    return newWidgetRef;
  }
}
