export class DomainsFilter {
  tenantId?: string;
  appId?: string;
  page: number;
  limit: number;
  isTopLevelView?: boolean;

  constructor() {
    this.page = 1;
    this.limit = 20;
  }
}
