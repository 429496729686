import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { FilterUtils, ObjectUtils } from '@utils';

import { FraudEvaluationsFilter, FraudEvaluationsResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class FraudEvaluationsService {
  constructor(private missionControlService: MissionControlService) {}

  getFraudEvaluations(filter: FraudEvaluationsFilter): Observable<FraudEvaluationsResult> {
    filter = ObjectUtils.sanitizeRequestObject<FraudEvaluationsFilter>(filter);

    const { sortBy, sortDirection, ...rest } = filter;
    let params = ObjectUtils.prepareQueryObject(rest);

    params = FilterUtils.appendHashQueryParam(params, 'sortBy', sortBy, sortDirection);

    return this.missionControlService.get<FraudEvaluationsResult>(
      'fraud_evaluations',
      new HttpParams({ fromObject: { ...params } })
    );
  }
}
