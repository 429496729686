import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { pointsStatementInitialState, PointsStatementState } from '../../types';
import {
  loadPointsStatement,
  loadPointsStatementFailure,
  loadPointsStatementSuccess
} from '../actions/points-statements.actions';

const pointsStatementTriggers: EntityTriggers = {
  single: {
    loading: [loadPointsStatement.type],
    resting: [loadPointsStatementSuccess.type],
    erroring: [loadPointsStatementFailure.type]
  }
};

export function pointsStatementsReducer(
  state: PointsStatementState = pointsStatementInitialState,
  action: Action
): PointsStatementState {
  return callStateReducer(baseReducer, pointsStatementTriggers)(state, action);
}

const baseReducer = createReducer(
  pointsStatementInitialState,
  on(loadPointsStatementSuccess, (state, { pointsStatement }) => ({
    ...state,
    pointsStatement
  }))
);
