import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ImageType, ImageUploadResponse } from '@shared/types';

import { Campaign, TimeAdjustmentPayload } from '../types';

export interface MissionControlCampaignsResponse {
  campaigns: Campaign[];
}

export interface MissionControlCampaignResponse {
  campaign: Campaign;
}

@Injectable({ providedIn: 'root' })
export class CampaignsService {
  constructor(private missionControlService: MissionControlService) {}

  getUserCampaigns(id: string): Observable<Campaign[]> {
    return this.missionControlService.get<Campaign[]>(`users/${id}/campaigns`);
  }

  getCampaigns(): Observable<Campaign[]> {
    return this.missionControlService
      .get<MissionControlCampaignsResponse>('campaigns')
      .pipe(map(missionControlCampaignsResponse => missionControlCampaignsResponse.campaigns));
  }

  getCampaign(id: string): Observable<Campaign> {
    return this.missionControlService
      .get<MissionControlCampaignResponse>(`campaigns/${id}`)
      .pipe(map(missionControlCampaignResponse => missionControlCampaignResponse.campaign));
  }

  createCampaign(campaign: Campaign): Observable<Campaign> {
    return this.missionControlService
      .post<MissionControlCampaignResponse>('campaigns', { campaign })
      .pipe(map(missionControlCampaignResponse => missionControlCampaignResponse.campaign));
  }

  updateCampaign(campaign: Campaign): Observable<Campaign> {
    return this.missionControlService
      .patch<MissionControlCampaignResponse>(`campaigns/${campaign.id}`, { campaign })
      .pipe(map(missionControlCampaignResponse => missionControlCampaignResponse.campaign));
  }

  startCampaign(campaignId: string): Observable<Campaign> {
    return this.missionControlService
      .post<MissionControlCampaignResponse>(`campaigns/${campaignId}/start`)
      .pipe(map(missionControlCampaignResponse => missionControlCampaignResponse.campaign));
  }

  stopCampaign(campaignId: string): Observable<Campaign> {
    return this.missionControlService
      .post<MissionControlCampaignResponse>(`campaigns/${campaignId}/stop`)
      .pipe(map(missionControlCampaignResponse => missionControlCampaignResponse.campaign));
  }

  adjustCampaign(timeAdjustmentPayload: Partial<TimeAdjustmentPayload>): Observable<Campaign> {
    return this.missionControlService
      .post<MissionControlCampaignResponse>(`campaigns/${timeAdjustmentPayload.campaignDetails.id}/time_adjustment`, {
        ...timeAdjustmentPayload.campaignDetails
      })
      .pipe(map(missionControlCampaignResponse => missionControlCampaignResponse.campaign));
  }

  uploadCampaignImage(
    partnerReferenceId: string,
    file: FormData,
    imageType: ImageType,
    campaignId?: string
  ): Observable<ImageUploadResponse> {
    const optionalParam = campaignId ? { campaignId } : {};
    return this.missionControlService.post<ImageUploadResponse>(
      'campaigns/upload_image',
      file,
      new HttpParams({
        fromObject: {
          ...optionalParam,
          partnerReferenceId,
          imageType
        }
      })
    );
  }
}
