import { trigger } from '@angular/animations';
import { Component, computed, ElementRef, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { NavGroup, NavItemV2 } from '@core/types/nav-item-v2.type';
import { expansionAnimation } from '@shared/animations/expansion-animations';
import { injectUntilDestroyed, ObjectUtils } from '@utils';

import { SideNavV2Store } from '../side-nav-v2.store';

@Component({
  selector: 'admin-side-nav-v2-navigation-list',
  templateUrl: 'side-nav-v2-navigation-list.component.html',
  styleUrl: 'side-nav-v2-navigation-list.component.scss',
  animations: [trigger('detailExpand', expansionAnimation)]
})
export class SideNavV2NavigationListComponent implements OnInit {
  //#region Children query

  @ViewChild('topAnchor', { static: true }) private topAnchor: ElementRef<HTMLDivElement>;

  //#endregion

  //#region Dependencies

  private sideNavV2Store = inject(SideNavV2Store);

  private untilDestroyed = injectUntilDestroyed();

  private elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  //#endregion

  //#region Inputs

  @Input({ required: true }) navItems: NavItemV2[] = [];

  private groupLevelSignal = signal<number | null>(null);
  @Input() set groupLevel(level: number | null) {
    this.groupLevelSignal.set(level);
  }
  get groupLevel(): number | null {
    return this.groupLevelSignal();
  }

  //#endregion

  //#region Side nav state

  levelSelectedNavGroup = computed(() => {
    const level = this.groupLevelSignal();

    if (level === null) {
      return null;
    }

    return this.sideNavV2Store.getSelectedGroupByLevel(level)();
  });

  isCollapsed = this.sideNavV2Store.isCollapsed;

  //#endregion

  //#region Lifecycle

  ngOnInit(): void {
    this.listenToNavListScroll();
  }

  //#endregion

  //#region Event handlers

  selectNavGroup(navItemGroup: NavGroup): void {
    this.sideNavV2Store.selectNavGroup(navItemGroup);
  }

  selectNavItem(navItemId: string): void {
    this.sideNavV2Store.selectNavItem(navItemId);
  }

  returnToPreviousGroup(): void {
    this.sideNavV2Store.popSelectedGroup();
  }

  listenToNavListScroll(): void {
    // Group level 0 is the root group
    // and any subsequent groups will have a level of 1 and above
    // but we start counting selected groups from 0, so we need to add 1 to the group level
    // to get the correct level for scrolling detection
    const groupLevel = ObjectUtils.isNullish(this.groupLevel) ? 0 : this.groupLevel + 1;

    const navigationListScrolling$ = new Observable<{
      isScrolling: boolean;
      groupLevel: number | null;
    }>(observer => {
      const intersectionObserver = new IntersectionObserver(
        entries => {
          // If it is't intersecting, it means the navigation list is scrolling
          // cause we shouldn't be able to see the top anchor when we scroll
          observer.next({
            isScrolling: entries.some(entry => !entry.isIntersecting),
            groupLevel
          });
        },
        {
          root: this.elementRef.nativeElement,
          threshold: 0.5
        }
      );

      intersectionObserver.observe(this.topAnchor.nativeElement);

      return {
        unsubscribe: () => {
          intersectionObserver.disconnect();
          observer.complete();
        }
      };
    });

    this.sideNavV2Store.updateBodyScrolling(navigationListScrolling$.pipe(this.untilDestroyed()));
  }

  //#endregion
}
