import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Domain, DomainsFilter, DomainsResult, UpdateDomainParams } from '../../types';

export const loadDomains = createAction('[Domains] Load Domains', props<{ filter: DomainsFilter }>());
export const loadDomainsSuccess = createAction('[Domains] Load Domains Success', props<{ result: DomainsResult }>());
export const loadDomainsFailure = createAction('[Domains] Load Domains Failure', props<ErrorState>());

export const loadDomain = createAction('[Domains] Load Domain', props<{ id: string }>());
export const loadDomainSuccess = createAction('[Domains] Load Domain Success', props<{ domain: Domain }>());
export const loadDomainFailure = createAction('[Domains] Load Domain Failure', props<ErrorState>());

export const fetchDomain = createAction('[Domains] Fetch Domain', props<{ id: string }>());
export const fetchDomainSuccess = createAction('[Domains] Fetch Domain Success', props<{ domain: Domain }>());
export const fetchDomainFailure = createAction('[Domains] Fetch Domain Failure', props<{ id: string }>());

export const createDomain = createAction('[Domains] Create Domain', props<{ domain: Domain }>());
export const createDomainSuccess = createAction('[Domains] Create Domain Success', props<{ domain: Domain }>());
export const createDomainFailure = createAction('[Domains] Create Domain Failure', props<ErrorState>());

export const updateDomain = createAction('[Domains] Update Domain', props<{ domain: UpdateDomainParams }>());
export const updateDomainSuccess = createAction('[Domains] Update Domain Success', props<{ domain: Domain }>());
export const updateDomainFailure = createAction('[Domains] Update Domain Failure', props<ErrorState>());

export const deleteDomain = createAction('[Domains] Delete Domain', props<{ id: string }>());
export const deleteDomainSuccess = createAction('[Domains] Delete Domain Success');
export const deleteDomainFailure = createAction('[Domains] Delete Domain Failure', props<ErrorState>());
