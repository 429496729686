import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { ScopesRelationOperator } from '@core/types';

/**
 * Show the content if the scope is included.
 * Example usage: <div *adminIfScopesInclude="'tenants:create'"></div>
 */
@Directive({ selector: '[adminIfScopesInclude]' })
export class IfScopesIncludeDirective implements OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('adminIfScopesInclude') inputScopes: string[] | string[][];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('adminIfScopesIncludeElse') elseTemplateRef?: TemplateRef<unknown>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('adminIfScopesIncludeRelationOperator') relationOperator: ScopesRelationOperator;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private scopes: Scopes
  ) {}

  ngOnChanges(): void {
    if (this.scopes.hasScopes(this.inputScopes, this.relationOperator)) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else if (this.elseTemplateRef) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }
}
