import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Change } from '@core/types';

@Component({
  selector: 'admin-changes-detail-dialog',
  templateUrl: './changes-detail-dialog.component.html'
})
export class ChangesDetailDialogComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['path', 'operation', 'was', 'is'];
  changes = new MatTableDataSource<Change>(this.data);

  constructor(@Inject(MAT_DIALOG_DATA) public data: Change[]) {}

  ngAfterViewInit(): void {
    this.changes.paginator = this.paginator;
  }
}
