import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { NydusNetworkBootstrapState, SharedConfigState, TenantBootstrapState, UserAbilitiesState } from '../types';

import { authReducer, AuthState } from './auth/reducers/auth.reducer';
import { interruptedReducer, InterruptedState } from './interrupted/reducers/interrupted.reducer';
import { nydusNetworkBootstrapReducer } from './nydus-network/reducers/nydus-network-bootstrap.reducer';
import { sharedConfigReducer } from './shared-config/reducers/shared-config.reducer';
import { tenantBootstrapReducer } from './tenant-bootstrap/reducers/tenant-bootstrap.reducer';
import { userAbilitiesReducer } from './user-abilities/reducers/user-abilities.reducer';

export interface CoreState {
  auth: AuthState;
  interrupted: InterruptedState;
  nydusNetworkBootstrap: NydusNetworkBootstrapState;
  tenantBootstrap: TenantBootstrapState;
  userAbilities: UserAbilitiesState;
  sharedConfig: SharedConfigState;
}

export const coreReducers: ActionReducerMap<CoreState> = {
  auth: authReducer,
  interrupted: interruptedReducer,
  nydusNetworkBootstrap: nydusNetworkBootstrapReducer,
  tenantBootstrap: tenantBootstrapReducer,
  userAbilities: userAbilitiesReducer,
  sharedConfig: sharedConfigReducer
};

export const getCoreState = createFeatureSelector<CoreState>('core');
