import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[adminIntegerInput]'
})
export class IntegerInputDirective {
  // only disallow . and e for entering float
  // allow rest of keys for other function, e.g., ctrl + v, enter
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): boolean {
    return !['.', 'e'].includes(event.key.toLowerCase());
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const data = event.clipboardData!.getData('text/plain');
    const result = data.slice(0, 1)?.replace(/[^0-9+-]/, '') + data.slice(1)?.replace(/[^0-9]/g, '');

    document.execCommand('insertHTML', false, result);
  }
}
