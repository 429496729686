import { ObjectUtils } from './object-utils';

export const ASCENDA_AUTH_PROVIDER = 'ascenda_google_oauth2';

/* eslint-disable no-useless-escape */
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9_]+$/;

export const URL_REGEX = /^http(s)?:\/\/[\w.-]+((\/[\\\w.\-~:|?#[\]®@!\$&'\(\)\*\+,;=.\s]+)+)?((\/)+)?$/;
export const BASE_URL_REGEX = /^http(s)?:\/\/[\w.-]+((\/[\\\w.\-~:|?#[\]®@!\$&'\(\)\*\+,;=.\s]+)+)?$/; // disallow '/' at back
export const DOMAIN_REGEX = /^[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*$/;
/* eslint-enable */

// format: (longitude, latitude)
export const LONLAT_REGEX =
  /^\(\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?),[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\)$/;

export const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

// value for 23:59:59 in milliseconds
export const END_OF_DAY_MILLISECONDS = ((23 * 60 + 59) * 60 + 59) * 1000;

export const INVALID_URL_MESSAGE = 'Must start with http(s) & valid domain';
export const INVALID_BASE_URL_MESSAGE = INVALID_URL_MESSAGE + ', and not end with /';
export const INVALID_E164_MESSAGE =
  'Must start with "+", followed by valid phone number of 10-15 digits accordingly (e.g. +6581234567)';
export const INVALID_EMAIL_MESSAGE = 'Invalid email';
export const INVALID_PHONE_NUMBER_MESSAGE = 'Please enter a Phone number in the valid format (e.g. 00000000)';

export const MAX_INTEGER = 999_999_999_999_999;
export const MAX_NN_INTEGER = 2_147_483_647; // 2^31 - 1

export const IMAGE_UPLOAD_CONFIGS = {
  allowedTypes: ['image/jpg', 'image/jpeg', 'image/png'],
  fileSizeLimit: 2_000_000,
  hintMessage: 'Maximum file size: 2MB',
  acceptedFiles: ['.jpg', '.jpeg', '.png']
};

export const IMAGE_MEDIA_TYPE_TO_FILE_EXTENSION = {
  'image/jpg': 'JPG',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/svg+xml': 'SVG',
  'image/x-icon': 'ICO',
  'image/vnd.microsoft.icon': 'ICO'
};
export const FILE_EXTENSION_TO_IMAGE_MEDIA_TYPE = ObjectUtils.invertKeyValue(IMAGE_MEDIA_TYPE_TO_FILE_EXTENSION);

// prettier-ignore
export const SELECTABLE_CURRENCIES = ['AFN', 'ALL', 'DZD', 'USD', 'EUR', 'AOA', 'XCD',
'ARS', 'AMD', 'AWG', 'AUD', 'AZN', 'BSD', 'BHD', 'BDT', 'BBD', 'BYN', 'BZD', 'XOF',
'BMD', 'BTN', 'INR', 'BOB', 'BOV', 'BAM', 'BWP', 'NOK', 'BRL', 'BND', 'BGN', 'BIF',
'CVE', 'KHR', 'XAF', 'CAD', 'KYD', 'CLF', 'CLP', 'CNY', 'COP', 'COU', 'KMF', 'CDF',
'NZD', 'CRC', 'HRK', 'CUC', 'CUP', 'ANG', 'CZK', 'DKK', 'DJF', 'DOP', 'EGP', 'SVC',
'ERN', 'ETB', 'FKP', 'FJD', 'XPF', 'GMD', 'GEL', 'GHS', 'GIP', 'GTQ', 'GNF', 'GYD',
'HTG', 'HNL', 'HKD', 'HUF', 'ISK', 'IDR', 'XDR', 'IRR', 'IQD', 'GBP', 'ILS', 'JMD',
'JPY', 'JOD', 'KZT', 'KES', 'KPW', 'KRW', 'KWD', 'KGS', 'LAK', 'LBP', 'LSL', 'ZAR',
'LRD', 'LYD', 'CHF', 'MOP', 'MGA', 'MWK', 'MYR', 'MVR', 'MRU', 'MUR', 'XUA', 'MXN',
'MXV', 'MDL', 'MNT', 'MAD', 'MZN', 'MMK', 'NAD', 'NPR', 'NIO', 'NGN', 'NOK', 'OMR',
'PKR', 'PAB', 'PGK', 'PYG', 'PEN', 'PHP', 'PLN', 'QAR', 'MKD', 'RON', 'RUB', 'RWF',
'SHP', 'WST', 'STN', 'SAR', 'RSD', 'SCR', 'SLE', 'SGD', 'XSU', 'SBD', 'SOS', 'ZAR',
'SSP', 'LKR', 'SDG', 'SRG', 'SZL', 'SEK', 'CHE', 'CHW', 'SYP', 'TWD', 'TJS', 'TZS',
'THB', 'TTD', 'TOP', 'TND', 'TRY', 'TMT', 'UGX', 'UAH', 'AED', 'USN', 'UYI', 'UYU',
'UZS', 'VUV', 'VEF', 'VED', 'VND', 'YER', 'ZMW', 'ZWL'];

export const X_MULTIPLE_LOCALES_HEADER = { 'X-MULTIPLE-LOCALES': 'true' };
export const PRODUCTION_UNLEASH_KEY = '*:production.ed9dc866a8362d926412ad673806fed91176182036fdca279f983b42';
export const STAGING_UNLEASH_KEY = '*:development.23acaf6ca5485c9ed7a23766b5fd67b8c96e4439247906c9a8b2d070';
export const UNLEASH_URL = 'https://us.app.unleash-hosted.com/usee0016/api/frontend';

export enum UnleashFlags {
  REWARDS_PORTAL_20 = 'rewards_portal_2.0_mc',
  RC_SIDENAV_V2 = 'ap_rc_sidenav_v2',
  WAAS_ENABLE = 'webhook_as_a_service_enabled'
}
