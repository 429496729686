import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Membership, MembershipResult, UpdateMembershipParams } from '../../types/memberships.type';

export const loadMemberships = createAction('[Memberships] Load Memberships', props<{ userId: string }>());
export const loadMembershipsSuccess = createAction(
  '[Memberships] Load Memberships Success',
  props<{ result: MembershipResult }>()
);
export const loadMembershipsFailure = createAction('[Memberships] Load Memberships Failure', props<ErrorState>());

export const updateMembership = createAction(
  '[Memberships] Update Memberships',
  props<{ userId: string; membershipId: string; membership: UpdateMembershipParams }>()
);
export const updateMembershipSuccess = createAction(
  '[Memberships] Update Memberships Success',
  props<{ result: Membership }>()
);
export const updateMembershipFailure = createAction('[Memberships] Update Memberships Failure', props<ErrorState>());

export const deleteMembership = createAction(
  '[Memberships] Delete Membership',
  props<{ userId: string; membershipId: string }>()
);
export const deleteMembershipSuccess = createAction(
  '[Memberships] Delete Membership Success',
  props<{ membershipId: string }>()
);
export const deleteMembershipFailure = createAction('[Memberships] Delete Membership Failure', props<ErrorState>());

export const setMembershipsLocale = createAction('[Memberships] Set Memberships Locale', props<{ locale: string }>());
