import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { OfferSourcesService } from '../../services/offer-sources.service';
import {
  loadOfferSources,
  loadOfferSourcesFailure,
  loadOfferSourcesSuccess,
  uploadOfferSources,
  uploadOfferSourcesFailure,
  uploadOfferSourcesSuccess
} from '../actions/offer-sources.actions';

@Injectable()
export class OfferSourcesEffects {
  loadOfferSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOfferSources),
      exhaustMap(action =>
        this.offerSourcesService.getOfferSources(action.filter).pipe(
          map(result => loadOfferSourcesSuccess({ result })),
          catchError(error => of(loadOfferSourcesFailure({ error })))
        )
      )
    )
  );

  uploadOfferSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadOfferSources),
      exhaustMap(action =>
        this.offerSourcesService.uploadOfferSources(action.file).pipe(
          map(() => uploadOfferSourcesSuccess({ dialogRefId: action.dialogRefId })),
          catchError(error => of(uploadOfferSourcesFailure({ error })))
        )
      )
    )
  );

  uploadOfferSourcesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadOfferSourcesSuccess),
        tap(({ dialogRefId }) => {
          this.snackBar.open('Upload offer sources successfully!', 'Dismiss', { panelClass: 'mat-snack-bar-success' });
          this.matDialog.getDialogById(dialogRefId)?.close(true);
        })
      ),
    { dispatch: false }
  );

  uploadOfferSourcesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadOfferSourcesFailure),
        tap(action => this.snackBar.open(action.error.message, 'Dismiss'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private offerSourcesService: OfferSourcesService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}
}
