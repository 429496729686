import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { Store } from '@ngrx/store';

import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { handleRequestValidation, INVALID_E164_MESSAGE, INVALID_EMAIL_MESSAGE } from '@utils';

import { phoneValidator } from '../../../../validators';
import { updateMfaIdentity } from '../../store/actions/mfa-identities.actions';
import { EditMfaIdentityDialogForm, MfaIdentity, MfaIdentityState } from '../../types';

interface DialogData {
  mfaIdentity: MfaIdentity;
  userId: string;
}

@Component({
  selector: 'admin-edit-mfa-identity-dialog-v2',
  templateUrl: './edit-mfa-identity-dialog-v2.component.html',
  styleUrls: ['./edit-mfa-identity-dialog-v2.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } }
  ]
})
export class EditMfaIdentityDialogV2Component implements OnInit {
  editForm: FormGroup<EditMfaIdentityDialogForm>;
  invalidE164Message = INVALID_E164_MESSAGE;
  invalidEmailMessage = INVALID_EMAIL_MESSAGE;

  constructor(
    private store: Store<MfaIdentityState>,
    private accessPolicies: AccessPolicies,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditMfaIdentityDialogV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  get uidControl(): AbstractControl {
    return this.editForm.get('uid');
  }

  get providerId(): string {
    return this.data.mfaIdentity.providerId;
  }

  get isFormSubmissionInvalid(): boolean {
    return (
      (this.uidControl.value === this.data.mfaIdentity.uid &&
        this.editForm.controls.verified.value === this.data.mfaIdentity.verified) ||
      this.editForm.invalid
    );
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      uid: this.fb.control(this.data.mfaIdentity.uid, [Validators.required]),
      verified: this.fb.control(this.data.mfaIdentity.verified)
    });
    this.handleRequestValidationPolicy();
    this.handleProviderIdValidators();
  }

  handleRequestValidationPolicy(): void {
    const requestValidationPolicy = this.accessPolicies.getRequestValidationSettings('mfaIdentities', 'update');

    if (requestValidationPolicy) {
      const { attributes, meta } = requestValidationPolicy;
      handleRequestValidation(this.editForm, attributes, meta?.otherAttributes);
    }
  }

  handleProviderIdValidators(): void {
    if (this.providerId === 'email') {
      this.uidControl.addValidators(Validators.email);
    } else if (this.providerId === 'sms') {
      this.uidControl.addValidators(phoneValidator());
    }
  }

  submitForm(): void {
    this.store.dispatch(
      updateMfaIdentity({
        id: this.data.mfaIdentity.id,
        params: {
          ...this.editForm.value,
          agentCreated: this.data.mfaIdentity.customData.agentCreated ?? false // pass to MC for request validation
        },
        userId: this.data.userId,
        dialogRefId: this.dialogRef.id
      })
    );
  }
}
