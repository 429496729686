import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { IframeConfig, IframeHandler } from '@kaligo/secure-fields-iframe';
import { Store } from '@ngrx/store';
import { iif, Observable, of, pairwise, startWith } from 'rxjs';

import { AttributeUtils } from '@core/services/attribute-utils/attribute-utils.service';
import { FileDownloadService } from '@core/services/file-download/file-download.service';
import { ScopesCheckService } from '@core/services/scopes/scopes-check.service';
import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { authQuery } from '@core/store/auth/selectors/auth.selectors';
import { AttributeDef, DecodedUser, SCOPES_GROUP, SCOPES_OR, SCOPES_OR_GENERATED } from '@core/types';
import { SecureFieldsService } from '@shared/services/secure-fields.service';
import {
  DashedDateAdapter,
  DateUtils,
  DomUtils,
  FilterFormUtils,
  HostTenantMappingUtils,
  ObjectUtils,
  StickyColumnConfig,
  StickyTableUtils
} from '@utils';

import { ExtraUserFilters, SecureFieldsConfigs, UiConfigs } from '../../../../app-module-config';
import { partialSearchValidator, uuidValidator } from '../../../../validators';
import { rolesQuery } from '../../../roles/store/selectors/roles.selectors';
import { Role, RoleState } from '../../../roles/types';
import { emptyFilterFormValues } from '../../mocks';
import { UsersService } from '../../services/users.service';
import { loadUser, loadUsers, loadUsersCount } from '../../store/actions/users.actions';
import { usersQuery } from '../../store/selectors/users.selectors';
import {
  DisplaySettings,
  getCustomerId,
  getCustomerIdentity,
  getPartnerStates,
  User,
  UserColumn,
  UsersFilter,
  UsersFilterFormValue,
  UsersPagePolicySettings,
  UserState,
  UserStateAttributes,
  userStateDisplayClass,
  UserStates,
  UserStatus
} from '../../types';
import { UsersUtils } from '../../utils/users-utils';
import { getPartnerStatuses, UserLoginMode } from './../../types/users.type';

@Component({
  selector: 'admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['users.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DashedDateAdapter
    }
  ]
})
export class UsersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  destroyRef = inject(DestroyRef);

  displayedColumns: string[] = [];
  tableUtil: StickyTableUtils;
  columnConfig: Record<string, StickyColumnConfig> = {};

  userStateDisplayClass = userStateDisplayClass;

  users$: Observable<User[]>;
  roles$: Observable<Role[]>;
  loading$: Observable<boolean>;
  usersTotalCount$: Observable<number>;
  isCustomersView: boolean;
  isAgentsView: boolean;
  currentUser$: Observable<DecodedUser>;
  detailsView: string;

  filter: UsersFilter;
  filterForm: UntypedFormGroup;

  // handle by frontend and response policy
  displaySettings: DisplaySettings;

  states: string[] = getPartnerStates(this.partnerPlatform).filter(state => state !== UserStates.Admin);
  statuses: string[] = getPartnerStatuses(this.partnerPlatform).filter(status => status !== UserStatus.Admin);
  loginModes: string[] = Object.values(UserLoginMode);
  stateControl = new FormControl();

  partialSearchFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'identityUid'];
  hasPartialSearch = false;
  displayPanField = false;
  iframeHandler: IframeHandler;

  showScopes = SCOPES_OR.showUsers;
  rolesScopes = SCOPES_OR.viewRoles;
  readonly SCOPES = SCOPES_OR_GENERATED;

  private readonly prefixColumnDefs: AttributeDef<UserColumn>[] = [{ key: 'customerId' }, { key: 'name' }];

  private readonly scrollableColumnDefs: AttributeDef<UserColumn>[] = [
    { key: 'id' },
    { key: 'roles' },
    { key: 'phoneNumber' },
    { key: 'email' },
    { key: 'identityId' },
    { key: 'pointsAccountColumn' },
    { key: 'createdAt' },
    { key: 'updatedAt' },
    { key: 'state' }
  ];

  private readonly suffixColumnDefs: AttributeDef<UserColumn>[] = [{ key: 'actions' }];

  constructor(
    @Inject('customerBankIdentityProvider') private identityProvider: string,
    @Inject('timezone') public timezone: string,
    @Inject('secureFieldsConfigs') private secureFieldsConfigs: SecureFieldsConfigs,
    @Inject('extraUserFilters') public extraUserFilters: ExtraUserFilters,
    @Inject('showAgentDashboard') private showAgentDashboard: string,
    @Inject('partnerPlatform') private partnerPlatform: string,
    @Inject('uiConfigs') private uiConfigsSchema: UiConfigs,
    private cdRef: ChangeDetectorRef,
    private store: Store<UserState>,
    private roleStore: Store<RoleState>,
    private scopes: Scopes,
    private scopesCheckService: ScopesCheckService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private accessPolicies: AccessPolicies,
    private attributeUtils: AttributeUtils,
    private fileDownloadService: FileDownloadService,
    private usersService: UsersService,
    private secureFieldsService: SecureFieldsService
  ) {}

  get hasIdSearch(): boolean {
    return !!this.filterForm.get('id').value;
  }

  ngOnInit(): void {
    this.isCustomersView = this.route.snapshot.data.isCustomerView;
    this.isAgentsView = !this.isCustomersView;

    this.loading$ = this.store.select(usersQuery.isLoading);
    this.usersTotalCount$ = this.store.select(usersQuery.getCount);
    this.roles$ = this.roleStore.select(rolesQuery.getRolesList);
    this.currentUser$ = this.store.select(authQuery.getUser);

    this.handleDisplaySettings();
    this.detailsViewScopesAndViewCheck();

    this.setFilter();
    this.createFilterForm();
    this.loadDataOnInit();

    DomUtils.preventScrollToOtherPage('.sticky-table-wrapper', takeUntilDestroyed(this.destroyRef));
    DomUtils.dragAndScroll('.sticky-table-wrapper', takeUntilDestroyed(this.destroyRef));
  }

  loadDataOnInit(): void {
    if (this.filter.id) {
      this.loadUser(this.filter.id);
    } else {
      this.filter.productId = null;
      this.loadUsers();
    }
  }

  setFilter(): void {
    this.filter = { ...this.route.snapshot.data.routeFilters };

    if (this.isCustomersView) {
      if (this.filter.status === UserStatus.Admin || !this.filter.status) {
        this.filter = { ...new UsersFilter(), ...UsersUtils.convertStateToAttributes(UserStatus.Active) };
      }
    } else if (this.isAgentsView && this.filter.status !== UserStatus.Admin) {
      this.filter = {
        ...new UsersFilter(),
        ...UsersUtils.convertStateToAttributes(UserStatus.Admin),
        ...(this.displaySettings.loginMode ? {} : { loginMode: null }), // set to null if the field is not displayed
        ...(this.displaySettings.activated ? {} : { activated: null }) // set to null if the field is not displayed
      };
    }
  }

  ngAfterViewInit(): void {
    if (this.displaySettings.pan && (this.extraUserFilters?.creditCardNumber || this.extraUserFilters?.accountNumber)) {
      this.setupPanField('Search by credit card number');
      this.cdRef.detectChanges();
    }

    const initialFormValues = this.getInitialFormValues();
    this.subscribeToFormChanges(initialFormValues);
    this.subscribeToStateControlChanges();
    this.subscribeToMatSortChange();
  }

  createFilterForm(): void {
    const { status, loginMode, activated } = this.filter;

    this.filterForm = this.fb.group({
      id: [this.filter.id, [uuidValidator()]],
      firstName: [this.filter.firstName, [partialSearchValidator()]],
      lastName: [this.filter.lastName, [partialSearchValidator()]],
      identityUid: [this.filter.identityUid, [Validators.minLength(2)]],
      partnerUid: [this.filter.partnerUid],
      email: [this.filter.email, [Validators.minLength(2)]],
      phoneNumber: [this.filter.phoneNumber, [Validators.minLength(2)]],
      status: [{ value: this.filter.status, disabled: this.isAgentsView }],
      loginMode: [this.filter.loginMode],
      activated: [this.filter.activated],
      roleId: [{ value: this.filter.roleId, disabled: this.isCustomersView }],
      createdAtDateRange: this.fb.group({
        createdAtFrom: [this.filter.createdAt?.from],
        createdAtTo: [this.filter.createdAt?.to]
      }),
      updatedAtDateRange: this.fb.group({
        updatedAtFrom: [this.filter.updatedAt?.from],
        updatedAtTo: [this.filter.updatedAt?.to]
      })
    });

    this.stateControl = new FormControl({
      value: UsersUtils.convertAttributesToState({ status, loginMode, activated } as UserStateAttributes),
      disabled: this.isAgentsView
    });

    if (this.extraUserFilters?.pointsAccount) {
      this.filterForm.addControl('accountId', new FormControl<string>(this.filter.accountId, [uuidValidator()]));
    }
  }

  getInitialFormValues(): UsersFilterFormValue {
    return {
      ...this.filter,
      createdAtDateRange: {
        createdAtFrom: this.filter?.createdAt?.from,
        createdAtTo: this.filter?.createdAt?.to
      },
      updatedAtDateRange: {
        updatedAtFrom: this.filter?.updatedAt?.from,
        updatedAtTo: this.filter?.updatedAt?.to
      }
    };
  }

  subscribeToFormChanges(initialFormValues: object): void {
    const handleIdSearch = FilterFormUtils.createIdSearchHandler<UsersFilterFormValue>(id => {
      this.iframeHandler?.clearValue();
      this.stateControl.setValue(null, { emitEvent: false });
      this.loadUser(id);
    });
    const handleRestValueSearch = FilterFormUtils.createOtherValueSearchHandler<UsersFilterFormValue>(
      filterFormValue => {
        this.iframeHandler?.clearValue();
        this.searchWithoutId(filterFormValue);
      }
    );

    this.filterForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(initialFormValues),
        debounceTime(1000),
        filter(() => !this.filterForm.invalid),
        pairwise()
      )
      .pipe(
        mergeMap(pairValues => this.handleProductIdSearch(pairValues as UsersFilterFormValue[])),
        mergeMap(pairValues => handleIdSearch(pairValues)),
        mergeMap(pairValues => handleRestValueSearch(pairValues))
      )
      .subscribe();
  }

  subscribeToStateControlChanges(): void {
    this.stateControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(state => UsersUtils.convertStateToAttributes(state))
      )
      .subscribe(attributes => {
        this.filter = { ...this.filter, ...attributes };
        this.filterForm.patchValue({ ...attributes });
      });
  }

  handleProductIdSearch(pairValues: UsersFilterFormValue[]): Observable<UsersFilterFormValue[]> {
    // return null to stop handling process - when there is productId in filter and rest are empty
    // return pairValues to pass to next handler
    return iif(() => this.filter.productId && ObjectUtils.isEmptyValueObject(pairValues[1]), of(null), of(pairValues));
  }

  searchWithoutId(filterFormValue: UsersFilterFormValue): void {
    const { createdAtDateRange, updatedAtDateRange, id, status, loginMode, activated, ...restFilterFormValue } =
      filterFormValue;
    const { createdAtFrom, createdAtTo } = createdAtDateRange || {};
    const { updatedAtFrom, updatedAtTo } = updatedAtDateRange || {};

    // clear id in form when switching from id search
    if (id) {
      this.filterForm.patchValue({ id: null });
    }

    // clear mat-sort when switching from id or productId search
    if (id || this.filter.productId) {
      this.sort.sort({ id: null, start: null, disableClear: false });
    }

    const newStateAttributes = this.handleStatusFieldsChange({ status, loginMode, activated });

    this.filter = {
      ...restFilterFormValue,
      ...newStateAttributes,
      id: null,
      page: 1,
      limit: this.filter.limit,
      createdAt: {
        from: createdAtFrom && DateUtils.getTenantStartOfDay(createdAtFrom, this.timezone),
        to: createdAtTo && DateUtils.getTenantEndOfDay(createdAtTo, this.timezone)
      },
      updatedAt: {
        from: updatedAtFrom && DateUtils.getTenantStartOfDay(updatedAtFrom, this.timezone),
        to: updatedAtTo && DateUtils.getTenantEndOfDay(updatedAtTo, this.timezone)
      },
      productId: null,
      sortBy: this.filter.sortBy,
      sortDirection: this.filter.sortDirection
    };

    this.loadUsers();
  }

  handleStatusFieldsChange(attributes: UserStateAttributes): UserStateAttributes {
    const { status, loginMode, activated } = attributes;
    let stateAttributes: UserStateAttributes;
    let newState;

    if (!status && !loginMode && activated === null) {
      const newStatus = this.isAgentsView ? UserStatus.Admin : UserStatus.Active;
      stateAttributes = UsersUtils.convertStateToAttributes(newStatus);
      newState = newStatus;

      // Not patching values on filterForm directly to avoid emitting valueChanges. { emitEvent: false } is not working with date range fields
      ['status', 'loginMode', 'activated'].forEach(subControl =>
        this.filterForm.get(subControl).setValue(stateAttributes[subControl], { emitEvent: false })
      );
    } else {
      stateAttributes = {
        status: this.isAgentsView ? UserStatus.Admin : status,
        loginMode,
        activated
      };
      newState = UsersUtils.convertAttributesToState(stateAttributes);
    }

    this.stateControl.setValue(newState, { emitEvent: false });

    return stateAttributes;
  }

  loadUsers(reloadUserCount: boolean = true): void {
    this.users$ = this.store.select(usersQuery.getUsersList);
    this.store.dispatch(loadUsers({ filter: this.filter }));
    if (reloadUserCount) {
      this.store.dispatch(loadUsersCount({ filter: this.filter }));
    }
    this.checkPartialSearchFields();
  }

  loadUser(id: string): void {
    this.filterForm.patchValue({ ...emptyFilterFormValues, id });
    this.filter = {
      ...new UsersFilter(),
      ...(this.filter.status === UserStatus.Admin &&
        this.isAgentsView &&
        UsersUtils.convertStateToAttributes(UserStatus.Admin)),
      id,
      page: 1
    };

    this.store.dispatch(loadUser({ id, filter: this.filter }));

    this.users$ = this.store.select(usersQuery.getUserById(id)).pipe(
      map(user => {
        if (this.isAgentsView) {
          return !!user && user.status === UserStatus.Admin ? [user] : [];
        } else {
          return !!user && user.status !== UserStatus.Admin ? [user] : [];
        }
      })
    );
  }

  clearFilter(): void {
    this.iframeHandler?.clearValue();
    const currentState = this.isCustomersView ? UserStatus.Active : UserStatus.Admin;

    this.filter = {
      ...new UsersFilter(),
      ...UsersUtils.convertStateToAttributes(currentState),
      page: 1,
      limit: this.filter.limit
    };

    this.filterForm.patchValue(this.filter);
  }

  onPage(pageEvent: PageEvent): void {
    this.filter = {
      ...this.filter,
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize
    };

    // we don't reload user count if we are doing page switch
    this.loadUsers(false);
  }

  subscribeToMatSortChange(): void {
    this.sort.sortChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      this.filter = {
        ...this.filter,
        page: 1,
        sortBy: event.direction ? event.active : '',
        sortDirection: event.direction
      };

      this.store.dispatch(loadUsers({ filter: this.filter }));
    });
  }

  getCustomerId(user: User): string {
    return getCustomerId(user, this.identityProvider);
  }

  getIdentityId(user: User): string {
    return getCustomerIdentity(user, this.identityProvider)?.uid;
  }

  tokenizationSuccess = (token: string): void => {
    this.filterForm.patchValue(emptyFilterFormValues);
    this.filter = { ...new UsersFilter(), productId: token };
    this.loadUsers();
  };

  setupPanField(panLabel: string): void {
    const { version, pciProxyBaseUrl, autoModeConfigKey } = this.secureFieldsConfigs;

    const iframeConfig: IframeConfig = {
      pciProxyBaseUrl,
      // use different id to force PCI-Proxy re-render view
      parentElementId: 'users-component-secure-field-' + (this.isCustomersView ? 'customer' : 'agent'),
      version,
      configKey: autoModeConfigKey,
      matFormFieldClasses: ['v1', 'filter-field', 'slim-form-field'],
      label: panLabel,
      placeholder: panLabel,
      styleUrl: HostTenantMappingUtils.getTenantStylesheetUrl(),
      onTokenize: this.tokenizationSuccess,
      onError: this.secureFieldsService.onTokenizeFailure()
    };

    this.iframeHandler = new IframeHandler(iframeConfig);
    this.iframeHandler.init();
    this.displayPanField = true;
  }

  handleDisplaySettings(): void {
    const defaultDisplaySettings: DisplaySettings = {
      actions: this.scopes.hasAny(this.showScopes),
      createdAt: true,
      customerId: true,
      email: true,
      id: true,
      identityId: false,
      name: true,
      pan: true,
      phoneNumber: true,
      pointsAccountColumn: !this.isANZ(),
      pointsAccountFilter: true,
      roles: this.isAgentsView && this.scopes.hasAny(this.rolesScopes),
      state: this.isCustomersView,
      status: false,
      loginMode: false,
      activated: false,
      updatedAt: true
    };
    const frontendSettings = this.accessPolicies.getFrontendSettings('users', 'index')
      ?.visible as UsersPagePolicySettings;
    const responseSettings = this.accessPolicies.getResponseSettings('users', 'index');

    this.displaySettings =
      frontendSettings || responseSettings
        ? UsersUtils.mergeSettings(frontendSettings, responseSettings, defaultDisplaySettings)
        : defaultDisplaySettings;

    this.handleColumnDisplay();
  }

  isANZ(): boolean {
    return this.partnerPlatform === 'anz';
  }

  handleColumnDisplay(): void {
    const [[], prefixColumns] = this.attributeUtils.filterColumns(this.prefixColumnDefs, this.displaySettings);
    const [[], scrollableColumns] = this.attributeUtils.filterColumns(this.scrollableColumnDefs, this.displaySettings);
    const [[], suffixColumns] = this.attributeUtils.filterColumns(this.suffixColumnDefs, this.displaySettings);

    this.tableUtil = new StickyTableUtils(prefixColumns, scrollableColumns, suffixColumns);
    this.displayedColumns = this.tableUtil.getDisplayedColumns();
    this.columnConfig = this.tableUtil.getStickyColumnConfig();
  }

  detailsViewScopesAndViewCheck(): void {
    if (this.uiConfigsSchema.users?.menu === 'v2') {
      this.detailsView = '/details';
    } else {
      this.detailsView =
        this.scopes.hasScopes(SCOPES_GROUP.viewDashboardPage, 'any-strict-groups') &&
        (this.isCustomersView || this.showAgentDashboard)
          ? '/dashboard'
          : '/details';
    }
  }

  checkPartialSearchFields(): void {
    for (const field of this.partialSearchFields) {
      if (this.filter[field]?.trim().length > 0) {
        this.hasPartialSearch = true;
        return;
      }
    }
    this.hasPartialSearch = false;
  }

  downloadAgents(): void {
    const confirmText = 'Do you want to download agent list csv file?';
    const fileName = 'agents.csv';
    this.fileDownloadService.downloadFile(this.usersService.downloadAgents(), fileName, confirmText);
  }

  showEdit(currentUser: DecodedUser, id: string): boolean {
    return currentUser?.sub === id || this.scopesCheckService.hasViewUpdatePageScopes(this.isCustomersView);
  }
}
