import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, FilterUtils, ObjectUtils } from '@utils';

import { AlfredTenantsResult } from '../types/alfred-tenants.type';

@Injectable({
  providedIn: 'root'
})
export class AlfredTenantsService {
  constructor(private missionControlService: MissionControlService) {}

  getTenants(): Observable<AlfredTenantsResult> {
    let params = ObjectUtils.prepareQueryObject({ limit: 1000 });
    params = FilterUtils.appendHashQueryParam(params, 'sort[]', 'id', '1');

    return this.missionControlService.get<AlfredTenantsResult>(
      'alfred/tenants',
      new HttpParams({ fromObject: { ...params }, encoder: new BracketParamsEncoder() })
    );
  }
}
