import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SCOPES_OR } from '@core/types';
import { createResolver } from '@shared/route/resolver-helper';
import { Nullable } from '@shared/types';

import { loadAuditLog } from '../store/actions/audit-logs.actions';
import { AuditLogsQuery } from '../store/selectors/audit-logs.selectors';
import { AuditLogState } from '../types';

export const auditLogResolver: ResolveFn<Nullable<void>> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  auditLogsQuery: AuditLogsQuery = inject(AuditLogsQuery)
): Observable<Nullable<void>> => {
  const id = route.params.id;

  return createResolver<AuditLogState>({
    scope: SCOPES_OR.viewAuditLogs,
    query: auditLogsQuery.isAuditLogLoaded(id),
    action: loadAuditLog({ id })
  });
};
