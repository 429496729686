import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { EventTypeFilter } from './event-types-filter.type';

export const DATA_TYPES = ['String', 'Boolean', 'Number', 'Date'] as const;
export type DataType = (typeof DATA_TYPES)[number];

export interface CustomAttributes {
  [key: string]: { description: string; type: DataType };
}

export interface EventType {
  id?: string;
  tenantId?: string;
  eventType: string;
  description: string;
  custom_attributes: CustomAttributes;
  isEditable?: boolean;
  createdAt?: string;
  status?: 'active' | 'deleted';
}

export interface EventTypesResult {
  eventTypes: EventType[];
}

export interface EventTypeResult {
  eventType: EventType;
}

export const eventTypeAdapter = createEntityAdapter<EventType>();

export interface EventTypeState extends EntityState<EventType>, CallState {
  filter: EventTypeFilter;
}

export const initialState: EventTypeState = {
  ...eventTypeAdapter.getInitialState<CallState>(initialCallState),
  filter: null
};
