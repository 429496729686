import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  standalone: true,
  selector: 'admin-drawer-content-body',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerContentBodyComponent {
  @HostBinding('class.admin-drawer-content-body') hostClass = true;

  @HostBinding('class') class = 'flex-1 overflow-auto';
}
