import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface PickupZone {
  name: string;
  alerts: Alert[];
  connection: {
    type: string;
    settings: {
      server: string;
      port: number;
      directory: string;
    };
  };
  recurringJobs: RecurringJob[];
  fileProcessingRequestsSummary: {
    new?: number;
    processing?: number;
    success?: number;
    failure?: number;
    cancelled?: number;
  };
}

interface Alert {
  type: string;
  settings: {
    from: string;
    to: string[];
    cc: string[];
    bcc: string[];
  };
}

interface RecurringJob {
  name: string;
  enabled: boolean;
  schedule: {
    cron: string;
  };
  parameters: {
    steps: string[];
  };
}

export interface PickupZoneState extends CallState {
  pickupZones: PickupZone[];
}

export const pickupZoneInitialState: PickupZoneState = {
  ...initialCallState,
  pickupZones: null
};
