import { concatMap, filter, map, startWith, switchMap } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { HttpError, SCOPES_GROUP, SCOPES_OR } from '@core/types';
import { DetailsDialogComponent } from '@shared/components/details-dialog/details-dialog.component';
import { DetailsDialogData } from '@shared/types';
import { ComponentState, getRenderState$, or, Params } from '@utils';

import { UiConfigs } from '../../../../app-module-config';
import { giftCardsQuery } from '../../../gift-cards/store/selectors/gift-cards.selectors';
import { usersQuery } from '../../../users/store/selectors/users.selectors';
import { getCustomerId } from '../../../users/types';
import { orderItemsQuery } from '../../store/selectors/order-items.selectors';
import { OrderItem, OrderItemTypeCode } from '../../types';

@Component({
  selector: 'admin-order-item-details',
  templateUrl: './order-item-details.component.html',
  styleUrls: ['./order-item-details.component.scss']
})
export class OrderItemDetailsComponent implements OnInit {
  orderItem$: Observable<OrderItem>;
  customerId$: Observable<string>;
  loading$: Observable<boolean>;
  error$: Observable<HttpError>;
  renderState$: Observable<ComponentState>;
  orderItemType = OrderItemTypeCode;
  isUserView: boolean;
  detailsView: string;
  readonly SCOPES = SCOPES_OR;

  constructor(
    @Inject('customerBankIdentityProvider') private identityProvider: string,
    @Inject('timezone') public timezone: string,
    @Inject('uiConfigs') private uiConfigsSchema: UiConfigs,
    private scopes: Scopes,
    private store: Store,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isUserView = this.route.snapshot.data.isUserOrdersItemsView;

    const orderItemId = Params.find(this.route, 'orderItemId');
    const { getOrderItemById, getSingleError, isSingleLoading } = orderItemsQuery;

    this.orderItem$ = this.store.select(getOrderItemById(orderItemId));
    this.customerId$ = this.orderItem$.pipe(
      concatMap(orderItem => this.store.select(usersQuery.getUserById(orderItem.userId))),
      map(user => !!user && getCustomerId(user, this.identityProvider))
    );

    const orderItemLoading$ = this.store.select(isSingleLoading);
    const userLoading$ = this.store.select(usersQuery.isSingleLoading);
    const detailsLoading$ = this.isDetailsLoading();
    this.loading$ = or(orderItemLoading$, userLoading$, detailsLoading$);
    this.error$ = this.store.select(getSingleError);
    this.detailsViewScopes();
    this.renderState$ = getRenderState$([this.loading$.pipe(startWith(null)), this.error$]);
  }

  isDetailsLoading(): Observable<boolean> {
    return this.orderItem$.pipe(
      filter(orderItem => !!orderItem),
      switchMap(orderItem => {
        switch (orderItem.type) {
          case this.orderItemType.GiftCardOrderItem: {
            return this.store.select(giftCardsQuery.isSingleLoading);
          }
        }
        return of(false);
      })
    );
  }

  getStripeReference(stripeSecret: string): string {
    return stripeSecret.slice(0, Math.max(0, stripeSecret.indexOf('_secret_')));
  }

  openOrderItemDetails(orderItem: OrderItem): void {
    const { id, stripePaymentIntentClientSecret: stripeClientSecret, pointsAmount, cashAmount } = orderItem.order;
    this.matDialog.open(DetailsDialogComponent, {
      data: {
        title: 'Stripe Reference Details',
        items: [
          { key: 'Order ID', value: id },
          { key: 'Stripe Reference', value: stripeClientSecret ? this.getStripeReference(stripeClientSecret) : 'N/A' },
          { key: 'Total charge', value: `${pointsAmount.toLocaleString('en-us')} points + $${cashAmount / 100}` }
        ],
        className: 'narrow-dialog'
      } as DetailsDialogData
    });
  }

  detailsViewScopes(): void {
    if (this.uiConfigsSchema.users?.menu === 'v2') {
      this.detailsView = '/details';
    } else {
      this.detailsView = this.scopes.hasScopes(SCOPES_GROUP.viewDashboardPage, 'any-strict-groups')
        ? '/dashboard'
        : '/details';
    }
  }
}
