import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { EarnRule, earnRuleAdapter, EarnRuleState } from '../../types';

const getEarnRuleState = createFeatureSelector<EarnRuleState>('earnRule');
const earnRuleDetector = createDetector<EarnRule>();

const { selectAll: getEarnRulesList, selectEntities: getEarnRulesDictionary } =
  earnRuleAdapter.getSelectors(getEarnRuleState);

const { isBatchLoading, isBatchResting, getBatchError, isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<EarnRuleState>(getEarnRuleState);

const isEarnRulesLoaded = createSelector(
  getEarnRulesDictionary,
  isBatchResting,
  (earnRules, batchResting) => earnRuleDetector('allLoaded')(earnRules) && batchResting
);

const isEarnRuleLoaded = (groupId: string): Selector<EarnRuleState, boolean> =>
  createSelector(getEarnRulesDictionary, isSingleResting, earnRule => earnRuleDetector('oneLoaded')(earnRule, groupId));

const getEarnRuleById = (groupId: string): Selector<EarnRuleState, EarnRule> =>
  createSelector(getEarnRulesDictionary, entities => entities[groupId]);

const getFilter = createSelector(getEarnRuleState, (state: EarnRuleState) => state.filter);

const getVersion = createSelector(getEarnRuleState, (state: EarnRuleState) => state.version);

export const earnRulesQuery = {
  isBatchLoading,
  isBatchResting,
  isSingleLoading,
  isSingleResting,
  getBatchError,
  getSingleError,
  isEarnRuleLoaded,
  isEarnRulesLoaded,
  getEarnRuleById,
  getFilter,
  getVersion,
  getEarnRulesList,
  getEarnRulesDictionary
};
