<div fxLayoutAlign="center center" fxFlex *ngIf="allowCancellationStatuses.includes(orderItem.status)">
  <admin-tooltip
    [key]="'orders-items.details.orderItem.refundOrder?allowed=' + enableRefundButton"
    [displayIcon]="false"
  >
    <button
      *adminIfScopesInclude="SCOPES.cancelOrderItems; relationOperator: 'or'"
      mat-raised-button
      color="primary"
      class="uppercase-button"
      [disabled]="!enableRefundButton"
      (click)="refundOrderItemDialog()"
    >
      <span class="uppercase-button__text">Refund</span>
    </button>
  </admin-tooltip>
</div>
