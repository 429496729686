import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';
import { ObjectUtils } from '@utils';

import { initialState, noteAdapter, NoteState } from '../../types/notes.type';
import {
  createNote,
  createNoteFailure,
  createNoteSuccess,
  deleteNote,
  deleteNoteFailure,
  deleteNoteSuccess,
  loadNotes,
  loadNotesFailure,
  loadNotesSuccess,
  updateNote,
  updateNoteFailure,
  updateNoteSuccess
} from '../actions/notes.actions';

export const noteTriggers: EntityTriggers = {
  single: {
    loading: [createNote.type, updateNote.type, deleteNote.type],
    resting: [createNoteSuccess.type, updateNoteSuccess.type, deleteNoteSuccess.type],
    erroring: [createNoteFailure.type, updateNoteFailure.type, deleteNoteFailure.type]
  },
  batch: {
    loading: [loadNotes.type],
    resting: [loadNotesSuccess.type],
    erroring: [loadNotesFailure.type]
  }
};

export function notesReducer(state: NoteState = initialState, action: Action): NoteState {
  return callStateReducer(baseReducer, noteTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadNotes, (state, { filter, isTopLevelView }) => ({ ...state, isTopLevelView, filter })),
  on(loadNotesSuccess, (state, { notesResult }) => ({
    ...state,
    ...noteAdapter.setAll(notesResult.data, state),
    metadata: { ...ObjectUtils.renameKey(notesResult.metadata, 'total', 'count') }
  })),
  on(createNoteSuccess, (state, { note }) => noteAdapter.addOne(note, state)),
  on(updateNoteSuccess, (state, { note }) => noteAdapter.setOne(note, state)),
  on(deleteNoteSuccess, (state, { id }) => noteAdapter.removeOne(id, state))
);
