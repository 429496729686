import { KafkaWidgetData } from '../types/kafka-widget.type';

export const oneErrorActionData: KafkaWidgetData = {
  actions: [
    {
      actionName: 'Test action name',
      overallStatus: 'failed',
      tasks: [
        {
          clientId: 'te',
          taskName: 'Test task name',
          status: 'successful'
        },
        {
          clientId: 'mc',
          taskName: 'mc task name',
          status: 'failed'
        },
        {
          clientId: 'nydus_network',
          taskName: 'nn task name',
          status: 'failed',
          changes: [
            {
              path: '/test/path',
              operation: 'UPDATED',
              was: 'foo',
              is: 'bar'
            },
            {
              path: '/test/path',
              operation: 'UPDATED',
              was: 'bad-reward',
              is: 'good-reward'
            },
            {
              path: '/test/path',
              operation: 'ADDED',
              was: null,
              is: 'new-reward'
            }
          ]
        }
      ]
    }
  ],
  scopesForRetry: [],
  displaySuccessSnackBarOnWidgetClose: false
};

export const multipleErrorActionsData: KafkaWidgetData = {
  actions: [
    {
      actionName: 'Test action name',
      overallStatus: 'failed',
      tasks: [
        {
          clientId: 'te',
          taskName: 'Test task name',
          status: 'successful'
        },
        {
          clientId: 'mc',
          taskName: 'mc task name',
          status: 'processing'
        },
        {
          clientId: 'nydus_network',
          taskName: 'nn task name',
          status: 'failed',
          changes: [
            {
              path: '/test/path',
              operation: 'UPDATED',
              was: 'foo',
              is: 'bar'
            }
          ]
        }
      ]
    },
    {
      actionName: 'Test action name 2',
      overallStatus: 'successful',
      tasks: [
        {
          clientId: 'te',
          taskName: 'Test task name',
          status: 'successful'
        }
      ]
    }
  ],
  scopesForRetry: [],
  displaySuccessSnackBarOnWidgetClose: false
};

export const oneSuccessActionData: KafkaWidgetData = {
  actions: [
    {
      actionName: 'Test action name',
      overallStatus: 'processing',
      tasks: [
        {
          clientId: 'te',
          taskName: 'Test task name',
          status: 'successful'
        },
        {
          clientId: 'mc',
          taskName: 'mc task name',
          status: 'processing'
        },
        {
          clientId: 'nydus_network',
          taskName: 'nn task name',
          status: 'successful'
        }
      ]
    }
  ],
  scopesForRetry: [],
  displaySuccessSnackBarOnWidgetClose: false
};

export const multipleSuccessActionsData: KafkaWidgetData = {
  actions: [
    {
      actionName: 'Test action name',
      overallStatus: 'processing',
      tasks: [
        {
          clientId: 'te',
          taskName: 'Test task name',
          status: 'successful',
          changes: [
            {
              path: '/test/path',
              operation: 'UPDATED',
              was: 'foo',
              is: 'bar'
            }
          ]
        },
        {
          clientId: 'mc',
          taskName: 'mc task name',
          status: 'processing',
          changes: [
            {
              path: '/test/path',
              operation: 'UPDATED',
              was: 'foo',
              is: 'bar'
            }
          ]
        },
        {
          clientId: 'nydus_network',
          taskName: 'nn task name',
          status: 'successful',
          changes: [
            {
              path: '/test/path',
              operation: 'UPDATED',
              was: 'foo',
              is: 'bar'
            }
          ]
        }
      ]
    },
    {
      actionName: 'Test action name 2',
      overallStatus: 'successful',
      tasks: [
        {
          clientId: 'te',
          taskName: 'Test task name',
          status: 'successful',
          changes: [
            {
              path: '/test/path',
              operation: 'UPDATED',
              was: 'foo',
              is: 'bar'
            }
          ]
        }
      ]
    }
  ],
  scopesForRetry: [],
  displaySuccessSnackBarOnWidgetClose: false
};
