import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { KafkaWidgetActionData, KafkaWidgetData, Status } from './types/kafka-widget.type';

@Component({
  selector: 'admin-kafka-widget',
  templateUrl: './kafka-widget.component.html',
  styleUrl: './kafka-widget.component.scss'
})
export class KafkaWidgetComponent implements OnChanges {
  @Input() widgetData: KafkaWidgetData;

  @Output() retryEvent = new EventEmitter<void>();
  @Output() closeEvent = new EventEmitter<void>();

  isMultipleActions: boolean;

  constructor(private snackBar: MatSnackBar) {}

  ngOnChanges(): void {
    this.isMultipleActions = this.widgetData?.actions?.length > 1;
    if (this.widgetData?.displaySuccessSnackBarOnWidgetClose) {
      this.snackBar.open('The changes have been saved successfully.', 'Dismiss', {
        panelClass: 'mat-snack-bar-success'
      });
      this.closeEvent.emit();
    }
  }

  get actions(): KafkaWidgetActionData[] {
    return this.widgetData?.actions;
  }

  get actionChipContent(): string {
    if (!this.actions) return '';
    const status = this.hasAnyActionError ? 'failed' : 'successful';
    const actionOrTaskCount = this.isMultipleActions
      ? this.getNumberOfActionsWithStatus(status)
      : this.getNumberOfTasksWithStatus(status, this.actions[0]);
    const total = this.isMultipleActions ? this.actions.length : this.actions[0].tasks.length;
    // eslint-disable-next-line unicorn/no-nested-ternary
    const entity = this.isMultipleActions ? 'changes' : this.actions[0].tasks.length > 1 ? 'tasks' : 'task';
    const verb = status === 'failed' ? 'failed' : 'completed';

    return `${actionOrTaskCount}/${total} ${entity} ${verb}`;
  }

  get taskChipContent(): string[] {
    if (!this.isMultipleActions || !this.actions) return [];
    return this.actions.map(action => {
      return this.hasErrorInAction(action)
        ? `${this.getNumberOfTasksWithStatus('failed', action)}/${action.tasks.length} tasks failed`
        : `${this.getNumberOfTasksWithStatus('successful', action)}/${action.tasks.length} ${action.tasks.length > 1 ? 'tasks' : 'task'} completed`;
    });
  }

  get hasAnyActionError(): boolean {
    if (!this.actions) return false;
    return this.actions.some(action => this.hasErrorInAction(action));
  }

  get hasAnyActionProcessing(): boolean {
    if (!this.actions) return false;
    return this.actions.some(action => action.overallStatus === 'processing');
  }

  get areAllActionsSuccessful(): boolean {
    if (!this.actions) return false;
    return this.actions.every(action => action.overallStatus === 'successful');
  }

  get isSomeActionFailed(): boolean {
    if (!this.actions) return false;
    return this.actions.some(action => action.overallStatus === 'failed');
  }

  get numberOfFailedActions(): number {
    if (!this.actions) return 0;
    return this.getNumberOfActionsWithStatus('failed');
  }

  shouldExpandTaskPanel(action: KafkaWidgetActionData): boolean {
    return action.overallStatus !== 'successful';
  }

  retryAll(event: Event): void {
    event.stopPropagation(); // this is needed to prevent toggling the mat expansion panel on retry button click
    this.retryEvent.emit();
  }

  private getNumberOfActionsWithStatus(status: Status): number {
    return this.actions.filter(action => action.overallStatus === status).length;
  }

  private getNumberOfTasksWithStatus(status: Status, action: KafkaWidgetActionData): number {
    return action.tasks.filter(task => task.status === status).length;
  }

  private hasErrorInAction(action: KafkaWidgetActionData): boolean {
    return this.getNumberOfTasksWithStatus('failed', action) > 0;
  }
}
