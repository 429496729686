import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder, NydusNetworkParamConverter, NydusNetworkParamsConfig, ObjectUtils } from '@utils';

import { WebhookEvent, WebhookEventsFilter, WebhookEventsFilterWithPagination, WebhookEventsResult } from '../types';

@Injectable({
  providedIn: 'root'
})
export class WebhookEventsService {
  constructor(private missionControlService: MissionControlService) {}

  getWebhookEvents(filter: WebhookEventsFilter | WebhookEventsFilterWithPagination): Observable<WebhookEventsResult> {
    const paramsConfig: NydusNetworkParamsConfig = {
      filter: [
        { key: 'id', operator: '$eq' },
        { key: 'eventName', operator: '$eq' }
      ]
    };
    const params = NydusNetworkParamConverter.convertToNydusNetworkParam(filter, paramsConfig);

    return this.missionControlService.get<WebhookEventsResult>(
      'outgoing_requests',
      new HttpParams({
        fromObject: {
          ...ObjectUtils.prepareQueryObject(ObjectUtils.sanitizeRequestObject(params))
        },
        encoder: new BracketParamsEncoder()
      })
    );
  }

  getWebhookEvent(id: string): Observable<WebhookEvent> {
    return this.missionControlService.get<WebhookEvent>(`outgoing_requests/${id}`);
  }
}
