import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[adminHandleExternalRoute]'
})
export class HandleExternalRouteDirective {
  @Input() externalRoute: string;

  @HostListener('click', ['$event'])
  onClick(): void {
    if (this.externalRoute) {
      window.open(this.externalRoute);
    }
  }
}
