import { filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActivatedRoute } from '@angular/router';
import { IframeConfig } from '@kaligo/secure-fields-iframe';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { AccessPolicies } from '@core/services/user-abilities/access-policies-helper.service';
import { tenantBootstrapQuery } from '@core/store/tenant-bootstrap/selectors/tenant-bootstrap.selectors';
import { SCOPES_OR, TenantBootstrapState } from '@core/types';
import { AccessPolicyUtils, HostTenantMappingUtils, ObjectUtils, or } from '@utils';

import { SecureFieldsConfigs } from '../../../../app-module-config';
import { FormFieldsComponent } from '../../../form-fields/components/form-fields/form-fields.component';
import { FormOpenApiSchema } from '../../../form-fields/services/form-open-api-schema';
import { enrollAdmin, enrollAscendaAdmin, enrollUser } from '../../store/actions/users.actions';
import { usersQuery } from '../../store/selectors/users.selectors';
import { EnrollAdminForm, UserState } from '../../types';
import {
  EnrollUserFormCustomEvent,
  EnrollUserFormValidationErrorEvent
} from '../../types/enroll-user-form-bootstraps.type';

type EnrollFormType = 'admin' | 'ascendaAdmin' | 'user';

@Component({
  selector: 'admin-enroll-user',
  templateUrl: './enroll-user.component.html',
  styleUrls: ['enroll-user.component.scss'],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }]
})
export class EnrollUserComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  isSecureFieldsLoading: boolean;

  schema: FormOpenApiSchema;
  enrollUserForm: UntypedFormGroup;
  enrollAdminForm: FormGroup<EnrollAdminForm>;
  enrollAscendaAdminFormControl: FormControl<string>;
  displayedForm: EnrollFormType;
  formTypes: EnrollFormType[] = [];

  secureFieldIframeConfig: IframeConfig;
  tokenizationResults: Record<string, string> = {};
  validationErrors: Record<string, object> = {};

  loading$: Observable<boolean>;
  previousPageLabel: string;
  hasValidationError = false;

  readonly SCOPES = SCOPES_OR;
  readonly submitBtnId = 'submitBtn';

  constructor(
    @Inject('secureFieldsConfigs') private secureFieldsConfigs: SecureFieldsConfigs,
    private accessPolicies: AccessPolicies,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private store: Store<TenantBootstrapState>,
    private scopes: Scopes,
    private userStore: Store<UserState>
  ) {}

  @ViewChild('formFieldsComponent')
  set formFieldsComponent(formFieldsComponent: FormFieldsComponent) {
    if (formFieldsComponent) {
      this.isSecureFieldsLoading = formFieldsComponent.isSecureFieldsLoading;
      this.cdRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.loading$ = or(
      this.store.select(tenantBootstrapQuery.isSingleLoading),
      this.userStore.select(usersQuery.isSingleLoading)
    );

    // display 'customers' for enrollments page if both agents and customers page are invisible
    const frontendSettings = this.accessPolicies.getFrontendSettings('sidenav', 'show');
    const shouldDisplayCustomers = AccessPolicyUtils.isBothUsersPageInvisible(frontendSettings);
    this.previousPageLabel = shouldDisplayCustomers ? 'customers' : this.route.parent.snapshot.url[0].path;

    this.createEnrollForms();
  }

  createEnrollForms(): void {
    if (this.scopes.hasAny(SCOPES_OR.createUserEnrollments)) {
      this.createEnrollUserForm();
    }

    if (this.scopes.hasAny(SCOPES_OR.createAdminEnrollments)) {
      this.createEnrollAdminForm();
      this.formTypes.push('admin');
    }

    if (this.scopes.hasAny(SCOPES_OR.createAscendaAdminEnrollments)) {
      this.createEnrollAscendaAdminForm();
      this.formTypes.push('ascendaAdmin');
    }

    this.displayForm(this.formTypes[0]);
  }

  createEnrollAdminForm(): void {
    this.enrollAdminForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required])
    });
  }

  createEnrollAscendaAdminForm(): void {
    this.enrollAscendaAdminFormControl = this.fb.control('', [Validators.required, Validators.email]);
  }

  createEnrollUserForm(): void {
    this.store
      .select(tenantBootstrapQuery.getEnrollmentOpenApiSchema)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(enrollmentOpenApiSchema => !!enrollmentOpenApiSchema)
      )
      .subscribe(enrollmentOpenApiSchema => {
        if (this.secureFieldsConfigs) {
          const { version, pciProxyBaseUrl, submitModeConfigKey } = this.secureFieldsConfigs;

          this.secureFieldIframeConfig = {
            pciProxyBaseUrl,
            parentElementId: 'secure-field',
            version,
            styleUrl: HostTenantMappingUtils.getTenantStylesheetUrl(),
            submitElementId: this.submitBtnId,
            configKey: submitModeConfigKey,
            matFormFieldClasses: ['v1', 'slim-form-field', 'enroll-user-component-iframe-field'],
            onTokenize: () => {}, // define actual implementation in form-fields.component
            onError: () => {} // define actual implementation in form-fields.component
          };
        }

        this.schema = new FormOpenApiSchema(enrollmentOpenApiSchema);
        this.enrollUserForm = this.schema.createForm();
        this.formTypes.push('user');
      });
  }

  onTokenizeSuccess({ fieldName, fieldValue }: EnrollUserFormCustomEvent): void {
    this.tokenizationResults[fieldName] = fieldValue;

    if (this.schema.getPanInputKeys().every(key => !!this.tokenizationResults[key])) {
      const enrollmentRequest = this.schema.buildPayload(this.enrollUserForm.value, this.tokenizationResults);
      this.store.dispatch(enrollUser({ enrollmentRequest }));
    }
  }

  onValidationError({ fieldName, validationError }: EnrollUserFormValidationErrorEvent): void {
    this.validationErrors[fieldName] = validationError;

    // for enabling/disabling submit button
    this.hasValidationError = !ObjectUtils.isEmptyArray(Object.values(this.validationErrors));
  }

  onSubmit(type: EnrollFormType): void {
    switch (type) {
      case 'admin': {
        return this.store.dispatch(enrollAdmin({ enrollAdminRequest: this.enrollAdminForm.getRawValue() }));
      }
      case 'user': {
        if (this.schema.getPanInputKeys().length === 0) {
          const enrollmentRequest = this.schema.buildPayload(this.enrollUserForm.value);
          this.store.dispatch(enrollUser({ enrollmentRequest }));
        }
        return;
      }
      case 'ascendaAdmin': {
        return this.store.dispatch(enrollAscendaAdmin({ email: this.enrollAscendaAdminFormControl.getRawValue() }));
      }
    }
  }

  displayForm(input: EnrollFormType): void {
    switch (this.displayedForm) {
      case 'admin': {
        this.enrollAdminForm.reset();
        break;
      }
      case 'ascendaAdmin': {
        this.enrollAscendaAdminFormControl.reset();
        break;
      }
      case 'user': {
        this.enrollUserForm.reset();
      }
    }

    this.displayedForm = input;
  }
}
