import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SCOPES_OR } from '@core/types';
import { createResolver } from '@shared/route/resolver-helper';

import { loadRoles } from '../store/actions/roles.actions';
import { rolesQuery } from '../store/selectors/roles.selectors';
import { RoleState } from '../types';

export const rolesResolver: ResolveFn<void> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<void> =>
  createResolver<RoleState>({
    scope: SCOPES_OR.viewRoles,
    query: rolesQuery.isRolesLoaded,
    action: loadRoles()
  });
