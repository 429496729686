<div class="range-field">
  <b>{{ label }} range:</b>
  <div class="range-slider">
    <span [matTooltip]="sliderOptions.floor!.toString()">{{ lowLimitLabel }}</span>
    <ngx-slider
      [(value)]="lowPointer"
      [(highValue)]="highPointer"
      [options]="sliderOptions"
      (userChangeEnd)="filterDataByRange($event)"
    ></ngx-slider>
    <span [matTooltip]="sliderOptions.ceil!.toString()">{{ highLimitLabel }}</span>
  </div>
</div>
