import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import { updateStepUpAction } from '@core/store/interrupted/actions/interrupted.actions';

import { KeysService } from '../../services/keys.service';
import { KeysFilter } from '../../types/keys-filter.type';
import { KeyState } from '../../types/keys.type';
import {
  createKey,
  createKeyFailure,
  createKeySuccess,
  loadKey,
  loadKeyFailure,
  loadKeys,
  loadKeysFailure,
  loadKeysSuccess,
  loadKeySuccess,
  loadPrivateKey,
  loadPrivateKeyFailure,
  loadPrivateKeySuccess
} from '../actions/keys.actions';
import { keysQuery } from '../selectors/keys.selectors';

@Injectable()
export class KeysEffects {
  loadKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadKeys),
      exhaustMap(action =>
        this.keysService.getKeys(action.filter).pipe(
          map(keys => loadKeysSuccess({ keys })),
          catchError(error => of(loadKeysFailure({ error })))
        )
      )
    )
  );

  loadKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadKey),
      exhaustMap(action =>
        this.keysService.getKey(action.id).pipe(
          map(key => loadKeySuccess({ key })),
          catchError(error => of(loadKeyFailure({ error })))
        )
      )
    )
  );

  loadPrivateKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPrivateKey),
      tap(action => this.store.dispatch(updateStepUpAction({ action }))),
      exhaustMap(action =>
        this.keysService.getPrivateKey(action.keyId).pipe(
          map(privateKey => loadPrivateKeySuccess({ privateKey, keyId: action.keyId })),
          catchError(error => of(loadPrivateKeyFailure({ error })))
        )
      )
    )
  );

  createKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createKey),
      exhaustMap(action =>
        this.keysService.createKey(action.newKey).pipe(
          map(key => createKeySuccess({ key })),
          catchError(error => of(createKeyFailure({ error })))
        )
      )
    )
  );

  createKeySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createKeySuccess),
      withLatestFrom(this.store.select(keysQuery.getFilter)),
      map(([_, filter]) => {
        filter ||= new KeysFilter();
        return loadKeys({ filter });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private keysService: KeysService,
    private store: Store<KeyState>
  ) {}
}
