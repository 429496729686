import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { pointsActivityAdapter, pointsActivityInitialState, PointsActivityState } from '../../types';
import {
  loadPointsActivities,
  loadPointsActivitiesFailure,
  loadPointsActivitiesSuccess,
  loadPointsActivity,
  loadPointsActivityFailure,
  loadPointsActivitySuccess,
  setPointsActivitiesLocale
} from '../actions/points-activities.actions';

const pointsActivityTriggers: EntityTriggers = {
  single: {
    loading: [loadPointsActivity.type],
    resting: [loadPointsActivitySuccess.type],
    erroring: [loadPointsActivityFailure.type]
  },
  batch: {
    loading: [loadPointsActivities.type],
    resting: [loadPointsActivitiesSuccess.type],
    erroring: [loadPointsActivitiesFailure.type]
  }
};

export function pointsActivitiesReducer(
  state: PointsActivityState = pointsActivityInitialState,
  action: Action
): PointsActivityState {
  return callStateReducer(baseReducer, pointsActivityTriggers)(state, action);
}

const baseReducer = createReducer(
  pointsActivityInitialState,
  on(loadPointsActivities, (state, { filter }) => ({ ...state, filter })),
  on(loadPointsActivitiesSuccess, (state, { result, userId }) => ({
    ...pointsActivityAdapter.setAll(result.data, state),
    count: result?.meta?.count || 0,
    userId
  })),
  on(loadPointsActivitySuccess, (state, { pointsActivity }) => pointsActivityAdapter.upsertOne(pointsActivity, state)),
  on(setPointsActivitiesLocale, (state, { locale }) => ({ ...state, locale }))
);
