import { Selector, createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { UserOfferActivity, UserOfferActivityState, userOfferActivityAdapter } from '../../types';

const getUserOfferActivityState = createFeatureSelector<UserOfferActivityState>('userOfferActivity');

const { isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<UserOfferActivityState>(getUserOfferActivityState);

const getMetadata = createSelector(getUserOfferActivityState, (state: UserOfferActivityState) => state.metadata);

const getFilter = createSelector(getUserOfferActivityState, (state: UserOfferActivityState) => state.filter);

const { selectAll: getUserOfferActivities } = userOfferActivityAdapter.getSelectors(getUserOfferActivityState);

const getUserOfferActivitiesByUserId = (userId: string): Selector<UserOfferActivityState, UserOfferActivity[]> =>
  createSelector(getUserOfferActivities, getUserOfferActivitiesList =>
    getUserOfferActivitiesList.filter(userOfferActivity => userOfferActivity.userId === userId)
  );

const isUserOfferActivitiesLoaded = (userId: string): Selector<UserOfferActivityState, boolean> =>
  createSelector(
    getUserOfferActivityState,
    isBatchResting,
    (state, batchResting) => batchResting && state.userId === userId
  );

export const userOfferActivitiesQuery = {
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getUserOfferActivities,
  getUserOfferActivitiesByUserId,
  getMetadata,
  getFilter,
  isUserOfferActivitiesLoaded
};
