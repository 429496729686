import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';
import { LogicRule, LogicRuleAttribute } from '@shared/types/logic-rule.type';

export const loadSegmentAttributes = createAction('[Segment Creation] Load Rules');
export const loadSegmentAttributesSuccess = createAction(
  '[Segment Creation] Load Rules Success',
  props<{ attributeMap: Record<string, LogicRuleAttribute> }>()
);
export const loadSegmentAttributesFailure = createAction('[Segment Creation] Load Rules Failure', props<ErrorState>());

export const loadSegmentPreview = createAction('[Segment Creation] Load Review', props<{ logics: LogicRule[] }>());
export const loadSegmentPreviewSuccess = createAction(
  '[Segment Creation] Load Review Success',
  props<{ memberCount: number }>()
);
export const loadSegmentPreviewFailure = createAction('[Segment Creation] Load Review Failure', props<ErrorState>());
export const resetSegmentPreview = createAction('[Segment Creation] Reset segment preview');
