import { Inject, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Nullable } from '@shared/types';

import { RollbarService } from '../../../services/rollbar/rollbar.service';
import { SentryLogger } from '../../../services/sentry/sentry.factory';
import { SENTRY_LOGGER } from '../../../tokens/sentry.token';

type TypeName = 'formControl' | 'formGroup';

type OutputType<T> = T extends 'formControl' ? UntypedFormControl : T extends 'formGroup' ? UntypedFormGroup : never;

// To convert types in HTMl templates (angular strictTemplates mode)
// transform AbstractControl to FormControl
@Pipe({ name: 'formTransform' })
export class FormTransformPipe implements PipeTransform {
  constructor(
    private rollbarService: RollbarService,
    @Inject(SENTRY_LOGGER) private sentryLogger: SentryLogger
  ) {}

  transform<T extends TypeName>(value: any, type: T): Nullable<OutputType<T>> {
    if (!value) {
      return null;
    }

    if ((type === 'formControl' || type === 'formGroup') && !(value instanceof AbstractControl)) {
      this.rollbarService.manualError(new TypeError('Type of the value is not AbstractControl'));
      this.sentryLogger.error(new TypeError('Type of the value is not AbstractControl'));
      return null;
    }

    return value as OutputType<T>;
  }
}
