<mat-form-field class="field" [ngClass]="errorMessage ? 'ng-invalid mat-form-field-invalid' : ''">
  <mat-label>{{ fieldLabel | customTitleCase: 'fromCamelCase' }}</mat-label>
  <input
    *ngIf="schema.type === 'number'"
    matInput
    autocorrect="off"
    type="number"
    [attr.aria-required]="required"
    [disabled]="disabled"
    [id]="formControlName"
    [max]="schema.maximum || schema.exclusiveMaximum - (schema.multipleOf || 1) || MAX_SAFE_INTEGER"
    [min]="schema.minimum || schema.exclusiveMinimum + (schema.multipleOf || 1) || MIN_SAFE_INTEGER"
    [required]="required"
    [step]="schema.multipleOf"
    [value]="value"
  />
  <input
    *ngIf="schema.type === 'integer'"
    matInput
    adminIntegerInput
    autocorrect="off"
    type="number"
    [attr.aria-required]="required"
    [disabled]="disabled"
    [id]="formControlName"
    [max]="schema.maximum || schema.exclusiveMaximum - (schema.multipleOf || 1) || MAX_SAFE_INTEGER"
    [min]="schema.minimum || schema.exclusiveMinimum + (schema.multipleOf || 1) || MIN_SAFE_INTEGER"
    [required]="required"
    [step]="schema.multipleOf"
    [value]="value"
  />
  <mat-hint role="alert" *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>

<mat-error class="error-message" role="alert" *ngIf="errorMessage && ngControl.control.enabled">
  {{ errorMessage }}
</mat-error>
