import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialTransactionsState, transactionAdapter, TransactionsState } from '../../types';
import { loadTransactions, loadTransactionsFailure, loadTransactionsSuccess } from '../actions/transactions.actions';

export const transactionsTriggers: EntityTriggers = {
  batch: {
    loading: [loadTransactions.type],
    resting: [loadTransactionsSuccess.type],
    erroring: [loadTransactionsFailure.type]
  }
};

export function transactionReducer(
  state: TransactionsState = initialTransactionsState,
  action: Action
): TransactionsState {
  return callStateReducer(baseReducer, transactionsTriggers)(state, action);
}

const baseReducer = createReducer(
  initialTransactionsState,
  on(loadTransactions, (state, { filter }) => ({ ...state, filter })),
  on(loadTransactionsSuccess, (state, { result: { data, metadata }, userId }) => ({
    ...transactionAdapter.setAll(data, state),
    metadata,
    userId
  }))
);
