var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i;
    function verb(n) { if (g[n]) i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
import { TIMEOUT_ERROR_CODE } from '../errors';
var Interval = /** @class */ (function () {
    function Interval(retryThreshold, retryInterval) {
        this.retryThreshold = retryThreshold;
        this.retryInterval = retryInterval;
        this.isRunning = false;
    }
    Interval.prototype.start = function () {
        this.isRunning = true;
    };
    Interval.prototype.stop = function () {
        this.isRunning = false;
    };
    Interval.prototype.getGenerator = function () {
        return this[Symbol.asyncIterator]();
    };
    Interval.prototype.tokenDelay = function (pollAheadDuration, expiresAt) {
        return __awaiter(this, void 0, void 0, function () {
            var adjustment, tokenTTL;
            return __generator(this, function (_a) {
                adjustment = pollAheadDuration * 1000;
                tokenTTL = expiresAt * 1000 - Date.now();
                // If TTL is short (for testing purposes), change adjustment to be shorter as well to avoid having negative delays
                if (tokenTTL < 60000) {
                    adjustment /= 10;
                }
                return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, tokenTTL - adjustment); })];
            });
        });
    };
    Interval.prototype[Symbol.asyncIterator] = function () {
        return __asyncGenerator(this, arguments, function _a() {
            var retryCount, response, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        retryCount = 0;
                        _b.label = 1;
                    case 1:
                        if (!this.isRunning) return [3 /*break*/, 12];
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 7, , 11]);
                        return [4 /*yield*/, __await(this.auth())];
                    case 3:
                        response = _b.sent();
                        return [4 /*yield*/, __await(response)];
                    case 4: return [4 /*yield*/, _b.sent()];
                    case 5:
                        _b.sent();
                        retryCount = 0;
                        return [4 /*yield*/, __await(this.delay(response))];
                    case 6:
                        _b.sent();
                        return [3 /*break*/, 11];
                    case 7:
                        error_1 = _b.sent();
                        if (!(error_1.code === TIMEOUT_ERROR_CODE && retryCount < this.retryThreshold)) return [3 /*break*/, 9];
                        retryCount++;
                        return [4 /*yield*/, __await(this.timeoutDelay())];
                    case 8:
                        _b.sent();
                        return [3 /*break*/, 10];
                    case 9:
                        this.stop();
                        throw error_1;
                    case 10: return [3 /*break*/, 11];
                    case 11: return [3 /*break*/, 1];
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    Interval.prototype.timeoutDelay = function () {
        var _this = this;
        return new Promise(function (resolve) { return setTimeout(resolve, _this.retryInterval * 1000); });
    };
    return Interval;
}());
export { Interval };
