<mat-slide-toggle
  [id]="componentId"
  (change)="onToggleChange($event.checked)"
  class="min-w-[42px]"
  [ngClass]="{ 'toggle-on': value, 'toggle-empty-switch': !toggleOptions.buttonText }"
  [checked]="value"
  [disabled]="disabled"
  [required]="required"
  [style.background-color]="
    toggleOptions.backgroundColor
      ? value
        ? toggleOptions.backgroundColor.on
        : toggleOptions.backgroundColor.off
      : null
  "
>
  <div
    *ngIf="toggleOptions.buttonText"
    [style.color]="value ? toggleOptions.buttonTextColor?.on : toggleOptions.buttonTextColor?.off"
    class="my-[2px]"
    [ngClass]="{ '-mr-2': value, '-ml-4': !value }"
  >
    {{ value ? toggleOptions.buttonText.on : toggleOptions.buttonText.off }}
  </div>
</mat-slide-toggle>
