export class EventTypeFilter {
  description: string;
  eventType: string;
  page: number;
  limit: number;

  constructor() {
    this.description = '';
    this.eventType = '';
    this.page = 1;
    this.limit = 10;
  }
}
