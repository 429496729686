<mat-card appearance="outlined" class="mat-elevation-z1" [class.overlay]="loading$ | async">
  <mat-card-content *ngIf="!(loading$ | async)">
    <mat-card-title>Update User</mat-card-title>
    <mat-divider></mat-divider>

    <form class="form-container" fxLayout="column" [formGroup]="userForm" (keydown.enter)="$event.preventDefault()">
      <mat-form-field class="slim-form-field">
        <mat-label>ID</mat-label>
        <input matInput placeholder="ID" required formControlName="id" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field class="slim-form-field">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name" />
      </mat-form-field>

      <mat-form-field class="slim-form-field">
        <mat-label>Tenant Status</mat-label>
        <mat-select required formControlName="status" disableOptionCentering panelClass="dropdown-panel">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status | customTitleCase: 'fromSnakeCase' }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field class="slim-form-field">
        <mat-label>Login Mode</mat-label>
        <mat-select required formControlName="loginMode" disableOptionCentering panelClass="dropdown-panel">
          <mat-option *ngFor="let loginMode of loginModes" [value]="loginMode">
            {{ loginMode | customTitleCase: 'fromSnakeCase' }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field class="slim-form-field">
        <mat-label>Activation Status</mat-label>
        <mat-select required formControlName="activated" disableOptionCentering panelClass="dropdown-panel">
          <mat-option [value]="true">Activated</mat-option>
          <mat-option [value]="false">Not Activated</mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <ng-container *adminIfScopesInclude="SCOPES.viewRoles; relationOperator: 'or'">
        <mat-form-field *ngIf="(roleIds$ | async).length > 0" class="roles-input">
          <mat-label>Roles</mat-label>
          <admin-input-chip-list
            formControlName="roles"
            placeholder="New Role"
            [selectableValues]="selectableRoleIds"
            [nonRemovableValues]="undeletableRoleIds"
            [allowAnyInput]="false"
          ></admin-input-chip-list>
          <mat-hint>Roles are effective only if user's status is <b>admin</b></mat-hint>
          <mat-error *ngIf="userForm.get('roles').hasError('duplicateValueError')">Duplicate value</mat-error>
        </mat-form-field>
      </ng-container>

      <mat-card-title>Details</mat-card-title>
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
        <mat-form-field class="slim-form-field" fxFlex="20">
          <mat-label>Salutation</mat-label>
          <input matInput placeholder="Salutation" formControlName="salutation" />
        </mat-form-field>

        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="firstName" />
        </mat-form-field>

        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>Middle Name</mat-label>
          <input matInput placeholder="Middle Name" formControlName="middleName" />
        </mat-form-field>

        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="lastName" />
        </mat-form-field>
      </div>

      <mat-form-field class="slim-form-field">
        <mat-label>Nickname</mat-label>
        <input matInput placeholder="Nickname" formControlName="nickname" />
      </mat-form-field>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>Gender</mat-label>
          <input matInput placeholder="Gender" formControlName="gender" />
        </mat-form-field>

        <mat-form-field class="slim-form-field" color="accent" fxFlex>
          <mat-label>Birthdate</mat-label>
          <input
            matInput
            [matDatepicker]="birthdatePicker"
            formControlName="birthdate"
            (focus)="birthdatePicker.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="birthdatePicker"></mat-datepicker-toggle>
          <mat-datepicker #birthdatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>Locale</mat-label>
          <input matInput placeholder="Locale" formControlName="locale" />
        </mat-form-field>

        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>Zoneinfo</mat-label>
          <input matInput placeholder="Zoneinfo" formControlName="zoneinfo" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" />
          <mat-error>Invalid email</mat-error>
          <admin-tooltip
            *ngIf="!(loading$ | async)"
            matSuffix
            [key]="'users.edit.button.editEmail?isEmailEditable=' + isEmailEditable"
            [displayIcon]="false"
            [styleClassName]="'email-edit-button-tooltip'"
          >
            <button mat-icon-button *ngIf="isEmailEditable" (click)="openEmailDialog()">
              <mat-icon>create</mat-icon>
            </button>
          </admin-tooltip>
        </mat-form-field>

        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="email-verified-toggle-wrapper">
          <mat-slide-toggle formControlName="emailVerified" fxFlex> Email Verified </mat-slide-toggle>
          <mat-hint class="hint">
            <div class="hint__icon h8 text-primary bg-secondary">!</div>
            <span
              >Be sure to toggle the “verified” flag of the user’s email MFA identity when changing this! You can find
              the list of email identities on the User info page.</span
            >
          </mat-hint>
        </div>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
        <mat-form-field class="slim-form-field" fxFlex>
          <mat-label>Phone Number</mat-label>
          <input matInput placeholder="Phone Number" formControlName="phoneNumber" />
          <mat-error>{{ invalidE164Message }}</mat-error>
        </mat-form-field>

        <mat-slide-toggle formControlName="phoneNumberVerified" fxFlex> Phone Number Verified </mat-slide-toggle>
      </div>

      <mat-slide-toggle id="eStatement-toggle" formControlName="eStatement" fxFlex> e-Statement </mat-slide-toggle>

      <mat-form-field class="slim-form-field">
        <mat-label>Picture</mat-label>
        <input matInput placeholder="Picture" formControlName="picture" />
      </mat-form-field>

      <mat-form-field class="slim-form-field">
        <mat-label>Preferred Username</mat-label>
        <input matInput placeholder="Preferred Username" formControlName="preferredUsername" />
      </mat-form-field>

      <mat-form-field class="slim-form-field">
        <mat-label>Profile</mat-label>
        <input matInput placeholder="Profile" formControlName="profile" />
      </mat-form-field>

      <ng-container formGroupName="address">
        <mat-card-title>Address</mat-card-title>
        <mat-form-field class="slim-form-field">
          <mat-label>Formatted</mat-label>
          <input matInput placeholder="Formatted" formControlName="formatted" />
        </mat-form-field>

        <mat-form-field class="slim-form-field">
          <mat-label>Street Address</mat-label>
          <input matInput placeholder="Street Address" formControlName="streetAddress" />
        </mat-form-field>

        <mat-form-field class="slim-form-field">
          <mat-label>Locality</mat-label>
          <input matInput placeholder="Locality" formControlName="locality" />
        </mat-form-field>

        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
          <mat-form-field class="slim-form-field" fxFlex>
            <mat-label>Region</mat-label>
            <input matInput placeholder="Region" formControlName="region" />
          </mat-form-field>

          <mat-form-field class="slim-form-field" fxFlex>
            <mat-label>Postal Code</mat-label>
            <input matInput placeholder="Postal Code" formControlName="postalCode" />
          </mat-form-field>

          <mat-form-field class="slim-form-field" fxFlex>
            <mat-label>Country</mat-label>
            <input matInput placeholder="Country" formControlName="country" />
          </mat-form-field>
        </div>
      </ng-container>

      <mat-card-title>Data</mat-card-title>
      <mat-form-field>
        <mat-label>Loyalty Data</mat-label>
        <textarea matInput placeholder="Loyalty Data" formControlName="loyalty_data" rows="4"></textarea>
        <mat-error>Invalid JSON</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Custom</mat-label>
        <textarea matInput placeholder="Custom" formControlName="custom" rows="4"></textarea>
        <mat-error *ngIf="userForm.get('custom').hasError('invalidJSON')">Invalid JSON</mat-error>
        <mat-error *ngIf="userForm.get('custom').hasError('invalidCustomDataFormat')">
          {{ invalidCustomDataMessage }}</mat-error
        >
        <mat-error *ngIf="userForm.get('custom').hasError('invalidEStatementDataType')"
          >digital_rewards_statement should be a boolean</mat-error
        >
      </mat-form-field>

      <div class="form-actions">
        <button mat-raised-button [routerLink]="cancelButtonLink" class="uppercase-button">Cancel</button>
        <button
          mat-raised-button
          adminHandleErrorFields
          adminPristineFormDisabled
          color="primary"
          type="submit"
          class="uppercase-button"
          [dateFields]="['birthdate']"
          [form]="userForm"
          (submitForm)="submitForm()"
        >
          Update
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<admin-spinner *ngIf="loading$ | async"></admin-spinner>
