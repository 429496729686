// following RD's DayJSConfig here
export const DayJsConfig = {
  // Thresholds are base on the default value in dayjs/plugin/relativeTime
  relativeTimeConfig: {
    thresholds: [
      { l: 's', r: 44, d: 'second' },
      { l: 'm', r: 89 },
      { l: 'mm', r: 44, d: 'minute' },
      { l: 'h', r: 89 },
      { l: 'hh', r: 23, d: 'hour' }, // Customize base on our requirement
      { l: 'd', r: 35 },
      { l: 'dd', r: 25, d: 'day' },
      { l: 'M', r: 45 },
      { l: 'MM', r: 10, d: 'month' },
      { l: 'y', r: 17 },
      { l: 'yy', d: 'year' }
    ]
  },

  enLocaleConfig: {
    relativeTime: {
      d: '1 business day',
      dd: '%d business days',
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years'
    }
  }
};
