import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, segmentAdapter, SegmentState } from '../../types';
import {
  activateSegment,
  activateSegmentFailure,
  activateSegmentSuccess,
  createSegment,
  createSegmentFailure,
  createSegmentSuccess,
  deactivateSegment,
  deactivateSegmentFailure,
  deactivateSegmentSuccess,
  deleteSegment,
  deleteSegmentFailure,
  deleteSegmentSuccess,
  loadSegment,
  loadSegmentFailure,
  loadSegments,
  loadSegmentsFailure,
  loadSegmentsSuccess,
  loadSegmentSuccess,
  updateSegment,
  updateSegmentFailure,
  updateSegmentSuccess
} from '../actions/segments.actions';

export const segmentTriggers: EntityTriggers = {
  single: {
    loading: [
      loadSegment.type,
      updateSegment.type,
      deleteSegment.type,
      createSegment.type,
      activateSegment.type,
      deactivateSegment.type
    ],
    resting: [
      loadSegmentSuccess.type,
      updateSegmentSuccess.type,
      deleteSegmentSuccess.type,
      createSegmentSuccess.type,
      activateSegmentSuccess.type,
      deactivateSegmentSuccess.type
    ],
    erroring: [
      loadSegmentFailure.type,
      updateSegmentFailure.type,
      deleteSegmentFailure.type,
      createSegmentFailure.type,
      activateSegmentFailure.type,
      deactivateSegmentFailure.type
    ]
  },
  batch: {
    loading: [loadSegments.type],
    resting: [loadSegmentsSuccess.type],
    erroring: [loadSegmentsFailure.type]
  }
};

export function segmentsReducer(state: SegmentState = initialState, action: Action): SegmentState {
  return callStateReducer(baseReducer, segmentTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadSegments, (state, { filter }) => ({ ...state, filter })),
  on(loadSegmentsSuccess, (state, { segments, total }) => ({ ...segmentAdapter.setAll(segments, state), total })),
  on(loadSegmentSuccess, (state, { segment }) => segmentAdapter.upsertOne(segment, state)),
  on(updateSegmentSuccess, (state, { segment }) =>
    segmentAdapter.updateOne({ id: segment.id, changes: segment }, state)
  ),
  on(deleteSegmentSuccess, (state, { id }) =>
    segmentAdapter.updateOne(
      {
        id,
        changes: {
          status: 'deleted'
        }
      },
      state
    )
  ),
  on(createSegmentSuccess, (state, { segment }) => segmentAdapter.addOne(segment, state))
);
