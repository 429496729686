import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { routerNavigate } from '@core/store/router/actions/router.actions';

import { ProductType } from '../../../dashboard/types';
import { RedemptionRatesService } from '../../services/redemption-rates.service';
import { RedemptionRateState } from '../../types';
import {
  createRedemptionRate,
  createRedemptionRateFailure,
  createRedemptionRateSuccess,
  deleteRedemptionRate,
  deleteRedemptionRateFailure,
  deleteRedemptionRateSuccess,
  fetchRedemptionRate,
  fetchRedemptionRateFailure,
  fetchRedemptionRateSuccess,
  loadRedemptionRate,
  loadRedemptionRateFailure,
  loadRedemptionRates,
  loadRedemptionRatesFailure,
  loadRedemptionRatesSuccess,
  loadRedemptionRateSuccess,
  updateRedemptionRate,
  updateRedemptionRateFailure,
  updateRedemptionRateSuccess
} from '../actions/redemption-rates.actions';
import { redemptionRatesQuery } from '../selectors/redemption-rates.selectors';

@Injectable()
export class RedemptionRatesEffects {
  loadRedemptionRates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRedemptionRates),
      exhaustMap(action =>
        this.redemptionRatesService.getRedemptionRates(action.filter).pipe(
          map(result => loadRedemptionRatesSuccess({ result })),
          catchError(error => of(loadRedemptionRatesFailure({ error })))
        )
      )
    )
  );

  loadRedemptionRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRedemptionRate),
      exhaustMap(({ id }) =>
        this.redemptionRatesService.getRedemptionRate(id).pipe(
          map(redemptionRate => loadRedemptionRateSuccess({ redemptionRate })),
          catchError(error => of(loadRedemptionRateFailure({ error })))
        )
      )
    )
  );

  fetchRedemptionRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRedemptionRate),
      mergeMap(({ id }) =>
        this.redemptionRatesService.getRedemptionRate(id).pipe(
          map(redemptionRate => fetchRedemptionRateSuccess({ redemptionRate })),
          catchError(() => of(fetchRedemptionRateFailure({ id })))
        )
      )
    )
  );

  createRedemptionRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRedemptionRate),
      exhaustMap(action =>
        this.redemptionRatesService.createRedemptionRate(action.redemptionRate).pipe(
          map(redemptionRate => createRedemptionRateSuccess({ redemptionRate })),
          catchError(error => of(createRedemptionRateFailure({ error })))
        )
      )
    )
  );

  updateRedemptionRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRedemptionRate),
      exhaustMap(action =>
        this.redemptionRatesService.updateRedemptionRate(action.redemptionRate).pipe(
          map(redemptionRate => updateRedemptionRateSuccess({ redemptionRate })),
          catchError(error => of(updateRedemptionRateFailure({ error })))
        )
      )
    )
  );

  deleteRedemptionRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRedemptionRate),
      exhaustMap(({ id }) =>
        this.redemptionRatesService.deleteRedemptionRate(id).pipe(
          map(() => deleteRedemptionRateSuccess()),
          catchError(error => of(deleteRedemptionRateFailure({ error })))
        )
      )
    )
  );

  createDeleteRedemptionRateSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRedemptionRateSuccess, deleteRedemptionRateSuccess),
      withLatestFrom(this.store.select(redemptionRatesQuery.getFilter)),
      filter(([_, redemptionRatefilter]) => !!redemptionRatefilter),
      map(([_, redemptionRatefilter]) => loadRedemptionRates({ filter: redemptionRatefilter }))
    )
  );

  createUpdateRedemptionRateSuccessRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRedemptionRateSuccess, updateRedemptionRateSuccess),
      map(({ redemptionRate }) => {
        let path = '';
        switch (redemptionRate.productType) {
          case ProductType.GiftCard:
            path = 'gift-card';
            break;

          case ProductType.Merchandise:
            path = 'merchandise';
            break;
        }
        return routerNavigate({ path: `redemption-rates/${path}` });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<RedemptionRateState>,
    private redemptionRatesService: RedemptionRatesService
  ) {}
}
