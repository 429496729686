import { createSelector } from '@ngrx/store';

import { CoreState, getCoreState } from '@core/store';
import { UserAbilitiesState } from '@core/types';

import { getUserName } from '../../../../users/types';
import { AuthState } from '../reducers/auth.reducer';

const getAuthState = createSelector(getCoreState, (state: CoreState) => state.auth);
const getUserAbilitiesState = createSelector(getCoreState, (state: CoreState) => state.userAbilities);

const getUser = createSelector(getAuthState, (state: AuthState) => state.user);
const getUserEmail = createSelector(getAuthState, (state: AuthState) => state.user?.email);
const getUserId = createSelector(getAuthState, (state: AuthState) => state.user?.sub);
const getTokenResult = createSelector(getAuthState, (state: AuthState) => state.tokenResult);
const getHermesLoading = createSelector(getAuthState, (state: AuthState) => state.hermesLoading);
const getIsLoggedIn = createSelector(getAuthState, (state: AuthState) => state.isLoggedIn);
const getScopes = createSelector(getAuthState, (state: AuthState) => state.scopes);
const getUserTenantId = createSelector(getAuthState, (state: AuthState) => state.user?.tenantId);
const getLoginFail = createSelector(getAuthState, (state: AuthState) => state.loginFail);
const getIsSessionCheckRunning = createSelector(getAuthState, (state: AuthState) => state.isSessionCheckRunning);
const getIsIdleCheckRunning = createSelector(getAuthState, (state: AuthState) => state.isIdleCheckRunning);
const getCurrentUserName = createSelector(getAuthState, (state: AuthState) => getUserName(state.user));
const isUserAbilitiesLoaded = createSelector(getUserAbilitiesState, (state: UserAbilitiesState) => state.loaded);

export const authQuery = {
  getUser,
  getUserEmail,
  getUserId,
  getTokenResult,
  getLoginFail,
  getHermesLoading,
  getIsLoggedIn,
  getScopes,
  getUserTenantId,
  getIsSessionCheckRunning,
  getIsIdleCheckRunning,
  getCurrentUserName,
  isUserAbilitiesLoaded
};
