import { Injectable } from '@angular/core';

import { Scopes } from '@core/services/scopes/scopes.service';
import { SCOPES_OR } from '@core/types';
import { Nullable, SELECTABLE_TAG_TYPES, Tag } from '@shared/types';
import { Formatters } from '@utils';

import { AppConnection } from '../../apps/types/app-connections.type';
import { App } from '../../apps/types/apps.type';
import { AuthenticationProvider } from '../../authentication-providers/types/authentication-providers.type';
import { Campaign } from '../../campaigns/types';
import { Domain } from '../../domains/types';
import { FraudRule } from '../../fraud-rules/types/fraud-rules.type';
import { LoyaltyProgram } from '../../loyalty-programs/types/loyalty-programs.type';
import { Merchandise } from '../../merchandises/types/merchandises.type';
import { Offer } from '../../offers/types';
import { RedemptionRate } from '../../redemption-rates/types';
import { RedemptionRateUtils } from '../../redemption-rates/utils/redemption-rate-utils';
import { isAdmin, User } from '../../users/types';
import { TagDictionary, TagInfo } from '../types/tag-dictionary.type';

@Injectable({ providedIn: 'root' })
export class TagDictionaryService {
  readonly userTagInfo: TagInfo = {
    getDisplayPrefix: (user: User) => (user ? (isAdmin(user) ? 'Agent' : 'Customer') : 'User'),
    getDisplayValue: ({ id, firstName, lastName }) =>
      firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName || id,
    getRouteLink: (user: User) => this.getUserRouteWithScopeCheck(user)
  };
  readonly tenantTagInfo: TagInfo = {
    getDisplayPrefix: () => 'Tenant',
    getRouteLink: (tag: Tag) => (this.scopes.hasAny(SCOPES_OR.showTenants) ? `/tenants/${tag.id}` : null)
  };

  readonly tagDictionary: TagDictionary = {
    app_connection_id: {
      getDisplayPrefix: () => 'App Connection',
      getDisplayValue: (appConnection: AppConnection) => appConnection.name,
      getRouteLink: (appConnection: AppConnection) =>
        this.scopes.hasAny(SCOPES_OR.showAppConnections)
          ? `/apps/${appConnection.resourceId}/details/connections/${appConnection.id}`
          : null
    },
    app_id: {
      getDisplayPrefix: () => 'App',
      getDisplayValue: (app: App) => app.name,
      getRouteLink: (app: App) => (this.scopes.hasAny(SCOPES_OR.showApps) ? `/apps/${app.id}` : null)
    },
    authentication_provider_reference: {
      getDisplayPrefix: () => 'Authentication Provider',
      getDisplayValue: (authenticationProvider: AuthenticationProvider) =>
        `${authenticationProvider.tenantId} - ${authenticationProvider.providerId}`,
      getRouteLink: (authenticationProvider: AuthenticationProvider) =>
        this.scopes.hasAny(SCOPES_OR.showAuthenticationProviders)
          ? `/authentication-providers/${authenticationProvider.reference}`
          : null
    },
    campaign_id: {
      getDisplayPrefix: () => 'Campaign',
      getDisplayValue: (campaign: Campaign) => campaign.title,
      getRouteLink: (campaign: Campaign) =>
        this.scopes.hasAny(SCOPES_OR.showCampaigns) ? `/campaigns/${campaign.id}/details` : null
    },
    domain_id: {
      getDisplayPrefix: () => 'Domain',
      getDisplayValue: (domain: Domain) => domain.domain,
      getRouteLink: (domain: Domain) =>
        this.scopes.hasAny(SCOPES_OR.showDomains) ? `/domains/${domain.id}/details` : null
    },
    loyalty_program_id: {
      getDisplayPrefix: () => 'Loyalty Program',
      getDisplayValue: (loyaltyProgram: LoyaltyProgram) => loyaltyProgram.name || loyaltyProgram.id,
      getRouteLink: (loyaltyProgram: LoyaltyProgram) =>
        this.scopes.hasAny(SCOPES_OR.showLoyaltyPrograms) ? `/loyalty-programs/${loyaltyProgram.id}` : null
    },
    offer_id: {
      getDisplayPrefix: () => 'Offer',
      getDisplayValue: (offer: Offer) => `"${offer.title} (${offer.brand})"`,
      getRouteLink: (offer: Offer) => (this.scopes.hasAny(SCOPES_OR.showOffers) ? `/offers/${offer.id}/details` : null)
    },
    redemption_rate_id: {
      getDisplayPrefix: () => 'Redemption Rate',
      getDisplayValue: (redemptionRate: RedemptionRate) =>
        Formatters.fromSnakeToTitleCase(redemptionRate.productType!) +
        ' ' +
        (redemptionRate.brand ?? 'All') +
        ': ' +
        RedemptionRateUtils.formatPrice(redemptionRate.minPrice!, redemptionRate.maxPrice!),
      getRouteLink: (redemptionRate: RedemptionRate) =>
        this.scopes.hasAny(SCOPES_OR.showPricing)
          ? `/redemption-rates/${redemptionRate.productType!.replace('_', '-')}`
          : null
    },
    agent_id: { ...this.userTagInfo },
    user_id: { ...this.userTagInfo },
    approver_id: { ...this.userTagInfo, getDisplayPrefix: () => 'Approver' },
    requester_id: { ...this.userTagInfo, getDisplayPrefix: () => 'Requester' },
    points_activity_id: {
      getDisplayPrefix: () => 'Points Activity',
      getDisplayValue: ({ tag }: { tag: Tag }) => tag.id,
      getRouteLink: ({ tag, userId }: { tag: Tag; userId: string }) =>
        userId && this.scopes.hasAny(SCOPES_OR.showPointsActivities)
          ? `/customers/${userId}/dashboard/points-activities/${tag.id}`
          : null
    },
    ability_id: {
      getDisplayPrefix: () => 'Ability'
    },
    access_policy_id: {
      getDisplayPrefix: () => 'Access Policy',
      getRouteLink: (tag: Tag) =>
        this.scopes.hasAny(SCOPES_OR.showAccessPolicies) ? `/access-policies/${tag.id}` : null
    },
    approval_request_id: {
      getDisplayPrefix: () => 'Approval Request',
      getRouteLink: (tag: Tag) =>
        this.scopes.hasAny(SCOPES_OR.showApprovalRequests) ? `/approval-requests/${tag.id}` : null
    },
    gift_card_id: {
      getDisplayPrefix: () => 'Gift Card',
      getRouteLink: (tag: Tag) => (this.scopes.hasAny(SCOPES_OR.showGiftCards) ? `/gift-cards/cards/${tag.id}` : null)
    },
    login_attempt_id: {
      getDisplayPrefix: () => 'Login Attempt',
      getRouteLink: (tag: Tag) => (this.scopes.hasAny(SCOPES_OR.showLoginAttempts) ? `/login-attempts/${tag.id}` : null)
    },
    note_id: {
      getDisplayPrefix: () => 'Note'
    },
    gift_card_order_id: {
      getDisplayPrefix: () => 'Gift Card Order',
      getRouteLink: (tag: Tag) =>
        this.scopes.hasAny(SCOPES_OR.showGiftCardOrders) ? `/gift-cards/orders/${tag.id}` : null
    },
    order_item_id: {
      getDisplayPrefix: () => 'Order Item',
      getRouteLink: (tag: Tag) => (this.scopes.hasAny(SCOPES_OR.showOrderItems) ? `/orders-items/${tag.id}` : null)
    },
    provider_id: {
      getDisplayPrefix: () => 'Auth Provider'
    },
    role_id: {
      getDisplayPrefix: () => 'Role',
      getRouteLink: (tag: Tag) => (this.scopes.hasAny(SCOPES_OR.showRoles) ? `/roles/${tag.id}` : null)
    },
    scope_id: {
      getDisplayPrefix: () => 'Scope'
    },
    tenant_id: { ...this.tenantTagInfo },
    token_id: {
      getDisplayPrefix: () => 'Token'
    },
    reference: {
      getDisplayPrefix: () => 'Reference'
    },
    merchandise_id: {
      getDisplayPrefix: () => 'Merchandise',
      getDisplayValue: (merchandise: Merchandise) => merchandise.name,
      getRouteLink: (merchandise: Merchandise) =>
        this.scopes.has('merchandises:show') ? `/merchandises/${merchandise.id}/details` : null
    },
    fraud_rule_id: {
      getDisplayPrefix: () => 'Fraud Rule',
      getDisplayValue: (fraudRule: FraudRule) => Formatters.fromSnakeToTitleCase(fraudRule.name),
      getRouteLink: (fraudRule: FraudRule) =>
        this.scopes.has('view_fraud_rules') ? `/fraud-rules/${fraudRule.id}/details` : null
    }
  };

  constructor(private scopes: Scopes) {}

  formatTag(tag: Tag, tagData?: any): Tag {
    const tagInfo = this.tagDictionary[tag.type]!;

    return SELECTABLE_TAG_TYPES.includes(tag.type)
      ? {
          ...tag,
          displayPrefix: tagInfo.getDisplayPrefix(tagData),
          displayValue: tagData ? tagInfo.getDisplayValue!(tagData) : tag.id,
          routeLink: tagData ? tagInfo.getRouteLink!(tagData) : null
        }
      : {
          ...tag,
          displayPrefix: tagInfo?.getDisplayPrefix() ?? Formatters.fromSnakeToTitleCase(tag.type),
          displayValue: tag.id,
          routeLink: tagInfo?.getRouteLink ? tagInfo.getRouteLink(tag) : null
        };
  }

  private getUserRouteWithScopeCheck(user: User): Nullable<string> {
    if (isAdmin(user)) {
      if (this.scopes.hasAny(SCOPES_OR.showAgents)) {
        return `/agents/${user.id}`;
      }
    } else {
      if (this.scopes.hasAny(SCOPES_OR.showCustomers)) {
        return `/customers/${user.id}`;
      }
    }

    return null;
  }
}
