import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { Domain, DomainsFilter, DomainsResult, UpdateDomainParams } from '../types';

@Injectable({
  providedIn: 'root'
})
export class DomainsService {
  constructor(private missionControlService: MissionControlService) {}

  getDomain(id: string): Observable<Domain> {
    return this.missionControlService.get<Domain>(`domains/${id}`);
  }

  getDomains(filter: DomainsFilter): Observable<DomainsResult> {
    const { isTopLevelView, ...rest } = filter;
    filter = ObjectUtils.sanitizeRequestObject<DomainsFilter>(rest);
    return this.missionControlService.get<DomainsResult>('domains', new HttpParams({ fromObject: { ...filter } }));
  }

  createDomain(domain: Domain): Observable<Domain> {
    const payload = ObjectUtils.sanitizeRequestObject<Domain>(domain);
    return this.missionControlService.post<Domain>('domains', payload);
  }

  updateDomain(domain: UpdateDomainParams): Observable<Domain> {
    return this.missionControlService.patch<Domain>(`domains/${domain.id}`, domain);
  }

  deleteDomain(id: string): Observable<void> {
    return this.missionControlService.delete<void>(`domains/${id}`);
  }
}
