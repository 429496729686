import { FeatureSettingProductKeys } from './nydus-network-partner-configuration.type';

export const scopesMapping: {
  view: {
    [key in FeatureSettingProductKeys]?: string;
  };
  manage: {
    [key in FeatureSettingProductKeys]?: string;
  };
  updateSortIndex: {
    [key in FeatureSettingProductKeys]?: string;
  };
} = {
  view: {
    crypto: 'view_crypto_products',
    cashback: 'view_cashback_products',
    sustainability: 'view_sustainability_products',
    frequentTravelerPrograms: 'view_frequent_traveler_programs',
    affiliateOffers: 'view_affiliate_offers',
    cardLinkedOffers: 'view_card_linked_offers_configs',
    giftCards: 'view_gift_cards_configs',
    cars: 'view_cars_configs',
    flights: 'view_flights_configs',
    hotels: 'view_hotels_configs'
  },
  manage: {
    crypto: 'manage_crypto_products',
    cashback: 'manage_cashback_products',
    sustainability: 'manage_sustainability_products',
    frequentTravelerPrograms: 'manage_frequent_traveler_programs',
    affiliateOffers: 'manage_affiliate_offers',
    cardLinkedOffers: 'manage_card_linked_offers_configs',
    giftCards: 'manage_gift_cards_configs',
    cars: 'manage_cars_configs',
    flights: 'manage_flights_configs',
    hotels: 'manage_hotels_configs'
  },
  updateSortIndex: {
    crypto: 'manage_crypto_products_sort_index',
    cashback: 'manage_cashback_products_sort_index',
    sustainability: 'manage_sustainability_products_sort_index',
    frequentTravelerPrograms: 'manage_frequent_traveler_programs_sort_index'
  }
};
