import { AttributeDef, NavItem } from '@core/types';

import { Preferences, User } from '../modules/users/types';

type UserAttributeDef = AttributeDef<User> | AttributeDef<Preferences>;

export const TooltipKeysUtils = {
  getUserDetailsSubKeys: (detail: UserAttributeDef): string => (detail.subKeys ? `.${detail.subKeys.join('.')}` : ''),

  getUserDetailsKey: (detail: UserAttributeDef): string =>
    `users.details.details.${detail.key + TooltipKeysUtils.getUserDetailsSubKeys(detail)}`,

  getUserDetailsKeys: (detailsArrays: UserAttributeDef[][]): string[] =>
    detailsArrays.flat().map(detail => TooltipKeysUtils.getUserDetailsKey(detail)),

  getNavItemKeys: (itemsArr: NavItem[]): string[] =>
    itemsArr
      .flatMap(topLevelItem => [topLevelItem.id].concat(topLevelItem.children?.map(childItem => childItem.id) || []))
      .filter(id => !!id)
      .map(id => 'sidenav.menu.' + id)
};
