import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

import { Scopes } from '@core/services/scopes/scopes.service';
import { CallState } from '@core/types';

import { UpsellDialogData } from '../../../core/types/upsell-dialog.type';
import { notifyIntentToUpgrade } from './store/actions/upsell-dialog.actions';

@Component({
  selector: 'admin-upsell-dialog',
  templateUrl: './upsell-dialog.component.html',
  standalone: true,
  imports: [LottieComponent]
})
export class UpsellDialogComponent {
  options: AnimationOptions = this.data.animationOptions;

  constructor(
    public dialogRef: MatDialogRef<UpsellDialogComponent>,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: UpsellDialogData,
    private scopes: Scopes,
    private store: Store<CallState>
  ) {}

  get isUpgradeButtonVisible(): boolean {
    return this.scopes.has('manage_account_upgrade');
  }

  goBack(): void {
    this.location.back();
  }

  notifyIntentToUpgrade(): void {
    this.store.dispatch(notifyIntentToUpgrade());
  }
}
