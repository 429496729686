var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { version } from '../../package.json';
import { SessionError, StateInvalidError, StateMissingError } from '../errors';
import { getLastActivityTimestamp, updateLastActivityTimestamp } from '../local-storage-util';
import { isBoolean, isNullish } from '../util';
import { LocalStorageQueue } from '../utils/local-storage-queue';
import { OptionsValidationUtils } from '../utils/options-validation-utils';
import { IframeHandler } from './iframe_handler';
var SessionCheck = /** @class */ (function () {
    function SessionCheck(options) {
        this.options = options;
        OptionsValidationUtils.checkOptionPropertyPresent(this.options, ['authBaseUrl']);
    }
    SessionCheck.prototype.call = function (state) {
        return __awaiter(this, void 0, void 0, function () {
            var response, hasTimestamp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new IframeHandler({
                            authBaseUrl: this.options.authBaseUrl,
                            url: this.buildUrl(state)
                        }).call()];
                    case 1:
                        response = _a.sent();
                        hasTimestamp = !isNullish(response.lastActivityTimestamp);
                        if (!isBoolean(response.loggedIn) || (hasTimestamp && !Number.isInteger(response.lastActivityTimestamp))) {
                            throw new SessionError();
                        }
                        if (!response.state) {
                            throw new StateMissingError();
                        }
                        if (response.state !== state) {
                            throw new StateInvalidError(state, response.state);
                        }
                        if (hasTimestamp) {
                            this.handleLastActivityTimestamp(response.lastActivityTimestamp);
                        }
                        return [2 /*return*/, response];
                }
            });
        });
    };
    SessionCheck.prototype.buildUrl = function (state) {
        var queryParams = "state=" + state + "&hermes_version=" + version;
        var lastActivityTimestamp = getLastActivityTimestamp();
        if (lastActivityTimestamp) {
            queryParams += "&lastActivityTimestamp=" + lastActivityTimestamp;
        }
        var refreshToken = this.getRefreshToken();
        if (refreshToken) {
            queryParams += "&refresh_token=" + refreshToken;
        }
        return this.options.authBaseUrl + "/session/check?" + queryParams;
    };
    SessionCheck.prototype.getRefreshToken = function () {
        var _a;
        var refreshToken = LocalStorageQueue.peek('refresh_token');
        return (_a = refreshToken === null || refreshToken === void 0 ? void 0 : refreshToken.token) !== null && _a !== void 0 ? _a : null;
    };
    SessionCheck.prototype.handleLastActivityTimestamp = function (backendTimestamp) {
        var localTimestamp = getLastActivityTimestamp();
        if (isNullish(localTimestamp) || backendTimestamp > localTimestamp) {
            updateLastActivityTimestamp(backendTimestamp);
        }
    };
    return SessionCheck;
}());
export { SessionCheck };
