import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { UserTokensFilter } from './user-tokens-filter.type';

export interface UserToken {
  id: string;
  userId: string;
  type: string;
  issuedAt: string;
  expiresAt: string;
  deviceInfo: DeviceInfo;
  invalidated: boolean;
  invalidatedAt: string;
  invalidatedReason: string;
  jwt: string;
}

export interface InvalidateTokenParams {
  userId: string;
  id: string;
  reason: string;
}

interface DeviceInfo {
  ip: string;
  host: string;
  userAgent: string;
}

export const userTokenAdapter = createEntityAdapter<UserToken>();

export interface UserTokenState extends EntityState<UserToken>, CallState {
  count: number;
  filter: UserTokensFilter;
}

export const userTokenInitialState: UserTokenState = {
  ...userTokenAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  filter: null
};
