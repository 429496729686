import { Component, Input, inject } from '@angular/core';

import { SegmentCreationService } from '../../../segments/services/segment-creation.service';
import { Segment } from '../../../segments/types';

@Component({
  selector: 'admin-segment-create-review',
  templateUrl: './segment-create-review.component.html',
  styleUrl: './segment-create-review.component.scss'
})
export class SegmentCreateReviewComponent {
  private segmentCreationService = inject(SegmentCreationService);

  @Input({ required: true }) rules: Segment['rules'];

  @Input({ required: true }) data: Pick<Segment, 'name' | 'description' | 'updateFrequency'>;

  segmentPreviewMemberState = this.segmentCreationService.segmentPreviewMemberState;

  segmentPreviewMemberCount = this.segmentCreationService.segmentPreviewMemberCount;

  segmentAttributes = this.segmentCreationService.segmentAttributes;
}
