import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { MembershipsService } from '../../services/memberships.service';
import { BenefitState } from '../../types/benefits.type';
import {
  deleteMembership,
  deleteMembershipFailure,
  deleteMembershipSuccess,
  loadMemberships,
  loadMembershipsFailure,
  loadMembershipsSuccess,
  updateMembership,
  updateMembershipFailure,
  updateMembershipSuccess
} from '../actions/memberships.actions';
import { membershipsQuery } from '../selectors/memberships.selectors';

@Injectable()
export class MembershipsEffects {
  loadMemberships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMemberships),
      withLatestFrom(this.store.select(membershipsQuery.getLocale)),
      exhaustMap(([{ userId }, locale]) =>
        this.membershipsService.getMemberships(userId, locale).pipe(
          map(result => loadMembershipsSuccess({ result })),
          catchError(error => of(loadMembershipsFailure({ error })))
        )
      )
    )
  );

  updateMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMembership),
      exhaustMap(({ userId, membershipId, membership }) =>
        this.membershipsService.updateMembership(userId, membershipId, membership).pipe(
          map(result => updateMembershipSuccess({ result })),
          catchError(error => of(updateMembershipFailure({ error })))
        )
      )
    )
  );

  deleteMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMembership),
      exhaustMap(({ userId, membershipId }) =>
        this.membershipsService.deleteMembership(userId, membershipId).pipe(
          map(() => deleteMembershipSuccess({ membershipId })),
          catchError(error => of(deleteMembershipFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private membershipsService: MembershipsService,
    private store: Store<BenefitState>
  ) {}
}
