import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import {
  CreateUser,
  EnrollAdminRequest,
  UnenrollUserResult,
  UpdateUsernameRequestParams,
  UpdateUsernameResponseParams,
  User,
  UsersCountResult,
  UsersFilter,
  UsersResult
} from '../../types';

export const loadUsers = createAction('[Users] Load Users', props<{ filter: UsersFilter }>());
export const loadUsersSuccess = createAction('[Users] Load Users Success', props<{ result: UsersResult }>());
export const loadUsersFailure = createAction('[Users] Load Users Failure', props<ErrorState>());

export const loadUsersCount = createAction('[Users] Load Users Count', props<{ filter: UsersFilter }>());
export const loadUsersCountSuccess = createAction(
  '[Users] Load Users Count Success',
  props<{ result: UsersCountResult }>()
);
export const loadUsersCountFailure = createAction('[Users] Load Users Count Failure', props<ErrorState>());

export const loadUser = createAction('[Users] Load User', props<{ id: string; filter?: UsersFilter }>());
export const loadUserSuccess = createAction('[Users] Load User Success', props<{ user: User }>());
export const loadUserFailure = createAction('[Users] Load User Failure', props<ErrorState>());

export const setUsersFilter = createAction('[Users] Set Users Filter', props<{ filter: UsersFilter }>());

export const fetchUser = createAction('[Users] Fetch User', props<{ id: string }>());
export const fetchUserSuccess = createAction('[Users] Fetch User Success', props<{ user: User }>());
export const fetchUserFailure = createAction('[Users] Fetch User Failure', props<{ id: string }>());

export const fetchUsers = createAction('[Users] Fetch Users', props<{ ids: string[]; batch: true }>());
export const fetchUsersSuccess = createAction('[Users] Fetch Users Success', props<{ data: User[]; batch: true }>());
export const fetchUsersFailure = createAction('[Users] Fetch Users Failure', props<{ batch: true }>());

export const createUser = createAction('[Users] Create User', props<{ user: CreateUser }>());
export const createUserSuccess = createAction(
  '[Users] Create User Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully added a new user!  Please refresh this page to view the newly added user.'
    }
  ) => ({ snackbarMessages })
);
export const createUserFailure = createAction(
  '[Users] Create User Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default:
        'Your user could not be added due to technical issues on our end. ' +
        '  Please try adding your User again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateUser = createAction(
  '[Users] Update User',
  props<{
    user: Partial<User>;
    createdRoles: string[];
    deletedRoles: string[];
    previousPath: string;
    message?: string;
    errorMessage?: string;
  }>()
);

export const updateUserSuccess = createAction(
  '[Users] Update User Success',
  props<{ user: User; previousPath: string; message?: string }>()
);
export const updateUserFailure = createAction(
  '[Users] Update User Failure',
  props<{ errorMessage?: string; error: HttpError }>()
);

export const requestResetPassword = createAction('[Users] Request Reset Password', props<{ userId: string }>());
export const requestResetPasswordSuccess = createAction(
  '[Users] Request Reset Password Success',
  (
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully sent the reset password email!'
    }
  ) => ({ snackbarMessages })
);
export const requestResetPasswordFailure = createAction(
  '[Users] Request Reset Password Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to send the reset password email. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const blockUser = createAction('[Users] Block User', props<{ id: string; dialogRefId?: string }>());
export const blockUserSuccess = createAction(
  '[Users] Block User Success',
  (
    user: User,
    dialogRefId?: string,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully blocked this user!'
    }
  ) => ({ user, dialogRefId, snackbarMessages })
);
export const blockUserFailure = createAction(
  '[Users] Block User Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to block this user. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const unblockUser = createAction('[Users] Unblock User', props<{ id: string; dialogRefId?: string }>());
export const unblockUserSuccess = createAction(
  '[Users] Unblock User Success',
  (
    user: User,
    dialogRefId?: string,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully unblocked this user!'
    }
  ) => ({ user, dialogRefId, snackbarMessages })
);
export const unblockUserFailure = createAction(
  '[Users] Unblock User Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to unblock this user. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const blockUserLogin = createAction('[Users] Block User Login', props<{ id: string; dialogRefId?: string }>());
export const blockUserLoginSuccess = createAction(
  '[Users] Block User Login Success',
  (
    user: User,
    dialogRefId?: string,
    snackbarMessages: SnackbarMessages = {
      default: "You have successfully blocked this user's login access!"
    }
  ) => ({ user, dialogRefId, snackbarMessages })
);
export const blockUserLoginFailure = createAction(
  '[Users] Block User Login Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: "You have failed to block this user's login access. Please try again."
    }
  ) => ({ error, snackbarMessages })
);

export const unblockUserLogin = createAction(
  '[Users] Unblock User Login',
  props<{ id: string; dialogRefId?: string }>()
);
export const unblockUserLoginSuccess = createAction(
  '[Users] Unblock User Login Success',
  (
    user: User,
    dialogRefId?: string,
    snackbarMessages: SnackbarMessages = {
      default: "You have successfully reactivated this user's login access!"
    }
  ) => ({ user, dialogRefId, snackbarMessages })
);
export const unblockUserLoginFailure = createAction(
  '[Users] Unblock User Login Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: "You have failed to reactivate this user's login access. Please try again."
    }
  ) => ({ error, snackbarMessages })
);

export const enrollUser = createAction('[Users] Enroll User', props<{ enrollmentRequest: object }>());
export const enrollUserSuccess = createAction('[Users] Enroll User Success', props<{ id: string }>());
export const enrollUserFailure = createAction('[Users] Enroll User Failure', props<ErrorState>());

// enroll bank admin
export const enrollAdmin = createAction('[Users] Enroll Admin', props<{ enrollAdminRequest: EnrollAdminRequest }>());
export const enrollAdminSuccess = createAction('[Users] Enroll Admin Success', props<{ id: string }>());
export const enrollAdminFailure = createAction('[Users] Enroll Admin Failure', props<ErrorState>());

// enroll ascenda admin
export const enrollAscendaAdmin = createAction('[Users] Enroll Ascsenda Admin', props<{ email: string }>());
export const enrollAscendaAdminSuccess = createAction('[Users] Enroll Ascsenda Admin Success', props<{ id: string }>());
export const enrollAscendaAdminFailure = createAction('[Users] Enroll Ascsenda Admin Failure', props<ErrorState>());

// enroll bank admin for RC
export const enrollTeamMember = createAction(
  '[Users] Enroll Team Member',
  props<{ enrollTeamMemberRequest: EnrollAdminRequest }>()
);
export const enrollTeamMemberSuccess = createAction('[Users] Enroll Team Member Success', () => ({
  snackbarMessages: { default: 'An invitation email has been sent to the team member' }
}));
export const enrollTeamMemberFailure = createAction(
  '[Users] Enroll Team Member Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      GH2202: 'This team member has already been invited',
      default:
        'We were unable to invite this team member due to technical issues on our end. ' +
        'Please try saving your changes again. If the issue persists, contact our Help Center.'
    }
  ) => ({ error, snackbarMessages })
);

export const unenrollUser = createAction('[Users] Unenroll User', props<{ id: string; dialogRefId?: string }>());
export const unenrollUserSuccess = createAction(
  '[Users] Unenroll User Success',
  props<{ result: UnenrollUserResult; dialogRefId?: string }>()
);
export const unenrollUserFailure = createAction('[Users] Unenroll User Failure', props<ErrorState>());

export const removeTeamMember = createAction(
  '[Users] Remove Team Member',
  props<{ id: string; filter: UsersFilter }>()
);
export const removeTeamMemberSuccess = createAction(
  '[Users] Remove Team Member Success',
  (
    filter: UsersFilter,
    snackbarMessages: SnackbarMessages = {
      default: 'The team member has been successfully removed!'
    }
  ) => ({ filter, snackbarMessages })
);
export const removeTeamMemberFailure = createAction(
  '[Users] Remove Team Member Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'Oops, we were unable to remove the team member. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const updateUsername = createAction(
  '[Users] Update Username',
  props<{
    updateUsernameRequestParams: UpdateUsernameRequestParams;
    dialogRefId: string;
  }>()
);
export const updateUsernameSuccess = createAction(
  '[Users] Update Username Success',
  props<{
    updateUsernameResponseParams: UpdateUsernameResponseParams;
    dialogRefId: string;
  }>()
);
export const updateUsernameFailure = createAction('[Users] Update Username Failure', props<ErrorState>());

export const logoutUser = createAction('[Users] Logout User', props<{ id: string; dialogRefId?: string }>());
export const logoutUserSuccess = createAction(
  '[Users] Logout User Success',
  (
    dialogRefId?: string,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully logged this user out of the portal!'
    }
  ) => ({ dialogRefId, snackbarMessages })
);
export const logoutUserFailure = createAction(
  '[Users] Logout User Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to log this user out of the portal. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);
