<ng-container *ngIf="(tooltipValue$ | async) || defaultValue as tooltipValue; else noTooltipValue">
  <div class="tooltip" [ngClass]="styleClassName" [matTooltip]="tooltipValue" [matTooltipPosition]="position">
    <mat-icon color="primary" *ngIf="displayIcon">info_outline</mat-icon>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>

<ng-template #noTooltipValue>
  <div [ngClass]="styleClassName">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
