<mat-toolbar color="primary" id="header" [ngClass]="hostPrefix">
  <span fxFlex></span>
  <div *ngIf="tenants?.length > 0">
    <button mat-button [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
      <span class="header-text">Switch Tenant</span>
      <mat-icon class="dropdown-icon">{{ trigger.menuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a [href]="tenant.url" mat-menu-item *ngFor="let tenant of tenants">{{ tenant.name }}</a>
    </mat-menu>
  </div>
  <div class="psuedo-menu flex items-center" *ngIf="isLoggedIn">
    <button
      mat-button
      id="profile-button"
      aria-label="Account"
      [routerLink]="'/agents/' + user.sub + '/details'"
      data-testid="button-profile"
    >
      <img
        alt="Profile Picture"
        [hidden]="hideProfilePicture"
        [src]="user?.picture?.trim()"
        (error)="hideProfilePictureToggle(true)"
        (load)="hideProfilePictureToggle(false)"
        class="profile-picture-icon account-icon"
      />
      <span *ngIf="hideProfilePicture" class="user-icon-placeholder">
        <mat-icon svgIcon="header-user"></mat-icon>
      </span>
      <span class="header-text">{{ getUserName(user) }}</span>
    </button>
    <admin-side-dropdown-menu [panelClass]="'header-menu'" [testid]="'button-menu-header-trigger'">
      <admin-tooltips-edit-button
        [style.--icon-fill-color]="isRC ? null : 'white'"
        data-testid="button-dropdown-menu"
        class="w-full"
        [url]="router.url"
        (isEditButtonDisplayed)="isEditButtonDisplayed = $event"
      ></admin-tooltips-edit-button>
      @if (isRC) {
        <a
          href="https://docs.ascenda.com/docs"
          mat-menu-item
          class="header-text"
          target="_blank"
          data-testid="button-menu-help-center"
        >
          Help Center
        </a>
      }
      @if (piiMaskingEnabled) {
        <button
          mat-button
          mat-menu-itemaria-label="Unmask PII"
          class="w-full text-left"
          data-testid="button-stepup"
          id="stepup-button"
          [ngClass]="{ disabled: piiAccess.isUnmasked() }"
          [disabled]="piiAccess.isUnmasked()"
          *adminIfScopesInclude="['step_up_for_pii']"
          (click)="stepUpForPiiDialog()"
        >
          <mat-icon svgIcon="eye-open" class="account-icon"></mat-icon>
          <span class="header-text text-left text-cloudburst-light">Unmask PII</span>
        </button>
      }
      <button
        mat-button
        mat-menu-itemaria-label="Contact Support"
        [routerLink]="SUBMIT_TICKET_ROUTE"
        class="w-full text-left"
        [class.active]="isOnSubmitTicketRoute"
        *adminIfScopesInclude="SCOPES.submitTickets; relationOperator: 'or'"
        id="contact-support-button"
        data-testid="button-menu-contact-support"
      >
        <mat-icon svgIcon="info-question-mark" class="account-icon"></mat-icon>
        <span class="header-text text-left">Contact Support</span>
      </button>

      <button
        mat-button
        class="w-full"
        mat-menu-itemaria-label="Logout"
        (click)="logout()"
        id="logout-button"
        data-testid="button-logout"
      >
        <mat-icon svgIcon="arrow-logout" class="account-icon"></mat-icon>
        <span class="header-text">Logout</span>
      </button>
    </admin-side-dropdown-menu>
  </div>
</mat-toolbar>
