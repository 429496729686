import { inject, Pipe, PipeTransform } from '@angular/core';

import { LogicRuleBuilderHelperService } from '@shared/services/logic-rule-builder-helper/logic-rule-builder-helper.service';

@Pipe({
  name: 'logicRuleCategoryKey'
})
export class LogicRuleCategoryKeyPipe implements PipeTransform {
  private logicRuleBuilderHelperService = inject(LogicRuleBuilderHelperService);

  transform(attributeKey: string): string | null {
    let result: string | null = null;

    // TODO: need parent key here or maybe create a function to find parent keys
    for (const value of this.logicRuleBuilderHelperService.attributesMap().values()) {
      if (value.attribute === attributeKey) {
        result = value.parentAttribute;
        break;
      }
    }

    return result;
  }
}
