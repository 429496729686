import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { benefitAdapter, BenefitState, initialState } from '../../types/benefits.type';
import { loadBenefits, loadBenefitsFailure, loadBenefitsSuccess } from '../actions/benefits.actions';

export const benefitTriggers: EntityTriggers = {
  batch: {
    loading: [loadBenefits.type],
    resting: [loadBenefitsSuccess.type],
    erroring: [loadBenefitsFailure.type]
  }
};

export function benefitsReducer(state: BenefitState = initialState, action: Action): BenefitState {
  return callStateReducer(baseReducer, benefitTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadBenefitsSuccess, (state, { result }) => ({
    ...benefitAdapter.setAll(result.data, state),
    count: result.meta.count
  }))
);
