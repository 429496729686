import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { ProductsFilter } from '../../types/products-filter.type';
import { ProductsResult } from '../../types/products-result.type';

export const loadProducts = createAction('[Loyalty Data] Load Products', props<{ filter: ProductsFilter }>());
export const loadProductsSuccess = createAction(
  '[Loyalty Data] Load Products Success',
  props<{ result: ProductsResult }>()
);
export const loadProductsFailure = createAction('[Loyalty Data] Load Products Failure', props<ErrorState>());
