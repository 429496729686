import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { earnRuleAdapter, EarnRuleState, initialState } from '../../types';
import {
  createEarnRule,
  createEarnRuleFailure,
  createEarnRuleSuccess,
  deactivateEarnRule,
  deactivateEarnRuleFailure,
  deactivateEarnRuleSuccess,
  loadEarnRule,
  loadEarnRuleFailure,
  loadEarnRules,
  loadEarnRulesFailure,
  loadEarnRulesSuccess,
  loadEarnRuleSuccess,
  setEarnRulesFilter,
  updateEarnRule,
  updateEarnRuleFailure,
  updateEarnRulesPriorities,
  updateEarnRulesPrioritiesFailure,
  updateEarnRulesPrioritiesSuccess,
  updateEarnRuleSuccess
} from '../actions/earn-rules.actions';

export const earnRuleTriggers: EntityTriggers = {
  single: {
    loading: [createEarnRule.type, deactivateEarnRule.type, loadEarnRule.type, updateEarnRule.type],
    resting: [
      createEarnRuleSuccess.type,
      deactivateEarnRuleSuccess.type,
      loadEarnRuleSuccess.type,
      updateEarnRuleSuccess.type
    ],
    erroring: [
      createEarnRuleFailure.type,
      deactivateEarnRuleFailure.type,
      loadEarnRuleFailure.type,
      updateEarnRuleFailure.type
    ]
  },
  batch: {
    loading: [loadEarnRules.type, updateEarnRulesPriorities.type],
    resting: [loadEarnRulesSuccess.type, updateEarnRulesPrioritiesSuccess.type],
    erroring: [loadEarnRulesFailure.type, updateEarnRulesPrioritiesFailure.type]
  }
};

export function earnRulesReducer(state: EarnRuleState = initialState, action: Action): EarnRuleState {
  return callStateReducer(baseReducer, earnRuleTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadEarnRulesSuccess, updateEarnRulesPrioritiesSuccess, (state, { result }) => ({
    ...earnRuleAdapter.setAll(result.earnRules, state),
    version: result.version
  })),
  on(loadEarnRuleSuccess, (state, { result }) => ({
    ...earnRuleAdapter.upsertOne(result.earnRule, state),
    version: result.version
  })),
  on(deactivateEarnRuleSuccess, updateEarnRuleSuccess, (state, { result }) => ({
    ...earnRuleAdapter.setOne(result.earnRule, state),
    version: result.version
  })),
  on(setEarnRulesFilter, (state, { filter }) => ({ ...state, filter }))
);
