import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { LogicRuleBuilderHelperService } from '@shared/services/logic-rule-builder-helper/logic-rule-builder-helper.service';
import { LogicRuleAttributeRecord, LogicRulesData } from '@shared/types/logic-rule.type';

@Component({
  selector: 'admin-logic-rules-summary',
  templateUrl: './logic-rules-summary.component.html',
  styleUrl: './logic-rules-summary.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LogicRuleBuilderHelperService]
})
export class LogicRulesSummaryComponent implements OnChanges {
  @Input({ required: true }) attributeDataSource: LogicRuleAttributeRecord;

  @Input({ required: true }) data: LogicRulesData | Record<string, never>;

  private logicRuleBuilderHelperService = inject(LogicRuleBuilderHelperService);

  constructor(@Inject('timezone') protected timezone: string) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attributeDataSource) {
      this.logicRuleBuilderHelperService.registerAttributesDataSource(this.attributeDataSource);
    }
  }
}
