import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';

import { Identity, identityAdapter, IdentityState } from '../../types';

const identitiesDetector = createDetector<Identity>();
const getIdentityState = createFeatureSelector<IdentityState>('identity');

const { selectAll: getIdentitiesList, selectEntities: getIdentitiesDictionary } =
  identityAdapter.getSelectors(getIdentityState);

const { isSingleLoading, isBatchInitial, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<IdentityState>(getIdentityState);

const isIdentitiesLoaded = (userId: string): Selector<IdentityState, boolean> =>
  createSelector(
    getIdentitiesDictionary,
    getIdentitiesList,
    isBatchResting,
    (identities, identityList, batchResting) =>
      identitiesDetector('allLoaded')(identities) &&
      batchResting &&
      identityList.every(identity => identity.userId === userId)
  );

export const identitiesQuery = {
  isSingleLoading,
  isBatchInitial,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getIdentitiesList,
  getIdentitiesDictionary,
  isIdentitiesLoaded
};
