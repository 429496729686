import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { RemoteFile } from '../../types';

export const loadRemoteFiles = createAction('[Pickup Zones] Load Remote Files', props<{ id: string }>());
export const loadRemoteFilesSuccess = createAction(
  '[Pickup Zones] Load Remote Files Success',
  props<{ remoteFiles: RemoteFile[] }>()
);
export const loadRemoteFilesFailure = createAction('[Pickup Zones] Load Remote Files Failure', props<ErrorState>());
