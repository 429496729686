import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { BulkUpdateTenantLocalesPayload, TenantLocale } from '../types';

@Injectable({
  providedIn: 'root'
})
export class TenantLocalesService {
  constructor(private missionControlService: MissionControlService) {}

  getTenantLocales(): Observable<TenantLocale[]> {
    return this.missionControlService.get<TenantLocale[]>('tenant_locales');
  }

  updateTenantLocales(payload: BulkUpdateTenantLocalesPayload): Observable<TenantLocale> {
    return this.missionControlService.put<TenantLocale>('tenant_locales/bulk_update', payload);
  }
}
