import { NgModule } from '@angular/core';

import { ErrorActionTypes } from '@core/store/errors';
import { SuccessActionTypes } from '@core/store/success';
import { SharedModule } from '@shared/shared.module';

import { TooltipsEditButtonComponent } from './components/tooltips-edit-button/tooltips-edit-button.component';
import { TooltipsEditDialogComponent } from './components/tooltips-edit-dialog/tooltips-edit-dialog.component';
import { updateTooltipsSuccess } from './store/actions/tooltips.actions';
import { tooltipTriggers } from './store/reducers/tooltips.reducer';

@NgModule({
  imports: [SharedModule],
  exports: [TooltipsEditButtonComponent],
  declarations: [TooltipsEditButtonComponent, TooltipsEditDialogComponent]
})
export class TooltipsModule {
  constructor(
    private errorActionTypes: ErrorActionTypes,
    private successActionTypes: SuccessActionTypes
  ) {
    this.errorActionTypes.registerActions([...tooltipTriggers.batch.erroring]);
    this.successActionTypes.registerActions([updateTooltipsSuccess.type]);
  }
}
