<button
  *adminIfScopesInclude="SCOPES.viewUsers; relationOperator: 'or'"
  class="back-button"
  mat-button
  color="primary"
  routerLink=".."
>
  <mat-icon>keyboard_backspace</mat-icon>
  Back to {{ previousPageLabel }}
</button>

<admin-spinner *ngIf="(loading$ | async) || isSecureFieldsLoading"></admin-spinner>

<mat-card appearance="outlined" class="mat-elevation-z1" [class.overlay]="(loading$ | async) || isSecureFieldsLoading">
  <mat-button-toggle-group class="button-group" [value]="formTypes[0]">
    <mat-button-toggle
      class="button-toggle"
      *ngFor="let formType of formTypes"
      [value]="formType"
      (click)="displayForm(formType)"
    >
      <span class="button-text">{{ formType | customTitleCase: 'fromCamelCase' }}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <!-- bank admin form -->
  <mat-card-content *ngIf="displayedForm === 'admin'">
    <div class="headline">
      <mat-card-title>Activate New Admin</mat-card-title>
    </div>
    <form
      (ngSubmit)="onSubmit('admin')"
      [formGroup]="enrollAdminForm"
      class="slim-form-field admin-form"
      adminFormWithTextArea
      fxLayout="column"
    >
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" required />
        <mat-error>Invalid email</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" formControlName="firstName" required />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Last Name" formControlName="lastName" required />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <div class="form-actions">
        <button
          *adminIfScopesInclude="SCOPES.viewUsers; relationOperator: 'or'"
          mat-raised-button
          class="uppercase-button"
          routerLink=".."
        >
          <span class="uppercase-button__text"> Cancel </span>
        </button>
        <button
          mat-raised-button
          class="uppercase-button"
          color="primary"
          type="submit"
          [disabled]="enrollAdminForm.invalid"
        >
          <span class="uppercase-button__text"> Activate </span>
        </button>
      </div>
    </form>
  </mat-card-content>

  <!-- ascenda admin form -->
  <mat-card-content *ngIf="displayedForm === 'ascendaAdmin'">
    <div class="headline">
      <mat-card-title>Activate New Ascenda Admin</mat-card-title>
    </div>
    <form
      (ngSubmit)="onSubmit('ascendaAdmin')"
      class="slim-form-field admin-form"
      adminFormWithTextArea
      fxLayout="column"
    >
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" [formControl]="enrollAscendaAdminFormControl" required />
        <mat-error>Invalid email</mat-error>
      </mat-form-field>

      <div class="form-actions">
        <button
          *adminIfScopesInclude="SCOPES.viewUsers; relationOperator: 'or'"
          mat-raised-button
          class="uppercase-button"
          routerLink=".."
        >
          <span class="uppercase-button__text"> Cancel </span>
        </button>
        <button
          mat-raised-button
          class="uppercase-button"
          color="primary"
          type="submit"
          [disabled]="enrollAscendaAdminFormControl.invalid"
        >
          <span class="uppercase-button__text"> Activate </span>
        </button>
      </div>
    </form>
  </mat-card-content>

  <!-- user form -->
  <mat-card-content *ngIf="schema && displayedForm === 'user'">
    <div class="headline">
      <mat-card-title>Activate New User</mat-card-title>
    </div>
    <form class="slim-form-field" (ngSubmit)="onSubmit('user')" [formGroup]="enrollUserForm" adminFormWithTextArea>
      <admin-form-fields
        #formFieldsComponent
        [formGroup]="enrollUserForm"
        [schema]="schema"
        [secureFieldIframeConfig]="secureFieldIframeConfig"
        (tokenizeSuccess)="onTokenizeSuccess($event)"
        (validationError)="onValidationError($event)"
      >
      </admin-form-fields>
      <div class="form-actions">
        <button
          *adminIfScopesInclude="SCOPES.viewUsers; relationOperator: 'or'"
          mat-raised-button
          class="uppercase-button"
          routerLink=".."
        >
          <span class="uppercase-button__text"> Cancel </span>
        </button>
        <button
          mat-raised-button
          class="uppercase-button"
          color="primary"
          type="submit"
          [id]="submitBtnId"
          [disabled]="enrollUserForm.invalid || hasValidationError"
        >
          <span class="uppercase-button__text"> Activate </span>
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
