<mat-card class="button-wrapper">
  <mat-card-content>
    <button
      *adminIfScopesInclude="SCOPES.viewOrderItems; relationOperator: 'or'"
      mat-button
      class="back-button"
      color="primary"
      routerLink=".."
    >
      <mat-icon>keyboard_backspace</mat-icon>
      Orders
    </button>
  </mat-card-content>
</mat-card>

<ng-container [ngSwitch]="renderState$ | async">
  <mat-card *ngSwitchCase="'loading'" class="mat-elevation-z0 details-card overlay box-shadow">
    <admin-spinner></admin-spinner>
  </mat-card>

  <mat-card *ngSwitchCase="'error'" class="mat-elevation-z0 details-card box-shadow" fxLayoutAlign="center center">
    <div>
      <p>
        <mat-icon [inline]="true">warning</mat-icon>
        Loading order item failed
      </p>
    </div>
  </mat-card>

  <ng-container *ngSwitchCase="'content'">
    <div *ngIf="orderItem$ | async as orderItem">
      <ng-template #OrderItemIdHeader>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
          <h2>
            Order ID:
            <a
              *adminIfScopesInclude="SCOPES.viewGiftCardOrders; relationOperator: 'or'; else: orderIdWithoutLink"
              routerLink="/gift-cards/orders/{{ orderItem.supplierReferenceId }}"
            >
              {{ orderItem.order.id }}
            </a>
            <ng-template #orderIdWithoutLink> Order ID: {{ orderItem.order.id }} </ng-template>
          </h2>
          <button (click)="openOrderItemDetails(orderItem)" mat-icon-button class="actions-button stripe-ref-button">
            <mat-icon matTooltip="Stripe Reference Details">remove_red_eye</mat-icon>
          </button>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" class="subheader">
          <h3>Item ID: {{ orderItem.id }}</h3>
          <admin-tooltip key="orders-items.details.header.orderItemId"></admin-tooltip>
        </div>
      </ng-template>

      <div class="header" *ngIf="!isUserView">
        <ng-template [ngTemplateOutlet]="OrderItemIdHeader"></ng-template>
      </div>

      <mat-card class="mat-elevation-z0 details-card box-shadow">
        <div class="header__user-view" *ngIf="isUserView">
          <ng-template [ngTemplateOutlet]="OrderItemIdHeader"></ng-template>
        </div>
        <mat-card-content>
          @switch (orderItem.type) {
            @case (orderItemType.GiftCardOrderItem) {
              <admin-gift-card-order-item
                [detailsView]="detailsView"
                [orderItem]="orderItem"
                [customerId]="customerId$ | async"
                [timezone]="timezone"
              >
              </admin-gift-card-order-item>
            }
            @case (orderItemType.PhysicalGiftCardOrderItem) {
              <admin-gift-card-order-item
                [detailsView]="detailsView"
                [orderItem]="orderItem"
                [customerId]="customerId$ | async"
                [timezone]="timezone"
              >
              </admin-gift-card-order-item>
            }
            @case (orderItemType.PointsTransferOrderItem) {
              <admin-points-transfer-order-item
                [orderItem]="orderItem"
                [customerId]="customerId$ | async"
                [timezone]="timezone"
              >
              </admin-points-transfer-order-item>
            }
            @case (orderItemType.CashRedemptionOrderItem) {
              <admin-cash-redemption-order-item
                [orderItem]="orderItem"
                [customerId]="customerId$ | async"
                [timezone]="timezone"
              >
              </admin-cash-redemption-order-item>
            }
            @case (orderItemType.TravelBookingOrderItem) {
              <admin-travel-booking-order-item
                [orderItem]="orderItem"
                [customerId]="customerId$ | async"
                [timezone]="timezone"
              >
              </admin-travel-booking-order-item>
            }
            @case (orderItemType.MerchandiseOrderItem) {
              <admin-merchandise-order-item
                [detailsView]="detailsView"
                [orderItem]="orderItem"
                [customerId]="customerId$ | async"
                [timezone]="timezone"
              >
              </admin-merchandise-order-item>
            }
            @default {
              <p fxLayoutAlign="center center">
                <mat-icon [inline]="true">warning</mat-icon>
                Order item type is currently not supported.
              </p>
            }
          }
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</ng-container>
