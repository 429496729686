<div class="flex justify-center">
  <button mat-button (click)="resetTimeouts()">
    <span class="flex items-center gap-2"><mat-icon>event_repeat</mat-icon>Reset Timeouts</span>
  </button>
</div>
<div>
  <p>When there is one action</p>
  <admin-kafka-widget [widgetData]="oneActionDataRef.data"></admin-kafka-widget>
</div>
<div>
  <p>When there are multiple actions</p>
  <admin-kafka-widget [widgetData]="multipleActionsDataRef.data"></admin-kafka-widget>
</div>

<div>
  <p>When there is one successful action</p>
  <admin-kafka-widget [widgetData]="oneSuccessfulActionDataRef.data"></admin-kafka-widget>
</div>
<div>
  <p>When there are multiple successful actions</p>
  <admin-kafka-widget [widgetData]="multipleSuccessfulActionsDataRef.data"></admin-kafka-widget>
</div>
