<mat-form-field
  class="color-input"
  [class.ng-invalid]="ngControl.control.invalid"
  [class.mat-form-field-invalid]="ngControl.control.invalid"
  (click)="toggleColorPicker($event)"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [attr.aria-required]="required"
    [disabled]="disabled || isColorPickerOpen"
    [required]="required"
    [value]="value"
    (mousedown)="$event.preventDefault()"
    (keydown)="$event.preventDefault()"
  />
  <div class="details-view-icon-button">
    <div class="preview" [style.backgroundColor]="value"></div>
    <mat-icon *ngIf="!value" svgIcon="color-input-icon"></mat-icon>
  </div>
</mat-form-field>
<mat-error class="error-message" role="alert" *ngIf="ngControl.control.hasError('required')">{{
  missingValueErrorMessage
}}</mat-error>
<color-chrome
  *ngIf="isColorPickerOpen"
  class="color-picker"
  [color]="value || '#ffffff'"
  [disableAlpha]="true"
  (click)="$event.stopImmediatePropagation()"
  (onChangeComplete)="handleColorPickerChange($event)"
></color-chrome>
