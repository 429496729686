import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { AppConnectionRequest } from '../types/app-connection-action.type';
import { AppConnection } from '../types/app-connections.type';

@Injectable({
  providedIn: 'root'
})
export class AppConnectionsService {
  constructor(private missionControlService: MissionControlService) {}

  getAppConnections(): Observable<AppConnection[]> {
    return this.missionControlService.get<AppConnection[]>('connections');
  }

  getAppConnection(id: string): Observable<AppConnection> {
    return this.missionControlService.get<AppConnection>(`connections/${id}`);
  }

  createAppConnection(appConnection: AppConnectionRequest): Observable<AppConnection> {
    appConnection = ObjectUtils.sanitizeRequestObject<AppConnectionRequest>(appConnection, { ignoredKeys: ['scopes'] });

    return this.missionControlService.post<AppConnection>('connections', appConnection);
  }

  updateAppConnection(appConnection: AppConnectionRequest): Observable<AppConnection> {
    return this.missionControlService.patch<AppConnection>(`connections/${appConnection.id}`, appConnection);
  }

  updateSigningKeys(appConnectionId: string): Observable<AppConnection> {
    return this.missionControlService.patch<AppConnection>(`connections/${appConnectionId}/signing_keys`);
  }
}
