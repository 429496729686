import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { INVALID_EMAIL_MESSAGE } from '@utils';

import { confirmationValidator } from '../../../../validators';
import { createIdentity } from '../../store/actions/identities.actions';
import { CreateIdentityDialogForm, CreateIdentityParams, IdentityState, User } from '../../types';

interface DialogData {
  user: User;
}

@Component({
  selector: 'admin-create-identity-dialog-v2',
  templateUrl: './create-identity-dialog-v2.component.html',
  styleUrls: ['./create-identity-dialog-v2.component.scss'],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } }
  ]
})
export class CreateIdentityDialogV2Component implements OnInit {
  destroyRef = inject(DestroyRef);

  createForm: FormGroup<CreateIdentityDialogForm>;
  subscribeToPasswordChanges: Subscription;

  displayPasswordFields = false;
  passwordProviderIdOptions = ['email', 'password'];
  providerIdOptions = [...this.passwordProviderIdOptions, 'qa_dummy'];
  invalidEmailMessage = INVALID_EMAIL_MESSAGE;

  constructor(
    private fb: FormBuilder,
    private store: Store<IdentityState>,
    public dialogRef: MatDialogRef<CreateIdentityDialogV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  get uidControl(): AbstractControl {
    return this.createForm.get('uid');
  }

  get passwordConfirmationControl(): AbstractControl {
    return this.createForm.get('passwordConfirmation');
  }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      providerId: this.fb.control('', Validators.required),
      uid: this.fb.control('')
    });

    this.subscribeToProviderIdChange();
  }

  subscribeToProviderIdChange(): void {
    this.createForm
      .get('providerId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.handlePasswordControls(value);
        this.handleUidControl(value);
      });
  }

  submitForm(): void {
    const params: CreateIdentityParams = {
      ...this.createForm.getRawValue(),
      userId: this.data.user.id
    };

    this.store.dispatch(
      createIdentity({
        params,
        dialogRefId: this.dialogRef.id
      })
    );
  }

  private handlePasswordControls(value: string): void {
    if (this.passwordProviderIdOptions.includes(value)) {
      this.createForm.addControl('password', this.fb.control(null, [Validators.required]));
      this.createForm.addControl(
        'passwordConfirmation',
        this.fb.control(null, [Validators.required, confirmationValidator('password')])
      );
      this.displayPasswordFields = true;

      this.subscribeToPasswordChanges = this.createForm.controls.password.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.createForm.controls.passwordConfirmation.updateValueAndValidity());
    } else {
      this.subscribeToPasswordChanges?.unsubscribe();

      this.displayPasswordFields = false;
      this.createForm.removeControl('passwordConfirmation');
      this.createForm.removeControl('password');
    }
  }

  private handleUidControl(value: string): void {
    this.uidControl.reset();

    if (value === 'email') {
      this.uidControl.setValidators([Validators.required, Validators.email]);
    } else {
      this.uidControl.clearValidators();

      if (!this.data.user.uid) {
        this.uidControl.setValidators([Validators.required]);
      }
    }
    this.uidControl.updateValueAndValidity();
  }
}
