<ng-container *ngIf="dataType === 'primitive'">
  <div class="wrapper" [ngClass]="{ 'item-with-copy-button': copyButtonValue }">
    <div class="label" [class.should-not-float]="!shouldLabelFloat" data-testid="admin-item-label">
      {{ label | customSentenceCase: 'fromCamelCase' }}
    </div>
    <div class="content" #content data-testid="admin-item-content">
      <ng-content></ng-content>
    </div>
    <admin-copy-to-clipboard
      *ngIf="copyButtonValue"
      [content]="copyButtonValue"
      [contentName]="label | customSentenceCase: 'fromCamelCase'"
      class="details-view-icon-button"
    ></admin-copy-to-clipboard>
  </div>

  <mat-hint *ngIf="hint" class="message" data-testid="admin-item-hint">{{ hint }}</mat-hint>
</ng-container>

<ng-container *ngIf="dataType === 'object'">
  <div class="subsection-header">{{ complexValueTitle ?? label | customSentenceCase: 'fromCamelCase' }}</div>
  <div class="admin-item-wrapper">
    <ng-container *ngFor="let obj of primitiveObjectEntries | removeNullish | keyvalue">
      <admin-item
        [label]="obj.key"
        [copyButtonValue]="decodeURIComponent(obj.value).match(URL_REGEX) ? obj.value : null"
        [class.cross-two-column]="obj.value.toString()?.length >= 150"
      >
        {{ obj.value | validNumber }}
      </admin-item>
    </ng-container>

    <ng-container *ngFor="let obj of complexObjectEntries | removeNullish | keyvalue">
      <admin-item [label]="obj.key" [data]="obj.value" class="cross-two-column">
        {{ obj.value }}
      </admin-item>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="dataType === 'array'">
  <ng-container *ngFor="let arrayItem of primitiveArrayItems">
    <admin-item
      [label]="label"
      [copyButtonValue]="decodeURIComponent(arrayItem).match(URL_REGEX) ? arrayItem : null"
      [class.cross-two-column]="arrayItem.toString()?.length >= 150"
    >
      {{ arrayItem | validNumber }}
    </admin-item>
  </ng-container>

  <div *ngIf="complexArrayItems.length" class="unpadded-bottom-subsection-header">
    {{ complexValueTitle ?? label | customSentenceCase: 'fromCamelCase' }}
  </div>
  <ng-container *ngFor="let arrayItem of complexArrayItems; let i = index">
    <admin-item [data]="arrayItem" [complexValueTitle]="[label, i + 1].join(' ')"></admin-item>
  </ng-container>
</ng-container>
