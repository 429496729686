import { createAction, props } from '@ngrx/store';

import { ErrorState, NydusNetworkBootstrap } from '../../../types';

export const loadNydusNetworkBootstrap = createAction('[Nydus Network Bootstrap] Load Nydus Network Bootstrap');
export const loadNydusNetworkBootstrapSuccess = createAction(
  '[Nydus Network Bootstrap] Load Nydus Network Bootstrap Success',
  props<{ nydusNetworkBootstrap: NydusNetworkBootstrap }>()
);
export const loadNydusNetworkBootstrapFailure = createAction(
  '[Nydus Network Bootstrap] Load Nydus Network Bootstrap Failure',
  props<ErrorState>()
);
