import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { BracketParamsEncoder } from '@utils';

import { Bootstrap } from '../types/bootstraps.type';

@Injectable({
  providedIn: 'root'
})
export class BootstrapsService {
  constructor(private missionControlService: MissionControlService) {}

  getBootstrap(tenantId?: string): Observable<Bootstrap> {
    return this.missionControlService.get<Bootstrap>(
      'alfred_bootstrap',
      new HttpParams({ fromObject: { ...(tenantId && { tenantId }) }, encoder: new BracketParamsEncoder() })
    );
  }
}
