import { debounceTime, filter, skip } from 'rxjs/operators';

import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { Dayjs } from 'dayjs';

import { Scopes } from '@core/services/scopes/scopes.service';
import { HttpError, SCOPES_OR } from '@core/types';
import { DomUtils, StickyColumnConfig, StickyTableUtils } from '@utils';

import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS, MAT_DAYJS_DATE_FORMATS } from '../../../../adaptors';
import {
  InvertedPointsActivityCategory,
  PointsActivitiesFilter,
  PointsActivity,
  PointsActivityCategory
} from '../../types';
import { PointsActivityForm } from '../../types/dashboard-forms.type';

@Component({
  selector: 'admin-points-activity-v2',
  templateUrl: './points-activities-v2.component.html',
  styleUrls: ['./points-activities-v2.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class PointsActivitiesV2Component implements OnInit, AfterViewInit {
  @Input() pointsActivities: PointsActivity[];
  @Input() count: number;
  @Input() loading: boolean;
  @Input() error: HttpError;
  @Input() filter: PointsActivitiesFilter;

  @Output() filterChanged = new EventEmitter<PointsActivitiesFilter>();

  destroyRef = inject(DestroyRef);

  filterForm: FormGroup<PointsActivityForm>;
  categoryList = PointsActivityCategory as { [Key: string]: string };
  invertedCategoryList = InvertedPointsActivityCategory as { [Key: string]: string };

  displayedColumns: string[] = [];
  tableUtil: StickyTableUtils;
  columnConfig: Record<string, StickyColumnConfig> = {};

  constructor(
    private fb: FormBuilder,
    private scopes: Scopes,
    @Inject('timezone') public timezone: string,
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    @Inject('pointsActivityDecimals') public pointsActivityDecimals: number
  ) {}

  get dateFrom(): Dayjs {
    return this.filterForm.controls.transactionTime.value.dateFrom;
  }

  get dateTo(): Dayjs {
    return this.filterForm.controls.transactionTime.value.dateTo;
  }

  ngOnInit(): void {
    this.createFilterForm();
    this.subscribeToSearchInputChanges();
    this.onDateChange();
    this.handleColumnDisplay();
  }

  ngAfterViewInit(): void {
    DomUtils.preventScrollToOtherPage('.sticky-table-wrapper', takeUntilDestroyed(this.destroyRef));
    DomUtils.dragAndScroll('.sticky-table-wrapper', takeUntilDestroyed(this.destroyRef));
  }

  createFilterForm(): void {
    this.filterForm = this.fb.group({
      description: this.fb.control(this.filter.description),
      category: this.fb.control(this.filter.category),
      transactionTime: this.fb.group({
        dateFrom: this.fb.control(this.filter.transactionTime?.dateFrom),
        dateTo: this.fb.control(this.filter.transactionTime?.dateTo)
      })
    });
  }

  subscribeToSearchInputChanges(): void {
    this.filterForm.controls.description.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skip(1), // skip the first emission to avoid sending event twice
        debounceTime(1000) // 1 second interval
      )
      .subscribe(searchInput => {
        // workaround to stop reloading points activities in case of error on IE11
        if (this.error) {
          return;
        }

        this.filter = {
          ...this.filter,
          page: 1,
          description: searchInput
        };
        this.filterChanged.emit(this.filter);
      });
  }

  onPage(pageEvent: PageEvent): void {
    this.filterChanged.emit({
      ...this.filter,
      limit: pageEvent.pageSize,
      page: pageEvent.pageIndex + 1
    });
  }

  onCategoryChange(): void {
    const category = this.filterForm.controls.category.value;
    this.filter = {
      ...this.filter,
      page: 1,
      category
    };
    this.filterChanged.emit(this.filter);
  }

  onDateChange(): void {
    this.filterForm.controls.transactionTime.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(1000),
        filter(() => this.filterForm.controls.transactionTime.valid)
      )
      .subscribe(({ dateFrom, dateTo }) => {
        this.filter = {
          ...this.filter,
          page: 1,
          transactionTime: { dateFrom, dateTo }
        };

        this.filterChanged.emit(this.filter);
      });
  }

  handleColumnDisplay(): void {
    const prefixColumns = ['description'];
    const scrollableColumns = ['category', 'amount', 'balanceAsOf', 'transactionTime'];
    const suffixColumns = this.scopes.hasAny(SCOPES_OR.showPointsActivities) ? ['actions'] : [];

    this.tableUtil = new StickyTableUtils(prefixColumns, scrollableColumns, suffixColumns);
    this.displayedColumns = this.tableUtil.getDisplayedColumns();
    this.columnConfig = this.tableUtil.getStickyColumnConfig();
  }

  getSign(entryType: string): string {
    return this.isAddition(entryType) ? '+' : '-';
  }

  isAddition(entryType: string): boolean {
    return entryType === 'credit';
  }
}
