import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers, sharedConfigInitialState, SharedConfigState } from '@core/types';

import { loadSharedConfig, loadSharedConfigError, loadSharedConfigSuccess } from '../actions/shared-config.actions';

export const sharedConfigTriggers: EntityTriggers = {
  single: {
    loading: [loadSharedConfig.type],
    resting: [loadSharedConfigSuccess.type],
    erroring: [loadSharedConfigError.type]
  }
};

export function sharedConfigReducer(
  state: SharedConfigState = sharedConfigInitialState,
  action: Action
): SharedConfigState {
  return callStateReducer(baseReducer, sharedConfigTriggers)(state, action);
}

const baseReducer = createReducer(
  sharedConfigInitialState,
  on(loadSharedConfigSuccess, (state, { sharedConfig }) => ({ ...state, sharedConfig }))
);
