<div class="m-[-24px] grid w-[26rem] gap-6">
  <div id="animation" class="h-[214px] w-full">
    <ng-lottie [options]="options" />
  </div>
  <div class="mx-8 mb-8 grid gap-6">
    <div mat-dialog-title id="title" class="text-cloud text-center text-xl font-bold">{{ data.dialogTitle }}</div>
    <div mat-dialog-content id="content" class="text-cloud mb-8 text-sm leading-[22px]">{{ data.upsellText }}</div>
    <div mat-dialog-actions align="end" class="flex justify-center gap-4">
      <button
        mat-raised-button
        color="secondary"
        class="uppercase-button text-primary cancel-button"
        (click)="goBack()"
      >
        <span class="uppercase-button__text">Back</span>
      </button>
      @if (isUpgradeButtonVisible) {
        <button
          mat-raised-button
          color="primary"
          class="uppercase-button confirm-button"
          (click)="notifyIntentToUpgrade()"
        >
          <span class="uppercase-button__text">Upgrade</span>
        </button>
      }
    </div>
  </div>
</div>
