import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { GiftCardsFilter } from '../../types/gift-cards-filter.type';
import { GiftCardsResult } from '../../types/gift-cards-result.type';
import { GiftCard, UpdateGiftCardParams } from '../../types/gift-cards.type';

export const loadGiftCards = createAction('[Gift Cards] Load Gift Cards', props<{ filter: GiftCardsFilter }>());
export const loadGiftCardsSuccess = createAction(
  '[Gift Cards] Load Gift Cards Success',
  props<{ result: GiftCardsResult }>()
);
export const loadGiftCardsFailure = createAction('[Gift Cards] Load Gift Cards Failure', props<ErrorState>());

export const loadGiftCard = createAction('[Gift Cards] Load Gift Card', props<{ id: string }>());
export const loadGiftCardSuccess = createAction('[Gift Cards] Load Gift Card Success', props<{ giftCard: GiftCard }>());
export const loadGiftCardFailure = createAction('[Gift Cards] Load Gift Card Failure', props<ErrorState>());

export const updateGiftCard = createAction('[Gift Cards] Update Gift Card', props<{ params: UpdateGiftCardParams }>());
export const updateGiftCardSuccess = createAction(
  '[Gift Cards] Update Gift Card Success',
  props<{ giftCard: GiftCard }>()
);
export const updateGiftCardFailure = createAction('[Gift Cards] Update Gift Card Failure', props<ErrorState>());

export const setGiftCardsFilter = createAction(
  '[Gift Cards] Set Gift Cards Filter',
  props<{ filter: GiftCardsFilter }>()
);
