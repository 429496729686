import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { RewardPreference } from '../types/reward-preferences.type';

@Injectable({
  providedIn: 'root'
})
export class RewardPreferencesService {
  constructor(private missionControlService: MissionControlService) {}

  getRewardPreference(id: string): Observable<RewardPreference> {
    return this.missionControlService.get<RewardPreference>(`users/${id}/reward_preference`);
  }
}
