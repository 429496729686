import { SortDirection } from '@angular/material/sort';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export const SegmentMemberStatuses = ['active', 'removed'] as const;
export type SegmentMemberStatus = (typeof SegmentMemberStatuses)[number];

export type SegmentMemberAddedSource = 'rules' | 'api';

export interface SegmentMembersResponse {
  data: SegmentMember[];
  metadata: {
    total: number;
  };
}

export interface SegmentMembersFilter {
  partnerUserId: string | null;
  status: SegmentMemberStatus | null;
  page: number;
  limit: number;
  sortBy: string;
  sortDirection: Exclude<SortDirection, ''>;
}

export interface SegmentMember {
  id: string;
  status: SegmentMemberStatus;
  userId: string | null;
  partnerUserId: string;
  addedSource: SegmentMemberAddedSource;
  addedAt: string;
  removedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export const defaultSegmentMembersFilter: SegmentMembersFilter = {
  partnerUserId: null,
  status: null,
  page: 1,
  limit: 20,
  sortBy: 'addedAt',
  sortDirection: 'desc'
};

export const segmentMemberAdapter = createEntityAdapter<SegmentMember>();

export interface SegmentMemberState extends EntityState<SegmentMember>, CallState {
  total: number;
  filter: SegmentMembersFilter;
  selectedSegmentId: string;
}

export const segmentMembersInitialState: SegmentMemberState = {
  ...segmentMemberAdapter.getInitialState<CallState>(initialCallState),
  total: 0,
  filter: defaultSegmentMembersFilter,
  selectedSegmentId: ''
};
