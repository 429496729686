import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { noopAction } from '@core/store/noop';

@Injectable()
export class NoopEffects {
  noop$ = createEffect(() => this.actions$.pipe(ofType(noopAction)), { dispatch: false });

  constructor(private actions$: Actions) {}
}
