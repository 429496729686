import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { AppConnection } from '../../apps/types/app-connections.type';
import { App } from '../../apps/types/apps.type';

import { KeysFilter } from './keys-filter.type';

export type NewKey = {
  algorithm: KeyAlgorithm;
  purpose: KeyPurpose;
  appConnectionId?: string;
  appId?: string;
};

export interface Key {
  id: string;
  algorithm: KeyAlgorithm;
  expiresAt: string;
  purpose: KeyPurpose;
  createdAt: string;
  updatedAt: string;
  appConnection?: AppConnection;
  app?: App;
  privateKey?: PrivateKey;
}

export interface PrivateKey {
  clientKey: string;
  resourceKey: string;
}

export const keyAdapter = createEntityAdapter<Key>();

export interface KeyState extends EntityState<Key>, CallState {
  filter: KeysFilter;
  isTopLevelView: boolean;
}

export const initialState: KeyState = {
  ...keyAdapter.getInitialState<CallState>(initialCallState),
  filter: null,
  isTopLevelView: false
};

export enum KeyPurpose {
  Encrypting = 'encrypting',
  Signing = 'signing'
}

export enum KeyAlgorithm {
  RS256 = 'RS256',
  RSA_OAEP = 'RSA-OAEP',
  RSA_OAEP_256 = 'RSA-OAEP-256',
  ES256 = 'ES256',
  ES384 = 'ES384',
  ES512 = 'ES512'
}
