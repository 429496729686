import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseTagDialogComponent, TypeSelectionDef } from '@core/containers/base-tag-dialog/base-tag-dialog.component';
import { BaseTagDialogForm } from '@core/types';
import { NullableFormControls } from '@utils';

import { uuidValidator } from '../../../../validators';

interface DialogData {
  dialogTitle?: string;
  submitButtonText?: string;
}

@Component({
  selector: 'admin-tag-dialog',
  templateUrl: './tag-dialog.component.html'
})
export class TagDialogComponent extends BaseTagDialogComponent implements OnInit {
  types: TypeSelectionDef[] = [
    { key: 'ability_id', name: 'Ability', validator: uuidValidator },
    { key: 'access_policy_id', name: 'Access Policy' },
    { key: 'agent_id', name: 'Agent', validator: uuidValidator },
    { key: 'app_connection_id', name: 'App connection', validator: uuidValidator },
    { key: 'app_id', name: 'App' },
    { key: 'approval_request_id', name: 'Approval Request', validator: uuidValidator },
    { key: 'authentication_provider_reference', name: 'Authentication provider', validator: uuidValidator },
    { key: 'campaign_id', name: 'Campaign', validator: uuidValidator },
    { key: 'domain_id', name: 'Domain', validator: uuidValidator },
    { key: 'fraud_rule_id', name: 'Fraud Rule', validator: uuidValidator },
    { key: 'gift_card_id', name: 'Gift Card', validator: uuidValidator },
    { key: 'gift_card_order_id', name: 'Gift Card Order', validator: uuidValidator },
    { key: 'loyalty_program_id', name: 'Loyalty program', validator: uuidValidator },
    { key: 'merchandise_id', name: 'Merchandise', validator: uuidValidator },
    { key: 'note_id', name: 'Note', validator: uuidValidator },
    { key: 'offer_id', name: 'Offer', validator: uuidValidator },
    { key: 'order_item_id', name: 'Order Item', validator: uuidValidator },
    { key: 'points_activity_id', name: 'Points activity', validator: uuidValidator },
    { key: 'redemption_rate_id', name: 'Redemption Rate', validator: uuidValidator },
    { key: 'role_id', name: 'Role' },
    { key: 'scope_id', name: 'Scope' },
    { key: 'tenant_id', name: 'Tenant' },
    { key: 'token_id', name: 'Token', validator: uuidValidator },
    { key: 'user_id', name: 'Customer', validator: uuidValidator }
  ];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.tagForm = this.fb.group<NullableFormControls<BaseTagDialogForm>>({
      type: this.fb.control(null, [Validators.required]),
      id: this.fb.control({ value: null, disabled: true }, this.defaultIdValidators)
    });
    this.subscribeToTypeValueChanges();
  }

  submitForm(): void {
    const value = this.tagForm.getRawValue();
    this.dialogRef.close({
      id: value.id,
      type: value.type
    });
  }
}
