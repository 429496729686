import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, userPasswordAdapter, UserPasswordState } from '../../types/user-passwords.type';
import {
  loadUserPasswords,
  loadUserPasswordsFailure,
  loadUserPasswordsSuccess
} from '../actions/user-passwords.actions';

export const userPasswordTriggers: EntityTriggers = {
  batch: {
    loading: [loadUserPasswords.type],
    resting: [loadUserPasswordsSuccess.type],
    erroring: [loadUserPasswordsFailure.type]
  }
};

export function userPasswordsReducer(state: UserPasswordState = initialState, action: Action): UserPasswordState {
  return callStateReducer(baseReducer, userPasswordTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadUserPasswordsSuccess, (state, { userPasswords }) => userPasswordAdapter.setAll(userPasswords, state))
);
