import { filter } from 'rxjs/operators';

import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  computed,
  inject,
  signal
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

import { LogicRuleBuilderHelperService } from '@shared/services/logic-rule-builder-helper/logic-rule-builder-helper.service';
import { Nullable } from '@shared/types';
import { LogicRuleForm } from '@shared/types/logic-rule-forms.type';
import {
  LogicRule,
  LogicRuleAttribute,
  LogicRuleAttributeGroupOption,
  LogicRuleAttributeOption,
  LogicRuleValue
} from '@shared/types/logic-rule.type';
import { DateUtils, injectUntilDestroyed } from '@utils';

@Component({
  selector: 'admin-logic-rule',
  templateUrl: './logic-rule.component.html',
  styleUrls: ['./logic-rule.component.scss', '../../../../stylesheets/v2-styles/rule-builder.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        hideRequiredMarker: true
      }
    },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'regular-dropdown-panel' } },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LogicRuleComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LogicRuleComponent,
      multi: true
    }
  ]
})
export class LogicRuleComponent implements OnChanges, OnInit, ControlValueAccessor, Validator {
  @Output() ruleRemoved = new EventEmitter<void>();

  // Only used for nested rules
  @Input() parentKey?: string;

  // Mostly used for Campaigns Qualification
  // to check if we're currently using the event type as the main attribute
  @Input() useEventTypeAsMainAttribute = false;

  @Input() disableMainAttributeSelection = false;

  @Input() elementIndex = 0;

  @Input({ required: true })
  set attributeOptions(value: (LogicRuleAttributeOption | LogicRuleAttributeGroupOption)[]) {
    this.attributeOptionsSignal.set(value);
  }

  get attributeOptions(): (LogicRuleAttributeOption | LogicRuleAttributeGroupOption)[] {
    return this.attributeOptionsSignal();
  }

  private attributeOptionsSignal = signal<(LogicRuleAttributeOption | LogicRuleAttributeGroupOption)[]>([]);

  logicRuleAttributeOptions = computed(() => this.attributeOptionsSignal().filter(this.isLogicRuleAttributeOption));

  logicRuleAttributeGroupOptions = computed(() =>
    this.attributeOptionsSignal().filter(this.isLogicRuleAttributeGroupOption)
  );

  attributeData: LogicRuleAttribute | null = null;
  attributeValueOptions = signal<(LogicRuleAttributeOption | LogicRuleAttributeGroupOption)[]>([]);
  attributeValueAsOptions = computed(() => this.attributeValueOptions().filter(this.isLogicRuleAttributeOption));
  attributeValueAsGroupOptions = computed(() =>
    this.attributeValueOptions().filter(this.isLogicRuleAttributeGroupOption)
  );
  childAttributeOptions: LogicRuleAttributeOption[] = [];
  cumulativeTimeRangeResourceOptions: LogicRuleAttributeOption[] = [];

  ruleForm = this.fb.group<LogicRuleForm>({
    attribute: this.fb.control(null, [Validators.required]),
    operator: this.fb.control(
      {
        value: null,
        disabled: true
      },
      [Validators.required]
    ),
    value: this.fb.control(
      {
        value: null,
        disabled: true
      },
      [Validators.required]
    )
  });

  // Control Value Accessor
  isTouched = false;

  onTouched: Nullable<() => void | null> = null;

  onChange: Nullable<(value: LogicRule) => void | null> = null;

  private untilDestroyed = injectUntilDestroyed();

  private logicRuleBuilderHelperService = inject(LogicRuleBuilderHelperService);

  constructor(
    private fb: FormBuilder,
    @Inject('timezone') private timezone: string
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.useEventTypeAsMainAttribute || changes.disableMainAttributeSelection) {
      this.toggleMainAttributeSelectionControls(this.useEventTypeAsMainAttribute && this.disableMainAttributeSelection);
    }
  }

  ngOnInit(): void {
    this.subscribeToValueChange();
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    this.ruleForm.valueChanges.pipe(this.untilDestroyed()).subscribe(() => {
      this.onChange?.(this.ruleForm.getRawValue());
      this.handleTouched();
    });
  }

  subscribeToValueChange(): void {
    // value.valueChanges is guaranteed to be called before
    // formGroup ruleForm.valueChanges
    // Ref: https://angular.io/api/forms/AbstractControl#valueChanges
    this.ruleForm.controls.value.valueChanges
      .pipe(
        filter(logicRuleValue => this.attributeData?.type === 'dependent_select' && !!logicRuleValue),
        this.untilDestroyed()
      )
      .subscribe(() => {
        const currentRule = this.ruleForm.getRawValue();

        this.removeNestedRulesControls();
        this.childAttributeOptions = this.filterDependentSelectOptions(
          this.logicRuleBuilderHelperService.getNestedAttributesAsOptions(currentRule.attribute!),
          String(currentRule.value)
        );
      });
  }

  getAttributeData(selectedAttribute: string): void {
    this.attributeData = this.parentKey
      ? this.logicRuleBuilderHelperService.getAttribute(`${this.parentKey}.${selectedAttribute}`)
      : this.logicRuleBuilderHelperService.getMainAttribute(selectedAttribute);

    if (this.attributeData) {
      this.attributeValueOptions.set(this.logicRuleBuilderHelperService.getAttributeOptions(this.attributeData));
      this.cumulativeTimeRangeResourceOptions = this.logicRuleBuilderHelperService.getCumulativeTimeResourceOptions(
        this.attributeData
      );

      /** When the attribute is `dependent_select` type, we need to reset the child nested attribute options
       *  into an empty array to block the nested attribute selection displayed
       */
      this.childAttributeOptions =
        this.attributeData.type === 'dependent_select'
          ? []
          : this.logicRuleBuilderHelperService.getNestedAttributesAsOptions(this.attributeData.attribute);
    } else {
      this.attributeValueOptions.set([]);
      this.childAttributeOptions = [];
      this.cumulativeTimeRangeResourceOptions = [];
    }
  }

  resetAndToggleFormControls(): void {
    const { operator: operatorControl, value: valueControl } = this.ruleForm.controls;

    if (this.attributeData) {
      operatorControl.enable({ emitEvent: false });
      valueControl.enable({ emitEvent: false });
    } else {
      operatorControl.disable({ emitEvent: false });
      valueControl.disable({ emitEvent: false });
    }

    operatorControl.reset(undefined, { emitEvent: false });
    valueControl.reset(this.getDefaultAttributeValue(this.attributeData?.type!), { emitEvent: false });

    // Remove all previous nested rules and connector, cumulative time range
    this.removeNestedRulesControls();
  }

  setupCumulativeTimeRangeControl(): void {
    if (this.attributeData?.isCumulativeType) {
      this.ruleForm.addControl('timeRange', this.fb.control(null, [Validators.required]), { emitEvent: false });
    }
  }

  /**** Event Handlers ****/

  handleAttributeChanged(selectedAttribute: string, isUserSelect: boolean = true): void {
    this.getAttributeData(selectedAttribute);
    this.resetAndToggleFormControls();
    this.setupCumulativeTimeRangeControl();

    // We only want to revalidate the form when the user
    // selects an attribute from the dropdown not when
    // writeValue is called
    // This to prevent form updating the value of control
    // causing wrong value when set the host form value
    if (isUserSelect) {
      // We don't want to set { emitEvent: false } here
      // because we want to validate operator and value
      // controls when they change their state from
      // disabled to enabled and vice versa
      this.ruleForm.updateValueAndValidity();
    }
  }

  addCondition(): void {
    // Set Validators.required on newly created attribute control
    // so that the form is always invalid when a new rule is added
    // We need to do this because Angular create a nested LogicRuleRuleComponent after
    // the parent rule component has run the validation function so the parent rule
    // component will always be valid if we don't set Validators.required
    const nestedRuleFormControl = this.fb.control<Nullable<LogicRule>>(null, [Validators.required]);

    if (this.ruleForm.controls.conditions) {
      this.ruleForm.controls.conditions.push(nestedRuleFormControl);
    } else {
      this.ruleForm.addControl('connector', this.fb.nonNullable.control<LogicRule['connector']>('and'));
      this.ruleForm.addControl('conditions', this.fb.array([nestedRuleFormControl]));
    }
  }

  removeRule(index: number): void {
    this.ruleForm.controls.conditions?.removeAt(index);

    if (this.ruleForm.controls.conditions?.length === 0) {
      this.ruleForm.removeControl('conditions');
      this.ruleForm.removeControl('connector');
    } else if (this.ruleForm.controls.conditions?.length === 1) {
      this.ruleForm.controls.connector?.setValue('and');
    }
  }

  removeNestedRulesControls(): void {
    // Remove all previous nested rules and connector, cumulative time range
    const controlsToRemove: ('conditions' | 'connector' | 'timeRange')[] = ['conditions', 'connector', 'timeRange'];
    controlsToRemove.forEach(controlName => {
      this.ruleForm.removeControl(controlName, { emitEvent: false });
    });
  }

  /**** Event Type as Main Event Handlers ****/

  handleEventTypeAsMainAttribute(selectedAttribute: string = 'event_type', attributeValue?: LogicRuleValue): void {
    this.getEventTypeAttributeData(selectedAttribute, attributeValue);
    this.resetAndToggleEventTypeFormControls();
  }

  getEventTypeAttributeData(selectedAttribute: string = 'event_type', attributeValue?: LogicRuleValue): void {
    // Get the first most event_type attribute
    this.attributeData = this.logicRuleBuilderHelperService.getMainAttribute(selectedAttribute);

    this.attributeValueOptions.set(
      this.attributeData ? this.logicRuleBuilderHelperService.getMainEventTypeResourceOptions() : []
    );
    // Since event_type is guaranteed to be dependent_select type
    // so we are safely to assume that the childAttributeOptions, cumulativeTimeRangeResourceOptions
    // will always be empty array
    // If we pass a value which means we're setting the value from the writeValue (by patching hostForm value)
    // therefore we need to get childAttributeOptions
    this.childAttributeOptions = attributeValue
      ? this.filterDependentSelectOptions(
          this.logicRuleBuilderHelperService.getNestedAttributesAsOptions(selectedAttribute),
          String(attributeValue)
        )
      : [];
    this.cumulativeTimeRangeResourceOptions = [];
  }

  resetAndToggleEventTypeFormControls(): void {
    const { attribute: attributeControl, operator: operatorControl, value: valueControl } = this.ruleForm.controls;

    // Since we only allow event_type as the main attribute
    // we can disable the attribute and operator control
    attributeControl.disable({ emitEvent: false });
    operatorControl.disable({ emitEvent: false });

    if (this.attributeData && !this.disableMainAttributeSelection) {
      valueControl.enable({ emitEvent: false });
    } else {
      valueControl.disable({ emitEvent: false });
    }

    // Reset all related controls
    const controlsToReset: ('attribute' | 'operator' | 'value')[] = ['attribute', 'operator', 'value'];
    controlsToReset.forEach(controlName => {
      this.ruleForm.controls[controlName].reset(undefined, { emitEvent: false });
    });

    // Remove all previous nested rules and connector, cumulative time range
    this.removeNestedRulesControls();
  }

  toggleMainAttributeSelectionControls(disabled: boolean): void {
    if (disabled) {
      this.ruleForm.controls.value.disable({ emitEvent: false });
    } else {
      this.ruleForm.controls.value.enable({ emitEvent: false });
    }
  }

  /**** CVA Methods ****/

  writeValue(value: LogicRule): void {
    // We will set default value for main attribute and operator
    // to { attribute: 'event_type', operator: '==' } cause
    // for Campaigns qualification it will always
    // be event_type as main attribute
    const defaultValue: LogicRule = this.useEventTypeAsMainAttribute
      ? { attribute: 'event_type', operator: '==', value: null }
      : {
          attribute: null,
          operator: null,
          value: null
        };

    const { connector, conditions, ...ruleData } = value ?? defaultValue;

    if (this.useEventTypeAsMainAttribute) {
      this.handleEventTypeAsMainAttribute(ruleData.attribute!, ruleData.value!);
    } else {
      this.handleAttributeChanged(ruleData.attribute!, false);
    }
    this.ruleForm.patchValue(ruleData, { emitEvent: false });

    if (connector && conditions && conditions.length > 0) {
      const ruleControls = conditions.map(condition => this.fb.control(condition, [Validators.required]));

      this.ruleForm.addControl('conditions', this.fb.array(ruleControls), { emitEvent: false });
      this.ruleForm.addControl('connector', this.fb.nonNullable.control<LogicRule['connector']>(connector), {
        emitEvent: false
      });
    }
  }

  registerOnChange(fn: (value: ReturnType<FormGroup<LogicRuleForm>['getRawValue']>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleTouched(): void {
    if (!this.isTouched && this.onTouched) {
      this.onTouched();
      this.isTouched = true;
    }
  }

  /**** Validator Methods ****/

  validate(): Nullable<ValidationErrors> {
    return this.ruleForm.invalid ? { invalidRule: true } : null;
  }

  /**** Utils methods ****/

  isLogicRuleAttributeGroupOption(
    value: LogicRuleAttributeOption | LogicRuleAttributeGroupOption
  ): value is LogicRuleAttributeGroupOption {
    return (value as LogicRuleAttributeGroupOption).options !== undefined;
  }

  isLogicRuleAttributeOption(
    value: LogicRuleAttributeOption | LogicRuleAttributeGroupOption
  ): value is LogicRuleAttributeOption {
    const attributeOptionValue = value as LogicRuleAttributeOption;

    return attributeOptionValue.label !== undefined && attributeOptionValue.value !== undefined;
  }

  isArray(value: any): value is any[] {
    return Array.isArray(value);
  }

  getDefaultAttributeValue(attributeType: LogicRuleAttribute['type'] | null): LogicRule['value'] | undefined {
    switch (attributeType) {
      case 'tags': {
        return [];
      }
      case 'datetime': {
        // eslint-disable-next-line unicorn/new-for-builtins
        return DateUtils.overrideTimezoneAndStripTime(24, Date(), this.timezone);
      }
      case 'date': {
        // For date type, we will set the default value to current date of tenant timezone
        const tenantCurrentDate = DateUtils.getDateInTenantTimezone(new Date(), this.timezone);

        return formatDate(tenantCurrentDate!, 'yyyy-MM-dd', 'en');
      }
      default: {
        return undefined;
      }
    }
  }

  filterDependentSelectOptions(options: LogicRuleAttributeOption[], keyword: string): LogicRuleAttributeOption[] {
    const prefixesToFilter: string[] = [`${keyword.toLowerCase()}.`];

    if (
      // For Segment, we will always fill shared. prefix
      !this.useEventTypeAsMainAttribute ||
      // Only for Campaigns Qualification
      // if the attribute value is in event category
      // then we will try to find shared. prefix as well
      this.logicRuleBuilderHelperService.getDependentValueCategory(keyword) === 'event'
    ) {
      prefixesToFilter.push('shared.');
    }

    // there are 3 types accepted for attributeOptions:
    // - shared.
    // - keyword.
    // - keyword.custom_attribute -> same as above but with a prefix
    return options.filter(option => {
      const optionValue = String(option.value).toLowerCase();

      return prefixesToFilter.some(prefix => optionValue.startsWith(prefix));
    });
  }
}
