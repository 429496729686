import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, TenantCampaign, tenantCampaignAdapter, TenantCampaignState } from '../../types';
import {
  acceptTenantCampaignConsent,
  acceptTenantCampaignConsentFailure,
  acceptTenantCampaignConsentSuccess,
  activateTenantCampaign,
  activateTenantCampaignFailure,
  activateTenantCampaignSuccess,
  createTenantCampaign,
  createTenantCampaignFailure,
  createTenantCampaignSuccess,
  deleteTenantCampaign,
  deleteTenantCampaignFailure,
  deleteTenantCampaignSuccess,
  duplicateTenantCampaign,
  duplicateTenantCampaignFailure,
  loadTenantCampaign,
  loadTenantCampaignAttributes,
  loadTenantCampaignAttributesFailure,
  loadTenantCampaignAttributesSuccess,
  loadTenantCampaignConsent,
  loadTenantCampaignConsentFailure,
  loadTenantCampaignConsentSuccess,
  loadTenantCampaignFailure,
  loadTenantCampaigns,
  loadTenantCampaignsFailure,
  loadTenantCampaignsSuccess,
  loadTenantCampaignSuccess,
  updateTenantCampaign,
  updateTenantCampaignFailure,
  updateTenantCampaignSuccess
} from '../actions/tenant-campaigns.actions';
import { duplicateTenantCampaignSuccess } from './../actions/tenant-campaigns.actions';

export const tenantCampaignTriggers: EntityTriggers = {
  single: {
    loading: [
      loadTenantCampaign.type,
      createTenantCampaign.type,
      updateTenantCampaign.type,
      deleteTenantCampaign.type,
      activateTenantCampaign.type,
      duplicateTenantCampaign.type,
      loadTenantCampaignConsent.type,
      acceptTenantCampaignConsent.type
    ],
    resting: [
      loadTenantCampaignSuccess.type,
      createTenantCampaignSuccess.type,
      updateTenantCampaignSuccess.type,
      deleteTenantCampaignSuccess.type,
      activateTenantCampaignSuccess.type,
      duplicateTenantCampaignSuccess.type,
      loadTenantCampaignConsentSuccess.type,
      acceptTenantCampaignConsentSuccess.type
    ],
    erroring: [
      loadTenantCampaignFailure.type,
      createTenantCampaignFailure.type,
      updateTenantCampaignFailure.type,
      deleteTenantCampaignFailure.type,
      activateTenantCampaignFailure.type,
      duplicateTenantCampaignFailure.type,
      loadTenantCampaignConsentFailure.type,
      acceptTenantCampaignConsentFailure.type
    ]
  },
  batch: {
    loading: [loadTenantCampaigns.type],
    resting: [loadTenantCampaignsSuccess.type],
    erroring: [loadTenantCampaignsFailure.type]
  }
};

export function tenantCampaignsReducer(state: TenantCampaignState = initialState, action: Action): TenantCampaignState {
  return callStateReducer(baseReducer, tenantCampaignTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadTenantCampaigns, (state, { filter }) => ({ ...state, filter })),
  on(loadTenantCampaignsSuccess, (state, { tenantCampaigns, total }) => ({
    ...tenantCampaignAdapter.setAll(tenantCampaigns, state),
    total
  })),
  on(loadTenantCampaignSuccess, (state, { tenantCampaign }) => tenantCampaignAdapter.upsertOne(tenantCampaign, state)),
  on(loadTenantCampaignAttributes, (state, { campaignType }) => ({
    ...state,
    attributes: {
      ...state.attributes,
      [campaignType]: {
        state: 'loading',
        error: null,
        attributeMap: null
      }
    }
  })),
  on(loadTenantCampaignAttributesSuccess, (state, { attributeCampaignType, attributeMap }) => ({
    ...state,
    attributes: {
      ...state.attributes,
      [attributeCampaignType]: {
        attributeMap,
        state: 'loaded' as const,
        error: null
      }
    }
  })),
  on(loadTenantCampaignAttributesFailure, (state, { attributeCampaignType, errorState }) => ({
    ...state,
    attributes: {
      ...state.attributes,
      [attributeCampaignType]: {
        attributeMap: null,
        state: 'error',
        error: errorState
      }
    }
  })),
  on(createTenantCampaign, state => ({ ...state, editingId: null })),
  on(updateTenantCampaign, state => ({ ...state, editingId: null })),
  on(activateTenantCampaign, state => ({ ...state, isActivateSuccess: null })),
  on(createTenantCampaignSuccess, (state, { tenantCampaign }) => ({
    ...tenantCampaignAdapter.upsertOne(tenantCampaign as TenantCampaign, state),
    editingId: tenantCampaign.id
  })),
  on(updateTenantCampaignSuccess, (state, { tenantCampaign }) => ({
    // TODO: We need to update the type for upsertOne to support partial shape
    ...tenantCampaignAdapter.upsertOne(tenantCampaign as TenantCampaign, state),
    editingId: tenantCampaign.id
  })),
  on(activateTenantCampaignSuccess, state => ({ ...state, isActivateSuccess: true })),
  on(loadTenantCampaignConsentSuccess, (state, { consent }) => ({
    ...state,
    isConsentSignedOff: consent
  })),
  on(acceptTenantCampaignConsentSuccess, state => ({
    ...state,
    isConsentSignedOff: true
  }))
);
