import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, QuestionState } from '../../types';
import {
  activateQuestions,
  activateQuestionsFailure,
  activateQuestionsSuccess,
  loadQuestions,
  loadQuestionsFailure,
  loadQuestionsSuccess
} from '../actions/questions.actions';

export const questionTriggers: EntityTriggers = {
  single: {
    loading: [],
    resting: [],
    erroring: []
  },
  batch: {
    loading: [loadQuestions.type, activateQuestions.type],
    resting: [loadQuestionsSuccess.type, activateQuestionsSuccess.type],
    erroring: [loadQuestionsFailure.type, activateQuestionsFailure.type]
  }
};

export function questionsReducer(state: QuestionState = initialState, action: Action): QuestionState {
  return callStateReducer(baseReducer, questionTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadQuestionsSuccess, (state, { questions }) => ({ ...state, questions }))
);
