import { FormControl } from '@angular/forms';
import { createAction, props } from '@ngrx/store';

import { ErrorState, HttpError, SnackbarMessages } from '@core/types';

import { PromoCodesFilter } from '../../types/promo-codes-filter.type';
import { PromoCodesResult } from '../../types/promo-codes-result.type';
import { PromoCode } from '../../types/promo-codes.type';

export const loadPromoCodes = createAction('[Promo Codes] Load Promo Codes', props<{ filter: PromoCodesFilter }>());
export const loadPromoCodesSuccess = createAction(
  '[Promo Codes] Load Promo Codes Success',
  props<{ promoCodesResult: PromoCodesResult }>()
);
export const loadPromoCodesFailure = createAction('[Promo Codes] Load Promo Codes Failure', props<ErrorState>());

export const loadPromoCode = createAction('[Promo Codes] Load Promo Code', props<{ id: string }>());
export const loadPromoCodeSuccess = createAction(
  '[Promo Codes] Load Promo Code Success',
  props<{ promoCode: PromoCode }>()
);
export const loadPromoCodeFailure = createAction('[Promo Codes] Load Promo Code Failure', props<ErrorState>());

export const createPromoCode = createAction(
  '[Promo Codes] Create Promo Code',
  props<{ promoCode: Partial<PromoCode> }>()
);
export const createPromoCodeSuccess = createAction(
  '[Promo Codes] Create Promo Code Success',
  (
    promoCode: PromoCode,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully created a promo!'
    }
  ) => ({ promoCode, snackbarMessages })
);
export const createPromoCodeFailure = createAction(
  '[Promo Codes] Create Promo Code Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      NN1009: 'Code already exists. Delete or enter new code.',
      default: 'You have failed to create the promo. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const updatePromoCode = createAction(
  '[Promo Codes] Update Promo Code',
  props<{ promoCode: Partial<PromoCode> }>()
);
export const updatePromoCodeSuccess = createAction(
  '[Promo Codes] Update Promo Code Success',
  (
    promoCode: PromoCode,
    snackbarMessages: SnackbarMessages = {
      default: 'Your changes have been successfully saved!'
    }
  ) => ({ promoCode, snackbarMessages })
);
export const updatePromoCodeFailure = createAction(
  '[Promo Codes] Update Promo Code Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to save the changes made. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const pausePromoCode = createAction(
  '[Promo Codes] Pause Promo Code',
  props<{ promoCode: Partial<PromoCode> }>()
);
export const pausePromoCodeSuccess = createAction(
  '[Promo Codes] Pause Promo Code Success',
  (
    promoCode: PromoCode,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully paused the promo!'
    }
  ) => ({ promoCode, snackbarMessages })
);
export const pausePromoCodeFailure = createAction(
  '[Promo Codes] Pause Promo Code Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to pause the promo. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const resumePromoCode = createAction(
  '[Promo Codes] Resume Promo Code',
  props<{ promoCode: Partial<PromoCode> }>()
);
export const resumePromoCodeSuccess = createAction(
  '[Promo Codes] Resume Promo Code Success',
  (
    promoCode: PromoCode,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully resumed the promo!'
    }
  ) => ({ promoCode, snackbarMessages })
);
export const resumePromoCodeFailure = createAction(
  '[Promo Codes] Resume Promo Code Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to resume the promo. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const deletePromoCode = createAction('[Promo Codes] Delete Promo Code', props<{ promoCodeId: string }>());
export const deletePromoCodeSuccess = createAction(
  '[Promo Codes] Delete Promo Code Success',
  (
    promoCodeId: string,
    snackbarMessages: SnackbarMessages = {
      default: 'You have successfully deleted the promo!'
    }
  ) => ({ promoCodeId, snackbarMessages })
);
export const deletePromoCodeFailure = createAction(
  '[Promo Codes] Delete Promo Code Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to delete the promo. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);

export const uploadPromoCodeImage = createAction(
  '[Promo Codes] Upload Promo Code Image',
  props<{
    promoCodeId?: string;
    file: FormData;
    dialogRefId: string;
    formControl: FormControl<string>;
  }>()
);
export const uploadPromoCodeImageSuccess = createAction(
  '[Promo Codes] Upload Promo Code Image Success',
  props<{
    imageUrl: string;
    dialogRefId: string;
    formControl: FormControl<string>;
  }>()
);
export const uploadPromoCodeImageFailure = createAction(
  '[Promo Codes] Upload Promo Code Image Failure',
  props<ErrorState>()
);
