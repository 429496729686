<ng-container [ngSwitch]="renderState$ | async">
  <mat-card *ngSwitchCase="'loading'" class="mat-elevation-z1 details-card overlay">
    <admin-spinner></admin-spinner>
  </mat-card>

  <mat-card *ngSwitchCase="'error'" fxLayout="row" fxLayoutAlign="center center">
    <div>
      <h4><mat-icon [inline]="true">warning</mat-icon>Loading offer failed</h4>
    </div>
  </mat-card>

  <ng-container *ngSwitchCase="'content'">
    @if (!isUserRoute) {
      <mat-card class="mat-elevation-z1 details-card">
        @if (offer$ | async; as offer) {
          <mat-card-content>
            <div class="headline">
              <mat-card-title>Offer ID: {{ offer.id }}</mat-card-title>
              <button
                mat-raised-button
                class="edit-button uppercase-button text-primary"
                [routerLink]="'../../edit/' + offer.id"
                *adminIfScopesInclude="SCOPES.updateOffers; relationOperator: 'or'"
              >
                <mat-icon class="uppercase-button__icon">create</mat-icon>
                <span class="uppercase-button__text">Edit Details</span>
              </button>
            </div>
            <ng-container *ngTemplateOutlet="offerDetails"></ng-container>
          </mat-card-content>
        }
      </mat-card>
    } @else {
      <ng-container *ngTemplateOutlet="offerDetails"></ng-container>
    }

    <!-- Reuse this section for user offer display -->
    <ng-template #offerDetails>
      @if (offer$ | async; as offer) {
        <div>
          <!--   first section   -->
          <div class="details-container">
            <div class="column">
              <div class="item">
                <div class="item__key">
                  <h4>Status</h4>
                </div>
                <span class="item__value">{{ offerStatusTypeCodes[offer.status] | titlecase }}</span>
              </div>
              @if (offer.createdAt) {
                <div class="item">
                  <div class="item__key">
                    <h4>Created At (UTC)</h4>
                  </div>
                  <span class="item__value">{{ offer.createdAt | date: 'yyyy-MM-dd' : '+0000' }}</span>
                </div>
              }
              <div class="item">
                <div class="item__key">
                  <h4>Brand</h4>
                </div>
                <span class="item__value">{{ offer.brand }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Category</h4>
                </div>
                <div class="item__value">
                  @for (category of offer.categories; track $index) {
                    <div class="item">{{ category | titlecase }}</div>
                  }
                </div>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Type</h4>
                </div>
                <span class="item__value">{{ offer.type | customTitleCase: 'fromSnakeCase' }}</span>
              </div>
            </div>
            <div class="column">
              <div class="item">
                <div class="item__key">
                  <h4>Start at (UTC)</h4>
                </div>
                <span class="item__value">{{ offer.startsAt | date: 'yyyy-MM-dd HH:mm' : '+0000' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Expire at (UTC)</h4>
                </div>
                <span class="item__value">{{ offer.endsAt | date: 'yyyy-MM-dd HH:mm' : '+0000' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Supplier</h4>
                </div>
                <span class="item__value">{{ offer.supplierId | uppercase }}</span>
              </div>
              @if (offer.sourceId) {
                <div class="item">
                  <div class="item__key">
                    <h4>Source ID</h4>
                  </div>
                  <span class="item__value">{{ offer.sourceId }}</span>
                </div>
              }
              <div class="item">
                <div class="item__key">
                  <h4>Activation Required</h4>
                </div>
                <span class="item__value">{{ offer.activationRequired }}</span>
              </div>
            </div>
          </div>

          <!--   second section   -->
          <div class="details-container border-top">
            <div class="column">
              <div class="item">
                <div class="item__key">
                  <h4>Title</h4>
                </div>
                <span class="item__value">{{ offer.title }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Short description</h4>
                </div>
                <span class="item__value">{{ offer.shortDescription }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Email</h4>
                </div>
                <span class="item__value">{{ offer.email }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Phone</h4>
                </div>
                <span class="item__value">{{ offer.phone }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Website</h4>
                </div>
                <span class="item__value">{{ offer.website }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Channels</h4>
                </div>
                <span class="item__value">{{
                  offer.channels.join(', ').replace('offline', 'in-store') | titlecase
                }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>How to use</h4>
                </div>
                <div class="item__value">
                  @for (item of offer.howToUse; track $index) {
                    <div class="field-header">{{ item?.header }}</div>
                    <div class="field-body">{{ item?.body }}</div>
                  }
                </div>
              </div>

              <div class="item">
                <div class="item__key">
                  <h4>Terms and conditions</h4>
                </div>
                <div class="item__value">
                  @for (item of merchantTerms; track $index) {
                    <span [innerHTML]="item" class="terms"></span>
                  }
                </div>
              </div>

              @if (visaTerms.length) {
                <div class="item">
                  <div class="item__key">
                    <h4>VISA terms and conditions</h4>
                  </div>
                  <div class="item__value">
                    @for (item of visaTerms; track $index) {
                      <span [innerHTML]="item" class="terms"></span>
                    }
                  </div>
                </div>
              }

              <div class="item">
                <div class="item__key">
                  <h4>Long Description</h4>
                </div>
                <div class="item__value">
                  @for (item of offer.longDescription; track $index) {
                    @if (item.body) {
                      <div class="field-header">{{ item.header }}</div>
                    }
                    <div class="field-body">{{ item.body }}</div>
                  }
                </div>
              </div>

              <div class="item">
                <div class="item__key">
                  <h4>Reward Rate (%)</h4>
                </div>
                <span class="item__value">{{ offer.rewardRate || 'N/A' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Reward Value</h4>
                </div>
                <span class="item__value">{{ offer.rewardValue || 'N/A' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Reward Type</h4>
                </div>
                <span class="item__value">{{ offer.rewardType || 'N/A' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Maximum Reward Per Transaction</h4>
                </div>
                <span class="item__value">{{ offer.maximumRewardPerTransaction || 'N/A' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Maximum Total Earnings</h4>
                </div>
                <span class="item__value">{{ offer.maxTotalEarnings || 'N/A' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Minimum Spend</h4>
                </div>
                <span class="item__value">{{ offer.minimumSpend || 'N/A' }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Maximum Redemptions</h4>
                </div>
                @if (offer.maxRedemptions) {
                  <span class="item__value"
                    >{{ offer.maxRedemptions.usesPerInterval }} redemption(s) every
                    {{ offerRedemptionInterval[offer.maxRedemptions.interval] }}</span
                  >
                } @else {
                  <span class="item__value"> N/A</span>
                }
              </div>
            </div>
            <div class="column">
              <div class="item">
                <div class="item__key">
                  <h4>Promo code</h4>
                </div>
                <span class="item__value">{{ offer.promocode }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Deep link</h4>
                </div>
                <span class="item__value">
                  <a [attr.href]="offer.trackingUrl" color="primary" target="_blank" rel="noopener noreferrer">
                    {{ offer.trackingUrl }}
                  </a>
                </span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Base URL</h4>
                </div>
                <span class="item__value">
                  <a
                    [attr.href]="offer.offerTrackingUrls?.baseUrl"
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ offer.offerTrackingUrls?.baseUrl }}
                  </a>
                </span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Cover image</h4>
                </div>
                <span class="item__value">
                  <img [src]="offer.coverImage" />
                </span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Brand logo</h4>
                </div>
                <span class="item__value">
                  <img [src]="offer.logo" />
                </span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Main category icon</h4>
                </div>
                <span class="item__value"> N/A </span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Location</h4>
                </div>
                <div class="item__value">
                  @for (location of offer.offerLocations; track $index) {
                    <div class="item location-item">
                      {{ location.address ? location.address + ', ' : '' }}
                      {{ location.city ? location.city + ', ' : '' }}
                      {{ location.country ? location.country : '' }}
                    </div>
                  }
                </div>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Excluded Dates</h4>
                </div>
                <span class="item__value">{{ offer.excludedDates }}</span>
              </div>
              <div class="item">
                <div class="item__key">
                  <h4>Valid Days</h4>
                </div>
                <div class="item__value">
                  @if ((offer.validDays | keyvalue)?.length > 0) {
                    @for (item of offer.validDays | keyvalue; track item.key) {
                      <div class="item">
                        <div class="item__key">
                          <h4>{{ item.key | customTitleCase: 'fromCamelCase' }}</h4>
                        </div>
                        <span class="item__value">{{ item.value.times }}</span>
                      </div>
                    }
                  } @else {
                    <span class="item__value">Valid for all days and times during the week</span>
                  }
                </div>
              </div>
            </div>
          </div>

          <!--   third section   -->
          @if (offer.scores) {
            <div class="details-container border-top">
              <div class="column">
                <div class="item">
                  <div class="item__key">
                    <h4>General scores</h4>
                  </div>
                  <span class="item__value">{{ offer.scores.general }}</span>
                </div>
                <div class="item">
                  <div class="item__key">
                    <h4>Age scores</h4>
                  </div>
                  <div class="item__value">
                    @for (item of offer.scores.age | keyvalue; track item.key) {
                      <div class="item">
                        <div class="item__key">
                          <h4>{{ item.key | customTitleCase: 'fromCamelCase' }}</h4>
                        </div>
                        <span class="item__value">{{ item.value }}</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="item">
                  <div class="item__key">
                    <h4>Gender scores</h4>
                  </div>
                  <div class="item__value">
                    @for (item of offer.scores.gender | keyvalue; track item.key) {
                      <div class="item">
                        <div class="item__key">
                          <h4>{{ item.key | customTitleCase: 'fromCamelCase' }}</h4>
                        </div>
                        <span class="item__value">{{ item.value }}</span>
                      </div>
                    }
                  </div>
                </div>
                <div class="item">
                  <div class="item__key">
                    <h4>Account type scores</h4>
                  </div>
                  <div class="item__value">
                    <div class="item">
                      <div class="item__key">
                        <h4>Savings</h4>
                      </div>
                      <span class="item__value">{{ offer.scores.accountType?.savings ?? 'N/A' }}</span>
                    </div>
                    <div class="item">
                      <div class="item__key">
                        <h4>Transaction</h4>
                      </div>
                      <span class="item__value">{{ offer.scores.accountType?.transaction ?? 'N/A' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </ng-template>
  </ng-container>
</ng-container>
