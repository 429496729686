import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { fraudRuleSchemaAdapter, FraudRuleSchemaState, initialState } from '../../types/fraud-rule-schemas.type';
import {
  loadFraudRuleSchemas,
  loadFraudRuleSchemasFailure,
  loadFraudRuleSchemasSuccess
} from '../actions/fraud-rule-schemas.actions';

export const fraudRuleSchemaTriggers: EntityTriggers = {
  batch: {
    loading: [loadFraudRuleSchemas.type],
    resting: [loadFraudRuleSchemasSuccess.type],
    erroring: [loadFraudRuleSchemasFailure.type]
  }
};

export function fraudRuleSchemasReducer(
  state: FraudRuleSchemaState = initialState,
  action: Action
): FraudRuleSchemaState {
  return callStateReducer(baseReducer, fraudRuleSchemaTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadFraudRuleSchemasSuccess, (state, { fraudRuleSchemas }) =>
    fraudRuleSchemaAdapter.setAll(fraudRuleSchemas, state)
  )
);
