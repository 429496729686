import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { User } from '../../users/types';

import { ProductsFilter } from './products-filter.type';

export interface Product {
  id: string;
  type: string;
  value: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  user?: User;
}

export const productAdapter = createEntityAdapter<Product>();

export interface ProductState extends EntityState<Product>, CallState {
  count: number;
  filter: ProductsFilter;
}

export const initialState: ProductState = {
  ...productAdapter.getInitialState<CallState>(initialCallState),
  count: 0,
  filter: null
};
