import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { WebhookSettingsCreateRequest, WebhookSettingsResults, WebhookSettingsUpdateRequest } from '../types';

@Injectable({
  providedIn: 'root'
})
export class WebhookSettingsService {
  constructor(private missionControlService: MissionControlService) {}

  getWebhookSettings(): Observable<WebhookSettingsResults> {
    return this.missionControlService.get<WebhookSettingsResults>('outgoing_request_settings?sort=updated_at');
  }

  createWebhookSettings(outgoingRequestSettings: WebhookSettingsCreateRequest): Observable<WebhookSettingsResults> {
    return this.missionControlService.post<WebhookSettingsResults>('outgoing_request_settings/bulk', {
      outgoingRequestSettings
    });
  }

  updateWebhookSettings(outgoingRequestSettings: WebhookSettingsUpdateRequest): Observable<WebhookSettingsResults> {
    return this.missionControlService.patch<WebhookSettingsResults>('outgoing_request_settings/bulk', {
      outgoingRequestSettings
    });
  }
}
