import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AutoRedemptionsService } from '../../services/auto-redemptions.service';
import {
  loadUserAutoRedemptions,
  loadUserAutoRedemptionsFailure,
  loadUserAutoRedemptionsSuccess
} from '../actions/auto-redemptions.actions';

@Injectable()
export class AutoRedemptionsEffects {
  loadUserAutoRedemptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserAutoRedemptions),
      exhaustMap(action =>
        this.autoRedemptionsService.getUserAutoRedemptions(action.userId).pipe(
          map(autoRedemptions => loadUserAutoRedemptionsSuccess({ autoRedemptions })),
          catchError(error => of(loadUserAutoRedemptionsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private autoRedemptionsService: AutoRedemptionsService
  ) {}
}
