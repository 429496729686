<admin-spinner *ngIf="loading$ | async"></admin-spinner>

<div class="main-container details-main-container" *ngIf="!(loading$ | async)">
  <h1 class="page-title-wrapper">Add User</h1>
  <div class="page-subtitle-wrapper">
    You can also add users directly via API. For more information on how to do so, please view the documentation
    <a href="https://docs.ascenda.com/reference/post_users" target="_blank" rel="noopener noreferrer">here</a>.
  </div>

  <div class="details-view">
    <form class="section" [formGroup]="createUserForm" (keydown.enter)="$event.preventDefault()">
      <mat-form-field>
        <mat-label>Email address</mat-label>
        <input matInput placeholder="e.g. john.smith@email.com" formControlName="email" />
        @if (createUserForm.get('email').hasError('required')) {
          <mat-error>Please enter an email address.</mat-error>
        }

        @if (createUserForm.get('email').hasError('email')) {
          <mat-error>Please enter an email address in the valid format (e.g. john.smith&#64;email.com).</mat-error>
        }
      </mat-form-field>

      <div class="unpadded-top-subsection-header partner-user-id">Partner User ID</div>
      <div class="partner-user-id-subheader">Unique identifier of user in the client system.</div>

      <mat-form-field>
        <mat-label>Partner user ID</mat-label>
        <input matInput placeholder="e.g. 00000000000" formControlName="partnerUserId" />
        <mat-error>Please enter a Partner user ID.</mat-error>
      </mat-form-field>

      <div class="admin-form-fields-grid-wrapper country-code">
        <mat-form-field>
          <mat-label>Country code (Optional)</mat-label>
          <input
            type="text"
            placeholder="e.g. + 00"
            aria-label="Number"
            matInput
            formControlName="phonePrefix"
            [matAutocomplete]="phonePrefix"
          />
          @if (getEmojyByCountryCode) {
            <span matPrefix class="flag">{{ getEmojyByCountryCode }}</span>
          }
          <mat-icon matSuffix [svgIcon]="isCodeAutoCompleteOpened ? 'up-thin' : 'down-thin'"></mat-icon>
          <mat-autocomplete
            #phonePrefix="matAutocomplete"
            (opened)="isCodeAutoCompleteOpened = true"
            (closed)="isCodeAutoCompleteOpened = false"
          >
            <ng-container *ngFor="let country of filteredCountryCodeOptions$ | async">
              <mat-option *ngFor="let countryCode of country.countryCodes" [value]="formatCountryCode(countryCode)">
                {{ isoCountryCodeToFlagEmoji(country.isoCode2) }} + {{ countryCode }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Phone number (Optional)</mat-label>
          <input matInput placeholder="e.g. 00000000" formControlName="phoneRaw" />
          <mat-error *ngIf="phoneRawControl.hasError('invalidE164')">{{ invalidPhoneMessage }}</mat-error>
        </mat-form-field>
      </div>

      <div class="admin-form-fields-grid-wrapper">
        <mat-form-field>
          <mat-label>First name</mat-label>
          <input matInput placeholder="e.g. John" formControlName="firstName" />
          <mat-error>Please enter a first name.</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last name</mat-label>
          <input matInput placeholder="e.g. Smith" formControlName="lastName" />
          <mat-error>Please enter a last name.</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Birth date (Optional)</mat-label>
          <input
            matInput
            placeholder="MM/YY/DDDD"
            [matDatepicker]="birthdatePicker"
            [max]="nowTime"
            (focus)="birthdatePicker.open()"
            formControlName="birthdate"
          />
          <mat-datepicker-toggle [for]="birthdatePicker" class="details-view-icon-button">
            <mat-icon svgIcon="datetime" matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #birthdatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Gender (Optional)</mat-label>
          <mat-select
            formControlName="gender"
            (openedChange)="toggleSelectDisplay()"
            [ngClass]="isGenderSelectOpened ? 'select-opened' : 'select-closed'"
            disableOptionCentering
          >
            <mat-option *ngFor="let gender of genders" [value]="gender">
              {{ gender | customTitleCase: 'fromSnakeCase' }}
            </mat-option>
          </mat-select>
          <admin-input-reset
            matSuffix
            initialValue=""
            [inputControl]="createUserForm.controls.gender"
            [loading]="loading$ | async"
          ></admin-input-reset>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Country (Optional)</mat-label>
        <input
          type="text"
          placeholder="e.g. United States of America"
          aria-label="Number"
          matInput
          formControlName="countryCode"
          [matAutocomplete]="countryCode"
        />
        @if (getEmojyByCountryName) {
          <span matPrefix class="flag">{{ getEmojyByCountryName }}</span>
        }
        <mat-icon
          matSuffix
          [svgIcon]="isCountryAutoCompleteOpened ? 'up-thin' : 'down-thin'"
          (click)="toggleAutoCompleteDropdown()"
        ></mat-icon>
        <mat-autocomplete
          #countryCode="matAutocomplete"
          (opened)="isCountryAutoCompleteOpened = true"
          (closed)="isCountryAutoCompleteOpened = false"
        >
          <mat-option *ngFor="let country of filteredCountryNameOptions$ | async" [value]="country.country">
            {{ isoCountryCodeToFlagEmoji(country.isoCode2) }} {{ country.country }}
          </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="countryCodeControl.hasError('invalidCountryCode')">
          Please select a country from the list or remove it
        </mat-error>
      </mat-form-field>

      <div class="section-header status">Status</div>
      <div class="status-management-section">
        <mat-radio-group formControlName="status">
          <div class="status-options">
            <mat-radio-button value="active"><span class="status-option">Active</span></mat-radio-button>
            <div>The user will have full access and redemption rights on the rewards portal.</div>
          </div>
          <div class="status-options">
            <mat-radio-button value="blocked"><span class="status-option">Blocked</span></mat-radio-button>
            <div>The user will have login access but is restricted from making redemptions on the rewards portal.</div>
          </div>
          <div class="status-options">
            <mat-radio-button value="closed"><span class="status-option">Closed</span></mat-radio-button>
            <div>The user is completely restricted from both login and redemptions on the rewards portal.</div>
          </div>
        </mat-radio-group>
      </div>
    </form>
  </div>

  <admin-footer>
    <button routerLink="../" class="secondary-button">Cancel</button>
    <button
      adminHandleErrorFields
      [disabled]="isSaveChangesDisabled"
      [ngClass]="isSaveChangesDisabled || isPristine ? 'disabled-button' : 'primary-button'"
      type="submit"
      [form]="createUserForm"
      (submitForm)="submitForm()"
    >
      Add
    </button>
  </admin-footer>
</div>
