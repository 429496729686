import { filter, map } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { nydusNetworkBootstrapQuery } from '@core/store/nydus-network/selectors/nydus-network-bootstrap.selectors';
import { NydusNetworkBootstrapState, RedemptionType } from '@core/types';
import { Params } from '@utils';

import { setSelectedPointsAccountId } from '../../../dashboard/store/actions/points-accounts.actions';
import { pointsAccountsQuery } from '../../../dashboard/store/selectors/points-accounts.selectors';
import { PointsAccountState } from '../../../dashboard/types';

@Component({
  selector: 'admin-order-item-create',
  templateUrl: './order-item-create.component.html',
  styleUrls: ['./order-item-create.component.scss']
})
export class OrderItemCreateComponent implements OnInit {
  pointsAccountData$ = this.pointsAccountStore.select(pointsAccountsQuery.getSelectedPointsAccount);
  pointsAccountError$ = this.pointsAccountStore.select(pointsAccountsQuery.getBatchError);
  pointsAccountLoading$ = this.pointsAccountStore.select(pointsAccountsQuery.isBatchLoading);

  pointsAccounts$ = this.pointsAccountStore.select(pointsAccountsQuery.getPointsAccountsList);
  selectedPointsAccountId$ = this.pointsAccountStore.select(pointsAccountsQuery.getSelectedPointsAccountId);

  isInvalidRedemptionType$: Observable<boolean>;

  currentRedemptionType: RedemptionType;

  constructor(
    private store: Store<NydusNetworkBootstrapState>,
    private pointsAccountStore: Store<PointsAccountState>,
    private route: ActivatedRoute,
    @Inject('showPointsAccountsSelector') public showPointsAccountsSelector: boolean
  ) {}

  ngOnInit(): void {
    this.currentRedemptionType = Params.find(this.route, 'redemptionType');
    this.isInvalidRedemptionType$ = this.store
      .select(nydusNetworkBootstrapQuery.getNydusNetworkBootstrapRedemptionTypes)
      .pipe(
        filter(redemptionTypes => !!redemptionTypes),
        map(redemptionTypes => !redemptionTypes.includes(this.currentRedemptionType))
      );
  }

  handlePointsAccountChange(pointsAccountId: string): void {
    this.pointsAccountStore.dispatch(setSelectedPointsAccountId({ selectedId: pointsAccountId }));
  }
}
