import { first, map } from 'rxjs/operators';

import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, MonoTypeOperatorFunction, Observable } from 'rxjs';

export type ComponentState = 'loading' | 'error' | 'content';

export function and(...observables: Observable<boolean>[]): Observable<boolean> {
  return combineLatest(observables).pipe(map(values => values.reduce((acc, value) => acc && value)));
}

export function or(...observables: Observable<any>[]): Observable<any> {
  return combineLatest(observables).pipe(map(values => values.reduce((acc, value) => acc || value)));
}

export function some(...observables: Observable<any>[]): Observable<boolean> {
  return or(...observables.map(observable => observable.pipe(map(value => !!value))));
}

export function every(...observables: Observable<any>[]): Observable<boolean> {
  return and(...observables.map(observable => observable.pipe(map(value => !!value))));
}

export function getRenderState$([loading$, error$]: [
  Observable<boolean>,
  Observable<any>
]): Observable<ComponentState> {
  return combineLatest([loading$, error$]).pipe(map(getRenderState));
}

export function getRenderState([loading, error]: [boolean, any]): ComponentState {
  return loading ? 'loading' : error ? 'error' : 'content';
}

export function whenLoaded(componentState$: Observable<ComponentState>): Observable<ComponentState> {
  return componentState$.pipe(first(state => state === 'content'));
}

export function injectUntilDestroyed(): <T>() => MonoTypeOperatorFunction<T> {
  const destroyRef = inject(DestroyRef);

  return <T>() => takeUntilDestroyed<T>(destroyRef);
}
