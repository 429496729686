import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { Ticket } from '../types';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  constructor(private missionControlService: MissionControlService) {}

  submitTicket(ticket: Ticket): Observable<void> {
    return this.missionControlService.post<void>('zendesk_tickets_v2', ticket);
  }
}
