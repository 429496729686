import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  Input,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl, UntypedFormGroup } from '@angular/forms';
import { startWith } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Formatters, ObjectUtils } from '@utils';

import { OpenApiSchema } from '../../types/open-api-schema.type';

@Component({
  selector: 'admin-array-input',
  templateUrl: './array-input.component.html',
  styleUrls: ['../form-field-shared.scss']
})
export class ArrayInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() fieldLabel: string;
  @Input() formControlName: string;
  @Input() schema: OpenApiSchema;
  @Input() required: boolean;

  destroyRef = inject(DestroyRef);

  errorMessage: string;
  value: string[];

  autocompleteValues: string[];
  valueFormattingObj: Record<string, string>;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostListener('focusout', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    const { type } = this.schema.items;

    if (this.isInvalidInput(type, inputValue)) {
      this.ngControl.control.setErrors({ invalidValueType: true });
      this.errorMessage = `Value should be ${type}`;
      return;
    }

    this.validateField();
  }

  ngOnInit(): void {
    this.autocompleteValues = this.schema.items.enum;

    if (this.autocompleteValues) {
      this.valueFormattingObj = Object.fromEntries(
        this.autocompleteValues.map(value => [value, Formatters.fromSnakeToSentenceCase(value)])
      );
    }
  }

  ngAfterViewInit(): void {
    // implementation below to solve setErrors issue https://github.com/angular/angular/issues/38191
    this.ngControl.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), startWith(null), delay(0))
      .subscribe(() => {
        this.validateField();
        this.cdRef.detectChanges();
      });
  }

  validateField(): void {
    if (this.required && ObjectUtils.isEmptyArray(this.value)) {
      this.ngControl.control.setErrors({ required: true });
      this.errorMessage = 'Required';
      return;
    }

    if (this.ngControl.control.hasError('duplicateValueError')) {
      this.errorMessage = 'Duplicate value';
      return;
    }

    this.ngControl.control.setErrors(null);
    this.errorMessage = null;
  }

  /* interface implementations */
  onChange = (_value: string[]): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string[]): void {
    const { type } = this.schema.items;

    this.value = value.filter(item => !this.isInvalidInput(type, item));
  }

  // TODO: enhance validations to support more rules in the future
  private isInvalidInput(type: any, inputValue: string): boolean {
    switch (type) {
      case 'string':
        return false;
      case 'integer':
        return !Number.isInteger(+inputValue) && !ObjectUtils.isNullish(inputValue);
      case 'number':
        return isNaN(+inputValue) && !ObjectUtils.isNullish(inputValue);
    }
  }
}
