import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { filter } from 'rxjs';

import { BaseTagDialogForm, HttpError } from '@core/types';
import { TagType } from '@shared/types';
import { NullableFormControls } from '@utils';

export interface TypeSelectionDef {
  key: TagType;
  name: string;
  validator?: () => ValidatorFn;
}

export abstract class BaseTagDialogComponent {
  tagForm: FormGroup<NullableFormControls<BaseTagDialogForm>>;
  types: TypeSelectionDef[] = [];
  defaultIdValidators = [Validators.required];

  get id(): AbstractControl {
    return this.tagForm.controls.id;
  }

  subscribeToTypeValueChanges(): void {
    this.tagForm.controls.type.valueChanges.pipe(filter(Boolean)).subscribe((type: string) => {
      this.id.enable();
      const selectionDef = this.types.find(typeDef => typeDef.key === type)!;

      if (selectionDef.validator) {
        this.id.setValidators([Validators.required, selectionDef.validator()]);
      } else {
        this.id.setValidators(this.defaultIdValidators);
      }

      this.id.updateValueAndValidity();
    });
  }

  formatErrorMessage(error: HttpError): string {
    return error.errors?.length > 0
      ? `Error: ${error.errors[0].code} ${error.errors[0].message}`
      : `Error: ${error.status}`;
  }
}
