import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

export const loadAllowedOrigins = createAction('[Allowed Origins] Load Allowed Origins');
export const loadAllowedOriginsSuccess = createAction(
  '[Allowed Origins] Load Allowed Origins Success',
  props<{ allowedOrigins: string[] }>()
);
export const loadAllowedOriginsFailure = createAction(
  '[Allowed Origins] Load Allowed Origins Failure',
  props<ErrorState>()
);

export const refreshAllowedOrigins = createAction('[Allowed Origins] Refresh Allowed Origins');
export const refreshAllowedOriginsSuccess = createAction('[Allowed Origins] Refresh Allowed Origins Success');
export const refreshAllowedOriginsFailure = createAction(
  '[Allowed Origins] Refresh Allowed Origins Failure',
  props<ErrorState>()
);
