import { AbstractControl, ValidatorFn } from '@angular/forms';

export function jsonValidator(option: { invalidEmptyJson: boolean } = { invalidEmptyJson: false }): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    try {
      if (control.value.length > 0) {
        const input = JSON.parse(control.value);

        // only object is allowed
        if (control.value.slice(0, 1) !== '{' || control.value.slice(control.value.length - 1) !== '}') {
          throw new Error('Invalid JSON');
        }

        if (option.invalidEmptyJson) {
          const keys = Object.keys(input);
          return keys.length === 0 ? { emptyJSON: { value: control.value } } : null;
        }
      }
      return null;
    } catch (error) {
      return { invalidJSON: { value: control.value } };
    }
  };
}
