import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Translations } from '../../types/translations.type';

export const loadTranslation = createAction('[Translations] Load Translation', props<{ localeCode: string }>());
export const loadTranslationSuccess = createAction(
  '[Translations] Load Translation Success',
  props<{ translation: Translations }>()
);
export const loadTranslationFailure = createAction('[Translations] Load Translation Failure', props<ErrorState>());
