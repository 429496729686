import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

export class Params {
  static find(route: ActivatedRoute | ActivatedRouteSnapshot, key: string): any {
    const snapshot = (route as ActivatedRoute).snapshot
      ? (route as ActivatedRoute).snapshot
      : (route as ActivatedRouteSnapshot);
    const value = snapshot.params[key];

    if (value) {
      return value;
    } else if (route.parent) {
      return this.find(route.parent, key);
    } else {
      return null;
    }
  }

  static findQueryParam(route: ActivatedRoute | ActivatedRouteSnapshot, key: string): any {
    const snapshot = (route as ActivatedRoute).snapshot
      ? (route as ActivatedRoute).snapshot
      : (route as ActivatedRouteSnapshot);
    return snapshot.queryParams[key];
  }
}
