import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { userTokenAdapter, userTokenInitialState, UserTokenState } from '../../types';
import {
  invalidateUserToken,
  invalidateUserTokenFail,
  invalidateUserTokenSuccess,
  loadUserTokens,
  loadUserTokensFailure,
  loadUserTokensSuccess
} from '../actions/user-tokens.actions';

export const userTokenTriggers: EntityTriggers = {
  single: {
    loading: [invalidateUserToken.type],
    resting: [invalidateUserTokenSuccess.type],
    erroring: [invalidateUserTokenFail.type]
  },
  batch: {
    loading: [loadUserTokens.type],
    resting: [loadUserTokensSuccess.type],
    erroring: [loadUserTokensFailure.type]
  }
};

export function userTokensReducer(state: UserTokenState = userTokenInitialState, action: Action): UserTokenState {
  return callStateReducer(baseReducer, userTokenTriggers)(state, action);
}

const baseReducer = createReducer(
  userTokenInitialState,
  on(loadUserTokens, (state, { filter }) => ({ ...state, filter })),
  on(loadUserTokensSuccess, (state, { userTokensResult }) => ({
    ...userTokenAdapter.setAll(userTokensResult.data, state),
    count: userTokensResult?.metadata?.total || 0
  }))
);
