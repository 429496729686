import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { MerchandiseCategoriesService } from '../../services/merchandise-categories.service';
import {
  loadMerchandiseCategories,
  loadMerchandiseCategoriesFailure,
  loadMerchandiseCategoriesSuccess,
  loadMerchandiseCategory,
  loadMerchandiseCategoryFailure,
  loadMerchandiseCategorySuccess
} from '../actions/merchandise-categories.actions';

@Injectable()
export class MerchandiseCategoriesEffects {
  loadMerchandiseCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMerchandiseCategories),
      exhaustMap(() =>
        this.merchandiseCategoriesService.getMerchandiseCategories().pipe(
          map(merchandiseCategory =>
            loadMerchandiseCategoriesSuccess({ merchandiseCategories: merchandiseCategory.data })
          ),
          catchError(error => of(loadMerchandiseCategoriesFailure({ error })))
        )
      )
    )
  );

  loadMerchandiseCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMerchandiseCategory),
      exhaustMap(action =>
        this.merchandiseCategoriesService.getMerchandiseCategory(action.id).pipe(
          map(merchandiseCategory => loadMerchandiseCategorySuccess({ merchandiseCategory })),
          catchError(error => of(loadMerchandiseCategoryFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private merchandiseCategoriesService: MerchandiseCategoriesService
  ) {}
}
