import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { usersQuery } from '../store/selectors/users.selectors';
import { UsersFilter, UserState } from '../types';

export const usersFilterResolver: ResolveFn<UsersFilter> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  store: Store<UserState> = inject(Store<UserState>)
): Observable<UsersFilter> =>
  store.pipe(
    select(usersQuery.getFilter),
    first(),
    map(filter => filter || new UsersFilter())
  );
