import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';

import { DrawerRef } from '@shared/services/drawer/drawer-ref.type';
import { DRAWER_DATA } from '@shared/tokens/drawer.tokens';

import { RecommendationType } from '../../../recommendations/types';

interface DrawerData {
  type: RecommendationType;
}

@Component({
  selector: 'admin-recommendations-drawer',
  templateUrl: './recommendations-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationsDrawerComponent {
  readonly type: RecommendationType;
  readonly drawerRef = inject(DrawerRef);

  constructor(@Inject(DRAWER_DATA) drawerData: DrawerData) {
    const { type } = drawerData;
    this.type = type;
  }
}
