import { Action, createReducer } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { ticketInitialState, TicketState } from '../../types';
import { submitTicket, submitTicketFailure, submitTicketSuccess } from '../actions/tickets.actions';

export const ticketTriggers: EntityTriggers = {
  single: {
    loading: [submitTicket.type],
    resting: [submitTicketSuccess.type],
    erroring: [submitTicketFailure.type]
  }
};

export function ticketsReducer(state: TicketState = ticketInitialState, action: Action): TicketState {
  return callStateReducer(baseReducer, ticketTriggers)(state, action);
}

const baseReducer = createReducer(ticketInitialState);
