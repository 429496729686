import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { GuardhouseService } from '@core/services/guardhouse/guardhouse.service';

import { ConnectionCredentialsService } from '../../services/connection-credentials.service';
import { ConnectionCredentialDetailsDialogComponent } from '../../views/connection-credential-details-dialog/connection-credential-details-dialog.component';
import {
  createConnectionCredentials,
  createConnectionCredentialsFailure,
  createConnectionCredentialsSuccess,
  loadConnectionCredentials,
  loadConnectionCredentialsFailure,
  loadConnectionCredentialsSuccess
} from '../actions/connection-credentials.actions';

@Injectable()
export class ConnectionCredentialsEffects {
  loadConnectionCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConnectionCredentials),
      exhaustMap(action =>
        this.connectionCredentialsService
          .getConnectionCredentials(action.appConnectionId, action.invalidated ?? false)
          .pipe(
            map(connectionCredentials => loadConnectionCredentialsSuccess({ connectionCredentials })),
            catchError(error => of(loadConnectionCredentialsFailure({ error })))
          )
      )
    )
  );

  createConnectionCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createConnectionCredentials),
      exhaustMap(action =>
        this.guardhouseService.generateToken(action.connectionRequest).pipe(
          map(connectionCredentialToken => createConnectionCredentialsSuccess({ connectionCredentialToken })),
          catchError(error => of(createConnectionCredentialsFailure({ error })))
        )
      )
    )
  );

  createConnectionCredentialsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createConnectionCredentialsSuccess),
        tap(({ connectionCredentialToken }) => {
          this.matDialog.open(ConnectionCredentialDetailsDialogComponent, {
            data: { connectionCredentialToken }
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private connectionCredentialsService: ConnectionCredentialsService,
    private guardhouseService: GuardhouseService,
    private matDialog: MatDialog
  ) {}
}
