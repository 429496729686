import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

@Injectable({
  providedIn: 'root'
})
export class UpsellDialogService {
  constructor(private missionControlService: MissionControlService) {}

  notifyIntentToUpgrade(): Observable<void> {
    return this.missionControlService.post<void>('notify/account_upgrade');
  }
}
