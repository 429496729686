import { createAction, props } from '@ngrx/store';

import { HttpError, SnackbarMessages } from '@core/types';

import { PaymentTier, UpdatePaymentTierPayload } from '../../types/payment-tiers.type';

export const updateTenantPaymentTier = createAction(
  '[Tenant Payment Tier] Update Tenant Payment Tier',
  props<Omit<UpdatePaymentTierPayload, 'version'>>()
);
export const updateTenantPaymentTierSuccess = createAction(
  '[Tenant Payment Tier] Update Tenant Payment Tier Success',
  (
    paymentTier: PaymentTier,
    snackbarMessages: SnackbarMessages = {
      default: 'Your have changes have been successfully submitted! Configurations will reload in a moment.'
    }
  ) => ({ paymentTier, snackbarMessages })
);
export const updateTenantPaymentTierFailure = createAction(
  '[Tenant Payment Tier] Update Tenant Payment Tier Failure',
  (
    error: HttpError,
    snackbarMessages: SnackbarMessages = {
      default: 'You have failed to save the changes made. Please try again.'
    }
  ) => ({ error, snackbarMessages })
);
