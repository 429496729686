<div class="dialog-content details-view">
  <div mat-dialog-title>{{ data.dialogTitle ?? 'Add tag' }}</div>
  <form [formGroup]="tagForm" (keydown.enter)="$event.preventDefault()">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" disableOptionCentering>
        <mat-option *ngFor="let type of types" [value]="type.key">
          {{ type.name }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>ID</mat-label>
      <input matInput type="text" formControlName="id" />
      <mat-error *ngIf="tagForm.get('id').errors?.invalidUuid">Invalid UUID</mat-error>
      <mat-error *ngIf="tagForm.get('id').errors?.required">Required</mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions>
    <button class="cancel-button" (click)="dialogRef.close(false)">
      <span>Cancel</span>
    </button>
    <button
      [disabled]="tagForm.invalid"
      [ngClass]="tagForm.invalid ? 'disabled-button' : 'primary-button'"
      (click)="submitForm()"
    >
      {{ data.submitButtonText ? data.submitButtonText : 'Submit' }}
    </button>
  </div>
</div>
