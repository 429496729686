<div class="details-view">
  <div class="section-header">General</div>
  <div class="section admin-item-wrapper">
    <admin-item label="Order ID">{{ orderItem.order.id }}</admin-item>
    <admin-item label="Item ID">{{ orderItem.id }}</admin-item>
    <admin-item class="cross-two-column" label="Product type">{{ orderItem.type | orderItemType }}</admin-item>
    <admin-item class="cross-two-column" label="Order initiator">{{ data?.orderSourceType | titlecase }}</admin-item>

    <div class="cross-two-column unpadded-subsection-header">Customer details</div>
    <admin-item label="Customer ID">{{ customerId }}</admin-item>
    <admin-item label="Ascenda ID" [copyButtonValue]="orderItem.userId">
      <a
        *adminIfScopesInclude="SCOPES.showUsers; relationOperator: 'or'; else: userIdWithoutLink"
        [routerLink]="'/customers/' + orderItem.userId + detailsView"
      >
        {{ orderItem.userId }}
      </a>
      <ng-template #userIdWithoutLink>
        {{ orderItem.userId }}
      </ng-template>
    </admin-item>
  </div>

  <div class="section-header">Benefit information</div>
  <div class="section admin-item-wrapper">
    <div class="cross-two-column unpadded-subsection-header">Membership details</div>
    <admin-item label="First name">{{ (data?.membershipInformation?.firstName | titlecase) || 'N/A' }}</admin-item>
    <admin-item label="Last name">{{ (data?.membershipInformation?.lastName | titlecase) || 'N/A' }}</admin-item>
    <admin-item class="cross-two-column" label="Membership number">{{
      data?.membershipInformation?.number
    }}</admin-item>

    <div class="cross-two-column unpadded-subsection-header">Program details</div>
    <admin-item label="Loyalty program ID">{{ data?.loyaltyProgramId }}</admin-item>
    <admin-item label="Loyalty program name">{{ data?.loyaltyProgramName }}</admin-item>

    <ng-container *ngIf="ObjectUtils.isNonEmptyObject(data?.statusUpgrade)">
      <div class="cross-two-column unpadded-subsection-header">Status upgrade details</div>
      <admin-item label="Status upgrade tier"> {{ data.statusUpgrade.statusUpgradeTier }}</admin-item>
      <admin-item label="Status upgrade type"> {{ data.statusUpgrade.statusUpgradeType }}</admin-item>
      <admin-item label="Anniversary date"> {{ data.statusUpgrade.anniversaryDate }}</admin-item>
    </ng-container>
  </div>
</div>
