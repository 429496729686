import { FormGroup } from '@angular/forms';
import { delay, iif, Observable, of, pairwise, startWith, switchMap } from 'rxjs';

import { ChangesUtils } from './changes-utils';
import { ObjectUtils } from './object-utils';

type PairValueObject<T> = { id: string } & T;

// please refer to offers.component.ts or order-items.component.ts for use cases
export const FilterFormUtils = {
  createIdSearchHandler<T>(actionFn: (id: string) => void): Function {
    return (pairValues: PairValueObject<T>[]) =>
      iif(
        // return true to stop values passing
        // return false to continue values passing
        () => FilterFormUtils.idSearchHandlerFn<T>(pairValues, actionFn),
        of(null),
        of(pairValues)
      );
  },

  createOtherValueSearchHandler<T>(actionFn: (formValue: T) => void): Function {
    return (pairValues: PairValueObject<T>[]) =>
      iif(
        // return true to stop values passing
        // return false to continue values passing
        () => FilterFormUtils.otherValuesSearchHandlerFn(pairValues, actionFn),
        of(null),
        of(pairValues)
      );
  },

  idSearchHandlerFn<T>(pairValues: PairValueObject<T>[], actionFn: (id: string) => void): boolean {
    // return true to stop passing value if there is no value passing from previous operator
    if (!pairValues) {
      return true;
    }

    const [previous, current] = pairValues;
    const previousId = previous.id;
    const { id: currentId, ...restCurrentFilter } = current;

    // if id exists and is different from previous value, meaning user is searching with id
    // return true to stop passing value
    if (currentId && previousId !== currentId) {
      actionFn(currentId);
      return true;
    }

    // return true to stop passing value if the action is to clear all fields (except id) when searching with id (id exists)
    return !!(previousId && currentId && ObjectUtils.isEmptyValueObject(restCurrentFilter));
  },

  otherValuesSearchHandlerFn<T>(pairValues: PairValueObject<T>[], actionFn: (formValue: T) => void): boolean {
    // return true to stop passing value if there is no value passing from previous operator
    if (!pairValues) {
      return true;
    }

    const [previous, current] = pairValues;
    const previousId = previous.id;
    const currentId = current.id;

    // return false to passing values if there is no difference between two values
    if (!ObjectUtils.hasDifferentValues(previous, current)) {
      return false;
    }

    // return true to stop passing values if
    // 1. there is difference between previous and current value (except id)
    // 2. user clear id field for getting back to default status (without any filter values)
    if (
      ObjectUtils.hasDifferentValues({ ...previous, id: null }, { ...current, id: null }) ||
      (previousId && !currentId && ObjectUtils.isEmptyValueObject(current))
    ) {
      actionFn(current);
      return true;
    }

    return true;
  },

  getValueChangesWithDebounce<FormGroupType extends FormGroup>(
    formGroup: FormGroupType,
    debounceTimeConfig: { [key in keyof FormGroupType['controls']]?: number } & { [path: string]: number }
  ): Observable<ReturnType<FormGroupType['getRawValue']>> {
    return formGroup.valueChanges.pipe(
      startWith(formGroup.value),
      pairwise(),
      switchMap(([prev, curr]) => {
        const changes = ChangesUtils.getChanges(prev, curr);

        // If there is only one change and it has debounce time, then
        // we should delay the next value emission
        // One change means that the user has changed only one field
        // at a time
        if (changes.length === 1 && debounceTimeConfig[changes[0].path]) {
          return of(curr).pipe(delay(debounceTimeConfig[changes[0].path]));
        }

        return of(curr);
      })
    );
  }
};
