import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

export interface LoyaltyProgramCatalog {
  id?: string;
  estimatedProcessingTime: number;
  creditTypes?: string[];
  dynamicDescriptor?: boolean;
  nameRequired: boolean;
  maintenance: {
    enabled: boolean;
    creditingDelay: number;
    creditingDelayStart?: string | null;
    creditingDelayEnd?: string | null;
  };
  type?: 'airline' | 'cash' | 'hotel' | 'crypto' | 'carbon';
  programName: string;
  shortProgramName?: string;
  currencyName: string;
  fullCurrencyName?: string;
  partnerName: string;
  description?: string;
  enrollmentLink?: string;
  termsAndConditionsLink?: string;
  imageLink?: string;
  detailsLink?: string;
  entityType: string;
  realTime: boolean;
}

export const loyaltyProgramCatalogAdapter = createEntityAdapter<LoyaltyProgramCatalog>();

export interface LoyaltyProgramCatalogState extends EntityState<LoyaltyProgramCatalog>, CallState {}

export const initialState: LoyaltyProgramCatalogState = {
  ...loyaltyProgramCatalogAdapter.getInitialState<CallState>(initialCallState)
};
