import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Currencies } from '@shared/types/currencies.type';

/**
 * Transform number from the smallest unit to dollar. Example usage:
 *
 * 10000 -> 100 for AUD
 *
 * 10000 -> 10000 for KRW
 */
@Pipe({ name: 'formatCurrency' })
export class CurrencyFormatPipe implements PipeTransform {
  transform(amount: number, code: string): Observable<number> {
    return from(import('@shared/types/currencies.type')).pipe(
      first(),
      map((module: { CURRENCIES: Currencies }) => {
        const currencies = module.CURRENCIES;
        if (currencies === undefined) {
          throw new Error('Could not load currencies data');
        }
        if (currencies[code] === undefined) {
          throw new Error(`Invalid currency ${code}`);
        }
        return amount / Math.pow(10, currencies[code].decimals);
      })
    );
  }
}
