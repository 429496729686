import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ComponentState, getRenderState$, ObjectUtils } from '@utils';

import { loadEnrollments } from '../../store/actions/enrollments.actions';
import { enrollmentsQuery } from '../../store/selectors/enrollments.selectors';
import { AccountType, Enrollment, EnrollmentState, RedemptionLevel } from '../../types';

@Component({
  selector: 'admin-anz-user-enrollments',
  templateUrl: './anz-user-enrollments.component.html',
  styleUrls: ['./anz-user-enrollments.component.scss']
})
export class ANZUserEnrollmentsComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  enrollments$: Observable<Enrollment[]>;
  filteredEnrollments$: Observable<Enrollment[]>;
  renderState$: Observable<ComponentState>;
  statuses: string[];
  statusControl: FormControl<string>;
  detailPanelOpenState = false;
  accountType = AccountType;
  redemptionLevel = RedemptionLevel;

  // TODO: remove showApidDetails and previous view after UAT test finished
  constructor(
    @Inject('timezone') public timezone: string,
    @Inject('showApidDetails') public showApidDetails: boolean,
    private store: Store<EnrollmentState>
  ) {}

  ngOnInit(): void {
    this.enrollments$ = this.store.select(enrollmentsQuery.getEnrollmentsList);
    const enrollmentsLoading$ = this.store.select(enrollmentsQuery.isBatchLoading);
    const enrollmentsErrors$ = this.store.select(enrollmentsQuery.getBatchError);

    this.renderState$ = getRenderState$([enrollmentsLoading$, enrollmentsErrors$]);

    this.createStatusFilter();
  }

  createStatusFilter(): void {
    const initialStatus = 'active';
    this.statusControl = new FormControl<string>(initialStatus);

    this.enrollments$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(enrollments => {
      this.statuses = ObjectUtils.removeArrayDuplicates([
        ...enrollments.map(enrollment => enrollment.product.partnerStatus),
        initialStatus
      ]).sort();
    });

    this.filteredEnrollments$ = this.statusControl.valueChanges.pipe(
      startWith(initialStatus),
      switchMap(status =>
        this.enrollments$.pipe(
          map(enrollments =>
            status ? enrollments.filter(enrollment => enrollment.product.partnerStatus === status) : enrollments
          )
        )
      )
    );
  }

  searchParentId(parentId: string): void {
    this.store.dispatch(loadEnrollments({ filter: { parentId }, isTopLevelView: true }));
  }
}
