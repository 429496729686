<div class="admin-form-fields-grid-wrapper" [formGroup]="dateTimeForm">
  <mat-form-field>
    <mat-label>{{ dateLabel }}</mat-label>
    <input
      matInput
      formControlName="date"
      adminSyncHostControlErrors
      adminMustDefineTogetherValidator
      [anotherValue]="dateTimeForm.controls.time.value!"
      [matDatepicker]="datePicker"
      [min]="minDate"
      (focus)="datePicker.open()"
      (blur)="onTouched()"
    />
    <mat-datepicker-toggle [for]="datePicker" class="details-view-icon-button">
      <mat-icon svgIcon="datetime" matDatepickerToggleIcon></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error>{{ getFormErrorsMessage(dateTimeForm.controls.date) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ timeLabel }}</mat-label>
    <input
      matInput
      [ngxTimepicker]="timepicker"
      [format]="24"
      formControlName="time"
      adminSyncHostControlErrors
      [syncErrorStatusOnly]="true"
      (blur)="onTouched()"
    />
    <button
      mat-icon-button
      class="details-view-icon-button !w-[52px]"
      [disabled]="dateTimeForm.controls.time.disabled"
      (click)="timepicker.open(); $event.preventDefault()"
    >
      <mat-icon svgIcon="datetime"></mat-icon>
    </button>
    <ngx-material-timepicker #timepicker></ngx-material-timepicker>
    <mat-error>{{ getFormErrorsMessage(dateTimeForm.controls.time) }}</mat-error>
  </mat-form-field>
</div>
