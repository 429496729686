import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SCOPES_OR } from '@core/types';
import { createResolver } from '@shared/route/resolver-helper';
import { Params } from '@utils';

import { loadMfaIdentities } from '../store/actions/mfa-identities.actions';
import { mfaIdentitiesQuery } from '../store/selectors/mfa-identities.selectors';
import { MfaIdentityState } from '../types';

export const mfaIdentitiesResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<void> => {
  const userId = Params.find(route, 'userId');

  return createResolver<MfaIdentityState>({
    scope: SCOPES_OR.viewMfaIdentities,
    query: mfaIdentitiesQuery.isMfaIdentitiesLoaded(userId),
    action: loadMfaIdentities({ userId })
  });
};
