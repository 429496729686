import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { fraudEvaluationAdapter, FraudEvaluationState, initialState } from '../../types';
import {
  loadFraudEvaluations,
  loadFraudEvaluationsFailure,
  loadFraudEvaluationsSuccess
} from '../actions/fraud-evaluations.actions';

export const fraudEvaluationTriggers: EntityTriggers = {
  batch: {
    loading: [loadFraudEvaluations.type],
    resting: [loadFraudEvaluationsSuccess.type],
    erroring: [loadFraudEvaluationsFailure.type]
  }
};

export function fraudEvaluationsReducer(
  state: FraudEvaluationState = initialState,
  action: Action
): FraudEvaluationState {
  return callStateReducer(baseReducer, fraudEvaluationTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadFraudEvaluations, (state, { filter }) => ({ ...state, filter })),
  on(loadFraudEvaluationsSuccess, (state, { result }) => ({
    ...fraudEvaluationAdapter.setAll(result.data, state),
    count: result?.metadata.total || 0
  }))
);
