import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';

import { FileProcessingRequestsFilter } from './file-processing-requests-filter.type';

export interface FileProcessingRequest {
  id: string;
  name: string;
  parameters: {
    steps: string[];
  };
  status: string;
  statusRemark: string;
  createdAt: string;
  updatedAt: string;
  items: Item[];
}

export interface Item {
  id: string;
  fileType: string;
  filename: string;
  status: string;
  statusRemark: string;
  sequence: number;
  startedAt: string;
  endedAt: string;
  metadata: {};
  recordsProcessed: number;
  rawFileId: string;
  createdAt: string;
  updatedAt: string;
}

export enum StatusTypeCodes {
  New = 'new',
  Processing = 'processing',
  Cancelled = 'cancelled',
  Success = 'success',
  Failure = 'failure'
}

export interface FileProcessingRequestState extends CallState {
  fileProcessingRequests: FileProcessingRequest[];
  filter: FileProcessingRequestsFilter;
}

export const fileProcessingRequestInitialState: FileProcessingRequestState = {
  ...initialCallState,
  fileProcessingRequests: null,
  filter: null
};
