import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { initialCallState } from '@core/store/call-state';
import { CallState } from '@core/types';
import { Tag } from '@shared/types/tags.type';

import { NotesFilter } from './notes-filter.type';

export interface Note {
  id?: string;
  adminUserId?: string;
  note: string;
  type?: NoteType;
  entity?: NoteEntity;
  entityId?: string;
  expiresAt?: string;
  createdAt?: string;
  updatedAt?: string;
  tags?: Tag[];
}

export const noteEntityTypes = ['users', 'campaigns', 'offers'] as const; // use plural
export type NoteEntity = (typeof noteEntityTypes)[number];

export enum NoteType {
  General = 'General',
  POA = 'POA',
  VIP = 'VIP',
  AlternativePhoneNumber = 'Alternative phone number'
}

export interface Metadata {
  count: number;
  currentPage: number;
  totalPages: number;
}

export const noteAdapter = createEntityAdapter<Note>();

export interface NoteState extends EntityState<Note>, CallState {
  filter: NotesFilter;
  isTopLevelView: boolean;
  metadata: Metadata;
}

export const initialState: NoteState = {
  ...noteAdapter.getInitialState<CallState>(initialCallState),
  metadata: null,
  filter: null,
  isTopLevelView: false
};
