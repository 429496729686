import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, orderAttemptAdapter, OrderAttemptState } from '../../types/order-attempts.type';
import {
  loadOrderAttempts,
  loadOrderAttemptsFailure,
  loadOrderAttemptsSuccess
} from '../actions/order-attempts.actions';

export const orderAttemptTriggers: EntityTriggers = {
  batch: {
    loading: [loadOrderAttempts.type],
    resting: [loadOrderAttemptsSuccess.type],
    erroring: [loadOrderAttemptsFailure.type]
  }
};

export function orderAttemptsReducer(state: OrderAttemptState = initialState, action: Action): OrderAttemptState {
  return callStateReducer(baseReducer, orderAttemptTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadOrderAttemptsSuccess, (state, { attempts }) => orderAttemptAdapter.setAll(attempts, state))
);
