import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';
import { ObjectUtils } from '@utils';

import { ConvertedCurrency, CurrencyConversionData } from '../types';

@Injectable({
  providedIn: 'root'
})
export class CurrencyConversionService {
  constructor(private missionControlService: MissionControlService) {}

  convertCurrency(currencyConversionData: CurrencyConversionData): Observable<ConvertedCurrency> {
    const payload = ObjectUtils.sanitizeRequestObject<CurrencyConversionData>(currencyConversionData);
    return this.missionControlService
      .post<{ data: ConvertedCurrency }>('currency_conversions', payload)
      .pipe(map(currencyConversionResponse => currencyConversionResponse.data));
  }
}
