import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { PointsAccount } from '../../../dashboard/types';

@Component({
  selector: 'admin-points-accounts-select-v2',
  templateUrl: './points-accounts-select-v2.component.html',
  styleUrls: ['../../../dashboard/views/dashboard-v2/dashboard-v2.component.scss']
})
export class PointsAccountsSelectV2Component implements OnInit {
  @Input() pointsAccounts: PointsAccount[];
  @Input() initialSelectedId: string;
  @Output() pointsAccountIdChanged = new EventEmitter<string>();

  readonly fb = inject(FormBuilder);

  pointsAccountsDropdownList: { id: string; status: string; groupReferenceId: string }[];
  pointsAccountControl: FormControl<string>;

  ngOnInit(): void {
    this.createDropdownList();
    this.subscribeToPointsAccountChange();
  }

  createDropdownList(): void {
    this.pointsAccountsDropdownList = this.pointsAccounts.map(account => {
      const id = account.id;
      const status = account.status === 'active' ? '' : `(${account.status})`;
      let groupReferenceId = account.enrollments[0].groupReferenceId;
      groupReferenceId = groupReferenceId.slice(-4);

      return { id, status, groupReferenceId };
    });

    // set initial value
    this.pointsAccountControl = this.fb.nonNullable.control<string>(this.initialSelectedId);
  }

  subscribeToPointsAccountChange(): void {
    this.pointsAccountIdChanged.emit(this.initialSelectedId);
    this.pointsAccountControl.valueChanges.subscribe(pointsAccountId => {
      this.pointsAccountIdChanged.emit(pointsAccountId);
    });
  }
}
