import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialState, watchlistAdapter, WatchlistsFilter, WatchlistState } from '../../types';
import {
  createWatchlist,
  createWatchlistFailure,
  createWatchlistSuccess,
  deleteWatchlist,
  deleteWatchlistFailure,
  deleteWatchlistSuccess,
  loadWatchlist,
  loadWatchlistFailure,
  loadWatchlists,
  loadWatchlistsFailure,
  loadWatchlistsSuccess,
  loadWatchlistSuccess,
  updateWatchlist,
  updateWatchlistFailure,
  updateWatchlistSuccess
} from '../actions/watchlists.actions';

export const watchlistTriggers: EntityTriggers = {
  single: {
    loading: [loadWatchlist.type, createWatchlist.type, updateWatchlist.type, deleteWatchlist.type],
    resting: [
      loadWatchlistSuccess.type,
      createWatchlistSuccess.type,
      updateWatchlistSuccess.type,
      deleteWatchlistSuccess.type
    ],
    erroring: [
      loadWatchlistFailure.type,
      createWatchlistFailure.type,
      updateWatchlistFailure.type,
      deleteWatchlistFailure.type
    ]
  },
  batch: {
    loading: [loadWatchlists.type],
    resting: [loadWatchlistsSuccess.type],
    erroring: [loadWatchlistsFailure.type]
  }
};

export function watchlistsReducer(state: WatchlistState = initialState, action: Action): WatchlistState {
  return callStateReducer(baseReducer, watchlistTriggers)(state, action);
}

const baseReducer = createReducer(
  initialState,
  on(loadWatchlistSuccess, (state, { watchlist }) => ({
    ...watchlistAdapter.upsertOne(watchlist, state),
    count: 1
  })),
  on(loadWatchlists, (state, { filter }) => ({ ...state, filter })),
  on(loadWatchlistsSuccess, (state, { result }) => ({
    ...watchlistAdapter.setAll(result.data, state),
    count: result?.metadata.total || 0
  })),
  on(updateWatchlistSuccess, (state, { watchlist }) => watchlistAdapter.setOne(watchlist, state)),
  on(createWatchlistSuccess, state => {
    state.filter ||= new WatchlistsFilter();
    return {
      ...state,
      filter: { ...state.filter, page: 1 }
    };
  })
);
