import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { PickupZone } from '../../types';

export const loadPickupZones = createAction('[Pickup Zones] Load Pickup Zones');
export const loadPickupZonesSuccess = createAction(
  '[Pickup Zones] Load Pickup Zones Success',
  props<{ pickupZones: PickupZone[] }>()
);
export const loadPickupZonesFailure = createAction('[Pickup Zones] Load Pickup Zones Failure', props<ErrorState>());
