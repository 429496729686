import { Component, Inject, Input, OnChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { HttpError } from '@core/types';
import { ComponentState, getRenderState } from '@utils';

import { Tranche } from '../../types';

@Component({
  selector: 'admin-points-expiring-table',
  templateUrl: './points-expiring-table.component.html',
  styleUrls: ['./points-expiring-table.component.scss']
})
export class PointsExpiringTableComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() pointsSummaryTranches: Tranche[];
  @Input() error: HttpError;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  renderState: ComponentState;
  pointsExpiryData: MatTableDataSource<Tranche>;
  columns: string[] = ['expiryDate', 'balance'];

  constructor(
    @Inject('loyaltyCurrency') public loyaltyCurrency: string,
    @Inject('pointsActivityDecimals') public pointsActivityDecimals: number
  ) {}

  ngOnChanges(): void {
    this.renderState = getRenderState([this.loading, this.error]);

    setTimeout(() => {
      this.pointsExpiryData = new MatTableDataSource<Tranche>(this.pointsSummaryTranches);
      this.pointsExpiryData.paginator = this.paginator;
    });
  }
}
