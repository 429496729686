import { createAction, props } from '@ngrx/store';

import { ErrorState } from '@core/types';

import { Attempt } from '../../types/order-attempts.type';

export const loadOrderAttempts = createAction('[Order Attempts] Load Order Attempts', props<{ orderId: string }>());
export const loadOrderAttemptsSuccess = createAction(
  '[Order Attempts] Load Order Attempts Success',
  props<{ attempts: Attempt[] }>()
);
export const loadOrderAttemptsFailure = createAction(
  '[Order Attempts] Load Order Attempts Failure',
  props<ErrorState>()
);
