import { Dictionary } from '@ngrx/entity';

import { EntityDetectorType } from '@core/types';

export type DetectorFn<E> = (entities?: Dictionary<E>, id?: string) => boolean;
export type Detector<E> = (type: EntityDetectorType) => DetectorFn<E>;

export function entitiesExist<E>(entities: Dictionary<E>): boolean {
  return !!Object.keys(entities).length;
}

export function entitiesDoNotExist<E>(entities: Dictionary<E>): boolean {
  return !entitiesExist(entities);
}

export function entityExists<E, T = E>(entities: Dictionary<E>, id: T[keyof T]): boolean {
  if (typeof id === 'string' || typeof id === 'number') {
    return id in entities;
  }

  return false;
}

export function entityDoesNotExist<E, T = E>(entities: Dictionary<E>, id: T[keyof T]): boolean {
  return !entityExists(entities, id);
}

const entityDetectorMap: { [key: string]: DetectorFn<any> } = {
  allLoaded: entitiesExist,
  noneLoaded: entitiesDoNotExist,
  oneLoaded: entityExists,
  notLoaded: entityDoesNotExist
};

export function getDetectorByType<E>(type: EntityDetectorType): DetectorFn<E> {
  return (entities?: Dictionary<E>, id?: string) => entityDetectorMap[type](entities, id);
}

// Creates a function that returns an entity detector function based on the type argument.
// Detector functions types:
//  - allLoaded: checks if there are any entities in the dictionary
//  - noneLoaded: checks if there are NO entities in the dictionary
//  - oneLoaded: checks if an entity is in the dictionary (by ID)
//  - notLoaded: checks if an entity is NOT in the dictionary (by ID)
//
// Example:
//  someDetector = createDetector<SomeType>()
//  someDetector('allLoaded')(entities) // returns true/false
//  someDetector('oneLoaded')(entities, 'someId') // returns true/false
export function createDetector<E>(): Detector<E> {
  return (type: EntityDetectorType) => getDetectorByType<E>(type);
}
