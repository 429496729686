import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { logoutUser } from '@core/store/auth/actions/auth.actions';
import { AuthState } from '@core/store/auth/reducers/auth.reducer';

@Component({
  selector: 'admin-self-help-faq',
  templateUrl: './self-help-faq.component.html'
})
export class SelfHelpFAQComponent {
  constructor(private store: Store<AuthState>) {}

  // logout so that we can back to login page
  redirectToLoginPage(): void {
    this.store.dispatch(logoutUser());
  }
}
