import { first, switchMap, tap } from 'rxjs/operators';

import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, ObservableInput, throwError } from 'rxjs';

import { GuardhouseErrorCodes, MissionControlErrorCodes } from '@core/errors/error-codes.type';
import { CoreState } from '@core/store';
import { approvalRequestedFail, insufficientLevelFail, noPermissionFail } from '@core/store/auth/actions/auth.actions';
import { InterruptedState } from '@core/store/interrupted/reducers/interrupted.reducer';
import { interruptedQuery } from '@core/store/interrupted/selectors/interrupted.selectors';
import { HttpError, HttpErrorMetadata, UNDEFINED_HTTP_ERROR } from '@core/types';

import { RollbarService } from '../../../../services/rollbar/rollbar.service';
import { SENTRY_LOGGER } from './../../../../tokens/sentry.token';

@Injectable({
  providedIn: 'root'
})
export class MissionControlErrorHandlerService {
  private readonly sentryLogger = inject(SENTRY_LOGGER);

  constructor(
    private store: Store<CoreState>,
    private interruptedStore: Store<InterruptedState>,
    private rollbarService: RollbarService
  ) {}

  handleError(url: string, errorResponse: HttpErrorResponse): ObservableInput<any> {
    const error = this.transformError(url, errorResponse);
    if (error.status === 401) {
      return this.handleUnauthorized(error);
    } else if (this.isBlobError(errorResponse)) {
      return this.parseErrorBlob(errorResponse);
    } else if (error.status === 0) {
      error.errors = [UNDEFINED_HTTP_ERROR];
      this.rollbarService.manualError(UNDEFINED_HTTP_ERROR.message);
      this.sentryLogger.error(UNDEFINED_HTTP_ERROR.message);
      return throwError(() => error);
    } else {
      return throwError(() => error);
    }
  }

  parseErrorBlob(errorResponse: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable(observer => {
      reader.onloadend = () => {
        const messageObject = JSON.parse(reader.result as string);
        observer.error({
          ...messageObject,
          message: messageObject.errors.message,
          status: errorResponse.status,
          statusText: errorResponse.statusText,
          headers: errorResponse.headers,
          url: errorResponse.url,
          errors: messageObject.errors ? messageObject.errors : []
        });
        observer.complete();
      };
    });
    reader.readAsText(errorResponse.error);
    return obs;
  }

  transformError(requestUrl: string, errorResponse: HttpErrorResponse | Error): HttpError {
    if (this.isErrorResponse(errorResponse)) {
      const { message, status, statusText, headers, url, error } = errorResponse;

      return {
        message,
        status,
        statusText,
        headers: this.extractHeaders(headers),
        url, // use full URL given by errorResponse when available
        errors: error ? error.errors : []
      };
    } else {
      return {
        message: errorResponse.message,
        status: null,
        statusText: null,
        headers: null,
        url: requestUrl,
        errors: []
      };
    }
  }

  private handleUnauthorized(error: HttpError): ObservableInput<any> {
    if (this.isNoPermissionError(error)) {
      this.store.dispatch(noPermissionFail());
      return EMPTY;
    } else if (this.isStepUpError(error)) {
      return this.interruptedStore.pipe(
        select(interruptedQuery.getStepUpAction),
        first(),
        tap(stepUpAction => this.store.dispatch(insufficientLevelFail({ error, stepUpAction }))),
        switchMap(() => throwError(() => error))
      );
    } else if (this.isApprovalRequestedError(error)) {
      return this.interruptedStore.pipe(
        select(interruptedQuery.getApprovalRequestedAction),
        first(),
        tap(action => {
          const { accessPolicy, payload, pendingCount } = this.getMetadataFromError(error);
          const approvalRequestedAction = { ...action, accessPolicy, payload, pendingCount };
          this.store.dispatch(approvalRequestedFail({ approvalRequestedAction }));
        }),
        switchMap(() => EMPTY)
      );
    } else {
      return throwError(() => error);
    }
  }

  private getMetadataFromError(httpError: HttpError): HttpErrorMetadata {
    const error = httpError.errors.find(err => err.code === MissionControlErrorCodes.APPORVAL_REQUESTED);
    return error?.metadata;
  }

  private isNoPermissionError(error: HttpError): boolean {
    const errorsHeader = error.headers['x-auth-errors'];

    return (
      Array.isArray(errorsHeader) &&
      (errorsHeader.includes(GuardhouseErrorCodes.NO_PERMISSION) ||
        errorsHeader.includes(GuardhouseErrorCodes.UNAUTHORISED_ADMIN_PANEL_ACCESS))
    );
  }

  private isStepUpError(error: HttpError): boolean {
    return this.hasErrorCode(error, GuardhouseErrorCodes.TOKEN_LEVEL_INSUFFICIENT);
  }

  private isApprovalRequestedError(error: HttpError): boolean {
    return this.hasErrorCode(error, MissionControlErrorCodes.APPORVAL_REQUESTED);
  }

  private hasErrorCode(error: HttpError, code: string): boolean {
    return error.errors?.map(err => err.code).includes(code);
  }

  private isErrorResponse(errorResponse: HttpErrorResponse | Error): errorResponse is HttpErrorResponse {
    return (errorResponse as HttpErrorResponse).statusText !== undefined;
  }

  private isBlobError(errorResponse: HttpErrorResponse | Error): errorResponse is HttpErrorResponse {
    return (
      errorResponse instanceof HttpErrorResponse &&
      errorResponse.error instanceof Blob &&
      errorResponse.error.type === 'application/json'
    );
  }

  private extractHeaders(headers: HttpHeaders): { [key: string]: string[] } {
    return headers.keys().reduce((res, key) => {
      res[key] = headers.getAll(key);
      return res;
    }, {});
  }
}
