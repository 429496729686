import { Action, createReducer, on } from '@ngrx/store';

import { callStateReducer } from '@core/store/call-state';
import { EntityTriggers } from '@core/types';

import { initialOfferSourceState, offerSourceAdaptor, OfferSourceState } from '../../types';
import {
  loadOfferSources,
  loadOfferSourcesFailure,
  loadOfferSourcesSuccess,
  uploadOfferSources,
  uploadOfferSourcesFailure,
  uploadOfferSourcesSuccess
} from '../actions/offer-sources.actions';

export const offerSourceTriggers: EntityTriggers = {
  single: {
    loading: [uploadOfferSources.type],
    resting: [uploadOfferSourcesSuccess.type],
    erroring: [uploadOfferSourcesFailure.type]
  },
  batch: {
    loading: [loadOfferSources.type],
    resting: [loadOfferSourcesSuccess.type],
    erroring: [loadOfferSourcesFailure.type]
  }
};

export function offerSourcesReducer(
  state: OfferSourceState = initialOfferSourceState,
  action: Action
): OfferSourceState {
  return callStateReducer(baseReducer, offerSourceTriggers)(state, action);
}

const baseReducer = createReducer(
  initialOfferSourceState,
  on(loadOfferSources, (state, { filter }) => ({ ...state, filter })),
  on(loadOfferSourcesSuccess, (state, { result }) => offerSourceAdaptor.setAll(result.data, state))
);
