import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { FileProcessingRequest, PickupZone, RemoteFile } from '../types';

export interface MissionControlFileProcessingRequestsResponse {
  fileProcessingRequests: FileProcessingRequest[];
}

export interface MissionControlPickupZonesResponse {
  pickupZones: PickupZone[];
}

export interface MissionControlRemoteFilesResponse {
  remoteFiles: RemoteFile[];
}

@Injectable({ providedIn: 'root' })
export class PickupZonesService {
  constructor(private missionControlService: MissionControlService) {}

  getPickupZones(): Observable<PickupZone[]> {
    return this.missionControlService
      .get<MissionControlPickupZonesResponse>('pickup_zones')
      .pipe(map(missionControlPickupZonesResponse => missionControlPickupZonesResponse.pickupZones));
  }

  getFileProcessingRequests(id: string): Observable<FileProcessingRequest[]> {
    return this.missionControlService
      .get<MissionControlFileProcessingRequestsResponse>(`pickup_zones/${id}/file_processing_requests`)
      .pipe(
        map(
          missionControlFileProcessingRequestsResponse =>
            missionControlFileProcessingRequestsResponse.fileProcessingRequests
        )
      );
  }

  getRemoteFiles(id: string): Observable<RemoteFile[]> {
    return this.missionControlService
      .get<MissionControlRemoteFilesResponse>(`pickup_zones/${id}/remote_files`)
      .pipe(map(missionControlRemoteFilesResponse => missionControlRemoteFilesResponse.remoteFiles));
  }
}
