import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { RecommendedSegment } from '../../../recommendations/types';

@Component({
  selector: 'admin-recommendations-side-sheet',
  templateUrl: './recommendations-side-sheet.component.html',
  animations: [
    trigger('expandedRecommendationButton', [
      transition(':enter', [
        // Need to minus 385px to account for sidenav width and add 100% to account for the width of the button
        // when translating it to the right.
        // We also set transform-origin to left to make the button expand from the left
        style({ transform: 'translateX(calc(-385px + 100%)) scaleX(0)', 'transform-origin': 'left' }),
        // We need to sync this with mat-sidenav transition while using mat-sidenav instead of a custom component
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0) scaleX(1)' }))
      ])
    ])
  ]
})
export class RecommendationsSideSheetComponent {
  @Input() recommendations: Array<RecommendedSegment>;
  @Input() loading: boolean;

  isRecommendationButtonFirstRendered = true;
  isButtonAnimationDone = false;
}
