import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MissionControlService } from '@core/services/mission-control/mission-control.service';

import { ApiSettings } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ApiSettingsService {
  constructor(private missionControlService: MissionControlService) {}

  getApiSettings(): Observable<ApiSettings[]> {
    return this.missionControlService.get<ApiSettings[]>('partner_apps');
  }
}
