import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { createDetector, getCallStateSelectors } from '@core/store/call-state';
import { getFetchStateSelector } from '@core/store/fetch-state';

import { AppConnection, appConnectionAdapter, AppConnectionState } from '../../types/app-connections.type';

const appConnectionDetector = createDetector<AppConnection>();
const getAppConnectionState = createFeatureSelector<AppConnectionState>('appConnection');

const { selectAll: getAppConnectionList, selectEntities: getAppConnectionsDictionary } =
  appConnectionAdapter.getSelectors(getAppConnectionState);

const { isSingleLoading, isSingleResting, getSingleError, isBatchLoading, isBatchResting, getBatchError } =
  getCallStateSelectors<AppConnectionState>(getAppConnectionState);

const getFetchList = getFetchStateSelector<AppConnectionState>(getAppConnectionState);

const isAppConnectionsLoaded = createSelector(
  getAppConnectionsDictionary,
  isBatchResting,
  (appConnections, batchResting) => appConnectionDetector('allLoaded')(appConnections) && batchResting
);

const isAppConnectionLoaded = (id: string): Selector<AppConnectionState, boolean> =>
  createSelector(
    getAppConnectionsDictionary,
    appConnections => !!(appConnectionDetector('oneLoaded')(appConnections, id) && isSingleResting)
  );

const getAppConnectionsByClientId = (appId: string): Selector<AppConnectionState, AppConnection[]> =>
  createSelector(getAppConnectionList, appConnections =>
    appConnections.filter(appConnection => appConnection.clientId === appId)
  );

const getAppConnectionsByResourceId = (appId: string): Selector<AppConnectionState, AppConnection[]> =>
  createSelector(getAppConnectionList, appConnections =>
    appConnections.filter(appConnection => appConnection.resourceId === appId)
  );

const getAppConnectionById = (id: string): Selector<AppConnectionState, AppConnection | undefined> =>
  createSelector(getAppConnectionsDictionary, entities => entities[id]);

export const appConnectionsQuery = {
  isSingleLoading,
  isSingleResting,
  getSingleError,
  isBatchLoading,
  isBatchResting,
  getBatchError,
  getFetchList,
  getAppConnectionList,
  getAppConnectionById,
  getAppConnectionsDictionary,
  isAppConnectionsLoaded,
  isAppConnectionLoaded,
  getAppConnectionsByClientId,
  getAppConnectionsByResourceId
};
