import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCallStateSelectors } from '@core/store/call-state';

import { FeatureSchema, PartnerConfigState } from '../../types';

const getPartnerConfigState = createFeatureSelector<PartnerConfigState>('partnerConfig');

const { isSingleLoading, isSingleResting, getSingleError } =
  getCallStateSelectors<PartnerConfigState>(getPartnerConfigState);

const getPartnerConfig = createSelector(getPartnerConfigState, state => state.partnerConfig);

const getUiSettings = createSelector(getPartnerConfigState, state => state.partnerConfig?.uiSettings);

const getUiSettingsSchema = createSelector(getPartnerConfigState, state => state.partnerConfig?.uiSettingsSchema);

const getVersion = createSelector(getPartnerConfigState, state => state.partnerConfig?.version);

const getApplicationUiSettings = createSelector(getUiSettings, state => state?.application);

const getTenantIdentification = createSelector(getApplicationUiSettings, state => ({
  name: state?.tenantName,
  logo: state?.theme?.brandLogo
}));

const getEnabledFeatures = createSelector(getUiSettings, state =>
  state
    ? Object.entries(state?.application?.features).reduce(
        (acc, [featureName, settings]) => ((settings as FeatureSchema).enabled ? [...acc, featureName] : acc),
        []
      )
    : []
);

const isSingleLoaded = createSelector(getPartnerConfigState, state => !!state.partnerConfig);

export const partnerConfigsQuery = {
  isSingleLoading,
  isSingleLoaded,
  isSingleResting,
  getSingleError,
  getPartnerConfig,
  getUiSettings,
  getUiSettingsSchema,
  getVersion,
  getEnabledFeatures,
  getApplicationUiSettings,
  getTenantIdentification
};
