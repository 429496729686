import { Injectable } from '@angular/core';

import { Tag } from '@shared/types';

import { TagDefs } from '../../types/tag-defs.type';

@Injectable({ providedIn: 'root' })
export class ReferenceTagDefs {
  readonly tagDefs: TagDefs<string> = {
    reference: {
      getDisplayPrefix: () => 'Reference',
      getDisplayValue: (tag: Tag) => tag.id
    }
  };
}
